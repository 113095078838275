import { connect } from 'react-redux';
import { FETCH_REPORT_DATA } from '../../ProductionReport/ProductionReportActions';
import MainView from './MainView';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';

import { fetchOrderStatsRequest } from './MainViewActions';

const mapStateToProps = state => {
  let mainViewState = state.getIn(['dashboard', 'main']);
  return {
    hasOrders: mainViewState.get('hasOrders'),
    ordersCountTotal: mainViewState.get('ordersCountTotal') || 0,
    ordersCountIssues: mainViewState.get('ordersCountIssues') || 0,
    ordersCountMonth: mainViewState.get('ordersCountMonth') || 0
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrderStats: () => dispatch(fetchOrderStatsRequest()),
    initProductionReport: () => dispatch(fetchAsync(FETCH_REPORT_DATA))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
