import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../shared/Modal';

const DisconnectStore = ({ storeId, provider, disconnectStore, cancel }) => {
  const providerName = useMemo(() => {
    switch (provider) {
      case 'etsy':
        return 'Etsy';
      case 'shopify':
        return 'Shopify';
      case 'woocommerce':
        return 'WooCommerce';
      case 'bigcommerce':
        return 'BigCommerce';
    }
  }, [provider]);

  return (
    <Modal
      isOpen
      className="medium"
      title="Are you sure you want to disconnect store?"
      cancelClick={cancel}
      cancelText="Cancel"
      ctaClick={() => disconnectStore(storeId)}
      actionText="Disconnect from Gooten"
    >
      <div className="p-4">{`This will remove your connection with your ${providerName} store. Your products created in Gooten will remain in the Product Hub.`}</div>
    </Modal>
  );
};

DisconnectStore.propTypes = {
  storeId: PropTypes.number.isRequired,
  provider: PropTypes.string.isRequired,
  disconnectStore: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default DisconnectStore;
