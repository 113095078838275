import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';

export const TestConnection = props => {
  switch (props.status) {
    case TestConnection.status.none:
      return props.canTest ? (
        <Button className="button-default small" onClick={() => props.testConnection()}>
          Test
        </Button>
      ) : null;
    case TestConnection.status.testing:
      return (
        <span className="webhook-test-btn">
          <LoadingSpinner />
        </span>
      );
    case TestConnection.status.success:
      return (
        <span className="webhook-test-btn color-success">
          <Icon small icon="checkCircle" />
          <b> Webhook Sent</b>
        </span>
      );
    case TestConnection.status.failed:
      return <span className="webhook-test-btn color-error">Failed!</span>;
  }
};

TestConnection.propTypes = {
  canTest: PropTypes.bool,
  testConnection: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired
};

TestConnection.status = {
  none: 0,
  testing: 1,
  success: 2,
  failed: 3
};

export default TestConnection;
