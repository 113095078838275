import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';
import { publishStateSelector } from '../../ProductPublishSelectors';
import { selectedProductIdsSelector } from '../../../../store/selectors/productDataSelectors';
import Config from '../../../../config';

export const availableProvidersSelector = createSelector([publishStateSelector], publish => {
  if (!publish.get('data')) return new List([]);

  const storage = publish.getIn(['data', 'storage']).set('name', 'Storage');

  // sort platforms in this order Shopify, Etsy, Woo, BigCommerce, TikTok
  const sortOrder = ['shopify', 'etsy', 'woocommerce', 'bigcommerce', 'tiktok'];
  let stores = publish
    .getIn(['data', 'stores'])
    .sort((a, b) => sortOrder.indexOf(a.get('provider')) - sortOrder.indexOf(b.get('provider')));

  return fromJS([storage, ...stores]);
});

export const isDisabledSelector = createSelector(
  [selectedProductIdsSelector],
  selectedIds => selectedIds.size > 1 || Config.get('linkMode')
);
