import React from 'react';
import PropTypes from 'prop-types';
import './ColorPill.scss';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import { COLORS } from '../../../constants';

export const ColorPill = props => {
  let className = 'colorpill-container flex-container';

  let isMultiColored = props.hex instanceof Array;

  if (props.active) {
    className += ' active';
  }

  if (props.disabled) {
    className += ' disabled';
  }

  if (isMultiColored) {
    className += ' two-color-item';
  }

  return isMultiColored ? (
    <div className={className}>
      <Tooltip content={props.title}>
        <div
          className="colorpill d-flex align-items-center justify-content-center"
          onClick={() => props.toggleValue(props.optionId, props.valueId)}
        >
          <div className="color-one" style={{ backgroundColor: props.hex[0] }} />
          <div className="color-two" style={{ backgroundColor: props.hex[1] }} />
          {props.active && (
            <Icon fill={COLORS.bananaPeel500} icon="check" className="position-absolute" />
          )}
        </div>
        <div className="disabled-diagonal-line" />
      </Tooltip>
    </div>
  ) : (
    <div className={className}>
      <Tooltip content={props.title}>
        <div
          style={{ backgroundColor: props.hex }}
          onClick={() => props.toggleValue(props.optionId, props.valueId)}
          className="colorpill d-flex"
        >
          {props.active && <Icon fill={COLORS.bananaPeel500} icon="check" className="m-auto" />}
        </div>
        <div className="disabled-diagonal-line" />
      </Tooltip>
    </div>
  );
};

ColorPill.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  hex: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  optionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueId: PropTypes.string.isRequired,
  toggleValue: PropTypes.func.isRequired
};

export default ColorPill;
