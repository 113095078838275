import { connect } from 'react-redux';
import { next, goto } from '../../containers/NavManager/NavManagerActions';
import {
  resetSelectedProduct,
  setCartItemEditMode,
  editCartItem,
  removeCartItem,
  toggleItemAsSample,
  clearCart,
  updateCartItemQuantity,
  showCartItemPreviewAsync,
  closeCartItemPreview,
  downloadCartItemPreviewAsync,
  fetchAsync,
  FETCH_COUNTRIES
} from '../../store/actions/dataActions';
import { countriesSelector } from '../../store/selectors/countriesSelectors';
import {
  cartItemsSelector,
  prodSelectionStepSelector,
  isCartItemPreviewShownSelector,
  previewImageUrlSelector,
  canProceedToCheckoutSelector
} from './CartSelectors';
import Cart from './Cart';
import withRouteStep from '../../hocs/withRouteStep';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = dispatch => {
  return {
    goNext: () => dispatch(next()),
    goToStep: (step, item) => {
      dispatch(resetSelectedProduct());

      if (step === 'ImageUpload') {
        dispatch(editCartItem(item));
        dispatch(setCartItemEditMode(true));
      }
      dispatch(goto(step));
    },
    editCartItem: (item, index) => {
      dispatch(setCartItemEditMode(true));
      dispatch(editCartItem(item, index));
    },
    removeCartItem: index => {
      dispatch(removeCartItem(index));
    },
    toggleItemAsSample: index => {
      dispatch(toggleItemAsSample(index));
    },
    clearCart: () => dispatch(clearCart()),
    updateCartItemQuantity: (item, newQuantity) => {
      dispatch(updateCartItemQuantity(item, newQuantity));
    },
    showCartItemPreview: item => {
      dispatch(showCartItemPreviewAsync(item));
    },
    closeCartItemPreview: () => dispatch(closeCartItemPreview()),
    downloadCartItemPreview: item => {
      dispatch(downloadCartItemPreviewAsync(item));
    },
    fetchShippingCountries: () => dispatch(fetchAsync(FETCH_COUNTRIES))
  };
};

const mapStateToProps = state => {
  const items = cartItemsSelector(state);
  const prodSelectionStep = prodSelectionStepSelector(state);
  const isCartItemPreviewShown = isCartItemPreviewShownSelector(state);
  const previewImageUrl = previewImageUrlSelector(state);
  const canProceedToCheckout = canProceedToCheckoutSelector(state);
  const shippingCountries = countriesSelector(state);

  return {
    items,
    prodSelectionStep,
    isCartItemPreviewShown,
    previewImageUrl,
    canProceedToCheckout,
    shippingCountries
  };
};

export default withRouteStep(connect(mapStateToProps, mapDispatchToProps)(Cart), 'cart-summary');
