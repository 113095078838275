import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('PACKING_SLIPS', 'CREATE');

export const SET_TOAST = getActionName('SET_TOAST');

export function setToast(message) {
  return {
    type: SET_TOAST,
    payload: { message }
  };
}
