import { connect } from 'react-redux';
import NeckLabels from './NeckLabels';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { initFor } from '../BaseCard/BaseCardActions';
import { сreateNeckLabel, updateNeckLabel, removeNeckLabel } from './NeckLabelsActions';

const init = initFor(NeckLabels.name);
const cardSelector = cardSelectorFor(NeckLabels.name);

const mapDispatchToProps = {
  init,
  сreateNeckLabel,
  updateNeckLabel,
  removeNeckLabel
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NeckLabels);
