import { fetch } from '../../../../../utils/http';
import Config from '../../../../../config';
import { tokenizeCard as tokenizeBt } from '../../../../../services/braintree';

const partnerId = () => Config.get('partnerId');
const recipeId = () => Config.get('recipeId');
const adminApiToken = () => Config.get('adminApiToken');

const countriesRequest = () => {
  const url = `${Config.get('storeApi')}countries`;

  const requestConfig = {
    method: 'GET',
    queryParams: { recipeId: recipeId() },
    headers: { Authorization: `Bearer ${adminApiToken()}` }
  };

  return { url, requestConfig };
};

const braintreeTokenRequest = () => {
  const url = `${Config.get('adminApi')}PartnersApi/GetBraintreeClientToken`;

  const requestConfig = {
    method: 'GET',
    queryParams: { partnerId: partnerId() },
    headers: { Authorization: `Bearer ${adminApiToken()}` }
  };

  return { url, requestConfig };
};

const paymentSettingsRequest = () => {
  const url = `${Config.get('adminApi')}partnersApi/GetPaymentsSettings`;

  const requestConfig = {
    method: 'GET',
    queryParams: { partnerId: partnerId() },
    headers: { Authorization: `Bearer ${adminApiToken()}` }
  };

  return { url, requestConfig };
};

const cardBody = card => ({
  paymentMethodType: 'CreditCard',
  NameOnCard: card.name,
  expirationMonth: card.month,
  expirationYear: card.year,
  cvv: card.cvv,
  isDefault: card.makeDefault,
  billingAddress: {
    line1: card.billingAddress.line1,
    line2: card.billingAddress.line2,
    city: card.billingAddress.city,
    state: card.billingAddress.state,
    postalCode: card.billingAddress.postalCode,
    countryCode: card.billingAddress.country
  },
  captchaToken: card.captchaToken
});

const addCardRequest = tokenized => {
  const MethodType = 'CreditCard';
  const body = { ...tokenized, MethodType };

  const url = `${Config.get('adminApi')}PartnersApi/AddPaymentMethod`;

  const requestConfig = {
    method: 'PUT',
    queryParams: {
      partnerId: partnerId()
    },
    headers: {
      Authorization: `Bearer ${adminApiToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return { url, requestConfig };
};

const updateCardRequest = card => {
  const body = cardBody(card);

  const url = `${Config.get('adminApi')}PartnersApi/UpdatePaymentMethod`;
  const token = card.token;

  const requestConfig = {
    method: 'PUT',
    queryParams: {
      partnerId: partnerId(),
      token
    },
    headers: {
      Authorization: `Bearer ${adminApiToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return { url, requestConfig };
};

const removeMethodRequest = token => {
  const url = `${Config.get('adminApi')}PartnersApi/RemovePaymentMethod`;
  const requestConfig = {
    method: 'POST',
    queryParams: {
      partnerId: partnerId(),
      token
    },
    headers: {
      Authorization: `Bearer ${adminApiToken()}`
    }
  };

  return { url, requestConfig };
};

const updatePaypalRequest = (nonce, token) => {
  const body = {
    MethodType: 'PayPal',
    PaymentMethodNonce: nonce,
    Token: token
  };

  const url = `${Config.get('adminApi')}PartnersApi/AddPaymentMethod`;
  const requestConfig = {
    method: 'PUT',
    queryParams: {
      partnerId: partnerId()
    },
    headers: {
      Authorization: `Bearer ${adminApiToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return { url, requestConfig };
};

const tokenizeCard = async card => {
  return new Promise((resolve, reject) => {
    tokenizeBt(card, card.braintreeToken)
      .then(res => {
        resolve({
          PaymentMethodNonce: res.nonce,
          MethodType: 'CreditCard',
          captchaToken: card.captchaToken,
          MakeDefault: card.makeDefault
        });
      })
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch(_ => reject({ msg: 'Failed to update Credit Card' }));
  });
};

const PaymentMethodsService = () => {
  const execute = async ({ url, requestConfig }) => fetch(url, requestConfig);

  return {
    countries: async () => execute(countriesRequest()),
    getBraintreeClientToken: async () => execute(braintreeTokenRequest()),
    getPaymentSettings: async () => execute(paymentSettingsRequest()),
    updateCard: async card => execute(updateCardRequest(card)),
    addCard: async tokenized => execute(addCardRequest(tokenized)),
    removeMethod: async token => execute(removeMethodRequest(token)),
    updatePaypal: async (nonce, token) => execute(updatePaypalRequest(nonce, token)),
    tokenizeCard: async card => tokenizeCard(card)
  };
};

// Singleton
export default PaymentMethodsService();
