import React from 'react';
import PropTypes from 'prop-types';
import { TabContainer, Tab } from 'gooten-components/src/components/shared/Tab';
import Chip from 'gooten-components/src/components/shared/Chip';
import SecurityService from '../../../Settings/SecurityService';

const OrdersViewTabsComponent = ({ tabs, group, updateGroupSelection, setPersonalizeView }) => {
  // This puts the 'All' tab at the beginning
  const tabsReOrdered = () => {
    const newTabs = tabs;
    const allTab = tabs.find(x => x.Value === 'All');
    newTabs.splice(newTabs.indexOf(allTab), 1);
    newTabs.unshift(allTab);
    return newTabs;
  };

  const updateSelection = item => {
    updateGroupSelection(item.Key);
    // hide the OrderItem/Order <Select/> component only when you are in NeedsPersonalization view
    if (item.Value === 'Needs Personalization') {
      setPersonalizeView(true);
    } else {
      setPersonalizeView(false);
    }
  };

  return tabs.length ? (
    <TabContainer className="ml-n2">
      {tabsReOrdered().map(item => {
        return (
          //NOTE: Vendors should not see Needs Personalization tab
          !(SecurityService.isVendor() && item.Value === 'Needs Personalization') && (
            <Tab active={group === item.Key} onClick={() => updateSelection(item)} key={item.Key}>
              <span className="mr-1">{item.Value}</span>
              <Chip>{item.RecordsCount}</Chip>
            </Tab>
          )
        );
      })}
    </TabContainer>
  ) : null;
};

OrdersViewTabsComponent.propTypes = {
  tabs: PropTypes.array,
  group: PropTypes.number,
  updateGroupSelection: PropTypes.func,
  setPersonalizeView: PropTypes.func
};

export default OrdersViewTabsComponent;
