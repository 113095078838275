import ILService from './il.service';

const _getCombineMapArg = (x, y) => {
  if (x >= 0) {
    x = '+' + x.toString();
  }
  else {
    x = x.toString();
  }

  if (y >= 0) {
    y = '+' + y.toString();
  }
  else {
    y = y.toString();
  }

  return x + y;
};

class ImgManipService {
  convertILtoImgManip(il) {
    // map IL to ImgManip string
    let iter = 0;
    let mapArgs = [];

    let cmdObj = {
      name: 'canvas',
      layers: [],
      commands: [],
      settings: {
        width: il.width,
        height: il.height
      }
    };

    il.layers.forEach((layer) => {

      const _convertToImgManipLayer = (img, isImage) => {
        let iobj = {
          name: 'image',
          settings: {
            uri: img.url
          },
          commands: []
        };

        if (isImage) {
          // checking only for image layers b/c image can be resized
          // and background area should be filled with transparent
          // background always transparent
          iobj.commands.push({name: 'repage'});
          iobj.commands.push({name: 'backgroundadd', args: {color: 'rgba(0,0,0,0)'}});
        }

        // crop
        if (img.crop) {
          iobj.commands.push({name: 'crop', args: {x1: img.crop.x1, y1: img.crop.y1, x2: img.crop.x2, y2: img.crop.y2}});
        }

        // resize
        iobj.commands.push({name: 'resize', args: {height: img.height, width: img.width}});

        if (img.rotation) {
          // rotate
          iobj.commands.push({name: 'rotate', args: {degrees: img.rotation}});
        }

        let lobj = {
          name: 'canvas',
          layers: [iobj],
          commands: [
            {name: 'combine', args: {map: '0='+_getCombineMapArg(img.left, img.top)}}
          ],
          settings: {
            width: img.viewport ? img.viewport.width : img.width,
            height: img.viewport ? img.viewport.height : img.height
          }
        };

        cmdObj.layers.push(lobj);

        // image positioning
        mapArgs.push(
          (iter++) + '=' + _getCombineMapArg(
            img.viewport ? img.viewport.x1 : img.x1,
            img.viewport ? img.viewport.y1 : img.y1
          )
        );
      };

      if (layer.images) {
        layer.images.forEach(
          img => _convertToImgManipLayer(img, true)
        );
      }
      else {
        _convertToImgManipLayer(layer);
      }
    });

    // add in final commands
    cmdObj.commands.push({name: 'combine', args: { map: mapArgs.join(',') }, index: 1 });

    // final crop for single templates only
    if (il.type === 'print' && il.final) {
      cmdObj.commands.push({
        name: 'crop', args: {
          x1: il.final.x1,
          x2: il.final.x2,
          y1: il.final.y1,
          y2: il.final.y2
        }, index: 2
      });
    }

    cmdObj.commands.push({name: 'resample2', args: {dpi: 300, units: 'PixelsPerInch'}, index: 998});

    // Todo: fixedW??
    // if (il.fixedW && il.fixedH) {
    //   var dW = il.width / il.fixedW;
    //   var dH = il.height / il.fixedH;
    //
    //   //Select delta to extent one of them
    //   var uDelta = Math.max(dW, dH);
    //
    //   var uW = Math.round(il.fixedW * uDelta);
    //   var uH = Math.round(il.fixedH * uDelta);
    //
    //   cmdObj.commands.push({ 'name': 'extent', 'args': { 'height': uH, 'width': uW, 'color': 'white' }, 'index': 898 });
    //   cmdObj.commands.push({ 'name': 'resize', 'args': { 'height': il.fixedH, 'width': il.fixedW, 'color': 'white' }, 'index': 899 });
    // }

    // Todo: imageFormatExtension??
    // if (imageFormatExtension && imageFormatExtension.convert) {
    //   cmdObj.commands.push({ 'name': 'convert',  'args': { 'format': imageFormatExtension.convert }, 'index' :999 });
    // }

    return cmdObj;
  }

  exportImgManipCmd(state) {
    // map editor state to ImgManip string
    const il = ILService.exportIL(state);
    const previewImgManip = this.convertILtoImgManip(il.preview);
    const printImgManip = this.convertILtoImgManip(il.print);

    return {previewImgManip, printImgManip};
  }
}

export default new ImgManipService();
