import React from 'react';
import PropTypes from 'prop-types';

import './Tile.scss';

export const Tile = props => {
  let className = 'thumbnail';

  if (props.active) {
    className += ' active';
  }

  if (props.disabled) {
    className += ' disabled';
  }

  const onToggleClick = () => {
    if (!props.disabled) {
      props.toggleValue(props.option, props.valueId);
    }
  };

  return (
    <div key={props.valueId} className="tile-container">
      <a onClick={onToggleClick} className={className}>
        <img src={props.imageUrl} alt="..." />
      </a>
      <span className="title"> {props.title} </span>
      {props.hasNecklabel ? (
        <>
          &ndash; <br />
          <span style={{ fontSize: '15px', lineHeight: '18px' }}>Custom Neck Label </span>
        </>
      ) : null}
    </div>
  );
};

Tile.propTypes = {
  active: PropTypes.bool.isRequired,
  hasNecklabel: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  valueId: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  toggleValue: PropTypes.func.isRequired
};

export default Tile;
