import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../shared/Button';
import Icon from '../../../../shared/Icon';
import { COLORS } from '../../../../../constants';

export const DeleteSelection = ({ handleClick, numberOfSelectedItems, handleClose }) => {
  return (
    <div className="delete-selection">
      <Button className="delete-selection-button p-2" onClick={handleClick}>
        Delete
      </Button>
      <span className="delete-selection-selected body-text-2">
        {numberOfSelectedItems} Variant{numberOfSelectedItems === 1 ? '' : 's'}
      </span>
      <div className="delete-selection-exit-icon" onClick={handleClose}>
        <Icon icon={'x'} fill="white" />
      </div>
      <style jsx>
        {`
          .delete-selection {
            z-index: 1000;
            display: flex;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            background-color: ${COLORS.neutralDark600};
            padding: 8px;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
          }
          :global(.delete-selection-button) {
            background: ${COLORS.neutralDark500};
          }
          :global(button.delete-selection-button .button-1) {
            color: white !important;
            padding-right: 8px !important;
            padding-left: 8px !important;
          }
          .delete-selection-selected {
            padding: 5px 26px 0 30px;
            height: 100%;
            color: white;
          }
          .delete-selection-exit-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 5px;
            padding-right: 5px;
          }
        `}
      </style>
    </div>
  );
};

DeleteSelection.propTypes = {
  handleClose: PropTypes.func.isRequired,
  numberOfSelectedItems: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default DeleteSelection;
