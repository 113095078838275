import { connect } from 'react-redux';
import UploadFromDevice from './UploadFromDevice';
import { fetchAsync } from '../../../../../../store/actions/dataActions';
import { IMAGE_FILE_UPLOAD } from '../../../ImageSelectionModalActions';
import { isMultiSpaceSelector } from '../../MyArtwork/MyArtworkSelectors';

const mapDispatchToProps = {
  uploadFiles: files => fetchAsync(IMAGE_FILE_UPLOAD, { files })
};

const mapStateToProps = (state, ownProps) => {
  return {
    isMultiSpace: isMultiSpaceSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFromDevice);
