import { Record } from 'immutable';

export default new Record({
  id: '',
  name: '',
  width: 0,
  height: 0,
  imgUrl: '',
  newName: ''
});
