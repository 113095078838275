import { connect } from 'react-redux';
import Draft from './Draft';
import {
  storeIdSelector,
  draftSelector,
  providerNameSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import { changeStoreField } from '../../../ProductPublishActions';

const mapDispatchToProps = {
  toogleDraftState: (storeId, selected) =>
    changeStoreField({ storeId, field: 'draft', value: selected })
};

const mapStateToProps = state => {
  return {
    selected: !!draftSelector(state),
    storeId: storeIdSelector(state),
    provider: providerNameSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Draft);
