import { createSelector } from 'reselect';
import {
  allProductsSelector,
  selectedProductIdSelector
} from '../../store/selectors/productDataSelectors';

export const allStepsSelector = state => state.get('__nav__').get('steps');

export const hasStepsSelector = createSelector([allStepsSelector], allSteps => allSteps.size);

export const currentStepSelector = state => state.get('__nav__').get('current');

export const mobileTitleSelector = createSelector(
  [allProductsSelector, selectedProductIdSelector],
  (products, productId) => {
    if (productId) {
      const product = products.find(p => p.get('id') === productId);
      if (product) {
        return product.get('name');
      }
    }

    return 'Create a New Product';
  }
);
