import StateManager from '../../../state/stateManager';
import { imageUpdate, editActivate } from '../../../state/actions/actionCreators';
import PositioningService from '../../../utils/positioningService';
import EventEmitter from 'events';
import { PUBLIC_EVENTS } from '../../../ImageEditor.events';

export class CenterButton {
  customClass: string;
  name: string;
  publicEvents: EventEmitter;
  element: any;
  isActive: boolean;
  handler: () => void;

  constructor(stateManager: StateManager, config: any, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;
    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Center';
    this.stateManager = stateManager;
    this.stateManager.subscribe(state => this.onStateChange(state));
  }

  onStateChange(state) {
    if (!this.element) {
      return;
    }
    
    const active = !state.getIn(['editor', 'cropActivated']) && state.getIn(['images', 'current', 'images']).size > 0;
    if (this.isActive !== active) {
      this.isActive = active;
      this.updateButtonState();
    }
  }

  updateButtonState() {
    this.element.className = ` editor-btn ${this.customClass} ${!this.isActive ? 'disabled' : ''}`;
    if (this.handler) {
      this.element.removeEventListener('click', this.handler);
      this.handler = null;
    }

    if (this.isActive) {
      this.handler = () => this.action();
      this.element.addEventListener('click', this.handler);
    }
  }

  getElement() {
    this.element = document.createElement('div');
    this.element.innerHTML = `<div class="icon-center toolbar-icon"></div>
    <span class="editor-tooltip-text">${this.name}</span>`;

    this.updateButtonState();
    return this.element;
  }

  action() {
    this.stateManager.dispatchAction(editActivate());
    let state = this.stateManager.getState();
    if (state.images.current.selected.imageId) {
      let position = PositioningService.getCenterPosition(state);
      this.stateManager.dispatchAction(
        imageUpdate(state.images.current.selected.imageId, position)
      );
      this.publicEvents.emit(PUBLIC_EVENTS.CENTER_IMAGE, position);
    }
  }
}
