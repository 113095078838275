// Rotate action
export const ROTATE_ANCHOR_RADIUS = 5;
export const ROTATE_ANCHOR_COLOR = '#F7E61B';

// Zoom action
// Max scale for images in normal size
export const MAX_SCALE = 5;
export const MIN_SIDE_SIZE = 30;

// Editor control
// internal max width/height of images loaded in canvas
export const MAX_IMAGE_WIDTH = 1000;
export const MAX_IMAGE_HEIGHT = 1000;
