import { connect } from 'react-redux';
import {
  cartItemsSelector,
  shippingMethodsGroupsSelector,
  shippingMethodsErrorsSelector,
  getShippingPricesRequestSelector
} from './ShippingMethodSelectors';
import { setShippingMethodForGroup } from '../../CheckoutActions';
import ShippingMethod from './ShippingMethod';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  const itemsInCart = cartItemsSelector(state);
  const shippingMethodsGroups = shippingMethodsGroupsSelector(state);
  const shippingMethodsErrors = shippingMethodsErrorsSelector(state);

  return {
    itemsInCart,
    shippingMethodsGroups,
    shippingMethodsErrors,
    shippingPricesRequestSelector: getShippingPricesRequestSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShippingMethodForGroup: (SKUs, shippingMethodId) => {
      return dispatch(setShippingMethodForGroup(SKUs, shippingMethodId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingMethod);
