import { createSelector } from 'reselect';

const returnAddressSelector = state => state.getIn(['settings', 'cards', 'returnAddress']);

export const loadingStatus = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['loading'])
);

export const savingStatus = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['saving'])
);

export const billingAddress = createSelector([returnAddressSelector], returnAddress => {
  const pd = returnAddress.getIn(['data', 'partnerDetails']).toJS();
  return [pd.Line1, pd.Line2, pd.City, pd.State, pd.PostalCode, pd.CountryCode]
    .filter(Boolean)
    .join(' ');
});

export const currentSelection = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['data', 'partnerDetails', 'ReturnAddressType'])
);

export const customAddressId = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['data', 'partnerDetails', 'ReturnAddress', 'AddressId'])
);

export const returnAddress = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['data', 'returnAddress'])
);

export const countries = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['data', 'countries']).toJS()
);

export const addressTypes = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['data', 'addressTypes']).toJS()
);

export const partnerDetails = createSelector([returnAddressSelector], returnAddress =>
  returnAddress.getIn(['data', 'partnerDetails']).toJS()
);
