import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import { emailOrEmptyRegex } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div`
  .panel .panel-body {
    padding-bottom: 42px;
  }
`;

class InviteTeamMember extends React.PureComponent {
  static get name() {
    return 'inviteTeamMember';
  }
  static get messages() {
    return {
      onFailure: 'Failed to invite Team Member',
      onSuccess: 'Team Member has been invited'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
  }

  componentDidUpdate() {
    if (this.props.isPristine) {
      Object.values(this.inputs).forEach(i => i.hideErrors());
    }
  }

  invite() {
    if (
      this.props.teamMembers.find(i => i.Email === this.props.data.email) ||
      this.props.invitations.find(i => i.Email === this.props.data.email)
    ) {
      this.props.notifyError('Failed to invite new Team Member. Email already used.');
    } else {
      this.props.update({ data: this.props.data });
    }
  }

  get canUpdate() {
    return (
      this.props.ready &&
      this.props.data.email &&
      Object.values(this.inputs).every(i => !i || i.isValid())
    );
  }

  render() {
    return (
      <Container>
        <BaseCard card={InviteTeamMember.name} title="Invite Team Members to Gooten">
          <Fragment>
            <div className="invite-team-member-card">
              <div className="row">
                <div className="col-sm-10 col-xs-12">
                  <TextInput
                    id="email"
                    showValidation
                    regexp={emailOrEmptyRegex}
                    validationMsg="Wrong format"
                    label="Email Address"
                    ref={ref => {
                      this.inputs.email = ref;
                    }}
                    placeholder="johannes@gooten.com…"
                    onChange={(value, id) => this.props.change(value, id)}
                    value={this.props.data.email}
                  />
                </div>
              </div>
            </div>
            <Button
              className="button-primary large"
              disabled={!this.canUpdate}
              onClick={() => this.invite()}
            >
              {this.props.invited ? 'Invite Sent Successfuly!' : 'Send Invites'}
            </Button>
          </Fragment>
        </BaseCard>
      </Container>
    );
  }
}

InviteTeamMember.propTypes = {
  teamMembers: PropTypes.instanceOf(List),
  invitations: PropTypes.instanceOf(List),
  notifyError: PropTypes.func.isRequired,
  isPristine: PropTypes.bool,
  ready: PropTypes.bool,
  change: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.object,
  invited: PropTypes.bool
};

export default InviteTeamMember;
