import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'gooten-components/src/components/shared/Spinner';
import OptionsModal from 'gooten-components/src/components/shared/OptionsModal';
import productsService from '../ProductsService';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Chip from 'gooten-components/src/components/shared/Chip';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import { errorInfo } from '../productsUtils';

class ProductsListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  handleSelectModalOption = action => {
    if (action === 'viewinstore') {
      window.open(this.productUrlInStore(), '_blank');
    } else {
      this.props.onSelectModalOption(this.props.item.get('id'), action);
    }
  };

  productUrlInStore = () =>
    productsService.getProductUrlInStore(this.props.storeName, this.props.item);

  preventStatusChange = () =>
    ['PENDING', 'PLATFORM_DEACTIVATED', 'FREEZE', 'FAILED'].includes(this.props.item.get('state'));

  changeSatatus = () => (this.props.item.get('status') === 'unpublished' ? 'Publish' : 'Unpublish');

  options = () => {
    if (this.props.item.get('type').toLowerCase() === 'storage') {
      return ['Place an Order', 'Edit', 'Duplicate', 'Delete'];
    } else {
      let opts = this.productUrlInStore()
        ? ['Place an Order', 'View In Store', 'Edit', 'Duplicate', 'Stop Fulfillment', 'Delete']
        : ['Place an Order', 'Edit', 'Duplicate', 'Stop Fulfillment', 'Delete'];

      if (!this.preventStatusChange()) {
        opts.splice(1, 0, this.changeSatatus());
      }

      return opts;
    }
  };

  handleMenuClick = e => {
    e.stopPropagation();
    this.setState({ showModal: true });
  };

  clickTimer = undefined;
  clickedProductArea = p => {
    if (this.clickTimer) {
      clearInterval(this.clickTimer);
      this.clickTimer = undefined;
      this.props.onSelectModalOption(this.props.item.get('id'), 'edit');
    } else {
      this.clickTimer = setTimeout(() => {
        this.clickTimer = undefined;
      }, 500);
      this.props.selectExclusive(p);
    }
  };

  //todo
  error = () => errorInfo(this.props.item.get('disabledSkus'));

  render() {
    const nameContainer = (
      <div className="mt-1">
        <span>{this.props.name}</span>
      </div>
    );

    const descContainer = (
      <div className="desc-container d-flex">
        <span>{this.props.desc}</span>
        {this.props.type === 'store' ? (
          <span className="hidden-xs">
            &nbsp;CONNECTED
            {this.props.isAll && ' IN ' + this.props.storeName}
          </span>
        ) : null}
      </div>
    );

    const errorContainer = this.error() && (
      <Tooltip containerClassName="w-auto mt-2" content={this.error().tooltip}>
        <Chip className="red" iconLeft="slash">
          {this.error().message}
        </Chip>
      </Tooltip>
    );

    return (
      <>
        <TableRow columns="3rem 5rem auto 4rem" size="auto">
          <Cell onClick={this.props.select}>
            <Checkbox className="no-click" checked={this.props.selected} />
          </Cell>
          <Cell className="p-1" onClick={this.clickedProductArea}>
            {this.props.pending ? (
              <Spinner />
            ) : this.props.image ? (
              <img className="img-thumbnail" src={this.props.image} alt={this.props.name} />
            ) : (
              <div className="img-missing" />
            )}
          </Cell>
          <Cell
            className="visible-xs"
            onClick={() => this.props.onSelectModalOption(this.props.item.get('id'), 'edit')}
          >
            <div className="name-and-desc d-flex justify-content-center h-100">
              {nameContainer}
              {descContainer}
              {errorContainer}
            </div>
          </Cell>
          <Cell className="hidden-xs" onClick={this.props.select}>
            <div className="col-sm-8">{nameContainer}</div>
            <div className="col-sm-4">{descContainer}</div>
            {errorContainer}
          </Cell>
          <Cell className="menu-column centered">
            <Button className="button-default medium" onClick={this.handleMenuClick} iconOnly>
              <Icon icon="moreVertical" />
            </Button>
            {this.state.showModal ? (
              <OptionsModal
                items={this.options()}
                onClickItem={this.handleSelectModalOption}
                onDismiss={() => {
                  this.setState({ showModal: false });
                }}
              />
            ) : null}
          </Cell>
        </TableRow>
        <style jsx>
          {`
            :global(.menu-column) :global(.options-modal) {
              position: absolute;
              right: 1rem;
              top: 3rem;
              text-transform: initial;
              font-weight: 900;
            }
            .img-thumbnail,
            .img-missing {
            }
            :global(.no-click) {
              pointer-events: none;
            }
            .name-and-desc {
              flex-direction: column;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 1.25;
            }
          `}
        </style>
      </>
    );
  }
}

ProductsListItem.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  selectExclusive: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  isAll: PropTypes.bool.isRequired,
  storeName: PropTypes.string,
  item: PropTypes.object,
  onSelectModalOption: PropTypes.func
};

export default ProductsListItem;
