import React from 'react';
import PropTypes from 'prop-types';
import './Backdrop.scss';

class Backdrop extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    closeSidebar: PropTypes.func.isRequired,
    hideBackdrop: PropTypes.func.isRequired
  };

  hide() {
    this.props.closeSidebar();
    this.props.hideBackdrop();
  }

  render() {
    let className = 'backdrop';

    if (this.props.isActive) {
      className += ' active';
    }

    return <div className={className} onClick={() => this.hide()} />;
  }
}

Backdrop.displayName = 'backdrop';

export default Backdrop;
