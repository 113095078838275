import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Wizard } from 'gooten-components';
import AppStore from '../../store';
import Config from '../../config';
import analyticsService from 'gooten-components/src/services/analyticsService';
import MobileNotificationModal from 'gooten-components/src/components/shared/MobileNotificationModal';
import Loader from 'gooten-components/src/components/shared/Loader';

import './CreateProductView.scss';

class CreateProductView extends React.PureComponent {
  componentDidMount() {
    analyticsService.track('Hub - Create Flow', 'Started', 'Hub');
    // Check that stores are loaded
    if (!this.props.storesLoaded) {
      this.props.loadStores();
    }
  }

  generateConfig() {
    // get initial config and modify it depends on how you want Wizard to show up
    const config = Config.toJS();
    config.analyticsSource = 'Hub - Create Flow';
    config.analyticsCategory = 'Hub';
    config.createMode = true;
    config.steps = [
      {
        id: 'ProductSelection',
        config: {}
      },
      {
        id: 'SKUSelection',
        config: {}
      },
      {
        id: 'ImageUpload',
        config: {
          editorConfig: {
            container: '.gooten-image-editor',
            state: null,
            allowMultiImages: false,
            zoomControlShown: true,
            previewControlShown: false,
            toolbarShown: true,
            toolbarOptions: {
              customClass: 'my-toolbar',
              buttons: [
                {
                  type: 'alignHorizontal',
                  name: 'Align Horizontal',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                {
                  type: 'alignVertical',
                  name: 'Align Vertical',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                { type: 'crop', name: 'Crop', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'rotate', name: 'Rotate', customClass: 'my-toolbar-action editor-tooltip' },
                // { type: 'orientation', name: 'Orientation', customClass: 'my-toolbar-action' },
                { type: 'undo', name: 'Undo', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'redo', name: 'Redo', customClass: 'my-toolbar-action editor-tooltip' }
              ]
            }
            // previewControlOptions: {
            //   customClass: 'my-preview-control', previewShown: false, maximizeShown: false
            // }
          },
          sliderConfig: {
            width: 400,
            height: 600,
            contentHeight: 490
          }
        }
      },
      {
        id: 'ProductPreview',
        config: {
          defaultWidth: 1024,
          defaultHeight: 1024,
          minWidth: 350,
          minHeight: 350,
          maxWidth: 2048,
          maxHeight: 2048
        }
      },
      {
        id: 'SaveAndPublish',
        config: {
          storageSelected: this.props.isStorageSelected,
          stores: this.props.stores.map(s => {
            // Etsy store is not active, can't have published products, only unpublished (drafts)
            const etsyInactive =
              s.get('provider').toLowerCase() === 'etsy' &&
              s.getIn(['settings', 'about', 'status']) !== 'active';

            return {
              id: s.get('id'),
              name: s.get('storeName'),
              inactive: etsyInactive,
              provider: s.get('provider'),
              selected: s.get('id') === this.props.selectedStoreId,
              draft: etsyInactive,
              collections: s.get('collections'),
              tags: s.get('tags')
            };
          })
        }
      }
    ];

    return config;
  }

  render() {
    // Check that stores are loaded
    if (!this.props.storesLoaded) {
      return <Loader />;
    }
    const conf = this.generateConfig();
    return (
      <div>
        <Wizard config={conf} store={AppStore} />
        <MobileNotificationModal />
      </div>
    );
  }
}

CreateProductView.propTypes = {
  stores: PropTypes.instanceOf(List),
  storesLoaded: PropTypes.bool,
  selectedStoreId: PropTypes.number,
  isStorageSelected: PropTypes.bool,
  loadStores: PropTypes.func.isRequired
};

export default CreateProductView;
