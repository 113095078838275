import React from 'react';
import EtsyPublishForm from './EtsyPublishForm';
import ShopifyPublishForm from './ShopifyPublishForm';
import StoragePublishForm from './StoragePublishForm';
import WooPublishForm from './WooPublishForm';
import BigCommercePublishForm from './BigCommercePublishForm';
import TikTokPublishForm from './TikTokPublishForm';

class ProvidersFactory {
  from(provider, sortedSelectedSkus, skusWithNeckTag, isProvidersTabHidden, isMultiProduct) {
    switch (provider.get('provider')) {
      case 'shopify':
        return (
          <ShopifyPublishForm
            key="1"
            skus={sortedSelectedSkus}
            providersTabHidden={isProvidersTabHidden}
            isMultiProduct={isMultiProduct}
          />
        );

      case 'etsy':
        return (
          <EtsyPublishForm
            key="2"
            skus={sortedSelectedSkus}
            providersTabHidden={isProvidersTabHidden}
            isMultiProduct={isMultiProduct}
          />
        );

      case 'woocommerce':
        return (
          <WooPublishForm
            key="3"
            skus={sortedSelectedSkus}
            providersTabHidden={isProvidersTabHidden}
            isMultiProduct={isMultiProduct}
          />
        );

      case 'bigcommerce':
        return (
          <BigCommercePublishForm
            key="5"
            skus={sortedSelectedSkus}
            providersTabHidden={isProvidersTabHidden}
            isMultiProduct={isMultiProduct}
          />
        );

      case 'tiktok':
        return (
          <TikTokPublishForm
            key="6"
            skus={sortedSelectedSkus}
            providersTabHidden={isProvidersTabHidden}
            isMultiProduct={isMultiProduct}
          />
        );

      default:
        return (
          <StoragePublishForm
            key="4"
            skus={sortedSelectedSkus}
            skusWithNeckTag={skusWithNeckTag}
            providersTabHidden={isProvidersTabHidden}
            isMultiProduct={isMultiProduct}
          />
        );
    }
  }
}

export default new ProvidersFactory();
