import { connect } from 'react-redux';
import ImageUpload from './ImageUpload';
import {
  selectedVariantSelector,
  selectedVariantIndexSelector,
  selectedSpaceSelector,
  selectedSpaceIndexSelector,
  totalItemsToProcessSelector,
  remainingItemsToProcessSelector,
  variantsSelector,
  productVariantsMinPriceSelector,
  productVariantsMaxPriceSelector,
  imageUploadStateSelector,
  isAllImagesFilledSelector,
  isAnyImageFilledSelector,
  isWorkingSelector,
  showPrintGuidelinesSelector,
  skuOverflow,
  spacesLoadedSelector,
  addToCartSelector,
  isCartItemEditMode,
  editItemSelector,
  editItemIndexSelector,
  hasProductDetails,
  hasProductVariants,
  selectedProductName,
  sideBarOpenedSelector,
  bulkLoaderProgressSelector
} from './ImageUploadSelectors';
import {
  variantSelect,
  editorNext,
  defaultVariantSelect,
  setReady,
  modeChange,
  togglePanel,
  editorBack,
  resetImageUpload,
  hideImagesMissingModal,
  showImagesMissingModal,
  prepareImages,
  showSideBar,
  editOrderedImage,
  initImageModal
} from './ImageUploadActions';
import {
  templatesInit,
  imageAdd,
  addToCart,
  setCartItemEditMode,
  updateCartItem,
  fetchAsync,
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH
} from '../../store/actions/dataActions';
import { goto } from '../../containers/NavManager/NavManagerActions';
import analyticsService from '../../services/analyticsService';
import Config from '../../config';
import withRouteStep from '../../hocs/withRouteStep';
import {
  isOrientationChangedSelector,
  shouldChangeOrientation,
  connectedStoresSelector,
  publishedOrientationSelector
} from '../SKUSelection/SKUSelectionSelectors';
import { fromJS } from 'immutable';
import { sidebarSelector } from '../ProductSelection/ProductSelectionSelectors';
import { closeSideBar } from '../ProductSelection/atoms/ProductDetails/ProductDetailsActions';
import { isEmbroiderySelected } from './atoms/Embroidery/EmbroiderySelectors';
import { multiproductSelector } from '../ProductPublish/ProductPublishSelectors';
import { bulkEditSelector } from '../../store/selectors/productDataSelectors';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const closeEditModeAndSave = (item, index) => {
    // update orientation here...
    const updatedItem = fromJS({
      ...item.toJS(),
      sku: {
        ...item.toJS().sku,
        orientation: stateProps.shouldChangeOrientation ? 'changed' : '',
        savedOrientation: item.getIn(['sku', 'orientation'])
      },
      pricing: null //remove pricing so it will get pricing again for neck label changes
    });
    dispatch(updateCartItem(updatedItem, index));
    dispatch(goto(ownProps.config.cartEditMode.parentStep));
  };

  const handleAddToCart = items => {
    // update orientation here...
    const updatedItems = items.map(item =>
      item.setIn(['sku', 'savedOrientation'], stateProps.publishedOrientation)
    );

    analyticsService.trackWithDynamicSource('Image Upload', 'Add to cart');
    items.forEach(i => {
      const productAddObj = {
        product_sku: i.getIn(['sku', 'sku']),
        product_name: i.get('name'),
        product_price: i.getIn(['variant', 'minCost'])
      };
      // This is to maintain old COF events
      analyticsService._track('custom order', 'product added to order', null, null, productAddObj);
    });
    dispatch(addToCart(updatedItems));

    if (ownProps.config.placeOrderSource) {
      browserBack();
    } else {
      dispatch(editorNext());
    }
  };

  const closeEditModeAndGoBack = () => {
    dispatch(goto(ownProps.config.cartEditMode.parentStep));
  };

  const browserBack = () => {
    window.history.go(-1);
  };

  let mergedProps = {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };

  mergedProps.isCOFEditDesignMode =
    ownProps.config.editDesignMode && ownProps.config.editDesignMode.active;

  if (Config.get('cof')) {
    mergedProps.next = () =>
      stateProps.isCartItemEditMode
        ? closeEditModeAndSave(stateProps.editItem, stateProps.editItemIndex)
        : handleAddToCart(stateProps.addToCartItems);
    mergedProps.back = () => {
      stateProps.isCartItemEditMode
        ? closeEditModeAndGoBack()
        : mergedProps.isCOFEditDesignMode
        ? browserBack()
        : dispatch(editorBack());
    };
  }

  return mergedProps;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const closeEditModeAndGoBack = () => {
    dispatch(goto(ownProps.config.cartEditMode.parentStep));
  };

  return {
    dispatch,
    loadProductVariants: productName => dispatch(fetchAsync(PRODUCT_VARIANTS_FETCH, productName)),
    loadProductDetails: productName => dispatch(fetchAsync(PRODUCT_DETAILS_FETCH, productName)),
    variantSelect: (skuIndex, spaceIndex, prevSkuIndex, prevSpaceIndex) =>
      dispatch(variantSelect(skuIndex, spaceIndex, prevSkuIndex, prevSpaceIndex)),
    modeChange: mode => dispatch(modeChange(mode)),
    templatesInit: () => dispatch(templatesInit()),
    imageAdd: images => dispatch(imageAdd(images)),
    togglePanel: () => dispatch(togglePanel()),
    defaultVariantSelect: variants => dispatch(defaultVariantSelect(variants)),
    setReady: () => dispatch(setReady()),
    resetImageUpload: () => dispatch(resetImageUpload()),
    next: () =>
      ownProps.config.editMode && ownProps.config.editMode.active
        ? closeEditModeAndGoBack()
        : dispatch(editorNext()),
    back: isMultiproduct =>
      ownProps.config.editMode && ownProps.config.editMode.active
        ? closeEditModeAndGoBack()
        : (Config.get('editMode') && isMultiproduct) || Config.get('duplicateMode')
        ? dispatch(goto('SaveAndPublish'))
        : dispatch(editorBack()),
    initImageModal: options => dispatch(initImageModal(options)),
    hideImagesMissingModal: () => dispatch(hideImagesMissingModal()),
    showImagesMissingModal: () => dispatch(showImagesMissingModal()),
    prepareImages: images => dispatch(prepareImages(images)),
    showSideBar: value => dispatch(showSideBar(value)),
    setCartItemEditMode: value => dispatch(setCartItemEditMode(value)),
    closeSideBar: () => dispatch(closeSideBar()),
    editOrderedImage: () => dispatch(editOrderedImage())
  };
};

const mapStateToProps = (state, ownProps) => {
  let props = {
    selectedProductName: selectedProductName(state),
    hasProductDetails: hasProductDetails(state),
    hasProductVariants: hasProductVariants(state),
    variants: variantsSelector(state) || [],
    currentVariantIndex: selectedVariantIndexSelector(state),
    currentVariant: selectedVariantSelector(state),
    minPrice: productVariantsMinPriceSelector(state),
    maxPrice: productVariantsMaxPriceSelector(state),
    editorMode: imageUploadStateSelector(state).get('editorMode'),
    panelShown: imageUploadStateSelector(state).get('panelShown'),
    isImagesMissingModalOpen: imageUploadStateSelector(state).get('isImagesMissingModalOpen'),
    skuOverflow: skuOverflow(state),
    isWorking: isWorkingSelector(state),
    spacesLoaded: spacesLoadedSelector(state),
    isCartItemEditMode: isCartItemEditMode(state),
    editItem: editItemSelector(state),
    editItemIndex: editItemIndexSelector(state),
    sideBarOpened: sideBarOpenedSelector(state),
    showPrintGuidelines: showPrintGuidelinesSelector(state),
    isOrientationChanged: isOrientationChangedSelector(state),
    connectedStores: connectedStoresSelector(state),
    sidebar: sidebarSelector(state),
    isEmbroidery: isEmbroiderySelected(state),
    isMultiproduct: multiproductSelector(state),
    isBulkEdit: bulkEditSelector(state),
    bulkLoaderProgress: bulkLoaderProgressSelector(state),
    shouldChangeOrientation: shouldChangeOrientation(state),
    publishedOrientation: publishedOrientationSelector(state),
    totalNumberOfItemsToProcess: totalItemsToProcessSelector(state),
    remainingNumberOfItemsToProcess: remainingItemsToProcessSelector(state),
    orientationDisabled: Config.get('linkMode') || ownProps.config.isOrderPageEdit
  };

  if (Config.get('cof') && !props.isCartItemEditMode) {
    // update orientation in COF create product flow
    props.addToCartItems = addToCartSelector(state).map(item =>
      item.setIn(['sku', 'orientation'], props.shouldChangeOrientation ? 'changed' : '')
    );
  }

  props.nextButtonLabel = props.isCartItemEditMode
    ? ownProps.config.cartEditMode.nextButtonLabel
    : ownProps.config.nextButtonLabel
    ? ownProps.config.nextButtonLabel
    : 'Continue';

  props.backButtonLabel = props.isCartItemEditMode
    ? ownProps.config.cartEditMode.backButtonLabel
    : ownProps.config.backButtonLabel
    ? ownProps.config.backButtonLabel
    : 'Previous step';

  if (props.currentVariant) {
    props.currentSpace = selectedSpaceSelector(state);
  }

  if (!props.currentSpace) {
    return props;
  }

  props.currentSpaceIndex = selectedSpaceIndexSelector(state);
  props.allImagesFilled = isAllImagesFilledSelector(state);
  props.anyImageFilled = isAnyImageFilledSelector(state);

  return props;
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImageUpload),
  'image-upload'
);
