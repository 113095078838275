import { connect } from 'react-redux';
import AccountActivity from './AccountActivity';
import { initFor, changePageFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';

const init = initFor(AccountActivity.name);
const changePage = changePageFor(AccountActivity.name);
const cardSelector = cardSelectorFor(AccountActivity.name);

const mapDispatchToProps = {
  init,
  changePage
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    page: cardState.get('page'),
    pageSize: cardState.get('pageSize'),
    total: cardState.get('total')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivity);
