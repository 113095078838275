// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_ORDERSTATS_REQUEST = 'FETCH_ORDERSTATS_REQUEST';
export const FETCH_ORDERSTATS_SUCCESS = 'FETCH_ORDERSTATS_SUCCESS';
export const FETCH_ORDERSTATS_FAIL = 'FETCH_ORDERSTATS_FAIL';

// ------------------------------------
// Actions
// ------------------------------------
export function fetchOrderStatsRequest() {
  return {
    type: FETCH_ORDERSTATS_REQUEST
  };
}

export function fetchOrderStatsSuccess(data) {
  return {
    type: FETCH_ORDERSTATS_SUCCESS,
    payload: { data }
  };
}

export function fetchOrderStatsFail(error) {
  return {
    type: FETCH_ORDERSTATS_FAIL,
    payload: { error }
  };
}
