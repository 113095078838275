import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';

export default class WelcomeHeader extends React.Component {
  render() {
    var currentHour = new Date().getHours();
    var greeting = 'Good ';

    if (currentHour < 12) {
      greeting += 'Morning, ';
    } else if (currentHour >= 12 && currentHour <= 18) {
      greeting += 'Afternoon, ';
    } else if (currentHour >= 18 && currentHour <= 24) {
      greeting += 'Evening, ';
    } else {
      greeting = 'Hello ';
    }

    greeting += this.props.userName + '!';

    return (
      <PageHeader
        size="standard"
        title={greeting}
        primaryText="Create a Product"
        secondaryText="Order a Product"
        primaryHref={
          this.props.isOLMPartner
            ? '/admin#/hub/create-product/product-selection'
            : this.props.onboardingCompleted
            ? '/admin#/hub/create-product/product-selection'
            : null
        }
        secondaryHref={
          this.props.isOLMPartner
            ? '/admin#/custom-order-form'
            : this.props.onboardingCompleted
            ? '/admin#/custom-order-form'
            : null
        }
      />
    );
  }
}

WelcomeHeader.propTypes = {
  userName: PropTypes.string,
  onboardingCompleted: PropTypes.bool,
  isOLMPartner: PropTypes.bool
};
