export const queryStringToJSON = qs => {
  qs = qs || location.search.slice(1);

  var pairs = qs.split('&');
  var result = {};
  pairs.forEach(p => {
    var pair = p.split('=');
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || '');

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        if (
          key === 'VendorIds' ||
          key === 'StatusIds' ||
          key === 'PartnerIds' ||
          key === 'PartnerGroupIds'
        ) {
          result[key].push(parseInt(value));
        } else {
          result[key].push(value);
        }
      } else {
        result[key] = [result[key], value];
      }
    } else {
      if (
        key === 'VendorIds' ||
        key === 'StatusIds' ||
        key === 'PartnerIds' ||
        key === 'PartnerGroupIds'
      ) {
        result[key] = [parseInt(value)];
      } else if (key === 'group' || key === 'pageSize' || key === 'page') {
        result[key] = parseInt(value);
      } else if (key === 'Desc') {
        return result[key] === 'true';
      } else {
        result[key] = value;
      }
    }
  });

  return JSON.parse(JSON.stringify(result));
};

export const paginationItems = (totalNumber, divider) => {
  let i = 1;
  let items = [];

  totalNumber = totalNumber / divider;

  while (i < totalNumber + 1) {
    items.push({ name: i, id: i });
    i++;
  }

  return items;
};

export const pageShowingCalculation = (pageSize, pageNumber, totalItems) => {
  pageSize = parseInt(pageSize);
  pageNumber = parseInt(pageNumber);
  totalItems = parseInt(totalItems);
  const showingFrom = pageSize * (pageNumber - 1);
  const showingTo = showingFrom + pageSize;
  return `Showing ${showingFrom} - ${showingTo} of ${totalItems}`;
};
