import { connect } from 'react-redux';
import NewProductSelection from './NewProductSelection';
import {
  regionsSelector,
  selectedRegionIndexSelector,
  selectedSubCategoryIndexSelector,
  currentVariantSelector,
  regionsActiveSelector,
  subCategoriesActiveSelector,
  mappingsProgressSelector,
  canGoNextSelector,
  selectedSubCategorySelector
} from '../../../../SKUsSelectionViewSelectors';
import { selectedGootenProductSelector } from '../../../../../LinkProductSelectors';
import {
  productsForSelectSelector,
  selectedProductsForSelectSelector,
  subCategoriesForSelectSelector
} from './NewProductSelectionSelectors';
import {
  selectProduct,
  selectRegionIndex,
  selectSubCategoryIndex,
  setSelection,
  deselectLastSKU,
  setRegionsToActive,
  setSubCategoriesToActive
} from '../../../../SKUsSelectionViewActions';
import { providerPathSelector } from '../../../../../ProductSelectionView/ProductSelectionViewSelectors';
import { continueButtonLabelSelector } from '../../ProductSelectionSelectors';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/
const mapDispatchToProps = {
  selectProduct: product => selectProduct(product),
  selectRegionIndex: region => selectRegionIndex(region),
  selectSubCategoryIndex: category => selectSubCategoryIndex(category),
  setRegionsToActive: () => setRegionsToActive(),
  setSubCategoriesToActive: () => setSubCategoriesToActive(),
  setSelectionActive: () => setSelection(true),
  deselectLastSKU: () => deselectLastSKU()
};

const mapStateToProps = state => {
  return {
    productsForSelect: productsForSelectSelector(state),
    regions: regionsSelector(state),
    selectedProduct: selectedGootenProductSelector(state),
    selectedProductsForSelect: selectedProductsForSelectSelector(state),
    selectedRegionIndex: selectedRegionIndexSelector(state),
    selectedSubCategoryIndex: selectedSubCategoryIndexSelector(state),
    selectedSubCategory: selectedSubCategorySelector(state),
    subCategoriesForSelect: subCategoriesForSelectSelector(state),
    selectedVariant: currentVariantSelector(state),
    providerPath: providerPathSelector(state),
    canGoNext: canGoNextSelector(state),
    mappingProgress: mappingsProgressSelector(state),
    regionsActive: regionsActiveSelector(state),
    subCategoriesActive: subCategoriesActiveSelector(state),
    continueButtonLabel: continueButtonLabelSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProductSelection);
