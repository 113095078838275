import React from 'react';
import PropTypes from 'prop-types';
import './BackgroundSelect.scss';
import ColorPreset from './../../../../shared/ColorPreset';
import Input from '../../../../shared/Input';
import Icon from '../../../../shared/Icon';
import Button from '../../../../shared/Button';

const hexRegex = /^([0-9a-f]{6})$/i;

const toHexFormat = color => `#${color}`;

class BackgroundSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addCustomColor: false };
  }

  static propTypes = {
    disabled: PropTypes.bool,
    presets: PropTypes.array,
    customColor: PropTypes.string,
    items: PropTypes.array,
    updateBackground: PropTypes.func,
    updateCustomColor: PropTypes.func
  };

  handleColorChange(color) {
    if (this.props.disabled) return;
    this.setState({ addCustomColor: false });
    this.props.updateBackground(this.props.items, color);
  }

  handleCustomColorChange(color) {
    this.props.updateCustomColor(color);

    if (this.props.disabled) return;
    const hexInvalid = !hexRegex.test(color);
    this.setState({ hexInvalid: hexInvalid });
    if (!hexInvalid) {
      this.props.updateBackground(this.props.items, toHexFormat(color));
    }
  }

  handleAddCustomColor() {
    if (this.props.disabled) return;
    this.setState({ addCustomColor: true });
  }

  getColorOfSelectedItems() {
    const sameColor = new Set(this.props.items.map(item => item.backgroundColor));
    return sameColor.size === 1 ? sameColor.values().next().value : '';
  }

  render() {
    const addCustomColorBtn = (
      <div className="d-flex">
        <Button
          iconOnly
          className="button-deafault large"
          onClick={() => this.handleAddCustomColor()}
        >
          <Icon icon="plusCircle" />
        </Button>
      </div>
    );

    const colors = this.props.presets.map((preset, index) => {
      return (
        <ColorPreset
          key={index}
          color={preset}
          getRealColor={() => this.getColorOfSelectedItems()}
          applyColor={() => this.handleColorChange(preset)}
        />
      );
    });

    return (
      <div
        className={`component-background-select text-left${this.props.disabled ? ' disabled' : ''}`}
      >
        <div>{colors}</div>
        <div className="row">
          <div className="col-xs-2 pr-1">{addCustomColorBtn}</div>
          {this.state.addCustomColor ? (
            <div className="col-xs-10 pl-1">
              <div className="input-wrapper hex-code-input">
                <Input
                  type="text"
                  disabled={this.props.disabled}
                  label={'Custom Color'}
                  value={this.props.customColor}
                  onChange={e => this.handleCustomColorChange(e.target.value)}
                  hasError={this.state.hexInvalid && this.props.customColor}
                  errorText={
                    this.state.hexInvalid && this.props.customColor ? 'Hex Code (FFFFFF)' : null
                  }
                />
                <div className="hex-placeholder">#</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

BackgroundSelect.displayName = 'BackgroundSelect';

export default BackgroundSelect;
