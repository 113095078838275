import { connect } from 'react-redux';
import CreditCard from './CreditCard';
import BusinessAddress from '../BillingAddress/BillingAddress';
import { initFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import {
  updateCard,
  removeMethod,
  updatePayPal,
  updateDefault,
  getSetupIntentId,
  showCreditCardModal,
  handleStripeResponse
} from './CreditCardActions';
import { countriesSelector } from 'gooten-components/src/store/selectors/countriesSelectors';
import {
  paymentSelector,
  selectedAddressSelector
} from 'gooten-components/src/components/Checkout/atoms/Payment/PaymentSelectors';

const init = initFor(CreditCard.name);
const cardSelector = cardSelectorFor(CreditCard.name);
const businessAddressCardSelector = cardSelectorFor(BusinessAddress.name);

const mapDispatchToProps = {
  init,
  updatePayPal,
  updateCard,
  updateDefault,
  removeMethod,
  getSetupIntentId,
  showCreditCardModal,
  handleStripeResponse
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  const businessAddressCardState = businessAddressCardSelector(state);
  const checkoutState = paymentSelector(state);

  return {
    countries:
      state.getIn(['settings', 'countries']) ||
      countriesSelector(state)
        .toJS()
        .map(country => ({ key: country.code, label: country.name })),
    data: cardState.get('data'),
    braintreeToken: cardState.get('braintreeToken'),
    unmodified: cardState.get('unmodified'),
    ready: !cardState.get('loading') && !cardState.get('busy'),
    addressData:
      Object.keys(checkoutState).length !== 0 // check if you are on checkout or settings page
        ? selectedAddressSelector(state).address // for checkout page, we take shipping address
        : businessAddressCardState.get('data') && businessAddressCardState.get('data').toJS(), // for settings page, we use company billing address
    loadingSetupIntentId: cardState.get('loadingSetupIntentId'),
    setupIntentId: cardState.get('setupIntentId'),
    clientSecret: cardState.get('clientSecret'),
    showingCreditCardModal: cardState.get('showingCreditCardModal'),
    stripeFormBusy: cardState.get('stripeFormBusy'),
    isOnCheckoutPage: Object.keys(checkoutState).length !== 0
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
