import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'immutable';
import { Link } from 'react-router';
import Button from 'gooten-components/src/components/shared/Button';

const LinkToCart = ({ className, disabled, proceedToCart, onClick, children, loading }) =>
  proceedToCart ? (
    <Link className="w-100" to="/custom-order-form/cart/start" onClick={onClick}>
      <Button loading={loading} className={'w-100 button-cta large go-to-cart'}>
        {children}
      </Button>
    </Link>
  ) : (
    <Button
      loading={loading}
      className={`w-100 ${disabled ? 'button-default large' : className}`}
      // disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );

LinkToCart.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool.isRequired,
  proceedToCart: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

/*
Add To Cart button
 It has 4 states
    - Nothing selected and nothing in the Cart - Disabled success Add To Cart - No Link
    - Some selected - Enabled success Add To Cart - No Link
    - Loading - Disabled success Loading... - No Link
    - Nothing selected and some in the Cart - Enabled primary Proceed To Cart - Link
 */
const AddToCartButton = styled(LinkToCart).attrs({
  // we can define static props
  type: 'div'

  // or we can define dynamic ones
  // margin: props => props.size || '1em',
  // padding: props => props.size || '1em'
})`
  width: 233px;
  height: 48px;
`;

const AddToCart = props => {
  const proceedToCart = !props.loading && !props.cartEmpty && props.selectedItems.isEmpty();
  const onAddToCartClick = () => {
    if (!proceedToCart && !props.selectedItems.isEmpty()) {
      props.addToCart(props.selectedItems);
    } else if (proceedToCart && props.onProceedToCart) {
      props.onProceedToCart();
    }
  };

  return (
    <AddToCartButton
      disabled={props.selectedItems.isEmpty()}
      loading={props.loading}
      className={
        proceedToCart ? 'proceed-to-cart button-default large' : 'add-to-cart button-primary large'
      }
      onClick={onAddToCartClick}
      proceedToCart={proceedToCart}
    >
      {props.failed && props.selectedItems.isEmpty()
        ? 'FAILED'
        : proceedToCart
        ? 'Proceed to cart'
        : 'Add to cart'}
    </AddToCartButton>
  );
};

AddToCart.propTypes = {
  selectedItems: PropTypes.instanceOf(List).isRequired,
  addToCart: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  cartEmpty: PropTypes.bool.isRequired,
  onProceedToCart: PropTypes.func
};

export default AddToCart;
