// @flow

import PositioningService from '../../utils/positioningService';

export class ImageService {

  /**
   * Return actual canvas states for all images
   * @param {Object} options
   */
  static getImagesCanvasStates(options) {
    let results = [];
    for(let image of options.images) {
      let layer = options.template.layers.find(l => l.id === image.layerId);
      let imgCanvasState = this.getSingleImageCanvasState(options, image, layer);
      results.push(imgCanvasState);
    }

    return results;
  }

  /**
   * Return actual canvas state of the given image
   * @param {Object} options
   */
  static getSingleImageCanvasState(options, image, layer) {
    let imgClone = {...image};

    let imageArgs = {
      id: image.id,
      layerId: layer.id,
      opacity: 1,
      visible: true,
      clip: {
        x: 0,
        y: 0,
        width: options.width,
        height: options.height
      },
      image: image.image,
      rotation: image.rotation || 0,
    };

    if (image.crop) {
      imageArgs.crop = { ...image.crop };
    }

    let viewportPosition = PositioningService.convertToCanvasSize(
      PositioningService.getViewportRect(layer),
      options
    );
    imageArgs.clip = viewportPosition;

    // set scaling from zoom api based on state.scale
    if(image.scale) {
      imgClone.width = image.realSourceWidth * image.scale;
      imgClone.height = image.realSourceHeight * image.scale;
    }

    let imagePosition = PositioningService.convertToCanvasSize(imgClone, options);

    Object.assign(imageArgs, imagePosition);
    imageArgs.scaleFromOriginal = image.realSourceWidth / imagePosition.width;
    imageArgs.scaleFromCurrent = image.sourceWidth / imagePosition.width;

    // Apply crop
    if (imageArgs.crop) {

      // Zoom cropped area
      this._applyZoomToCrop(imageArgs);

      // Show only cropped
      this._applyCrop(imageArgs);
    }

    imageArgs.x -= imageArgs.width/2;
    imageArgs.y -= imageArgs.height/2;

    return imageArgs;
  }

  //imageArgs - scaled to canvas image
  //returned by image.service
  static _applyZoomToCrop(imageArgs: any) {
    let crop = imageArgs.crop;


    crop.width = crop.width / imageArgs.scaleFromOriginal;
    crop.height = crop.height / imageArgs.scaleFromOriginal;
    crop.x = crop.x / imageArgs.scaleFromOriginal;
    crop.y = crop.y / imageArgs.scaleFromOriginal;
  }

  static _applyCrop(imageState: any) {
    imageState.sourceImageCrop = {
      x: imageState.crop.x * imageState.scaleFromCurrent,
      y: imageState.crop.y * imageState.scaleFromCurrent,
      width: imageState.crop.width * imageState.scaleFromCurrent,
      height: imageState.crop.height * imageState.scaleFromCurrent
    };
    this._setCroppedPositionToImage(imageState);
  }

  static _setCroppedPositionToImage(imageState: any) {
    let crop = imageState.crop;
    imageState.width = crop.width;
    imageState.height = crop.height;
  }

}

// singleton
export default ImageService;
