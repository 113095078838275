import StateManager from '../../../state/stateManager';
import {
  setCroppingMode,
  setCroppingSaveMode,
  editActivate
} from '../../../state/actions/actionCreators';
// import CropService from '../../actions/crop/crop.service';
import EventEmitter from 'events';
import { PUBLIC_EVENTS } from '../../../ImageEditor.events';

export class CropButton {
  customClass: string;
  name: string;
  publicEvents: EventEmitter;
  element: any;
  isActive: boolean;
  handler: () => void;

  constructor(stateManager: StateManager, config: any, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;

    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Crop';
    this.stateManager = stateManager;
    this.stateManager.subscribe(state => this.onStateChange(state));
  }

  onStateChange(state) {
    if (!this.element) {
      return;
    }

    const active = state.getIn(['images', 'current', 'images']).size > 0;
    if (this.isActive !== active) {
      this.isActive = active;
      this.updateButtonState();
    }
  }

  updateButtonState() {
    this.element.className = ` editor-btn ${this.customClass} ${!this.isActive ? 'disabled' : ''}`;
    if (this.handler) {
      this.element.removeEventListener('click', this.handler);
      this.handler = null;
    }

    if (this.isActive) {
      this.handler = () => this.action();
      this.element.addEventListener('click', this.handler);
    }
  }

  getElement() {
    this.element = document.createElement('div');

    this.element.innerHTML = `<div class="icon-crop toolbar-icon"></div>
      <span class="editor-tooltip-text">${this.name}</span>`;

    this.updateButtonState();
    return this.element;
  }

  action() {
    let state = this.stateManager.getState();

    if (state.editor.cropActivated) {
      this.stateManager.dispatchAction(setCroppingSaveMode(true));
      return;
      // this.publicEvents.emit('cropcancel');
      // CropService.resetCropState();
    }
    else {
      this.publicEvents.emit(PUBLIC_EVENTS.CROP_START);
    }

    this.stateManager.dispatchAction(editActivate());
    this.stateManager.dispatchAction(setCroppingMode(!state.editor.cropActivated));
  }
}
