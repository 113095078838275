import { delay } from 'redux-saga';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import {
  SET_SHIPPING_ADDRESS,
  updateShippingMethods,
  updateSurchargeCosts
} from '../../CheckoutActions';
import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  UPDATE_CART_ITEM_QUANTITY
} from '../../../../store/actions/dataActions';
import { showLoading, hideLoading } from '../../../../store/actions/globalActions';
import { getShippingPricesRequestSelector } from './ShippingMethodSelectors';
import shippingPricesService from './services/shippingPricesService';

export function* refreshShippingPrices(action) {
  yield put(showLoading());
  // NOTE: can occure too offten
  // wait 500ms so this can be canceled while waiting
  yield call(delay, 500);
  const shippingPricesRequest = yield select(getShippingPricesRequestSelector);
  if (shippingPricesRequest && !shippingPricesRequest.Items.isEmpty()) {
    try {
      const shippingPricesResult = yield call(
        shippingPricesService.getShippingPrices,
        shippingPricesRequest
      );

      // select 1st option in each group by default
      shippingPricesResult.Result.forEach(shipGroup => {
        if (shipGroup.ShipOptions.length !== 0) {
          shipGroup.SelectedMethodId = shipGroup.ShipOptions[0].Id;
        }
      });
      const surchargeCosts = [
        {
          id: 1,
          cost: shippingPricesResult.StandardSurcharge || 0,
          methodName: 'Standard'
        },
        {
          id: 2,
          cost: shippingPricesResult.ExpeditedSurcharge || 0,
          methodName: 'Expedited'
        },
        {
          id: 3,
          cost: shippingPricesResult.OvernightSurcharge || 0,
          methodName: 'OverNight'
        }
      ];

      yield put(updateSurchargeCosts({ surchargeCosts }));
      yield put(updateShippingMethods(shippingPricesResult.Result, []));
    } catch (error) {
      console.warn('shippingPricesResponse error:', error);
      // NOTE: In case of 500 error, error.Errors does not exist
      yield put(
        updateShippingMethods([], error.Errors || [{ Message: 'Shipping price estimate error' }])
      );
    }
  }
  yield put(hideLoading());
}

export function* watchShippingAddressChange() {
  yield takeLatest(
    [SET_SHIPPING_ADDRESS, ADD_TO_CART, REMOVE_CART_ITEM, CLEAR_CART, UPDATE_CART_ITEM_QUANTITY],
    refreshShippingPrices
  );
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchShippingAddressChange()]);
}
