import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';

export const CircleGradient = ({ startColor, endColor, id }) => {
  return (
    <svg style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id={id} gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

const CircleProgressBar = ({ value, maxValue, gradient }) => {
  return (
    <>
      {gradient ? (
        <CircleGradient
          startColor={gradient.startColor}
          endColor={gradient.endColor}
          id="circular"
        />
      ) : null}
      <CircularProgressbar
        className="CircularProgressbar"
        value={value}
        maxValue={maxValue}
        strokeWidth={13}
      />
      <style jsx>
        {`
          .CircularProgressbar {
            width: 90%;
            vertical-align: middle;
          }

          :global(.CircularProgressbar-path) {
            stroke: url(#circular);
            stroke-linecap: round;
            transition: stroke-dashoffset 0.5s ease 0s;
          }

          :global(.CircularProgressbar-trail) {
            stroke: #d6d6d6;
            stroke-linecap: round;
          }
        `}
      </style>
    </>
  );
};

CircleGradient.propTypes = {
  id: PropTypes.string.isRequired,
  startColor: PropTypes.string,
  endColor: PropTypes.string
};

CircleProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  gradient: PropTypes.shape({
    startColor: PropTypes.string,
    endColor: PropTypes.string
  })
};

export default CircleProgressBar;
