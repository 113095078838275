import React from 'react';
import PropTypes from 'prop-types';
import StoreConnect from '../shared/StoreConnect';
import analyticsService from 'gooten-components/src/services/analyticsService';
import Alert from 'gooten-components/src/components/shared/Alert';

import './TikTokConnect.scss';

const betaNotification =
  // eslint-disable-next-line no-multi-str
  'This is an early-release beta integration. If this is a critical piece of your business, we \
strongly recommend that you wait a few weeks until we’re finished with the official integration.';

class TikTokConnect extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    init: PropTypes.func.isRequired,
    connectStore: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { notification: { text: betaNotification, type: 'success' } };
    analyticsService._track(
      'Hub - Integrations',
      'Accessed',
      'TikTok',
      '/tiktok/connect-store',
      null
    );
  }

  componentDidMount() {
    const state = this.props.location.query;
    // All data to connect shop provided in query
    if (state.state && state.code && state.locale && state.shop_region) {
      // Initialte shop connect
      this.props.init(state);
    }
  }

  render() {
    return (
      <div className="tiktok-connect-container bla">
        <StoreConnect
          storeProvider="TikTok"
          description=""
          trademark
          inputLabel="Shop Region"
          inputPlaceholder="Region"
          predefinedStoreName="US"
          storeNameDisabled
          connectStore={region => this.props.connectStore(region)}
        >
          <div>
            <h3 className="store-connect-title center-block text-center">
              Before Connecting Your TikTok Shop
            </h3>
            <div className="resource-card-container card">
              <div className="card-content">
                <div className="title-1 w-100 card-title">Seller Shipping in your TikTok Shop</div>
                <div className="card-list-container">
                  <ol className="tree w-100">
                    <li>
                      <label>Add Gooten Warehouse as the pickup warehouse</label>
                    </li>
                    <li>
                      <label>Add a return warehouse</label>
                    </li>
                    <li>
                      <label>
                        Create a shipping template <br />
                      </label>
                      <div className="url-link-container pl-4">
                        <a
                          className="ml-3"
                          href="https://help.gooten.com/hc/en-us/articles/26094771179803-Integrating-with-TikTok"
                        >
                          <span>Click here for detailed instructions</span>
                        </a>
                      </div>
                    </li>
                  </ol>
                </div>
                <Alert className="ml-auto mr-auto mt-2 mb-2" isOpen type="warning">
                  <span>
                    Not setting up Seller Shipping accurately will prevent order from being
                    purchased by TikTok users and/or being fulfilled by Gooten.
                  </span>
                </Alert>
              </div>
            </div>
            <div className="resource-card-container card">
              <div className="card-content">
                <div className="title-1 w-100 card-title">View Eligible Products</div>
                <div className="card-list-container">
                  <ol className="tree w-100">
                    <li>
                      <label>
                        Eligible Products can be fulfilled within TikTok Shop Policies. <br />
                      </label>
                      <div className="url-link-container pl-4">
                        <a
                          className="ml-3"
                          href="https://docs.google.com/spreadsheets/d/1vRstnMV4e9oT37EE6_MgO44cZlLPGr2y5NfE4qBFU-I"
                        >
                          <span>Click here to view product list</span>
                        </a>
                      </div>
                    </li>
                  </ol>
                </div>
                <Alert className="ml-auto mr-auto mt-2 mb-2" isOpen type="warning">
                  <span>
                    Orders for ineligible products could result in suspension of your TikTok Shop
                  </span>
                </Alert>
              </div>
            </div>
          </div>
        </StoreConnect>
      </div>
    );
  }
}

export default TikTokConnect;
