import { connect } from 'react-redux';
import BillingView from './BillingView';
import { costBreakdown, orderBilling, billingLoading, billingErrors } from './BillingViewSelectors';
import { showCustomerCosts } from '../OrderDetailsSelectors';
import { fetchBillingDataAsync } from './BillingViewActions';

const mapDispatchToProps = {
  fetchBillingDataAsync: orderId => fetchBillingDataAsync(orderId)
};

const mapStateToProps = state => {
  return {
    billingLoading: billingLoading(state),
    costBreakdown: costBreakdown(state),
    orderBilling: orderBilling(state),
    showCustomerCosts: showCustomerCosts(state),
    billingErrors: billingErrors(state).toJS()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingView);
