import { connect } from 'react-redux';
import ProductPricing from './ProductPricing';
import { changeStorePrices } from './ProductPricingActions';
import {
  selectedVariantsSelector,
  storeIdSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import pricingService from './productPricingService';

const mapDispatchToProps = {
  onChange: (storeId, item) => changeStorePrices({ storeId, item })
};

const mapStateToProps = state => {
  const variants = selectedVariantsSelector(state);

  return {
    variants,
    priceData: pricingService.mapVariantPrices(variants.toJS()),
    storeId: storeIdSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPricing);
