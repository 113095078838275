import { connect } from 'react-redux';
import StoreBulkMigrationView from './StoreBulkMigrationView';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';

const mapDispatchToProps = {};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(StoreBulkMigrationView)
);
