import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { roundStringPrice } from '../../../../../utils/price';
import ProductPricingFullscreen from './ProductPricingFullscreen/';

import Button from '../../../../shared/Button';
import Input from '../../../../shared/Input';
import Modal from '../../../../shared/Modal';

const ProductPricing = props => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [margin, setMargin] = useState(props.priceData && props.priceData.get('margin'));
  const [customerPrice, setCustomerPrice] = useState(
    props.priceData && props.priceData.get('customerPrice')
  );
  const [isMarginLocked, lockMargin] = useState(true);
  const [isCustomerPriceLocked, lockCustomerPrice] = useState(true);

  useEffect(() => {
    setMargin(props.priceData && props.priceData.get('margin'));
    setCustomerPrice(props.priceData && props.priceData.get('customerPrice'));
  }, [props.variants.toString()]);

  const onInput = (type, value) => {
    switch (type) {
      case 'margin':
        setMargin(value);
        lockMargin(false);
        break;
      case 'customerPrice':
        setCustomerPrice(value);
        lockCustomerPrice(false);
        break;
    }
  };

  const onMarginChange = value => {
    if (!isMarginLocked) {
      let val = roundStringPrice(value) || 0;
      val = val < 0 ? 0 : val;
      props.priceData.get('variants').forEach(item => {
        const newItem = item.set('customerPrice', item.get('maxCost') + val);
        props.onChange(props.storeId, newItem.toJS());
      });
      lockMargin(true);
    }
  };

  const onPriceChange = value => {
    if (!isCustomerPriceLocked) {
      const val = roundStringPrice(value);
      props.priceData.get('variants').forEach(item => {
        const newItem = item.set(
          'customerPrice',
          val && val > item.get('maxCost') ? val : item.get('maxCost')
        );
        props.onChange(props.storeId, newItem.toJS());
      });
      lockCustomerPrice(true);
    }
  };

  return (
    <div className="product-publish-pricing pt-4 pb-4 d-flex">
      <span className="pricing-title title-2 mb-4">Pricing</span>
      <div className="price-edit-holder flex-container">
        <div className="holder flex-container">
          <Input
            label={'Our Price'}
            className="text-center"
            disabled
            value={props.priceData && props.priceData.get('price')}
          />
        </div>
        <span className="sign title-2 pl-2 pr-2 pt-2">+</span>
        <div className="holder flex-container">
          <Input
            className="text-center"
            label={'Your Profit'}
            disabled={false}
            onChange={e => onInput('margin', e.target.value)}
            onBlur={e => onMarginChange(e.target.value)}
            value={margin}
          />
        </div>
        <span className="sign title-2 pl-2 pr-2 pt-2">=</span>
        <div className="holder flex-container">
          <Input
            className="text-center"
            label={'Customer Price'}
            disabled={false}
            onChange={e => onInput('customerPrice', e.target.value)}
            onBlur={e => onPriceChange(e.target.value)}
            value={customerPrice}
          />
        </div>
      </div>
      {props.variants && props.variants.size > 1 ? (
        <Button className="button-default medium" onClick={() => setModalOpen(true)}>
          Edit SKU prices
        </Button>
      ) : null}
      <Modal isOpen={isModalOpen} cancelClick={() => setModalOpen(false)} title="Pricing">
        {isModalOpen ? (
          <ProductPricingFullscreen
            onClose={() => setModalOpen(false)}
            storeId={props.storeId}
            priceData={props.priceData}
          />
        ) : null}
      </Modal>
      <style jsx>
        {`
          .product-publish-pricing {
            flex-direction: column;
          }
        `}
      </style>
    </div>
  );
};

ProductPricing.propTypes = {
  variants: PropTypes.instanceOf(List).isRequired,
  priceData: PropTypes.instanceOf(Map).isRequired,
  storeId: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default ProductPricing;
