import { connect } from 'react-redux';
import ProductSelectionView from './ProductSelectionView';
import {
  isReadySelector,
  selectedProductSelector,
  productsIndexingSelector
} from './ProductSelectionViewSelectors';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';
import { INIT } from './ProductSelectionViewActions';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/

const mapDispatchToProps = {
  init: (providerPath, storeId) => fetchAsync(INIT, { providerPath, storeId })
};

const mapStateToProps = state => {
  const ready = isReadySelector(state);
  const selectedProduct = selectedProductSelector(state);
  const productsIndexing = productsIndexingSelector(state);
  return {
    ready,
    productsIndexing,
    selectedProduct
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelectionView);
