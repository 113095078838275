import { sanitizeCDNUrl } from 'gooten-js-utils/src/url';

export const prepareIlForManipCommand = il => {
  return {
    ...il,
    layers: il.layers.map(l => {
      let lm = { ...l };
      // NOTE: IL can include design layers with includeInPrint: true
      // these layers don't have images array
      if (l.images) {
        lm.images = l.images.map(i => ({
          ...i,
          url: sanitizeCDNUrl(i.url)
        }));
      }

      return lm;
    })
  };
};
