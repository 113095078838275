import { connect } from 'react-redux';
import MyArtwork from './MyArtwork';
import { modalClose, IMAGES_FETCH, IMAGE_REMOVE } from '../../ImageSelectionModalActions';
import { fetchAsync } from '../../../../../store/actions/dataActions';
import {
  imagesSelector,
  loadingImagesCountSelector,
  filterSelector,
  isMultiSpaceSelector,
  selectedSKUSpacePairsSelector,
  spacesSelector,
  anySpaceReadySelector,
  loadedSelector,
  isBulkModeSelector
} from './MyArtworkSelectors';
import { showLoadingSelector } from '../../ImageSelectionModalSelectors';
import { setSort, setFilter, selectSpace, selectImage } from './MyArtworkActions';
import { requiredImageSizeSelector } from '../../../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';

const mapDispatchToProps = {
  modalClose: () => modalClose(),
  loadImages: page => fetchAsync(IMAGES_FETCH, { page }),
  setSort: sort => setSort(sort),
  setFilter: filter => setFilter(filter),
  selectSpace: space => selectSpace(space),
  selectImage: image => selectImage(image),
  removeImage: image => fetchAsync(IMAGE_REMOVE, { image })
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    images: imagesSelector(state),
    loadingImagesCount: loadingImagesCountSelector(state),
    showLoading: showLoadingSelector(state),
    filter: filterSelector(state),
    isMultiSpace: isMultiSpaceSelector(state),
    selectedSKUSpacePairs: selectedSKUSpacePairsSelector(state),
    spaces: spacesSelector(state),
    anySpaceReady: anySpaceReadySelector(state),
    loaded: loadedSelector(state),
    isBulkMode: isBulkModeSelector(state),
    requiredImageSizeData: requiredImageSizeSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyArtwork);
