import { createSelector } from 'reselect';
import PagedQuery from '../../../../models/PagedQuery';
import { cofShippingCountrySelector } from 'gooten-components/src/store/selectors/countriesSelectors';

const cofSelector = state => state.get('customOrderForm');

const hubProductsViewSelector = createSelector([cofSelector], cof => cof.get('hubProductsView'));

const hubProductsSelector = createSelector([hubProductsViewSelector], hubProductsView =>
  hubProductsView.get('hubProducts')
);

export const searchTextSelector = createSelector([hubProductsViewSelector], hubProductsView =>
  hubProductsView.get('search')
);

export const isHubProductsLoadedSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('loaded')
);

export const currentPageSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('page')
);

export const totalPagesSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('totalPages')
);

const pageSizeSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('pageSize')
);

export const totalSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('total')
);

export const sortSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('sortValue')
);

export const hubProductsQuerySelector = createSelector(
  [
    searchTextSelector,
    currentPageSelector,
    pageSizeSelector,
    cofShippingCountrySelector,
    sortSelector
  ],
  (searchText, currentPage, pageSize, shippingCountry, sortValue) =>
    new PagedQuery({
      searchText: searchText,
      page: currentPage,
      sortValue: sortValue,
      pageSize: pageSize,
      countryCode: shippingCountry
    })
);

export const hubProductsItemsSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('items').map(i => {
    return i;
  })
);

export const selectedItemsSelector = createSelector([hubProductsSelector], hubProducts =>
  hubProducts.get('selected')
);

const dataSelector = state => state.get('data');

const productsSelector = createSelector([dataSelector], data => data.get('products'));

export const outOfStockItemsSelector = createSelector([productsSelector], products =>
  products.filter(product => product.out_of_stock)
);
