import React from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import { parseCurrency } from './helpers';

const CustomerCostsCard = ({ orderBilling }) => {
  return (
    <div className="card-container">
      <Card title="Customer Costs">
        <div className="d-flex justify-content-between">
          <p>Sub Total</p>
          <div className="customer-costs-subtotal d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('CustomerSubTotal'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Shipping</p>
          <div className="customer-costs-shipping d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('CustomerShipping'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Tax</p>
          <div className="customer-costs-tax d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('CustomerTax'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Discount</p>
          <div className="customer-costs-discount d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('CustomerDiscount'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Total</p>
          <div className="customer-costs-total d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('CustomerTotal'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Payment Method</p>
          <div className="customer-costs-payment-method d-flex flex-wrap justify-content-end">
            {orderBilling.get('CustomerPaymentMethod') || '-'}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Revenue Collected by</p>
          <div className="customer-costs-revenue-collected d-flex flex-wrap justify-content-end">
            {orderBilling.get('BilledTo') || '-'}
          </div>
        </div>
      </Card>
    </div>
  );
};

CustomerCostsCard.propTypes = {
  orderBilling: PropTypes.object
};

export default CustomerCostsCard;
