import { fromJS, List } from 'immutable';
import { initialState } from './MyArtworkInitialState';
import {
  IMAGES_FETCH,
  IMAGE_FILE_UPLOAD,
  IMAGE_URL_UPLOAD,
  IMAGE_REMOVE
} from '../../ImageSelectionModalActions';
import { SET_FILTER, SET_SORT, SELECT_SPACE, SET_SPACES, SELECT_IMAGE } from './MyArtworkActions';
import { EDITOR_BACK } from '../../../../ImageUpload/ImageUploadActions';
import imageService from '../../services/imageSelectionService';

const MyArtworkReducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case IMAGES_FETCH.SUCCESS: {
      let newState = state;

      // if filter doesn't exist, create empty one...
      if (action.payload.type && !state.getIn(['images', action.payload.type])) {
        newState = state.setIn(
          ['images', action.payload.type],
          fromJS({ loaded: false, images: [] })
        );
      }

      return newState
        .updateIn(['images', action.payload.type, 'images'], images =>
          images.concat(fromJS(action.payload.images))
        )
        .setIn(['images', action.payload.type, 'loaded'], true);
    }

    case IMAGE_FILE_UPLOAD.ASYNC:
    case IMAGE_URL_UPLOAD.ASYNC: {
      return state.set(
        'loadingImagesCount',
        state.get('loadingImagesCount') +
          (
            imageService.filterImageFiles(action.payload.files) ||
            imageService.filterImageUrls(action.payload.urls)
          ).length
      );
    }

    case IMAGE_FILE_UPLOAD.FAIL:
    case IMAGE_URL_UPLOAD.FAIL: {
      return state.set(
        'loadingImagesCount',
        state.get('loadingImagesCount') - action.payload.failedUploads.length
      );
    }

    case IMAGE_FILE_UPLOAD.SUCCESS:
    case IMAGE_URL_UPLOAD.SUCCESS: {
      action.payload.createdImages.forEach(img => {
        // if some of regular filters (landscape, portrait or square) is loaded, update it with newly uploaded images
        const regularFilter = imageService.getImageTypeString(img.type);
        if (state.getIn(['images', regularFilter, 'loaded'])) {
          state = state.updateIn(['images', regularFilter, 'images'], images =>
            images.unshift(fromJS(img))
          );
        }

        // if "all" filter images are loaded, update it also... (this should be loaded by default, even with [])
        if (state.getIn(['images', 'all', 'loaded'])) {
          state = state.updateIn(['images', 'all', 'images'], images =>
            images.unshift(fromJS(img))
          );
        }

        // if custom filter exist, update it also...
        // first create custom filter with image width and height
        const customFilter = `${img.width}x${img.height}`;
        if (state.getIn(['images', customFilter, 'loaded'])) {
          state = state.updateIn(['images', customFilter, 'images'], images =>
            images.unshift(fromJS(img))
          );
        }
      });
      return state.set(
        'loadingImagesCount',
        state.get('loadingImagesCount') - action.payload.createdImages.length
      );
    }

    case IMAGE_REMOVE.SUCCESS:
      const regularFilter = imageService.getImageTypeString(action.payload.image.type);
      const customFilter = `${action.payload.image.width}x${action.payload.image.height}`;

      // remove image from regular filters
      if (state.getIn(['images', regularFilter, 'loaded'])) {
        state = state.updateIn(['images', regularFilter, 'images'], images =>
          images.filterNot(img => img.get('id') === action.payload.image.id)
        );
      }

      // remove from "all" filter
      if (state.getIn(['images', 'all', 'loaded'])) {
        state = state.updateIn(['images', 'all', 'images'], images =>
          images.filterNot(img => img.get('id') === action.payload.image.id)
        );
      }

      // remove from custom filter if exist
      if (state.getIn(['images', customFilter, 'loaded'])) {
        state = state.updateIn(['images', customFilter, 'images'], images =>
          images.filterNot(img => img.get('id') === action.payload.image.id)
        );
      }
      return state;

    case SET_FILTER:
      return state.set('filter', action.payload);

    case SET_SORT:
      return state.set('sort', action.payload);

    case SET_SPACES:
      return state.set('spaces', action.payload);

    case SELECT_SPACE:
      return state.update('spaces', spaces =>
        spaces.map(s => s.set('selected', s.get('id') === action.payload.get('id')))
      );

    // case MODAL_CLOSE:
    //   return state.update('spaces', spaces => spaces.map(s => {
    //     // eslint-disable-next-line
    //     const { image, ...woImage } = s.toJS()
    //     return fromJS(woImage)
    //   }))

    case SELECT_IMAGE:
      return state.update('spaces', spaces =>
        spaces.map(s => (s.get('selected') ? s.set('image', fromJS(action.payload)) : s))
      );

    case EDITOR_BACK:
      return state.set('spaces', new List());

    default:
      return state;
  }
};

export default MyArtworkReducer;
