import React from 'react';
import PropTypes from 'prop-types';
import { toShortDate } from 'gooten-components/src/utils/strings';
import Icon from 'gooten-components/src/components/shared/Icon';
import Modal from 'gooten-components/src/components/shared/Modal';

class RecieptModal extends React.PureComponent {
  render() {
    return (
      <div className="reciept-modal-container">
        <Modal
          isOpen={this.props.isOpen}
          cancelClick={this.props.modalClose}
          title="Receipt"
          cancelText="Okay"
        >
          <div className="p-4">
            <Icon icon="gootenLogoType" className="ml-n2" />
            <p>Date: {toShortDate(this.props.record.ChargeTime)}</p>
            <p>Transaction: {this.props.record.TransactionId}</p>
            <p>Account: {this.props.partnerDetails.CompanyName}</p>
            <br />
            <br />
            <table>
              <tbody>
                <tr className="table-caption">
                  <th>Description</th>
                  <th>Total USD</th>
                </tr>
                <tr>
                  <td className="table-row-devider" />
                  <td className="table-row-devider" />
                </tr>
                <tr>
                  <td>Costs for order {this.props.record.OrderId}</td>
                  <td>${this.props.record.Amount}</td>
                </tr>
              </tbody>
            </table>
            <br />

            <p>Billed to:</p>
            <p>{this.props.partnerDetails.CompanyName}</p>
            <p>{this.props.partnerDetails.Line1}</p>
            <p>{this.props.partnerDetails.Line2}</p>
            <p>
              {this.props.partnerDetails.City}, {this.props.partnerDetails.State}
              {this.props.partnerDetails.PostalCode}
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

RecieptModal.propTypes = {
  isOpen: PropTypes.bool,
  modalClose: PropTypes.func.isRequired,
  record: PropTypes.object,
  partnerDetails: PropTypes.object
};

export default RecieptModal;
