import { getActionName } from '../../ImageUploadActions';

// ------------------------------------
// Constants
// ------------------------------------

export const IMAGE_EDITOR_CHANGE = getActionName('IMAGE_EDITOR_CHANGE');
export const IMAGE_EDITOR_DX_CHANGE = getActionName('IMAGE_EDITOR_DX_CHANGE');
export const IMAGE_EDITOR_UNDO = getActionName('IMAGE_EDITOR_UNDO');
export const IMAGE_EDITOR_REDO = getActionName('IMAGE_EDITOR_REDO');
export const IMAGE_EDITOR_BULK_MODE_TOGGLE = getActionName('IMAGE_EDITOR_BULK_MODE_TOGGLE');

// ------------------------------------
// Actions
// ------------------------------------

export function imageEditorChange(state = null) {
  return {
    type: IMAGE_EDITOR_CHANGE,
    payload: state
  };
}

export function imageEditorDxChange(state = null) {
  return {
    type: IMAGE_EDITOR_DX_CHANGE,
    payload: state
  };
}

export function imageEditorUndo() {
  return {
    type: IMAGE_EDITOR_UNDO
  };
}

export function imageEditorRedo() {
  return {
    type: IMAGE_EDITOR_REDO
  };
}

export function imageEditorBulkModeToggle(bulk) {
  return {
    type: IMAGE_EDITOR_BULK_MODE_TOGGLE,
    payload: bulk
  };
}
