import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoginForm } from './LoginForm';
import { ResetPassword, ResetPasswordSuccess } from './ResetPasswordForm';

export const CustomizedLogin = props => {
  const [resetPassword, showResetPassword] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [wrongToken, updateWrongToken] = useState(props.wrongToken);

  useEffect(() => {
    // our index page uses both versions of favicon implementations, update both
    if (props.partnerData.favIcon) {
      let iconLink = document.querySelector('link[rel="icon"]');
      if (iconLink) iconLink.href = `${props.partnerData.favIcon}`;
      let shortcutIconLink = document.querySelector('link[rel="shortcut icon"]');
      if (shortcutIconLink) shortcutIconLink.href = `${props.partnerData.favIcon}`;
    }

    document.title = `Log In | ${props.partnerData.formattedName}`;
  }, []);

  return (
    <div className="customized-login p-0 w-100">
      <div className="row m-0 p-0 h-100">
        <div className="col-md-6 full-height-container d-flex align-items-center">
          <div className="form-container w-100 mr-auto ml-auto">
            <div className="d-flex align-items-center mb-5">
              {props.partnerData.logoImage ? (
                <img
                  className="login-logo"
                  src={props.partnerData.logoImage}
                  alt={`${props.partnerData.partnerName} logo`}
                />
              ) : (
                <div className="text-nologo">{props.partnerData.formattedName}</div>
              )}
              <span className={`p-3 ${props.partnerData.xStyle || ''}`}>&#10006;</span>
              <img
                className="gooten-logo"
                src={props.partnerData.gootenLogoImage}
                alt="gooten logo"
              />
            </div>
            {resetPassword ? (
              <ResetPassword />
            ) : (
              <LoginForm partnerData={props.partnerData} showResetPassword={showResetPassword} />
            )}
          </div>
        </div>

        <div
          className={`col-md-6 full-height-container bullet-container d-flex justify-content-center align-items-center ${
            props.partnerData.backgroundImage ? 'custom-bg' : 'default-bg'
          }`}
        >
          <ul>
            {props.partnerData.bullets &&
              props.partnerData.bullets.map((bullet, key) => (
                <li
                  key={key}
                  className="bullet mb-5 position-relative"
                  dangerouslySetInnerHTML={{ __html: bullet }}
                />
              ))}
          </ul>
        </div>
      </div>
      <style jsx>
        {`
          .gooten-logo {
            width: 48px;
          }
          .login-logo {
            max-width: 150px;
          }
          .white-x {
            color: #fff;
          }
          .text-nologo {
            font-size: 2rem;
            font-weight: 600;
          }
          .customized-login {
            font-family: Avenir-Heavy;
          }
          .full-height-container {
            min-height: 100vh !important;
            background-color: ${props.partnerData.bgColor || '#fff'};
          }
          .form-container {
            max-width: 25rem;
            margin-bottom: 15rem;
            padding: 3rem 0;
          }
          :global(label) {
            color: ${props.partnerData.inputPlaceholderColor || '#2c2229'} !important;
          }
          .bullet-container {
            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-blend-mode: normal;
          }
          .custom-bg {
            background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, ${props.partnerData.backgroundImageDarken || '0'}),
                rgba(0, 0, 0, ${props.partnerData.backgroundImageDarken || '0'})
              ),
              url('${props.partnerData.backgroundImage}');
          }
          .default-bg {
            background-image: linear-gradient(
                rgb(211, 226, 248) 0px,
                rgb(211, 226, 248) 40%,
                rgb(10, 37, 153) 40%,
                rgb(10, 37, 153) 100%
              ),
              url('${'/admin-assets/img/login-illustration.png'}');
            background-blend-mode: multiply;
          }
          .bullet-container > ul {
            width: 100%;
            max-width: 25rem;
            display: block;
            list-style-type: none;
            margin-block-start: 3rem;
            height: auto;
            padding-inline-start: 2rem;
          }
          .bullet {
            display: list-item;
            font-size: 16px;
            color: ${props.partnerData.bulletTextColor || '#fff'} !important;
            line-height: 24px;
          }
          :global(.bullet-container a) {
            color: ${props.partnerData.bulletLinkColor || '#faebd7'} !important;
            text-decoration: underline !important;
          }
          .bullet::before {
            content: '•';
            position: absolute;
            left: -2rem;
            font-size: 2rem;
          }

          @media screen and (max-width: 992px) {
            ul {
              padding-top: 1rem;
              min-height: 50vh;
            }
            .full-height-container {
              min-height: 100% !important;
            }
            .form-container {
              padding: 3rem 0;
              margin-bottom: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

CustomizedLogin.displayName = 'CustomizedLogin';

CustomizedLogin.propTypes = {
  wrongToken: PropTypes.bool,
  partnerData: PropTypes.object
};
