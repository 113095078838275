import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import { emailRegex } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div``;

class AdvancedSettings extends React.Component {
  static get name() {
    return 'advancedSettings';
  }

  constructor(props) {
    super(props);
    this.inputs = {};
  }

  componentDidUpdate() {
    if (this.props.isPristine) {
      Object.values(this.inputs).forEach(i => i.hideErrors());
    }
  }

  get canDeactivate() {
    return (
      this.props.canDeactivate &&
      this.props.data.Email &&
      this.props.data.Password &&
      Object.values(this.inputs).every(i => !i || i.isValid())
    );
  }

  get canDownloadData() {
    return this.props.canDownloadData;
  }

  deactivate() {
    this.props.deactivate({ data: this.props.data });
  }

  downloadData() {
    this.props.downloadData({ data: this.props.data });
  }

  renderInput(name, label, placeholder, className, regexp) {
    return (
      <TextInput
        className={className}
        id={name}
        showValidation
        isRequired
        emptyMsg="This field is required"
        regexp={regexp}
        validationMsg="Wrong format"
        label={label}
        placeholder={placeholder}
        inputType={name === 'Password' ? 'password' : 'text'}
        onChange={(value, id) => this.props.change(value, id)}
        ref={ref => {
          this.inputs[name] = ref;
        }}
        value={this.props.data.get(name) || ''}
      />
    );
  }

  render() {
    return (
      <BaseCard card={AdvancedSettings.name} title="Advanced Settings" canToggle>
        <Container>
          <div className="advanced-settings-card">
            <h5>
              <b>Deactivate Account</b>
            </h5>
            <div className="row">
              <div className="col-xs-12">
                <p>
                  You won’t be able to log in to your account. You can reactivate your account
                  within 30 days of deactivation by emailing us at partnersupport@gooten.com
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 col-xs-12">
                <div className="row">
                  <div className="col-sm-6">
                    {this.renderInput(
                      'Email',
                      'Email Address',
                      'johannes@gooten.com…',
                      '',
                      emailRegex
                    )}
                  </div>
                  <div className="col-sm-6">
                    {this.renderInput('Password', 'Password', 'Password...', '', null)}
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="button-primary large"
              disabled={!this.canDeactivate}
              onClick={() => this.deactivate()}
            >
              Submit request
            </Button>
            <br />
            <br />
            <h5>
              <b>Request Data Download</b>
            </h5>
            <div className="row">
              <div className="col-xs-12">
                <p>
                  As part of our ongoing commitment to your privacy, you may request to download
                  your data from Gooten. Once you have requested your data, you will receive an
                  email within 30 days (at
                  {this.props.profileInformation.get('EmailAddress')}) with a link to the archive.
                </p>
              </div>
            </div>
            <Button
              className="button-primary large"
              disabled={!this.canDownloadData}
              onClick={() => this.downloadData()}
            >
              Submit request {this.props.canDownloadData}
            </Button>
          </div>
        </Container>
      </BaseCard>
    );
  }
}

AdvancedSettings.propTypes = {
  data: PropTypes.object,
  profileInformation: PropTypes.object,
  isPristine: PropTypes.bool,
  change: PropTypes.func.isRequired,
  canDeactivate: PropTypes.bool,
  canDownloadData: PropTypes.bool,
  deactivate: PropTypes.func.isRequired,
  downloadData: PropTypes.func.isRequired
};

export default AdvancedSettings;
