import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { postJson } from 'gooten-components/src/utils/http';
import { IsCapsLockActive } from '../atoms/IsCapsLockActive';
import sha1 from 'sha1';

import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Alert from 'gooten-components/src/components/shared/Alert';
import analyticsService from 'gooten-components/src/services/analyticsService';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../config';

export const LoginForm = () => {
  useEffect(() => {
    // We need a hard window focus because autofill text is unstyled until window is focused
    setTimeout(() => {
      window.focus();
    }, 500);
  }, []);

  const recaptchaRef = React.useRef();
  const [captchaToken, setCaptchaToken] = useState('');

  const alertType = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const alert = urlParams.get('alert');
    return alert;
  };

  const onChangeCaptcha = value => {
    setCaptchaToken(value);
  };

  const bypass = email => {
    const emails = [
      'automation@tests.com',
      'hub_partner_at@gooten.com',
      'partner-account2@gooten.com',
      'enabled-user@disabled-partner.com',
      'partner-account4@gooten.com',
      'micah+vendor-test@breakoutcommerce.com',
      'manualww@test.com'
    ];

    // the above accounts (plus those containing 'test+QA') are used by QA scripts and should be allowed to bypass the recaptcha check
    if (emails.includes(email) || email.includes('test+QA')) {
      // pass back the recaptcha token that the api recognizes as a token to bypass
      return 'bypass-b4d49408-c49f-463c-a417-bdca7594e27a';
    }

    return false;
  };

  return (
    <Formik
      validateOnBlur
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={() =>
        object().shape({
          email: string().required('Please enter a valid email.'),
          password: string().required('Please enter a valid password.')
        })
      }
      onSubmit={(data, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        const submit = async () => {
          const token =
            bypass(data.email) || captchaToken || (await recaptchaRef.current.executeAsync());
          const credentials = {
            UserName: data.email,
            Password: data.password,
            RememberMe: false,
            captcha: token
          };
          postJson(`/admin/account/LogOnAjax`, credentials)
            .then(
              data =>
                new Promise((resolve, reject) => {
                  data.isAuthenticated ? resolve(data) : reject(data);
                })
            )
            .then(data => {
              const sha1Email = sha1(credentials.UserName);
              analyticsService.impactIdentify(data.User.Id, sha1Email);
              window.localStorage.userId = data.User.Id;
              window.localStorage.emailSha1 = sha1Email;
              window.localStorage.token = data.User.ApiKey;
              window.localStorage.isFirstLogin = data.isFirstLogin;
              window.location.reload();
            })
            .catch(reason => {
              setSubmitting(false);
              setFieldError('email', 'Incorrect email or password.');
              setFieldError('password', 'Incorrect email or password.');
              setCaptchaToken('');
              recaptchaRef.current.reset();
            });
        };
        submit();
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched }) => (
        <div
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          {alertType() === 'resetsuccess' ? (
            <Alert isOpen type="good">
              Password changed! Please log in to your account.
            </Alert>
          ) : null}
          <div className="title" style={{ marginBottom: '30px' }}>
            Log in to your account
          </div>

          {/* Email Field */}
          <Input
            name="email"
            type="email"
            className="login-email-field"
            placeholder="Your Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            disabled={isSubmitting}
            hasError={touched.email && !!errors.email}
            errorText={touched.email && !!errors.email ? errors.email : ''}
          />

          {/* Password Field */}
          <div style={{ position: 'relative' }}>
            <Input
              name="password"
              type="password"
              className="login-password-field"
              placeholder="Your Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={isSubmitting}
              hasError={touched.password && !!errors.password}
              errorText={touched.password && !!errors.password ? errors.password : null}
            />
            <IsCapsLockActive>
              {active => (active ? <span className="caps-on-icon" /> : null)}
            </IsCapsLockActive>
          </div>
          <div className="btn-group">
            <span />
            <Button
              loading={isSubmitting}
              className="button-primary large sm-media-full login-submit"
              type="submit"
              onClick={e => (isSubmitting ? null : handleSubmit())}
            >
              Log in
            </Button>
          </div>
          <ReCAPTCHA
            // Note: Staging site key is to allow automated tests to work in staging env.
            // Swap keys to test locally.
            sitekey={
              Config.get('env') === 'staging'
                ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // staging key
                : '6LddGPUZAAAAABzQtPIEygB83xnjdSv0c9YgWr63'
            } // production key
            onChange={onChangeCaptcha}
            onExpired={() => setCaptchaToken('')}
            onErrored={() => setCaptchaToken('')}
            ref={recaptchaRef}
            size="invisible"
          />
          <style jsx>
            {`
            .caps-on-icon {
              position: absolute;
              top: 9px;
              right: 15px;
              opacity: 1;
              width: 30px;
              height: 30px;
              background-image: url("https://adminassets.azureedge.net/admin-assets/img/caps-lock.svg");
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 4px;
              visibility: visible;
              z-index: 999;
            }
            :global(.alert-content) {
              display: flex;
            }
            :global(.alert-body) {
              margin-left: 5px;
            }
            :global(.alert-body) {
              font-family: Avenir-Roman, Avenir, sans-serif;
            }
            .title {
              font-family: Avenir-Heavy;
              font-weight: 800;
              font-size: 1.5rem;
              line-height: 160%;
              margin-bottom: 1rem;
            }
            .btn-group {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              margin-top: -3px;
            }
            @media (max-width: 768px) {
              .btn-group {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: -3px;
              }
              @media (max-width: 768px) {
                .btn-group {
                  margin-top: 0;
                }
              }
            `}
          </style>
        </div>
      )}
    </Formik>
  );
};
