import React, { useState, useEffect } from 'react';
import { fromJS, List } from 'immutable';
import PropTypes from 'prop-types';
import NeckTagUploadModal from '../../../shared/NeckTagUploadModal';
import Button from '../../../shared/Button';
import OptionsModal from '../../../shared/OptionsModal';
import { NECK_LABEL_PRICE } from '../../../../const/NeckLabel';
import { COLORS } from '../../../../constants';

const NeckLabelsCOF = ({
  error,
  fetchNeckTags,
  neckLabelList,
  setSelectedNeckTag,
  createNeckTag,
  skusWithNeckTags,
  selectedNeckLabel,
  neckLabelsPreviewsLoading
}) => {
  const [isNewNeckTagModalOpen, setIsNewNeckTagModalOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');

  // if all skus selected don't contain a neck tag space, hide this component.
  const showComponent = !!skusWithNeckTags.size;
  const [showModal, setShowModal] = useState(false);
  const [necklabelOptionList, setNeckLabelOptionList] = useState([]);

  const handleNeckLabelSelection = option => {
    if (option?.id == 'none') {
      setSelectedNeckTag(null);
    } else if (option?.id == 'new') {
      setIsNewNeckTagModalOpen(true);
    } else {
      setSelectedNeckTag(fromJS(option));
    }
  };

  useEffect(() => {
    showComponent && fetchNeckTags();
  }, []);

  useEffect(() => {
    let nlOptions = [
      {
        text: 'None',
        id: 'none',
        icon: ''
      }
    ];
    if (neckLabelList && neckLabelList.size > 0) {
      const nls = neckLabelList.toJS()?.map(nl => {
        return {
          text: nl.name,
          icon: 'tag',
          ...nl
        };
      });
      nlOptions = nlOptions.concat(nls);
    }
    nlOptions.push({
      text: 'Create New Neck Label',
      id: 'new',
      icon: 'plusCircle'
    });
    setNeckLabelOptionList(nlOptions);
  }, [neckLabelList]);

  //for use case when the neck tag is already applied but user went previous step and added new variants
  useEffect(() => {
    if (skusWithNeckTags?.size) {
      const skusWithNeckTagSelected = skusWithNeckTags.filter(s => s.neckTagId);
      if (
        skusWithNeckTagSelected?.size > 0 &&
        skusWithNeckTagSelected?.size < skusWithNeckTags?.size &&
        selectedNeckLabel
      ) {
        setSelectedNeckTag(selectedNeckLabel);
      }
    }
  }, [selectedNeckLabel?.get('id'), skusWithNeckTags?.size]);

  const createNewNeckLabel = image => {
    createNeckTag(image);
    setIsNewNeckTagModalOpen(false);
    setNewTagName('');
  };

  if (showComponent) {
    return (
      <div className="neck-label-selection">
        <div className="d-flex align-items-center">
          <Button
            className={`button-secondary large ${showModal & 'outline-secondary'}`}
            iconRight="chevronDown"
            onClick={() => setShowModal(true)}
            loading={neckLabelsPreviewsLoading}
          >
            <span className="nl-elipsed">
              {selectedNeckLabel
                ? `${selectedNeckLabel.get('name')} Selected`
                : 'Select Neck Label'}
            </span>
          </Button>
          {selectedNeckLabel ? (
            <p className="price-necktag">+${NECK_LABEL_PRICE.toFixed(2)}</p>
          ) : null}
        </div>

        {showModal ? (
          <OptionsModal
            items={necklabelOptionList}
            onClickItem={handleNeckLabelSelection}
            onDismiss={() => setShowModal(false)}
          />
        ) : null}
        {isNewNeckTagModalOpen ? (
          <NeckTagUploadModal
            neckLabelsList={neckLabelList}
            onSave={image => createNewNeckLabel(image)}
            onCancel={() => setIsNewNeckTagModalOpen(false)}
            onChange={(value, id) => setNewTagName(value)}
            value={newTagName}
          />
        ) : null}
        <style jsx>
          {`
            .neck-label-selection {
              margin-bottom: 8px;
            }
            .nl-elipsed {
              overflow: hidden;
              max-width: 329px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .price-necktag {
              color: ${COLORS.monstera500};
              padding-left: 12px;
              padding-top: 8px;
              font-family: 'Avenir-Heavy';
              font-style: normal;
              font-weight: 800;
              font-size: 0.8125rem;
              line-height: 24px;
              letter-spacing: 0.15px;
            }
          `}
        </style>
      </div>
    );
  }
  return null;
};

NeckLabelsCOF.propTypes = {
  error: PropTypes.string,
  neckLabelList: PropTypes.any,
  selectedNeckLabel: PropTypes.any,
  createNeckTag: PropTypes.func.isRequired,
  setSelectedNeckTag: PropTypes.func.isRequired,
  fetchNeckTags: PropTypes.func.isRequired,
  skusWithNeckTags: PropTypes.instanceOf(List),
  neckLabelsPreviewsLoading: PropTypes.bool
};

export default NeckLabelsCOF;
