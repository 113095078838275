import { connect } from 'react-redux';
import ApiKeys from './ApiKeys';
import { initFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';

const init = initFor(ApiKeys.name);
const cardSelector = cardSelectorFor(ApiKeys.name);

const mapDispatchToProps = {
  init
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
