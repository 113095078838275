import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const RESEND_INVITE = customActionFor('TEAM').async('RESEND_INVITE');
export const REMOVE_INVITE = customActionFor('TEAM').async('REMOVE_INVITE');

// ------------------------------------
// Actions
// ------------------------------------

export function resendInvite(payload) {
  return {
    type: RESEND_INVITE.ASYNC,
    payload
  };
}

export function removeInvite(payload) {
  return {
    type: REMOVE_INVITE.ASYNC,
    payload
  };
}
