import React from 'react';
import PropTypes from 'prop-types';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import { urlRegex } from 'gooten-components/src/utils/regex';
import TestConnection from './components/TestConnection';
import Button from 'gooten-components/src/components/shared/Button';
import Config from 'gooten-components/src/config';
import SwitchField from 'gooten-components/src/components/shared/SwitchField';
import Icon from 'gooten-components/src/components/shared/Icon';

class Webhooks extends React.Component {
  static get name() {
    return 'webhooks';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Webhooks Information',
      onFailure: 'Failed to update Webhooks',
      onSuccess: 'Your Webhooks has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
    this.state = {
      checked: undefined
    };
  }

  componentDidMount() {
    this.props.init();
  }

  get canUpdate() {
    const prestineChecked = this.props.unmodified ? !!this.props.unmodified.Endpoint : false;
    return (
      this.props.ready &&
      this.props.data &&
      ((prestineChecked && this.state.checked === false) ||
        (!this.props.data.equals(this.props.unmodified) &&
          Object.values(this.inputs).every(i => !i || i.isValid())))
    );
  }

  toggleWebhooks(value) {
    this.setState({
      checked: value
    });
  }

  cancel() {
    this.setState({ checked: undefined });
    this.props.cancel();
  }

  update(checked) {
    if (checked) {
      this.props.update({ data: this.props.data });
    } else {
      const Endpoint = '';
      const data = this.props.data.set('Endpoint', '');
      this.props.change(Endpoint, 'Endpoint');
      this.props.update({ data });
    }
  }

  renderInput(name, label, placeholder, className, regexp, isRequired) {
    return (
      <TextInput
        className={className}
        id={name}
        showValidation
        isRequired={isRequired}
        emptyMsg="This field is required"
        regexp={regexp}
        validationMsg="Wrong format"
        label={label}
        placeholder={placeholder}
        onChange={(value, id) => this.props.change(value, id)}
        ref={ref => {
          this.inputs[name] = ref;
        }}
        value={this.props.data.get(name) || ''}
      >
        <TestConnection
          canTest={!!this.props.data.get(name) && this.canUpdate}
          testConnection={() => this.props.testConnection()}
          status={this.props.testConnectionStatus}
        />
        <p className="input-note color-gray">
          <Icon icon="alertCircle" className="mr-1" />
          It may take up to 10 minutes for the webhook to hit your endpoint
        </p>
      </TextInput>
    );
  }

  getOrderStatusUpdatesKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047749051-Webhooks-for-your-order-status-using-our-API';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4530139098267-Receiving-order-status-updates-via-Webhooks';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7613974926875-Receiving-order-status-updates-via-Webhooks';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047749051-Webhooks-for-your-order-status-using-our-API';
    }
  };

  render() {
    const checked =
      this.state.checked === undefined
        ? this.props.unmodified
          ? !!this.props.unmodified.Endpoint
          : false
        : this.state.checked;
    return (
      <BaseCard card={Webhooks.name} title="Webhooks">
        <div>
          <div className="webhooks-card">
            <div className="row">
              <div className="col-sm-12">
                <SwitchField
                  label="Order Status Notifications"
                  checked={checked}
                  onChange={value => this.toggleWebhooks(value)}
                />
              </div>
              <div className="col-sm-10 col-xs-7">
                <p>
                  Webhooks allow you to recieve notifications of events that happen in Gooten. For
                  more information see the&nbsp;
                  <a
                    className="settings-link"
                    href={this.getOrderStatusUpdatesKbLink()}
                    target="_blank"
                  >
                    Webhook Reference.
                  </a>
                </p>
              </div>
            </div>
            {checked ? (
              <div className="row test-button">
                <div className="col-xs-12">
                  {this.renderInput(
                    'Endpoint',
                    'Endpoint',
                    'Type endpoint URL',
                    '',
                    urlRegex,
                    true
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-sm-10 col-xs-12 cart-buttons-block">
              <Button
                className="button-primary large"
                disabled={!this.canUpdate}
                onClick={() => this.update(checked)}
              >
                Save endpoint
              </Button>
              <Button className="button-default large ml-2" onClick={() => this.cancel()}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <style jsx>
          {`
            .test-button :global(button.small) {
              position: absolute;
              top: 0.75rem;
              right: 0.75rem;
            }
          `}
        </style>
      </BaseCard>
    );
  }
}

Webhooks.propTypes = {
  init: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.object,
  unmodified: PropTypes.object,
  ready: PropTypes.bool,
  testConnection: PropTypes.func.isRequired,
  testConnectionStatus: PropTypes.number
};

export default Webhooks;
