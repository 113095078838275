class PrintAreaService {
  getPrintAreaColor(sku, options) {
    const getHardcodedColor = () => {
      if (sku.get('sku').indexOf('Mug-Black-') === 0 || sku.get('sku').indexOf('BackPack') === 0) {
        return '#000000';
      } else {
        switch (sku.get('sku')) {
          case 'VikingTumbler-20oz-White':
            return '#ffffff';
          case 'WineTumbler-12oz-White':
          case 'PeristyleBottle-16oz-White':
            return '#d1d1d1';
          case 'PintGlass-16oz-Clear':
            return '#F9F9F9';
          case 'VikingTumbler-20oz-Mint':
          case 'PeristyleBottle-16oz-Mint':
            return '#0fb5ac';

          case 'VikingTumbler-20oz-Copper':
          case 'PeristyleBottle-16oz-Copper':
            return '#d88b63';

          case 'VikingTumbler-20oz-Black':
          case 'WineTumbler-12oz-Black':
          case 'PeristyleBottle-16oz-Black':
            return '#0f1217';

          case 'VikingTumbler-20oz-Stainless':
          case 'WineTumbler-12oz-StainlessSteel':
          case 'PeristyleBottle-16oz-Stainless':
            return '#bcbcbc';

          case 'WineTumbler-12oz-Mint':
            return '#299695';
        }
      }
    };

    const getSkuColor = () => {
      const skuWithOptions = options.find(o => o.sku === sku.get('sku'));
      if (skuWithOptions) {
        const colorOption = skuWithOptions.options.find(opt =>
          opt.name.toLowerCase().match(/color/)
        );
        const color = colorOption
          ? Array.isArray(colorOption.colorHex)
            ? colorOption.colorHex[0]
            : colorOption.colorHex
          : '#ffffff';
        return color && color.indexOf('#') === -1 ? '#' + color : color;
      }
      return '#ffffff';
    };

    // first check if sku print area color is hardcoded
    return getHardcodedColor() || getSkuColor();
  }
}

export default new PrintAreaService();
