import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import background from '../../../images/blog-posts.png';

const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () =>
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows()
};

class MobileNotificationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: isMobile.any(),
      open: true
    };
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    return this.state.isMobile ? (
      <div className="mobile-notification-modal-container">
        <Modal
          isOpen={this.state.open}
          cancelClick={() => {
            this.close();
          }}
        >
          <div className="text-center p-4">
            <div>
              <img className="mobile-notification-image center-block" src={background} />
            </div>
            <div className="title-2 text-center">Our tablet experience isn't optimized yet.</div>
            <p className="body-text-2 mb-3 mt-3">
              <b>For best results use a desktop.</b>
            </p>
            <Button
              onClick={() => {
                this.close();
              }}
              className="button-default large mb-3 mt-3"
            >
              Continue Anyway
            </Button>
          </div>
        </Modal>
        <style jsx>
          {`
            .mobile-notification-image {
              max-height: 220px;
              max-width: 100%;
              width: auto;
              display: block;
            }
          `}
        </style>
      </div>
    ) : null;
  }
}

export default MobileNotificationModal;
