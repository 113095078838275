import React from 'react';
import LoadingSpinnerOverlay from '../../../Shared/LoadingSpinnerOverlay';
import { useEffect } from 'react';
import Alert from 'gooten-components/src/components/shared/Alert';
import PagedTable from '../../../Shared/PagedTable';

export const HistoryView = ({ isLoading, fetchHistory, history, historyError }) => {
  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <>
      {isLoading && <LoadingSpinnerOverlay />}

      {historyError && (
        <Alert isOpen type="failure" className={'ml-1 mr-1 mb-2'}>
          <div>Failed to retrieve history</div>
        </Alert>
      )}

      <div className="history-tab">
        <div className="history-content mt-4">
          <div className="grid-container">
            <div className="title-2 mt-3 ml-3 header">Previous uploads</div>

            {history && <PagedTable data={history}></PagedTable>}
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryView;
