import React from 'react';
import Input from 'gooten-components/src/components/shared/Input';
import Button from 'gooten-components/src/components/shared/Button';
import PropTypes from 'prop-types';
import FileUpload from 'react16-fileupload';
import Modal from 'gooten-components/src/components/shared/Modal';
import Icon from 'gooten-components/src/components/shared/Icon';

// This is a class component because the fileupload package needs a string ref below
class OrderImageUpload extends React.Component {
  state = {
    newUrl: null,
    newUrlError: null,
    selectedFileName: null,
    openModal: false
  };

  handleImageUrlSubmit = () => {
    this.setState({ newUrlError: null });
    if (this.state.newUrl) {
      this.props.submitImageUrl(this.props.image.Id, this.state.newUrl);
    } else {
      this.setState({ newUrlError: 'You must submit a valid url' });
    }
  };

  fileUploadOptions = {
    wrapperDisplay: 'inline-block',
    chooseAndUpload: false,
    baseUrl: './',
    multiple: false,
    numberLimit: 1,
    accept: 'image/jpeg,image/png',
    chooseFile: file => {
      this.setState({ selectedFileName: file[0] ? file[0].name : '' });
    },
    doUpload: file => {
      this.props.submitImageFile(this.props.image.Id, file);
    }
  };

  render() {
    return (
      <div>
        <Button
          onClick={() => this.setState({ openModal: true })}
          className="button-default large m-1"
          iconLeft="image"
        >
          Change Image
        </Button>
        <Modal
          isOpen={this.state.openModal}
          className="medium"
          cancelText="Cancel"
          title="Change Image"
          cancelClick={() => this.setState({ openModal: false })}
        >
          <div className="p-3">
            <div className="upload-container pt-5 pb-5 pr-3 pl-3 w-100 mb-4">
              <FileUpload
                className="file-upload-field d-flex align-items-start justify-content-center mr-n1 ml-n1 flex-wrap"
                options={this.fileUploadOptions}
              >
                {this.state.selectedFileName && (
                  <Input
                    noLabel
                    id={`upload-file-${this.props.image.Id}`}
                    className="m-1 mb-0"
                    value={this.state.selectedFileName || ''}
                  />
                )}
                <div ref="chooseBtn">
                  {!this.state.selectedFileName ? (
                    <Button className="button-cta large m-1">Upload from device</Button>
                  ) : (
                    <Button iconOnly className="button-default large m-1">
                      <Icon icon="file" />
                    </Button>
                  )}
                </div>
                {this.state.selectedFileName ? (
                  <div ref="uploadBtn">
                    <Button className="button-cta large m-1">Submit</Button>
                  </div>
                ) : null}
              </FileUpload>
            </div>
            <div className="d-flex mr-n1 ml-n1">
              <Input
                id={`upload-url-${this.props.image.Id}`}
                className="mr-1 ml-1"
                type="text"
                value={this.state.newUrl || ''}
                hasError={!!this.state.newUrlError}
                errorText={this.state.newUrlError}
                onChange={e => this.setState({ newUrl: e.target.value })}
                label="Upload from Url"
                placeholder="Paste image URL"
              />
              <Button
                className={`button-${this.state.newUrl ? 'cta' : 'default'} extra-large mr-1 ml-1`}
                onClick={() => this.handleImageUrlSubmit()}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
        <style jsx>
          {`
            .upload-container {
              border: 2px dashed darkgray;
              border-radius: 0.25rem;
            }
            .upload-container :global(.input-container),
            .upload-container :global(input) {
              max-width: 20rem;
            }
            .upload-container :global(button) {
              flex-grow: 1;
            }
          `}
        </style>
      </div>
    );
  }
}

OrderImageUpload.propTypes = {
  image: PropTypes.object,
  submitImageFile: PropTypes.func,
  submitImageUrl: PropTypes.func
};

export default OrderImageUpload;
