import { createSelector } from 'reselect';
import { imageUploadStateSelector } from '../../../ImageUploadSelectors';
import storageService from '../../../../../services/storageService';

export const isDisclaimerDisabledSelector = () =>
  !!storageService.getItem('hide_emb_disclaimer_modal');

export const disclaimerVisibilitySelector = createSelector([imageUploadStateSelector], state =>
  state.getIn(['embroideryDisclaimer', 'visible'])
);

export const disclaimerPayloadSelector = createSelector([imageUploadStateSelector], state =>
  state.getIn(['embroideryDisclaimer', 'payload'])
);
