'use strict';
// @flow

import {EDITOR_RECONSTITUTE, TEMPLATE_LOAD, TEMPLATE_LOADED, EDITOR_RESTORE} from '../actions/actionTypes';
import initialState from '../initialState';
import { fromJS } from 'immutable';

export default (state = initialState.get('template'), action) => {

  switch (action.type) {

  case EDITOR_RESTORE: {
    return action.payload.template
      ? fromJS(action.payload.template)
      : state;
  }

  case EDITOR_RECONSTITUTE: {
    return state.merge(action.payload.template);
  }

  // update template state
  // on TEMPLATE_LOAD - has img urls
  // on TEMPLATE_LOADED - has images
  case TEMPLATE_LOAD:
  case TEMPLATE_LOADED: {
    return state.merge(action.payload);
  }

  default:
    return state;
  }
};
