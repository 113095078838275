import { injectReducer } from '../../store/reducers';
import { injectSaga } from '../../store/sagas';
import CheckoutContainer from './CheckoutContainer';
import CheckoutReducer from './CheckoutReducer';
import CheckoutSaga from './CheckoutSaga';
import AddressSelectorSaga from './atoms/AddressSelector/AddressSelectorSaga';
import AddressFormSaga from './atoms/AddressSelector/AddressForm/AddressFormSaga';
import PaymentSaga from './atoms/Payment/PaymentSaga';
import ShippingMethodSaga from './atoms/ShippingMethod/ShippingMethodSaga';
import CountrySelectionSaga from '../shared/CountrySelection/CountrySelectionSaga';
import OrderSummarySaga from '../shared/OrderSummary/OrderSummarySaga';

const componentName = 'checkout';

/* Sync Inject reducers and return Component */

export default store => {
  injectReducer(store, { key: componentName, reducer: CheckoutReducer });
  injectSaga(store, { key: 'checkout', saga: CheckoutSaga });
  injectSaga(store, { key: 'addressSelector', saga: AddressSelectorSaga });
  injectSaga(store, { key: 'addressForm', saga: AddressFormSaga });
  injectSaga(store, { key: 'payment', saga: PaymentSaga });
  injectSaga(store, { key: 'shippingMethod', saga: ShippingMethodSaga });
  // These are used only in cof
  injectSaga(store, { key: 'data', saga: CountrySelectionSaga });
  injectSaga(store, { key: 'orderSummary', saga: OrderSummarySaga });
  return CheckoutContainer;
};
