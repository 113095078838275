// use observer to observe `navigation-panel` size changes, in order to
// update margin for full screen notifications
import throttle from 'gooten-components/src/utils/throttle';

class NavigationPanelObserver {
  constructor() {
    this.handleMutation = throttle(this.handleMutation.bind(this), 400);
  }

  connect(callback) {
    if (this.navigationPanelDOM()) {
      this.callback = callback;
      const config = { attributes: true };
      this.observer = new MutationObserver(this.handleMutation);
      this.observer.observe(this.navigationPanelDOM(), config);
    }
  }

  disconnect() {
    if (this.navigationPanelDOM()) {
      this.observer.disconnect();
    }
  }

  handleMutation() {
    if (this.callback) {
      this.callback();
    }
  }

  navigationPanelDOM() {
    const navigationPanel = document.getElementsByClassName('navigation-panel');
    return navigationPanel && navigationPanel.length ? navigationPanel[0] : undefined;
  }

  marginLeft() {
    return this.navigationPanelDOM() ? this.navigationPanelDOM().offsetWidth : 0;
  }
}

export default new NavigationPanelObserver();
