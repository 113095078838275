import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import OrderFromView from './OrderFromView';
import CreateNewView from './CreateNewView';
import HubProductsView from './HubProductsView';
import PastOrdersView from './PastOrdersView';
import EditDesignView from './EditDesignView';
import CartView from './CartView';

const customOrderFormRoutes = {
  path: '/custom-order-form',
  component: CoreLayout,
  indexRoute: { component: withErrorBoundary(OrderFromView) },
  childRoutes: [
    {
      path: 'create-new(/:step)',
      component: withErrorBoundary(CreateNewView)
    },
    {
      path: 'hub-products(/:step)',
      component: withErrorBoundary(HubProductsView)
    },
    {
      path: 'past-orders(/:step)',
      component: withErrorBoundary(PastOrdersView)
    },
    {
      path: 'edit-design(/:step)',
      component: withErrorBoundary(EditDesignView)
    },
    {
      path: 'cart(/:step)',
      component: withErrorBoundary(CartView)
    }
  ]
};

export default customOrderFormRoutes;
