import { connect } from 'react-redux';
import CountrySelection from './CountrySelection';
import {
  updateShippingCountry,
  fetchAsync,
  FETCH_COUNTRIES
} from '../../../store/actions/dataActions';
import {
  supportedShippingCountriesOptionsSelector,
  defaultCountryCodeSelector,
  cofShippingCountrySelector,
  shippingCountrySelector
} from '../../../store/selectors/countriesSelectors';
import withRouteStep from '../../../hocs/withRouteStep';

const mapDispatchToProps = {
  selectCountry: countryCode => updateShippingCountry(countryCode),
  fetchShippingCountries: () => fetchAsync(FETCH_COUNTRIES)
};

const mapStateToProps = state => {
  return {
    shippingCountries: supportedShippingCountriesOptionsSelector(state),
    defaultCountryCode: defaultCountryCodeSelector(state),
    shippingCountry: cofShippingCountrySelector(state),
    isShippingCountrySet: !!shippingCountrySelector(state)
  };
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(CountrySelection),
  'shipping-country'
);
