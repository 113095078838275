import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TikTokConnect from './TikTokConnect';
import { initStoreConnect, connectStore } from '../ConnectStoreViewActions';

const mapDispatchToProps = {
  init: state => initStoreConnect('tiktok', state),
  connectStore: region => connectStore('tiktok', region)
};

export default withRouter(connect(null, mapDispatchToProps)(TikTokConnect));
