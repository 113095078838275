import { createSelector } from 'reselect';

const statusViewSelector = state => state.getIn(['orders', 'statusView']);

// export const statusData = createSelector([statusViewSelector], orders =>
//   orders.get('statusData').toJS()
// )

export const loadingStatus = createSelector([statusViewSelector], orders => orders.get('loading'));

export const acceptableStatuses = createSelector([statusViewSelector], orders => {
  let allStatuses = orders.get('acceptableStatuses').toJS();

  const moveToBegin = (statuses, id) => {
    var sts = allStatuses.find(x => x.Id === id);
    if (sts) {
      statuses.splice(statuses.indexOf(sts), 1);
      statuses.unshift(sts);
    }
  };

  // moving Cancelled, Hold, and ReadyForPrint statuses to top
  moveToBegin(allStatuses, 34);
  moveToBegin(allStatuses, 31);
  moveToBegin(allStatuses, 8);

  return allStatuses;
});
