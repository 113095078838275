import { fromJS } from 'immutable';
import { IMAGE_FILE_UPLOAD, SHOW_UPLOAD, SHOW_LOADING, RESET } from './ImageUploadModalActions';

const initialState = fromJS({ image: null, loading: false, upload: false });

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    case SHOW_UPLOAD:
      return state.set('upload', action.payload);
    case SHOW_LOADING:
      return state.set('loading', action.payload);
    case IMAGE_FILE_UPLOAD.ASYNC:
      return state.set('loading', true);
    case IMAGE_FILE_UPLOAD.SUCCESS:
      return state.set('loading', false).set('image', action.payload).set('upload', false);
    case IMAGE_FILE_UPLOAD.FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
};
