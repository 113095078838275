import { takeEvery, call, put, all, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { PUSH, dismiss } from './NotificationsActions';
import { allNotificationsSelector } from './NotificationsSelectors';

export function* pushHandler(action) {
  yield call(delay, 6500);
  const notifications = yield select(allNotificationsSelector);
  const index = notifications.findIndex(x => x.text === action.payload.text);
  yield put(dismiss(index));
}

export function* watchPush() {
  yield takeEvery(PUSH, pushHandler);
}

export default function* rootSaga() {
  yield all([watchPush()]);
}
