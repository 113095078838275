import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const IsCapsLockActive = props => {
  const EVENT_KEY_UP = 'keyup';
  const EVENT_KEY_DOWN = 'keydown';

  const [capsLockActive, setCapsLockActive] = useState(false);

  useEffect(() => {
    document.addEventListener(EVENT_KEY_UP, checkCapsLockStatus);
    document.addEventListener(EVENT_KEY_DOWN, checkCapsLockStatus);

    return () => {
      document.removeEventListener(EVENT_KEY_UP, checkCapsLockStatus);
      document.removeEventListener(EVENT_KEY_DOWN, checkCapsLockStatus);
    };
  }, []);

  const checkCapsLockStatus = event =>
    event.getModifierState
      ? event.getModifierState('CapsLock')
        ? setCapsLockActive(true)
        : setCapsLockActive(false)
      : setCapsLockActive(false);

  return props.children(capsLockActive);
};

IsCapsLockActive.propTypes = {
  children: PropTypes.func.isRequired
};
