import { createSelector } from 'reselect';
import { publishDataSelector } from '../../../ProductPublishSelectors';
import { selectedProviderSelector } from '../../ProvidersTab/ProvidersTabSelectors';
import { fromJS } from 'immutable';
import Config from '../../../../../config';

export const storesSelector = createSelector(
  [publishDataSelector, selectedProviderSelector],
  (data, sProvider) => {
    let stores = [];

    if (data.get('storage') && data.getIn(['storage', 'enabled'])) {
      stores.push({
        name: 'Storage',
        provider: 'storage'
      });
    }

    if (data.get('stores') && data.get('stores').size) {
      stores = stores.concat(
        data
          .get('stores')
          .filter(s => s.get('enabled'))
          .map(s => ({
            name: s.get('name'),
            sId: s.get('id'),
            provider: s.get('provider')
          }))
          .toJS()
      );
    }

    // exclude current provider
    stores = stores.filter(s => s.sId !== sProvider.get('id'));

    return fromJS(stores);
  }
);
