import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import ErrorMessage from '../ErrorMessage';
import Rules from '../../../ErrorsHandler/ValidationRules';
import './ProductDescription.scss';
import { COLORS } from '../../../../../constants';

const ProductDescription = props => {
  const [error, setError] = useState(null);

  // Error messages
  const errors = {
    required: 'Product description is required.'
  };

  useEffect(() => {
    let err = Rules.productDescription.isDescriptionRequired(props.required, props.description)
      ? errors.required
      : null;

    setError(err);
    props.setError(props.storeId, 'ProductDescription', err);
  }, [props.description]);

  const onChangeHandler = (val, delta, author) => {
    // ReactQuill doesn't support all formatting styles from Shopify or Etsy,
    // do not update description  if it wasn't changed by user.
    if (author === 'user') {
      props.onChange(props.storage, props.storeId, 'productDesc', val);
    }
  };

  return (
    <div className="product-publish-description pb-4">
      <div className="button-1 description-tag w-100 pl-3 pt-1">Description</div>
      <ReactQuill
        theme="snow"
        defaultValue={props.description || ''}
        value={props.description || ''}
        onChange={onChangeHandler}
      />
      {error ? <ErrorMessage customClass="mt-1" message={error} /> : null}

      <style jsx>
        {`
          .description-tag {
            border: 1px solid ${error ? COLORS.cayennePepper500 : COLORS.neutralLight400};
            border-width: 1px 1px 0 1px;
            border-radius: 4px 4px 0 0;
            color: ${error ? COLORS.cayennePepper500 : COLORS.neutralDark200};
          }
          :global(.quill .ql-toolbar) {
            border-color: ${error ? COLORS.cayennePepper500 : COLORS.neutralLight400};
            background: none !important;
            border-width: 0 1px 0 1px;
          }

          :global(.ql-container) {
            border-color: ${error ? COLORS.cayennePepper500 : COLORS.neutralLight400} !important;
            border-top: none;
            border-radius: 0 0 4px 4px;
          }
          :global(.ql-editor) {
            height: 10.5rem;
            background: none !important;
          }
          :global(.quill button),
          :global(.quill .ql-picker) {
            background: #f0f0f0 !important;
            margin-right: 4px;
            border-radius: 4px !important;
          }
        `}
      </style>
    </div>
  );
};

ProductDescription.propTypes = {
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setError: PropTypes.func,
  storage: PropTypes.bool,
  storeId: PropTypes.number,
  required: PropTypes.bool
};

export default ProductDescription;
