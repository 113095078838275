import { connect } from 'react-redux';
import CreateNewView from './CreateNewView';
import {
  hasPastOrdersSelector,
  hasHubProductsSelector
} from '../OrderFromView/OrderFromViewSelectors';
import { hasCartItemsSelector } from '../CartView/CartViewSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    hasPastOrders: hasPastOrdersSelector(state),
    hasHubProducts: hasHubProductsSelector(state),
    hasCartItems: hasCartItemsSelector(state)
  };
};

export default connect(mapStateToProps)(CreateNewView);
