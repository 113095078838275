/* eslint-disable no-control-regex */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import Card from 'gooten-components/src/components/shared/Card';
import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Select from 'gooten-components/src/components/shared/Select';
import Alert from 'gooten-components/src/components/shared/Alert';
import { usStatesIndex, caStatesIndex } from 'gooten-components/src/utils/states.js';
import { COHBER_VENDOR_ID } from 'gooten-components/src/const/VendorRules.js';
const ShippingAddressCard = props => {
  const {
    orderId,
    itemsData,
    summaryData: { ShippingAddress, MediationId, MediationSettings, CanEditShippingAddress },
    countries,
    addressTypes,
    getCountries,
    getAddressTypes,
    saveShippingAddress,
    updateEditingShippingAddress,
    editingShippingAddress,
    ignoreSuggestedAddress,
    applySuggestedAddress,
    isShippingAddressEditDisabled
  } = props;

  const parseMediationSettings = settings => {
    if (settings === null) {
      return null;
    }

    const parseJSON = str => {
      try {
        return JSON.parse(str);
      } catch (error) {
        return null;
      }
    };

    // try to get mediation setting without cleaning string...
    let result = parseJSON(settings);
    if (result === null) {
      // we got an error while trying to parse string
      // try to clean it...
      result = parseJSON(
        settings
          .replace(/\\/g, '')
          .replace(/^"/, '')
          .replace(/"$/, '')
          .replace(/:""([^,])/g, (m, x) => ':"' + x)
          .replace(/([^:])"",/g, (m, x) => x + '",')
      );
    }

    // it appears that tabs has to be cleaned after parsing result only...
    result = typeof result === 'string' ? result.replace(/\t+/g, '') : result;
    return parseJSON(result);
  };

  const checkIfProposedAddressExists = suggestedAddress => {
    if (suggestedAddress === null) {
      return false;
    }
    if (
      suggestedAddress['Proposed.Line1'] ||
      suggestedAddress['Proposed.Line2'] ||
      suggestedAddress['Proposed.City'] ||
      suggestedAddress['Proposed.State'] ||
      suggestedAddress['Proposed.PostalCode'] ||
      suggestedAddress['Proposed.CountryCode']
    ) {
      return true;
    }
    return false;
  };

  const suggestedAddress = parseMediationSettings(MediationSettings);
  const hasProposedAddress = checkIfProposedAddressExists(suggestedAddress);

  const [selectedCountryCode, selectCountryCode] = useState(ShippingAddress.CountryCode);

  const states = () => {
    switch (selectedCountryCode) {
      case 'US':
        return usStatesIndex;
      case 'CA':
        return caStatesIndex;
    }
  };

  const isCohberVendor =
    itemsData.find(item => item && item.VendorId === COHBER_VENDOR_ID) != undefined;

  const handleChangeCountryCode = (event, handleChange, setFieldValue) => {
    // handleChange function from Formik...
    handleChange(event);
    // when country is changed, reset state field...
    setFieldValue('stateRegion', '');

    selectCountryCode(event.target.value);
  };

  return (
    <>
      <Card title={!editingShippingAddress ? '' : 'Shipping Address'}>
        {!editingShippingAddress && (
          <>
            <div className="d-flex mr-n1 ml-n1 justify-content-between flex-wrap">
              <div className="original-address mr-1 ml-1 mb-2">
                <div className="d-flex justify-content-between">
                  <p className="title-2">Shipping Address</p>
                  {(!MediationId || !hasProposedAddress) && !isShippingAddressEditDisabled && (
                    <Button
                      onClick={() => {
                        if (countries.length === 0) getCountries();
                        if (addressTypes.length === 0) getAddressTypes();
                        updateEditingShippingAddress(!editingShippingAddress);
                      }}
                      className="button-default medium"
                    >
                      Edit
                    </Button>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="shipping-first-name">{ShippingAddress.FirstName}</span>{' '}
                    <span className="shipping-last-name">{ShippingAddress.LastName}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="shipping-address-line-1">{ShippingAddress.Line1}</span>
                  </p>
                </div>
                {ShippingAddress.Line2 && (
                  <div className="d-flex justify-content-between">
                    <p>
                      <span className="shipping-address-line-2">{ShippingAddress.Line2}</span>
                    </p>
                  </div>
                )}
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="shipping-address-city">{ShippingAddress.City}</span>&nbsp;
                    <span className="shipping-address-state">{ShippingAddress.State}</span>&nbsp;
                    <span className="shipping-address-postal">{ShippingAddress.PostalCode}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="shipping-country">{ShippingAddress.CountryCode}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="shipping-phone">Phone: {ShippingAddress.Phone}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    <a href={`mailto:${ShippingAddress.Email}`}>
                      <span className="shipping-email">{ShippingAddress.Email}</span>
                    </a>
                  </p>
                </div>
              </div>
              {CanEditShippingAddress &&
                MediationId &&
                hasProposedAddress &&
                !isShippingAddressEditDisabled && (
                  <div className="mr-1 ml-1 mb-2">
                    <div className="d-flex justify-content-between">
                      <p className="title-2">Suggested Address</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>
                        <span className="suggested-address-line-1">
                          {suggestedAddress['Proposed.Line1']}
                        </span>
                      </p>
                    </div>
                    {suggestedAddress['Proposed.Line2'] && (
                      <div className="d-flex justify-content-between">
                        <p>
                          <span className="suggested-address-line-2">
                            {suggestedAddress['Proposed.Line2']}
                          </span>
                        </p>
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <p>
                        <span className="suggested-address-city">
                          {suggestedAddress['Proposed.City']}&nbsp;
                        </span>
                        <span className="suggested-address-state">
                          {suggestedAddress['Proposed.State']}&nbsp;
                        </span>
                        <span className="suggested-address-postal">
                          {suggestedAddress['Proposed.PostalCode']}
                        </span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>
                        <span className="suggested-country">
                          {suggestedAddress['Proposed.CountryCode']}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
            </div>
            {CanEditShippingAddress && hasProposedAddress && !isShippingAddressEditDisabled && (
              <div className="d-flex flex-wrap justify-content-between suggested-buttons mr-n1 ml-n1 mb-2 mt-4">
                {MediationId && (
                  <>
                    <Button
                      className={'button-destructive large ml-1 mr-1 mb-2'}
                      onClick={() => {
                        ignoreSuggestedAddress(orderId, { mediationId: MediationId });
                      }}
                    >
                      Ignore Suggested Address
                    </Button>
                    <Button
                      className={'button-primary large ml-1 mr-1 mb-2'}
                      onClick={() => {
                        applySuggestedAddress(orderId, { mediationId: MediationId });
                      }}
                    >
                      Apply Suggested Address
                    </Button>
                    <Button
                      className={'button-default large ml-1 mr-1 mb-2'}
                      onClick={() => {
                        if (countries.length === 0) getCountries();
                        if (addressTypes.length === 0) getAddressTypes();
                        updateEditingShippingAddress(!editingShippingAddress);
                      }}
                    >
                      Edit Shipping Address
                    </Button>
                  </>
                )}
              </div>
            )}
            {suggestedAddress && suggestedAddress.Suggestion && (
              <Alert isOpen type="warning">
                <div>{suggestedAddress.Suggestion}</div>
              </Alert>
            )}
          </>
        )}
        {editingShippingAddress && (
          <Formik
            initialValues={{
              firstName: ShippingAddress.FirstName || '',
              lastName: ShippingAddress.LastName || '',
              address1: ShippingAddress.Line1 || '',
              address2: ShippingAddress.Line2 || '',
              city: ShippingAddress.City || '',
              stateRegion:
                ShippingAddress.State &&
                states() &&
                states().hasOwnProperty(ShippingAddress.State.toUpperCase())
                  ? ShippingAddress.State
                  : '',
              zipPostalCode: ShippingAddress.PostalCode || '',
              country: ShippingAddress.CountryCode || 'US',
              phone: ShippingAddress.Phone || '',
              email: ShippingAddress.Email || '',
              addressType: ShippingAddress.AddressTypeId || 1
            }}
            validationSchema={() =>
              object().shape({
                firstName: string()
                  .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid First Name') // name
                  .matches(/[\x00-\x7F]+/, 'Enter a valid First Name') // onlyEnglish
                  .required('Enter a valid First Name'), // required
                lastName: string()
                  .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid Last Name') // name
                  .matches(/[\x00-\x7F]+/, 'Enter a valid Last Name') // onlyEnglish
                  .required('Enter a valid Last Name'), // required
                address1: string()
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
                  .matches(/[\x00-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
                  .max(35, 'Please limit to 35 characters')
                  .required('Enter a valid Address 1'), // required
                address2: string().when(['address1', 'country'], (address1, country) => {
                  if (isCohberVendor && country !== 'US') {
                    if (address1) {
                      return string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                        .matches(/[\x00-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                        .max(
                          35 - address1.length,
                          'Please limit to 35 characters for Address 1 + Address 2'
                        );
                    } else {
                      return string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                        .matches(/[\x00-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                        .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
                    }
                  } else {
                    return string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                      .matches(/[\x00-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                      .max(35, 'Please limit to 35 characters');
                  }
                }),
                city: string()
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
                  .matches(/[\x00-\x7F]+/, 'Enter a valid City') // onlyEnglish
                  .required('Enter a valid City'), // required
                stateRegion: string()
                  .when('country', {
                    is: country => {
                      if (country) {
                        return ['CA', 'US'].includes(country);
                      }
                      return false;
                    },
                    then: string().required('Enter a valid State/Region')
                  }) // required if country is CA or US
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Region') // shippingRequiredUSandCA
                  .matches(/[\x00-\x7F]+/, 'Enter a valid State/Region'), // onlyEnglish
                zipPostalCode: string().when('country', {
                  is: country => country === 'US',
                  then: string()
                    .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                    .required('Enter a valid zip code'),
                  otherwise: string().when('country', {
                    is: country => country === 'CA',
                    then: string()
                      .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                      .required('Enter a valid postal code'),
                    otherwise: string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                  })
                }),
                country: string().required('Enter a Country'), // required
                phone: string()
                  .matches(
                    /^[+\-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                    'Enter a valid Phone Number'
                  )
                  .matches(/^.{0,15}$/, 'Please limit to 15 characters')
                  .required('Enter a valid Phone Number'),
                email: string()
                  .matches(
                    // eslint-disable-next-line max-len
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    'Enter a valid Email'
                  ) // email
                  .required('Enter a valid Email'),
                addressType: number().required('Enter an Address Type')
              })
            }
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                AddressTypeId: values.addressType,
                City: values.city,
                CountryCode: values.country,
                Email: values.email,
                FirstName: values.firstName,
                LastName: values.lastName,
                Line1: values.address1,
                Line2: values.address2,
                Phone: values.phone,
                PostalCode: values.zipPostalCode,
                State: values.stateRegion,
                type: 'shipping'
              };
              saveShippingAddress(orderId, data);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              submitCount,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="firstLastName d-flex">
                  <Input
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    className="mr-3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    disabled={isSubmitting}
                    hasError={submitCount > 0 && !!errors.firstName}
                    errorText={submitCount > 0 && !!errors.firstName ? errors.firstName : null}
                  />
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    disabled={isSubmitting}
                    hasError={submitCount > 0 && !!errors.lastName}
                    errorText={submitCount > 0 && !!errors.lastName ? errors.lastName : null}
                  />
                </div>
                <Input
                  name="address1"
                  type="text"
                  placeholder="Address 1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address1}
                  disabled={isSubmitting}
                  hasError={submitCount > 0 && !!errors.address1}
                  errorText={submitCount > 0 && !!errors.address1 ? errors.address1 : null}
                />
                <Input
                  name="address2"
                  type="text"
                  placeholder="Address 2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address2}
                  disabled={isSubmitting}
                  hasError={submitCount > 0 && !!errors.address2}
                  errorText={submitCount > 0 && !!errors.address2 ? errors.address2 : null}
                />
                <Input
                  name="city"
                  type="text"
                  placeholder="City"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  disabled={isSubmitting}
                  hasError={submitCount > 0 && !!errors.city}
                  errorText={submitCount > 0 && !!errors.city ? errors.city : null}
                />
                {states() ? (
                  <Select
                    name="stateRegion"
                    label="State"
                    onChange={handleChange}
                    value={values.stateRegion?.toUpperCase() || -1}
                    disabled={isSubmitting}
                    hasError={submitCount > 0 && !!errors.stateRegion}
                    errorText={submitCount > 0 && !!errors.stateRegion ? errors.stateRegion : null}
                  >
                    {!values.stateRegion ? (
                      <option key={-1} value="">
                        Select a State
                      </option>
                    ) : null}
                    {Object.keys(states()).map(key => (
                      <option key={key} value={key}>
                        {`${key} - ${states()[key]}`}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    name="stateRegion"
                    type="text"
                    placeholder="State/Region"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stateRegion}
                    disabled={isSubmitting}
                    hasError={submitCount > 0 && !!errors.stateRegion}
                    errorText={submitCount > 0 && !!errors.stateRegion ? errors.stateRegion : null}
                  />
                )}
                <Input
                  name="zipPostalCode"
                  type="text"
                  placeholder="Zip/Postal Code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zipPostalCode}
                  disabled={isSubmitting}
                  hasError={submitCount > 0 && !!errors.zipPostalCode}
                  errorText={
                    submitCount > 0 && !!errors.zipPostalCode ? errors.zipPostalCode : null
                  }
                />
                <Select
                  name="country"
                  label={'Country'}
                  disabled={isSubmitting}
                  onChange={event => handleChangeCountryCode(event, handleChange, setFieldValue)}
                  value={values.country}
                  hasError={submitCount > 0 && !!errors.country}
                  errorText={submitCount > 0 && !!errors.country ? errors.country : null}
                >
                  {countries
                    .filter(country => !['Cuba', 'Iran', 'Syria'].includes(country.Name))
                    .map(country => (
                      <option key={country.Id} value={country.CountryCode}>
                        {country.CountryCode} - {country.Name}
                      </option>
                    ))}
                </Select>
                <Input
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  disabled={isSubmitting}
                  hasError={submitCount > 0 && !!errors.phone}
                  errorText={submitCount > 0 && !!errors.phone ? errors.phone : null}
                />
                <Input
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={isSubmitting}
                  hasError={submitCount > 0 && !!errors.email}
                  errorText={submitCount > 0 && !!errors.email ? errors.email : null}
                />
                <Select
                  label={'Address Type'}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  name="addressType"
                  value={values.addressType}
                  hasError={submitCount > 0 && !!errors.addressType}
                  errorText={submitCount > 0 && !!errors.addressType ? errors.addressType : null}
                >
                  {addressTypes.map(addressType => (
                    <option key={addressType.Id} value={addressType.Id}>
                      {addressType.Name}
                    </option>
                  ))}
                </Select>
                <div className="d-flex justify-content-end mb-3">
                  <Button
                    className="mr-2 button-default large cancel-button"
                    onClick={() =>
                      isSubmitting ? null : updateEditingShippingAddress(!editingShippingAddress)
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-primary large submit-button"
                    onClick={() => (isSubmitting ? null : handleSubmit())}
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </Card>
      <style jsx>
        {`
          .order-page-content {
            background: white;
          }

          .firstName {
            margin-right: 15px !important;
          }

          :global(.suggested-buttons button),
          .original-address {
            flex-grow: 1;
          }
          :global(.submit-btn),
          :global(.cancel-btn) {
            width: 150px !important;
          }
        `}
      </style>
    </>
  );
};

ShippingAddressCard.propTypes = {
  orderId: PropTypes.string.isRequired,
  itemsData: PropTypes.array.isRequired,
  summaryData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  saveShippingAddress: PropTypes.func.isRequired,
  updateEditingShippingAddress: PropTypes.func.isRequired,
  editingShippingAddress: PropTypes.bool.isRequired,
  ignoreSuggestedAddress: PropTypes.func.isRequired,
  applySuggestedAddress: PropTypes.func.isRequired,
  getAddressTypes: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  addressTypes: PropTypes.array.isRequired,
  isShippingAddressEditDisabled: PropTypes.bool
};

export default ShippingAddressCard;
