import React from 'react';
import PropTypes from 'prop-types';
import { LoginHeader } from '../Login/StandardLogin/LoginHeader';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPasswordView = props => {
  return (
    <>
      <div className="sign-in">
        <div className="bg-cover">
          <img src="https://adminassets.azureedge.net/admin-assets/img/login-illustration.png" />
        </div>
        <LoginHeader contentDescription="Reset your Gooten Password." />

        <div className="middle-content">
          <div className="sign-in-card">
            <ResetPasswordForm
              email={props.config.email}
              token={props.config.changePasswordToken}
            />
          </div>
        </div>
        <div className="forgot-password-link">
          I remembered!
          <a href="/admin/account/login" className="forgot-password-link">
            Log in now
          </a>
        </div>
      </div>
      <style jsx>{`
        .sign-in {
          display: flex;
          flex-direction: column;
          height: inherit;
        }
        .bg-cover {
          background: linear-gradient(
            to bottom,
            #d3e2f8 0px,
            #d3e2f8 260px,
            #0a2599 260px,
            #0a2599 100%
          );
          position: absolute;
          top: 0;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
        }
        .bg-cover > img {
          position: absolute;
          top: -5vw;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 2;
          margin: auto;
        }
        @media (max-width: 992px) {
          .bg-cover > img {
            display: none;
          }
        }
        .middle-content {
          z-index: 20;
          display: flex;
          margin: 70px auto 25px auto;
          max-width: 1000px;
        }
        .sign-in-card {
          width: 580px;
          height: fit-content;
          padding: 30px;
          background-color: #fff;
          border: 1px solid #ededed;
          border-radius: 4px;
          box-shadow: 0px 0px 18px 0px #8d8d8d6b;
          margin-bottom: 10px;
        }
        @media (max-width: 768px) {
          .middle-content {
            margin: 36px 24px;
          }
          .sign-in-card {
            width: 100%;
          }
        }
        .forgot-password-link {
          z-index: 5;
          margin: 0 auto;
          color: white;
          font-family: Avenir-Roman;
          font-size: 1.1em;
          line-height: 175%;
        }
        .forgot-password-link > span {
          color: white;
          font-family: Avenir-Roman;
          font-size: 1.1em;
          line-height: 175%;
        }
        .forgot-password-link > a {
          color: white;
          font-family: Avenir-Roman;
          font-size: 1.1em;
          line-height: 175%;
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};

ResetPasswordView.displayName = 'ResetPasswordView';

ResetPasswordView.propTypes = {
  config: PropTypes.object
};
