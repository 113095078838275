import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, string } from 'yup';

import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Alert from 'gooten-components/src/components/shared/Alert';

export const ResetPassword = ({ passwordInvitationSent, wrongToken }) => {
  return (
    <Formik
      validateOnBlur
      initialValues={{
        email: ''
      }}
      validationSchema={() =>
        object().shape({
          email: string()
            .required('Please enter a valid email.')
            .email('Please enter a valid email.')
        })
      }
      onSubmit={(data, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        fetch(`/Admin/Account/EmailPasswordReset`, {
          method: 'POST',
          body: JSON.stringify({ email: data.email }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(() => passwordInvitationSent(true))
          .catch(err => {
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched }) => (
        <div
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          {wrongToken ? (
            <Alert isOpen type="important">
              Wrong Token
            </Alert>
          ) : null}
          <div className="main-header">
            Password reset
            <p>Enter the email address you use to sign in.</p>
          </div>
          {/* Email Field */}
          <div className={`sign-in-field ${touched.email && !!errors.email ? 'field-error' : ''}`}>
            <div className="ui input fluid">
              <Input
                name="email"
                type="email"
                className="password-reset-email"
                placeholder="Your Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={isSubmitting}
                hasError={touched.email && !!errors.email}
                errorText={touched.email && !!errors.email ? errors.email : ''}
              />
            </div>
          </div>

          <div className="btn-group">
            <span />
            <Button
              loading={isSubmitting}
              className="button-primary large sm-media-full password-reset-submit"
              type="submit"
              onClick={() => (isSubmitting ? null : handleSubmit())}
            >
              Email me instructions
            </Button>
          </div>
          <style jsx>{`
            .main-header {
              font-family: Avenir-Heavy;
              font-weight: 800;
              font-size: 1.5rem;
              line-height: 160%;
              margin-bottom: 1rem;
            }
            :global(.alert-content) {
              display: flex;
            }
            :global(.alert-body) {
              margin-left: 5px;
            }
            .main-header > p {
              font-family: Avenir-Roman;
              color: #646b72;
              font-size: 13.5px;
              margin-top: -12px;
              font-weight: 400;
            }
            .btn-group {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }
            @media (max-width: 768px) {
              .btn-group {
                margin-top: 0;
              }
            }
          `}</style>
        </div>
      )}
    </Formik>
  );
};

export const ResetPasswordSuccess = () => (
  <div className="middle-content">
    <div className="email-sent-card">
      <span className="title">Email Sent!</span>
      <div className="content">
        Check your inbox for instructions on how to reset your Gooten password. You may now close
        this window.
      </div>
      <div className="suc-image-container">
        <img
          className="email-success-image"
          src="https://adminassets.azureedge.net/admin-assets/img/about-us.png"
          att="success icon"
        />
      </div>
      <hr style={{ marginTop: '-1px', borderBottom: 0, borderTop: '0.5 solid lightgrey' }} />
      <div className="help-message">
        Unsure if that email address was correct?
        <a href="mailto:partnersupport@gooten.com">We can help.</a>
      </div>
    </div>
    <style jsx>
      {`
        .title {
          font-family: Avenir-Heavy;
          font-weight: 800;
          font-size: 25px;
          text-align: center;
          display: block;
        }
        .content {
          font-family: Avenir-Roman;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          color: #000;
        }
        .suc-image-container {
          width: 100%;
          text-align: center;
        }
        .email-success-image {
          width: 55%;
          margin: auto;
        }
        .suc-image-container + hr {
          width: calc(100% + 60px);
          margin-left: -30px;
        }
        @media (max-width: 992px) {
          .email-success-image {
            width: 55%;
          }
        }
        @media (max-width: 768px) {
          .email-success-image {
            width: 70%;
          }
        }
        .help-message {
          font-family: Avenir-Roman;
          font-weight: 500;
          font-size: 18px;
          text-align: center;
        }
        .help-message > a {
          color: #000;
          text-decoration: none;
          position: relative;
        }
        .help-message > a:after {
          content: '';
          color: #000;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 1px;
          border-width: 0 0 1px;
          border-style: solid;
        }
      `}
    </style>
  </div>
);

ResetPassword.propTypes = {
  passwordInvitationSent: PropTypes.func.isRequired,
  wrongToken: PropTypes.bool
};
