import { connect } from 'react-redux';
import PastOrders from './PastOrders';
import {
  isOrderItemsLoadedSelector,
  orderItemsQuerySelector,
  orderItemsSelector,
  outOfStockItemsSelector,
  selectedItemsSelector,
  currentPageSelector,
  totalPagesSelector,
  totalSelector
} from './PastOrdersSelectors';
import {
  orderItemsLoad,
  toggleOrderItemSelected,
  deselectAll,
  setCurrentPage
} from './PastOrdersActions';
import { editDesign } from '../../../EditDesignView/EditDesignViewActions';
import { mapEditDesignItemFromOrderItem } from '../../../../models/EditDesignItem';
import analyticsService from 'gooten-components/src/services/analyticsService';
import withRouteStep from 'gooten-components/src/hocs/withRouteStep';

const mapDispatchToProps = {
  loadOrderItems: query => orderItemsLoad(query),
  onOrderItemSelectionToggled: (orderItem, isChecked) => {
    if (isChecked) {
      analyticsService.track('COF - Past Orders Flow', 'Order Item Selected', 'COF');
    }
    return toggleOrderItemSelected(orderItem, isChecked);
  },
  deselectAll: () => deselectAll(),
  setCurrentPage: page => setCurrentPage(page),
  editDesign: orderItem => {
    analyticsService.track('COF - Past Orders Flow', 'Order Item Edit Design Clicked', 'COF');
    return editDesign(mapEditDesignItemFromOrderItem(orderItem));
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    isOrderItemsLoaded: isOrderItemsLoadedSelector(state),
    orderItemsQuery: orderItemsQuerySelector(state),
    orderItems: orderItemsSelector(state),
    outOfStockItems: outOfStockItemsSelector(state),
    selectedItems: selectedItemsSelector(state),
    currentPage: currentPageSelector(state),
    totalPages: totalPagesSelector(state),
    total: totalSelector(state)
  };
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(PastOrders),
  'products-selection'
);
