import { createSelector } from 'reselect';
import { productsStateSelector, showSampleOrderModalSelector } from '../ProductsSelectors';
import storageService from 'gooten-components/src/services/storageService';

// at this moment it's only for embroidery...
// if needed later, add logic and expand this to other products
export const sampleOrderModalTitleSelector = () => 'Embroidery Sample';
export const sampleOrderModalTextSelector = () =>
  'We recommend ordering embroidery samples to ensure the final product meets your expectations.';

export const createTaskSelector = createSelector([productsStateSelector], productsState =>
  productsState
    .get('tasks')
    .sort((a, b) => b.get('id') - a.get('id'))
    .find(t => t.get('publishType') === 'create' && t.get('status') === 'success')
);

export const showSelector = createSelector(
  [createTaskSelector, showSampleOrderModalSelector],
  (createTask, showSampleOrderModal) => {
    // for now only embroidery products will use sample order...
    // add logic to cover other products, when needed...
    return (
      createTask?.get('isEmbroidery') &&
      !storageService.getItem('hide_sample_order_modal') &&
      showSampleOrderModal
    );
  }
);

export const sampleOrderProductIdSelector = createSelector([createTaskSelector], createTask =>
  createTask?.getIn(['results', 'tasks', 0, 'published_product', 'id'])
);
