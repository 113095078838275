import orientationService from './orientationService';

class OptionsService {
  isValueSelected(optionId, valueId, selectedOptions) {
    return !!(
      selectedOptions &&
      selectedOptions[optionId] &&
      selectedOptions[optionId].includes(valueId)
    );
  }

  isValueDisabled(optionId, valueId, selectedOptions, allOptions, availableSkus) {
    // If either selectedOptions, allOptions or availableSkus are undefined, do not apply filtering
    if (!(selectedOptions && allOptions && availableSkus)) return false;

    if (Object.keys(selectedOptions).length === 0) {
      return false;
    }

    // Orientation options can't be disabled
    if (optionId === 'orientation') {
      return false;
    }

    let skus = this.producesExistingSkus(
      optionId,
      valueId,
      selectedOptions,
      allOptions,
      availableSkus
    );
    let result = !skus;

    // check for Orientation (virtual option) filter
    // if option is already disabled, no need to check for Orientation match
    // only Square orientation value filters options
    if (
      !result &&
      selectedOptions.orientation &&
      selectedOptions.orientation.length === 1 &&
      selectedOptions.orientation[0] === 'square'
    ) {
      // filter square options only for Size option
      const sizeOption = allOptions.find(o => o.title.toLowerCase().indexOf('size') !== -1);
      const oId = sizeOption && sizeOption.id;
      if (oId === optionId && skus) {
        result = !orientationService.isSquareSize(skus.sku);
      }
    }
    return result;
  }

  producesExistingSkus(optionId, valueId, selectedOptions, allOptions, availableSkus) {
    // If either selectedOptions, allOptions or availableSkus are undefined, do not apply filtering
    if (!(selectedOptions && allOptions && availableSkus)) return false;

    let { ...otherSelectedOptions } = selectedOptions;

    valueId ? (otherSelectedOptions[optionId] = [valueId]) : delete otherSelectedOptions[optionId];

    let result = availableSkus.find(sku => {
      let foundOptionId = false;
      let passesFilter = allOptions.every(o => {
        // Loop through all available options, not just selected ones
        if (foundOptionId) return true;

        if (o.id === optionId) {
          foundOptionId = true; // Skip all iterations starting from the next one
        }
        if (!otherSelectedOptions.hasOwnProperty(o.id)) {
          return true; // Ignore options above current option that the user has not selected
        }
        return sku.hasOwnProperty(o.id) && otherSelectedOptions[o.id].indexOf(sku[o.id]) !== -1;
      });
      return foundOptionId && passesFilter;
    });

    return result;
  }

  getSelectedDisabledValues(options, selectedOptions, allOptions, availableSkus) {
    let result = { valueIds: [] };
    let foundSomeValues = false;

    options.some(option => {
      if (selectedOptions[option.id]) {
        option.values.forEach(value => {
          if (
            this.isValueDisabled(option.id, value.id, selectedOptions, allOptions, availableSkus) &&
            this.isValueSelected(option.id, value.id, selectedOptions)
          ) {
            result.valueIds.push(value.id);
            foundSomeValues = true;
          }
        });
      }

      if (foundSomeValues) {
        result['optionId'] = option.id;
      }
      // We need to go level by level
      // Unselect values in one Option, redraw and do a new check for next hierarchy level
      // Repeat until redraw is not triggered anymore (no values change)
      return foundSomeValues; // breaks outer loop if true
    });

    return result;
  }
}

// singleton
export default new OptionsService();
