import { connect } from 'react-redux';
import UploadFromUrl from './UploadFromUrl';
import { fetchAsync } from '../../../../../../store/actions/dataActions';
import { IMAGE_URL_UPLOAD } from '../../../ImageSelectionModalActions';

const mapDispatchToProps = {
  uploadFromUrls: urls => fetchAsync(IMAGE_URL_UPLOAD, { urls })
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFromUrl);
