import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../../constants';

const EmbroideryNoArtwork = ({ onClick }) => {
  return (
    <React.Fragment>
      <div className="embroidery-no-artwork">
        <div className="text-content">
          <h2>No artwork files that satisfy requirements</h2>
          <span className="body-text-1">
            Upload artwork that meets the requirements for this product
          </span>
        </div>
        <div className="no-image" />
      </div>
      <style jsx>{`
        .embroidery-no-artwork {
          display: flex;
          border-radius: 4px;
          background: ${COLORS.neutralLight300};
          margin: 0 20px 20px 20px;
          justify-content: space-between;
        }

        .text-content {
          display: flex;
          flex-direction: column;
          padding: 32px;
          width: 60%;
        }

        h2 {
          font-size: 31px;
          margin-top: 0px;
          margin-bottom: 14px;
          color: ${COLORS.neutralDark600};
        }

        .no-image {
          margin-right: 32px;
          height: 296px;
          width: 309px;
          background: url('https://adminassets.azureedge.net/admin-assets/img/embroidery-no-image.png')
            center center / contain no-repeat;
        }
      `}</style>
    </React.Fragment>
  );
};

EmbroideryNoArtwork.propTypes = {
  onClick: PropTypes.func
};

export default EmbroideryNoArtwork;
