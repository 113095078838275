import React from 'react';
import PropTypes from 'prop-types';
import ProductSection from '../../../shared/ProductSection';
import Options from './Options';

import './OptionsPanel.scss';

class OptionsPanel extends React.Component {
  static propTypes = {
    minCost: PropTypes.number,
    maxCost: PropTypes.number,
    showErrors: PropTypes.bool,
    resetErrors: PropTypes.func
  };

  render() {
    return (
      <div className="options-panel-container">
        <ProductSection minPrice={this.props.minCost} maxPrice={this.props.maxCost} />
        <h4 className="options-title">Select the options you wish to sell.</h4>
        <hr />
        <Options resetErrors={this.props.resetErrors} showErrors={this.props.showErrors} />
      </div>
    );
  }
}

export default OptionsPanel;
