import { connect } from 'react-redux';
import ProvidersSelectionPanel from './ProvidersSelectionPanel';
import { availableProvidersSelector, isDisabledSelector } from './ProvidersSelectionPanelSelectors';
import { changeProviderState } from './ProvidersSelectionPanelActions';

const mapDispatchToProps = {
  changeProviderState: provider => changeProviderState(provider)
};

const mapStateToProps = state => {
  return {
    availableProviders: availableProvidersSelector(state),
    isDisabled: isDisabledSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersSelectionPanel);
