import { useState, useEffect } from 'react';
import throttle from '../../utils/throttle';

export const windowWidth = () => {
  const [windowWidth, storeWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleOnResize);
    handleOnResize();

    return () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);

  const handleOnResize = throttle(() => {
    storeWindowWidth(window.innerWidth);
  }, 300);

  return windowWidth;
};
