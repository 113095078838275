import { Record, List } from 'immutable';

// Hold data paged collections
const PagedCollection = new Record({
  items: new List(),
  totalPages: 0,
  total: 0
});

export default PagedCollection;
