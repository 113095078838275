import {
  getAuthQueryParams,
  getImageProcessUrl,
  getImgManipReq
} from '../components/ProductPreview/services/productPreviewService';
import Config from '../config';
import { postJson } from '../utils/http';

const getExternalMockupGenerateUrl = () => `${Config.get('externalMockupApi')}generate`;
const functionKey = () =>
  Config.get('env') === 'staging'
    ? 'ymijdUZYsed9olHu-qkw0Zu2PoJ-RuPEgz0n4oZe6LHbAzFuk_qB4A=='
    : '06ouSkUaB7ztJntPNuOR-1VT64lCAe78MNiM9GtIqBFgAzFuGnFkIA==';

export const getMockupOrientation = mockup => {
  const layerCrop = mockup?.Layers?.[0]?.Crop;
  return layerCrop && layerCrop.Height > layerCrop.Width
    ? 'portrait'
    : layerCrop.Height == layerCrop.Width
    ? 'square'
    : 'landscape';
};

export const doesOrientationMatch = (mockup, orientations) => {
  const mockupOrientation = getMockupOrientation(mockup);

  return (
    !orientations || orientations.includes(mockupOrientation) || mockupOrientation === 'square'
  );
};

// external mockup api requires the image to be rotated before sending it.
// if orientation has been changed...
export const getSmallPrintImages = async (sku, rotateAngle) => {
  if (rotateAngle == null) {
    return sku.spaces.map(space => space.smallPrintImgUrl);
  }

  try {
    const promises = sku.spaces.map(space => {
      const imgManipReq = getImgManipReq('print-small', space.smallPrintImgManipCmd, 90);

      return postJson(getImageProcessUrl(), imgManipReq, getAuthQueryParams()).then(res => {
        if (!res.url) {
          throw new Error('Small print image for external mockups was not generated');
        }
        return res.url;
      });
    });

    const urls = await Promise.all(promises);
    return urls;
  } catch (error) {
    throw error;
  }
};

const getCropCommand = crop =>
  crop !== undefined
    ? {
        X: crop.X,
        Y: crop.Y,
        Width: crop.Width,
        Height: crop.Height
      }
    : null;

const getExternalMockupGenerateBody = (mockup, sku, smallPrintImages) => {
  let body =
    sku.spaces?.length > 1
      ? [
          {
            ID: mockup.ID,
            Layers: sku.spaces.map((space, index) => {
              return {
                ID: mockup.Layers[index].ID,
                ImageUrl:
                  index < smallPrintImages?.length
                    ? smallPrintImages[index]
                    : space.smallPrintImgUrl,
                Crop: getCropCommand(mockup.Layers[index].Crop),
                Color: mockup.Layers[index].Color
              };
            })
          }
        ]
      : [
          {
            ID: mockup.ID,
            Layers: mockup.Layers.map((layer, index) => {
              return {
                ID: layer.ID,
                ImageUrl:
                  index < smallPrintImages?.length
                    ? smallPrintImages[index]
                    : sku.spaces[0].smallPrintImgUrl,
                Crop: getCropCommand(layer.Crop),
                Color: layer.Color
              };
            })
          }
        ];

  return body;
};

const getAuthorizationQueryParams = (productId, sku) => ({
  queryParams: {
    partnerId: Config.get('partnerId'),
    productId,
    recipeId: Config.get('recipeId'),
    apiKey: Config.get('storeApiKey'),
    isStaging: Config.get('env') === 'staging',
    source: 'hub',
    sku: sku.sku,
    code: functionKey()
  }
});

class ExternalMockupsService {
  generate(mockup, sku, productId, smallPrintImages) {
    return postJson(
      getExternalMockupGenerateUrl(),
      getExternalMockupGenerateBody(mockup, sku, smallPrintImages),
      getAuthorizationQueryParams(productId, sku)
    );
  }
}

export default new ExternalMockupsService();
