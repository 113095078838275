import React from 'react';
import PropTypes from 'prop-types';
import ProChip from './TierChips/proChip.svg';
import PlusChip from './TierChips/plusChip.svg';
import EliteChip from './TierChips/eliteChip.svg';

export const PartnerTierChip = ({ id }) => {
  switch (id) {
    case 'plus':
      return <img src={PlusChip} alt={id + '_chip'} />;
    case 'pro':
      return <img src={ProChip} alt={id + '_chip'} />;
    case 'elite':
      return <img src={EliteChip} alt={id + '_chip'} />;
    default:
      return null;
  }
};

PartnerTierChip.propTypes = {
  id: PropTypes.string.isRequired
};
