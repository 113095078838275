import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Icon from 'gooten-components/src/components/shared/Icon';
import HoverImage from 'gooten-components/src/components/shared/HoverImage';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';

export const HubProductsListItem = props => {
  const { hubProduct, showSelector, showRemove, parentProductData } = props;

  const [showHoverImage, setShowHoverImage] = useState('');

  const handleSelect = () => {
    props.onHubProductSelectionToggled(
      {
        ...hubProduct,
        parentId: parentProductData.id,
        parentName: parentProductData.name,
        parentImage: parentProductData.image
      },
      !props.isSelected
    );
  };

  const variantName = variantData => {
    let concatinatedOptionValues = '';
    if (variantData.options && variantData.options.length) {
      for (let index = 0; index < variantData.options.length; index++) {
        const option = variantData.options[index];
        if (variantData.options.length === index + 1) {
          concatinatedOptionValues = concatinatedOptionValues + option.value;
        } else {
          concatinatedOptionValues = concatinatedOptionValues + option.value + ', ';
        }
      }
    }

    return concatinatedOptionValues || variantData.sku || 'Unknown Variant Name';
  };

  const variantPrice = variantData => {
    if (variantData.price && variantData.price.formatted_price) {
      return variantData.price.formatted_price;
    }
    return 'n/a';
  };

  return (
    <>
      <TableRow
        columns="2rem 6rem auto 8rem"
        size="medium"
        className="hub-products-list-item-container w-auto"
      >
        {showSelector && (
          <Cell className="checkbox-container p-0">
            {hubProduct.reorderable ? (
              <Checkbox checked={props.isSelected} onChange={handleSelect} />
            ) : (
              <Icon icon="alertCircle" fill="#F3C11A" />
            )}
          </Cell>
        )}
        <Cell className="pt-1 pb-1">
          <div
            onMouseOver={() => setShowHoverImage(true)}
            onMouseLeave={() => setShowHoverImage(false)}
            className="hub-product-list-thumbnail position-relative"
          >
            {showHoverImage && <HoverImage image={hubProduct.image_url} />}
            <Thumbnail className="medium" image={hubProduct.image_url} />
          </div>
        </Cell>
        <Cell className="hubproduct-details-wrapper d-flex justify-content-between pt-2 pb-2">
          <div className="hubproduct-variant-name">{variantName(hubProduct)}</div>
          <div className="hubproduct-price">{variantPrice(hubProduct)}</div>
        </Cell>
        <>
          {showRemove && (
            <Cell>
              <Button
                iconOnly
                className="button-default medium"
                onClick={() => props.removeVariant()}
              >
                <Icon icon="xCircle" />
              </Button>
            </Cell>
          )}
          {!showRemove && (
            <Cell>
              <Button
                disabled={!hubProduct.reorderable}
                className="button-default small hub-products-design-button"
                onClick={() => {
                  props.editDesign(hubProduct);
                }}
              >
                {hubProduct.reorderable ? 'Edit design' : 'Unavailable'}
              </Button>
            </Cell>
          )}
        </>
      </TableRow>
      <style jsx>
        {`
          .hub-product-list-thumbnail {
            width: 4rem;
            height: 4rem;
          }
          .hub-product-list-thumbnail :global(.cerulean-thumbnail) {
            position: absolute;
            top: 0;
          }
          :global(.hub-products-list-item-container) {
            margin-left: 6rem;
          }
          @media screen and (max-width: 768px) {
            :global(.hubproduct-details-wrapper) {
              flex-direction: column;
              justify-content: flex-start !important;
              align-items: flex-start !important;
            }
            :global(.hub-products-list-item-container) {
              grid-template-columns: 2rem 6rem auto 0 !important;
              margin-left: 3rem;
            }
            :global(.hub-products-design-button) {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

HubProductsListItem.propTypes = {
  hubProduct: PropTypes.object.isRequired,
  showSelector: PropTypes.bool,
  showRemove: PropTypes.bool,
  isSelected: PropTypes.bool,
  onHubProductSelectionToggled: PropTypes.func,
  editDesign: PropTypes.func,
  removeVariant: PropTypes.func,
  parentProductData: PropTypes.object
};

export default HubProductsListItem;
