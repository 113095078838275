import React from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import { parseCurrency } from './helpers';

const GootenCostsCard = ({ orderBilling }) => {
  return (
    <div className="card-container">
      <Card title="Gooten Costs">
        <div className="d-flex justify-content-between">
          <p>Sub Total</p>
          <div className="gooten-costs-subtotal d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioSubTotal'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Shipping</p>
          <div className="gooten-costs-shipping d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioShipping'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Tax</p>
          <div className="gooten-costs-tax d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioTax'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Discount</p>
          <div className="gooten-costs-discount d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioDiscount'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Total</p>
          <div className="gooten-costs-total d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioTotal'))}
          </div>
        </div>
        <hr />
        {orderBilling.get('PerVendorTotals') &&
          orderBilling
            .get('PerVendorTotals')
            .toJS()
            .map(vendorObject => (
              <div key={vendorObject.Vendor} className="d-flex justify-content-between">
                <p>{vendorObject.Vendor}</p>
                <div className="d-flex flex-wrap justify-content-end">
                  {parseCurrency(vendorObject.TotalCost)}
                </div>
              </div>
            ))}
      </Card>
    </div>
  );
};

GootenCostsCard.propTypes = {
  orderBilling: PropTypes.object
};

export default GootenCostsCard;
