import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import HubProductProductType from '../HubProductsList/HubProductType';
import HubProductsListItem from '../HubProducts/HubProductsList/HubProductsListItem/HubProductsListItem';
import Modal from 'gooten-components/src/components/shared/Modal';

export const VariantModal = props => {
  const { selectedItems } = props;

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [removedVariants, setRemovedVariants] = useState([]);

  const orderedTree = () => {
    let uniqueParents = {};
    selectedItems.toJS().forEach(item => {
      uniqueParents[item.parentId] = item;
    });

    const uniqueParentsArray = Object.values(uniqueParents);
    const updatedUnique = uniqueParentsArray.map(parent => {
      let updatedParent = {
        name: parent.parentName,
        id: parent.parentId,
        image: parent.parentImage,
        variants: []
      };
      updatedParent.variants = selectedItems
        .toJS()
        .filter(item => item.parentId === parent.parentId);
      return updatedParent;
    });
    setSelectedProducts(updatedUnique);
  };

  const preRemoveVariant = removedVariant => {
    let selectedProductsClone = [...selectedProducts];
    setRemovedVariants([...removedVariants, removedVariant]);

    selectedProductsClone.forEach((product, index) => {
      selectedProductsClone[index].variants = product.variants.filter(
        variant => variant.id !== removedVariant.id
      );
      if (product.variants.length === 0) {
        selectedProductsClone.splice(index, 1);
      }
    });
    setSelectedProducts(selectedProductsClone);
  };

  const preRemoveProduct = productId => {
    let removedItems = [];
    selectedItems.toJS().forEach(selected => {
      if (selected.parentId === productId) {
        removedItems.push(selected);
      }
    });

    const selectedProductsClone = [...selectedProducts];
    const filteredProduct = selectedProductsClone.filter(
      productObject => productObject.id !== productId
    );
    setRemovedVariants([...removedVariants, ...removedItems]);
    setSelectedProducts(filteredProduct);
  };

  useEffect(() => {
    orderedTree();
  }, []);

  return (
    <Modal
      style={{ top: '50%', left: '50%' }}
      isOpen
      title={'Variants selected'}
      primaryClick={() => {
        props.showSelected();
        props.removeSelections(removedVariants);
      }}
      actionText="Done"
      cancelClick={() => props.showSelected()}
      cancelText="Cancel"
    >
      <div className="hub-product-variant-modal-content pr-3 pl-3">
        {!selectedProducts.length && (
          <div
            className="p-4"
            style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}
          >
            There are no selected products
          </div>
        )}
        {selectedProducts.map(product => (
          <div className="hub-product-variant-container p-3" key={product.id}>
            <HubProductProductType
              key={product.id}
              showSelector={false}
              product={product}
              showRemove
              remove={() => preRemoveProduct(product.id)}
            />
            {product.variants.map(variant => (
              <HubProductsListItem
                key={variant.id}
                hubProduct={variant}
                showSelector={false}
                removeVariant={() => preRemoveVariant(variant)}
                showRemove
              />
            ))}
          </div>
        ))}
      </div>
      <style jsx>
        {`
          .hub-product-variant-container {
            border-bottom: 1px solid #ededed;
          }
          .hub-product-variant-container:last-child {
            border-bottom: none;
          }
          .hub-product-variant-modal-content :global(.hub-products-type-container) {
            grid-template-columns: 6rem auto 4rem;
          }
          .hub-product-variant-modal-content :global(.hub-products-list-item-container) {
            grid-template-columns: 6rem auto 4rem;
          }
        `}
      </style>
    </Modal>
  );
};

VariantModal.propTypes = {
  selectedItems: PropTypes.instanceOf(List),
  showSelected: PropTypes.func,
  removeSelections: PropTypes.func
};

export default VariantModal;
