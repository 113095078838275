import { connect } from 'react-redux';
import WooConnect from './WooConnect';
import { connectStore } from '../ConnectStoreViewActions';
import Config from '../../../config';

const mapDispatchToProps = {
  connectStore: domainName =>
    connectStore('woocommerce', { domainName: domainName, recipeId: Config.get('recipeId') })
};

export default connect(null, mapDispatchToProps)(WooConnect);
