import { createSelector } from 'reselect';
import { cofSelector } from '../../../shared/AddToCart/AddToCartSelectors';

const pastOrdersViewSelector = createSelector([cofSelector], cof => cof.get('pastOrdersView'));

const pastOrdersSelector = createSelector([pastOrdersViewSelector], pastOrdersView =>
  pastOrdersView.get('pastOrders')
);

export const selectedOrderItemsSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('selected')
);
