import { List, fromJS } from 'immutable';

class SKUsListService {
  /**
   * Returns suggested variants based on mapping variants
   * @param {*} variants
   * @param {*} mappings
   */
  filterSuggestedVariants(variants, mapping) {
    // return variants that have highest number of matched options
    const variantsWithMatches = variants
      .map(variant =>
        fromJS({
          variant,
          matches: mapping
            .get('variant')
            .get('options')
            .filter(
              a =>
                variant.get('options') &&
                variant
                  .get('options')
                  .find(b => b.get('value').toLowerCase().includes(a.get('value').toLowerCase()))
            ).size
        })
      )
      .sort((a, b) => b.get('matches') - a.get('matches'));

    const highestMatchNum = variantsWithMatches.getIn([0, 'matches']);
    return highestMatchNum && highestMatchNum > 0
      ? variantsWithMatches
          .filter(a => a.get('matches') === highestMatchNum)
          .map(a => a.get('variant'))
      : new List();
  }

  /**
   * Creates pages used in Pager
   * @param {*} numberOfSkus
   * @param {*} pageSize
   */
  createPages(numberOfSkus, pageSize) {
    const totalPageSize = Math.ceil(numberOfSkus / pageSize);
    return numberOfSkus && totalPageSize && numberOfSkus > pageSize
      ? Array.from(Array(totalPageSize).keys()).map(i => ({ name: i + 1, index: i + 1 }))
      : [];
  }

  /**
   * Filter visible variants in Saved Product flow
   * @param {*} variants
   * @param {*} options
   */
  filterVisibleVariants(variants, options, isSuggestedSection) {
    return variants.filter(sku => {
      let visible = true;
      options.forEach(o => {
        const filteredOptions = sku.get('options').filter(a => a.get('id') === o.get('id'));
        const selectedValues = o
          .get('selectedValues')
          .filter(v => isSuggestedSection === !!v.get('isSuggested'))
          .map(sv => sv.get('name'));

        if (
          filteredOptions.size &&
          selectedValues.size &&
          selectedValues.indexOf(filteredOptions.first().get('value')) < 0
        ) {
          visible = false;
        }
      });

      return visible;
    });
  }

  /**
   * Returns variants for selected page, based on flow selection
   * @param {*} suggestedMatches
   * @param {*} allVariants
   * @param {*} options
   * @param {*} page
   * @param {*} pageSize
   * @param {*} isNewProductFlow
   */
  pageVariants(suggestedMatches, allVariants, options, page, pageSize) {
    const visibleSuggestedMatches = this.filterVisibleVariants(suggestedMatches, options, true).map(
      item => item.set('sectionSuggested', true)
    );
    const visibleAllVariants = this.filterVisibleVariants(allVariants, options, false).map(item =>
      item.set('sectionAll', true)
    );
    const merged = visibleSuggestedMatches
      .concat(visibleAllVariants)
      .skip(pageSize * (page - 1))
      .take(pageSize);

    return merged;
  }

  preparedOptionsFromVariants(variants) {
    return variants.map(v => v.get('options')).reduce((a, c) => a.concat(c), new List());
  }

  /**
   * Returns values for option
   * @param {*} option
   * @param {*} variants
   */
  valuesForOption(option, preparedOptions, isSavedProductFlow = false) {
    // get all options from variants into single array
    const filteredVariantsOptions =
      // filter it to match selected option id
      preparedOptions.filter(o => o.get('id') === option.get('id'));

    return option
      .get('values')
      .filter(v =>
        filteredVariantsOptions
          .map(v => v.get('value'))
          .includes(isSavedProductFlow ? v : v.get('name'))
      )
      .map((v, i) => ({ key: i, label: isSavedProductFlow ? v : v.get('name') }))
      .toJS();
  }
}

export default new SKUsListService();
