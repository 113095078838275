import React from 'react';
import PropTypes from 'prop-types';
import SKUsListItem from './../SKUsListItem';
import { List } from 'immutable';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';

const SKUsList = props => {
  const columns = props.item.get('options').map(opt => (
    <Cell key={opt.name}>
      <b>{opt.name}</b>
    </Cell>
  ));

  const columnsLength = props.item
    .get('options')
    .map(o => {
      return 'minmax(6rem, 1fr)';
    })
    .join(' ')
    .toString();

  const clickSelectAll = () => {
    props.onSelectAll(filteredVariants().map(v => v.get('id')));
  };

  const handleEditItem = variantID => {
    props.onEditItem(props.item.get('id'), variantID);
  };

  const filteredVariants = () => {
    return (
      (props.item.get('variants') instanceof List &&
        props.item
          .get('variants')
          .filterNot(v => props.cartItems.includes(v.getIn(['gootenMapping', 'sku'])))) ||
      new List()
    );
  };

  const renderHead = () => {
    const selected =
      props.selectedVariantIDs.filter(e => filteredVariants().find(v => v.get('id') === e)).size ===
      filteredVariants().size;

    return filteredVariants().size ? (
      <TableRow className="skus-list-item" header columns={`8rem ${columnsLength} 8rem 5rem`}>
        <Cell className="">
          {filteredVariants().size > 1 && (
            <div className="selection-holder-inner head-left-content">
              <Checkbox
                checked={selected}
                className="pull-left"
                onChange={() => clickSelectAll()}
              />
            </div>
          )}
        </Cell>
        {columns}
        {filteredVariants().some(e => e.get('price')) ? (
          <Cell className="title-cost">
            <b>Cost</b>
          </Cell>
        ) : null}
        <Cell className="sticky-right">
          <div className="head-right-content" />
        </Cell>
      </TableRow>
    ) : null;
  };

  const renderBody = () => {
    return filteredVariants().map(v => (
      <SKUsListItem
        key={v.get('id')}
        item={v}
        selected={!!props.selectedVariantIDs.find(e => e === v.get('id'))}
        onSelectVariantID={props.onSelectVariantID}
        onEditItem={handleEditItem}
      />
    ));
  };

  return (
    <div className="skus-list">
      {filteredVariants().size ? (
        <>
          {renderHead()}
          {renderBody()}
        </>
      ) : (
        <div>All skus have been added to Cart. To remove or edit them, please proceed to Cart.</div>
      )}
    </div>
  );
};

SKUsList.propTypes = {
  item: PropTypes.object.isRequired,
  // eslint-disable-next-line
  onSelectVariantID: PropTypes.func.isRequired,
  // eslint-disable-next-line
  onSelectAll: PropTypes.func.isRequired,
  // eslint-disable-next-line
  selectedVariantIDs: PropTypes.instanceOf(List).isRequired,
  // eslint-disable-next-line
  onEditItem: PropTypes.func,
  // eslint-disable-next-line
  cartItems: PropTypes.instanceOf(List)
};

export default SKUsList;
