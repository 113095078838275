import EmbroideryDSTUpload from './EmbroideryDSTUpload';
import { connect } from 'react-redux';
import { IMAGE_FILE_UPLOAD } from '../../../../shared/ImageSelectionModal/ImageSelectionModalActions';
import { fetchAsync } from '../../../../../store/actions/dataActions';
import { requiredImageSizeSelector } from '../EmbroiderySelectors';

const mapDispatchToProps = {
  uploadFiles: files => fetchAsync(IMAGE_FILE_UPLOAD, { files })
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    requiredPNGSize: requiredImageSizeSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmbroideryDSTUpload);
