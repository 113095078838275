import ToolbarOptions from './toolbar.options';
import * as Buttons from './buttons';
import EventEmiter from 'events';

export class ToolbarService {
  constructor(stateManager: any, options: ToolbarOptions, publicEvents: EventEmiter) {
    let self = this;

    this.buttonTypes = {
      alignVertical: Buttons.default.AlignVerticalButton,
      alignHorizontal: Buttons.default.AlignHorizontalButton,
      crop: Buttons.default.CropButton,
      center: Buttons.default.CenterButton,
      rotate: Buttons.default.RotateButton,
      orientation: Buttons.default.OrientationButton,
      undo: Buttons.default.UndoButton,
      redo: Buttons.default.RedoButton,
      remove: Buttons.default.RemoveButton
    };
    this.buttons = [];

    options.buttons.forEach(btn => {
      self.buttons.push(new self.buttonTypes[btn.type](stateManager, btn, publicEvents));
    });
  }
}
