import { createSelector } from 'reselect';

const checkoutSelector = state => state.get('checkout');

const shippingSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('shipping');
});

const addressesObjectSelector = createSelector(shippingSelector, shipping => {
  return shipping.get('addresses');
});

export const userEnteredShippingAddressSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['userEntered', 'address']);
  }
);

export const isUserEnteredShippingAddressValidSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['userEntered', 'isValid']);
  }
);

export const suggestedShippingAddressSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['suggested', 'address']);
  }
);

export const isSuggestedShippingAddressValidSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['suggested', 'isValid']);
  }
);

export const addressValidationRequestSelector = createSelector(
  userEnteredShippingAddressSelector,
  userEnteredShippingAddress => {
    let address = {
      line1: userEnteredShippingAddress.get('addressLine1'),
      city: userEnteredShippingAddress.get('city'),
      state: userEnteredShippingAddress.get('state'),
      postalCode: userEnteredShippingAddress.get('zipCode'),
      countryCode: userEnteredShippingAddress.get('country')
    };
    if (userEnteredShippingAddress.get('addressLine2')) {
      address.line2 = userEnteredShippingAddress.get('addressLine2');
    }

    return address;
  }
);

export const tempAddressSelector = createSelector(addressesObjectSelector, addressesObject => {
  return addressesObject.getIn(['temp', 'address']);
});
