import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductVariantsItem from './ProductVariantsItem';
import OptionsModal from '../../../../shared/OptionsModal';
import MockupsFullscreen from '../../Mockups/MockupsFullscreen';
import { List, fromJS, Map } from 'immutable';
import { whiteSpaces } from './../../../../../utils/regex';
import ErrorMessage from '../ErrorMessage';
import Rules from '../../../ErrorsHandler/ValidationRules';
import Button from '../../../../shared/Button';
import Icon from '../../../../shared/Icon';
import Modal from '../../../../shared/Modal';
import { TableRow, Cell } from '../../../../shared/Table';
import Checkbox from '../../../../shared/Checkbox';
import Dialogue from '../../../../shared/Dialogue';
import DeleteSelection from '../DeleteSelection';
import Alert from '../../../../shared/Alert';

const ProductVariants = props => {
  const [error, setError] = useState(null);
  const [isMockupsModalOpen, setMockupsModalOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState({ show: false, pos: 0 });
  const [isDeleteDialogueVisible, showDeleteDialogue] = useState(false);
  const [currentFilter, setCurrentFilter] = useState();
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [skuMockupUrl, setSkuMockupUrl] = useState();
  const [selectedSkuIndex, setSelectedSkuIndex] = useState(-1);

  // local error messages
  const errors = {
    maxNumberOfSkus: `Max number of skus is ${props.maxNumberOfSkus}.`,
    emptySku: `SKU can't be empty.`,
    duplicatedSkus: 'SKU name duplicated. Please provide unique SKU names.'
  };

  const checkSkusValidation = () => {
    let err = Rules.productVariants.numberOfSkusExceeded(props.variants.size, props.maxNumberOfSkus)
      ? errors.maxNumberOfSkus
      : Rules.productVariants.anySkuEmpty(props.variants)
      ? errors.emptySku
      : props.forbidDuplicatedSkus
      ? Rules.productVariants.duplicatedSkus(props.variants)
        ? errors.duplicatedSkus
        : null
      : null;

    setError(err);
    props.setError(props.storage ? -1 : props.storeId, 'ProductVariants', err);
  };

  useEffect(() => {
    const errors =
      props.serverErrors.get('errors').size &&
      props.serverErrors
        .get('errors')
        .filter(err => err.get('storeId') === props.storeId && err.get('skuRelated'));

    if (errors && errors.size) {
      setError(
        errors
          .map(err => err.get('message'))
          .toJS()
          .toString()
      );
    } else {
      setError(null);
    }
  }, [props.serverErrors.toString()]);

  useEffect(() => {
    checkSkusValidation();
  }, [props.variants.toString()]);

  const handleSelectItemOption = (option, index) => {
    switch (option) {
      case 'renamesku':
        break;

      case 'changemockup':
        handleChangeMockup(index);
        break;
    }
  };

  const handleRenameSku = (index, value) => {
    props.renameSku(props.storage, props.storeId, index, value);
  };

  const handleCloseModal = () => {
    setMockupsModalOpen(false);
    setSelectedSkuIndex(-1);
    setSkuMockupUrl(null);
  };

  const handleSelectSkuMockupUrl = url => {
    setSkuMockupUrl(url);
  };

  const handleSaveSkuMockupUrl = () => {
    if (skuMockupUrl) {
      props.saveSkuMockup(props.storage, props.storeId, skuMockupUrl, selectedSkuIndex);
    }
    handleCloseModal();
  };

  const handleChangeMockup = index => {
    setSelectedSkuIndex(index);
    setMockupsModalOpen(true);
  };

  const handleDelete = () => {
    showDeleteDialogue(false);
    props.allVariantsSelected ? props.deleteProduct() : props.deleteVariants();
  };

  const mockups = () => {
    const index =
      selectedSkuIndex !== -1 && selectedSkuIndex !== undefined
        ? props.variants.findIndex(v => v.get('index') === selectedSkuIndex)
        : props.variants.findIndex(v => v.get('selected'));

    const selectedSkuMockupUrl = index !== -1 ? props.variants.getIn([index, 'previewUrl']) : '';

    return props.mockups.map(m =>
      fromJS({
        ...m.toJS(),
        main: selectedSkuMockupUrl === m.get('imageUrl')
      })
    );
  };

  // Filters
  const handleSelectFilterModalOption = option => {
    const selectedFilterOption = props.filterOptions
      .find(fOpt => fOpt.get('id') === currentFilter.get('id'))
      .get('values')
      .find(fil => fil.get('name').replace(whiteSpaces, '').toLowerCase() === option)
      .toJS();

    if (!selectedFilterOptions.find(option => option.id === selectedFilterOption.id)) {
      const filtered = selectedFilterOptions.filter(
        item => item.filterId !== currentFilter.get('id')
      );
      filtered.push({ ...selectedFilterOption, filterId: currentFilter.get('id') });
      setSelectedFilterOptions(filtered);
    }
  };

  const filterModalOptions = () => {
    return props.filterOptions
      .find(fOpt => fOpt.get('id') === currentFilter.get('id'))
      .get('values')
      .map(v => v.get('name'))
      .toJS();
  };

  const filters = props.filters.map((opt, i) => {
    const selectedFilterOption = selectedFilterOptions.find(f => f.filterId === opt.get('id'));
    const title = selectedFilterOption ? selectedFilterOption.name : opt.get('title');

    return (
      <div className="filter-options-holder flex-container" key={'filter-options' + i}>
        <Button
          className={
            selectedFilterOption ? 'button-primary small mr-2' : 'button-default small mr-2'
          }
          onClick={() => {
            if (selectedFilterOption) {
              const filtered = selectedFilterOptions.filter(
                item => item.filterId !== opt.get('id')
              );
              setSelectedFilterOptions(filtered);
            } else {
              setCurrentFilter(opt);
              setShowFilterModal({ show: true, pos: i });
            }
          }}
        >
          {title.toUpperCase()} <Icon small icon="chevronDown" className="ml-3 mr-n1" />
        </Button>
        {showFilterModal.show && showFilterModal.pos === i ? (
          <div>
            <OptionsModal
              items={filterModalOptions()}
              onClickItem={handleSelectFilterModalOption}
              onDismiss={() => setShowFilterModal({ show: false, pos: i })}
            />
          </div>
        ) : null}
      </div>
    );
  });

  const renderSearchAndFilters = () => (
    <div className="search-and-filters flex-container mt-3 mb-3">
      <span className="filters button-2 mr-3">FILTERS</span>
      {filters}
    </div>
  );

  const renderSelectionBox = () => (
    <Cell>
      <Checkbox
        checked={props.anyVariantSelected || props.allVariantsSelected}
        partial={props.anyVariantSelected && !props.allVariantsSelected}
        onChange={() => props.selectAllVariants(!props.allVariantsSelected)}
      />
    </Cell>
  );

  const renderHead = () => (
    <TableRow
      header
      columns={`${!props.disableRemovingSkus ? '2.5rem 4.5rem' : '4.5rem'} auto ${
        !!props.skusWithNeckTag.size ? '8rem' : ''
      }  4rem`}
    >
      {props.disableRemovingSkus ? null : renderSelectionBox()}
      <Cell />
      <Cell>
        <span className="header-title">SKU Name</span>
      </Cell>
      {!!props.skusWithNeckTag.size && props.currentNeckLabel ? (
        <Cell>
          <span className="header-title">Neck Label</span>
        </Cell>
      ) : null}
    </TableRow>
  );

  const renderBody = () => {
    return props.variants
      .filter(
        v =>
          !selectedFilterOptions.length ||
          selectedFilterOptions
            .map(sv => sv.id)
            .every(sv =>
              v
                .get('options')
                .map(op => op.get('value'))
                .includes(sv)
            )
      )
      .map((v, i) => {
        const disabledSku = props.disabledSkus.find(ds => ds.get('sku') === v.get('sku'));
        const neckTagAvailable = props.skusWithNeckTag.find(n => n.sku === v.get('sku'));
        const neckTagUrl = props.variantsInPublish?.find(
          vip => vip.sku === v.get('sku')
        )?.neckTagUrl;
        const updatedVariant = v.set(
          'previewUrl',
          disabledSku ? disabledSku.get('previewUrl') : v.get('previewUrl')
        );
        return (
          <ProductVariantsItem
            variant={updatedVariant}
            key={updatedVariant.get('sku') + i}
            disabled={!!disabledSku}
            disabledReason={
              updatedVariant.get('archivedReason') || disabledSku?.get('archivedReason') || 0
            }
            handleSelectItem={() => props.selectVariant(updatedVariant.get('sku'))}
            handleSelectOption={handleSelectItemOption}
            handleRenameSku={handleRenameSku}
            index={updatedVariant.get('index')}
            isLoading={props.isLoading}
            disableRenamingSkus={props.disableRenamingSkus}
            disableChangeMockup={props.disableChangeMockup || !props.mockups.size}
            disableRemovingSkus={props.disableRemovingSkus}
            neckTagAvailable={!!neckTagAvailable}
            neckTagUrl={neckTagUrl}
            currentNeckLabel={props.currentNeckLabel}
            productSupportsNeckLabel={!!props.skusWithNeckTag.size}
          />
        );
      });
  };

  return (
    <>
      <div
        className={`product-publish-variants pt-4 pb-4 mb-2 ${
          !props.filters.size ? 'mt-0' : 'mt-5'
        }`}
      >
        <div className={`d-flex ${props.isEditMode || 'flex-column'}`}>
          <span className={`variants-title title-2  ${!props.isEditMode || 'mb-3'}`}>Variants</span>
          {props.filters.size ? renderSearchAndFilters() : null}
          {props.isEditMode && !props.isMultiproduct && !props.isDuplicateMode ? (
            <div className="d-flex justify-content-end w-100">
              <Button className="button-default medium" onClick={() => props.addVariant()}>
                Edit Variants
              </Button>
            </div>
          ) : null}
        </div>
        <div className="variants-table">
          {props.forbidDuplicatedSkus ? (
            Rules.productVariants.duplicatedSkus(props.variants) ? (
              <Alert isOpen type="important">
                {errors.duplicatedSkus}
              </Alert>
            ) : null
          ) : null}
          {renderHead()}
          {renderBody()}
        </div>

        {error ? <ErrorMessage message={error} /> : null}
        <Modal
          isOpen={isMockupsModalOpen}
          cancelClick={() => handleCloseModal()}
          primaryClick={handleSaveSkuMockupUrl}
          actionText="Save"
          title="Select SKU mockup"
        >
          <MockupsFullscreen
            mockups={mockups()}
            mainMockupUrl={skuMockupUrl}
            onSelectItem={handleSelectSkuMockupUrl}
          />
        </Modal>

        {props.anyVariantSelected ? (
          <DeleteSelection
            handleClick={() => showDeleteDialogue(true)}
            numberOfSelectedItems={props.variants.filter(v => !!v.get('selected')).size}
            handleClose={() => props.selectAllVariants(false)}
          />
        ) : null}

        <Dialogue
          isOpen={isDeleteDialogueVisible}
          cancelClick={() => showDeleteDialogue(false)}
          destructiveClick={handleDelete}
          actionText={props.allVariantsSelected ? 'Delete product' : 'Delete variants'}
          title={
            props.allVariantsSelected
              ? 'Permanently delete product?'
              : 'Permanently delete variants?'
          }
        >
          <span className="body-text-2">
            {props.allVariantsSelected
              ? 'The product and all its variants will be deleted from Gooten and connected sales channels'
              : 'Variants will be deleted from Gooten and all connected sales channels'}
          </span>
        </Dialogue>
      </div>
      <style jsx>{`
        product-publish-variants,
        :global(.alert-body) {
          font-size: 13px !important;
          line-height: 200% !important;
        }
      `}</style>
    </>
  );
};

ProductVariants.propTypes = {
  variants: PropTypes.instanceOf(List).isRequired,
  disabledSkus: PropTypes.instanceOf(List).isRequired,
  filters: PropTypes.instanceOf(List).isRequired,
  filterOptions: PropTypes.instanceOf(List).isRequired,
  mockups: PropTypes.instanceOf(List).isRequired,
  // eslint-disable-next-line
  anyVariantSelected: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  allVariantsSelected: PropTypes.bool.isRequired,
  storeId: PropTypes.number,
  selectVariant: PropTypes.func.isRequired,
  selectAllVariants: PropTypes.func.isRequired,
  renameSku: PropTypes.func.isRequired,
  saveSkuMockup: PropTypes.func.isRequired,
  // eslint-disable-next-line
  storage: PropTypes.bool,
  setError: PropTypes.func,
  serverErrors: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  // validation below
  maxNumberOfSkus: PropTypes.number,
  disableChangeMockup: PropTypes.bool,
  disableRemovingSkus: PropTypes.bool,
  disableRenamingSkus: PropTypes.bool,
  forbidDuplicatedSkus: PropTypes.bool,
  deleteVariants: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  addVariant: PropTypes.func.isRequired,
  isMultiproduct: PropTypes.bool,
  isDuplicateMode: PropTypes.bool,
  skusWithNeckTag: PropTypes.instanceOf(List),
  variantsInPublish: PropTypes.instanceOf(List),
  currentNeckLabel: PropTypes.object
};

export default ProductVariants;
