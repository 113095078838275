import Config from '../config';
import { fetch, postJson, $delete } from '../utils/http';

const getNeckLabelsApiUrl = () =>
  `${Config.get('api')}RecipeAddons?recipeid=${Config.get('recipeId')}&addonsType=0`;

const mapTo = model => {
  const dto = {
    Name: model.name,
    Type: 0,
    SettingsJSON: JSON.stringify({
      image_url: model.imgUrl,
      height: model.height,
      width: model.width
    })
  };
  if (model.id) {
    dto.Id = model.id;
  }
  return dto;
};

const mapFrom = model => {
  const modelSettings = JSON.parse(model.SettingsJSON);
  const dto = {
    id: model.Id,
    name: model.Name,
    type: 0,
    imgUrl: modelSettings.image_url,
    height: modelSettings.height,
    width: modelSettings.width
  };
  return dto;
};

// Also useed for updating neckTag.
export const createNeckTag = item =>
  new Promise((resolve, reject) =>
    postJson(`${getNeckLabelsApiUrl()}`, mapTo(item))
      .then(res => {
        if (!res.error) {
          resolve(mapFrom(res));
        } else {
          reject(res);
        }
      })
      .catch(err => reject(err))
  );

export const fetchNeckTags = () =>
  new Promise((resolve, reject) =>
    fetch(`${getNeckLabelsApiUrl()}`)
      .then(res => {
        if (!res.error) {
          resolve({ Records: res.map(i => mapFrom(i)) });
        } else {
          reject(res);
        }
      })
      .catch(err => reject(err))
  );

export const removeNeckTag = itemId =>
  new Promise((resolve, reject) =>
    $delete(`${getNeckLabelsApiUrl()}&addonId=${itemId}`)
      .then(res => resolve(res))
      .catch(err => reject(err))
  );
