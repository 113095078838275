import { Record } from 'immutable';

// Hold data for 1 currency
const Currency = new Record({
  name: '',
  code: '',
  format: ''
});

export default Currency;

export const mapCurrency = obj =>
  new Currency({
    name: obj.Name,
    code: obj.Code,
    format: obj.Format
  });
