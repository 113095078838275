import { fromJS } from 'immutable';

import {
  RESET,
  STORES_CONNECT_PROVIDER,
  STORES_CONNECT_STEP,
  STORE_CONNECT,
  STORE_LOG_OUT,
  STORE_DISCONNECT_PROMPT,
  STORE_DISCONNECT_CANCEL
} from './StoresActions';

// ------------------------------------
// Reducer
// ------------------------------------
const storesDefaultState = fromJS({
  step: 0,
  provider: null,
  busy: false,
  errorMsg: ''
});

const storesReducer = (state = storesDefaultState, action) => {
  switch (action.type) {
    case RESET:
      return storesDefaultState;

    case STORES_CONNECT_PROVIDER:
      return state.set('provider', action.payload.provider);

    case STORES_CONNECT_STEP:
      return state.set('errorMsg', '').set('successMsg', '').set('step', action.payload.step);

    case STORE_CONNECT.ASYNC:
      return state
        .set('provider', action.payload.provider)
        .set('busy', true)
        .set('errorMsg', '')
        .set('successMsg', '');

    case STORE_CONNECT.FAIL:
      return state
        .set('busy', false)
        .set('errorMsg', action.payload.errorMsg)
        .set('successMsg', '');

    case STORE_CONNECT.SUCCESS:
      return state
        .set('step', 0)
        .set('busy', false)
        .set('errorMsg', '')
        .set(
          'successMsg',
          'Your store is now connected. Continue by syncing your products for fulfillment.'
        );

    case STORE_CONNECT.CANCEL:
      return state.set('step', 0).set('busy', false).set('errorMsg', '').set('successMsg', '');

    case STORE_DISCONNECT_PROMPT:
      return state.set('disconnect', fromJS(action.payload));

    case STORE_DISCONNECT_CANCEL:
      return state.set('disconnect', fromJS({}));

    case STORE_LOG_OUT.ASYNC:
      return state.set('busy', true).set('disconnect', fromJS({})).set('successMsg', '');

    case STORE_LOG_OUT.SUCCESS:
      return state.set('busy', false).set('successMsg', 'Your store is now disconnected.');

    case STORE_LOG_OUT.FAIL:
      return state
        .set('busy', false)
        .set('errorMsg', action.payload.errorMsg)
        .set('successMsg', '');

    default:
      return state;
  }
};

export default storesReducer;
