import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ApiKeys from '../Cards/ApiKeys';
import Webhooks from '../Cards/Webhooks';

const Container = styled.div``;
class ApiView extends React.Component {
  render() {
    return (
      <Container>
        <ApiKeys />
        <Webhooks />
      </Container>
    );
  }
}

ApiView.propTypes = {
  // eslint-disable-next-line
  router: PropTypes.object.isRequired
};

export default ApiView;
