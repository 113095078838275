import { connect } from 'react-redux';
import AddToCart from './../../../../CustomOrderForm/shared/AddToCart';
import { selectedVariantsSelector } from './../../PlaceOrderViewSelectors';
import {
  isLoadingSelector,
  isFailedSelector,
  isCartEmptySelector
} from './../../../../CustomOrderForm/shared/AddToCart/AddToCartSelectors';
import {
  addToCartRequestAsync,
  updateSource
} from './../../../../CustomOrderForm/CartView/CartViewActions';
import { mapAddToCartRequestFromHubProducts } from './../../../../models/AddToCartRequest';
import analyticsService from 'gooten-components/src/services/analyticsService';
import { deselectAll } from '../../PlaceOrderViewActions';

const source = 'Hub - Place an Order';

const mapDispatchToProps = (dispatch, ownProps) => ({
  addToCart: hubProducts => {
    analyticsService.track(source, 'Add to Cart', 'Hub');
    hubProducts.forEach(hp => {
      const productAddObj = {
        product_sku: hp.getIn(['gootenMapping', 'items', 0, 'sku']),
        product_name: hp.productName,
        product_price: hp.price
      };
      // This is to maintain old COF events
      analyticsService._track(source, 'Product added to Cart', null, null, productAddObj);
    });
    dispatch(addToCartRequestAsync(mapAddToCartRequestFromHubProducts(hubProducts), source));
    dispatch(deselectAll());
  },
  onProceedToCart: () => {
    dispatch(updateSource(source));
    analyticsService.track(source, 'Proceed to Cart', 'Hub');
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    selectedItems: selectedVariantsSelector(state),
    loading: isLoadingSelector(state),
    failed: isFailedSelector(state),
    cartEmpty: isCartEmptySelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
