import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GootenCostsCard from './components/GootenCostsCard';
import PartnerCostsCard from './components/PartnerCostsCard';
import CustomerCostsCard from './components/CustomerCostsCard';
import GootenProfitCard from './components/GootenProfitCard';
import PartnerProfitCard from './components/PartnerProfitCard';
import { List } from 'immutable';
import OrderItemTable from './components/OrderItemTable';
import SecurityService from '../../../Settings/SecurityService';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Alert from 'gooten-components/src/components/shared/Alert';
import Button from 'gooten-components/src/components/shared/Button';

const BillingView = ({
  params,
  fetchBillingDataAsync,
  costBreakdown,
  orderBilling,
  showCustomerCosts,
  billingLoading,
  billingErrors
}) => {
  useEffect(() => {
    fetchBillingDataAsync({ orderId: params.id });
  }, []);

  return (
    <>
      <div className="order-page-content mt-4">
        {billingLoading && <LoadingSpinner />}
        {!!billingErrors.length &&
          billingErrors.map(error => (
            <Alert key={error} isOpen type="important">
              {error}
            </Alert>
          ))}
        {!billingLoading && !billingErrors.length && (
          <>
            <div className="billing-cards-grid">
              {SecurityService.isSuperAdmin() && <GootenCostsCard orderBilling={orderBilling} />}

              <PartnerCostsCard orderBilling={orderBilling} />

              {showCustomerCosts && <CustomerCostsCard orderBilling={orderBilling} />}

              {SecurityService.isSuperAdmin() && <GootenProfitCard orderBilling={orderBilling} />}

              {showCustomerCosts && <PartnerProfitCard orderBilling={orderBilling} />}
            </div>
            {!!SecurityService.isAdmin() && (
              <>
                <div className="download-wrapper d-flex justify-content-end">
                  <a
                    className="download-csv"
                    href={`/admin/Orders/ExportBilling?orderId=${params.id}`}
                    target="_blank"
                  >
                    <Button className="button-default large">Download as CSV </Button>
                  </a>
                </div>
                <div className="title-2 mb-4">Order Item Costs Breakdown</div>
                <OrderItemTable
                  orderBilling={orderBilling.toJS()}
                  costBreakdown={costBreakdown.toJS()}
                  showCustomerCosts={showCustomerCosts}
                />
              </>
            )}
          </>
        )}
      </div>

      <style jsx>
        {`
          :global(.card) {
            width: 100% !important;
            min-height: 364px !important;
          }
          .billing-cards-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
            row-gap: 1rem;
            column-gap: 1rem;
            padding: 0.5rem 0 2rem;
          }
        `}
      </style>
    </>
  );
};

BillingView.propTypes = {
  params: PropTypes.object.isRequired,
  costBreakdown: PropTypes.instanceOf(List),
  orderBilling: PropTypes.object,
  showCustomerCosts: PropTypes.bool,
  fetchBillingDataAsync: PropTypes.func.isRequired,
  billingLoading: PropTypes.bool,
  billingErrors: PropTypes.array
};

export default BillingView;
