import { connect } from 'react-redux';
import Design from './Design';
import { designsSelector } from './DesignSelectors';
import { gotoDesignPage } from './DesignActions';

const mapDispatchToProps = {
  gotoImageUpload: () => gotoDesignPage()
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    designs: designsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Design);
