import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';
import {
  imgSelectionModalState,
  currentStateSelector,
  selectedSpaceNameInEditorSelector
} from '../../ImageSelectionModalSelectors';
import { removeDuplicatesBy } from '../../../../../utils/array';
import { dataSelector } from '../../../../../store/selectors/productDataSelectors';
import {
  isEmbroiderySelected,
  requiredImageSizeSelector
} from '../../../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';
import { whiteSpaces } from '../../../../../utils/regex';

const myArtworkSelector = createSelector([imgSelectionModalState], imgSelection =>
  imgSelection.get('myArtwork')
);

export const loadingImagesCountSelector = createSelector([myArtworkSelector], myArtwork =>
  myArtwork.get('loadingImagesCount')
);

export const filterSelector = createSelector(
  [myArtworkSelector, isEmbroiderySelected, requiredImageSizeSelector],
  (myArtwork, isEmbroidery, embroideryRequiredImageSize) => {
    const sizesString = embroideryRequiredImageSize
      .map(size => `${size.width}x${size.height}`)
      .join(',');
    // don't store custom filters in state, it could brake current filters and caching...
    // instead check here, do we have custom filter, and if we do, return that custom filter
    return isEmbroidery ? sizesString : myArtwork.get('filter');
  }
);

export const sortSelector = createSelector([myArtworkSelector], myArtwork => myArtwork.get('sort'));

export const imagesSelector = createSelector(
  [myArtworkSelector, filterSelector, sortSelector],
  (myArtwork, filter, sort) => {
    let filtered;
    switch (filter) {
      case 'png':
      case 'jpg':
        filtered = myArtwork
          .getIn(['images', 'all', 'images'])
          .filter(item => item.get('imageType') === filter);
        break;
      default:
        filtered = myArtwork.getIn(['images', filter, 'images']) || fromJS([]);
    }

    // do the sorting here
    switch (sort) {
      case 'large-small':
        return filtered.sort((a, b) => b.get('resolution') - a.get('resolution'));
      case 'small-large':
        return filtered.sort((a, b) => a.get('resolution') - b.get('resolution'));
      default:
        return filtered;
    }
  }
);

export const loadedSelector = createSelector(
  [myArtworkSelector, filterSelector],
  (myArtwork, filter) =>
    myArtwork.getIn(['images', filter === 'png' || filter === 'jpg' ? 'all' : filter, 'loaded'])
);

const modalOptionsSelector = createSelector([currentStateSelector], currentState =>
  currentState.get('options')
);

export const selectedSKUSpacePairsSelector = createSelector(
  [modalOptionsSelector, dataSelector],
  (modalOptions, data) => {
    let skus = new List();

    if (!modalOptions.isEmpty()) {
      if (modalOptions.get('bulk') === true) {
        data.get('selectedSkus').forEach((sku, i) => {
          sku.getIn(['template', 'Spaces']).forEach((space, si) => {
            skus = skus.push({
              sku: sku.get('sku'),
              variantIndex: i,
              spaceName: space.get('Description')?.replace(whiteSpaces, '')?.toLowerCase(),
              spaceIndex: si
            });
          });
        });
      } else {
        skus = modalOptions
          .get('spaces')
          .filter(s => s.get('Layers').find(l => l.get('Type') === 'Image'))
          .map((space, i) => ({
            sku: modalOptions.get('sku'),
            variantIndex: modalOptions.get('variantIndex'),
            spaceName: space.get('Description')?.replace(whiteSpaces, '')?.toLowerCase(),
            spaceIndex: i
          }));
      }
    }
    return skus;
  }
);

const mapSpace = (space, i) =>
  fromJS({
    index: i,
    name: space.get('name'),
    id: space.get('id'),
    images: space.get('images')
  });

export const selectedSpacesSelector = createSelector(
  [dataSelector, modalOptionsSelector],
  (data, options) => {
    if (options.get('bulk') === true) {
      const allSpaces = data
        .get('selectedSkus')
        .map(s => s.get('spaces'))
        .reduce((a, c) => a.concat(c));

      // get all uniq spaces (by space name) from all selected variants, ONLY if
      // variant has more than one space...
      // otherwise take first space only.
      if (allSpaces.size > data.get('selectedSkus').size) {
        const uniqSpaces = fromJS(removeDuplicatesBy(k => k.name, allSpaces.toJS()));
        return uniqSpaces.map((s, i) => mapSpace(s, i));
      }
      return new List([mapSpace(allSpaces.first(), 0)]);
    } else {
      return data
        .getIn(['selectedSkus', options.get('variantIndex'), 'spaces'])
        .map((s, i) => mapSpace(s, i));
    }
  }
);

export const spacesSelector = createSelector([myArtworkSelector], myArtwork =>
  myArtwork.get('spaces')
);

export const isMultiSpaceSelector = createSelector([spacesSelector], spaces => spaces.size > 1);

export const anySpaceReadySelector = createSelector([myArtworkSelector], myArtwork =>
  myArtwork.get('spaces').some(s => !!s.getIn(['image', 'url']))
);

const allImagesSelector = createSelector([myArtworkSelector], myArtwork =>
  myArtwork
    .getIn(['images', 'all', 'images'])
    .concat(myArtwork.getIn(['images', 'landscape', 'images']))
    .concat(myArtwork.getIn(['images', 'portrait', 'images']))
    .concat(myArtwork.getIn(['images', 'square', 'images']))
);

const areSpacesEqual = (s1, s2, compareIndex) =>
  compareIndex
    ? s1.get('name') === s2.get('name') && s1.get('index') === s2.get('index')
    : s1.get('name') === s2.get('name');

export const prefilledSpaceImagesSelector = createSelector(
  [
    selectedSpacesSelector,
    dataSelector,
    selectedSpaceNameInEditorSelector,
    modalOptionsSelector,
    allImagesSelector
  ],
  (selectedSpaces, data, selectedSpaceName, options, allImages) => {
    let modalSpaces = selectedSpaces.map(ss => {
      let newS = ss.set('selected', areSpacesEqual(ss, selectedSpaceName, true));

      if (options.get('bulk') === true) {
        const allSpaces = data
          .get('selectedSkus')
          .map(s => s.get('spaces').map((o, i) => mapSpace(o, i)))
          .reduce((a, c) => a.concat(c));
        const spaceImages = allSpaces
          .filter(s => areSpacesEqual(s, ss, true) && s.get('images').size)
          .map(s => s.getIn(['images', 0]));
        const uniqueSpaceImages = fromJS(removeDuplicatesBy(x => x.imageUrl, spaceImages.toJS()));

        if (uniqueSpaceImages.size === 1) {
          const matchedImage = allImages.find(
            img => img.get('url') === uniqueSpaceImages.getIn([0, 'imageUrl'])
          );
          newS = newS.set('image', matchedImage);
        }
        newS = newS.set('multiple', uniqueSpaceImages.size);
      } else {
        const allSpaces = data
          .getIn(['selectedSkus', options.get('variantIndex'), 'spaces'])
          .map((o, i) => mapSpace(o, i));
        const spaceImage = allSpaces
          .filter(s => areSpacesEqual(s, ss, true))
          .getIn([0, 'images', 0]);
        if (spaceImage) {
          // TODO: Make sure spaceImage always immutable map, fix all places
          const imageUrl = spaceImage.imageUrl || spaceImage.get('imageUrl');
          const matchedImage = allImages.find(img => img.get('url') === imageUrl);
          newS = newS.set('image', matchedImage);
        }
      }
      return newS;
    });
    return modalSpaces;
  }
);

export const isBulkModeSelector = createSelector(
  [modalOptionsSelector],
  options => options.get('bulk') === true
);
