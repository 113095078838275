import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ToastModal from '../../../shared/ToastModal';
import Icon from '../../../shared/Icon';
import { Tab, TabContainer } from '../../../shared/Tab';
import { COLORS } from '../../../../constants';

const ProvidersTab = props => {
  useEffect(() => {
    // when mounting component, set first available provider as selected
    if (!props.providers.isEmpty() && !props.isSelectedProviderAvailable) {
      props.setProvider(props.providers.first());
    }
  }, [props.providers]);

  const iconName = (isSelected, provider) => {
    switch (provider) {
      case 'etsy':
        return 'etsy';
      case 'woocommerce':
        return 'wooCommerce';
      case 'shopify':
        return 'shopify';
      case 'bigcommerce':
        return 'bigCommerce';
      case 'tiktok':
        return 'tikTok';
      case undefined:
        return 'gootenLogoAlt';
    }
  };

  return (
    <div className="product-publish-providers-tab mb-4">
      <TabContainer hideControls>
        {props.providers.map(p => {
          const hasError =
            props.errors.some(
              err =>
                err.get('storeId') === p.get('id') ||
                (err.get('storeId') === -1 && p.get('name') === 'Storage')
            ) ||
            props.serverErrors
              .get('errors')
              .map(err => err.get('storeId'))
              .includes(p.get('id')) ||
            (props.serverErrors.get('errors').some(err => err.get('storageRelated')) &&
              p.get('name') === 'Storage');
          const isSelected =
            props.selectedProvider &&
            props.selectedProvider.get('name') === p.get('name') &&
            props.selectedProvider.get('provider') === p.get('provider');

          return (
            <Tab
              key={p.get('name') + p.get('provider')}
              active={isSelected}
              to={null}
              onClick={() => props.setProvider(p)}
            >
              <Icon className="provider-icon mr-2" icon={iconName(isSelected, p.get('provider'))} />
              {p.get('name').toUpperCase()}
              {hasError ? <Icon className="ml-2" icon="alertCircle" fill={'#C80404'} /> : null}
            </Tab>
          );
        })}
      </TabContainer>

      <ReactCSSTransitionGroup
        transitionName="fade"
        transitionEnterTimeout={150}
        transitionLeaveTimeout={150}
      >
        {props.serverErrors.get('errors').size ? (
          <ToastModal
            show
            title={props.serverErrors.get('notificationMessage')}
            type="info"
            fullScreen
            avoidTimer
            zIndex={1001}
          />
        ) : null}
      </ReactCSSTransitionGroup>
      <style jsx>
        {`
          .product-publish-providers-tab {
            border-bottom: 1px solid ${COLORS.neutralLight200};
          }
          .product-publish-providers-tab :global(.tab-container) {
            justify-content: flex-start;
          }
          :global(.gooten-hub-container.gooten-components-container) {
            position: relative;
          }
        `}
      </style>
    </div>
  );
};

ProvidersTab.propTypes = {
  providers: PropTypes.instanceOf(List).isRequired,
  errors: PropTypes.instanceOf(List).isRequired,
  serverErrors: PropTypes.instanceOf(Map),
  selectedProvider: PropTypes.instanceOf(Map),
  setProvider: PropTypes.func,
  isSelectedProviderAvailable: PropTypes.bool.isRequired
};

export default ProvidersTab;
