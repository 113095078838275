import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components';
import PrintSpaceItem from './PrintSpaceItem';
import './PrintSpaces.scss';

const Container = styled.div`
  width: 174px;
  height: 540px;
  border: 1px solid #dedede;
  margin: 15px -15px -15px 0;
  flex-direction: column;

  .title {
    font-weight: 900;
    height: 20px;
    font-size: 13px;
    text-align: center;
    margin: 20px 0 30px 0;
  }

  .spaces-holder {
    display: block;
    overflow-y: auto;
  }
`;

const PrintSpaces = props => {
  const items = props.spaces.map((s, i) => (
    <PrintSpaceItem
      key={`${s.get('name')}${i}`}
      title={s.get('name')}
      numberOfImages={s.get('multiple') || 0}
      handleSelect={() => props.handleSelect(s)}
      imageUrl={s.getIn(['image', 'previewUrl'])}
      selected={s.get('selected')}
    />
  ));

  return (
    <Container className="image-selection-print-spaces flex-container">
      <span className="title">PRINT SPACES</span>
      <div className="spaces-holder">{items}</div>
    </Container>
  );
};

PrintSpaces.propTypes = {
  spaces: PropTypes.instanceOf(List).isRequired,
  handleSelect: PropTypes.func
};

export default PrintSpaces;
