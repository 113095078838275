import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgGradient from '../SvgGradient';
import { tierGradients, badgeModels } from './constants';

export const PartnerTierBadge = ({ id, width, height }) => {
  const [tierColor, setTierColor] = useState('');
  const [tierBadge, setTierBadge] = useState('');

  useEffect(() => {
    setTierColor(tierGradients[id]);
    setTierBadge(badgeModels[id]);
  }, [id, width, height]);

  return (
    <>
      {tierBadge && tierColor ? (
        <svg
          style={{ margin: 'auto 0 auto 0' }}
          width={width || tierBadge.width}
          height={height || tierBadge.height}
          viewBox={tierBadge.viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={tierBadge.path} fill={`url(#${tierColor.id}badge)`} />
          <defs>
            <SvgGradient
              id={tierColor.id + 'badge'}
              startColor={tierColor.startColor}
              endColor={tierColor.endColor}
              {...tierColor.badge}
            />
          </defs>
        </svg>
      ) : null}
    </>
  );
};

PartnerTierBadge.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number
};

export default PartnerTierBadge;
