import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ProductsListItem from './ProductsListItem';

export const ProductsList = props => (
  <div className="products-list-container">
    <div className="mt-2 mb-5">
      {props.pendingProducts.size
        ? props.pendingProducts.map((p, i) => (
            <ProductsListItem
              key={`pending_product_${i}`}
              selected={false}
              select={() => undefined}
              image={''}
              pending
              name={p.get('name')}
              type={p.get('type')}
              desc={
                p.get('type') === 'store'
                  ? `${p.get('connectedVariants')}/${p.get('totalVariants')} SKUS`
                  : 'STORAGE'
              }
              isAll={props.isAll}
              storeName={p.get('storeName')}
              item={p}
              onSelectModalOption={props.onSelectModalOption}
            />
          ))
        : null}
      {props.products.map(p => (
        <ProductsListItem
          key={p.get('id') || p.get('name')}
          selected={!!props.selected.find(e => e.get('id') === p.get('id'))}
          select={() =>
            !props.selected.find(e => e.get('id') === p.get('id'))
              ? props.select(p)
              : props.unselect(p)
          }
          selectExclusive={() => props.selectExclusive(p)}
          image={p.get('image')}
          name={p.get('name')}
          type={p.get('type')}
          desc={
            p.get('type') === 'store'
              ? `${p.get('connectedVariants')}/${p.get('totalVariants')} SKUS`
              : 'STORAGE'
          }
          isAll={props.isAll}
          storeName={p.get('storeName')}
          item={p}
          onSelectModalOption={props.onSelectModalOption}
        />
      ))}
    </div>
  </div>
);

ProductsList.propTypes = {
  products: PropTypes.instanceOf(List).isRequired,
  // pending products are products which recently was created, updated, deleted
  // and task is not finished yet
  pendingProducts: PropTypes.instanceOf(List).isRequired,
  /* eslint-disable */
  isAll: PropTypes.bool.isRequired,
  selected: PropTypes.instanceOf(List).isRequired,
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  selectExclusive: PropTypes.func.isRequired,
  onSelectModalOption: PropTypes.func
  /* eslint-enable */
};

export default ProductsList;
