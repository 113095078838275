import React from 'react';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import './AddressSelector.scss';
import AddressForm from './AddressForm';
import AddressItem from './AddressItem';
import RadioButtonGroup from '../../../shared/RadioButtonGroup';
import Strings from './strings';
import { animateScrollTo } from '../../../../utils/scroll';
import { isNotMissingRequiredFields } from '../../../../utils/address';
import Button from '../../../shared/Button';
import Icon from '../../../shared/Icon';
import Dialogue from '../../../shared/Dialogue';

class AddressSelector extends React.Component {
  PAGE_SIZE = 10;

  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: undefined,
      selectionIndex: -1
    };
  }

  static defaultProps = {
    shippingAddresses: new List(),
    currentPage: 1
  };

  resetSelectionState = () => {
    this.setState({
      selectedAddress: undefined,
      selectionIndex: -1
    });
  };

  setAddressFormContainerDomRef = node => {
    if (node) {
      this.addressFormContainerDom = node;
    }
  };

  setAddressFormRef = node => {
    if (node) {
      this.addressForm = node.getWrappedInstance();
    }
  };

  static calculateSelectionIndex = (props, state) => {
    if (props.isFormVisible || !state.selectedAddress) {
      return -1;
    }
    const addressId = state.selectedAddress.get('id');
    return props.shippingAddresses.map(a => a.get('id')).indexOf(addressId);
  };

  componentDidMount = () => {
    if (!this.props.isShippingAddressesLoaded || !this.props.selectedShippingAddress) {
      this.props.fetchShippingAddresses(this.props.currentPage);
    } else {
      this.setState({ selectedAddress: this.props.selectedShippingAddress }, () => {
        this.setState({
          selectionIndex: AddressSelector.calculateSelectionIndex(this.props, this.state)
        });
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    return { selectionIndex: AddressSelector.calculateSelectionIndex(props, state) };
  }

  onClickAddress = (addressItem, index) => {
    if (this.state.selectionIndex !== index) {
      this.setState({ selectedAddress: addressItem.props.address }, () => {
        this.setState({
          selectionIndex: AddressSelector.calculateSelectionIndex(this.props, this.state)
        });
      });
    } else {
      this.resetSelectionState();
    }
    this.props.setFormVisible(false);
  };

  onClickUseDifferentAddress = () => {
    this.resetSelectionState();
    this.props.setFormVisible(true);
    if (this.state.isEditing || this.props.isFormVisible) {
      this.setState({ isEditing: false }, () => {
        this.addressForm.clear();
        this.scrollToAddressForm();
      });
    }
  };

  onClickUseThisAddress = () => {
    if (isNotMissingRequiredFields(this.state.selectedAddress)) {
      this.props.setShippingAddress(this.state.selectedAddress);
    } else {
      this.handleEditAddressClick(this.state.selectedAddress);
    }
  };

  handleNewAddressEntered = addressWithoutId => {
    const randomUserShippingAddressId = 'user' + Math.random();
    const address = addressWithoutId.set('id', randomUserShippingAddressId);
    this.props.setFormVisible(false);
    this.props.storeUserAddress(address);
    this.props.setShippingAddress(address);
    this.setState({ isEditing: false });
  };

  handleEditAddressClick = address => {
    this.resetSelectionState();
    this.props.setFormVisible(true);
    this.addressForm.prefillAddress(address.toJS());
    this.setState({ isEditing: true }, () => {
      this.scrollToAddressForm();
    });
  };

  scrollToAddressForm = () => {
    animateScrollTo(
      document.getElementsByClassName('left-side-content')[0],
      document.getElementsByClassName('new-address')[0],
      300,
      -10
    );
  };

  handleDeleteAddressClick = address => {
    this.setDeleteAddressModalOpen(true, address);
  };

  handleDismissDeleteAddressModal = () => {
    this.setDeleteAddressModalOpen(false);
  };

  deleteAddress = () => {
    this.setDeleteAddressModalOpen(false);
    if (this.props.selectedShippingAddress === this.state.addressToDelete) {
      // Deleting currently selected address
      this.props.setShippingAddress(fromJS(undefined));
    }
    const addressIdToDelete = String(this.state.addressToDelete.get('id'));
    if (addressIdToDelete.startsWith('user')) {
      this.props.deleteUserEnteredShippingAddress(addressIdToDelete); // Deleting User Entered address
    } else {
      this.props.deleteShippingAddress(addressIdToDelete); // Deleted Stored address
    }
  };

  setDeleteAddressModalOpen = (isOpen, addressToDelete) => {
    this.setState({
      isDeleteAddressModalOpen: isOpen,
      addressToDelete
    });
  };

  handleClickPreviousPage = () => {
    this.props.fetchShippingAddresses(this.props.currentPage - 1);
  };

  handleClickNextPage = () => {
    this.props.fetchShippingAddresses(this.props.currentPage + 1);
  };

  render = () => {
    if (!this.props.isShippingAddressesLoaded) {
      return null;
    }
    const hasShippingAddresses = this.props.shippingAddresses.size !== 0;

    let addressesList = null;
    let deleteAddressModal = null;

    if (hasShippingAddresses) {
      const totalPages = Math.ceil(
        (this.props.totalAddresses - this.props.userEnteredShippingAddressesCount) / this.PAGE_SIZE
      );

      const buttonPrevPage = (
        <Button
          className="button-default small"
          iconOnly
          disabled={this.props.currentPage <= 1}
          onClick={this.handleClickPreviousPage}
        >
          <Icon icon="chevronLeft" small />
        </Button>
      );

      const buttonNextPage = (
        <Button
          className="button-default small"
          iconOnly
          disabled={this.props.currentPage >= totalPages}
          onClick={this.handleClickNextPage}
        >
          <Icon icon="chevronRight" small />
        </Button>
      );

      const userAddressesCount = this.props.userEnteredShippingAddressesCount;

      let firstDisplayedItem = (this.props.currentPage - 1) * this.PAGE_SIZE + 1;
      if (this.props.currentPage !== 1) {
        firstDisplayedItem += userAddressesCount;
      }

      let lastDisplayedItem = firstDisplayedItem + this.PAGE_SIZE - 1;
      if (this.props.currentPage === 1) {
        lastDisplayedItem += userAddressesCount;
      }
      lastDisplayedItem = Math.min(lastDisplayedItem, this.props.totalAddresses);

      const currentPageDisplay = (
        <div className="pages-container mb-4">
          {buttonPrevPage}
          <span className="pager-text overline mr-3 ml-3">
            {`${firstDisplayedItem}-${lastDisplayedItem} of ${this.props.totalAddresses}`}
          </span>
          {buttonNextPage}
        </div>
      );

      const addressItems = this.props.shippingAddresses.map(address => {
        return (
          <AddressItem
            address={address}
            onClickEdit={this.handleEditAddressClick}
            onClickDelete={this.handleDeleteAddressClick}
          />
        );
      });

      const buttonUseDifferentAddress = (
        <Button className="button-default medium" onClick={this.onClickUseDifferentAddress}>
          <Icon icon="plusCircle" />
          {Strings.USE_DIFFERENT_ADDRESS}
        </Button>
      );

      addressesList = (
        <div className="saved-addresses">
          {currentPageDisplay}
          <RadioButtonGroup
            items={addressItems}
            selectionIndex={this.state.selectionIndex}
            onChange={this.onClickAddress}
          />
          {buttonUseDifferentAddress}
          <hr />
          <style jsx>
            {`
              .saved-addresses :global(.label-text) {
                width: 100%;
              }
            `}
          </style>
        </div>
      );

      deleteAddressModal = (
        <Dialogue
          isOpen={this.state.isDeleteAddressModalOpen}
          cancelText={Strings.CANCEL}
          cancelClick={this.handleDismissDeleteAddressModal}
          actionText={Strings.MODAL_DELETE_ADDRESS_CONFIRM}
          destructiveClick={this.deleteAddress}
          title={Strings.MODAL_DELETE_ADDRESS_TITLE}
        >
          {Strings.MODAL_DELETE_ADDRESS_TEXT}
        </Dialogue>
      );
    }

    const newAddressForm = (
      <div className="new-address" ref={this.setAddressFormContainerDomRef}>
        <AddressForm
          isVisible={this.props.isFormVisible}
          setAddress={this.handleNewAddressEntered}
          ref={this.setAddressFormRef}
        />
      </div>
    );

    const buttonUseThisAddress = (
      <Button
        className="button-secondary large use-this-address"
        disabled={!this.state.selectedAddress}
        onClick={this.onClickUseThisAddress}
      >
        {Strings.USE_THIS_ADDRESS}
      </Button>
    );

    return (
      <div className="address-selector">
        {hasShippingAddresses ? addressesList : null}
        {hasShippingAddresses ? deleteAddressModal : null}
        {newAddressForm}
        {this.props.isFormVisible ? null : buttonUseThisAddress}
      </div>
    );
  };
}

AddressSelector.propTypes = {
  fetchShippingAddresses: PropTypes.func.isRequired,
  totalAddresses: PropTypes.number,
  currentPage: PropTypes.number,
  isShippingAddressesLoaded: PropTypes.bool,
  setFormVisible: PropTypes.func.isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  shippingAddresses: PropTypes.instanceOf(List),
  userEnteredShippingAddressesCount: PropTypes.number,
  selectedShippingAddress: PropTypes.object,
  setShippingAddress: PropTypes.func.isRequired,
  deleteShippingAddress: PropTypes.func.isRequired,
  deleteUserEnteredShippingAddress: PropTypes.func.isRequired,
  storeUserAddress: PropTypes.func
};

AddressSelector.displayName = 'AddressSelector';

export default AddressSelector;
