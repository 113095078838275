export const parseCurrency = data => {
  if (data === 0) {
    return `$${data.toFixed(2)}`;
  }

  if (Math.sign(data) === -1) {
    return `-$${Math.abs(data.toFixed(2))}`;
  }

  return data ? `$${data.toFixed(2)}` : '-';
};
