import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB-LINK', 'PRODUCT_DESIGN_VIEW');

// ------------------------------------
// Constants
// ------------------------------------
export const PREPARING = getActionName('PREPARING');
export const READY = getActionName('READY');

// ------------------------------------
// Actions
// ------------------------------------
export function prepareLinkData(connectExistingData, stores) {
  return {
    type: PREPARING,
    payload: { connectExistingData, stores }
  };
}

export function getReady() {
  return {
    type: READY
  };
}
