import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './../../../shared/Spinner';
import './FullScreenPreview.scss';
import Button from '../../../shared/Button';
import Icon from '../../../shared/Icon';

class FullScreenPreview extends React.Component {
  static propTypes = {
    allOptions: PropTypes.string,
    allAssociatedOptions: PropTypes.string,
    onCloseFullScreenPreview: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    currentItem: PropTypes.object,
    setFullScreenCurrentItems: PropTypes.func,
    showLoading: PropTypes.bool,
    cancelFullscreenGeneration: PropTypes.func
  };

  currentItemIndex() {
    return this.props.items.findIndex(
      item =>
        item.sku.toLowerCase() === this.props.currentItem.sku.toLowerCase() &&
        item.sId === this.props.currentItem.sId &&
        item.index === this.props.currentItem.index
    );
  }

  showImage = nextPrevItemIndex => {
    if (this.props.items.length === 1) return;

    this.props.cancelFullscreenGeneration();
    this.props.setFullScreenCurrentItems([this.props.items[nextPrevItemIndex]]);
  };

  clickOnNext = () => {
    const nextIndex = this.props.items[this.currentItemIndex() + 1]
      ? this.currentItemIndex() + 1
      : 0;
    this.showImage(nextIndex);
  };

  clickOnPrevious = () => {
    const prevIndex = this.props.items[this.currentItemIndex() - 1]
      ? this.currentItemIndex() - 1
      : this.props.items.length - 1;
    this.showImage(prevIndex);
  };

  clickOnClose = () => {
    this.props.cancelFullscreenGeneration();
    this.props.onCloseFullScreenPreview();
  };

  render() {
    const style = {
      backgroundColor: this.props.showLoading
        ? 'white'
        : this.props.currentItem.backgroundColor || 'white'
    };

    const associatedOptions = this.props.allAssociatedOptions.length ? (
      <div className="associated-options">
        <span>SKUs associated with this image:</span>
        <span className="sku-options">{this.props.allAssociatedOptions}</span>
      </div>
    ) : null;

    const viewMode =
      this.props.currentItem &&
      this.props.currentItem.isExclusiveMockup &&
      this.props.currentItem.view ? (
        <div className="associated-options">
          <span>
            View Space: <br /> {this.props.currentItem.view}
          </span>
        </div>
      ) : null;

    return (
      <div className="full-screen-preview-container">
        <div className="left-close col-sm-1" onClick={() => this.clickOnClose()} />
        <div className="left-content col-sm-7 col-xs-12">
          <div className="button-holder col-sm-1 col-xs-1">
            <Button
              className="button-secondary large"
              iconOnly
              onClick={() => this.clickOnPrevious()}
            >
              <Icon icon="chevronLeft" />
            </Button>
          </div>
          <div className="slider-content col-sm-10 col-xs-10">
            <span>SKU MOCKUPS </span>
            <span className="number-of-mockups">
              ({this.currentItemIndex() + 1}/{this.props.items.length})
            </span>
            <div className="product-image" style={style}>
              <Spinner />
              <img
                src={
                  this.props.currentItem.isExclusiveMockup
                    ? this.props.currentItem.previewImageUrl
                    : this.props.currentItem.fullscreenImageUrl
                }
                className={this.props.showLoading ? '' : 'image-loaded'}
              />
            </div>
          </div>
          <div className="button-holder col-sm-1 col-xs-1">
            <Button className="button-secondary large" iconOnly onClick={() => this.clickOnNext()}>
              <Icon icon="chevronRight" />
            </Button>
          </div>
        </div>
        <div className="right-content col-sm-3 col-xs-12">
          <div className="col-xs-1" />
          <div className="col-sm-12 col-xs-10">
            <span>Image Shown:</span>
            <span className="sku-options">{this.props.allOptions}</span>
            {viewMode}
            {associatedOptions}
          </div>
        </div>
        <div className="right-close col-sm-1" onClick={() => this.clickOnClose()}>
          <span>Close X</span>
        </div>
      </div>
    );
  }
}

export default FullScreenPreview;
