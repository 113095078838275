import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
// import Config from '../../../../config'
import analyticsService from 'gooten-components/src/services/analyticsService';
import { Tab, TabContainer } from 'gooten-components/src/components/shared/Tab';

import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import LogOutConfirmationModal from '../Stores/LogOutConfirmationModal';
import { CheckoutWithToast } from 'gooten-components/src/components/Checkout/CheckoutContainer';
import PageBody from 'gooten-components/src/components/shared/PageBody';

class Providers extends React.PureComponent {
  static propTypes = {
    availableProviders: PropTypes.instanceOf(List).isRequired,
    selectedProvider: PropTypes.string,
    params: PropTypes.object,
    router: PropTypes.object,
    selectProvider: PropTypes.func.isRequired,
    hasSelectedStore: PropTypes.bool.isRequired,
    selectedStoreHasProductsToLink: PropTypes.bool.isRequired,
    resetLinkFlow: PropTypes.func.isRequired,
    selectedStoreName: PropTypes.string,
    storeLogOut: PropTypes.func.isRequired,
    selectedStore: PropTypes.number
  };

  validProviders = ['all', 'storage', 'shopify', 'etsy', 'woocommerce', 'bigcommerce', 'tiktok'];

  componentDidMount() {
    // save to state selected provider from route param if valid or first available
    if (!this.props.selectedProvider) {
      if (
        this.props.params.provider &&
        ~this.validProviders.indexOf(this.props.params.provider.toLowerCase())
      ) {
        this.props.selectProvider({
          provider: this.props.params.provider,
          reset: false
        });
      } else {
        const defaultProvider = this.props.availableProviders.first().get('id');
        this.props.selectProvider({ provider: defaultProvider });
        this.props.router.replace(`/hub/${defaultProvider}`);
      }
    } else if (
      this.props.selectedProvider !== this.props.params.provider &&
      this.props.params.provider &&
      ~this.validProviders.indexOf(this.props.params.provider.toLowerCase())
    ) {
      // case when redirect to /hub/all page after wizard
      this.props.selectProvider({
        provider: this.props.params.provider,
        reset: true
      });
    }
  }

  onProviderClick = provider => {
    if (this.props.selectedProvider !== provider.get('id')) {
      analyticsService.track(`Hub - Page ${provider.get('name')}`, 'Selected', 'Hub');
      console.log('Provider Selected: [', provider.get('id'), '][', provider.get('name'), ']');
      this.props.selectProvider({ provider: provider.get('id') });
    }
  };

  onAddStore(storeId) {
    analyticsService.track('Hub - Page', 'Add a new ' + storeId + ' store clicked', 'Hub');
  }

  onCreateProductClick() {
    analyticsService.track('Hub - Page', 'Create button click', 'Hub', null, {
      page: this.props.router.location.pathname
    });
  }

  onStoreLogOut() {
    this.logOutConfirmationModal.openModal();
  }

  storeLogOut() {
    this.props.storeLogOut(this.props.selectedStore);
  }

  render() {
    const links = this.props.availableProviders
      .map((p, index) => {
        const isBeta = p.get('isBeta');
        let liClass = isBeta ? ' beta-icon' : '';
        return (
          <Tab
            key={p.get('id')}
            className={'direct-link' + liClass}
            active={this.props.selectedProvider === p.get('id')}
            to={this.props.selectedProvider === p.get('id') ? '' : `/hub/${p.get('id')}`}
            onClick={() => this.onProviderClick(p)}
          >
            {p.get('name')}
          </Tab>
        );
      })
      .toArray();

    // const partnerId = Config.get('partnerId')
    const {
      router: { params }
    } = this.props;
    return (
      <>
        <CheckoutWithToast />
        <PageHeader
          size="wide"
          title="Product Hub"
          primaryText="Add new product"
          primaryClick={() => this.onCreateProductClick()}
          primaryHref="#/hub/create-product/start"
          secondaryText={
            this.props.hasSelectedStore && this.props.selectedStoreHasProductsToLink
              ? 'Sync product'
              : null
          }
          secondaryClick={
            this.props.hasSelectedStore && this.props.selectedStoreHasProductsToLink
              ? () => this.props.resetLinkFlow()
              : null
          }
          secondaryHref={
            this.props.hasSelectedStore && this.props.selectedStoreHasProductsToLink
              ? `#/hub/${params.provider}/${params.store}/link-product/product-selection`
              : null
          }
          menuItems={
            this.props.hasSelectedStore
              ? [
                  <ListItem
                    key={this.props.selectedStoreName}
                    text={`Log Out: ${this.props.selectedStoreName}`}
                    leftIcon="logOut"
                    onSelect={() => this.onStoreLogOut()}
                  />,
                  this.props.hasSelectedStore && this.props.selectedStoreHasProductsToLink ? (
                    <a
                      key={`${this.props.selectedStoreName}-sync`}
                      className="hidden-large"
                      href={`#/hub/${params.provider}/${params.store}/link-product/product-selection`}
                    >
                      <ListItem
                        key={`${this.props.selectedStoreName}-sync`}
                        leftIcon="uploadCloud"
                        text="Sync Product"
                        onSelect={() => this.props.resetLinkFlow()}
                      />
                    </a>
                  ) : null
                ]
              : null
          }
        />
        <PageBody
          size="wide"
          noBottomPadding
          className="providers-container d-flex justify-content-between align-items-start"
        >
          <TabContainer>{links}</TabContainer>
          <LogOutConfirmationModal
            ref={instance => {
              this.logOutConfirmationModal = instance;
            }}
            confirm={() => this.storeLogOut()}
          />
        </PageBody>
        <style jsx>
          {`
            a:hover {
              text-decoration: none;
            }
            @media (min-width: 768px) {
              a.hidden-large {
                display: none;
              }
            }
          `}
        </style>
      </>
    );
  }
}

export default Providers;
