import { connect } from 'react-redux';
import ReturnAddress from './ReturnAddress';
import {
  loadingStatus,
  savingStatus,
  billingAddress,
  currentSelection,
  returnAddress,
  customAddressId,
  countries,
  addressTypes,
  partnerDetails
} from './ReturnAddressSelectors';
import {
  loadInitialData,
  setSelection,
  saveReturnAddress,
  useExistingReturnAddress
} from './ReturnAddressActions';

const mapDispatchToProps = {
  loadInitialData: () => loadInitialData(),
  setSelection: selection => setSelection(selection),
  saveReturnAddress: data => saveReturnAddress(data),
  useExistingReturnAddress: () => useExistingReturnAddress()
};

const mapStateToProps = state => {
  return {
    loading: loadingStatus(state),
    saving: savingStatus(state),
    billingAddress: billingAddress(state),
    currentSelection: currentSelection(state),
    returnAddress: returnAddress(state),
    customAddressId: customAddressId(state),
    countries: countries(state),
    addressTypes: addressTypes(state),
    partnerDetails: partnerDetails(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnAddress);
