import { connect } from 'react-redux';
import DuplicateProductView from './DuplicateProductView';
import {
  currentStepSelector,
  selectedProductForEditSelector
} from './DuplicateProductViewSelectors';
import { changeStep } from './DuplicateProductViewActions';
import { allStoresSelector } from '../HubView/components/Stores/StoresSelectors';

const mapDispatchToProps = {
  changeStep: step => changeStep(step)
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedProduct: selectedProductForEditSelector(state),
    stores: allStoresSelector(state),
    currentStep: currentStepSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateProductView);
