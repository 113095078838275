import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';
import Icon from 'gooten-components/src/components/shared/Icon';
import { COLORS } from 'gooten-components/src/constants';

const ReportTooltip = ({ position }) => {
  const content = `<div class="d-flex flex-column p-2 text-align-left">
      <span class="title-3 pb-2">Production Report</span>
      Our Gooten production report allows you to track the time it 
      takes your orders to be in production. The report will display the different product 
      categories and their correlated production time range. All data provided in the report 
      are specific to your orders in the system.
      <a class="link d-flex align-items-center justify-content-center mt-3"
        href="https://help.gooten.com/hc/en-us/articles/360057459971" target="_blank">
        <span>Learn more</span>
      </a>
    </div>`;

  return (
    <div className="report-tooltip d-flex">
      <Tooltip
        title="Production Report"
        content={content}
        containerClassName="m-auto"
        position={position}
        light
        long
      >
        <Icon icon="info" small />
      </Tooltip>
      <style jsx>{`
        .report-tooltip {
          cursor: pointer;
        }
        :global(.report-tooltip .link) {
          background: ${COLORS.neutralLight200};
          border-radius: 4px;
          height: 2rem;
          padding: 0 0.5rem;
          text-decoration: none !important;
        }

        :global(.report-tooltip .link span) {
          font-weight: 800;
        }

        :global(.report-tooltip .tooltip-container svg) {
          margin-top: 6px;
        }
      `}</style>
    </div>
  );
};

ReportTooltip.propTypes = {
  position: PropTypes.string
};

export default ReportTooltip;
