import { connect } from 'react-redux';
import Actions from './Actions';
import {
  productsCountSelector,
  viewByOptionsSelector,
  availableOptionsSelector
} from './ActionsSelectors';
import {
  selectedProductsSelector,
  displaySelector,
  viewBySelector,
  sortBySelector,
  productUrlInStoreSelector
} from '../Products/ProductsSelectors';
import {
  selectedStoreSelector,
  selectedStoreHasProductsToLinkSelector,
  storesLoadedSelector
} from '../Stores/StoresSelectors';
import {
  displayChange,
  viewByChange,
  sortByChange,
  unpublishProducts,
  publishProducts,
  deleteProducts,
  unlinkProducts,
  fetchShopifyTokenScopes
} from './ActionsActions';

// import { customReset } from '../../../../store/actions/globalActions'

const mapDispatchToProps = {
  changeDisplay: display => displayChange(display),
  changeSortBy: sortBy => sortByChange(sortBy),
  changeViewBy: viewBy => viewByChange(viewBy),
  // resetLinkFlow: () => customReset('link'),
  unpublishProducts: products => unpublishProducts(products),
  publishProducts: products => publishProducts(products),
  deleteProducts: products => deleteProducts(products),
  unlinkProducts: products => unlinkProducts(products)
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasSelectedStore: !!selectedStoreSelector(state),
    selectedStoreHasProductsToLink: selectedStoreHasProductsToLinkSelector(state),
    storesLoaded: storesLoadedSelector(state),
    selectedProducts: selectedProductsSelector(state),
    productsCount: productsCountSelector(state),
    display: displaySelector(state),
    viewBy: viewBySelector(state),
    sortBy: sortBySelector(state),
    viewByOptions: viewByOptionsSelector(state),
    productUrlInStore: productUrlInStoreSelector(state),
    availableOptions: availableOptionsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
