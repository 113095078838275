'use strict';
// @flow

import * as ACTIONS from '../actions/actionTypes';
import initialState from '../initialState';
import { Map, List, fromJS } from 'immutable';

export default (state = initialState.get('images'), action) => {
  switch (action.type) {

  case ACTIONS.EDITOR_RESTORE: {
    return action.payload.images
      ? fromJS(action.payload.images)
      : state;
  }

  case ACTIONS.IMAGE_ADD: {
    // NOTE: Images in layers are sorted by zindex!
    // Each new image added with last(max+1) zindex - appear on top of the layer
    // If added image is 1st in layer then - zindex = 0
    const zindex =
      state.get('current')
        .get('images')
        .filter(i => i.get('layerId') === action.payload.layerId).size === 0
        ? 0
        : state.get('current')
          .get('images')
          .filter(i => i.get('layerId') === action.payload.layerId)
          .maxBy(img => img.get('zindex'))
          .get('zindex') + 1;

    // NOTE: If allowMultiImages is false
    // then check if that layer has already image
    if(action.payload.allowMultiImages === false && zindex > 0) {
      return state;
    }

    return state
      .update('past', past => past.push(state.get('current')))
      .update('current', current => current
        .update('images', images =>
          images.push(Map({
            id: action.payload.id,
            src: action.payload.image,
            layerId: action.payload.layerId,
            realSourceHeight: action.payload.realSourceHeight,
            realSourceWidth: action.payload.realSourceWidth,
            zindex: zindex
          }))
        )
        .update('selected', selected => selected
          .set('imageId', action.payload.id)
          .set('layerId', action.payload.layerId)
        )
        .sortBy(img => img.get('zindex'))
      )
      .set('future', List([]));
  }

  case ACTIONS.IMAGE_DELETE: {
    // NOTE: Images in layers are sorted by zindex!
    // If removing 1 image then need to make sure
    // sequence of zindexes is not broken
    // if it's then need update zindexes
    const removedZindex = state.get('current')
      .get('images')
      .find(i => i.get('id') === action.payload.id)
      .get('zindex');

    return state
      .update('past', past => past.push(state.get('current')))
      .update('current', current => current
        .set('images', state
          .get('current')
          .get('images')
          .filter(i => i.get('id') !== action.payload.id)
          .map(img => {
            // NOTE: Check only images from layer where image was removed
            // Decrese by 1 all images zindexes after removed image
            if (img.get('layerId') === action.payload.layerId &&
              removedZindex < img.get('zindex')) {
              return img.set('zindex', img.get('zindex') - 1);
            }

            return img;
          })
          .sortBy(img => img.get('zindex'))
        )
        .update('selected', selected => selected
          .set('imageId', '')
          // NOTE: Leave layer selected to not clip other layer images
          // if layer is not selected in edit mode
          // all images will be clipped by theirs viewports
          //.set('layerId', '')
        )
      )
      .set('future', List([]));
  }

  case ACTIONS.SELECT: {
    // NOTE: Selected image appear at the top
    // Need to find that image and set max zindex to it
    // After update all images zindexes in that layer
    const selectedOrigZindex = state.get('current')
      .get('images')
      .find(i => i.get('id') === action.payload.imageId)
      .get('zindex');

    const maxZindex = state.get('current')
      .get('images')
      .filter(i => i.get('layerId') === action.payload.layerId)
      .maxBy(img => img.get('zindex'))
      .get('zindex');

    const selectedImage = state.get('current')
      .get('images')
      .find(i =>
        i.get('layerId') === action.payload.layerId &&
        i.get('id') === action.payload.imageId
      );

    const selectedImageId = state.get('current')
      .get('selected')
      .get('imageId');

    const selectedLayerId = state.get('current')
      .get('selected')
      .get('layerId');

    // NOTE: Do nothing if zindex is already max and image already selected
    if (
      selectedImage.get('zindex') === maxZindex &&
      selectedImageId === action.payload.imageId &&
      selectedLayerId === action.payload.layerId

    ) {
      return state;
    }

    return state
      .update('past', past => past.push(state.get('current')))
      .update('current', current => current
        .setIn(['selected', 'imageId'], action.payload.imageId)
        .setIn(['selected', 'layerId'], action.payload.layerId)
        .set('images', state.get('current')
          .get('images')
          .map(img => {
            // NOTE: update only images in that layer
            if (img.get('layerId') === action.payload.layerId) {
              // Set max zindex to selected image
              if (img.get('id') === action.payload.imageId) {
                return img.set('zindex', maxZindex);
              }
              // NOTE: Update zindexes of other images
              // ONLY if they were after selected image
              else if (img.get('zindex') > selectedOrigZindex) {
                return img.set('zindex', img.get('zindex') - 1);
              }
              else {
                return img;
              }
            }
            else {
              return img;
            }
          })
          .sortBy(img => img.get('zindex'))
        )
      )
      .set('future', List([]));
  }

  case ACTIONS.IMAGE_LOADED: {

    // NOTE: In case no image were loaded
    // case when allowMultiImages is false
    // and user try to add more than 1 image
    if (action.payload === null) {
      return state;
    }

    // NOTE: When image is loaded
    // add image object, sizes, position, etc.
    return state
      .update('current', current => current
        .update('images', images => {
          let index = images.findIndex(i => i.get('id') === action.payload.id);
          return images.updateIn([index], img => img.merge(action.payload));
        })
      );
  }

  // TODO: Remove the image object from past and future, replace back when undo redo.
  case ACTIONS.IMAGE_UPDATE: {
    // NOTE: Update current image state with new size, rotate, crop, position
    return state
      .update('past', past => past.push(state.get('current')))
      .update('current', current => current
        .update('images', images => {
          let index = images.findIndex(i => i.get('id') === action.payload.id);
          return images.updateIn([index], img => img.merge(action.payload));
        })
      )
      .set('future', List([]));
  }

  // TODO: Remove the image object from past and future, replace back when undo redo.
  case ACTIONS.IMAGE_UNDO: {
    // NOTE: Undo to prev state
    if (!state.get('past').size) {
      return state;
    }

    const currentCandidate = state.get('past').last();

    return state
      .update('past', past => past.delete(-1))
      .update('current', current => currentCandidate)
      .update('future', future => future.insert(0, state.get('current')));
  }

  case ACTIONS.IMAGE_REDO: {
    // NOTE: Undo to next state
    if (!state.get('future').size) {
      return state;
    }

    const currentCandidate = state.get('future').first();

    return state
      .update('past', past => past.push(state.get('current')))
      .update('current', current => currentCandidate)
      .update('future', future => future.delete(0));
  }

  case ACTIONS.IMAGE_CLEAR_HISTORY: {
    return state
      .set('past', List([]));
  }

  default:
    return state;
  }
};
