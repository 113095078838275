import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import Icon from 'gooten-components/src/components/shared/Icon';
import { copy } from 'gooten-components/src/utils/clipboard';

const Container = styled.div`
  .copy-row {
    position: relative;

    .copy-link {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
    }
  }

  .text-input-container input.form-control[readonly] {
    background-color: transparent;
    padding-right: 40px;
  }
`;

class ApiKeys extends React.Component {
  static get name() {
    return 'apiKeys';
  }
  static get messages() {
    return { onInitFailure: 'Failed to load API Keys' };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
  }

  componentDidMount() {
    this.props.init();
  }

  renderInput(name, label, className) {
    return (
      <div className="copy-row">
        <TextInput
          readOnly
          className={className}
          id={name}
          label={label}
          placeholder=""
          ref={ref => {
            this.inputs[name] = ref;
          }}
          value={this.props.data.get(name) || ''}
        />
        <a className="copy-link" onClick={() => copy(name)}>
          <Icon icon="copy" />
        </a>
      </div>
    );
  }

  render() {
    return (
      <BaseCard card={ApiKeys.name} title="API Keys">
        <Container>
          <div className="api-keys-card">
            <div className="row">
              <div className="col-sm-10 col-xs-12">
                <div className="row">
                  <div className="col-xs-12">{this.renderInput('RecipeId', 'Recipe ID', '')}</div>
                  <div className="col-xs-12">
                    {this.renderInput('ApiKey', 'Partner Billing Key', '')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </BaseCard>
    );
  }
}

ApiKeys.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default ApiKeys;
