const easeInOut = (currentTime, start, change, duration) => {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }
  currentTime -= 1;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
};

export const animateScrollTo = (element, toElement, duration, offset) => {
  let scrollToElement =
    typeof toElement === 'string' ? document.getElementById(toElement) : toElement;

  if (scrollToElement === null || element === null) {
    return;
  }
  let to = scrollToElement.offsetTop - element.offsetTop + offset;
  let start = element.scrollTop;
  let change = to - start;
  let increment = 10;

  let animateScroll = elapsedTime => {
    elapsedTime += increment;
    let position = easeInOut(elapsedTime, start, change, duration);
    element.scrollTop = position;
    if (elapsedTime < duration) {
      setTimeout(() => {
        animateScroll(elapsedTime);
      }, increment);
    }
  };
  animateScroll(0);
};

export const scrollToTop = () => {
  const container = document.getElementsByClassName('gooten-hub-container')[0];
  container.scrollTo(0, 0);
};

export const scrollIntoView = element => {
  element.scrollIntoView({ block: 'start', behavior: 'smooth' });
};

export const scrollToWithDelay = (element, delay) => {
  setTimeout(() => {
    element.scrollIntoView(element);
  }, delay);
};
