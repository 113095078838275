import { connect } from 'react-redux';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import { fetchHistory } from '../../../StoreBulkCreation/StoreBulkCreationActions';
import {
  fetchHistoryErrorSelector,
  historySelector,
  showLoadingSelector
} from '../../../StoreBulkCreation/StoreBulkCreationSelectors';
import HistoryView from '../../../StoreBulkCreation/Components/HistoryView/HistoryView';

const mapDispatchToProps = {
  fetchHistory: payload => fetchHistory(payload)
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: showLoadingSelector(state),
    history: historySelector(state),
    historyError: fetchHistoryErrorSelector(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(HistoryView));
