import { createSelector } from 'reselect';

const storeBulkCreationSelector = state => state.getIn(['admin', 'storeBulkCreation']);

export const showLoadingSelector = createSelector([storeBulkCreationSelector], state =>
  state.get('loading')
);

export const getStoresSelector = createSelector(
  [storeBulkCreationSelector],
  state => state.get('stores') && state.get('stores').toJS()
);

export const getProvidersSelector = createSelector(
  [storeBulkCreationSelector],
  state => state.get('providers') && state.get('providers').toJS()
);

export const getEmailSelector = createSelector([storeBulkCreationSelector], state =>
  state.get('emailAddress')
);

export const getFetchStoresErrorMsgSelector = createSelector([storeBulkCreationSelector], state =>
  state.get('fetchStoresError')
);

export const getFetchEmailErrorMsgSelector = createSelector([storeBulkCreationSelector], state =>
  state.get('fetchEmailErrorMsg')
);

export const csvDataSelector = createSelector(
  [storeBulkCreationSelector],
  state => state.get('csvData') && state.get('csvData').toJS()
);

export const validateCsvError = createSelector([storeBulkCreationSelector], state =>
  state.get('validateCsvError')
);

export const validateCsvSuccess = createSelector([storeBulkCreationSelector], state =>
  state.get('validateCsvSuccess')
);

export const publishCsvError = createSelector([storeBulkCreationSelector], state =>
  state.get('publishCsvError')
);

export const publishCsvSuccess = createSelector([storeBulkCreationSelector], state =>
  state.get('publishCsvSuccess')
);

export const historySelector = createSelector(
  [storeBulkCreationSelector],
  state => state.get('history') && state.get('history').toJS()
);

export const fetchHistoryErrorSelector = createSelector([storeBulkCreationSelector], state =>
  state.get('fetchHistoryError')
);
