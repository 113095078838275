import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'gooten-components/src/components/shared/Loader';
import { scrollToWithDelay } from 'gooten-components/src/utils/scroll';
import { withRouter } from 'react-router';
import ReactDOM from 'react-dom';
import Icon from 'gooten-components/src/components/shared/Icon';

class BaseCard extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.router.params.card) {
      if (this.props.router.params.card === this.props.card && !this.props.isOpen) {
        this.toggleCard();
      } else if (
        this.props.canToggle &&
        this.props.router.params.card !== this.props.card &&
        this.props.isOpen
      ) {
        this.toggleCard();
      }
    }
  }

  componentDidMount() {
    if (this.props.router && this.props.router.params.card === this.props.card) {
      scrollToWithDelay(ReactDOM.findDOMNode(this.wrapper), 1000);
    }
  }

  toggleCard() {
    this.props.toggleCard(this.props.card);
  }

  render() {
    return (
      <div
        ref={wrapper => {
          this.wrapper = wrapper;
        }}
      >
        <div className="panel">
          <div
            className={`panel-body p-3 ${this.props.customCardStyle ? '' : 'pt-4 pb-4'}`}
            style={this.props.customCardStyle}
          >
            <div
              onClick={this.props.canToggle ? () => this.toggleCard() : () => null}
              className={`panel-header d-flex align-items-center justify-content-between ml-3 mr-3 ${
                this.props.canToggle ? 'inline-link' : ''
              }`}
            >
              {this.props.title && <div className={`title-2`}>{this.props.title}</div>}
              {this.props.canToggle ? (
                <div className="pull-right inline-link">
                  <div
                    className={`rotating-arrow mt-n2 ${this.props.isOpen ? '' : 'upside-down'} `}
                  >
                    <Icon icon="chevronUp" />
                  </div>
                </div>
              ) : null}
            </div>
            {this.props.isOpen ? (
              this.props.isLoading ? (
                <Loader inline />
              ) : (
                <div
                  className={`settings-card ${
                    this.props.customCardStyle ? 'col-xs-12' : 'mt-4 mb-3 col-xs-12'
                  }`}
                >
                  {this.props.children}
                </div>
              )
            ) : null}
            {this.props.isBusy ? <Loader /> : null}
          </div>
        </div>
        <style jsx>
          {`
            .panel {
              background: none !important;
              box-shadow: none !important;
            }
            .panel .panel-body {
              border-radius: 4px;
              background: white;
              border: 1px solid #dedfe1;
            }
            .inline-link {
              cursor: pointer;
            }
            .rotating-arrow {
              transition-duration: 0.3s;
            }
            .upside-down {
              transform: rotate(180deg);
            }
          `}
        </style>
      </div>
    );
  }
}

BaseCard.propTypes = {
  // Card specific props
  card: PropTypes.string,
  title: PropTypes.string,
  // Common props
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isBusy: PropTypes.bool,
  canToggle: PropTypes.bool,
  toggleCard: PropTypes.func,
  customCardStyle: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  router: PropTypes.object
};

export default withRouter(BaseCard);
