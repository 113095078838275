import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { parseCurrency } from './helpers';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import SecurityService from '../../../../Settings/SecurityService';

const OrderItemTable = ({ costBreakdown, orderBilling, showCustomerCosts }) => {
  const getColumns = () => {
    let cols = 8;
    if (!!SecurityService.isSuperAdmin()) {
      cols = cols + 3;
    }
    if (!!orderBilling.PartnerFees) {
      cols++;
    }
    if (!!orderBilling.PartnerDiscount) {
      cols++;
    }
    if (showCustomerCosts) {
      cols = cols + 5;
    }
    return cols;
  };
  return (
    <TableRow
      columns={`${'auto '.repeat(getColumns())}`}
      scrollableX
      size="small"
      className="order-details-breakdown-grid w-100"
    >
      <Cell sticky className="start-row order-item-id-key" header noTextWrap>
        Order Item ID
      </Cell>
      <Cell header className="start-row order-item-sku-key" noTextWrap>
        SKU
      </Cell>
      <Cell header className="start-row order-item-quantity-key" noTextWrap>
        Quantity
      </Cell>

      {!!SecurityService.isSuperAdmin() && (
        <>
          <Cell header className="start-row order-item-gooten-item-cost-key" noTextWrap>
            Gooten Item Cost
          </Cell>
          <Cell header className="start-row order-item-gooten-shipping-cost-key" noTextWrap>
            Gooten Shipping Cost
          </Cell>
          <Cell header className="start-row order-item-gooten-total-key" noTextWrap>
            Gooten Total
          </Cell>
        </>
      )}

      <Cell header className="start-row order-item-partner-item-cost-key" noTextWrap>
        Partner Item Cost
      </Cell>
      {!!orderBilling.PartnerFees && (
        <Cell header className="start-row order-item-partner-fees-key" noTextWrap>
          Partner Fees
        </Cell>
      )}
      {!!orderBilling.PartnerDiscount && (
        <Cell header className="start-row order-item-partner-discount-key" noTextWrap>
          Partner Discount
        </Cell>
      )}
      <Cell header className="start-row order-item-partner-shipping-cost-key" noTextWrap>
        Partner Shipping Cost
      </Cell>
      <Cell header className="start-row order-item-partner-surcharge-cost-key" noTextWrap>
        Partner Surcharge Cost
      </Cell>
      <Cell header className="start-row order-item-partner-tax-cost-key" noTextWrap>
        Partner Tax Cost
      </Cell>
      <Cell header className="start-row order-item-partner-total-cost-key" noTextWrap>
        Partner Total Cost
      </Cell>

      {showCustomerCosts && (
        <>
          <Cell header className="start-row order-item-customer-item-cost-key" noTextWrap>
            Customer Item Cost
          </Cell>
          <Cell header className="start-row order-item-customer-shipping-cost-key" noTextWrap>
            Customer Shipping Cost
          </Cell>
          <Cell header className="start-row order-item-customer-tax-cost-key" noTextWrap>
            Customer Tax Cost
          </Cell>
          <Cell header className="start-row order-item-customer-discount-key" noTextWrap>
            Customer Discount
          </Cell>
          <Cell header className="start-row order-item-customer-total-cost-key" noTextWrap>
            Customer Total Cost
          </Cell>
        </>
      )}

      {!!costBreakdown.length &&
        costBreakdown.map(orderItem => (
          <Fragment key={orderItem.OrderItemId}>
            <Cell sticky className="start-row order-item-id-value">
              {orderItem.OrderItemId}
            </Cell>
            <Cell noTextWrap className="order-item-sku-value">
              {orderItem.SKU}
            </Cell>
            <Cell className="order-item-quantity-value">{orderItem.Quantity}</Cell>

            {SecurityService.isSuperAdmin() ? (
              <>
                <Cell className="order-item-gooten-item-cost-value">
                  {parseCurrency(orderItem.GootenItemCost)}
                </Cell>
                <Cell className="order-item-gooten-shipping-cost-value">
                  {parseCurrency(orderItem.GootenShippingCost)}
                </Cell>
                <Cell className="order-item-gooten-total-value">
                  {parseCurrency(orderItem.GootenTotal)}
                </Cell>
              </>
            ) : null}

            <Cell className="order-item-partner-item-cost-value">
              {parseCurrency(orderItem.PartnerItemCost)}
            </Cell>
            {!!orderBilling.PartnerFees && (
              <Cell className="order-item-partner-fees-value">
                {parseCurrency(orderItem.PartnerFees)}
              </Cell>
            )}
            {!!orderBilling.PartnerDiscount && (
              <Cell className="order-item-partner-discount-value">
                {parseCurrency(orderItem.PartnerDiscount)}
              </Cell>
            )}
            <Cell className="order-item-partner-shipping-cost-value">
              {parseCurrency(orderItem.PartnerShippingCost)}
            </Cell>
            <Cell />
            <Cell className="order-item-partner-tax-cost-value">
              {parseCurrency(orderItem.PartnerTaxCost)}
            </Cell>
            <Cell className="order-item-partner-total-cost-value">
              {parseCurrency(orderItem.PartnerTotalCost)}
            </Cell>

            {showCustomerCosts && (
              <>
                <Cell className="order-item-customer-item-cost-value">
                  {parseCurrency(orderItem.CustomerItemCost)}
                </Cell>
                <Cell className="order-item-customer-shipping-cost-value">
                  {parseCurrency(orderItem.CustomerShippingCost)}
                </Cell>
                <Cell className="order-item-customer-tax-cost-value">
                  {parseCurrency(orderItem.CustomerTaxCost)}
                </Cell>
                <Cell className="order-item-customer-discount-value">
                  {parseCurrency(orderItem.DiscountAmount)}
                </Cell>
                <Cell className="order-item-customer-total-cost-value">
                  {parseCurrency(orderItem.CustomerTotalCost)}
                </Cell>
              </>
            )}
          </Fragment>
        ))}
      <Cell sticky className="start-row total-row order-item-id-total">
        Total
      </Cell>
      <Cell className="total-row">&nbsp;</Cell>
      <Cell className="total-row order-item-quantity-total">{orderBilling.TotalQuantity}</Cell>

      {SecurityService.isSuperAdmin() && (
        <>
          <Cell className="total-row order-item-gooten-item-cost-total">
            {parseCurrency(orderBilling.PrintioSubTotal)}
          </Cell>
          <Cell className="total-row order-item-gooten-shipping-cost-total">
            {parseCurrency(orderBilling.PrintioShipping)}
          </Cell>
          <Cell className="total-row order-item-gooten-total-total">
            {parseCurrency(orderBilling.PrintioTotal)}
          </Cell>
        </>
      )}

      <Cell className="total-row order-item-partner-item-cost-total">
        {parseCurrency(orderBilling.PartnerSubTotal)}
      </Cell>

      {!!orderBilling.PartnerFees && (
        <Cell className="total-row order-item-partner-fees-total">
          {parseCurrency(orderBilling.PartnerFees)}
        </Cell>
      )}
      {!!orderBilling.PartnerDiscount && (
        <Cell className="total-row order-item-partner-discount-total">
          {parseCurrency(orderBilling.PartnerDiscount)}
        </Cell>
      )}
      <Cell className="total-row order-item-partner-shipping-cost-total">
        {parseCurrency(orderBilling.PartnerShipping)}
      </Cell>
      <Cell className="total-row order-item-partner-surcharge-cost-total">
        {parseCurrency(orderBilling.ShippingSurcharge)}
      </Cell>
      <Cell className="total-row order-item-partner-tax-cost-total">
        {parseCurrency(orderBilling.PartnerTax)}
      </Cell>
      <Cell className="total-row order-item-partner-total-cost-total">
        {parseCurrency(orderBilling.PartnerTotal)}
      </Cell>

      {showCustomerCosts && (
        <>
          <Cell className="total-row order-item-customer-item-cost-total">
            {parseCurrency(orderBilling.CustomerSubTotal)}
          </Cell>
          <Cell className="total-row order-item-customer-shipping-cost-total">
            {parseCurrency(orderBilling.CustomerShipping)}
          </Cell>
          <Cell className="total-row order-item-customer-tax-cost-total">
            {parseCurrency(orderBilling.CustomerTax)}
          </Cell>
          <Cell className="total-row order-item-customer-discount-total">
            {parseCurrency(orderBilling.CustomerDiscount)}
          </Cell>
          <Cell className="total-row order-item-customer-total-cost-total">
            {parseCurrency(orderBilling.CustomerTotal)}
          </Cell>
        </>
      )}
      <style jsx>
        {`
          :global(.order-details-breakdown-grid) {
            overflow-x: auto;
          }
          :global(.total-row) {
            box-shadow: inset 0px 1px 0px #b1b5b9 !important;
          }
          :global(.order-details-breakdown-grid .header-cell.table-cell) {
            padding: 0 1rem 0 0.5rem !important;
          }
        `}
      </style>
    </TableRow>
  );
};

OrderItemTable.propTypes = {
  orderBilling: PropTypes.object,
  costBreakdown: PropTypes.array,
  showCustomerCosts: PropTypes.bool
};

export default OrderItemTable;
