import { connect } from 'react-redux';
import HistoryView from './HistoryView';
import { historyViewData } from './HistoryViewSelectors';
import {
  fetchHistoryDataAsync,
  setFilters,
  updatePageNumber,
  updateOrderId
} from './HistoryViewActions';

const mapDispatchToProps = {
  fetchHistoryDataAsync: () => fetchHistoryDataAsync(),
  setFilters: data => setFilters(data),
  updatePageNumber: pageNumber => updatePageNumber(pageNumber),
  updateOrderId: orderId => updateOrderId(orderId)
};

const mapStateToProps = state => {
  return {
    historyViewData: historyViewData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryView);
