import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'gooten-components/src/components/shared/Toast';

class SimpleNotification extends React.PureComponent {
  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate() {
    this.startTimer();
  }

  startTimer = () => {
    setTimeout(() => {
      this.props.onDurationEnd();
    }, this.props.duration);
  };

  render() {
    return (
      <div>
        <Toast
          className="simple-notification-container"
          isOpen
          closeClick={this.props.showCloseButton ? () => this.props.onDurationEnd() : null}
          key="simple-notification"
        >
          {this.props.title}
        </Toast>
        <style jsx>
          {`
            div {
              position: fixed !important;
              top: 4rem;
              width: 100%;
            }
          `}
        </style>
      </div>
    );
  }
}

SimpleNotification.propTypes = {
  title: PropTypes.string,
  duration: PropTypes.number,
  onDurationEnd: PropTypes.func,
  showCloseButton: PropTypes.bool
};

export default SimpleNotification;
