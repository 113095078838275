import { ToolbarOptions } from './components/toolbar/toolbar.options';
import { PreviewOptions } from './components/preview/preview.options';
// import TemplateMappingService from './services/data/templateMapping.service';

export class ImageEditorOptions {
  width: number;
  height: number;
  maximizedWidth: number;
  maximizedHeight: number;
  // CSS Selector for Container
  container: string;
  // HTML Element for Container, will be set in ctor
  domContainer: HTMLElement;
  validationShown: boolean;
  zoomControlShown: boolean;
  previewControlShown: boolean;
  toolbarShown: boolean;

  toolbarOptions: ToolbarOptions;
  previewControlOptions: PreveiwOptions;

  template: any;
  dumpedState: any;

  // restore editor to maximized state
  maximized: boolean;
  // restore editor to product|print preview
  preview: string;

  // Allow add more than 1 image to image layer
  allowMultiImages: boolean;

  // Enable CORS for image loading
  cors: boolean;

  //TODO: implement callbacks
  onInit: any;

  constructor(config: any) {
    config = config || {};
    this.width = config.width || 300;
    this.height = config.height || 500;
    this.maximizedWidth = config.maximizedWidth || 700;
    this.maximizedHeight = config.maximizedHeight || 1000;
    this.allowMultiImages = config.allowMultiImages || false;

    if (config.container) {
      this.container = config.container;
    }
    else {
      throw 'Gooten Image Editor: "container" is required in configuration';
    }

    if (config.template) {
      // TODO: Use TemplateMappingService.mapTemplateSpaceData(config.template);
      // to map from productBuildInfo.Spaces[0] to editor template format
      this.template = config.template;
    }
    else if(config.state) {
      this.dumpedState = config.state;
      this.maximized = config.state.editor.maximized;
      this.preview = config.state.editor.preview;
    }
    else {
      throw 'Gooten Image Editor: "template" or "state" is required in configuration';
    }

    //set default value to true if not specified
    this.validationShown = config.validationShown === undefined ? true : config.validationShown;
    this.zoomControlShown = config.zoomControlShown === undefined ? true : config.zoomControlShown;
    this.previewControlShown = config.previewControlShown === undefined ? true : config.previewControlShown;
    this.toolbarShown = config.toolbarShown === undefined ? true : config.toolbarShown;

    this.cors = config.cors || false;
    this.toolbarOptions = new ToolbarOptions(config.toolbarOptions, this);
    this.previewControlOptions = new PreviewOptions(config.previewControlOptions, this);
    this.onInit = config.onInit || function(){};
  }
}
