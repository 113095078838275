import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

export const periodsSelector = () => fromJS(['Last 7 days', 'Last 30 days', 'Last 3 months']);

export const tableHeaderSelector = () =>
  fromJS(['PRODUCT', '0 - 3 DAYS', '4 - 5 DAYS', '6 - 8 DAYS', '9+ DAYS']);

export const tableMaxItemsSelector = () => 10;

const reportStateSelector = state => state.get('productionReport');

export const errorSelector = createSelector([reportStateSelector], state => state.get('error'));

export const inProgressSelector = createSelector([reportStateSelector], state =>
  state.get('inProgress')
);

export const selectedPeriodSelector = createSelector([reportStateSelector], state =>
  state.get('period')
);

export const sortSelector = createSelector([reportStateSelector], state => state.get('sort'));

const sortProductData = (products, sort) => {
  const sortPercents = pos =>
    products.sort((a, b) => {
      const aValue = parseInt(a.getIn(['percents', pos])) || 0;
      const bValue = parseInt(b.getIn(['percents', pos])) || 0;
      return aValue - bValue;
    });

  const percentPositions = {
    '0-3days': 0,
    '4-5days': 1,
    '6-8days': 2,
    '9+days': 3
  };
  if (sort.get('type') === 'product') {
    return sort.get('aToZ')
      ? products.sortBy(p => p.get('name'))
      : products.sortBy(p => p.get('name')).reverse();
  }
  return sort.get('aToZ')
    ? sortPercents(percentPositions[sort.get('type')]).reverse()
    : sortPercents(percentPositions[sort.get('type')]);
};

const mapProductsData = products => {
  const tableValue = data =>
    data === 0 ? '-' : Math.round(data) === 0 ? '<1%' : `${Math.round(data)}%`;

  return products.map(p =>
    fromJS({
      name: p.get('PRODUCT'),
      percents: [
        tableValue(p.get('AVG_PERCENT_OF_0_3')),
        tableValue(p.get('AVG_PERCENT_OF_4_5')),
        tableValue(p.get('AVG_PERCENT_OF_6_8')),
        tableValue(p.get('AVG_PERCENT_OF_9_PLUS'))
      ]
    })
  );
};

export const dataExistSelector = createSelector(reportStateSelector, state => {
  // return true if data exist for at least one filter range...
  return state.get('data').size;
});

export const dataSelector = createSelector(
  [reportStateSelector, selectedPeriodSelector, sortSelector],
  (state, period, sort) => {
    const periods = ['7_day', '30_day', '3_month'];
    const filteredData = state.get('data').find(d => d.get('FILTER_RANGE') === periods[period]);
    return filteredData
      ? sortProductData(mapProductsData(filteredData.get('PRODUCTS')), sort)
      : fromJS([]);
  }
);
