import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'HELP_DATA');

export const UPDATE_PAGE_NUMBER = getActionName('UPDATE_PAGE_NUMBER');
export const FETCH_HELP_DATA = getActionName.async('FETCH_HELP_DATA');
export const SEARCH_START = getActionName('SEARCH_START');
export const UPDATE_SEARCH_DATA = getActionName('UPDATE_SEARCH_DATA');
export const UPDATE_HELP_DATA = getActionName('UPDATE_HELP_DATA');

export function fetchHelpData() {
  return {
    type: FETCH_HELP_DATA.ASYNC
  };
}

export function updateHelpData(data) {
  return {
    type: UPDATE_HELP_DATA,
    payload: data
  };
}
