import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'gooten-components/src/components/shared/SearchInput';

import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';

export const SearchBarMobile = props => {
  const searchBarClass = props.isOpen ? '' : 'hidden-xs';
  return (
    <div className="search-bar-mobile-container">
      <SearchInput
        searchText={props.searchText}
        className={searchBarClass}
        onSearchChange={props.doSearch}
        onSearchClick={props.toggle}
      />
      {!props.isOpen && (
        <Button iconOnly className="button-default large visible-xs" onClick={() => props.toggle()}>
          <Icon icon="search" />
        </Button>
      )}
    </div>
  );
};

SearchBarMobile.propTypes = {
  doSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default SearchBarMobile;
