import PositioningService from '../../../utils/positioningService';
import { polyIntersect } from '../../../utils/math';

export default class ZoomService {
  /**
   * Return editor scale ofr print/product preview
   * @param {Object} state
   */
  static getEditorScale(state) {
    if (state.editor.preview === 'print') {
      return Math.min(
        state.editor.width/(state.template.finalX2 - state.template.finalX1),
        state.editor.height/(state.template.finalY2 - state.template.finalY1)
      );
    }
    else {
      return state.template.scale;
    }
  }

  /**
   * Return true if scaled image is NOT overlapping with viewport
   * @param {Object} crop
   * @param {Object} state
   */
  static isZoomedImageOutsideOfViewport(scale: number, image: any, state: any): boolean {
    // form viewport polygon in template sizes
    const viewportRect = state.template.layers.find(
      l => l.id === state.images.current.selected.layerId);
    const viewportPolygon = [
      {x: viewportRect.x1, y: viewportRect.y1},
      {x: viewportRect.x2, y: viewportRect.y1},
      {x: viewportRect.x2, y: viewportRect.y2},
      {x: viewportRect.x1, y: viewportRect.y2}
    ];

    // form zoomed image polygon in template sizes
    const rotatedTopLeft = PositioningService.getRotatedPoint(
      { x: image.x - image.sourceWidth*scale/2, y: image.y - image.sourceHeight*scale/2 },
      image,
      -image.rotation || 0
    );
    const rotatedTopRight = PositioningService.getRotatedPoint(
      { x: image.x + image.sourceWidth*scale/2, y: image.y - image.sourceHeight*scale/2 },
      image,
      -image.rotation || 0
    );
    const rotatedBottomRight = PositioningService.getRotatedPoint(
      { x: image.x + image.sourceWidth*scale/2, y: image.y + image.sourceHeight*scale/2 },
      image,
      -image.rotation || 0
    );
    const rotatedBottomLeft = PositioningService.getRotatedPoint(
      { x: image.x - image.sourceWidth*scale/2, y: image.y + image.sourceHeight*scale/2 },
      image,
      -image.rotation || 0
    );

    const imageAreaPolygon = [
      rotatedTopLeft,
      rotatedTopRight,
      rotatedBottomRight,
      rotatedBottomLeft
    ];

    // check if they are collide
    return !polyIntersect(viewportPolygon, imageAreaPolygon);
  }

  static getDpiValue(scale: number, image: any, state: any): number {
    if (!state.template.dpi) {
      return null;
    }

    return Math.round(state.template.dpi / scale);
  }
}
