import { connect } from 'react-redux';
import EditProductView from './EditProductView';
import { currentStepSelector, selectedProductForEditSelector } from './EditProductViewSelectors';
import { changeStep } from './EditProductViewActions';
import { allStoresSelector } from '../HubView/components/Stores/StoresSelectors';

const mapDispatchToProps = {
  changeStep: step => changeStep(step)
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedProduct: selectedProductForEditSelector(state),
    stores: allStoresSelector(state),
    currentStep: currentStepSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProductView);
