import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

export const LinkButton = props => {
  return (
    <div
      className={`mt-1 mb-1 link-button ${props.class && props.class.length ? props.class : ''}`}
    >
      <Button
        iconLeft={props.icon}
        href="javascript:void(0)"
        className={`${
          props.className || ''
        } ${props.title.toLowerCase()}-action button-default medium`}
        onClick={() => props.onClick()}
      >
        {props.title}
      </Button>
    </div>
  );
};

LinkButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  class: PropTypes.string,
  className: PropTypes.string
};

export default LinkButton;
