import { createSelector } from 'reselect';
import { itemsDataSelector } from 'gooten-components/src/store/selectors/hubSelectors';

export const orderItemsViewSelector = state => state.getIn(['orders', 'orderItemsSelectionView']);

/**
 * Return order items grouped by Vendor
 */
export const groupedItemsSelector = createSelector([itemsDataSelector], items => {
  const groupedItems = items.reduce(
    (acc, item) => ({
      ...acc,
      [item.VendorId]: [...(acc[item.VendorId] || []), item]
    }),
    {}
  );

  return Object.values(groupedItems).map(items => ({
    vendorId: items[0].VendorId,
    name: items[0].Name,
    items
  }));
});

export const orderItemsArchivedStatuses = createSelector([orderItemsViewSelector], orderItemsView =>
  orderItemsView.get('archivedStatuses')
);

export const orderItemsSelectionLoading = createSelector(
  [orderItemsArchivedStatuses],
  archivedStatuses =>
    archivedStatuses.some(as => {
      return as.get('isLoading');
    })
);
