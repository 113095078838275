import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BigCommerceConnect from './BigCommerceConnect';
import { initStoreConnect, connectStore } from '../ConnectStoreViewActions';

const mapStateToProps = state => ({ state: state.getIn(['hub', 'connectStoreView']).toJS() });

const mapDispatchToProps = {
  init: () => initStoreConnect('bigcommerce'),
  connectStore: storeName => connectStore('bigcommerce', storeName)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BigCommerceConnect));
