import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Icon from './Icon.js';
import Button from './Button';

const SidePanel = ({
  isOpen,
  className,
  children,
  title,
  cancelClick,
  cancelText,
  actionText,
  actionClick
}) => {
  return (
    <>
      {isOpen && (
        <div className={`${className || ''} sidepanel-container`}>
          <div className="sidepanel-backdrop" onClick={cancelClick} />
          <div className="sidepanel dropshadow-3">
            {title && (
              <div className="sidepanel-head d-flex ml-3 title-2">
                <div className="title-text"> {title} </div>
              </div>
            )}
            <Button onClick={cancelClick} iconOnly className="close-sidepanel button-white medium">
              <Icon icon="x" />
            </Button>
            <div className="sidepanel-body pt-3">{children}</div>
            {(cancelText || actionText) && (
              <div className="sidepanel-buttons p-3 d-flex justify-content-between">
                {cancelClick && (
                  <Button className="button-default large" onClick={cancelClick}>
                    {cancelText || 'Cancel'}
                  </Button>
                )}
                {actionClick && (
                  <Button className="button-primary large ml-2" onClick={actionClick}>
                    {actionText || 'Cancel'}
                  </Button>
                )}
              </div>
            )}
          </div>
          <style jsx>
            {`
              .sidepanel-backdrop {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: ${COLORS.overlay500};
                z-index: 1009;
              }
              .sidepanel {
                width: 23rem;
                background: white;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1010;
                animation-duration: 0.5s;
                animation-name: slide-in;
                animation-timing-function: ease-in-out;
              }
              @keyframes slide-in {
                from {
                  transform: translateX(23rem);
                }
                to {
                  transform: translateX(0);
                }
              }
              @media screen and (max-width: 576px) {
                .sidepanel {
                  width: 100%;
                }
              }
              .sidepanel-head {
                height: 3.5rem;
                min-height: 3.5rem;
                text-align: left;
                padding-right: 3.5rem;
                align-items: center;
                white-space: nowrap;
              }
              .title-text {
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .sidepanel-head[type='text'] {
                text-overflow: ellipsis;
              }
              .sidepanel :global(.close-sidepanel) {
                position: absolute;
                right: 0.75rem;
                top: 0.75rem;
                line-height: 0;
                cursor: pointer;
                fill: ${COLORS.neutralDark200};
              }
              .sidepanel-body {
                height: fit-content;
                border-top: 1px solid ${COLORS.neutralLight200};
                overflow: auto;
                height: calc(100% - 8rem);
              }
              .sidepanel-buttons {
                height: 4.5rem;
                border-top: 1px solid ${COLORS.neutralLight200};
              }
            `}
          </style>
        </div>
      )}
    </>
  );
};

SidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  cancelClick: PropTypes.func,
  cancelText: PropTypes.string,
  actionText: PropTypes.string,
  actionClick: PropTypes.func
};

export default SidePanel;
