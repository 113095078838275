import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropDown } from 'gooten-components/src/components/shared/DropDown/index';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import ConnectStore from 'gooten-components/src/components/Stores/atoms/ConnectStore';
import DisconnectStore from 'gooten-components/src/components/Stores/atoms/DisconnectStore';
import SelectPlatform from 'gooten-components/src/components/Stores/atoms/SelectPlatform';
import Alert from 'gooten-components/src/components/shared/Alert';
import Loader from 'gooten-components/src/components/shared/Loader';
import Button from 'gooten-components/src/components/shared/Button';

import etsyThumb from 'gooten-components/src/components/Stores/atoms/SelectPlatform/assets/etsy.png';
import shopifyThumb from 'gooten-components/src/components/Stores/atoms/SelectPlatform/assets/shopify.png';
import wooThumb from 'gooten-components/src/components/Stores/atoms/SelectPlatform/assets/woo.png';
import bigCommerceThumb from 'gooten-components/src/components/Stores/atoms/SelectPlatform/assets/bigcommerce.png';
import { COLORS } from 'gooten-components/src/constants';

import './Stores.scss';
import PageBody from 'gooten-components/src/components/shared/PageBody';
import ConnectSuccessModal from '../Hub/ConnectStoreView/shared/ConnectSuccessModal';
import Config from '../config';

const Stores = ({
  storesLoaded,
  errorMsg,
  successMsg,
  allStores,
  busy,
  disconnectProvider,
  connectStep,
  reset,
  fetchStores,
  setConnectStep,
  disconnectStorePrompt,
  location
}) => {
  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    setConnectStep(0);
  }, []);

  const cbhSettings = Config.get('cbhSettings').toJS();

  return (
    <>
      <style jsx>{`
        .wrapper {
          margin-left: auto;
          margin-right: auto;
          max-width: 800px;
          width: 100%;
        }

        .store {
          border: 1px solid gray;
          border-radius: 0.25rem;
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          margin-top: 1rem;
          padding: 0.5rem;
        }

        .store-icon {
          display: grid;
          justify-items: center;
        }

        .store-icon {
          background-size: cover;
          border: 1px solid lightgray;
          height: 48px;
          width: 48px;
        }

        .store-icon.etsy {
          background-image: url('${etsyThumb}');
        }

        .store-icon.shopify {
          background-image: url('${shopifyThumb}');
        }

        .store-icon.woocommerce {
          background-image: url('${wooThumb}');
        }

        .store-icon.bigcommerce {
          background-image: url('${bigCommerceThumb}');
        }

        .name {
          display: grid;
          grid-column: span 4;
        }

        .synced {
          display: grid;
          justify-items: center;
        }

        .synced a {
          font-weight: bold;
        }

        .error {
          align-content: center;
          display: grid;
          justify-items: center;
        }

        .error span {
          background-color: pink;
          border-radius: 1rem;
          color: red;
          grid-column: span 2;
          padding: 0.25rem;
        }

        .menu {
          text-align: right;
        }

        .no-stores {
          background-color: ${COLORS.gootenBlue300};
          border: 1px solid ${COLORS.gootenBlue300};
          border-radius: 0.5rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 0 2rem;
        }

        .no-store-text {
          padding: 2rem 0;
        }

        .no-stores-blurb {
          color: ${COLORS.neutralDark200};
          margin-bottom: 2rem;
        }
      `}</style>
      <div className="wrapper">
        <PageHeader
          title="Sales Channels"
          primaryText={'Connect new store'}
          primaryClick={allStores.length > 0 && storesLoaded && (() => setConnectStep(1))}
        />

        <PageBody className="stores">
          {errorMsg && connectStep === 0 && (
            <Alert isOpen className="alert" type="important" closeClick={() => reset()}>
              {errorMsg}
            </Alert>
          )}
          {successMsg && (
            <Alert isOpen className="alert" type="good" closeClick={() => reset()}>
              {successMsg}
            </Alert>
          )}

          {allStores.length === 0 && storesLoaded && (
            <div className="no-stores">
              <div className="no-store-text">
                <div className="title-1">Connect your store</div>
                <div className="body-text-1 no-stores-blurb">
                  Easily connect your Shopify, Etsy, WooCommerce, or BigCommerce store.
                </div>
                <Button className="button-primary large" onClick={() => setConnectStep(1)}>
                  Connect new store
                </Button>
              </div>
              {cbhSettings.Images.showNoStoresImage && <div className="no-stores-image" />}
            </div>
          )}

          {allStores.map(store => (
            <div className="store" key={store.id}>
              <div className={`store-icon ${store.provider}`} />
              <div className="name">
                <strong>{store.storeName}</strong>
                <small>{store.provider}</small>
              </div>
              <div className="synced">
                <a href={`/#/hub/${store.provider}/${store.id}`}>
                  {store.settings.connected_products_count}
                </a>
                <div>synced</div>
              </div>
              <div className="error" />
              <div className="menu">
                <DropDown title="Store" buttonOnly>
                  <ListItem
                    text="Remove store"
                    onSelect={() => disconnectStorePrompt(store.provider, store.id)}
                  />
                </DropDown>
              </div>
            </div>
          ))}
        </PageBody>
      </div>
      <SelectPlatform />
      {location.query.storeId && <ConnectSuccessModal />}
      <ConnectStore />
      {disconnectProvider && <DisconnectStore />}
      {busy && <Loader />}
    </>
  );
};

Stores.propTypes = {
  storesLoaded: PropTypes.bool,
  busy: PropTypes.bool,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  allStores: PropTypes.array.isRequired,
  disconnectProvider: PropTypes.string,
  connectStep: PropTypes.number,
  reset: PropTypes.func.isRequired,
  fetchStores: PropTypes.func.isRequired,
  setConnectStep: PropTypes.func.isRequired,
  disconnectStorePrompt: PropTypes.func.isRequired
};

export default Stores;
