import React from 'react';
import PropTypes from 'prop-types';
import ProductImage from '../atoms/shared/ProductImage';
import ProductName from '../atoms/shared/ProductName';
import ProductDescription from '../atoms/shared/ProductDescription';
import ProductVariants from '../atoms/shared/ProductVariants';
import DuplicateChanges from '../atoms/shared/DuplicateChanges';
import { windowWidth } from '../../shared/ComponentsHooks';
import Config from '../../../config';

import Mockups from '../atoms/Mockups';
import Design from '../atoms/Design';
import NeckLabels from '../atoms/NeckLabels';
import ProvidersSelectionPanel from '../atoms/ProvidersSelectionPanel';
import Advanced from '../atoms/Advanced';

const provider = props => {
  const width = windowWidth();
  const mockupsAndDesign = (
    <React.Fragment>
      <Mockups maxNumberOfImages={9999} hideUploadMockups />
      <Design showViewAll />
      <Advanced />
      <NeckLabels />
    </React.Fragment>
  );

  return (
    <div className="platform-form flex-container">
      <div className="product-settings">
        <ProductImage />
        {width <= 992 && !props.providersTabHidden ? <ProvidersSelectionPanel /> : null}
        <ProductName storage />
        <ProductDescription storage />
        <DuplicateChanges options={['productName', 'productDesc']} />
        {width <= 992 ? (
          <div className="mockups-design-mobile-holder flex-container">{mockupsAndDesign}</div>
        ) : null}
        <ProductVariants
          storage
          disableChangeMockup
          disableRemovingSkus={!Config.get('editMode')}
          disableRenamingSkus={Config.get('editMode')}
        />
        <DuplicateChanges options={['variants']} />
      </div>
      {width > 992 ? (
        <div className="mockups-design-holder flex-container">
          {props.providersTabHidden ? null : <ProvidersSelectionPanel />}
          {mockupsAndDesign}
        </div>
      ) : null}
    </div>
  );
};

provider.propTypes = {
  providersTabHidden: PropTypes.bool,
  isMultiProduct: PropTypes.bool
};

export default provider;
