import StateManager from '../../../state/stateManager';
import { editorReconstitute } from '../../../state/actions/actionCreators';
import PositioningService from '../../../utils/positioningService';
//import {PreviewOptions} from '../preview.options';

export class MaximizeButton {

  customClass: string;
  name: string;
  maximized: boolean;
  element: any;
  constructor(config: any, stateManager: StateManager) {
    this.config = config || {};
    this.maximized = config.editorConfig.maximized === undefined ? false : config.editorConfig.maximized;
    this.customClass = this.config.customClass || '';
    this.stateManager = stateManager;
  }

  getElement() {
    let element = document.createElement('div');
    element.className += ` editor-btn ${this.customClass}`;

    if (this.maximized) {
      this.config.editorConfig.domContainer.style.width = `${this.config.editorConfig.maximizedWidth}px`;
      // NOTE: Don't set height since it is equal to canvas height and total editor height is bigger
      // this.config.editorConfig.domContainer.style.height = `${this.config.editorConfig.maximizedHeight}px`;
      this.maximized = true;
      element.innerHTML = '<div class="maximize"><div class="icon-collapse icon"></div></div>';
    }
    else {
      this.config.editorConfig.domContainer.style.width = `${this.config.editorConfig.width}px`;
      // NOTE: Don't set height since it is equal to canvas height and total editor height is bigger
      // this.config.editorConfig.domContainer.style.height = `${this.config.editorConfig.height}px`;
      element.innerHTML = '<div class="maximize"><div class="icon-expand icon"></div></div>';
    }

    element.addEventListener('click', () => this.action());

    this.element = element;
    return element;
  }

  //TODO: implement minimize, add max min to state
  action() {
    const state = this.stateManager.getState();
    if( this.maximized ) {
      this.config.editorConfig.domContainer.style.width = `${this.config.editorConfig.width}px`;
      // NOTE: Don't set height since it is equal to canvas height and total editor height is bigger
      // this.config.editorConfig.domContainer.style.height = `${this.config.editorConfig.height}px`;
      let payload = PositioningService.reconstituteEditor(
        state,
        this.config.editorConfig.width,
        this.config.editorConfig.height
      );
      this.stateManager.dispatchAction(
        editorReconstitute({
          ...payload,
          maximized: false
        })
      );
      this.maximized = false;
      this.element.innerHTML = '<div class="maximize"><div class="icon-expand icon"></div></div>';
    }
    else {
      this.config.editorConfig.domContainer.style.width = `${this.config.editorConfig.maximizedWidth}px`;
      // NOTE: Don't set height since it is equal to canvas height and total editor height is bigger
      // this.config.editorConfig.domContainer.style.height = `${this.config.editorConfig.maximizedHeight}px`;
      let payload = PositioningService.reconstituteEditor(
        state,
        this.config.editorConfig.maximizedWidth,
        this.config.editorConfig.maximizedHeight
      );
      this.stateManager.dispatchAction(
        editorReconstitute({
          ...payload,
          maximized: true
        })
      );
      this.maximized = true;
      this.element.innerHTML = '<div class="maximize"><div class="icon-collapse icon"></div></div>';
    }
  }
}
