const Rules = {
  mockups: {
    numberOfMockupsExceeded: (size, maxNumber) => size > maxNumber
  },
  productName: {
    maxLengthExceeded: (nameLength, maxLength) => nameLength > maxLength,
    isRegexValid: (regex, name) => (regex ? !!name.match(regex) : true),
    isNameEmpty: name => !name?.length
  },
  productDescription: {
    isDescriptionRequired: (required, description) =>
      required && (!description.length || description === '<p><br></p>')
  },
  productTags: {
    allowNewTags: (tagsSize, maxNumber) => tagsSize <= (maxNumber || 999),
    tagLengthExceeded: (tags, maxLength) => tags.some(tag => tag.length > maxLength),
    isRegexValid: (regex, name) => (regex ? !!name.match(regex) : true)
  },
  productCollections: {
    collectionLengthExceeded: (collections, maxLength) =>
      collections.some(coll => coll.length > maxLength)
  },
  productVariants: {
    numberOfSkusExceeded: (size, maxNumber) => size > maxNumber,
    anySkuEmpty: skus => skus.some(sku => !sku.get('customSku').length),
    duplicatedSkus: skus =>
      Array.from(new Set(skus.map(s => s.get('customSku')))).length !== skus.size
  }
};

export default Rules;
