import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Button from '../Button';
import braintree from 'braintree-web';

class PayPalButton extends React.Component {
  componentDidMount() {
    const component = this;
    const onChange = this.props.onChange;
    const container = this.container;
    braintree.setup(this.props.token, 'custom', {
      onReady: function (integration) {
        component.integration = integration;
      },
      paypal: {
        container: container,
        singleUse: false,
        headless: true,
        billingAgreementDescription: 'Your agreement description',
        locale: 'en_US'
      },
      dataCollector: {
        paypal: true
      },
      onPaymentMethodReceived: function (obj) {
        const nonce = obj.nonce;
        onChange(nonce);
      }
    });
  }

  render() {
    return (
      <div className="paypal-field">
        <Button
          className="button-default large add-paypal"
          id="paypal-container"
          forwardRef={ref => {
            this.container = ref;
          }}
          onClick={() => this.integration && this.integration.paypal.initAuthFlow()}
        >
          Connect PayPal
        </Button>
      </div>
    );
  }
}

PayPalButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

export default PayPalButton;
