import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import SpinnerSmall from '../SpinnerSmall';
import ImageThumb from '../ImageThumb';
import './OrderSummary.scss';
import { formatPrice } from '../../../utils/price';
import Strings from './strings';
import Button from '../Button';
import { COLORS } from '../../../constants';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import Config from '../../../config';

class OrderSummary extends React.PureComponent {
  formatTax(tax) {
    if (tax === undefined) {
      return '$-.--';
    }

    let taxFormatted = formatPrice(tax);

    // Need to override the $-.-- returned from formatPrice since tax was calculated at $0.00
    if (taxFormatted === '$-.--') {
      return '$0.00';
    }

    return taxFormatted;
  }

  getAutomateSalesTaxKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047812952-Gooten-s-Partnering-with-Avalara-to-Automate-Sales-Tax';

    switch (partnerGroup.get('Id')) {
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7613642598299-Gooten-s-Partnering-with-Avalara-to-Automate-Sales-Tax';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047812952-Gooten-s-Partnering-with-Avalara-to-Automate-Sales-Tax';
    }
  };

  render() {
    let loadingSpinnerSmall = <SpinnerSmall className="spinner-margin" />;

    let itemsInCartCount =
      this.props.itemsInCartCount > 1 ? (
        <span className="bold gray">{` (${this.props.itemsInCartCount})`}</span>
      ) : null;

    let checkoutModeEditButton = (
      <Button className="button-default medium" onClick={this.props.onClickEdit}>
        {Strings.EDIT}
      </Button>
    );

    let checkoutModeCartItems = null;
    if (this.props.isCheckout) {
      checkoutModeCartItems = (
        <div className="cart-items-container">
          <hr />
          <div className="summary-item">
            {this.props.itemsInCart.map((item, index) => (
              <div key={index} className="row no-gutters product-preview">
                <div className="col-md-3 product-thumbnail">
                  <ImageThumb
                    loading={item.get('previewImageUrl') === undefined}
                    imageUrl={item.get('previewImageUrl')}
                  />
                </div>
                <div className="col-md-6 product-info">
                  <div className="product-name">{item.get('name')}</div>
                  <div className="product-quantity">
                    {`${Strings.QUANTITY}${item.get('quantity')}`}
                  </div>
                </div>
                <div className="col-md-3 product-price">
                  {formatPrice(item.getIn(['pricing', 'PartnerPrice', 'Price']))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    let displayErrorsIfAny;
    if (this.props.orderSummaryErrors) {
      let errors = this.props.orderSummaryErrors
        .filter(error => {
          const errorMessage = error.get('Message') || error.get('ErrorMessage');
          return !errorMessage.includes('unavailable for');
        })
        .map((error, index) => {
          const errorMessage = error.get('Message') || error.get('ErrorMessage');
          return <div key={index}>{errorMessage}</div>;
        });

      if (errors.size > 0) {
        displayErrorsIfAny = (
          <div>
            <hr />
            <div className="summary-item">{errors}</div>
          </div>
        );
      }
    }

    return (
      <div className="order-summary-container">
        <style jsx>
          {`
            .tax-callout {
              background-color: ${COLORS.gootenBlue200};
              border-radius: 6px;
              color: ${COLORS.gootenBlue500};
              padding: 1rem;
            }
          `}
        </style>
        <div className="title-container flex-container flex-center-aligned">
          <div className="title-2">Order Summary</div>
          {this.props.isCheckout ? checkoutModeEditButton : null}
        </div>
        {this.props.isCheckout ? checkoutModeCartItems : null}
        <hr />
        <div className="summary-item cof-subtotal-row">
          <span className="bold cof-subtotal-key">Subtotal</span>
          {itemsInCartCount}
          <span className="bold pull-right cof-subtotal-value">
            {this.props.isLoading ? loadingSpinnerSmall : formatPrice(this.props.subtotal)}
          </span>
        </div>
        <div className="summary-item cof-shipping-row">
          <span className="cof-shipping-key">Shipping</span>
          <span className="pull-right cof-shipping-value">
            {this.props.isLoading ? loadingSpinnerSmall : formatPrice(this.props.shipping)}
          </span>
        </div>
        {this.props.surcharge ? (
          <div className="summary-item cof-surcharge-row">
            <span className="cof-surcharge-key">Surcharge </span>
            <>
              <Tooltip position="top" content="Extra surcharge cost for US shipments">
                <Icon className="tax-info-icon" icon="info" height={16} width={16} />
              </Tooltip>
            </>
            <span className="pull-right cof-surcharge-value">
              {formatPrice(this.props.surcharge)}
            </span>
          </div>
        ) : null}
        <div className="summary-item cof-estimated-taxes-row">
          <span className="cof-estimated-value-key">Estimated Taxes</span>
          {this.props.tax && this.props.tax.Jurisdiction !== '' && (
            <>
              <span className="tax-detail">({this.props.tax.Rate}%)</span>
              <Tooltip position="top" content={this.props.tax.Jurisdiction}>
                <Icon className="tax-info-icon" icon="info" height={16} width={16} />
              </Tooltip>
            </>
          )}
          <span className="pull-right cof-estimated-taxes-value">
            {this.props.isLoading ? loadingSpinnerSmall : this.formatTax(this.props.tax?.Total)}
          </span>
        </div>
        <hr />
        <div className="summary-item cof-estimated-total-row">
          <span className="bold cof-estimated-total-key">Estimated Total</span>
          <span className="bold pull-right cof-estimated-total-value">
            {this.props.isLoading ? loadingSpinnerSmall : formatPrice(this.props.total)}
          </span>
        </div>
        <div className="tax-callout">
          Increase profits by applying for sales tax exemption.{' '}
          <a target="_blank" href={this.getAutomateSalesTaxKbLink()}>
            Learn more
          </a>
        </div>
        {displayErrorsIfAny}
      </div>
    );
  }
}

OrderSummary.propTypes = {
  isLoading: PropTypes.bool,
  itemsInCart: PropTypes.instanceOf(List),
  itemsInCartCount: PropTypes.number,
  subtotal: PropTypes.number,
  shipping: PropTypes.number,
  surcharge: PropTypes.number,
  tax: PropTypes.object,
  total: PropTypes.number,
  orderSummaryErrors: PropTypes.instanceOf(List),
  isCheckout: PropTypes.bool,
  onClickEdit: PropTypes.func
};

export default OrderSummary;
