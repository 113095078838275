import { createSelector } from 'reselect';
import initialState from './../../store/initialState';

const ordersListSelector = state => state.getIn(['orders', 'ordersView']);
const defaultSearchFields = initialState.getIn(['orders', 'ordersView', 'searchFields']);

export const ordersList = createSelector([ordersListSelector], orders => orders.get('searchData'));

export const searchFields = createSelector([ordersListSelector], searchFields =>
  searchFields.get('searchFields')
);

export const searchLoading = createSelector([ordersListSelector], orders =>
  orders.get('searchLoading')
);

export const ordersViewErrors = createSelector([ordersListSelector], orders =>
  orders.get('errors')
);

export const noOrders = createSelector([ordersListSelector], orders => {
  const searchFields = JSON.stringify(orders.get('searchFields').toJS());
  const initialSearchFields = JSON.stringify(defaultSearchFields.toJS());

  const i =
    searchFields === initialSearchFields &&
    (orders.getIn(['searchData', 'total']) === 0 || orders.getIn(['searchData', 'total']) === null);

  return i;
});
