import { tierRanks } from 'gooten-components/src/components/shared/PartnerTier';

const annual =
  new Date().getFullYear() !== 2020 || window.localStorage.getItem('annual') == 'annual';

const tierRanksLimits = () => {
  return {
    insider: !annual ? 0 : 0,
    plus: !annual ? 6250 : 25000,
    pro: !annual ? 25000 : 100000,
    elite: !annual ? 100000 : 400000
  };
};

const roundTo100th = num => Number((Math.floor((num + Number.EPSILON) * 100) / 100).toFixed(2));

const getOrdersTotal = tierData => {
  let ordersTotal =
    typeof tierData.OrdersTotal === 'number' ? roundTo100th(tierData.OrdersTotal) : 0;

  if (ordersTotal > 10000) {
    ordersTotal = Math.floor(ordersTotal);
  }
  return ordersTotal;
};

export const mapCurrentTierSettingsDto = tierData => ({
  annual: annual,
  tierLimits: tierRanksLimits(),
  ordersTotal: getOrdersTotal(tierData),
  status: (tierData.Status && tierData.Status.toLowerCase()) || '',
  assignedAt: tierData.AssignedAt,
  expireAt: tierData.ExpireAt
});

export const mapNextTierSettingsDto = tierData => {
  const currentTierRank = tierRanks.find(tier => tier.name === tierData.Status.toLowerCase());

  const nextLevelStatus = currentTierRank.nextTier;

  const nextLevelRank = nextLevelStatus && tierRanks.find(tier => tier.name === nextLevelStatus);

  if (!nextLevelRank) {
    return null;
  }

  const periodName = !annual ? `Quarter 4` : `Year ${new Date().getFullYear()}`;

  const periodEnd = `December 31st, ${new Date().getFullYear()}`;

  let ordersTotal =
    typeof tierData.OrdersTotal === 'number' ? roundTo100th(tierData.OrdersTotal) : 0;

  if (ordersTotal > 10000) {
    ordersTotal = Math.floor(ordersTotal);
  }

  return {
    nextLevelStatus: nextLevelStatus,
    periodName: periodName,
    periodEnd: periodEnd,
    ordersTotal: ordersTotal,
    limit: tierRanksLimits()[nextLevelStatus],
    assignedBy: tierData.AssignedBy || '',
    updatedAt: tierData.RevisedAt
  };
};
