import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import Chip from 'gooten-components/src/components/shared/Chip';
import Card from 'gooten-components/src/components/shared/Card';
import { COLORS } from 'gooten-components/src/constants';
import Alert from 'gooten-components/src/components/shared/Alert';
import Input from 'gooten-components/src/components/shared/Input';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import ImagesViewService from '../ImagesViewService';
import orientationService from 'gooten-components/src/components/SKUSelection/atoms/OptionsPanel/Options/services/orientationService';
import { precalculateTemplateSize } from 'gooten-components/src/components/ImageUpload/ImageUploadSaga';

const InputRows = ({
  image,
  submitManipCommand,
  item,
  recipeId,
  imageIndex,
  itemIndex,
  storeRealImageDimensions
}) => {
  const [showTextArea, setShowTextArea] = useState(false);
  const [manipCommands, setManipCommands] = useState(image.ManipCommand);
  const [hasImageSizeWarning, setImageSizeWarning] = useState(false);
  const [hasImageRatioWarning, setImageRatioWarning] = useState(false);
  const [updatedResolution, setUpdatedResolution] = useState(image.Resolution);
  const [realDimensions, setRealDimensions] = useState(null);
  const [imageRatio, setImageRatio] = useState(1);

  /* eslint-disable */
  async function fetchImageSizeValidator() {
    // check if image is rotated
    if (image.ManipCommand) {
      let rotateCommand = JSON.parse(image.ManipCommand).commands.find(function (x) {
        return x.name === 'rotate';
      });
      if (rotateCommand) {
        let rotationAngle = rotateCommand.args.degrees;
        if ([-270, -90, 90, 270].includes(rotationAngle)) {
          image.rotated = true;
        }
      }
    }

    // image template data mostly copy pasted from original imagesTabVM
    const imageTemplateData = await ImagesViewService.getSkuData(recipeId, item.SKU);
    let option = imageTemplateData.Options.find(o => o.Name.toLowerCase() == 'single');
    let space = option.Spaces[imageIndex];
    if (space) {
      image.Dimensions.Width = space.FinalX2 - space.FinalX1;
      image.Dimensions.Height = space.FinalY2 - space.FinalY1;
    }

    if (!(image.Dimensions.Width + image.Dimensions.Height > 0)) {
      if (!space.Layers || !space.Layers) {
        return;
      }
      var layer = space.Layers.filter(function (s) {
        return s.Type && s.Type.toLowerCase() == 'image';
      })[0];
      if (!layer) {
        return;
      }
      image.Dimensions.Width = layer.X2 - layer.X1;
      image.Dimensions.Height = layer.Y2 - layer.Y1;
      if (!(image.Dimensions.Width + image.Dimensions.Height > 0)) {
        return;
      }
    }

    const res = await fetch(
      'https://imgmanip.print.io/service.ashx' + '?imageinfo=' + encodeURIComponent(image.SourceUrl)
    );
    res
      .json()
      .then(imgInfo => {
        storeRealImageDimensions(imgInfo.Width, imgInfo.Height, itemIndex, imageIndex);
        setRealDimensions(imgInfo);

        // actions needed to get template default orientation and compare it with image orientation,
        // in order to check if item orientation has been changed...
        precalculateTemplateSize(option, imageIndex);
        const defaultOrientation = orientationService.getDefaultTemplatesOrientation({
          sku: option
        });
        const imageOrientation = imgInfo.Width > imgInfo.Height ? 'landscape' : 'portrait';
        const orientationChanged = defaultOrientation !== imageOrientation;

        // apply orientation...
        const width = orientationChanged ? image.Dimensions.Height : image.Dimensions.Width;
        const height = orientationChanged ? image.Dimensions.Width : image.Dimensions.Height;
        image.Resolution = width + ' x ' + height;
        setUpdatedResolution(image.Resolution);
        setImageRatio((width / height).toFixed(2));

        if (image.rotated) {
          image.Dimensions.Width = [
            image.Dimensions.Height,
            (image.Dimensions.Height = image.Dimensions.Width)
          ][0];
        }
        if (imgInfo.Width < width || imgInfo.Height < height) {
          setImageSizeWarning(true);
        }
        if (Math.abs(imgInfo.Width / imgInfo.Height - width / height) > 0.01) {
          setImageRatioWarning(true);
        }
      })
      .catch(err => console.log(err));
  }
  /* eslint-enable */

  useEffect(() => {
    // get data on whether image is valid with extra fetch call
    if (image.SourceUrl) {
      fetchImageSizeValidator(image);
    }
  }, []);

  async function copyToClipboard(text) {
    if (!navigator.clipboard) {
    } else {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error('Failed to copy!', err);
      }
    }
  }

  const canManipImage = () => {
    return [17, 27, 31, 13, 12, 32, 14, 7, 30, 18, 26, 15, 20].includes(item.StatusId);
  };

  return (
    <>
      {hasImageRatioWarning || hasImageSizeWarning ? (
        <div className="warnings">
          {hasImageSizeWarning ? (
            <Alert className="ml-auto mr-auto mt-2 mb-2" isOpen type="warning">
              <span>
                <b>Small Image Size. </b>
                This image is not the same size as recommended for this product.&nbsp; This may
                cause stretching/cropping to occur during printing.&nbsp; The recommended size for
                this image is&nbsp;
                {updatedResolution}px.
              </span>
            </Alert>
          ) : null}
          {hasImageRatioWarning ? (
            <Alert className="ml-auto mr-auto mt-2 mb-2" isOpen type="important">
              <span>
                <b>Image Ratio Missmatch. </b>
                The image size proportions are not the same as the recommended size.&nbsp; This may
                cause stretching/cropping or an incorrectly printed product.&nbsp; The recommended
                size for this product is&nbsp;
                {updatedResolution}px in a ratio of&nbsp;
                {imageRatio}.
              </span>
            </Alert>
          ) : null}
        </div>
      ) : null}

      <div>
        <Chip iconLeft="atSign" className="blue mb-1">
          {realDimensions
            ? `${realDimensions.Width} x ${realDimensions.Height}`
            : `${image.Dimensions.Width} x ${image.Dimensions.Height}`}
        </Chip>
      </div>
      <div className="d-flex">
        <Input id={`source-url-${image.Id}`} label="Source URL" value={image.SourceUrl || ''} />
        <Button
          onClick={() => copyToClipboard(image.SourceUrl)}
          className="ml-2 button-default extra-large"
          iconLeft="clipBoard"
        >
          Copy
        </Button>
      </div>

      <div className="mt-n3">
        <Chip iconLeft="atSign" className="blue mb-1">
          {updatedResolution}
        </Chip>
      </div>
      <div className="d-flex">
        <Input
          id={`our-url-${image.Id}`}
          label="Our URL"
          disabled={!image.FileName}
          value={image.FileName || ''}
        />
        <Button
          onClick={() => copyToClipboard(image.FileName)}
          className="ml-2 button-default extra-large"
          iconLeft="clipBoard"
        >
          Copy
        </Button>
      </div>

      <div className="d-flex">
        <Input
          id={`manip-cmd-${image.Id}`}
          label="Manipulation command"
          value={showTextArea ? '' : image.ManipCommand || ''}
        />
        {showTextArea ? (
          <>
            {canManipImage() && manipCommands !== image.ManipCommand && (
              <Button
                onClick={() => submitManipCommand(image.Id, manipCommands)}
                className="ml-2 button-primary extra-large"
                iconLeft="file"
              >
                Save
              </Button>
            )}
            <Button
              onClick={() => setShowTextArea(false)}
              className="ml-2 button-default extra-large"
              iconLeft="x"
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            {canManipImage() ? (
              <Button
                onClick={() => setShowTextArea(true)}
                className="ml-2 button-default extra-large"
                iconLeft="edit"
              >
                Edit
              </Button>
            ) : (
              <Button
                onClick={() => setShowTextArea(true)}
                className="ml-2 button-default extra-large"
                iconLeft="maximize"
              >
                Open
              </Button>
            )}
            <Button
              onClick={() => copyToClipboard(manipCommands)}
              className="ml-2 button-default extra-large"
              iconLeft="clipBoard"
            >
              Copy
            </Button>
          </>
        )}
      </div>
      {showTextArea ? (
        <textarea
          readOnly={!canManipImage()}
          onChange={e => setManipCommands(e.target.value)}
          className="w-100 p-3 mt-n4"
          defaultValue={manipCommands}
        />
      ) : null}
      {image.ManipError && (
        <Alert className="ml-auto mr-auto mt-2 mb-2" isOpen type="important">
          Cannot save: ImgManip error
        </Alert>
      )}

      {item.PrintImages && item.PrintImages.length ? (
        <Card
          className="w-100 mt-3 mb-3 print-ready-section"
          collapsible
          title={
            <div className="d-flex align-items-center title-3 m-auto">
              {item.PrintImages.length} Print Ready Image{item.PrintImages.length > 1 ? 's' : ''}
            </div>
          }
        >
          <div className="mt-3 mb-3">
            <div className="d-flex align-items-center ml-n3 mr-n3">
              {item.PrintImages.map((printimage, key) => (
                <div className="flex-column d-flex align-items-center" key={key}>
                  <a className="m-3" href={printimage.ImageUrl} target="_blank">
                    <Thumbnail className="large m-0" image={printimage.ImageUrl} />
                  </a>
                  {printimage.Tags &&
                    printimage.Tags.map((tag, key) => (
                      <Chip className="mr-2 ml-2" key={key}>
                        {tag}
                      </Chip>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </Card>
      ) : null}
      <style jsx>
        {`
          textarea {
            border-radius: 4px;
            border: 1px solid ${COLORS.neutralLight400};
          }
        `}
      </style>
    </>
  );
};

InputRows.propTypes = {
  image: PropTypes.object.isRequired,
  submitManipCommand: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  recipeId: PropTypes.string,
  imageIndex: PropTypes.number,
  itemIndex: PropTypes.number,
  storeRealImageDimensions: PropTypes.func
};

export default InputRows;
