import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import Strings from './strings';

import './StoreConnect.scss';
import Button from 'gooten-components/src/components/shared/Button';

const SHOP_NAME_MAX_LENGTH = 50;

class StoreConnect extends React.Component {
  static propTypes = {
    predefinedStoreName: PropTypes.string,
    connectStore: PropTypes.func.isRequired,
    storeProvider: PropTypes.string,
    inputAddon: PropTypes.object,
    description: PropTypes.object,
    trademark: PropTypes.bool,
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    children: PropTypes.any
  };

  storeNameRegex = /^[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]+$/;
  // Will contain refs to all input fields
  inputs = {};

  constructor(props) {
    super(props);
    this.state = {
      storeName: props.predefinedStoreName || '',
      hasErrors: false,
      errorMsg: ''
    };
  }

  getValidationState = () => {
    const isValid = Object.keys(this.inputs).reduce(
      (previous, key) => (previous &= this.inputs[key] && this.inputs[key].isValid()),
      true
    );

    return isValid;
  };

  updateFormValidationState = () => {
    const isValid = this.getValidationState();
    this.setState({
      hasErrors: !isValid
    });
  };

  handleTextInputChange = value => {
    this.setState({
      storeName: value,
      hasErrors: false,
      errorMsg: ''
    });
  };

  handleButtonClick = () => {
    this.props.connectStore(this.state.storeName);
  };
  render() {
    const inputContainerClass =
      'flex-container-form flex-inline-container col-md-7 col-sm-10 col-xs-12 store-connect-form' +
      (this.props.inputAddon ? '' : '-small');
    const trademarkClass =
      'col-xs-12 center-block trademark' + (this.props.inputAddon ? '' : '-small');
    const submitBtnDisabled =
      this.state.hasErrors ||
      !this.state.storeName.length ||
      this.state.storeName.length > SHOP_NAME_MAX_LENGTH;
    const submitBtnClass =
      'button-cta large visible-xs col-xs-12 center-block store-btn' +
      (this.props.inputAddon ? '' : '-small') +
      (submitBtnDisabled ? ' btn-disabled' : ' btn-success');
    const storeProvider = this.props.storeProvider;
    return (
      <div className="store-connect-container center-block">
        <h3 className="text-center store-connect-title">
          Connect your {storeProvider}{' '}
          {storeProvider === 'Etsy' || storeProvider === 'TikTok' ? 'Shop' : 'store'}
        </h3>
        <p className="center-block text-center store-connect-description">
          {this.props.description}
        </p>
        <div className="flex-container-row">
          <div
            className="flex-container"
            style={{ marginTop: '2em', justifyContent: 'center', width: '100%' }}
          >
            <div className={inputContainerClass}>
              <div className="flex-container-column-base" style={{ flexGrow: '1' }}>
                <TextInput
                  id="storeName"
                  inputAddon={this.props.inputAddon}
                  emptyMsg={Strings.FIELD_REQUIRED}
                  disabled={this.props.storeNameDisabled}
                  showValidation
                  validationMsg={
                    !this.state.storeName.length
                      ? Strings.VALIDATION_ERROR
                      : Strings.INVALID_SHOP_NAME_ERROR
                  }
                  regexp={this.storeNameRegex}
                  maxLength={SHOP_NAME_MAX_LENGTH}
                  label={this.props.inputLabel || Strings.STORE_LABEL}
                  placeholder={this.props.inputPlaceholder || Strings.STORE_PLACEHOLDER}
                  value={this.state.storeName}
                  customError={this.state.errorMsg}
                  onChange={this.handleTextInputChange}
                  ref={input => (this.inputs['storeName'] = input)}
                  onErrorsStatusUpdated={this.updateFormValidationState}
                />
              </div>
              <Button
                disabled={submitBtnDisabled}
                className={
                  submitBtnDisabled
                    ? 'button-cta disabled extra-large hidden-xs ml-2'
                    : 'button-cta extra-large hidden-xs ml-2'
                }
                onClick={this.handleButtonClick}
              >
                {Strings.SUBMIT_BUTTON_TEXT}
              </Button>
            </div>
          </div>
          <Button
            disabled={submitBtnDisabled}
            className={submitBtnClass}
            onClick={this.handleButtonClick}
          >
            {Strings.SUBMIT_BUTTON_TEXT}
          </Button>
        </div>

        {this.props.trademark ? (
          <div className="flex-container-row text-center">
            <p className={trademarkClass}>
              <br className="visible-xs" />
              The term &apos;{storeProvider}&apos; is a trademark of {storeProvider}, Inc. This
              application uses the {storeProvider} API but is not endorsed or certified by{' '}
              {storeProvider}, Inc.
            </p>
          </div>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

export default StoreConnect;
