import React from 'react';
import Providers from './components/Providers';
import Stores from './components/Stores';
import Actions from './components/Actions';
import Products from './components/Products';
import PageBody from 'gooten-components/src/components/shared/PageBody';

export const HubView = props => {
  return (
    <React.Fragment>
      <Providers {...props} />
      <PageBody size="wide">
        <Stores {...props} />
        <Actions {...props} />
        <Products {...props} />
      </PageBody>
    </React.Fragment>
  );
};

export default HubView;
