import React from 'react';
import PropTypes from 'prop-types';
import UrlLink from '../UrlLink';
import './ResourceCard.scss';

class ResourceCard extends React.Component {
  static propTypes = {
    id: PropTypes.number
  };

  render() {
    const padding = { padding: '20px 15px 10px 15px' };
    return (
      <div id={this.props.id} style={padding} className="resource-card-container card">
        <ResourceCardContent {...this.props} id={'resource-card-' + this.props.id} />
      </div>
    );
  }
}

class ResourceCardContent extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    handleAnalytics: PropTypes.func
  };

  render() {
    const data = this.props.data[0] || this.props.data;
    const resData = data.resources;
    const resources = [];

    for (var i = 0; i < resData.length; i++) {
      resources.push(
        <ResourceFolder
          key={'ResourceFolder-Top' + i}
          data={resData[i]}
          handleAnalytics={this.props.handleAnalytics}
        />
      );
    }

    return (
      <div className="card-content" id={data.id}>
        <span className="card-title">{data.title}</span>
        <div className="card-list-container">
          <ol className="tree">{resources}</ol>
        </div>
      </div>
    );
  }
}

class ResourceFolder extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    handleAnalytics: PropTypes.func
  };

  render() {
    const data = this.props.data;
    var links = [];
    var folders = [];
    const id = data.folderLabel.toLowerCase().split(' ').join('_');

    if (data.links) {
      for (var i = 0; i < data.links.length; i++) {
        if (data.links[i].url) {
          links.push(
            <li key={'link-' + i}>
              <UrlLink handleAnalytics={this.props.handleAnalytics} data={data.links[i]} />
            </li>
          );
        }
      }
    }

    if (data.folders) {
      for (i = 0; i < data.folders.length; i++) {
        links.push(<ResourceFolder key={'ResourceFolder-' + id + i} data={data.folders[i]} />);
      }
    }

    return (
      <li>
        {data.folderLabel && <label htmlFor={id}>{data.folderLabel}</label>}
        <input type="checkbox" checked="checked" readOnly id={id} />
        <ol>
          {links}
          {folders}
        </ol>
      </li>
    );
  }
}

export default ResourceCard;
