import React from 'react';
import PropTypes from 'prop-types';
import './Slider.scss';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Controls from './Controls';

class Slider extends React.Component {
  static propTypes = {
    children: PropTypes.object,
    config: PropTypes.object,
    current: PropTypes.object,
    verticalItems: PropTypes.array,
    horizontalItems: PropTypes.array,
    goTo: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      transitionName: 'change'
    };

    this.slideHorizontally = this.slideHorizontally.bind(this);
    this.slideVertically = this.slideVertically.bind(this);
  }

  setTransitionState(transitionName) {
    this.setState({
      transitionName: transitionName
    });
  }

  slideHorizontally(newIndex) {
    let slideDelta = newIndex - this.props.current.horizontal;

    let slideAnimation =
      slideDelta === -1 ? 'slide-left' : slideDelta === 1 ? 'slide-right' : 'change';
    this.slide(slideAnimation, newIndex, this.props.current.vertical);
  }

  slideVertically(newIndex) {
    let slideDelta = newIndex - this.props.current.vertical;

    let slideAnimation =
      slideDelta === -1 ? 'slide-up' : slideDelta === 1 ? 'slide-down' : 'change';

    this.slide(slideAnimation, this.props.current.horizontal, newIndex);
  }

  slide(transitionName, horizontal, vertical) {
    this.setTransitionState(transitionName);
    this.props.goTo(horizontal, vertical);
    setTimeout(() => this.setTransitionState('change'), 500);
  }

  render() {
    const sliderContentStyle = {
      height: this.props.config.height
    };

    const sliderVerticalControlsStyle = {
      height: this.props.config.contentHeight || this.props.config.height
    };

    return (
      <div className="component-slider">
        <div className="row center col-xs-12">
          <div
            className="slider-veritical-controls-container flex-container flex-dead-centered"
            style={sliderVerticalControlsStyle}
          >
            {this.props.verticalItems ? (
              <Controls
                size={30}
                slideTo={this.slideVertically}
                items={this.props.verticalItems}
                current={this.props.current.vertical}
                showNames
                position="vertical"
              />
            ) : null}
          </div>
          <div className="slider-content" style={sliderContentStyle}>
            <ReactCSSTransitionGroup
              transitionName={this.state.transitionName}
              transitionEnterTimeout={1000}
              transitionLeaveTimeout={1000}
            >
              {this.props.children}
            </ReactCSSTransitionGroup>
          </div>
        </div>
        <div className="slider-horizontal-controls-container">
          {this.props.horizontalItems ? (
            <Controls
              size={38}
              slideTo={this.slideHorizontally}
              items={this.props.horizontalItems}
              current={this.props.current.horizontal}
              position="horizontal"
            />
          ) : null}
        </div>
      </div>
    );
  }
}

Slider.displayName = 'Slider';

export default Slider;
