import React from 'react';
import PropTypes from 'prop-types';
import SpinnerSmall from 'gooten-components/src/components/shared/SpinnerSmall';
import Button from 'gooten-components/src/components/shared/Button';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';

class TestConnection extends React.Component {
  test() {
    this.tooltip && this.tooltip.hideTooltip();
    this.props.testConnection();
  }

  render() {
    let btnClass = 'medium';
    let text = '';

    switch (this.props.status) {
      case TestConnection.status.none:
        btnClass = btnClass + ' button-default';
        text = 'Test Connection';
        break;
      case TestConnection.status.testing:
        btnClass = btnClass + ' button-default';
        text = <SpinnerSmall />;
        break;
      case TestConnection.status.success:
        btnClass = btnClass + ' button-primary';
        text = 'Connection Succesful';
        break;
      case TestConnection.status.failed:
        btnClass = btnClass + ' button-secondary';
        text = 'Connection Failed';
        break;
    }

    const error =
      this.props.status === TestConnection.status.failed
        ? this.props.error
        : 'Press to Test Connection';

    return (
      <Tooltip containerClassName="ml-n2" content={error}>
        <Button
          className={btnClass}
          disabled={!this.props.canTest || this.props.status === TestConnection.status.testing}
          onClick={() => this.test()}
        >
          {text}
        </Button>
      </Tooltip>
    );
  }
}

TestConnection.propTypes = {
  canTest: PropTypes.bool,
  testConnection: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  error: PropTypes.string
};

TestConnection.status = {
  none: 0,
  testing: 1,
  success: 2,
  failed: 3
};

export default TestConnection;
