import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'immutable';
import RadioButtonGroup from 'gooten-components/src/components/shared/RadioButtonGroup';
import HubProductSelection from './components/HubProductSelection';
import NewProductSelection from './components/NewProductSelection';
import { stepIndexElement } from 'gooten-components/src/components/shared/SelectionElements/SelectionElements';
import { nextSection } from './components/ProductSelectionSharedComponents';

import './ProductSelection.scss';

const Container = styled.div`
  .step-outer {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;

    svg {
      height: 100%;

      text {
        fill: #fff;
        font-size: 88px;
        font-weight: 900;
      }
    }
  }

  .clear-link {
    cursor: pointer;
    margin-right: -32px;
  }

  .step-container {
    position: relative;

    .step-index-container {
      position: absolute;
    }
  }

  .step-index {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    text-align: center;
    line-height: 30px;
    color: white;
    font-size: 22px;
    font-weight: bold;
    padding-top: 2px;

    .icon-progress-selected-check {
      font-size: 32px;
      line-height: 30px;
      font-weight: normal;
    }

    &.next {
      font-size: 10px;

      .icon-scroll-right {
        font-size: 9px;
        margin-left: 2px;
      }
    }
  }

  .step-content {
    padding: 4px 46px 0px 46px;
    margin-bottom: 20px;

    .step-title {
      font-size: 17px;

      &.active {
        font-weight: 600;
      }
    }
  }

  hr.delimiter {
    width: 100%;
  }
`;

export class ProductSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionIndex: props.flowSelectedIndex
    };
  }

  /*
   * FLOW selection
   */
  onSelectFlow(reset) {
    this.setState({
      selectionIndex: -1
    });
    this.props.setFlowSelectedIndex(reset ? -1 : this.state.selectionIndex);
  }

  onSelectionChange = (item, index) => {
    this.setState({ selectionIndex: index });

    // give time user to see what has been selected
    setTimeout(() => {
      this.props.setFlowSelectedIndex(index);
    }, 300);
  };

  /*
   * RENDER sections
   */
  render() {
    return (
      <Container className="product-selection-container">
        <div className="panel panel-default">
          <div className="panel-body">
            {this.renderFlowSelection()}
            {this.renderNextSection()}
            {this.renderProductSelection()}
          </div>
        </div>
      </Container>
    );
  }

  renderNextSection() {
    if (this.props.flowSelectedIndex === -1) {
      return nextSection('Next: Sync product', true);
    }
  }

  renderProductSelection() {
    // flowSelectedIndex 1 => New Product
    // flowSelectedIndex 0 => Saved(HUB) Product
    return this.props.flowSelectedIndex !== -1 ? (
      this.props.flowSelectedIndex === 0 ? (
        <HubProductSelection initialStepIndex={2} marginLeft={this.props.marginLeft} />
      ) : (
        <NewProductSelection initialStepIndex={2} marginLeft={this.props.marginLeft} />
      )
    ) : null;
  }

  renderFlowSelection() {
    const radioItems = new List([<span>Yes</span>, <span>No</span>]);

    return (
      <div>
        <div className="row step-container">
          <div className="step-index-container">
            {this.props.flowSelectedIndex !== -1 ? (
              <div className="step-index">
                <i className="icon-progress-selected-check" />
              </div>
            ) : (
              stepIndexElement(1)
            )}
          </div>
          {this.props.flowSelectedIndex === -1 ? (
            // choose between NEW PRODUCT or HUB PRODUCT flow
            <div className="step-content">
              <p className="step-title active ">
                Do you have this product saved to your Product Hub?
              </p>
              <RadioButtonGroup
                items={radioItems}
                selectionIndex={this.state.selectionIndex}
                onChange={this.onSelectionChange}
                radioStyle
              />
            </div>
          ) : (
            // product selection flow has been selected, show 'change' button
            // flowSelectedIndex 1 => New Product
            // flowSelectedIndex 0 => Saved(HUB) Product
            <div className="step-content">
              <span>{this.props.flowSelectedIndex === 1 ? 'New Product' : 'Saved Product'}</span>
              <span className="pull-right clear-link" onClick={() => this.onSelectFlow(true)}>
                change
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProductSelection.propTypes = {
  setFlowSelectedIndex: PropTypes.func,
  flowSelectedIndex: PropTypes.number,
  marginLeft: PropTypes.number
};

export default ProductSelection;
