import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import Modal from 'gooten-components/src/components/shared/Modal';
import Toast from 'gooten-components/src/components/shared/Toast';
import ReasonItem from './ReasonItem';

const TaskNotification = ({ hide, notification }) => {
  const hideModal = () => {
    notification && hide(notification.get('id'));
  };

  const getSuccessPublishType = () => {
    return notification.get('publishType') === 'link'
      ? 'synced'
      : notification.get('publishType') === 'edit'
      ? 'edited'
      : notification.get('publishType') === 'duplicate'
      ? 'duplicated'
      : 'created';
  };

  // Toast should auto-dismiss after 3 seconds if it is a success action.
  useEffect(() => {
    notification && notification.get('status') === 'success'
      ? setTimeout(() => {
          hideModal();
        }, 3000)
      : console.log('No notification');
  }, [notification]);

  const componentClassAddon = notification ? notification.get('status') : '';
  const isOpen = notification ? notification.get('notify') : false;

  const reasons =
    notification && notification.get('reasons') && notification.get('reasons').length
      ? notification.get('reasons').map(r => <ReasonItem key={`reason-${r.store}`} reason={r} />)
      : null;

  const statusUpdate = !notification ? (
    <div />
  ) : notification.get('status') === 'success' ? (
    <div className="toast-container w-100">
      <Toast isOpen={isOpen} closeClick={() => hideModal()}>
        Your product has been {getSuccessPublishType()} successfully
        {/* <div className='success-image text-center' /> */}
      </Toast>
      <style jsx>
        {`
          .toast-container {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto;
            z-index: 5;
            top: 0.5rem;
          }
        `}
      </style>
    </div>
  ) : (
    <>
      <Modal
        className="medium"
        title={notification && notification.get('status') === 'success' ? 'Success' : 'Error'}
        isOpen={isOpen}
        cancelText="Okay"
        cancelClick={() => hideModal()}
      >
        <div className="p-4">
          <div className="row">
            {notification.get('error') ? (
              <div className="col-xs-12">
                <p>
                  <b>{notification.get('error')}</b>
                </p>
                {reasons}
                <p>
                  Please confirm that your site is not broken, and you have not changed access to
                  your site.
                </p>
                <p>If the problem persists, please contact our customer support.</p>
              </div>
            ) : (
              <div className="col-xs-12">
                <p>
                  <b>Something is preventing this product from connecting to your store.</b>
                </p>
                {reasons}
                <p>
                  Please confirm that your site is not broken, and you have not changed access to
                  your site.
                </p>
                <p>If the problem persists, please contact our customer support.</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );

  return <div className={'task-notification-container ' + componentClassAddon}>{statusUpdate}</div>;
};

TaskNotification.propTypes = {
  hide: PropTypes.func,
  notification: PropTypes.instanceOf(Map)
};

export default TaskNotification;
