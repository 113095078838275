import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import StoreBulkMigration from './index';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';

const storeBulkMigrationRoutes = {
  path: '/store-bulk-migration',
  component: CoreLayout,
  indexRoute: { component: withErrorBoundary(StoreBulkMigration) }
};

export default storeBulkMigrationRoutes;
