import { fetch } from 'gooten-components/src/utils/http';
import Config from './../../../../../../config';
import { mapProductVariant } from './../../../../../../models/ProductVariant';
import { mapProduct } from './../../../../../../models/Product';

class ProductSelectionService {
  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }

  /**
   * Returns regex for filter string
   * @param {*} filter
   */
  getRegexForFilter(filter) {
    return new RegExp(`^all-${filter}-`);
  }

  /**
   * Returns initial query object
   */
  getInitialQuery(filter) {
    return { page: 1, pageSize: 30, search: filter || '' };
  }

  /**
   * Creates key (string) from query object.
   * If no query is provided, default query from getInitialQuery
   * will be used
   * @param {*} query
   */
  getKeyFromQuery(query) {
    let q = query || this.getInitialQuery();
    return `all-${q.search}-${q.page}`;
  }

  /**
   * Fetchs all products, from storage and from all stores,
   * with provided query object
   * @param {*} query
   */
  fetchAllProducts(q) {
    return new Promise((resolve, reject) => {
      let query = q || this.getInitialQuery();

      fetch(`${Config.get('storeApi')}products/all`, {
        queryParams: {
          ...this.getAuthQueryParams(),
          connected: 'true',
          page: query.page,
          pageSize: query.pageSize,
          search: query.search || ''
        }
      })
        .then(res => {
          if (!res.error) {
            resolve({
              res: {
                products: res.products.map(p => mapProduct(p).toJS()),
                total: res.total
              },
              key: this.getKeyFromQuery(query)
            });
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Fetchs product variants, depending of product type (store, storage)
   * @param {*} product
   */
  fetchVariants(product) {
    return new Promise((resolve, reject) => {
      if (!product) {
        reject(new Error('fetchVariants() failed because product is not specified'));
      }

      let url =
        product.type === 'store'
          ? `${Config.get('storeApi')}stores/${product.storeId}/products/${product.id}/variants`
          : `${Config.get('storeApi')}storage/products/${product.id}/variants`;

      fetch(url, { queryParams: this.getAuthQueryParams() })
        .then(res => {
          if (!res.error) {
            resolve({
              res: res.variants
                .filter(variant => variant.gooten_mapping)
                .sort((x, y) => x.gooten_mapping.id - y.gooten_mapping.id)
                .map(mapProductVariant)
            });
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default new ProductSelectionService();
