import ImageEditorControl from 'gooten-js-editor/src/_scripts/main';
import { sanitizeCDNUrl } from 'gooten-js-utils/src/url';
import Log from '../../../services/logService';

class ImageEditorService {
  constructor() {
    this.editorInstance = null;
  }

  editorInstanceExists() {
    return !!this.editorInstance;
  }

  createEditor(config, space, ilHistory, disableImageEditing) {
    if (this.editorInstance) {
      this.destroyEditor();
    }

    this.editorInstance = new ImageEditorControl({
      ...config,
      toolbarShown: !!space.images.length && !disableImageEditing,
      zoomControlShown: !!space.images.length && !disableImageEditing,
      template: !space.dumpedState ? space.template : null,
      state: space.dumpedState
    });

    if (!space.dumpedState && !!space.il) {
      // NOTE: space.il is a print il and contains full size image !!!
      // pass imageSources with resized to 1000x1000 urls and real sizes data
      // .map here to avoid state mutations by ref
      const imageSources = space.images.map(i => ({ ...i }));
      this.editorInstance.restoreFromIL(space.il, imageSources, ilHistory);
    }

    this.editorInstance.on('error', err => {
      const errorMessage = `Image editor error ${err.friendlyMsg || err}`;
      Log.error(err, errorMessage, err.details);
    });
  }

  changeOrientation(changeOrientation) {
    if (!this.editorInstance) {
      return;
    }
    this.editorInstance.changeOrientation(changeOrientation);
  }

  setContainerColor(color) {
    if (!this.editorInstance) {
      return;
    }
    this.editorInstance.setContainerColor(color);
  }

  onChange(fn) {
    this.editorInstance.on('imagechange', fn);
  }

  onChangeDx(fn) {
    this.editorInstance.on('imagechangedx', fn);
  }

  onUndo(fn) {
    this.editorInstance.on('undo', fn);
  }

  onRedo(fn) {
    this.editorInstance.on('redo', fn);
  }

  onBulkModeToggle(fn) {
    this.editorInstance.on('bulkmode', fn);
  }

  onImagesLoaded(fn) {
    this.editorInstance.on('imagesloaded', fn);
  }

  setBulkEditingAvailability(isAvailable) {
    if (!this.editorInstance) {
      return;
    }
    this.editorInstance.setBulkEditingAvailability(isAvailable);
  }

  changeBulkEditMode(bulk) {
    if (!this.editorInstance) {
      return;
    }
    this.editorInstance.changeBulkEditing(bulk);
  }

  changeEditorMode(mode) {
    if (!this.editorInstance) {
      return;
    }

    this.editorInstance.changeMode(mode);
  }

  changeEditorSize(size) {
    if (!this.editorInstance) {
      return;
    }

    this.editorInstance.changeEditorSize(size);
  }

  addImage(imageUrl, layerId, width, height) {
    if (!this.editorInstance) {
      return;
    }

    this.editorInstance.addImage(imageUrl, layerId, width, height);
    this.editorInstance.clearHistory();
  }

  destroyEditor() {
    if (!this.editorInstance) {
      return;
    }

    this.editorInstance.destroy();
    this.editorInstance = null;
  }

  getState() {
    return this.editorInstance && this.editorInstance.dumpState(false);
  }

  getUpdatedILbyDx(il, dx, template) {
    return ImageEditorControl.applyDxUpdateToIL(il, dx, template);
  }

  getIl() {
    if (this.editorInstance) {
      // Sanitize resized image urls
      // NOTE: Print IL can include design layers with includeInPrint: true
      // these layers don't have images array
      const il = this.editorInstance.exportIL(false);
      return {
        ...il,
        print: {
          ...il.print,
          layers: il.print.layers.map(l => {
            let layer = { ...l };
            if (l.images) {
              layer.images = l.images.map(i => ({
                ...i,
                url: sanitizeCDNUrl(i.url)
              }));
            } else if (l.url) {
              // this is design layer with includeInPrint = true
              layer.url = sanitizeCDNUrl(l.url);
            }
            return layer;
          })
        }
      };
    }
  }

  comparePrintIls(il1, il2) {
    // IL can has desing layers with includeInPrint: true
    // They don't have images
    const image1 = il1.layers.find(l => l.images).images[0];
    const image2 = il2.layers.find(l => l.images).images[0];
    // Compares 2 print ILs
    // returns true if they are equal, false otherwise
    if (
      image1 &&
      image2 &&
      il1 &&
      il2 &&
      il1.spaceId === il2.spaceId &&
      il1.top === il2.top &&
      il1.left === il2.left &&
      il1.width === il2.width &&
      il1.height === il2.height &&
      il1.final.x1 === il2.final.x1 &&
      il1.final.x2 === il2.final.x2 &&
      il1.final.y1 === il2.final.y1 &&
      il1.final.y2 === il2.final.y2 &&
      image1.centerX === image2.centerX &&
      image1.centerY === image2.centerY &&
      image1.height === image2.height &&
      image1.width === image2.width &&
      image1.left === image2.left &&
      image1.top === image2.top &&
      sanitizeCDNUrl(image1.url) === sanitizeCDNUrl(image2.url) &&
      image1.realSourceHeight === image2.realSourceHeight &&
      image1.realSourceWidth === image2.realSourceWidth &&
      image1.rotation === image2.rotation &&
      image1.scaleDx === image2.scaleDx &&
      image1.sourceHeight === image2.sourceHeight &&
      image1.sourceWidth === image2.sourceWidth &&
      image1.x1 === image2.x1 &&
      image1.x2 === image2.x2 &&
      image1.y1 === image2.y1 &&
      image1.y2 === image2.y2 &&
      image1.viewport.height === image2.viewport.height &&
      image1.viewport.width === image2.viewport.width &&
      image1.viewport.x1 === image2.viewport.x1 &&
      image1.viewport.x2 === image2.viewport.x2 &&
      image1.viewport.y1 === image2.viewport.y1 &&
      image1.viewport.y2 === image2.viewport.y2
    ) {
      // check crop if not exists
      if (!image1.crop && !image2.crop) {
        return true;
      } else if (
        image1.crop &&
        image1.crop &&
        image1.crop.x1 === image2.x1 &&
        image1.crop.y1 === image2.y1 &&
        image1.crop.x2 === image2.x2 &&
        image1.crop.y2 === image2.y2 &&
        image1.crop.width === image2.width &&
        image1.crop.height === image2.height
      ) {
        return true;
      }

      return false;
    }

    return false;
  }

  compareBasicPrintIls(il1, il2) {
    // IL can has desing layers with includeInPrint: true
    // They don't have images
    const image1 = il1.layers.find(l => l.images).images[0];
    const image2 = il2.layers.find(l => l.images).images[0];
    // Compares 2 print ILs
    // returns true if they are equal, false otherwise
    if (
      image1 &&
      image2 &&
      il1 &&
      il2 &&
      image1.height === image2.height &&
      image1.width === image2.width &&
      image1.left === image2.left &&
      image1.top === image2.top &&
      sanitizeCDNUrl(image1.url) === sanitizeCDNUrl(image2.url) &&
      image1.rotation === image2.rotation &&
      image1.scaleDx === image2.scaleDx
    ) {
      // check crop if not exists
      if (!image1.crop && !image2.crop) {
        return true;
      } else if (
        image1.crop &&
        image2.crop &&
        image1.crop.x1 === image2.x1 &&
        image1.crop.y1 === image2.y1 &&
        image1.crop.x2 === image2.x2 &&
        image1.crop.y2 === image2.y2 &&
        image1.crop.width === image2.width &&
        image1.crop.height === image2.height
      ) {
        return true;
      }

      return false;
    }

    return false;
  }

  initImageState(template, image, isRotated) {
    return ImageEditorControl.initImageState(template, image, isRotated);
  }

  getRecomendedEditorSize(isMultispace) {
    // ImageEditor is placed on left container. Based on left and right container size ratio 58%/42%
    // (defined in ImageUpload.scss), and screen height, we calculate recomended editor size. Value of
    // 266 is height of image editor controls. If product is multispaced, additional control is added to view...
    // min size will be 400x400
    const offset = 266 + (isMultispace ? 34 : 0);
    const width = window.innerHeight - offset < 400 ? 400 : window.innerHeight - offset;
    return { width, height: width };
  }
}

// singleton
export default new ImageEditorService();
