import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import './VariantsSelection.scss';

import styled from 'styled-components';
const Container = styled.div`
  .img-missing-2 {
    background-size: 100%;
    width: 100%;
    padding-top: 100%;
  }

  .variants-container {
    display: inline-block;

    .selected-variant-preview {
      width: 230px;
      img {
        width: 100%;
      }
      margin-right: 10px;
    }

    .variants-panel {
      width: 65px;
      .scroll-item,
      .scroll-item-empty {
        width: 65px;
        height: 20px;
      }
      .scroll-item,
      .variants-item {
        cursor: pointer;
      }
      .variants-item {
        position: relative;
        padding: 4px;
        width: 65px;
        height: 65px;
        img {
          width: 100%;
        }

        .selected-item-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -11px;
          margin-top: -11px;
          font-size: 22px;
          border-radius: 11px;
        }
      }
    }
  }
`;

export class VariantsSelection extends Component {
  state = {
    topScrolledVariant: 0
  };

  onScroll(position) {
    this.setState({ topScrolledVariant: position });
  }

  skipVariant() {
    this.props.skip();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentVariantIndex > state.topScrolledVariant &&
      props.currentVariantIndex - state.topScrolledVariant > 2
    ) {
      return { topScrolledVariant: props.currentVariantIndex - 2 };
    } else if (props.currentVariantIndex < state.topScrolledVariant) {
      return { topScrolledVariant: props.currentVariantIndex };
    }
    return null;
  }

  render() {
    const selectedVariant = this.props.variants.get(this.props.currentVariantIndex).get('variant');

    const selectedVariantId = this.props.variants
      .get(this.props.currentVariantIndex)
      .get('variant')
      .get('id');
    const variantsToShow =
      this.props.variants.size > 1
        ? this.props.variants
            .skip(this.state.topScrolledVariant)
            .take(3)
            .map((v, idx) => {
              const id = v.getIn(['variant', 'id']);
              const selected = id === selectedVariantId;

              return (
                <div
                  className="variants-item"
                  key={id}
                  onClick={() =>
                    this.props.setCurrentVariantIndex(this.state.topScrolledVariant + idx)
                  }
                >
                  {v.getIn(['variant', 'image_url']) ? (
                    <img
                      className={selected ? 'selected' : ''}
                      src={v.getIn(['variant', 'image_url'])}
                    />
                  ) : (
                    <div className={selected ? 'img-missing-2 selected' : 'img-missing-2'} />
                  )}

                  {v.get('uniqueProduct') ? (
                    <span className="selected-item-icon icon-success" />
                  ) : null}
                </div>
              );
            })
        : null;

    return (
      <Container className="variant-selection-container">
        <h4>{selectedVariant.get('sku')}</h4>
        <p className="hidden-xs">{this.props.storeProductName}</p>
        <div className="variants-container">
          <div
            className={`selected-variant-preview pull-left ${
              this.props.variants.size > 1 ? '' : 'alone'
            }`}
          >
            {selectedVariant.get('image_url') ? (
              <img src={selectedVariant.get('image_url')} />
            ) : (
              <div className="img-missing-2" />
            )}
          </div>
          <div className="variants-panel  pull-left">
            {this.state.topScrolledVariant > 0 ? (
              <div
                className="scroll-item text-center"
                onClick={() => this.onScroll(this.state.topScrolledVariant - 1)}
              >
                <i className="icon-collapse" />
              </div>
            ) : (
              <div className="scroll-item-empty" />
            )}
            {variantsToShow}
            {this.props.variants.size - this.state.topScrolledVariant > 3 ? (
              <div
                className="scroll-item text-center"
                onClick={() => this.onScroll(this.state.topScrolledVariant + 1)}
              >
                <i className="icon-dropdown" />
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    );
  }
}

VariantsSelection.propTypes = {
  variants: PropTypes.instanceOf(List).isRequired,
  currentVariantIndex: PropTypes.number,
  setCurrentVariantIndex: PropTypes.func,
  storeProductName: PropTypes.string,
  skip: PropTypes.func
};

export default VariantsSelection;
