import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../../shared/Spinner';
import { DragSource, DropTarget } from 'react-dnd';
import './PreviewItem.scss';
import Checkbox from '../../../../shared/Checkbox';

const Types = {
  BLOCK: 'block'
};

const blockSource = {
  beginDrag(props) {
    return {
      id: props.id,
      smallPreviewImageUrl: props.item.smallPreviewImageUrl
    };
  }
};

const blockTarget = {
  hover(props, monitor) {
    const draggedId = monitor.getItem().id;

    if (draggedId !== props.id) {
      props.moveBlock(draggedId, props.id);
    }
  }
};

const sourceCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

const targetCollect = connect => {
  return {
    connectDropTarget: connect.dropTarget()
  };
};

class PreviewItem extends React.Component {
  static propTypes = {
    isEditMode: PropTypes.bool,
    isMaximized: PropTypes.bool,
    onSelect: PropTypes.func,
    onClick: PropTypes.func,
    position: PropTypes.number,
    selected: PropTypes.bool,
    item: PropTypes.object,
    showLoading: PropTypes.bool,
    isDragging: PropTypes.bool,
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
    numberOfItems: PropTypes.number
  };

  itemClassName() {
    if (this.props.numberOfItems === 1) {
      return !this.props.isMaximized
        ? 'product-item col-lg-4 col-md-4'
        : 'product-item col-lg-3 col-md-3 col-sm-3 col-xs-12';

      // number of items is <=2 for all screens
    } else if (this.props.numberOfItems <= 2) {
      return !this.props.isMaximized
        ? 'product-item col-lg-4 col-md-4'
        : 'product-item col-lg-3 col-md-3 col-sm-3 col-xs-6';

      // under 6
    } else if (this.props.numberOfItems <= 6) {
      return !this.props.isMaximized
        ? this.props.position === 0
          ? 'product-item col-lg-6 col-md-8'
          : 'product-item col-lg-3 col-md-4'
        : this.props.position === 0
        ? 'product-item col-lg-4 col-md-6 col-sm-6 col-xs-6'
        : 'product-item col-lg-2 col-md-3 col-sm-3 col-xs-6';

      // under 9
    } else if (this.props.numberOfItems <= 9) {
      return !this.props.isMaximized
        ? this.props.position === 0
          ? 'product-item col-lg-6 col-md-8'
          : 'product-item col-lg-3 col-md-4'
        : this.props.position === 0
        ? 'product-item col-lg-4 col-md-6 col-sm-3 col-xs-6'
        : 'product-item col-lg-2 col-md-3 col-sm-3 col-xs-6';
    }

    // over 6(9)
    return !this.props.isMaximized
      ? 'product-item col-lg-3 col-md-4'
      : 'product-item col-lg-2 col-md-3 col-sm-3 col-xs-6';
  }

  render() {
    const opacity = this.props.isDragging ? 0 : 1;
    const connectDragSource = this.props.connectDragSource;
    const connectDropTarget = this.props.connectDropTarget;
    const imageUrl = !this.props.item.isExclusiveMockup
      ? this.props.item.smallPreviewImageUrl
      : this.props.item.previewImageUrl;

    return connectDragSource(
      connectDropTarget(
        <div style={{ opacity }} className={this.itemClassName()}>
          <div className="product-content">
            {this.props.isEditMode ? (
              <Checkbox
                key={'key'}
                checked={this.props.selected}
                onChange={() => this.props.onSelect(!this.props.selected, this.props.item)}
              />
            ) : null}
            {this.props.position === 0 ? <span className="main-title">Main</span> : null}
            {this.props.showLoading ? <Spinner /> : null}
            <div
              className={`product-image ${this.props.isEditMode ? 'edit-mode' : ''}
            ${this.props.showLoading ? '' : ' image-loaded'}`}
              style={
                this.props.showLoading
                  ? {}
                  : { background: `url(${imageUrl}) center center / contain no-repeat` }
              }
              onClick={() => this.props.onClick(this.props.item)}
            />
          </div>
          <style jsx>
            {`
              .product-content :global(label) {
                width: 100%;
                height: 100%;
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 1rem;
              }
            `}
          </style>
        </div>
      )
    );
  }
}

const source = DragSource(Types.BLOCK, blockSource, sourceCollect)(PreviewItem);
const block = DropTarget(Types.BLOCK, blockTarget, targetCollect)(source);

export default block;
