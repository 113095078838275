import { createSelector } from 'reselect';
import { List, Map, fromJS } from 'immutable';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import { dataSelector } from '../../../../store/selectors/productDataSelectors';

export const designsSelector = createSelector([dataSelector], data => {
  const images = data
    .get('selectedSkus')
    .map(s =>
      s
        .get('spaces')
        .map(space => fromJS({ ...space.toJS(), numberOfSpaces: s.get('spaces').size }))
    )
    .reduce((a, c) => a.concat(c))
    .map(s =>
      s.get('images').map(img => {
        const imgObj = Map.isMap(img) ? img.toJS() : img;
        return fromJS({ ...imgObj, spaceName: s.get('numberOfSpaces') > 1 ? s.get('name') : '' });
      })
    )
    .reduce((a, c) => a.concat(c));

  let designs = new List();
  images.forEach(img => {
    if (!designs.map(d => d.get('imageUrl')).contains(img.get('imageUrl'))) {
      designs = designs.push(
        fromJS({
          imageUrl: img.get('imageUrl'),
          height: img.get('height'),
          width: img.get('width'),
          format: img.get('imageUrl').toLowerCase().includes('.png') ? 'PNG' : 'JPG'
        })
      );
    }
  });

  return designs.map(d =>
    fromJS({
      ...d.toJS(),
      imageUrl: getSmallPreviewImageResizerUrl(d.get('imageUrl')),
      spaceNames: Array.from(
        new Set(
          images
            .filter(img => img.get('imageUrl') === d.get('imageUrl'))
            .map(s => s.get('spaceName'))
            .toJS()
        )
      ).filter(n => n.length)
    })
  );
});
