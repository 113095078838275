import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Regions from './Regions';
import OptionValues from './OptionValues';
import analyticsService from '../../../../../services/analyticsService';
import optionsService from './services/optionsService';

import './Options.scss';
import Select from '../../../../shared/Select';

class Options extends React.Component {
  static propTypes = {
    allOptions: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    selectOption: PropTypes.func.isRequired,
    selectBatchOptions: PropTypes.func.isRequired,
    deselectOption: PropTypes.func.isRequired,
    deselectBatchOptions: PropTypes.func.isRequired,
    selectedOptions: PropTypes.instanceOf(Map),
    selectedRegions: PropTypes.instanceOf(List),
    setCascadeOption: PropTypes.func.isRequired,
    cascade: PropTypes.array,
    gotoNextGroup: PropTypes.func.isRequired,
    gotoGroup: PropTypes.func.isRequired,
    maxGroupId: PropTypes.number,
    currentGroup: PropTypes.number.isRequired,
    preselectedOptions: PropTypes.array,
    shouldInitFromRawSkus: PropTypes.bool.isRequired,
    filteredSkusByGroup: PropTypes.array,
    showErrors: PropTypes.bool,
    resetErrors: PropTypes.func,
    shouldPreselectOptions: PropTypes.bool
  };

  componentDidMount() {
    if (this.props.shouldInitFromRawSkus) {
      this.props.selectBatchOptions(this.props.preselectedOptions);
      this.props.gotoGroup(this.props.maxGroupId);
      return;
    }

    // select top level in cascade
    if (!this.props.selectedOptions.size) {
      let topOption = this.props.cascade[0];
      if (topOption) {
        this.props.selectOption(topOption.id, topOption.values[0].id);
        this._handleTrigger(topOption, topOption.values[0].id);
      }
    }

    this.preselectOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.shouldPreselectOptions) {
      this.preselectOptions();
    }
    if (prevProps.options.length !== this.props.options.length) {
      this.props.resetErrors();
    }
    this.unselectIncompatibleValues();
  }

  preselectOptions() {
    // select value if its single in option
    let optionsToSelect = this.props.options
      .filter(opt => opt.values.length === 1)
      .map(opt => {
        return { optionId: opt.id, values: opt.values.map(x => x.id) };
      });

    if (optionsToSelect.length) {
      this.props.selectBatchOptions(optionsToSelect);
    }
  }

  unselectIncompatibleValues() {
    let valuesToDeselect = optionsService.getSelectedDisabledValues(
      this.props.options,
      this.props.selectedOptions.toJS(),
      this.props.allOptions,
      this.props.filteredSkusByGroup
    );
    valuesToDeselect.valueIds.forEach(valueId =>
      this.props.deselectOption(valuesToDeselect.optionId, valueId)
    );
  }

  getOptions() {
    return this.props.options
      .filter(o => o.values.length)
      .map(o => (
        <OptionValues
          showErrors={this.props.showErrors}
          key={o.id}
          option={o}
          isValueSelected={this.isValueSelected}
          isValueDisabled={this.isValueDisabled}
          toggleValue={this.toggleValue}
          setOverride={this.setOverride}
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
        />
      ));
  }

  getCascade() {
    // NOTE: Cascade are options with control type dropdown
    // which do reset by cascade all child options when selected
    // In dropdown only 1 value can be selected at a time
    return this.props.cascade.map(opt => (
      <div key={opt.id} className="row ml-0">
        <div className="row col-xs-9 top-buffer">
          <Select
            hasError={this.props.showErrors && !this.props.selectedOptions.get(opt.id)}
            errorText={
              this.props.showErrors && !this.props.selectedOptions.get(opt.id)
                ? 'Please select an option'
                : null
            }
            label={opt.title}
            value={
              this.props.selectedOptions.get(opt.id)
                ? this.props.selectedOptions.get(opt.id).first()
                : ''
            }
            onChange={evt => this._setCascadeOption(opt, evt.target.value)}
            placeholder={opt.title}
          >
            {!this.props.selectedOptions.get(opt.id) ? (
              <option key={''} value="">
                {opt.title}
              </option>
            ) : null}
            {this._cascadeValues(opt)}
          </Select>
        </div>
      </div>
    ));
  }

  _setCascadeOption(option, valueId) {
    this.props.setCascadeOption(option.id, valueId, option.topOptionsIds);
    this._handleTrigger(option, valueId);

    // deselect bottom level of cascade
    let optsToDeselect = this.props.options.map(x => x.id);
    let cascadeToDeselect = this.props.cascade
      .filter(
        x => option.id !== x.id && (!option.topOptionsIds || !option.topOptionsIds.includes(x.id))
      )
      .map(x => x.id);
    this.props.deselectBatchOptions([...optsToDeselect, ...cascadeToDeselect]);

    // set group
    if (this.props.currentGroup > 1) {
      this.props.gotoGroup(option.resetGroup);
    }
  }

  _cascadeValues(option) {
    return option.values.map(opt => (
      <option key={opt.id} value={opt.id}>
        {opt.name}
      </option>
    ));
  }

  // NOTE: Use arrow fn here since we are passing this fn into OptionValues component
  // and with arrow fn we avoid using .bind(this) in ctor
  isValueSelected = (optionId, valueId) => {
    return optionsService.isValueSelected(optionId, valueId, this.props.selectedOptions.toJS());
  };

  isValueDisabled = (optionId, valueId) => {
    return optionsService.isValueDisabled(
      optionId,
      valueId,
      this.props.selectedOptions.toJS(),
      this.props.allOptions,
      this.props.filteredSkusByGroup
    );
  };

  toggleValue = (optionId, valueId, optionTitle) => {
    if (this.isValueSelected(optionId, valueId)) {
      analyticsService.trackWithDynamicSource('Sku select', 'Option deselected', optionTitle);
      this.props.deselectOption(optionId, valueId);
    } else {
      analyticsService.trackWithDynamicSource('Sku select', 'Option selected', optionTitle);
      this.props.selectOption(optionId, valueId);
    }
  };

  selectAll = option => {
    analyticsService.trackWithDynamicSource('Sku select', 'Option select all', option.title);
    // for Select All options we don't need to check for disabled values, only for selecting individual options
    this.props.selectBatchOptions([
      {
        optionId: option.id,
        values: option.values.map(v => v.id)
      }
    ]);
  };

  deselectAll = option => {
    analyticsService.trackWithDynamicSource('Sku select', 'Option deselect all', option.title);
    this.props.deselectBatchOptions([option.id]);
  };

  setOverride = (option, valueId) => {
    this.props.selectBatchOptions([{ optionId: option.id, values: [valueId] }]);
    this._handleTrigger(option);
  };

  _handleTrigger(option, valueId) {
    if (option.trigger && valueId) {
      this.props.gotoNextGroup();
    }
  }

  render() {
    return (
      <div className="options-container">
        <Regions showErrors={this.props.showErrors} />
        {this.getCascade()}
        {this.getOptions()}
      </div>
    );
  }
}

export default Options;
