import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'immutable';
import PrintSpaceItem from './PrintSpaceItem';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { windowWidth } from '../../../../ComponentsHooks';
import './PrintSpacesMobile.scss';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => (props.numberOfItems > 8 ? 95 : 60)}%;
  bottom: 0;
  left: 0;
  z-index: 1000;

  .spaces-mobile-content {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;

    .spaces-holder {
      overflow-y: auto;
      height: 90%;
      padding: 20px;

      .image-selection-print-space-item {
        .image-placeholder {
          width: 100%;
          height 140px;
        }

        .number-of-images {
          margin: -50px 0 34px 1px;
        }
      }
    }

    .title-holder {
      padding: 20px 10px 10px 10px;

      span {
        font-weight: 900;
        font-size: 15px;
        margin-top: 1px;
        padding-left: 8px;
      }

      .btn-close {
        float: right;
        height: 15px;
        width: 15px;
        margin: 4px 8px 0 0;
        cursor: pointer;
      }
    }
  }

  .fade-appear {
    transform: translate(0px, 100%);
  }
  
  .fade-appear.fade-appear-active {
    transform: translate(0px, 0px);
    transition: transform 300ms ease-in-out;
  }
`;

const PrintSpacesMobile = props => {
  const width = windowWidth();

  let customClass = 'col-xs-3';
  if (width <= 480) {
    customClass = 'col-xs-6';
  } else if (width <= 576) {
    customClass = 'col-xs-4';
  }

  const handleSelect = s => {
    props.handleSelect(s);
    props.onClose();
  };

  const items = props.spaces.map((s, i) => (
    <PrintSpaceItem
      key={`${s.get('name')}${i}`}
      title={s.get('name')}
      numberOfImages={s.get('multiple') || 0}
      handleSelect={() => handleSelect(s)}
      imageUrl={s.getIn(['image', 'previewUrl'])}
      customClass={customClass}
      selected={s.get('selected')}
    />
  ));

  return (
    <Container className="image-selection-print-spaces-mobile" numberOfItems={items.size}>
      <ReactCSSTransitionGroup
        transitionAppear
        transitionAppearTimeout={300}
        transitionEnter={false}
        transitionLeave={false}
        transitionName="fade"
      >
        <div className="spaces-mobile-content">
          <div className="title-holder flex-container">
            <span>PRINT SPACES</span>
            <div style={{ flexGrow: 8 }}>
              <div className="btn-close" onClick={props.onClose} />
            </div>
          </div>
          <div className="spaces-holder row">{items}</div>
        </div>
      </ReactCSSTransitionGroup>
    </Container>
  );
};

PrintSpacesMobile.propTypes = {
  spaces: PropTypes.instanceOf(List).isRequired,
  handleSelect: PropTypes.func,
  onClose: PropTypes.func
};

export default PrintSpacesMobile;
