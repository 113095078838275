import { fromJS } from 'immutable';

const defaultConfig = fromJS({
  defaultWidth: 1024,
  defaultHeight: 1024,
  maxWidth: 2048,
  maxHeight: 2048,
  minWidth: 500,
  minHeight: 500
});

export default defaultConfig;
