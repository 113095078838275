/* eslint-disable max-len */
import React from 'react';
import UserDetails from './pages/UserDetails';
import PropTypes from 'prop-types';
import BusinessDetails from './pages/BusinessDetails';
import StoreDetails from './pages/StoreDetails';
import './SignupView.scss';
import Config from '../config';
import SignUpInfo from './pages/SignUpInfo';
import Header from './pages/Header';
import { Helmet } from 'react-helmet';
import fetchJsonp from 'fetch-jsonp';
import signupService from './SignupAPIService';
import { postJson } from 'gooten-components/src/utils/http';
import { mainStyles } from 'gooten-components/src/styles.js';
import analyticsService from 'gooten-components/src/services/analyticsService';
import sha1 from 'sha1';
import ReCAPTCHA from 'react-google-recaptcha';

const pages = {
  USER_DETAILS: 'userDetails',
  BUSINESS_DETAILS: 'businessDetails',
  STORE_DETAILS: 'storeDetails'
};

const whiteLabelledPartners = [
  {
    partnerName: 'sensaria',
    formattedName: 'Sensaria',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/sensaria/logo.svg',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogoWhite.svg',
    xStyle: 'white-x',
    favIcon: '/admin-assets/img/AssetsWhiteLabel/sensaria/favicon.ico',
    topBgColor: '#1D5F79',
    botBgColor: '#e7e7e7',
    header: 'Grow your business with custom print-on-demand solutions.',
    checklist: [
      '1. Complete the Sign Up form',
      '2. Set up your new account and submit billing information',
      '3. Create your first products and start placing orders'
    ]
  },
  {
    partnerName: 'fujifilm',
    formattedName: 'Fujifilm',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/fujifilm/logo.svg',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogo.svg',
    favIcon: '/admin-assets/img/AssetsWhiteLabel/fujifilm/favicon.ico',
    topBgColor: '#F2F2F2',
    botBgColor: '#000',
    header: 'When operations matter, Fujifilm x Gooten makes the difference.',
    checklist: [
      'Create Fujifilm: Print on Demand apparel items and dozens more fully customizable products at preferential pricing',
      'High-quality, scalable, on-demand order fulfillment for global rights holders & their brands',
      'Built-in Shopify integration with tools to create, link, and sell new & existing products in minutes',
      'White glove customer service & account management for established brands'
    ]
  }
];

let subDomain = window.location.host.split('.')[0];
const partnerData = whiteLabelledPartners.find(
  element => element.partnerName === subDomain || `${element.partnerName}-stage` === subDomain
);

class SignupView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      captchaToken: '',
      currentPage: pages.USER_DETAILS,
      data: {
        userDetails: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          country: ''
        },
        businessDetails: {
          storeStatus: undefined,
          integration: undefined
        },
        storeDetails: {
          companyName: '',
          storeUrl: '',
          agreeToTerms: false
        },
        countries: []
      },
      queryParams: '',
      isSubmitting: false,
      errors: []
    };

    document.documentElement.style.height = '100%';
    document.body.style.minHeight = '100%';
    document.body.style.background = partnerData ? partnerData.botBgColor : '#0a2599';

    // our index page uses both versions of favicon implementations, update both
    let iconLink = document.querySelector('link[rel="icon"]');
    if (iconLink && partnerData) iconLink.href = `${partnerData.favIcon}`;
    let shortcutIconLink = document.querySelector('link[rel="shortcut icon"]');
    if (shortcutIconLink && partnerData) shortcutIconLink.href = `${partnerData.favIcon}`;

    document.title = `Sign Up | ${partnerData ? partnerData.formattedName : 'Gooten'}`;
  }

  componentDidMount() {
    if (window.location.search.length > 0) {
      this.setState({ queryParams: this.parseQueryString(window.location.search) });
    }

    // This ensure the user can't be ahead of the signup on refresh or url copy paste
    if (this.props.params.page !== '1') {
      this.props.router.replace('/1');
    }
    this.fetchUserCountry();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.page !== prevProps.params.page) {
      this.changeRoute(this.props.params.page);
    }
  }

  onChangeCaptcha = value => {
    this.setState({ captchaToken: value });
  };

  parseQueryString(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return JSON.stringify(query);
  }

  getCountryFromList = async countryCode => {
    let country = 'United States';

    return new Promise(resolve => {
      signupService
        .getCountries()
        .then(countries => {
          this.setState({
            data: {
              ...this.state.data,
              countries
            }
          });
          countries.forEach(countryItem => {
            if (countryItem.code === countryCode) {
              resolve(countryItem.name);
            }
          });
          resolve(country);
        })
        .catch(() => resolve(country));
    });
  };

  fetchUserCountry = async () => {
    fetchJsonp('https://printio-geo.appspot.com/ip/jsonp')
      .then(response => {
        return response.json();
      })
      .then(async countryCode => {
        let country = await this.getCountryFromList(countryCode);
        this.setState({
          data: {
            ...this.state.data,
            userDetails: {
              ...this.state.data.userDetails,
              country: country
            }
          }
        });
      })
      .catch(() => {
        this.setState({
          data: {
            ...this.state.data,
            userDetails: {
              ...this.state.data.userDetails,
              country: 'United States'
            }
          }
        });
      });
  };

  changeRoute = page => {
    switch (page) {
      case '1':
        this.setState({ currentPage: pages.USER_DETAILS });
        break;
      case '2':
        this.setState({ currentPage: pages.BUSINESS_DETAILS });
        break;
      case '3':
        this.setState({ currentPage: pages.STORE_DETAILS });
        break;
      default:
        this.setState({ currentPage: pages.USER_DETAILS });
        break;
    }
  };

  onBackButton = () => {
    switch (this.state.currentPage) {
      case pages.BUSINESS_DETAILS:
        this.props.router.goBack();
        break;
      case pages.STORE_DETAILS:
        this.props.router.goBack();
        break;
      default:
        this.props.router.push('/1');
        break;
    }
  };

  onUserDetails = userDetails => {
    this.props.router.push('/2');
    this.setState({
      data: {
        ...this.state.data,
        userDetails
      }
    });
  };

  onBusinessDetails = businessDetails => {
    this.props.router.push('/3');
    this.setState({
      data: {
        ...this.state.data,
        businessDetails
      }
    });
  };

  onStoreDetails = storeDetails => {
    this.setState(
      {
        data: {
          ...this.state.data,
          storeDetails
        },
        isSubmitting: true
      },
      this.signUpUser
    );
  };

  bypass = email => {
    const emails = [
      'automation@tests.com',
      'hub_partner_at@gooten.com',
      'partner-account2@gooten.com',
      'enabled-user@disabled-partner.com',
      'partner-account4@gooten.com',
      'micah+vendor-test@breakoutcommerce.com',
      'manualww@test.com'
    ];

    // the above accounts (plus those containing 'test+QA') are used by QA scripts and should be allowed to bypass the recaptcha check
    if (emails.includes(email) || email.includes('test+QA')) {
      // pass back the recaptcha token that the api recognizes as a token to bypass
      return 'bypass-b4d49408-c49f-463c-a417-bdca7594e27a';
    }

    return false;
  };

  signUpUser = () => {
    const { queryParams } = this.state;
    const { userDetails, businessDetails, storeDetails } = this.state.data;

    let userData = {
      Name: userDetails.firstName.trim() + ' ' + userDetails.lastName.trim(),
      Email: userDetails.email,
      BusinessName: userDetails.email,
      Company: storeDetails.companyName.trim(),
      Password: userDetails.password,
      Country: userDetails.country,
      NoIntegration: businessDetails.integration === 'Other',
      Api: businessDetails.integration === 'API',
      Mobile: businessDetails.integration === 'API',
      WebMobile: businessDetails.integration === 'API',
      Shopify: businessDetails.integration === 'Shopify',
      ShopifyStoreName: businessDetails.integration === 'Shopify' ? storeDetails.storeUrl : null, // businessDetails.integration === 'Shopify' ? shopifyUrl : null,
      Etsy: businessDetails.integration === 'Etsy',
      EtsyStoreName: null, // businessDetails.integration === 'Etsy' ? company : null,
      WooCommerce: businessDetails.integration === 'WooCommerce',
      WooCommerceStoreName: null, // businessDetails.integration === 'Shopify' ? shopifyUrl : null,
      BigCommerce: businessDetails.integration === 'BigCommerce',
      BigCommerceStoreName: null,
      Website: storeDetails.storeUrl,
      Subdomain: window.location.host.split('.')[0].replace(/\-stage/gi, ''),
      QueryStringObj: queryParams
    };

    if (businessDetails.integration === 'Shopify') {
      userData.ShopifyStoreName = userData.ShopifyStoreName.replace('https://', '')
        .replace('.myshopify.com/', '')
        .replace('.myshopify.com', '');
      userData.Website = userData.Website.replace('https://', '')
        .replace('.myshopify.com/', '')
        .replace('.myshopify.com', '');
    }

    return fetch(`${Config.get('adminApi')}PartnerSignupApi/Submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(data => {
        if (data.HasError) {
          this.setState({
            errors: data.Errors,
            isSubmitting: false
          });
        } else {
          const submit = async () => {
            const token =
              this.bypass(userData.Email) ||
              this.state.captchaToken ||
              (await this.recaptchaRef.current.executeAsync());
            const sha1Email = sha1(userData.Email);
            analyticsService.impactIdentify(data.PartnerId, sha1Email);
            analyticsService.impactOrderTrack(data.PartnerId, data.PartnerId, sha1Email);
            window.localStorage.userId = data.PartnerId;
            window.localStorage.emailSha1 = sha1Email;
            // Facebook pixel event trigger
            analyticsService.fbqTrack('CompleteRegistration');
            postJson(`/admin/account/LogOnAjax`, {
              UserName: userDetails.email,
              Password: userDetails.password,
              captcha: token
            })
              .then(
                data =>
                  new Promise((resolve, reject) => {
                    data.isAuthenticated ? resolve(data) : reject(data);
                  })
              )
              .then(data => {
                window.localStorage.loginRedirect = true;
                window.localStorage.isFirstLogin = data.isFirstLogin;
                window.localStorage.isWelcomeMessageShown = false;
                window.localStorage.signUpFlow = false;
                window.localStorage.token = data.User.ApiKey;
                window.location.href = '/admin#/onboardingdashboard?firstLogin=true';
              })
              .catch(reason => {
                this.setState({
                  errors: ['An unknown error has occurred.  Please try again later.'],
                  isSubmitting: false
                });
              });
          };
          submit();
        }
      })
      .catch(err => {
        console.error('Something went wrong.', err);
        this.setState({
          errors: ['An unknown error has occurred.  Please try again later.'],
          isSubmitting: false
        });
      });
  };

  render() {
    const { currentPage } = this.state;
    return (
      <div className={`sign-up ${partnerData ? partnerData.partnerName : ''}`}>
        <Helmet>
          <meta
            name="description"
            content="Create your Gooten account and get started with our print on-demand order management system."
          />
        </Helmet>
        <Header partnerData={partnerData} />
        {this.state.data.userDetails.country.length > 0 && (
          <div className="middle-content">
            <UserDetails
              show={currentPage === pages.USER_DETAILS}
              userDetailValues={this.state.data.userDetails}
              countries={this.state.data.countries}
              onSubmit={this.onUserDetails}
            />
            <BusinessDetails
              show={currentPage === pages.BUSINESS_DETAILS}
              businessDetailValues={this.state.data.businessDetails}
              onSubmit={this.onBusinessDetails}
              onBackButton={() => this.onBackButton()}
            />
            <StoreDetails
              show={currentPage === pages.STORE_DETAILS}
              onSubmit={this.onStoreDetails}
              storeType={this.state.data.businessDetails.integration}
              onBackButton={() => this.onBackButton()}
              isSubmitting={this.state.isSubmitting}
              errors={this.state.errors}
            />
            <SignUpInfo partnerData={partnerData} />
            <ReCAPTCHA
              // Note: Staging site key is to allow automated tests to work in staging env.
              // Swap keys to test locally.
              sitekey={
                Config.get('env') === 'staging'
                  ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // staging key
                  : '6LddGPUZAAAAABzQtPIEygB83xnjdSv0c9YgWr63'
              } // production key
              onChange={this.onChangeCaptcha}
              onExpired={() => this.setState({ captchaToken: '' })}
              onErrored={() => this.setState({ captchaToken: '' })}
              ref={this.recaptchaRef}
              size="invisible"
            />
          </div>
        )}
        <style jsx global>
          {mainStyles}
        </style>
      </div>
    );
  }
}

SignupView.propTypes = {
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default SignupView;
