import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead, Menu, menuItemContainer, Highlighter } from 'react-bootstrap-typeahead';

import './Autocomplete.scss';
import Button from '../Button';
import Icon from '../Icon';
import { COLORS } from '../../../constants';

// NOTE: In latest ver of react-bootstrap-typeahead which suppoorts React16
// it renders menu items as <a href='#'>
// which force redirects in AngularJS.
// CustomMenuItem to fix it.
const CustomMenuItem = menuItemContainer(props => (
  <li className={props.active ? 'active' : ''}>
    <a role="button" onClick={props.onClick}>
      {props.children}
    </a>
  </li>
));

const propTypes = {
  allowTypedDuplicates: PropTypes.bool,
  allowSelectedDuplicates: PropTypes.bool,
  children: PropTypes.any,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  maxSelected: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  onNew: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onAdd: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onRemove: PropTypes.func.isRequired
};

export const Autocomplete = props => {
  let typeahead = null;

  // Product collections now are represented by an object to add id property since collection
  // name is not an appropriate identifier have (duplicate names should cooexist)
  const convertToString = (item, propName = 'name') => {
    if (typeof item === 'object' && item.hasOwnProperty(propName)) {
      return (item[propName] || '').toString();
    }
    return item;
  };

  const canSelect = !props.maxSelected || props.selectedItems.length < props.maxSelected;

  const select = value => {
    const valueLower = convertToString(value).toLowerCase();

    if (
      !props.items.find(i => valueLower === convertToString(i).toLowerCase()) ||
      (props.allowTypedDuplicates && typeof value === 'string')
    ) {
      props.onNew(value);
      return;
    }

    // if SelectedDuplicates are allowed filter and choose from existing items if they have ids.
    // do not create new items with duplicate names.
    if (
      !props.selectedItems.find(i => valueLower === convertToString(i).toLowerCase()) ||
      props.allowSelectedDuplicates
    ) {
      if (props.items.length && props.items[0].id) {
        if (props.allowSelectedDuplicates) {
          const existingDuplicates = props.items.filter(
            i => valueLower === convertToString(i).toLowerCase()
          );
          const selectedDuplicates = props.selectedItems.filter(
            i => valueLower === convertToString(i).toLowerCase()
          );
          const reqItem = existingDuplicates.find(
            x => !selectedDuplicates.find(y => y.id === x.id)
          );
          return reqItem ? props.onAdd(reqItem) : null;
        }
        return;
      }
      props.onAdd(value);
    }
  };

  const onSubmit = e => {
    if (!canSelect) return;
    e.preventDefault();
    let inputValue = e.target.elements[0].value;
    if (!inputValue) {
      return;
    }
    inputValue = inputValue.substring(0, 30);
    typeahead.getInstance().clear();
    select(inputValue);
  };

  const onChange = e => {
    if (
      e.length &&
      e[0] &&
      convertToString(e[0]).length &&
      props.selectedItems.indexOf(e[0]) === -1 &&
      canSelect
    ) {
      select(e[0]);
      typeahead && typeahead.getInstance().clear();
    }
  };

  const onKeyDown = e => {
    // latest ver doesnt support submitFormOnEnter
    // handle on enter press manually
    if (e.keyCode === 13) {
      const instance = typeahead.getInstance();
      const value = instance.state.activeItem || instance.state.text;
      onChange([value]);
    }
  };

  const selectedItems = props.selectedItems.map(i => (
    <React.Fragment key={typeof i === 'object' ? i.id : i}>
      <Button onClick={() => props.onRemove(i)} className="button-default medium mr-1 mb-1">
        <Icon icon="x" />
        <div>{convertToString(i)}</div>
      </Button>
    </React.Fragment>
  ));

  return (
    <div className="autocomplete-container">
      {props.children}
      <div className="clearfix" />
      <div className="row">
        <div className="form-container col-xs-12">
          <form onSubmit={onSubmit}>
            <div className="label">{props.label}</div>
            <Typeahead
              id="type-ahead-id"
              ref={input => {
                typeahead = input;
              }}
              filterBy={(option, props) => convertToString(option)}
              labelKey={option => (typeof option === 'object' ? option.name : option)}
              valueKey="id"
              options={props.items}
              renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>
                  {results.map((result, index) => (
                    <CustomMenuItem key={index} option={result} position={index}>
                      <Highlighter search={menuProps.text}>{convertToString(result)}</Highlighter>
                    </CustomMenuItem>
                  ))}
                </Menu>
              )}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder="Choose..."
              maxResults={1000}
              paginate={false}
            />
            {canSelect ? (
              <button type="submit" className="submit-btn">
                <Icon icon="plusCircle" fill={COLORS.neutralDark200} />
              </button>
            ) : (
              <span className="help-block text-left">
                Only {props.maxSelected} items can be selected
              </span>
            )}
          </form>
        </div>
      </div>
      {!!props.selectedItems.length && <div className="selected-items">{selectedItems}</div>}
      <style jsx>
        {`
          form {
            position: relative;
          }
          .label {
            position: absolute;
            font-size: 0.8125rem;
            top: 0.25rem;
            transform: translateY(0);
            color: #646b72;
            z-index: 1;
            left: 0.5rem;
            font-family: Avenir Heavy, Avenir-Roman, sans-serif;
          }
          .selected-items :global(button) {
            max-width: 100%;
          }
          .selected-items :global(button div) {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .selected-items :global(svg) {
            min-width: 24px !important;
          }
        `}
      </style>
    </div>
  );
};

Autocomplete.propTypes = propTypes;

export default Autocomplete;
