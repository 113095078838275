import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import PagedQuery from '../../../../models/PagedQuery';
import HubProductsList from './HubProductsList';
import Pager from 'gooten-components/src/components/shared/Pager';
import Loader from 'gooten-components/src/components/shared/Loader';
import EmptySearchResultContainer from 'gooten-components/src/components/ProductSelection/atoms/ProductList/EmptySearchResult';

class HubProducts extends React.PureComponent {
  inContainer = children => <div>{children}</div>;

  componentDidMount() {
    if (!this.props.isHubProductsLoaded) {
      this.props.loadHubProducts(this.props.hubProductsQuery);
    }
  }

  componentDidUpdate() {
    if (!this.props.isHubProductsLoaded) {
      this.props.loadHubProducts(this.props.hubProductsQuery);
    }
  }

  render() {
    if (!this.props.isHubProductsLoaded) {
      return this.inContainer(<Loader />);
    } else if (this.props.hubProducts.size === 0) {
      return this.inContainer(<EmptySearchResultContainer />);
    } else {
      const pages = Array.from(Array(this.props.totalPages).keys()).map(i => ({
        name: i + 1,
        index: i + 1
      }));

      return this.inContainer(
        <div className="hub-products-container mt-4">
          <HubProductsList
            hubProducts={this.props.hubProducts.toJS()}
            selectedItems={this.props.selectedItems}
            onHubProductSelectionToggled={this.props.onHubProductSelectionToggled}
            editDesign={this.props.editDesign}
          />
          <div className="spacing-top mt-4" />
          {this.props.totalPages > 1 && (
            <Pager
              items={pages}
              maxSize={5}
              current={this.props.currentPage}
              goTo={e => this.props.setCurrentPage(e)}
            />
          )}
          <div className="spacing-top" />
        </div>
      );
    }
  }

  componentWillUnmount() {
    this.props.deselectAll();
  }
}

HubProducts.propTypes = {
  isHubProductsLoaded: PropTypes.bool.isRequired,
  hubProductsQuery: PropTypes.instanceOf(PagedQuery).isRequired,
  hubProducts: PropTypes.instanceOf(List).isRequired,
  loadHubProducts: PropTypes.func.isRequired,
  selectedItems: PropTypes.instanceOf(List),
  onHubProductSelectionToggled: PropTypes.func.isRequired,
  deselectAll: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPages: PropTypes.number,
  editDesign: PropTypes.func.isRequired
};

export default HubProducts;
