// @flow

import {Circle, Group, Line} from 'konva';
import {imageUpdate} from '../../../state/actions/actionCreators';
import TooltipComponent from '../tooltip/tooltip.component';
import RotateService  from './rotate.service.js';
import type { Point } from '../../../flow/types';
import EventEmitter from 'events';
import { noBubble } from '../../../utils/eventUtils';
import RotateCenterImage from './rotateCenter.image';
import { PUBLIC_EVENTS, INTERNAL_EVENTS } from '../../../ImageEditor.events';
import {
  ROTATE_ANCHOR_RADIUS,
  ROTATE_ANCHOR_COLOR
} from '../../../ImageEditor.const';

export default class RotateAction {

  actionGroup: Group;
  actionRectElm: any;
  tooltip: TooltipComponent;
  publicEvents: EventEmitter;
  rotateAnchor: any;

  constructor(
    actionGroup: Group,
    actionRectElm: any,
    tooltip: TooltipComponent,
    publicEvents: EventEmitter
  ) {
    this.publicEvents = publicEvents;
    this.actionGroup = actionGroup;
    //TODO REMOVE NOT USED
    this.actionRectElm = actionRectElm;
    this.tooltip = tooltip;
  }

  _getRotateAnchor(rotateAnchorPoint: Point) {
    const rotateAnchor = new Circle({
      x: rotateAnchorPoint.x,
      y: rotateAnchorPoint.y + 1,
      radius: ROTATE_ANCHOR_RADIUS,
      fill: ROTATE_ANCHOR_COLOR,
      fillEnabled: true
    });
    rotateAnchor.addName('rotateAnchor');

    rotateAnchor.off('mouseover').on('mouseover', function (evt) {
      document.body.style.cursor = 'pointer';
    });

    rotateAnchor.off('mouseout').on('mouseout', function (evt) {
      document.body.style.cursor = 'default';
    });

    return rotateAnchor;
  }

  _getRotateAnchorLine(rotateAnchorPoint: Point) {
    return new Line({
      points: rotateAnchorPoint.position === 'top'
        ? [
          rotateAnchorPoint.x, -10,
          rotateAnchorPoint.x, 0
        ]
        : [
          rotateAnchorPoint.x - ROTATE_ANCHOR_RADIUS/2 - 12, rotateAnchorPoint.y,
          rotateAnchorPoint.x - ROTATE_ANCHOR_RADIUS/2, rotateAnchorPoint.y
        ],
      stroke: ROTATE_ANCHOR_COLOR,
      strokeWidth: 2
    });
  }

  draw(ctx: any) {
    let rotateAnchorPoint = RotateService.getRotateAnchorPoint(ctx.state);
    let rotateAnchor = this._getRotateAnchor(rotateAnchorPoint);
    let rotateAnchorLine = this._getRotateAnchorLine(rotateAnchorPoint);

    this.actionGroup.add(rotateAnchor);
    this.actionGroup.add(rotateAnchorLine);

    let correlation = 0;
    let rotateCenterRect = RotateService.getRotateCenterRect(ctx.state);
    let centerIndicator = null;
    Konva.Image.fromURL(RotateCenterImage.getBase64(), (image) => {
      centerIndicator = image;
      centerIndicator.position({
        x: rotateCenterRect.x,
        y: rotateCenterRect.y
      });
      centerIndicator.size({
        width: rotateCenterRect.width,
        height: rotateCenterRect.height,
      });
      centerIndicator.visible(false);

      // add image when it's loaded
      this.actionGroup.add(centerIndicator);
    });

    rotateAnchor.off('mousedown').on('mousedown', () => {
      // Disable draging when rotation start
      this.actionGroup.setDraggable(false);
      rotateAnchor.controlled = true;

      let mousePos = ctx.stage.getPointerPosition();

      //Save correlation for current rotation and mouse position
      correlation = RotateService.getRotationAngleDegrees(
        this.actionGroup.getX(),
        this.actionGroup.getY(),
        mousePos.x,
        mousePos.y,
        ctx.stage.getWidth(),
        ctx.state.editor.defaultOrientation !== ctx.state.editor.orientation,
        this.actionGroup.rotation() || 0 //Base correlation it's current rotation angle
      );

    });

    // Rotation
    ctx.stage.off('contentMousemove').on('contentMousemove', () => {
      if (rotateAnchor.controlled) {

        ctx.stage.stopDrag();

        centerIndicator.show();

        // Calc rotation angle in degrees
        let mousePos = ctx.stage.getPointerPosition();
        let hasOrientationChanged = ctx.state.editor.defaultOrientation !== ctx.state.editor.orientation;
        let rotationAngleDegrees = RotateService.getRotationAngleDegrees(
          this.actionGroup.getX(),
          this.actionGroup.getY(),
          mousePos.x,
          mousePos.y,
          ctx.stage.getWidth(),
          hasOrientationChanged,
          correlation
        );

        // Rotate action group
        this.actionGroup.rotation(rotationAngleDegrees);

        // Tell ImageLayer to update image
        ctx.events.emit(INTERNAL_EVENTS.IMAGE_UPDATE, {
          imageId: ctx.state.images.current.selected.imageId,
          rotation: rotationAngleDegrees
        });

        // Show rotation degrees
        this.tooltip.show(ctx, rotationAngleDegrees);

        ctx.stage.batchDraw();
      }
    });

    ctx.stage.off('contentMouseup').on('contentMouseup', (event) => {
      if (rotateAnchor.controlled) {

        // NOTE: Cancel bubble to skip it in stage content click
        // drag.action add listener to stage.contentClick event
        // if it's not canceled then it will toggle edit mode
        // when click outside of image
        noBubble(event.evt);

        rotateAnchor.controlled = false;

        // Hide indicators after rotation
        this.tooltip.hide();
        centerIndicator.hide();

        // Enable draging once rotation ended
        this.actionGroup.setDraggable(true);

        // Calc rotation angle in degrees
        let mousePos = ctx.stage.getPointerPosition();
        let hasOrientationChanged = ctx.state.editor.defaultOrientation !== ctx.state.editor.orientation;
        let rotationAngleDegrees = RotateService.getRotationAngleDegrees(
          this.actionGroup.getX(),
          this.actionGroup.getY(),
          mousePos.x,
          mousePos.y,
          ctx.stage.getWidth(),
          hasOrientationChanged,
          correlation
        );

        // Persist image position to state
        ctx.actionProcessorCb(
          imageUpdate(
            ctx.state.images.current.selected.imageId,
            { rotation: rotationAngleDegrees }
          )
        );

        // fire public event
        this.publicEvents.emit(PUBLIC_EVENTS.ROTATE, rotationAngleDegrees);
      }
    });

    // NOTE: All immediate image updates happens only here
    ctx.events.on(INTERNAL_EVENTS.IMAGE_UPDATE, eventArgs => {
      if(eventArgs.action == 'zoom'){
        rotateAnchorLine.hide();
        rotateAnchor.hide();
      }
    });

  }
}
