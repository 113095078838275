import { connect } from 'react-redux';
import EmbroideryDisclaimer from './EmbroideryDisclaimer';
import {
  disclaimerPayloadSelector,
  disclaimerVisibilitySelector
} from './EmbroideryDisclaimerSelectors';
import { showDisclaimer } from './EmbroideryDisclaimerActions';
import { modalOpen } from '../../../../shared/ImageSelectionModal/ImageSelectionModalActions';

const mapDispatchToProps = {
  showDisclaimer: value => showDisclaimer({ visible: value }),
  handleAccept: payload => modalOpen(payload)
};

const mapStateToProps = (state, ownProps) => ({
  disclaimerVisible: disclaimerVisibilitySelector(state),
  disclaimerPayload: disclaimerPayloadSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbroideryDisclaimer);
