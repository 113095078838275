import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

const connectStateSelector = state => state.getIn(['stores']) || fromJS({});

export const busySelector = createSelector([connectStateSelector], connectState =>
  connectState.get('busy')
);
export const errorMsgSelector = createSelector([connectStateSelector], connectState =>
  connectState.get('errorMsg')
);
export const successMsgSelector = createSelector([connectStateSelector], connectState =>
  connectState.get('successMsg')
);
export const connectStepSelector = createSelector([connectStateSelector], connectState =>
  connectState.get('step')
);
export const connectProviderSelector = createSelector([connectStateSelector], connectState =>
  connectState.get('provider')
);

const disconnectStateSelector = state => state.getIn(['stores', 'disconnect']) || fromJS({});
export const disconnectStoreProviderSelector = createSelector(
  [disconnectStateSelector],
  disconnectState => disconnectState.get('provider')
);
export const disconnectStoreIdSelector = createSelector(
  [disconnectStateSelector],
  disconnectState => disconnectState.get('storeId')
);
