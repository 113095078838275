import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { cancelableWithError } from '../../../utils/promise';
import './ImageThumb.scss';

class ImageThumb extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onImageClick = this.onImageClick.bind(this);
    this.onLoadingError = this.onLoadingError.bind(this);
    this.state = {
      detailsOpen: false,
      loading: true,
      hasError: false
    };
    this.updateImage(props);
  }

  onLoadingError(reason = {}) {
    if (!reason.isCanceled && this.refs.placeholder) {
      this.refs.placeholder.className = this.refs.placeholder.className.replace(
        ' image-missing',
        ''
      );
      this.refs.placeholder.className = this.refs.placeholder.className + ' image-error';
      this.setState({ loading: false, hasError: true });

      // check if this image was auto selected. if yes, then remove selection,
      // because loading error occured
      if (this.props.selected) {
        this.handleImageClick();
      }
    }
  }

  updateImage(props) {
    if (props.imageUrl) {
      this.imageLoadTask = cancelableWithError(
        new Promise((resolve, reject) => {
          this.img = new Image();
          let finished = false;
          this.img.onload = () => {
            if (!finished) {
              finished = true;
              resolve();
            }
          };
          this.img.onerror = () => {
            if (!finished) {
              finished = true;
              // eslint-disable-next-line prefer-promise-reject-errors
              reject();
            }
          };
          this.img.src = props.imageUrl;
        })
      );

      this.imageLoadTask.promise.then(() => {
        if (this.refs.placeholder) {
          // NOTE: Using div with background image here since need to keep all static images in css
          // Which correctly resolve relative path (admin integration)
          this.refs.placeholder.style.background = `url('${props.imageUrl}') center center / contain no-repeat`;
          this.refs.placeholder.className = this.refs.placeholder.className.replace(
            ' image-missing',
            ''
          );
          this.setState({ loading: false, hasError: false });
        }
      }, this.onLoadingError);

      this.imageLoadTask.promise.catch(this.onLoadingError);
    }
  }

  onMouseLeaveHandler() {
    if (this.state.detailsOpen) this.setState({ detailsOpen: false });
  }

  onImageClick() {
    if (!this.props.loading && !this.props.error && !this.state.hasError) {
      this.handleImageClick();
    }
    this.onMouseLeaveHandler();
  }

  handleImageClick() {
    if (this.props.onImageClick) {
      this.props.onImageClick(this.props.selected, this.props.imageId, this.props.customParam);
    }
  }

  openDetails() {
    this.setState({ detailsOpen: !this.state.detailsOpen });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.updateImage(this.props);
    }
  }

  componentWillUnmount() {
    if (this.imageLoadTask) {
      this.imageLoadTask.cancel();
      // stop browser image loading
      this.img.src = '';
    }
  }

  render() {
    let removable = !!this.props.onRemoveClick;

    let icon =
      this.state.loading && !this.props.error ? (
        <Spinner />
      ) : this.props.selected ? (
        <span className="icon-checkmark" />
      ) : null;

    let removeIcon =
      removable && !this.props.loading ? (
        <div
          className="image-details-actions-container"
          onMouseLeave={() => this.onMouseLeaveHandler()}
        >
          <span
            className="icon-more-dropdown-v2"
            onClick={e => {
              e.stopPropagation();
              this.openDetails();
            }}
          />
          <button
            className={
              this.state.detailsOpen
                ? 'btn btn-default remove-image-btn active'
                : 'btn btn-default remove-image-btn'
            }
            onClick={e => {
              e.stopPropagation();
              this.props.onRemoveClick(this.props.imageId);
            }}
          >
            Delete from Images
          </button>
        </div>
      ) : null;
    return (
      <div
        onClick={this.onImageClick}
        className={`image-thumb ${
          this.props.loading
            ? ' loading'
            : this.props.error
            ? ' error'
            : this.props.selected
            ? ' active'
            : ''
        }`}
      >
        {icon}
        <div className="thumb-container">
          <div className="img-wrap flex-container flex-center-aligned">
            <div className="responsive-container">
              <div
                className={
                  'img-responsive center-block' +
                  (this.props.error ? ' image-error' : ' image-missing')
                }
                ref="placeholder"
              />
            </div>
          </div>
        </div>
        {removeIcon}
        {this.props.caption && this.props.caption.length ? (
          <div className="caption">
            {this.props.loading ? 'Loading...' : this.props.error ? 'Error' : this.props.caption}
          </div>
        ) : null}
        {this.props.title && this.props.title.length ? (
          <p className="title">{this.props.title}</p>
        ) : null}
      </div>
    );
  }
}

ImageThumb.propTypes = {
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onImageClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  imageId: PropTypes.number,
  customParam: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  imageUrl: PropTypes.string,
  caption: PropTypes.string,
  title: PropTypes.string
};

export default ImageThumb;
