import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'immutable';
import PreviewImageItem from '../shared/PreviewImageItem';
import './DesignFullscreen.scss';

const Container = styled.div`
  flex-direction: column;

  .fullscreen-images-list {
    height: 440px;
    overflow-y: auto;

    .design-item {
      height: 167px;
      padding-left: 32px;
      margin-bottom: 32px;

      .publish-preview-image-item {
        margin-right: 15px;
        height: 167px;
        width: 167px;

        .image-placeholder {
          height: 167px;
          padding-bottom: 0;
        }
      }

      .info {
        flex-direction: column;
        height: 46%;
        justify-content: space-between;
        margin-top: 10px;

        span {
          font-weight: 900;
        }

        span:nth-child(1) {
          font-size: 13px;
        }

        span:nth-child(3) {
          font-size: 12px;
        }

        .file-name-holder {
          span:nth-child(1) {
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;
            padding: 2px 8px 0 8px;
            border-radius: 17px;
            text-align: center;
            width: 40px;
            background: gray;
          }
        }
      }
    }
  }
`;

const DesignFullscreen = props => {
  const item = (design, i) => (
    <div className="design-item flex-container" key={`${design.get('imageUrl')}${i}`}>
      <PreviewImageItem imageUrl={design.get('imageUrl')} />
      <div className="info flex-container">
        <span>{design.get('spaceNames').join(', ').toUpperCase()}</span>
        <div className="file-name-holder flex-container">
          <span>{design.get('format')}</span>
        </div>
        <span>{`${design.get('height')} x ${design.get('width')} PX`}</span>
      </div>
    </div>
  );

  const designs = props.designs.map((design, i) => item(design, i));

  return (
    <Container className="product-publish-design-fullscreen flex-container">
      <div className="fullscreen-images-list">{designs}</div>
    </Container>
  );
};

DesignFullscreen.propTypes = {
  designs: PropTypes.instanceOf(List)
};

export default DesignFullscreen;
