import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Input from '../../../../../shared/Input';
import Checkbox from '../../../../../shared/Checkbox';
import { TableRow, Cell } from '../../../../../shared/Table';

const ProductPricingItem = props => {
  return (
    <TableRow
      className="product-pricing-item"
      size="auto"
      columns="3rem minmax(15rem, calc(100% - 28rem)) 8rem 8rem 8rem"
    >
      <Cell className="pricing-data static-data sticky-left">
        <Checkbox checked={props.variant.get('priceSelected')} onChange={props.handleSelectItem} />
      </Cell>
      <Cell className="pricing-data">
        <span className="pt-1 pb-1">{props.variant.get('sku')}</span>
      </Cell>
      <Cell className="pricing-data">
        <Input small noLabel className="text-center" disabled value={props.variant.get('price')} />
      </Cell>
      <Cell className="pricing-data">
        <Input
          small
          noLabel
          className="text-center"
          disabled={false}
          onChange={e => props.onInput(props.index, 'margin', e.target.value)}
          onBlur={e => props.onMarginChange(props.index, e.target.value)}
          value={'$' + (props.variant.get('margin') + '').replace(/\$/g, '')}
        />
      </Cell>
      <Cell className="pricing-data">
        <Input
          small
          noLabel
          className="text-center"
          disabled={false}
          onChange={e => props.onInput(props.index, 'customerPrice', e.target.value)}
          onBlur={e => props.onPriceChange(props.index, e.target.value)}
          value={'$' + props.variant.get('customerPrice')}
        />
      </Cell>
      <style jsx>
        {`
          :global(.product-pricing-item) {
            position: unset !important;
          }
          :global(.pricing-data.sticky-left label) {
            position: absolute;
            left: 0;
            z-index: 1;
            height: 3rem;
            padding: 0 1rem;
          }
        `}
      </style>
    </TableRow>
  );
};

ProductPricingItem.propTypes = {
  index: PropTypes.number,
  variant: PropTypes.instanceOf(Map),
  handleSelectItem: PropTypes.func,
  onInput: PropTypes.func,
  onMarginChange: PropTypes.func,
  onPriceChange: PropTypes.func
};

export default ProductPricingItem;
