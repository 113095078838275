// @flow

export type KonvaShape = {
  draw:()=>void;

  // not sure how to express this
  scaleX:(...n:any)=>number;
  scaleY:(...n:any)=>number;
}
export type KonvaLayer = {
  draw:()=>void;
  add: (shape:KonvaShape) => void;
  on:(name:string,fn:(e:any)=>void) => void;
};
export type KonvaStage = {
  add: (layer: KonvaLayer | KonvaShape) => void;
};

export type SurfaceElement = {
  addToCanvas: (layer: KonvaLayer) => void;
  on:(name:string,fn:(e:any)=>void) => void;
}

export type SurfaceLayer = {
  id: string;
  addToCanvas: (stage: KonvaStage) => void;
  draw: (args: any) => void;
}

export type ActionControl = {
  id: string;
  draw: (args: any) => void;
}

export const ImageEvents = {
  dragStart: 'dragStart',
  dragEnd: 'dragEnd',
  mouseEnter: 'mouseEnter',
  mouseLeave: 'mouseLeave'
};
