import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import Icon from 'gooten-components/src/components/shared/Icon';
import OptionsModal from 'gooten-components/src/components/shared/OptionsModal';
import PreviewImageItem from 'gooten-components/src/components/ProductPublish/atoms/shared/PreviewImageItem';
import NeckTagUploadModal from 'gooten-components/src/components/shared/NeckTagUploadModal';
import Button from 'gooten-components/src/components/shared/Button';
import Config from 'gooten-components/src/config';

const Container = styled.div`
  .image-column {
    img {
      height: 40px;
      object-fit: cover;
    }
  }
  .options-button {
    line-height: 40px;
    font-size: 18px;
    cursor: pointer;
  }
  .options-modal {
    position: absolute;
  }

  .btn.btn-success.create {
    color: #62c3b1;
    background-color: white;
    display: flex;
    .icon-add {
      margin: auto;
      font-size: 20px;
      margin-right: 15px;
    }
    span.create {
      padding-top: 4px;
      font-weight: 700;
    }
  }
  .settings-link {
    color: #62c3b1 !important;
  }
`;

const NeckLabels = props => {
  const [neckTag, setNeckTag] = useState({});
  const [options, setOptions] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  // init fetches existing necklabels from our api. required only on mount.
  useEffect(() => {
    props.init();
  }, []);

  const reset = () => {
    setNeckTag({});
    setOptions(false);
    setModalOpen(false);
  };

  // if neck tag has an id then we update it, otherwise we create a new one.
  const updateNeckTag = image => {
    const newNeckTag = {
      ...neckTag,
      width: image.width,
      height: image.height,
      imgUrl: image.imgUrl,
      name: neckTag.newName
    };

    neckTag.id ? props.updateNeckLabel(newNeckTag) : props.сreateNeckLabel(newNeckTag);
    reset();
  };

  // actions are: create, remove, update.
  const handleItemAction = action =>
    action === 'remove'
      ? (props.removeNeckLabel(neckTag), reset())
      : action === 'create'
      ? (setModalOpen(true), setNeckTag({ newName: '' }))
      : (setModalOpen(true), setNeckTag({ ...neckTag, newName: neckTag.name }));

  const renderItem = (item, first) => (
    <div key={item.get('id')} className="col-xs-12 item-line-row">
      {first ? null : <hr className="medium" />}
      <div className="row">
        <div className="col-md-1 col-sm-2 col-xs-3 image-column">
          <div className="item-block">
            <PreviewImageItem
              imageUrl={item.get('imgUrl')}
              key={`${item.id}`}
              width="40px !important"
              height="40px"
              marginBot="0px"
              backgroundColor="white"
              paddingBottom="0"
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-3">
          <div className="item-block ellipsis" style={{ overflow: 'unset' }}>
            {item.get('name')}
          </div>
        </div>
        <div className="col-xs-2 col-sm-1 pull-right">
          <Button
            iconOnly
            className="button-default medium"
            onClick={() => {
              setOptions(true);
              setNeckTag(item.toJS());
            }}
          >
            <Icon icon="moreVertical" />
          </Button>
          {neckTag.id === item.get('id') && options ? (
            <OptionsModal
              items={['Edit', 'Remove']}
              onClickItem={action => handleItemAction(action)}
              onDismiss={() => setOptions(false)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );

  const getAddingNeckLabelsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047743111-Adding-neck-labels-to-apparel-products';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521346827291-Adding-neck-labels-to-apparel-products';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612611287195-Adding-neck-labels-to-apparel-products';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047743111-Adding-neck-labels-to-apparel-products';
    }
  };

  return (
    <Container>
      {isModalOpen ? (
        <NeckTagUploadModal
          errorStyles={{ marginTop: '-25px' }}
          editName={neckTag.name}
          onCancel={() => reset()}
          neckLabelsList={props.data}
          presetImage={neckTag.imgUrl}
          onSave={image => updateNeckTag(image)}
          customFormUpdated={neckTag.name !== neckTag.newName}
          onChange={(value, id) => setNeckTag({ ...neckTag, newName: value })}
          value={neckTag.newName}
        />
      ) : null}
      <BaseCard card={NeckLabels.name} title="Branded Neck Labels">
        <div className="neck-labels-card">
          <div className="row">
            <div className="col-xs-12">
              <p className="card-description">
                To learn more about creating neck labels and view a list of eligible products&nbsp;
                <a
                  // eslint-disable-next-line
                  href={getAddingNeckLabelsKbLink()}
                  target="_blank"
                  className="settings-link"
                >
                  read the article
                </a>
                .
              </p>
            </div>
          </div>
          {props.data.size > 0 ? (
            <div className="row margin-top-20 margin-bottom-20">
              {props.data.map((item, index) => renderItem(item, index === 0))}
            </div>
          ) : null}
          <br />
          <div className="row">
            <div className="col-xs-12">
              <Button
                className="button-default large add-new-necklabel"
                onClick={() => handleItemAction('create')}
              >
                <Icon icon="plusCircle" />
                <span className="create">Create new </span>
              </Button>
            </div>
          </div>
        </div>
      </BaseCard>
    </Container>
  );
};

NeckLabels.propTypes = {
  data: PropTypes.instanceOf(List),
  init: PropTypes.func.isRequired,
  сreateNeckLabel: PropTypes.func.isRequired,
  updateNeckLabel: PropTypes.func.isRequired,
  removeNeckLabel: PropTypes.func.isRequired
};

// need to switch to using displayName instead of name in settings cards.
Object.defineProperty(NeckLabels, 'name', { value: 'neckLabels' });

NeckLabels.messages = { onInitFailure: 'Failed to load Neck Labels' };

export default NeckLabels;
