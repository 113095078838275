import { connect } from 'react-redux';
import { changeTrackingAsync } from '../../OrdersViewActions';
import ChangeTrackingComponent from './ChangeTrackingComponent';

const mapDispatchToProps = {
  changeTrackingNumber: (itemId, trackingNumber) =>
    changeTrackingAsync({
      trackingNumber,
      ids: [itemId]
    })
};

const mapStateToProps = _ => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTrackingComponent);
