import { RESET } from '../../../../store/actions/globalActions';
import { FETCH_PAYMENT_METHODS, UPDATE_CARD } from './PaymentActions';
import { fromJS } from 'immutable';

// ------------------------------------
// Reducer
// ------------------------------------
const paymentMethodsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS.SUCCESS:
      return action.payload;
    case UPDATE_CARD.SUCCESS:
      return state.set('paymentMethods', action.payload);
    default:
      return state;
  }
};

// make root reducer
export default (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return paymentMethodsReducer(state, action);
};
