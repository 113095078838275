import { connect } from 'react-redux';
import { FETCH_PAYMENT_METHODS } from './PaymentActions';
import { paymentSelector } from './PaymentSelectors';
import { fetchAsync } from '../../../../store/actions/dataActions';
import Payment from './Payment';
import { cardsSelector } from '../../../../../../gooten-hub/src/Settings/Cards/BaseCard/BaseCardSelectors';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = dispatch => {
  return {
    fetchPaymentMethods: () => dispatch(fetchAsync(FETCH_PAYMENT_METHODS))
  };
};

const mapStateToProps = state => {
  const paymentMethods = paymentSelector(state).paymentMethods;
  const cardState = cardsSelector(state);

  return {
    braintreeToken: paymentSelector(state).braintreeToken,
    isOnCredit: paymentMethods && paymentMethods.IsOnCredit,
    paymentMethods: paymentMethods,
    cardData: cardState.get('creditCard')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
