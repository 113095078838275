import StateManager from '../../../state/stateManager';
import { imageUndo } from '../../../state/actions/actionCreators';
import EventEmitter from 'events';
import { PUBLIC_EVENTS } from '../../../ImageEditor.events';

export class UndoButton {
  publicEvents: EventEmitter;
  customClass: string;
  name: string;
  element: any;
  isActive: boolean;
  handler: () => void;

  constructor(stateManager: StateManager, config: any, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;

    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Undo';
    this.stateManager = stateManager;
    this.stateManager.subscribe(state => this.onStateChange(state));
  }

  onStateChange(state) {
    if (!this.element) {
      return;
    }

    const active = !state.getIn(['editor', 'cropActivated']) && state.getIn(['images', 'past']).size > 0
      && state.getIn(['images', 'future']).size < 10;
    if (this.isActive !== active) {
      this.isActive = active;
      this.updateButtonState();
    }
  }

  updateButtonState() {
    this.element.className = ` editor-btn ${this.customClass} ${!this.isActive ? 'disabled' : ''}`;
    if (this.handler) {
      this.element.removeEventListener('click', this.handler);
      this.handler = null;
    }

    if (this.isActive) {
      this.handler = () => this.action();
      this.element.addEventListener('click', this.handler);
    }
  }

  getElement() {
    this.element = document.createElement('div');
    this.element.innerHTML = `<div class="icon-undo toolbar-icon"></div>
      <span class="editor-tooltip-text">${this.name}</span>`;

    this.updateButtonState();
    return this.element;
  }

  action() {
    this.stateManager.dispatchAction(imageUndo());
    this.publicEvents.emit(PUBLIC_EVENTS.UNDO);
  }
}
