import { createSelector } from 'reselect';
import { Record, List } from 'immutable';

const productsSelector = state => state.getIn(['hub', 'hubView', 'products']);
const placeOrderSelector = state => state.getIn(['hub', 'placeOrder']);

export const cartLoadingStatusSelector = state =>
  state.getIn(['customOrderForm', 'cartView', 'loadingStatus']);

export const cartSKUItemsSelector = state =>
  state.getIn(['data', 'cart', 'items']).map(item => item.getIn(['sku', 'prpSKU']));

export const selectedProductsSelector = createSelector([productsSelector], products =>
  products.get('selected')
);

export const isReadySelector = createSelector([placeOrderSelector], placeOrder =>
  placeOrder.get('isReady')
);

export const selectedVariantIDsSelector = createSelector([placeOrderSelector], placeOrder =>
  placeOrder.get('selectedVariantIDs')
);

export const isSampleOrderSelector = createSelector([placeOrderSelector], placeOrder =>
  placeOrder.get('setOrderAsSample')
);

export const selectedVariantsSelector = createSelector(
  [selectedVariantIDsSelector, selectedProductsSelector, isSampleOrderSelector],
  (vIDs, selectedProducts, isSample) => {
    const allVariants = selectedProducts.reduce((a, c) => a.concat(c.get('variants')), new List());
    return vIDs.map(vID => allVariants.find(e => e.get('id') === vID).set('isSample', isSample));
  }
);
