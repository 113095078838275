// @flow

import { Image, Group, Filters } from 'konva';
import type {KonvaLayer} from '../../flow/konvaTypes';
import { hexToRgb } from '../../utils/math';

export default class CanvasMaskElement {

  id: string;
  element: Image;
  image: HTMLCanvasElement;
  x: number;
  y: number;
  zindex: number;
  draggable: boolean;
  layer: any;
  clip: any;
  offsetX: number;
  offsetY: number;
  rotation: number;
  scale: number;
  width: number;
  height: number;
  sourceWidth: number;
  sourceHeight: number;
  crop: any;
  sourceImageCrop: any;
  scaleFromOriginal: number;

  constructor(state: Object) {
    this.id = state.id;
    this.x = state.x;
    this.y = state.y;
    this.zindex = state.z;
    this.draggable = state.draggable;
    this.image = state.image;
    this.scale = 1;//state.scale || 1;
    this.width = state.width;
    this.height = state.height;
    this.offsetX = state.width / 2;
    this.offsetY = state.height / 2;

    this.rotation = state.rotation || 0;
    this.clip = state.clip || null;
    this.crop = state.crop;
    this.sourceImageCrop = state.sourceImageCrop;
    this.scaleFromOriginal = state.scaleFromOriginal;
    this.sourceWidth = state.sourceWidth;
    this.sourceHeight = state.sourceHeight;

    this.backgroundColor = state.backgroundColor;
  }

  update(args: any) {
    this._draw(args);
    this._updateState();

  }

  _updateState() {
    // just update state from element
    this.x = this.element.x();
    this.y = this.element.y();
    this.width = this.element.width();
    this.height = this.element.height();
    this.offsetX = this.element.offsetX();
    this.offsetY = this.element.offsetY();
    this.rotation = this.element.rotation();
  }

  _draw(args: any) {
    this.element.clearCache();

    // check if x, y are inside args
    if ('x' in args && 'y' in args) {
      this.element.x(args.x);
      this.element.y(args.y);
    }

    if ('width' in args && 'height' in args) {
      this.element.width(args.width);
      this.element.height(args.height);
    }

    if ('offsetX' in args && 'offsetY' in args) {
      this.element.offsetX(args.offsetX);
      this.element.offsetY(args.offsetY);
    }

    if ('rotation' in args) {
      this.element.rotation(args.rotation);
    }

    if ('backgroundColor' in args) {
      this.backgroundColor = args.backgroundColor;
      this._updateFilters();
    }
  }

  _updateFilters() {
    if (this.backgroundColor) {
      this.element.cache();
      let rgb = hexToRgb(this.backgroundColor);
      this.element.red(rgb.r);
      this.element.green(rgb.g);
      this.element.blue(rgb.b);
    }
  }

  addToCanvas(layer: KonvaLayer): void {
    let imageObj = this.getImage();

    this.element = imageObj;

    this._updateFilters();

    this.layer = layer;

    if (this.clip) {
      let group = new Group({
        x: 0,
        y: 0,
        width: layer.getWidth(),
        height: layer.getHeight(),
        clipX: this.clip.x,
        clipY: this.clip.y,
        clipWidth: this.clip.width,
        clipHeight: this.clip.height
      });
      group.add(imageObj);
      layer.add(group);
    }
    else {
      layer.add(imageObj);
    }
  }

  getImage() {
    let image = new Image({
      image: this.image,
      x: this.x + this.offsetX,
      y: this.y + this.offsetY,
      //TODO need refactor offset and scale to prevent conflicts
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      //Using prepared width/height instead scale to prevent conflicts with offset
      //but it's better calculate it here from image original sizes
      width: this.width,
      height: this.height,
      draggable: this.draggable,
      rotation: this.rotation,
      crop: this.sourceImageCrop
    });
    image.filters([Filters.RGB]);
    return image;
  }

}
