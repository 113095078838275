import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List, Map } from 'immutable';
import ErrorMessage from '../ErrorMessage';
import Icon from '../../../../shared/Icon';
import Button from '../../../../shared/Button';
import Select from '../../../../shared/Select';

const Container = styled.div`
  flex-direction: column;

  div.publish-error-message {
    color: #717176;
    margin-top: -8px;
  }

  .add-more-btn-container {
    &.single-item {
      margin-top: 20px;
    }
  }
`;

const ProductOptions = props => {
  const [error, setError] = useState(null);

  // local error messages
  const errors = {
    maxNumberOfOptions: `${
      props.selectedProvider.get('provider').charAt(0).toUpperCase() +
      props.selectedProvider.get('provider').slice(1)
    } has a limit of ${props.maxOptions} product options`
  };

  useEffect(() => {
    let err =
      props.selectedOptions.size >= (props.maxOptions || 999) ? errors.maxNumberOfOptions : null;

    setError(err);
  }, [props.selectedOptions.size]);

  const disabled = props.linkMode || (props.editMode && props.selectedProvider.get('wasConnected'));
  const showAddMoreBtn =
    !disabled &&
    props.selectedOptions.size < 3 &&
    props.selectedOptions.size < props.options.size &&
    !error;

  const createOption = option => (
    <option key={'' + option.get('id') + option.get('id')} value={option.get('id')}>
      {option.get('title')}
    </option>
  );

  const options = props.selectedOptions
    .slice(0, props.maxOptions || props.selectedOptions.size)
    .map(o => (
      <div className="flex-container" key={o.get('id')}>
        <Select
          label="Product Options"
          disabled={disabled}
          autoComplete="false"
          value={o.get('value')}
          placeholder="Select Option"
          onChange={e => props.changeProductOption(props.storeId, o.get('id'), e.target.value)}
        >
          {props.options.map(createOption)}
        </Select>
        {!disabled && props.selectedOptions.size > 1 ? (
          <Button
            className="button-default extra-large"
            iconOnly
            onClick={() => props.removeProductOption(props.storeId, o.get('id'))}
          >
            <Icon icon="xCircle" />
          </Button>
        ) : null}
      </div>
    ));

  return (
    <Container className="pb-4 product-publish-options flex-container">
      {options}
      {error ? <ErrorMessage message={error} /> : null}
      {showAddMoreBtn ? (
        <div className={'add-more-btn-container ' + (options.length === 1 && 'single-item')}>
          <Button
            className="button-default medium"
            onClick={() => props.addProductOption(props.storeId)}
          >
            <Icon icon="plusCircle" /> Add more options
          </Button>
        </div>
      ) : null}
    </Container>
  );
};

ProductOptions.propTypes = {
  options: PropTypes.instanceOf(List).isRequired,
  selectedOptions: PropTypes.instanceOf(List).isRequired,
  selectedProvider: PropTypes.instanceOf(Map).isRequired,
  changeProductOption: PropTypes.func.isRequired,
  removeProductOption: PropTypes.func.isRequired,
  addProductOption: PropTypes.func.isRequired,
  storeId: PropTypes.number,
  linkMode: PropTypes.bool,
  editMode: PropTypes.bool,
  maxOptions: PropTypes.number
};

export default ProductOptions;
