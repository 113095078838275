import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import RadioField from 'gooten-components/src/components/shared/RadioField';
import { pendingTimePatern } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Select from 'gooten-components/src/components/shared/Select';
import Loader from 'gooten-components/src/components/shared/Loader';
import Modal from 'gooten-components/src/components/shared/Modal';
import Config from 'gooten-components/src/config';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import { loadInitialData } from './ReturnAddressActions';

const ReturnAddress = props => {
  const {
    loading,
    saving,
    billingAddress,
    loadInitialData,
    currentSelection,
    setSelection,
    partnerDetails,
    getCountries,
    getAddressTypes,
    getPartnerDetails,
    saveReturnAddress,
    useExistingReturnAddress,
    countries,
    addressTypes,
    customAddressId
  } = props;

  useEffect(() => {
    loadInitialData();
  }, []);

  const [suggestedAddressOption, setSuggestedAddressOption] = useState(0);

  const isSameAddress = (address, suggestedAddress) => {
    return ['AddressTypeId', 'City', 'CountryCode', 'Line1', 'PostalCode', 'State'].every(
      key => address[key] === suggestedAddress[key]
    );
  };

  const getReturnAddressKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047292072#h_01EWXB2059MJH9E2G9QCA88FGH';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521581015835-Returns#h_01EWXB2059MJH9E2G9QCA88FGH';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612945497755-Returns#h_01EWXB2059MJH9E2G9QCA88FGH';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047292072#h_01EWXB2059MJH9E2G9QCA88FGH';
    }
  };

  return (
    <>
      {loading ? (
        <Loader inline />
      ) : (
        <div className="panel">
          {saving ? <Loader /> : null}
          <div className="panel-body p-3 pt-4 pb-4">
            <div className="title-2 ml-3 mr-3">Return Address</div>
            <br />
            <div className="col-xs-12">
              <div className="card-description">
                Set the location packages are returned to. In rare situations packages cannot be
                returned.&nbsp;
                <a href={getReturnAddressKbLink()} target="_blank">
                  Learn more
                </a>
              </div>
              <RadioField
                name="billingAddress"
                label={billingAddress ? 'Billing Address' : 'Business Address'}
                secondaryText={billingAddress}
                key="billingAddress"
                disabled={saving}
                checked={currentSelection !== 2}
                onClick={() => (currentSelection === 2 ? setSelection(1) : null)}
              />
              <RadioField
                name="customAddress"
                label={'Custom Address'}
                secondaryText="Manually enter a custom return address"
                key="customAddress"
                disabled={saving}
                checked={currentSelection === 2}
                onClick={() => setSelection(2)}
              />
            </div>
            {currentSelection === 2 && (
              <div className="col-xs-12 custom-address">
                <Formik
                  enableReinitialize
                  initialValues={{
                    firstName: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.FirstName || ''
                      : '',
                    lastName: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.LastName || ''
                      : '',
                    address1: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.Line1 || ''
                      : '',
                    address2: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.Line2 || ''
                      : '',
                    city: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.City || ''
                      : '',
                    stateRegion: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.State || ''
                      : '',
                    zipPostalCode: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.PostalCode || ''
                      : '',
                    country: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.CountryCode || 'US'
                      : 'US',
                    phone: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.Phone || ''
                      : '',
                    email: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.Email || ''
                      : '',
                    addressType: partnerDetails.ReturnAddress
                      ? partnerDetails.ReturnAddress.AddressTypeId || 1
                      : 1
                  }}
                  validationSchema={() =>
                    object().shape({
                      firstName: string()
                        .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid First Name') // name
                        .matches(/[\x00-\x7F]+/, 'Enter a valid First Name') // onlyEnglish
                        .required('Enter a valid First Name'), // required
                      lastName: string()
                        .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid Last Name') // name
                        .matches(/[\x00-\x7F]+/, 'Enter a valid Last Name') // onlyEnglish
                        .required('Enter a valid Last Name'), // required
                      address1: string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
                        .matches(/[\x00-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
                        .required('Enter a valid Address 1'), // required
                      address2: string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                        .matches(/[\x00-\x7F]+/, 'Enter a valid Address 2'), // onlyEnglish
                      city: string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
                        .matches(/[\x00-\x7F]+/, 'Enter a valid City') // onlyEnglish
                        .required('Enter a valid City'), // required
                      stateRegion: string()
                        .when('country', {
                          is: country => {
                            if (country) {
                              return ['CA', 'US'].includes(country);
                            }
                            return false;
                          },
                          then: string().required('Enter a valid State/Region')
                        }) // required if country is CA or US
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Region') // shippingRequiredUSandCA
                        .matches(/[\x00-\x7F]+/, 'Enter a valid State/Region'), // onlyEnglish
                      zipPostalCode: string().when('country', {
                        is: country => country === 'US',
                        then: string()
                          .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                          .required('Enter a valid zip code'),
                        otherwise: string().when('country', {
                          is: country => country === 'CA',
                          then: string()
                            .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                            .required('Enter a valid postal code'),
                          otherwise: string().matches(
                            /^[a-zA-Z\d- ]*$/,
                            'Enter a valid postal code'
                          ) // postalcode
                        })
                      }),
                      country: string().required('Enter a Country'), // required
                      phone: string()
                        .matches(
                          /^[+\-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                          'Enter a valid Phone Number'
                        )
                        .matches(/^.{0,15}$/, 'Please limit to 15 characters')
                        .required('Enter a valid Phone Number'),
                      email: string()
                        .matches(
                          // eslint-disable-next-line max-len
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          'Enter a valid Email'
                        ) // email
                        .required('Enter a valid Email'),
                      addressType: number().required('Enter an Address Type')
                    })
                  }
                  onSubmit={(values, { setSubmitting }) => {
                    const data = {
                      AddressTypeId: values.addressType,
                      City: values.city,
                      CountryCode: values.country,
                      Email: values.email,
                      FirstName: values.firstName,
                      LastName: values.lastName,
                      Line1: values.address1,
                      Line2: values.address2,
                      Phone: values.phone,
                      PostalCode: values.zipPostalCode,
                      State: values.stateRegion
                    };
                    saveReturnAddress(data);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    submitCount,
                    dirty
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="firstLastName d-flex">
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          className="mr-3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          disabled={saving}
                          hasError={submitCount > 0 && !!errors.firstName}
                          errorText={
                            submitCount > 0 && !!errors.firstName ? errors.firstName : null
                          }
                        />
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          disabled={saving}
                          hasError={submitCount > 0 && !!errors.lastName}
                          errorText={submitCount > 0 && !!errors.lastName ? errors.lastName : null}
                        />
                      </div>
                      <Input
                        name="address1"
                        type="text"
                        placeholder="Address 1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address1}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.address1}
                        errorText={submitCount > 0 && !!errors.address1 ? errors.address1 : null}
                      />
                      <Input
                        name="address2"
                        type="text"
                        placeholder="Address 2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address2}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.address2}
                        errorText={submitCount > 0 && !!errors.address2 ? errors.address2 : null}
                      />
                      <Input
                        name="city"
                        type="text"
                        placeholder="City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.city}
                        errorText={submitCount > 0 && !!errors.city ? errors.city : null}
                      />
                      <Input
                        name="stateRegion"
                        type="text"
                        placeholder="State/Region"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.stateRegion}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.stateRegion}
                        errorText={
                          submitCount > 0 && !!errors.stateRegion ? errors.stateRegion : null
                        }
                      />
                      <Input
                        name="zipPostalCode"
                        type="text"
                        placeholder="Zip/Postal Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipPostalCode}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.zipPostalCode}
                        errorText={
                          submitCount > 0 && !!errors.zipPostalCode ? errors.zipPostalCode : null
                        }
                      />
                      <Select
                        name="country"
                        label={'Country'}
                        disabled={saving}
                        onChange={handleChange}
                        value={values.country}
                        hasError={submitCount > 0 && !!errors.country}
                        errorText={submitCount > 0 && !!errors.country ? errors.country : null}
                      >
                        {countries
                          .filter(country => !['Cuba', 'Iran', 'Syria'].includes(country.Name))
                          .map(country => (
                            <option key={country.Id} value={country.CountryCode}>
                              {country.Name}
                            </option>
                          ))}
                      </Select>
                      <Input
                        name="phone"
                        type="text"
                        placeholder="Phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.phone}
                        errorText={submitCount > 0 && !!errors.phone ? errors.phone : null}
                      />
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={saving}
                        hasError={submitCount > 0 && !!errors.email}
                        errorText={submitCount > 0 && !!errors.email ? errors.email : null}
                      />
                      <Select
                        label={'Address Type'}
                        disabled={saving}
                        onChange={handleChange}
                        name="addressType"
                        value={values.addressType}
                        hasError={submitCount > 0 && !!errors.addressType}
                        errorText={
                          submitCount > 0 && !!errors.addressType ? errors.addressType : null
                        }
                      >
                        {addressTypes.map(addressType => (
                          <option key={addressType.Id} value={addressType.Id}>
                            {addressType.Name}
                          </option>
                        ))}
                      </Select>
                      {dirty && (
                        <div className="d-flex justify-content-end">
                          {!!customAddressId && (
                            <Button
                              className="mr-2 button-default large cancel-button"
                              disabled={saving}
                              onClick={() => resetForm()}
                            >
                              Cancel
                            </Button>
                          )}
                          <Button
                            className="button-primary large submit-button"
                            disabled={saving}
                            onClick={() => (saving ? null : handleSubmit())}
                          >
                            Save Address
                          </Button>
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </div>
          {partnerDetails.SuggestedAddress &&
            partnerDetails.SuggestedAddress.AddressTypeId &&
            !isSameAddress(partnerDetails.ReturnAddress, partnerDetails.SuggestedAddress) &&
            !saving && (
              <Modal
                isOpen={true}
                title={'Verify Address'}
                className="small"
                primaryClick={() => {
                  if (suggestedAddressOption === 0) {
                    const data = {
                      AddressTypeId: partnerDetails.SuggestedAddress.AddressTypeId,
                      City: partnerDetails.SuggestedAddress.City,
                      CountryCode: partnerDetails.SuggestedAddress.CountryCode,
                      Email: partnerDetails.SuggestedAddress.Email,
                      FirstName: partnerDetails.SuggestedAddress.FirstName,
                      LastName: partnerDetails.SuggestedAddress.LastName,
                      Line1: partnerDetails.SuggestedAddress.Line1,
                      Line2: partnerDetails.SuggestedAddress.Line2,
                      Phone: partnerDetails.SuggestedAddress.Phone,
                      PostalCode: partnerDetails.SuggestedAddress.PostalCode,
                      State: partnerDetails.SuggestedAddress.State
                    };
                    saveReturnAddress(data);
                  } else {
                    useExistingReturnAddress();
                  }
                  setSuggestedAddressOption(0);
                }}
                actionText="Save"
                hideCloseButton
              >
                <div className="p-4">
                  <RadioField
                    name="suggestedAddress"
                    label={'Suggested Address'}
                    secondaryText={
                      <span className="address">
                        <span>
                          {partnerDetails.SuggestedAddress.FirstName}{' '}
                          {partnerDetails.SuggestedAddress.LastName}
                        </span>
                        <span>{partnerDetails.SuggestedAddress.Line1}</span>
                        <span>{partnerDetails.SuggestedAddress.Line2}</span>
                        <span>
                          {partnerDetails.SuggestedAddress.City}{' '}
                          {partnerDetails.SuggestedAddress.State}{' '}
                          {partnerDetails.SuggestedAddress.CountryCode}{' '}
                          {partnerDetails.SuggestedAddress.PostalCode}
                        </span>
                      </span>
                    }
                    key="suggestedAddress"
                    checked={suggestedAddressOption === 0}
                    onClick={() => setSuggestedAddressOption(0)}
                  />
                  <RadioField
                    name="enteredAddress"
                    label={'Entered Address'}
                    secondaryText={
                      <span className="address">
                        <span>
                          {partnerDetails.ReturnAddress.FirstName}{' '}
                          {partnerDetails.ReturnAddress.LastName}
                        </span>
                        <span>{partnerDetails.ReturnAddress.Line1}</span>
                        <span>{partnerDetails.ReturnAddress.Line2}</span>
                        <span>
                          {partnerDetails.ReturnAddress.City} {partnerDetails.ReturnAddress.State}{' '}
                          {partnerDetails.ReturnAddress.CountryCode}{' '}
                          {partnerDetails.ReturnAddress.PostalCode}
                        </span>
                      </span>
                    }
                    key="enteredAddress"
                    checked={suggestedAddressOption === 1}
                    onClick={() => setSuggestedAddressOption(1)}
                  />
                </div>
              </Modal>
            )}
        </div>
      )}
      <style jsx>
        {`
          .panel .panel-body {
            border-radius: 4px;
            border: 1px solid #dedfe1;
          }

          .text-input-container.form-group {
            min-width: 226px;
          }

          .card-description {
            margin-bottom: 10px;
          }

          .custom-address {
            padding: 15px 15px 0 45px !important;
          }

          .inline-link {
            cursor: pointer;
          }

          :global(.loader-box) {
            height: 0 !important;
          }

          .address {
            position: relative;
          }

          .address span {
            display: block;
          }
        `}
      </style>
    </>
  );
};

export default ReturnAddress;
