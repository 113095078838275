import { createSelector } from 'reselect';

const providersStateSelector = state => state.getIn(['hub', 'hubView', 'providers']);

export const availableProvidersSelector = createSelector([providersStateSelector], providersState =>
  providersState.get('items')
);

export const selectedProviderSelector = createSelector([providersStateSelector], providersState =>
  providersState.get('selected')
);
