import { connect } from 'react-redux';
import PasswordByEmail from './PasswordByEmail';
import Password from '../Password/Password';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { sendEmail } from '../Password/PasswordActions';

const passwordCardSelector = cardSelectorFor(Password.name);

const mapDispatchToProps = {
  sendEmail
};

const mapStateToProps = (state, ownProps) => {
  return {
    ready: !passwordCardSelector(state).get('busy'),
    users: state.getIn(['settings', 'cards', 'yourTeam', 'data'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordByEmail);
