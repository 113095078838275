import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Toolbar from '../shared/Toolbar';
import ImagesGrid from '../shared/ImagesGrid';
import imagesService from '../../services/imageSelectionService';
import Loader from '../../../Loader';
import PrintSpaces from '../shared/PrintSpaces';
import PrintSpacesMobile from '../shared/PrintSpaces/PrintSpacesMobile';
import { windowWidth } from '../../../ComponentsHooks';
import { imagesGridHeight } from '../shared/ImagesGrid/ImagesGrid';
import './MyArtwork.scss';
import Button from '../../../Button';
import Dialogue from '../../../Dialogue';
import Modal from '../../../Modal';
import EmbroideryImageRequirements from './atoms/EmbroideryImageRequirements';
import EmbroideryNoArtwork from './atoms/EmbroideryNoArtwork';
import { whiteSpaces } from '../../../../../utils/regex';

const Container = styled.div`
  .loader-container {
    height: ${props => props.loaderHeight}px;

    .loader-box {
      top: 38%;
    }
  }

  .images-content-holder {
    width: ${props => (props.showPrintSpaces && !props.isMobile ? '724px' : '100%')};

    .emb-image-requirements {
      max-width: none;
      width: auto;
      margin: 16px 20px 16px 20px !important;
    }

    .images-grid {
      margin-right: 0px;
      margin-left: 0px;
    }

    .image-selection-toolbar {
      margin-right: 20px;
    }

    .upload-artwork-bottom {
      margin: 16px 16px 0 16px;

      button.btn {
        width: 100%;
      }
    }
  }

  .images-grid .infinite-scroll-component {
    overflow: auto;
  }

  .image-selection-bottom-bar {
    width: ${props =>
      !props.isMobile ? (props.showPrintSpaces ? '740px' : '898px') : 'calc(100% - 32px)'};
  }
`;

const MyArtwork = props => {
  const [hasMore, setHasMore] = useState(false);
  const width = windowWidth();
  const isMobile = width <= 991;
  const [showSpacesMobileModal, setShowSpacesMobileModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(0);
  const [imageToDelete, setImageToDelete] = useState(null);
  const loaderHeight = imagesGridHeight(width <= 480, props.isMultiSpace);

  useEffect(() => {
    if (!props.loaded) {
      setPage(0);
      props.loadImages(0);
    }
  }, [props.filter]);

  useEffect(() => {
    const imagesCount = props.images.size;

    setPage(Math.ceil(imagesCount / imagesService.pageSize));
    setHasMore(!!imagesCount && imagesCount % imagesService.pageSize === 0);
  }, [props.images]);

  const onSelectOptionItem = (optionItem, isFilter) => {
    if (isFilter) {
      setPage(0);
      props.setFilter(optionItem);
    } else {
      props.setSort(optionItem);
    }
  };

  const handleSelectImage = image => {
    if (!props.isMultiSpace) {
      let images = [];
      props.selectedSKUSpacePairs.forEach(skuSpace => {
        images.push({
          variantIndex: skuSpace.variantIndex,
          spaceIndex: skuSpace.spaceIndex,
          imageUrl: image.url,
          imageId: image.id,
          width: image.width,
          height: image.height,
          bulk: props.isBulkMode
        });
      });
      props.imageApply(images);
      props.modalClose();
    } else {
      props.selectImage(image);
    }
  };

  const handleApplyMultiSpaceImages = () => {
    let images = [];

    props.selectedSKUSpacePairs.forEach(skuSpace => {
      const image = props.spaces
        .find(s => s.get('name')?.replace(whiteSpaces, '')?.toLowerCase() === skuSpace.spaceName)
        ?.get('image');

      if (image) {
        images.push({
          variantIndex: skuSpace.variantIndex,
          spaceIndex: skuSpace.spaceIndex,
          imageUrl: image.get('url'),
          imageId: image.get('id'),
          width: image.get('width'),
          height: image.get('height'),
          bulk: props.isBulkMode
        });
      }
    });
    props.imageApply(images);
    props.modalClose();
  };

  const handleSelectSpace = space => {
    props.selectSpace(space);
  };

  const handleDelete = image => {
    setShowConfirmationModal(true);
    setImageToDelete(image);
  };

  const removeImageConfirmation = () => {
    props.removeImage(imageToDelete);
    setImageToDelete(null);
    setShowConfirmationModal(false);
  };

  const imagesGrid = () => (
    <ImagesGrid
      isMultiSpace={props.isMultiSpace}
      isEmbroidery={props.isEmbroidery}
      images={props.images}
      loadingImagesCount={props.loadingImagesCount}
      hasMore={hasMore}
      spaces={props.spaces}
      onLoadMore={() => props.loadImages(page)}
      threeColumns={props.isMultiSpace}
      handleSelect={handleSelectImage}
      handleDelete={handleDelete}
      requiredImageSizeData={props.requiredImageSizeData}
    />
  );

  return (
    <Modal
      cancelClick={props.modalClose}
      cancelText={props.isEmbroidery ? null : 'Done'}
      isOpen={props.isOpen}
      primaryClick={
        props.isMultiSpace ? (props.anySpaceReady ? handleApplyMultiSpaceImages : null) : null
      }
      actionText={props.isEmbroidery ? null : 'Apply Changes'}
      title="My Artwork"
      disableOutsideClose
    >
      <Container
        className="image-selection-my-artwork"
        showPrintSpaces={props.isMultiSpace}
        loaderHeight={loaderHeight}
        isSmallMobile={width <= 480}
        isMobile={isMobile}
      >
        <div className="flex-container" style={{ position: 'relative', overflow: 'hidden' }}>
          <div className="images-content-holder">
            <Toolbar
              onSelectOptionItem={onSelectOptionItem}
              showSpaceSelection={props.isMultiSpace}
              onShowSpaces={() => setShowSpacesMobileModal(!showSpacesMobileModal)}
              onUploadNewArtwork={props.onUploadNewArtwork}
              hideFilter={props.isEmbroidery}
              hideSort={props.isEmbroidery}
              showAdditionalFilesUpload={props.isEmbroidery}
              onUploadAdditionalFiles={props.onUploadAdditionalFiles}
            />
            {props.showLoading && page === 0 ? (
              <Loader />
            ) : props.isEmbroidery ? (
              !props.images.size ? (
                <React.Fragment>
                  <EmbroideryImageRequirements
                    requiredImageSizeData={props.requiredImageSizeData}
                  />
                  <EmbroideryNoArtwork onClick={() => props.onUploadNewArtwork()} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <EmbroideryImageRequirements
                    requiredImageSizeData={props.requiredImageSizeData}
                  />
                  {imagesGrid()}
                </React.Fragment>
              )
            ) : (
              imagesGrid()
            )}
            <div className="upload-artwork-bottom visible-xs mb-3">
              <Button
                className="button-primary large full-width"
                onClick={() => props.onUploadNewArtwork()}
              >
                Upload new
              </Button>
            </div>
          </div>
          {props.isMultiSpace ? (
            isMobile ? (
              showSpacesMobileModal ? (
                <PrintSpacesMobile
                  spaces={props.spaces}
                  handleSelect={handleSelectSpace}
                  onClose={() => setShowSpacesMobileModal(false)}
                />
              ) : null
            ) : (
              <PrintSpaces spaces={props.spaces} handleSelect={handleSelectSpace} />
            )
          ) : null}
        </div>
      </Container>
      {props.isMultiSpace && isMobile && showSpacesMobileModal ? (
        <div className="print-spaces-mobile-background" />
      ) : null}
      <Dialogue
        isOpen={showConfirmationModal}
        cancelText="CANCEL"
        actionText="DELETE"
        cancelClick={() => setShowConfirmationModal(false)}
        destructiveClick={() => removeImageConfirmation()}
        title="Are you sure you want to delete this image?"
      >
        <div>This action is irreversible.</div>
      </Dialogue>
    </Modal>
  );
};

MyArtwork.propTypes = {
  modalClose: PropTypes.func.isRequired,
  onUploadNewArtwork: PropTypes.func,
  loadImages: PropTypes.func.isRequired,
  images: PropTypes.instanceOf(List).isRequired,
  // eslint-disable-next-line
  loadingImagesCount: PropTypes.number.isRequired,
  showLoading: PropTypes.bool.isRequired,
  setFilter: PropTypes.func,
  setSort: PropTypes.func,
  filter: PropTypes.string.isRequired,
  imageApply: PropTypes.func.isRequired,
  isMultiSpace: PropTypes.bool.isRequired,
  selectedSKUSpacePairs: PropTypes.instanceOf(List).isRequired,
  spaces: PropTypes.instanceOf(List).isRequired,
  selectSpace: PropTypes.func.isRequired,
  selectImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  anySpaceReady: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  isBulkMode: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isEmbroidery: PropTypes.bool,
  onUploadAdditionalFiles: PropTypes.func,
  requiredImageSizeData: PropTypes.array
};

export default MyArtwork;
