export default class Strings {
  static CART = `Cart`;
  static CLEAR_CART = `Clear cart`;
  static UPDATE_SHIPPING_COUNTRY = `Update shipping country`;
  static ITEM = `item`;
  static ITEMS = `items`;
  static ADD_MORE_PRODUCTS_TO_CART = `Add more products to cart`;
  static ADD_PRODUCTS_TO_CART = `Add products to cart`;
  static CLOSE = `Okay`;
  static PROCEED_TO_CHECKOUT = `Proceed to checkout`;
}
