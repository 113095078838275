import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import ProductPublish from './ProductPublish';
import withRouteStep from '../../hocs/withRouteStep';
import { fetchAsync, PUBLISH_PREPARE, PUBLISH } from '../../store/actions/dataActions';
import {
  selectedProviderSelector,
  providersSelector
} from './atoms/ProvidersTab/ProvidersTabSelectors';
import {
  publishDataReadySelector,
  publishingStateSelector,
  multiproductSelector,
  warningDialogSelector
} from './ProductPublishSelectors';
import { selectedSKUsSelector } from '../../store/selectors/productDataSelectors';
import { configure, componentMounted, setWarningDialog } from './ProductPublishActions';
import publishService from './services/publishService';
import { isDisabledSelector } from './atoms/ProvidersSelectionPanel/ProvidersSelectionPanelSelectors';

const mapDispatchToProps = dispatch => {
  return {
    configure: config => dispatch(configure(publishService.mergeConfigs(fromJS({}), config))),
    prepareData: () => dispatch(fetchAsync(PUBLISH_PREPARE)),
    save: () => dispatch(fetchAsync(PUBLISH)),
    componentMounted: () => dispatch(componentMounted()),
    closeWarningDialog: () => dispatch(setWarningDialog({ show: false }))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedProvider: selectedProviderSelector(state),
    isDataReady: publishDataReadySelector(state),
    publishingState: publishingStateSelector(state),
    anyProviderAvailable: !!providersSelector(state).size,
    isProvidersTabHidden: isDisabledSelector(state),
    sortedSelectedSkus: selectedSKUsSelector(state),
    isMultiproduct: multiproductSelector(state),
    warningDialog: warningDialogSelector(state)
  };
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(ProductPublish),
  'publish'
);
