import { connect } from 'react-redux';
import TaxExemption from './TaxExemption';
import { initFor } from '../BaseCard/BaseCardActions';

import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';

const init = initFor(TaxExemption.name);

const cardSelector = cardSelectorFor(TaxExemption.name);

const mapDispatchToProps = {
  init
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    ready: !cardState.get('loading')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxExemption);
