import React from 'react';
import PropTypes from 'prop-types';
import { PartnerTierBadge } from './TierBadge';

export const PartnerTierLabel = ({ id, children }) => {
  return (
    <>
      <div className="text-capitalize tier-label caption-text">
        <span>
          <PartnerTierBadge id={id} width={13} height={13} />
        </span>{' '}
        <b>{id}</b>
        <div className="children">{children}</div>
      </div>
      <style jsx>
        {`
          .tier-label {
            text-align: center;
          }
          .tier-label:first-child {
            text-align: left;
          }
          .tier-label:last-child {
            text-align: right;
          }
          .tier-label {
            font-size: 13px;
            letter-spacing: 0.15px;
            color: #646b72 !important;
            letter-spacing: 0.5pt;
          }
          .tier-label b {
            color: #1e2731;
          }
        `}
      </style>
    </>
  );
};

PartnerTierLabel.propTypes = {
  id: PropTypes.string.isRequired
};
