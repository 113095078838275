import { connect } from 'react-redux';
import Password from './Password';
import { updateFor, changeFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { sendEmail } from './PasswordActions';

const update = updateFor(Password.name);
const change = changeFor(Password.name);
const cardSelector = cardSelectorFor(Password.name);

const mapDispatchToProps = {
  sendEmail,
  update,
  change
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    isPristine: cardState.get('isPristine'),
    validationError: cardState.get('validationError'),
    ready: !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
