import { connect } from 'react-redux';
import Backdrop from './Backdrop';
import { globalCloseSidebar, hideBackdrop } from '../../../store/actions/globalActions';
/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = {
  closeSidebar: () => globalCloseSidebar(),
  hideBackdrop: () => hideBackdrop()
};

const mapStateToProps = state => {
  return {
    isActive: !!state.get('globalUI') && !!state.get('globalUI').get('backdrop')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Backdrop);
