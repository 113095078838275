import { Record, List, Map } from 'immutable';
import { mapProductPreconfiguration } from './ProductPreconfiguration';

// Hold data for 1 store product variant
const ProductVariant = new Record({
  id: 0,
  productName: '',
  sku: '',
  imageUrl: '',
  price: null,
  options: null,
  // used only in COF
  productInfo: '',
  gootenMapping: null,
  IsReOrderable: null,
  isSample: false
});

export default ProductVariant;

const mapGootenMapping = obj => {
  if (obj.gooten_mapping) {
    return new Map(
      Object.assign(obj.id ? { id: obj.gooten_mapping.id, sku: obj.gooten_mapping.sku } : {}, {
        items: new List(
          obj.gooten_mapping.items && obj.gooten_mapping.items.map(mapProductPreconfiguration)
        ),
        images: new List(
          obj.gooten_mapping.images && obj.gooten_mapping.images.map(i => new Map(i))
        ),
        neck_tag_id: obj.gooten_mapping.neck_tag_id,
        neck_tag_image_url: obj.gooten_mapping.neck_tag_image_url,
        orientation: obj.gooten_mapping.orientation,
        hasMainMockup: obj.gooten_mapping.has_main_mockup
      })
    );
  } else if (obj.Preconfiguration) {
    return new Map({
      sku: obj.Preconfiguration.Sku,
      items: new List(obj.Preconfiguration.Items.map(mapProductPreconfiguration)),
      images: new List(obj.Preconfiguration.Images.map(i => new Map(i))),
      neck_tag_id: obj.Preconfiguration.NeckTagId,
      neck_tag_image_url: obj.Preconfiguration.NeckTagImageUrl,
      orientation: obj.Preconfiguration.Orientation
    });
  }
};

const mapOptionValues = obj => {
  if (obj.Options) {
    return new List(obj.Options.map(o => new Map(o)));
  } else if (obj.options) {
    return new List(obj.options.map(o => new Map(o)));
  } else {
    return null;
  }
};

export const mapProductVariant = obj =>
  new ProductVariant({
    id: obj.id || obj.Id,
    IsReOrderable: obj.IsReOrderable,
    isSample: obj.isSample,
    productName: obj.product_name || obj.ProductName,
    sku: obj.sku || obj.Sku,
    imageUrl: obj.image_url || obj.PreviewUrl,
    price: obj.price ? new Map(obj.price) : null,
    options: mapOptionValues(obj),
    productInfo: '', // used only in COF
    // Gooten Mapping exists for both store and storage product variants
    // for store product variant gootenMapping contains id and sku of prp
    // for storage product variant doesn't, b/c id and sku is same as variant id and sku
    // other data in gootenMapping similar
    gootenMapping: mapGootenMapping(obj)
  });
