import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import Chip from 'gooten-components/src/components/shared/Chip';
import Input from 'gooten-components/src/components/shared/Input';
import Button from 'gooten-components/src/components/shared/Button';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import ProductCard from 'gooten-components/src/components/shared/ProductCard';
import { getOrderDetailsImageResizerUrl } from 'gooten-js-utils/src/url';
import SecurityService from '../../../Settings/SecurityService';
import Icon from 'gooten-components/src/components/shared/Icon';
import Alert from 'gooten-components/src/components/shared/Alert';

const StatusView = props => {
  useEffect(() => {
    props.getAcceptableStatuses(props.params.id);

    // Note: Default status should be ReadyForPrint (Id no. 8) for admins
    if (!selectedStatus && SecurityService.isSuperAdmin()) {
      setSelectedStatus(8);
    }
  }, []);

  // If all items are BillRefund, it should only have "BillRefunded" as an option
  useEffect(() => {
    if (
      props.itemsData &&
      SecurityService.isSuperAdmin() &&
      props.itemsData.find(x => x.StatusId === 21) && // At least one item is BillRefunded
      props.itemsData.filter(x => x.StatusId === 21).length === props.itemsData.length // All items are BillRefunded
    ) {
      setSelectedStatus(21);
    }
  }, [props.itemsData]);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [comments, setComments] = useState('');
  const [noStatusSelectedError, setNoStatusSelectedError] = useState(false);
  const [noTrackingInfoError, setNoTrackingInfoError] = useState(false);
  const [noItemsSelectedError, setNoItemsSelectedError] = useState(false);
  const [highlightedButton, setHighlightedButton] = useState(null);

  const resetAfterSuccessSubmit = () => {
    if (SecurityService.isSuperAdmin()) {
      setSelectedStatus(8);
    } else {
      setSelectedStatus(null);
    }
    setComments('');
    setNoItemsSelectedError(false);
  };

  const submitStatusChange = () => {
    if (!selectedStatus) {
      setNoStatusSelectedError(true);
    } else if (!selectedIds.length) {
      setNoItemsSelectedError(true);
    } else if (selectedStatus === 1) {
      // submit tracking required for shipped status update
      if (!comments) {
        setNoTrackingInfoError(true);
      } else {
        props.changeTrackingNumber({
          trackingNumber: comments,
          ids: selectedIds
        });
        // reset everything after success submit
        setNoTrackingInfoError(false);
        resetAfterSuccessSubmit();
      }
    } else {
      props.updateItemsStatus({
        comments: comments,
        ids: selectedIds,
        statusId: selectedStatus
      });
      // reset everything after success submit
      resetAfterSuccessSubmit();
    }
    selectNone();
  };

  // select and unselect a single item
  const toggleSelected = itemNumber => {
    let copyOfSelectedIds = Array.from(selectedIds);
    if (copyOfSelectedIds.includes(itemNumber)) {
      copyOfSelectedIds.splice(copyOfSelectedIds.indexOf(itemNumber), 1);
      setSelectedIds(copyOfSelectedIds);
    } else {
      copyOfSelectedIds.push(itemNumber);
      setSelectedIds(copyOfSelectedIds);
    }
    setHighlightedButton(null);
  };

  // deselect all
  const selectNone = () => {
    setSelectedIds([]);
    setHighlightedButton('none');
  };

  // select everything
  const selectAll = () => {
    selectNone();
    let copyOfSelectedIds = Array.from(selectedIds);
    props.itemsData.forEach(item => copyOfSelectedIds.push(item.Item));
    setSelectedIds(copyOfSelectedIds);
    setHighlightedButton('all');
  };

  // select by a item status
  const selectByStatusId = id => {
    let itemsWithMatchingStatus = [];
    props.itemsData.forEach(item => {
      if (item.StatusId === id) {
        itemsWithMatchingStatus.push(item.Item);
      }
    });
    setSelectedIds(itemsWithMatchingStatus);
    setHighlightedButton(id);
  };

  // select by group name
  const selectByGroup = groupName => {
    let itemsWithMatchingGroupName = [];
    props.itemsData.forEach(item => {
      if (item.Name === groupName) {
        itemsWithMatchingGroupName.push(item.Item);
      }
    });
    setSelectedIds(itemsWithMatchingGroupName);
    setHighlightedButton(groupName);
  };

  const acceptableStatusesUpdated = () => {
    const acceptableStatusAdjusted = props.acceptableStatuses;
    if (
      (SecurityService.isPartner() || SecurityService.isPartnerAdmin()) &&
      props.summaryData.Meta &&
      props.summaryData.Meta.needs_customization === 'true'
    ) {
      acceptableStatusAdjusted.unshift({ Id: 'FakeReadyToPrint', Name: 'SendToProduction' });
    }
    // return only array of unique statuses (fix issue where FakeReadyToPrint sometimes returns many times)
    const acceptableStatuses = acceptableStatusAdjusted.filter((x, index) => {
      const _x = JSON.stringify(x);
      return (
        index ===
        acceptableStatusAdjusted.findIndex(obj => {
          return JSON.stringify(obj) === _x;
        })
      );
    });
    return acceptableStatuses;
  };

  const scrollToTop = () => {
    if (document.getElementsByClassName('gooten-hub-container')[0]) {
      document
        .getElementsByClassName('gooten-hub-container')[0]
        .scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      {props.loading ? (
        <LoadingSpinner />
      ) : (
        <div className="status-tab">
          <div className="change-status-content mt-4">
            <div className="title-2">Change Status</div>
            <p className="body-text-2">
              Please select the status change & items or group of items you want change status of:
            </p>
            <div className="update-actions-row m-n1">
              <div className="body-text-2 heavy d-flex m-1 pt-1 pb-1">Update Status</div>
              <div className="p-1">
                <DropDownContainer
                  hasError={noStatusSelectedError}
                  title={
                    selectedStatus ? <Chip noTooltip statusNumber={selectedStatus} /> : 'Select'
                  }
                >
                  {acceptableStatusesUpdated().map((option, key) => (
                    <ListItem
                      key={key}
                      text={<Chip noTooltip statusNumber={option.Id} />}
                      onSelect={() => {
                        setSelectedStatus(option.Id);
                        setNoStatusSelectedError(false);
                      }}
                    />
                  ))}
                </DropDownContainer>
              </div>
              <div className="p-1">
                <Input
                  hasError={selectedStatus === 1 && !comments}
                  errorText={
                    selectedStatus === 1 && noTrackingInfoError ? 'Tracking info required' : null
                  }
                  value={comments}
                  onChange={e => setComments(e.target.value)}
                  noLabel
                  placeholder={selectedStatus === 1 ? 'Tracking Number' : 'Comment'}
                />
              </div>
              <div className="p-1">
                <Button onClick={() => submitStatusChange()} className="button-primary large w-100">
                  Change Status
                </Button>
              </div>
            </div>
          </div>
          <hr className="large" />
          <div className="d-flex quick-select mb-4">
            <div className="body-text-2 heavy d-flex ml-1 mb-2 mt-2 mr-4">Quick Select</div>
            <div className="quick-select-options d-flex flex-wrap">
              {props.itemsData &&
                props.itemsData
                  .map(item => item.Name)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((group, key) => (
                    <Button
                      onClick={() => selectByGroup(group)}
                      key={key}
                      className={`${
                        highlightedButton === group ? 'button-primary' : 'button-default'
                      } medium m-1`}
                    >
                      {group}
                    </Button>
                  ))}
              <Button
                onClick={selectNone}
                className={`${
                  highlightedButton === 'none' ? 'button-primary' : 'button-default'
                } medium m-1`}
              >
                None
              </Button>
              <Button
                onClick={selectAll}
                className={`${
                  highlightedButton === 'all' ? 'button-primary' : 'button-default'
                } medium m-1`}
              >
                All
              </Button>
              <Button
                onClick={() => selectByStatusId(32)}
                className={`${
                  highlightedButton === 32 ? 'button-primary' : 'button-default'
                } medium m-1`}
              >
                All Needs Approval
              </Button>
              <Button
                onClick={() => selectByStatusId(31)}
                className={`${
                  highlightedButton === 31 ? 'button-primary' : 'button-default'
                } medium m-1`}
              >
                All Hold
              </Button>
              <Button
                onClick={() => selectByStatusId(1)}
                className={`${
                  highlightedButton === 1 ? 'button-primary' : 'button-default'
                } medium m-1`}
              >
                All Shipped
              </Button>
              <Button
                onClick={() => selectByStatusId(12)}
                className={`${
                  highlightedButton === 12 ? 'button-primary' : 'button-default'
                } medium m-1`}
              >
                All Images Issues
              </Button>
            </div>
          </div>
          <Alert isOpen={noItemsSelectedError} type="warning">
            Please select at least one order item below you would like to update
          </Alert>
          {props.itemsData && (
            <>
              <div className="grid-container">
                {props.itemsData.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {/* This starts a new GROUP section if there is a new item name change */}
                      {key === 0 ? (
                        <div className="title-2 mt-3 header">{item.Name}</div>
                      ) : props.itemsData[key - 1].Name !== item.Name ? (
                        <div className="title-2 mt-3 header">{item.Name}</div>
                      ) : null}
                      <div key={key} className="order-item">
                        <ProductCard
                          key={key}
                          type="hub"
                          selectClick={() => toggleSelected(item.Item)}
                          productClick={() => toggleSelected(item.Item)}
                          title={item.SKU}
                          additionalInfo={
                            <div className="item-info">
                              <div className="body-text-2">
                                <div className="d-flex justify-content-between">
                                  <i>Quantity:</i> {item.Quantity}
                                </div>
                                <div className="d-flex justify-content-between">
                                  <i>Item ID:</i> {item.Item}
                                </div>
                              </div>
                              <Chip statusNumber={item.StatusId} className="mt-2 ml-n1" />
                            </div>
                          }
                          image={
                            item.Images[0]
                              ? getOrderDetailsImageResizerUrl(item.Images[0].SourceUrl)
                              : null
                          }
                          selected={selectedIds.includes(item.Item)}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
          {props.itemsData && props.itemsData.length > 30 && (
            <div className="scroll-to-top">
              <Button
                className="button-default extra-large"
                iconOnly
                round
                onClick={() => scrollToTop()}
              >
                <Icon icon="chevronUp" />
              </Button>
            </div>
          )}
        </div>
      )}
      <style jsx>
        {`
          .update-actions-row {
            display: grid;
            grid-template-columns: 8rem 20.5rem minmax(10rem, auto) 10rem;
          }
          @media screen and (max-width: 767px) {
            .update-actions-row {
              grid-template-columns: 1fr;
            }
            .update-actions-row :global(.dropdown-container) {
              max-width: 100%;
            }
          }
          .grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            row-gap: 1rem;
            column-gap: 1rem;
            padding: 0.5rem 0 2rem;
          }
          .order-group :global(.card) {
            overflow: visible;
          }
          .order-group :global(.truncated-text) {
            overflow: unset;
            -webkit-line-clamp: unset;
          }
          .header {
            grid-column-start: 1;
            grid-column: 1 / -1;
          }
          .grid-container :global(.card) {
            overflow: visible;
          }
          .scroll-to-top {
            position: fixed;
            right: 2rem;
            bottom: 1rem;
          }
          @media screen and (max-width: 576px) {
            .scroll-to-top {
              right: 1rem;
            }
          }
          .grid-container :global(.product-title) {
            display: -webkit-box;
            -webkit-line-clamp: 3;
          }
        `}
      </style>
    </>
  );
};

StatusView.propTypes = {
  params: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getAcceptableStatuses: PropTypes.func.isRequired,
  acceptableStatuses: PropTypes.array.isRequired,
  updateItemsStatus: PropTypes.func.isRequired,
  itemsData: PropTypes.array.isRequired,
  summaryData: PropTypes.object.isRequired,
  changeTrackingNumber: PropTypes.func
};

export default StatusView;
