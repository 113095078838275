import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../Loader';
import UploadFromDevice from './atoms/shared/UploadFromDevice';
import { NeckTagPreview } from '../NeckTagUploadModal';
import './ImageUploadModal.scss';
import Button from '../Button';
import Modal from '../Modal';

const Container = styled.div`
  .modal-content .modal-body {
    padding: 0;
  }

  .image-preview {
    margin-bottom: 16px;
    .current-image-container {
      width: 100%;
      .img-responsive {
        max-width: 300px;
      }
    }
    .btn-primary {
      margin-top: 16px;
    }
  }
  .nav-title {
    font-size: 21px !important;
  }
  label {
    font-size: 12px;
  }
`;
class ImageUploadModal extends React.PureComponent {
  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const canSave =
      !this.props.customFormInvalid &&
      ((this.props.customFormUpdated && this.props.presetImage) || this.props.state.get('image'));
    return (
      <Container className="image-upload-modal">
        <Modal
          isOpen={this.props.isOpen}
          cancelClick={() => this.props.onCancel()}
          cancelText="Cancel"
          primaryClick={canSave ? () => this.props.onSave(this.props.image) : null}
          actionText="Save"
          title={this.props.modalTitle}
        >
          {this.props.state.get('loading') ? <Loader /> : null}
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-xs-12">{this.props.children}</div>
            </div>
            {!this.props.state.get('upload') && this.props.image ? (
              <div className="row image-preview">
                <div className="col-xs-12">
                  <div className="col-xs-12">
                    <label>Preview</label>
                  </div>
                  <div className="col-xs-12-">
                    {this.props.neckTagPreview ? (
                      <NeckTagPreview>
                        <div className="preview-container">
                          <div className="necktag-container">
                            <img
                              onLoad={this.props.getImgSize || null}
                              className="necktag-image"
                              src={this.props.image}
                            />
                          </div>
                        </div>
                      </NeckTagPreview>
                    ) : (
                      <div className="current-image-container">
                        <img
                          onLoad={this.props.getImgSize || null}
                          className="img-responsive col-xs-12"
                          src={this.props.image}
                        />
                      </div>
                    )}
                    <div className="col-xs-12">
                      <Button
                        className="button-primary large mt-2"
                        onClick={() => this.props.showUpload(true)}
                      >
                        REPLACE IMAGE
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row image-upload">
                <div className="col-xs-12">
                  <div className="col-xs-12">
                    <label>Upload Image</label>
                  </div>
                </div>
                <UploadFromDevice
                  isMultiSpace={false}
                  uploadFiles={files => this.props.uploadFiles(files)}
                />
              </div>
            )}
            {this.props.imageLegend}
          </div>
        </Modal>
      </Container>
    );
  }
}

ImageUploadModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  customFormUpdated: PropTypes.bool,
  customFormInvalid: PropTypes.bool,
  children: PropTypes.object,
  presetImage: PropTypes.string,
  imageLegend: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  neckTagPreview: PropTypes.bool,

  state: PropTypes.object.isRequired,
  image: PropTypes.string,

  reset: PropTypes.func.isRequired,
  showUpload: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  getImgSize: PropTypes.func
};

export default ImageUploadModal;
