import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../shared/Input';

const ProductType = props => {
  return (
    <div className="product-publish-product-type pb-4 flex-container">
      <div className="control-group has-feedback">
        <Input
          type="text"
          placeholder="Product Type"
          value={props.value}
          onChange={e => props.onChange(props.storeId, 'productType', e.target.value)}
        />
      </div>
      <style jsx>
        {`
          .product-publish-product-type {
            flex-direction: column;
          }
        `}
      </style>
    </div>
  );
};

ProductType.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  storeId: PropTypes.number
};

export default ProductType;
