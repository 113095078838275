import React from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../shared/Slider';

import './ProductPanel.scss';

class ProductPanel extends React.Component {
  static propTypes = {
    productDetails: PropTypes.object.isRequired,
    currentImage: PropTypes.number.isRequired,
    setCurrentImage: PropTypes.func.isRequired
  };

  getImages() {
    let config = { width: 'max-content', height: 'max-content' };

    return (
      <Slider
        config={config}
        current={{ horizontal: this.props.currentImage }}
        horizontalItems={this.props.productDetails.images}
        goTo={(hIndex, vIndex) => {
          this.props.setCurrentImage(hIndex);
        }}
      >
        <img
          src={this.props.productDetails.images[this.props.currentImage]}
          className="product-panel-image"
          alt=""
        />
      </Slider>
    );
  }

  render() {
    return (
      <div className="product-panel-container text-center">
        <div className="row">{this.getImages()}</div>
      </div>
    );
  }
}

export default ProductPanel;
