import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import Chip from 'gooten-components/src/components/shared/Chip';
import Modal from 'gooten-components/src/components/shared/Modal';
import RadioField from 'gooten-components/src/components/shared/RadioField';
import SecurityService from '../../../../../Settings/SecurityService';
import { getSmallPreviewImageResizerUrl, getProductImageResizerUrl } from 'gooten-js-utils/src/url';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import { Link } from 'react-router';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';
import Icon from 'gooten-components/src/components/shared/Icon';
import { COLORS, SHIPPING_TIMES_KB_LINK } from 'gooten-components/src/constants';
import Input from 'gooten-components/src/components/shared/Input';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import moment from 'moment';

const OrderItemCard = props => {
  const { id, data, summaryData, changeSkuData, changeSku, getVariantsForSku } = props;

  const [showAllMetaDetails, expandMeta] = useState(false);
  const [isHovered, setHover] = useState(false);
  const [selectedSku, setSelectedSku] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showChangeSkuModal, setShowChangeSkuModal] = useState(false);

  const showMetaData = data => {
    let metaInfo = '';

    Object.keys(data.Meta).forEach((key, index) => {
      if (metaInfo.length > 0) metaInfo += ', ';
      metaInfo += `${key}: ${data.Meta[key]}`;
    });

    if (!showAllMetaDetails && metaInfo.length > 30) {
      metaInfo = metaInfo.substring(0, 30) + '...';
    }

    return <a onClick={() => expandMeta(!showAllMetaDetails)}>{metaInfo}</a>;
  };

  const calcColumnWidths = dynamicColumms => {
    let columnData = '3.5rem minmax(3.5rem, 6.5rem)';
    for (let i = 0; i <= dynamicColumms; i++) {
      columnData += ' auto';
    }
    return columnData;
  };

  const getArchivedReason = reason => {
    switch (reason) {
      case 3:
        return 'Out of stock';
      case 2:
        return 'Discontinued';
      case 1:
        return 'Temporarily disabled';
      default:
        return 'Unavailable';
    }
  };

  const isUnavailable = variant => {
    return variant.IsArchived || variant.Meta?.vendor_is_out_of_stock === 'true';
  };

  const renderChangeSkuDetails = () => {
    if (!showChangeSkuModal) {
      return null;
    } else if (changeSkuData.loading) {
      return (
        <div className="loading-spinner">
          <LoadingSpinner />
        </div>
      );
    } else if (changeSkuData.variants.HadError) {
      setShowChangeSkuModal(false);
    } else if (changeSkuData.variants.Variants.length === 0) {
      return <div>You don't have any variants to change SKU.</div>;
    } else {
      return (
        <>
          <div className="d-flex mb-3">
            <Thumbnail
              className="medium m-0 mr-3"
              image={
                data.Images[0]
                  ? data.Images[0].FileName ||
                    getSmallPreviewImageResizerUrl(data.Images[0].SourceUrl)
                  : null
              }
            />
            <div>
              <div className="overline">#{data.Item}</div>
              <div className="title-2">{data.SKU ? data.SKU.split('-')[0] : null}</div>
              <div className="body-text-1">
                {data.Options ? data.Options.map(x => x.Value).join(', ') : null}
              </div>
            </div>
          </div>
          <hr className="medium mr-n4 ml-n4" />
          <Input
            type="text"
            className="mb-2"
            value={searchQuery}
            noLabel
            icon="search"
            iconPlacement="leading"
            placeholder="Search"
            onChange={e => setSearchQuery(e.target.value)}
          />
          <TableRow
            scrollableX
            columns={calcColumnWidths(changeSkuData.variants.Variants[0].Options.length)}
          >
            <Cell header />
            <Cell header />
            {changeSkuData.variants.Variants[0].Options.map(options => (
              <Cell key={options.Name} header>
                {options.Name}
              </Cell>
            ))}
            <Cell header>
              <div className="d-flex w-100 justify-content-end">Cost</div>
            </Cell>
            {changeSkuData.variants.Variants.map(variant => {
              return (
                (!searchQuery ||
                  (searchQuery &&
                    variant.ProductSku.toLowerCase().includes(
                      searchQuery.toLowerCase().replace(' ', '')
                    ))) && (
                  <React.Fragment key={variant.Sku}>
                    <Cell
                      onClick={() => (isUnavailable(variant) ? null : setSelectedSku(variant.Sku))}
                    >
                      {isUnavailable(variant) ? (
                        <div className="ml-n1">
                          <Icon height="26" width="26" icon="alertCircle" fill="#C80404" />
                        </div>
                      ) : (
                        <RadioField checked={selectedSku === variant.Sku} />
                      )}
                    </Cell>
                    <Cell
                      onClick={() => (isUnavailable(variant) ? null : setSelectedSku(variant.Sku))}
                    >
                      <img
                        src={
                          data.Images[0]
                            ? data.Images[0].FileName ||
                              getSmallPreviewImageResizerUrl(variant.PreviewUrl)
                            : null
                        }
                        className={'sku-img'}
                      />
                    </Cell>
                    {reorderOptions(
                      changeSkuData.variants.Variants[0].Options,
                      variant.Options
                    ).map(options => (
                      <Cell
                        onClick={() =>
                          isUnavailable(variant) ? null : setSelectedSku(variant.Sku)
                        }
                        key={options.Value}
                      >
                        {options.Value}
                      </Cell>
                    ))}
                    <Cell
                      onClick={() => (isUnavailable(variant) ? null : setSelectedSku(variant.Sku))}
                    >
                      <div className="d-flex w-100 justify-content-end">
                        {isUnavailable(variant) ? (
                          <div className="mr-n3">
                            <Chip className="red">{getArchivedReason(variant.ArchivedReason)}</Chip>
                          </div>
                        ) : (
                          variant.PartnerPrice
                        )}
                      </div>
                    </Cell>
                  </React.Fragment>
                )
              );
            })}
          </TableRow>
        </>
      );
    }
  };

  const reorderOptions = (headerOptions, options) => {
    let result = [];
    for (let i = 0; i < headerOptions.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (headerOptions[i].Name === options[j].Name) {
          result.push(options[j]);
        }
      }
    }
    return result;
  };

  const onUpdatedSku = () => {
    if (selectedSku) {
      setShowChangeSkuModal(false);
      changeSku(changeSkuData, summaryData, selectedSku, data.Item);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="d-flex item-number-row item-header">
            <p className="item-number-key">Item</p>&nbsp;
            <p className="item-number-value">{data.Item}</p>
          </div>
          <div className="d-flex partner-order-item-row item-header">
            <p className="partner-order-item-id-key">Partner Order Item ID:</p>&nbsp;
            <p className="partner-order-item-id-value">{data.Id}</p>
          </div>
          <div className="d-flex vendor-order-id-row item-header">
            <p className="vendor-order-id-key">Vendor Order ID:</p>&nbsp;
            <p className="vendor-order-id-value">{data.SecondId}</p>
          </div>
          <div className="d-flex justify-content-between status-row">
            <p className="mr-3 status-key">Status</p>
            <div className="status-value">
              <Chip statusNumber={data.StatusId} />
            </div>
          </div>
          <div className="d-flex justify-content-between gooten-sku-row">
            <p className="gooten-sku-key mr-3">Gooten SKU</p>
            <p className="gooten-sku-value text-right">{data.SKU}</p>
          </div>
          <div className="d-flex justify-content-between quantity-row">
            <p className="quantity-key mr-3">Quantity</p>
            <p className="quantity-value">{data.Quantity}</p>
          </div>
          {data.Options.map(option => (
            <div
              key={option.Name}
              className={`d-flex justify-content-between options option-${option.Name.toLowerCase().replace(
                ' ',
                '-'
              )}`}
            >
              <p className="option-key no-wrap mr-3">Option: {option.Name}</p>
              <p className="option-value">{option.Value}</p>
            </div>
          ))}
          {data.EstDeliveryDate ? (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex">
                    <p className="mr-1">Estimated Delivery Date</p>
                    <Tooltip
                      position="right"
                      content="Delivery date estimate is provided by the carrier"
                    >
                      <a href={SHIPPING_TIMES_KB_LINK} target="_blank">
                        <Icon className="info-icon" icon="info" height={16} width={16} />
                      </a>
                    </Tooltip>
                  </div>
                  <p className="shipment-tracking-url">
                    <>
                      •&nbsp;
                      <a href={data.TrackingUrl} target="_blank">
                        {data.TrackingNumber}
                      </a>
                    </>
                  </p>
                </div>
                <p className="mt-3">
                  {moment(data.EstDeliveryDate, 'MM/DD/YYYY').format('MMM - DD')}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between tracking-number-row">
                <p className="mr-3 tracking-number-key">Tracking Number</p>
                <p className="tracking-number-value">
                  {data.TrackingNumber}
                  {data.TrackingUrl && (
                    <a target="_blank" href={data.TrackingUrl}>
                      <Button className="button-default small ml-1 mr-1" iconOnly>
                        <Icon icon="externalLink" />
                      </Button>
                    </a>
                  )}
                </p>
              </div>
            </>
          )}
          {data.EstimatedShipDate &&
            data.EstimatedShipDateConfidenceLevel &&
            data.EstimatedShipDateConfidenceLevel >= 0.5 && (
              <div className="d-flex justify-content-between estimated-ship-date-row">
                <Tooltip
                  position="top"
                  content={`Ship date is estimated using historical data with an accuracy of ${
                    data.EstimatedShipDateConfidenceLevel * 100
                  }%`}
                >
                  <p className="mr-3 estimated-ship-date-key">Ship Date</p>
                </Tooltip>
                <p className="estimated-ship-date-value">{data.EstimatedShipDate}</p>
              </div>
            )}
          <div className="d-flex justify-content-between carrier-method-row">
            <p className="mr-3 carrier-method-key">Carrier Method</p>
            <p className="carrier-method-value">{data.CarrierMethod}</p>
          </div>
          <div className="d-flex justify-content-between carrier-priority-row">
            <p className="mr-3 carrier-priority-key">Carrier Priority</p>
            <p className="carrier-priority-value">{data.ShipmentMethod}</p>
          </div>
          <div className="d-flex justify-content-between meta-row">
            <p className="mr-3 meta-key">Meta</p>
            <p className="metaDetails meta-value">
              {data.Meta && Object.keys(data.Meta).length !== 0 ? showMetaData(data) : '-'}
            </p>
          </div>
          <div className="justify-content-between mr-n1 ml-n1 mt-3 mb-3">
            {SecurityService.isSuperAdmin() && !data.IsEmbroidery && (
              <Link to={`/printReadyProducts?partnerId=${id}`}>
                <Button className="button-default m-1 large">Save as Print-Ready Product</Button>
              </Link>
            )}
            {data.CanChangeImage && (
              <Link to={`orders-new/${id}/images`}>
                <Button
                  className={'button-default m-1 large'}
                  onClick={() => props.setActiveImage(data.Item)}
                >
                  Change Image
                </Button>
              </Link>
            )}
            {data.Meta && data.Meta.PreconfigSku && data.CanChangeSku && (
              <Button
                className="button-default m-1 large"
                onClick={() => {
                  getVariantsForSku(
                    summaryData.PartnerInternalId,
                    data.VendorId,
                    data.Meta.PreconfigSku
                  );
                  setShowChangeSkuModal(true);
                }}
              >
                Change SKU
              </Button>
            )}
          </div>
        </div>
        <div
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="col-sm-12 col-md-6"
        >
          {data.Images[0] ? (
            data.Images[0].FileName || data.Images[0].SourceUrl ? (
              <>
                <img
                  src={
                    data.Images[0].FileName || getProductImageResizerUrl(data.Images[0].SourceUrl)
                  }
                  className={'item-img'}
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = '/admin-assets/img/AssetsOrderDetails/image-missing.png';
                  }}
                />
                {data.Status === 'ImageIssue' && (
                  <div className="image-issue">
                    Image with a higher resolution should be uploaded to ensure great product
                    quality.
                  </div>
                )}
              </>
            ) : (
              <div className="icons-container d-flex align-items-center justify-content-center w-100 h-100">
                <Icon width={40} height={40} fill={COLORS.neutralDark200} icon="image" />
              </div>
            )
          ) : null}
          {isHovered && data.CanChangeImage && (
            <Link to={`orders-new/${id}/images`}>
              <Button
                className="button-cta large mt-3 center-on-image"
                onClick={() => props.setActiveImage(data.Item)}
              >
                Change Image
              </Button>
            </Link>
          )}
        </div>
      </div>

      <Modal
        isOpen={showChangeSkuModal}
        title={'Change SKU'}
        cancelClick={() => setShowChangeSkuModal(false)}
        cancelText="Cancel"
        primaryClick={
          changeSkuData.variants &&
          changeSkuData.variants.Variants &&
          changeSkuData.variants.Variants.length > 0
            ? () => onUpdatedSku()
            : null
        }
        actionText="Save"
      >
        <div className="p-4">{renderChangeSkuDetails()}</div>
      </Modal>
      <style jsx>
        {`
          .item-header p {
            font-weight: 400;
            margin-bottom: 25px;
            font-size: 18px;
          }
          .shipment-tracking-url {
            fontsize: 13px;
            textalign: left;
            margintop: -8px;
            opacity: 0.8;
          }
          .item-img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
          }

          :global(.sku-img) {
            max-height: 50px;
          }

          .no-wrap {
            white-space: nowrap;
          }
          .production-times-link {
            text-decoration: underline;
            color: ${COLORS.gootenBlue};
          }
          .image-issue {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: white;
            background: #443c55;
            text-align: center;
            margin: 0 26px;
            padding: 10px;
          }

          :global(.loading-spinner) {
            padding: 40px !important;
          }

          :global(.center-on-image) {
            position: absolute !important;
            width: 124px !important;
            height: 40px !important;
            top: 50% !important;
            left: 50% !important;
            margin: -20px 0px 0px -62px !important;
          }

          :global(.metaDetails) {
            text-align: right;
          }

          :global(.item-buttons button:last-child) {
            margin: 0 !important;
          }

          :global(.submit-btn) {
            width: 150px !important;
            margin-right: 15px !important;
          }

          :global(.cancel-btn) {
            width: 150px !important;
          }
          .icons-container {
            height: 0;
            padding-bottom: 100%;
            position: relative;
            border: 1px solid ${COLORS.neutralLight300};
            border-radius: 4px;
          }
          .icons-container :global(svg) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .meta-value {
            word-break: break-word;
          }
        `}
      </style>
    </>
  );
};

OrderItemCard.propTypes = {
  data: PropTypes.object.isRequired,
  summaryData: PropTypes.object.isRequired,
  changeSkuData: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  getVariantsForSku: PropTypes.func.isRequired,
  changeSku: PropTypes.func.isRequired,
  setActiveImage: PropTypes.func
};

OrderItemCard.defaultProps = {
  productionTime: null
};

export default OrderItemCard;
