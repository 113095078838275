import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import CartItem from '../CartItem';
import './CartItems.scss';
import { List } from 'immutable';

class CartItems extends React.PureComponent {
  labels() {
    return (
      <div className="row no-gutters header-labels clearfix hidden-xs">
        <span className="col-lg-8 col-md-8 col-sm-7 col-xs-6" />
        <span className="col-lg-2 col-md-2 col-sm-3 col-xs-3">Quantity</span>
        <span className="col-lg-2 col-md-2 col-sm-2 col-xs-3">Price</span>
      </div>
    );
  }

  render() {
    let cartItems = this.props.items.map((item, i) => {
      return (
        <CartItem
          key={`cart-item-${item.get('_random_id')}`}
          item={item}
          onClickEdit={() => this.props.editCartItem(item, i)}
          onClickRemove={() => this.props.removeCartItem(i)}
          onToggleSample={() => this.props.toggleItemAsSample(i)}
          onQuantityChange={this.props.updateCartItemQuantity}
          onClickPreview={this.props.showCartItemPreview}
          onClickDownload={this.props.downloadCartItemPreview}
        />
      );
    });

    return (
      <div className="cart-items">
        {this.labels()}
        <hr />
        <ReactCSSTransitionGroup
          transitionName="slide-up"
          transitionEnterTimeout={0}
          transitionLeaveTimeout={700}
        >
          {cartItems}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

CartItems.propTypes = {
  items: PropTypes.instanceOf(List).isRequired,
  editCartItem: PropTypes.func.isRequired,
  toggleItemAsSample: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  updateCartItemQuantity: PropTypes.func.isRequired,
  showCartItemPreview: PropTypes.func.isRequired,
  downloadCartItemPreview: PropTypes.func.isRequired
};

export default CartItems;
