import { takeEvery, takeLatest, call, put, select, all } from 'redux-saga/effects';
import {
  PRODUCT_VARIANTS_FETCH,
  fetchAsyncSuccess,
  fetchAsyncFail,
  SKU_SELECT,
  skuSelect
} from '../../store/actions/dataActions';
import {
  SELECT_OPTION,
  deselectOption,
  saveOrientation,
  orientationChanged,
  CANCEL_SKU_SELECTION
} from './SKUSelectionActions';
import {
  getRightPanel,
  selectedOptionsJson,
  savedOrientationsSelector,
  selectedOptionsSelector
} from './SKUSelectionSelectors';
import productDataService from '../../services/productDataService';
import Log from '../../services/logService';
import { hasProductDetails } from '../ImageUpload/ImageUploadSelectors';
import { goto } from '../../containers/NavManager/NavManagerActions';
import { backupSkusSelector } from '../ProductPublish/ProductPublishSelectors';

export function* productVariantsFetchAsyncHandler(action) {
  try {
    const productName = action.payload;
    // call and pass correct this as 1st array param
    const variants = yield call(
      [productDataService, productDataService.getProductVariants],
      productName
    );
    yield put(fetchAsyncSuccess(PRODUCT_VARIANTS_FETCH, { productName, variants }));
  } catch (err) {
    yield put(fetchAsyncFail(PRODUCT_VARIANTS_FETCH, err));
    throw Log.withFriendlyMsg('Failed to fetch product variants', err, { action });
  }
}

export function* printAreaOptionSelectHandler(action) {
  let printAreaOptionName = 'Print Area';
  let printAreaMultiSpaceOptionName = 'Center Front and Center Back';
  let hasDetails = yield select(hasProductDetails);
  if (!hasDetails) {
    return;
  }

  let options = yield select(getRightPanel);
  let option = options.find(o => o.id === action.payload.optionId);
  if (!option || option.title !== printAreaOptionName) {
    return;
  }

  let selectedOptions = yield select(selectedOptionsJson);
  if (!selectedOptions[option.id].length) {
    return;
  }

  let value = option.values.find(v => v.id === action.payload.valueId);
  let cfcbValue = option.values.find(v => v.name === printAreaMultiSpaceOptionName);
  if (value.name === printAreaMultiSpaceOptionName) {
    for (let selectedValueId of selectedOptions[option.id]) {
      if (selectedValueId !== cfcbValue.id) {
        yield put(deselectOption(option.id, selectedValueId));
      }
    }
  } else if (cfcbValue && selectedOptions[option.id].includes(cfcbValue.id)) {
    yield put(deselectOption(option.id, cfcbValue.id));
  }
}

function* skuSelectHandler() {
  // watch for SKU_SELECT action, which is fired when user clicks NEXT,
  // and save current orientation that's selected
  const selectedOptions = yield select(selectedOptionsSelector);
  const savedOrientations = yield select(savedOrientationsSelector);
  const orientationExist =
    selectedOptions.get('orientation') && selectedOptions.get('orientation').size;

  // if we have no saved orientations, or we have them but orientation is changed,
  // then save them
  if (
    (!savedOrientations.size && orientationExist) ||
    (savedOrientations.size &&
      orientationExist &&
      savedOrientations.toJS().join('') !== selectedOptions.get('orientation').toJS().join(''))
  ) {
    yield put(saveOrientation(selectedOptions.get('orientation')));
    yield put(orientationChanged(true));
  } else {
    yield put(orientationChanged(false));
  }
}

function* cancelSkuSelectionHandler() {
  const backupSkus = yield select(backupSkusSelector);
  if (backupSkus && backupSkus.size) {
    yield put(skuSelect(backupSkus.toJS()));
  }
  yield put(goto('SaveAndPublish'));
}

export function* watchProductVariantsFetchAsync() {
  yield takeEvery(PRODUCT_VARIANTS_FETCH.ASYNC, productVariantsFetchAsyncHandler);
}

export function* watchProductOptionSelect() {
  yield takeEvery(SELECT_OPTION, printAreaOptionSelectHandler);
}

export function* watchSKUSelectAsync() {
  yield takeLatest(SKU_SELECT, skuSelectHandler);
}

export function* watchCancelSkuSelection() {
  yield takeLatest(CANCEL_SKU_SELECTION, cancelSkuSelectionHandler);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchProductVariantsFetchAsync(),
    watchProductOptionSelect(),
    watchSKUSelectAsync(),
    watchCancelSkuSelection()
  ]);
}
