import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Pill from '../../../../../shared/Pill';
import Icon from '../../../../../shared/Icon';

class Regions extends React.PureComponent {
  static propTypes = {
    isRegionsShown: PropTypes.bool.isRequired,
    regions: PropTypes.array.isRequired,
    selectedRegions: PropTypes.instanceOf(List),
    selectRegions: PropTypes.func.isRequired,
    deselectRegion: PropTypes.func.isRequired,
    cleanSelectedOptions: PropTypes.func.isRequired,
    showErrors: PropTypes.bool
  };

  componentDidMount() {
    // pre-select regions only if there 1 region
    if (this.props.regions.length === 1) {
      this.props.selectRegions(this.props.regions);
    }
  }

  isRegionSelected(r) {
    return this.props.selectedRegions.includes(r);
  }

  toggleRegion(r) {
    if (this.isRegionSelected(r)) {
      this.props.deselectRegion(r);
      this.props.cleanSelectedOptions();
    } else {
      this.props.selectRegions([r]);
    }
  }

  render() {
    let regions = this.props.regions.map(r => (
      <Pill
        key={r}
        title={r}
        optionId={r}
        valueId={r}
        active={this.isRegionSelected(r)}
        toggleValue={r => this.toggleRegion(r)}
      />
    ));

    return (
      <div
        className={
          'row position-relative options-line' + (!this.props.isRegionsShown ? ' hidden' : '')
        }
      >
        <div>
          <h4
            className={`${
              this.props.showErrors && this.props.selectedRegions.size === 0 ? 'error-color' : ''
            }`}
          >
            Ship to?
          </h4>
          {this.props.showErrors && this.props.selectedRegions.size === 0 && (
            <div className="error-field caption-text d-flex align-items-center">
              <Icon icon="alertCircle" small />
              Please select at least one option below
            </div>
          )}
        </div>
        <div className="option-values">{regions}</div>
      </div>
    );
  }
}

export default Regions;
