import React from 'react';
import PropTypes from 'prop-types';
import CountrySelection from 'gooten-components/src/components/shared/CountrySelection';
import Search from '../shared/Search';
import AddToCart from './components/AddToCart';
import PastOrders from './components/PastOrders';
import analyticsService from 'gooten-components/src/services/analyticsService';

import './PastOrdersView.scss';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import PageBody from 'gooten-components/src/components/shared/PageBody';

class PastOrdersView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countrySelected: false
    };
  }

  onCountrySelect = () => {
    this.setState({
      countrySelected: true
    });
  };

  componentDidMount() {
    analyticsService.track('COF - Past Orders Flow', 'Past Orders Page Reached', 'COF');
  }

  componentWillUnmount() {
    this.props.searchReset();
  }

  renderPastOrders() {
    return (
      <div className="cof-past-orders-view-container">
        <PageHeader size="standard" title="Past Orders" customButtons={<AddToCart />} />
        <PageBody>
          <Search doSearch={this.props.doSearch} searchText={this.props.searchText} />
          <PastOrders />
        </PageBody>
      </div>
    );
  }

  render() {
    if (!this.props.hasCartItems && !this.state.countrySelected) {
      return <CountrySelection onNextClick={this.onCountrySelect} />;
    } else {
      return this.renderPastOrders();
    }
  }
}

PastOrdersView.propTypes = {
  searchReset: PropTypes.func,
  doSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  hasCartItems: PropTypes.bool.isRequired
};

export default PastOrdersView;
