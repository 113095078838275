import { connect } from 'react-redux';
import NavigationBar from './NavigationBar';
import { back } from '../../../../containers/NavManager/NavManagerActions';
import Config from '../../../../config';
import { disableNextSelector } from './NavigationBarSelectors';
import { providersSelector } from '../ProvidersTab/ProvidersTabSelectors';

const mapDispatchToProps = {
  back: () => back()
};

const mapStateToProps = state => ({
  isEdit: Config.get('editMode'),
  isLink: Config.get('linkMode'),
  disableNext: disableNextSelector(state),
  isStorageOnly: providersSelector(state).size === 1 && !providersSelector(state).getIn([0, 'id'])
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
