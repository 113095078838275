import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Multiselect from 'gooten-components/src/components/shared/Multiselect';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Container } from './../ProductSelectionStyle';
import { stepIndexElement } from 'gooten-components/src/components/shared/SelectionElements/SelectionElements';
import { nextSection } from '../ProductSelectionSharedComponents';
import './NewProductSelection.scss';
import Button from 'gooten-components/src/components/shared/Button';

const multiselectConfig = {
  multiple: false,
  showFilter: true,
  showBulkAction: false,
  showImages: true
};
const selectedProductDefaultState = { label: 'Search for Product...', key: -1 };
const selectedSubCategoryDefaultState = { label: 'Select Brand + Model', key: -1 };

export class NewProductSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: selectedProductDefaultState,
      selectedSubCategory: selectedSubCategoryDefaultState
    };
  }

  /*
   * PRODUCT selection
   */
  onProductSelectionChange(selected) {
    if (selected.length) {
      this.setState({ selectedProduct: selected[0] });

      // give time user to see what has been selected
      setTimeout(() => {
        this.props.selectProduct(selected[0].key);
      }, 300);
    } else {
      // reset product selection
      this.props.selectProduct(null);
      this.setState({ selectedProduct: selectedProductDefaultState });
    }
  }

  onCancelFullfillment() {
    this.props.deselectLastSKU();
  }

  /*
   * REGION selection
   */
  onRegionSelectionChange(selected) {
    if (selected.length) {
      this.props.selectRegionIndex(selected[0].key);
    } else {
      // reset regions selection
      this.props.setRegionsToActive();
    }
  }

  /*
   * SUBCATEGORY selection
   */
  onSubCategorySelectionChange(selected) {
    if (selected.length) {
      this.setState({ selectedSubCategory: selected[0] });

      // give time user to see what has been selected
      setTimeout(() => {
        this.props.selectSubCategoryIndex(selected[0].key);
      }, 300);
    } else {
      // reset subcategory selection
      this.props.setSubCategoriesToActive();
      this.setState({ selectedSubCategory: selectedSubCategoryDefaultState });
    }
  }

  /*
   * RENDER sections
   */
  render() {
    return (
      <Container
        className="new-product-selection-container"
        marginLeft={this.props.marginLeft}
        isNewProductFlow
      >
        {this.renderProductSelection()}
        {this.renderRegionSelection()}
        {this.renderSubCategoriesSelection()}
        {this.renderNextSection()}
        {this.renderFooter()}
      </Container>
    );
  }

  renderProductSelection() {
    return (
      <div>
        {this.props.initialStepIndex > 1 ? <hr className="delimiter" /> : null}
        <div className="row step-container">
          <div className="step-index-container">
            {this.props.selectedProduct ? (
              <div className="step-index">
                <i className="icon-progress-selected-check" />
              </div>
            ) : (
              stepIndexElement(this.props.initialStepIndex)
            )}
          </div>
          {!this.props.selectedProduct ? (
            <div className="step-content">
              <p className={!this.props.selectedProduct ? 'step-title active ' : 'step-title'}>
                Select your matching product
              </p>
              <div>
                <Multiselect
                  title={this.state.selectedProduct.label}
                  placeholder={'Type Product Name...'}
                  options={this.props.productsForSelect}
                  selectedOptions={this.props.selectedProductsForSelect}
                  onChange={s => this.onProductSelectionChange(s)}
                  config={multiselectConfig}
                />
              </div>
            </div>
          ) : (
            <div className="step-content">
              <span>{this.props.selectedProduct.get('name')}</span>
              <span
                className="pull-right clear-link"
                onClick={() => this.onProductSelectionChange([])}
              >
                change
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderRegionSelection() {
    if (
      !this.props.selectedProduct ||
      !this.props.regions ||
      !this.props.regions.get(this.props.selectedRegionIndex)
    ) {
      return null;
    }

    return (
      <div>
        <hr className="delimiter" />
        <div className="row step-container">
          <div className="step-index-container">
            {!this.props.regionsActive ? (
              <div className="step-index">
                <i className="icon-progress-selected-check" />
              </div>
            ) : (
              stepIndexElement(this.props.initialStepIndex + 1)
            )}
          </div>
          {this.props.regions.size > 1 && this.props.regionsActive ? (
            <div className="step-content">
              <p className={this.props.regionsActive ? 'step-title active' : 'step-title'}>
                Select your Shipping Region(s)
              </p>
              <div className="row regions-container">
                {this.props.regions.map((r, index) => {
                  return (
                    <Button
                      key={r.get('name')}
                      onClick={() => this.onRegionSelectionChange([{ key: index }])}
                      className="button-secondary large"
                    >
                      {r.get('name')}
                    </Button>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="step-content">
              <span>{this.props.regions.get(this.props.selectedRegionIndex).get('name')}</span>
              {this.props.regions.size > 1 ? (
                <span
                  className="pull-right clear-link"
                  onClick={() => this.onRegionSelectionChange([])}
                >
                  change
                </span>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderSubCategoriesSelection() {
    if (
      this.props.regionsActive ||
      !this.props.selectedProduct ||
      this.props.subCategoriesForSelect.length < 2
    ) {
      return null;
    }
    const selectedSubCategories = [];

    return (
      <div>
        <hr className="delimiter" />
        <div className="row step-container">
          <div className="step-index-container">
            {!this.props.subCategoriesActive ? (
              <div className="step-index">
                <i className="icon-progress-selected-check" />
              </div>
            ) : (
              stepIndexElement(this.props.initialStepIndex + 2)
            )}
          </div>
          {this.props.subCategoriesActive ? (
            <div className="step-content">
              <p className={this.props.subCategoriesActive ? 'step-title active ' : 'step-title'}>
                Select Brand + Model
              </p>
              <Multiselect
                title={this.state.selectedSubCategory.label}
                options={this.props.subCategoriesForSelect}
                selectedOptions={selectedSubCategories}
                onChange={s => this.onSubCategorySelectionChange(s)}
                config={multiselectConfig}
              />
            </div>
          ) : (
            <div className="step-content">
              <span>{this.props.selectedSubCategory.get('name')}</span>
              <span
                className="pull-right clear-link"
                onClick={() => this.onSubCategorySelectionChange([])}
              >
                change
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderNextSection() {
    if (!this.props.selectedProduct) {
      return nextSection('Next: Sync your Variants', this.props.selectedProduct);
    } else if (this.props.regionsActive && !this.props.regions.size) {
      return nextSection('Loading...', this.props.selectedProduct);
    }
  }

  renderFooter() {
    return this.props.selectedProduct &&
      this.props.regions.get(this.props.selectedRegionIndex) &&
      this.props.regions.get(this.props.selectedRegionIndex).get('sub-categories').size &&
      this.props.canGoNext ? (
      <ReactCSSTransitionGroup
        // eslint-disable-next-line
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
        transitionName="fade"
      >
        <div className="can-go-next-container text-center">
          <ReactCSSTransitionGroup
            // eslint-disable-next-line
            transitionAppear={true}
            transitionAppearTimeout={800}
            transitionEnter={false}
            transitionLeave={false}
            transitionName="slide-up"
          >
            <div className="content-holder">
              <p>Your SKUs are now synced!</p>
              <Link to={`${this.props.providerPath}/link-product/product-design/start`}>
                <Button className="button-cta large">{this.props.continueButtonLabel}</Button>
              </Link>
              <Button
                className="button-default small m-2"
                onClick={() => this.onCancelFullfillment()}
              >
                Cancel
              </Button>
            </div>
          </ReactCSSTransitionGroup>
        </div>
      </ReactCSSTransitionGroup>
    ) : null;
  }

  renderMappingsProgress(progress) {
    return (
      <div>
        <hr className="delimiter" />
        <div className="row step-container">
          <div className="step-index-container">
            {progress.selected === progress.total && !progress.canUpdate ? (
              <div className="step-index">
                <i className="icon-progress-selected-check" />
              </div>
            ) : (
              <div className="step-index active">
                {this.props.regions.get(this.props.selectedRegionIndex).get('sub-categories').size >
                1
                  ? '4'
                  : '3'}
              </div>
            )}
          </div>
          <div className="step-content">
            <p className="step-title">
              {progress.selected === progress.total ? 'All SKUs selected' : 'Select matching SKUs'}
              {progress.selected === progress.total && !progress.canUpdate ? (
                <span
                  className="pull-right clear-link"
                  onClick={() => this.props.setSelectionActive()}
                >
                  change
                </span>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

NewProductSelection.propTypes = {
  productsForSelect: PropTypes.array.isRequired,
  regions: PropTypes.instanceOf(List),
  selectedProduct: PropTypes.any,
  selectedProductsForSelect: PropTypes.array,
  selectedRegionIndex: PropTypes.any,
  selectProduct: PropTypes.func,
  selectRegionIndex: PropTypes.func,
  selectSubCategoryIndex: PropTypes.func,
  selectedSubCategory: PropTypes.instanceOf(Map),
  subCategoriesForSelect: PropTypes.array,
  setRegionsToActive: PropTypes.func,
  setSubCategoriesToActive: PropTypes.func,
  setSelectionActive: PropTypes.func,
  regionsActive: PropTypes.bool,
  subCategoriesActive: PropTypes.bool,
  canGoNext: PropTypes.bool,
  providerPath: PropTypes.string,
  deselectLastSKU: PropTypes.func,
  initialStepIndex: PropTypes.number.isRequired,
  marginLeft: PropTypes.number,
  continueButtonLabel: PropTypes.string
};

export default NewProductSelection;
