import ILService from './services/data/il.service';

export default class ImagePreviewOptions {

  width: number;
  height: number;
  maximizedWidth: number;
  maximizedHeight: number;
  container: string;
  domContainer: any;
  // what to render
  template: any;
  il: any;
  // fill overlay frame mask with it
  backgroundColor: string;
  backgroundImageUrl: string;
  backgroundImage: any;

  // show product|print preview
  preview: string;

  constructor(config: any) {
    config = config || {};
    this.width = config.width || 300;
    this.height = config.height || 500;
    this.maximizedWidth = config.maximizedWidth || 700;
    this.maximizedHeight = config.maximizedHeight || 1000;
    this.backgroundColor = config.backgroundColor || '#FFFFFF';
    // real image size can be provided here
    // will prevent issues with small preview generation
    // when resized images passed in il
    this.imageSources = config.imageSources;
    this.preview = config.preview || 'product';

    this.onRedraw = config.onRedraw || (() => {});
    this.onError = config.onError || (() => {});

    if (config.container) {
      this.container = config.container;
    }
    else {
      throw 'Gooten Image Preview: "container" is required in configuration';
    }

    if (config.template) {
      this.template = {
        ...config.template,
        layers : config.template.layers.filter(l => l.type !== 'bleed')
      };
      //Moved from image loading step
      for (let layer of this.template.layers) {
        // template image is not always correct sizes
        // take correct sizes from coordiantes
        layer.width = layer.x2 - layer.x1;
        layer.height = layer.y2 - layer.y1;
      }

    }
    else {
      throw 'Gooten Image Preview: "template" is required in configuration';
    }

    if (config.il) {
      this.images = ILService.importIL(config.il);
    }
    else {
      throw 'Gooten Image Preview: "il" is required in configuration';
    }
  }

  setBackgroundColor(colorHex: string) {
    this.backgroundColor = colorHex;
  }

}
