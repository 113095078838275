import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import HubProductsListItem from './HubProductsListItem/HubProductsListItem';
import HubProductProductType from '../../HubProductsList/HubProductType';

export const HubProductsList = props => {
  const selectedItems = props.selectedItems;
  const editDesign = props.editDesign;

  return (
    <div>
      {props.hubProducts.map(productData => {
        return (
          <div className="hub-products-list pb-3 mb-3" key={productData.id}>
            <HubProductProductType
              key={productData.id}
              showSelector
              product={productData}
              selectedItems={selectedItems}
              onHubProductSelectionToggled={props.onHubProductSelectionToggled}
            />
            {productData.variants.map(hubProduct => (
              <HubProductsListItem
                key={hubProduct.id}
                hubProduct={hubProduct}
                showSelector
                isSelected={!!selectedItems.find(i => i.id === hubProduct.id)}
                parentProductData={productData}
                onHubProductSelectionToggled={props.onHubProductSelectionToggled}
                editDesign={hubProduct => editDesign(hubProduct)}
              />
            ))}
          </div>
        );
      })}
      <style jsx>
        {`
          :global(.table-cell) {
            box-shadow: none !important;
          }
          .hub-products-list {
            border-bottom: 1px solid #ededed;
          }
        `}
      </style>
    </div>
  );
};

HubProductsList.propTypes = {
  hubProducts: PropTypes.array,
  selectedItems: PropTypes.instanceOf(List),
  onHubProductSelectionToggled: PropTypes.func.isRequired,
  editDesign: PropTypes.func.isRequired
};

export default HubProductsList;
