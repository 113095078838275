import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { COLORS } from '../../../constants';
import DropDownContainer from '../../shared/DropDown/DropDownContainer';
import ListItem from '../../shared/ListItem';

const ShowPaymentMethod = ({ icon, info, isDefault, token, setDefault, removeMethod }) => {
  const actions = useMemo(() => {
    const items = [];

    if (!isDefault) {
      items.push(
        <ListItem
          leftIcon="checkCircle"
          key="default"
          text="Set as Primary"
          onSelect={() => setDefault(token)}
        />
      );
    }

    return [
      ...items,
      <ListItem key="remove" text="Remove" leftIcon="trash" onSelect={() => removeMethod(token)} />
    ];
  }, [isDefault]);

  return (
    <div className="show-method p-3 w-100 mb-2 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center icon mr-3">{icon}</div>
        <div className="middle">{info}</div>
      </div>
      <DropDownContainer title="Actions" buttonOnly rightAlign>
        {actions.map(action => action)}
      </DropDownContainer>
      <style jsx>{`
        .show-method {
          background-color: ${COLORS.gootenBlue200};
          border: 1px solid ${COLORS.gootenBlue500};
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
};

ShowPaymentMethod.propTypes = {
  icon: PropTypes.element.isRequired,
  info: PropTypes.element.isRequired,
  isDefault: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  setDefault: PropTypes.func.isRequired,
  removeMethod: PropTypes.func.isRequired,
  editMethod: PropTypes.func
};

export default ShowPaymentMethod;
