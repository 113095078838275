import { connect } from 'react-redux';
import NavManager from './NavManager';
import { init, goto } from './NavManagerActions';
import { hasStepsSelector, currentStepSelector } from './NavManagerSelectors';

const mapDispatchToProps = {
  init,
  goto
};

const mapStateToProps = (state, props) => {
  let initDone = hasStepsSelector(state) > 0;

  return {
    ...props,
    initDone,
    currentStep: currentStepSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavManager);
