import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import throttle from 'gooten-components/src/utils/throttle';
import ProductsGrid from './ProductsGrid';
import ProductsList from './ProductsList';
import Loader from 'gooten-components/src/components/shared/Loader';
import Pager from 'gooten-components/src/components/shared/Pager';
import ConfirmNavigationModal from 'gooten-components/src/components/shared/ConfirmationModal/ConfirmNavigationModal';

import SimpleNotification from './../../../LinkProduct/shared/SimpleNotification';
import AllEmptyLanding from './EmptyLanding/AllEmptyLanding';
import StorageEmptyLanding from './EmptyLanding/StorageEmptyLanding';
import StoreEmptyLanding from './EmptyLanding/StoreEmptyLanding';
import TaskNotification from './TaskNotification';
import analyticsService from 'gooten-components/src/services/analyticsService';
/*eslint-disable */
import EmptySearchResult from 'gooten-components/src/components/ProductSelection/atoms/ProductList/EmptySearchResult';
/* eslint-enable */
import observer from './../../../../utils/NavigationPanelObserver';
import { COLORS } from 'gooten-components/src/constants';

import './Products.scss';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';
import OrderSampleModal from './OrderSampleModal';
import Config from 'gooten-components/src/config';
import KnowledgeBaseInfo from 'gooten-components/src/components/shared/KnowledgeBaseInfo';
import {
  IMAGE_TRANSPARENCY_KB_TEXT,
  IMAGE_TRANSPARENCY_KB_LINK,
  IMAGE_TRANSPARENCY_KB_LINK_FUJI,
  IMAGE_TRANSPARENCY_KB_LINK_SENSARIA
} from 'gooten-components/src/constants';

class Products extends React.PureComponent {
  static propTypes = {
    selectedProducts: PropTypes.instanceOf(List).isRequired,
    pendingProducts: PropTypes.instanceOf(List).isRequired,
    display: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    currentPageSize: PropTypes.number.isRequired,
    totalPagesCount: PropTypes.number.isRequired,
    productsQuery: PropTypes.instanceOf(Map).isRequired,
    productsQueryData: PropTypes.instanceOf(Map).isRequired,
    router: PropTypes.object,
    isUpdating: PropTypes.bool,
    loadProductsQuery: PropTypes.func.isRequired,
    changeViewBy: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changePageSize: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    selectProductById: PropTypes.func.isRequired,
    selectExclusive: PropTypes.func.isRequired,
    storeToCheckLink: PropTypes.number,
    refreshStoreData: PropTypes.func.isRequired,
    unselectProduct: PropTypes.func.isRequired,
    unselectAll: PropTypes.func.isRequired,
    unpublishProducts: PropTypes.func.isRequired,
    publishProducts: PropTypes.func.isRequired,
    deleteProducts: PropTypes.func.isRequired,
    unlinkProducts: PropTypes.func.isRequired,
    currentProvider: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    currentQueryProducts: PropTypes.instanceOf(List).isRequired,
    fetchShopifyTokenScopes: PropTypes.func.isRequired,
    shopifyRedirectRequiredStores: PropTypes.Array
  };

  state = {
    isMobile: false
  };

  constructor(props) {
    super(props);
    this.onResize = throttle(this.onResize.bind(this), 300);
    this.selectProduct = this.selectProduct.bind(this);
    this.selectExclusive = this.selectExclusive.bind(this);

    this.state = {
      marginLeft: observer.marginLeft(),
      showDeleteModal: false,
      confirmNavigationalModalDismissed: false
    };
  }

  isLoaded = () => this.props.productsQueryData.get('loaded');

  isEmpty = () => this.props.productsQueryData.get('data').get('items').size === 0;

  isStorage = () => this.props.productsQuery.get('provider') === 'storage';

  isAll = () => this.props.productsQuery.get('provider') === 'all';

  searchValue = () => this.props.productsQuery.get('search');

  sortBy = () => this.props.productsQuery.get('sortBy');

  viewBy = () => this.props.productsQuery.get('viewBy');

  onResize() {
    if (this._ismounted) {
      this.setState({ isMobile: window.innerWidth <= 768 });
    }
  }

  componentDidMount() {
    this._ismounted = true;
    // navigation-panel size observer
    observer.connect(() => {
      this.setState({ marginLeft: observer.marginLeft() });
    });

    // initiate products query loading
    if (!this.isLoaded()) {
      this.props.loadProductsQuery(this.props.productsQuery);
      this.props.fetchShopifyTokenScopes();
    }

    // update pageSize from URL query
    if (this.props.router.location.query.pageSize) {
      const pageSizeNum = parseInt(this.props.router.location.query.pageSize);
      if (pageSizeNum && pageSizeNum !== this.props.currentPageSize) {
        this.props.changePageSize(pageSizeNum);
      }
    }

    // update page from URL query
    if (this.props.router.location.query.page) {
      const pageNum = parseInt(this.props.router.location.query.page);
      if (pageNum && pageNum !== this.props.currentPage) {
        this.props.changePage(pageNum);
      }
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    this._ismounted = false;
    observer.disconnect();
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    // initiate products query loading
    if (!this.isLoaded()) {
      this.props.loadProductsQuery(this.props.productsQuery);
    }
    if (this.props.storeToCheckLink) {
      this.props.refreshStoreData(this.props.storeToCheckLink);
    }
  }

  onPageChange = page => {
    this.props.changePage(page);
    // Push paging to URL query
    this.props.router.push({
      ...this.props.router.location,
      query: {
        ...this.props.router.location.query,
        page,
        pageSize: this.props.currentPageSize
      }
    });
  };

  selectProduct(product) {
    analyticsService.track('Hub - Page', 'Product Selected', 'Hub', null, {
      page: this.props.router.location.pathname,
      id: product.get('id'),
      name: product.get('name'),
      type: product.get('type'),
      storeName: product.get('storeName')
    });
    this.props.selectProduct(product);
  }

  selectExclusive(product) {
    analyticsService.track('Hub - Page', 'Product Selected', 'Hub', null, {
      page: this.props.router.location.pathname,
      id: product.get('id'),
      name: product.get('name'),
      type: product.get('type'),
      storeName: product.get('storeName')
    });
    this.props.selectExclusive(product);
  }

  categoryLandingsFactory = (category, showTitle) => {
    return {
      title: showTitle ? category : null,
      go: () => {
        if (this.props.productsQuery.get('viewBy') !== category) {
          this.props.changeViewBy(category);
          // Push viewBy to URL query
          this.props.router.push({
            ...this.props.router.location,
            query: {
              ...this.props.router.location.query,
              viewBy: category,
              page: 1,
              pageSize: this.props.currentPageSize,
              sortBy: this.props.productsQuery.get('sortBy')
            }
          });
        }
      }
    };
  };

  onOptionSelect(action) {
    analyticsService.track('Hub - Page', action, 'Hub', null, {
      page: this.props.router.location.pathname
    });
  }

  deleteProducts(products) {
    this.onOptionSelect('Delete product started');
    this.setState({ showDeleteModal: true, selectedProducts: products });
  }

  handleSelectModalOption = (prodId, option) => {
    // click on option in ProductGridItem, single product.
    let selectedProducts = this.props.selectedProducts;

    if (
      option === 'unpublish' ||
      option === 'publish' ||
      option === 'delete' ||
      option === 'stopfulfillment'
    ) {
      selectedProducts = new List([
        this.props.currentQueryProducts.find(item => item.get('id') === prodId)
      ]);
    } else {
      this.props.unselectAll();
      this.props.selectProductById(prodId);
    }

    switch (option) {
      case 'placeanorder':
        analyticsService.track('Hub - Place an Order', 'Place an order', 'Hub');
        this.props.router.push('/hub/place-order');
        break;
      case 'edit':
        analyticsService.track('Hub - Place an Order', 'Edit', 'Hub');
        this.props.router.push('/hub/edit-product/start');
        break;
      case 'duplicate':
        analyticsService.track('Hub - Place an Order', 'Duplicate', 'Hub');
        this.props.router.push('/hub/duplicate-product/start');
        break;
      case 'unpublish':
        analyticsService.track('Hub - Place an Order', 'Unpublish', 'Hub');
        this.props.unpublishProducts(selectedProducts);
        break;
      case 'publish':
        analyticsService.track('Hub - Place an Order', 'Publish', 'Hub');
        this.props.publishProducts(selectedProducts);
        break;
      case 'delete':
        analyticsService.track('Hub - Place an Order', 'Delete', 'Hub');
        this.deleteProducts(selectedProducts);
        break;
      case 'stopfulfillment':
        analyticsService.track('Hub - Place an Order', 'Stop fulfillment', 'Hub');
        this.props.unlinkProducts(selectedProducts);
        break;
    }
  };

  renderProductsCategory(items) {
    // NOTE: Show pending products only in All provider
    const pendingProducts = this.isAll() ? this.props.pendingProducts : new List();
    return items.size ? (
      <div>
        {this.props.display === 'grid' && !this.state.isMobile ? (
          <ProductsGrid
            products={items}
            pendingProducts={pendingProducts}
            selected={this.props.selectedProducts}
            select={this.selectProduct}
            unselect={this.props.unselectProduct}
            selectExclusive={this.selectExclusive}
            onSelectModalOption={this.handleSelectModalOption}
            currentProvider={this.props.currentProvider}
          />
        ) : (
          <ProductsList
            products={items}
            pendingProducts={pendingProducts}
            selected={this.props.selectedProducts}
            select={this.selectProduct}
            unselect={this.props.unselectProduct}
            selectExclusive={this.selectExclusive}
            isAll={this.isAll()}
            onSelectModalOption={this.handleSelectModalOption}
            currentProvider={this.props.currentProvider}
          />
        )}
      </div>
    ) : null;
  }

  renderProducts() {
    const items = this.props.productsQueryData.get('data').get('items');
    return this.renderProductsCategory(items);
  }

  renderProductsNotFound() {
    return (
      <EmptySearchResult>
        <p>Try searching for the specific title of your product.</p>
        <style jsx>{`
          color: ${COLORS.grey};
          font-size: 1.125rem;
        `}</style>
      </EmptySearchResult>
    );
  }

  renderCollectionIsEmpty() {
    return (
      <EmptySearchResult>
        <p>The collection you selected doesn't have any synced products.</p>
        <p>Try to select different collection.</p>
        <style jsx>{`
          color: ${COLORS.grey};
          font-size: 1.125rem;
        `}</style>
      </EmptySearchResult>
    );
  }

  renderPageSwitcher() {
    const pages = Array.from(Array(this.props.totalPagesCount).keys()).map(i => ({
      name: i + 1,
      index: i + 1
    }));

    return (
      !!pages.length && (
        <div className="text-center">
          <Pager
            current={this.props.currentPage}
            items={pages}
            goTo={index => this.onPageChange(index)}
          />
          <style jsx>
            {`
              @media screen and (max-width: 767px) {
                :global(.component-pager) {
                  margin-bottom: 6rem !important;
                }
              }
            `}
          </style>
        </div>
      )
    );
  }

  formatedProvider() {
    const provider = this.props.productsQuery.get('provider');

    switch (provider) {
      case 'woocommerce':
        return 'WooCommerce';
      case 'bigcommerce':
        return 'BigCommerce';
      case 'tiktok':
        return 'TikTok';
      default:
        return provider[0].toUpperCase() + provider.slice(1).toLowerCase();
    }
  }

  topNotification() {
    return (
      <div>
        {this.props.notification.get('show') && (
          <SimpleNotification
            showCloseButton
            title={`You synced ${this.props.notification.get('message')} successfully!`}
            duration={5000}
            onDurationEnd={() => this.props.showNotification({ show: false, message: '' })}
          />
        )}
      </div>
    );
  }

  getImageTransparencyKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return IMAGE_TRANSPARENCY_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return IMAGE_TRANSPARENCY_KB_LINK_FUJI;
      case 2:
        return IMAGE_TRANSPARENCY_KB_LINK_SENSARIA;
      default:
        return IMAGE_TRANSPARENCY_KB_LINK;
    }
  };

  closeConfirmNavigationModal = () => {
    this.setState({
      confirmNavigationalModalDismissed: true
    });
  };

  render() {
    return !this.isLoaded() ? (
      <Loader />
    ) : this.isEmpty() ? (
      this.searchValue() ? (
        this.renderProductsNotFound()
      ) : this.isStorage() ? (
        <StorageEmptyLanding />
      ) : this.viewBy() ? (
        this.renderCollectionIsEmpty()
      ) : this.isAll() ? (
        <AllEmptyLanding />
      ) : (
        <StoreEmptyLanding integration={this.formatedProvider()} />
      )
    ) : (
      <div className="products-container">
        <TaskNotification />
        <OrderSampleModal router={this.props.router} />
        <ConfirmNavigationModal
          isOpen={
            this.props.shopifyRedirectRequiredStores &&
            this.props.shopifyRedirectRequiredStores.length > 0 &&
            !this.state.confirmNavigationalModalDismissed
          }
          url={this.props.shopifyRedirectRequiredStores[0]}
          hideModal={this.closeConfirmNavigationModal}
        />
        {this.topNotification()}
        {this.renderProducts()}
        {this.renderPageSwitcher()}
        {this.props.isUpdating ? <Loader /> : null}
        <Dialogue
          isOpen={this.state.showDeleteModal}
          cancelClick={() => {
            this.setState({ showDeleteModal: false });
          }}
          destructiveClick={() => {
            this.props.deleteProducts(this.state.selectedProducts || new List([]));
            this.setState({ showDeleteModal: false });
          }}
          actionText="Delete"
          title="Delete Product"
        >
          {this.props.selectedProducts.size > 1 ? (
            // eslint-disable-next-line no-multi-str
            'Deleting these products will remove it from Gooten and all connected sales channels. This action cannot \
            be undone'
          ) : (
            <span>
              Deleting&nbsp;
              {this.state.selectedProducts && Array.from(this.state.selectedProducts) ? (
                <b>{Array.from(this.state.selectedProducts)[0].get('name')}</b>
              ) : (
                'this item'
              )}
              &nbsp;will remove it from Gooten and all connected sales channels. This action cannot
              be undone.
            </span>
          )}
        </Dialogue>
        <KnowledgeBaseInfo
          kbArticles={[
            {
              visibleText: IMAGE_TRANSPARENCY_KB_TEXT,
              link: this.getImageTransparencyKbLink()
            }
          ]}
        />
      </div>
    );
  }
}

export default Products;
