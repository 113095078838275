import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { COLORS, FILTER_COUNTRIES } from '../../../constants';
import Loader from '../../shared/Loader';
import Input from '../../shared/Input';
import Select from '../../shared/Select';
import Checkbox from '../../shared/Checkbox';
import adminApi from '../../../services/adminApiService';
import Config from '../../../config';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import ErrorField from '../ErrorField';
import Alert from '../Alert';

const gootenFormStyle = {
  style: {
    base: {
      fontFamily: 'Avenir, sans-serif',
      fontWeight: '400',
      iconColor: COLORS.gootenBlue,
      fontSize: '16px',
      color: COLORS.neutralDark200,
      '::placeholder': {
        color: COLORS.neutralDark200
      }
    },
    invalid: {
      color: COLORS.cayennePepper
    }
  }
};

const EditCreditCard = ({
  countries,
  sameAsAddress,
  loadingSetupIntentId,
  handleStripeResponse,
  paymentMethodCount,
  setParentLoading,
  showCreditCardModal,
  isOnCheckoutPage
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const reCaptchaRef = useRef();

  const handleRecaptchaResponse = async recaptchaToken => {
    return adminApi
      .post(`PaymentGateway/InitPaymentMethod?partnerId=${Config.get('partnerId')}`)
      .then(res => res);
  };

  const [sameBillingAddress, setSameBillingAddress] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          firstName: sameAsAddress ? sameAsAddress.FirstName || '' : '',
          lastName: sameAsAddress ? sameAsAddress.LastName || '' : '',
          address: sameAsAddress ? sameAsAddress.Line1 || '' : '',
          city: sameAsAddress ? sameAsAddress.City || '' : '',
          stateRegion: sameAsAddress ? sameAsAddress.State || '' : '',
          country: sameAsAddress ? sameAsAddress.CountryCode || '' : '',
          zipPostalCode: sameAsAddress ? sameAsAddress.PostalCode || '' : '',
          primaryPaymentMethod: paymentMethodCount === 0 ? true : false,
          captchaToken: '',
          setupIntentId: '',
          clientSecret: ''
        }}
        validationSchema={() =>
          object().shape({
            firstName: string()
              .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid First Name') // name
              .matches(/[\x00-\x7F]+/, 'Enter a valid First Name') // onlyEnglish
              .required('Enter a valid First Name'), // required
            lastName: string()
              .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid Last Name') // name
              .matches(/[\x00-\x7F]+/, 'Enter a valid Last Name') // onlyEnglish
              .required('Enter a valid Last Name'), // required
            address: string()
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
              .matches(/[\x00-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
              .required('Enter a valid Address 1'), // required
            city: string()
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
              .matches(/[\x00-\x7F]+/, 'Enter a valid City') // onlyEnglish
              .required('Enter a valid City'), // required
            stateRegion: string()
              .when('country', {
                is: country => {
                  if (country) {
                    return ['CA', 'US'].includes(country);
                  }
                  return false;
                },
                then: string().required('Enter a valid State/Region')
              }) // required if country is CA or US
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Region') // shippingRequiredUSandCA
              .matches(/[\x00-\x7F]+/, 'Enter a valid State/Region'), // onlyEnglish
            country: string().required('Enter a Country'), // required
            zipPostalCode: string().when('country', {
              is: country => country === 'US',
              then: string()
                .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                .required('Enter a valid zip code'),
              otherwise: string().when('country', {
                is: country => country === 'CA',
                then: string()
                  .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                  .required('Enter a valid postal code'),
                otherwise: string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
              })
            }),
            captchaToken: string().required('Please confirm that you are not a robot'), // required
            setupIntentId: string().required(), // required
            clientSecret: string().required() // required
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          elements.getElement(CardNumberElement).update({ disabled: true });
          elements.getElement(CardExpiryElement).update({ disabled: true });
          elements.getElement(CardCvcElement).update({ disabled: true });

          setParentLoading(true);

          await stripe
            .confirmCardSetup(values.clientSecret, {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  address: {
                    city: values.city,
                    line1: values.address,
                    postal_code: values.zipPostalCode,
                    state: values.stateRegion,
                    country: (countryCode => {
                      switch (countryCode) {
                        case 'UK':
                          return 'GB'; // stripe doesn't support UK, so if found replace with GB
                        case 'UI':
                          return 'VC'; // same with Union Island
                        default:
                          return countryCode;
                      }
                    })(values.country)
                  },
                  name: `${values.firstName} ${values.lastName}`
                }
              }
            })
            .then(result => {
              if (!result.error) {
                handleStripeResponse({
                  result,
                  primaryPaymentMethod: values.primaryPaymentMethod,
                  captchaToken: values.captchaToken
                });
                showCreditCardModal(false);
              } else {
                reCaptchaRef.current.reset();
                values.captchaToken = '';
                setSubmitting(false);
                setParentLoading(false);
              }
            })
            .catch(err => {
              console.log(err);
              reCaptchaRef.current.reset();
              values.captchaToken = '';
              setSubmitting(false);
              setParentLoading(false);
            })
            .finally(() => {
              setSubmitting(false);
              setParentLoading(false);
              elements.getElement(CardNumberElement).update({ disabled: false });
              elements.getElement(CardExpiryElement).update({ disabled: false });
              elements.getElement(CardCvcElement).update({ disabled: false });
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          submitCount,
          isSubmitting
        }) => (
          <>
            {loadingSetupIntentId ? (
              <div className="pt-5 pb-5">
                <Loader />
              </div>
            ) : (
              <form id="edit-credit-card-form" onSubmit={handleSubmit} className="mt-4">
                <div className="edit-credit-card">
                  <div className="overline mb-3">CARD INFORMATION</div>
                  <div className="stripe-container">
                    <div className="stripe-field card-number">
                      <CardNumberElement options={gootenFormStyle} />
                      <div className="error-display">
                        <ErrorField>Invalid card number</ErrorField>
                      </div>
                    </div>
                    <div className="d-flex exp-cvc">
                      <div className="stripe-field card-expiry mr-3 w-100">
                        <CardExpiryElement options={gootenFormStyle} />
                        <div className="error-display">
                          <ErrorField>Invalid expiration date</ErrorField>
                        </div>
                      </div>
                      <div className="stripe-field card-cvc w-100">
                        <CardCvcElement options={gootenFormStyle} />
                        <div className="error-display">
                          <ErrorField>Invalid CVC number</ErrorField>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!sameBillingAddress && (
                    <>
                      <div className="overline mb-3">BILLING ADDRESS</div>
                      <div className="firstLastName d-flex">
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          className="mr-3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          disabled={isSubmitting}
                          hasError={submitCount > 0 && !!errors.firstName}
                          errorText={
                            submitCount > 0 && !!errors.firstName ? errors.firstName : null
                          }
                        />
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          disabled={isSubmitting}
                          hasError={submitCount > 0 && !!errors.lastName}
                          errorText={submitCount > 0 && !!errors.lastName ? errors.lastName : null}
                        />
                      </div>
                      <Input
                        name="address"
                        type="text"
                        placeholder="Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        disabled={isSubmitting}
                        hasError={submitCount > 0 && !!errors.address}
                        errorTex
                        t={submitCount > 0 && !!errors.address ? errors.address : null}
                      />

                      <div className="firstLastName d-flex">
                        <Input
                          name="city"
                          type="text"
                          placeholder="City"
                          className="mr-3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          disabled={isSubmitting}
                          hasError={submitCount > 0 && !!errors.city}
                          errorText={submitCount > 0 && !!errors.city ? errors.city : null}
                        />
                        <Input
                          name="stateRegion"
                          type="text"
                          placeholder="State/Region"
                          className="mr-3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.stateRegion}
                          disabled={isSubmitting}
                          hasError={submitCount > 0 && !!errors.stateRegion}
                          errorText={
                            submitCount > 0 && !!errors.stateRegion ? errors.stateRegion : null
                          }
                        />
                        <Input
                          name="zipPostalCode"
                          type="text"
                          placeholder="Zip/Postal Code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zipPostalCode}
                          disabled={isSubmitting}
                          hasError={submitCount > 0 && !!errors.zipPostalCode}
                          errorText={
                            submitCount > 0 && !!errors.zipPostalCode ? errors.zipPostalCode : null
                          }
                        />
                      </div>
                      <Select
                        name="country"
                        label={'Country'}
                        disabled={isSubmitting}
                        placeholder="Select a Country"
                        onChange={handleChange}
                        value={values.country || ''}
                        hasError={submitCount > 0 && !!errors.country}
                        errorText={submitCount > 0 && !!errors.country ? errors.country : null}
                      >
                        {countries
                          .filter(country => !FILTER_COUNTRIES.includes(country.label))
                          .map(country => (
                            <option key={country.key} value={country.key}>
                              {country.label}
                            </option>
                          ))}
                      </Select>
                    </>
                  )}
                  <Checkbox
                    name="primaryPaymentMethod"
                    className="mb-4"
                    checked={paymentMethodCount === 0 ? true : values.primaryPaymentMethod}
                    disabled={paymentMethodCount === 0 || isSubmitting}
                    label={`Primary payment method`}
                    onChange={() =>
                      setFieldValue('primaryPaymentMethod', !values.primaryPaymentMethod)
                    }
                  />
                  {isOnCheckoutPage && (
                    <Checkbox
                      name="setSameBillingAddress"
                      className="mb-4"
                      checked={sameBillingAddress}
                      disabled={!sameAsAddress || isSubmitting}
                      label={`Billing address is same as shipping address`}
                      onChange={() => {
                        setSameBillingAddress(!sameBillingAddress);
                        if (!sameBillingAddress) {
                          values.firstName = sameAsAddress.firstName;
                          values.lastName = sameAsAddress.lastName;
                          values.address = `${sameAsAddress.addressLine1}${
                            sameAsAddress.addressLine2 ? ', ' + sameAsAddress.addressLine2 : ''
                          }`;
                          values.city = sameAsAddress.city;
                          values.stateRegion = sameAsAddress.state;
                          values.country = sameAsAddress.country;
                          values.zipPostalCode = sameAsAddress.zipCode;
                        }
                      }}
                    />
                  )}
                  {!errors.captchaToken && errors.setupIntentId && errors.clientSecret && (
                    <Alert isOpen type="important" className="mb-2">
                      Failed to load stripe id
                    </Alert>
                  )}
                  {errors.captchaToken && (
                    <ErrorField className="mb-2"> {errors.captchaToken}</ErrorField>
                  )}
                  <ReCAPTCHA
                    sitekey={
                      Config.get('env') === 'staging'
                        ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // staging key
                        : '6LddGPUZAAAAABzQtPIEygB83xnjdSv0c9YgWr63'
                    } // production key
                    onChange={async value => {
                      const resp = await handleRecaptchaResponse(value);
                      setFieldValue('captchaToken', value);
                      setFieldValue('setupIntentId', resp['SetupIntentId']);
                      setFieldValue('clientSecret', resp['ClientId']);
                    }}
                    onExpired={() => setFieldValue('captchaToken', '')}
                    onErrored={() => setFieldValue('captchaToken', '')}
                    ref={reCaptchaRef}
                  />
                </div>
              </form>
            )}
          </>
        )}
      </Formik>
      <style jsx>{`
        :global(.StripeElement) {
          border-radius: 4px;
          padding: 1.25rem 1rem 0.25rem;
          border: 1px solid ${COLORS.neutralLight400};
          margin-bottom: 2rem;
          box-sizing: border-box;
          height: 3rem;
          outline: none;
          cursor: text;
        }
        :global(.StripeElement--invalid) {
          border: 1px solid ${COLORS.cayennePepper};
        }
        :global(.StripeElement + .error-display) {
          display: none;
          position: absolute;
          top: 3.25rem;
        }
        :global(.StripeElement--invalid + .error-display) {
          display: block;
        }
        :global(.StripeElement--focus) {
          border: 1px solid ${COLORS.gootenBlue};
        }
        .stripe-field {
          position: relative;
        }
        // Hide initial display of placeholders
        :global(.StripeElement:before) {
          transition-duration: 0.3s;
        }
        :global(.StripeElement--empty:before) {
          content: '';
          position: absolute;
          background: white;
          top: 4px;
          left: 4px;
          width: 90%;
          height: 2.5rem;
          z-index: 1;
          pointer-events: none;
        }
        :global(.StripeElement--focus:before) {
          display: none;
        }
        // Floating Labels
        :global(.StripeElement:after, .StripeElement--focus:after) {
          position: absolute;
          top: 4px;
          font-size: 13px;
          font-weight: 700;
          color: ${COLORS.neutralDark200};
          transition: all 150ms cubic-bezier(0.6, 0, 0.3, 1);
          z-index: 2;
          font-family: Avenir Heavy, sans-serif;
        }
        :global(.StripeElement--empty:after) {
          top: 12px;
          font-size: 16px;
        }
        :global(.StripeElement--focus:after) {
          top: 4px;
          font-size: 13px;
          color: ${COLORS.gootenBlue};
        }
        :global(.StripeElement--invalid:after) {
          color: ${COLORS.cayennePepper};
        }
        :global(.card-number .StripeElement:after) {
          content: 'Card Number';
        }
        :global(.card-expiry .StripeElement:after) {
          content: 'Expiration';
        }
        :global(.card-cvc .StripeElement:after) {
          content: 'CVC';
        }
        .caption-text {
          color: ${COLORS.cayennePepper};
        }
      `}</style>
    </>
  );
};

EditCreditCard.propTypes = {
  countries: PropTypes.array.isRequired,
  sameAsAddress: PropTypes.object,
  cancelEdit: PropTypes.func,
  getSetupIntentId: PropTypes.func.isRequired,
  loadingSetupIntentId: PropTypes.bool.isRequired,
  setupIntentId: PropTypes.string.isRequired,
  clientSecret: PropTypes.string.isRequired,
  handleStripeResponse: PropTypes.func.isRequired,
  stripeFormBusy: PropTypes.bool.isRequired,
  paymentMethodCount: PropTypes.number.isRequired,
  setParentLoading: PropTypes.func.isRequired,
  showCreditCardModal: PropTypes.func.isRequired,
  isOnCheckoutPage: PropTypes.bool.isRequired
};

export default EditCreditCard;
