import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HubSKUsListItem from './HubSKUsListItem';
import Multiselect from 'gooten-components/src/components/shared/Multiselect';
import Pager from 'gooten-components/src/components/shared/Pager';
import SimpleNotification from '../../../../shared/SimpleNotification';
import skusListService from './../services/skusListService';

import { List } from 'immutable';
import { Cell, TableRow } from 'gooten-components/src/components/shared/Table';

const multiselectConfig = {
  multiple: true,
  showFilter: true,
  showBulkActions: false
};

export class HubSKUsList extends Component {
  select = sku => {
    this.props.select(sku, this.props.selectedOptionsLabel);
    this.props.next();
  };

  render() {
    const filteredAllVariants = this.props.pageVariants.filter(item => item.get('sectionAll'));
    const filteredSuggestedMatches = this.props.pageVariants.filter(item =>
      item.get('sectionSuggested')
    );
    const columnsLength =
      this.props.options && this.props.options.size
        ? this.props.options
            .map(o => {
              return '1fr';
            })
            .join(' ')
            .toString()
        : '1fr';

    // The logic below makes sure that dropdown options are available even when incompatible options are picked.
    // e.g. when bandanas are selected as SQUARE and 25x18in, this would not work.
    // The < 8 logic is so that header row does not show up alone for large lists
    const suggestedMatches =
      this.props.suggestedVariants.size && filteredAllVariants.size < 8 ? (
        <Fragment>
          {this.renderSection('SUGGESTED MATCHES')}

          {/* desktop view */}
          <div className="hidden-sm hidden-xs">
            <TableRow size="small" columns={`6rem ${columnsLength} 8rem`}>
              {this.renderHead(this.props.disableFiltering, true)}
              {this.renderBody(filteredSuggestedMatches)}
            </TableRow>
          </div>
          {/* mobile view */}
          <div className="visible-sm visible-xs">
            <TableRow size="small" columns={`1fr 1fr`}>
              {this.renderHead(this.props.disableFiltering, true)}
            </TableRow>
            <TableRow size="auto" columns={`6rem 1fr`}>
              {this.renderBody(filteredSuggestedMatches)}
            </TableRow>
          </div>
        </Fragment>
      ) : null;

    const allVariants =
      this.props.allVariants.size && filteredSuggestedMatches.size < 8 ? (
        <Fragment>
          {this.renderSection('ALL VARIANTS')}

          {/* desktop view */}
          <div className="hidden-sm hidden-xs">
            <TableRow size="small" columns={`6rem ${columnsLength} minmax(8rem, auto)`}>
              {this.renderHead()}
              {this.renderBody(filteredAllVariants)}
            </TableRow>
          </div>

          {/* mobile view */}
          <div className="visible-sm visible-xs">
            <TableRow size="small" columns={`1fr 1fr`}>
              {this.renderHead()}
            </TableRow>
            <TableRow size="auto" columns={`6rem 1fr`}>
              {this.renderBody(filteredAllVariants)}
            </TableRow>
          </div>
        </Fragment>
      ) : null;

    return (
      <div className="skus-list-container">
        <div className="col-sm-12 col-xs-12 table-container mb-4">
          <div>
            {suggestedMatches}
            {allVariants}
          </div>
          {this.props.mappingProgress.selected === this.props.mappingProgress.total &&
          !this.props.mappingProgress.canUpdate ? (
            <div className="shaddow" />
          ) : null}
          {this.renderPager()}
        </div>
        {this.renderNotification()}
        <style jsx>
          {`
            :global(.selection-column.header-cell) {
              padding: 0.25rem 0.125rem 0.5rem 0.125rem !important;
            }
          `}
        </style>
      </div>
    );
  }

  renderSection(title) {
    return <div className="title-2 mt-4 mb-4">{title}</div>;
  }

  onOptionValuesSelect(o, selection, isSuggested) {
    this.props.setOptionSelectedValues(
      o.get('id'),
      selection.map(s => ({ id: s.key, name: s.label, isSuggested })),
      isSuggested
    );
  }

  renderHead(disableFiltering = false, isSuggested = false) {
    const preparedOptions = skusListService.preparedOptionsFromVariants(
      isSuggested ? this.props.suggestedVariants : this.props.allVariants
    );
    const options = this.props.options.map(o => {
      // const values = o.get('values').map((v, i) => ({ key : i, label : v })).toJS()
      const values = skusListService.valuesForOption(o, preparedOptions, true);
      const selectedValues = o
        .get('selectedValues')
        .filter(sv => !!sv.get('isSuggested') === isSuggested)
        .map(v => ({ key: v.get('id'), label: v.get('name') }))
        .toJS();

      return disableFiltering ? (
        <Cell header className="selection-column no-multiselect" key={'h-' + o.get('id')}>
          <div className="col-xs-11">
            <b>{o.get('name')}</b>
          </div>
        </Cell>
      ) : (
        <Cell header className="selection-column" key={'h-' + o.get('id')}>
          <Multiselect
            title={o.get('name')}
            options={values}
            selectedOptions={selectedValues}
            onChange={s => this.onOptionValuesSelect(o, s, isSuggested)}
            config={multiselectConfig}
          />
        </Cell>
      );
    });

    return (
      <>
        <Cell header className="hidden-sm hidden-xs" />
        {!(this.props.options && this.props.options.size) && <Cell header />}
        {options}
        <Cell header className="price-column hidden-sm hidden-xs">
          <div className="col-xs-11">
            <b>Price</b>
          </div>
        </Cell>

        <style jsx>
          {`
            :global(.selection-column .multiselect-component) {
              width: 100%;
            }
          `}
        </style>
      </>
    );
  }

  renderBody(skus) {
    return (
      <>
        {skus.map(sku => (
          <HubSKUsListItem key={sku.get('sku')} item={sku} select={this.select} />
        ))}
      </>
    );
  }

  renderPager() {
    return (
      !!this.props.pages.length && (
        <div className="col-xs-12 text-center mt-4">
          <Pager
            current={this.props.page}
            items={this.props.pages}
            goTo={index => this.props.changePage(index)}
          />
        </div>
      )
    );
  }

  renderNotification() {
    return (
      <div>
        {this.props.skuNotification.get('show') && (
          <SimpleNotification
            title={`${this.props.skuNotification.get('value', '')} was successfully synced`}
            duration={2000}
            onDurationEnd={() => this.props.resetNotification()}
          />
        )}
      </div>
    );
  }
}

HubSKUsList.propTypes = {
  suggestedVariants: PropTypes.instanceOf(List).isRequired,
  allVariants: PropTypes.instanceOf(List).isRequired,
  pageVariants: PropTypes.instanceOf(List).isRequired,
  options: PropTypes.instanceOf(List).isRequired,
  setOptionSelectedValues: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.array.isRequired,
  changePage: PropTypes.func,
  select: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  skuNotification: PropTypes.object,
  resetNotification: PropTypes.func,
  mappingProgress: PropTypes.any,
  // marginLeft: PropTypes.number,
  disableFiltering: PropTypes.bool,
  selectedOptionsLabel: PropTypes.string
};

export default HubSKUsList;
