import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import OrderPanelLayout from '../../layouts/OrderPanelLayout';
import OrderSummary from '../shared/OrderSummary';
import AddressSelector from './atoms/AddressSelector';
import AddressItem from './atoms/AddressSelector/AddressItem';
import Payment from './atoms/Payment';
import ShippingMethod from './atoms/ShippingMethod';
import TermsSection from './atoms/TermsSection';
import Loader from '../shared/Loader';
import './Checkout.scss';
import Strings from './strings';
import analyticsService from '../../services/analyticsService';
import Icon from '../shared/Icon';
import Modal from '../shared/Modal';
import illustrationCartEmpty from '../../images/Illustration_Cart_Empty.png';
import Notifications from '../shared/Notifications';
import Toast from '../shared/Toast';
import { COLORS } from '../../constants';
import Alert from '../shared/Alert';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false
    };
  }

  componentDidMount() {
    analyticsService.trackWithConfigSource('Checkout Page Reached');
  }

  componentDidUpdate() {
    if (this.props.order && this.props.order.get('orderNumber')) {
      this.props.clearCart();
      this.goToMostRecentOrderPage();
    }
  }

  goToMostRecentOrderPage = () => {
    setTimeout(() => {
      if (
        this.props.sourceHistory === 'Hub - Place an Order' &&
        !window.location.href.includes('create-new')
      ) {
        window.location.hash = '#/hub/all';
      } else {
        window.location.hash = '#/custom-order-form';
      }
    }, 100);
  };

  handlePlaceOrder = () => {
    this.setState({
      showAlert: false
    });
    if (!this.props.canPlaceOrder && this.props.errorAlert.length > 0) {
      this.setState({
        showAlert: true
      });
    } else {
      this.props.placeOrder();
    }
  };

  render() {
    const itemOrItems = this.props.items.size === 1 ? Strings.ITEM : Strings.ITEMS;

    const totalQuantity = this.props.items.reduce(
      (totalQuantity, cartItem) => totalQuantity + cartItem.get('quantity'),
      0
    );

    const cartItemsCount = `(${totalQuantity} ${itemOrItems})`;

    const loader = (
      <div className="checkout-loader-container">
        <Loader />
      </div>
    );

    const secureCheckoutNote = (
      <div className="caption-text mt-2 d-flex align-items-center justify-content-center">
        <Icon icon="lock" height={20} width={20} className="mr-2 mb-1" />
        <span>Secure Checkout</span>
      </div>
    );

    return (
      <div className="checkout-container">
        <Notifications />
        <Modal
          title={"It's not you, it's us."}
          isOpen={!!(this.props.order && this.props.order.get('Errors'))}
          cancelText="Dismiss"
          className="medium"
          cancelClick={this.props.clearOrder}
        >
          <div className="d-flex">
            <div className="m-auto text-center p-4">
              <img src={illustrationCartEmpty} className="mb-3" />
              <br />
              There was an issue submitting your order. Please try again in 5-10 minutes
            </div>
          </div>
        </Modal>
        <OrderPanelLayout
          onNextButtonClick={this.handlePlaceOrder}
          nextButtonLabel={Strings.PLACE_ORDER}
          nextButtonHidden={this.props.items.isEmpty()}
          nextButtonDisabled={this.props.isLoading || !this.props.canPlaceOrder}
          after={secureCheckoutNote}
        >
          <div key="left-side-title">
            <div className="cart-items-header p pt-4 mt-2 pb-2">
              <div className="title-1 ml-2">{`${Strings.CHECKOUT} ${
                this.props.items.isEmpty() ? '' : cartItemsCount
              }`}</div>
            </div>
          </div>
          <div key="left-side">
            {this.props.isLoading ? loader : null}
            {this.props.errorAlert.length > 0 && this.state.showAlert && (
              <Alert isOpen type="important" className="w-auto">
                <p className="m-0 heavy">Please correct the following issues:</p>
                {this.props.errorAlert.map((x, key) => (
                  <div key={key}>&bull; &nbsp; {x}</div>
                ))}
              </Alert>
            )}
            <div className="card">
              <h4>Shipping Address</h4>
              <AddressSelector key="open" />
              {this.props.selectedShippingAddress ? (
                <div className="ml-2 pl-4 heavy selected-address">
                  <p className="title-3">SELECTED ADDRESS:</p>
                  <div className="body-text-2">
                    <AddressItem key="closed" address={this.props.selectedShippingAddress} />
                  </div>
                </div>
              ) : null}
            </div>

            <div className="card">
              <Payment
                title="Payment and Billing"
                selectedPaymentMethod={this.props.selectedPaymentMethod}
              />
            </div>

            <div className="card">
              <ShippingMethod title="Shipping" />
            </div>

            <TermsSection />
          </div>
          <OrderSummary key="right-side" isCheckout onClickEdit={this.props.goBack} />
        </OrderPanelLayout>
        <style jsx>
          {`
            .selected-address {
              border-left: 4px solid ${COLORS.gootenBlue300};
              border-radius: 4px;
            }
            .card,
            :global(.checkout-container .cerulean-alert) {
              margin: 2rem 0 !important;
            }
            .card {
              background-color: white;
              padding: 2rem;
              border: 1px solid ${COLORS.neutralLight400};
              border-radius: 4px;
            }
            @media screen and (min-width: 992px) {
              .card,
              :global(.checkout-container .cerulean-alert) {
                margin: 2rem !important;
                max-width: 992px !important;
              }
              .cart-items-header {
                padding: 0 1.5rem;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

Checkout.propTypes = {
  goBack: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(List).isRequired,
  selectedShippingAddress: PropTypes.object,
  selectedPaymentMethod: PropTypes.object,
  isLoading: PropTypes.bool,
  placeOrder: PropTypes.func.isRequired,
  order: PropTypes.object,
  clearOrder: PropTypes.func,
  clearCart: PropTypes.func,
  canPlaceOrder: PropTypes.bool.isRequired,
  errorAlert: PropTypes.array,
  sourceHistory: PropTypes.string
};

export default Checkout;

const CheckoutToast = props => {
  useEffect(() => {
    if (props.order && props.order.get('orderNumber')) {
      setTimeout(() => {
        props.clearOrder();
      }, 10000);
    }
  }, [props.order]);

  const handleGoToOrderNumber = () => {
    if (props.order.get('hadErrorFetchingOrderNumber')) {
      props.clearOrder();
      return;
    }
    props.clearOrder();

    window.location.hash = '#/orders-new/' + props.order.get('orderNumber');
  };

  return (
    <>
      {!!(props.order && props.order.get('orderNumber')) && (
        <div className="toast-container" onClick={props.clearOrder}>
          <Toast
            className="mt-3"
            isOpen={!!(props.order && props.order.get('orderNumber'))}
            textButtonClick={handleGoToOrderNumber}
            buttonText="View order"
          >
            Order &nbsp;<b>#{props.order.get('orderNumber')}</b>&nbsp; confirmed
          </Toast>
        </div>
      )}
      <style jsx>
        {`
          .toast-container {
            position: absolute;
            top: 0.5rem;
            right: 0;
            left: 0;
            bottom: 0;
          }
        `}
      </style>
    </>
  );
};

CheckoutToast.propTypes = {
  order: PropTypes.object,
  clearOrder: PropTypes.func
};

export { CheckoutToast };
