import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import OptionsModal from '../../../../shared/OptionsModal';
import Spinner from '../../../../shared/Spinner';
import './ProductVariantsItem.scss';
import Input from '../../../../shared/Input';
import Button from '../../../../shared/Button';
import Icon from '../../../../shared/Icon';
import { TableRow, Cell } from '../../../../shared/Table';
import { COLORS } from '../../../../../constants';
import noImageBackground from './assets/image-missing.png';
import Tooltip from '../../../../shared/Tooltip';
import Checkbox from '../../../../shared/Checkbox';
import Chip from '../../../../shared/Chip';
import { ArchivedReason, getArchivedReason } from '../../../../../const/ArchivedReasons';
import Thumbnail from '../../../../shared/Thumbnail';
import HoverImage from '../../../../shared/HoverImage';
import Modal from '../../../../shared/Modal';
const ProductVariantsItem = props => {
  const inputEl = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showNeckTagPreviewModel, setShowNeckTagPreviewModel] = useState(false);
  const [isInputDisabled, disableInput] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showHoverImage, setShowHoverImage] = useState('');

  const modalOptions = () => {
    let options = ['Rename SKU', 'Change mockup'];
    if (
      props.disableChangeMockup ||
      (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
    ) {
      options = options.filter(o => o !== 'Change mockup');
    }
    if (
      props.disableRenamingSkus ||
      (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
    ) {
      options = options.filter(o => o !== 'Rename SKU');
    }
    return options;
  };

  const handleMenuClick = e => {
    e.stopPropagation();
    setShowModal(true);
  };

  const handleSelectModalOption = option => {
    props.handleSelectOption(option, props.index);

    switch (option) {
      case 'renamesku':
        enableInput();
        break;
    }
  };

  const enableInput = () => {
    if (
      props.disableRenamingSkus ||
      (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
    ) {
      return;
    }
    disableInput(false);
    setTimeout(() => {
      inputEl.current.focus();
    }, 200);
  };

  let archivedReason = null;
  if (props.disabled || props.disabledReason == ArchivedReason.outOfStock) {
    archivedReason = getArchivedReason(props.disabledReason);
  }

  return (
    // disabled == !disabled in this case below because you show error tooltip only when the sku is disabled
    <>
      <TableRow
        className="product-variants-item"
        size="medium"
        columns={`${!props.disableRemovingSkus ? '2.5rem 4.5rem' : '4.5rem'} auto${
          props.productSupportsNeckLabel && props.currentNeckLabel ? ' 8rem' : ''
        } 4rem`}
      >
        {props.disableRemovingSkus ? null : (
          <Cell className="selection-column centered">
            <Checkbox checked={!!props.variant.get('selected')} onChange={props.handleSelectItem} />
          </Cell>
        )}
        <Cell className="image-column mr-n2 ml-n2" onClick={null}>
          {props.variant.get('previewUrl') || props.isLoading ? (
            <React.Fragment>
              <img
                className="item-image"
                onClick={() =>
                  props.disableChangeMockup ||
                  (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
                    ? null
                    : handleSelectModalOption('changemockup')
                }
                src={props.variant.get('previewUrl')}
                onLoad={() => setImageLoaded(true)}
              />
              <Spinner />
            </React.Fragment>
          ) : (
            <div className="item-image-placeholder" />
          )}
        </Cell>
        <Cell
          className="sku-column"
          onClick={() => enableInput()}
          tooltip={props.variant?.get('sku')}
          tooltipPosition="top"
        >
          <div
            className={`sku-name w-100 sku-name-div ${
              !!archivedReason ? 'sku-name-div-has-error' : ''
            }`}
          >
            <div className="sku-name-input">
              <Input
                noLabel
                ref={inputEl}
                className="sku-value"
                disabled={
                  props.disableRenamingSkus ||
                  (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
                }
                value={props.variant.get('customSku')}
                onChange={e => props.handleRenameSku(props.index, e.target.value)}
                onBlur={() => disableInput(true)}
                hasError={!!archivedReason}
                errorText={archivedReason}
              />
            </div>
          </div>
        </Cell>
        {props.productSupportsNeckLabel && props.currentNeckLabel ? (
          <Cell>
            {props.neckTagAvailable ? (
              <>
                <Modal
                  isOpen={showNeckTagPreviewModel}
                  cancelClick={() => setShowNeckTagPreviewModel(false)}
                  onDismiss={() => setShowNeckTagPreviewModel(false)}
                  primaryDisabled={true}
                  title="Neck Label"
                  className="neck-tag-preview-modal"
                >
                  <div className="neck-tag-preview-modal-container">
                    <img src={props.neckTagUrl}></img>
                  </div>
                </Modal>

                <div className="hub-product-type-thumbnail position-relative">
                  {props.currentNeckLabel && showHoverImage && props.neckTagUrl && (
                    <HoverImage image={props.neckTagUrl} />
                  )}
                  {props.currentNeckLabel && !props.neckTagUrl && <Spinner />}
                  {props.currentNeckLabel && props.neckTagUrl && (
                    <div
                      onMouseOver={() => setShowHoverImage(true)}
                      onMouseLeave={() => setShowHoverImage(false)}
                      onClick={() => setShowNeckTagPreviewModel(true)}
                    >
                      <Thumbnail className="medium" image={props.neckTagUrl} />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center no-neck-tag">
                <Tooltip
                  containerClassName="w-auto"
                  content={'Neck label is unavailable for this SKU'}
                >
                  <Icon icon="info" className="sku-status-problem-icon" />
                </Tooltip>
              </div>
            )}

            {/* <Chip className={props.neckTagAvailable ? 'green' : 'red'}>
              {props.neckTagAvailable ? 'Available' : 'Unavailable'}
            </Chip> */}
          </Cell>
        ) : null}
        <Cell className="menu-column">
          {modalOptions().length ? (
            <Button className="button-default medium" iconOnly onClick={e => handleMenuClick(e)}>
              <Icon icon="moreVertical" />
            </Button>
          ) : null}
          {showModal ? (
            <OptionsModal
              items={modalOptions()}
              onClickItem={handleSelectModalOption}
              onDismiss={() => setShowModal(false)}
            />
          ) : null}
        </Cell>
      </TableRow>
      <style jsx>
        {`
          .sku-name-div {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 4rem;
          }
          .sku-name-div-has-error {
            padding-bottom: 12px;
          }        
          :global(.neck-tag-preview-modal) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            width: fit-content !important;
          }
          :global(.neck-tag-preview-modal img) {
            height: 100%;
            width: 100%;
            object-fit: contain;
            padding-right: 24px;
            padding-left: 24px;
          }
          :global(.neck-tag-preview-modal-container) {
            height: calc(80vh);
          }        
          .hub-product-type-thumbnail {
            width: 4rem;
            height: 4rem;
          }
          .hub-product-type-thumbnail :global(.cerulean-thumbnail) {
            position: absolute;
            top: 0;
          }        
          .no-neck-tag {
            position: static;
            top: 8px;
            width: 4rem;
            height: 4rem;
            background: #FFFFFF;
            
            border: 1px solid ${COLORS.neutralLight300};
            box-sizing: border-box;
            border-radius: 4px;
          }
          .sku-name-input {
            margin-bottom: 4px !important;
            width: 100%;          
          }

          :global(.sku-status-problem-icon) {
            min-width: fit-content;          
            fill: ${COLORS.cayennePepper500};
          }
          .sku-status-problem {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .sku-problem-text {
            color: ${COLORS.cayennePepper500};        
            font-family: 'Avenir-Roman';
            font-style: normal;
            font-weight: 500;
            font-size: 0.8125rem;
            line-height: 16px;
          }
          :global(.product-variants-item .image-column), :global(.product-variants-item .menu-column) {
            position: relative;
          }
          :global(.image-column .spinner-container) {
            display: ${imageLoaded ? 'none' : 'initial'};
          }
          .item-image {
            width: 100%;
            opacity: ${imageLoaded ? 1 : 0};
            transition: opacity 0.3s;
            cursor: ${
              props.disableChangeMockup ||
              (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
                ? 'default'
                : 'pointer'
            };
          }
          .item-image:hover {
            border: ${
              props.disableChangeMockup ||
              (props.disabled && props.disabledReason != ArchivedReason.outOfStock)
                ? 'none'
                : 'solid 1px #bcbfc2'
            }; 
          }
          :global(.product-variants-item .options-modal) {
            position: absolute;
            right: 1rem;
            top: 2.75rem; 
          }
          .sku-name :global(input.sku-value) {
            border: ${
              isInputDisabled
                ? `1px solid ${COLORS.neutralLight100}`
                : `1px solid ${COLORS.gootenBlue500}`
            };
          }
          .item-image-placeholder {
            width: 100%;
            height: 100%;
            background: url('${noImageBackground}) center center / contain no-repeat;
          }
          .neck-tag-preview {
            length: 72px;

          }

        `}
      </style>
    </>
  );
};

ProductVariantsItem.propTypes = {
  variant: PropTypes.instanceOf(Map).isRequired,
  handleSelectItem: PropTypes.func,
  handleSelectOption: PropTypes.func,
  handleRenameSku: PropTypes.func,
  index: PropTypes.number,
  disableChangeMockup: PropTypes.bool,
  disableRemovingSkus: PropTypes.bool,
  disableRenamingSkus: PropTypes.bool,
  disabled: PropTypes.bool,
  disableReason: PropTypes.number,
  isLoading: PropTypes.bool,
  neckTagAvailable: PropTypes.bool,
  nectTagUrl: PropTypes.string,
  currentNeckLabel: PropTypes.object,
  productSupportsNeckLabel: PropTypes.bool
};

export default ProductVariantsItem;
