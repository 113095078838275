import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const DEACTIVATE = customActionFor('ADVANCED_SETTINGS').async('DEACTIVATE');
export const DOWNLOAD_DATA = customActionFor('ADVANCED_SETTINGS').async('DOWNLOAD_DATA');

// ------------------------------------
// Actions
// ------------------------------------

export function deactivate(payload) {
  return {
    type: DEACTIVATE.ASYNC,
    payload
  };
}

export function downloadData(payload) {
  return {
    type: DOWNLOAD_DATA.ASYNC,
    payload
  };
}
