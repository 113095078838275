import React from 'react';

import PropTypes from 'prop-types';
import Icon from '../../../../shared/Icon';
import { COLORS } from '../../../../../constants';

const ErrorMessage = ({ message, customClass }) => {
  return (
    <div className={`d-flex align-items-center caption-text ${customClass || ''}`}>
      <Icon small icon="alertCircle" />
      <span className="error-message ml-1">{message}</span>
      <style jsx>
        {`
          .caption-text {
            color: ${COLORS.cayennePepper500};
            fill: ${COLORS.cayennePepper500};
          }
        `}
      </style>
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  customClass: PropTypes.string
};

export default ErrorMessage;
