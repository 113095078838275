import CanvasImageElement from '../canvas/canvasImageElement';
import CanvasLayer from '../canvas/canvasLayer';
import PositioningService from '../../utils/positioningService';
import { SurfaceLayer, KonvaStage } from '../../flow/konvaTypes';

export default class TemplateSurfaceLayer implements SurfaceLayer {

  id: string;
  layer: CanvasLayer;
  image: CanvasImageElement;

  constructor(id: string) {
    this.id = id;
    this.layer = new CanvasLayer({ id, fast: true });
  }

  addToCanvas(stage: KonvaStage) {
    this.layer.addToStage(stage);
  }

  draw(ctx: any) {
    if (!this.image) {
      this.image = this.drawImage(ctx);
    }

    else {
      let canvasRect = Object.assign({
        // TODO: Handle it in better way, now on first init ImageElement set offsets which we don't need
        offsetX: 0,
        offsetY: 0
      }, this.getSizeRect(ctx));
      this.image.update(canvasRect);
    }

    let isLayerVisible = true;
    this.layer.setOpacity(1);
    if (ctx.state.editor.preview === 'print' && ctx.layer.type !== 'bleed') {
      isLayerVisible = false;
    }
    else if (ctx.state.editor.preview === 'product' && ctx.layer.type === 'bleed') {
      isLayerVisible = false;
    }
    else if (ctx.state.editor.mode === 'edit') {

      let imgLayer = ctx.state.template.layers.find(l => l.type === 'image');
      if (imgLayer.zindex < ctx.layer.zindex) {
        this.layer.setOpacity(0.3);
      }
    }

    this.layer.setVisible(isLayerVisible);
    this.layer.draw();
  }

  drawImage(ctx) {
    let img = ctx.image;
    let elmState = {};
    elmState.image = img;
    let canvasRect = this.getSizeRect(ctx);
    Object.assign(elmState, canvasRect);

    elmState.listening = false;
    let image = new CanvasImageElement(elmState);
    this.layer.addElement(image);
    return image;
  }

  getSizeRect(ctx) {
    let currentRect = {
      x: ctx.layer.x1,
      y: ctx.layer.y1,
      width: ctx.layer.x2 - ctx.layer.x1,
      height: ctx.layer.y2 - ctx.layer.y1
    };
    let finalRect = PositioningService.convertToCanvasSize(currentRect, ctx.state, ctx.layer.id);
    //finalRect.x += finalRect.width/2;
    //finalRect.y += finalRect.height/2;

    return finalRect;
  }
};
