import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import FileUpload from 'react16-fileupload';
import DropzoneComponent from 'react-dropzone-component';
import styled from 'styled-components';
import './UploadFromDevice.scss';
import Button from '../../../../Button';
import { getImageDimensions } from '../../../../../../utils/image';

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 362px;

  @media (max-width: 991px) {
    height: ${props => (props.isMultiSpace ? '424' : '362')}px;
  }

  @media (max-width: 767px) {
    height: ${props => (props.isMultiSpace ? '424' : '418')}px;
  }

  @media (max-width: 480px) {
    height: 278px;
  }

  .bcg-image {
    height: 110px;
    width: 150px;
    margin-bottom: 28px;
  }
`;

class UploadFromDevice extends PureComponent {
  fileUploadOptions = {
    wrapperDisplay: 'block',
    baseUrl: '',
    multiple: true,
    numberLimit: 99,
    accept: this.props.fileTypes,
    chooseFile: files => {
      this.checkImageRequirements(files, () => {
        this.props.uploadFiles(files);
      });
    }
  };

  checkImageRequirements(files, onSuccess) {
    // check if required file type is satisfied
    if (
      [...files].filter(f => this.props.fileTypes.indexOf(f.type) !== -1).length !== files.length
    ) {
      this.props.onError(
        (files.length > 1 ? 'One of images ' : 'Image ') + 'does not meet file type requirements'
      );
      return;
    }

    // check if required size is satisfied
    if (this.props.requiredImageSize) {
      Promise.all([...files].map(file => getImageDimensions(file))).then(values =>
        values.filter(val =>
          this.props.requiredImageSize.some(r => val.width === r.width && val.height === r.height)
        ).length === files.length
          ? onSuccess()
          : this.props.onError(
              (files.length > 1 ? 'One of images ' : 'Image ') + 'does not meet size requirements'
            )
      );
    } else {
      onSuccess();
    }
  }

  componentConfig = {
    iconFiletypes: ['.jpg', '.png'],
    showFiletypeIcon: false,
    postUrl: 'no-url',
    dropzoneSelector: '#image-selection-upload-device-container-id'
  };

  djsConfig = {
    addRemoveLinks: false,
    autoProcessQueue: false,
    autoQueue: false,
    clickable: false,
    acceptedFiles: this.props.fileTypes,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview" />
    )
  };

  eventHandlers = {
    addedfile: f => {
      this.checkImageRequirements([f], () => {
        this.callback(f);
      });
    }
  };

  componentDidMount() {
    const cont = document.getElementById('image-selection-upload-device-container-id');
    cont.addEventListener('dragenter', this.dragEffectEnter, false);
    cont.addEventListener('dragleave', this.dragEffectLeave, false);
  }

  componentWillUnmount() {
    const cont = document.getElementById('image-selection-upload-device-container-id');
    cont.removeEventListener('dragenter', this.dragEffectEnter);
    cont.removeEventListener('dragleave', this.dragEffectLeave);
  }

  dragEffectEnter = () => {
    document.getElementById('image-selection-upload-device-container-id').style.backgroundColor =
      '#eeeeee';
  };

  dragEffectLeave = () => {
    document.getElementById('image-selection-upload-device-container-id').style.backgroundColor =
      '#ffffff';
  };

  callback = f => {
    // give time to react-dropzone to cancel all subscriptions and asynchronous tasks
    setTimeout(() => {
      this.props.uploadFiles([f]);
    }, 200);
  };

  render() {
    return (
      <Container
        className="image-selection-upload-device flex-container"
        isSmallMobile={this.props.isSmallMobile}
        isMultiSpace={this.props.isMultiSpace}
        id="image-selection-upload-device-container-id"
      >
        <div className="bcg-image" />
        <span className="hidden-xs">Drag and drop images or</span>
        <FileUpload options={this.fileUploadOptions}>
          <span ref="chooseBtn">
            <Button className="button-cta large">Upload from device</Button>
          </span>
        </FileUpload>
        <DropzoneComponent
          config={this.componentConfig}
          eventHandlers={this.eventHandlers}
          djsConfig={this.djsConfig}
        />
      </Container>
    );
  }
}

UploadFromDevice.propTypes = {
  uploadFiles: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isMultiSpace: PropTypes.bool,
  isSmallMobile: PropTypes.bool,
  fileTypes: PropTypes.string, // format: 'image/jpeg,image/png'
  requiredImageSize: PropTypes.array // format: [{ width: 140, height: 200 }]
};

export default UploadFromDevice;
