import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import './OrderSummary.scss';

const OrderSummary = props => {
  let currentMonth = new Date().toLocaleString('en-us', { month: 'long' });
  let solveLinkTab = props.ordersCountIssues > 0 ? '3' : '4';

  return (
    <div className="dashboard-order-summary-container">
      <h1 className="order-summary__title">Order Summary</h1>

      <div className="orders--month">
        <p className="order-count__title">{currentMonth} Orders:</p>
        <p className="order-count">{props.ordersCountMonth}</p>
        <div className="url-link-container">
          {/* eslint-disable max-len */}
          <Link
            to="/orders-new?SortField=dateCreated&Desc&page=1&tab=4"
            onClick={() => {
              props.handleAnalytics(null, 'See List - clicked', null);
            }}
          >
            See List
          </Link>
        </div>
      </div>

      <div className="orders--attention">
        <p className="order-count__title">Orders Requiring Attention:</p>
        <p className="order-count">{props.ordersCountIssues}</p>
        <div className="url-link-container">
          {/* eslint-disable max-len */}
          <Link
            to={`/orders-new?SortField=dateCreated&Desc&page=1&tab=${solveLinkTab}`}
            onClick={() => {
              props.handleAnalytics(null, 'Solve Now - clicked', null);
            }}
          >
            Solve Now
          </Link>
        </div>
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  ordersCountMonth: PropTypes.number.isRequired,
  ordersCountIssues: PropTypes.number.isRequired,
  handleAnalytics: PropTypes.func
};

export default OrderSummary;
