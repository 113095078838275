import { connect } from 'react-redux';

import { storesLoad } from '../Hub/HubView/components/Stores/StoresActions';
import { allStoresSelector } from '../Hub/HubView/components/Stores/StoresSelectors';

import {
  busySelector,
  errorMsgSelector,
  successMsgSelector,
  disconnectStoreProviderSelector
} from 'gooten-components/src/components/Stores/StoresSelectors';

import { storesLoadedSelector } from '../Hub/HubView/components/Stores/StoresSelectors';
import {
  reset,
  storesConnectStep,
  storesConnectProvider,
  storeDisconnectPrompt,
  storeDisconnectCancel
} from 'gooten-components/src/components/Stores/StoresActions';

import { connectStepSelector } from './StoresSelectors';

import Stores from './Stores';

const mapStateToProps = state => ({
  errorMsg: errorMsgSelector(state),
  successMsg: successMsgSelector(state),
  busy: busySelector(state),
  allStores: allStoresSelector(state).toJS(),
  disconnectProvider: disconnectStoreProviderSelector(state),
  connectStep: connectStepSelector(state),
  storesLoaded: storesLoadedSelector(state)
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset()),
  fetchStores: () => dispatch(storesLoad(true)),
  setConnectStep: step => dispatch(storesConnectStep(step)),
  setConnectProvider: provider => dispatch(storesConnectProvider(provider)),
  disconnectStorePrompt: (provider, storeId) => dispatch(storeDisconnectPrompt(provider, storeId)),
  disconnectStoreCancel: () => dispatch(storeDisconnectCancel())
});

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
