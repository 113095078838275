import React from 'react';
import PropTypes from 'prop-types';
import ImageThumb from '../../../../../shared/ImageThumb';
import RadioButtonGroup from '../../../../../shared/RadioButtonGroup';
import Icon from '../../../../../shared/Icon';
import Tooltip from '../../../../../shared/Tooltip';

class ShippingMethodGroup extends React.Component {
  constructor(props) {
    super(props);

    this.handleShippingMethodSelected = this.handleShippingMethodSelected.bind(this);
  }

  static propTypes = {
    group: PropTypes.object,
    selectedShippingMethodId: PropTypes.number,
    onShippingMethodSelected: PropTypes.func,
    index: PropTypes.number
  };

  handleShippingMethodSelected(shippingMethod) {
    const skus = this.props.group.get('SKUs');
    const selectedShippingMethodId = shippingMethod.props.data.get('Id');
    this.props.onShippingMethodSelected(skus, selectedShippingMethodId);
  }

  renderShippingTime(item) {
    let minDays = item.get('MinDays') || 0;
    let maxDays = item.get('MaxDays') || 0;
    let estBusinessDaysTilDelivery = item.get('EstBusinessDaysTilDelivery') || 0;

    if ((!minDays || !maxDays) && !estBusinessDaysTilDelivery) {
      return 'Unknown Business Days';
    } else if (!minDays || !maxDays) {
      let newMin = Math.floor(estBusinessDaysTilDelivery - estBusinessDaysTilDelivery * 0.1);
      let newMax = Math.ceil(estBusinessDaysTilDelivery + estBusinessDaysTilDelivery * 0.1);
      return `${newMin} - ${newMax} Business Days`;
    } else {
      return `${item.get('MinDays')} - ${item.get('MaxDays')} Business Days`;
    }
  }

  productionRangeFormat = itemData => {
    if (itemData.productionTimeData) {
      const { Max, Mean, ConfidenceLevel } = itemData.productionTimeData;
      if (ConfidenceLevel < 0.5) {
        return null;
      }
      const confidencePercentage = ConfidenceLevel * 100;

      const icon = (
        <Tooltip
          content={`Production time is estimated using historical data with an accuracy of ${confidencePercentage}%`}
        >
          <Icon icon="info" small />
        </Tooltip>
      );
      return (
        <>
          {Mean}-{Max} business days production time {icon}
        </>
      );
    }
    return null;
  };

  render() {
    let index = this.props.group
      .get('ShipOptions')
      .findIndex(shipOption => shipOption.get('Id') === this.props.selectedShippingMethodId);
    if (index === -1) {
      index = 0;
    }

    const shippingMethods = this.props.group.get('ShipOptions').map(item => (
      <div data={item}>
        <div className="first-line">{this.renderShippingTime(item)}</div>
        <div className="second-line">
          {`${item.getIn(['PartnerPrice', 'FormattedPrice'])} - ${item.get('Name')}`}
        </div>
      </div>
    ));

    return (
      <div className="shipping-method-group">
        {this.props.index > 0 && <hr />}
        <div className="shipping-time-rows d-flex flex-wrap">
          <div className="mb-3 item-thumbnails">
            {this.props.group.get('Items').map((item, index) => {
              return (
                <div key={index} className="product-preview mb-3 d-flex">
                  <div className="product-thumbnail">
                    <ImageThumb
                      className="mb-3"
                      loading={item.get('previewImageUrl') === undefined}
                      imageUrl={item.get('previewImageUrl')}
                    />
                  </div>
                  <div className="product-name pl-2 pr-5">
                    <div className="body-text-1 text-tight heavy">{item.get('name')}</div>
                    <div className="body-text-1 text-tight heavy production-time-data">
                      {this.productionRangeFormat(item.toJS())}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="group-shipping-method">
            <RadioButtonGroup
              items={shippingMethods}
              selectionIndex={index}
              onChange={this.handleShippingMethodSelected}
            />
          </div>
        </div>
        <style jsx>
          {`
            .product-thumbnail {
              width: 50%;
              min-width: 1rem;
              max-width: 5rem;
            }
            .item-thumbnails {
              flex-grow: 0.5;
            }
            .production-time-data {
              display: flex !important;
              font-size 13px;
              font-weight: 400;
            }
          `}
        </style>
      </div>
    );
  }
}

ShippingMethodGroup.displayName = 'ShippingMethodGroup';

export default ShippingMethodGroup;
