import { arrangePrice } from '../../../../../utils/price';
import { fromJS } from 'immutable';

class ProductPricingService {
  mapVariantPrices(variants) {
    let minPrice, maxPrice, minCustomerPrice, maxCustomerPrice;
    let minMargin = Number.MAX_VALUE;
    let maxMargin = 0;

    const stateVariants = variants.map(variant => {
      const customerPrice = variant.customerPrice || variant.maxCost;
      const marginFromMin = customerPrice - variant.minCost;
      const marginFromMax = customerPrice - variant.maxCost;

      minPrice = Math.min(minPrice || Number.MAX_VALUE, variant.minCost);
      minCustomerPrice = Math.min(minCustomerPrice || Number.MAX_VALUE, customerPrice);
      minMargin = Math.min(minMargin, marginFromMax);
      maxPrice = Math.max(maxPrice || 0, variant.maxCost);
      maxCustomerPrice = Math.max(maxCustomerPrice || 0, customerPrice);
      maxMargin = Math.max(maxMargin, marginFromMin);
      return {
        index: variant.index,
        sku: variant.sku,
        minCost: variant.minCost,
        maxCost: variant.maxCost,
        price: arrangePrice(variant.minCost, variant.maxCost),
        margin: arrangePrice(marginFromMin, marginFromMax),
        customerPrice: parseFloat(customerPrice).toFixed(2),
        priceSelected: !!variant.priceSelected
      };
    });

    return fromJS({
      customerPrice: arrangePrice(minCustomerPrice, maxCustomerPrice),
      margin: arrangePrice(minMargin, maxMargin),
      price: arrangePrice(minPrice, maxPrice),
      variants: stateVariants
    });
  }
}

export default new ProductPricingService();
