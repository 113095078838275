import { connect } from 'react-redux';
import EmailsContent from './EmailsContent';
import EmailServices from '../EmailServices/EmailServices';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { initFor, changeFor, cancelFor, updateFor } from '../BaseCard/BaseCardActions';
import {
  uploadStart,
  uploadDone,
  uploadFailed,
  toggleUploadFromUrl,
  changeUploadFromUrl,
  uploadFrom
} from './EmailsContentActions';

const init = initFor(EmailsContent.name);
const change = changeFor(EmailsContent.name);
const cancel = cancelFor(EmailsContent.name);
const update = updateFor(EmailsContent.name);
const cardSelector = cardSelectorFor(EmailsContent.name);
const servicesSelector = cardSelectorFor(EmailServices.name);

const mapDispatchToProps = {
  init,
  change,
  cancel,
  update,
  uploadStart,
  uploadDone,
  uploadFailed,
  toggleUploadFromUrl,
  changeUploadFromUrl,
  uploadFrom
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  const servicesState = servicesSelector(state);
  return {
    logo: cardState.get('logo'),
    data: cardState.get('data'),
    unmodified: cardState.get('unmodified'),
    ready: !cardState.get('loading') && !cardState.get('busy'),
    uploadFromUrlOpen: cardState.get('uploadFromUrlOpen'),
    uploadFromUrl: cardState.get('uploadFromUrl'),
    useOrderConfirmation: servicesState.getIn(['data', 'SendConfirmation']),
    useShippingConfirmation: servicesState.getIn(['data', 'SendShipping'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsContent);
