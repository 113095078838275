import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Button from 'gooten-components/src/components/shared/Button';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Icon from 'gooten-components/src/components/shared/Icon';
import HoverImage from 'gooten-components/src/components/shared/HoverImage';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';

export const HubProductProductType = props => {
  const { showSelector, showRemove, product, selectedItems } = props;

  const [selectedState, setSelectedState] = useState('');
  const [showHoverImage, setShowHoverImage] = useState('');

  const isProductTypeSelected = () => {
    let numberOfSelectedItems = 0;

    product.variants.forEach(variant => {
      let found = selectedItems.find(item => item.id === variant.id);
      if (found) {
        numberOfSelectedItems = numberOfSelectedItems + 1;
      }
    });

    if (numberOfSelectedItems === product.variants.length) {
      setSelectedState('checked');
    } else if (numberOfSelectedItems > 0) {
      setSelectedState('minus');
    } else {
      setSelectedState('');
    }
  };

  const selectionHandler = () => {
    product.variants.forEach(variant => {
      if (variant.reorderable) {
        props.onHubProductSelectionToggled(
          {
            ...variant,
            parentId: props.product.id,
            parentName: props.product.name,
            parentImage: props.product.image
          },
          selectedState === ''
        );
      }
    });
  };

  const productTitleCreator = productData => {
    let name = productData.name;
    if (productData.productType) {
      name = `${name} (${productData.productType})`;
    }
    return name;
  };

  const isReorderable = productData => {
    let isReorderable = false;
    productData.variants.forEach(variant => {
      if (variant.reorderable) {
        isReorderable = true;
      }
    });
    return isReorderable;
  };

  useEffect(() => {
    if (showSelector) {
      isProductTypeSelected();
    }
  });

  return (
    <>
      <TableRow columns="2rem 6rem auto 2rem" size="medium" className="hub-products-type-container">
        {showSelector && (
          <Cell className="checkbox-container p-0">
            {isReorderable(product) ? (
              <Checkbox
                partial={selectedState === 'minus'}
                checked={selectedState === 'minus' || selectedState === 'checked'}
                onChange={() => selectionHandler()}
              />
            ) : (
              <Icon icon="alertCircle" fill="#F3C11A" />
            )}
          </Cell>
        )}
        <Cell className="pt-1 pb-1">
          <div
            onMouseOver={() => setShowHoverImage(true)}
            onMouseLeave={() => setShowHoverImage(false)}
            className="hub-product-type-thumbnail position-relative"
          >
            {showHoverImage && <HoverImage image={product.image} />}
            <Thumbnail className="medium" image={product.image} />
          </div>
        </Cell>
        <Cell className="product-name pt-2 pb-2">{productTitleCreator(product)}</Cell>
        {showRemove && (
          <Cell>
            <Button
              iconOnly
              className="button-default medium"
              onClick={() => props.remove(props.product.id)}
            >
              <Icon icon="xCircle" />
            </Button>
          </Cell>
        )}
      </TableRow>
      <style jsx>
        {`
          .hub-product-type-thumbnail {
            width: 4rem;
            height: 4rem;
          }
          .hub-product-type-thumbnail :global(.cerulean-thumbnail) {
            position: absolute;
            top: 0;
          }
        `}
      </style>
    </>
  );
};

HubProductProductType.propTypes = {
  showSelector: PropTypes.bool,
  showRemove: PropTypes.bool,
  product: PropTypes.object.isRequired,
  remove: PropTypes.func,
  selectedItems: PropTypes.instanceOf(List),
  onHubProductSelectionToggled: PropTypes.func
};

export default HubProductProductType;
