import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB-EDIT', 'EDIT_PRODUCT_VIEW');

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_STEP = getActionName('CHANGE_STEP');

// ------------------------------------
// Actions
// ------------------------------------
export function changeStep(step) {
  return {
    type: CHANGE_STEP,
    payload: { step }
  };
}
