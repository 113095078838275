import { createSelector } from 'reselect';

const cofSelector = state => state.get('customOrderForm');

const hubProductsViewSelector = createSelector([cofSelector], cof => cof.get('hubProductsView'));

export const searchTextSelector = createSelector([hubProductsViewSelector], hubProductsView =>
  hubProductsView.get('search')
);

export const sortValue = createSelector([hubProductsViewSelector], hubProductsView =>
  hubProductsView.get('sortValue')
);
