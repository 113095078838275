import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import ActionsLineItem from './ActionsLineItem';
import OptionsModal from 'gooten-components/src/components/shared/OptionsModal';
import analyticsService from 'gooten-components/src/services/analyticsService';
import Icon from 'gooten-components/src/components/shared/Icon';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';
import { useWindowSize } from 'gooten-components/src/utils/hooks';

const ActionsLine = props => {
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const onOptionSelection = action => {
    switch (action) {
      case 'all':
        props.selectAll();
        break;
      case 'none':
        props.unselectAll();
        break;
      case 'published':
        props.selectAll('published');
        break;
      case 'unpublished':
        props.selectAll('unpublished');
        break;
    }
  };

  const onAction = action => {
    switch (action) {
      case 'placeanorder':
        analyticsService.track('Hub - Place an Order', 'Place an order', 'Hub');
        props.router.push('/hub/place-order');
        break;
      case 'delete':
        analyticsService.track('Hub - Place an Order', 'Delete', 'Hub');
        deleteProducts();
        break;
      case 'stopfulfillment':
        // this check will prevent spamming unlinkProducts call if we have another action in progress (isUpdating flag)
        if (props.isUpdating) break;
        analyticsService.track('Hub - Place an Order', 'Stop Fulfillment', 'Hub');
        props.unlinkProducts(props.selectedProducts);
        break;
      case 'unpublish':
        analyticsService.track('Hub - Place an Order', 'Unpublish', 'Hub');
        props.unpublishProducts(props.selectedProducts);
        break;
      case 'publish':
        analyticsService.track('Hub - Place an Order', 'Publish', 'Hub');
        props.publishProducts(props.selectedProducts);
        break;
      case 'edit':
        analyticsService.track('Hub - Place an Order', 'Edit', 'Hub');
        props.router.push('/hub/edit-product/start');
        break;
      case 'duplicate':
        analyticsService.track('Hub - Place an Order', 'Duplicate', 'Hub');
        props.router.push('/hub/duplicate-product/start');
        break;
      case 'viewinstore':
        analyticsService.track('Hub - Place an Order', 'View in Store', 'Hub');
        window.open(props.productUrlInStore, '_blank');
        break;
      case 'personalized':
        analyticsService.track('Hub - Place an Order', 'Personalize', 'Hub');
        getPersonalizedStatus()
          ? props.depersonalizeProducts(props.selectedProducts)
          : props.personalizeProducts(props.selectedProducts);
        break;
    }
  };

  const onOptionSelect = action => {
    analyticsService.track('Hub - Page', action, 'Hub', null, {
      page: props.router.location.pathname
    });
  };

  const getGroupLabel = () => {
    return props.selectedProducts.size > 1 ? '(group)' : '';
  };

  const deleteProducts = () => {
    onOptionSelect(`Delete product started ${getGroupLabel()}`);
    setShowDeleteModal(true);
  };

  const getCheckboxStatus = () => {
    if (props.productsQueryData.get('loaded')) {
      const totalItems = props.productsQueryData.get('data').get('items').size;
      const totalSelected = props.selectedProducts.size;
      if (totalItems === totalSelected) return 'checkbox-on';
      if (totalSelected > 0) return 'checkbox-mixed';
    }
    return 'checkbox-off';
  };

  const getPersonalizedStatus = () => {
    return (
      props.productsQueryData.get('loaded') &&
      props.selectedProducts.every(p => p.get('personalize'))
    );
  };

  const [containerWidth, setContainerWidth] = React.useState(0);
  const getContainerInfo = React.useCallback(
    e => {
      if (e !== null) {
        setContainerWidth(e.getBoundingClientRect().width);
      }
    },
    [props]
  );

  const items = props.items.map((item, pos) => (
    <ActionsLineItem
      key={pos}
      title={item.title}
      icon={item.icon}
      onClick={onAction}
      switch={item.title === 'Personalized'}
      checked={getPersonalizedStatus()}
    />
  ));

  return (
    <div className="actions-line d-flex w-100 mt-2">
      {!props.isEmpty ? (
        <div className="checkbox-container d-flex align-items-center justify-content-center">
          <div className="d-flex checkbox-and-icon">
            <Checkbox
              onChange={
                getCheckboxStatus() === 'checkbox-off'
                  ? () => props.selectAll()
                  : () => props.unselectAll()
              }
              checked={
                getCheckboxStatus() === 'checkbox-on' || getCheckboxStatus() === 'checkbox-mixed'
              }
              partial={getCheckboxStatus() === 'checkbox-mixed'}
            />
            <div
              onClick={() => {
                setShowModal(true);
              }}
            >
              <Icon className="ml-2" icon="chevronDown" />
            </div>
          </div>
          {showModal ? (
            <OptionsModal
              items={
                props.currentProvider === 'storage'
                  ? ['All', 'None']
                  : ['All', 'None', 'Published', 'Unpublished']
              }
              onClickItem={onOptionSelection}
              onDismiss={() => {
                setShowModal(false);
              }}
            />
          ) : null}
        </div>
      ) : null}
      <div className="items-holder ml-3">
        <div
          ref={getContainerInfo}
          className={`items d-flex ${
            useWindowSize().width - (useWindowSize().width < 915 ? 180 : 350) < containerWidth
              ? 'mobile-actions'
              : 'desktop-actions'
          }`}
        >
          {items}
        </div>
      </div>
      <Dialogue
        title="Delete Product"
        isOpen={showDeleteModal}
        cancelClick={() => {
          setShowDeleteModal(false);
        }}
        destructiveClick={() => {
          props.deleteProducts(props.selectedProducts);
          setShowDeleteModal(false);
        }}
        actionText="Delete"
      >
        {props.selectedProducts.size > 1 ? (
          // eslint-disable-next-line no-multi-str
          'Deleting these products will remove it from Gooten and all connected sales channels. This action cannot be \
          undone'
        ) : (
          <span>
            Deleting&nbsp;
            {props.selectedProducts && Array.from(props.selectedProducts)[0] ? (
              <b>{Array.from(props.selectedProducts)[0].get('name')}</b>
            ) : (
              'this item'
            )}
            &nbsp;will remove it from Gooten and all connected sales channels. This action cannot be
            undone.
          </span>
        )}
      </Dialogue>
      <style jsx>
        {`
          .checkbox-container {
            position: relative;
            cursor: pointer;
            height: 3.5rem;
          }
          .checkbox-and-icon {
            width: 3rem;
          }
          .items-holder {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
          }
          .items {
            display: ${items.length > 0 ? '1' : '0'};
            transition-duration: 0.3s;
            width: fit-content;
          }
          .actions-line :global(.options-modal) {
            top: 3rem;
            left: 0;
          }
        `}
      </style>
    </div>
  );
};

ActionsLine.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Map)]),
  selectedProducts: PropTypes.instanceOf(List).isRequired,
  productsQueryData: PropTypes.instanceOf(Map).isRequired,
  selectAll: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  unpublishProducts: PropTypes.func.isRequired,
  publishProducts: PropTypes.func.isRequired,
  deleteProducts: PropTypes.func.isRequired,
  unlinkProducts: PropTypes.func.isRequired,
  personalizeProducts: PropTypes.func.isRequired,
  depersonalizeProducts: PropTypes.func.isRequired,
  router: PropTypes.object,
  isEmpty: PropTypes.bool,
  currentProvider: PropTypes.string.isRequired,
  productUrlInStore: PropTypes.string,
  isUpdating: PropTypes.bool
};

export default ActionsLine;
