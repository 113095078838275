'use strict';
// @flow

import { fromJS } from 'immutable';

const initialState = {
  editor: {
    templateloaded: false,
    imageloading: false,
    imageloaded: false,
    // editor mode: preview | edit
    mode: 'preview',
    cropActivated: false,
    // preview mode: product | print
    preview: 'product',
    orientation: 'landscape',
    defaultOrientation: 'landscape',
    bulkEdit: true,
    bulkEditAvailable: true
  },

  // editor template contains img urls, coordinates, etc.
  template: {
    rotation: 0,
    layers: [
      /*{
      id: 0,
      type: 'design' | 'image'
      zindex: 0,
      x1: 0,
      x2: 100,
      y1: 0,
      y2: 200,
      imageurl: '',
      image: Image
    }*/
    ]
  },

  images: {
    past: [],
    current: {
      selected: {
        imageId: '',
        layerId: ''
      },
      images: [
        /* {
          id: 0, // dynamic id in editor
          layerId: 0, // layer id from template
          zindex: 0, // position with other images
          src: null, // source url or object for image
          image: null, // image loaded by editor, already minified to be maximum 1000x1000
          scale: 1, // image scale, 1 = 100%
          realSourceHeight: 1, // real height of image
          realSourceWidth: 1, // real width of image
          sourceHeight: 1, // height of minified image
          sourceWidth: 1, // width of minified image
          crop: { // crop object inside image rect. Max rect is: [0, 0, realSourceWidth, realSourceHeight]
            x: 0,
            y: 0,
            width: 1,
            height: 1
          },
          x: 0,
          y: 0  // coordinates of image center
        }*/
      ]
    },
    future: []
  }
};

export const initialStateJS = initialState;

export default fromJS(initialState);
