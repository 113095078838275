import { connect } from 'react-redux';
import Config from '../../../../../config';
import DuplicateChanges from './DuplicateChanges';
import { storesSelector } from './DuplicateChangesSelector';
import { duplicateChanges } from './DuplicateChangesAction';

const mapDispatchToProps = {
  duplicateChanges: (options, stores) => duplicateChanges({ options, stores })
};

const mapStateToProps = state => {
  const disabledStoreOptions = Config.get('editMode') ? { storage: ['pricing', 'variants'] } : {};

  return {
    disabledStoreOptions,
    stores: storesSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateChanges);
