import { connect } from 'react-redux';
import EmailCredentials from './EmailCredentials';
import EmailServices from '../EmailServices/EmailServices';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { initFor, updateFor, changeFor, cancelFor } from '../BaseCard/BaseCardActions';
import { testConnection } from './EmailCredentialsActions';

const init = initFor(EmailCredentials.name);
const change = changeFor(EmailCredentials.name);
const cancel = cancelFor(EmailCredentials.name);
const update = updateFor(EmailCredentials.name);
const cardSelector = cardSelectorFor(EmailCredentials.name);
const servicesSelector = cardSelectorFor(EmailServices.name);

const mapDispatchToProps = {
  init,
  update,
  change,
  cancel,
  testConnection
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  const servicesState = servicesSelector(state);
  return {
    data: cardState.get('data'),
    unmodified: cardState.get('unmodified'),
    testConnectionStatus: cardState.get('testConnectionStatus'),
    testConnectionError: cardState.get('testConnectionError'),
    ready: !cardState.get('loading') && !cardState.get('busy'),
    useOrderConfirmation: servicesState.getIn(['data', 'SendConfirmation']),
    useShippingConfirmation: servicesState.getIn(['data', 'SendShipping'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCredentials);
