import CanvasImageElement from '../canvas/canvasImageElement';
import CanvasMaskElement from '../canvas/canvasMaskElement';
import CanvasLayer from '../canvas/canvasLayer';
import { SurfaceLayer, KonvaStage } from '../../flow/konvaTypes';

export default class TemplateSurfaceLayer implements SurfaceLayer {

  id: string;
  layer: CanvasLayer;
  image: CanvasImageElement;

  constructor(id: string) {
    this.id = id;
    this.layer = new CanvasLayer({ id, fast: true });
  }

  addToCanvas(stage: KonvaStage) {
    this.layer.addToStage(stage);
  }

  draw(layerOptions: any) {
    if (!this.image) {
      this.image = layerOptions.mask
        ? this.drawMask(layerOptions)
        : this.drawImage(layerOptions);
    }
    else {
      let canvasRect = Object.assign({
        offsetX: 0,
        offsetY: 0,
        backgroundColor: layerOptions.backgroundColor
      }, this.getSizeRect(layerOptions));
      this.image.update(canvasRect, layerOptions);
    }

    let isLayerVisible = true;
    if (layerOptions.preview === 'print') {
      isLayerVisible = false;
    }

    this.layer.setVisible(isLayerVisible);
    this.layer.draw();
  }

  drawImage(layerOptions) {
    let elmState = {
      listening: false,
      image: layerOptions.image,
      ...this.getSizeRect(layerOptions)
    };

    let image = new CanvasImageElement(elmState);
    this.layer.addElement(image);

    return image;
  }

  drawMask(layerOptions, backgroundColor) {
    let elmState = {
      backgroundColor: layerOptions.backgroundColor,
      listening: false,
      image: layerOptions.image,
      ...this.getSizeRect(layerOptions)
    };

    let image = new CanvasMaskElement(elmState);
    this.layer.addElement(image);

    return image;
  }

  getSizeRect(layerOptions) {
    return {
      x: layerOptions.x,
      y: layerOptions.y,
      width: layerOptions.width,
      height: layerOptions.height
    };
  }
};
