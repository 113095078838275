import React from 'react';
import PropTypes from 'prop-types';
import './Controls.scss';

class Controls extends React.Component {
  static propTypes = {
    current: PropTypes.number,
    items: PropTypes.array,
    slideTo: PropTypes.func,
    size: PropTypes.number,
    position: PropTypes.string,
    showNames: PropTypes.bool
  };

  canSlidePrev() {
    return !!this.props.items[this.props.current - 1];
  }

  canSlideNext() {
    return !!this.props.items[this.props.current + 1];
  }

  slidePrev() {
    this.props.slideTo(this.props.current - 1);
  }

  slideNext() {
    this.props.slideTo(this.props.current + 1);
  }

  render() {
    if (!this.props.items || this.props.items.length < 2) {
      return <div />;
    }

    let position =
      this.props.items.length < 5 || this.props.current < 2
        ? 0
        : Math.min(this.props.current - 1, this.props.items.length - 4);

    let prevClass, nextClass, slideContainerStyle, translation;
    if (this.props.position === 'vertical') {
      prevClass = 'icon-arrow-up';
      nextClass = 'icon-arrow-down';
      slideContainerStyle = {
        height: Math.min(this.props.items.length * this.props.size, this.props.size * 4) + 'px'
      };
      translation = `translate(0px, ${position * -this.props.size}px)`;
    } else {
      prevClass = 'icon-arrow-left';
      nextClass = 'icon-arrow-right';
      slideContainerStyle = {
        width: Math.min(this.props.items.length * this.props.size, this.props.size * 4) + 'px'
      };
      translation = `translate(${position * -this.props.size}px)`;
    }

    let slideContentStyle = { transform: translation };

    const itemMap = (item, index, isActive, clickCb) => (
      <div className={this.props.showNames ? 'slide-item show-names' : 'slide-item'} key={index}>
        <div
          className={isActive ? 'icon-circle-active big-icon' : 'icon-circle big-icon'}
          onClick={() => isActive || clickCb()}
        />
        <div className="item-name">{item.name || item.id || index}</div>
      </div>
    );

    let items = this.props.items.map((item, index) => {
      return itemMap(item, index, index === this.props.current, () => {
        this.props.slideTo(index);
      });
    });

    return (
      <div className="slider-controls center-block">
        {this.canSlidePrev() ? (
          <div
            className={'big-icon highlight-on-hover ' + prevClass}
            onClick={() => this.slidePrev()}
          />
        ) : (
          <div className="icon-circle big-icon empty" />
        )}
        <div className="sc-slide-container" style={slideContainerStyle}>
          <div className="sc-slide-content animate-position" style={slideContentStyle}>
            {items}
          </div>
        </div>
        {this.canSlideNext() ? (
          <div
            className={'big-icon highlight-on-hover ' + nextClass}
            onClick={() => this.slideNext()}
          />
        ) : (
          <div className="icon-circle big-icon empty" />
        )}
      </div>
    );
  }
}

Controls.displayName = 'Controls';

export default Controls;
