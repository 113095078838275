import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div``;
const passwordRegex = /^.{8,}$/;
const emptyRegex = /^$/;

class Password extends React.Component {
  static get name() {
    return 'password';
  }
  static get messages() {
    return {
      onFailure: 'Failed to update Password',
      onSuccess: 'Your Password has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
  }

  componentDidUpdate() {
    if (this.props.validationError) {
      Object.values(this.inputs).forEach(i => i.validate());
    } else if (this.props.isPristine) {
      Object.values(this.inputs).forEach(i => i.hideErrors());
    }
  }

  get canSave() {
    return (
      this.props.ready &&
      this.props.data.Password &&
      this.props.data.CurrentPassword &&
      this.inputs['Password'] &&
      this.inputs['Password'].isValid() &&
      !this.validationError
    );
  }

  renderInput(id, label, name, regex, error) {
    return (
      <TextInput
        className="col-sm-6 col-xs-12"
        id={id}
        isRequired
        emptyMsg="This field is required"
        showValidation
        regexp={error ? emptyRegex : regex}
        validationMsg={error || 'Password is too short'}
        label={label}
        ref={ref => {
          this.inputs[name] = ref;
        }}
        placeholder="Password"
        inputType="password"
        preValidate={!!error}
        onChange={value => this.props.change(value, name)}
        value={this.props.data[name] || ''}
      />
    );
  }

  render() {
    return (
      <BaseCard card={Password.name} title="Password" canToggle>
        <Container>
          <div className="password-card">
            <div className="row">
              <div className="col-sm-10 col-xs-12">
                {this.renderInput('NewPassword', 'New Password', 'Password', passwordRegex)}
                {this.renderInput(
                  'CurrentPassword',
                  'Current Password',
                  'CurrentPassword',
                  null,
                  this.props.validationError
                )}
              </div>
            </div>
          </div>
          <Button
            className="button-primary large"
            disabled={!this.canSave}
            onClick={() => this.props.update({ data: this.props.data })}
          >
            Update password
          </Button>
          <p className="mt-2">
            Can’t remember your password? Update by&nbsp;
            <a className="settings-link" onClick={() => this.props.sendEmail()}>
              email.
            </a>
          </p>
        </Container>
      </BaseCard>
    );
  }
}

Password.propTypes = {
  data: PropTypes.object,
  ready: PropTypes.bool,
  isPristine: PropTypes.bool,
  sendEmail: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  validationError: PropTypes.string,
  update: PropTypes.func.isRequired
};

export default Password;
