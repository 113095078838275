import { connect } from 'react-redux';

import { storeDisconnectCancel, storeLogOut } from '../../StoresActions';

import { disconnectStoreProviderSelector, disconnectStoreIdSelector } from '../../StoresSelectors';

import DisconnectStore from './DisconnectStore';

const mapStateToProps = state => ({
  storeId: disconnectStoreIdSelector(state),
  provider: disconnectStoreProviderSelector(state)
});

const mapDispatchToProps = dispatch => ({
  cancel: () => dispatch(storeDisconnectCancel()),
  disconnectStore: storeId => dispatch(storeLogOut(storeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DisconnectStore);
