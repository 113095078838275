import { connect } from 'react-redux';
import ProvidersTab from './ProvidersTab';
import {
  providersSelector,
  selectedProviderSelector,
  isSelectedProviderAvailableSelector
} from './ProvidersTabSelectors';
import { setProvider } from './ProvidersTabActions';
import { publishErrorsSelector, serverErrorsSelector } from '../../ProductPublishSelectors';

const mapDispatchToProps = {
  setProvider: provider => setProvider(provider)
};

const mapStateToProps = state => ({
  errors: publishErrorsSelector(state), // local validation errors
  serverErrors: serverErrorsSelector(state), // errors returned from server
  providers: providersSelector(state),
  selectedProvider: selectedProviderSelector(state),
  isSelectedProviderAvailable: isSelectedProviderAvailableSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersTab);
