import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import {
  setSelectedNeckTag,
  CREATE_NECKTAG,
  FETCH_NECKTAGS,
  SELECT_NECKTAG
} from './NeckLabelsActions';
import { selectedNeckLabelIdSelector } from './NeckLabelsSelectors';
import { publishDataSelector } from '../../ProductPublishSelectors';
import { createNeckTag, fetchNeckTags } from '../../../../services/addOns';
import Config from '../../../../config';
import {
  fetchAsync,
  fetchAsyncFail,
  fetchAsyncSuccess
} from '../../../../store/actions/dataActions';
import { NECK_LABEL_PRICE } from '../../../../const/NeckLabel';
import { changeStorePrices } from '../shared/ProductPricing/ProductPricingActions';
import {
  GET_NECK_TAG_PREVIEWS,
  neckLabelSelectionChanged
} from '../../../../store/actions/dataActions';
import { clearSkuNeckTagPreview } from '../shared/ProductVariants/ProductVariantsActions';
import { fromJS } from 'immutable';
import { skusWithNeckTagSelector } from '../../ProductPublishSelectors';
export function* createNeckTags(action) {
  try {
    const response = yield call(createNeckTag, action.payload.neckTag);
    const skusWithNeckTag = yield select(skusWithNeckTagSelector);
    yield put(fetchAsyncSuccess(CREATE_NECKTAG, { neckTag: response }));
    if (action.payload?.isCof) {
      yield put(neckLabelSelectionChanged(fromJS(response)));
    } else {
      yield put(setSelectedNeckTag({ neckTagId: response.id, skusWithNeckTag: skusWithNeckTag }));
    }
  } catch (err) {
    yield put(
      fetchAsyncFail(CREATE_NECKTAG, {
        err: 'Neck label was not created, please try again or use another image'
      })
    );
  }
}

export function* fetchNeckTagsAsyncHandler() {
  try {
    const data = yield call(fetchNeckTags);
    yield put(fetchAsyncSuccess(FETCH_NECKTAGS, { neckTags: data.Records }));

    // to share selected neck tag between providers
    const dataState = yield select(publishDataSelector);
    const editNeckTagId = yield select(selectedNeckLabelIdSelector);
    const skusWithNeckTag = yield select(skusWithNeckTagSelector);

    if (
      Config.get('editMode') &&
      !dataState?.has('neckTagId') &&
      !!data.Records.find(x => x.id === editNeckTagId)
    ) {
      yield put(
        setSelectedNeckTag({
          neckTagId: editNeckTagId,
          editModeInit: true,
          skusWithNeckTag: skusWithNeckTag
        })
      );
    }

    if (
      dataState?.has('neckTagId') &&
      !!data.Records.find(x => x.id === dataState?.get('neckTagId'))
    ) {
      yield put(
        setSelectedNeckTag({
          neckTagId: dataState.get('neckTagId'),
          skusWithNeckTag: skusWithNeckTag
        })
      );
    }

    yield put(fetchAsync(GET_NECK_TAG_PREVIEWS));
  } catch (err) {
    yield put(
      fetchAsyncFail(FETCH_NECKTAGS, { err: 'Could not fetch neck tags please refresh the page.' })
    );
  }
}

// handle price updates after selecting neck tag
function* selectNeckTagHangler(action) {
  const publishData = yield select(publishDataSelector);
  yield put(clearSkuNeckTagPreview());
  // update all variant prices under all stores
  if (publishData.get('stores')) {
    for (const store of publishData.get('stores')) {
      for (const variant of store.get('variants')) {
        if (variant.get('hasNeckTag') === false || variant.get('hadNeckTag') === false) {
          if (
            variant.get('hasNeckTag') === true &&
            !variant.get('customerPriceIncludesAddonCost')
          ) {
            const newVar = variant
              .set('customerPrice', variant.get('customerPrice') + NECK_LABEL_PRICE)
              .set('maxCost', variant.get('maxCost') + NECK_LABEL_PRICE)
              .set('minCost', variant.get('minCost') + NECK_LABEL_PRICE)
              .set('customerPriceIncludesAddonCost', true);
            yield put(
              changeStorePrices({
                storeId: store.get('id'),
                item: newVar.toJS()
              })
            );
          } else if (
            //customerPriceAlready includes addon cost
            variant.get('hasNeckTag') === true &&
            !variant.get('ourCostIncludesAddonCost')
          ) {
            const newVar = variant
              .set('maxCost', variant.get('maxCost') + NECK_LABEL_PRICE)
              .set('minCost', variant.get('minCost') + NECK_LABEL_PRICE)
              .set('ourCostIncludesAddonCost', true);
            yield put(
              changeStorePrices({
                storeId: store.get('id'),
                item: newVar.toJS()
              })
            );
          } else if (
            variant.get('hasNeckTag') === false &&
            variant.get('customerPriceIncludesAddonCost') === true
          ) {
            const newVar = variant
              .set('customerPrice', variant.get('customerPrice') - NECK_LABEL_PRICE)
              .set('maxCost', variant.get('maxCost') - NECK_LABEL_PRICE)
              .set('minCost', variant.get('minCost') - NECK_LABEL_PRICE)
              .set('customerPriceIncludesAddonCost', false);
            yield put(
              changeStorePrices({
                storeId: store.get('id'),
                item: newVar.toJS()
              })
            );
          }
        }
      }
    }
  }
  yield put(fetchAsync(GET_NECK_TAG_PREVIEWS));
}

export function* watchCreate() {
  yield takeLatest(CREATE_NECKTAG.ASYNC, createNeckTags);
}

export function* watchUpdates() {
  yield takeLatest(FETCH_NECKTAGS.ASYNC, fetchNeckTagsAsyncHandler);
}

function* watchSelectNeckTag() {
  yield takeLatest(SELECT_NECKTAG, selectNeckTagHangler);
}

export default function* rootSaga() {
  yield all([watchCreate(), watchUpdates(), watchSelectNeckTag()]);
}
