import React from 'react';
import ReactDOM from 'react-dom';
import AppStore from './store';
import AppContainer from './containers/AppContainer';
import SignupContainer from './containers/SignupContainer';
import LoginContainer from './containers/LoginContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import ShopifyOAuthContainer from './containers/ShopifyOAuthContainer';
import CreatePasswordContainer from './containers/CreatePasswordContainer';
import { globalReset } from './store/actions/globalActions';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// ========================================================
// Performance tuning helper
// https://github.com/garbles/why-did-you-update
// Notify in browser console when unnecessary React component re-render happens
// TODO: Fix it with override shouldComponentUpdate
// ========================================================
// if (__DEV__) {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }

// ========================================================
// Render Setup
// ========================================================
let MOUNT_NODE;

let render = config => {
  MOUNT_NODE = document.querySelector('.gooten-components-container');
  const routes = require('./routes/index').default();
  console.debug('React version is', React.version);
  ReactDOM.render(<AppContainer config={config} store={AppStore} routes={routes} />, MOUNT_NODE);
};

let renderSignup = config => {
  MOUNT_NODE = document.querySelector('.gooten-components-container');
  console.debug('Starting signup view, react v.', React.version);
  ReactDOM.render(<SignupContainer config={config} store={AppStore} />, MOUNT_NODE);
};

let renderLogin = config => {
  MOUNT_NODE = document.querySelector('.gooten-components-container');
  console.debug('Starting login view, react v.', React.version);
  ReactDOM.render(<LoginContainer config={config} store={AppStore} />, MOUNT_NODE);
};

let renderResetPassword = config => {
  MOUNT_NODE = document.querySelector('.gooten-components-container');
  console.debug('Starting login view, react v.', React.version);
  ReactDOM.render(<ResetPasswordContainer config={config} store={AppStore} />, MOUNT_NODE);
};

let renderShopifyOAuth = config => {
  MOUNT_NODE = document.querySelector('.gooten-components-container');
  console.debug('Starting signup view, react v.', React.version);
  ReactDOM.render(<ShopifyOAuthContainer config={config} store={AppStore} />, MOUNT_NODE);
};

let renderCreatePassword = config => {
  MOUNT_NODE = document.querySelector('.gooten-components-container');
  console.debug('Starting create password view, react v.', React.version);
  ReactDOM.render(<CreatePasswordContainer config={config} store={AppStore} />, MOUNT_NODE);
};

let destroy = () => {
  if (MOUNT_NODE) {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
  }
  AppStore.dispatch(globalReset());
};

// ========================================================
// Developer Tools Setup
// ========================================================
if (__DEV__) {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    // window.__REDUX_DEVTOOLS_EXTENSION__.open()
  }
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render function

    // Development render functions
    const renderApp = render;
    const renderError = error => {
      const RedBox = require('redbox-react').default;

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    let lastConfig;
    // Wrap render in try/catch
    render = config => {
      try {
        lastConfig = config;
        renderApp(config);
      } catch (error) {
        console.error(error);
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render(lastConfig);
      })
    );
  }
}

// ========================================================
// Entry point
// ========================================================
export default render;
window.GTN = {
  ...window.GTN,
  render,
  destroy,
  renderSignup,
  renderLogin,
  renderResetPassword,
  renderShopifyOAuth,
  renderCreatePassword
};

// ========================================================
// Go!
// ========================================================
if (window._gtnConfig) {
  window.GTN.render(window._gtnConfig);
}

if (window._signupConfig) {
  window.GTN.renderSignup(window._signupConfig);
}

if (window._loginConfig) {
  window.GTN.renderLogin(window._loginConfig);
}

if (window._resetPasswordConfig) {
  window.GTN.renderResetPassword(window._resetPasswordConfig);
}

if (window._shopifyOAuthConfig) {
  window.GTN.renderShopifyOAuth(window._shopifyOAuthConfig);
}

if (window._createPasswordConfig) {
  window.GTN.renderCreatePassword(window._createPasswordConfig);
}
