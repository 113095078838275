import React from 'react';
import PropTypes from 'prop-types';
import SearchBarMobile from './SearchBarMobile';

class Search extends React.PureComponent {
  static propTypes = {
    doSearch: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    router: PropTypes.object
  };

  componentDidMount() {
    // update viewBy from URL query
    if (this.props.router.location.query.search) {
      if (this.props.router.location.query.search !== this.props.searchText) {
        this.props.doSearch(this.props.router.location.query.search);
      }
    }
  }

  state = {
    isOpen: false
  };

  toggleSearch = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearch = searchText => {
    this.props.router.push({
      ...this.props.router.location,
      query: {
        ...this.props.router.location.query,
        page: 1,
        search: searchText || undefined
      }
    });

    this.props.doSearch(searchText);
  };

  render() {
    return (
      <SearchBarMobile
        doSearch={this.onSearch}
        searchText={this.props.searchText}
        isOpen={this.state.isOpen}
        toggle={() => this.toggleSearch()}
      />
    );
  }
}

export default Search;
