import { connect } from 'react-redux';
import OrdersView from './OrdersView';
import {
  searchFields,
  ordersList,
  searchLoading,
  ordersViewErrors,
  noOrders
} from './OrdersViewSelectors';
import {
  searchStart,
  updatePageNumber,
  updateTabNumber,
  toggleSelectedRow,
  toggleSelectedRows,
  updateSearchFields,
  updateSearchFieldsWithObject,
  updateSearchFieldsWithObjectInitial,
  changeStatusAsync,
  updateOrderOrderItem
} from './OrdersViewActions';
import { fetchHelpData } from '../HelpData/HelpDataActions';
import { helpData } from '../HelpData/HelpDataSelectors';

const mapDispatchToProps = {
  searchStart: () => searchStart(),
  updateSearchFields: (key, value, sortField) => updateSearchFields(key, value, sortField),
  updateSearchFieldsWithObject: searchObject => updateSearchFieldsWithObject(searchObject),
  updateSearchFieldsWithObjectInitial: searchObject =>
    updateSearchFieldsWithObjectInitial(searchObject),
  updatePageNumber: pageNumber => updatePageNumber(pageNumber),
  updateTabNumber: tabNumber => updateTabNumber(tabNumber),
  toggleSelectedRow: orderId => toggleSelectedRow(orderId),
  toggleSelectedRows: selected => toggleSelectedRows(selected),
  fetchHelpData: () => fetchHelpData(),
  changeStatus: data => changeStatusAsync(data),
  updateOrderOrderItem: data => updateOrderOrderItem(data)
};

const mapStateToProps = state => {
  return {
    searchFields: searchFields(state),
    ordersList: ordersList(state),
    helpData: helpData(state),
    searchLoading: searchLoading(state),
    ordersViewErrors: ordersViewErrors(state),
    noOrders: noOrders(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersView);
