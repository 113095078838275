import React from 'react';
import PropTypes from 'prop-types';

export const DropDownItem = props => {
  return props.link ? (
    <li>
      <a title={props.tooltip || ''} target={props.target} href={props.link}>
        {props.children}
      </a>
    </li>
  ) : (
    <li>
      <a title={props.tooltip || ''} onClick={props.onSelect} style={{ minWidth: '200px' }}>
        {props.children}
      </a>
    </li>
  );
};

DropDownItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onSelect: PropTypes.func,
  link: PropTypes.string,
  target: PropTypes.string,
  tooltip: PropTypes.string
};

export default DropDownItem;
