import React from 'react';
import PropTypes from 'prop-types';
import './SpinnerSmall.scss';

export const SpinnerSmall = props => (
  <span className={`spinner-small-container ${props.className}`} />
);

SpinnerSmall.propTypes = {
  className: PropTypes.string
};

export default SpinnerSmall;
