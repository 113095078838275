import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import {
  ADD_VARIANT,
  DELETE_PRODUCT,
  DELETE_VARIANTS,
  SAVE_SKU_MOCKUP,
  updateSkuPreview
} from './ProductVariantsActions';
import { selectedVariantsSelector } from '../../ProvidersTab/ProvidersTabSelectors';
import { publishDataSelector } from '../../../ProductPublishSelectors';
import actionsService from '../../../../../services/actionsService';
import {
  fetchAsync,
  fetchAsyncFail,
  fetchAsyncSuccess,
  skuSelect,
  PUBLISH_PREPARE,
  setDisabledSkus
} from '../../../../../store/actions/dataActions';
import { hashHistory } from 'react-router';
import { delay } from 'redux-saga';
import { goto } from '../../../../../containers/NavManager/NavManagerActions';
import {
  disabledSKUsSelector,
  selectedSKUsSelector
} from '../../../../../store/selectors/productDataSelectors';
import { updateAssociatedSkus } from '../../../../ProductPreview/ProductPreviewActions';

function* saveSkuMockupHandler(action) {
  const variants = yield select(selectedVariantsSelector);
  const index =
    action.payload.selectedSkuIndex !== undefined && action.payload.selectedSkuIndex !== -1
      ? action.payload.selectedSkuIndex
      : variants.findIndex(v => v.get('selected'));

  yield put(
    updateSkuPreview({
      ...action.payload,
      selectedSkuIndex: index
    })
  );

  const selectedSkus = yield select(selectedSKUsSelector);
  const selectedItem =
    selectedSkus && selectedSkus.toJS().find(x => x.index === action.payload.selectedSkuIndex);

  yield put(updateAssociatedSkus(selectedItem, action.payload.skuMockupUrl));
}

function* deleteVariantsHandler() {
  const publishData = yield select(publishDataSelector);
  const selectedSkus = yield select(selectedSKUsSelector);
  const disabledSkus = yield select(disabledSKUsSelector);

  // find selected variants...
  const skusToDelete = publishData
    .getIn(['storage', 'variants'])
    .filter(v => v.get('selected'))
    .map(v => v.get('sku'));

  // remove deleted skus from `selectedSkus`
  const filteredSkus = selectedSkus.filter(s => !skusToDelete.includes(s.get('sku')));
  yield put(skuSelect(filteredSkus.toJS()));

  // remove deleted skus from `disabledSkus`
  const filteredDisabledSkus = disabledSkus.filter(s => !skusToDelete.includes(s.get('sku')));
  yield put(setDisabledSkus(filteredDisabledSkus));

  yield put(fetchAsync(PUBLISH_PREPARE));
}

function* deleteProductHandler() {
  try {
    const publishData = yield select(publishDataSelector);

    // get products IDs, first from storage and then from stores
    const productIds = [
      {
        id: publishData.getIn(['storage', 'productId']),
        storeId: -1
      }
    ];
    publishData.get('stores').forEach(s => {
      productIds.push({
        id: s.get('productId'),
        storeId: s.get('id')
      });
    });

    const request = fromJS([productIds.find(pid => pid.id !== null)]);

    yield call([actionsService, actionsService.deleteProducts], request);
    yield put(fetchAsyncSuccess(DELETE_PRODUCT));
  } catch (err) {
    yield put(fetchAsyncFail(DELETE_PRODUCT, err));
  }
}

function* deleteSuccessHandler() {
  // taken from ProductsSaga -> publishAsyncSuccessHandler
  yield call(delay, 500);
  hashHistory.push('/hub/all');
}

function* addVariantHandler() {
  yield put(goto('SKUSelection'));
}

function* watchSaveSkuMockup() {
  yield takeLatest(SAVE_SKU_MOCKUP, saveSkuMockupHandler);
}

function* watchDeleteVariants() {
  yield takeLatest(DELETE_VARIANTS, deleteVariantsHandler);
}

function* watchDeleteProduct() {
  yield takeLatest(DELETE_PRODUCT.ASYNC, deleteProductHandler);
}

function* watchDeleteSuccess() {
  yield takeLatest([DELETE_PRODUCT.SUCCESS], deleteSuccessHandler);
}

function* watchAddVariant() {
  yield takeLatest(ADD_VARIANT, addVariantHandler);
}

export default function* rootSaga() {
  yield all([
    watchSaveSkuMockup(),
    watchDeleteVariants(),
    watchDeleteProduct(),
    watchDeleteSuccess(),
    watchAddVariant()
  ]);
}
