import React from 'react';
import PropTypes from 'prop-types';
import StoreConnect from '../shared/StoreConnect';
import analyticsService from 'gooten-components/src/services/analyticsService';

import './EtsyConnect.scss';

// eslint-disable-next-line
const description = (
  <span>
    Please provide the name of your store (you can find it inside your URL as <br />
    pictured below) and click Connect. You'll be redirected to your Etsy account <br />
    and prompted to allow Gooten to access it.
  </span>
);

const betaNotification =
  // eslint-disable-next-line no-multi-str
  'This is an early-release beta integration. If this is a critical piece of your business, we \
strongly recommend that you wait a few weeks until we’re finished with the official integration.';

class EtsyConnect extends React.Component {
  static propTypes = {
    connectStore: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { notification: { text: betaNotification, type: 'success' } };
    analyticsService._track('Hub - Integrations', 'Accessed', 'Etsy', '/etsy/connect-store', null);
  }

  render() {
    return (
      <div className="etsy-connect-container bla">
        <StoreConnect
          storeProvider="Etsy"
          description={description}
          trademark
          connectStore={storeName => this.props.connectStore(storeName)}
        >
          <div className="flex-container border-block-img-etsy-container">
            <div className="border-block-img" />
          </div>
        </StoreConnect>
      </div>
    );
  }
}

export default EtsyConnect;
