import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BenefitsCard from 'gooten-components/src/components/shared/PartnerTier/BenefitsCard';
import { tierBenefits, tierRanks } from 'gooten-components/src/components/shared/PartnerTier';

const NextTierBenefitsCard = ({ status, annual }) => {
  const [tierBenefitsData, setTierBenefitsData] = useState([]);

  useEffect(() => {
    setTierBenefitsData(tierBenefits[status]);
  }, [status]);

  return (
    <>
      <p className={'title-1 mb-4 pt-4 ' + (annual ? '' : 'pl-4')}>
        NEXT VIM STATUS ({tierRanks.find(x => x.name === status).displayName}) Benefits
      </p>
      <div className="d-flex flex-wrap p-4">
        {tierBenefitsData.map((b, i) => (
          <BenefitsCard
            key={i}
            icon={b.icon}
            text={b.text}
            viewAll={i === tierBenefitsData.length - 1}
          />
        ))}
        <div style={{ flex: '1 0 220px', height: 0 }} />
      </div>
      <style jsx>{`
        .title-1 {
          font-size: 11px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #646b72 !important;
        }
      `}</style>
    </>
  );
};

NextTierBenefitsCard.propTypes = {
  status: PropTypes.string
};

export default NextTierBenefitsCard;
