import { ActionNameFactory } from '../../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'IMAGE_UPLOAD_MODAL');

// ------------------------------------
// Constants
// ------------------------------------
export const IMAGE_FILE_UPLOAD = getActionName.async('IMAGE_FILE_UPLOAD');
export const SHOW_LOADING = getActionName('SHOW_LOADING');
export const SHOW_UPLOAD = getActionName('SHOW_UPLOAD');
export const RESET = getActionName('RESET');

export const showLoading = payload => ({
  type: SHOW_LOADING,
  payload
});

export const showUpload = payload => ({
  type: SHOW_UPLOAD,
  payload
});

export const reset = () => ({
  type: RESET
});
