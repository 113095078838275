import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import reprintService from '../../../ReprintSelfService/ReprintSelfServiceService';
import Card from 'gooten-components/src/components/shared/Card';
import Button from 'gooten-components/src/components/shared/Button';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import Select from 'gooten-components/src/components/shared/Select';
import { COLORS } from 'gooten-components/src/constants';

const NotesCard = props => {
  const [allReasonsData, setAllReasonsData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [adminNotes, setAdminNotes] = useState(undefined);
  const [reason, setReason] = useState(undefined);
  const [editFieldError, setEditFieldError] = useState(null);
  useEffect(() => {
    setAdminNotes(props.Meta ? unescape(props.Meta.AdminComments) : '');
    setReason(
      props.Meta && props.Meta.RefundReprintReason ? unescape(props.Meta.RefundReprintReason) : null
    );
  }, [props.Meta]);

  async function fetchAllReasons() {
    if (!allReasonsData.length) {
      const res = await reprintService.getAllReasons();
      const reasons = res.map(x => x.ReasonName);
      const currentReason = unescape(props.Meta.RefundReprintReason);
      if (props.Meta && props.Meta.RefundReprintReason && !reasons.includes(currentReason)) {
        reasons.push(currentReason);
      }
      setAllReasonsData(reasons);
    }
  }

  useEffect(() => {
    fetchAllReasons();
  }, [props.Meta]);

  const zendeskTicketId = useMemo(() => {
    return props.Meta && props.Meta.ZendeskTicketId && unescape(props.Meta.ZendeskTicketId);
  }, [props.Meta]);

  const submitUpdatedMeta = () => {
    if (adminNotes.length) {
      props.updateMeta({
        ...props.Meta,
        AdminComments: adminNotes,
        RefundReprintReason: reason
      });
      handleCancel();
    } else {
      setEditFieldError('You cannot submit an empty notes field');
    }
  };

  const updateReason = value => {
    setReason(value);
  };

  const handleCancel = () => {
    // cancel without saving reverts to old Meta info
    if (unescape(props.Meta.AdminComments) !== adminNotes) {
      setAdminNotes(unescape(props.Meta.AdminComments));
    }
    if (props.Meta.RefundReprintReason !== reason) {
      setReason(props.Meta.RefundReprintReason);
    }
    setShowEdit(false);
    setEditFieldError(null);
  };
  return (
    <>
      <Card className="mt-3 m-0" title={props.title} type={'fullwidth'}>
        {!!props.itemIds.length && (
          <div className="d-flex justify-content-between mb-1">
            <div>Item ID</div>
            <div className="body-text-2">{props.itemIds}</div>
          </div>
        )}
        {props.Meta && props.Meta.RefundTransactionId && (
          <div className="d-flex justify-content-between mb-3">
            <div>Refund transaction ID</div>
            <div className="body-text-2">{props.Meta.RefundTransactionId}</div>
          </div>
        )}
        {reason && (
          <div className="d-flex justify-content-between mb-1">
            {showEdit ? (
              <Select
                label="Select a Reason"
                placeholder="Select a Reason"
                value={reason || ''}
                onChange={e => updateReason(e.target.value)}
              >
                {allReasonsData.map(r => (
                  <option key={r} value={r}>
                    {r}
                  </option>
                ))}
              </Select>
            ) : (
              <>
                <div>Reason</div>
                <div className="body-text-2">{reason}</div>
              </>
            )}
          </div>
        )}
        {zendeskTicketId && (
          <div className="d-flex justify-content-between mb-1">
            <div>Zendesk ticket ID</div>
            <div className="body-text-2">{zendeskTicketId}</div>
          </div>
        )}
        <textarea
          onChange={e => setAdminNotes(e.target.value)}
          rows="4"
          className="w-100 p-3"
          disabled={!showEdit}
          value={adminNotes}
        />
        {editFieldError && <div className="caption-text">{editFieldError}</div>}
        <div className="buttons-row mt-3">
          {showEdit ? (
            <>
              <Button onClick={() => submitUpdatedMeta()} className="button-primary large mr-2">
                Save notes
              </Button>
              <Button onClick={() => handleCancel()} className="button-default large mr-2">
                Cancel
              </Button>
            </>
          ) : (
            <Button onClick={() => setShowEdit(true)} className="button-default large mr-2">
              Edit notes
            </Button>
          )}
        </div>

        {props.Meta.ImageEvidence && (
          <div className="image-evidence mt-4 mb-2">
            <div className="overline">Image evidence</div>
            <div className="notes-images-container ml-n1 mr-n1 d-flex flex-wrap justify-content-between">
              {props.Meta.ImageEvidence.split(',').map((evidence, key) => (
                <a className="m-1" key={key} target="_blank" href={evidence}>
                  <Thumbnail className="large m-0" image={evidence} alt="image evidence" />
                </a>
              ))}
            </div>
          </div>
        )}
      </Card>
      <style jsx>
        {`
          textarea {
            border-radius: 4px;
            border: 1px solid ${editFieldError ? COLORS.cayennePepper : COLORS.neutralLight400};
          }
        `}
      </style>
    </>
  );
};

NotesCard.propTypes = {
  Meta: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  itemIds: PropTypes.string.isRequired,
  updateMeta: PropTypes.func.isRequired
};

export default NotesCard;
