import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import { Cell } from 'gooten-components/src/components/shared/Table';

const getValue = (o, item) => {
  const valueId = item.get(o.get('id'));
  const value = o.get('values').find(v => v.get('id') === valueId);
  if (!value) {
    return 'undefined';
  }
  return value.get('name');
};

export const SKUsListItem = props => {
  const select = props.select;

  const columns = props.options.map(o => {
    return (
      <Cell className="option-column hidden-sm hidden-xs" key={props.item.get('sku') + o.get('id')}>
        <div className="col-md-12 col-sm-6 col-xs-6">{getValue(o, props.item)}</div>
      </Cell>
    );
  });

  const columnsMobile = props.options.map(o => {
    return (
      <div className="option-column row" key={props.item.get('sku') + o.get('id')}>
        <div className="col-sm-6 col-xs-6 visible-sm visible-xs text-right">
          <b>{o.get('name')}:</b>
        </div>
        <div className="col-md-12 col-sm-6 col-xs-6 p-0">{getValue(o, props.item)}</div>
      </div>
    );
  });
  return (
    <>
      {/* Desktop View */}
      <Cell className="selection-column centered hidden-sm hidden-xs">
        <Button className="button-primary small" onClick={() => select(props.item)}>
          Select
        </Button>
      </Cell>
      {columns}
      <Cell className="price-column hidden-sm hidden-xs">
        <div className="col-md-11 col-sm-6 col-xs-6 price-value">
          <span className="no-wrap">$ {props.item.get('maxCost').toFixed(2)}</span>
        </div>
      </Cell>

      {/* Mobile view below */}
      <Cell className="visible-sm visible-xs selection-column centered pt-2">
        <Button className="button-primary small" onClick={() => select(props.item)}>
          Select
        </Button>
      </Cell>
      <Cell className="visible-sm visible-xs pt-2 pb-2">
        <div className="products-column-mobile row w-100">
          {columnsMobile}
          <div className="col-sm-6 col-xs-6 visible-sm visible-xs text-right">
            <b>Price:</b>
          </div>
          <div className="col-md-11 col-sm-6 col-xs-6 price-value">
            <span className="no-wrap">$ {props.item.get('maxCost').toFixed(2)}</span>
          </div>
        </div>
      </Cell>

      <style jsx>
        {`
          .products-column-mobile {
            flex-direction: column;
            line-height: 1.2;
            white-space: normal;
            word-break: break-word;
          }
        `}
      </style>
    </>
  );
};

SKUsListItem.propTypes = {
  item: PropTypes.any.isRequired,
  options: PropTypes.instanceOf(List).isRequired,
  select: PropTypes.func.isRequired
};

export default SKUsListItem;
