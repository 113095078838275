import { connect } from 'react-redux';
import Webhooks from './Webhooks';
import { initFor, changeFor, cancelFor, updateFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { testConnection } from './WebhooksActions';

const init = initFor(Webhooks.name);
const change = changeFor(Webhooks.name);
const cancel = cancelFor(Webhooks.name);
const update = updateFor(Webhooks.name);
const cardSelector = cardSelectorFor(Webhooks.name);

const mapDispatchToProps = { init, change, cancel, update, testConnection };

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    unmodified: cardState.get('unmodified'),
    testConnectionStatus: cardState.get('testConnectionStatus'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);
