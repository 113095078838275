import {Group} from 'konva';
// import {KonvaLayer} from '../../flow/konvaTypes';
import {imageUpdate} from '../../state/actions/actionCreators';
import TooltipComponent from './tooltip/tooltip.component';
import ImageService from '../image/image.service';
import EventEmitter from 'events';
import { PUBLIC_EVENTS, INTERNAL_EVENTS } from '../../ImageEditor.events';

export default class TouchAction {
  actionGroup: Group;
  actionRectElm: any
  tooltip: TooltipComponent;
  publicEvents: EventEmitter;

  constructor(actionGroup: Group, actionRectElm: any, tooltip: TooltipComponent, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;
    this.actionGroup = actionGroup;
    this.actionRectElm = actionRectElm;
    this.tooltip = tooltip;
  }

  draw(ctx: any) {
    // Mobile Zoom
    let scale, baseScaleLng, scaleMultiplier;
    let rotation, baseDegrees, rotationDelta;
    //After starting drag mode multi-touch events should be disabled
    let dragMode = false;

    let stageContent = ctx.stage.getContent();
    this.actionRectElm.addEventListener('touchstart', (evt) => {
      if (evt.touches.length > 1) {
        scale = ctx.zoomApi.getCurrentScale();
        rotation = ImageService.getSelectedImageCanvasState(ctx.state).rotation;
        baseScaleLng = undefined;
        baseDegrees = undefined;
        stageContent.addEventListener('touchmove', this.touchMoveHandler, false);
        stageContent.addEventListener('touchend', this.touchEndHandler, false);
      }

    });
    this.touchMoveHandler = (evt) => {

      if (evt.touches.length > 1 && !dragMode){
        //Concurrency wins multi-touch
        this.actionRectElm.touched = true;

        this.actionRectElm.rotating = true;
        this.actionGroup.visible(false);

        //Scale calculation
        let lng = Math.sqrt(Math.pow(evt.touches[1].clientX - evt.touches[0].clientX, 2) + Math.pow(evt.touches[1].clientY - evt.touches[0].clientY, 2));
        if (lng > 50) {
          if (baseScaleLng === undefined) {
            baseScaleLng = lng;
          }
          scaleMultiplier = lng / baseScaleLng;
          ctx.zoomApi.interactImageScale(scale * scaleMultiplier);
        }

        //Rotation calculation
        let deg = evt.rotation
          || Math.atan2(evt.touches[0].clientY - evt.touches[1].clientY, evt.touches[0].clientX - evt.touches[1].clientX) * 180 / Math.PI;

        if (baseDegrees === undefined) {
          baseDegrees = deg;
        }

        rotationDelta = baseDegrees - deg;
        this.actionGroup.setRotation(deg);

        // do emit imageUpdate
        let eventArgs = {
          imageId: ctx.state.images.current.selected.imageId,
          rotation: rotation - rotationDelta
        };
        ctx.events.emit(INTERNAL_EVENTS.IMAGE_UPDATE, eventArgs);

        let middlePos = {
          x : ((evt.touches[0].pageX - stageContent.offsetLeft + evt.touches[1].pageX - stageContent.offsetLeft) / 2) - 15,
          y : ((evt.touches[0].pageY - stageContent.offsetTop + evt.touches[1].pageY - stageContent.offsetTop) / 2) - 15
        };

        this.tooltip.show(ctx, (rotation - rotationDelta) % 360, middlePos);
        ctx.stage.batchDraw();

      }
      else if (evt.touches.length === 1 && !this.actionRectElm.touched) {
        //Concurrency wins drag
        dragMode = true;
      }
    };

    this.touchEndHandler = (evt) => {
      //Clear flag
      dragMode = false;

      stageContent.removeEventListener('touchmove', this.touchMoveHandler);
      stageContent.removeEventListener('touchend', this.touchEndHandler);

      this.actionGroup.visible(true);
      //hide indicators after rotataion
      this.tooltip.hide();

      //Apply scale change
      if (baseScaleLng !== undefined) {
        ctx.zoomApi.applyImageScale(scale * scaleMultiplier);
      }

      //Apply rotation change
      if (this.actionRectElm.touched && this.actionRectElm.rotating) {
        this.actionRectElm.touched = false;
        this.actionRectElm.rotating = false;
        //hide indicators after rotataion
        this.tooltip.hide();

        // update image layer state
        ctx.actionProcessorCb(
          imageUpdate(
            ctx.state.images.current.selected.imageId, {
              rotation : rotation - rotationDelta
            }
          )
        );

        //send public event
        this.publicEvents.emit(PUBLIC_EVENTS.ROTATE, rotation - rotationDelta);
      }

    };


  }

}
