import React from 'react';
import PropTypes from 'prop-types';
import './SizeSelect.scss';
import Input from '../Input';

class SizeSelect extends React.Component {
  static propTypes = {
    updatePreviewSize: PropTypes.func,
    config: PropTypes.object,
    previewSize: PropTypes.object,
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      widthInvalid: false,
      heightInvalid: false,
      width: this.props.previewSize ? this.props.previewSize.width : this.props.config.defaultWidth,
      height: this.props.previewSize
        ? this.props.previewSize.height
        : this.props.config.defaultHeight
    };
  }

  handleChange(width, height) {
    if (this.props.disabled) return;
    const updatedSize = {
      width: Number(width),
      height: Number(height)
    };
    const widthInvalid =
      !updatedSize.width ||
      updatedSize.width < this.props.config.minWidth ||
      updatedSize.width > this.props.config.maxWidth;
    const heightInvalid =
      !updatedSize.height ||
      updatedSize.height < this.props.config.minHeight ||
      updatedSize.height > this.props.config.maxHeight;
    this.setState({
      widthInvalid,
      heightInvalid,
      width,
      height
    });
    if (!widthInvalid && !heightInvalid) {
      this.props.updatePreviewSize(updatedSize);
    }
  }

  getInputClass(invalid) {
    return invalid ? 'control-group has-error' : 'control-group has-success';
  }

  render() {
    const validationText = this.state.widthInvalid
      ? `Width should be number in range from ${this.props.config.minWidth} to ${this.props.config.maxWidth}`
      : this.state.heightInvalid
      ? `Height should be number in range from ${this.props.config.minHeight} to ${this.props.config.maxHeight}`
      : null;

    return (
      <div className={`component-size-select text-left${this.props.disabled ? ' disabled' : ''}`}>
        <div className="row">
          <div className={'size-input col-md-5 col-sm-3 col-xs-5'}>
            <div className={this.getInputClass(false)}>
              <Input
                type="text"
                label={'Width'}
                disabled={false}
                value={this.state.width}
                placeholder="px"
                onChange={e => this.handleChange(e.target.value, this.state.height)}
              />
            </div>
          </div>
          <div className="col-xs-1 text-center size-delimiter mt-n3">x</div>
          <div className={'size-input col-md-5 col-sm-3 col-xs-5'}>
            <div className={this.getInputClass(false)}>
              <Input
                type="text"
                label={'Height'}
                disabled={false}
                value={this.state.height}
                placeholder="px"
                onChange={e => this.handleChange(this.state.width, e.target.value)}
              />
            </div>
          </div>
        </div>
        {validationText ? (
          <div className="col-sm-12 control-group has-error">
            <span className="help-block">{validationText}</span>
          </div>
        ) : null}
      </div>
    );
  }
}

SizeSelect.displayName = 'SizeSelect';

export default SizeSelect;
