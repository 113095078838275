import React from 'react';
import PropTypes from 'prop-types';
import StoreConnect from '../shared/StoreConnect';
import analyticsService from 'gooten-components/src/services/analyticsService';

import './WooConnect.scss';

// eslint-disable-next-line
const description = (
  <span>
    Please provide the name of your store (you can find it inside your URL as pictured below) and
    click Connect. You’ll be redirected to your WooCommerce account and prompted to allow Gooten to
    access it.
  </span>
);

class WooConnect extends React.Component {
  static propTypes = {
    connectStore: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    analyticsService._track(
      'Hub - Integrations',
      'Accessed',
      'WooCommerce',
      '/woocommerce/connect-store',
      null
    );
  }

  render() {
    return (
      <div className="woo-connect-container">
        <StoreConnect
          storeProvider="WooCommerce"
          description={description}
          inputPlaceholder="my-amazing-store.io"
          connectStore={storeName => this.props.connectStore(storeName)}
        >
          <div className="flex-container border-block-img-woo-container">
            <div className="border-block-img" />
          </div>
        </StoreConnect>
      </div>
    );
  }
}

export default WooConnect;
