import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const UPLOAD = customActionFor('PROFILE').async('UPLOAD');

// ------------------------------------
// Actions
// ------------------------------------

export function uploadStart(payload) {
  return {
    type: UPLOAD.ASYNC,
    payload
  };
}

export function uploadDone(payload) {
  return {
    type: UPLOAD.SUCCESS,
    payload
  };
}

export function uploadFailed(payload) {
  return {
    type: UPLOAD.FAIL,
    payload
  };
}
