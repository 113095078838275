import { connect } from 'react-redux';
import ProductDesignView from './ProductDesignView';
import { allStoresSelector } from '../../HubView/components/Stores/StoresSelectors';
import { hasSelectedSkusSelector, linkStateIsReadySelector } from './ProductDesignViewSelectors';
import { prepareLinkData } from './ProductDesignViewActions';
import {
  selectedStoreNameSelector,
  selectedStoreProductSelector,
  selectedGootenProductSelector,
  selectedMappingsSelector,
  flowVerifiedSelector
} from '../LinkProductSelectors';

const mapDispatchToProps = {
  prepareLinkData: (data, stores) => prepareLinkData(data, stores)
};

const mapStateToProps = (state, ownProps) => {
  const flowVerified = flowVerifiedSelector(state);

  return !flowVerified
    ? { flowVerified }
    : {
        flowVerified,
        selectedStoreName: selectedStoreNameSelector(state),
        hasSelectedSkus: hasSelectedSkusSelector(state),
        isReady: linkStateIsReadySelector(state),
        stores: allStoresSelector(state),
        storeProduct: selectedStoreProductSelector(state),
        product: selectedGootenProductSelector(state),
        mappings: selectedMappingsSelector(state)
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDesignView);
