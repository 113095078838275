'use strict';
// @flow

import templateReducer from './templateReducer';
import editorReducer from './editorReducer';
import imageReducer from './imageReducer';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

export default {
  template: templateReducer,
  editor: editorReducer,
  images: imageReducer
};
