import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import {
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail
} from 'gooten-components/src/store/actions/dataActions';

export const getActionName = ActionNameFactory('ORDERS', 'HISTORY_VIEW');

export const FETCH_HISTORY_DATA = getActionName.async('FETCH_HISTORY_DATA');
export const SET_FILTERS = getActionName('SET_FILTERS');
export const UPDATE_PAGE_NUMBER = getActionName('UPDATE_PAGE_NUMBER');
export const UPDATE_ORDER_ID = getActionName('UPDATE_ORDER_ID');

export const fetchHistoryDataAsync = () => fetchAsync(FETCH_HISTORY_DATA);
export const fetchHistoryDataSuccess = payload => fetchAsyncSuccess(FETCH_HISTORY_DATA, payload);
export const fetchHistoryDataFail = payload => fetchAsyncFail(FETCH_HISTORY_DATA, payload);

export function setFilters(filterObject) {
  return {
    type: SET_FILTERS,
    payload: filterObject
  };
}

export function updatePageNumber(pageNumber) {
  return {
    type: UPDATE_PAGE_NUMBER,
    payload: { pageNumber }
  };
}

export function updateOrderId(orderId) {
  return {
    type: UPDATE_ORDER_ID,
    payload: { orderId }
  };
}
