import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'gooten-components/src/components/shared/SearchInput';

export const Search = props => {
  return (
    <div className="w-100">
      <SearchInput searchText={props.searchText} onSearchChange={props.doSearch} />
    </div>
  );
};

Search.propTypes = {
  doSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string
};

export default Search;
