import StateManager from '../../../state/stateManager';
import { imageDelete } from '../../../state/actions/actionCreators';
// import PositioningService from '../../../utils/positioningService';
import EventEmitter from 'events';
import { PUBLIC_EVENTS } from '../../../ImageEditor.events';

export class RemoveButton {
  customClass: string;
  name: string;
  publicEvents: EventEmitter;
  element: any;
  isActive: boolean;
  handler: () => void;

  constructor(stateManager: StateManager, config: any, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;
    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Remove';
    this.stateManager = stateManager;
    this.stateManager.subscribe(state => this.onStateChange(state));
  }

  onStateChange(state) {
    if (!this.element) {
      return;
    }
    
    const active = !state.getIn(['editor', 'cropActivated']);
    if (this.isActive !== active) {
      this.isActive = active;
      this.updateButtonState();
    }
  }

  updateButtonState() {
    this.element.className = ` editor-btn ${this.customClass} ${!this.isActive ? 'disabled' : ''}`;
    if (this.handler) {
      this.element.removeEventListener('click', this.handler);
      this.handler = null;
    }

    if (this.isActive) {
      this.handler = () => this.action();
      this.element.addEventListener('click', this.handler);
    }
  }

  getElement() {
    this.element = document.createElement('div');
    this.element.innerHTML = `<div class="icon-remove toolbar-icon"></div>
      <div class="name">${this.name}</div>`;

    this.updateButtonState();
    return this.element;
  }

  action() {
    const confirmFn = () => {
      let state = this.stateManager.getState();
      if (state.images.current.selected.imageId) {
        this.stateManager.dispatchAction(imageDelete(state.images.current.selected.imageId));
      }
      else if (state.images.current.images.length === 1) {
        this.stateManager.dispatchAction(imageDelete(state.images.current.images[0].id));
      }
    };

    // if no listeners then remove without confirmation
    if (this.publicEvents.listenerCount(PUBLIC_EVENTS.REMOVE) === 0) {
      confirmFn();
    }

    this.publicEvents.emit(PUBLIC_EVENTS.REMOVE, { confirm: confirmFn });
  }
}
