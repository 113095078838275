import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { object, string } from 'yup';
import Input from 'gooten-components/src/components/shared/Input';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';

// const validateStore = (value) => {
//   let storeName = value.replace('https://', '')
//   storeName = storeName.replace('http://', '')
//   storeName = storeName.replace(/\.myshopify\.com.*$/g, '')
//   storeName = storeName.trim()
//   const specialChars = new RegExp(/[ !@#$%^&*()_+=[\]{};':"\\|,.<>/?]/g)
//   var wwwTest = new RegExp('www.')
//   if (wwwTest.test(storeName) || specialChars.test(storeName)) {
//     return 'Store address is invalid. Use your .myshopify URL.'
//   } else {
//     return new Promise(resolve => {
//       fetch(`/admin/Account/IsStoreConnected?store=${encodeURIComponent(storeName)}&partnerId=0`)
//         .then((response) => {
//           response.json().then(resp => {
//             if (resp.isConnected) {
//               resolve('Shopify store is already connected to another partner.')
//             } else {
//               resolve('')
//             }
//           })
//         })
//     })
//   }
// }

const StoreDetails = props => {
  const filteredErrors = props.errors.filter(error => error !== "Shopify store doesn't exist");

  const getUrlSuffix =
    props.storeType === 'Shopify'
      ? '.myshopify.com'
      : props.storeType === 'BigCommerce'
      ? '.mybigcommerce.com'
      : '';

  // Used for BigCommerce store name validation
  async function fetchWithTimeout(resource, options) {
    const { timeout = 5000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      mode: 'no-cors',
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
  }

  async function validateBigCommerceUrl(values, actions) {
    const formattedBCStoreName = values.storeUrl
      .replace('.mybigcommerce.com', '')
      .replace(/[^a-zA-Z0-9-]/g, '');
    const fullBCUrl = 'https://' + formattedBCStoreName + '.mybigcommerce.com';

    try {
      await fetchWithTimeout(fullBCUrl, {
        timeout: 5000
      }).then(response => {
        if (response) {
          props.onSubmit(values);
          actions.setSubmitting(false);
        }
      });
    } catch (error) {
      actions.setErrors({ storeUrl: 'Please provide a valid BigCommerce url' });
      console.warn(`Timeout Error. Big Commerce url ${fullBCUrl} likely does not exist`);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur
      initialValues={{
        companyName: '',
        storeUrl: '',
        agreeToTerms: false
      }}
      validationSchema={() =>
        object().shape({
          companyName: string().required('Please provide the name of your store.'),
          storeUrl: string().required('Please provide a valid URL of your store'),
          agreeToTerms: string().matches(
            /true/,
            "Please agree to Gooten's Privacy Policy and Terms of Service to continue."
          )
        })
      }
      onSubmit={(values, actions) => {
        if (props.storeType === 'BigCommerce') {
          validateBigCommerceUrl(values, actions);
        } else {
          props.onSubmit(values);
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        submitCount,
        isSubmitting
      }) => (
        <div className="sign-up-card" style={{ display: !props.show && 'none' }}>
          <div className="main-header">Sign Up</div>
          <div className="main-subheader">No Credit Card Required. Takes 90 seconds!</div>
          <div className="card-section">
            <div className="card-header">YOUR STORE</div>
            <div className="progress-step">
              STEP <span className="current-step">3</span>/3
            </div>
          </div>
          <Input
            className="signup-companyname"
            name="companyName"
            type="text"
            label="Company Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.companyName}
            disabled={props.isSubmitting}
            hasError={submitCount > 0 && !!errors.companyName}
            errorText={submitCount > 0 && !!errors.companyName ? errors.companyName : ''}
          />
          <div className="store-input">
            {/* <Field name='storeUrl' validate={props.storeType === 'Shopify' ? validateStore : null}> */}
            <Field name="storeUrl">
              {({ field, form }) => (
                <Input
                  className="signup-storename"
                  name="storeUrl"
                  type="text"
                  label={props.storeType === 'Shopify' ? 'Shopify URL' : 'Your Store URL'}
                  placeholder={
                    props.storeType === 'Shopify' || props.storeType === 'BigCommerce'
                      ? `your-store${getUrlSuffix}`
                      : ''
                  }
                  onChange={handleChange}
                  maxLength={60}
                  onBlur={handleBlur}
                  value={values.storeUrl}
                  disabled={props.isSubmitting}
                  hasError={
                    (submitCount > 0 && !!errors.storeUrl) ||
                    props.errors.includes("Shopify store doesn't exist") ||
                    errors.storeUrl === 'Shopify store is already connected to another partner.'
                  }
                  errorText={
                    errors.storeUrl || props.errors.includes("Shopify store doesn't exist")
                      ? errors.storeUrl || "Shopify store doesn't exist"
                      : ''
                  }
                />
              )}
            </Field>
          </div>
          {/* Below is css for the '.myshopify' suffix to work.
          It adjusts at 27 characters, where the suffix would break the view */}
          <style jsx>
            {`
              :global(.store-input .input-container:after) {
                content: ${props.storeType === 'Shopify' || props.storeType === 'BigCommerce'
                  ? values.storeUrl.length > 27
                    ? `'${getUrlSuffix}'`
                    : `'${values.storeUrl}${getUrlSuffix}'`
                  : ''};
                font-size: 1rem;
                color: #bcc4cb;
                position: absolute;
                left: ${values.storeUrl.length < 28 ? '17px' : 'auto'};
                right: ${values.storeUrl.length > 27 ? '17px' : 'auto'};
                bottom: 3px;
                z-index: -1;
                display: ${values.storeUrl ? 'block' : 'none'};
              }
              :global(.store-input .input-container),
              :global(.store-input .input-container input) {
                background: none;
                z-index: 1;
              }
              :global(.store-input .input-container input) {
                padding-right: ${values.storeUrl.length > 27 ? '8rem' : '1rem'};
              }
              .store-input {
                overflow: hidden;
              }
            `}
          </style>
          <div
            className={`sign-up-field button-spacing ${
              submitCount > 0 && !!errors.agreeToTerms ? 'field-error' : ''
            }`}
          >
            <div className="d-flex">
              <Checkbox
                className="signup-store-details-agreement mr-1"
                checked={values.agreeToTerms}
                onChange={() => setFieldValue('agreeToTerms', !values.agreeToTerms)}
                disabled={props.isSubmitting}
              />
              <p className="body-text-2 mb-1 ml-2">
                I agree to Gooten's{' '}
                <a
                  className="privacy-link"
                  href="https://www.gooten.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  className="terms-of-service-link"
                  href="https://www.gooten.com/terms"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </p>
            </div>
            {submitCount > 0 && !!errors.agreeToTerms && (
              <div className="error-text d-flex caption-text mt-n1">
                <Icon className="mr-1" icon="alertCircle" small />
                {errors.agreeToTerms}
              </div>
            )}
          </div>
          {props.storeType === 'Shopify' ? (
            <div className="caption-text pb-4">
              You will be redirected to install the Gooten app in your Shopify store
            </div>
          ) : null}
          <div className="d-flex justify-content-end">
            <Button
              className="button-default extra-large ml-2 signup-store-details-back"
              onClick={() => props.onBackButton()}
            >
              Back
            </Button>
            <Button
              className="button-primary extra-large ml-2 signup-store-details-continue"
              loading={props.isSubmitting || isSubmitting}
              onClick={() => (props.isSubmitting ? null : handleSubmit())}
            >
              Sign up to gooten
            </Button>
          </div>
          {filteredErrors.length > 0 && (
            <div className="submit-error mt-3">
              <strong className="error-text">Oops! Something went wrong.</strong>
              <ul>
                {Object.values(filteredErrors).map(err => (
                  <li key={err} className="error-text">
                    {err}
                  </li>
                ))}
              </ul>
              <p>
                If you need help, contact
                <a href="mailto:partnersupport@gooten.com">partnersupport@gooten.com</a>.
              </p>
            </div>
          )}
          <style jsx>
            {`
              .error-text {
                fill: #c80404;
                color: #c80404;
              }
            `}
          </style>
        </div>
      )}
    </Formik>
  );
};

StoreDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onBackButton: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  storeType: PropTypes.string
};

StoreDetails.defaultProps = {
  storeType: undefined
};

export default StoreDetails;
