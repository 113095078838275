import { connect } from 'react-redux';

import { storesConnectStep, storesConnectProvider, connectStore } from '../../StoresActions';

import {
  busySelector,
  connectProviderSelector,
  connectStepSelector,
  errorMsgSelector
} from '../../StoresSelectors';

import ConnectStore from './ConnectStore';

const mapStateToProps = state => ({
  busy: busySelector(state),
  connectStep: connectStepSelector(state),
  selectedProvider: connectProviderSelector(state),
  errorMsg: errorMsgSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setConnectStep: step => dispatch(storesConnectStep(step)),
  setConnectProvider: provider => dispatch(storesConnectProvider(provider)),
  connectStore: (provider, storeName) => dispatch(connectStore(provider, storeName))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectStore);
