import { fromJS } from 'immutable';
import { RESET_PUBLISH_DATA } from '../../ProductPublishActions';
import { SET_PROVIDER } from './ProvidersTabActions';

const initialState = {
  selected: null
};

const ProvidersTabReducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case SET_PROVIDER:
      return state.set('selected', action.payload);

    case RESET_PUBLISH_DATA:
      return fromJS(initialState);

    default:
      return state;
  }
};

export default ProvidersTabReducer;
