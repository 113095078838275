import { createSelector } from 'reselect';

const checkoutSelector = state => state.get('checkout');

const shippingSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('shipping');
});

const addressesObjectSelector = createSelector(shippingSelector, shipping => {
  return shipping.get('addresses');
});

export const currentPageSelector = createSelector([addressesObjectSelector], addressesObject => {
  return addressesObject.get('currentPage');
});

export const loadedPageSelector = createSelector([addressesObjectSelector], addressesObject => {
  return addressesObject.get('loadedPage');
});

export const selectedShippingAddressSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['selected', 'address']);
  }
);

export const isShippingAddressesLoadedSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.get('loaded');
  }
);

const userEnteredShippingAddressesSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.get('userItems');
  }
);

export const userEnteredShippingAddressesCountSelector = createSelector(
  userEnteredShippingAddressesSelector,
  userEnteredShippingAddresses => {
    return userEnteredShippingAddresses.size;
  }
);

const storedShippingAddressesSelector = createSelector(addressesObjectSelector, addressesObject => {
  return addressesObject.get('items');
});

export const shippingAddressesSelector = createSelector(
  [userEnteredShippingAddressesSelector, storedShippingAddressesSelector, loadedPageSelector],
  (userEnteredShippingAddresses, storedShippingAddresses, loadedPage) => {
    if (loadedPage === 1) {
      return userEnteredShippingAddresses.concat(storedShippingAddresses);
    } else {
      return storedShippingAddresses;
    }
  }
);

export const isFormVisibleSelector = createSelector(
  [addressesObjectSelector, userEnteredShippingAddressesSelector, storedShippingAddressesSelector],
  (addressesObject, userEnteredShippingAddresses, storedShippingAddresses) => {
    return (
      addressesObject.get('formVisible') ||
      (userEnteredShippingAddresses.size === 0 && storedShippingAddresses.size === 0)
    );
  }
);

export const totalAddressesSelector = createSelector(
  [addressesObjectSelector, userEnteredShippingAddressesSelector],
  (addressesObject, userEnteredShippingAddresses) => {
    return addressesObject.get('totalAddresses') + userEnteredShippingAddresses.size;
  }
);
