import { connect } from 'react-redux';
import SummaryView from './SummaryView';
import {
  countries,
  addressTypes,
  productionTimes,
  changeSkuData,
  editingShippingAddress,
  editingBillingAddress,
  isShippingAddressEditDisabledSelector
} from './SummaryViewSelectors';
import {
  getCountries,
  getAddressTypes,
  getProductionTimes,
  clearProductionTimes,
  updateEditingShippingAddress,
  updateEditingBillingAddress,
  getVariantsForSku
} from './SummaryViewActions';
import {
  saveShippingAddress,
  saveBillingAddress,
  ignoreSuggestedAddress,
  applySuggestedAddress,
  changeSku,
  setActiveImage,
  updateShippingMethod
} from '../../OrderDetails/OrderDetailsActions';
import {
  loadingStatus,
  billingData,
  showCustomerCosts
} from '../../OrderDetails/OrderDetailsSelectors';
import { itemsDataSelector } from 'gooten-components/src/store/selectors/hubSelectors';

const mapDispatchToProps = {
  getCountries: () => getCountries(),
  getAddressTypes: () => getAddressTypes(),
  saveShippingAddress: (orderId, data) => saveShippingAddress(orderId, data),
  saveBillingAddress: (orderId, data) => saveBillingAddress(orderId, data),
  ignoreSuggestedAddress: (orderId, data) => ignoreSuggestedAddress(orderId, data),
  applySuggestedAddress: (orderId, data) => applySuggestedAddress(orderId, data),
  getProductionTimes: (recipeId, data) => getProductionTimes(recipeId, data),
  getVariantsForSku: (partnerId, vendorId, sku) => getVariantsForSku(partnerId, vendorId, sku),
  changeSku: (changeSkuData, summaryData, sku, orderItemId) =>
    changeSku(changeSkuData, summaryData, sku, orderItemId),
  updateEditingShippingAddress: data => updateEditingShippingAddress(data),
  updateEditingBillingAddress: data => updateEditingBillingAddress(data),
  clearProductionTimes: () => clearProductionTimes(),
  setActiveImage: orderItem => setActiveImage(orderItem),
  updateShippingMethod: data => updateShippingMethod(data)
};

const mapStateToProps = state => {
  return {
    loading: loadingStatus(state),
    countries: countries(state),
    addressTypes: addressTypes(state),
    itemsData: itemsDataSelector(state),
    billingData: billingData(state),
    showCustomerCosts: showCustomerCosts(state),
    productionTimes: productionTimes(state),
    changeSkuData: changeSkuData(state),
    editingShippingAddress: editingShippingAddress(state),
    editingBillingAddress: editingBillingAddress(state),
    isShippingAddressEditDisabled: isShippingAddressEditDisabledSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
