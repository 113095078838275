import React from 'react';
import './TermsSection.scss';

class TermsSection extends React.Component {
  // This is not env specific
  termsOfServiceUrl = 'https://www.gooten.com/terms/';

  render() {
    return (
      <div className="terms-section-container">
        <p>
          Returns and refunds are subject to our&nbsp;
          <a className="terms-of-service-link" target="_blank" href={this.termsOfServiceUrl}>
            Terms of Service.
          </a>
        </p>
        <p>
          International Shipping: Please note that your country may charge Import duties, taxes and
          fees that you may have to pay ahead of delivery.
        </p>
      </div>
    );
  }
}

TermsSection.displayName = 'TermsSection';

export default TermsSection;
