import { getActionName } from '../../SKUsSelectionViewActions';

// ------------------------------------
// Constants
// ------------------------------------
export const SET_VARIANT_INDEX = getActionName('SET_VARIANT_INDEX');

// ------------------------------------
// Actions
// ------------------------------------
export const setCurrentVariantIndex = value => {
  return {
    type: SET_VARIANT_INDEX,
    payload: value
  };
};
