export default class Strings {
  static USE_DIFFERENT_ADDRESS = `Use a different address`;
  static USE_THIS_ADDRESS = `Use this address`;
  static MODAL_DELETE_ADDRESS_TITLE = `Delete address?`;
  static MODAL_DELETE_ADDRESS_TEXT =
    `Are you sure you want to delete the selected shipping address?\n` +
    `This action can not be undone.`;
  static MODAL_DELETE_ADDRESS_CONFIRM = `DELETE`;
  static CANCEL = `Cancel`;
}
