import { connect } from 'react-redux';
import {
  setUserEnteredShippingAddress,
  deleteSuggestedAddress,
  storeTempAddress,
  clearTempAddress
} from './AddressFormActions';
import {
  userEnteredShippingAddressSelector,
  isUserEnteredShippingAddressValidSelector,
  suggestedShippingAddressSelector,
  isSuggestedShippingAddressValidSelector,
  tempAddressSelector
} from './AddressFormSelectors';
import {
  cofShippingCountrySelector,
  shippingCountryFullNameSelector
} from '../../../../../store/selectors/countriesSelectors';
import AddressForm from './AddressForm';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  const shippingCountry = cofShippingCountrySelector(state);
  const shippingCountryFullName = shippingCountryFullNameSelector(state);
  const userEnteredShippingAddress = userEnteredShippingAddressSelector(state);
  const isUserEnteredShippingAddressValid = isUserEnteredShippingAddressValidSelector(state);
  const suggestedShippingAddress = suggestedShippingAddressSelector(state);
  const isSuggestedShippingAddressValid = isSuggestedShippingAddressValidSelector(state);
  const tempAddress = tempAddressSelector(state);

  return {
    shippingCountry,
    shippingCountryFullName,
    userEnteredShippingAddress,
    isUserEnteredShippingAddressValid,
    suggestedShippingAddress,
    isSuggestedShippingAddressValid,
    tempAddress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserEnteredShippingAddress: address => dispatch(setUserEnteredShippingAddress(address)),
    deleteSuggestedAddress: () => dispatch(deleteSuggestedAddress()),
    storeTempAddress: tempAddress => dispatch(storeTempAddress(tempAddress)),
    clearTempAddress: () => dispatch(clearTempAddress())
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(AddressForm);
