import { ActionNameFactory } from '../../utils/actions';
// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'PREVIEW');

export const CONFIGURE = getActionName('CONFIGURE');
export const MAXIMIZE_ON = getActionName('MAXIMIZE_ON');
export const MAXIMIZE_OFF = getActionName('MAXIMIZE_OFF');
export const FULLSCREEN = getActionName('FULLSCREEN');
export const FULLSCREEN_CURRENT_ITEMS = getActionName('FULLSCREEN_CURRENT_ITEMS');
export const SELECT_ITEMS = getActionName('SELECT_ITEMS');
export const DESELECT_ITEM = getActionName('DESELECT_ITEM');
export const DESELECT_ALL_ITEMS = getActionName('DESELECT_ALL_ITEMS');
export const GENERATE_PREVIEWS = getActionName('GENERATE_PREVIEWS');
export const GENERATE_FULLSCREEN_PREVIEW = getActionName('GENERATE_FULLSCREEN_PREVIEW');
export const GENERATE_BACKGROUNDS = getActionName('GENERATE_BACKGROUNDS');
export const UPDATE_PREVIEW_DATA = getActionName('UPDATE_PREVIEW_DATA');
export const UPDATE_PREVIEW_IMAGE_URL = getActionName('UPDATE_PREVIEW_IMAGE_URL');
export const UPDATE_ASSOCIATED_SKUS = getActionName('UPDATE_ASSOCIATED_SKUS');
export const UPDATE_SMALL_PREVIEW_IMAGE_URL = getActionName('UPDATE_SMALL_PREVIEW_IMAGE_URL');
export const UPDATE_GENERATION_STATE = getActionName('UPDATE_GENERATION_STATE');
export const UPDATE_GENERATION_ITEMS_COUNT = getActionName('UPDATE_GENERATION_ITEMS_COUNT');
export const REMOVE_GENERATED_SKUS = getActionName('REMOVE_GENERATED_SKUS');
export const ADD_GENERATED_SKU = getActionName('ADD_GENERATED_SKU');
export const UPDATE_HIGH_RES_GENERATION_STATE = getActionName('UPDATE_HIGH_RES_GENERATION_STATE');
export const UPDATE_PREVIEW_SIZE = getActionName('UPDATE_PREVIEW_SIZE');
export const UPDATE_BACKGROUNDS = getActionName('UPDATE_BACKGROUNDS');
export const REMOVE_ITEMS = getActionName('REMOVE_ITEMS');
export const SHOW_REMOVE_MODAL = getActionName('SHOW_REMOVE_MODAL');
export const SHOW_DOWNLOAD_MODAL = getActionName('SHOW_DOWNLOAD_MODAL');
export const UPDATE_DOWNLOAD_MODAL = getActionName('UPDATE_DOWNLOAD_MODAL');
export const SHOW_GENERATING_MODAL = getActionName('SHOW_GENERATING_MODAL');
export const RESTORE_ALL_ITEMS = getActionName('RESTORE_ALL_ITEMS');
export const RESTORE_LAST_DELETED_ITEMS = getActionName('RESTORE_LAST_DELETED_ITEMS');
export const RESET_NOTIFICATION_STATE = getActionName('RESET_NOTIFICATION_STATE');
export const RESET_PREVIEWS = getActionName('RESET_PREVIEWS');
export const EDIT_MODE_ON = getActionName('EDIT_MODE_ON');
export const EDIT_MODE_OFF = getActionName('EDIT_MODE_OFF');
export const SELECT_STYLE = getActionName('SELECT_STYLE');
export const UPDATE_EDIT_STATE = getActionName('UPDATE_EDIT_STATE');
export const CANCEL_EDIT_MOCKUPS = getActionName('CANCEL_EDIT_MOCKUPS');
export const RESET_EDIT_STATE = getActionName('RESET_EDIT_STATE');
export const CANCEL_PREVIEWS_GENERATION = getActionName('CANCEL_PREVIEWS_GENERATION');
export const CANCEL_FULLSCREEN_GENERATION = getActionName('CANCEL_FULLSCREEN_GENERATION');
export const SHOW_SHOPIFY_IMAGES_COUNT_MODAL = getActionName('SHOW_SHOPIFY_IMAGES_COUNT_MODAL');
export const DOWNLOAD_PREVIEWS = getActionName('DOWNLOAD_PREVIEWS');

export const configure = config => {
  return {
    type: CONFIGURE,
    payload: config
  };
};

export const updateGenerationState = value => {
  return {
    type: UPDATE_GENERATION_STATE,
    payload: value
  };
};

export const updateGenerationItemsCount = value => {
  return {
    type: UPDATE_GENERATION_ITEMS_COUNT,
    payload: value
  };
};

export const removeGeneratedSkus = skus => {
  return {
    type: REMOVE_GENERATED_SKUS,
    payload: skus
  };
};

export const addGeneratedSku = sku => {
  return {
    type: ADD_GENERATED_SKU,
    payload: sku
  };
};

export const updateHighResGenerationState = value => {
  return {
    type: UPDATE_HIGH_RES_GENERATION_STATE,
    payload: value
  };
};

export const maximizeOn = () => {
  return {
    type: MAXIMIZE_ON
  };
};

export const maximizeOff = () => {
  return {
    type: MAXIMIZE_OFF
  };
};

export const editModeOn = () => {
  return {
    type: EDIT_MODE_ON
  };
};

export const editModeOff = () => {
  return {
    type: EDIT_MODE_OFF
  };
};

export const fullScreenPreview = value => {
  return {
    type: FULLSCREEN,
    payload: value
  };
};

export const fullScreenCurrentItems = data => {
  return {
    type: FULLSCREEN_CURRENT_ITEMS,
    payload: { data, highRes: true }
  };
};

export const selectItems = value => {
  return {
    type: SELECT_ITEMS,
    payload: value
  };
};

export const deselectItem = value => {
  return {
    type: DESELECT_ITEM,
    payload: value
  };
};

export const deselectAllItems = value => {
  return {
    type: DESELECT_ALL_ITEMS,
    payload: value
  };
};

export const generatePreviews = (data, highRes, hasBcgChanged) => {
  return {
    type: GENERATE_PREVIEWS,
    payload: { data, highRes, hasBcgChanged }
  };
};

export const generateFullscreenPreview = data => {
  return {
    type: GENERATE_FULLSCREEN_PREVIEW,
    payload: { data, highRes: true }
  };
};

export const generateBackgrounds = data => {
  return {
    type: GENERATE_BACKGROUNDS,
    payload: { data, highRes: false, hasBcgChanged: true }
  };
};

export const updatePreviewData = value => {
  return {
    type: UPDATE_PREVIEW_DATA,
    payload: value
  };
};

export const updatePreviewImageUrl = (
  previewImageUrl,
  sku,
  sId,
  dataIndex,
  previewSize,
  isFullscreen
) => {
  return {
    type: UPDATE_PREVIEW_IMAGE_URL,
    payload: { previewImageUrl, sku, sId, dataIndex, previewSize, isFullscreen }
  };
};

export const updateAssociatedSkus = (selected, newUrl) => {
  return {
    type: UPDATE_ASSOCIATED_SKUS,
    payload: { selected, newUrl }
  };
};

export const updateSmallPreviewImageUrl = (imageUrl, sku, sId, dataIndex, hasBcgChanged) => {
  return {
    type: UPDATE_SMALL_PREVIEW_IMAGE_URL,
    payload: { imageUrl, sku, sId, dataIndex, hasBcgChanged }
  };
};

export const updatePreviewSize = value => {
  return {
    type: UPDATE_PREVIEW_SIZE,
    payload: value
  };
};

export const updateBackgrounds = data => {
  return {
    type: UPDATE_BACKGROUNDS,
    payload: data
  };
};

export const removeItems = items => {
  return {
    type: REMOVE_ITEMS,
    payload: items
  };
};

export const showRemoveModal = value => {
  return {
    type: SHOW_REMOVE_MODAL,
    payload: value
  };
};

export const showDownloadModal = value => {
  return {
    type: SHOW_DOWNLOAD_MODAL,
    payload: value
  };
};

export const updateDownloadModal = (current, total) => {
  return {
    type: UPDATE_DOWNLOAD_MODAL,
    payload: { current, total }
  };
};

export const showGeneratingModal = value => {
  return {
    type: SHOW_GENERATING_MODAL,
    payload: value
  };
};

export const restoreAllItems = () => {
  return {
    type: RESTORE_ALL_ITEMS
  };
};

export const restoreLastDeletedItems = () => {
  return {
    type: RESTORE_LAST_DELETED_ITEMS
  };
};

export const resetNotificationState = () => {
  return {
    type: RESET_NOTIFICATION_STATE
  };
};

export const resetPreviews = () => {
  return {
    type: RESET_PREVIEWS
  };
};

export const selectStyle = payload => {
  return {
    type: SELECT_STYLE,
    payload
  };
};

export const updateEditState = payload => {
  return {
    type: UPDATE_EDIT_STATE,
    payload
  };
};

export const cancelEditMockups = payload => {
  return {
    type: CANCEL_EDIT_MOCKUPS,
    payload
  };
};

export const resetEditState = () => {
  return {
    type: RESET_EDIT_STATE
  };
};

export const cancelPreviewsGeneration = () => {
  return {
    type: CANCEL_PREVIEWS_GENERATION
  };
};

export const cancelFullscreenGeneration = () => {
  return {
    type: CANCEL_FULLSCREEN_GENERATION
  };
};

export const showShopifyImagesCountModal = () => {
  return {
    type: SHOW_SHOPIFY_IMAGES_COUNT_MODAL
  };
};

export const downloadPreviews = () => {
  return {
    type: DOWNLOAD_PREVIEWS
  };
};
