import React from 'react';
import PropTypes from 'prop-types';
import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import Notifications from 'gooten-components/src/components/shared/Notifications';
import security from '../SecurityService';
import './SettingsLayout.scss';
import { Tab, TabContainer } from 'gooten-components/src/components/shared/Tab';
import PageBody from 'gooten-components/src/components/shared/PageBody';

const renderLink = (link, title, location) => (
  <Tab to={link} active={location.pathname.startsWith(link)} className="direct-link">
    {title}
  </Tab>
);

class SettingsLayout extends React.Component {
  componentWillUnmount() {
    this.props.clearData();
  }

  render() {
    const { router, children } = this.props;
    return (
      <CoreLayout>
        <Notifications />
        <div className="settings-layout-container">
          {security.access('nav', 'menu') ? (
            <div className="container-fluid setting-nav-bar dropshadow-1">
              <div className="m-auto">
                <TabContainer className="m-auto">
                  {renderLink('/settings/profile', 'Profile', router.location)}
                  {security.access('page', 'billing')
                    ? renderLink('/settings/billing', 'Billing', router.location)
                    : null}
                  {renderLink('/settings/api', 'API', router.location)}
                  {security.access('page', 'team')
                    ? renderLink('/settings/team', 'Team', router.location)
                    : null}
                  {security.access('page', 'fulfillment')
                    ? renderLink('/settings/fulfillment', 'Fulfillment', router.location)
                    : null}
                  {renderLink('/settings/add-ons', 'Add-ons', router.location)}
                  {renderLink('/settings/activity', 'Account activity', router.location)}
                </TabContainer>
              </div>
            </div>
          ) : null}
          <div className="custom-background" />
          <div className="content">
            <PageBody size="small" background="none" className="settings-cards-container">
              {children}
            </PageBody>
          </div>
        </div>
        <style jsx>
          {`
            .settings-layout-container :global(.tab-container) {
              width: fit-content;
            }
          `}
        </style>
      </CoreLayout>
    );
  }
}

SettingsLayout.propTypes = {
  clearData: PropTypes.func.isRequired,
  router: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired
};

export default SettingsLayout;
