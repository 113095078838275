import { fetch, postJson } from 'gooten-components/src/utils/http';
import Config from '../../../config';

class ServiceWrapper {
  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }

  // USED: Retreive all stores associated with the account
  getUserStores() {
    return fetch(`${Config.get('storeApi')}stores`, { queryParams: this.getAuthQueryParams() });
  }

  // USED: Retreive PRP Products
  getPrpProducts() {
    return fetch(`${Config.get('api')}prpproducts`, {
      queryParams: {
        recipeId: Config.get('recipeId')
      }
    });
  }

  getAllProducts() {
    return fetch(`${Config.get('storeApi')}products/all`, {
      queryParams: {
        recipeId: Config.get('recipeId'),
        apiKey: Config.get('storeApiKey'),
        connected: 'true',
        page: 1,
        pageSize: 8,
        search: ''
      }
    });
  }

  // USED: Get All Past Orders
  getPastOrders() {
    return fetch(`${Config.get('api')}orderitemssearch/`, {
      queryParams: {
        recipeId: Config.get('recipeId'),
        partnerBillingKey: Config.get('storeApiKey'),
        page: 1,
        pageSize: 1,
        sortField: 'orderItemId'
      }
    });
  }

  // USED: Retreive Partner Account Data
  getPartnerDataWithId(partnerId) {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}ShopifyAccounts/GetPartnerData`
      : '/admin/api/ShopifyAccounts/GetPartnerData';

    return fetch(url, {
      queryParams: { partnerId },
      headers: {
        Authorization: 'Bearer ' + window.localStorage.token,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  getPartnerData() {
    return this.getPartnerDataWithId(Config.get('partnerId'));
  }

  // USED: Retreive Partner Details
  getPartnerDetails() {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}PartnersApi/GetDetails`
      : '/admin/api/PartnersApi/GetDetails';

    return fetch(url, {
      queryParams: { partnerId: Config.get('partnerId') },
      headers: {
        Authorization: 'Bearer ' + window.localStorage.token,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  // USED: Update Partner details
  updatePartnerDetails(partnerDetails) {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}PartnersApi/UpdateDetails?partnerId=` + Config.get('partnerId')
      : '/admin/api/PartnersApi/UpdateDetails?partnerId=' + Config.get('partnerId');

    return postJson(url, partnerDetails, {
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.token,
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  // USED: Check if store is connected
  isStoreConnected(storeName) {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}ShopifyAccounts/IsStoreConnected`
      : '/admin/api/ShopifyAccounts/IsStoreConnected';

    return postJson(
      url,
      {
        PartnerId: Config.get('partnerId'),
        Store: storeName
      },
      {
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.token,
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
  }

  // USED: Retrieve Customer Checkout Settings partnerId=NaN
  getPaymentSettings() {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}PartnersApi/GetPaymentsSettings`
      : '/admin/api/PartnersApi/GetPaymentsSettings';

    return fetch(url, {
      queryParams: { partnerId: Config.get('partnerId') },
      headers: {
        Authorization: 'Bearer ' + window.localStorage.token,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  // Connect to shopify store
  connectToStore(storeName, oneTimeCode) {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}ShopifyAccounts/ConnectToStore`
      : '/admin/api/ShopifyAccounts/ConnectToStore';

    return postJson(
      url,
      {
        OneTimeCode: oneTimeCode,
        Store: storeName,
        partnerId: Config.get('partnerId')
      },
      {
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.token,
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
  }

  // Get Application ... key
  getAppClientKey() {
    const url = Config.get('adminApi')
      ? `${Config.get('adminApi')}ShopifyAccounts/GetAppClientKey`
      : '/admin/api/ShopifyAccounts/GetAppClientKey';

    return fetch(url, {
      queryParams: { partnerId: Config.get('partnerId') },
      headers: {
        Authorization: 'Bearer ' + window.localStorage.token,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
  }
}

// singleton
export default new ServiceWrapper();
