/* eslint-disable max-len */
import React from 'react';
import UserDetails from './pages/UserDetails';
import PropTypes from 'prop-types';
import './CreatePasswordView.scss';
import Config from '../config';
import SignUpInfo from './pages/SignUpInfo';
import Header from './pages/Header';
import { Helmet } from 'react-helmet';
import { postJson } from 'gooten-components/src/utils/http';
import { mainStyles } from 'gooten-components/src/styles.js';
import analyticsService from 'gooten-components/src/services/analyticsService';
import sha1 from 'sha1';
import ReCAPTCHA from 'react-google-recaptcha';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class CreatePasswordView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      captchaToken: '',
      data: {
        userDetails: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          country: '',
          agreeToTerms: false,
          userId: null,
          company: ''
        },
        businessDetails: {
          storeStatus: undefined,
          integration: undefined
        },
        storeDetails: {
          companyName: '',
          storeUrl: '',
          agreeToTerms: false
        },
        countries: []
      },
      isSubmitting: false,
      errors: []
    };

    document.documentElement.style.height = '100%';
    document.body.style.minHeight = '100%';
    document.body.style.background = '#0a2599';
  }

  componentDidMount() {
    this.fetchUserInfo();
    this.setState();
  }

  checkIfEmailTaken = async email => {
    const response = await fetch(
      `/admin/account/checkIfEmailTaken?email=${encodeURIComponent(email)}`
    );
    const data = await response.json();

    return data.taken;
  };

  fetchUserInfo = async () => {
    // Get User Info from Hubspot with ContactId Query param
    if (this.props.location.query && this.props.location.query.contactId) {
      try {
        const response = await fetch(
          `${Config.get('adminApi')}PartnerSignupApi/GetHubspotContact?contactId=${
            this.props.location.query.contactId
          }`,
          {
            method: 'GET'
          }
        );
        const data = await response.json();

        if (data.properties['gooten_user_id']) {
          console.error('Partner ID already exists - ', data.properties['gooten_user_id']);
          this.redirectToLogin();
          return;
        }

        if (!data.properties.email || (await this.checkIfEmailTaken(data.properties.email))) {
          console.error(
            'Email "' + data.properties.email + '" is missing or is already taken as an Admin User.'
          );
          this.redirectToLogin();
          return;
        }

        this.setState({
          data: {
            ...this.state.data,
            userDetails: {
              ...this.state.userDetails,
              firstName: data.properties.firstname,
              lastName: data.properties.lastname,
              country: data.properties.country,
              email: data.properties.email,
              userId: data.properties.gooten_user_id,
              company: data.properties.company
            },
            businessDetails: {
              ...this.state.businessDetails,
              storeStatus: data.properties.partner_program_status
            }
          }
        });
      } catch (error) {
        console.error(error);
        this.setState({ errors: ['An error occurred - unable to fetch User Details'] });
        this.redirectToSignup();
      }
    } else {
      console.error('Invalid Signup Link - unable to fetch User Details');
      this.setState({ errors: ['Invalid Signup Link - unable to fetch User Details'] });
      this.redirectToSignup();
    }
  };

  onChangeCaptcha = value => {
    this.setState({ captchaToken: value });
  };

  onUserDetails = userDetails => {
    this.setState(
      {
        data: {
          ...this.state.data,
          userDetails: {
            ...this.state.data.userDetails,
            ...userDetails
          }
        },
        isSubmitting: true
      },
      this.signUpUser
    );
  };

  signUpUser = async () => {
    const { userDetails, businessDetails, storeDetails } = this.state.data;

    let userData = {
      Name: userDetails.firstName.trim() + ' ' + userDetails.lastName.trim(),
      Email: userDetails.email,
      BusinessName: userDetails.email,
      Company: userDetails.company ? userDetails.company : userDetails.email,
      Password: userDetails.password,
      Country: userDetails.country,
      NoIntegration: true,
      Api: false,
      Mobile: false,
      WebMobile: false,
      Shopify: false,
      ShopifyStoreName: null,
      Etsy: false,
      EtsyStoreName: null,
      WooCommerce: false,
      WooCommerceStoreName: null,
      BigCommerce: false,
      BigCommerceStoreName: null,
      Website: '',
      Subdomain: window.location.host.split('.')[0],
      PartnerTierStatus: businessDetails.storeStatus
        ? capitalizeFirstLetter(businessDetails.storeStatus)
        : ''
    };

    if (businessDetails.integration === 'Shopify') {
      userData.ShopifyStoreName = userData.ShopifyStoreName.replace('https://', '')
        .replace('.myshopify.com/', '')
        .replace('.myshopify.com', '');
      userData.Website = userData.Website.replace('https://', '')
        .replace('.myshopify.com/', '')
        .replace('.myshopify.com', '');
    }

    // Submit new Partner and link new Partner ID to Hubspot
    try {
      const submitPartnerResponse = await fetch(
        `${Config.get('adminApi')}PartnerSignupApi/Submit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        }
      );
      const submitPartnerData = await submitPartnerResponse.json();

      if (submitPartnerData.HasError) {
        this.setState({
          errors: submitPartnerData.Errors,
          isSubmitting: false
        });

        return null;
      } else if (submitPartnerData.AdminUserId == null) {
        throw new Error('New Partner Creation Failed - No Admin User ID');
      }

      // Send Partner ID to Hubspot via Admin API
      const linkAdminIdResponse = await fetch(
        `${Config.get('adminApi')}PartnerSignupApi/UpdateHubspotPartnerId?contactId=${
          this.props.location.query.contactId
        }&partnerId=${submitPartnerData.AdminUserId}`,
        {
          method: 'POST'
        }
      );

      const token = this.state.captchaToken || (await this.recaptchaRef.current.executeAsync());
      const sha1Email = sha1(userData.Email);
      analyticsService.impactIdentify(submitPartnerData.PartnerId, sha1Email);
      analyticsService.impactOrderTrack(
        submitPartnerData.PartnerId,
        submitPartnerData.PartnerId,
        sha1Email
      );
      window.localStorage.userId = submitPartnerData.PartnerId;
      window.localStorage.emailSha1 = sha1Email;
      // Facebook pixel event trigger
      analyticsService.fbqTrack('CompleteRegistration');

      const loginData = await postJson(`/admin/account/LogOnAjax`, {
        UserName: userDetails.email,
        Password: userDetails.password,
        captcha: token
      });
      if (!loginData.isAuthenticated) {
        throw new Error('Login Failed');
      }

      window.localStorage.loginRedirect = true;
      window.localStorage.isFirstLogin = loginData.isFirstLogin;
      window.localStorage.isWelcomeMessageShown = false;
      window.localStorage.signUpFlow = false;
      window.localStorage.token = loginData.User.ApiKey;
      window.location.href = '/admin#/onboardingdashboard?firstLogin=true';
    } catch (e) {
      console.error('Something went wrong.', e);
      this.setState({
        errors: ['An error occured - ' + e.message],
        isSubmitting: false
      });
    }
  };

  redirectToSignup = () => {
    window.location.href = '/admin/account/SignUp';
  };

  redirectToLogin = () => {
    window.location.href = '/admin/account/Login';
  };

  render() {
    return (
      <div className="sign-up">
        <Helmet>
          <meta
            name="description"
            content="Create your Gooten account and get started with our print on-demand order management system."
          />
        </Helmet>
        <Header />
        <div className="middle-content">
          <UserDetails
            userDetailValues={this.state.data.userDetails}
            onSubmit={this.onUserDetails}
            isSubmitting={this.state.isSubmitting}
          />
          <SignUpInfo />
          <ReCAPTCHA
            // Note: Staging site key is to allow automated tests to work in staging env.
            // Swap keys to test locally.
            sitekey={
              Config.get('env') === 'staging'
                ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // staging key
                : '6LddGPUZAAAAABzQtPIEygB83xnjdSv0c9YgWr63'
            } // production key
            onChange={this.onChangeCaptcha}
            onExpired={() => this.setState({ captchaToken: '' })}
            onErrored={() => this.setState({ captchaToken: '' })}
            ref={this.recaptchaRef}
            size="invisible"
          />
        </div>
        <style jsx global>
          {mainStyles}
        </style>
      </div>
    );
  }
}

CreatePasswordView.propTypes = {
  router: PropTypes.object.isRequired
};

export default CreatePasswordView;
