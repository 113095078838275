import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import loadImage from 'blueimp-load-image';
import { fetchBlob } from '../../../utils/http';
import { reflect } from '../../../utils/promise';
import {
  IMAGES_CHECK,
  IMAGES_FETCH,
  IMAGE_URL_UPLOAD,
  IMAGE_FILE_UPLOAD,
  IMAGE_REMOVE,
  showLoading,
  MODAL_OPEN,
  showScreen
} from './ImageSelectionModalActions';
import { filterSelector, prefilledSpaceImagesSelector } from './atoms/MyArtwork/MyArtworkSelectors';
import { fetchAsyncSuccess, fetchAsyncFail, fetchAsync } from '../../../store/actions/dataActions';
import Log from '../../../services/logService';
import Analytics from '../../../services/analyticsService';
import imageService from './services/imageSelectionService';
import { setSpaces, setFilter, setSort } from './atoms/MyArtwork/MyArtworkActions';
import { hasImagesSelector, SCREEN_TYPE } from './ImageSelectionModalSelectors';
import { isEmbroiderySelected } from '../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';

export function* initSpacesHandler(action) {
  const prefilledSpaces = yield select(prefilledSpaceImagesSelector);
  yield put(setSpaces(prefilledSpaces));
}

export function* imagesCheckAsyncHandler(action) {
  try {
    // call and pass correct this as 1st array param
    let exists = yield call([imageService, imageService.checkRecipeImages]);
    yield put(fetchAsyncSuccess(IMAGES_CHECK, { exists }));
  } catch (err) {
    yield put(fetchAsyncFail(IMAGES_CHECK, err));
    throw Log.withFriendlyMsg('Failed to check recipe images', err, { action });
  }
}

function* initModal() {
  const isEmbroidery = yield select(isEmbroiderySelected);
  const hasImages = yield select(hasImagesSelector);
  yield put(
    showScreen(hasImages || isEmbroidery ? SCREEN_TYPE.myArtwork : SCREEN_TYPE.uploadImage)
  );
}

export function* imagesFetchAsyncHandler(action) {
  const type = yield select(filterSelector);
  try {
    yield put(showLoading(true));
    // call and pass correct this as 1st array param
    let images = yield call(
      [imageService, imageService.getRecipeImages],
      type,
      action.payload.page
    );
    yield put(fetchAsyncSuccess(IMAGES_FETCH, { images, type }));
  } catch (err) {
    yield put(fetchAsyncFail(IMAGES_FETCH, err));
    throw Log.withFriendlyMsg('Failed to fetch recipe images', err, { action, type });
  }
}

export function* imageUrlsUploadAsyncHandler(action) {
  try {
    Analytics.trackWithConfigSource('image_upload_started');

    // NOTE: switch to my images to show loading images in progress
    yield put(showScreen(SCREEN_TYPE.myArtwork));

    // NOTE: reflect will make sure that promises will always resolved
    // The rejected promises will have status: rejected
    // This is prevent from error throw for rejected with yield
    // const results = yield Promise.all(tasks.map(reflect))

    // Client side approach - NOT WORKING IN 90% B/C OF CORS
    // get AWS S3 Signature once before upload
    const s3Config = yield call([imageService, imageService.getS3Config]);

    const uploadTasks = action.payload.urls.map(url => {
      let imageSizeTask = new Promise((resolve, reject) => {
        var img = new Image();
        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight
          });
        };
        img.onerror = err => reject(err);
        img.src = url;
      });

      let uploadTask = new Promise((resolve, reject) => {
        // Pray it wouldn't fail...
        fetchBlob(url, {
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(blob => {
            const ext = blob.type.split('/').pop();
            imageService
              .uploadImageFile(blob, s3Config, 'blob', ext)
              .then(res => {
                resolve({
                  url: res.url
                });
              })
              .catch(err => reject(err));
          })
          .catch(err => reject(err));
      });

      return Promise.all([imageSizeTask, uploadTask].map(reflect)).catch(error => {
        Analytics.trackWithConfigSource('image_upload_failed');
        throw Log.withFriendlyMsg('Failed to upload images to S3', error, { action });
      });
    });

    const try1Results = yield Promise.all(uploadTasks.map(reflect));

    // NOTE: At this stage we should do upload to our api for failed uploads
    const results = yield Promise.all(
      try1Results
        .map(
          (res, i) =>
            new Promise((resolve, reject) => {
              if (res.data[0].status === 'rejected') {
                // image size failed
                reject(res.data[0].data);
              } else if (res.data[1].status === 'rejected') {
                // upload failed
                const url = action.payload.urls[i];
                imageService
                  .uploadImageFromUrl(url)
                  .then(apiData => {
                    if (apiData.error) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      reject();
                    } else {
                      resolve({
                        url: apiData.url,
                        width: res.data[0].data.width,
                        height: res.data[0].data.height
                      });
                    }
                  })
                  .catch(err => reject(err));
              } else {
                // all got uploaded amazing
                resolve({
                  url: res.data[1].data.url,
                  width: res.data[0].data.width,
                  height: res.data[0].data.height
                });
              }
            })
        )
        .map(reflect)
    ).catch(error => {
      Analytics.trackWithConfigSource('image_upload_failed');
      throw Log.withFriendlyMsg('Failed to upload images to API', error, { action });
    });

    // save recipe images for all uploaded files

    const recipeImagesCreateReq = results
      .filter(res => res.status === 'resolved')
      .map(res => ({
        url: res.data.url,
        width: res.data.width,
        height: res.data.height
      }));

    if (recipeImagesCreateReq.length) {
      // call api to save uploaded recipe images
      const createdImages = yield call(
        [imageService, imageService.createRecipeImages],
        recipeImagesCreateReq
      );

      Analytics.trackWithConfigSource('image_upload_success');
      // add images
      yield put(
        fetchAsyncSuccess(IMAGE_URL_UPLOAD, {
          createdImages,
          maxAllowedImages: action.payload.maxAllowedImages
        })
      );
      yield call(resetSortAndFilters);

      yield put(fetchAsync(IMAGES_CHECK));
    }

    const failedUploads = results.filter(r => r.status === 'rejected');
    if (failedUploads.length) {
      Analytics.trackWithConfigSource('image_upload_failed');
      yield put(fetchAsyncFail(IMAGE_URL_UPLOAD, { failedUploads }));
    }
  } catch (error) {
    Analytics.trackWithConfigSource('image_upload_failed');
    Log.error(error, 'Failed to upload images from URLs', { action });
    yield put(fetchAsyncFail(IMAGE_URL_UPLOAD, error));
  }
}

const recipeImagesRequest = tasks => {
  const findTaskByFileType = type =>
    tasks.find(t => t.data.url?.split('.').pop().toLowerCase() === type);

  // special case is when we have emb product with stitch file...
  // 3 files are needed, and one of the files should be DST
  if (tasks.length === 3 && findTaskByFileType('dst')) {
    const pngFile = findTaskByFileType('png');
    return [
      {
        StitchFileUrl: findTaskByFileType('dst')?.data?.url,
        ColorKeyUrl: findTaskByFileType('pdf')?.data?.url,
        EmbroideryPreviewUrl: pngFile?.data?.url,
        url: pngFile?.data?.url,
        width: pngFile?.data.width,
        height: pngFile?.data.height,
        StitchCount: findTaskByFileType('dst')?.data?.stitchCount
      }
    ];
  }

  // regular image files
  return tasks.map(res => ({
    url: res.data.url,
    width: res.data.width,
    height: res.data.height
  }));
};

export function* imageFilesUploadAsyncHandler(action) {
  try {
    Analytics.trackWithConfigSource('image_upload_started');
    // NOTE: switch to my images to show loading images in progress
    yield put(showScreen(SCREEN_TYPE.myArtwork));

    // get AWS S3 Signature once before upload
    const s3Config = yield call([imageService, imageService.getS3Config]);
    // fire multiple file uploads here in parallel
    let tasks = [];
    // NOTE: action.payload.files is FileList there is no .map on it
    for (let i = 0; i < action.payload.files.length; i++) {
      const file = action.payload.files[i];
      tasks.push(
        new Promise((resolve, reject) => {
          if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            // these are not image files. It could be *dst or *pdf...
            // DST file has added stitchCount in format { file: dstFile, stitchCount: number }
            const f = file.stitchCount ? file.file : file;
            imageService
              .uploadImageFile(f, s3Config, f.name, f.name.split('.').pop())
              .then(({ url }) => resolve({ url, stitchCount: file.stitchCount }))
              .catch(err => reject(err));
          } else {
            // Load image to get it width and height
            loadImage(file, (res, data) => {
              if (res.type === 'error') {
                reject(res);
              } else {
                // res is Img here, not Canvas
                imageService
                  .uploadImageFile(file, s3Config, file.name, file.name.split('.').pop())
                  .then(({ url }) =>
                    resolve({
                      url,
                      width: res.naturalWidth,
                      height: res.naturalHeight
                    })
                  )
                  .catch(err => reject(err));
              }
            });
          }
        })
      );
    }

    // NOTE: reflect will make sure that promises will always resolved
    // The rejected promises will have status: rejected
    // This is prevent from error throw for rejected with yield
    const results = yield Promise.all(tasks.map(reflect));
    // create recipe images for all uploaded files,
    const recipeImagesCreateReq = recipeImagesRequest(
      results.filter(res => res.status === 'resolved')
    );
    if (recipeImagesCreateReq.length) {
      // NOTE: call api to save uploaded recipe images
      const createdImages = yield call(
        [imageService, imageService.createRecipeImages],
        recipeImagesCreateReq
      );

      Analytics.trackWithConfigSource('image_upload_success');
      // NOTE: add images
      yield put(
        fetchAsyncSuccess(IMAGE_FILE_UPLOAD, {
          createdImages,
          maxAllowedImages: action.payload.maxAllowedImages
        })
      );
      yield call(resetSortAndFilters);

      yield put(fetchAsync(IMAGES_CHECK));
    }

    // NOTE: put FAIL action with file names and errors of not uploaded files
    const failedUploads = results.filter(res => res.status === 'rejected');
    if (failedUploads.length) {
      // TODO: HANDLE ERRORS
      Analytics.trackWithConfigSource('image_upload_failed');
      yield put(fetchAsyncFail(IMAGE_FILE_UPLOAD, { failedUploads }));
    }
  } catch (error) {
    Analytics.trackWithConfigSource('image_upload_failed');
    Log.error(error, 'Failed to upload images from files', { files: action.payload.files });
    yield put(fetchAsyncFail(IMAGE_FILE_UPLOAD, error));
  }
}

function* resetSortAndFilters() {
  yield put(setFilter('all'));
  yield put(setSort(''));
}

export function* imageRemoveAsyncHandler(action) {
  try {
    yield call([imageService, imageService.removeRecipeImage], action.payload.image.id);
    yield put(fetchAsyncSuccess(IMAGE_REMOVE, action.payload));
  } catch (error) {
    Log.error(error, 'Failed to remove image', { action });
    yield put(fetchAsyncFail(IMAGE_REMOVE, error));
  }
}

// watchers
export function* watchModalOpen() {
  yield takeEvery(MODAL_OPEN, initModal);
  yield takeEvery(MODAL_OPEN, initSpacesHandler);
}

export function* watchImagesCheckAsync() {
  yield takeEvery(IMAGES_CHECK.ASYNC, imagesCheckAsyncHandler);
  yield takeEvery([IMAGES_CHECK.SUCCESS, IMAGES_CHECK.FAIL], initModal);
}

export function* watchImagesFetchAsync() {
  yield takeEvery(IMAGES_FETCH.ASYNC, imagesFetchAsyncHandler);
}

export function* watchImageUrlsUploadAsync() {
  yield takeEvery(IMAGE_URL_UPLOAD.ASYNC, imageUrlsUploadAsyncHandler);
}

export function* watchImageFilesUploadAsync() {
  yield takeEvery(IMAGE_FILE_UPLOAD.ASYNC, imageFilesUploadAsyncHandler);
}

export function* watchImageRemoveAsync() {
  yield takeEvery(IMAGE_REMOVE.ASYNC, imageRemoveAsyncHandler);
}

export default function* rootSaga() {
  yield all([
    watchModalOpen(),
    watchImagesCheckAsync(),
    watchImagesFetchAsync(),
    watchImageRemoveAsync(),
    watchImageUrlsUploadAsync(),
    watchImageFilesUploadAsync()
  ]);
}
