import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB-DUPLICATE', 'DUPLICATE_PRODUCT_VIEW');

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_STEP_DUPLICATE = getActionName('CHANGE_STEP_DUPLICATE');

// ------------------------------------
// Actions
// ------------------------------------
export function changeStep(step) {
  return {
    type: CHANGE_STEP_DUPLICATE,
    payload: { step }
  };
}
