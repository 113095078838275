import React from 'react';
import PropTypes from 'prop-types';
import ProductsFiltering from './components/ProductsFiltering';
import ProductList from './components/ProductList';
import Loader from 'gooten-components/src/components/shared/Loader';
import { Link } from 'react-router';
import MobileNotificationModal from 'gooten-components/src/components/shared/MobileNotificationModal';

import './ProductSelectionView.scss';
import Button from 'gooten-components/src/components/shared/Button';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import PageBody from 'gooten-components/src/components/shared/PageBody';

class ProductSelectionView extends React.Component {
  static propTypes = {
    ready: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    selectedProduct: PropTypes.any,
    productsIndexing: PropTypes.object,
    router: PropTypes.object
  };

  get providerPath() {
    return `/hub/${this.props.router.params.provider}/${this.props.router.params.store}`;
  }

  get breadcrumbsSteps() {
    return [{ name: 'Hub', link: `#${this.providerPath}` }];
  }

  componentDidMount() {
    this.props.init(this.providerPath, parseInt(this.props.router.params.store));
  }

  render() {
    // Will normalize UI notification if indexing products count was changed during process
    let indexingProducts =
      this.props.productsIndexing.indexingProducts &&
      this.props.productsIndexing.indexingProducts < this.props.productsIndexing.indexedProducts
        ? this.props.productsIndexing.indexedProducts
        : this.props.productsIndexing.indexingProducts;
    let content = null;
    if (!this.props.ready) {
      content = <Loader />;
    } else {
      content = (
        <div className="container-fluid container-mobile">
          <PageHeader
            size="standard"
            title="Select a product to sync"
            backHref={this.breadcrumbsSteps[0].link}
            backText={this.breadcrumbsSteps[0].name}
            customButtons={this.renderConnectLink()}
          />
          <PageBody>
            <div>
              {indexingProducts &&
              indexingProducts !== this.props.productsIndexing.indexedProducts ? (
                <div className="alert alert-info" role="alert">
                  <b>We are working on importing your shopify products.</b>
                  {`There are ${this.props.productsIndexing.indexedProducts} ` +
                    `from ${indexingProducts} products ready.`}
                </div>
              ) : null}
            </div>
            <ProductsFiltering />
            <ProductList />
          </PageBody>
        </div>
      );
    }

    return (
      <div className="link-product-selection-view-container">
        {content}
        <MobileNotificationModal />
      </div>
    );
  }
  renderConnectLink() {
    return (
      <Link
        className="w-100"
        to={`/hub/${this.props.router.params.provider}/${this.props.router.params.store}/link-product/skus-selection`}
        disabled={!this.props.selectedProduct}
      >
        <Button
          disabled={!this.props.selectedProduct}
          className={`w-100 ${
            this.props.selectedProduct ? ' button-primary large' : 'button-default large'
          }`}
        >
          Sync&nbsp;<span className="hidden-xs">product</span>
        </Button>
      </Link>
    );
  }
}

export default ProductSelectionView;
