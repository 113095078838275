import { createSelector } from 'reselect';
import { cofShippingCountrySelector } from '../../store/selectors/countriesSelectors';
import {
  subtotalSelector,
  shippingSelector as shippingTotalSelector,
  taxSelector,
  totalSelector
} from '../shared/OrderSummary/OrderSummarySelectors';
import { isPrice } from '../../utils/price';
import ImagePreviewControl from 'gooten-js-preview/src/_scripts/main';
import { prepareIlForManipCommand } from '../../utils/imgmanip';
import { sanitizeCDNUrl } from 'gooten-js-utils/src/url';
import Config from '../../config';
import { dataSelector } from '../../store/selectors/productDataSelectors';
import imageEditorService from '../ImageUpload/services/imageEditorService';

const allStepsSelector = state => state.get('__nav__').get('steps');

const checkoutSelector = state => state.get('checkout');

const customOrderFormSelector = state => state.get('customOrderForm').get('cartView');

const shippingSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('shipping');
});

const addressesObjectSelector = createSelector(shippingSelector, shipping => {
  return shipping.get('addresses');
});

const shippingMethodsSelector = createSelector(shippingSelector, shipping => {
  return shipping.get('methods');
});

const shippingGroupsSelector = createSelector(shippingMethodsSelector, shippingMethods => {
  return shippingMethods.get('groups');
});

export const selectedShippingAddressSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['selected', 'address']);
  }
);

export const paymentSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('payment');
});

const cartSelector = createSelector(dataSelector, data => {
  return data.get('cart');
});

export const cartItemsSelector = createSelector(cartSelector, cart => {
  return cart.get('items');
});

export const prodSelectionStepSelector = createSelector(allStepsSelector, allSteps => {
  return allSteps.filter(step => step === 'ProductSelection').first();
});

export const isCartItemPreviewShownSelector = createSelector(cartSelector, cart => {
  return cart.get('isCartItemPreviewShown');
});

export const previewImageUrlSelector = createSelector(cartSelector, cart => {
  return cart.get('previewImageUrl');
});

export const sourceHistorySelector = createSelector(customOrderFormSelector, list => {
  return list.get('sourceHistory');
});

/**
 * Compare source image with template, in order to check if it fits
 * template without any image adjustments
 * @param {*} space
 * @returns source image url if condition is passed
 */
export const canSubmitSourceImage = space => {
  if (!space?.images?.length || !space?.il || !space?.template) {
    return '';
  }
  const spaceImage = space.images[0];
  // check if it match template size
  if (
    spaceImage.width === space.template.requiredImageSize.width &&
    spaceImage.height === space.template.requiredImageSize.height
  ) {
    // check if it was not edited
    const imageLayer = space.template.layers.find(l => l.type === 'image');
    if (!imageLayer) {
      return '';
    }
    const ilImageLayer = space.il.layers.find(l => l.layerId === imageLayer.id);
    if (!ilImageLayer) {
      return '';
    }
    const ilImageObj = ilImageLayer.images[0];
    if (
      ilImageObj.left === 0 &&
      ilImageObj.top === 0 &&
      ilImageObj.rotation === 0 &&
      ilImageObj.scaleDx === 1
    ) {
      return sanitizeCDNUrl(ilImageObj.url);
    }
  }

  return '';
};

const orderItemsSelector = createSelector(
  [cartItemsSelector, shippingGroupsSelector],
  (cartItems, shippingGroups) => {
    const orderItems = cartItems.map(item => {
      // Prepare the OrderItems part of the POST /orders payload
      // By default it submits via Gooten SKU + ImgManip command
      // and it contains 2 optimizations
      // Read more: https://gooten.atlassian.net/l/c/a8AAbb5C

      // convert to plain js, because we have mixted nested immutable and plain js objects
      const spaces = item.getIn(['sku', 'spaces']).toJS();

      // Optimization #1
      // If the PRP Image wasn't edited, we can use the PRP SKU
      // By default we assume it was edited
      let isPrpEdited = true;

      // Check if partnerId is same as current partner
      // otherwise partner was changed, and the PRP SKU doesn't belong to current partner
      // This flow used by CS to order partner products (as samples) on their CS Samples account.
      // If partner changed we can't use PRP SKU anymore, skip optimizations.
      const isPartnerChanged = item.get('partnerId') !== Config.get('partnerId');

      if (isPartnerChanged) {
        console.warn('Partner was changed, can not use PRP SKUs');
      }

      // space.IL - the current Image Layer settings for space
      // space.prpIL - the original Image Layer settings for space, saved in PRP
      if (
        !isPartnerChanged &&
        spaces.filter(s => s.prpIL && s.prpIL.layers).length === spaces.length
      ) {
        const orientation = item.getIn(['sku', 'orientation']);
        const savedOrientation = item.getIn(['sku', 'savedOrientation']);

        isPrpEdited =
          !!(savedOrientation && orientation !== savedOrientation) ||
          new Set(
            spaces.map(space => {
              // compare ILs to check if PRP image was edited
              const sameIL = imageEditorService.comparePrintIls(space.il, space.prpIL);
              if (!sameIL) return false;
              // Even though PRP image wasn't changed, if the template got changed
              // the PRP image may not fit to the new template size.
              // Check that PRP IL settings comform to the current template size.

              // Simple check by spaceId
              // spaceId/layerId generated as hash from the template body
              // if they are equal than it's 100% nothing was changed in template
              const sameSpaceId = space.template.spaceId === space.prpIL.spaceId;
              if (sameSpaceId) return true;

              // If spaceId doesn't match,
              // it may be a case that some design image was updated in template
              // and that doesn't impact on the actual print image specs
              // so we can check the print image size
              const prpILHeight = space.prpIL.final.y2 - space.prpIL.final.y1;
              const prpILWidth = space.prpIL.final.x2 - space.prpIL.final.x1;

              return (
                prpILHeight === space.template.requiredImageSize.height &&
                prpILWidth === space.template.requiredImageSize.width
              );
            })
          ).has(false);
      }

      let spaceImages = null;
      if (isPrpEdited) {
        spaceImages = spaces.map((space, index) => {
          // Optimization #2
          // if the source image is up to specs and wasn't edited (croped, scaled, moved, rotated)
          // we can use the source image as is.
          if (canSubmitSourceImage(space)) {
            console.debug('Use source image for order submission');
            return {
              Url: sanitizeCDNUrl(space.images[0].imageUrl),
              Index: index,
              SpaceId: space.id,
              ImageId: space.images[0].imageId
            };
          }

          // Otherwise we will generate a new img manip command and submit it.

          const ilSanitized = prepareIlForManipCommand(space.il);

          const cmds = ImagePreviewControl.exportImgManipCmd(
            {
              template: space.template,
              il: ilSanitized
            },
            // Passing dummy background color and preview size
            // because we only need printImgManip here
            // and width and height are only for preview IL
            '#FFFFFF',
            { width: 0, height: 0 },
            item.getIn(['sku', 'orientation']) === 'changed'
          );

          return {
            Index: index,
            ManipCommand: JSON.stringify(cmds.printImgManip),
            SpaceId: space.id
          };
        });
      }

      const sku = item.getIn(['sku', isPrpEdited ? 'sku' : 'prpSKU']);
      const neckTagImgUrl = item.getIn(['sku', 'neck_tag_image_url']);
      const neckTagImgId = item.getIn(['sku', 'neck_tag_id']);
      const addons = neckTagImgUrl ? { [`necktag_image_url`]: neckTagImgUrl } : null;

      return {
        SKU: sku,
        Quantity: item.get('quantity'),
        Images: spaceImages,
        IsSample: !!item.get('isSample'),
        AddOns: addons,
        ShipCarrierMethodId: shippingGroups
          .find(group => group.get('SKUs').contains(item.getIn(['sku', 'sku'])))
          .get('SelectedMethodId'),
        Meta: {
          necktag_id: neckTagImgId
        }
      };
    });

    return orderItems;
  }
);

export const placeOrderRequestSelector = createSelector(
  [cofShippingCountrySelector, selectedShippingAddressSelector, orderItemsSelector, totalSelector],
  (shippingCountry, selectedShippingAddress, orderItems, total) => {
    if (selectedShippingAddress.get('country') !== shippingCountry) {
      console.warn('Selected shipping address:', selectedShippingAddress.toJS());
      console.warn('Selected shipping country:', shippingCountry);
      throw new Error('Selected shipping address does not belong to selected shipping country');
    }

    const convertedShippingAddress = {
      FirstName: selectedShippingAddress.get('firstName'),
      LastName: selectedShippingAddress.get('lastName'),
      Line1: selectedShippingAddress.get('addressLine1'),
      Line2: selectedShippingAddress.get('addressLine2'),
      City: selectedShippingAddress.get('city'),
      State: selectedShippingAddress.get('state'),
      CountryCode: selectedShippingAddress.get('country'),
      PostalCode: selectedShippingAddress.get('zipCode'),
      Phone: selectedShippingAddress.get('number'),
      Email: selectedShippingAddress.get('email')
    };

    return {
      ShipToAddress: convertedShippingAddress,
      SaveShippingAddress: selectedShippingAddress.get('save'),
      BillingAddress: convertedShippingAddress,
      Items: orderItems.toJS(),
      Payment: {
        // NOTE: We don't submit total in COF
        // Our email sys is depends on it
        // If it will be submitted it will include cost summary with wrong Total
        // which is actually partner price
        // Total: total,
        CurrencyCode: 'USD',
        PartnerBillingKey: Config.get('storeApiKey')
      },
      // SourceId: undefined
      Meta: {
        // Don't change it since chartio analytics rely on it
        ordered_via: 'admin simple form',
        CurrentUser: Config.get('adminUserName')
      },
      IsPreSubmit: false
    };
  }
);

export const orderObjectSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('order');
});

export const canPlaceOrderSelector = createSelector(
  [
    checkoutSelector,
    subtotalSelector,
    shippingTotalSelector,
    taxSelector,
    totalSelector,
    cartItemsSelector
  ],
  (checkout, subtotal, shipping, tax, total, cartItems) => {
    const paymentMethods = checkout.getIn(['payment', 'paymentMethods', 'PaymentMethods']);

    let canPlaceOrder = true;
    // Do not allow to place order if
    if (!cartItems.size) {
      // has no cart items
      canPlaceOrder = false;
    } else if (!checkout.getIn(['shipping', 'addresses', 'selected', 'address'])) {
      // shipping address is not set
      // TODO: validate address here?
      canPlaceOrder = false;
    } else if (
      checkout.getIn(['shipping', 'methods', 'errors']) &&
      checkout.getIn(['shipping', 'methods', 'errors']).size
    ) {
      // shipping methods has errors
      canPlaceOrder = false;
    } else if (
      checkout.getIn(['shipping', 'methods', 'groups']) &&
      checkout.getIn(['shipping', 'methods', 'groups']).some(g => !g.get('SelectedMethodId'))
    ) {
      // some shipping methods has no selected methods
      canPlaceOrder = false;
    } else if (!tax) {
      canPlaceOrder = false;
    } else if (!isPrice(subtotal) || !isPrice(shipping) || !isPrice(tax.Total) || !isPrice(total)) {
      // subtotal, shipping and tax total isn't valid price
      canPlaceOrder = false;
    } else if (cartItems.some(cartItem => !cartItem.get('pricing'))) {
      // some cart items has no pricing
      canPlaceOrder = false;
    } else if (
      (!paymentMethods || paymentMethods.size === 0) &&
      !checkout.getIn(['payment', 'paymentMethods', 'IsOnCredit'])
    ) {
      // if has not card attached and not on credit
      canPlaceOrder = false;
    }

    return canPlaceOrder;
  }
);

export const errorAlertSelector = createSelector(
  [checkoutSelector, cartItemsSelector],
  (checkout, cartItems) => {
    const paymentMethods = checkout.getIn(['payment', 'paymentMethods', 'PaymentMethods']);

    let errorType = [];
    // show error if
    if (!cartItems.size) {
      // has no cart items
      errorType.push('Your cart is empty');
    }
    if (!checkout.getIn(['shipping', 'addresses', 'selected', 'address'])) {
      // shipping address is not set
      errorType.push('Please select an address');
    }
    if (
      checkout.getIn(['shipping', 'methods', 'errors']) &&
      checkout.getIn(['shipping', 'methods', 'errors']).size
    ) {
      // shipping methods has errors
      errorType.push('Please correct your shipping method');
    }
    if (
      checkout.getIn(['shipping', 'methods', 'groups']) &&
      checkout.getIn(['shipping', 'methods', 'groups']).some(g => !g.get('SelectedMethodId'))
    ) {
      // some shipping methods has no selected methods
      errorType.push('Please correct your shipping method');
    }
    if (
      (!paymentMethods || paymentMethods.size === 0) &&
      !checkout.getIn(['payment', 'paymentMethods', 'IsOnCredit'])
    ) {
      // if has not card attached and not on credit
      errorType.push('Please update your payment method');
    }

    return errorType;
  }
);
