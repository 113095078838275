import type { Rect, Point } from '../flow/types';

//NOTE: Make height/width smaller to allow padding
const PADDING = 40;

export class PositioningService {
  /**
   * Return rect for given layer
   * @param {Object} template
   * @param {Number} surfaceWidth
   * @param {Number} surfaceHeight
   */
  getViewportRect(layer: any): Rect {
    const targetRect = {
      x: layer.x1,
      y: layer.y1,
      width: layer.x2 - layer.x1,
      height: layer.y2 - layer.y1
    };

    return targetRect;
  }

  /**
   * Add real width, height, scale to template
   * @param {Object} template
   */
  initTemplatePosition(template: any) {
    var imageLayers = template.layers.filter(l => l.type === 'image');

    let templateRect = this.getMaxRect(template.layers);
    let printRect = this.getMaxRect(imageLayers);

    Object.assign(template, {
      templateWidth: templateRect.width,
      templateHeight: templateRect.height,
      finalX1: printRect.x1,
      finalY1: printRect.y1,
      finalX2: printRect.x2,
      finalY2: printRect.y2
    });
  }

  /**
   * Returns max boundaries rect
   * @param {Array} layers
   */
  getMaxRect(layers: any[]) {
    var maxX = 0,
      maxY = 0;

    var minX = layers[0].x1;
    var minY = layers[0].y1;

    layers.forEach(layer => {
      maxX = Math.max(maxX, layer.x2);
      maxY = Math.max(maxY, layer.y2);

      minX = Math.min(minX, layer.x1);
      minY = Math.min(minY, layer.y1);
    });

    return {
      x1: minX,
      x2: maxX,
      y1: minY,
      y2: maxY,
      width: maxX - minX,
      height: maxY - minY
    };
  }

  /**
   * Add real x, y, width, height, scale to image
   * @param {Object} image
   * @param {Object} template
   */
  initImagePosition(image: any, template: any, isRotated: Boolean) {
    const positionings = {
      TopLeft: (viewportRect, image) => {
        return {
          x: viewportRect.x + image.w / 2,
          y: viewportRect.y + image.h / 2
        };
      },
      TopCenter: (viewportRect, image) => {
        return {
          x: viewportRect.x + viewportRect.width / 2,
          y: viewportRect.y + image.h / 2
        };
      },
      TopRight: (viewportRect, image) => {
        return {
          x: viewportRect.x + image.w / 2 - (image.w - viewportRect.width),
          y: viewportRect.y + image.h / 2
        };
      },
      CenterLeft: (viewportRect, image) => {
        return {
          x: viewportRect.x + image.w / 2,
          y: viewportRect.y + viewportRect.height / 2
        };
      },
      CenterCenter: (viewportRect, image) => {
        return {
          x: viewportRect.x + viewportRect.width / 2,
          y: viewportRect.y + viewportRect.height / 2
        };
      },
      CenterRight: (viewportRect, image) => {
        return {
          x: viewportRect.x + image.w / 2 - (image.w - viewportRect.width),
          y: viewportRect.y + viewportRect.height / 2
        };
      },
      BottomLeft: (viewportRect, image) => {
        return {
          x: viewportRect.x + image.w / 2,
          y: viewportRect.y + image.h / 2 - (image.h - viewportRect.height)
        };
      },
      BottomCenter: (viewportRect, image) => {
        return {
          x: viewportRect.x + viewportRect.width / 2,
          y: viewportRect.y + image.h / 2 - (image.h - viewportRect.height)
        };
      },
      BottomRight: (viewportRect, image) => {
        return {
          x: viewportRect.x + image.w / 2 - (image.w - viewportRect.width),
          y: viewportRect.y + image.h / 2 - (image.h - viewportRect.height)
        };
      }
    };
    const imageLayer = template.layers.find(l => l.id === image.layerId);
    // Viewport coordinates in canvas
    const scale = this.getInitialScale(imageLayer, image, isRotated);

    const imageSizes = {
      w: image.realSourceWidth * scale,
      h: image.realSourceHeight * scale
    };

    const viewportRect = this.getViewportRect(imageLayer);

    const positioningFn = positionings[imageLayer.positioning || 'CenterCenter'];
    const position = positioningFn(viewportRect, imageSizes);
    Object.assign(image, {
      x: position.x,
      y: position.y,
      //Save in state to calculate max scale for small images
      initialScale: scale,
      scale: scale
    });
  }

  getInitialScale(imageLayer, image, isRotated): number {
    const vW = isRotated ? imageLayer.y2 - imageLayer.y1 : imageLayer.x2 - imageLayer.x1;
    const vH = isRotated ? imageLayer.x2 - imageLayer.x1 : imageLayer.y2 - imageLayer.y1;

    const dW = vW / image.realSourceWidth;
    const dH = vH / image.realSourceHeight;

    //Will extend/squeeze image to fill viewport width or height
    return imageLayer.imageFill === 'CanFill' || isRotated ? Math.min(dW, dH) : Math.max(dW, dH);
  }

  getCurrentImage(state) {
    return !!state.images.current.selected.imageId
      ? state.images.current.images.find(i => i.id === state.images.current.selected.imageId)
      : null;
  }

  getCenterPosition(state): Point {
    const currentImage = this.getCurrentImage(state);

    if (!currentImage || !currentImage.sourceWidth) {
      return {};
    }

    const imageLayer = state.template.layers.find(
      l => l.id === state.images.current.selected.layerId
    );

    const viewportRect = this.getViewportRect(imageLayer);

    return {
      x: viewportRect.x + viewportRect.width / 2,
      y: viewportRect.y + viewportRect.height / 2
    };
  }

  /**
   * Recalculate sizes when editor size got changed
   */
  reconstituteEditor(state: any, newWidth: number, newHeight: number) {
    const imageLayers = state.template.layers.filter(l => l.type === 'image');

    const templateRect = this.getMaxRect(state.template.layers);
    const printRect = this.getMaxRect(imageLayers);

    const scale = Math.min(newWidth / templateRect.width, newHeight / templateRect.height);

    state.template.layers.forEach(layer => {
      let updated = Object.assign({}, layer);
      const sWidth = layer.x2 - layer.x1;
      const sHeight = layer.y2 - layer.y1;
      updated.width = sWidth * scale;
      updated.height = sHeight * scale;
    });

    return {
      editor: {
        width: newWidth,
        height: newHeight
      },
      template: {
        templateWidth: templateRect.width,
        templateHeight: templateRect.height,
        finalX1: printRect.x1,
        finalY1: printRect.y1,
        finalX2: printRect.x2,
        finalY2: printRect.y2,
        scale: scale
      }
    };
  }

  rotate90Image(state: any): number {
    return this.rotateImage(state, 90);
  }

  rotateImage(state: any, degrees): Number {
    const currentImage = !!state.images.current.selected.imageId
      ? state.images.current.images.find(i => i.id == state.images.current.selected.imageId)
      : null;

    if (!currentImage) {
      return null;
    }

    return (currentImage.rotation || 0) + degrees;
  }

  rotateImageObj(image: any, degrees) {
    if (image) {
      image.rotation = degrees;
    }
  }

  centerImageObj(image: any, template: any) {
    if (image && template) {
      const viewportRect = {
        x: template.finalX1,
        y: template.finalY1,
        width: template.finalX2 - template.finalX1,
        height: template.finalY2 - template.finalY1
      };
      image.x = viewportRect.x + viewportRect.width / 2;
      image.y = viewportRect.y + viewportRect.height / 2;
    }
  }

  // get print or product template size
  getTemplateSize(state): Rect {
    const isPrintPreview = state.editor.preview === 'print';
    let targetRect;
    if (!isPrintPreview) {
      targetRect = {
        x: 0,
        y: 0,
        width: state.template.templateWidth,
        height: state.template.templateHeight
      };
    }
    else {
      targetRect = {
        x: state.template.finalX1,
        y: state.template.finalY1,
        width: state.template.finalX2 - state.template.finalX1,
        height: state.template.finalY2 - state.template.finalY1
      };
    }

    return targetRect;
  }

  // get current editor scale (compare template size and editor canvas object size)
  getCurrentScale(canvasSize, templateRect): number {
    const scale = Math.min(
      canvasSize.width / templateRect.width,
      canvasSize.height / templateRect.height
    );
    return scale;
  }

  // convert rect from template to canvas size based on current editor scale
  convertToCanvasSize(rect, state) {
    const templateRect = this.getTemplateSize(state);
    // NOTE: Make height/width smaller to allow padding
    const canvasSize = {
      width: state.editor.width - PADDING,
      height: state.editor.height - PADDING
    };

    const scale = this.getCurrentScale(canvasSize, templateRect);
    const width = rect.width * scale;
    const height = rect.height * scale;
    let finalRect = {
      width: width,
      height: height,
      x: (rect.x - templateRect.x) * scale + PADDING / 2,
      y: (rect.y - templateRect.y) * scale + PADDING / 2,
      scale: scale
    };

    // NOTE: Center Vertical/Horizontal
    if (canvasSize.width / templateRect.width < canvasSize.height / templateRect.height) {
      finalRect.y += (canvasSize.height - templateRect.height * scale) / 2;
    }
    else {
      finalRect.x += (canvasSize.width - templateRect.width * scale) / 2;
    }

    return finalRect;
  }

  convertToTemplateSize(rect, state): Point {
    //NOTE: Make height/width smaller to allow padding
    const templateRect = this.getTemplateSize(state);
    const canvasSize = {
      width: state.editor.width - PADDING,
      height: state.editor.height - PADDING
    };

    const scale = this.getCurrentScale(canvasSize, templateRect);
    const finalRect = {
      x:
        (rect.x - PADDING / 2 - (canvasSize.width - templateRect.width * scale) / 2) / scale +
        templateRect.x,
      y:
        (rect.y - PADDING / 2 - (canvasSize.height - templateRect.height * scale) / 2) / scale +
        templateRect.y
    };

    return finalRect;
  }

  /**
   * Rotate point(x, y) according center(x, y) to given angle degrees
   * @param {Point} point
   * @param {Point} centerPoint
   * @param {Number} angle
   */
  getRotatedPoint(point: Point, centerPoint: Point, angle: number): Point {
    const radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (point.x - centerPoint.x) + sin * (point.y - centerPoint.y) + centerPoint.x,
      ny = cos * (point.y - centerPoint.y) - sin * (point.x - centerPoint.x) + centerPoint.y;
    return {
      x: nx,
      y: ny
    };
  }

  /**
   * Return max rect for rotated rect
   * imagine romb inside rect
   * @param {Number} centerX
   * @param {Number} centerY
   * @param {Number} width
   * @param {Number} height
   * @param {Number} rotation
   */
  getRotatedMaxRect(centerX, centerY, width, height, rotation) {
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const center = {
      x: centerX,
      y: centerY
    };

    const notRotatedTopLeft = {
      x: centerX - halfWidth,
      y: centerY - halfHeight
    };
    const notRotatedBottomLeft = {
      x: centerX - halfWidth,
      y: centerY + halfHeight
    };
    const notRotatedTopRight = {
      x: centerX + halfWidth,
      y: centerY - halfHeight
    };
    const notRotatedBottomRight = {
      x: centerX + halfWidth,
      y: centerY + halfHeight
    };

    const rotatedTopLeft = this.getRotatedPoint(notRotatedTopLeft, center, -rotation || 0);
    const rotatedBottomLeft = this.getRotatedPoint(notRotatedBottomLeft, center, -rotation || 0);
    const rotatedTopRight = this.getRotatedPoint(notRotatedTopRight, center, -rotation || 0);
    const rotatedBottomRight = this.getRotatedPoint(notRotatedBottomRight, center, -rotation || 0);

    const maxRect = this.getMaxRect([
      {
        x1: rotatedBottomLeft.x,
        y1: rotatedBottomLeft.y,
        x2: rotatedTopRight.x,
        y2: rotatedTopRight.y
      },
      {
        x1: rotatedTopLeft.x,
        y1: rotatedTopLeft.y,
        x2: rotatedBottomRight.x,
        y2: rotatedBottomRight.y
      },
      {
        x1: rotatedTopRight.x,
        y1: rotatedTopRight.y,
        x2: rotatedBottomLeft.x,
        y2: rotatedBottomLeft.y
      },
      {
        x1: rotatedBottomRight.x,
        y1: rotatedBottomRight.y,
        x2: rotatedTopLeft.x,
        y2: rotatedTopLeft.y
      }
    ]);

    return maxRect;
  }

  // Used to calc position DX
  // to be applied to other IL's
  getDistanceFromCenter(state): Point {
    const currentImage = this.getCurrentImage(state);

    if (!currentImage || !currentImage.sourceWidth) {
      return {};
    }
    const centerPosition = this.getCenterPosition(state);

    return {
      x: centerPosition.x - currentImage.x,
      y: centerPosition.y - currentImage.y
    };
  }

  // Used to calc scale DX
  // to be applied to other IL's
  getProportionalScale(state): number {
    const currentImage = this.getCurrentImage(state);
    const imageLayer = state.template.layers.find(l => l.id === currentImage.layerId);
    const initialScale = this.getInitialScale(imageLayer, currentImage);
    return currentImage.scale / initialScale;
  }

  /**
   * Converts crop object to proportional crop DX
   * to be applied to other IL's
   * @param {Object} crop
   * @param {Object} state
   */
  getProportionalCrop(crop, state): Rect {
    const currentImage = this.getCurrentImage(state);
    const propCrop = {
      x: crop.x / currentImage.realSourceWidth,
      y: crop.y / currentImage.realSourceHeight,
      height: crop.height / currentImage.realSourceHeight,
      width: crop.width / currentImage.realSourceWidth
    };
    return propCrop;
  }

  // Converts current image update into proportional DX update
  // to be applied to other IL's
  getProportionalDxUpdate(imageUpdate, state) {
    const currentImageId = state.images.current.selected.imageId;
    if (!currentImageId) {
      return;
    }
    let dx = {};
    if (imageUpdate.x || imageUpdate.y) {
      dx = Object.assign({}, dx, this.getDistanceFromCenter(state));
    }
    if (imageUpdate.scale) {
      dx = Object.assign({}, dx, { scale: this.getProportionalScale(state) });
    }
    if (imageUpdate.rotation) {
      dx = Object.assign({}, dx, { rotation: imageUpdate.rotation });
    }
    if (imageUpdate.crop) {
      dx = Object.assign({}, dx, { crop: this.getProportionalCrop(imageUpdate.crop, state) });
    }
    return dx;
  }

  getUpdatedPositionFromDxUpdate(imageLayer, dx): Point {
    if (!dx.hasOwnProperty('x') || !dx.hasOwnProperty('y')) {
      return {};
    }
    const viewportRect = this.getViewportRect(imageLayer);

    const centerPosition = {
      x: viewportRect.x + viewportRect.width / 2,
      y: viewportRect.y + viewportRect.height / 2
    };

    return {
      x: centerPosition.x - dx.x,
      y: centerPosition.y - dx.y
    };
  }

  // Apply dx update onto image state.
  getImageStateUpdateFromDx(dx, image, imageLayer) {
    let updatedState = Object.assign(
      {},
      image,
      dx,
      this.getUpdatedPositionFromDxUpdate(imageLayer, dx)
    );
    if (dx.scale) {
      const deltaScale = dx.scale || 1;
      const initialScale = this.getInitialScale(imageLayer, image);
      const scale = initialScale * deltaScale;
      updatedState = Object.assign({}, updatedState, { scale: scale });
    }
    if (dx.crop) {
      const crop = {
        x: dx.crop.x * image.realSourceWidth,
        y: dx.crop.y * image.realSourceHeight,
        height: dx.crop.height * image.realSourceHeight,
        width: dx.crop.width * image.realSourceWidth
      };
      updatedState = Object.assign({}, updatedState, { crop: crop });
    }

    return updatedState;
  }
}

// singleton
export default new PositioningService();
