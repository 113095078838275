import { fetch } from 'gooten-components/src/utils/http';
import { List, fromJS } from 'immutable';
import PagedCollection from '../../models/PagedCollection';
import { mapProduct } from '../../models/Product';
import Config from '../../config';
import { getDateNowStr } from '../../../../gooten-components/src/utils/random';

const PAGESIZE = 8;

const getAuthQueryParams = () => ({
  recipeId: Config.get('recipeId'),
  apiKey: Config.get('storeApiKey')
});

const getQueryParams = (page, search, viewBy) => {
  let params = {
    connected: false,
    page,
    pageSize: PAGESIZE
  };

  if (search) {
    params = {
      ...params,
      search
    };
  }

  if (viewBy && viewBy !== 'all') {
    if (viewBy === 'published' || viewBy === 'unpublished') {
      params = {
        ...params,
        status: viewBy
      };
    } else {
      params = {
        ...params,
        collection: viewBy
      };
    }
  }
  return params;
};

const getStoreProductsUrl = storeId => `${Config.get('storeApi')}stores/${storeId}/products`;

const getStoreProductVariantUrl = (storeId, productId) =>
  `${Config.get('storeApi')}stores/${storeId}/products/${productId}/variants`;

const getStoreSettingsUrl = storeId => `${Config.get('storeApi')}stores/${storeId}/settings`;

const fillData = (storeProduct, mappings, product, fillOptions) => {
  return {
    productId: product.id,
    productName: storeProduct.name || '',
    productDesc: storeProduct.description || '',
    productType: storeProduct.productType,
    selectedTags: storeProduct.tags || [],
    selectedCollections: storeProduct.collections,
    // Gooten options will be used on Publish step
    options: !fillOptions
      ? null
      : storeProduct.options.map((o, i) => ({
          id: o.id || o.position, // position is unique can be used as key
          title: o.name
        })),
    selectedOptions: !fillOptions
      ? null
      : storeProduct.options.map((o, i) => ({
          id: i + 1, // just unique index for sorting
          value: o.id || o.position
        })),
    variants: mappings.map((m, index) => {
      // For WooCommerce we should take a timestamp to suffix the SKU as they need to be unique within a store
      let suffix = index;
      if (storeProduct.provider === 'woocommerce') {
        suffix = getDateNowStr();
      }

      return {
        id: m.variant.id,
        sku: m.uniqueProduct.sku,
        index,
        // autofill store sku if empty
        customSku: m.variant.sku || `${m.uniqueProduct.sku}-${suffix}`,
        customerPrice: m.variant.price.price
      };
    })
  };
};

class LinkProductService {
  pageSize = PAGESIZE;

  getStoreProducts({ storeId, page, search, viewBy }) {
    const url = getStoreProductsUrl(storeId);
    const params = getQueryParams(page, search, viewBy);
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        queryParams: {
          ...getAuthQueryParams(),
          ...params
        }
      })
        .then(res => {
          if (!res.error) {
            resolve(
              new PagedCollection({
                items: new List(res.products.map(p => mapProduct(p))),
                total: res.total
              })
            );
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  checkStoreIndexing(storeId) {
    const url = getStoreSettingsUrl(storeId);
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        queryParams: {
          ...getAuthQueryParams()
        }
      })
        .then(res => {
          if (!res.error) {
            resolve({
              connectedProducts: res.store.settings.connected_products_count,
              indexedProducts: res.store.settings.products_count,
              indexingProducts: res.store.settings.indexing_products_count
            });
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getStoreProductVariants({ storeId, productId }) {
    const url = getStoreProductVariantUrl(storeId, productId);
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        queryParams: {
          ...getAuthQueryParams()
        }
      })
        .then(res => {
          if (!res.error) {
            resolve(res.variants);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getDefaultRegionIndex(regions) {
    // Try get US region or use first one
    let selectedRegionIndex = -1;
    regions.forEach((r, i) => {
      if (selectedRegionIndex === -1 && r.name.indexOf('US') > -1) {
        selectedRegionIndex = i;
      }
    });
    return selectedRegionIndex === -1 ? 0 : selectedRegionIndex;
  }

  mapRegionOptions(regions, regionIndex, subCategoryIndex) {
    return regions
      .get(regionIndex)
      .get('sub-categories')
      .get(subCategoryIndex)
      .get('attributes')
      .filter(a => a.get('id'))
      .map(o => o.set('selectedValues', new List()));
  }

  getPublishData(data, stores) {
    const { mappings, storeProduct, product, storeId } = data;
    return {
      storage: {
        selected: false,
        tags: Array.from(
          stores
            .toJS()
            .reduce((tags, store) => new Set([...tags, ...store.tags.map(t => t.name)]), new Set())
        ),
        collections: [],
        ...fillData(storeProduct, mappings, product)
      },
      stores: stores
        ? stores.map(s =>
            fromJS({
              id: s.get('id'),
              name: s.get('storeName'),
              // Etsy store is not active, can't have published products, only unpublished (drafts)
              inactive:
                s.get('provider').toLowerCase() === 'etsy' &&
                s.getIn(['settings', 'about', 'status']) !== 'active',
              provider: s.get('provider'),
              collections: s.collections,
              selected: storeId === s.get('id'),
              draft: storeId !== s.get('id') || storeProduct.status !== 'published',
              updatePreview: false,
              ...fillData(
                { provider: s.get('provider'), ...storeProduct },
                mappings,
                product,
                storeId === s.get('id')
              )
            })
          )
        : [],
      validation: {
        errors: [],
        issues: {
          variantsSkus: mappings.map(() => null)
        },
        failures: {
          variantsSkus: [],
          productNames: []
        },
        stores: stores
          ? stores.map(s =>
              fromJS({
                id: s.get('id'),
                failures: {
                  variantsSkus: [],
                  productNames: []
                },
                issues: {
                  variantsSkus: mappings.map(() => null)
                }
              })
            )
          : []
      }
    };
  }
}

// singleton
export default new LinkProductService();
