import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { tierRanks, PartnerTierLabel } from 'gooten-components/src/components/shared/PartnerTier';

const toCurrency = i => (i ? i.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : i);

const AnnualSpendIndicator = ({ tierLimits, ordersTotal, status, nextTierData }) => {
  let ranges = Object.values(tierLimits).sort((a, b) => a - b);
  let percents = nextTierData
    ? Math.min(
        ranges.slice(1).reduce((acc, v, i) => {
          return (
            acc +
            (ordersTotal < v ? Math.max((ordersTotal - ranges[i]) / (v - ranges[i]), 0) * 33 : 33)
          );
        }, 1),
        100
      )
    : 100;

  return (
    <>
      <div className="px-0 pt-3 pb-3">
        <div className="d-flex justify-content-between">
          <div className="title-1 next-level-title ml-0 my-auto mr-auto">
            <div className="orders-total m-auto">
              <p className="mb-0 mt-2">
                <span className="title-2">{toCurrency(ordersTotal)}</span>
                <span className="caption-text total">
                  <b> Annual Spend</b>
                </span>
              </p>
              <p className="mb-0">
                {nextTierData ? (
                  <span className="caption-text period">
                    {toCurrency(nextTierData.limit - ordersTotal)} to{' '}
                    <span className="text-capitalize">{nextTierData.nextLevelStatus}</span>
                  </span>
                ) : (
                  <span>{'{Nothing here YET...}'}</span>
                )}
              </p>
            </div>
          </div>
          <br />
        </div>
        <div className="d-flex my-4 mx-auto">
          <div className="tier-progress">
            <div className={'tier-progress-bar ' + status} style={{ width: percents + '%' }}>
              <span></span>
            </div>
          </div>
        </div>
        <div className="d-flex my-4 mx-auto justify-content-between">
          {tierRanks.map(r => (
            <PartnerTierLabel id={r.name} key={r.name}>
              {tierLimits[r.name] > ordersTotal ? (
                <b>{toCurrency(tierLimits[r.name])}</b>
              ) : (
                <span>Achieved</span>
              )}
            </PartnerTierLabel>
          ))}
        </div>
      </div>
      <hr />
      <style jsx>
        {`
          .caption-text {
            font-size: 11px;
            color: #646b72 !important;
          }
          hr {
            margin: 0 !important;
          }
          .next-level-title {
            font-size: 11px;
            letter-spacing: 1px;
            color: #646b72 !important;
          }

          .orders-total {
            flex: 5;
          }
          .title-2 {
            color: #373f49 !important;
          }
          .total {
            font-size: 13px;
          }
          .period {
            font-size: 13px;
            color: #373f49 !important;
          }

          .tier-progress {
            width: 100%;
            height: 16px;
            background: #ededed;
            border-radius: 8px;
          }

          .tier-progress-bar {
            height: 16px;
            border-radius: 8px;
          }

          .tier-progress-bar.insider {
            background: linear-gradient(135deg, #6a85b6 0%, #bac8e0 100%);
          }

          .tier-progress-bar.plus {
            background: linear-gradient(135deg, #0fffa9 0%, #00ccd9 100%);
          }

          .tier-progress-bar.pro {
            background: linear-gradient(135deg, #21b3fd 0%, #4521fd 100%);
          }

          .tier-progress-bar.elite {
            background: linear-gradient(135deg, #ac32e4 0%, #4801ff 100%);
          }
        `}
      </style>
    </>
  );
};

AnnualSpendIndicator.propTypes = {
  tierLimits: PropTypes.object.isRequired,
  nextTierData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  ordersTotal: PropTypes.number.isRequired
};
export default AnnualSpendIndicator;
