// @flow

import { Stage } from 'konva';
import EventEmitter from 'events';
import ImageSurfaceLayer from '../image/image.layer';
import TemplateSurfaceLayer from '../template/template.layer';
import ActionsSurfaceLayer from '../actions/actions.layer';
import ViewportSurfaceLayer from '../viewport/viewport.layer';
import BackgroundSurfaceLayer from '../background/background.layer';
import ZoomApi from '../actions/zoom/zoom.api';
import { PUBLIC_EVENTS } from '../../ImageEditor.events';

import type { KonvaStage } from '../../flow/konvaTypes';

export default class CanvasSurface {
  orientation: string; // portrait | landscape
  width: number;
  height: number;
  container: any;
  stage: KonvaStage;
  actionProcessorCb: any;
  zoomApi: ZoomApi;
  publicEvents: EventEmitter;

  constructor(publicEvents: EventEmitter, width: number, height: number, container: any, zoomControl: any, actionProcessorCb: any) {
    this.publicEvents = publicEvents;
    this.orientation = 'landscape';
    this.width = width;
    this.height = height;
    this.container = container;
    this.actionProcessorCb = actionProcessorCb;

    this.stage = new Stage({
      container: this.container,
      width: width,
      height: height
    });

    this.layersHash = {};

    this.zoomApi = new ZoomApi(zoomControl, publicEvents);

    const SCALE_BY = 1.02;
    const MAX_SCALE = 3;
    const MIN_SCALE = 0.7;
    this._setZoomStageRelativeToPointerOnWheel(SCALE_BY, MIN_SCALE, MAX_SCALE);
  }

  _setZoomStageRelativeToPointerOnWheel(scaleBy: number, minScale: number, maxScale: number){
    this.stage.on('wheel', (e) => {
      // if dropdown from toolbar for zoom canvas is disabled, disable also zoom stage on wheel. 
      // this is use in crop mode to default zoom to 100%. refer to #TECH-6335
      const canvasZoomDropdownElement = document.querySelector('#canvas-zoom');
      if(canvasZoomDropdownElement && canvasZoomDropdownElement.disabled) {
        return;
      }
      
      // stop default scrolling
      e.evt.preventDefault();
      var oldScale = this.stage.scaleX();

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1;
      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      // restrict by minScale and minScale
      if((oldScale <= minScale && direction < 0) 
      || (oldScale >= maxScale && direction > 0))
        return;

      var pointerInMiddle = { x: this.stage.height()/2, y: this.stage.width()/2 };
      var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      this.stage.scale({ x: newScale, y: newScale });
      
      var newPos = {
        x: pointerInMiddle.x - pointerInMiddle.x * newScale,
        y: pointerInMiddle.y - pointerInMiddle.y * newScale,
      };

     if(this.stage.rotation() === 90) {
      newPos = {
        x: this.stage.width() * newScale + pointerInMiddle.x - pointerInMiddle.x * newScale,
        y: pointerInMiddle.y - pointerInMiddle.y * newScale,
      };
    }
      let backgroundLayer = this.layersHash['background_layer'];
      if (backgroundLayer && backgroundLayer.layer) {
        this.stage.container().style.backgroundColor = backgroundLayer.color;
      }  

      this.publicEvents.emit(PUBLIC_EVENTS.CANVAS_ZOOM, newScale);
      this.stage.position(newPos);
      this.stage.batchDraw();
    });
  }

  redraw(state$) {
    let state = state$.toJS();
    // TODO: Should it emit event on start or on end?
    //this.publicEvents.emit(PUBLIC_EVENTS.CHANGE, state);

    // Render Canvas Surface from the given state

    // do nothing if template not loaded
    if (!state.template.layers.length) {
      return;
    }

    // update orientation
    this.setOrientation(state.editor.defaultOrientation, state.editor.orientation);

    // sort layers by z index
    let renderLayers = state.template.layers.sort((a, b) => a.zindex - b.zindex);

    let imageLayerDrawed = false;

    // Editor Context - created each time state changed,
    // store all global editor parts for intercommunications
    let editorCtx = {
      state: state,
      stage: this.stage,
      events: new EventEmitter(), // only local events between redraw cycles (for example - dragging, scaling, rotating)
      actionProcessorCb: this.actionProcessorCb
    };

    // draw background color layer, used to match print area color, should be added first
    this.drawLayer(editorCtx, 'background_layer', BackgroundSurfaceLayer);

    for(let l of renderLayers) {
      if (l.type === 'design' || l.type === 'bleed') {
        let templateState = {
          image: l.image,
          layer: l,
          state: state
        };
        this.drawLayer(templateState, 'template_layer_' + l.id, TemplateSurfaceLayer);
      }
      else if (l.type === 'image' && !imageLayerDrawed) {
        this.drawLayer(editorCtx, 'image_layer', ImageSurfaceLayer);
        // draw only one image layer. This layer will contain all user images
        imageLayerDrawed = true;
      }
    }

    // viewport and action layers always renders on top of others, they are catching all mouse/touch events
    this.drawLayer(editorCtx, 'viewport_layer', ViewportSurfaceLayer);
    this.drawLayer(editorCtx, 'action_layer', ActionsSurfaceLayer);

    this.zoomApi.setActive(editorCtx);
    editorCtx.zoomApi = this.zoomApi;
  }

  drawLayer(ctx: any, layerId: string, Type: any) {
    let existingLayer = this.layersHash[layerId];
    if (existingLayer) {
      existingLayer.draw(ctx);
    }
    else {
      let newLayer = new Type(layerId, this.publicEvents);
      newLayer.addToCanvas(this.stage);
      newLayer.draw(ctx);
      this.layersHash[layerId] = newLayer;
    }
  }

  destroy() {
    Object.values(this.layersHash).forEach(function(l) {
      // TODO: use CanvasLayer wrapper for all layers
      if (l.layer.layer) {
        l.layer.destroy();
      }
      else {
        l.layer.destroyChildren();
        l.layer.destroy();
      }
    });
    this.layersHash = {};

    if (this.stage) {
      this.stage.destroyChildren();
      this.stage.destroy();
      this.stage = null;
    }

    this.container.innerHTML = '';
  }

  setOrientation(defaultOrientation, orientation) {
    if (this.orientation === orientation) {
      return;
    }

    if (defaultOrientation !== orientation) {
      this.orientation = orientation;
      this.stage.rotation(90);
      let w = this.stage.width();
      let h = this.stage.height();
      this.stage.width(h);
      this.stage.height(w);
      this.stage.x(this.stage.width());
    }
    else {
      this.orientation = orientation;
      this.stage.rotation(0);
      this.stage.x(0);
      let w = this.stage.width();
      let h = this.stage.height();
      this.stage.width(h);
      this.stage.height(w);
    }

    this.stage.batchDraw();
  }
}
