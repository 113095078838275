import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import './CountrySelectorInline.scss';
import Strings from './strings';
import Multiselect from '../Multiselect';
import Log from '../../../services/logService';
import Alert from '../Alert';

const multiselectConfig = {
  multiple: false,
  showFilter: true,
  showBulkAction: false
};

class CountrySelectorInline extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (
      this.props.shippingCountries &&
      this.props.shippingCountries.length &&
      this.props.shippingCountry
    ) {
      const isCountryValid = this.props.shippingCountries.find(
        c => c.code === this.props.shippingCountry
      );
      if (!isCountryValid) {
        // passed country code is not valid
        // pre-select default US
        Log.warn(`Country code ${this.props.shippingCountry} not found, reset to default US`);
        this.props.updateShippingCountry(this.props.defaultCountryCode);
      }
    }
  }

  onCountryChange = selected => {
    this.props.updateShippingCountry(selected[0] ? selected[0].code : '');
  };

  getContent = () => {
    if (!this.props.isOpen) {
      return null;
    }

    if (!this.props.shippingCountries) {
      return null;
    }

    const currentShippingCountry = this.props.shippingCountries.find(
      country => country.code === this.props.shippingCountry
    );

    let errorInfo = null;

    if (currentShippingCountry) {
      const currentShippingCountryFullName = currentShippingCountry.key;

      errorInfo = (
        <div className="flex-container flex-center-aligned">
          Some of your items below cannot be shipped to {currentShippingCountryFullName}
        </div>
      );
    }

    const selected = currentShippingCountry ? [currentShippingCountry] : [];

    return (
      <div className="country-selector-inline-body">
        <div className="top-row">
          <div className="title">Ship items to:</div>
        </div>
        <div className="bottom-row flex-container flex-center-aligned">
          <Multiselect
            title={Strings.SELECT_SHIPPING_COUNTRY}
            options={this.props.shippingCountries}
            selectedOptions={selected}
            onChange={this.onCountryChange}
            config={multiselectConfig}
          />
          {this.props.cartHasErrors ? (
            <Alert type="warning" className="ml-3" isOpen>
              {errorInfo}
            </Alert>
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="country-selector-inline-container">
        <ReactCSSTransitionGroup
          transitionName="slide-down"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {this.getContent()}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

CountrySelectorInline.propTypes = {
  isOpen: PropTypes.bool,
  cartHasErrors: PropTypes.bool,
  shippingCountries: PropTypes.array,
  defaultCountryCode: PropTypes.string,
  shippingCountry: PropTypes.string,
  updateShippingCountry: PropTypes.func
};

export default CountrySelectorInline;
