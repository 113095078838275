import { connect } from 'react-redux';
import FullScreenPreview from './FullScreenPreview';
import {
  allOptionsSelector,
  allAssociatedOptionsSelector,
  currentItemSelector
} from './FullScreenPreviewSelectors';
import { generationStateSelector } from './../../ProductPreviewSelectors';
import {
  fullScreenCurrentItems,
  generateFullscreenPreview,
  cancelFullscreenGeneration
} from './../../ProductPreviewActions';

const mapDispatchToProps = dispatch => {
  return {
    setFullScreenCurrentItems: items => {
      dispatch(fullScreenCurrentItems(items));
      if (
        !items[0].isExclusiveMockup &&
        (!items[0].fullscreenImageUrl || items[0].generateFullscreen)
      ) {
        dispatch(generateFullscreenPreview(items));
      }
    },
    cancelFullscreenGeneration: () => dispatch(cancelFullscreenGeneration())
  };
};

const mapStateToProps = state => {
  const currentItem = currentItemSelector(state);
  return {
    allOptions: allOptionsSelector(state),
    allAssociatedOptions: allAssociatedOptionsSelector(state),
    currentItem,
    showLoading: generationStateSelector(state).fullscreenState === 'WORKING'
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenPreview);
