import NeckLabelsCOF from './NeckLabelsCOF';

import { connect } from 'react-redux';
import { skusWithNeckTagSelector } from '../../../ProductPublish/ProductPublishSelectors';
import {
  createNeckTag,
  fetchNeckTags
} from '../../../ProductPublish/atoms/NeckLabels/NeckLabelsActions';
import {
  neckLabelsSelector,
  errorSelector
} from '../../../ProductPublish/atoms/NeckLabels/NeckLabelsSelectors';
import { selectedNeckLabelSelector } from './NeckLabelsCOFSelectors';
import { neckLabelSelectionChanged } from '../../../../store/actions/dataActions';
import { neckLabelsPreviewsLoadingSelector } from '../../ImageUploadSelectors';
const mapDispatchToProps = dispatch => {
  return {
    fetchNeckTags: () => dispatch(fetchNeckTags()),
    setSelectedNeckTag: item => dispatch(neckLabelSelectionChanged(item)),
    createNeckTag: item => dispatch(createNeckTag(item, true))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    error: errorSelector(state),
    neckLabelList: neckLabelsSelector(state),
    skusWithNeckTags: skusWithNeckTagSelector(state),
    selectedNeckLabel: selectedNeckLabelSelector(state),
    neckLabelsPreviewsLoading: neckLabelsPreviewsLoadingSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NeckLabelsCOF);
