import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Button from './Button.js';
import Icon from './Icon.js';
import Chip from './Chip.js';
import Spinner from './Spinner';
import Checkbox from './Checkbox.js';
import Tooltip from './Tooltip';

const ProductCard = ({
  type = 'hub',
  title,
  description,
  // active = false,
  selected = false,
  loading = false,
  // stores = 0,
  image,
  selectClick,
  productClick,
  editItemClick,
  additionalInfo,
  menuClick,
  errorInfo,
  fullLengthTitle,
  disabled
}) => {
  return (
    <div className={`card ${disabled ? 'grayscale' : ''}`}>
      <div className="image-container position-relative">
        {loading ? (
          <Spinner />
        ) : image ? (
          <div className="image-holder">
            <img onClick={disabled ? null : productClick} src={image} alt={title} />
          </div>
        ) : (
          <div
            onClick={disabled ? null : productClick}
            className="icons-container position-absolute"
          >
            <Icon width={40} height={40} fill={COLORS.neutralDark200} icon="image" />
          </div>
        )}
        {type === 'promo' ? (
          <Button iconOnly className="right button-default small">
            <Icon small icon="x" />
          </Button>
        ) : (
          <>
            {selectClick && (
              <Button
                onClick={disabled ? null : selectClick}
                iconOnly
                className="left button-default medium"
              >
                <Checkbox checked={selected} />
              </Button>
            )}
            {menuClick && (
              <Button
                onClick={disabled ? null : menuClick}
                iconOnly
                className="right button-default medium"
              >
                <Icon icon="moreVertical" />
              </Button>
            )}
            {selectClick && (
              <div
                className="topleft-click-area checkbox-click"
                onClick={disabled ? null : selectClick}
              />
            )}
            {menuClick && (
              <div
                className="topright-click-area overflow-click"
                onClick={disabled ? null : menuClick}
              />
            )}
          </>
        )}
      </div>
      <div className="text-container" onClick={disabled ? null : editItemClick}>
        <div>
          <div
            title={title}
            className={`product-title ${!fullLengthTitle ? 'truncated-text' : ''} title-3`}
          >
            {title}
          </div>
          <div className="caption-text">{additionalInfo && additionalInfo}</div>
          {description && (
            <div className={`product-type caption-text truncated-text`}>{description}</div>
          )}
        </div>
        {errorInfo && (
          <Tooltip containerClassName="w-100 mt-2" content={errorInfo.tooltip}>
            <Chip className="red" iconLeft="slash">
              {errorInfo.message}
            </Chip>
          </Tooltip>
        )}
        {/* implementing chip status at a later time below */}
        {/* {type === 'to be adjusted' && (
          <Chip className="light">
            {active ? 'Active ' : 'Inactive '} in {stores} stores
          </Chip>
        )} */}
        {type === 'promo' && <Button className="button-secondary medium">View product</Button>}
      </div>

      <style jsx>
        {`
          * {
            transition-duration: 0.2s;
            box-sizing: border-box;
          }
          .card {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            max-width: 18rem;
            border: 1px solid ${selected ? COLORS.gootenBlue500 : COLORS.neutralLight300};
            display: flex;
            flex-direction: column;
            margin: auto;
            position: relative;
            background: ${selected ? COLORS.gootenBlue200 : COLORS.neutralLight50};
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
          }
          .grayscale {
            filter: grayscale(1);
          }
          .card img {
            max-width: ${selected ? 'calc(100% - 16px)' : '100%'};
            max-height: ${selected ? 'calc(100% - 16px)' : '100%'};
            transition: all ease 150ms;
            border-radius: ${selected ? '4px' : '0'};
          }
          .image-container {
            width: 100%;
            padding-bottom: ${type === 'promo' ? '80%' : '100%'};
            height: 0;
            position: relative;
          }
          .image-holder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
          .topleft-click-area {
            position: absolute;
            height: 4rem;
            width: 50%;
            left: 0;
            top: 0;
          }
          .topright-click-area {
            position: absolute;
            height: 4rem;
            width: 50%;
            right: 0;
            top: 0;
          }
          .image-container :global(button.right) {
            position: absolute;
            right: 1rem;
            top: 1rem;
            opacity: 0;
          }
          .image-container :global(button.left) {
            position: absolute;
            left: 1rem;
            top: 1rem;
            opacity: ${selected ? '1' : '0'};
          }
          .image-container:hover :global(button) {
            opacity: 1;
          }
          .text-container {
            padding: 0.5rem 1rem 1rem 1rem;
            text-align: left;
            height: ${type === 'promo' ? '9rem' : 'auto'};
            position: relative;
          }
          .text-container :global(button) {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
          }
          .truncated-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${type === 'hub' ? '1' : '3'};
          }
          @media screen and (max-width: 991px) {
            .image-container :global(button) {
              opacity: 1 !important;
            }
          }
          .image-container :global(label) {
            margin: 0;
          }
          .icons-container {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
    </div>
  );
};

ProductCard.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  active: PropTypes.bool,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  stores: PropTypes.number,
  image: PropTypes.string,
  selectClick: PropTypes.func,
  productClick: PropTypes.func,
  editItemClick: PropTypes.func,
  additionalInfo: PropTypes.object,
  menuClick: PropTypes.func,
  errorInfo: PropTypes.object,
  fullLengthTitle: PropTypes.bool
};

export default ProductCard;
