import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import './Collapse.scss';
import Icon from 'gooten-components/src/components/shared/Icon';
import { COLORS } from 'gooten-components/src/constants';

class Collapse extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || props.forceOpen
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.toggle = this.toggle.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.forceOpen) {
      return {
        open: props.forceOpen
      };
    } else if (props.open !== null) {
      return {
        open: props.open
      };
    }

    return null;
  }

  handleToggle() {
    if (!this.props.forceOpen) {
      let newState = {
        open: !this.state.open
      };
      if (this.props.onToggle) {
        this.props.onToggle(this, newState.open);
      }
      this.setState(newState);
    }
  }

  toggle() {
    if (!this.props.forceOpen) {
      this.handleToggle();
    }
  }

  collapse() {
    if (!this.props.forceOpen) {
      this.setState({ open: false });
    }
  }

  render() {
    const collapseAnchor = (
      <span
        className={
          this.state.open ? 'collapse-icon icon-collapse' : 'collapse-icon-closed icon-dropdown'
        }
      />
    );

    const showHideLink = (
      <a className="show-hide-link" onClick={this.handleToggle}>
        {this.state.open ? 'Hide' : 'Show'}
      </a>
    );

    const changeLink = (
      <a className="change-link" onClick={this.handleToggle}>
        {this.state.open ? '' : 'Change'}
      </a>
    );

    const titleClassName =
      `d-flex title ${this.state.open || 'title-closed-view'}` +
      ` ${this.props.forceOpen || ' clickable'}`;

    const checkIcon = (
      <div className="check-container mr-3">
        {this.props.checked && <Icon icon="check" width={30} height={30} />}
        <style jsx>
          {`
            .check-container {
              display: flex;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: ${this.props.checked ? COLORS.gootenBlue500 : COLORS.neutralLight200};
              border: 1px solid
                ${this.props.checked ? COLORS.gootenBlue500 : COLORS.neutralLight400};
              fill: white;
            }
          `}
        </style>
      </div>
    );

    let titleHiLevel = (
      <h3 className={titleClassName} onClick={this.handleToggle}>
        <div className="d-flex">
          {checkIcon}
          {this.props.title}
        </div>
        {this.props.forceOpen ? null : (
          <div className="show-hide-container flex-inline-container flex-center-aligned">
            {showHideLink}
            {collapseAnchor}
          </div>
        )}
      </h3>
    );

    let titleRegular = (
      <h4 className={titleClassName} onClick={this.handleToggle}>
        <div className="d-flex">
          {checkIcon}
          {this.props.title}
        </div>
        {this.props.forceOpen ? null : (
          <div className="show-hide-container flex-inline-container flex-center-aligned">
            {this.props.closedView ? changeLink : null}
            {collapseAnchor}
          </div>
        )}
      </h4>
    );

    let titleSmall = (
      <h5 className={titleClassName} onClick={this.handleToggle}>
        <div className="d-flex">
          {checkIcon}
          {this.props.title}
        </div>
        {this.props.forceOpen ? null : (
          <div className="show-hide-container flex-inline-container flex-center-aligned">
            {this.props.closedView ? changeLink : null}
            {collapseAnchor}
          </div>
        )}
      </h5>
    );

    const title = this.props.hiLevel
      ? titleHiLevel
      : this.props.smallTitle
      ? titleSmall
      : titleRegular;

    return (
      <div
        className={`collapse-container ${
          this.props.hiLevel || this.props.smallTitle || 'display-bottom-border'
        }`}
      >
        {title}

        <div className="collapse-content-container">
          {!this.state.open && this.props.closedView && (
            <div className="collapse-closed">{this.props.closedView}</div>
          )}
          <ReactCSSTransitionGroup
            transitionName="slide-down"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {this.state.open ? <div className="collapse-content">{this.props.children}</div> : null}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  }
}

Collapse.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  hiLevel: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  forceOpen: PropTypes.bool,
  closedView: PropTypes.any,
  smallTitle: PropTypes.bool,
  checked: PropTypes.bool
};

Collapse.defaultProps = {
  open: false
};

export default Collapse;
