import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TikTokConnect from './TikTokConnect';
import EtsyConnect from './EtsyConnect';
import ShopifyConnect from './ShopifyConnect';
import WooConnect from './WooConnect';
import BigCommerceConnect from './BigCommerceConnect';
import Providers from '../HubView/components/Providers';
import Loader from 'gooten-components/src/components/shared/Loader';
import ErrorModal from 'gooten-components/src/components/shared/ErrorModal';
import Strings from './strings';
import ConnectSuccessModal from './shared/ConnectSuccessModal';

const ConnectStoreView = props => {
  useEffect(() => {
    // on component mount, check if need to autoconnect to specific store
    const query = new URLSearchParams(props.location.search);
    const storeName = query && query.get('store');
    const shouldAutoconnect =
      props.selectedProvider === 'tiktok' ||
      props.selectedProvider === 'etsy' ||
      props.selectedProvider === 'woocommerce' ||
      props.selectedProvider === 'bigcommerce';

    if (storeName && shouldAutoconnect) {
      props.connectStore(props.selectedProvider, storeName);
    }
  }, [props.selectedProvider]);

  let connectStore = null;
  if (props.selectedProvider) {
    if (props.selectedProvider === 'etsy') {
      connectStore = <EtsyConnect />;
    } else if (props.selectedProvider === 'tiktok') {
      connectStore = <TikTokConnect />;
    } else if (props.selectedProvider === 'woocommerce') {
      connectStore = <WooConnect />;
    } else if (props.selectedProvider === 'shopify') {
      connectStore = <ShopifyConnect />;
    } else if (props.selectedProvider === 'bigcommerce') {
      connectStore = <BigCommerceConnect />;
    } else if (props.selectedProvider === 'all' || props.selectedProvider === 'storage') {
      // fix for console error during navigation from connect page
      connectStore = null;
    } else {
      throw new Error('Connect store view is not available for ' + props.selectedProvider);
    }
  }
  return (
    <React.Fragment>
      <Providers {...props} />
      {props.location.query.storeId && <ConnectSuccessModal />}

      <ErrorModal
        isOpen={!props.isConnecting && !!props.oauthErrorMsg.length}
        errorMessage={props.oauthErrorMsg}
        modalClose={props.cancel}
      />

      {props.isConnecting ? (
        <React.Fragment>
          <Loader />
          <div className="col-md-offset-2 col-md-8" style={{ paddingTop: '100px' }}>
            <div className="col-sm-12 text-center">
              <div className="icon-slothy-connect" />
              <h3 className="text-center">{Strings.PAGE_TEXT}</h3>
              <p className="text-center">
                If you are having trouble connecting, ensure that your popup blocker is disabled and
                try again.
              </p>
            </div>
          </div>
        </React.Fragment>
      ) : (
        connectStore
      )}
    </React.Fragment>
  );
};

ConnectStoreView.propTypes = {
  selectedProvider: PropTypes.string,
  isConnecting: PropTypes.bool.isRequired,
  oauthErrorMsg: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  connectStore: PropTypes.func.isRequired,
  stores: PropTypes.object,
  location: PropTypes.object
};

export default ConnectStoreView;
