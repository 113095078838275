import { postJson } from '../../../../../utils/http';
import Config from '../../../../../config';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId'),
      partnerBillingKey: Config.get('storeApiKey')
    }
  };
};

// NOTE: API V5 is using special http status code like 400 for validation errors
// Which cause fetch to catch it as error
// Make sure to try catch these errors
const getShippingPricesUrl = () => `${Config.get('api')}v/5/source/api/shippingprices`;

class ShippingPricesService {
  getShippingPrices(request) {
    return new Promise((resolve, reject) => {
      postJson(getShippingPricesUrl(), request, getAuthQueryParams())
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

// singleton
export default new ShippingPricesService();
