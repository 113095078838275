import { connect } from 'react-redux';
import Providers from './Providers';
import { availableProvidersSelector, selectedProviderSelector } from './ProvidersSelectors';
import { providerSelect } from './ProvidersActions';
import {
  selectedStoreSelector,
  selectedStoreNameSelector,
  selectedStoreHasProductsToLinkSelector
} from '../Stores/StoresSelectors';
import { storeLogOut } from '../Stores/StoresActions';
import { customReset } from '../../../../store/actions/globalActions';

const mapDispatchToProps = {
  selectProvider: ({ provider, reset }) => providerSelect({ provider, reset }),
  resetLinkFlow: () => customReset('link'),
  storeLogOut: id => storeLogOut(id)
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasSelectedStore: !!selectedStoreSelector(state),
    selectedStoreHasProductsToLink: selectedStoreHasProductsToLinkSelector(state),
    availableProviders: availableProvidersSelector(state),
    selectedProvider: selectedProviderSelector(state),
    selectedStoreName: selectedStoreNameSelector(state),
    selectedStore: selectedStoreSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
