import { connect } from 'react-redux';
import EmailPreferences from './EmailPreferences';
import { initFor, updateFor, changeFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { updateOccasionalEmails } from './EmailPreferencesActions';

const init = initFor(EmailPreferences.name);
const change = changeFor(EmailPreferences.name);
const update = updateFor(EmailPreferences.name);
const cardSelector = cardSelectorFor(EmailPreferences.name);

const mapDispatchToProps = {
  init,
  change,
  update,
  updateOccasionalEmails
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreferences);
