import { connect } from 'react-redux';
import EmailServices from './EmailServices';
import { initFor, changeFor, updateFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';

const init = initFor(EmailServices.name);
const change = changeFor(EmailServices.name);
const update = updateFor(EmailServices.name);
const cardSelector = cardSelectorFor(EmailServices.name);

const mapDispatchToProps = {
  init,
  change,
  update
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailServices);
