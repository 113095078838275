import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabContainer } from 'gooten-components/src/components/shared/Tab';
import { List } from 'immutable';
// import LogOutConfirmationModal from './LogOutConfirmationModal'
import throttle from 'gooten-components/src/utils/throttle';

import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';
// import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer'
// import ListItem from 'gooten-components/src/components/shared/ListItem'

const STORE_PROVIDERS = new Set(['shopify', 'etsy', 'woocommerce', 'bigcommerce', 'tiktok']);

class Stores extends React.PureComponent {
  static propTypes = {
    storesLoaded: PropTypes.bool.isRequired,
    availableStores: PropTypes.instanceOf(List).isRequired,
    selectedStore: PropTypes.number,
    params: PropTypes.object,
    router: PropTypes.object,
    selectStore: PropTypes.func.isRequired,
    selectedProvider: PropTypes.string,
    // selectedStoreName : PropTypes.string,
    loadStores: PropTypes.func.isRequired
    // storeLogOut: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { isMobile: false };
    this.onResize = throttle(this.onResize.bind(this), 300);
  }

  onResize() {
    if (this._ismounted) {
      this.setState({ isMobile: window.innerWidth <= 767 });
    }
  }

  componentDidMount() {
    this._ismounted = true;
    // get user stores from API
    if (!this.props.storesLoaded) {
      this.props.loadStores();
    } else {
      this.componentDidUpdate();
    }

    // load new stores after connecte new store redirect`
    if (
      window.GTN.__bridge__ &&
      window.GTN.__bridge__.connectNewStore &&
      window.GTN.__bridge__.connectNewStore.RecipeIntegrationId
    ) {
      this.props.loadStores();
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    // put selected store to state
    if (this.props.storesLoaded && !this.props.selectedStore) {
      // take from route path if valid
      if (
        this.props.params.store &&
        this.props.availableStores.find(s => s.get('id') === parseInt(this.props.params.store))
      ) {
        this.props.selectStore({
          store: parseInt(this.props.params.store),
          reset: false
        });
      } else if (this.props.availableStores.size) {
        // take first from available
        const defaultStore = this.props.availableStores.first();
        this.props.selectStore({ store: defaultStore.get('id') });
        this.props.router.replace(`/hub/${defaultStore.get('provider')}/${defaultStore.get('id')}`);
      } else if (STORE_PROVIDERS.has(this.props.selectedProvider)) {
        // redirect to store connect page
        this.storeConnect(true);
      }
    }

    // select just new connected store
    if (
      window.GTN.__bridge__ &&
      window.GTN.__bridge__.connectNewStore &&
      window.GTN.__bridge__.connectNewStore.RecipeIntegrationId
    ) {
      console.debug('has connectNewStore', window.GTN.__bridge__.connectNewStore);
      if (
        this.props.availableStores.find(
          s => s.get('id') === window.GTN.__bridge__.connectNewStore.RecipeIntegrationId
        )
      ) {
        console.debug('found new store in availables');
        this.props.selectStore({
          store: window.GTN.__bridge__.connectNewStore.RecipeIntegrationId
        });
        this.props.router.replace(
          `/hub/shopify/${window.GTN.__bridge__.connectNewStore.RecipeIntegrationId}`
        );
        window.GTN.__bridge__.connectNewStore = null;
      }
    }
  }

  onStoreClick = store => {
    if (this.props.selectedStore !== store.get('id')) {
      this.props.selectStore({ store: store.get('id') });
    }
  };

  // onStoreLogOut () {
  //   this.logOutConfirmationModal.openModal()
  // }

  storeConnect(replace = false) {
    const redirect = replace ? this.props.router.replace : this.props.router.push;
    redirect(`/hub/${this.props.selectedProvider}/connect-store`);
  }

  // storeLogOut () {
  //   this.props.storeLogOut(this.props.selectedStore)
  // }

  render() {
    if (!this.props.storesLoaded || !this.props.availableStores.size) {
      return null;
    }

    const items = this.props.availableStores
      .map((s, index) => (
        <Tab
          className={this.props.availableStores.size === 1 ? 'single' : ''}
          key={s.get('id')}
          active={this.props.selectedStore === s.get('id')}
          to={`/hub/${s.get('provider')}/${s.get('id')}`}
          onClick={() => this.onStoreClick(s)}
        >
          {s.get('storeName')}
        </Tab>
      ))
      .toArray();

    if (!this.state.isMobile) {
      // add store button to NavBar
      items.push(
        <Tab
          key="add-store-button"
          className="add-store-button"
          onClick={() => {
            this.storeConnect();
          }}
        >
          <Icon icon="plusSquare" />
          <span className="pl-1">Add Store</span>
        </Tab>
      );
    }

    return (
      <div className="stores-container d-flex mb-3">
        <div className="d-flex w-100">
          <Button
            className="visible-xs button-default large"
            iconOnly
            onClick={() => {
              this.storeConnect();
            }}
          >
            <Icon icon="plusCircle" />
          </Button>
          <div className="nav-bar-holder w-100">
            <TabContainer className="nav-pills">{items}</TabContainer>
          </div>
        </div>
        {/* <div className='d-flex logout'>
          <DropDownContainer
            buttonOnly
            rightAlign
          >
            <ListItem text={`Log Out: ${this.props.selectedStoreName}`}
            leftIcon='logOut' onSelect={() => this.onStoreLogOut()} />
          </DropDownContainer>
        </div>
        <LogOutConfirmationModal
          ref={instance => { this.logOutConfirmationModal = instance }}
          confirm={() => this.storeLogOut()} /> */}
        <style jsx>
          {`
            .stores-container {
              justify-content: space-between;
              overflow: visible;
            }
            .logout {
              align-items: center;
            }
            .logout :global(button) {
              height: 2rem;
              width: 2rem;
            }
          `}
        </style>
      </div>
    );
  }
}

export default Stores;
