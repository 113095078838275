import styled from 'styled-components';

export default styled.div`
  .preview-container {
    background-image: url('https://appassets.azureedge.net/placeholder/fully_blank_nl.png');
    height: 300px;
    width: 251px;
    margin-left: 15px;
    margin-top: 20px;
    background-size: cover;
    border: 1px solid #bcbfc2;
  }

  .necktag-container {
    position: relative;
    width: 250px;
    height: 100px;
    overflow: hidden;
  }

  .necktag-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
  }
`;
