import { ActionNameFactory } from '../../../../../utils/actions';

export const getActionName = ActionNameFactory(
  'COMPONENTS',
  'IMAGE UPLOAD - EMBROIDERY DISCLAIMER'
);

export const SHOW_DISCLAIMER = getActionName('SHOW_DISCLAIMER');

export const showDisclaimer = payload => ({
  type: SHOW_DISCLAIMER,
  payload
});
