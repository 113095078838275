import React, { useState, useRef, useEffect } from 'react';

const CollapsibleTextComponent = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth);
    }
  }, [text]);

  const handleToggle = e => {
    if (window.getSelection().toString() === '') {
      setIsExpanded(!isExpanded);
    }
  };

  const containerStyle = {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const textStyle = isExpanded
    ? {
        whiteSpace: 'pre-wrap', // Wrap text and preserve whitespace
        overflow: 'hidden',
        flexGrow: 1,
        cursor: 'pointer',
        padding: '8px 0' // Adding 2px padding to the top and bottom
      }
    : {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        cursor: 'pointer',
        padding: '8px 0' // Adding 2px padding to the top and bottom
      };

  const buttonStyle = {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    marginLeft: '8px',
    fontWeight: 'bold',
    fontSize: '18px'
  };

  // Function to render the text, with JSON parts as code if expanded
  const renderText = () => {
    if (isExpanded) {
      try {
        const parts = text.split(/({.*})/g);
        return parts.map((part, index) => {
          try {
            JSON.parse(part);
            return (
              <code key={index} style={{ whiteSpace: 'pre-wrap' }}>
                {part}
              </code>
            );
          } catch {
            return part;
          }
        });
      } catch {
        return text;
      }
    }
    return text;
  };

  return (
    <div style={containerStyle}>
      <div ref={textRef} style={textStyle} onClick={handleToggle}>
        {renderText()}
      </div>
      {isOverflowing && (
        <button onClick={handleToggle} style={buttonStyle}>
          {isExpanded ? '↑' : '↓'}
        </button>
      )}
    </div>
  );
};

export default CollapsibleTextComponent;
