import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import CountrySelection from 'gooten-components/src/components/shared/CountrySelection';
import HubProducts from './components/HubProducts';
import HubProductsSearch from './components/HubProductsSearch';
import analyticsService from 'gooten-components/src/services/analyticsService';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import SelectorDialog from './components/SelectorDialog';
import VariantModal from './components/VariantModal';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import PageBody from 'gooten-components/src/components/shared/PageBody';

class HubProductsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countrySelected: false,
      showSelected: false,
      showMobileSort: false,
      sortArray: [
        {
          title: 'Product title asc',
          value: 'product-title'
        },
        {
          title: 'Product title desc',
          value: 'product-title-reversed'
        },
        {
          title: 'Date created',
          value: 'date-created'
        },
        {
          title: 'Date updated',
          value: 'date-updated'
        }
      ]
    };
  }

  onCountrySelect = () => {
    this.setState({
      countrySelected: true
    });
  };

  componentDidMount() {
    analyticsService.track('COF - Hub Products Flow', 'Hub Products Page Reached', 'COF');
  }

  componentWillUnmount() {
    this.props.searchReset();
  }

  goToCart = () => {
    window.location.href = '#/custom-order-form/cart';
  };

  goToCOF = () => {
    window.location.href = '#/custom-order-form';
  };

  removeSelections = selections => {
    if (!selections.length) {
      return;
    }
    selections.forEach(selection => {
      this.props.toggleHubProductSelected(selection, false);
    });
  };

  renderListItem(sortItem, mobile = false) {
    const { title, value } = sortItem;

    if (mobile) {
      return (
        <div key={value} onClick={() => this.props.changeSortValue(value)}>
          <ListItem
            className="full-width-list-item"
            text={title}
            leftIcon={this.props.sortValue === value ? 'check' : null}
          />
        </div>
      );
    } else {
      return (
        <ListItem
          key={value}
          text={title}
          leftIcon={this.props.sortValue === value ? 'check' : null}
          onSelect={() => this.props.changeSortValue(value)}
        />
      );
    }
  }

  renderHubProducts() {
    return (
      <>
        <div>
          <PageHeader
            size="standard"
            title="Hub Products"
            customButtons={
              this.props.hasCartItems && (
                <Button
                  onClick={() => this.goToCart()}
                  className="button-cta full-width large go-to-cart"
                >
                  Go to cart
                </Button>
              )
            }
            backClick={() => {
              this.setState({ countrySelected: false });
              this.props.searchReset();
              if (this.props.hasCartItems) {
                this.goToCOF();
              }
            }}
          />
        </div>
        <PageBody className="cof-hub-products-view-container">
          <div>
            <div className="hub-products-search-container">
              <div className="hub-products-search-input-container pr-2">
                <HubProductsSearch
                  doSearch={this.props.doSearch}
                  searchText={this.props.searchText}
                />
              </div>
              <Button
                iconOnly
                className="slider-button button-default large"
                onClick={() => this.setState({ showMobileSort: true })}
              >
                <Icon icon={'sliders'} />
              </Button>

              <DropDownContainer rightAlign className="drop-down-container-updated" title={'Sort'}>
                {this.state.sortArray.map(sortItem => this.renderListItem(sortItem))}
              </DropDownContainer>
            </div>

            <HubProducts />

            {!!this.props.selectedItems.size && (
              <SelectorDialog
                addToCart={() => this.props.addToCart(this.props.selectedItems)}
                numberOfSelectedItems={this.props.selectedItems.size}
                showSelected={() => this.setState({ showSelected: !this.state.showSelected })}
                deselectAll={() => this.props.deselectAll()}
              />
            )}
            {this.state.showSelected && (
              <VariantModal
                selectedItems={this.props.selectedItems}
                showSelected={() => this.setState({ showSelected: !this.state.showSelected })}
                removeSelections={selections => this.removeSelections(selections)}
              />
            )}

            {this.state.showMobileSort && (
              <div className="hub-products-sort-mobile-container">
                <div onClick={() => this.setState({ showMobileSort: false })} style={{ flex: 1 }} />
                <div className="hub-products-sort-mobile-selector">
                  {this.state.sortArray.map(sortItem => this.renderListItem(sortItem, true))}
                  <Button
                    onClick={() => this.setState({ showMobileSort: false })}
                    className={'button-default large'}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </PageBody>
        <style jsx>
          {`
            .hub-products-search-input-container {
              flex: 1;
            }
            .hub-products-search-container {
              display: flex;
              margin-top: 0px;
              padding-top: 0px;
              flex: 1;
            }
            .hub-products-sort-mobile-container {
              position: fixed;
              z-index: 1050;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.5);
              display: flex;
              flex-direction: column;
            }
            .hub-products-sort-mobile-selector {
              display: flex;
              flex-direction: column;
              background-color: white;
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
              padding: 16px;
            }
            .hub-products-header {
              flex: 1;
              padding-top: 8px;
              margin: 0 0 24px 0 !important;
            }
            @media (max-width: 699px) {
              :global(.drop-down-container-updated) {
                display: none;
              }
            }
            @media (min-width: 700px) {
              :global(.slider-button) {
                display: none;
              }
            }
            :global(.full-width-list-item) {
              max-width: 100% !important;
              cursor: pointer;
              padding-left: 32px !important;
            }
          `}
        </style>
      </>
    );
  }

  render() {
    if (!this.props.hasCartItems && !this.state.countrySelected) {
      return <CountrySelection onNextClick={this.onCountrySelect} />;
    } else {
      return this.renderHubProducts();
    }
  }
}

HubProductsView.propTypes = {
  searchReset: PropTypes.func,
  doSearch: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  hasCartItems: PropTypes.bool.isRequired,
  selectedItems: PropTypes.instanceOf(List),
  addToCart: PropTypes.func.isRequired,
  toggleHubProductSelected: PropTypes.func.isRequired,
  sortValue: PropTypes.string.isRequired,
  changeSortValue: PropTypes.func.isRequired
};

export default HubProductsView;
