import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'gooten-components/src/components/shared/Alert';

const StatusDescriptions = props => {
  const SHIPPING_ISSUE_STATUS_ID = 25;
  const SHIPPING_ISSUE_STATUS_MSG =
    'There was a problem shipping this order. For details, click the tracking link below.';

  const orderStatusesWithDesc = props.orderStatuses.filter(status => {
    if (status.Id === SHIPPING_ISSUE_STATUS_ID && props.shippingIssues.length === 0) return false;
    return status.Description;
  });

  return (
    <>
      {orderStatusesWithDesc.length > 0 && (
        <Alert isOpen disabled={true} className="w-100" type="warning">
          {orderStatusesWithDesc.map(orderStatus => {
            const desc =
              orderStatus.Id === SHIPPING_ISSUE_STATUS_ID
                ? SHIPPING_ISSUE_STATUS_MSG
                : orderStatus.Description;
            return (
              <React.Fragment key={orderStatus.Id}>
                <div dangerouslySetInnerHTML={{ __html: desc }} />
                {orderStatus.Id === SHIPPING_ISSUE_STATUS_ID &&
                  props.shippingIssues.map((issue, index) => (
                    <React.Fragment key={issue.TrackingNumber}>
                      <a target="_blank" href={issue.TrackingUrl}>
                        {issue.TrackingNumber}
                      </a>
                      {index < props.shippingIssues.length - 1 ? ', ' : ''}
                    </React.Fragment>
                  ))}
              </React.Fragment>
            );
          })}
        </Alert>
      )}
      <style jsx>
        {`
          .order-page-content {
            background: white;
          }

          .justify-content-between p:first-child {
            min-width: 130px;
          }

          .justify-content-between p:last-child {
            text-align: right;
          }
        `}
      </style>
    </>
  );
};

StatusDescriptions.propTypes = {
  orderStatuses: PropTypes.array.isRequired
};

export default StatusDescriptions;
