import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'gooten-components/src/constants';
import Button from 'gooten-components/src/components/shared/Button';
import ProductCard from 'gooten-components/src/components/shared/ProductCard';
import Chip from 'gooten-components/src/components/shared/Chip';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Select from 'gooten-components/src/components/shared/Select';
import { getOrderDetailsImageResizerUrl } from 'gooten-js-utils/src/url';
import { flatten } from 'gooten-components/src/utils/array';
import SecurityService from '../../../Settings/SecurityService';
import orderDetailsService from 'gooten-components/src/services/orderDetailsService';
import Alert from 'gooten-components/src/components/shared/Alert';

const ARCHIVED_STATUS_ID_OOS = 3;

const OrderItemsSelection = ({
  archivedStatuses,
  getArchivedStatuses,
  groupedItems,
  params,
  summaryData,
  loading
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [type, setType] = useState();
  const [reprintError, setReprintError] = useState(false);

  useEffect(() => {
    // gets page type from location (reprint, refund or reorder)
    const type = window.location.hash.split('/').reverse()[1];

    setType(type);

    if (type === 'reprint') {
      groupedItems
        .filter(group => group.vendorId)
        .forEach(group =>
          getArchivedStatuses(
            group.vendorId,
            (group.items || []).map(item => item.SKU)
          )
        );
    }
  }, []);

  const buttonTitle = () => {
    switch (type) {
      case 'reprint':
        return 'Re-Print';
      case 'refund':
        return 'Re-Fund';
      case 'reorder':
        return 'Re-Order';
      default:
        return '';
    }
  };

  const reprintStatuses = [
    1, // Shipped
    23, // Delivered
    25, // ShippingIssue
    24 // OutForDelivery
  ];

  const IsNotReprintable = item => {
    if (type === 'reprint') {
      // NOTE: Partners cannot reprint already reprinted items
      if (!reprintStatuses.includes(item.StatusId) || !!item.Meta?.ReprintedBy) {
        return true;
      }

      // Partners cannot reprint items that are current out of stock
      if (
        archivedStatuses.getIn([item.VendorId, 'data', item.SKU, 'Id']) === ARCHIVED_STATUS_ID_OOS
      ) {
        return true;
      }
    }

    return false;
  };
  const selectNone = () => {
    setSelectedIds([]);
  };

  const selectAll = () => {
    if (type === 'reprint') {
      setSelectedIds(
        flatten(groupedItems.map(group => group.items))
          .filter(x => reprintStatuses.includes(x.StatusId) && !x.Meta.ReprintedBy)
          .map(item => item.Item)
      );
    } else {
      setSelectedIds(flatten(groupedItems.map(group => group.items)).map(item => item.Item));
    }
  };

  const selectSingle = name => {
    const matchedGroup = groupedItems.find(group => group.name === name);

    if (matchedGroup && type === 'reprint') {
      setSelectedIds(
        matchedGroup.items
          .filter(x => reprintStatuses.includes(x.StatusId) && !x.Meta.ReprintedBy)
          .map(item => item.Item)
      );
    } else if (matchedGroup) {
      setSelectedIds(matchedGroup.items.map(item => item.Item));
    }
  };

  const toggleSelected = itemId => {
    setSelectedIds(
      selectedIds.includes(itemId)
        ? selectedIds.filter(id => id !== itemId)
        : selectedIds.concat([itemId])
    );
  };

  async function handleNext() {
    // NOTE: This block calls the GetItems API again to double check that
    // multiple reprints are not being placed
    setReprintError(false);
    const mostRecentItems = await orderDetailsService.getItemsData(params.id);

    const reprintedItems = flatten(mostRecentItems.map(group => group.Items)).filter(
      itm => !!itm.Meta.ReprintOf || !!itm.Meta.ReprintedBy
    );

    if (reprintedItems.some(x => selectedIds.includes(x.Item))) {
      setReprintError(true);
      return;
    }
    //

    const selectedItems = flatten(groupedItems.map(group => group.items))
      .filter(item => selectedIds.includes(item.Item))
      .map(item =>
        item.Item in quantity
          ? {
              ...item,
              Quantity: quantity[item.Item]
            }
          : item
      );

    // Reprint Self Service
    if (type === 'reprint' && !SecurityService.isSuperAdmin()) {
      // if it's a non-admin user, then go to Reprint Self Service otherwise to Reprint Admin
      // pass data through 'localStorage' is used only while SOF exist (Angular)
      // this should be changed when SOF is completely removed from Admin...
      try {
        window.localStorage.setItem(
          'pio.reprintData',
          JSON.stringify({ orderId: params.id, items: selectedItems })
        );
        window.location.hash = '#/reprint';
      } catch (err) {
        console.err('could not set reprint data to local storage', err);
      }
    }

    // Admin Reprint TO-DO

    // Reorder TO-DO

    // Refund TO-DO
  }

  const onQuantityChange = (id, e) => {
    setQuantity({
      ...quantity,
      [id]: Number.parseInt(e.target.value) || 1
    });
  };

  const selectionBlock = () => (
    <>
      <div className="d-flex quick-select mb-4 justify-content-between">
        <div className="d-flex">
          <div className="body-text-2 heavy d-flex ml-1 mb-2 mt-2 mr-4">Quick Select</div>
          <div className="quick-select-options d-flex flex-wrap">
            <Button onClick={selectNone} className="button-default medium m-1">
              None
            </Button>
            {groupedItems.map(item => (
              <Button
                onClick={() => selectSingle(item.name)}
                key={item.name}
                className="button-default medium m-1"
              >
                {item.name}
              </Button>
            ))}
            <Button onClick={selectAll} className="button-default medium m-1">
              All
            </Button>
          </div>
        </div>
        <Button className="button-cta" large onClick={handleNext} disabled={!selectedIds.length}>
          {buttonTitle()} {selectedIds.length} items
        </Button>
      </div>
      <style jsx>{`
        .quick-select {
          margin-top: 36px;
        }
      `}</style>
    </>
  );

  const groupsBlock = () =>
    groupedItems.map(group => (
      <React.Fragment key={group.name}>
        <div className="items-group grid-container">
          <div className="title-1 header">{group.name}</div>
          {group.items.map(item => {
            const isSelected = selectedIds.includes(item.Item);
            const isQuantityEditable = isSelected && type === 'reprint';
            const purchasedQuantity = Number.parseInt(item.Quantity);
            const qty =
              isQuantityEditable && item.Item in quantity ? quantity[item.Item] : purchasedQuantity;

            return (
              <div key={item.Item} className="order-item">
                <ProductCard
                  disabled={IsNotReprintable(item)}
                  key={item.Item}
                  type="hub"
                  selectClick={() => toggleSelected(item.Item)}
                  productClick={() => toggleSelected(item.Item)}
                  title={item.SKU}
                  additionalInfo={
                    <div className="item-info">
                      <div className="body-text-2">
                        <div className={!isQuantityEditable ? 'info-row' : ''}>
                          <i>Quantity: </i>
                          {isQuantityEditable ? (
                            <Select
                              noLabel
                              onChange={e => onQuantityChange(item.Item, e)}
                              type="number"
                              value={`${qty}`}
                            >
                              {[...Array(purchasedQuantity).keys()]
                                .map(x => x + 1)
                                .map(qtyOpt => (
                                  <option key={`${qtyOpt}`} value={`${qtyOpt}`}>
                                    {qtyOpt}
                                  </option>
                                ))}
                            </Select>
                          ) : (
                            item.Quantity
                          )}
                        </div>
                        <div className="info-row">
                          <i>Item ID:</i> {item.Item}
                        </div>
                      </div>
                      <Chip statusNumber={item.StatusId} className="mt-2 ml-n1" />
                    </div>
                  }
                  image={getOrderDetailsImageResizerUrl(item.Images[0].SourceUrl)}
                  selected={isSelected}
                />
              </div>
            );
          })}
        </div>
        <style jsx>{`
          .grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            row-gap: 1rem;
            column-gap: 1rem;
            padding: 0.5rem 0 2rem;
          }

          .header {
            grid-column-start: 1;
            grid-column: 1 / -1;
          }

          .info-row {
            display: flex;
            justify-content: space-between;
          }

          .item-info :global(.select-container) {
            margin-bottom: 4px;
          }

          .order-item :global(.card) {
            height: auto;
          }
        `}</style>
      </React.Fragment>
    ));

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="order-items-selection d-flex flex-column m-4">
          <span className="order-number-holder title-1 w-100 mb-4">Order {params.id}</span>
          {type !== 'refund' ? (
            <>
              <span className="body-text-1">
                Select which order items you would like to {buttonTitle().toLowerCase()}.
              </span>
              <span className="body-text-1">
                You will be able to edit the items, enter shipping address and review your order on
                the next page.
              </span>
            </>
          ) : null}
          {selectionBlock()}
          <div>
            <Alert isOpen={reprintError} type="important" className="w-100">
              Our system shows that you have already made a Reprint request on at least one selected
              item.
            </Alert>
          </div>
          {groupsBlock()}
        </div>
      )}
      <style jsx>{`
        .order-number-holder {
          color: ${COLORS.neutralDark};
        }

        span {
          color: ${COLORS.neutralDark200};
        }
      `}</style>
    </>
  );
};

OrderItemsSelection.propTypes = {
  params: PropTypes.object.isRequired,
  groupedItems: PropTypes.array.isRequired
};

export default OrderItemsSelection;
