import { ArchivedReason } from 'gooten-components/src/const/ArchivedReasons';

/**
 * Returns sku status error info, based on disabled skus
 */
export const errorInfo = disabledSkus => {
  let e = null;
  const disabledSkusCount = disabledSkus?.length;
  if (disabledSkusCount) {
    // we have disabled skus. check if they're only OOS, or Discontinued...
    const oosLength = disabledSkus.filter(
      ds => ds.archived_reason === ArchivedReason.outOfStock
    ).length;
    if (oosLength == disabledSkusCount) {
      return {
        message: 'Out Of Stock',
        tooltip: `Product contains out of stock variant${oosLength > 1 ? 's' : ''}`
      };
    }
    return {
      message: 'Discontinued',
      tooltip: `Product contains discontinued variant${
        disabledSkusCount - oosLength > 1 ? 's' : ''
      }`
    };
  }
  return e;
};
