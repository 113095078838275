'use strict';
// @flow

import {Rect} from 'konva';
import type {KonvaLayer, KonvaShape} from '../flow/konvaTypes';

export default class CanvasRectElement {

  element: KonvaShape;
  x: number;
  y: number;
  height: number;
  width: number;
  zindex: number;
  draggable: boolean;
  fill: boolean;

  constructor(state: Object) {
    this.x = state.x;
    this.y = state.y;
    this.zindex = state.z;
    this.height = state.height;
    this.width = state.width;
    this.draggable = state.draggable;
    this.fill = state.fill;
  }

  addToCanvas(layer: KonvaLayer):void {
    let solidRect = new Rect({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      stroke: 'black',
      strokeWidth: 1,
      draggable: this.draggable,
      fillEnabled: this.fill,
    });

    let rect = new Rect({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      stroke: 'white',
      strokeWidth: 1.8,
      draggable: this.draggable,
      fillEnabled: this.fill,
      dash: [10]
    });

    this.element = rect;

    // add the shape to the layer
    layer.add(solidRect);
    layer.add(rect);
  }

  on(name: string, fn: (e: any) => void) {
    // if (name == 'resize') {
    //   this.grip.on('dragend', fn);
    // }
    this.element.on(name, fn);
  }
}
