import { injectReducer } from '../../store/reducers';
import { injectSaga } from '../../store/sagas';
import ProductPublishContainer from './ProductPublishContainer';
import ProductPublishReducer from './ProductPublishReducer';
import ProductPublishSaga from './ProductPublishSaga';
import ProvidersTabSaga from './atoms/ProvidersTab/ProvidersTabSaga';
import DuplicateChangesSaga from './atoms/shared/DuplicateChanges/DuplicateChangesSaga';
import ProductImageSaga from './atoms/shared/ProductImage/ProductImageSaga';
import ProvidersSelectionPanelSaga from './atoms/ProvidersSelectionPanel/ProvidersSelectionPanelSaga';
import MockupsSaga from './atoms/Mockups/MockupsSaga';
import DesignSaga from './atoms/Design/DesignSaga';
import NeckLabelsSaga from './atoms/NeckLabels/NeckLabelsSaga';
import ProductVariantsSaga from './atoms/shared/ProductVariants/ProductVariantsSaga';
import ErrorsHandlerSaga from './ErrorsHandler/ErrorsHandlerSaga';

const componentName = 'publish';

export default store => {
  injectReducer(store, { key: componentName, reducer: ProductPublishReducer });
  injectSaga(store, { key: componentName, saga: ProductPublishSaga });
  injectSaga(store, { key: 'product-changes', saga: ProvidersTabSaga });
  injectSaga(store, { key: 'duplicate-changes', saga: DuplicateChangesSaga });
  injectSaga(store, { key: 'product-image', saga: ProductImageSaga });
  injectSaga(store, { key: 'providers-selection', saga: ProvidersSelectionPanelSaga });
  injectSaga(store, { key: 'mockups', saga: MockupsSaga });
  injectSaga(store, { key: 'design', saga: DesignSaga });
  injectSaga(store, { key: 'necklabels', saga: NeckLabelsSaga });
  injectSaga(store, { key: 'variants', saga: ProductVariantsSaga });
  injectSaga(store, { key: 'errors', saga: ErrorsHandlerSaga });

  return ProductPublishContainer;
};
