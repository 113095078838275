import {
  call,
  cancelled,
  cancel,
  put,
  select,
  take,
  fork,
  all,
  takeLatest
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import {
  GENERATE_PREVIEWS,
  updatePreviewImageUrl,
  updateGenerationState,
  showDownloadModal,
  updateSmallPreviewImageUrl,
  CANCEL_PREVIEWS_GENERATION,
  GENERATE_FULLSCREEN_PREVIEW,
  GENERATE_BACKGROUNDS,
  CANCEL_FULLSCREEN_GENERATION,
  DOWNLOAD_PREVIEWS,
  updateHighResGenerationState,
  updateGenerationItemsCount,
  removeGeneratedSkus,
  addGeneratedSku,
  showGeneratingModal,
  updateDownloadModal,
  editModeOn,
  maximizeOff,
  deselectAllItems,
  selectItems,
  updateEditState
} from './ProductPreviewActions';
import { generatePreview } from '../../services/previewGenerationService';
import {
  selectedItemsSelector,
  previewSizeSelector,
  allSKUsSelector,
  previewConfigSelector,
  previewDataSelector,
  productNameSelector,
  previewCountSelector
} from './ProductPreviewSelectors';
import previewService, { prepareManipCommands } from './services/productPreviewService';
import {
  PREVIEWS_PREPARE,
  EXCLUSIVE_MOCKUPS_PREPARE,
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail,
  fetchAsyncCancel
} from '../../store/actions/dataActions';
import { RESET } from '../../store/actions/globalActions';
import { BACK, goto } from '../../containers/NavManager/NavManagerActions';
import Log from '../../services/logService';
import {
  getProductVariants,
  selectedProductIdSelector
} from '../../store/selectors/productDataSelectors';
import {
  selectedOptionsSelector,
  shouldChangeOrientation
} from '../SKUSelection/SKUSelectionSelectors';
import { SET_PREVIEW_OPTIONS } from '../ProductPublish/atoms/Mockups/MockupsActions';
import Config from '../../config';
import { doesOrientationMatch, getMockupOrientation } from '../../services/externalMockupsService';

function* generateProductPreviews(action) {
  const previewData = yield select(previewDataSelector);

  // split exclusive mockups and simple mockups
  const exclusiveMockups = action.payload.data.filter(item => item.isExclusiveMockup);
  const simpleMockups = action.payload.data.filter(item => !item.isExclusiveMockup);

  const highRes = action.payload.highRes;
  const hasBcgChanged = action.payload.hasBcgChanged;
  const allSKUs = yield select(allSKUsSelector);
  const previewItems = simpleMockups.map(item => ({
    sku: item.sku,
    sId: item.sId,
    dataIndex: item.dataIndex,
    space: allSKUs
      .find(s => s.sku.toLowerCase() === item.sku.toLowerCase() && s.index === item.dataIndex)
      .spaces.find(s => s.id === item.sId),
    backgroundColor: item.backgroundColor
  }));
  const changeOrientation = yield select(shouldChangeOrientation);

  /*
   * Create exclusive mockups...
   */
  if (exclusiveMockups.length) {
    yield put(
      updateGenerationItemsCount({
        done: 0,
        from: previewData.items ? previewData.items.length : 0
      })
    );
    yield put(
      fetchAsync(EXCLUSIVE_MOCKUPS_PREPARE, {
        skus: Array.from(new Set(exclusiveMockups.map(item => item.sku)))
      })
    );
  }

  /*
   * Create simple mockups locally, for each sku and all spaces inside
   */
  if (previewItems.length && !highRes) {
    // create simple mockups in resolution 550x550. If user chooses to download mockups
    // then generate mockups in default resolution from previewConfig
    const defaultSize = { width: 550, height: 550 };
    const size = {
      width: defaultSize.width,
      height: defaultSize.height
    };
    yield put(removeGeneratedSkus(previewItems.map(a => a.sku + a.sId + a.dataIndex)));
    for (let res of previewItems) {
      yield put(updateGenerationState('WORKING'));
      try {
        let base64 = yield generatePreview(res.space, {
          ...size,
          imageWidth: defaultSize.width,
          imageHeight: defaultSize.height,
          preview: 'product',
          backgroundColor: res.backgroundColor,
          changeOrientation
        });

        yield put(
          updateSmallPreviewImageUrl(base64, res.sku, res.space.id, res.dataIndex, hasBcgChanged)
        );
        yield put(addGeneratedSku(res.sku + res.sId + res.dataIndex));

        const currentPreviewCount = yield select(previewCountSelector);
        yield put(
          updateGenerationItemsCount({
            done: currentPreviewCount.done + 1,
            from: previewData.items ? previewData.items.length : 0
          })
        );
      } catch (err) {
        // Suppress here to not break user flow, since these previews are not critical
        Log.error(err, 'Preview generation suppressed');
      }
    }
    yield put(updateGenerationState('SUCCESS'));
  }

  /*
   * Create simple mockups on server side
   */
  if (previewItems.length && highRes) {
    let previewSize = yield select(previewSizeSelector);

    // for fullscreen preview we need to use constant preview size, to match thumbnail
    if (action.type === GENERATE_FULLSCREEN_PREVIEW) {
      const defaultConfig = yield select(previewConfigSelector);
      previewSize = { width: defaultConfig.defaultWidth, height: defaultConfig.defaultHeight };
    }

    let manipCommandsToGenerate = null;
    try {
      // create manip commands for previews
      manipCommandsToGenerate = yield call(
        previewService.preparePreviewsManipCommands,
        previewItems,
        previewSize,
        changeOrientation
      );

      if (manipCommandsToGenerate.length) {
        // change generation state, so loading can be visible until it's finished
        yield put(updateHighResGenerationState('WORKING'));

        // start generating previews
        yield call(
          previewService.generatePreviewUrls,
          manipCommandsToGenerate,
          function* (current, total) {},
          []
        );

        // update previewImageUrl in previewData.items
        for (let item of manipCommandsToGenerate) {
          yield put(
            updatePreviewImageUrl(
              item.space.previewImgUrl,
              item.sku,
              item.sId,
              item.dataIndex,
              previewSize,
              action.type === GENERATE_FULLSCREEN_PREVIEW
            )
          );
        }

        // update generation state, previews generations has been finished
        yield put(updateHighResGenerationState('SUCCESS'));
      }
    } catch (err) {
      // Suppress here to not break user flow, since these previews are not critical
      Log.error(err, 'High res previews generation suppressed', {
        action,
        manipCommandsToGenerate
      });
    }
  }
}

export function* downloadPreviews(action) {
  let selectedItems = yield select(selectedItemsSelector);
  const previewSize = yield select(previewSizeSelector);
  const changeOrientation = yield select(shouldChangeOrientation);

  // generate high res previews if needed
  const previewsToGenerate = selectedItems.filter(
    item =>
      !item.isExclusiveMockup &&
      (!item.previewImageUrl ||
        item.generateHighRes ||
        // if previews size has been changed, we need to regenerate high res previews
        JSON.stringify(item.generatedHighResSize) !== JSON.stringify(previewSize))
  );

  if (previewsToGenerate.length) {
    const allSKUs = yield select(allSKUsSelector);

    const previewItems = previewsToGenerate.map(item => ({
      sku: item.sku,
      sId: item.sId,
      dataIndex: item.dataIndex,
      space: allSKUs
        .find(s => s.sku.toLowerCase() === item.sku.toLowerCase() && s.index === item.dataIndex)
        .spaces.find(s => s.id === item.sId),
      backgroundColor: item.backgroundColor
    }));

    let manipCommandsToGenerate = null;
    try {
      // create manip commands for previews
      manipCommandsToGenerate = yield call(
        previewService.preparePreviewsManipCommands,
        previewItems,
        previewSize,
        changeOrientation
      );
      if (manipCommandsToGenerate.length) {
        yield put(showGeneratingModal(true));

        // start generating previews
        yield call(
          previewService.generatePreviewUrls,
          manipCommandsToGenerate,
          function* (current, total) {},
          []
        );

        // update previewImageUrl in previewData.items
        for (let item of manipCommandsToGenerate) {
          yield put(
            updatePreviewImageUrl(
              item.space.previewImgUrl,
              item.sku,
              item.sId,
              item.dataIndex,
              previewSize
            )
          );
        }
      }
    } catch (err) {
      // Suppress here to not break user flow, since these previews are not critical
      Log.error(err, 'High res previews download suppressed', { action, manipCommandsToGenerate });
      return;
    } finally {
      yield put(showGeneratingModal(false));
    }
  }

  // get updated items and download previews
  yield put(showDownloadModal(true));
  selectedItems = yield select(selectedItemsSelector);
  const fileName = yield select(productNameSelector);
  yield call(
    previewService.downloadPreviews,
    selectedItems,
    fileName,
    function* (current, total) {
      yield put(updateDownloadModal(current, total));
    },
    function* () {
      yield put(showDownloadModal(false));
    }
  );
}

export function* previewsPrepareAsyncHandler(action) {
  const previewSize = yield select(previewSizeSelector);
  const allSKUs = yield select(allSKUsSelector);
  const changeOrientation = yield select(shouldChangeOrientation);

  const skus = allSKUs.map((item, i) => {
    const previews = action.payload.items.filter(a =>
      a.associatedSkus && a.associatedSkus.length
        ? a.associatedSkus.filter(as => as.sku.toLowerCase() === item.sku.toLowerCase())
        : item.sku.toLowerCase() === a.sku.toLowerCase() && item.index === a.dataIndex
    );
    const sku = {
      sku: item.sku,
      index: i,
      dataIndex: item.index,
      spaces: item.spaces
    };

    if (previews.length && previews[0] && previews[0].backgroundColor) {
      return {
        ...sku,
        backgroundColor: previews[0].backgroundColor
      };
    } else {
      return sku;
    }
  });

  let manipCommandsToGenerate = null;

  try {
    const variants = yield select(getProductVariants);

    // This add previewImgManipCmd and printImgManipCmd inside to each space of each sku
    manipCommandsToGenerate = yield call(
      prepareManipCommands,
      skus,
      previewSize,
      changeOrientation
    );

    // NOTE: Proceed to next step early
    if (!action.payload.disableNextStep) {
      yield put(goto('SaveAndPublish'));
    }
    if (manipCommandsToGenerate.length) {
      yield all([
        // Submit print image manip async and get print image url
        // TODO: Make it ASYNC, used in Prp.Data
        call(previewService.submitPrintImageManipCmdsAsync, manipCommandsToGenerate),
        // Submit preview image manip async and get preview image url
        // Should be SYNC b/c need that image exist when publish to Shopify
        call(
          previewService.submitPreviewImageManipCmdsSync,
          manipCommandsToGenerate,
          function* (current, total) {
            // Update progress only if task wasn't cancelled
            if (!(yield cancelled())) {
              yield put(updateGenerationState('WORKING'));
              yield put(updateGenerationItemsCount({ done: current, from: total }));
            }
          },
          variants
        )
      ]);
    }
    yield put(fetchAsyncSuccess(PREVIEWS_PREPARE, { items: manipCommandsToGenerate, previewSize }));
  } catch (err) {
    Log.error(err, 'Failed to generate previews', { action, manipCommandsToGenerate });
    yield put(fetchAsyncFail(PREVIEWS_PREPARE, err));
  }
}

export function* exclusiveMockupsPrepareAsyncHandler(action) {
  const productId = yield select(selectedProductIdSelector);
  const previewSize = yield select(previewSizeSelector);
  const allSKUs = yield select(allSKUsSelector);
  const changeOrientation = yield select(shouldChangeOrientation);
  const selectedOptions = yield select(selectedOptionsSelector);
  const selectedOrientations = selectedOptions?.get('orientation');

  const skus = allSKUs
    .map((item, i) => ({ ...item, originalIndex: i }))
    .filter(item => item.proPreview || item.externalMockup)
    .map(item => {
      // we have to filter out external mockups by selected orientation
      var externalMockup = {
        ...item.externalMockup,
        mockups: item.externalMockup?.mockups?.filter(m =>
          doesOrientationMatch(m, selectedOrientations)
        )
      };
      return {
        index: item.originalIndex,
        dataIndex: item.index,
        sku: item.sku,
        proPreview: item.proPreview,
        externalMockup,
        spaces: item.spaces
      };
    });

  let manipCommandsToGenerate = null;
  yield put(removeGeneratedSkus(skus.map(a => a.sku)));
  try {
    // This add smallPrintImgManipCmd to each space of each sku in action.payload
    manipCommandsToGenerate = yield call(prepareManipCommands, skus, previewSize);
    if (manipCommandsToGenerate.length) {
      yield all([
        call(
          previewService.submitPrintImageManipCmdsAsync,
          manipCommandsToGenerate,
          changeOrientation
        ),
        call(
          previewService.generateExclusiveMockupsUrls,
          manipCommandsToGenerate,
          function* (current, total) {
            // Update progress only if task wasn't cancelled
            if (!(yield cancelled())) {
              if (current < total) {
                yield put(updateGenerationState('WORKING'));
                yield put(addGeneratedSku(skus[current] && skus[current].sku));
                yield put(
                  updateGenerationItemsCount({
                    done: current + 1,
                    from: total
                  })
                );
              }
            }
          },
          { changeOrientation },
          productId
        )
      ]);
    }
    yield put(updateGenerationState('SUCCESS'));
    yield put(fetchAsyncSuccess(EXCLUSIVE_MOCKUPS_PREPARE, manipCommandsToGenerate));
  } catch (err) {
    Log.error(err, 'Failed to generate Exclusive mockups', { action, manipCommandsToGenerate });
    yield put(fetchAsyncFail(EXCLUSIVE_MOCKUPS_PREPARE, err));
  }
}

// Sets Preview page opening options
function* previewOptionsHandler(action) {
  // check if should open in edit mode
  if (action.payload.editMode) {
    yield put(editModeOn());
    yield call(delay, 100);
    yield put(maximizeOff());
  }

  // check if all mockups should be selected
  if (action.payload.allSelected) {
    const previewData = yield select(previewDataSelector);
    if (previewData && previewData.items && previewData.items.length) {
      yield put(updateEditState(previewData));
      yield put(deselectAllItems());
      yield put(selectItems(previewData.items));
    }
  }
}

export function* watchCreatePreviews() {
  let task;
  while (true) {
    // Watch these actions
    const action = yield take([GENERATE_PREVIEWS, BACK, RESET]);
    if (task) {
      // Cancel previous task
      yield cancel(task);
    }

    if (action.type === GENERATE_PREVIEWS) {
      // start new task
      task = yield fork(generateProductPreviews, action);
    }
  }
}

export function* watchCreateFullscreenPreview() {
  let task;
  while (true) {
    // Watch these actions
    const action = yield take([
      GENERATE_FULLSCREEN_PREVIEW,
      BACK,
      RESET,
      CANCEL_FULLSCREEN_GENERATION
    ]);
    if (task) {
      // Cancel previous task
      yield cancel(task);
    }

    if (action.type === GENERATE_FULLSCREEN_PREVIEW) {
      // start new task
      task = yield fork(generateProductPreviews, action);
    }
  }
}

export function* watchUpdateBackgrouds() {
  let task;
  while (true) {
    // Watch these actions
    const action = yield take([GENERATE_BACKGROUNDS, BACK, RESET, CANCEL_PREVIEWS_GENERATION]);
    if (task) {
      // Cancel previous task
      yield cancel(task);
    }

    if (action.type === GENERATE_BACKGROUNDS) {
      // start new task
      task = yield fork(generateProductPreviews, action);
    }
  }
}

export function* watchDownloadPreviews() {
  let task;
  while (true) {
    // Watch these actions
    const action = yield take([DOWNLOAD_PREVIEWS, BACK, RESET]);
    if (task) {
      // Cancel previous task
      yield cancel(task);
    }

    if (action.type === DOWNLOAD_PREVIEWS) {
      // start new task
      task = yield fork(downloadPreviews, action);
    }
  }
}

export function* watchPreviewsPrepareAsync() {
  let task;
  while (true) {
    // Watch these actions
    const action = yield take([PREVIEWS_PREPARE.ASYNC, BACK, RESET]);
    if (task) {
      // Cancel previous task
      yield cancel(task);
      yield put(fetchAsyncCancel(PREVIEWS_PREPARE));
    }

    if (action.type === PREVIEWS_PREPARE.ASYNC) {
      // start new task
      task = yield fork(previewsPrepareAsyncHandler, action);
    }
  }
}

export function* watchProPreviewsPrepareAsync() {
  let task;
  while (true) {
    // Watch these actions
    const action = yield take([EXCLUSIVE_MOCKUPS_PREPARE.ASYNC, BACK, RESET]);
    if (task) {
      // Cancel previous task
      yield cancel(task);
      yield put(fetchAsyncCancel(EXCLUSIVE_MOCKUPS_PREPARE));
    }

    if (action.type === EXCLUSIVE_MOCKUPS_PREPARE.ASYNC) {
      // start new task
      task = yield fork(exclusiveMockupsPrepareAsyncHandler, action);
    }
  }
}

function* watchPreviewOptions() {
  yield takeLatest(SET_PREVIEW_OPTIONS, previewOptionsHandler);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchCreatePreviews(),
    watchCreateFullscreenPreview(),
    watchUpdateBackgrouds(),
    watchDownloadPreviews(),
    watchPreviewsPrepareAsync(),
    watchProPreviewsPrepareAsync(),
    watchPreviewOptions()
  ]);
}
