import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import './InfoLine.scss';

import styled from 'styled-components';
const Container = styled.div`
  .col-sm-6 {
    margin-top: 30px;

    a {
      cursor: pointer;
      font-weight: 900;
      font-size: 12px;
    }

    .selected-label {
      font-weight: 900;
      font-size: 12px;
    }

    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .col-xs-12 {
    hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;

export const InfoLine = props => {
  const selectedVariantsCountLabel =
    props.variants.filter(v => v.get('uniqueProduct')).size + `/${props.variants.size} SKUs synced`;

  return (
    <Container className="info-line-container">
      <div className="col-sm-6 col-xs-12">
        <span>
          <b>Select a match for: </b> {props.selectedOptionsLabel}
        </span>
        <a
          className={`skip-link ${props.canGoNext ? 'disabled' : ''}`}
          onClick={() => (props.canGoNext ? null : props.skip())}
        >
          Skip
        </a>
      </div>
      <div className="col-sm-6 col-xs-12  text-right">
        <span className="selected-label">{selectedVariantsCountLabel}</span>
      </div>
      <div className="col-xs-12">
        <hr />
      </div>
    </Container>
  );
};

InfoLine.propTypes = {
  variants: PropTypes.instanceOf(List).isRequired,
  selectedOptionsLabel: PropTypes.string,
  skip: PropTypes.func,
  canGoNext: PropTypes.bool
};

export default InfoLine;
