import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import RadioButtonGroup from 'gooten-components/src/components/shared/RadioButtonGroup';
import TestConnection from './components/TestConnection';
import { emailRegex, urlRegex } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';
import SwitchField from 'gooten-components/src/components/shared/SwitchField';
import Input from 'gooten-components/src/components/shared/Input';

const Container = styled.div`
  .input-row {
    position: relative;

    .sm-roundsign-icon {
      position: absolute;
      right: 24px;
      top: 38px;
    }
  }
`;
const switchBoxConfig = {
  checkedLabel: 'YES',
  uncheckedLabel: 'NO'
};

class EmailCredentials extends React.Component {
  static get name() {
    return 'emailCredentials';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Email Credentials info',
      onFailure: 'Failed to update Email Credentials',
      onSuccess: 'Your Email Credentials has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      services: []
    };
    this.inputs = {};
  }

  static getDerivedStateFromProps(props, state) {
    return {
      services: new List([
        'Email your customers via Gooten mail services (services@gooten.com)',
        `Email your customers via ${props.data.get('customServiceName')} mail services`
      ]),
      selectedServiceIndex: props.data.get('MailMethod') - 1
    };
  }

  componentDidMount() {
    this.props.init();
  }

  clearInputs(names) {
    names.map(name => {
      this.inputs[name] = null;
    });
    return null;
  }

  get isValid() {
    return (
      this.props.data.MailMethod === 1 ||
      (this.props.data.SmtpServer &&
        this.props.data.SmtpUsername &&
        this.props.data.SmtpPassword &&
        this.inputs.SmtpServer &&
        Object.values(this.inputs).every(i => !i || i.isValid()))
    );
  }

  get canUpdate() {
    return (
      this.props.ready &&
      this.props.data &&
      !this.props.data.equals(this.props.unmodified) &&
      this.isValid
    );
  }

  get canTest() {
    return (
      this.props.ready &&
      this.props.data &&
      this.props.data.SmtpServer &&
      this.props.data.SmtpUsername &&
      this.props.data.SmtpPassword &&
      (!this.inputs.SmtpServer || this.inputs.SmtpServer.isValid())
    );
  }

  renderInput(name, label, placeholder, className, isRequired, regexp) {
    return (
      <div className="row">
        <div className="input-row">
          <TextInput
            disabled={!this.props.ready}
            className={className}
            id={name}
            isRequired={isRequired}
            emptyMsg="This field is required"
            showValidation
            regexp={regexp}
            validationMsg="Wrong format"
            label={label}
            placeholder={placeholder}
            onChange={(value, id) => this.props.change(value, id)}
            ref={ref => {
              this.inputs[name] = ref;
            }}
            value={this.props.data.get(name) || ''}
          />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.useShippingConfirmation && !this.props.useOrderConfirmation) {
      return null;
    }
    return (
      <BaseCard card={EmailCredentials.name} title="Customize Email Credentials">
        <Container>
          <div className="email-credentials-card">
            <div className="row m-0">
              <div className="col-xs-12">
                <RadioButtonGroup
                  items={this.state.services}
                  selectionIndex={this.state.selectedServiceIndex}
                  onChange={(i, index) => this.props.change(index + 1, 'MailMethod')}
                />
              </div>
            </div>
            <br />

            {this.props.data.get('MailMethod') === 2 ? (
              <div className="row m-0">
                <div className="col-sm-8 col-xs-12">
                  <h5 className="ml-n3">Email Addresses</h5>
                  {this.renderInput(
                    'DisplayName',
                    'Display Name',
                    'Type Display Name...',
                    'col-xs-12',
                    false
                  )}
                  {this.props.useOrderConfirmation
                    ? this.renderInput(
                        'OrdersEmail',
                        'Orders Email',
                        'orders@mystore.com...',
                        'col-xs-12',
                        true,
                        emailRegex
                      )
                    : this.clearInputs(['OrdersEmail'])}
                  {this.props.useShippingConfirmation
                    ? this.renderInput(
                        'ShippingEmail',
                        'Shipping Email',
                        'shipping@mystore.com...',
                        'col-xs-12',
                        true,
                        emailRegex
                      )
                    : this.clearInputs(['ShippingEmail'])}
                  <h5 className="ml-n3">SMTP Email Credentials</h5>
                  {this.renderInput(
                    'SmtpServer',
                    'SMTP Server',
                    'smtp.mystore.com...',
                    'col-xs-12',
                    true,
                    urlRegex
                  )}

                  <div className="row">
                    <div className="input-row">
                      <div className="input-container">
                        <Input
                          label="SMTP Port"
                          id="SmtpPort"
                          type="number"
                          onChange={event => this.props.change(event.target.value, 'SmtpPort')}
                          placeholder="465..."
                          value={this.props.data.get('SmtpPort') || ''}
                        />
                      </div>
                    </div>
                  </div>
                  {this.renderInput('SmtpUsername', 'SMTP User', 'Type User...', 'col-xs-12', true)}
                  {this.renderInput(
                    'SmtpPassword',
                    'SMTP Password',
                    'Type Password...',
                    'col-xs-12',
                    true
                  )}
                  <br />
                  <div className="row m-0">
                    <div className="col-xs-6 p-0">
                      <SwitchField
                        label="SMTP Uses SSL"
                        config={switchBoxConfig}
                        disabled={!this.props.ready}
                        checked={this.props.data.get('SmtpUseSSL')}
                        onChange={value => this.props.change(value, 'SmtpUseSSL')}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row m-0">
                    <div className="col-xs-12 p-0">
                      <TestConnection
                        canTest={this.canTest}
                        testConnection={() => this.props.testConnection()}
                        status={this.props.testConnectionStatus}
                        error={this.props.testConnectionError}
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            ) : (
              this.clearInputs([
                'OrdersEmail',
                'ShippingEmail',
                'SmtpServer',
                'SmtpUsername',
                'SmtpPassword'
              ])
            )}
          </div>
          <div className="cart-buttons-block">
            <Button
              className="button-primary large"
              disabled={!this.canUpdate}
              onClick={() => this.props.update({ data: this.props.data })}
            >
              Save credentials
            </Button>
            <Button className="button-default large ml-2" onClick={() => this.props.cancel()}>
              Cancel
            </Button>
          </div>
        </Container>
      </BaseCard>
    );
  }
}

EmailCredentials.propTypes = {
  init: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.object,
  unmodified: PropTypes.object,
  testConnection: PropTypes.func.isRequired,
  ready: PropTypes.bool,
  testConnectionStatus: PropTypes.number,
  testConnectionError: PropTypes.string,
  useShippingConfirmation: PropTypes.bool,
  useOrderConfirmation: PropTypes.bool
};

export default EmailCredentials;
