import { connect } from 'react-redux';
import ConfirmNavigationModal from './ConfirmNavigationModal';

import { connectStore } from '../ConfirmationModalActions';

const mapDispatchToProps = {
  connectStore: storeName => connectStore('shopify', storeName, true)
};

export default connect(null, mapDispatchToProps)(ConfirmNavigationModal);
