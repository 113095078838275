// @flow

import { Layer, Group, Rect, Line } from 'konva';
import { KonvaLayer } from '../../../flow/konvaTypes';
import { imageUpdate, setCroppingMode, setCroppingSaveMode } from '../../../state/actions/actionCreators';
import CanvasImageElement from '../../canvas/canvasImageElement';
import CropService from './crop.service';
import ImageService from '../../image/image.service';
import EventEmitter from 'events';
import { noBubble } from '../../../utils/eventUtils';
import PositioningService from '../../../utils/positioningService';
import { PUBLIC_EVENTS } from '../../../ImageEditor.events';

export default class CropArea {
  layer: Layer;
  actionGroup: Group;
  foregroundImageGroup: Group;
  publicEvents: EventEmitter;

  constructor(actionLayer: KonvaLayer, actionGroup: Group, publicEvents: EventEmitter) {
    this.layer = actionLayer;
    this.actionGroup = actionGroup;
    this.publicEvents = publicEvents;
  }

  _drawBackgroundImageGroup(cropAreaGroupPosition, imageArgs) {
    const backgroundImageGroup = new Group({
      opacity: 0.5,
      ...cropAreaGroupPosition
    });
    const backgroundImage = new CanvasImageElement(imageArgs).getImage();
    backgroundImageGroup.add(backgroundImage);
    this.layer.add(backgroundImageGroup);
  }

  _drawBackgroundGroup(cropActivated: boolean, width: number, height: number, ctx: any) {
    const backgroundGroup = new Group({
      visible: cropActivated
    });
    const backgroundRect = new Rect({
      x: 0,
      y: 0,
      width: width,
      height: height,
      fillEnabled: true,
      fill: '#706e6b',
      opacity: 0.5,
    });
    backgroundRect.off('click').on('click', (event) => {
      //Prevent image disapering by clicking on backdrop
      noBubble(event.evt);
      //Logic for apply crop may be added here
    });
    backgroundGroup.add(backgroundRect);
    this.layer.add(backgroundGroup);
  }

  _drawForegroundImageGroup(cropAreaGroupPosition, imageArgs, cropAreaRect) {
    const foregroundImageGroup = new Group(cropAreaGroupPosition);
    const foregroundImage = new CanvasImageElement(imageArgs).getImage();
    foregroundImageGroup.add(foregroundImage);

    // clip foreground so not cropped image will be half transparent
    foregroundImageGroup.setClip(cropAreaRect);
    this.layer.add(foregroundImageGroup);

    return foregroundImageGroup;
  }

  _drawCroppingGroup(cropAreaGroupPosition, cropAreaRect, currentImageCanvasState, ctx) {
    const croppingGroup = new Group(cropAreaGroupPosition);
    const cropRectElm = new Rect({
      x: cropAreaRect.x,
      y: cropAreaRect.y,
      width: cropAreaRect.width,
      height: cropAreaRect.height,
      stroke: '#F7E61B',
      dash: [19, 10, 0.001, 10],
      strokeWidth: 1,
      fillEnabled: true,
      draggable: true
    });
    croppingGroup.addName('croppingGroup');
    croppingGroup.add(cropRectElm);
    const tlAnchor = this._getAnchor(cropAreaRect, 'tl');
    const trAnchor = this._getAnchor(cropAreaRect, 'tr');
    const brAnchor = this._getAnchor(cropAreaRect, 'br');
    const blAnchor = this._getAnchor(cropAreaRect, 'bl');
    croppingGroup.add(tlAnchor);
    croppingGroup.add(trAnchor);
    croppingGroup.add(brAnchor);
    croppingGroup.add(blAnchor);
    this.layer.add(croppingGroup);

    const roundNumber = (n: number) => {
      return Math.round(n * 100) / 100;
    };

    const saveCurrentPosition = () => {
      let position = this.foregroundImageGroup.clip();

      CropService.saveCropState(position, ctx.state);
    };

    // update anchors on resize
    const updateAnchors = () => {
      tlAnchor.setPosition(cropRectElm.position());
      trAnchor.setPosition({x: cropRectElm.getX() + cropRectElm.width(), y: cropRectElm.getY() });
      blAnchor.setPosition({x: cropRectElm.getX(), y: cropRectElm.getY() + cropRectElm.height() });
      brAnchor.setPosition({x: cropRectElm.getX() + cropRectElm.width(), y: cropRectElm.getY() + cropRectElm.height() });
    };

    // Crop handling
    //
    const anchorDragHandler = (activeAnchor, position) => {
      cropRectElm.setDraggable(false);
      const anchorX = activeAnchor.getX();
      const anchorY = activeAnchor.getY();
      const isTop = position[0] === 't';
      const isLeft = position[1] === 'l';

      const cropPoints = {
        x1: (position == 'tl' || position == 'bl') ? anchorX : tlAnchor.getX(),
        y1: (position == 'tl' || position == 'tr') ? anchorY : tlAnchor.getY(),
        x2: (position == 'tr' || position == 'br') ? anchorX : brAnchor.getX(),
        y2: (position == 'bl' || position == 'br') ? anchorY : brAnchor.getY()
      };

      // get new rect
      let cropAreaRect = {
        x: cropPoints.x1,
        y: cropPoints.y1,
        width: cropPoints.x2 - cropPoints.x1,
        height: cropPoints.y2 - cropPoints.y1,
      };

      // apply limits to new rect
      cropAreaRect = CropService.getLimitedCropAreaRect(cropAreaRect, currentImageCanvasState, isTop, isLeft);

      // apply calculated positions
      cropRectElm.position({x: cropAreaRect.x, y: cropAreaRect.y});
      cropRectElm.width(cropAreaRect.width);
      cropRectElm.height(cropAreaRect.height);
      updateAnchors();

      this.foregroundImageGroup.setClip({
        ...cropAreaRect
      });

      this.layer.batchDraw();
    };

    const applyCrop = () => {
      const crop = {
        x: roundNumber(cropRectElm.getX() * currentImageCanvasState.scaleFromOriginal),
        y: roundNumber(cropRectElm.getY() * currentImageCanvasState.scaleFromOriginal),
        width: roundNumber(cropRectElm.width() * currentImageCanvasState.scaleFromOriginal),
        height: roundNumber(cropRectElm.height() * currentImageCanvasState.scaleFromOriginal)
      };

      const cropRect = {
        x: cropRectElm.getX(),
        y: cropRectElm.getY(),
        width: cropRectElm.width(),
        height: cropRectElm.height()
      };
      const croppingGroupPos = {
        x: croppingGroup.getX(),
        y: croppingGroup.getY(),
        offsetX: croppingGroup.getOffsetX(),
        offsetY: croppingGroup.getOffsetY(),
        rotation: croppingGroup.rotation()
      };
      const cropAreaCenterPoint = CropService.getCropAreaCenterPoint(cropRect, croppingGroupPos, ctx.state);

      let cropPos = {
        x: roundNumber(cropAreaCenterPoint.x),
        y: roundNumber(cropAreaCenterPoint.y),
      };

      // NOTE: Check if cropped image is no longer inside printable area
      // do the centering then
      const isCropOutsideOfViewport = CropService.isCropOutsideOfViewport(
        {...cropAreaCenterPoint, width: crop.width, height: crop.height, rotation: croppingGroupPos.rotation},
        ctx.state
      );

      if (isCropOutsideOfViewport) {
        let centerPosition = PositioningService.getCenterPosition(ctx.state);
        cropPos = {...centerPosition};
      }

      // do crop
      ctx.actionProcessorCb(
        imageUpdate(
          currentImageCanvasState.id, {
            crop: crop,
            ...cropPos
          }
        )
      );

      // turn off crop mode
      ctx.actionProcessorCb(
        setCroppingMode(false)
      );

      CropService.resetCropState();


      this.publicEvents.emit(PUBLIC_EVENTS.CROP_END);
    };

    // Do crop on click
    cropRectElm.off('click tap').on('click tap', () => {
      applyCrop();
    });

    cropRectElm.off('dragend').on('dragend', () => {
      saveCurrentPosition();
    });

    // Cropping area drag
    cropRectElm.off('dragmove').on('dragmove', () => {
      updateAnchors();
      this.foregroundImageGroup.setClip({
        x: cropRectElm.getX(),
        y: cropRectElm.getY(),
        width: cropRectElm.width(),
        height: cropRectElm.height()
      });
      this.layer.batchDraw();
    });

    // Limit crop area drag to not go outside of image boundaries
    cropRectElm.dragBoundFunc(pos => {
      return CropService.getLimitedCropAreaPointOnDrag(
        pos.x,
        pos.y,
        cropRectElm.width(),
        cropRectElm.height(),
        currentImageCanvasState,
        ctx.state
      );
    });

    //top left anchor move handler
    tlAnchor.off('dragmove').on('dragmove', () => {
      anchorDragHandler(tlAnchor, 'tl');
    });
    tlAnchor.off('dragend').on('dragend', () => {
      cropRectElm.setDraggable(true);
      saveCurrentPosition();
    });
    tlAnchor.off('click').on('click', (event) => {
      noBubble(event.evt);
    });

    //top right anchor move handler
    trAnchor.off('dragmove').on('dragmove', () => {
      anchorDragHandler(trAnchor, 'tr');
    });
    trAnchor.off('dragend').on('dragend', () => {
      cropRectElm.setDraggable(true);
      saveCurrentPosition();
    });
    trAnchor.off('click').on('click', (event) => {
      noBubble(event.evt);
    });

    //bottom right anchor move handler
    brAnchor.off('dragmove').on('dragmove', () => {
      anchorDragHandler(brAnchor, 'br');
    });
    brAnchor.off('dragend').on('dragend', () => {
      cropRectElm.setDraggable(true);
      saveCurrentPosition();
    });
    brAnchor.off('click').on('click', (event) => {
      noBubble(event.evt);
    });

    //bottom left anchor move handler
    blAnchor.off('dragmove').on('dragmove', () => {
      anchorDragHandler(blAnchor, 'bl');
    });
    blAnchor.off('dragend').on('dragend', () => {
      cropRectElm.setDraggable(true);
      saveCurrentPosition();
    });
    blAnchor.off('click').on('click', (event) => {
      noBubble(event.evt);
    });

    // crop saving mode which can be activated outside of current component
    // used by Crop button in Toolbar
    if (ctx.state.editor.cropSaving) {
      ctx.actionProcessorCb(setCroppingSaveMode(false));

      if (!CropService.cropState) {
        // cancel crop mode if no changes was made
        ctx.actionProcessorCb(setCroppingMode(false));
        this.publicEvents.emit(PUBLIC_EVENTS.CROP_CANCEL);
      }
      else {
        applyCrop();
      }
    }
  }

  draw(ctx: any) {

    const currentImageCanvasState = ImageService.getSelectedImageCanvasState(ctx.state);

    const cropAreaRect = CropService.getCropAreaRect(ctx.state, true);

    // set initial position of image to 0,0
    // because its group position is set to original image position
    // all calculations are made based on group position
    const imageArgs = {
      ...currentImageCanvasState,
      ...{ x: 0, y: 0, rotation: 0 }
    };

    const cropAreaGroupPosition = CropService.getCropAreaGroupPosition(
      currentImageCanvasState,
      CropService.getCropAreaRect(ctx.state, false)
    );

    // backgroundImageGroup - group contains image which is shown as half transparent when cropping
    this._drawBackgroundImageGroup(cropAreaGroupPosition, imageArgs);

    // backgroundGroup - group contains grey background rect
    this._drawBackgroundGroup(ctx.state.editor.cropActivated, ctx.state.editor.width, ctx.state.editor.height, ctx);

    // foregroundImageGroup - group contains overlay image to show cropped area in true color
    this.foregroundImageGroup = this._drawForegroundImageGroup(cropAreaGroupPosition, imageArgs, cropAreaRect);

    // croppingGroup - group contains anchors and rect for selecting crop area
    this._drawCroppingGroup(cropAreaGroupPosition, cropAreaRect, currentImageCanvasState, ctx);
  }

  _getAnchor(cropAreaRect: any, position: string) {
    const anchorGroup = new Group({
      draggable: true
    });

    let vline = null;
    let hline = null;
    let cursor = 'pointer';
    if (position === 'tl') {
      vline = this._getAnchorLine([0, 0, 0, 20]);
      hline = this._getAnchorLine([-3, 0, 20, 0]);
      anchorGroup.setX(cropAreaRect.x);
      anchorGroup.setY(cropAreaRect.y);

      //set cursor for hover
      cursor = 'nwse-resize';
    }
    else if (position === 'tr') {
      anchorGroup.setX(cropAreaRect.width + cropAreaRect.x);
      anchorGroup.setY(cropAreaRect.y);
      vline = this._getAnchorLine([0, 0, 0, 20]);
      hline = this._getAnchorLine([3, 0, - 20, 0]);

      //set cursor for hover
      cursor = 'nesw-resize';
    }
    else if (position === 'bl') {
      anchorGroup.setX(cropAreaRect.x);
      anchorGroup.setY(cropAreaRect.height + cropAreaRect.y);
      vline = this._getAnchorLine([0, 0, 0, - 20]);
      hline = this._getAnchorLine([-3, 0, 20, 0]);

      //set cursor for hover
      cursor = 'nesw-resize';
    }
    else if (position === 'br') {
      anchorGroup.setX(cropAreaRect.width + cropAreaRect.x);
      anchorGroup.setY(cropAreaRect.height + cropAreaRect.y);
      vline = this._getAnchorLine([0, 0, 0, -20]);
      hline = this._getAnchorLine([3, 0, -20, 0]);

      //set cursor for hover
      cursor = 'nwse-resize';
    }

    anchorGroup.add(vline);
    anchorGroup.add(hline);

    vline.off('mouseover').on('mouseover', () => {
      document.body.style.cursor = cursor;
    });
    hline.off('mouseover').on('mouseover', () => {
      document.body.style.cursor = cursor;
    });
    vline.off('mouseout').on('mouseout', () => {
      document.body.style.cursor = 'pointer';
    });
    hline.off('mouseout').on('mouseout', () => {
      document.body.style.cursor = 'pointer';
    });

    return anchorGroup;
  }

  _getAnchorLine(points) {
    const line = new Line({
      points: points,
      stroke: '#F7E61B',
      strokeWidth: 6,
      width: 200,
      height: 15
    });

    return line;
  }
}
