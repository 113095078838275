import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageBody from 'gooten-components/src/components/shared/PageBody';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import { TabContainer, Tab } from 'gooten-components/src/components/shared/Tab';
import Button from 'gooten-components/src/components/shared/Button';
import SummaryView from './SummaryView';
import StatusView from './StatusView';
import HistoryView from './HistoryView';
import ShipmentView from './ShipmentView';
import NotesView from './NotesView';
import ImagesView from './ImagesView';
import BillingView from './BillingView';
import VendorsView from './VendorsView';
import Notifications from 'gooten-components/src/components/shared/Notifications';
import SecurityService from '../../Settings/SecurityService';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import { withRouter, Link } from 'react-router';
import Alert from 'gooten-components/src/components/shared/Alert';
import Icon from 'gooten-components/src/components/shared/Icon';
import KnowledgeBaseInfo from 'gooten-components/src/components/shared/KnowledgeBaseInfo';
import Config from 'gooten-components/src/config';
import {
  ORDER_SUPPORT_POLICIES_KB_TEXT,
  ORDER_SUPPORT_POLICIES_KB_LINK,
  ORDER_SUPPORT_POLICIES_KB_LINK_FUJI,
  ORDER_SUPPORT_POLICIES_KB_LINK_SENSARIA,
  SHIPPING_TIMES_KB_TEXT,
  SHIPPING_TIMES_KB_LINK,
  SHIPPING_TIMES_KB_LINK_FUJI,
  SHIPPING_TIMES_KB_LINK_SENSARIA
} from 'gooten-components/src/constants';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';

const OrderDetails = props => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    props.resetOrderState();
    props.getSharedOrderData(props.params.id);
  }, []);

  useEffect(() => {
    document.title = `Order ${props.params.id} | Gooten`;

    return () => {
      document.title = props.location.pathname.includes('orders-new')
        ? 'Orders | Gooten'
        : document.title;
    };
  }, [props.location]);

  const tabVisibleIf = (condition, ...tab) => {
    return condition ? tab : [];
  };

  const notesTabVisible =
    SecurityService.isSuperAdmin() &&
    (props.summaryData.PaymentMethod === 12 ||
      (props.summaryData.Meta &&
        (props.summaryData.Meta.AdminComments || props.summaryData.Meta.RefundItems)));

  const vendorTabVisible = SecurityService.isSuperAdmin();

  // Email button is visible if they are not a vendor and there is a order total value
  const emailButtonVisible =
    (props.summaryData.SendShipping || props.summaryData.SendConfirmation) &&
    !isNaN(
      parseFloat(
        props.summaryData.OrderTotal ? props.summaryData.OrderTotal.replace(/\D/g, '') : null
      )
    );

  const tabItems = [
    { name: 'Summary', to: `orders-new/${props.params.id}/summary` },
    { name: 'Status', to: `orders-new/${props.params.id}/status` },
    { name: 'Images', to: `orders-new/${props.params.id}/images` },
    ...tabVisibleIf(!SecurityService.isVendor(), {
      name: 'Billing',
      to: `orders-new/${props.params.id}/billing`
    }),
    { name: 'History', to: `orders-new/${props.params.id}/history` },
    { name: 'Shipment', to: `orders-new/${props.params.id}/shipment` },
    ...tabVisibleIf(vendorTabVisible, {
      name: 'Vendors',
      to: `orders-new/${props.params.id}/vendors`
    }),
    ...tabVisibleIf(notesTabVisible, { name: 'Notes', to: `orders-new/${props.params.id}/notes` })
  ];

  const renderKbInfo = () => {
    const getOrderSupportPoliciesKbLink = () => {
      const partnerGroup = Config.get('partnerGroup');
      if (!partnerGroup) return ORDER_SUPPORT_POLICIES_KB_LINK;

      switch (partnerGroup.get('Id')) {
        case 1:
          return ORDER_SUPPORT_POLICIES_KB_LINK_FUJI;
        case 2:
          return ORDER_SUPPORT_POLICIES_KB_LINK_SENSARIA;
        default:
          return ORDER_SUPPORT_POLICIES_KB_LINK;
      }
    };

    const getShippingTimesKbLink = () => {
      const partnerGroup = Config.get('partnerGroup');
      if (!partnerGroup) return SHIPPING_TIMES_KB_LINK;

      switch (partnerGroup.get('Id')) {
        case 1:
          return SHIPPING_TIMES_KB_LINK_FUJI;
        case 2:
          return SHIPPING_TIMES_KB_LINK_SENSARIA;
        default:
          return SHIPPING_TIMES_KB_LINK;
      }
    };

    return (
      <KnowledgeBaseInfo
        kbArticles={[
          {
            visibleText: ORDER_SUPPORT_POLICIES_KB_TEXT,
            link: getOrderSupportPoliciesKbLink()
          },
          {
            visibleText: SHIPPING_TIMES_KB_TEXT,
            link: getShippingTimesKbLink()
          }
        ]}
      />
    );
  };

  const IsInReprintableStatus = () => {
    let isAllowed = false;
    const reprintStatuses = [
      1, // Shipped
      23, // Delivered
      25, // ShippingIssue
      24 // OutForDelivery
    ];

    props.itemsData.forEach(item => {
      if (reprintStatuses.includes(item.StatusId)) {
        isAllowed = true;
      }
    });

    return isAllowed;
  };

  const isAlreadyReprinted = props.itemsData.every(
    item => item.Meta?.ReprintedBy || item.Meta?.ReprintOf
  );

  const renderTabContents = () => {
    if (mounted) {
      switch (props.params.tab) {
        case 'summary':
          return <SummaryView {...props} />;
        case 'status':
          return <StatusView {...props} />;
        case 'images':
          return <ImagesView {...props} />;
        case 'billing':
          return <BillingView {...props} />;
        case 'history':
          return <HistoryView {...props} />;
        case 'shipment':
          return <ShipmentView {...props} />;
        case 'vendors':
          return <VendorsView {...props} />;
        case 'notes':
          return <NotesView {...props} />;
        default:
          return <SummaryView {...props} />;
      }
    }
  };

  const renderKbLinks = () => {
    let loading = true;
    if (props.params.tab == 'summary') {
      loading = props.loadingSummary;
    } else if (props.params.tab == 'status') {
      loading = props.loadingStatus;
    } else if (props.params.tab == 'images') {
      loading = props.imageLoading;
    } else if (props.params.tab == 'billing') {
      loading = props.billingLoading;
    } else if (props.params.tab == 'history') {
      loading = !props.historyViewData || props.historyViewData.get('loading');
    } else if (props.params.tab == 'shipment') {
      loading = props.loadingShipment;
    } else if (props.params.tab == 'vendors') {
      loading = props.vendorLoading;
    } else if (props.params.tab == 'notes') {
      loading = false;
    }

    return !loading ? renderKbInfo() : null;
  };

  const handleOrderAction = type => {
    // Note : The following code has been temporarily added while we wait on the react version of the 'Duplicate Order',
    // 'Refund' and 'Reprint' pages to be completed.  The code below gathers up all the data the angular page requires
    // to render those pages and then stores it in local storage for the angular side to read it in.  Once the react
    // versions have been completed the code below can be removed and modified to redirect them to the correct page
    const modifiedSummaryData = JSON.parse(JSON.stringify(props.summaryData));

    var result = [];
    modifiedSummaryData.metaObj = modifiedSummaryData.Meta || {};

    if (modifiedSummaryData.metaObj.ImageEvidence) {
      modifiedSummaryData.ImageEvidences = modifiedSummaryData.metaObj.ImageEvidence.split(',');
    }

    if (modifiedSummaryData.metaObj.AdminComments) {
      modifiedSummaryData.metaObj.AdminComments = unescape(
        modifiedSummaryData.metaObj.AdminComments
      );
    }

    for (const property in modifiedSummaryData.Meta) {
      result.push(`${property}: ${modifiedSummaryData.Meta[property]}`);
    }

    modifiedSummaryData.meta = result.join(', ');
    modifiedSummaryData.shortMeta =
      modifiedSummaryData.meta.length > 33
        ? modifiedSummaryData.meta.substr(0, 30) + '...'
        : undefined;

    // build the vendorItems object
    const modifiedItemsDataOrig = JSON.parse(JSON.stringify(props.itemsDataOrig));
    for (let i = 0; i < modifiedItemsDataOrig.length; i++) {
      for (let j = 0; j < modifiedItemsDataOrig[i].Items.length; j++) {
        for (let k = 0; k < modifiedItemsDataOrig[i].Items[j].Images.length; k++) {
          modifiedItemsDataOrig[i].Items[j].Images[k].ManipCommand = null;
        }
      }
    }

    const finalObj = {
      order: modifiedSummaryData,
      selectedItems: [],
      vendorItems: modifiedItemsDataOrig
    };

    localStorage.setItem('reactToAngular', JSON.stringify(finalObj));
    if ((SecurityService.isPartner() || SecurityService.isPartnerAdmin()) && type === 'reprint') {
      window.location.hash = `#/orders-new/${type}/${props.params.id}`;
    } else {
      window.location.hash = `#/orders/setup/${type}/${props.params.id}`;
    }
  };

  const [orderTitleNum, setOrderTitleNum] = useState(props.params.id);
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      props.router.push(`/orders-new/${orderTitleNum}/summary`);
      props.resetOrderState();
      props.getSharedOrderData(orderTitleNum);
    }
  };
  const orderTitle = (
    <div>
      Order
      <input
        type="number"
        className="pl-1 pr-1"
        onChange={e => setOrderTitleNum(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
        value={orderTitleNum}
      />
      <style jsx>
        {`
          input {
            border: none;
            max-width: 8rem;
            border: 2px solid transparent;
            background: none;
          }
          input:hover {
            border-bottom: 2px solid gray;
          }
        `}
      </style>
    </div>
  );

  return (
    <>
      <Notifications />
      <div className="mt-3 ml-4 pl-2 mb-n4 d-flex justify-content-start text-right hidden-xs hidden-sm caption-text">
        <Icon height={16} width={16} className="mr-2" icon="sparkle" fill={'#FFCC4D'} /> We’re
        updating the orders page with new features and a new look.&nbsp;
        <Link className="link" to={`/orders/${props.params.id}/summary`}>
          View old orders page
        </Link>
      </div>
      <PageHeader
        title={orderTitle}
        customButtons={
          <div className="d-flex flex-wrap order-details-buttons m-n1">
            {emailButtonVisible ? (
              <DropDownContainer title="Email" className="m-1">
                {props.summaryData.SendShipping && (
                  <ListItem
                    onSelect={() => props.sendShippingConfirmation(props.params.id)}
                    leftIcon="mail"
                    text="Shipping Confirmation"
                  />
                )}
                {props.summaryData.SendConfirmation && (
                  <ListItem
                    onSelect={() => props.sendOrderConfirmation(props.params.id)}
                    leftIcon="mail"
                    text="Order Confirmation"
                  />
                )}
              </DropDownContainer>
            ) : null}
            {SecurityService.isSuperAdmin() && props.itemsDataOrig.length > 0 ? (
              <>
                <Button
                  iconLeft="copy"
                  className="button-default m-1 large"
                  vis
                  onClick={() => handleOrderAction('reorder')}
                >
                  Duplicate Order
                </Button>
                {
                  // Note: Do not allow refunds on reprints. Disable button instead.
                  !props.itemsData.filter(x => (x.Meta ? x.Meta.ReprintOf : [])).length && (
                    <Button
                      iconLeft="dollarSign"
                      className="button-default m-1 large"
                      onClick={() => handleOrderAction('refund')}
                    >
                      Refund
                    </Button>
                  )
                }
              </>
            ) : null}
            {(SecurityService.isSuperAdmin() ||
              (SecurityService.getFeatures().AllowReprint && IsInReprintableStatus())) &&
            props.itemsDataOrig.length > 0 ? (
              <Tooltip
                disabled={SecurityService.isSuperAdmin() || !isAlreadyReprinted}
                content="Our system shows that you have already made a Reprint request"
                position="left"
              >
                <Button
                  iconLeft="refreshCCW"
                  className="button-default m-1 large reprint"
                  onClick={() => handleOrderAction('reprint')}
                  disabled={!SecurityService.isSuperAdmin() && isAlreadyReprinted}
                >
                  Reprint
                  {!SecurityService.isSuperAdmin() && isAlreadyReprinted && (
                    <Icon small className="ml-0 mr-n1" icon="info" fill="#1E2731" />
                  )}
                </Button>
              </Tooltip>
            ) : null}
          </div>
        }
      />
      <PageBody size="full">
        {props.errorMessage ? (
          <Alert isOpen type="important" className="mt-4">
            {props.errorMessage}. Try refreshing or go back to the{' '}
            <Link to="/orders-new">orders listing page</Link>.
          </Alert>
        ) : (
          <>
            <TabContainer>
              {tabItems.map((tab, key) => (
                <Tab
                  to={tab.to}
                  active={props.location.pathname.includes(tab.name.toLowerCase())}
                  key={key}
                >
                  {tab.name}
                </Tab>
              ))}
            </TabContainer>
            <div className="order-page-content mt-4">
              {renderTabContents()}
              {renderKbLinks()}
            </div>
          </>
        )}
      </PageBody>
      <style jsx>
        {`
          .order-details-buttons :global(button) {
            flex-grow: 1;
          }
          :global(.products-actions-container h3) {
            display: none;
          }
          :global(.card p) {
            margin-bottom: 0.3125rem !important;
          }
        `}
      </style>
    </>
  );
};

OrderDetails.propTypes = {
  errorMessage: PropTypes.string,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  getSharedOrderData: PropTypes.func.isRequired,
  summaryData: PropTypes.object.isRequired,
  itemsDataOrig: PropTypes.array.isRequired,
  itemsData: PropTypes.array.isRequired,
  sendShippingConfirmation: PropTypes.func.isRequired,
  sendOrderConfirmation: PropTypes.func.isRequired,
  resetOrderState: PropTypes.func.isRequired,
  router: PropTypes.object
};

export default withRouter(OrderDetails);
