import { createSelector } from 'reselect';
import { cofShippingCountrySelector } from '../../../../store/selectors/countriesSelectors';
import { dataSelector } from '../../../../store/selectors/productDataSelectors';
import Strings from './strings';
import Config from '../../../../config';

const cartSelector = createSelector(dataSelector, data => {
  return data.get('cart');
});

export const cartItemsSelector = createSelector(cartSelector, cart => {
  return cart.get('items');
});

const checkoutSelector = state => state.get('checkout');

const shippingSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('shipping');
});

const addressesObjectSelector = createSelector(shippingSelector, shipping => {
  return shipping.get('addresses');
});

const shippingMethodsSelector = createSelector(shippingSelector, shipping => {
  return shipping.get('methods');
});

export const shippingMethodsGroupsSelector = createSelector(
  shippingMethodsSelector,
  shippingMethods => {
    return shippingMethods.get('groups');
  }
);

export const shippingMethodsErrorsSelector = createSelector(
  shippingMethodsSelector,
  shippingMethods => {
    return shippingMethods.get('errors');
  }
);

export const selectedShippingAddressSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['selected', 'address']);
  }
);

export const getShippingPricesRequestSelector = createSelector(
  [cofShippingCountrySelector, cartItemsSelector, selectedShippingAddressSelector],
  (shippingCountry, cartItems, selectedShippingAddress) => {
    // Skip if address isn't selected yet
    if (!selectedShippingAddress) {
      return null;
    }

    if (selectedShippingAddress.get('country') !== shippingCountry) {
      console.warn('Selected shipping address:', selectedShippingAddress.toJS());
      console.warn('Selected shipping country:', shippingCountry);
      throw new Error('Selected shipping address does not belong to selected shipping country');
    }

    const items = cartItems.map(item => ({
      Quantity: item.get('quantity'),
      SKU: item.getIn(['variant', 'sku'])
    }));

    // NOTE: Need to make sure that selected shipping address belongs to shipping country
    // TODO: Make API to filter by county?
    return {
      ShipToPostalCode: selectedShippingAddress.get('zipCode'),
      ShipToCountry: selectedShippingAddress.get('country'),
      ShipToState: selectedShippingAddress.get('state'),
      CurrencyCode: 'USD',
      LanguageCode: 'en',
      Items: items
    };
  }
);

export const shippingTimeDisclaimerSelector = createSelector([cartItemsSelector], cartItems => {
  const cartProductIds = Array.from(new Set(cartItems.map(ci => ci.get('productId')).toJS()));
  // TO-DO hardcoded for now. Folded Cards(live 76, staging 45) and Flat Cards(live 82, staging 77) shipping time...
  const productIds = Config.get('env') === 'staging' ? [45, 77] : [76, 82];

  return cartProductIds.some(pId => productIds.includes(pId))
    ? Strings.SHIPPING_TIMES_EXTENDED_DISCLAIMER
    : Strings.SHIPPING_TIMES_DISCLAIMER;
});
