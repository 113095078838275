import React from 'react';
import PropTypes from 'prop-types';
import { getGuid } from '../../../utils/random';
import { letterPatern } from '../../../utils/regex';
import './Multiselect.scss';
import Icon from '../Icon';
import Input from '../Input';
import Button from '../Button';
import DropDown from '../DropDown';
import ListItem from '../ListItem';

class Multiselect extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    // elements, which have isSection set, and key < 0
    // are not clickable elements, and can be used as Section titles
    options: PropTypes.array.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    config: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.optionsElements = [];
    this.state = {
      id: getGuid(),
      open: false,
      filter: '',
      position: this.getFirstAvailablePosition(props.options)
    };
  }

  componentDidMount() {
    const component = this;
    // TODO: Re-use closeOnClickOutsideManager
    this.outsideClickListener = event => {
      if (component.wrapper && !component.wrapper.contains(event.target) && component.state.open) {
        component.toggleDropdown();
      }
    };

    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('click', this.outsideClickListener);
    } else {
      document.addEventListener('click', this.outsideClickListener);
    }
  }

  componentWillUnmount() {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('click', this.outsideClickListener);
    } else {
      document.removeEventListener('click', this.outsideClickListener);
    }
  }

  toggleDropdown() {
    this.setState({
      open: !this.state.open,
      filter: ''
    });
  }

  filterOptions(filter) {
    this.setState({ filter, position: this.getFirstAvailablePosition() });
  }

  checkAll() {
    this.props.onChange(this.props.options);
  }

  uncheckAll() {
    this.props.onChange([]);
  }

  onKeyPressed(e) {
    let position = this.state.position;
    let index = this.getAvailablePositions().indexOf(position);

    switch (e.key) {
      case 'ArrowUp':
        if (index > 0) {
          e.preventDefault();
          position = this.getAvailablePositions()[--index];
          this.setState({ position });
          this.checkScroll(position);
        }
        break;
      case 'ArrowDown':
        if (index < this.getAvailablePositions().length - 1) {
          e.preventDefault();
          position = this.getAvailablePositions()[++index];
          this.setState({ position });
          this.checkScroll(position);
        }
        break;
      case 'Enter':
        this.check(this.getFilteredOptions()[position]);
        break;
    }
  }

  getFilteredOptions() {
    const filterStr = this.state.filter
      ? this.state.filter.toLocaleLowerCase().replace(letterPatern, '')
      : null;
    return this.props.options.filter(
      o =>
        !filterStr ||
        o.isSection ||
        (o.label ? o.label.toLocaleLowerCase().replace(letterPatern, '').indexOf(filterStr) : 0) >
          -1
    );
  }

  getAvailablePositions(options) {
    return (
      options ||
      this.getFilteredOptions()
        .map((o, i) => ({ isSection: o.isSection, position: i }))
        .filter(o => !o.isSection)
        .map(o => o.position)
    );
  }

  getFirstAvailablePosition(options) {
    return this.getAvailablePositions(options).length ? this.getAvailablePositions(options)[0] : 0;
  }

  checkScroll(position) {
    const element = this.optionsElements[position];
    const scrollPosition = this.scrollContainer.scrollTop;
    const elementOffset = element.offsetTop;

    if (elementOffset - scrollPosition > 160) {
      this.scrollContainer.scrollTop = elementOffset;
    } else if (elementOffset < scrollPosition) {
      this.scrollContainer.scrollTop = elementOffset;
    }
  }

  check(option) {
    if (!option) {
      return;
    }
    let selectedOptions = this.props.selectedOptions;
    const selected = selectedOptions.find(o => o.key === option.key);

    if (this.props.config && this.props.config.multiple) {
      if (selected) {
        selectedOptions = selectedOptions.filter(o => o.key !== option.key);
      } else {
        selectedOptions.push(option);
      }
    } else {
      if (selected && !this.props.config.preventEmpty) {
        selectedOptions = [];
      } else {
        selectedOptions = [option];
      }
      this.toggleDropdown();
    }

    this.props.onChange(selectedOptions);
  }

  isChecked(option) {
    return !!this.props.selectedOptions.find(o => o.key === option.key);
  }

  get title() {
    return (
      (this.props.config.getCustomTitle
        ? this.props.config.getCustomTitle(this.props.selectedOptions)
        : null) ||
      (this.props.selectedOptions.length
        ? this.props.selectedOptions.length > 1
          ? `${this.props.selectedOptions.length} items selected`
          : this.props.selectedOptions[0].label
        : this.props.title)
    );
  }

  render() {
    const multiple = this.props.config && this.props.config.multiple;
    const showFilter = this.props.config && this.props.config.showFilter;
    const showBulkAction = this.props.config && this.props.config.showBulkActions;
    const showImages = this.props.config && this.props.config.showImages;

    const filteredOptions = this.getFilteredOptions();
    // const labelClass = this.props.config && this.props.config.wrapMode
    //   ? 'select-item-label with-wrap'
    //   : 'select-item-label'
    const options = filteredOptions.map((o, i) => {
      // const itemClassName = o.isSection
      //   ? 'select-item disabled'
      //   : `select-item ${this.state.position === i ? 'selected' : ''}`

      const onClick = o.isSection ? () => null : () => this.check(o);

      return (
        <div
          title={o.label}
          key={this.props.title + o.key}
          ref={e => {
            this.optionsElements[i] = e;
          }}
        >
          <ListItem
            className="dropdown-item"
            onSelect={onClick}
            active={this.state.position === i}
            thumbnail={!o.isSection && showImages ? o.imageUrl : null}
            text={o.label}
            rightIcon={this.isChecked(o) ? 'check' : null}
          />
        </div>
      );
    });

    const currentFilteredOptionLabel = filteredOptions[this.state.position]
      ? filteredOptions[this.state.position].label
      : '';
    const inputAutocomplite =
      this.state.open &&
      showFilter &&
      this.state.filter &&
      currentFilteredOptionLabel &&
      currentFilteredOptionLabel.indexOf(this.state.filter) === 0 ? (
        <span className="form-control input-autocomplete">{currentFilteredOptionLabel}</span>
      ) : null;

    const dropDown = this.state.open ? (
      <div className="dropdown-menu-container" tabIndex="0" onKeyDown={e => this.onKeyPressed(e)}>
        {showFilter ? (
          <div className="filter-item mb-1 mt-1">
            {inputAutocomplite}
            <Input
              type="text"
              icon="search"
              noLabel
              iconPlacement="trailing"
              autoFocus
              value={this.state.filter}
              placeholder={this.props.placeholder}
              onChange={e => this.filterOptions(e.target.value)}
            />
          </div>
        ) : null}
        {multiple && showBulkAction ? (
          <div className="action-item p-1">
            <Button className="button-default small" onClick={() => this.checkAll()}>
              <Icon small icon="checkSquare" /> Check All
            </Button>
          </div>
        ) : null}
        {multiple && showBulkAction ? (
          <div className="action-item p-1">
            <Button className="button-default small" onClick={() => this.uncheckAll()}>
              <Icon small icon="minusSquare" /> Uncheck All
            </Button>
          </div>
        ) : null}
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          id={this.state.id}
          ref={e => {
            this.scrollContainer = e;
          }}
        >
          {options}
        </div>
      </div>
    ) : null;

    return (
      <div
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        className="dropdown multiselect-component"
        ref={ref => {
          this.wrapper = ref;
        }}
      >
        <DropDown
          title={this.title}
          isOpen={!!dropDown}
          className="dropdown-toggle"
          toggle={() => this.toggleDropdown()}
          id="dropdownMenuButton"
        >
          {dropDown || ''}
        </DropDown>
      </div>
    );
  }
}

export default Multiselect;
