import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../shared/PageHeader';

const NavigationBar = props => {
  const handleNext = () => {
    if (!props.disableNext) {
      props.handleNext();
    }
  };

  const btnTitle = () =>
    props.isEdit
      ? 'Save'
      : props.isLink
      ? 'Sync product'
      : props.isStorageOnly
      ? 'Publish to storage'
      : 'Publish to stores';

  return (
    <div className="page-header-container pb-2 w-100">
      <PageHeader
        size="standard"
        title="Product Details"
        backClick={() => props.back()}
        backText="Design"
        primaryClick={() => (props.disableNext ? null : handleNext())}
        primaryText={btnTitle()}
      />
      <style jsx>
        {`
          .page-header-container {
            position: sticky;
            top: -3rem;
            background: white;
            max-width: 66rem;
            z-index: 11;
          }
          :global(.title-row) {
            margin-top: 2rem !important;
            padding-bottom: 1rem;
          }
          :global(.navigation-row) {
            position: fixed;
            top: 4rem;
            width: 14rem;
            background: white;
            left: calc((100% - 1010px) / 2);
          }
          @media screen and (max-width: 1125px) {
            :global(.navigation-row) {
              left: 3.5rem;
            }
          }
          @media screen and (max-width: 767px) {
            :global(.navigation-row) {
              left: 0.5rem;
            }
          }
        `}
      </style>
    </div>
  );
};

NavigationBar.propTypes = {
  disableNext: PropTypes.bool,
  handleNext: PropTypes.func,
  back: PropTypes.func,
  isEdit: PropTypes.bool,
  isLink: PropTypes.bool,
  isStorageOnly: PropTypes.bool
};

export default NavigationBar;
