import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { getOrderDetailsImageResizerUrl } from 'gooten-js-utils/src/url';

const ShipmentGroups = ({ groups }) => {
  const item = (sku, imageUrl, disabled, quantity) => (
    <React.Fragment key={imageUrl}>
      <div className={`item-sh ${disabled ? 'disabled' : ''}`}>
        <div
          className="sku-image"
          style={{
            background: `url(${getOrderDetailsImageResizerUrl(
              imageUrl
            )}) center center / contain no-repeat`
          }}
        />
        <div className="title-3">{sku}</div>
        <div className="quantity">
          <div className="title-3">Quantity</div>
          <div className="value">{quantity}</div>
        </div>
      </div>
      <style jsx>{`
        .item-sh {
          margin: 20px;
          display: flex;
          align-items: center;
        }

        .item-sh .title-3 {
          flex: 1;
        }

        .disabled {
          opacity: 50%;
        }

        .sku-image {
          margin-right: 20px;
          height: 50px;
          width: 50px;
          border: 1px solid lightgray;
          border-radius: 4px;
        }

        .quantity .value {
          text-align: center;
        }
      `}</style>
    </React.Fragment>
  );

  const group = (group, pos) => (
    <React.Fragment key={pos + 'pos'}>
      <div className="group-sh">
        <div className="title-2">Shipment group {pos + 1}</div>
        {group
          .get('items')
          .map(itm =>
            item(
              itm.get('SKU'),
              itm.getIn(['Images', 0, 'SourceUrl']),
              !itm.get('supported'),
              itm.get('Quantity')
            )
          )}
      </div>
      <style jsx>{``}</style>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="shipment-groups">{groups.map((g, pos) => group(g, pos))}</div>
      <style jsx>{`
        .shipment-groups {
          margin: 20px;
          width: 80%;
        }
      `}</style>
    </React.Fragment>
  );
};

ShipmentGroups.propTypes = {
  groups: PropTypes.instanceOf(List).isRequired
};

export default ShipmentGroups;
