import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('HUB', 'PRODUCTS - SAMPLE ORDER MODAL');

export const SHOW_SAMPLE_ORDER_MODAL = getActionName('SHOW_SAMPLE_ORDER_MODAL');

export const showModal = payload => ({
  type: SHOW_SAMPLE_ORDER_MODAL,
  payload
});
