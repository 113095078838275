import { connect } from 'react-redux';
import OrderDetails from './OrderDetails';
import {
  summaryData,
  billingData,
  errorMessage,
  itemsDataOrig,
  loadingStatus
} from './OrderDetailsSelectors';
import {
  getSharedOrderData,
  sendShippingConfirmation,
  sendOrderConfirmation,
  updateItemsStatus
} from './OrderDetailsActions';
import { resetOrderState } from '../OrderActions';
import { itemsDataSelector } from 'gooten-components/src/store/selectors/hubSelectors';
import { billingLoading } from './BillingView/BillingViewSelectors';
import { historyViewData } from './HistoryView/HistoryViewSelectors';
import { loadingShipment } from './ShipmentView/ShipmentViewSelectors';

const mapDispatchToProps = {
  getSharedOrderData: orderId => getSharedOrderData(orderId),
  resetOrderState: () => resetOrderState(),
  sendShippingConfirmation: orderId => sendShippingConfirmation(orderId),
  sendOrderConfirmation: orderId => sendOrderConfirmation(orderId),
  updateItemsStatus: items => updateItemsStatus(items)
};

const mapStateToProps = state => {
  return {
    summaryData: summaryData(state),
    billingData: billingData(state),
    itemsData: itemsDataSelector(state),
    errorMessage: errorMessage(state),
    itemsDataOrig: itemsDataOrig(state),
    billingLoading: billingLoading(state),
    imageLoading: loadingStatus(state),
    historyViewData: historyViewData(state),
    loadingShipment: loadingShipment(state),
    vendorLoading: loadingStatus(state),
    summaryLoading: loadingStatus(state),
    loadingSummary: loadingStatus(state),
    loadingStatus: loadingStatus(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
