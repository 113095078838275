import React from 'react';
import PropTypes from 'prop-types';
import CoreLayout from '../../layouts/CoreLayout';
import Backdrop from '../../components/shared/Backdrop';

class NavManagerComponent extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    init: PropTypes.func.isRequired,
    initDone: PropTypes.bool.isRequired,
    currentStep: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.props.init(this.props.config.steps.map(s => s.id));
  }

  render() {
    let content = <h3>Initializing...</h3>;
    if (this.props.initDone) {
      const step = this.props.config.steps.find(s => s.id === this.props.currentStep);
      if (step) {
        content = React.createElement(step.component, { config: step.config });
      }
    }

    return (
      <CoreLayout>
        {content}
        <Backdrop />
      </CoreLayout>
    );
  }
}

export default NavManagerComponent;
