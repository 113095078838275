import { injectReducer } from '../../store/reducers';
import { injectSaga } from '../../store/sagas';
import ProductPreviewComponent from './ProductPreviewContainer';
import ProductPreviewReducer from './ProductPreviewReducer';
import ProductPreviewSaga from './ProductPreviewSaga';

const componentName = 'preview';

export default store => {
  injectReducer(store, { key: componentName, reducer: ProductPreviewReducer });
  injectSaga(store, { key: componentName, saga: ProductPreviewSaga });

  return ProductPreviewComponent;
};
