import { connect } from 'react-redux';
import Mockups from './Mockups';
import { mockupsSelector, updatePreviewsSelector } from './MockupsSelectors';
import { setError } from '../../ProductPublishActions';
import { storeIdSelector, providerNameSelector } from '../ProvidersTab/ProvidersTabSelectors';
import { saveMainMockup, toggleUpdatePreview, gotoPreviewsPage } from './MockupsActions';
import { areMockupsLoadingSelector } from '../../ProductPublishSelectors';
import { isWorkingSelector } from '../../../ImageUpload/ImageUploadSelectors';

const mapDispatchToProps = dispatch => {
  return {
    setError: (storeId, section, message) => dispatch(setError({ storeId, section, message })),
    gotoPreviewsPage: () => dispatch(gotoPreviewsPage()),
    saveMainMockup: payload => dispatch(saveMainMockup(payload)),
    toggleUpdatePreview: (storeId, value) => dispatch(toggleUpdatePreview({ storeId, value }))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    mockups: mockupsSelector(state),
    storeId: storeIdSelector(state),
    providerName: providerNameSelector(state),
    isLoading: areMockupsLoadingSelector(state),
    updatePreview: updatePreviewsSelector(state),
    isWorking: isWorkingSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mockups);
