// @flow
'use strict';

import { bind } from '../../utils/eventUtils';
import StateManager from '../../state/stateManager';
import * as actions from '../../state/actions/actionCreators';
import { PUBLIC_EVENTS } from '../../ImageEditor.events';

import analytics from 'gooten-components/src/services/analyticsService';
import EventEmitter from 'events';

export default class ZoomComponent {
  valueLabel: any;
  minus: any;
  plus: any;
  slider: any;
  _canvasZoomValue: number;
  canvasZoom: any;
  domElement: any;
  lastSlideValue: any;
  _scale: number;
  _maxScale: number;
  _minScale: number;
  bulkEditToggle: any;
  switchElement: any;
  _stateManager: StateManager;
  publicEvents: EventEmitter;
  validationBlock: any;
  validationComponent: any;
  onOffLabel: any;
  wrapper: any;

  constructor(stateManager: StateManager, publicEvents: EventEmitter, config: any) {
    let element = document.createElement('div');
    element.className = 'zoomcontrol';

    element.innerHTML = `
        <div class='zoom-and-validation'>
          <div class="validation-block">
          </div>
          <div class='zoomvalue-wrapper'>
            <label class="zoomvalue"></label>
            <label class="zoomdpi"></label>

            <label class="bulk-edit-toggle">
              <span class="on-off-label"></span>
              <input type="checkbox" class="bulk_edit_toggle">
              <div class="switch-element"></div>
            </label>
            <label class="bulk-edit-text">Apply Edits to All SKUs</label>
          </div>

          <div class="zoom-canvas-wrapper">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill="#34333A" d="M11.1667 5.33335C7.94509 5.33335 5.33341 7.94503 5.33341 11.1667C5.33341 14.3883 7.94509 17 11.1667 17C14.3884 17 17.0001 14.3883 17.0001 11.1667C17.0001 7.94503 14.3884 5.33335 11.1667 5.33335ZM3.66675 11.1667C3.66675 7.02455 7.02461 3.66669 11.1667 3.66669C15.3089 3.66669 18.6667 7.02455 18.6667 11.1667C18.6667 15.3088 15.3089 18.6667 11.1667 18.6667C7.02461 18.6667 3.66675 15.3088 3.66675 11.1667Z M15.2858 15.2858C15.6113 14.9603 16.1389 14.9603 16.4643 15.2858L20.0893 18.9108C20.4148 19.2362 20.4148 19.7638 20.0893 20.0893C19.7639 20.4147 19.2363 20.4147 18.9108 20.0893L15.2858 16.4643C14.9604 16.1388 14.9604 15.6112 15.2858 15.2858Z"/>
          </svg>
          <select id="canvas-zoom" class='dropdown-list dropdown-text'>
            <option class="dropdown-text" value="0.7">70 %</option>
            <option class="dropdown-text" value="1" selected>100 %</option>
            <option class="dropdown-text" value="1.5">150 %</option>
            <option class="dropdown-text" value="3">300 %</option>
          </select>
        </div>

        </div>
        <div>
          <button class="zoomminus">–</button>
          <div class="zoomslider-container">
            <input type="range" class="zoomslider" min="1" max="530">
          </div>
          <button class="zoomplus">+</button>
        </div>`;


    this.domElement = element;
    this.wrapper = this.domElement.querySelector('.zoomvalue-wrapper');
    this.valueLabel = this.domElement.querySelector('.zoomvalue');
    this.dpiLabel = this.domElement.querySelector('.zoomdpi');
    this.minus = this.domElement.querySelector('.zoomminus');
    this.plus = this.domElement.querySelector('.zoomplus');
    this.bulkEditToggle = this.domElement.querySelector('.bulk_edit_toggle');
    this.slider = this.domElement.querySelector('.zoomslider');
    this.switchElement = this.domElement.querySelector('.switch-element');
    this.onOffLabel = this.domElement.querySelector('.on-off-label');
    this.canvasZoom = this.domElement.querySelector("#canvas-zoom");
    if (config) {
      if (config.validationComponent) {

        this.validationComponent = config.validationComponent;
        this.validationBlock = this.domElement.querySelector('.validation-block');
        this.validationBlock.appendChild(this.validationComponent.domElement);
      }
    }
    this._stateManager = stateManager;
    this.publicEvents = publicEvents;
    this._stateManager.subscribe(this._stateChange.bind(this));
    // NOTE: Pass correct this
    this._minusClickHandler = this._minusClickHandler.bind(this);
    this._plusClickHandler = this._plusClickHandler.bind(this);
    this._canvasZoomHandler = this._canvasZoomHandler.bind(this);
    this._slideHandler = this._slideHandler.bind(this);
    this._applyHandler = this._applyHandler.bind(this);
    this._bulkEditToggleHandler = this._bulkEditToggleHandler.bind(this);
  }

  register(events: any) {
    this.events = events;
  }

  setActive(ctx: any) {
    this.ctx = ctx;
    this.draw(ctx);
  }
  _stateChange (state: any) {
    const bulkEditAvailable = state.getIn(['editor', 'bulkEditAvailable']);
    const bulkEditOn = state.getIn(['editor', 'bulkEdit']);
    this._updateBulkEditToggle(bulkEditOn, bulkEditAvailable);
  }

  _renderBulkEditToggle(on, available) {
    if (available) {
      this.wrapper.className = 'zoomvalue-wrapper';
    }
    else {
      this.wrapper.className = 'zoomvalue-wrapper bulk-edit-unavailable';
    }
    if (on) {
      this.switchElement.className = 'switch-element checked';
      this.switchElement.innerHTML = '<span class="switch-handle pull-right"></span>';
      this.onOffLabel.className = 'on-off-label checked';
      this.onOffLabel.innerHTML = 'ON';
    }
    else {
      this.switchElement.className = 'switch-element';
      this.switchElement.innerHTML = '<span class="switch-handle pull-left"></span>';
      this.onOffLabel.className = 'on-off-label';
      this.onOffLabel.innerHTML = 'OFF';
    }
  }

  _updateBulkEditToggle(on: boolean, available: boolean) {
    const effectiveOn = available ? on : false;
    this.bulkEditToggle.checked = effectiveOn;
    this._renderBulkEditToggle(effectiveOn, available);
  }

  updateDpi(dpi) {
    if (!dpi) {
      this.dpiLabel.innerHTML = '';
      this.dpiLabel.style.display = 'none';
    }
    else {
      this.dpiLabel.innerHTML = dpi + 'dpi';
      this.dpiLabel.style.display = null;
    }
  }

  disable(currentScale: any) {
    if (currentScale) {
      this.valueLabel.innerHTML = Math.round(currentScale * 100) + ' %';
    }
    else {
      this.valueLabel.innerHTML = '-';
    }
    // remove listeners
    this._removeEventListeners();
    this.domElement.style['opacity'] = 0.5;
    this.slider.disabled = true;
    this.canvasZoom.value = 1;
    this.canvasZoom.disabled = true;
    let event = new Event('forcedChange');
    this._canvasZoomHandler(event);
  }
  _removeEventListeners() {
    this.minus.removeEventListener('click', this._minusClickHandler);
    this.minus.removeEventListener('touchend', this._minusClickHandler);
    this.plus.removeEventListener('click', this._plusClickHandler);
    this.plus.removeEventListener('touchend', this._plusClickHandler);
    this.canvasZoom.removeEventListener('change', this._canvasZoomHandler);
    this.slider.removeEventListener('input', this._slideHandler);
    this.slider.removeEventListener('change', this._applyHandler);
    this.bulkEditToggle.removeEventListener('change', this._bulkEditToggleHandler);
  }

  _bulkEditToggleHandler(e) {
    let bulk = this.bulkEditToggle.checked;
    if (bulk) {
      this._stateManager.dispatchAction(actions.enableBulkEditing());
    }
    else {
      this._stateManager.dispatchAction(actions.disableBulkEditing());
    }
    this.publicEvents.emit(PUBLIC_EVENTS.BULK_MODE_TOGGLE, bulk);
  }

  _minusClickHandler(e) {
    if (this._scale !== this._minScale) {
      analytics.track('Image Editor', 'Zoom out', 'Editor Action');
      this.events['onApply'](Math.max(this._scale - 0.01, this._minScale));
    }
  }

  _plusClickHandler(e) {
    if (this._scale !== this._maxScale) {
      analytics.track('Image Editor', 'Zoom in', 'Editor Action');
      this.events['onApply'](Math.min(this._scale + 0.01, this._maxScale));
    }
  }

  _slideHandler(evt) {
    this.lastSlideValue = parseFloat(this.slider.value);
    this.valueLabel.innerHTML = this.lastSlideValue + ' %';
    const scale = this.lastSlideValue / 100;
    this.events['onInteract'](scale);
  }

  _canvasZoomHandler(e) {
    let zoom = this.canvasZoom.value;
    analytics.track('Image Editor', 'Canvas zoom', 'Editor Action');
    this.events['onZoomCanvas'](zoom);
  }

  _applyHandler(evt) {
    const val = this.lastSlideValue || parseFloat(this.slider.value);
    if (Math.round(this._scale * 100) === val) {
      //Prevent unnecessary update
      return;
    }
    analytics.track('Image Editor', this._scale * 100 < val ? 'Zoom in' : 'Zoom out', 'Editor Action');
    const scale = val / 100;
    this.events['onApply'](scale);
    this.lastSlideValue = null;
  }

  draw(args: any) {
    this._scale = args.scale;
    this._canvasZoomValue = args.stageScaleX;
    this._maxScale = args.maxScale;
    this._minScale = args.minScale;
    this.domElement.style['opacity'] = 1;
    this.slider.disabled = false;
    this.canvasZoom.disabled = false;
    //Update label
    this.valueLabel.innerHTML = Math.round(this._scale * 100) + ' %';

    // remove listeners
    this._removeEventListeners();

    // add
    // minus
    this.minus.addEventListener('click', this._minusClickHandler);
    bind(this.minus, 'touchend', this._minusClickHandler, {
      mode: 'debounce',
      delay: 500,
    });

    // plus
    this.plus.addEventListener('click', this._plusClickHandler);
    bind(this.plus, 'touchend', this._plusClickHandler, {
      mode: 'debounce',
      delay: 500,
    });

    //Update slider
    this.slider.setAttribute('min', Math.round(this._minScale * 100));
    this.slider.setAttribute('max', Math.round(this._maxScale * 100));
    this.slider.setAttribute('value', Math.round(this._scale * 100));
    this.slider.value = this._scale * 100;

    // update image zoom on UI
    this.slider.addEventListener('input', this._slideHandler);

    // update image zoon in state
    this.slider.addEventListener('change', this._applyHandler);

    this.bulkEditToggle.addEventListener('change', this._bulkEditToggleHandler);

    this.canvasZoom.addEventListener('change', this._canvasZoomHandler);

    this.publicEvents.on(PUBLIC_EVENTS.CANVAS_ZOOM, newScale => {

      if(newScale >= 0.7 && newScale < 0.8) {
        this.canvasZoom.value = 0.7;
      }
      if(newScale >= 0.9 && newScale < 1.1) {
        this.canvasZoom.value = 1;
      }     
      if(newScale >= 1.4 && newScale < 1.6) {
        this.canvasZoom.value = 1.5;
      }
      if(newScale >= 2.5) {
        this.canvasZoom.value = 3;
      }
    });
  }
}
