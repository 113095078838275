import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import PreviewImageItem from '../shared/PreviewImageItem';

const MockupsFullscreen = props => {
  const mockups = props.mockups.map((m, i) => (
    <PreviewImageItem
      imageUrl={m.get('imageUrl')}
      customClass=""
      key={`${m}${i}`}
      main={props.mainMockupUrl ? m.get('imageUrl') === props.mainMockupUrl : m.get('main')}
      onSelect={props.onSelectItem}
    />
  ));

  return (
    <div className="product-publish-mockups-fullscreen flex-container">
      <div className="fullscreen-images-grid d-flex p-4">{mockups}</div>
      <style jsx>
        {`
          .fullscreen-images-grid {
            flex-wrap: wrap;
          }
          :global(.product-publish-mockups-fullscreen .publish-preview-image-item) {
            height: 7.5rem !important;
            padding: 0 0.75rem;
            width: 7.5rem !important;
          }
        `}
      </style>
    </div>
  );
};

MockupsFullscreen.propTypes = {
  mockups: PropTypes.instanceOf(List),
  onSelectItem: PropTypes.func,
  mainMockupUrl: PropTypes.string
};

export default MockupsFullscreen;
