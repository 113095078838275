import { createSelector } from 'reselect';
import {
  rootSelector,
  SKUsSelector,
  selectedSubCategorySelector
} from '../../../SKUsSelectionViewSelectors';
import skusListService from './../services/skusListService';
import { fromJS } from 'immutable';

export const pageSizeSelector = createSelector([], () => 8);

export const pageSelector = createSelector([rootSelector], root => root.get('page'));

export const optionsSelector = createSelector([rootSelector], root => {
  // TODO: check other products
  const categoriesToExclude =
    root.get('regions').size &&
    root.getIn(['regions', root.get('selectedRegionIndex'), 'sub-categories']).size > 1
      ? ['Brand', 'Model']
      : [];
  return root.get('options').filter(o => categoriesToExclude.indexOf(o.get('name')) === -1);
});

/**
 * All skus from selected product to link to
 */
export const categorySKUsSelector = createSelector(
  [SKUsSelector, selectedSubCategorySelector],
  (skus, subCategory) => {
    if (!skus || !subCategory) {
      return skus;
    }

    const options = subCategory.get('attributes').filter(a => a.get('id'));
    return skus.filter(sku =>
      options.reduce((result, o) => {
        const skuOptionValue = sku.get(o.get('id'));
        if (!skuOptionValue || !o.get('values').find(v => v.get('id') === skuOptionValue)) {
          return false;
        }
        return result;
      }, true)
    );
  }
);

export const skuNotificationSelector = createSelector([rootSelector], root =>
  root.get('skuSelectedNotification')
);

/**
 * All skus with options assigned
 */
export const allVariantsSelector = createSelector(
  [categorySKUsSelector, optionsSelector],
  (skus, options) =>
    skus.map(sku =>
      sku.set(
        'options',
        options.map(option => {
          const key = sku.get(option.get('id'));
          let optionValue;
          if (key) {
            const opt = option.get('values').find(val => val.get('id') === key);
            if (opt) {
              optionValue = opt.get('name');
            }
          }

          return fromJS({
            id: option.get('id'),
            name: option.get('name'),
            value: optionValue
          });
        })
      )
    )
);

export const suggestedMatchesSelector = createSelector(
  [rootSelector, allVariantsSelector],
  (root, skus) =>
    skusListService.filterSuggestedVariants(
      skus,
      root.getIn(['variantsMappings', root.get('currentVariantIndex')])
    )
);

/**
 * All variants that should be displayed on single page in skus list, filtered by visibility
 * and page
 */
export const pageVariantsSelector = createSelector(
  [suggestedMatchesSelector, allVariantsSelector, optionsSelector, pageSizeSelector, pageSelector],
  (suggestedMatches, allVariants, options, pageSize, page) =>
    skusListService.pageVariants(suggestedMatches, allVariants, options, page, pageSize)
);

export const pagesSelector = createSelector(
  [suggestedMatchesSelector, allVariantsSelector, optionsSelector, pageSizeSelector],
  (suggestedMatches, allVariants, options, pageSize) => {
    const skusSize =
      skusListService.filterVisibleVariants(suggestedMatches, options, true).size +
      skusListService.filterVisibleVariants(allVariants, options, false).size;
    return skusListService.createPages(skusSize, pageSize);
  }
);

export const disableFilteringSelector = createSelector(
  [suggestedMatchesSelector],
  suggestedMatches => suggestedMatches.size < 6
);
