import { connect } from 'react-redux';
import FulfillmentSettings from './FulfillmentSettings';
import { initFor, changeFor, updateFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';

const init = initFor(FulfillmentSettings.name);
const change = changeFor(FulfillmentSettings.name);
const update = updateFor(FulfillmentSettings.name);
const cardSelector = cardSelectorFor(FulfillmentSettings.name);

const mapDispatchToProps = {
  init,
  change,
  update
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    unmodified: cardState.get('unmodified'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentSettings);
