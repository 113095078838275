import React from 'react';
import moment from 'moment';

const PackingSlipPreviewComponent = ({ storeName, logoUrl, message, storeUrl, helpContact }) => {
  return (
    <div className="packing-slip-component">
      <div className="header">
        <div className="left">
          <div className="store-name">{storeName || 'Store name'}</div>
          <div className="order-id-wrapper">
            Order <span className="order-id">#1234567890</span>
          </div>
          <div className="date">{moment().format('ll')}</div>
        </div>
        <div className="logo"></div>
      </div>
      <hr className="thick" />
      <div className="ship-to-wrapper">
        <div className="left">Ship to</div>
        <div className="right">
          Johannes Gutenburg
          <br />
          5 4th Place, Apt 4R
          <br />
          Brooklyn, NY 11231, US
        </div>
      </div>
      <hr className="thick" />
      <div className="items-title">Items</div>
      <div className="table-wrapper">
        <table className="main-table">
          <thead>
            <tr>
              <th className="pb-2">Description</th>
              <th className="pb-2 align-right">Qty Shipped</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pb-2">12345</td>
              <td className="pb-2 align-right">12</td>
            </tr>
            <tr>
              <td className="pb-2">12345</td>
              <td className="pb-2 align-right">12</td>
            </tr>
            <tr>
              <td className="pb-2">12345</td>
              <td className="pb-2 align-right">12</td>
            </tr>
            <tr className="total-row">
              <td className="total">Total</td>
              <td className="align-right total number">48</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="message-wrapper">
        <div className="mb-3 message">{message || 'Thank you message'}</div>
      </div>
      <div className="footer">
        <div className="mr-3 website">{storeUrl || 'Store website'}</div>
        <div className="help-contact">{helpContact || 'Help contact'}</div>
      </div>
      <style jsx>
        {`
          /* latin-ext */
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2)
              format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
              U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
              format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
              U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          /* latin-ext */
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2)
              format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
              U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
              format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
              U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          hr {
            margin-top: 9px;
            margin-bottom: 9px;
          }
          hr.thick {
            border-top-width: 2px;
            border-color: black;
          }
          hr.bottom {
            border-top-width: 2px;
            margin-top: 20px;
            border-color: black;
          }
          .packing-slip-component {
            font-family: 'Inter';
          }
          .store-name {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 0px;
          }
          .logo {
            width: 234px;
            height: 94px;
            background-image: url(${logoUrl || 'https://placehold.it/234x64'});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
          }
          .order-id-wrapper {
            font-size: 20px;
            color: #000000;
          }
          .date {
            font-size: 20px;
            color: #000000;
          }
          .items-title {
            color: black;
            font-size: 20px;
            font-weight: 700;
          }
          .total-row td {
            border-top: 2px solid #000000;
            padding-top: 10px;
          }
          .table-wrapper {
            margin-bottom: 20px;
          }
          .total {
            font-size: 10px;
            font-weight: 700;
            color: black !important;
          }
          .total.number {
            font-weight: 700;
          }
          .main-table {
            width: 100%;
            color: #000000;
          }
          .main-table th {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
          }
          .main-table td {
            font-size: 20px;
            color: #000000;
          }
          .align-right {
            text-align: right;
          }
          .ship-to-wrapper {
            display: flex;
            font-size: 16px;
            color: #000000;
          }
          .ship-to-wrapper .left {
            padding-right: 20px;
          }
          .header {
            display: flex;
          }
          .header .left {
            flex: 1;
          }
          .message-wrapper {
            min-height: 160px;
            display: flex;
            color: #000000;
          }
          .message {
            flex: 1;
            padding-top: 15px;
            border-top: 2px solid #000000;
            align-self: flex-end;
            font-size: 20px;
          }
          .footer {
            display: flex;
            font-size: 20px;
            color: #000000;
          }
          .footer .website {
            flex: 1;
          }
          .footer .help-contact {
            flex: 1;
            text-align: right;
          }
        `}
      </style>
    </div>
  );
};

export default PackingSlipPreviewComponent;
