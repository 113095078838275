import { Record } from 'immutable';

export const PartnerTierModel = new Record({
  Status: '',
  AssignedAt: '',
  AssignedBy: '',
  Comments: '',
  ExpireAt: '',
  RevisedAt: '',
  OrdersTotal: 0,
  PreviousStatus: '',
  PreviousOrdersTotal: ''
});
