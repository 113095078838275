import { connect } from 'react-redux';
import PublishView from './PublishView';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import {
  fetchUserData,
  fetchStores,
  validateCsv,
  publishCsv,
  loadCsv,
  resetValidateAndPublish
} from '../../StoreBulkCreationActions';
import {
  showLoadingSelector,
  getProvidersSelector,
  getStoresSelector,
  getEmailSelector,
  getFetchStoresErrorMsgSelector,
  getFetchEmailErrorMsgSelector,
  validateCsvError,
  validateCsvSuccess,
  publishCsvError,
  publishCsvSuccess,
  csvDataSelector
} from '../../StoreBulkCreationSelectors';

const mapDispatchToProps = {
  fetchUserData: payload => fetchUserData(payload),
  fetchStores: payload => fetchStores(payload),
  validateCsv: payload => validateCsv(payload),
  publishCsv: payload => publishCsv(payload),
  loadCsv: payload => loadCsv(payload),
  resetValidateAndPublish: payload => resetValidateAndPublish(payload)
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: showLoadingSelector(state),
    emailAddress: getEmailSelector(state),
    providers: getProvidersSelector(state),
    stores: getStoresSelector(state),
    fetchStoresErrorMsg: getFetchStoresErrorMsgSelector(state),
    fetchEmailErrorMsg: getFetchEmailErrorMsgSelector(state),
    validateCsvError: validateCsvError(state),
    validateCsvSuccess: validateCsvSuccess(state),
    publishCsvError: publishCsvError(state),
    publishCsvSuccess: publishCsvSuccess(state),
    csvData: csvDataSelector(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(PublishView));
