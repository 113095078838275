import { put, select } from 'redux-saga/effects';
import {
  defaultOrientationSelector,
  publishedOrientationSelector,
  selectedOptionsSelector
} from '../../../../SKUSelectionSelectors';
import { selectOption } from '../../../../SKUSelectionActions';
import Config from '../../../../../../config';
import { selectedProductsSelector } from '../../../../../../store/selectors/productDataSelectors';

// Generator -> setup orientation for Edit mode. use orientation from gooten_mappings
// and set it in selectedOrientations if it's Portrait
export function* setupOrientation() {
  const publishedOrientation = yield select(publishedOrientationSelector);
  const defaultOrientation = yield select(defaultOrientationSelector);
  const selectedProducts = yield select(selectedProductsSelector);
  const selectedOptions = yield select(selectedOptionsSelector);

  const supportOrientation =
    selectedProducts &&
    selectedProducts.every(
      prod =>
        prod.get('details') &&
        prod.get('details').product.options.find(opt => opt.id.toLowerCase() === 'orientation')
    );

  // check if products support orientation and set default if not set...
  if (supportOrientation) {
    // check if we have orientation in options....
    const orientation = selectedOptions && selectedOptions.get('orientation');
    const selectedOrientation =
      orientation && orientation.indexOf('portrait') !== -1
        ? 'portrait'
        : orientation && orientation.indexOf('landscape') !== -1
        ? 'landscape'
        : '';

    yield put(selectOption('orientation', selectedOrientation || defaultOrientation));

    if (publishedOrientation === 'changed' && !selectedOrientation) {
      // this means that orientation has been changed, and product was published with changed orientation
      // now we have to update selectedOptions based on defaultOrientation
      yield put(
        selectOption('orientation', defaultOrientation === 'landscape' ? 'portrait' : 'landscape')
      );
    }
  }
}

class OrientationService {
  getOrientationOptionJSON(anySquareOption, groupId) {
    const opt = {
      id: 'orientation',
      title: 'Orientation',
      placement: 'sku-select-right',
      values: [
        {
          id: 'portrait',
          name: 'Portrait'
        },
        {
          id: 'landscape',
          name: 'Landscape'
        }
      ],
      'control-type': 'pills',
      'group-id': groupId || 1,
      'multi-select': false,
      prefill: true,
      isSelectAllDisabled: true
    };

    if (anySquareOption) {
      opt.values.unshift({
        id: 'square',
        name: 'Square'
      });
    }
    return opt;
  }

  isSquareSize(sku) {
    // first try to split with "-"
    let splited = sku.split('-');
    // if it's not splited, try to split with "_"
    splited = splited.length === 1 ? sku.split('_') : splited;

    const size = splited.find(item => !isNaN(item.replace(/[x]/gi, '')));
    if (size) {
      const measures = size.split('x') || size.split('X');
      return new Set(measures).size === 1;
    }
  }

  getDefaultTemplatesOrientation(templates) {
    const orientations = Array.from(
      new Set(
        Object.values(templates)
          .map(v => v.size)
          .map(s =>
            s.width > s.height ? 'landscape' : s.width === s.height ? 'square' : 'portrait'
          )
      )
    );
    return orientations.indexOf('landscape') !== -1 ? 'landscape' : 'portrait';
  }
}

export default new OrientationService();
