import { getActionName } from './../../ProductSelection/components/HubProductSelection/HubProductSelectionActions';

export const SET_OPTION_SELECTED_VALUES = getActionName('SET_OPTION_SELECTED_VALUES');
export const SET_PAGE = getActionName('SET_PAGE');

export const setOptionSelectedValues = (optionId, values, isSuggested) => {
  return {
    type: SET_OPTION_SELECTED_VALUES,
    payload: { optionId, values, isSuggested }
  };
};

export const setPage = value => {
  return {
    type: SET_PAGE,
    payload: value
  };
};
