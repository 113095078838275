import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import defaultState from './initialState';
import makeRootReducer from './reducers';

// ========================================================
// State Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__ || defaultState;

// ======================================================
// Middleware Configuration
// ======================================================
const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware];

if (__PROD__) {
  middleware = [...middleware];
}

// ======================================================
// Store Enhancers
// ======================================================
const enhancers = [];
if (__DEV__) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

// ========================================================
// Store Instantiation and HMR Setup
// ========================================================
const store = createStore(
  makeRootReducer(),
  initialState,
  compose(applyMiddleware(...middleware), ...enhancers)
);
store.asyncReducers = {};
store.makeRootReducer = makeRootReducer;
store.sagaMiddleware = sagaMiddleware;
store.asyncSagas = {};

if (module.hot) {
  module.hot.accept('./reducers/index', () => {
    const reducers = require('./reducers/index').default;
    store.replaceReducer(reducers(store.asyncReducers));
  });
}

export default store;
