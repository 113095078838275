import { createSelector } from 'reselect';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import { previewDataSelector } from '../../../ProductPreview/ProductPreviewSelectors';
import { selectedProviderSelector } from '../ProvidersTab/ProvidersTabSelectors';
import { fromJS } from 'immutable';

export const skusWithPreview = previewData =>
  previewData.items
    .map(item => [
      item.sku + item.dataIndex,
      item.associatedSkus && item.associatedSkus.map(a => a.sku + a.dataIndex)
    ])
    .reduce((a, c) => a.concat(c), [])
    .reduce((a, c) => a.concat(c), []);

export const mockupsSelector = createSelector([previewDataSelector], previewData =>
  fromJS(
    previewData.items.map(pr => ({
      imageUrl: pr.previewImageUrl && getSmallPreviewImageResizerUrl(pr.previewImageUrl),
      main: previewData.items[0] && previewData.items[0].previewImageUrl === pr.previewImageUrl
    }))
  )
);

export const updatePreviewsSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('updatePreview')
);
