import React from 'react';
import PropTypes from 'prop-types';
import Strings from './strings';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import Chip from 'gooten-components/src/components/shared/Chip';
import Icon from 'gooten-components/src/components/shared/Icon';

// This component renders individual order items
export const PastOrdersListHeader = props => {
  const selectionPill = (
    <Chip className="green" onClick={props.deselectAll}>
      {`${props.selectedItemsCount} ${Strings.SELECTION}`}
      <Icon className="mr-n2" icon="x" />
    </Chip>
  );

  return (
    <TableRow header columns="8rem 20% minmax(10rem, 50%) 6.5rem 6.5rem">
      <Cell>
        <div className="ml-n2 mr-n2">{props.selectedItemsCount > 0 && selectionPill}</div>
      </Cell>
      <Cell>{Strings.PRODUCT_NAME}</Cell>
      <Cell>{Strings.PRODUCT_INFORMATION}</Cell>
      <Cell>{Strings.ORDERS_ID}</Cell>
      <Cell rightAlign>{`${props.totalItems} ${Strings.TOTAL}`}</Cell>
    </TableRow>
  );
};

PastOrdersListHeader.propTypes = {
  totalItems: PropTypes.number,
  selectedItemsCount: PropTypes.number,
  deselectAll: PropTypes.func
};

export default PastOrdersListHeader;
