import { connect } from 'react-redux';
import { next, back } from '../../containers/NavManager/NavManagerActions';
import { PLACE_ORDER, clearOrder, setShippingAddress } from './CheckoutActions';
import { fetchAsync, clearCart, updateShippingCountry } from '../../store/actions/dataActions';
import {
  cartItemsSelector,
  selectedShippingAddressSelector,
  paymentSelector,
  orderObjectSelector,
  canPlaceOrderSelector,
  errorAlertSelector,
  sourceHistorySelector
} from './CheckoutSelectors';
import { isShippingAddressesLoadedSelector } from './atoms/AddressSelector/AddressSelectorSelectors';
import { isLoadingSelector } from '../../store/selectors/globalSelector';
import Checkout, { CheckoutToast } from './Checkout';
import analyticsService from '../../services/analyticsService';
import withRouteStep from '../../hocs/withRouteStep';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  const items = cartItemsSelector(state);
  const selectedShippingAddress = selectedShippingAddressSelector(state);
  const selectedPaymentMethod = paymentSelector(state);
  const isLoading = isLoadingSelector(state) || !isShippingAddressesLoadedSelector(state);
  const order = orderObjectSelector(state);
  const canPlaceOrder = canPlaceOrderSelector(state);
  const errorAlert = errorAlertSelector(state);
  const sourceHistory = sourceHistorySelector(state);
  return {
    items,
    selectedShippingAddress,
    selectedPaymentMethod,
    isLoading,
    order,
    canPlaceOrder,
    errorAlert,
    sourceHistory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    gotoNextStep: () => dispatch(next()),
    goBack: () => dispatch(back()),
    placeOrder: () => {
      analyticsService.trackWithConfigSource('Place Order Clicked');
      return dispatch(fetchAsync(PLACE_ORDER));
    },
    clearOrder: () => {
      dispatch(clearOrder());
    },
    clearCart: () => {
      dispatch(clearCart());
      dispatch(updateShippingCountry());
      dispatch(setShippingAddress());
    }
  };
};

export default withRouteStep(connect(mapStateToProps, mapDispatchToProps)(Checkout), 'checkout');

export const CheckoutWithToast = connect(mapStateToProps, mapDispatchToProps)(CheckoutToast);
