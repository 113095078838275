import StateManager from '../../state/stateManager';
import { ToolbarOptions } from './toolbar.options';
import { ToolbarService } from './toolbar.service';
import EventEmitter from 'events';

export class ToolbarComponent {
  domElement: any;

  constructor(stateManager: StateManager, config: any, publicEvents: EventEmitter) {
    let options = new ToolbarOptions(config);
    let toolbarService = new ToolbarService(stateManager, options, publicEvents);

    let container = document.createElement('div');
    container.className = 'toolbar' + (options.customClass ? ` ${options.customClass}` : '');

    toolbarService.buttons.forEach((btn, index) => {
      container.appendChild(btn.getElement());
      if (index === 1 || index === 3) {
        const verticalDivider = document.createElement('div');
        verticalDivider.style.cssText = `
        border-right: 1px solid #E3E6E8;
        width: 0px;
        height: 16px;
        margin: 8px 0px 8px 0px;
        display: inline-block;`;
        container.appendChild(verticalDivider);
      }
    });

    this.domElement = container;
  }
}
