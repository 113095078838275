import { all, put, select, takeLatest } from 'redux-saga/effects';
import { DUPLICATE_CHANGES } from './DuplicateChangesAction';
import { storesSelector } from './DuplicateChangesSelector';
import { selectedProviderSelector } from '../../ProvidersTab/ProvidersTabSelectors';
import { changeStoreField, changeStorageField } from '../../../ProductPublishActions';
import { changeStorePrices } from '../ProductPricing/ProductPricingActions';
import Config from '../../../../../config';

const isRestricted = (option, provider) => {
  switch (option) {
    case 'variants':
      return Config.get('editMode') && provider === 'storage';
    case 'selectedOptions':
      return Config.get('editMode');
    default:
      return false;
  }
};

export function* duplicateChangesHandler(action) {
  const stores = yield select(storesSelector);
  const selectedProvider = yield select(selectedProviderSelector);
  const selectedStores = stores.filter((s, i) => action.payload.stores.includes(i));

  let tasks = [];
  action.payload.options.forEach(option => {
    switch (option) {
      case 'productName':
      case 'productDesc':
      case 'productType':
      case 'selectedTags':
      case 'selectedOptions':
      case 'selectedCollections':
      case 'variants':
      case 'draft': {
        selectedStores.forEach(store => {
          const field = option;
          const value = selectedProvider.get(option);

          if (isRestricted(option, store.get('provider'))) return;

          const action = store.get('sId')
            ? changeStoreField({ storeId: store.get('sId'), field, value })
            : changeStorageField({ field, value });

          tasks.push(put(action));
        });
        break;
      }

      case 'pricing': {
        selectedStores.forEach(store => {
          selectedProvider.get('variants').forEach(variant => {
            const item = {
              index: variant.get('index'),
              customerPrice: variant.get('customerPrice')
            };
            if (store.get('sId')) {
              tasks.push(put(changeStorePrices({ storeId: store.get('sId'), item })));
            }
          });
        });
        break;
      }
    }
  });

  yield all(tasks);
}

export function* watchDuplicateChanges() {
  yield takeLatest([DUPLICATE_CHANGES], duplicateChangesHandler);
}

export default function* rootSaga() {
  yield all([watchDuplicateChanges()]);
}
