import StateManager from '../../../state/stateManager';
import { switchToPrintPreview } from '../../../state/actions/actionCreators';
export class PrintPreviewButton {

  customClass: string;
  name: string;
  element: any;
  active: boolean;

  constructor(config: any, stateManager : StateManager) {
    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Print Preview';
    this.stateManager = stateManager;
    this.active = config.editorConfig.preview === 'print';
  }

  getElement() {

    let element = document.createElement('div');
    element.className += ` editor-btn printPreview ${this.customClass}`;
    if (this.active) {
      element.className += ' active';
    }
    element.innerHTML = this.name;

    element.addEventListener('click', () => this.action());
    this.element = element;
    return element;
  }

  action() {
    this.stateManager.dispatchAction(switchToPrintPreview());
    this.element.previousSibling.classList.remove('active');

    this.element.classList.add('active');
  }
}
