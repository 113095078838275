import { createSelector } from 'reselect';

const cofSelector = state => state.get('customOrderForm');

const orderFromViewSelector = createSelector([cofSelector], cof => cof.get('orderFromView'));

export const isLoadedSelector = createSelector([orderFromViewSelector], orderFromView =>
  orderFromView.get('loaded')
);

export const hasPastOrdersSelector = createSelector([orderFromViewSelector], orderFromView =>
  orderFromView.get('hasPastOrders')
);

export const hasHubProductsSelector = createSelector([orderFromViewSelector], orderFromView =>
  orderFromView.get('hasHubProducts')
);
