import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import FileUpload from 'react16-fileupload';
import DropzoneComponent from 'react-dropzone-component';
import styled from 'styled-components';
import Button from '../../../../Button';

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 32px 32px 28px;
  height: 362px;

  @media (max-width: 991px) {
    height: ${props => (props.isMultiSpace ? '424' : '362')}px;
  }

  @media (max-width: 767px) {
    height: ${props => (props.isMultiSpace ? '424' : '418')}px;
  }

  @media (max-width: 480px) {
    height: 278px;
  }
`;

class UploadFromDevice extends PureComponent {
  fileUploadOptions = {
    wrapperDisplay: 'block',
    baseUrl: '',
    multiple: true,
    numberLimit: 999,
    accept: 'image/jpeg,image/png',
    chooseFile: files => {
      this.props.uploadFiles(files);
    }
  };

  componentConfig = {
    iconFiletypes: ['.jpg', '.png'],
    showFiletypeIcon: false,
    postUrl: 'no-url',
    dropzoneSelector: '#image-selection-upload-device-container-id'
  };

  djsConfig = {
    addRemoveLinks: false,
    autoProcessQueue: false,
    autoQueue: false,
    clickable: false,
    acceptedFiles: 'image/jpeg,image/png',
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview" />
    )
  };

  eventHandlers = {
    addedfile: f => this.callback(f)
  };

  componentDidMount() {
    const cont = document.getElementById('image-selection-upload-device-container-id');
    cont.addEventListener('dragenter', this.dragEffectEnter, false);
    cont.addEventListener('dragleave', this.dragEffectLeave, false);
  }

  componentWillUnmount() {
    const cont = document.getElementById('image-selection-upload-device-container-id');
    cont.removeEventListener('dragenter', this.dragEffectEnter);
    cont.removeEventListener('dragleave', this.dragEffectLeave);
  }

  dragEffectEnter = () => {
    document.getElementById('image-selection-upload-device-container-id').style.backgroundColor =
      '#eeeeee';
  };

  dragEffectLeave = () => {
    document.getElementById('image-selection-upload-device-container-id').style.backgroundColor =
      '#ffffff';
  };

  callback = f => {
    // give time to react-dropzone to cancel all subscriptions and asynchronous tasks
    setTimeout(() => {
      this.props.uploadFiles([f]);
    }, 200);
  };

  render() {
    return (
      <Container
        className="image-selection-upload-device flex-container"
        isMultiSpace={this.props.isMultiSpace}
        id="image-selection-upload-device-container-id"
      >
        <div className="bcg-image" />
        <span className="hidden-xs">Drag and drop images or</span>
        <FileUpload options={this.fileUploadOptions}>
          <Button className="button-primary large" ref="chooseBtn">
            Upload from device
          </Button>
        </FileUpload>
        <DropzoneComponent
          config={this.componentConfig}
          eventHandlers={this.eventHandlers}
          djsConfig={this.djsConfig}
        />
        <style jsx>
          {`
            .image-selection-upload-device {
              border: 2px dashed #dedfe1;
              border-radius: 4px;
            }
          `}
        </style>
      </Container>
    );
  }
}

UploadFromDevice.propTypes = {
  uploadFiles: PropTypes.func.isRequired,
  isMultiSpace: PropTypes.bool
};

export default UploadFromDevice;
