import { postJson, fetch } from '../../../utils/http';
import Config from '../../../config';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId')
    }
  };
};

const ordersUrl = () => `${Config.get('api')}orders`;

class OrdersService {
  placeOrder(request) {
    return new Promise((resolve, reject) => {
      postJson(ordersUrl(), request, getAuthQueryParams())
        .then(res => {
          if (!res.HadError) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getOrderNumber(request) {
    return new Promise((resolve, reject) => {
      let combinedParams = getAuthQueryParams();
      let queryParams = combinedParams.queryParams;
      combinedParams.queryParams = { ...queryParams, ...request };
      fetch(ordersUrl(), combinedParams)
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

// singleton
export default new OrdersService();
