import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../shared/Button';
import { COLORS } from '../../../../constants';

const Orientation = props => {
  return props.selectedOrientation.length && !props.disable ? (
    <>
      <div className="orientation-content">
        <div className="body-text-2">ORIENTATION</div>
        <div className="d-flex mt-2 mb-4">
          <Button
            className={`button-white large ${
              props.selectedOrientation === 'portrait' ? 'border-selected' : 'border'
            } w-50 mr-2 mb-3'`}
            onClick={() => props.selectOption('portrait')}
          >
            <div className="button-content w-100 d-flex align-items-center">
              <div className="rectangle-portrait mr-4" />
              <span>Portrait</span>
            </div>
          </Button>
          <Button
            className={`button-white large ${
              props.selectedOrientation === 'landscape' ? 'border-selected' : 'border'
            } w-50 ml-2 mb-3'`}
            onClick={() => props.selectOption('landscape')}
          >
            <div className="button-content w-100 d-flex align-items-center">
              <div className="rectangle-landscape mr-4" />
              <span>Landscape</span>
            </div>
          </Button>
        </div>
        <hr />
      </div>
      <style jsx>{`
        .rectangle-portrait {
          width: 10px;
          height: 18px;
          border: 1.6px solid ${COLORS.neutralDark};
          border-radius: 2px;
        }
        .rectangle-landscape {
          width: 10px;
          height: 18px;
          border: 1.6px solid ${COLORS.neutralDark};
          border-radius: 2px;
          transform: rotate(90deg);
        }
      `}</style>
    </>
  ) : null;
};

Orientation.propTypes = {
  selectOption: PropTypes.func.isRequired,
  selectedOrientation: PropTypes.string.isRequired,
  disable: PropTypes.bool
};

export default Orientation;
