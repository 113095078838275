import { connect } from 'react-redux';
import ProductPreview from './ProductPreview';
import defaultConfig from './ProductPreviewDefaultConfig';
import previewService from './services/productPreviewService';
import {
  isMaximizedSelector,
  isFullScreenPreviewSelector,
  selectedItemsSelector,
  previewItemsSelector,
  previewDataSelector,
  generationStateSelector,
  showRemoveModalSelector,
  removeItemsModalDataSelector,
  isExclusiveMockupSelector,
  isEditMode,
  proPreviewModalSelector,
  downloadModalSelector,
  shopifyImagesCountModalSelector,
  shouldRegeneratePreviewsSelector,
  allSKUsSelector,
  editDataSelector,
  generatingModalSelector
} from './ProductPreviewSelectors';
import {
  configure,
  maximizeOn,
  maximizeOff,
  fullScreenPreview,
  fullScreenCurrentItems,
  generatePreviews,
  selectItems,
  deselectItem,
  updatePreviewData,
  deselectAllItems,
  removeItems,
  showRemoveModal,
  restoreAllItems,
  resetNotificationState,
  resetPreviews,
  editModeOn,
  editModeOff,
  updatePreviewSize,
  showShopifyImagesCountModal,
  restoreLastDeletedItems,
  updateEditState,
  cancelEditMockups,
  resetEditState,
  cancelPreviewsGeneration,
  generateFullscreenPreview
} from './ProductPreviewActions';
import { back, goto } from '../../containers/NavManager/NavManagerActions';
import { PREVIEWS_PREPARE, fetchAsync } from '../../store/actions/dataActions';
import withRouteStep from '../../hocs/withRouteStep';

const mapDispatchToProps = dispatch => {
  dispatch(configure(defaultConfig));

  return {
    toggleMaximize: current => {
      !current ? dispatch(maximizeOn()) : dispatch(maximizeOff());
    },
    enableEditMode: current => {
      current ? dispatch(editModeOn()) : dispatch(editModeOff());
    },
    setFullScreenPreview: set => dispatch(fullScreenPreview(set)),
    setFullScreenCurrentItems: items => {
      dispatch(fullScreenCurrentItems(items));
      if (
        !items[0].isExclusiveMockup &&
        (!items[0].fullscreenImageUrl || items[0].generateFullscreen)
      ) {
        dispatch(generateFullscreenPreview(items));
      }
    },
    cancel: () => {
      dispatch(back());
      dispatch(resetPreviews());
    },
    selectItems: items => dispatch(selectItems(items)),
    deselectItem: item => dispatch(deselectItem(item)),
    deselectAllItems: () => dispatch(deselectAllItems()),
    updatePreviewData: items => {
      const data = previewService.reIndexItems(items);
      dispatch(updatePreviewData(data));
    },
    removeItems: items => {
      dispatch(removeItems(items));
      dispatch(showRemoveModal(false));
    },
    showRemoveModal: value => dispatch(showRemoveModal(value)),
    restoreAllItems: () => dispatch(restoreAllItems()),
    restoreLastDeletedItems: () => dispatch(restoreLastDeletedItems()),
    resetNotificationState: () => dispatch(resetNotificationState()),
    prepareToPublish: (items, allSkus, shouldRegeneratePreviews) => {
      if (allSkus.some(item => item.proPreview === undefined) && shouldRegeneratePreviews) {
        dispatch(fetchAsync(PREVIEWS_PREPARE, { items }));
      } else {
        dispatch(goto('SaveAndPublish'));
      }
    },
    updatePreviewSize: value => dispatch(updatePreviewSize(value)),
    generatePreviews: items => {
      const data = previewService.reIndexItems(items);
      dispatch(deselectAllItems());
      dispatch(generatePreviews(data.filter(a => a.generatePreview)));
    },
    updateEditState: value => dispatch(updateEditState(value)),
    cancelEditMockups: editData => dispatch(cancelEditMockups(editData)),
    resetEditState: () => dispatch(resetEditState()),
    cancelPreviewsGeneration: items => {
      if (
        items.every(item =>
          item.isExclusiveMockup ? item.previewImageUrl : item.smallPreviewImageUrl
        )
      ) {
        dispatch(cancelPreviewsGeneration());
      }
    },
    showShopifyImagesCountModal: () => dispatch(showShopifyImagesCountModal())
  };
};

const mapStateToProps = state => {
  return {
    allSkus: allSKUsSelector(state),
    items: previewItemsSelector(state),
    previewData: previewDataSelector(state),
    isMaximized: isMaximizedSelector(state),
    isFullScreenPreview: isFullScreenPreviewSelector(state),
    selectedItems: selectedItemsSelector(state),
    generationState: generationStateSelector(state),
    isRemoveModalVisible: showRemoveModalSelector(state),
    removeItemsModalData: removeItemsModalDataSelector(state),
    isExclusiveMockup: isExclusiveMockupSelector(state),
    isEditMode: isEditMode(state),
    proPreviewModal: proPreviewModalSelector(state),
    downloadModal: downloadModalSelector(state),
    generatingModal: generatingModalSelector(state),
    shouldRegeneratePreviews: shouldRegeneratePreviewsSelector(state),
    editData: editDataSelector(state),
    shopifyImagesCountModalData: shopifyImagesCountModalSelector(state)
  };
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(ProductPreview),
  'preview'
);
