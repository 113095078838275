import { createSelector } from 'reselect';
import NeckLabel from '../../../../models/NeckLabel';

export const dataSelector = state => state.get('data');
export const selectedSKUsSelector = createSelector([dataSelector], data =>
  data.get('selectedSkus')
);
const neckLabelsSelector = state => state.getIn(['publish', 'neckLabel', 'neckLabelList']);

const REORDER_MISSING_NL_ID = -1;
export const selectedSkuNeckLabelIdSelector = createSelector([selectedSKUsSelector], selectedSkus =>
  selectedSkus.size
    ? selectedSkus
        .find(sku => sku.get('neck_tag_id') && sku.get('neck_tag_id') != REORDER_MISSING_NL_ID) //re-reorder case
        ?.get('neck_tag_id') ||
      (selectedSkus.find(sku => sku.get('neck_tag_image_url')) && REORDER_MISSING_NL_ID) //-1 relates to not having neck_tag_id with neck_tag_image_url in reorder case. See: https://gooten.atlassian.net/browse/TECH-5588
    : null
);

export const selectedNeckLabelSelector = createSelector(
  [selectedSkuNeckLabelIdSelector, neckLabelsSelector],
  (selectedNeckLabelId, neckLabels) => {
    const neckLabel =
      selectedNeckLabelId && neckLabels && neckLabels.size
        ? neckLabels.find(nl => nl.get('id') == selectedNeckLabelId) ||
          new NeckLabel({
            id: REORDER_MISSING_NL_ID,
            neck_tag_image_url: neckLabels
              .find(nl => nl.get('neck_tag_image_url'))
              ?.get('neck_tag_image_url'),
            name: 'Existing Neck Label'
          })
        : null;
    return neckLabel;
  }
);
