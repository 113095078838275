const capRe = /[A-Z]/;

/**
 * Splits a camelCase or PascalCase word into individual words separated by spaces.
 * @param {Object} word
 * @returns {String}
 */
export const splitCamelCase = word => {
  let output;
  let i;
  let l;
  if (typeof word !== 'string') {
    throw new Error('The "word" parameter must be a string.');
  }
  output = [];
  for (i = 0, l = word.length; i < l; i += 1) {
    if (i === 0) {
      output.push(word[i].toUpperCase());
    } else {
      if (i > 0 && capRe.test(word[i])) {
        output.push(' ');
      }
      output.push(word[i]);
    }
  }
  return output.join('');
};

export const toShortDate = dateString => {
  return dateString ? dateString.split('T')[0].replace(/-/g, '/') : dateString;
};

const isArray = obj => Array.isArray(obj);
const isObject = obj => typeof obj === 'object' && obj !== null;
const isString = obj => typeof obj === 'string';

const flatObjLevel = (obj, res, currenLevel) => {
  if (isArray(obj)) {
    obj.forEach((item, index) => {
      flatObjLevel(item, res, !currenLevel ? '[' + index + ']' : currenLevel + ' [' + index + ']');
    });
  } else if (isObject(obj)) {
    Object.keys(obj).map(key => {
      const item = obj[key];
      flatObjLevel(item, res, !currenLevel ? key : currenLevel + '.' + key);
    });
  } else if (isString(obj) && obj.indexOf('/Date(') === 0) {
    res[currenLevel] = new Date(parseInt(obj.substr(6)));
  } else {
    // Try to parse string as JSON
    try {
      obj = JSON.parse(obj);
    } catch (e) {
      obj = obj.toString();
    }
    if (isObject(obj)) {
      Object.keys(obj).map(key => {
        const item = obj[key];
        flatObjLevel(item, res, !currenLevel ? key : currenLevel + '.' + key);
      });
    } else {
      res[currenLevel] = obj;
    }
  }
};

export const flatObject = obj => {
  const flatten = {};
  flatObjLevel(obj, flatten);
  return flatten;
};

export const truncate = (str, length) =>
  str.length <= length ? str : str.slice(0, length) + '...';
