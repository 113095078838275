import React from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';

export const SelectorDialog = props => {
  return (
    <div className="hub-selector-dialog">
      <div className="selector-dialog-left-container">
        <Button className="hub-dialog-add-to-cart" onClick={() => props.addToCart()}>
          <Icon icon={'shoppingCart'} />
          Add to cart
        </Button>
      </div>
      <div className="selector-dialog-right-container">
        <Button className="hub-dialog-selected" onClick={() => props.showSelected()}>
          {props.numberOfSelectedItems} Selected
        </Button>
      </div>
      <div className="selector-dialog-exit-icon" onClick={() => props.deselectAll()}>
        <Icon icon={'x'} />
      </div>
      <style jsx>
        {`
          .hub-selector-dialog {
            z-index: 1000;
            display: flex;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            background-color: white;
            padding: 8px;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
          }
          :global(.hub-dialog-add-to-cart) {
            background-color: white;
          }
          :global(.hub-dialog-add-to-cart),
          :global(.hub-dialog-selected) {
            margin-right: 10px;
            padding-left: 16px;
            padding-right: 16px;
            height: 100%;
          }
          :global(.hub-dialog-selected) {
            height: 100%;
            color: #092dc5;
            background: #d3e2f8;
          }
          .selector-dialog-left-container {
            margin-right: 5px;
            border-right: 1px solid #ededed;
          }
          .selector-dialog-right-container {
            margin-left: 5px;
          }
          .selector-dialog-exit-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 5px;
            padding-right: 5px;
          }
        `}
      </style>
    </div>
  );
};

SelectorDialog.propTypes = {
  showSelected: PropTypes.func,
  deselectAll: PropTypes.func,
  numberOfSelectedItems: PropTypes.number,
  addToCart: PropTypes.func
};

export default SelectorDialog;
