import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { getGuid } from '../../utils/random';
import { RESET } from '../../store/actions/globalActions';
import {
  UPDATE_DATA,
  DATA_READY,
  CONFIGURE,
  CHANGE_STORE_FIELD,
  CHANGE_STORAGE_FIELD,
  SET_ERROR,
  VALIDATION_UPDATE,
  RESET_PUBLISH_DATA,
  SET_ORIENTATION_CHANGED,
  BACKUP_SKUS,
  SET_WARNING_DIALOG
} from './ProductPublishActions';
import {
  CHANGE_PRODUCT_OPTION,
  REMOVE_PRODUCT_OPTION,
  ADD_PRODUCT_OPTION
} from './atoms/shared/ProductOptions/ProductOptionsActions';
import validationService from './services/validationService';
import ProvidersTabReducer from './atoms/ProvidersTab/ProvidersTabReducer';
import {
  CHANGE_PROVIDER_STATE,
  SET_DEFAULT_STATE
} from './atoms/ProvidersSelectionPanel/ProvidersSelectionPanelActions';
import {
  ADD_COLLECTION,
  REMOVE_COLLECTION,
  NEW_COLLECTION
} from './atoms/shared/ProductCollection/ProductCollectionActions';
import { ADD_TAG, NEW_TAG, REMOVE_TAG } from './atoms/shared/ProductTags/ProductTagsActions';
import { PUBLISH } from '../../store/actions/dataActions';
import {
  SELECT_VARIANT,
  RENAME_SKU_STORAGE,
  RENAME_SKU_STORE,
  UPDATE_SKU_PREVIEW,
  UPDATE_SKU_NECKTAG,
  SELECT_ALL_VARIANTS,
  DELETE_PRODUCT,
  CLEAR_SKU_NECKTAG
} from './atoms/shared/ProductVariants/ProductVariantsActions';
import {
  CHANGE_STORE_PRICES,
  SELECT_STORE_PRICES,
  SELECT_STORE_PRICE_INDEX
} from './atoms/shared/ProductPricing/ProductPricingActions';
import { SET_INFO } from './atoms/shared/ProductImage/ProductImageActions';
import { STORE_UPDATE_PREVIEW_CHANGE } from './atoms/Mockups/MockupsActions';
import neckLabelsReducer from './atoms/NeckLabels/NeckLabelsReducer';
import { SELECT_NECKTAG, NECKTAG_GENERATION_ERROR } from './atoms/NeckLabels/NeckLabelsActions';
import { HOLD_FOR_PERSONALIZATION } from './atoms/Advanced/AdvancedActions';

export const publishConfigReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case CONFIGURE:
      return fromJS(action.payload);

    case RESET_PUBLISH_DATA:
      return fromJS({});

    default:
      return state;
  }
};

export const publishDataReducer = (state = null, action) => {
  switch (action.type) {
    case UPDATE_DATA:
      return validationService.validateSelectedStoresState(fromJS(action.payload));

    case DATA_READY:
      return state.set('ready', true);

    case CHANGE_PROVIDER_STATE: {
      const storeId = action.payload.get('id');

      if (!storeId) {
        return state.setIn(['storage', 'enabled'], !state.getIn(['storage', 'enabled']));
      } else {
        return state.update('stores', stores => {
          const storeIndex = stores.findIndex(s => s.get('id') === storeId);
          return stores.setIn([storeIndex, 'enabled'], !stores.getIn([storeIndex, 'enabled']));
        });
      }
    }

    case CHANGE_PRODUCT_OPTION: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store =>
            store.update('selectedOptions', selectedOptions => {
              const optionValueToExchange = selectedOptions
                .get(selectedOptions.findIndex(o => o.get('id') === action.payload.optionId))
                .get('value');

              const selectedOptionsIndexToExchange = selectedOptions.findIndex(
                v => v.get('value') === action.payload.valueId
              );

              const newSet =
                selectedOptionsIndexToExchange > -1
                  ? selectedOptions.update(selectedOptionsIndexToExchange, o =>
                      o.set('value', optionValueToExchange)
                    )
                  : selectedOptions;

              return newSet.update(
                selectedOptions.findIndex(v => v.get('id') === action.payload.optionId),
                o => o.set('value', action.payload.valueId)
              );
            })
        )
      );
    }

    case REMOVE_PRODUCT_OPTION: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store =>
            store.update('selectedOptions', selectedOptions => {
              return selectedOptions.filter(o => o.get('id') !== action.payload.optionId);
            })
        )
      );
    }

    case ADD_PRODUCT_OPTION: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store => {
            const unusedValue = state
              .get('storage')
              .get('options')
              .map(o => o.get('id'))
              .toSet()
              .subtract(store.get('selectedOptions').map(o => o.get('value')))
              .first();

            return store.update('selectedOptions', selectedOptions => {
              return selectedOptions.push(
                fromJS({
                  id: getGuid(),
                  value: unusedValue
                })
              );
            });
          }
        )
      );
    }

    case NEW_COLLECTION: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store => store.update('collections', collections => collections.push(action.payload.item))
        )
      );
    }

    case ADD_COLLECTION: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store =>
            store.update('selectedCollections', selectedCollections =>
              selectedCollections.push(action.payload.item)
            )
        )
      );
    }

    case REMOVE_COLLECTION: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store =>
            store.update('selectedCollections', selectedCollections =>
              selectedCollections.filter(c => (c.id || c.get('id')) !== action.payload.item.id)
            )
        )
      );
    }

    case SELECT_NECKTAG: {
      // Add necktag id to publish state to share between different providers.
      const newState = state.set('neckTagId', action.payload.neckTagId);
      const skusWithNeckTag = action.payload.skusWithNeckTag;
      const hasNeckTag = !!action.payload.neckTagId;

      var updateVariantsNeckTagStatus = function updateVariantsNeckTagStatusFn(variants) {
        return variants.map(variant => {
          const skuSupportsNeckTag = skusWithNeckTag?.filter(
            sku => sku.sku?.toLowerCase() == variant?.get('sku')?.toLowerCase()
          );
          if (skuSupportsNeckTag?.size > 0) {
            let uVar = variant
              .set('hasNeckTag', hasNeckTag)
              .set('hadNeckTag', !!variant.get('hasNeckTag'))
              .set('neckTagId', action.payload.neckTagId);
            if (!hasNeckTag) {
              uVar = uVar.delete('neckTagUrl');
            }
            return uVar;
          } else {
            return variant;
          }
        });
      };

      const storesUpdate = newState.update('stores', stores =>
        stores.map(store =>
          store.update('variants', variants => updateVariantsNeckTagStatus(variants))
        )
      );
      return storesUpdate.updateIn(['storage', 'variants'], variants =>
        updateVariantsNeckTagStatus(variants)
      );
    }

    case ADD_TAG: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store => {
            return store.update('selectedTags', selectedTags => {
              return selectedTags.push(action.payload.item);
            });
          }
        )
      );
    }

    case REMOVE_TAG: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store => {
            return store.update('selectedTags', selectedTags => {
              return selectedTags.filter(c => c !== action.payload.item);
            });
          }
        )
      );
    }

    case NEW_TAG: {
      return state.update('storage', storage => {
        return storage.update('tags', tags => {
          return tags.push(action.payload.item);
        });
      });
    }

    case CHANGE_STORE_FIELD: {
      // clean up validation on store name update
      const baseState = state.update('validation', validation =>
        validationService.validate(action.payload.field, action.payload.value, validation)
      );
      const updatedState = baseState.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store => {
            return store.set(action.payload.field, action.payload.value);
          }
        )
      );
      return updatedState.update('validation', v =>
        v.update('stores', stores =>
          stores.update(
            stores.findIndex(i => i.get('id') === action.payload.storeId),
            storeValidation =>
              validationService.validate(
                action.payload.field,
                action.payload.value,
                storeValidation
              )
          )
        )
      );
    }

    case CHANGE_STORAGE_FIELD: {
      const updatedState = state.update('storage', storage =>
        storage.set(action.payload.field, action.payload.value)
      );
      // validate
      return updatedState.update('validation', validation =>
        validationService.validate(action.payload.field, action.payload.value, validation)
      );
    }

    case CHANGE_STORE_PRICES: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(i => i.get('id') === action.payload.storeId),
          store =>
            store.update('variants', variants =>
              variants.update(
                variants.findIndex(v => v.get('index') === action.payload.item.index),
                item => {
                  let newItem = item;
                  if (action.payload.item.maxCost) {
                    newItem = newItem.set('maxCost', Number(action.payload.item.maxCost));
                  }
                  if (action.payload.item.minCost) {
                    newItem = newItem.set('minCost', Number(action.payload.item.minCost));
                  }
                  if (action.payload.item.customerPriceIncludesAddonCost != undefined) {
                    newItem = newItem.set(
                      'customerPriceIncludesAddonCost',
                      action.payload.item.customerPriceIncludesAddonCost
                    );
                  }
                  if (action.payload.item.ourCostIncludesAddonCost != undefined) {
                    newItem = newItem.set(
                      'ourCostIncludesAddonCost',
                      action.payload.item.ourCostIncludesAddonCost
                    );
                  }
                  return newItem.set('customerPrice', Number(action.payload.item.customerPrice));
                }
              )
            )
        )
      );
    }

    case SELECT_VARIANT: {
      const changeSelectedState = (v, sku) => {
        if (v.get('sku') === sku) {
          return v.set('selected', !v.get('selected'));
        }
        return v;
      };

      // select all matched variants in storage
      return (
        state
          .updateIn(['storage', 'variants'], variants =>
            variants.map(v => changeSelectedState(v, action.payload))
          )

          // select all matched variants in store
          .update('stores', stores =>
            stores.map(store =>
              store.update('variants', variants =>
                variants.map(v => changeSelectedState(v, action.payload))
              )
            )
          )
      );
    }

    case SELECT_ALL_VARIANTS: {
      return state
        .updateIn(['storage', 'variants'], variants =>
          variants.map(v => v.set('selected', action.payload))
        )
        .update('stores', stores =>
          stores.map(store =>
            store.update('variants', variants =>
              variants.map(v => v.set('selected', action.payload))
            )
          )
        );
    }

    case SELECT_STORE_PRICE_INDEX: {
      const storeIndex = state.get('stores').findIndex(s => s.get('id') === action.payload.storeId);
      return state.updateIn(['stores', storeIndex, 'variants', action.payload.index], v =>
        v.set('priceSelected', action.payload.set)
      );
    }

    case SELECT_STORE_PRICES: {
      const storeIndex = state.get('stores').findIndex(s => s.get('id') === action.payload.storeId);
      switch (action.payload.option) {
        case 'all':
          return state.updateIn(['stores', storeIndex, 'variants'], variants =>
            variants.map(v => v.set('priceSelected', true))
          );
        case 'inverse':
          return state.updateIn(['stores', storeIndex, 'variants'], variants =>
            variants.map(v => v.set('priceSelected', !v.get('priceSelected')))
          );
        case 'none':
          return state.updateIn(['stores', storeIndex, 'variants'], variants =>
            variants.map(v => v.set('priceSelected', false))
          );
        default:
          return state;
      }
    }

    case RENAME_SKU_STORAGE:
      const variantIndex = state
        .getIn(['storage', 'variants'])
        .findIndex(v => v.get('index') === action.payload.index);
      return state.updateIn(['storage', 'variants', variantIndex], v =>
        v.set('customSku', action.payload.value)
      );

    case RENAME_SKU_STORE: {
      const storeIndex = state.get('stores').findIndex(s => s.get('id') === action.payload.storeId);
      const variantIndex = state
        .getIn(['stores', storeIndex, 'variants'])
        .findIndex(v => v.get('index') === action.payload.index);
      const updatedState = state.updateIn(['stores', storeIndex, 'variants', variantIndex], v =>
        v.set('customSku', action.payload.value)
      );

      const storeState = updatedState.getIn(['stores', storeIndex]);
      return updatedState.updateIn(['validation', 'stores', storeIndex], storeValidation =>
        validationService.validate(
          'customSku',
          action.payload.value,
          storeValidation,
          action.payload.index,
          storeState
        )
      );
    }

    case SET_INFO: {
      return state.set('info', action.payload);
    }

    case SET_DEFAULT_STATE: {
      return state
        .setIn(['storage', 'enabled'], action.payload.storage.enabled)
        .setIn(['storage', 'name'], action.payload.storage.name)
        .update('stores', stores =>
          stores.map(s =>
            s.set('enabled', action.payload.stores.find(st => st.id === s.get('id')).enabled)
          )
        );
    }

    case SET_ERROR: {
      return state.updateIn(['validation', 'errors'], errors => {
        if (
          !action.payload.storeId ||
          !errors ||
          errors.some(
            err =>
              err.get('storeId') === action.payload.storeId &&
              err.get('section') === action.payload.section &&
              err.get('message') === action.payload.message
          )
        ) {
          return errors;
        }

        if (action.payload.message) {
          return errors.push(
            fromJS({
              storeId: action.payload.storeId,
              section: action.payload.section,
              message: action.payload.message
            })
          );
        } else {
          return errors.filterNot(
            error =>
              error.get('storeId') === action.payload.storeId &&
              error.get('section') === action.payload.section
          );
        }
      });
    }

    case STORE_UPDATE_PREVIEW_CHANGE: {
      return state.update('stores', stores =>
        stores.update(
          stores.findIndex(v => v.get('id') === action.payload.storeId),
          store => store.set('updatePreview', action.payload.value)
        )
      );
    }

    case UPDATE_SKU_NECKTAG: {
      action.payload.neckTagImages.map(item => {
        let variantIndex = state
          .getIn(['storage', 'variants'])
          .findIndex(v => v.get('index') === item.skuIndex);
        state = state.updateIn(['storage', 'variants', variantIndex], variant =>
          variant.set('neckTagUrl', item.neckTagImgUrl).set('neckTagId', item.neckTagId)
        );
      });
      return state;
    }

    case CLEAR_SKU_NECKTAG: {
      return state.updateIn(['storage', 'variants'], variants =>
        variants.map(variant => variant.delete('neckTagUrl'))
      );
    }

    case SET_ORIENTATION_CHANGED: {
      return state.set('orientation', action.payload ? 'changed' : 'default');
    }

    case UPDATE_SKU_PREVIEW: {
      if (action.payload.storage) {
        const variantIndex = state
          .getIn(['storage', 'variants'])
          .findIndex(v => v.get('index') === action.payload.selectedSkuIndex);
        return state.updateIn(['storage', 'variants', variantIndex], variant =>
          variant.set('previewUrl', action.payload.skuMockupUrl)
        );
      } else {
        return state.update('stores', stores =>
          stores.update(
            stores.findIndex(v => v.get('id') === action.payload.storeId),
            store =>
              store.update('variants', variants =>
                variants.update(
                  variants.findIndex(v => v.get('index') === action.payload.selectedSkuIndex),
                  variant => variant.set('previewUrl', action.payload.skuMockupUrl)
                )
              )
          )
        );
      }
    }

    case VALIDATION_UPDATE: {
      return state.update('validation', validation => action.payload);
    }

    case RESET_PUBLISH_DATA: {
      return null;
    }

    case HOLD_FOR_PERSONALIZATION: {
      return state.set('personalize', action.payload);
    }

    default:
      return state;
  }
};

export const publishingStateReducer = (state = null, action) => {
  switch (action.type) {
    case UPDATE_DATA: {
      return null;
    }
    case DELETE_PRODUCT.ASYNC:
    case PUBLISH.ASYNC: {
      return 'WORKING';
    }
    case DELETE_PRODUCT.SUCCESS:
    case PUBLISH.SUCCESS: {
      return 'SUCCESS';
    }
    case NECKTAG_GENERATION_ERROR:
    case VALIDATION_UPDATE: {
      return 'INVALID';
    }
    case RESET_PUBLISH_DATA:
      return null;
    default:
      return state;
  }
};

export const backupReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case BACKUP_SKUS: {
      return state.set('skus', action.payload);
    }
    default:
      return state;
  }
};

// can store various information needed in S&P page...
export const infoReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case SET_WARNING_DIALOG: {
      // set warning dialog which will pop up when user lands on S&P page...
      return state.set('warningDialog', fromJS(action.payload));
    }
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  neckLabel: neckLabelsReducer,
  config: publishConfigReducer,
  data: publishDataReducer,
  providersTab: ProvidersTabReducer,
  publishingState: publishingStateReducer,
  backup: backupReducer,
  info: infoReducer
});

// make root reducer
export default (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }
  return combinedReducer(state, action);
};
