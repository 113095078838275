import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'gooten-components/src/components/shared/Loader';
import Button from 'gooten-components/src/components/shared/Button';
import DetailsCard from './Cards/DetailsCard';
import PartnerInfoCard from './Cards/PartnerInfoCard';
import ShippingAddressCard from './Cards/ShippingAddressCard';
import BillingAddressCard from './Cards/BillingAddressCard';
import OrderItemCard from './Cards/OrderItemCard';
import Alert from 'gooten-components/src/components/shared/Alert';
import Card from 'gooten-components/src/components/shared/Card';
import { COLORS } from 'gooten-components/src/constants';
import ShippingMethodCard from './Cards/ShippingMethodCard';

let scrollDiv = document.getElementsByClassName('gooten-hub-container')[0];

const SummaryView = props => {
  const {
    loading,
    summaryData,
    countries,
    addressTypes,
    getCountries,
    getAddressTypes,
    getProductionTimes,
    clearProductionTimes,
    showCustomerCosts,
    updateEditingShippingAddress,
    updateEditingBillingAddress,
    editingShippingAddress,
    editingBillingAddress,
    saveShippingAddress,
    saveBillingAddress,
    ignoreSuggestedAddress,
    applySuggestedAddress,
    getVariantsForSku,
    itemsData,
    billingData,
    changeSkuData,
    changeSku,
    productionTimes,
    updateShippingMethod,
    isShippingAddressEditDisabled
  } = props;

  useEffect(() => {
    scrollDiv.scrollTop = 0;
  }, []);

  useEffect(() => {
    loadProductionTimes(itemsData);
    return () => clearProductionTimes();
  }, [itemsData]);

  const loadProductionTimes = itemsData => {
    let items = itemsData.map(item => {
      return {
        SKU: item.SKU,
        Quantity: item.Quantity
      };
    });

    if (items.length > 0) {
      const data = {
        items,
        LanguageCode: 'en',
        CurrencyCode: 'USD',
        ShipToCountry: summaryData.ShippingAddress.CountryCode || 'US',
        ShipToPostalCode: summaryData.ShippingAddress.PostalCode,
        ShipToCountry: summaryData.ShippingAddress.CountryCode,
        ShipToState: summaryData.ShippingAddress.State
      };

      getProductionTimes(summaryData.PartnerApiKey, data);
    }
  };

  const getPrinterFriendlyUrl = orderId => {
    const location = document.createElement('a');
    location.setAttribute('href', window.location.href);
    return `${location.protocol}//${location.hostname}${
      location.port ? `:${location.port}` : ''
    }/admin/Orders/PrinterFriendly/${orderId}`;
  };

  return (
    <>
      <div className="order-page-content mt-4">
        {loading ? (
          <Loader />
        ) : Object.keys(summaryData).length === 0 || summaryData.IsNotExists ? (
          <Alert isOpen type="important">
            No plan found
          </Alert>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <div className="title-2">Order Summary</div>
              <Button
                className={'button-default large mb-3'}
                onClick={() => window.open(getPrinterFriendlyUrl(props.params.id), '_blank')}
              >
                Print
              </Button>
            </div>

            <div className="row d-flex flex-wrap-reverse">
              <div className="left-column col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <DetailsCard
                  data={summaryData}
                  items={itemsData}
                  showCustomerCosts={showCustomerCosts}
                />
                {itemsData.length === 0 ? (
                  <Loader inline />
                ) : (
                  <div className="order-items-section">
                    <Card title="Order Items">
                      {itemsData.map((item, index) => (
                        <div key={index} className="order-item">
                          {index === 0 ? (
                            <div className="title-2 mb-n2 mt-3">{item.Name}</div>
                          ) : props.itemsData[index - 1].Name !== item.Name ? (
                            <div className="title-2 mb-n2 mt-3">{item.Name}</div>
                          ) : null}
                          <div>
                            <hr className="small mb-4" />
                            <OrderItemCard
                              setActiveImage={props.setActiveImage}
                              id={props.params.id}
                              data={item}
                              summaryData={summaryData}
                              getVariantsForSku={getVariantsForSku}
                              changeSkuData={changeSkuData}
                              changeSku={changeSku}
                            />
                          </div>
                        </div>
                      ))}
                    </Card>
                  </div>
                )}
              </div>
              <div className="right-column col-xs-12 col-sm-12 col-md-12 col-lg-4">
                <ShippingAddressCard
                  orderId={props.params.id}
                  itemsData={itemsData}
                  summaryData={summaryData}
                  countries={countries}
                  addressTypes={addressTypes}
                  getCountries={getCountries}
                  getAddressTypes={getAddressTypes}
                  updateEditingShippingAddress={updateEditingShippingAddress}
                  editingShippingAddress={editingShippingAddress}
                  saveShippingAddress={saveShippingAddress}
                  ignoreSuggestedAddress={ignoreSuggestedAddress}
                  applySuggestedAddress={applySuggestedAddress}
                  isShippingAddressEditDisabled={isShippingAddressEditDisabled}
                />
                <ShippingMethodCard
                  orderId={props.params.id}
                  summaryData={summaryData}
                  itemsData={itemsData}
                  editingShippingAddress={editingShippingAddress}
                  shippingCostsData={productionTimes.data}
                  updateShippingMethod={updateShippingMethod}
                />
                <BillingAddressCard
                  orderId={props.params.id}
                  summaryData={summaryData}
                  countries={countries}
                  addressTypes={addressTypes}
                  getCountries={getCountries}
                  getAddressTypes={getAddressTypes}
                  updateEditingBillingAddress={updateEditingBillingAddress}
                  editingBillingAddress={editingBillingAddress}
                  saveBillingAddress={saveBillingAddress}
                />
                <PartnerInfoCard summaryData={summaryData} billingData={billingData} />
              </div>
            </div>
          </>
        )}
      </div>
      <style jsx>
        {`
          .order-page-content {
            background: white;
          }
          :global(.card) {
            width: 100% !important;
            margin: 0 0 1.5rem !important;
          }
          .order-items-section .title-2 {
            color: ${COLORS.neutralDark200};
          }
        `}
      </style>
    </>
  );
};

SummaryView.propTypes = {
  params: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getProductionTimes: PropTypes.func.isRequired,
  clearProductionTimes: PropTypes.func.isRequired,
  showCustomerCosts: PropTypes.bool,
  updateEditingShippingAddress: PropTypes.func.isRequired,
  updateEditingBillingAddress: PropTypes.func.isRequired,
  editingShippingAddress: PropTypes.bool.isRequired,
  editingBillingAddress: PropTypes.bool.isRequired,
  saveShippingAddress: PropTypes.func.isRequired,
  saveBillingAddress: PropTypes.func.isRequired,
  ignoreSuggestedAddress: PropTypes.func.isRequired,
  applySuggestedAddress: PropTypes.func.isRequired,
  getVariantsForSku: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getAddressTypes: PropTypes.func.isRequired,
  changeSku: PropTypes.func.isRequired,
  summaryData: PropTypes.object.isRequired,
  billingData: PropTypes.object.isRequired,
  changeSkuData: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  addressTypes: PropTypes.array.isRequired,
  itemsData: PropTypes.array.isRequired,
  setActiveImage: PropTypes.func,
  productionTimes: PropTypes.object,
  updateShippingMethod: PropTypes.func,
  isShippingAddressEditDisabled: PropTypes.bool
};

SummaryView.defaultProps = {
  showCustomerCosts: false
};

export default SummaryView;
