import { connect } from 'react-redux';
import {
  isLoadingSelector,
  cartItemsSelector,
  itemsInCartCountSelector,
  subtotalSelector,
  shippingSelector,
  highestSurchargeSelector,
  taxSelector,
  totalSelector,
  orderSummaryErrorsSelector
} from './OrderSummarySelectors';
import OrderSummary from './OrderSummary';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = null;

const mapStateToProps = state => {
  let isLoading = isLoadingSelector(state);
  let itemsInCart = cartItemsSelector(state);
  let itemsInCartCount = itemsInCartCountSelector(state);
  let subtotal = subtotalSelector(state);
  let shipping = shippingSelector(state);
  let tax = taxSelector(state);
  let total = totalSelector(state);
  let surcharge = highestSurchargeSelector(state);
  let orderSummaryErrors = orderSummaryErrorsSelector(state);

  return {
    isLoading,
    itemsInCart,
    itemsInCartCount,
    subtotal,
    shipping,
    surcharge,
    tax,
    total,
    orderSummaryErrors
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
