import React from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import { parseCurrency } from './helpers';
import Icon from 'gooten-components/src/components/shared/Icon';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';

const PartnerProfitCard = ({ orderBilling }) => {
  return (
    <>
      <div className="card-container min-height-card">
        <Card
          title={
            <div className="d-flex">
              <div className="mr-2">Partner Profit</div>
              <Tooltip content="Discounts are applied to the total profit only.">
                <Icon height={20} width={20} icon="helpCircle" />
              </Tooltip>
            </div>
          }
        >
          <div className="d-flex justify-content-between">
            <p>Product Profit</p>
            <div className="d-flex flex-wrap justify-content-end">
              {parseCurrency(orderBilling.get('PartnerProductProfit'))}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p>Ship Profit</p>
            <div className="d-flex flex-wrap justify-content-end">
              {parseCurrency(orderBilling.get('PartnerShipProfit'))}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p>Total Profit</p>
            <div className="d-flex flex-wrap justify-content-end">
              {parseCurrency(orderBilling.get('PartnerTotalProfit'))}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p>Profit Margin</p>
            <div className="d-flex flex-wrap justify-content-end">
              {orderBilling.get('PartnerProfitMargin')}%
            </div>
          </div>
        </Card>
      </div>
      <style jsx>
        {`
          .card-container :global(.title-2) {
            overflow: visible !important;
          }
        `}
      </style>
    </>
  );
};

PartnerProfitCard.propTypes = {
  orderBilling: PropTypes.object
};

export default PartnerProfitCard;
