import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'SHIPMENT_VIEW');

export const GET_SHIPMENT_DATA = getActionName('GET_SHIPMENT_DATA');
export const UPDATE_SHIPMENT_DATA = getActionName('UPDATE_SHIPMENT_DATA');

export function getShipmentData(orderId) {
  return {
    type: GET_SHIPMENT_DATA,
    payload: { orderId }
  };
}

export function updateShipmentData(data) {
  return {
    type: UPDATE_SHIPMENT_DATA,
    payload: { data }
  };
}
