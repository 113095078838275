import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ImageSelectionModal.scss';
import MyArtwork from './atoms/MyArtwork';
import UploadImage from './atoms/UploadImage';
import EmbroideryDSTUpload from '../../ImageUpload/atoms/Embroidery/EmbroideryDST';
import { SCREEN_TYPE } from './ImageSelectionModalSelectors';

const ImageSelectionModal = props => {
  useEffect(() => {
    if (!props.hasImages) {
      props.checkImages();
    }
  }, []);

  const dismissModal = () => {
    props.showScreen(SCREEN_TYPE.none);
    props.modalClose();
  };

  const uploadImage = () => (
    <UploadImage
      isOpen={props.isOpen}
      onBack={() => props.showScreen(SCREEN_TYPE.myArtwork)}
      onClose={() => dismissModal()}
      showBack={props.hasImages}
      fileTypes={props.isEmbroidery ? 'image/png' : 'image/jpeg,image/png'}
      requiredImageSize={props.isEmbroidery ? props.requiredImageSizeData : null}
    />
  );

  const myArtwork = () => (
    <MyArtwork
      isOpen={props.isOpen}
      imageApply={props.imageApply}
      onClose={() => dismissModal()}
      onUploadNewArtwork={() => props.showScreen(SCREEN_TYPE.uploadImage)}
      onUploadAdditionalFiles={() => props.showScreen(SCREEN_TYPE.uploadAdditionalFiles)}
      isEmbroidery={props.isEmbroidery}
    />
  );

  const additionalFiles = () => (
    <EmbroideryDSTUpload
      handleBack={() => props.showScreen(SCREEN_TYPE.myArtwork)}
      handleCancel={() => dismissModal()}
    />
  );

  const renderScreen = () => {
    switch (props.screenType) {
      case SCREEN_TYPE.none:
        return null;

      case SCREEN_TYPE.myArtwork:
        return myArtwork();

      case SCREEN_TYPE.uploadImage:
        return uploadImage();

      case SCREEN_TYPE.uploadAdditionalFiles:
        return additionalFiles();

      default:
        null;
    }
  };

  return <div className="image-modal-selection-container p-0">{renderScreen()}</div>;
};

ImageSelectionModal.propTypes = {
  modalClose: PropTypes.func,
  // eslint-disable-next-line
  isOpen: PropTypes.bool,
  checkImages: PropTypes.func,
  hasImages: PropTypes.bool,
  // eslint-disable-next-line
  imageApply: PropTypes.func.isRequired,
  loadingImagesCount: PropTypes.number.isRequired,
  isEmbroidery: PropTypes.bool,
  screenType: PropTypes.number,
  showScreen: PropTypes.func.isRequired,
  hasArtwork: PropTypes.bool,
  requiredImageSizeData: PropTypes.array
};

export default ImageSelectionModal;
