import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../shared/Checkbox';

const Advanced = props => {
  return (
    <>
      {!props.customization ? (
        <>
          <div className="product-publish-advanced d-flex flex-column">
            <span className="title-2">Advanced</span>
            <Checkbox
              className="personalization-cb"
              label="Hold for Personalization"
              secondaryText="Hold orders for this product until artwork has been updated"
              noBorder
              checked={!!props.checked}
              onChange={() => props.holdForPersonalization(!props.checked)}
            />
          </div>
          <style jsx>{`
            .product-publish-advanced {
              margin-top: 35px;
            }

            :global(.product-publish-advanced) :global(.personalization-cb) {
              margin-top: 15px;
            }
          `}</style>
        </>
      ) : null}
    </>
  );
};

Advanced.propTypes = {
  customization: PropTypes.bool,
  holdForPersonalization: PropTypes.func,
  checked: PropTypes.bool
};

export default Advanced;
