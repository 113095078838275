import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import moment from 'moment';
import { COLORS } from 'gooten-components/src/constants';
import Icon from 'gooten-components/src/components/shared/Icon';

const HistoryTable = props => {
  return (
    <>
      <TableRow
        scrollableX
        columns="minmax(7rem, auto) minmax(5rem, auto) minmax(5rem, auto) minmax(10rem, auto)
        minmax(7rem, auto) minmax(7rem, auto)"
      >
        <Cell sticky header>
          Object
        </Cell>
        <Cell header>Change Type</Cell>
        <Cell header>User</Cell>
        <Cell header>Description</Cell>
        <Cell header>Comments</Cell>
        <Cell header>Date</Cell>

        {!!props.historyViewData.getIn(['data', 'items']).length &&
          props.historyViewData.getIn(['data', 'items']).map((item, key) => {
            return <HistoryTableRow key={key} item={item} />;
          })}
      </TableRow>
    </>
  );
};

HistoryTable.propTypes = {
  historyViewData: PropTypes.object
};

export default HistoryTable;

const HistoryTableRow = props => {
  const [openOldData, setOpenOldData] = useState(false);
  return (
    <>
      <Cell
        className={props.item.OldObj ? 'cursor-pointer' : ''}
        onClick={() => setOpenOldData(!openOldData)}
        sticky
      >
        {props.item.OldObj ? (
          openOldData ? (
            <Icon className="ml-n3 mr-1" icon="chevronUp" />
          ) : (
            <Icon className="ml-n3 mr-1" icon="chevronDown" />
          )
        ) : null}
        {props.item.Object}
      </Cell>
      <Cell
        className={props.item.OldObj ? 'cursor-pointer' : ''}
        onClick={() => setOpenOldData(!openOldData)}
      >
        {props.item.Action}
      </Cell>
      <Cell
        className={props.item.OldObj ? 'cursor-pointer' : ''}
        onClick={() => setOpenOldData(!openOldData)}
      >
        {props.item.User.Name}
      </Cell>
      <Cell
        className={props.item.OldObj ? 'cursor-pointer' : ''}
        onClick={() => setOpenOldData(!openOldData)}
      >
        <div className="break-all">{props.item.Description}</div>
      </Cell>
      <Cell
        className={props.item.OldObj ? 'cursor-pointer' : ''}
        onClick={() => setOpenOldData(!openOldData)}
      >
        {props.item.Comments || 'No Comments'}
      </Cell>
      <Cell
        className={props.item.OldObj ? 'cursor-pointer' : ''}
        onClick={() => setOpenOldData(!openOldData)}
      >
        {moment(props.item.Date).format('MMM DD, YYYY, hh:mm A')}
      </Cell>

      {/* Display old data in toggleable dropdown if available */}
      {props.item.OldObj && openOldData ? (
        <>
          <Cell className="gray-bg mb-3" sticky header>
            Prior Values
          </Cell>
          <Cell className="gray-bg mb-3 p-2 column-span-five">
            <div className="d-flex flex-column prior-data w-100 pt-2 pb-2">
              {Object.entries(JSON.parse(props.item.OldObj)).map((x, y) => (
                <div key={y} className="prior-data-row">
                  <span>
                    <b>{x[0]}:</b>
                  </span>
                  <span>{x[1]}</span>
                </div>
              ))}
            </div>
          </Cell>
        </>
      ) : null}
      <style jsx>
        {`
          :global(.column-span-five) {
            grid-column: auto / span 5;
          }
          :global(.table-cell.gray-bg) {
            background: ${COLORS.neutralLight100};
          }
          .prior-data {
            max-height: 10rem;
            overflow-y: auto;
            max-width: calc(100vw - 11rem);
          }
          :global(.cursor-pointer) {
            cursor: pointer !important;
          }
          .prior-data-row {
            display: grid;
            grid-template-columns: 8.5rem auto;
            word-break: break-all;
          }
          @media screen and (max-width: 576px) {
            .prior-data-row {
              grid-template-columns: auto;
            }
          }
          .break-all {
            word-break: break-all;
          }
        `}
      </style>
    </>
  );
};

HistoryTableRow.propTypes = {
  item: PropTypes.object
};
