import { Layer, Group } from 'konva';
import { SurfaceLayer, KonvaStage, KonvaLayer, SurfaceElement, ImageEvents }
  from '../../flow/konvaTypes';
import ViewportService from './viewport.service';
import CanvasRectElement from '../canvas/canvasRectElement';

export default class ViewportSurfaceLayer implements SurfaceLayer {

  id: string;
  layer: KonvaLayer;
  outlineElement: SurfaceElement;
  centerLines: any[];
  hovering: boolean;
  dragging: boolean;
  hasImages: boolean;

  constructor(id: string) {
    this.id = id;
    this.layer = new Layer();
    this.layer.getCanvas()._canvas.id = id;
    this.centerLines = [];
    this.hovering = false;
    this.dragging = false;
    this.hasImages = false;
  }

  addToCanvas(stage: KonvaStage) {
    stage.add(this.layer);
  }

  draw(ctx: any) {
    this.layer.destroyChildren();
    this.layer.clear();

    var viewportsArgs = ViewportService.getViewports(ctx.state);
    for(let viewportArgs of viewportsArgs) {

      let viewportGroup = new Group(viewportArgs);

      this.drawOutline(ctx, viewportArgs, viewportGroup);

      ctx.events.on(ImageEvents.mouseEnter + '_' + viewportArgs.layer.id, () => {
        this.hovering = true;
        this.setVisibility(ctx, viewportArgs, viewportGroup);
        this.layer.batchDraw();
      });

      ctx.events.on(ImageEvents.mouseLeave + '_' + viewportArgs.layer.id, () => {
        this.hovering = false;
        this.setVisibility(ctx, viewportArgs, viewportGroup);
        this.layer.batchDraw();
      });

      ctx.events.on(ImageEvents.dragStart + '_' + viewportArgs.layer.id, () => {
        this.dragging = true;
        this.setVisibility(ctx, viewportArgs, viewportGroup);
        this.drawCenterLines(ctx, viewportArgs, viewportGroup);
        this.layer.batchDraw();
      });

      ctx.events.on(ImageEvents.dragEnd + '_' + viewportArgs.layer.id, () => {
        this.dragging = false;
        this.setVisibility(ctx, viewportArgs, viewportGroup);
        this.centerLines.forEach((l) => l.destroy());
        this.centerLines = [];
        this.layer.batchDraw();
      });

      this.setVisibility(ctx, viewportArgs, viewportGroup);
      this.layer.add(viewportGroup);
    }

    this.layer.batchDraw();
  }

  drawOutline(ctx, viewportArgs, layerGroup) {
    let viewportState = {
      x: 0,
      y: 0,
      width: viewportArgs.width,
      height: viewportArgs.height,
    };
    viewportState.fill = false;
    viewportState.listening = false;
    let rectElm = new CanvasRectElement(viewportState);
    rectElm.addToCanvas(layerGroup);

    return rectElm;
  }

  drawCenterLines(ctx, viewportArgs, layerGroup) {
    let viewportState = { x: 0, y: 0, width: viewportArgs.width, height: viewportArgs.height };

    let leftCenter = [viewportState.x + 1, viewportState.y + (viewportState.height / 2)];
    let rightCenter = [viewportState.x + viewportState.width - 1, viewportState.y + (viewportState.height / 2)];
    let center = [viewportState.x + (viewportState.width / 2), viewportState.y + (viewportState.height / 2)];
    let centerTop = [viewportState.x + (viewportState.width / 2), viewportState.y + 1];
    let centerBottom = [viewportState.x + (viewportState.width / 2), viewportState.y + viewportState.height - 1];

    this.drawLine(leftCenter, [center[0] - 10, center[1]], layerGroup);
    this.drawLine([center[0] + 10, center[1]], rightCenter, layerGroup);
    this.drawLine(centerTop, [center[0], center[1] - 10], layerGroup);
    this.drawLine([center[0], center[1] + 10], centerBottom, layerGroup);
  }

  drawLine(p1, p2, layerGroup) {
    let line = new Konva.Line({
      points: [p1[0], p1[1], p2[0], p2[1]],
      stroke: 'yellow',
      strokeWidth: 1,
      lineCap: 'round',
      lineJoin: 'round'
    });

    this.centerLines.push(line);
    layerGroup.add(line);
  }

  drawCrop(p1, p2, layerGroup) {
    let line = new Konva.Line({
      points: [p1[0], p1[1], p2[0], p2[1]],
      stroke: 'yellow',
      strokeWidth: 1,
      lineCap: 'round',
      lineJoin: 'round'
    });

    this.centerLines.push(line);
    layerGroup.add(line);
  }

  setVisibility(ctx, viewportArgs, viewportGroup) {
    let isShown = ViewportService.isViewportShown(ctx.state, viewportArgs.layer);
    viewportGroup.setVisible(isShown || this.hovering || this.dragging);
  }
};
