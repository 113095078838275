import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { COMPONENT_MOUNTED } from '../../../ProductPublishActions';
import { filterOptionsSelector } from '../ProductVariants/ProductVariantsSelector';
import { setInfo } from './ProductImageActions';
import { delay } from 'redux-saga';
import {
  CHANGE_PRODUCT_OPTION,
  ADD_PRODUCT_OPTION,
  REMOVE_PRODUCT_OPTION
} from '../ProductOptions/ProductOptionsActions';
import { SET_PROVIDER } from '../../ProvidersTab/ProvidersTabActions';

export function* componentMountedHandler() {
  yield call(delay, 300);

  try {
    const options = yield select(filterOptionsSelector);
    let info = '';
    options.forEach(option => {
      info += `${option.get('values').size} ${option.get('title').toLowerCase()} | `;
    });

    yield put(setInfo(info.slice(0, info.length - 3)));
  } catch (error) {}
}

export function* watchComponentMount() {
  yield takeLatest(
    [
      COMPONENT_MOUNTED,
      CHANGE_PRODUCT_OPTION,
      ADD_PRODUCT_OPTION,
      REMOVE_PRODUCT_OPTION,
      SET_PROVIDER
    ],
    componentMountedHandler
  );
}

export default function* rootSaga() {
  yield all([watchComponentMount()]);
}
