export const initialState = {
  images: {
    all: {
      loaded: false,
      images: []
    },
    landscape: {
      loaded: false,
      images: []
    },
    portrait: {
      loaded: false,
      images: []
    },
    square: {
      loaded: false,
      images: []
    }
  },
  loadingImagesCount: 0,
  filter: 'all',
  sort: '',
  spaces: []
};
