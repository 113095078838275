import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import Button from 'gooten-components/src/components/shared/Button';
import RadioField from 'gooten-components/src/components/shared/RadioField';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import { getProductImageResizerUrl } from 'gooten-js-utils/src/url';
import { Formik } from 'formik';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';
import Icon from 'gooten-components/src/components/shared/Icon';
import { ableToEditShipping, ableToEditShippingStatuses } from '../../OrderDetailsSelectors';
import { fromJS } from 'immutable';

const ShippingMethodCard = props => {
  const {
    summaryData: { CanEditShippingAddress },
    itemsData,
    shippingCostsData,
    orderId,
    updateShippingMethod
  } = props;

  const [openShippingMethod, setOpenShippingMethod] = useState(false);
  const [confirmShippingEdit, setConfirmShippingEdit] = useState(false);
  const [newShippingTotal, setNewShippingTotal] = useState(null);
  const [originalShippingTotal, setOriginalShippingTotal] = useState(null);
  const [currencyType, setCurrencyType] = useState('');

  const getOriginalCostsTotal = () => {
    // add up all original shipping costs
    let originalCosts = [];
    shippingCostsData.Result.forEach(shipGroup => {
      const matchingSKU = itemsData.find(x => shipGroup.SKUs.includes(x.SKU));
      const matchingMethod = shipGroup.ShipOptions.find(
        q => matchingSKU.ShipmentMethod === q.CarrierName
      );
      originalCosts.push(matchingMethod.Price.Price);
      setCurrencyType(matchingMethod.Price.CurrencyCode);
    });
    setOriginalShippingTotal(originalCosts.reduce((a, b) => a + b, 0));
  };

  const prePaymentStatuses = ableToEditShippingStatuses();

  // we need to check item statuses for each vendor group, and if at least one group
  // has statuses that are allowing editing shipping method, we have to enable Edit button...
  const isEditAllowed = fromJS(itemsData)
    .groupBy(x => x.get('VendorId'))
    .reduce((a, group) => a || ableToEditShipping(group.map(x => x.get('StatusId'))), false);

  return (
    <>
      <Card className="shipping-method-card">
        <div className="d-flex justify-content-between">
          <div className="title-2">Shipping Method</div>
          {!openShippingMethod && CanEditShippingAddress && isEditAllowed && (
            <Button onClick={() => setOpenShippingMethod(true)} className="button-default small">
              Edit
            </Button>
          )}
        </div>

        <div className="shipping-method-section mt-3">
          <>
            <div className="d-flex justify-content-between align-items-center">
              {!openShippingMethod && (
                <div>
                  {shippingCostsData.Result &&
                    shippingCostsData.Result.map((shipGroup, key) => {
                      const matchingSKU = itemsData.find(x => shipGroup.SKUs.includes(x.SKU));
                      return (
                        matchingSKU && (
                          <React.Fragment key={key}>
                            {shippingCostsData.Result.length > 1 && (
                              <div className="overline mt-3">Group {key + 1}</div>
                            )}
                            <div key={key}>
                              {matchingSKU.CarrierMethod || ''}
                              {matchingSKU.CarrierMethod && ' - '}
                              {matchingSKU.ShipmentMethod || ''}{' '}
                              {matchingSKU.CarrierMethod ? '' : 'Shipping'}
                              {shipGroup.ShipOptions.map(
                                (option, index) =>
                                  option.Name === matchingSKU.ShipmentMethod && (
                                    <div className="caption-text" key={index}>
                                      {option.Price.FormattedPrice}
                                    </div>
                                  )
                              )}
                            </div>
                          </React.Fragment>
                        )
                      );
                    })}
                </div>
              )}
            </div>

            {shippingCostsData && openShippingMethod && (
              <Formik
                initialValues={{
                  OrderId: orderId,
                  ShipmentMethods: shippingCostsData.Result.map((sGroup, y) => {
                    const matchingSKU = itemsData.find(x => sGroup.SKUs.includes(x.SKU));
                    const matchingShippingOption = sGroup.ShipOptions.find(
                      x => x.Name === matchingSKU.ShipmentMethod
                    );
                    return {
                      OrderItemIds: itemsData
                        .filter(x => sGroup.SKUs.includes(x.SKU))
                        .map(z => z.Item),
                      NewShippingMethod: matchingSKU.ShipmentMethod,
                      NewShippingMethodId: matchingShippingOption.Id,
                      NewShippingCost: matchingShippingOption.Price
                        ? matchingShippingOption.Price.Price
                        : null
                    };
                  })
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const data = {
                    orderId: orderId,

                    ShipmentMethods: values.ShipmentMethods
                  };
                  setConfirmShippingEdit(false);
                  setOpenShippingMethod(false);
                  updateShippingMethod(data);
                }}
              >
                {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    {shippingCostsData.Result.map((shipGroup, key) => (
                      <div key={key}>
                        {shippingCostsData.Result.length > 1 && (
                          <div className="title-3 mt-3">Group {key + 1}</div>
                        )}
                        <div className="d-flex justify-content-between">
                          <div className="w-50">
                            {itemsData.map((item, index) => {
                              if (shipGroup.SKUs.includes(item.SKU)) {
                                return (
                                  <div key={index} className="mb-3 mt-3 d-flex">
                                    <Thumbnail
                                      image={
                                        item.Images[0].FileName ||
                                        getProductImageResizerUrl(item.Images[0].SourceUrl)
                                      }
                                    />
                                    <div className="ml-2 mr-3 w-75 caption-text sku-name">
                                      {item.SKU}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div className="w-50">
                            {/*Check if any item in shipping group is NOT in prepayment status before
                            allowing change */}
                            {!shipGroup.SKUs.map(sg =>
                              prePaymentStatuses.includes(
                                itemsData.find(z => z.SKU === sg).StatusId
                              )
                            ).includes(false)
                              ? shipGroup.ShipOptions.map((option, index) => (
                                  <div className="mt-3" key={index}>
                                    <RadioField
                                      onClick={() => {
                                        setFieldValue(
                                          `ShipmentMethods[${key}].NewShippingMethodId`,
                                          option.Id
                                        );
                                        setFieldValue(
                                          `ShipmentMethods[${key}].NewShippingCost`,
                                          option.Price.Price
                                        );
                                        setFieldValue(
                                          `ShipmentMethods[${key}].NewShippingMethod`,
                                          option.Name
                                        );
                                      }}
                                      checked={
                                        values.ShipmentMethods[key].NewShippingMethod ===
                                        option.Name
                                      }
                                      label={option.Name}
                                      secondaryText={option.Price.FormattedPrice}
                                    />
                                  </div>
                                ))
                              : // show only disabled radio field with no options for items already sent to vendor
                                shipGroup.ShipOptions.map((option, index) => (
                                  <div className="mt-3" key={index}>
                                    {values.ShipmentMethods[key].NewShippingMethod ===
                                      option.Name && (
                                      <div className="body-text-2 heavy">
                                        <RadioField
                                          disabled
                                          label={option.Name}
                                          secondaryText={option.Price.FormattedPrice}
                                          checked={
                                            values.ShipmentMethods[key].NewShippingMethod ===
                                            option.Name
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                          </div>
                        </div>
                        {shippingCostsData.Result.length > 1 && <hr className="micro" />}
                      </div>
                    ))}
                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        className="mr-2 button-default large cancel-button"
                        onClick={() => setOpenShippingMethod(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="button-primary large submit-button"
                        onClick={() => {
                          setConfirmShippingEdit(true);
                          // generate old and new shipping costs and display to user
                          // for the confimation modal
                          getOriginalCostsTotal();
                          setNewShippingTotal(
                            values.ShipmentMethods
                              ? values.ShipmentMethods.map(j => j.NewShippingCost).reduce(
                                  (a, b) => a + b,
                                  0
                                )
                              : 0
                          );
                        }}
                      >
                        Update
                      </Button>
                      {confirmShippingEdit && (
                        <Dialogue
                          isOpen
                          cancelText="Cancel"
                          cancelClick={() => setConfirmShippingEdit(false)}
                          actionText="Update shipping"
                          primaryClick={() => handleSubmit()}
                          title="Are you sure you want to update shipping method?"
                        >
                          {originalShippingTotal && newShippingTotal && (
                            <>
                              <div className="overline mt-3">Price Change</div>
                              <div className="body-text-1 mt-2 d-flex align-items-center">
                                <span>
                                  {currencyType === 'USD' && '$'}
                                  {originalShippingTotal.toFixed(2)}{' '}
                                </span>
                                <Icon className="ml-3 mr-3" fill="#7F8994" icon="arrowRight" />{' '}
                                <b>
                                  {currencyType === 'USD' && '$'}
                                  {newShippingTotal.toFixed(2)}
                                </b>
                              </div>
                            </>
                          )}
                        </Dialogue>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </>
        </div>
      </Card>
      <style jsx>
        {`
          :global(.shipping-method-card.card) {
            min-height: auto !important;
          }
          .sku-name {
            word-break: break-word;
          }
        `}
      </style>
    </>
  );
};

ShippingMethodCard.propTypes = {
  summaryData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  itemsData: PropTypes.array,
  shippingCostsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  updateShippingMethod: PropTypes.func
};

export default ShippingMethodCard;
