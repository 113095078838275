import { List } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import RadioButtonGroup from 'gooten-components/src/components/shared/RadioButtonGroup';
import { pendingTimePatern } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div`
  .panel .panel-body {
    h4.card-title {
      margin-bottom: 8px;
    }
    h5.card-section-title {
      margin-bottom: 4px;
    }
  }

  .text-input-container.form-group {
    min-width: 226px;
  }
`;

const enabled = 'enable';
const disabled = 'disable';
const radioButtonOptions = new List([enabled, disabled]);

class FulfillmentSettings extends React.Component {
  static get name() {
    return 'fulfillmentSettings';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Fulfillment Settings',
      onFailure: 'Failed to update Fulfillment Settings',
      onSuccess: 'Your Fulfillment Settings has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
  }

  componentDidMount() {
    this.props.init();
  }

  get canUpdate() {
    return (
      this.props.ready &&
      this.props.data &&
      !this.props.data.equals(this.props.unmodified) &&
      Object.values(this.inputs).every(i => !i || (i.validate(), i.isValid()))
    );
  }

  render() {
    return (
      <Container>
        <BaseCard card={FulfillmentSettings.name} title="Fulfillment">
          <div>
            <div className="row">
              <div className="col-xs-12">
                <h5 className="card-section-title">Hold Items For Personalization</h5>
                <br />
                <p className="card-description">
                  Hold orders from your connected stores so that custom images can be uploaded
                  before they are sent to production.
                </p>
                <RadioButtonGroup
                  items={radioButtonOptions}
                  disabled={!this.props.ready}
                  selectionIndex={this.props.data.get('CustomizationSettings') ? 0 : 1}
                  onChange={value => this.props.change(value === enabled, 'CustomizationSettings')}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-xs-12">
                <h5 className="card-section-title">Pending Time</h5>
                <br />
                <p className="card-description">
                  The amount of time orders will wait in Pending status before being sent to
                  production.
                </p>
                <div className="row">
                  <div className="col-md-4 col-xs-12">
                    <TextInput
                      id="PendingTimeDuration"
                      showValidation
                      regexp={pendingTimePatern}
                      validationMsg="Value must be in range 0.01-99.99"
                      label="Time (in hours)"
                      placeholder="Hours"
                      onChange={(v, id) => this.props.change(v, id)}
                      ref={ref => {
                        this.inputs['PendingTimeDuration'] = ref;
                      }}
                      value={this.props.data.get('PendingTimeDuration') || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="button-primary large"
            disabled={!this.canUpdate}
            onClick={() => this.props.update({ data: this.props.data })}
          >
            Update fulfillment settings
          </Button>
        </BaseCard>
      </Container>
    );
  }
}

FulfillmentSettings.propTypes = {
  init: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  data: PropTypes.object,
  unmodified: PropTypes.object,
  update: PropTypes.func.isRequired,
  ready: PropTypes.bool
};

export default FulfillmentSettings;
