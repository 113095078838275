import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FulfillmentSettings from '../Cards/FulfillmentSettings';
import ReturnAddress from '../Cards/ReturnAddress';
import security from '../SecurityService';
const Container = styled.div``;
class FulfillmentView extends React.Component {
  render() {
    return security.access('page', 'fulfillment') ? (
      <Container>
        <FulfillmentSettings />
        <ReturnAddress />
      </Container>
    ) : null;
  }
}

FulfillmentView.propTypes = {
  // eslint-disable-next-line
  router: PropTypes.object.isRequired
};

export default FulfillmentView;
