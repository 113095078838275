// @flow

import { Stage, FastLayer, Rect } from 'konva';
import TemplateSurfaceLayer from '../template/template.layer';
import ImageSurfaceLayer from '../image/image.layer';
import type { KonvaStage } from '../../flow/konvaTypes';
import PositioningService from '../../utils/positioningService';

export default class CanvasSurface {
  orientation: string; // portrait | landscape,
  width: number;
  height: number;
  container: any;
  stage: KonvaStage;

  constructor(width:number, height:number, container:any) {
    this.width = width;
    this.height = height;
    this.container = container;
    this.orientation = 'landscape';

    this.stage = new Stage({
      container: this.container,
      width: width,
      height: height
    });

    this.layersHash = {};
  }

  redraw(options) {
    // Render Canvas Surface from the given options
    this.setOrientation(this.orientation);

    this.width = options.width;
    this.height = options.height;
    // Serg: commented out during perf optmization
    // since it's redundant
    // this.stage.width(options.width);
    // this.stage.height(options.height);
    this.drawBackground(options);

    // do nothing if template not loaded
    if (!options.template.layers.length) {
      return;
    }

    // sort layers by z index
    let renderLayers = options.template.layers.sort((a, b) => a.zindex - b.zindex);
    let imageLayerDrawed = false;

    // NOTE: Only render
    for(let l of renderLayers) {
      if (l.type !== 'image' && options.preview === 'product') {
        const templateParams = Object.assign({backgroundColor : options.backgroundColor || '#FFFFFF', preview: options.preview}, l,
          PositioningService.convertToCanvasSize({...l, x: l.x1, y: l.y1}, options)
        );
        this.drawLayer(templateParams, 'template_layer_' + l.id, TemplateSurfaceLayer);
      }
      else if (!imageLayerDrawed) {
        this.drawLayer(options, 'image_layer', ImageSurfaceLayer);
      }

    }

    options.onRedraw();
  }

  drawBackground(options) {
    let existing = this.backgroundLayer;
    if (existing) {
      this.backgroundRect.fill(options.backgroundColor);
      // Serg: commented out during perf optmization
      // since it's redundant
      // this.backgroundRect.width(options.width);
      // this.backgroundRect.height(options.height);
      existing.batchDraw();
    }
    else {
      let newLayer = new FastLayer();
      this.backgroundRect = new Rect({x: 0, y: 0, width : options.width, height : options.height, fill : options.backgroundColor});
      newLayer.add(this.backgroundRect);
      this.stage.add(newLayer);
      newLayer.batchDraw();
      this.backgroundLayer = newLayer;
    }
  }

  drawLayer(layerOptions: any, layerId: string, Type: any) {
    let existingLayer = this.layersHash[layerId];
    if (existingLayer) {
      existingLayer.draw(layerOptions);
    }
    else {
      let newLayer = new Type(layerId);
      newLayer.addToCanvas(this.stage);
      newLayer.draw(layerOptions);
      this.layersHash[layerId] = newLayer;
    }
  }

  setOrientation(orientation) {
    if (this.orientation === orientation) {
      return;
    }
    this.orientation = orientation;

    if (orientation === 'portrait') {
      this.orientation = orientation;
      this.stage.rotation(90);
      let w = this.stage.width();
      let h = this.stage.height();
      this.stage.width(h);
      this.stage.height(w);
      this.stage.x(this.stage.width());
    } 
    else {
      this.orientation = orientation;
      this.stage.rotation(0);
      this.stage.x(0);
      let w = this.stage.width();
      let h = this.stage.height();
      this.stage.width(h);
      this.stage.height(w);
    }

    this.stage.batchDraw();
  }

  destroy() {
    Object.values(this.layersHash).forEach(function(l) {
      // TODO: use CanvasLayer wrapper for all layers
      if (l.layer.layer) {
        l.layer.destroy();
      }
      else {
        l.layer.destroyChildren();
        l.layer.destroy();
      }
    });
    this.layersHash = {};

    if (this.backgroundLayer) {
      this.backgroundLayer.destroyChildren();
      this.backgroundLayer.destroy();
      this.backgroundLayer = null;
    }

    if (this.stage) {
      this.stage.destroyChildren();
      this.stage.destroy();
      this.stage = null;
    }

    this.container.innerHTML = '';
  }
}
