import { post } from '../../../../utils/http';
import Config from '../../../../config';

class ShippingTimeService {
  fetchShippingTimeData(query) {
    return post(
      `${Config.get('api')}shippingprices/?recipeid=${Config.get('recipeId')}`,
      JSON.stringify(query),
      {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      }
    );
  }
}

export default new ShippingTimeService();
