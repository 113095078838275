import { connect } from 'react-redux';
import VariantsSelector from './VariantsSelection';
import { skipMapping } from '../../SKUsSelectionViewActions';
import {
  variantsMappingsSelector,
  currentVariantIndexSelector
} from '../../SKUsSelectionViewSelectors';
import { setCurrentVariantIndex } from './VariantsSelectionActions';
import { selectedStoreProductSelector } from '../../../LinkProductSelectors';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/
const mapDispatchToProps = {
  setCurrentVariantIndex: index => setCurrentVariantIndex(index),
  skip: () => skipMapping()
};

const mapStateToProps = state => {
  return {
    variants: variantsMappingsSelector(state),
    currentVariantIndex: currentVariantIndexSelector(state),
    storeProductName: selectedStoreProductSelector(state).get('name')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantsSelector);
