import React, { useState, useEffect } from 'react';
import SKUsListItem from './SKUsListItem';
import Pager from 'gooten-components/src/components/shared/Pager';
import SimpleNotification from '../../../../shared/SimpleNotification';
import Multiselect from 'gooten-components/src/components/shared/Multiselect';
import skusListService from './../services/skusListService';
import { Cell, TableRow } from 'gooten-components/src/components/shared/Table';
import Loader from 'gooten-components/src/components/shared/Loader';

const multiselectConfig = {
  multiple: true,
  showFilter: true,
  showBulkActions: false
};

const SKUsList = ({
  suggestedVariants,
  allVariants,
  pageVariants,
  options,
  setOptionSelectedValues,
  page,
  pages,
  changePage,
  mappingProgress,
  select,
  next,
  resetNotification,
  skuNotification,
  disableFiltering,
  selectedOptionsLabel
}) => {
  const filteredAllVariants = pageVariants.filter(item => item.get('sectionAll'));
  const filteredSuggestedMatches = pageVariants.filter(item => item.get('sectionSuggested'));
  const columnsLength =
    options && options.size
      ? options
          .map(o => {
            return '1fr';
          })
          .join(' ')
          .toString()
      : '1fr';

  const onOptionValuesSelect = (o, selection, isSuggested) => {
    setOptionSelectedValues(
      o.get('id'),
      selection.map(s => ({ id: s.key, name: s.label, isSuggested })),
      isSuggested
    );
  };

  const handleSelect = sku => {
    select(sku, selectedOptionsLabel);
    next();
  };

  const renderNotification = () => {
    return (
      <div>
        {skuNotification.get('show') && (
          <SimpleNotification
            title={`${skuNotification.get('value', '')} was successfully synced`}
            duration={2000}
            onDurationEnd={() => resetNotification()}
          />
        )}
      </div>
    );
  };

  const renderPager = () => {
    return (
      !!pages.length && (
        <div className="col-xs-12 text-center">
          <Pager current={page} items={pages} goTo={index => changePage(index)} />
        </div>
      )
    );
  };

  const renderBody = skus => {
    return (
      <>
        {skus.map(sku => (
          <SKUsListItem key={sku.get('sku')} item={sku} options={options} select={handleSelect} />
        ))}
      </>
    );
  };

  const [headAll, setHeadAll] = useState(null);
  const [headSuggested, setHeadSuggested] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    // prepare head to be rendered...
    // for suggested variants....
    const suggestedPromise = prepareHead(disableFiltering, true);
    // for all variants...
    const allVarsPromise = prepareHead();

    Promise.all([suggestedPromise, allVarsPromise]).then(values => {
      setHeadSuggested(values[0]);
      setHeadAll(values[1]);
      setIsLoading(false);
    });
  }, [options, suggestedVariants, allVariants]);

  const prepareHead = (disableFiltering = false, isSuggested = false) => {
    return new Promise((resolve, reject) => {
      const preparedOptions = skusListService.preparedOptionsFromVariants(
        isSuggested ? suggestedVariants : allVariants
      );

      const optionElements = options.map(o => {
        const values = skusListService.valuesForOption(o, preparedOptions);
        const selectedValues = o
          .get('selectedValues')
          .filter(sv => !!sv.get('isSuggested') === isSuggested)
          .map(v => ({ key: v.get('id'), label: v.get('name') }))
          .toJS();

        return disableFiltering ? (
          <Cell header className="selection-column no-multiselect" key={'h-' + o.get('id')}>
            <div className="col-xs-11">
              <b>{o.get('name')}</b>
            </div>
          </Cell>
        ) : (
          <Cell header className="selection-column" key={'h-' + o.get('id')}>
            <Multiselect
              title={o.get('name')}
              options={values}
              selectedOptions={selectedValues}
              onChange={s => onOptionValuesSelect(o, s, isSuggested)}
              config={multiselectConfig}
            />
          </Cell>
        );
      });

      resolve(
        <>
          <Cell header className="hidden-sm hidden-xs" />
          {optionElements}
          <Cell header className="price-column hidden-sm hidden-xs">
            <div className="col-xs-11">
              <b>Price</b>
            </div>
          </Cell>
          <style jsx>
            {`
              :global(.selection-column .multiselect-component) {
                width: 100%;
              }
            `}
          </style>
        </>
      );
    });
  };

  const renderSection = title => {
    return <div className="title-2 mt-4 mb-4">{title}</div>;
  };

  // The logic below makes sure that dropdown options are available even when incompatible options are picked.
  // e.g. when bandanas are selected as SQUARE and 25x18in, this would not work.
  // The < 8 logic is so that header row does not show up alone for large lists
  const suggestedMatches =
    suggestedVariants.size && filteredAllVariants.size < 8 ? (
      <>
        {renderSection('SUGGESTED MATCHES:')}

        {/* desktop view */}
        <div className="hidden-sm hidden-xs">
          <TableRow size="small" columns={`6rem ${columnsLength} 8rem`}>
            {headSuggested}
            {renderBody(filteredSuggestedMatches)}
          </TableRow>
        </div>
        {/* mobile view */}
        <div className="visible-sm visible-xs">
          <TableRow size="small" columns={`1fr 1fr`}>
            {headSuggested}
          </TableRow>
          <TableRow size="auto" columns={`6rem 1fr`}>
            {renderBody(filteredSuggestedMatches)}
          </TableRow>
        </div>
      </>
    ) : null;

  const allVariantsElements =
    allVariants.size && filteredSuggestedMatches.size < 8 ? (
      <>
        {renderSection('ALL VARIANTS')}
        {/* desktop view */}
        <div className="hidden-sm hidden-xs">
          <TableRow size="small" columns={`6rem ${columnsLength} 8rem`}>
            {headAll}
            {renderBody(filteredAllVariants)}
          </TableRow>
        </div>

        {/* mobile view */}
        <div className="visible-sm visible-xs">
          <TableRow size="small" columns={`1fr 1fr`}>
            {headAll}
          </TableRow>
          <TableRow size="auto" columns={`6rem 1fr`}>
            {renderBody(filteredAllVariants)}
          </TableRow>
        </div>
      </>
    ) : null;
  const progress = mappingProgress;

  return (
    <div className="skus-list-container">
      <div className="col-sm-12 col-xs-12 table-container mb-4">
        <div className="table">
          {suggestedMatches}
          {allVariantsElements}
        </div>
        {progress.selected === progress.total && !progress.canUpdate ? (
          <div className="shaddow" />
        ) : null}
        {renderPager()}
      </div>
      {renderNotification()}
      {isLoading ? <Loader /> : null}
      <style jsx>
        {`
          :global(.selection-column.header-cell) {
            padding: 0.25rem 0.125rem 0.5rem 0.125rem !important;
          }
        `}
      </style>
    </div>
  );
};

export default SKUsList;
