import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import StoreBulkCreation from './index';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';

const storeBulkCreationRoutes = {
  path: '/store-bulk-creation',
  component: CoreLayout,
  indexRoute: { component: withErrorBoundary(StoreBulkCreation) }
};

export default storeBulkCreationRoutes;
