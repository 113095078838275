import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import Config from 'gooten-components/src/config';

const reprintStateSelector = state => state.get('reprintSelfService');

export const reprintReasonsSelector = createSelector([reprintStateSelector], state => {
  if (Config.get('hasOwnShippingAcccount')) {
    // https://gooten.atlassian.net/browse/TECH-7312
    // For partners who has their own shipping account (Sam's club)
    // we hide 'Broken/Damaged' reprint/refund reason
    return state.get('allReasons').filter(r => r.get('ReasonName') !== 'Broken/Damaged');
  } else {
    return state.get('allReasons');
  }
});

export const selectedRulesSelector = createSelector([reprintStateSelector], state =>
  state.get('selectedRules')
);

export const imagesEvidenceSelector = createSelector([reprintStateSelector], state =>
  state.get('imagesEvidence')
);

export const reprintDataSelector = createSelector([reprintStateSelector], state =>
  state.get('reprintData')
);

export const orderSummarySelector = createSelector([reprintStateSelector], state =>
  state.get('orderSummary')
);

export const partnerDetailsSelector = createSelector([reprintStateSelector], state =>
  state.get('partnerDetails')
);

export const partnerPreferencesSelector = createSelector([reprintStateSelector], state =>
  state.get('partnerPreferences')
);

export const toastTextSelector = createSelector([reprintStateSelector], state =>
  state.get('toastText')
);

export const selectedReasonSelector = createSelector([reprintStateSelector], state =>
  state.get('selectedReason')
);

export const showLoadingSelector = createSelector([reprintStateSelector], state =>
  state.get('loading')
);

export const shipmentGroupsSelector = createSelector([reprintDataSelector], data => {
  const groups = [];
  data.get('items') &&
    data.get('items').forEach(item => {
      const itemJS = item.toJS();
      if (!groups.length) {
        groups.push({ vendorId: itemJS.VendorId, items: [itemJS] });
      } else {
        groups.find(group =>
          group.vendorId === itemJS.VendorId
            ? group.items.push(itemJS)
            : groups.push({ vendorId: itemJS.VendorId, items: [itemJS] })
        );
      }
    });

  return fromJS(groups);
});

export const submitReprintRequestSelector = createSelector(
  [
    orderSummarySelector,
    selectedRulesSelector,
    partnerPreferencesSelector,
    reprintDataSelector,
    imagesEvidenceSelector
  ],
  (orderSummary, selectedRules, partnerPreferences, reprintData, images) => {
    // items supported for reprint by specific vendors...
    const supportedItems = reprintData.get('items').filter(item => item.get('supported'));

    const createItemImages = images =>
      images.map((image, i) => ({
        ImageId: image.Id,
        Index: i,
        ManipCommand: image.ManipCommand,
        SpaceId: image.SpaceId,
        Url: image.SourceUrl
      }));

    const orderMeta = {
      AdminComments: '',
      IsReprint: 'true',
      CurrentUser: `Partner ID: ${orderSummary.get('PartnerInternalId')}`,
      ImageEvidence: images.join(', '),
      ReprintAgent: 'Reprint Self Service',
      ReprintItems: supportedItems.map(item => item.get('Item')).join(', '),
      ordered_via: 'reprint self service',
      original_order_number: reprintData.get('orderId'),
      reordered_via: 'reprint self service',
      original_external_order_id: orderSummary.get('PartnerId')
    };

    // add packing slips if exist...
    if (orderSummary.getIn(['Meta', 'packing_slip_url'])) {
      orderMeta['packing_slip_url'] = orderSummary.getIn(['Meta', 'packing_slip_url']);
    }

    return {
      BillingAddress: orderSummary.get('BillingAddress'),
      IsPreSubmit: false,
      Items: supportedItems.map(item => {
        const rules = selectedRules.filter(rule => rule.get('VendorId') === item.get('VendorId'));
        const addOns = {};
        const meta = { ReprintOf: item.get('Item') };

        if (item.getIn(['Meta', 'addon_necktag_image_url'])) {
          addOns['necktag_image_url'] = item.getIn(['Meta', 'addon_necktag_image_url']);
          meta['necktag_id'] = item.getIn(['Meta', 'necktag_id']);
        }

        return {
          Covering: {
            ItemBy: rules
              ?.find(rule => rule.get('CostType')?.toLowerCase() === 'item')
              ?.get('WhoCovers')
              ?.toLowerCase(),
            // https://gooten.atlassian.net/browse/TECH-7237
            // For partners that have their own shipping account (Sam's Club)
            // we default to Vendor covers shipping to set shipping costs to 0.
            ShippingBy: Config.get('hasOwnShippingAcccount')
              ? 'vendor'
              : rules
                  ?.find(rule => rule.get('CostType')?.toLowerCase() === 'shipping')
                  ?.get('WhoCovers')
                  ?.toLowerCase()
          },
          Images: createItemImages(item.get('Images').toJS()),
          Quantity: item.get('Quantity'),
          SKU: item.get('SKU'),
          ShipCarrierMethodId: 1,
          Meta: meta,
          VendorId: item.get('VendorId'),
          AddOns: addOns
        };
      }),
      Meta: orderMeta,
      Payment: {
        CurrencyCode: partnerPreferences.get('currencyCode'),
        PartnerBillingKey: Config.get('storeApiKey'), // partnerDetails.get('ApiKey'),
        AgentBillingKey: window.localStorage.token // Config.get('adminApiToken')
      },
      Reprint: {
        IsBilledTo: 1
      },
      ShipToAddress: orderSummary.get('ShippingAddress'),
      SourceId: `${reprintData.get('orderId')}-R`
    };
  }
);
