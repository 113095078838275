import { getActionName } from '../../../CheckoutActions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const SET_USER_ENTERED_SHIPPING_ADDRESS = getActionName('SET_USER_ENTERED_SHIPPING_ADDRESS');
export const SET_SUGGESTED_ADDRESS = getActionName('SET_SUGGESTED_ADDRESS');
export const DELETE_SUGGESTED_ADDRESS = getActionName('DELETE_SUGGESTED_ADDRESS');
export const STORE_TEMP_ADDRESS = getActionName('STORE_TEMP_ADDRESS');
export const CLEAR_TEMP_ADDRESS = getActionName('CLEAR_TEMP_ADDRESS');

export function setUserEnteredShippingAddress(address) {
  return {
    type: SET_USER_ENTERED_SHIPPING_ADDRESS,
    payload: {
      address
    }
  };
}

export function setSuggestedAddress(suggestedAddress) {
  return {
    type: SET_SUGGESTED_ADDRESS,
    payload: {
      suggestedAddress
    }
  };
}

export function deleteSuggestedAddress() {
  return {
    type: DELETE_SUGGESTED_ADDRESS
  };
}

export function storeTempAddress(tempAddress) {
  return {
    type: STORE_TEMP_ADDRESS,
    payload: {
      tempAddress
    }
  };
}

export function clearTempAddress() {
  return {
    type: CLEAR_TEMP_ADDRESS
  };
}
