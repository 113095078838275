import { connect } from 'react-redux';
import BillingHistory from './BillingHistory';
import BillingAddress from '../BillingAddress/BillingAddress';
import { initFor, changePageFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';

const init = initFor(BillingHistory.name);
const changePage = changePageFor(BillingHistory.name);
const cardSelector = cardSelectorFor(BillingHistory.name);
const billingAddressSelector = cardSelectorFor(BillingAddress.name);

const mapDispatchToProps = {
  init,
  changePage
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    partnerDetails: billingAddressSelector(state).get('data'),
    data: cardState.get('data'),
    page: cardState.get('page'),
    pageSize: cardState.get('pageSize'),
    total: cardState.get('total')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
