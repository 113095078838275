class WindowService {
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  scrollSidePanelToTop() {
    document.getElementsByClassName('panel-content')[0].scrollTop = 0;
  }
}

export default new WindowService();
