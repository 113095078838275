import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import Icon from 'gooten-components/src/components/shared/Icon';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';

const ShipmentView = props => {
  useEffect(() => {
    props.getShipmentData(props.params.id);
  }, [props.params.id]);
  const [copyButtonText, setCopyButtonText] = useState('Copy');

  const getCarrierImage = carrier => {
    const assetUrl = 'https://appassets.azureedge.net/admin-assets';
    return (carrier || '')
      .toLowerCase()
      .split(';')
      .map(c => {
        switch (c) {
          case 'dpd':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/dpd.jpg`} />;
          case 'fedex':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/fedex.png`} />;
          case 'interlink':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/interlink.png`} />;
          case 'parcel force':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/parcelforce.gif`} />;
          case 'royal':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/royalmail.png`} />;
          case 'tnt':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/TNT.png`} />;
          case 'ups':
            return <img className="carrier-image" alt={c} src={`${assetUrl}/ups.gif`} />;
          default:
            return c;
        }
      });
  };

  const displayTrackingInfo = shipment => {
    if (shipment.TrackingNumber) {
      const trackingNumbers = shipment.TrackingNumber.split(';');
      const trackingUrls = shipment.TrackingUrl ? shipment.TrackingUrl.split(';') : [];

      return trackingNumbers.map(trackingNumber => {
        const trackingUrl = trackingUrls.filter(url => url.includes(trackingNumber));
        return (
          <React.Fragment key={trackingNumber}>
            {trackingNumber}
            {trackingUrl.length > 0 && (
              <a target="_blank" href={trackingUrl}>
                <Icon className="ml-1 mr-2" icon="externalLink" />
              </a>
            )}
          </React.Fragment>
        );
      });
    }
    return null;
  };

  async function copyToClipboard() {
    if (!navigator.clipboard) {
    } else if (props.shipmentData.length) {
      const text = props.shipmentData
        .map(sh => {
          return [
            `Item ID: ${sh.ItemId}\n`,
            `Package: ${sh.Package}\n`,
            `Ship Date: ${sh.ShipDate || ''}\n`,
            `Delivery Date: ${sh.DeliveryDate}\n`,
            `Tracking #: ${sh.TrackingNumber}\n`,
            `Status: ${sh.Status}\n`
          ].join('');
        })
        .join('\n');
      try {
        await navigator.clipboard.writeText(text);
        setCopyButtonText('Copied');
      } catch (err) {
        console.error('Failed to copy!', err);
      }
    }
  }

  return (
    <>
      {props.loading ? (
        <LoadingSpinner />
      ) : (
        <div className="shipment-tab mt-4">
          {props.shipmentData.length ? (
            <>
              <div className="title-row mb-4">
                <div className="title-2">Shipment</div>
              </div>
              <TableRow
                scrollableX
                columns="minmax(6rem, auto) minmax(5rem, auto) minmax(6rem, auto) minmax(6rem, auto) minmax(6rem, auto)
                minmax(10rem, auto) minmax(6rem, auto) minmax(6rem, auto)"
              >
                <Cell sticky header>
                  Item Id
                </Cell>
                <Cell header>Package</Cell>
                <Cell header>Provider</Cell>
                <Cell header>Ship Date</Cell>
                <Cell header>Delivery Date</Cell>
                <Cell header>Tracking</Cell>
                <Cell header>SKU</Cell>
                <Cell header>Status</Cell>

                {props.shipmentData.map((shipment, key) => (
                  <React.Fragment key={key}>
                    <Cell sticky>{shipment.ItemId}</Cell>
                    <Cell>{shipment.Package}</Cell>
                    <Cell>
                      <Tooltip content={shipment.Provider}>
                        {getCarrierImage(shipment.Provider)}
                      </Tooltip>
                    </Cell>
                    <Cell>{shipment.ShipDate}</Cell>
                    <Cell>{shipment.DeliveryDate}</Cell>
                    <Cell className="pt-1 pb-1">{displayTrackingInfo(shipment)}</Cell>
                    <Cell className="pt-1 pb-1">{shipment.SKU}</Cell>
                    <Cell>{shipment.Status}</Cell>
                  </React.Fragment>
                ))}
              </TableRow>
              <div className="shipment-actions d-flex align-items-center justify-content-center mt-5">
                {navigator.clipboard ? (
                  <Button
                    onClick={() => copyToClipboard()}
                    iconLeft="clipBoard"
                    className="m-1 button-default large"
                  >
                    {copyButtonText}
                  </Button>
                ) : null}
                <DropDownContainer title="Export" className="m-1">
                  <a
                    className="no-text-decoration"
                    href={`/admin/Orders/ExportShipments/${props.params.id}`}
                  >
                    <ListItem leftIcon="fileText" text="To CSV file" />
                  </a>
                </DropDownContainer>
                <Button
                  onClick={() => props.sendShippingConfirmation(props.params.id)}
                  iconLeft="mail"
                  className="button-default large m-1"
                >
                  Email
                </Button>
              </div>
            </>
          ) : (
            <div className="d-flex h-100">
              <div className="m-auto d-flex align-items-center p-5 body-text-2">
                <Icon className="mr-2" icon="archive" />
                <div className="justify-content-center">No shipments</div>
              </div>
            </div>
          )}
          <style jsx>
            {`
              .shipment-tab :global(.carrier-image) {
                max-height: 2rem;
                max-width: 3rem;
              }
              .shipment-actions {
                color: #777777;
              }
            `}
          </style>
        </div>
      )}
    </>
  );
};

ShipmentView.propTypes = {
  params: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  shipmentData: PropTypes.array.isRequired,
  getShipmentData: PropTypes.func.isRequired,
  sendShippingConfirmation: PropTypes.func.isRequired
};

export default ShipmentView;
