import React from 'react';
import PropTypes from 'prop-types';
import { usStatesIndex, caStatesIndex } from '../../../utils/states';
import TextInput from '../TextInput';
import Select from '../Select';

const mapToOptions = states => Object.keys(states).map(key => ({ key, label: states[key] }));

const statesOptions = {
  US: mapToOptions(usStatesIndex),
  CA: mapToOptions(caStatesIndex)
};

class CountryStateSelection extends React.PureComponent {
  handleTextInputChange(value) {
    this.props.update(value);
  }

  renderStateDropdown(options, statesIndex) {
    // const selectedOptions = this.getSelectedOptions(options)
    return (
      <div className={this.props.className}>
        {/* <div className='input-container'>
          <Multiselect
            title={this.props.placeholder || 'Select an Option'}
            options={options}
            selectedOptions={selectedOptions}
            onChange={(values) => this.handleMultiselectChange(values[0])}
            config={multiselectConfig} />
        </div> */}
        <Select
          label={this.props.label}
          placeholder="Select an Option"
          onChange={e => this.handleTextInputChange(e.target.value)}
          hasError={!this.props.valid}
          value={this.props.state}
        >
          {options.map(loc => (
            <option key={loc.key} value={loc.key}>
              {`${loc.key} - ${loc.label}`}
            </option>
          ))}
        </Select>
      </div>
    );
  }

  renderStateInput() {
    return (
      <TextInput
        {...this.props}
        id={this.props.id || 'state'}
        maxLength={50}
        label={this.props.label || 'State'}
        value={this.props.state}
        onChange={(val, i) => this.handleTextInputChange(val, i)}
      />
    );
  }

  render() {
    const options = statesOptions[this.props.countryCode];

    return options ? this.renderStateDropdown(options) : this.renderStateInput();
  }
}

CountryStateSelection.propTypes = {
  countryCode: PropTypes.string,
  state: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.bool,
  update: PropTypes.func.isRequired
};

export default CountryStateSelection;
