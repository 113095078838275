import { all, put, takeLatest } from 'redux-saga/effects';
import { GOTO_DESIGN_PAGE } from './DesignActions';
import { goto } from '../../../../containers/NavManager/NavManagerActions';

export function* gotoDesignPageHandler() {
  yield put(goto('ImageUpload'));
}

export function* watchGotoDesignPage() {
  yield takeLatest(GOTO_DESIGN_PAGE, gotoDesignPageHandler);
}

export default function* rootSaga() {
  yield all([watchGotoDesignPage()]);
}
