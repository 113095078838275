import { createSelector } from 'reselect';
import {
  optionsSelector,
  selectedOptionsSelector,
  selectedVariantsSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import {
  selectedProductsAttributes,
  allDetailedSkusSelector,
  selectedProductsSelector
} from '../../../ProductPublishSelectors';
import { fromJS, List } from 'immutable';
import { disabledSKUsSelector } from '../../../../../store/selectors/productDataSelectors';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import { selectedProductVariantsSelector } from '../../../../../store/selectors/hubSelectors';
import { createImmutableSelector } from '../../../../../utils/reselect';

export const filtersSelector = createImmutableSelector(
  [selectedOptionsSelector, optionsSelector],
  (selectedOptions, options) =>
    selectedOptions.map(sOpt => options.find(o => o.get('id') === sOpt.get('value')))
);

export const filterOptionsSelector = createSelector(
  [
    filtersSelector,
    selectedProductsAttributes,
    allDetailedSkusSelector,
    selectedVariantsSelector,
    selectedProductsSelector
  ],
  (filters, attrs, skus, selectedVariants, products) =>
    filters.map(filter => {
      const detailedSkus = skus
        .filter(sku => selectedVariants.map(v => v.get('index')).includes(sku.get('index')))
        .toJS();
      const optionId = products
        .getIn([0, 'details'])
        .product.options.find(o => o.title === filter.get('title')).id;
      const reducedAttrs = attrs.reduce((res, a) => {
        if (a.id === optionId) {
          res.push.apply(res, a.values);
        }
        return res;
      }, []);
      const uniqOptionIds = Array.from(new Set(reducedAttrs.map(attr => attr.id)));
      const uniqAttrs = uniqOptionIds.map(oId => reducedAttrs.find(a => a.id === oId));
      const values = Array.from(new Set(detailedSkus.map(sku => sku[optionId])));

      return fromJS({
        ...filter.toJS(),
        values: uniqAttrs.filter(val => values.indexOf(val.id) !== -1)
      });
    })
);

export const disabledSkusWithPreviewSelector = createSelector(
  [disabledSKUsSelector],
  disabledSkus => {
    return disabledSkus.map(dSku =>
      fromJS({
        ...dSku.toJS(),
        previewUrl: dSku.get('imageUrl') && getSmallPreviewImageResizerUrl(dSku.get('imageUrl')),
        sku: dSku.getIn(['gootenMapping', 'items', 0, 'sku']) || dSku.get('sku'),
        archivedReason: dSku.get('archivedReason')
      })
    );
  }
);
