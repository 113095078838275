import React from 'react';
import PropTypes from 'prop-types';
import UrlLink from '../UrlLink';
import ButtonType from '../ButtonType';
import Collapse from '../Collapse';
import Config from '../../../config';

class ActionCard extends React.Component {
  static propTypes = {
    id: PropTypes.number
  };

  render() {
    return (
      <div id={'ActionCard-' + this.props.id} className="card">
        <ActionCardContent {...this.props} id={'action-card-' + this.props.id} />
      </div>
    );
  }
}

class ActionCardContent extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    handleAnalytics: PropTypes.func
  };

  render() {
    const data = this.props.data;
    const actionWidgets = [];
    const textAlign = {
      textAlign: 'center',
      paddingTop: '1.5em',
      fontSize: '16px',
      fontWeight: 'bold'
    };
    const cbhSettings = Config.get('cbhSettings').toJS();

    for (var i = 0; i < data.actions.length; i++) {
      if (data.actions[i].type.toLowerCase() === 'button') {
        actionWidgets.push(
          <ButtonType
            key={'Button-' + i + data.actions[i].url}
            data={data.actions[i]}
            handleAnalytics={this.props.handleAnalytics}
          />
        );
      }

      if (data.actions[i].type.toLowerCase() === 'link') {
        actionWidgets.push(
          <span style={textAlign} key={'link-span-' + i}>
            <UrlLink
              key={'UrlLink-' + i + data.actions[i]}
              data={data.actions[i]}
              handleAnalytics={this.props.handleAnalytics}
            />
          </span>
        );
      }
    }

    const margin = { paddingLeft: '35px', paddingRight: '15px' };
    const padding = { paddingBottom: '20px' };
    const cardParagraphs = data.text.map((line, i) => {
      return <p key={i}>{line}</p>;
    });
    const paragraphPadding = { paddingRight: '35px' };
    return (
      <div className="card-content">
        <Collapse
          open={!this.props[data.monitors]}
          checked={this.props[data.monitors]}
          smallTitle
          title={data.title}
        >
          <div className="flex-container-column" style={margin}>
            <div className="flex-container" style={paragraphPadding}>
              <div>
                {cardParagraphs}
                <br />
              </div>
            </div>
            <div className="flex-container flex-wrap">
              <div className="flex-container-column" style={padding}>
                {actionWidgets}
              </div>
              {cbhSettings.Images.showSloth && (
                <div className="flex-inline-container flex-no-wrap flex-align-self-end">
                  <div className="border-block-img pull-right">
                    <div className={'img-responsive ' + data.img} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default ActionCard;
