import React from 'react';
import PropTypes from 'prop-types';
import Strings from './strings';
import Loader from '../Loader';
import analyticsService from '../../../services/analyticsService';
import Multiselect from '../Multiselect';
import Log from '../../../services/logService';
import './CountrySelection.scss';

import PageHeader from '../PageHeader';
import Button from '../Button';
import { CheckoutWithToast } from '../../Checkout/CheckoutContainer';
import PageBody from '../PageBody';

const multiselectConfig = {
  multiple: false,
  showFilter: true,
  showBulkAction: false
};

class CountrySelection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      country: props.shippingCountry
    };
  }

  onCountryChange = selected => {
    this.setState({
      country: selected[0] ? selected[0].code : ''
    });
  };

  onNextClick = () => {
    // update country only if it is changed
    // to avoid additional API calls for prices
    if (!this.props.isShippingCountrySet || this.props.shippingCountry !== this.state.country) {
      this.props.selectCountry(this.state.country);
      analyticsService._track('COF', 'Country Selected', null, this.state.country);
    }

    if (this.props.onNextClick) {
      this.props.onNextClick();
    }
  };

  componentDidMount() {
    if (!this.props.shippingCountries.length) {
      this.props.fetchShippingCountries();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.shippingCountries.length && this.state.country) {
      const isCountryValid = this.props.shippingCountries.find(c => c.code === this.state.country);
      if (!isCountryValid) {
        // passed country code is not valid
        // pre-select default US
        Log.warn(`Country code ${this.state.country} not found, reset to default US`);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          country: this.props.defaultCountryCode
        });
        this.props.selectCountry(this.props.defaultCountryCode);
      }
    }
  }

  getSelected() {
    if (this.props.shippingCountries.length && this.state.country) {
      const selected = this.props.shippingCountries.find(c => c.code === this.state.country);
      if (selected) {
        // return only if found
        return [selected];
      }
    }
    return [];
  }

  render() {
    const selected = this.getSelected();

    return !this.props.shippingCountries.length ? (
      <Loader />
    ) : (
      <div className="country-selection-container">
        <CheckoutWithToast />
        <PageHeader title={Strings.SHIPPING_COUNTRY} size="standard" />
        <PageBody background="none">
          <div className="mb-3 title-3">Where are you shipping to?</div>
          <Multiselect
            title="Select country"
            options={this.props.shippingCountries}
            selectedOptions={selected}
            onChange={this.onCountryChange}
            config={multiselectConfig}
          />
          <Button
            onClick={this.onNextClick}
            className="shipping-to-button button-primary mt-3 large"
          >
            Next: Choose a product
          </Button>
        </PageBody>
        <style jsx>
          {`
            .country-selection-container {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
            }
            :global(.shipping-to-button) {
              width: 20rem !important;
            }
          `}
        </style>
      </div>
    );
  }
}

CountrySelection.propTypes = {
  fetchShippingCountries: PropTypes.func.isRequired,
  selectCountry: PropTypes.func.isRequired,
  shippingCountries: PropTypes.array,
  defaultCountryCode: PropTypes.string.isRequired,
  onNextClick: PropTypes.func,
  shippingCountry: PropTypes.string,
  isShippingCountrySet: PropTypes.bool
};

export default CountrySelection;
