import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import {
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail
} from 'gooten-components/src/store/actions/dataActions';

export const getActionName = ActionNameFactory('ORDERS', 'BILLING_VIEW');

export const FETCH_BILLING_DATA = getActionName.async('FETCH_ORDER_ITEMS_COSTS_BREAKDOWN');

export const fetchBillingDataAsync = payload => fetchAsync(FETCH_BILLING_DATA, payload);
export const fetchBillingDataSuccess = payload => fetchAsyncSuccess(FETCH_BILLING_DATA, payload);
export const fetchBillingDataFail = () => fetchAsyncFail(FETCH_BILLING_DATA);
