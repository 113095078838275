import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageThumb from '../../../shared/ImageThumb';
import InputNumber from '../../../shared/InputNumber';
import { formatPrice } from '../../../../utils/price';
import './CartItem.scss';
import Checkbox from '../../../shared/Checkbox';
import Strings from './strings';
import analyticsService from '../../../../services/analyticsService';
import Button from '../../../shared/Button';
import Icon from '../../../shared/Icon';
import Alert from '../../../shared/Alert';

const CartItem = props => {
  useEffect(() => {
    setShowAlert(false);
    setTimeout(() => setShowAlert(true), 1200);
  }, [props.item]);

  const [showAlert, setShowAlert] = useState(false);

  const removeCartItem = () => {
    analyticsService.trackWithConfigSource('Cart Item Removed');
    props.onClickRemove();
  };

  const onQuantityChange = quantity => {
    if (quantity > props.item.get('quantity')) {
      analyticsService.trackWithConfigSource('Cart Item Quantity Increased');
    } else {
      analyticsService.trackWithConfigSource('Cart Item Quantity Decreased');
    }
    props.onQuantityChange(props.item, quantity);
  };

  const handleClickPreview = () => {
    props.onClickPreview(props.item);
  };

  const handleEditItem = () => {
    analyticsService.trackWithConfigSource('Cart Item Edit');
    props.onClickEdit();
  };

  const options = () => {
    return props.item.get('options').map(option => (
      <span key={option.get('optionId')} className="item-option">
        {option.get('name')}: {option.get('value')}
      </span>
    ));
  };

  const onClickDownload = () => {
    props.onClickDownload(props.item);
  };

  return (
    <div className="cart-item-container">
      {props.item.get('itemSpecificError') && (
        <Alert className="mt-3 w-100" isOpen type="important">
          {props.item.get('itemSpecificError')}
        </Alert>
      )}
      {/* TODO: Alert below is temporary fix for items with missing pricing. 
        Error should be returned in API. Await that fix and remove this below */}
      {!props.item.get('pricing') && showAlert && props.item.get('loaded') && (
        <Alert className="mt-3 w-100" isOpen type="important">
          Sorry, this product is unavailable for this region. You can remove this product or update
          your shipping country.
        </Alert>
      )}
      <div className="row no-gutters product-info-container clearfix">
        <div className="col-lg-8 col-md-7 col-sm-7 col-xs-12">
          <div className="item-image-section">
            <ImageThumb
              loading={props.item.get('previewImageUrl') === undefined}
              imageUrl={props.item.get('previewImageUrl')}
              onImageClick={handleClickPreview}
            />
            {/* <Button className='button-default small full-width mb-1' onClick={handleEditItem}>
                <Icon small icon='edit2' />{' '}
                <span className='visible-sm-inline visible-md-inline visible-lg-inline'> {Strings.EDIT_ARTWORK}</span>
                <span className='visible-xs-inline'> {Strings.EDIT_ARTWORK_SHORT}</span>
              </Button>
              <Button className='button-default small full-width mb-1' onClick={onClickDownload}>
                <Icon small icon='download' />{' '}
                <span> {Strings.DOWNLOAD}</span>
              </Button> */}
            <Button
              dataEvent="edit-artwork"
              iconLeft="edit2"
              className="button-default small full-width mb-1 edit-artwork"
              onClick={handleEditItem}
            >
              <span className="visible-sm-inline visible-md-inline visible-lg-inline">
                {Strings.EDIT_ARTWORK}
              </span>
              <span className="visible-xs-inline"> {Strings.EDIT_ARTWORK_SHORT}</span>
            </Button>
            <Button
              iconLeft="download"
              className="button-default small full-width mb-1"
              onClick={onClickDownload}
            >
              {Strings.DOWNLOAD}
            </Button>
            <Checkbox
              noBorder
              className="m-1 ml-2"
              checked={props.item.get('isSample', false)}
              onChange={props.onToggleSample}
              label={Strings.SAMPLE}
            />
          </div>
          <div className="item-options">
            <span className="item-title">
              {props.item.getIn(['sku', 'productName']) || props.item.get('name')}
            </span>
            <span className="product-price-xs visible-xs">
              {formatPrice(props.item.getIn(['pricing', 'PartnerPrice', 'Price']))}
            </span>
            <div className="options">{options()}</div>
          </div>
        </div>
        <div className="price-and-quantity flex-container flex-dead-centered col-lg-2 col-md-3 col-sm-3 col-xs-12">
          <InputNumber
            value={props.item.get('quantity')}
            onChange={newValue => {
              onQuantityChange(newValue);
            }}
          />
        </div>
        <div
          className={
            'price-and-quantity flex-container flex-dead-centered product-price col-lg-2 col-md-2 col-sm-2 hidden-xs'
          }
        >
          <span>{formatPrice(props.item.getIn(['pricing', 'PartnerPrice', 'Price']))}</span>
        </div>
      </div>
      <div className="d-flex justify-content-end bottom-row mt-2 mb-2">
        <div>
          <Button className="button-default medium" onClick={removeCartItem}>
            <Icon icon="trash" /> {Strings.DELETE}
          </Button>
        </div>
      </div>
      <hr />
      <style jsx>
        {`
          .item-image-section :global(.image-thumb) {
            margin-bottom: 0.25rem;
          }
        `}
      </style>
    </div>
  );
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  onToggleSample: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onClickPreview: PropTypes.func.isRequired,
  onClickDownload: PropTypes.func.isRequired
};

export default CartItem;
