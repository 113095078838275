import { connect } from 'react-redux';
import ProductDescription from './ProductDescription';
import {
  productDescriptionSelector,
  storeIdSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import { changeStoreField, changeStorageField, setError } from '../../../ProductPublishActions';

const mapDispatchToProps = {
  setError: (storeId, section, message) => setError({ storeId, section, message }),
  onChange: (storage, storeId, field, value) =>
    storage ? changeStorageField({ field, value }) : changeStoreField({ storeId, field, value })
};

const mapStateToProps = state => {
  return {
    description: productDescriptionSelector(state),
    storeId: storeIdSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescription);
