import { createSelector } from 'reselect';

const shipmentViewSelector = state => state.getIn(['orders', 'shipmentView']);

export const shipmentData = createSelector([shipmentViewSelector], orders =>
  orders.get('shipmentData').toJS()
);

export const loadingShipment = createSelector([shipmentViewSelector], orders =>
  orders.get('loading')
);
