import { createSelector } from 'reselect';
import { removeDuplicatesBy, flatten } from './../../../../utils/array';
import {
  selectedProductsSelector,
  getProductVariants
} from '../../../../store/selectors/productDataSelectors';

const variantFilteredOptions = (variant, filteredOptions) => {
  return Object.keys(variant)
    .filter(option => option.length > 20) // filter only needed options
    .map(option => ({
      id: option,
      value: variant[option]
    }))
    .map(item =>
      removeDuplicatesBy(
        x => x.id,
        filteredOptions
          .filter(a => a.id === item.id)
          .map(item => item.values)
          .reduce((a, b) => a.concat(b), [])
      ).find(a => a.id === item.value)
    )
    .filter(item => item !== undefined)
    .map(item => item.name)
    .join(', ');
};

export const currentItemSelector = state => {
  const fullScreenState = state.getIn(['preview', 'fullScreenPreview']);
  return fullScreenState && fullScreenState.get('currentItem').toJS();
};

export const filteredOptionsSelector = createSelector([selectedProductsSelector], products =>
  flatten(products.map(product => product.details.product.regions))
    .map(region => region['sub-categories'])
    .reduce((a, b) => a.concat(b), [])
    .map(item => item.attributes)
    .reduce((a, b) => a.concat(b), [])
    .filter(item => item.id !== undefined)
);

export const allOptionsSelector = createSelector(
  [currentItemSelector, getProductVariants, filteredOptionsSelector],
  (currentItem, variants, filteredOptions) => {
    const variant = variants.find(variant => variant.sku === currentItem.sku);
    return variantFilteredOptions(variant, filteredOptions);
  }
);

export const allAssociatedOptionsSelector = createSelector(
  [currentItemSelector, getProductVariants, filteredOptionsSelector],
  (currentItem, variants, filteredOptions) => {
    if (currentItem.associatedSkus && currentItem.associatedSkus.length > 1) {
      let associatedOptions = '';
      currentItem.associatedSkus.forEach(aSku => {
        const variant = variants.find(
          variant => variant.sku.toLowerCase() === aSku.sku.toLowerCase()
        );
        associatedOptions += variantFilteredOptions(variant, filteredOptions) + '\n';
      });
      return associatedOptions;
    }
    return '';
  }
);
