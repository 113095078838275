import { Record } from 'immutable';

const PagedQuery = new Record({
  searchText: '',
  sortValue: '',
  countryCode: '',
  page: 1,
  pageSize: 8
});

export default PagedQuery;
