import React from 'react';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import { COLORS } from '../../../constants';

const KnowledgeBaseInfo = props => {
  const kbArticles = props.kbArticles;
  const shouldLeftAligned = props.positionLeft ? 'kb-info-wrapper-left' : '';
  return (
    <div className={props.className}>
      {kbArticles && kbArticles.length > 0 ? (
        <div
          className={
            'mt-3 ml-4 pl-2 mb-n4 d-flex justify-content-center kb-info-wrapper ' +
            shouldLeftAligned
          }
        >
          <div className="kb-info">
            <div className="kb-info-icon">
              <Icon icon="info" />
            </div>
            {kbArticles.map((kbArticle, idx) => (
              <div key={`kbiDiv${idx}`}>
                <a
                  className="kb-info-link"
                  href={kbArticle.link}
                  target="_blank"
                >{`${kbArticle.visibleText}`}</a>
                {kbArticles.length == idx + 1 ? null : <span className="kb-info-link">, </span>}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <style jsx>
        {`
            .kb-info-wrapper {
              display: flex;
              min-width: 100%;
            }

            .kb-info-wrapper-left {
              justify-content: left !important;
              margin-left: 0px !important;
              padding-left: 0px !important;
              padding-top: 0px; !important;
              padding-bottom: 40px; !important;
            }
            .kb-info {
              background: ${COLORS.neutralLight150};
              border-radius: 6px;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 12px 16px;
            }

            .kb-info-link {
              font-family: 'Avenir-Roman';
              font-style: normal;
              font-size: 15px;
              line-height: 24px;
              color: ${COLORS.neutralDark600};
              font-weight: 400;
              padding: 0px;
              white-space: pre;
            }

            .kb-info-icon {
              padding: 0px 14px 0px 0px;
              height: 24px;
            }
          `}
      </style>
    </div>
  );
};

KnowledgeBaseInfo.propTypes = {
  className: PropTypes.string,
  kbArticles: PropTypes.arrayOf(
    PropTypes.exact({
      visibleText: PropTypes.string,
      link: PropTypes.string
    })
  ).isRequired,
  positionLeft: PropTypes.bool
};

export default KnowledgeBaseInfo;
