import Config from '../config';
import countrySelectionService from 'gooten-components/src/components/shared/CountrySelection/CountrySelectionService';
import { removeDuplicatesBy } from 'gooten-components/src/utils/array';

class SignupService {
  async getCountries() {
    const recipeId =
      Config.get('env') === 'staging'
        ? '00000000-0000-0000-0000-000000000000'
        : '1ab4e1f8-dbcb-4d6c-829f-ee0b2a60c0b3';
    const countries = await countrySelectionService.loadCountries(recipeId, Config.get('api'));
    return removeDuplicatesBy(obj => obj.code, countries);
  }
}

export default new SignupService();
