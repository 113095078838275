import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import MainView from './MainView';
import IntegrateShopifyStoreView from './IntegrateShopifyStoreView';

const onboardingDashboardRoutes = {
  path: '/onboardingdashboard',
  component: CoreLayout,
  indexRoute: { component: withErrorBoundary(MainView) },
  childRoutes: [
    {
      path: 'integrate-shopify-store',
      component: withErrorBoundary(IntegrateShopifyStoreView)
    }
  ]
};

export default onboardingDashboardRoutes;
