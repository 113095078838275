import { createSelector } from 'reselect';
import {
  publishValidationSelector,
  publishDataSelector,
  areMockupsLoadingSelector,
  publishingStateSelector
} from '../../ProductPublishSelectors';
import { availableProvidersSelector } from '../ProvidersSelectionPanel/ProvidersSelectionPanelSelectors';

export const disableNextSelector = createSelector(
  [
    publishValidationSelector,
    areMockupsLoadingSelector,
    publishDataSelector,
    availableProvidersSelector,
    publishingStateSelector
  ],
  (validation, areMockupsLoading, data, providers, publishingState) => {
    if (!data || publishingState === 'WORKING') {
      return true;
    }

    // check if all providers are disabled
    if (providers.every(p => !p.get('enabled'))) {
      return true;
    }

    const enabledStores = data.get('stores').filter(s => s.get('enabled'));
    const issues = validation.get('issues');
    const errors = validation.get('errors');

    // first check if mockups creation is in process
    if (areMockupsLoading) {
      return true;
    }

    // check for Product Name and Custom sku issues
    if (issues && (issues.get('productName') || issues.get('customSku'))) {
      return true;
    }

    // check storage errors
    if (data.getIn(['storage', 'enabled']) && errors.some(err => err.get('storeId') === -1)) {
      return true;
    }

    // check for any error in enabled stores
    if (errors) {
      // exclude mockups errors, so user can publish
      const storesWithError = errors
        .filter(err => err.get('section') !== 'Mockups')
        .map(err => err.get('storeId'));
      return enabledStores.some(st => storesWithError.includes(st.get('id')));
    }

    return false;
  }
);
