import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Config from '../config';
import CreatePasswordView from '../CreatePassword/CreatePasswordView';
import { hashHistory, Router, Route } from 'react-router';

import '../styles/_core.scss';

class CreatePasswordContainer extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    // Save immutable config
    const { config } = props;
    Config.destroy();
    Config.init(config);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <Router history={hashHistory}>
          <Route path="/(:page)" component={CreatePasswordView} />
        </Router>
      </Provider>
    );
  }
}

export default CreatePasswordContainer;
