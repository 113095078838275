import React from 'react';

export const stepIndexElement = i => {
  return (
    <div className="step-outer">
      <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet">
        <g>
          <circle r="50%" cx="50%" cy="50%" className="circle-back" />
          <text x="50%" y="50%" textAnchor="middle" dy="0.35em">
            {i}
          </text>
        </g>
      </svg>
    </div>
  );
};
