import { Layer, FastLayer } from 'konva';
import { KonvaStage } from '../../flow/konvaTypes';

export default class CanvasLayer {

  layer: KonvaLayer;
  elementsHash: any;

  constructor({id = '', fast = false}) {
    if (fast) {
      // Layers are tied to their own canvas element
      // and are used to contain shapes only.
      // If you don't need node nesting, mouse and touch interactions,
      // or event pub/sub, you should use FastLayer instead of Layer to create your layers.
      // It renders about 2x faster than normal layers.
      this.layer = new FastLayer();
    }
    else {
      this.layer = new Layer();
    }

    this.layer.getCanvas()._canvas.id = id;
    this.elementsHash = {};
  }

  addToStage(stage: KonvaStage) {
    stage.add(this.layer);
  }

  addElement(element: any) {
    this.elementsHash[element.id] = element;
    element.addToCanvas(this.layer);
  }

  getElement(id: string) {
    return this.elementsHash[id];
  }

  setVisible(visible) {
    this.layer.setVisible(visible);
  }

  setOpacity(opacity) {
    this.layer.setOpacity(opacity);
  }

  clear() {
    // called on each re-draw from imageLayer.draw
    this.layer.clear();
    // It's needed to clean up canvas
    this.layer.destroyChildren();
  }

  destroy () {
    // called from surface.destroy
    this.layer.destroyChildren();
    this.layer.destroy();
    this.elementsHash = {};
  }

  draw() {
    this.layer.batchDraw();
  }
}
