import { getActionName } from '../../ProductSelectionViewActions';

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_SEARCH = getActionName('CHANGE_SEARCH');
export const CHANGE_VIEWBY = getActionName('CHANGE_VIEWBY');

// ------------------------------------
// Actions
// ------------------------------------
export const searchChanged = (value = '') => {
  return {
    type: CHANGE_SEARCH,
    payload: value
  };
};

export const viewByChanged = value => {
  return {
    type: CHANGE_VIEWBY,
    payload: value
  };
};
