import { PUSH, DISMISS } from './NotificationsActions';
import { fromJS } from 'immutable';
import { Notification } from '../../../models/Notification';

const defaultState = fromJS({ list: [] });
export default (state = defaultState, action) => {
  switch (action.type) {
    case PUSH: {
      return state.update('list', list => list.unshift(new Notification(action.payload)));
    }
    case DISMISS: {
      return state.update('list', list => list.update(action.key, n => n.set('dismiss', true)));
    }
    default:
      return state;
  }
};
