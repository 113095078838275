import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ShippingMethodGroup from './atoms/ShippingMethodGroup';

class ShippingMethod extends React.Component {
  filteredGroups = () => {
    let filteredGroupsArray = [];
    if (this.props.shippingMethodsGroups) {
      filteredGroupsArray = this.props.shippingMethodsGroups.map((group, index) => {
        const items = this.props.itemsInCart.filter(cartItem =>
          // TODO: Fix cartItem should always has immutable structure inside
          // NOTE: Upon cartRestore - cartItems became fully immutable b/c they are passed via config
          group.get('SKUs').includes(cartItem.get('sku').sku || cartItem.getIn(['sku', 'sku']))
        );
        return group.set('Items', items);
      });
    }
    return filteredGroupsArray;
  };

  render() {
    let displayErrorsIfAny;
    if (this.props.shippingMethodsErrors) {
      displayErrorsIfAny = this.props.shippingMethodsErrors.map((error, index) => (
        <div key={index}>{error.get('Message')}</div>
      ));
    }

    const shippingGroups = (
      <div className="cart-items-container">
        {this.filteredGroups().map((group, index) => (
          <ShippingMethodGroup
            key={group.get('SKUs')}
            index={index}
            group={group}
            selectedShippingMethodId={group.get('SelectedMethodId')}
            onShippingMethodSelected={this.props.setShippingMethodForGroup}
            shippingPricesRequestSelector={this.props.shippingPricesRequestSelector}
          />
        ))}
        {displayErrorsIfAny}
      </div>
    );

    return (
      <>
        <h4>{this.props.title}</h4>
        <div className="shipping-method-container  mt-4 mb-4">{shippingGroups}</div>
        <style jsx>
          {`
            .shipping-method-container {
              // overflow: hidden;
            }
          `}
        </style>
      </>
    );
  }
}

ShippingMethod.propTypes = {
  itemsInCart: PropTypes.instanceOf(List),
  shippingMethodsGroups: PropTypes.instanceOf(List),
  shippingMethodsErrors: PropTypes.instanceOf(List),
  setShippingMethodForGroup: PropTypes.func,
  title: PropTypes.string.isRequired,
  shippingPricesRequestSelector: PropTypes.object
};

ShippingMethod.displayName = 'ShippingMethod';

export default ShippingMethod;
