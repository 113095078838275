import { Layer, Group, Rect, Text } from 'konva';
import { KonvaLayer } from '../../../flow/konvaTypes';

export default class TooltipComponent {

  tooltipLayer: KonvaLayer;
  text: Text;
  group: Group;
  rect: Rect;

  constructor(stage: any, orientation: string) {
    this.tooltipLayer = new Layer({
      visible: false
    });
    this.tooltipLayer.getCanvas()._canvas.id = 'tooltip_layer';
    this.group = new Group({});

    this.rect = new Rect({
      x: 0,
      y: 0,
      width: 29,
      height: 15,
      fill: '#484848',
      cornerRadius: 2
    });

    this.text = new Text({
      x: 0,
      y: 0,
      text: '',
      fontFamily: 'avenir',
      fontSize: 10,
      padding: 3,
      // textFill: "white",
      fill: 'white',
      alpha: 0.75
    });

    this.group.add(this.rect);
    this.group.add(this.text);
    this.tooltipLayer.add(this.group);
    stage.add(this.tooltipLayer);
  }

  setOrientation(orientation: string) {
    if (orientation === 'portrait') {
      this.group.rotation(-90);
    }
    else {
      this.group.rotation(0);
    }
    this.tooltipLayer.batchDraw();
  }

  //Tooltip position can be predefined in "mpos" argument, or calculated inside method
  show (ctx: any, degrees: number, mpos: any) {
    degrees = degrees.toFixed(1);

    if(degrees.length > 4){
      this.rect.width(35);
    }
    else if(degrees.length > 3) {
      this.rect.width(30);
    }
    else if(degrees.length > 2) {
      this.rect.width(28);
    }

    let hasOrientationChanged = ctx.state.editor.defaultOrientation !== ctx.state.editor.orientation;
    let mousePos = mpos || ctx.stage.getPointerPosition();
    let x = mousePos.x;
    let y = mousePos.y;

    // swap axis if orientation changed
    if (hasOrientationChanged) {
      y = [ctx.stage.getWidth() - x, x = y][0];
    }

    this.group.position({
      x: x + 15,
      y: y + 15
    });
    this.text.text(degrees + ' ' + String.fromCharCode(176));
    this.tooltipLayer.show();
    this.tooltipLayer.batchDraw();
  }

  hide() {
    this.tooltipLayer.hide();
  }
}
