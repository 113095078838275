import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
  PartnerTierBadge,
  tierGradients,
  tierRanks
} from 'gooten-components/src/components/shared/PartnerTier';
import BaseCard from '../../BaseCard';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';

export const PartnerTierCardView = ({ status }) => {
  const [tierGradient, setTierGradient] = useState({});

  useEffect(() => {
    setTierGradient(tierGradients[status]);
  }, [status]);

  return (
    <Link to="/settings/profile/vim" style={{ margin: 'auto 0' }}>
      <BaseCard
        title=""
        card={PartnerTierCardView.name}
        customCardStyle={{
          border: 0,
          borderRadius: '8px',
          background: tierGradient.cardBackground
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <PartnerTierBadge id={status} />
            <p className="title-2 my-auto mx-3">
              {tierRanks.find(x => x.name === status).displayName}
            </p>
          </div>
          <Button iconOnly round className="button-white medium mr-2" title="arrowRight">
            <Icon fill="black" icon="arrowRight" width={32} height={32} className="back-arrow" />
          </Button>
        </div>
        <style jsx>
          {`
            :global(.button-white):hover {
              background: white !important;
            }
            .title-2 {
              text-transform: capitalize;
            }
          `}
        </style>
      </BaseCard>
    </Link>
  );
};

PartnerTierCardView.propTypes = {
  status: PropTypes.string.isRequired
};

Object.defineProperty(PartnerTierCardView, 'name', { value: 'partnerTier' });
