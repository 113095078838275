'use strict';
// @flow

import { applyMiddleware, compose, createStore } from 'redux';
import createPublicEventsTriggerMiddleware from './middleware/publicEventsTrigger';
import defaultState, { initialStateJS } from './initialState';
import reducers from './reducers';
import { defaultMemoize } from 'reselect';
import { combineReducers } from 'redux-immutable';
import { editorRestore } from './actions/actionCreators';


export default class StateManager {

  _stateChangeListeners: Array;

  constructor(publicEvents){

    // ========================================================
    // State Instantiation
    // ========================================================
    let initialState = window.___INITIAL_STATE__ || defaultState;

    // ======================================================
    // Middleware Configuration
    // ======================================================
    const publicEventsTriggerMiddleware = createPublicEventsTriggerMiddleware(publicEvents, this);
    let middleware = [publicEventsTriggerMiddleware];

    // ======================================================
    // Store Enhancers
    // ======================================================
    let enhancers = [];
    let devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
    this.store = createStore(
      combineReducers(reducers),
      initialState,
      compose(
        applyMiddleware(...middleware),
        ...enhancers
      )
    );
    this._stateChangeListeners = [];
    this.store.subscribe(() => {
      const currentState = this.store.getState();
      if (window._GTN_EDITOR_DEBUG_ENABLED_ === true) {
        console.log('Editor State: ', currentState);
      }
      for (var i = 0, len = this._stateChangeListeners.length; i < len; i++) {
        this._stateChangeListeners[i](currentState);
      }
    });
  }

  _toJSMemo = defaultMemoize(immutable$ => {
    return immutable$.toJS();
  });

  getState () {
    return this._toJSMemo(this.store.getState());
  }

  getDumpedState(clean: boolean = true) {
    let dumpedState = this.getState();

    if (clean) {
      // clear all images
      dumpedState.images.current.images.forEach(i => delete i.image);
      dumpedState.images.past.forEach(i => i.images.forEach(im => delete im.image));
      dumpedState.images.future.forEach(i => i.images.forEach(im => delete im.image));
      dumpedState.template.layers.forEach(l => delete l.image);
    }

    return dumpedState;
  }

  injectDumpedState(dumpedState: any) {
    // inject state
    this.store.dispatch(editorRestore(dumpedState));
  }

  injectImagesState(imagesState: Object, pastState: Object, futureState: Object) {
    // inject images.current
    let state = {
      editor: { imageloaded: true },
      images: { ...initialStateJS.images }
    };
    state.images.current.images = imagesState;
    if (imagesState.length > 0) {
      state.images.current.selected = {
        imageId: imagesState[0].id,
        layerId: imagesState[0].layerId
      };
    }
    if (pastState) {
      state.images.past = pastState;
    }
    if (futureState) {
      state.images.future = futureState;
    }
    this.store.dispatch(editorRestore(state));
  }

  subscribe(cb) {
    this._stateChangeListeners.push(cb);
  }

  dispatchAction(action) {
    if (window._GTN_EDITOR_DEBUG_ENABLED_ === true) {
      console.log('Editor Action: ', action);
    }
    this.store.dispatch(action);
  }
}
