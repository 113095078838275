import styled from 'styled-components';

export const Container = styled.div`
  .step-outer {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;

    svg {
      height: 100%;

      text {
        fill: #fff;
        font-size: 88px;
        font-weight: 900;
      }
    }
  }

  .regions-container {
    margin-left: -10px;
    button {
      margin-right: 10px;
    }
  }

  .can-go-next-container {
    position: fixed;
    width: calc(100% - ${props => props.marginLeft}px);
    padding: 40px 0 10px 0;
    bottom: 0;
    left: ${props => props.marginLeft}px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 300;

    .next-btn {
      display: block;
      margin: 0 auto;
    }

    > span {
      display: block;
      font-size: 17px;
      font-weight: 900;
      margin-bottom: 25px;

      &.cancel {
        display: inline-block;
        font-size: 12px;
        cursor: pointer;
        height: 21px;
        margin-top: 25px;
      }
    }

    a {
      display: block;
      margin: 0 auto;
      width: ${props => (props.isNewProductFlow ? '308px' : '220px')};
      font-weight: 900;
    }
  }

  .step-container {
    position: relative;
  }

  .pill-container {
    font-size: 14px;
    font-weight: bold;
  }

  // animation fade
  .fade-appear {
    background: rgba(255, 255, 255, 0);
  }

  .fade-appear.fade-appear-active {
    background: rgba(255, 255, 255, 0.8);
    transition: background 500ms ease-in-out;
  }

  // animation slide up
  .can-go-next-container {
    .slide-up-appear {
      opacity: 0.01;
      transform: translate(0%, 140%);
    }

    .slide-up-appear.slide-up-appear-active {
      opacity: 1;
      transform: translate(0%, 0%);
      transition: transform 300ms ease-in-out 500ms, opacity 300ms ease-in-out 500ms;
    }
  }
`;
