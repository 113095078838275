import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SecurityService from '../../../../../Settings/SecurityService';
import Card from 'gooten-components/src/components/shared/Card';
import Chip from 'gooten-components/src/components/shared/Chip';
import PaymentImageComponent from '../../../../OrdersView/components/PaymentImageComponent';
import StatusDescriptions from '../../StatusDescriptions';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import Alert from 'gooten-components/src/components/shared/Alert';
import { Link } from 'react-router';

const DetailsCard = props => {
  const { data, items, showCustomerCosts } = props;

  const [showAllMetaDetails, expandMeta] = useState(false);
  const [showAllSourceDetails, expandSource] = useState(false);

  const showMetaData = data => {
    let metaInfo = '';

    Object.keys(data.Meta).forEach((key, index) => {
      if (key === 'ReturnAddress') {
        // temporarly commented this out until a decision is made what to do with the return address
        // for now if the return address is found in the meta info we will not show it
        // metaInfo += `${key} : ${formatReturnAddress(unescape(data.Meta[key]))}, `;
      } else {
        if (metaInfo.length > 0) metaInfo += ', ';
        metaInfo += `${key}: ${unescape(data.Meta[key])}`;
      }
    });

    if (!showAllMetaDetails && metaInfo.length > 30) {
      metaInfo = metaInfo.substring(0, 30) + '...';
    }

    return (
      <div className="meta-info mb-2">
        {metaInfo}
        {metaInfo.length > 30 ? (
          <Button
            className="ml-2 button-default medium"
            iconOnly
            onClick={() => expandMeta(!showAllMetaDetails)}
          >
            <Icon icon={showAllMetaDetails ? 'chevronUp' : 'chevronDown'} />
          </Button>
        ) : null}
        <style jsx>
          {`
            word-break: break-word;
          `}
        </style>
      </div>
    );
  };

  const formatReturnAddress = data => {
    const ra = JSON.parse(data);
    return `${ra.FirstName || ''} ${ra.LastName || ''} ${ra.Line1 || ''} ${ra.Line2 || ''} ${
      ra.State || ''
    } ${ra.CountryCode || ''} ${ra.PostalCode || ''}`;
  };

  const showSourceData = data => {
    return (
      <div className="meta-info mb-2">
        {data.ClientDetails ? (
          <a role="button" onClick={() => expandSource(!showAllSourceDetails)}>
            {data.Source}
          </a>
        ) : (
          data.Source
        )}
        {showAllSourceDetails && <div>{data.ClientDetails}</div>}
        <style jsx>
          {`
            word-break: break-word;
          `}
        </style>
      </div>
    );
  };

  return (
    <>
      <Card className="details-card" title="Details">
        <Alert
          type="important"
          className="w-100 mb-3"
          isOpen={
            data.OrderStatuses && !!data.OrderStatuses.find(stat => stat.Name === 'Unavailable')
          }
        >
          A product is currently out of stock, replace or cancel item to resolve issue
        </Alert>
        <div className="d-flex justify-content-between status-row">
          <p className="status-key">Status</p>
          <div className="d-flex flex-wrap justify-content-end status-key-value">
            {data.OrderStatuses.map(status => (
              <Chip className="ml-1 mb-1" statusNumber={status.Id} key={status.Id} />
            ))}
          </div>
        </div>
        {!SecurityService.isVendor() && (
          <div className="d-flex justify-content-between order-total-row">
            <p className="order-total-key">{showCustomerCosts ? 'Order Total' : 'Total Cost'}</p>
            <div className="order-total-value">
              <span className="mr-2">{data.OrderPartnerTotal}</span>
              <PaymentImageComponent paymentId={data.PaymentMethod} />
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between order-quantity-row">
          <p className="order-quantity-key">Items in Order</p>
          <p className="order-quantity-value">{data.ItemsCount}</p>
        </div>
        <div className="d-flex justify-content-between order-date-row">
          <p className="order-date-key">Order Date</p>
          <p className="order-date-value">
            {moment(data.OrderDate).format('MMM DD, YYYY h:mma Z')}
          </p>
        </div>
        <div className="d-flex justify-content-between source-row">
          <p className="source-key">Source</p>
          <div className="source-value">{showSourceData(data)}</div>
        </div>
        <div className="d-flex justify-content-between coupon-code-row">
          <p className="coupon-code-key">Coupon Code</p>
          <p className="coupon-code-value">
            {Object.entries(data.Coupons).map((coupon, key) => (
              <Link
                key={key}
                to={`/coupon/${coupon[1]}${
                  data.PartnerInternalId ? '?partnerId=' + data.PartnerInternalId : ''
                }`}
              >
                {coupon[0]}
                <br />
              </Link>
            ))}
          </p>
        </div>
        {data.SafeId && (
          <div className="d-flex justify-content-between safe-id-row">
            <p className="safe-id-key">Safe Id</p>
            <p className="safe-id-value">{data.SafeId}</p>
          </div>
        )}
        {data.ShortId && (
          <div className="d-flex justify-content-between short-id-row">
            <p className="short-id-key">Short Id</p>
            <p className="short-id-value">{data.ShortId}</p>
          </div>
        )}
        {data.Meta && (
          <div className="d-flex justify-content-between meta-info-row">
            <p className="meta-info-key">Meta Info</p>
            <div className="metaDetails meta-info-value">{showMetaData(data)}</div>
          </div>
        )}
        <StatusDescriptions
          orderStatuses={data.OrderStatuses}
          shippingIssues={items.filter(item => item.StatusId === 25 && item.TrackingNumber)}
        />
      </Card>
      <style jsx>
        {`
          .order-page-content {
            background: white;
          }

          .justify-content-between p:first-child {
            min-width: 130px;
          }

          .justify-content-between p:last-child {
            text-align: right;
          }
        `}
      </style>
    </>
  );
};

DetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  showCustomerCosts: PropTypes.bool
};

export default DetailsCard;
