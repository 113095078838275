import _loadImage from 'blueimp-load-image';

export default class ImageLoader {
  // return HtmlImageElement with loaded fullsize image
  static loadImage(image: File | string): Promise  {
    return new Promise((resolve, reject) => {

      let opts = {
        // Preview control requires images to be CORS enabled
        // since it is doing canvas exporting to base64 or blob data URL
        // upon previews generation.
        // Otherwise images load as blank and this warning is shown:
        // > Konva warning: Unable to get data URL.
        // > Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
        // If image hosting is not CORS enabled then error will be thrown,
        // but it should be catched in place where it's used and handled
        crossOrigin : 'Anonymous'
      };

      let retries = 0;

      const onImageLoad = (res, data) => {
        if (res.type === 'error') {
          if (retries < 3) {
            console.log(`Image preview: retry to load ${image}`);
            retries++;
            _loadImage(image, onImageLoad, opts);
          }
          else {
            reject(new Error(`Image preview: failed to load ${image}, after ${retries} retries`));
          }
        }
        else {
          _loadImage.parseMetaData(image, data => {
            let orientation = false;
            if (data && data.exif) {
              orientation = data.exif.get('Orientation');
            }
            resolve({image: res, orientation});
          });
        }
      };

      _loadImage(image, onImageLoad, opts);

    });
  };

  // Serg: Commented out upon perf optimization
  // static resizeImage(image, orientation, maxWidth, maxHeight) {
  //   return _loadImage.scale(image, {
  //     maxWidth,
  //     maxHeight,
  //     canvas: true,
  //     orientation
  //   });
  // }
}
