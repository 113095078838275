import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CreditCard from '../../../../../../gooten-hub/src/Settings/Cards/CreditCard';

const Payment = ({
  braintreeToken,
  isOnCredit,
  paymentMethods,
  title,
  fetchPaymentMethods,
  cardData
}) => {
  useEffect(() => {
    fetchPaymentMethods();
  }, [cardData]);

  const primaryMethod = paymentMethods && paymentMethods.PaymentMethods.find(m => m.IsDefault);

  return (
    <div className="payment-container">
      <h4>{title}</h4>
      {isOnCredit && !primaryMethod && <p>Your order will be placed on your credit terms.</p>}

      {(primaryMethod || (!primaryMethod && !isOnCredit && braintreeToken)) && (
        <>
          <CreditCard />
          <div className="caption-text mt-n3">
            By clicking “place order” your account will be charged when the product[s] goes into
            production.
          </div>
        </>
      )}
    </div>
  );
};

Payment.propTypes = {
  fetchPaymentMethods: PropTypes.func.isRequired,
  isOnCredit: PropTypes.bool,
  title: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object,
  braintreeToken: PropTypes.string,
  cardData: PropTypes.object
};

Payment.displayName = 'Payment';

export default Payment;
