import { connect } from 'react-redux';
import CreateProductView from './CreateProductView';
import {
  allStoresSelector,
  selectedStoreSelector,
  storesLoadedSelector,
  storageSelectionSelector
} from '../HubView/components/Stores/StoresSelectors';
import { storesLoad } from '../HubView/components/Stores/StoresActions';

const mapDispatchToProps = {
  loadStores: () => storesLoad()
};

const mapStateToProps = (state, ownProps) => {
  return {
    stores: allStoresSelector(state),
    storesLoaded: storesLoadedSelector(state),
    selectedStoreId: selectedStoreSelector(state),
    isStorageSelected: storageSelectionSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductView);
