import React, { useState } from 'react';
import Loader from '../../../../../gooten-components/src/components/shared/Loader';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { object, string, ref } from 'yup';
import Input from 'gooten-components/src/components/shared/Input';
import Select from 'gooten-components/src/components/shared/Select';
import Button from 'gooten-components/src/components/shared/Button';
import './index.scss';

const UserDetails = props => {
  if (!props.show) {
    return null;
  }
  const [previousEmail, setPreviousEmail] = useState(props.userDetailValues.email);
  const [emailError, setEmailError] = useState('');

  const filterDisabledCountries = countriesArray => {
    const filterCountries = ['Cuba', 'Iran', 'Syria'];
    return countriesArray.filter(countryObject => !filterCountries.includes(countryObject.name));
  };

  const validateEmail = value => {
    return new Promise(resolve => {
      if (previousEmail === value) {
        resolve(emailError);
      } else {
        setPreviousEmail(value);
        fetch(`/admin/account/checkIfEmailTaken?email=${encodeURIComponent(value)}`).then(
          response => {
            response.json().then(resp => {
              if (resp.taken) {
                setEmailError('Email is not unique.');
                resolve('Email is not unique.');
              } else {
                setEmailError('');
                resolve('');
              }
            });
          }
        );
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{
          firstName: props.userDetailValues.firstName,
          lastName: props.userDetailValues.lastName,
          email: props.userDetailValues.email,
          validateEmail: '',
          password: props.userDetailValues.password,
          country: props.userDetailValues.country
        }}
        validationSchema={() =>
          object().shape({
            firstName: string()
              .required('Please provide your First Name.')
              .max(24, 'First name must be at most 24 characters'),
            lastName: string()
              .required('Please provide your Last Name.')
              .max(24, 'Last name must be at most 24 characters'),
            email: string()
              .required('Please provide a valid Email address.')
              .email('Please provide a valid Email address.'),
            validateEmail: string()
              .required("Emails don't match.")
              .oneOf([ref('email')], "Emails don't match."),
            password: string()
              .required('Please provide a Password.')
              .min(8, 'Password should contain 8 characters minimum.')
          })
        }
        onSubmit={async (data, { setSubmitting, setFieldError }) => {
          await fetch(`/admin/account/checkIfEmailTaken?email=${encodeURIComponent(data.email)}`)
            .then(response => {
              response.json().then(resp => {
                if (resp.taken) {
                  setFieldError('email', 'Email is not unique.');
                } else {
                  props.onSubmit(data);
                }
              });
            })
            .catch(err => console.error(err));
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          submitCount
        }) => (
          <div className="sign-up-card" style={{ display: !props.show && 'none' }}>
            {isSubmitting && <Loader />}
            <div className="main-header">Sign Up</div>
            <div className="main-subheader">No Credit Card Required. Takes 90 seconds!</div>
            <div className="card-section">
              <div className="card-header">YOUR DETAILS</div>
              <div className="progress-step">
                STEP <span className="current-step">1</span>/3
              </div>
            </div>
            <div className="ui input fluid">
              <Input
                className="signup-firstname-field"
                name="firstName"
                type="text"
                label="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                disabled={isSubmitting}
                hasError={submitCount > 0 && !!errors.firstName}
                errorText={submitCount > 0 && !!errors.firstName ? errors.firstName : ''}
              />
            </div>
            <div className="ui input fluid">
              <Input
                className="signup-lastname-field"
                name="lastName"
                type="text"
                label="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                disabled={isSubmitting}
                hasError={submitCount > 0 && !!errors.lastName}
                errorText={submitCount > 0 && !!errors.lastName ? errors.lastName : ''}
              />
            </div>
            <div className="ui input fluid">
              <Field name="email" validate={validateEmail}>
                {({ field, form }) => (
                  <Input
                    className="signup-email-field"
                    name="email"
                    type="email"
                    label="Email"
                    icon="mail"
                    iconPlacement="trailing"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={isSubmitting}
                    hasError={
                      (touched.email && !!errors.email) || errors.email === 'Email is not unique.'
                    }
                    errorText={
                      (touched.email && !!errors.email) || errors.email === 'Email is not unique.'
                        ? errors.email
                        : ''
                    }
                  />
                )}
              </Field>
            </div>
            {values.email.length > 0 && (
              <div className="ui input fluid">
                <Field name="validateEmail">
                  {({ field, form }) => (
                    <Input
                      className="signup-email-confirm-field"
                      name="validateEmail"
                      type="email"
                      label="Confirm Email"
                      icon="mail"
                      iconPlacement="trailing"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.validateEmail}
                      disabled={isSubmitting}
                      hasError={touched.validateEmail && !!errors.validateEmail}
                      errorText={
                        touched.validateEmail && !!errors.validateEmail ? errors.validateEmail : ''
                      }
                    />
                  )}
                </Field>
              </div>
            )}
            <div className="ui input fluid">
              <Input
                className="signup-password-field"
                name="password"
                type="password"
                label="Password"
                icon="lock"
                iconPlacement="trailing"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                disabled={isSubmitting}
                hasError={touched.password && !!errors.password}
                errorText={
                  touched.password && !!errors.password
                    ? errors.password
                      ? errors.password
                      : '8 character minimum'
                    : ''
                }
              />
            </div>
            <Select
              className="signup-country-select"
              label={'Country'}
              disabled={isSubmitting}
              onChange={handleChange}
              name="country"
              value={values.country}
            >
              {filterDisabledCountries(props.countries).map(country => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </Select>
            <div className="pull-right">
              <Button
                className="button-secondary extra-large signup-user-details-continue"
                onClick={() => (isSubmitting ? null : handleSubmit())}
                iconRight="chevronRight"
              >
                Continue
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

UserDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userDetailValues: PropTypes.object.isRequired,
  countries: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default UserDetails;
