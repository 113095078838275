import React, { useEffect, useState } from 'react';
import Alert from 'gooten-components/src/components/shared/Alert';
import Input from 'gooten-components/src/components/shared/Input';
import Button from 'gooten-components/src/components/shared/Button';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Select from 'gooten-components/src/components/shared/Select';
import UploadCSVButton from '../../../Shared/UploadCSVButton';
import LoadingSpinnerOverlay from '../../../Shared/LoadingSpinnerOverlay';
import PagedTable from '../../../Shared/PagedTable';
import { CSV_TEMPLATE_MIGRATION_URL } from '../../../constants';

export const PublishView = ({
  isLoading,
  stores,
  providers,
  fetchStores,
  fetchStoresError,
  validateCsv,
  validateCsvError,
  validateCsvSuccess,
  publishCsv,
  publishCsvError,
  publishCsvSuccess,
  csvData,
  loadCsv,
  resetValidateAndPublish
}) => {
  const [form, setForm] = useState({
    partnerId: '',
    provider: '',
    store: '',
    uploadMocks: false
  });
  const didMount = React.useRef(false);
  useEffect(() => {
    if (didMount.current) {
      const timeOutId = setTimeout(() => fetchStores(form.partnerId), 500);
      return () => clearTimeout(timeOutId);
    } else {
      didMount.current = true;
    }
  }, [form.partnerId]);

  useEffect(() => {
    resetValidateAndPublish();
  }, [form]);

  function renderImportDataForm() {
    return (
      <>
        {/* partnerId */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Input
              type="number"
              placeholder="1234"
              label="Partner Id"
              value={form.partnerId}
              onChange={e => setForm({ ...form, partnerId: e.target.value })}
              errorText={fetchStoresError}
            />
          </div>
        </div>

        {/* providers */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Select
              label={'Provider'}
              placeholder="Select provider"
              onChange={e => setForm({ ...form, store: '', provider: e.target.value })}
              value={form.provider}
            >
              {stores &&
                providers.map(provider => (
                  <option key={provider} value={provider}>
                    {provider.toUpperCase()}
                  </option>
                ))}
            </Select>
          </div>
        </div>

        {/* stores */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Select
              label={'Store'}
              placeholder="Select store"
              onChange={e => setForm({ ...form, store: e.target.value })}
              value={form.store}
            >
              {stores &&
                stores
                  .filter(store => store.provider === form.provider)
                  .map(store => (
                    <option key={store.id} value={store.storeName}>
                      {store.storeName}
                    </option>
                  ))}
            </Select>
          </div>
        </div>

        {/* mockups */}
        <div className="row mb-4">
          <div className="col-4 col-sm-4">
            <Checkbox
              checked={form.uploadMocks}
              label={'Upload mockups'}
              onChange={() => setForm({ ...form, uploadMocks: !form.uploadMocks })}
            ></Checkbox>
          </div>
        </div>
      </>
    );
  }

  function renderCsvButtons() {
    return (
      <div className="row">
        <div className="col-8 col-sm-8">
          <div className="d-flex flex-wrap justify-content-between csv-buttons mr-n1 ml-n1 mb-2 mt-4">
            <UploadCSVButton onUpload={e => loadCsv(e)}></UploadCSVButton>
            <Button className={'button-primary large ml-2 mr-2 mb-4'}>
              <a
                href={CSV_TEMPLATE_MIGRATION_URL}
                target={'_blank'}
                className="w-100 h-100 position-absolute"
              ></a>
              CSV Template
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function renderValidate() {
    return (
      <div className="row">
        <div className="col-4 col-sm-4">
          <div className="d-flex flex-wrap csv-buttons mr-n1 ml-n1 mb-0 mt-4">
            <Button
              className={'button-primary large ml-1 mr-1 mb-2'}
              onClick={() =>
                validateCsv({
                  partnerId: form.partnerId,
                  provider: form.provider,
                  storeName: form.store,
                  csv: csvData
                })
              }
              disabled={!(form.partnerId && form.store && csvData?.length)}
            >
              Validate
            </Button>
          </div>
        </div>

        <div className="col-sm-12">
          {validateCsvError && (
            <Alert isOpen type="failure" className={'ml-1 mr-1 mb-2 w-100'}>
              <div>Validate CSV result</div>
              <p dangerouslySetInnerHTML={{ __html: validateCsvError }}></p>
            </Alert>
          )}
          {validateCsvSuccess && (
            <Alert isOpen type="success" className={'ml-1 mr-1 mb-2 w-100'}>
              <div>Validate CSV result</div>
              <p>{validateCsvSuccess}</p>
            </Alert>
          )}
        </div>

        <div className="col-4 col-sm-4">
          <div className="d-flex flex-wrap csv-buttons mr-n1 ml-n1 mb-0 mt-4">
            {validateCsvSuccess && (
              <Button
                className={'button-primary large ml-1 mr-1 mb-2'}
                disabled={publishCsvSuccess}
                onClick={() => {
                  publishCsv({
                    partnerId: form.partnerId,
                    provider: form.provider,
                    storeName: form.store,
                    uploadMockups: form.uploadMocks,
                    csv: csvData
                  });
                }}
              >
                Publish
              </Button>
            )}
          </div>
        </div>

        <div className="col-sm-12">
          {publishCsvError && (
            <Alert isOpen type="failure" className={'ml-1 mr-1 mb-2 w-100'}>
              <div>Publish CSV result</div>
              <p>{publishCsvError}</p>
            </Alert>
          )}
          {publishCsvSuccess && (
            <Alert isOpen type="success" className={'ml-1 mr-1 mb-2 w-100'}>
              <div>Publish CSV result</div>
              <p>{publishCsvSuccess}</p>
            </Alert>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {isLoading && <LoadingSpinnerOverlay />}
      <div className="publish-tab mt-4">
        <div className="publish-content">
          {renderImportDataForm()}
          {renderCsvButtons()}
          <PagedTable data={csvData} showLineNumber={true}></PagedTable>
          {renderValidate()}
        </div>
      </div>
      <style jsx>
        {`
          .publish-content :global(.dropdown-container) {
            max-width: 100%;
          }
          :global(.csv-buttons button) {
            flex-grow: 1;
          }
        `}
      </style>
    </>
  );
};

export default PublishView;
