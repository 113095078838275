import { createSelector } from 'reselect';

const checkoutSelector = state => state.get('checkout');
const shippingSelector = createSelector([checkoutSelector], checkout => checkout.get('shipping'));
const addressesSelector = createSelector([shippingSelector], shipping => shipping.get('addresses'));

export const selectedAddressSelector = createSelector([addressesSelector], addresses =>
  addresses.get('selected').toJS()
);

export const paymentSelector = createSelector([checkoutSelector], checkout =>
  checkout.get('payment').toJS()
);
