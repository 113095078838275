import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List, Map } from 'immutable';
import Rules from '../../../ErrorsHandler/ValidationRules';
import Input from '../../../../shared/Input';

const Container = styled.div`
  margin-top: 10px;
  flex-direction: column;

  span:nth-child(1) {
    font-size: 12px;
    font-weight: 900;
  }

  input.product-name-input.form-control {
    height: 40px;
    border-color: ${props => (props.hasError ? '#F1643A' : '#ccc')};
  }
`;

const ProductName = props => {
  const [error, setError] = useState(null);

  // Error messages
  const errors = {
    maxLength: `Max product name length is ${props.maxLength} chars.`,
    invalid: 'Product name is not valid or missing.'
  };

  const checkIfNameIsValid = () => {
    let err = Rules.productName.maxLengthExceeded(props.productName?.length, props.maxLength)
      ? errors.maxLength
      : Rules.productName.isNameEmpty(props.productName) ||
        !Rules.productName.isRegexValid(props.regex, props.productName)
      ? errors.invalid
      : null;

    setError(err);
    props.setError(props.storeId, 'ProductName', err);
  };

  useEffect(() => {
    checkIfNameIsValid();
  }, [props.productName]);

  useEffect(() => {
    // Storage and bigcommerce do not allow duplicate productNames
    const onlyAllowsUniqueNames = props.storage || props.selectedProviderName === 'bigcommerce';
    const errors =
      props.serverErrors.get('errors').size &&
      props.serverErrors
        .get('errors')
        .filter(err => err.get('storageRelated') && onlyAllowsUniqueNames);

    if (errors && errors.size) {
      setError(
        errors
          .map(err => err.get('message'))
          .toJS()
          .toString()
      );
    } else {
      checkIfNameIsValid();
    }
  }, [props.serverErrors.toString(), props.associatedErrorNames]);

  const displayError =
    props.associatedErrorNames?.includes(props.productName) || !!error ? error : null;

  return (
    <Container className="product-publish-product-name flex-container">
      <Input
        type="text"
        label="Product Name"
        value={props.productName}
        hasError={!!displayError}
        errorText={displayError}
        onChange={e => props.onChange(props.storage, props.storeId, 'productName', e.target.value)}
      />
    </Container>
  );
};

ProductName.propTypes = {
  productName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setError: PropTypes.func,
  storage: PropTypes.bool,
  storeId: PropTypes.number,
  maxLength: PropTypes.number,
  regex: PropTypes.string,
  serverErrors: PropTypes.instanceOf(Map),
  selectedProviderName: PropTypes.string,
  associatedErrorNames: PropTypes.instanceOf(List)
};

export default ProductName;
