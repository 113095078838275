import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import BaseCard from '../BaseCard';
import Icon from 'gooten-components/src/components/shared/Icon';
import RecieptModal from './RecieptModal';
import Button from 'gooten-components/src/components/shared/Button';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import billingHistoryService from './BillingHistoryService';

class BillingHistory extends React.Component {
  static get name() {
    return 'billingHistory';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Billing History'
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      currentReciept: null
    };
  }

  componentDidMount() {
    this.props.init(this.props);
  }

  openReciept(currentReciept) {
    this.setState({ currentReciept });
  }

  downloadReciept(reciept) {
    billingHistoryService.downloadReciept(reciept, this.props.partnerDetails);
  }

  closeReciept() {
    this.setState({ currentReciept: null });
  }

  loadMore() {
    var data = this.props.data.toJS();
    let chargeTime = data[data.length - 1].ChargeTime;
    this.props.changePage(parseInt(Date.parse(chargeTime)) / 1000, this.props.pageSize);
  }

  get hasMore() {
    // unfortunately because stripe does not let us know if there are more transaction to get so for
    // now we just show the 'Show more' button at all times as long as there is at least 1 transaction
    // return this.props.total > this.props.page * this.props.pageSize;
    return this.props.data.toJS().length > 0;
  }

  getStatus(status) {
    if (status === 'Success' || status === 'succeeded') return 'Success';
    if (status === 'requires_payment_method') return 'No Payment Method';
    return status;
  }

  renderHead() {
    return (
      <>
        <TableRow
          columns="95px 85px 85px 1fr 90px 90px"
          header
          size="small"
          className="billing-header"
        >
          <Cell className="hidden-xs">Charge Date</Cell>
          <Cell className="hidden-xs">Amount</Cell>
          <Cell className="hidden-xs">Order ID</Cell>
          <Cell className="hidden-xs">Transaction ID</Cell>
          <Cell className="hidden-xs">Status</Cell>
          <Cell className="hidden-xs ml-2">Receipt</Cell>
        </TableRow>
        <style jsx>
          {`
            :global(.billing-header .table-cell) {
              white-space: nowrap;
            }
          `}
        </style>
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.props.data.map((i, key) => (
          <div key={key}>
            <Cell className="visible-xs p-3 body-text-1">
              <div className="row">
                <div className="col-xs-6">
                  <b>Charge Date:</b>
                </div>
                <div className="col-xs-6">
                  {i.ChargeTime ? new Date(i.ChargeTime).toLocaleDateString() : null}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <b>Amount:</b>
                </div>
                <div className="col-xs-6">${(i.Amount || 0).toFixed(2)}</div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <b>Order ID:</b>
                </div>
                <div className="col-xs-6">
                  <a
                    className="settings-link"
                    href={`/admin#/orders-new/${i.OrderId}/summary`}
                    target="_blank"
                  >
                    {i.OrderId}
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <b>Transaction ID:</b>
                </div>
                <div className="col-xs-6">{i.TransactionId}</div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <b>Status:</b>
                </div>
                <div className="col-xs-6">{this.getStatus(i.Status)}</div>
              </div>
              {this.getStatus(i.Status) === 'Success' ? (
                <div className="row">
                  <div className="col-xs-6">
                    <b>Receipt:</b>
                  </div>
                  <div className="col-xs-6">
                    <Button
                      className="button-default small"
                      iconOnly
                      onClick={() => this.openReciept(i)}
                    >
                      <Icon icon="file" small />
                    </Button>
                    <Button
                      className="button-default small"
                      iconOnly
                      onClick={() => this.downloadReciept(i)}
                    >
                      <Icon icon="download" small />
                    </Button>
                  </div>
                </div>
              ) : null}
            </Cell>
          </div>
        ))}

        <TableRow columns="1fr 1fr 1fr 1fr 1fr 1fr" size="small">
          <Cell header className="hidden-xs">
            Charge Date
          </Cell>
          <Cell header className="hidden-xs">
            Amount
          </Cell>
          <Cell header className="hidden-xs">
            Order ID
          </Cell>
          <Cell header className="hidden-xs">
            Transaction ID
          </Cell>
          <Cell header className="hidden-xs">
            Status
          </Cell>
          <Cell header className="hidden-xs ml-2">
            Receipt
          </Cell>
          {this.props.data.map((i, key) => (
            <React.Fragment key={key}>
              <Cell className="hidden-xs">
                {i.ChargeTime ? new Date(i.ChargeTime).toLocaleDateString() : null}
              </Cell>
              <Cell className="hidden-xs">${(i.Amount || 0).toFixed(2)}</Cell>
              <Cell className="hidden-xs">
                <a
                  className="settings-link"
                  href={`/admin#/orders-new/${i.OrderId}/summary`}
                  target="_blank"
                >
                  {i.OrderId}
                </a>
              </Cell>
              <Cell className="hidden-xs">{i.TransactionId}</Cell>
              <Cell className="hidden-xs">{this.getStatus(i.Status)}</Cell>
              <Cell className="hidden-xs">
                {this.getStatus(i.Status) === 'Success' ? (
                  <>
                    <Button
                      className="button-default medium m-auto"
                      iconOnly
                      onClick={() => this.openReciept(i)}
                    >
                      <Icon icon="file" />
                    </Button>
                    <Button
                      className="button-default medium m-auto"
                      iconOnly
                      onClick={() => this.downloadReciept(i)}
                    >
                      <Icon icon="download" />
                    </Button>
                  </>
                ) : null}
              </Cell>
            </React.Fragment>
          ))}
        </TableRow>
      </>
    );
  }

  render() {
    return (
      <BaseCard card={BillingHistory.name} title="Billing History">
        <div>
          <div className="billing-history-card">
            {this.props.data.size ? (
              <div className="table table-hover"> {this.renderBody()}</div>
            ) : (
              <p className="card-description">
                Looks like you don’t have any transactions yet. When you do, you can find them here.
              </p>
            )}
            {this.hasMore ? (
              <div className="text-center">
                <Button className="button-default large" onClick={() => this.loadMore()}>
                  Show more
                </Button>
              </div>
            ) : null}
          </div>
          {this.props.partnerDetails ? (
            <RecieptModal
              record={this.state.currentReciept || {}}
              partnerDetails={this.props.partnerDetails}
              isOpen={!!this.state.currentReciept}
              modalClose={() => this.closeReciept()}
            />
          ) : null}
        </div>
      </BaseCard>
    );
  }
}

BillingHistory.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(List),
  partnerDetails: PropTypes.object,
  total: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  changePage: PropTypes.func
};

export default BillingHistory;
