import React from 'react';
import PropTypes from 'prop-types';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';

export const HoverImage = props => {
  const isInViewport = e => {
    const { top: t, height: h } = e.currentTarget.getBoundingClientRect();
    const isInView = t <= window.innerHeight && t - 210 + h >= 0;
    if (isInView) {
      e.currentTarget.classList.add('hover-image-top');
    } else {
      e.currentTarget.classList.add('hover-image-bottom');
    }
    return isInView;
  };

  const renderHoverImage = image => {
    return (
      <div className="hover-image" onLoad={e => isInViewport(e)}>
        <div className="hover-image-child-container dropshadow-2">
          <div className="triangle" />
          <img className="image" src={`${getSmallPreviewImageResizerUrl(image)}`} />
        </div>
        <style jsx>
          {`
            .triangle {
              position: absolute;
              left: 72px;
              bottom: -8px;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid white;
            }
            .hover-image-bottom .triangle {
              top: -8px;
              border-bottom: 8px solid white;
              border-top: none;
            }
            .hover-image {
              display: flex;
              position: relative;
              z-index: 2000;
              transform: translate(-65px, -165px);
              visibility: hidden;
              width: 160px;
              height: 160px;
            }
            .hover-image-child-container {
              border-radius: 4px;
              width: 160px;
              background-color: white;
              padding: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .hover-image-top > .hover-image-child-container {
              align-self: flex-end;
            }
            .hover-image-bottom > .hover-image-child-container {
              align-self: flex-start;
            }
            .hover-image-top {
              transform: translate(-3rem, -10.5rem);
              visibility: visible;
            }
            .hover-image-bottom {
              transform: translate(-3rem, 4.5rem);
              visibility: visible;
            }
            .image {
              max-width: 9rem;
              max-height: 9rem;
            }
          `}
        </style>
      </div>
    );
  };

  return renderHoverImage(props.image);
};

HoverImage.propTypes = {
  image: PropTypes.string
};

export default HoverImage;
