import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import CountryStateSelection from 'gooten-components/src/components/shared/CountryStateSelection';
import {
  firstNameRegex,
  lastNameRegex,
  phoneNumberRegex,
  zipCodeRegex,
  emailRegex,
  urlRegex
} from 'gooten-components/src/utils/regex';
import Analytics from 'gooten-components/src/services/analyticsService';
import Button from 'gooten-components/src/components/shared/Button';
import Select from 'gooten-components/src/components/shared/Select';
import { FILTER_COUNTRIES } from 'gooten-components/src/constants';
import Config from 'gooten-components/src/config';

const Container = styled.div`
  .dropdown.multiselect-component {
    .dropdown-menu,
    .btn {
      min-width: 10px;
    }
  }
`;

const countriesWithStates = ['US', 'CA'];

class BillingAddress extends React.Component {
  static get name() {
    return 'billingAddress';
  }
  static get messages() {
    return {
      noLogError: true,
      onInitFailure: 'Failed to load Billing Address',
      onFailure: 'Failed to update Billing Address',
      onSuccess: 'Your Billing Address has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
    this.state = {};
  }

  componentDidMount() {
    this.props.init();

    this.setCompanyNameLocked(this.props.data.get('CompanyName'));
  }

  setCompanyNameLocked(companyName) {
    if (companyName.startsWith('MerchCast - ')) {
      this.setState({ companyNameLocked: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unmodified !== this.props.unmodified) {
      this.props.unmodified && this.setCompanyNameLocked(this.props.unmodified.get('CompanyName'));
    }
  }

  changeCountry(value) {
    this.props.change(value || '', 'CountryCode');
    this.props.change('', 'State');
  }

  get needState() {
    return (
      !!this.props.data.CountryCode &&
      countriesWithStates.indexOf(this.props.data.CountryCode) !== -1
    );
  }

  get canUpdate() {
    return (
      this.props.ready &&
      this.props.data &&
      !this.props.data.equals(this.props.unmodified) &&
      Object.values(this.inputs).every(i => !i || i.isValid()) &&
      !this.props.validationIssues.get('State') &&
      !this.props.validationIssues.get('CountryCode')
    );
  }

  update() {
    this.props.update({ data: this.props.data });

    this.setCompanyNameLocked(this.props.data.get('CompanyName'));
  }

  renderInput(
    name,
    label,
    placeholder,
    className,
    regexp,
    isRequired,
    note,
    caseIgnore = false,
    disabled = false
  ) {
    return (
      <TextInput
        className={className}
        id={name}
        showValidation
        isRequired={isRequired}
        emptyMsg="This field is required"
        preValidate
        regexp={regexp}
        caseIgnore={caseIgnore}
        validationMsg="Wrong format"
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(v, id) => this.props.change(v, id)}
        ref={ref => {
          this.inputs[name] = ref;
        }}
        value={this.props.data.get(name) || ''}
        note={note}
      />
    );
  }

  filterDisabledCountries = countriesArray => {
    if (!countriesArray) {
      return [];
    }

    const updatedCountries = countriesArray.filter(countryObject => {
      return !FILTER_COUNTRIES.includes(countryObject.label);
    });
    return updatedCountries;
  };

  getReturnsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return 'https://help.gooten.com/hc/en-us/articles/360047292072-Returns';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521581015835-Returns';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612945497755-Returns';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047292072-Returns';
    }
  };

  render() {
    return (
      <BaseCard card={BillingAddress.name} title="Business Information">
        <Container>
          <div className="profile-information-card">
            <div className="row">
              <div className="col-xs-12">
                <p className="card-description">
                  This information must be filled in prior to recieving or placing an order. This
                  address is also used on return address labels for the&nbsp;
                  <a href={this.getReturnsKbLink()} target="_blank" className="settings-link">
                    products that have this feature enabled.
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    {this.renderInput(
                      'FirstName',
                      'Account Owner First Name',
                      'First Name..',
                      '',
                      firstNameRegex,
                      true
                    )}
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    {this.renderInput(
                      'LastName',
                      'Account Owner Last Name',
                      'Last Name...',
                      '',
                      lastNameRegex,
                      true
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {this.renderInput(
                      'Email',
                      'Account Owner Email Address',
                      'johannes@gooten.com...',
                      '',
                      emailRegex,
                      true
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {this.renderInput(
                      'CompanyName',
                      'Business Name',
                      'Business Name...',
                      '',
                      null,
                      true,
                      null,
                      false,
                      this.state.companyNameLocked
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {this.renderInput(
                      'Website',
                      'Business URL',
                      'Type Business URL...',
                      '',
                      urlRegex,
                      true,
                      null,
                      true
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {this.renderInput(
                      'Line1',
                      'Business Address',
                      'Address Line 1...',
                      '',
                      null,
                      true
                    )}
                  </div>
                  <div className="col-xs-12">
                    {this.renderInput('Line2', '', 'Address Line 2...', '', null, false)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    {this.renderInput('City', 'City', 'City...', '', null, true)}
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <CountryStateSelection
                      countryCode={this.props.data.CountryCode}
                      state={this.props.data.State}
                      label="State/Province"
                      id="State"
                      placeholder="State..."
                      valid={
                        this.props.data.equals(this.props.unmodified) ||
                        !this.props.validationIssues.get('State')
                      }
                      update={value => this.props.change(value, 'State')}
                    />
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    {this.renderInput(
                      'PostalCode',
                      'Postal Code',
                      'Postal Code...',
                      '',
                      zipCodeRegex,
                      true
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="text-input-container text-left col-sm-12">
                    <div className="input-container">
                      <Select
                        label="Country"
                        placeholder="Select a Country"
                        value={this.props.data.CountryCode || ''}
                        onChange={e => this.changeCountry(e.target.value)}
                      >
                        {this.filterDisabledCountries(this.props.countries).map(country => (
                          <option key={country.key} value={country.key}>
                            {`${country.key} - ${country.label}`}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {this.renderInput(
                      'Phone',
                      'Phone Number',
                      'Phone Number...',
                      '',
                      phoneNumberRegex,
                      true,
                      'We use this in the case of important order issues. '
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="button-primary large"
            disabled={!this.canUpdate}
            onClick={() => this.update()}
          >
            Update business information
          </Button>
        </Container>
      </BaseCard>
    );
  }
}

BillingAddress.propTypes = {
  init: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  data: PropTypes.object,
  countries: PropTypes.array,
  unmodified: PropTypes.object,
  update: PropTypes.func.isRequired,
  validationIssues: PropTypes.object.isRequired,
  ready: PropTypes.bool
};

export default BillingAddress;
