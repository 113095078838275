import React from 'react';
import PropTypes from 'prop-types';
import Pill from '../../../../../shared/Pill';
import ColorPill from '../../../../../shared/ColorPill';
import Tile from '../../../../../shared/Tile';
import { getProductImageResizerUrl } from 'gooten-js-utils/src/url';
import RadioField from '../../../../../shared/RadioField';
import Icon from '../../../../../shared/Icon';

const selectAllControlTypes = ['pills', 'color-pallete'];

class OptionValues extends React.Component {
  static propTypes = {
    option: PropTypes.object.isRequired,
    isValueSelected: PropTypes.func.isRequired,
    isValueDisabled: PropTypes.func.isRequired,
    toggleValue: PropTypes.func.isRequired,
    setOverride: PropTypes.func.isRequired,
    selectAll: PropTypes.func.isRequired,
    deselectAll: PropTypes.func.isRequired,
    showErrors: PropTypes.bool
  };

  renderPills(option) {
    return option.values.map(val => (
      <Pill
        key={val.id}
        active={this.props.isValueSelected(option.id, val.id)}
        disabled={this.props.isValueDisabled(option.id, val.id)}
        toggleValue={(optionId, valueId) => this.props.toggleValue(optionId, valueId, option.title)}
        title={val.name}
        optionId={option.id}
        valueId={val.id}
      />
    ));
  }

  renderColorPills(option) {
    return option.values.map(val => (
      <ColorPill
        key={val.id}
        active={this.props.isValueSelected(option.id, val.id)}
        disabled={this.props.isValueDisabled(option.id, val.id)}
        toggleValue={(optionId, valueId) => this.props.toggleValue(optionId, valueId, option.title)}
        hex={val.hex}
        title={val.name}
        optionId={option.id}
        valueId={val.id}
      />
    ));
  }
  renderTiles(option) {
    return option.values.map(val => {
      return (
        <div key={val.id} className="col-xs-6 col-sm-4">
          <Tile
            title={val.name}
            hasNecklabel={!!val.necklabel}
            option={option}
            valueId={val.id}
            active={this.props.isValueSelected(option.id, val.id)}
            disabled={this.props.isValueDisabled(option.id, val.id)}
            toggleValue={(option, valueId) => this.props.setOverride(option, valueId)}
            imageUrl={getProductImageResizerUrl(val.url)}
          />
        </div>
      );
    });
  }

  renderValues(option) {
    switch (option['control-type']) {
      case 'pills':
        return this.renderPills(option);
      case 'color-pallete':
        return this.renderColorPills(option);
      case 'tiles':
        return this.renderTiles(option);
      default:
        throw new Error(`Product data issue: unknown control type, option id: ${option.id}`);
    }
  }

  renderSelectAll(option) {
    if (
      selectAllControlTypes.indexOf(option['control-type']) > -1 &&
      option.values.length > 1 &&
      !option.isSelectAllDisabled
    ) {
      return (
        <RadioField
          className="pull-left mt-2 pt-1 ml-3 mb-2"
          onClick={() =>
            option.anyValueSelected ? this.props.deselectAll(option) : this.props.selectAll(option)
          }
          checked={option.anyValueSelected}
          label={option.anyValueSelected ? 'Deselect all' : 'Select all'}
        />
      );
    }
  }

  renderTitleRow() {
    const visibleErrors = this.props.showErrors && !this.props.option.anyValueSelected;
    return (
      <div>
        <h4 className={`${visibleErrors ? 'error-color' : ''}`}>{this.props.option.title}</h4>
        {visibleErrors && (
          <div className="error-field caption-text d-flex align-items-center">
            <Icon icon="alertCircle" small />
            Please select at least one option below
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="row options-line position-relative">
        {this.renderTitleRow()}
        <div className="option-values">
          {this.renderValues(this.props.option)}
          {this.renderSelectAll(this.props.option)}
        </div>
      </div>
    );
  }
}

export default OptionValues;
