import { connect } from 'react-redux';
import ActionsLine from './ActionsLine';
import {
  selectAll,
  unselectAll,
  unpublishProducts,
  publishProducts,
  deleteProducts,
  unlinkProducts,
  personalizeProducts,
  depersonalizeProducts
} from './../ActionsActions';
import {
  selectedProductsSelector,
  currentProviderSelector,
  productsQueryDataSelector,
  isUpdatingSelector
} from './../../Products/ProductsSelectors';
import productsService from '../../Products/ProductsService';
import { isEmptySelector } from './ActionsLineSelector';

const mapDispatchToProps = {
  selectAll: status => selectAll(status),
  unselectAll: () => unselectAll(),
  unpublishProducts: products => unpublishProducts(products),
  publishProducts: products => publishProducts(products),
  deleteProducts: products => deleteProducts(products),
  unlinkProducts: products => unlinkProducts(products),
  personalizeProducts: products => personalizeProducts(products),
  depersonalizeProducts: products => depersonalizeProducts(products)
};

const mapStateToProps = (state, ownProps) => {
  const selectedProducts = selectedProductsSelector(state);

  return {
    selectedProducts,
    isUpdating: isUpdatingSelector(state),
    isEmpty: isEmptySelector(state),
    currentProvider: currentProviderSelector(state),
    productsQueryData: productsQueryDataSelector(state),
    productUrlInStore:
      selectedProducts.get(0) &&
      productsService.getProductUrlInStore(
        selectedProducts.getIn([0, 'storeName']),
        selectedProducts.first()
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsLine);
