import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';

const Switch = ({ disabled, checked, className, label, secondaryText, onChange }) => {
  return (
    <label className={`${className} ${disabled ? 'disabled' : ''}`}>
      {label && (
        <div className="ml-3">
          <p className="mt-0 heavy body-text-2">{label}</p>
          {secondaryText && <p className="caption-text d-flex">{secondaryText}</p>}
        </div>
      )}
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={e => onChange(e.target.checked)}
      />
      <div className="indicator">
        <div className="nipple" />
      </div>
      <style jsx>
        {`
          // input must be 'visible' to screen readers for accessiblity
          input {
            border: 0;
            clip: rect(0 0 0 0);
            clippath: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
          }
          label {
            min-height: ${label ? '2.5rem' : 'auto'};
            display: flex;
            align-items: ${label ? 'flex-start' : 'center'};
            justify-content: ${label ? 'space-between' : 'center'};
            width: 100%;
          }
          .indicator {
            box-sizing: border-box;
            border-radius: 1rem;
            width: 2.625rem;
            height: 1.625rem;
            border: ${checked
              ? `2px solid ${COLORS.gootenBlue300}`
              : `2px solid ${COLORS.neutralLight400}`};
            display: flex;
            align-items: center;
            transition-duration: 0.3s;
          }
          .nipple {
            height: 1rem;
            width: 1rem;
            border-radius: 0.5rem;
            background: ${checked ? COLORS.gootenBlue500 : COLORS.neutralLight600};
            margin-left: ${checked ? '1.2rem' : '.2rem'};
            transition-duration: 0.3s;
          }
          p {
            margin: 0.5rem 0;
          }
          .disabled {
            cursor: not-allowed;
          }
        `}
      </style>
    </label>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  secondaryText: PropTypes.string
};

export default Switch;
