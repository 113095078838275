import { connect } from 'react-redux';
import PendingInvitations from './PendingInvitations';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { resendInvite, removeInvite } from './PendingInvitationsActions';

const cardSelector = cardSelectorFor(PendingInvitations.name);

const mapDispatchToProps = {
  resendInvite,
  removeInvite
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingInvitations);
