import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { postJson } from 'gooten-components/src/utils/http';
import sha1 from 'sha1';

import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import analyticsService from 'gooten-components/src/services/analyticsService';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../config';

export const LoginForm = ({ showResetPassword, partnerData }) => {
  const recaptchaRef = React.useRef();
  const [captchaToken, setCaptchaToken] = useState('');

  const onChangeCaptcha = value => {
    setCaptchaToken(value);
  };

  const bypass = email => {
    const emails = [
      'automation@tests.com',
      'hub_partner_at@gooten.com',
      'partner-account2@gooten.com',
      'enabled-user@disabled-partner.com',
      'partner-account4@gooten.com',
      'micah+vendor-test@breakoutcommerce.com',
      'manualww@test.com'
    ];

    // the above accounts (plus those containing 'test+QA') are used by QA scripts and should be allowed to bypass the recaptcha check
    if (emails.includes(email) || email.includes('test+QA')) {
      // pass back the recaptcha token that the api recognizes as a token to bypass
      return 'bypass-b4d49408-c49f-463c-a417-bdca7594e27a';
    }

    return false;
  };

  return (
    <>
      <div className="title">Login</div>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={() =>
          object().shape({
            email: string().required('Please enter a valid email.'),
            password: string().required('Please enter a valid password.')
          })
        }
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          const submit = async () => {
            const token =
              bypass(values.email) || captchaToken || (await recaptchaRef.current.executeAsync());
            const credentials = {
              UserName: values.email,
              Password: values.password,
              RememberMe: false,
              captcha: token
            };
            postJson(`/admin/account/LogOnAjax`, credentials)
              .then(
                data =>
                  new Promise((resolve, reject) => {
                    data.isAuthenticated ? resolve(data) : reject(data);
                  })
              )
              .then(data => {
                const sha1Email = sha1(credentials.UserName);
                analyticsService.impactIdentify(data.User.Id, sha1Email);
                window.localStorage.userId = data.User.Id;
                window.localStorage.emailSha1 = sha1Email;
                window.localStorage.token = data.User.ApiKey;
                window.localStorage.isFirstLogin = data.isFirstLogin;
                window.location.reload();
              })
              .catch(reason => {
                setSubmitting(false);
                setFieldError('email', 'Incorrect email or password.');
                setFieldError('password', 'Incorrect email or password.');
                setCaptchaToken('');
                recaptchaRef.current.reset();
              });
          };
          submit();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitCount,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit} className="mt-4">
            <Input
              name="email"
              type="input"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isSubmitting}
              hasError={submitCount > 0 && !!errors.email}
              errorText={submitCount > 0 && !!errors.email ? errors.email : null}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={isSubmitting}
              hasError={submitCount > 0 && !!errors.password}
              errorText={submitCount > 0 && !!errors.password ? errors.password : null}
            />
            {/* <div className="forgot-password text-right">
              <a href="#" onClick={() => showResetPassword(true)}>
                forgot password
              </a>
            </div> */}
            <div className="d-flex justify-content-end mb-3">
              <Button
                type="submit"
                className="submit-button extra-large full-width mt-2"
                loaderColor={partnerData.loaderColor || '#3f3f3f'}
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={() => (isSubmitting ? null : handleSubmit())}
              >
                LOGIN
              </Button>
            </div>
            <ReCAPTCHA
              // Note: Staging site key is to allow automated tests to work in staging env.
              // Swap keys to test locally.
              sitekey={
                Config.get('env') === 'staging'
                  ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // staging key
                  : '6LddGPUZAAAAABzQtPIEygB83xnjdSv0c9YgWr63'
              } // production key
              onChange={onChangeCaptcha}
              onExpired={() => setCaptchaToken('')}
              onErrored={() => setCaptchaToken('')}
              ref={recaptchaRef}
              size="invisible"
            />
          </form>
        )}
      </Formik>
      <style jsx>
        {`
          .title {
            font-size: 42px;
            color: ${partnerData.headerColor || '#2c2229'};
          }
          .forgot-password {
            margin-bottom: 28px;
          }
          .forgot-password a {
            color: ${partnerData.headerColor || '#2c2229'};
          }
          :global(input) {
            border: 1px solid ${partnerData.inputBorderColor || '#ccc'} !important;
          }
          :global(input:hover) {
            border: 1px solid ${partnerData.inputBorderColorHover || '#000'} !important;
          }
          :global(.submit-button) {
            color: ${partnerData.buttonTextColor || '#fff'} !important;
            background: ${partnerData.buttonBgColor || '#000'} !important;
          }
          :global(.error-field) {
            color: ${partnerData.errorTextColor || '#c80404'} !important;
          }
        `}
      </style>
    </>
  );
};
