import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const SEND_EMAIL = customActionFor('PASSWORD').async('SEND_EMAIL');

// ------------------------------------
// Actions
// ------------------------------------

export function sendEmail(payload) {
  return {
    type: SEND_EMAIL.ASYNC,
    payload
  };
}
