import { connect } from 'react-redux';
import ReprintSelfService from './ReprintSelfService';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import {
  reprintReasonsSelector,
  imagesEvidenceSelector,
  orderSummarySelector,
  reprintDataSelector,
  showLoadingSelector,
  shipmentGroupsSelector,
  toastTextSelector,
  selectedReasonSelector
} from './ReprintSelfServiceSelectors';
import {
  uploadImage,
  initSelfService,
  submitReprint,
  toastText,
  reasonSelect
} from './ReprintSelfServiceActions';

const mapDispatchToProps = {
  uploadImage: files => uploadImage(files),
  initSelfService: () => initSelfService(),
  submitReprint: payload => submitReprint(payload),
  clearToast: () => toastText(''),
  reasonSelect: reasonId => reasonSelect(reasonId)
};

const mapStateToProps = (state, ownProps) => {
  return {
    reprintReasons: reprintReasonsSelector(state),
    imagesEvidence: imagesEvidenceSelector(state),
    orderSummary: orderSummarySelector(state),
    reprintData: reprintDataSelector(state),
    showLoading: showLoadingSelector(state),
    shipmentGroups: shipmentGroupsSelector(state),
    toastText: toastTextSelector(state),
    reason: selectedReasonSelector(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(ReprintSelfService));
