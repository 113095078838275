const onboardingData = {
  cards: [
    {
      index: 2,
      type: 'FormCard',
      integration: 'shopify',
      monitors: 'stores',
      title: 'Connect Your Shopify Store',
      onboardingStep: true,
      text: [
        'Enter your myshopify.com address to begin creating products you wish to fulfill with Gooten.'
      ],
      checked: true,
      img: 'img-sloth-connect',
      form: [],
      actions: [
        {
          type: 'Button',
          text: 'CONNECT MY STORE',
          url: '',
          target: '_blank',
          analyticsId: 'CONNECT MY STORE'
        },
        {
          type: 'link',
          text: 'Need help connecting your store?',
          target: 'help_connecting_store',
          analyticsId: 'Need help connecting your store?',
          url: 'https://help.gooten.com/hc/en-us/sections/360010012952-Integrating-your-stores',
          // eslint-disable-next-line prettier/prettier
          fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/sections/4519629743771-Integrating-your-stores',
          // eslint-disable-next-line prettier/prettier
          sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/sections/7612085950619-Integrating-your-stores'
        }
      ]
    },
    {
      index: 2,
      type: 'FormCard',
      integration: 'etsy',
      monitors: 'stores',
      title: 'Connect Your Etsy Store',
      onboardingStep: true,
      text: [
        'Enter your Etsy Shop Name to begin creating products you wish to fulfill with Gooten.'
      ],
      checked: true,
      img: 'img-sloth-connect',
      form: [],
      actions: [
        {
          type: 'Button',
          text: 'INTEGRATE MY STORE',
          url: '#/hub/etsy/connect-store',
          target: '',
          analyticsId: 'CONNECT MY STORE'
        },
        {
          type: 'link',
          text: 'Need help connecting your store?',
          target: 'help_connecting_store',
          analyticsId: 'Need help connecting your store?',
          url: 'https://help.gooten.com/hc/en-us/sections/360010012952-Integrating-your-stores',
          // eslint-disable-next-line prettier/prettier
          fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/sections/4519629743771-Integrating-your-stores',
          // eslint-disable-next-line prettier/prettier
          sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/sections/7612085950619-Integrating-your-stores'
        }
      ]
    },
    {
      index: 1,
      type: 'ActionCard',
      integration: 'all',
      title: 'Browse and Create a Product',
      onboardingStep: true,
      monitors: 'products',
      text: [
        // eslint-disable-next-line
        'Gooten has 100+ items for you to browse through and choose to add to your store. The Product Hub is where you \
        can select an item, upload your custom artwork, and edit the design to your liking. You can then input all the \
        information needed to either Publish it to your store, or save it in Storage for later.',
        'Check out the Best-Seller category for our most popular products.'
      ],
      checked: true,
      img: 'img-sloth-product',
      actions: [
        {
          type: 'Button',
          text: 'START CREATING NEW PRODUCTS',
          url: '#/hub/create-product',
          target: '_self',
          analyticsId: 'START CREATING NEW PRODUCTS'
        },
        {
          type: 'link',
          text: 'Need help creating products?',
          target: 'help_creating_product',
          analyticsId: 'Need help creating products?',
          url: 'https://help.gooten.com/hc/en-us/sections/360010067091-Creating-Products-in-Gooten',
          // eslint-disable-next-line prettier/prettier
          fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/sections/4520663470235-Creating-Products-in-Fuji-Co-branded-Hub',
          // eslint-disable-next-line prettier/prettier
          sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/sections/7612086923163-Creating-Products-in-Sensaria-Co-branded-Hub',
        }
      ]
    },
    {
      index: 3,
      type: 'ActionCard',
      integration: 'all',
      title: 'Set up Your Account',
      onboardingStep: true,
      monitors: 'billing',
      text: ['Input your billing information so your orders can go directly into production.'],
      checked: false,
      img: 'img-sloth-link',
      actions: [
        {
          type: 'Button',
          text: 'SUBMIT BILLING INFORMATION',
          url: '#/settings/billing',
          target: '_self',
          analyticsId: 'SUBMIT BILLING INFORMATION'
        },
        {
          type: 'link',
          text: 'Need help setting up your account?',
          target: 'help_settingup_account',
          analyticsId: 'Need help setting up your account?',
          url: 'https://help.gooten.com/hc/en-us/sections/360010066351-Configuring-your-account',
          // eslint-disable-next-line prettier/prettier
          fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/sections/4519662862875-Configuring-your-account',
          // eslint-disable-next-line prettier/prettier
          sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/sections/7612080946715-Configuring-your-account'
        }
      ]
    },
    {
      index: 4,
      type: 'ActionCard',
      integration: 'all',
      title: 'Order a Sample',
      onboardingStep: true,
      monitors: 'orders',
      text: [
        // eslint-disable-next-line
        'Using Gooten’s Custom Order Form, you can create one-off sample orders to see for yourself the quality of the product you’ll be selling with Gooten. To help you along the way, once you process $100 worth of orders, either through your store or through our Custom Order Form, we will automatically credit you $25!'
      ],
      checked: true,
      img: 'img-sloth-package',
      actions: [
        {
          type: 'Button',
          text: 'CHOOSE A PRODUCT',
          url: '#/custom-order-form',
          target: '_self',
          analyticsId: 'CHOOSE A PRODUCT'
        },
        {
          type: 'link',
          text: 'Need help ordering a Sample?',
          target: 'help_ordering_samples',
          analyticsId: 'Need help ordering a Sample?',
          url: 'https://help.gooten.com/hc/en-us/articles/360048807691-How-to-order-and-use-my-sample-credit-from-VIM',
          // eslint-disable-next-line prettier/prettier
          fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/articles/6202218225563-How-to-use-Fujifilm-sample-credit',
          // eslint-disable-next-line prettier/prettier
          sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/articles/7613869189531-How-to-use-Sensaria-sample-credit-'
        }
      ]
    },
    {
      index: 5,
      type: 'ReferenceCard',
      // means that section will always be hidden on UI, we can remove it in future
      integration: 'none',
      title: 'Need Help Creating Products?',
      onboardingStep: true,
      id: 'help_creating_product',
      references: [
        {
          type: 'Watch: ',
          text: 'What is the Hub?',
          url: 'https://my.gooten.com/gootenguides/',
          target: '_blank',
          analyticsId: 'What is the Hub?',
          integration: 'all'
        },
        {
          type: 'Watch: ',
          text: 'Creating a Product in the Hub',
          url: 'https://my.gooten.com/gootenguides-2/',
          target: '_blank',
          analyticsId: 'Creating a Product in the Hub',
          integration: 'all'
        },
        {
          type: 'Read: ',
          text: 'Building a Better Gooten: New Features',
          url: 'https://www.gooten.com/blog/gooten/2017/10/23/building-a-better-gooten/',
          target: '_blank',
          analyticsId: 'Building a Better Gooten: New Features',
          integration: 'all'
        },
        {
          type: 'Webinar: ',
          text: 'Detailed Intro to the Product Hub',
          url: 'https://my.gooten.com/intro-to-hub-webinar/',
          target: '_blank',
          analyticsId: 'Detailed Intro to the Product Hub',
          integration: 'all'
        },
        {
          type: 'Blog: ',
          text: "Storeowner's Guide to Migrating Your Shopify Products",
          // eslint-disable-next-line
          url: `https://www.gooten.com/blog/gooten/2018/03/21/storeowners-guide-to-migrating-your-shopify-products-to-
                gooten/`,
          target: '_blank',
          analyticsId: "Storeowner's Guide to Migrating Your Shopify Products",
          integration: 'shopify'
        },
        {
          type: 'Blog: ',
          text: 'Design Trends for Print on Demand Products in 2018',
          // eslint-disable-next-line
          url: 'https://www.gooten.com/blog/design-tips/2017/12/11/design-trends-for-print-on-demand-products-in-2018/',
          target: '_blank',
          analyticsId: 'Design Trends for Print on Demand Products in 2018',
          integration: 'all'
        }
      ]
    },

    {
      index: 6,
      type: 'ReferenceCard',
      // means that section will always be hidden on UI, we can remove it in future
      integration: 'none',
      title: 'Need Help Connecting your Store?',
      onboardingStep: true,
      id: 'help_connecting_store',
      references: [
        {
          type: 'Watch: ',
          text: 'How to Add My Shopify Store to Gooten',
          url: 'https://my.gooten.com/gootenguides-how-to-add-your-shopify-store-print-on-demand/',
          target: '_blank',
          analyticsId: 'How to Add My Shopify Store to Gooten',
          integration: 'shopify'
        },
        {
          type: 'Watch: ',
          text: 'How to Add My Etsy Shop to Gooten',
          url: 'https://www.youtube.com/watch?v=EL-P3DQmEEI',
          target: '_blank',
          analyticsId: 'How to Add My Etsy Shop to Gooten',
          integration: 'etsy'
        },
        {
          type: 'Read: ',
          text: 'Etsy Guide: Getting Started With Gooten',
          url: 'https://www.gooten.com/blog/gooten/2019/02/14/etsy-print-on-demand-guide-getting-started-with-gooten/',
          target: '_blank',
          analyticsId: 'Etsy Guide: Getting Started With Gooten',
          integration: 'etsy'
        },
        {
          type: 'Watch: ',
          text: 'How to Create and Publish Etsy Products to Your Shop',
          url: 'https://www.youtube.com/watch?v=ia97JbAbfQw',
          target: '_blank',
          analyticsId: 'How to Create and Publish Etsy Products to Your Shop',
          integration: 'etsy'
        },
        {
          type: 'Watch: ',
          text: 'How to Connect Existing Etsy Products to Gooten',
          url: 'https://youtu.be/CHOGUlwoD-0',
          target: '_blank',
          analyticsId: 'How to Connect Existing Etsy Products to Gooten',
          integration: 'etsy'
        },
        {
          type: 'Watch: ',
          text: 'Using Gooten to Grow Your Ecommerce Store',
          url: 'https://my.gooten.com/guide-to-gooten-ecommerce-platform/',
          target: '_blank',
          analyticsId: 'Using Gooten to Grow Your Ecommerce Store',
          integration: 'all'
        },
        {
          type: 'Read: ',
          text: 'Best Practices to Becomming an Expert Seller with the Gooten Platform',
          // eslint-disable-next-line
          url: `https://www.gooten.com/blog/design-tips/2017/10/16/become-an-expert-seller-with-the-gooten-platform/
          ?utm_source=unbounce&utm_content=blog_krista&`,
          target: '_blank',
          analyticsId: 'Best Practices to Becomming an Expert Seller with the Gooten Platform',
          integration: 'all'
        },
        {
          type: 'Webinar: ',
          text: 'Optimizing Your Shopify Store',
          url: 'https://my.gooten.com/optimizing-your-shopify-store/',
          target: '_blank',
          analyticsId: 'Optimizing Your Shopify Store',
          integration: 'shopify'
        },
        {
          type: 'Free Download: ',
          text: 'List of Recommended Shopify Apps',
          url: 'https://my.gooten.com/recommended-shopify-apps-themes/',
          target: '_blank',
          analyticsId: 'List of Recommended Shopify Apps',
          integration: 'shopify'
        },
        {
          type: 'EBook: ',
          text: 'How to Launch an Ecommerce Store Without Losing Your Shirt',
          url: 'https://my.gooten.com/ecommerce-how-to-launch-your-store/',
          target: '_blank',
          analyticsId: 'How to Launch an Ecommerce Store Without Losing Your Shirt',
          integration: 'all'
        }
      ]
    },
    {
      index: 9,
      type: 'ResourceCard',
      integration: 'all',
      title: 'Resources',
      onboardingStep: false,
      resources: [
        {
          folderLabel: 'Preparing Your Artwork',
          links: [
            {
              type: '',
              text: 'How to Prepare your Artwork',
              // eslint-disable-next-line
              url: '',
              target: '_blank',
              analyticsId: 'How to Prepare your Artwork'
            },
            {
              type: '',
              text: 'Pixel Dimensions Requirements',
              // eslint-disable-next-line
              url: '',
              target: '_blank',
              analyticsId: 'Pixel Dimensions Requirements'
            },
            {
              type: '',
              text: 'Templates',
              url: 'https://gooten.box.com/s/u2stb6wkx1lzluql7zgbhjvzdmo4sqt3',
              target: '_blank',
              analyticsId: 'Templates'
            }
          ]
          /**
          folders: [
            {
              folderLabel: 'level 2 folder',
              links: [
                {
                  type:'',
                  text:'level 2 link',
                  url:'',
                  target:''
                }
              ]
            }
          ]
          **/
        },
        {
          folderLabel: 'Selling Your Products',
          links: [
            {
              type: '',
              text: 'Product Mockups',
              // eslint-disable-next-line
              url: '',
              target: '_blank',
              analyticsId: 'Product Mockups'
            },
            {
              type: '',
              text: 'Pricing',
              // eslint-disable-next-line
              url: '',
              target: '_blank',
              analyticsId: 'Pricing'
            },
            {
              type: '',
              text: 'Shipping',
              // eslint-disable-next-line
              url: '',
              target: '_blank',
              analyticsId: 'Shipping'
            },
            {
              type: '',
              text: 'Apparel Sizing',
              url: 'https://gooten.box.com/s/07dmhdzohyq4rab4236ephbdd7cxqq0p',
              target: '_blank',
              analyticsId: 'Apparel Sizing'
            },
            {
              type: '',
              text: 'Gooten Product Photography',
              url: '',
              target: '_blank',
              analyticsId: ''
            }
          ]
        },
        {
          folderLabel: 'Key Support Resources',
          links: [
            {
              type: '',
              text: 'Knowledge Base',
              url: 'https://help.gooten.com/hc/en-us',
              fujiUrl: 'https://fujihelp.gooten.com/hc/en-us',
              sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us',
              target: '_blank',
              analyticsId: 'Knowledge Base'
            },
            {
              type: '',
              text: 'Holiday Guidelines',
              url: 'https://help.gooten.com/hc/en-us/articles/360050944452-Holiday-Order-Submission-Guidance',
              // eslint-disable-next-line prettier/prettier
              fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/articles/6202305726747-Holiday-Order-Submission-Guidance',
              // eslint-disable-next-line prettier/prettier
              sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/articles/7666955876635-Holiday-Order-Submission-Guidance',
              target: '_blank',
              analyticsId: 'Holiday Guidelines'
            },
            {
              type: '',
              text: 'Production Ship Times',
              url: 'https://help.gooten.com/hc/en-us/categories/360003979952-Shipping-and-Production',
              // eslint-disable-next-line prettier/prettier
              fujiUrl: 'https://fujihelp.gooten.com/hc/en-us/categories/4530133827483-Shipping-and-Production',
              // eslint-disable-next-line prettier/prettier
              sensariaUrl: 'https://sensariahelp.gooten.com/hc/en-us/categories/7613423723675-Shipping-Production',
              target: '_blank',
              analyticsId: 'Production Ship Times'
            }
          ]
        }
      ]
    },
    {
      index: -10,
      monitors: 'products',
      type: 'ReferenceCard',
      integration: 'all',
      title: 'Your Guide to Holidays 2018',
      onboardingStep: false,
      id: 'help_holiday_sales',
      expiration: '12-31-18',
      references: [
        {
          type: 'Watch: ',
          text: 'Keys to a Stress-free and Successful Holiday Sales Season',
          url: 'https://my.gooten.com/holiday-success-webinar/',
          target: '_blank',
          analyticsId: 'Keys to a Stress-free and Successful Holiday Sales Season',
          integration: 'all'
        },
        {
          type: 'Read: ',
          text: 'Gooten’s Holiday Shipping Cut-off Dates',
          url: 'https://www.gooten.com/blog/holiday-season/2018/10/03/gootens-guide-to-holiday-shipping-cut-off-dates/',
          target: '_blank',
          analyticsId: 'Gooten’s Holiday Shipping Cut-off Dates',
          integration: 'all'
        },
        {
          type: 'Free Download: ',
          text: 'Guide to Contacting Gooten Support During Holiday Rush',
          url: 'https://my.gooten.com/guide-to-customer-support/',
          target: '_blank',
          analyticsId: 'Guide to Contacting Gooten Support During Holiday Rush',
          integration: 'all'
        },
        {
          type: 'Read: ',
          text: 'Game Plan for More Sales This Holiday Season',
          // eslint-disable-next-line
          url: `https://www.gooten.com/blog/holiday-season/2018/09/17/2018-holiday-sales-guide-your-game-plan-for-a-
          successful-and-stress-free-season/`,
          target: '_blank',
          analyticsId: 'Game Plan for More Sales This Holiday Season',
          integration: 'all'
        },
        {
          type: 'Products To Add: ',
          text: 'Recommended Holiday Products Your Store Needs',
          url: 'https://my.gooten.com/2018-holiday-ecommerce-guides/',
          target: '_blank',
          analyticsId: 'Recommended Holiday Products Your Store Needs',
          integration: 'all'
        }
      ]
    },
    {
      index: -11,
      type: 'OLMResourceCard',
      integration: 'all',
      title: 'Resources',
      onboardingStep: false,
      resources: [
        {
          folderLabel: '',
          links: [
            {
              type: '',
              text: 'Catalog',
              // eslint-disable-next-line
              url: 'https://www.gooten.com/dropshipping-products/print-on-demand-trending/',
              target: '_blank',
              analyticsId: 'Merchcast - Catalog'
            },
            {
              type: '',
              text: 'Design Guides',
              // eslint-disable-next-line
              url: 'https://www.gooten.com/print-on-demand/design-guides/',
              target: '_blank',
              analyticsId: 'Merchcast - Design Guides'
            },
            {
              type: '',
              text: 'Templates',
              // eslint-disable-next-line
              url: 'https://gooten.box.com/s/evekh1n80jpkbmsvfopa8ocw2jbnh8sl',
              target: '_blank',
              analyticsId: 'Merchcast - Templates'
            },
            {
              type: '',
              text: 'Learn more about Merchcast',
              url: 'https://my.gooten.com/merchcast/',
              target: '_blank',
              analyticsId: 'Learn more about Merchcast'
            }
          ]
        }
      ]
    }
  ]
};

export default onboardingData;
