import { itemsDataSelector } from 'gooten-components/src/store/selectors/hubSelectors';
import { connect } from 'react-redux';
import { editDesign } from '../../../../CustomOrderForm/EditDesignView/EditDesignViewActions';
import { mapEditDesignItemFromOrderItem } from '../../../OrderDetails/ImagesView/ImagesViewContainer';
import { getSharedOrderData, splitOrderItem } from '../../../OrderDetails/OrderDetailsActions';
import { loadingStatus, summaryData } from '../../../OrderDetails/OrderDetailsSelectors';
import { updatePersonalizedImages } from '../../OrdersViewActions';
import PersonalizeActionsComponent from './PersonalizeActionsComponent';

const mapDispatchToProps = {
  getSharedOrderData: orderId => getSharedOrderData(orderId),
  editDesign: orderItem => {
    return editDesign(mapEditDesignItemFromOrderItem(orderItem));
  },
  splitOrderItem: (orderId, orderItemId) => splitOrderItem(orderId, orderItemId),
  updatePersonalizedImages: data => updatePersonalizedImages(data)
};

const mapStateToProps = state => {
  return {
    loading: loadingStatus(state),
    itemsData: itemsDataSelector(state),
    summaryData: summaryData(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizeActionsComponent);
