/**
 * Bind event listeners with optional debounce and throttle modes.
 *
 * @param {object} el        The object to bind an event to.
 * @param {string} eventType The type of event to listen for.
 * @param {object} handler   The callback handler.
 * @param {object} options   Options to modify behavior.
 *                         - delay: Time in milliseconds. Behavior depends on mode.
 *                         - init: Whether the handler should be called when registered. Default is false.
 *                         - mode: 'debounce' or 'throttle'. Default is false.
 */
export const bind = ( el, eventType, handler, options ) => {
  	let eventArgs = [ eventType ],
    callback, doCallback, method, settings, timeoutId;

  settings = {
    delay: 0,
    init: false, // Set to true to fire when registered.
    mode: false // debounce|throttle
  };

  for ( var o in settings ) {
    if ( o in options ) {
      settings[ o ] = options[ o ];
    }
  }

  doCallback = 'throttle' === settings.mode ? true : false;

  // Callback wraps the handler to implement debounce/throttle logic.
  callback = function() {
    if ( 'throttle' !== settings.mode || doCallback ) {
      doCallback = false;

      if ('debounce' === settings.mode ) {
        clearTimeout( timeoutId );
      }

      timeoutId = setTimeout( function() {
        doCallback = 'throttle' === settings.mode ? true : false;
      }, settings.delay );
    }
  };

  eventArgs.push( callback );

  method = 'addEventListener';
  eventArgs.push( false ); // useCapture parameter.

  // Add the event listener.
  if ( method ) {
    el[ method ].apply( el, eventArgs );
  }

  // Call the handler immediately.
  if ( settings.init ) {
    handler.call( el, eventType );
  }
};

/**
 * Prevent event bubble
 * https://social.msdn.microsoft.com/Forums/ie/en-US/7d1bf031-bb9e-4218-a43d-bc93c4f75bf2/javascript-cancelbubble-not-working-in-internet-explorer-11?forum=iewebdevelopment
 *
 * @param  {object} e		event
 */
export const noBubble = ( e ) => {
  if (e) {
    e.returnValue = false;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
  }
};
