import { List } from 'immutable';
import { fetch, $delete } from '../../../../../utils/http';
import Config from '../../../../../config';
import { mapAddress } from '../../../../../models/Address';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId'),
      partnerBillingKey: Config.get('storeApiKey')
    }
  };
};

const shippingAddressesUrl = shippingCountry =>
  `${Config.get('api')}shippingaddresses?countryCode=${shippingCountry}`;

const deleteShippingAddressUrl = addressId =>
  `${Config.get('api')}shippingaddresses?addressId=${addressId}`;

class ShippingAddressesService {
  getShippingAddresses(shippingCountry, requestParams) {
    return new Promise((resolve, reject) => {
      let combinedParams = getAuthQueryParams();
      let queryParams = combinedParams.queryParams;
      combinedParams.queryParams = { ...queryParams, ...requestParams };
      fetch(shippingAddressesUrl(shippingCountry), combinedParams)
        .then(res => {
          if (!res.error) {
            const addresses = new List(res.ShippingAddresses.map(mapAddress));
            const total = res.Total;
            resolve({ addresses, total });
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteShippingAddress(addressId) {
    return $delete(deleteShippingAddressUrl(addressId), null, getAuthQueryParams());
  }
}

// singleton
export default new ShippingAddressesService();
