import React from 'react';
import PropTypes from 'prop-types';
import { whiteSpaces } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import Switch from 'gooten-components/src/components/shared/Switch';

const ActionsLineItem = props => {
  const buttonType = `${props.title ? (props.switch ? 'switch' : 'title') : 'icon'}`;

  return (
    <div
      className="actions-line-item flex-container mb-1 mt-1 pt-2 pb-2 pr-1 pl-1"
      onClick={() => props.onClick(props.title.replace(whiteSpaces, '').toLowerCase())}
    >
      <Button className="button-default medium">
        {
          {
            switch: (
              <>
                <Switch
                  className="action-switch"
                  checked={props.checked}
                  disabled
                  onChange={() => props.onClick(props.title.replace(whiteSpaces, '').toLowerCase())}
                />
                <span>{props.title}</span>
              </>
            ),
            title: (
              <>
                <Icon icon={props.icon} />
                <span>{props.title}</span>
              </>
            ),
            icon: <Icon icon={props.icon} />
          }[buttonType]
        }
      </Button>
      <style jsx>
        {`
          :global(.desktop-actions) .actions-line-item :global(.action-switch) {
            margin: 0 8px 0 0;
            cursor: pointer;
          }
          :global(.mobile-actions) .actions-line-item :global(.action-switch) {
            margin: 0 8px 0 0;
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

ActionsLineItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  switch: PropTypes.bool,
  checked: PropTypes.bool
};

export default ActionsLineItem;
