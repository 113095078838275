import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';

export const SearchInput = props => {
  const onSearchChange = event => props.onSearchChange(event.target.value);

  return (
    <div className={'search-input-container' + (props.className ? ` ${props.className}` : '')}>
      <Input
        required
        type="text"
        icon="search"
        className="input seach-field"
        iconPlacement="leading"
        noLabel
        onChange={onSearchChange}
        value={props.searchText}
      />
      <Button
        iconOnly
        className="button-default large search-input-button hidden"
        onClick={props.onSearchClick}
      >
        <Icon icon="search" />
      </Button>
    </div>
  );
};

SearchInput.propTypes = {
  searchText: PropTypes.string,
  className: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func
};

export default SearchInput;
