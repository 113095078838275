import React from 'react';
import PropTypes from 'prop-types';
import './OrderPanelLayout.scss';
import Button from '../../components/shared/Button';
import PageBody from '../../components/shared/PageBody';

const getComponent = (children, key) => {
  return children.filter(c => c.key === key);
};

export const OrderPanelLayout = props => {
  const buttonsContainerTop = (
    <div className="buttons-container top visible-xs-block">
      <Button
        className={`button-cta large full-width`}
        disabled={props.nextButtonDisabled}
        onClick={() => props.onNextButtonClick()}
      >
        {props.nextButtonLabel}
      </Button>
      {props.after}
    </div>
  );

  const buttonsContainerBottom = (
    <div className="buttons-container bottom pr-4 pl-4 pt-3 pb-3">
      <Button
        className={`button-cta large full-width`}
        disabled={props.nextButtonDisabled}
        onClick={() => props.onNextButtonClick()}
      >
        {props.nextButtonLabel}
      </Button>
      {props.after}
    </div>
  );

  return (
    <PageBody size="full" className="order-panel-layout-container position-relative">
      <div className="left-side-container">
        <div className="left-side-content">
          {getComponent(props.children, 'left-side-title')}
          {props.nextButtonHidden ? null : buttonsContainerTop}
          {getComponent(props.children, 'left-side')}
        </div>
      </div>
      <div className="right-side-container">
        <div className="right-side-content">{getComponent(props.children, 'right-side')}</div>
      </div>
      {props.nextButtonHidden ? null : buttonsContainerBottom}
    </PageBody>
  );
};

OrderPanelLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  onNextButtonClick: PropTypes.func,
  nextButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  nextButtonDisabled: PropTypes.bool,
  nextButtonHidden: PropTypes.bool,
  after: PropTypes.element
};

export default OrderPanelLayout;
