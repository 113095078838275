import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'gooten-components/src/components/shared/Spinner';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Icon from 'gooten-components/src/components/shared/Icon';
import { COLORS } from 'gooten-components/src/constants';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';
import HoverImage from 'gooten-components/src/components/shared/HoverImage';

export const ProductsListItem = props => {
  const [showHoverImage, setShowHoverImage] = useState(false);
  const storeName = props.product.get('storeName');
  const productHandle = props.product.get('handle');
  const storeLink =
    storeName && productHandle && props.product.get('status') === 'published'
      ? productHandle.startsWith('http')
        ? productHandle
        : `https://${storeName}.myshopify.com/products/${productHandle}`
      : null;
  const dataTip = props.provider
    ? `View product on ${formattedProvider(props.provider.toLowerCase())}`
    : 'View product in store';
  return (
    <>
      <TableRow
        className={'products-list-item-container' + (props.selected ? ' selected' : '')}
        columns="minmax(5%, 20%) auto"
        size="medium"
        disabled={props.product.get('totalVariants') == 0}
        onClick={() =>
          props.product.get('totalVariants') == 0 ? null : props.select(!props.selected)
        }
      >
        <Cell className="selection-column pt-2 pb-2">
          <div className="ignore-clicks">
            <Checkbox checked={props.selected} onChange={() => props.select(!props.selected)} />
          </div>
          <div
            onMouseOver={() => setShowHoverImage(true)}
            onMouseLeave={() => setShowHoverImage(false)}
            className="hidden-xs m-auto position-relative sync-product-list-thumbnail"
          >
            {showHoverImage && props.product.get('image') && (
              <HoverImage image={props.product.get('image')} />
            )}
            {props.pending ? (
              <Spinner />
            ) : props.product.get('image') ? (
              <Thumbnail
                image={props.product.get('image')}
                alt={props.product.get('name')}
                className="medium"
              />
            ) : (
              <div className="img-missing-3" />
            )}
          </div>
        </Cell>
        <Cell>
          <div className="col-xs-6 product-name">{props.product.get('name')}</div>
          <div className="col-sm-2 col-xs-3 text-center">
            {props.product.get('connectedVariants')}
          </div>

          <div className="col-sm-2 col-xs-3 text-center">{props.product.get('totalVariants')}</div>

          <div className="col-sm-2 hidden-xs in-store-container text-center">
            <span onClick={e => e.stopPropagation()}>
              <a
                disabled={!storeLink}
                href={storeLink}
                target="_blank"
                className={storeLink ? 'active' : ''}
              >
                <Tooltip content={dataTip} containerClassName="m-auto">
                  <Icon icon="externalLink" fill={storeLink ? '' : COLORS.neutralLight300} />
                </Tooltip>
              </a>
            </span>
          </div>
        </Cell>
      </TableRow>
      <style jsx>
        {`
          .ignore-clicks {
            pointer-events: none;
          }
          .img-missing-3 {
            background-size: 100%;
            height: 59px;
            width: 56px;
          }
          .product-name {
            white-space: normal;
            line-height: 1.2;
            word-break: break-word;
          }
          .sync-product-list-thumbnail {
            width: 4rem;
            height: 4rem;
          }
          .sync-product-list-thumbnail :global(.cerulean-thumbnail) {
            position: absolute;
            top: 0;
          }
        `}
      </style>
    </>
  );
};

function formattedProvider(provider) {
  switch (provider) {
    case 'woocommerce':
      return 'WooCommerce';
    case 'bigcommerce':
      return 'BigCommerce';
    default:
      return provider[0].toUpperCase() + provider.slice(1).toLowerCase();
  }
}

ProductsListItem.propTypes = {
  product: PropTypes.any.isRequired,
  selected: PropTypes.bool,
  select: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  provider: PropTypes.string
};

export default ProductsListItem;
