import { ActionNameFactory } from '../../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'COLLECTIONS');
export const ADD_COLLECTION = getActionName('ADD_COLLECTION');
export const REMOVE_COLLECTION = getActionName('REMOVE_COLLECTION');
export const NEW_COLLECTION = getActionName('NEW_COLLECTION');

export const addCollection = payload => ({
  type: ADD_COLLECTION,
  payload
});

export const removeCollection = payload => ({
  type: REMOVE_COLLECTION,
  payload
});

export const newCollection = payload => ({
  type: NEW_COLLECTION,
  payload
});
