import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('HUB', 'PRODUCTION_REPORT');

export const FETCH_REPORT_DATA = getActionName.async('FETCH_REPORT_DATA');
export const SET_SELECTED_PERIOD = getActionName('SET_SELECTED_PERIOD');
export const SET_SORT = getActionName('SET_SORT');
export const RESET_FILTER_AND_SORT = getActionName('RESET_FILTER_AND_SORT');

export const setSelectedPeriod = payload => ({
  type: SET_SELECTED_PERIOD,
  payload
});

export const setSort = payload => ({
  type: SET_SORT,
  payload
});

export const resetFilterAndSort = () => ({
  type: RESET_FILTER_AND_SORT
});
