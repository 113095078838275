import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../shared/Button';
import TextInput from '../../../shared/TextInput';
import Modal from '../../../shared/Modal';
import Config from '../../../../config';

import './ConnectStore.scss';

const ConnectStore = ({
  busy,
  connectStep,
  selectedProvider,
  errorMsg,
  setConnectStep,
  connectStore
}) => {
  const title = useMemo(() => {
    switch (selectedProvider) {
      case 'etsy':
        return 'Connect your Etsy store';
      case 'shopify':
        return 'Connect your Shopify store';
      case 'woocommerce':
        return 'Connect your WooCommerce';
      case 'bigcommerce':
        return 'Connect your BigCommerce';
    }
  }, [selectedProvider]);

  const blurb = useMemo(() => {
    switch (selectedProvider) {
      case 'etsy':
        return 'You will be redirected to your Etsy account and prompted to allow Gooten to access it.';
      case 'shopify':
        return 'You will be redirected to install the Gooten app in your Shopify store';
      case 'woocommerce':
        return 'You will be redirected to your WooCommerce account and prompted to allow Gooten to access it.';
      case 'bigcommerce':
        return 'You will be redirected to install the Gooten app in your BigCommerce store';
    }
  }, [selectedProvider]);

  const placeholder = useMemo(() => {
    switch (selectedProvider) {
      case 'etsy':
        return 'Store name';
      case 'shopify':
        return 'myperfectstore';
      case 'woocommerce':
        return 'your-store.com';
      case 'bigcommerce':
        return 'your-store.mybigcommerce.com';
    }
  }, [selectedProvider]);

  const label = useMemo(() => {
    switch (selectedProvider) {
      case 'etsy':
        return 'Store name';
      case 'shopify':
        return 'Enter Your Shopify Store Address';
      case 'woocommerce':
        return 'Store URL';
      case 'bigcommerce':
        return 'Unique store URL id';
    }
  }, [selectedProvider]);

  const [storeName, setStoreName] = useState('');

  const inputAddon = <div className="input-group-addon">.myshopify.com</div>;

  useEffect(() => {
    if (connectStep === 0) {
      setStoreName('');
    }
  }, [connectStep]);

  const getIntegratingYourStoresKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/sections/360010012952-Integrating-your-stores';

    switch (partnerGroup.get('Id')) {
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/sections/7612085950619-Integrating-your-stores';
      default:
        return 'https://help.gooten.com/hc/en-us/sections/360010012952-Integrating-your-stores';
    }
  };

  return (
    <Modal
      className="large connect-store"
      isOpen={connectStep === 2}
      cancelClick={() => setConnectStep(0)}
    >
      <style jsx>{`
        .wrapper {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }

        .body {
          display: grid;
          grid-template-rows: 1fr;
          padding: 2rem;
        }

        .side-image {
          background-size: cover;
          height: 400px;
          width: 100%;
          overflow: hidden;
          background-position: center;
        }

        .actions {
          border-top: 1px solid lightgray;
        }

        :global(.cerulean-modal.connect-store) {
          overflow: hidden;
        }
        @media screen and (max-width: 576px) {
          .wrapper {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
      <div className="wrapper">
        <div className={`side-image ${selectedProvider}`} />
        <div className="body">
          <div className="d-flex flex-column">
            <div className="title-1 flex-container-column-base">{title}</div>
            <TextInput
              id="storeName"
              inputAddon={selectedProvider === 'shopify' ? inputAddon : null}
              label={label}
              customError={errorMsg}
              placeholder={placeholder}
              value={storeName}
              onChange={value => setStoreName(value)}
            />

            <div className="body-text-2">{blurb}</div>
            <div className="mb-2 mt-auto">
              <a target="_blank" href={getIntegratingYourStoresKbLink()}>
                Having trouble?
              </a>
            </div>
          </div>
          <div className="actions pt-4 d-flex justify-content-end">
            <Button className="action button-default large mr-2" onClick={() => setConnectStep(0)}>
              Cancel
            </Button>
            <Button
              className="action button-primary large"
              onClick={() => connectStore(selectedProvider, storeName)}
              loading={busy}
              disabled={storeName.trim() === ''}
            >
              Connect store
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ConnectStore.propTypes = {
  busy: PropTypes.bool,
  connectStep: PropTypes.number.isRequired,
  selectedProvider: PropTypes.string,
  errorMsg: PropTypes.string.isRequired,
  setConnectStep: PropTypes.func.isRequired,
  connectStore: PropTypes.func.isRequired
};

export default ConnectStore;
