import { Record, List } from 'immutable';
import camelcaseKeysDeep from 'camelcase-keys-deep';

const AddToCartRequestSkuImage = new Record({
  spaceId: '',
  imageUrl: '',
  imageId: 0,
  il: null,
  prpIL: null
});

const AddToCartRequestSku = new Record({
  productName: '',
  sku: '',
  images: new List(), // List of AddToCartRequestSkuImage
  neck_tag_id: '',
  neck_tag_image_url: '',
  neck_tag_id: '',
  orientation: undefined,
  prpSKU: '',
  isSample: false
});

const AddToCartRequestProduct = new Record({
  productId: 0,
  skus: new List()
});

const AddToCartRequest = new Record({
  products: new List() // List of AddToCartRequestProduct
});

export default AddToCartRequest;

// Used in COF Past Orders
export const mapAddToCartRequestFromOrderItems = orderItems => {
  var orderItemsByProduct = orderItems.groupBy(oi => oi.productId).toJS();
  // NOTE: Need to group by product id - since we will load product info sequentially
  return new AddToCartRequest({
    products: new List(
      Object.keys(orderItemsByProduct).map(
        productId =>
          new AddToCartRequestProduct({
            productId: parseInt(productId),
            skus: new List(
              orderItemsByProduct[productId].map(oi =>
                AddToCartRequestSku({
                  productName: oi.productName,
                  sku: oi.sku,
                  neck_tag_image_url: oi.meta['addon_necktag_image_url'],
                  neck_tag_id: oi.meta['necktag_id'],
                  images: new List(
                    oi.images.map(
                      i =>
                        new AddToCartRequestSkuImage({
                          spaceId: i.spaceId,
                          imageUrl: i.imageUrl,
                          imageId: i.imageId
                        })
                    )
                  ),
                  isSample: oi.isSample
                })
              )
            )
          })
      )
    )
  });
};

// Used in Hub, Order From Hub
export const mapAddToCartRequestFromHubProducts = hubProducts => {
  var hubProductsByProduct = hubProducts
    .groupBy(p => p.gootenMapping.getIn(['items', 0, 'productId']))
    .toJS();
  // NOTE: Need to group by product id - since we will load product info sequentially
  return new AddToCartRequest({
    products: new List(
      Object.keys(hubProductsByProduct).map(
        productId =>
          new AddToCartRequestProduct({
            productId: parseInt(productId),
            skus: new List(
              hubProductsByProduct[productId].map(oi =>
                AddToCartRequestSku({
                  productName: oi.productName,
                  sku: oi.gootenMapping.items[0].sku,
                  prpSKU: (oi.gootenMapping && oi.gootenMapping.sku) || '',
                  neck_tag_image_url: oi.gootenMapping && oi.gootenMapping.neck_tag_image_url,
                  neck_tag_id: oi.gootenMapping && oi.gootenMapping.neck_tag_id,
                  orientation: oi.gootenMapping && oi.gootenMapping.orientation,
                  images: new List(
                    oi.gootenMapping.items[0].preconfigurations.map(
                      i =>
                        new AddToCartRequestSkuImage({
                          spaceId: i.spaceId,
                          imageId: i.imageId,
                          imageUrl: i.url,
                          il: i.il,
                          // used in COF for comparsion to check whether prp image was edited
                          prpIL: i.il
                        })
                    )
                  ),
                  isSample: oi.isSample
                })
              )
            )
          })
      )
    )
  });
};

// Used in COF Saved Products
export const mapAddToCartRequestFromGootenMappings = gootenMappings => {
  var gootenMappingsByProduct = gootenMappings
    .groupBy(p => p.getIn(['items', 0, 'product_id']))
    .toJS();
  return new AddToCartRequest({
    products: new List(
      Object.keys(gootenMappingsByProduct).map(
        productId =>
          new AddToCartRequestProduct({
            productId: parseInt(productId),
            skus: new List(
              gootenMappingsByProduct[productId].map(oi =>
                AddToCartRequestSku({
                  productName: oi.items[0].product_variant_sku,
                  sku: oi.items[0].product_variant_sku,
                  prpSKU: oi.sku,
                  neck_tag_image_url: oi.neck_tag_image_url,
                  neck_tag_id: oi.neck_tag_id,
                  orientation: oi.orientation,
                  images: new List(
                    oi.items[0].preconfigurations.map(
                      i =>
                        new AddToCartRequestSkuImage({
                          spaceId: i.space_id,
                          imageUrl: i.url,
                          imageId: i.image_id,
                          il: camelcaseKeysDeep(i.il),
                          // used in COF for comparsion to check whether prp image was edited
                          prpIL: camelcaseKeysDeep(i.il)
                        })
                    )
                  )
                })
              )
            )
          })
      )
    )
  });
};
