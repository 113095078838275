import StateManager from '../../../state/stateManager';
import { toPortrait, toLandscape } from '../../../state/actions/actionCreators';
// import PositioningService from '../../../utils/positioningService';
import EventEmitter from 'events';
import { PUBLIC_EVENTS } from '../../../ImageEditor.events';

export class OrientationButton {
  customClass: string;
  name: string;
  publicEvents: EventEmitter;

  constructor(stateManager: StateManager, config: any, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;

    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Orientation';
    this.stateManager = stateManager;
  }

  getElement() {
    let element = document.createElement('div');
    element.className += ` editor-btn ${this.customClass}`;
    element.innerHTML = `<div class="icon-orientation-left toolbar-icon"></div>
    <span class="editor-tooltip-text">${this.name}</span>`;

    element.addEventListener('click', () => this.action());
    return element;
  }

  action() {
    let state = this.stateManager.getState();
    if (state.editor.orientation === 'landscape') {
      this.stateManager.dispatchAction(toPortrait());
    }
    else {
      this.stateManager.dispatchAction(toLandscape());
    }

    this.publicEvents.emit(PUBLIC_EVENTS.ORIENTATION_CHANGE);
  }
}
