import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ProductsGridItem from './ProductsGridItem';

import './ProductsGrid.scss';

export const ProductsGrid = props => {
  return (
    <div className="products-grid-container">
      {props.pendingProducts.size
        ? props.pendingProducts.map((p, i) => (
            <ProductsGridItem
              key={`pending_product_${i}`}
              selected={false}
              select={() => undefined}
              image={''}
              pending
              name={p.get('name')}
              desc={
                p.get('type') === 'store'
                  ? `${p.get('connectedVariants')}/${p.get('totalVariants')} SKUS`
                  : 'STORAGE'
              }
              storeName={p.get('storeName')}
              item={p}
              onSelectModalOption={props.onSelectModalOption}
              currentProvider={props.currentProvider}
            />
          ))
        : null}
      {props.products.map(p => (
        <ProductsGridItem
          key={p.get('id') || p.get('name')}
          selected={!!props.selected.find(e => e.get('id') === p.get('id'))}
          select={() =>
            !props.selected.find(e => e.get('id') === p.get('id'))
              ? props.select(p)
              : props.unselect(p)
          }
          selectExclusive={() => props.selectExclusive(p)}
          image={p.get('image')}
          name={p.get('name')}
          desc={
            p.get('type') === 'store'
              ? `${p.get('connectedVariants')}/${p.get('totalVariants')} SKUS`
              : 'STORAGE'
          }
          storeName={p.get('storeName')}
          item={p}
          onSelectModalOption={props.onSelectModalOption}
          currentProvider={props.currentProvider}
        />
      ))}
      <style jsx>
        {`
          .products-grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            row-gap: 1rem;
            column-gap: 1rem;
            padding: 0.5rem 0 2rem;
          }
          .product-landing {
            align-items: center;
            justify-content: center;
          }
          .landing-body {
            max-width: 18rem;
            border-radius: 4px;
            height: 100%;
            border: 1px solid #ededed;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
        `}
      </style>
    </div>
  );
};

ProductsGrid.propTypes = {
  products: PropTypes.instanceOf(List).isRequired,
  // pending products are products which recently was created, updated, deleted
  // and task is not finished yet
  pendingProducts: PropTypes.instanceOf(List).isRequired,
  /* eslint-disable */
  selected: PropTypes.instanceOf(List).isRequired,
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  selectExclusive: PropTypes.func.isRequired,
  onSelectModalOption: PropTypes.func,
  /* eslint-enable */
  currentProvider: PropTypes.string
};

export default ProductsGrid;
