import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import SearchInput from '../../../../SearchInput'
import OptionsModal from '../../../../OptionsModal';
import { List } from 'immutable';
import { windowWidth } from '../../../../ComponentsHooks';
import Button from '../../../../Button';
import Icon from '../../../../Icon';
import DropDownContainer from '../../../../DropDown/DropDownContainer';
import ListItem from '../../../../ListItem';
import { COLORS } from '../../../../../../constants';

const Container = styled.div`
  flex-direction: column;
  margin: 0 30px 10px 20px;

  button.space-selection {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .search-input-container {
    height: 40px;
    width: 200px;

    .input-group {
      input {
        height: 38px;
      }
    }

    .input-group-btn button {
      height: 38px;
    }
  }

  .options-modal {
    position: absolute;
    top: ${props => (props.showSpaceSelection ? '136' : '54')}px;
    left: ${props => (props.isFilter ? 15 : 107)}px;
  }
`;

const ButtonsHolder = styled.div`
  margin-top: 15px;
  height: 40px;

  button {
    float: right;
  }
`;

const FILTER_TITLE = 'FILTER';
const SORT_TITLE = 'SORT';

const Toolbar = props => {
  const width = windowWidth();
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [isFilterModal, setFilterModal] = useState(false);

  const [filter, setFilter] = useState(props.filterTitle || FILTER_TITLE);
  const [sort, setSort] = useState(props.sortTitle || SORT_TITLE);

  useEffect(() => {
    setFilter(props.filterTitle || FILTER_TITLE);
    setSort(props.sortTitle || SORT_TITLE);
  }, [props.filterTitle, props.sortTitle]);

  const onSelectOptionItem = (item, title) => {
    isFilterModal ? setFilter(title) : setSort(title);
    props.onSelectOptionItem(item, isFilterModal);
  };

  const renderSort = () => {
    const isSelected = sort !== SORT_TITLE;
    return (
      <Button
        className={`mr-2 ${isSelected ? 'button-secondary small' : 'button-default small'}`}
        onClick={() => {
          setFilterModal(false);
          setShowOptionsModal(!showOptionsModal && !isSelected);
          if (isSelected) {
            setSort(SORT_TITLE);
            props.onSelectOptionItem('', false);
          }
        }}
      >
        {sort}&nbsp;
        {isSelected ? (
          <Icon className="mr-n2 ml-2" small icon="x" />
        ) : (
          <Icon className="mr-n2 ml-2" small icon="chevronDown" />
        )}
      </Button>
    );
  };

  const renderFilter = () => {
    const isSelected = filter !== FILTER_TITLE;
    return (
      <Button
        className={`mr-2 ${isSelected ? 'button-secondary small' : 'button-default small'}`}
        onClick={() => {
          setFilterModal(true);
          setShowOptionsModal(!showOptionsModal && !isSelected);
          if (isSelected) {
            setFilter(FILTER_TITLE);
            props.onSelectOptionItem('all', true);
          }
        }}
      >
        {filter}&nbsp;
        {isSelected ? (
          <Icon className="mr-n2 ml-2" small icon="x" />
        ) : (
          <Icon className="mr-n2 ml-2" small icon="chevronDown" />
        )}
      </Button>
    );
  };

  return (
    <Container
      className="image-selection-toolbar flex-container"
      isFilter={isFilterModal}
      showSpaceSelection={props.showSpaceSelection && width <= 992}
    >
      {props.showSpaceSelection ? (
        <Button className="button-default mt-3 large space-selection" onClick={props.onShowSpaces}>
          Editing {props.title}
          <Icon icon="chevronDown" className="ml-3 mr-n3" />
        </Button>
      ) : null}
      <ButtonsHolder className="flex-container">
        <div className="flex-container" style={{ alignItems: 'flex-end' }}>
          {/* <SearchInput /> */}
          {props.hideFilter ? null : renderFilter()}
          {props.hideSort ? null : renderSort()}
        </div>
        <div style={{ flexGrow: 8 }}>
          {props.showAdditionalFilesUpload ? (
            <>
              <DropDownContainer rightAlign title="Upload new artwork">
                <ListItem onSelect={() => props.onUploadNewArtwork()} text="PNG file" />
                <ListItem onSelect={() => props.onUploadAdditionalFiles()} text="DST file" />
              </DropDownContainer>
              <style jsx>{`
                :global(.dropdown-container) {
                  max-width: none !important;
                }
                :global(.dropdown-container button) {
                  color: ${COLORS.neutralLight50} !important;
                  fill: ${COLORS.neutralLight50} !important;
                  background: ${COLORS.gootenBlue500} !important;
                  width: auto !important;
                }
                :global(.dropdown-text span) {
                  color: ${COLORS.neutralLight50} !important;
                }
              `}</style>
            </>
          ) : (
            <Button
              className="button-primary large hidden-xs upload-new"
              onClick={() => props.onUploadNewArtwork()}
            >
              Upload new artwork
            </Button>
          )}
        </div>
        {showOptionsModal ? (
          <OptionsModal
            items={isFilterModal ? props.filterItems.toJS() : props.sortItems.toJS()}
            onClickItem={onSelectOptionItem}
            onDismiss={() => setShowOptionsModal(false)}
          />
        ) : null}
      </ButtonsHolder>
    </Container>
  );
};

Toolbar.propTypes = {
  onShowSpaces: PropTypes.func,
  onUploadNewArtwork: PropTypes.func,
  onUploadAdditionalFiles: PropTypes.func,
  sortItems: PropTypes.instanceOf(List).isRequired,
  filterItems: PropTypes.instanceOf(List).isRequired,
  onSelectOptionItem: PropTypes.func.isRequired,
  showSpaceSelection: PropTypes.bool,
  title: PropTypes.string,
  filterTitle: PropTypes.string,
  sortTitle: PropTypes.string,
  hideFilter: PropTypes.bool,
  hideSort: PropTypes.bool,
  showAdditionalFilesUpload: PropTypes.bool
};

export default Toolbar;
