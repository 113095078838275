import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import OrdersView from './OrdersView';
import OrderDetails from './OrderDetails';
import OrderItemsSelection from './OrderDetails/OrderItemsSelection';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import EditDesignView from './OrderDetails/EditDesignView';

const ordersRoutes = {
  path: '/orders-new',
  component: CoreLayout,
  indexRoute: { component: withErrorBoundary(OrdersView) },
  childRoutes: [
    {
      path: 'reprint/:id',
      component: withErrorBoundary(OrderItemsSelection)
    },
    {
      path: 'refund/:id',
      component: withErrorBoundary(OrderItemsSelection)
    },
    {
      path: 'reorder/:id',
      component: withErrorBoundary(OrderItemsSelection)
    },
    {
      path: 'edit-design(/:step)',
      component: withErrorBoundary(EditDesignView)
    },
    {
      path: ':id(/:tab)',
      component: withErrorBoundary(OrderDetails)
    }
  ]
};

export default ordersRoutes;
