import React from 'react';
import PropTypes from 'prop-types';
import TextInputWithButton from '../../../../TextInputWithButton';
import { imagesPattern, imagesPNGPattern } from '../../../../../../utils/regex';
import { getImageDimensions } from '../../../../../../utils/image';

const UploadFromUrl = props => {
  let textInput = React.createRef();

  const checkImageRequirements = (url, onSuccess) => {
    // check if required size is satisfied
    if (props.requiredImageSize) {
      getImageDimensions(null, url).then(val => {
        if (props.requiredImageSize.some(r => val.width === r.width && val.height === r.height)) {
          onSuccess();
        } else {
          props.onError('Image does not meet size requirements');
        }
      });
    } else {
      onSuccess();
    }
  };

  return (
    <div className="image-selection-upload-url mt-3">
      <TextInputWithButton
        label="Upload from Url"
        placeholder="Paste image URL"
        onButtonClick={() =>
          textInput.current.state.value.length
            ? checkImageRequirements(textInput.current.state.value, () => {
                props.uploadFromUrls([textInput.current.state.value]);
              })
            : null
        }
        buttonText="Upload from URL"
        showValidation
        ref={textInput}
        validationMsg="This URL isn't working. Check supported file type or try another one!"
        // TO-DO use this file types to make regex pattern, instead of this bellow
        regexp={props.fileTypes.indexOf('jpeg') === -1 ? imagesPNGPattern : imagesPattern}
      />
    </div>
  );
};

UploadFromUrl.propTypes = {
  uploadFromUrls: PropTypes.func,
  onError: PropTypes.func.isRequired,
  fileTypes: PropTypes.string, // format: 'image/jpeg,image/png'
  requiredImageSize: PropTypes.array // format: [{ width: 140, height: 200 }]
};

export default UploadFromUrl;
