import { Record } from 'immutable';
export const Address = new Record({
  id: null,
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  number: '',
  email: '',
  save: false
});

export const mapAddress = address => {
  return new Address({
    id: address.Id,
    firstName: address.FirstName,
    lastName: address.LastName,
    addressLine1: address.Line1,
    addressLine2: address.Line2,
    city: address.City,
    state: address.State,
    country: address.CountryCode,
    zipCode: address.PostalCode,
    number: address.Phone,
    email: address.Email
  });
};
