import { connect } from 'react-redux';
import PlaceOrderView from './PlaceOrderView';
import {
  selectedProductsSelector,
  isReadySelector,
  selectedVariantIDsSelector
} from './PlaceOrderViewSelectors';
import {
  loadVariants,
  selectVariant,
  selectAll,
  setEditProductID,
  removeVariant,
  setOrderAsSample
} from './PlaceOrderViewActions';
import { editDesign } from '../../CustomOrderForm/EditDesignView/EditDesignViewActions';

const mapDispatchToProps = {
  loadVariants: products => loadVariants(products),
  selectVariant: variantID => selectVariant(variantID),
  removeVariant: variantID => removeVariant(variantID),
  selectAll: ids => selectAll(ids),
  setEditProductID: index => setEditProductID(index),
  editDesign: design => editDesign(design),
  setOrderAsSample: () => setOrderAsSample()
};

const mapStateToProps = state => {
  return {
    selectedProducts: selectedProductsSelector(state),
    isReady: isReadySelector(state),
    selectedVariantIDs: selectedVariantIDsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderView);
