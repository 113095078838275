import { connect } from 'react-redux';
import Toolbar from './Toolbar';
import {
  sortItems,
  filterItems,
  selectedSpaceTitleSelector,
  filterTitleSelector,
  sortTitleSelector
} from './ToolbarSelectors';

const mapDispatchToProps = {};

const mapStateToProps = (state, ownProps) => {
  return {
    sortItems: sortItems(),
    filterItems: filterItems(),
    title: selectedSpaceTitleSelector(state),
    filterTitle: filterTitleSelector(state),
    sortTitle: sortTitleSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
