import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Autocomplete from '../../../../shared/Autocomplete';
import Select from '../../../../shared/Select';
import ErrorMessage from '../ErrorMessage';
import Rules from '../../../ErrorsHandler/ValidationRules';

const ProductCollection = props => {
  const [error, setError] = useState(null);

  // Hide category selection for new product in TikTok
  if (!props.productId && props.selectedProvider.get('provider') === 'tiktok') {
    return <></>;
  }

  // Prevent category change in TikTok
  const disabled = props.productId && props.selectedProvider.get('provider') === 'tiktok';

  // Error messages
  const errors = {
    maxCollectionLength: `Max collection length is ${props.maxCollectionLength} chars.`
  };

  useEffect(() => {
    let err = Rules.productCollections.collectionLengthExceeded(
      props.selectedCollections,
      props.maxCollectionLength
    )
      ? errors.maxCollectionLength
      : null;

    setError(err);
    props.setError(props.storeId, 'ProductCollections', err);
  }, [props.selectedCollections.toString()]);
  return (
    <div className="product-publish-collection flex-container pb-4">
      {disabled ? (
        <Select
          label="Collection"
          disabled={disabled}
          autoComplete="false"
          value={props.selectedCollections.get(0).get('id')}
          onChange={() => {}}
          placeholder="Select Collection"
        >
          <option value={props.selectedCollections.get(0).get('id')}>
            {props.selectedCollections.get(0).get('name')}
          </option>
        </Select>
      ) : (
        <Autocomplete
          allowSelectedDuplicates
          items={props.collections}
          selectedItems={props.selectedCollections.toJS()}
          maxSelected={props.maxSelected || 20}
          onNew={coll => props.newCollection(props.storeId, coll)}
          onAdd={coll => props.addCollection(props.storeId, coll)}
          onRemove={coll => props.removeCollection(props.storeId, coll)}
          label="Collection"
        />
      )}
      {error ? <ErrorMessage message={error} /> : null}
      <style>
        {`
        .product-publish-collection {
          flex-direction: column
        }
        :global(.product-publish-collection input.rbt-input-main) {
          height: 40px;
          border-color: ${error ? '#F1643A' : '#ccc'} !important;
        }
        `}
      </style>
    </div>
  );
};

ProductCollection.propTypes = {
  linkMode: PropTypes.bool,
  editMode: PropTypes.bool,
  maxSelected: PropTypes.number,
  selectedProvider: PropTypes.instanceOf(Map),
  collections: PropTypes.array.isRequired,
  selectedCollections: PropTypes.instanceOf(List).isRequired,
  productId: PropTypes.any,
  storeId: PropTypes.number,
  setError: PropTypes.func,
  addCollection: PropTypes.func.isRequired,
  removeCollection: PropTypes.func.isRequired,
  newCollection: PropTypes.func.isRequired,
  // validation below
  maxCollectionLength: PropTypes.number
};

export default ProductCollection;
