import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import { fromJS, Map, Set } from 'immutable';
import {
  RESTORE_CART,
  ADD_TO_CART,
  UPDATE_CART_ITEM,
  REMOVE_CART_ITEM,
  TOGGLE_ITEM_AS_SAMPLE,
  CLEAR_CART,
  UPDATE_CART_ITEM_QUANTITY,
  UPDATE_SHIPPING_COUNTRY,
  SHOW_CART_ITEM_PREVIEW,
  DOWNLOAD_CART_ITEM_PREVIEW,
  downloadCartItemPreviewSuccess,
  showCartItemPreviewSuccess,
  restoreCart,
  updateCartItemPreview,
  updateCartItemPricing,
  EDIT_CART_ITEM,
  fetchAsyncSuccess,
  PRODUCT_VARIANTS_FETCH,
  updateProductionTimeData,
  PRODUCT_DETAILS_FETCH,
  loadedItemPrice
} from '../../store/actions/dataActions';
import { PLACE_ORDER } from '../Checkout/CheckoutActions';
import { PARTNER_DATA_LOADED } from '../../store/actions/globalActions';
import { generatePreview } from '../../services/previewGenerationService';
import { getItemsPriceInfo, getSkuItemPriceKey } from './services/ItemsPriceInfoService';
import {
  cartItemsSelector,
  sanitizedCartItemsSelector,
  isCartRestoredSelector
} from './CartSelectors';
import { cofShippingCountrySelector } from '../../store/selectors/countriesSelectors';
import Config from '../../config';
import Log from '../../services/logService';
import orientationService, {
  setupOrientation
} from '../SKUSelection/atoms/OptionsPanel/Options/services/orientationService';
import {
  setPublishedOrientationChanged,
  setDefaultOrientation
} from '../SKUSelection/SKUSelectionActions';
import { defaultOrientationSelector } from '../SKUSelection/SKUSelectionSelectors';
import { selectedProductSelector } from '../ImageUpload/ImageUploadSelectors';
import { goto } from '../../containers/NavManager/NavManagerActions';
import productDataService from '../../services/productDataService';
import { isImmutable } from '../../utils/object';
import productionTimesService from './services/productionTimesService';

function* generateProductPreviews(cartItems) {
  // NOTE: This occurs when an item is added to the cart, or existing cart item is updated
  const imagePrepareTasks = cartItems
    .filter(item => !item.get('previewImageUrl'))
    .map(item => ({
      cartItemId: item.get('_random_id'),
      space: item.getIn(['sku', 'spaces']).get(0).toJS(),
      changeOrientation: item.getIn(['sku', 'orientation']) === 'changed'
    }));

  for (let res of imagePrepareTasks) {
    try {
      // Generate product previews for items in Cart

      const smallCartItemPreviewUrl = yield generatePreview(res.space, {
        width: 300,
        height: 300,
        imageWidth: 200,
        imageHeight: 200,
        preview: 'product',
        changeOrientation: res.changeOrientation
      });

      yield put(updateCartItemPreview(smallCartItemPreviewUrl, res.cartItemId));
    } catch (err) {
      // Suppress here to not break user flow, since these previews are not critical
      Log.error(err, 'Small cart preview generation suppressed');
    }
  }
}

export function* initProductPreviewGenerator(action) {
  const cartItems = yield select(cartItemsSelector);
  yield generateProductPreviews(cartItems);
}

export function* updateItemsPricing(action) {
  yield put(loadedItemPrice(false));
  // NOTE: Make sure items pricing are actual for current selected shipping country
  // Upon cart items saving/restoring from localStorage we are not saving pricing
  // So pricing will be retrieved again on cart restore
  const shippingCountry = yield select(cofShippingCountrySelector);

  if (shippingCountry) {
    // Get items already in cart
    const cartItems = yield select(cartItemsSelector);

    // group by sku
    const bySku = cartItems.groupBy(getSkuItemPriceKey);

    // if any of the items for a sku has a price already, grab it
    const knownPricing = bySku
      .map(items => {
        return items.find(item => item.get('pricing'), null, new Map()).get('pricing');
      })
      .filter(x => x);

    // update all items with known prices
    if (knownPricing.size) {
      yield put(updateCartItemPricing(knownPricing));
    }

    // find all items with skus where pricing is unknown
    const cartItemsRequireApiCall = bySku.entrySeq().reduce((acc, [sku, items]) => {
      if (!knownPricing.get(sku)) {
        return acc.union(items);
      }

      return acc;
    }, new Set());

    if (cartItemsRequireApiCall.size) {
      // fetch the additional data
      const priceInfoResponse = yield call(
        getItemsPriceInfo,
        shippingCountry,
        cartItemsRequireApiCall
      );

      // update any items where the price was unknown
      yield put(updateCartItemPricing(fromJS(priceInfoResponse)));
      yield put(loadedItemPrice(true));
    }
  } else {
    yield put(loadedItemPrice(true));
    console.warn("Can't load cart items pricing since shipping country is not selected");
  }
}

// TODO Handle non 200 responses from server
export function* updateProductionTimes(action) {
  const cartItems = yield select(cartItemsSelector);

  const uniqueProductIds = Set(cartItems.map(item => item.get('productId')).filter(x => x));

  for (const id of uniqueProductIds.toArray()) {
    try {
      const data = yield call(
        [productionTimesService, productionTimesService.getProductionTimeData],
        id
      );

      yield put(updateProductionTimeData(id, data));
    } catch (error) {
      // Silent fail, data not critical
      console.log(error);
    }
  }
}

export function* watchAddToCart() {
  yield takeLatest([RESTORE_CART, ADD_TO_CART, UPDATE_CART_ITEM], initProductPreviewGenerator);
  yield takeLatest(
    [RESTORE_CART, ADD_TO_CART, UPDATE_SHIPPING_COUNTRY, UPDATE_CART_ITEM],
    updateItemsPricing
  );
  yield takeLatest([RESTORE_CART, ADD_TO_CART], updateProductionTimes);
}

export function* cartItemsChangeHandler() {
  // Call callback defined in config when cart items added, removed, qty updated
  const onCartUpdateFn = Config.get('onCartUpdate');
  if (onCartUpdateFn) {
    // Sanitize and clean cartItems for localstorage save and then restore
    const cartItems = yield select(sanitizedCartItemsSelector);
    onCartUpdateFn(cartItems);
  }
}

export function* watchCartItemsChange() {
  // Watch actions which modify cart items qty
  yield takeLatest(
    [
      RESTORE_CART,
      ADD_TO_CART,
      TOGGLE_ITEM_AS_SAMPLE,
      REMOVE_CART_ITEM,
      CLEAR_CART,
      UPDATE_CART_ITEM,
      UPDATE_CART_ITEM_QUANTITY,
      PLACE_ORDER.SUCCESS
    ],
    cartItemsChangeHandler
  );
}

export function* shippingCountryUpdateHandler(action) {
  // Call callback defined in config when shipping country updated
  const onShippingCountryUpdate = Config.get('onShippingCountryUpdate');
  if (onShippingCountryUpdate) {
    onShippingCountryUpdate(action.payload.countryCode);
  }
}

export function* watchShippingCountryUpdate() {
  yield takeLatest([UPDATE_SHIPPING_COUNTRY], shippingCountryUpdateHandler);
}

export function* cartInitHandler() {
  // Restore cart items from config when browser reload
  // check config.cartItems and pre-load them into state
  // don't pre-load if state already has cart items
  const cartItems = Config.get('cartItems');
  const shippingCountry = yield select(cofShippingCountrySelector);
  if (cartItems && cartItems.size && shippingCountry) {
    const isCartRestored = yield select(isCartRestoredSelector);
    if (!isCartRestored) {
      yield put(restoreCart(cartItems, shippingCountry));
    }
  }
}

export function* watchCartInit() {
  yield takeLatest(PARTNER_DATA_LOADED, cartInitHandler);
}

export function* showCartItemPreviewHandler(action) {
  // check if item has already large preview generated
  let largePreviewUrl = action.payload.item.get('largePreviewImageUrl');
  if (largePreviewUrl) {
    yield put(showCartItemPreviewSuccess(largePreviewUrl));
    return;
  }
  // generate if not
  const space = action.payload.item.getIn(['sku', 'spaces', 0]).toJS();
  try {
    // Generate product previews for items in Cart
    largePreviewUrl = yield generatePreview(space, {
      width: 1000,
      height: 1000,
      imageWidth: 1000,
      imageHeight: 1000,
      preview: 'product',
      changeOrientation: action.payload.item.getIn(['sku', 'orientation']) === 'changed'
    });
    yield put(showCartItemPreviewSuccess(largePreviewUrl, action.payload.item.get('_random_id')));
  } catch (err) {
    // Suppress here to not break user flow, since these previews are not critical
    Log.error(err, 'Large cart preview generation suppressed');
  }
}

export function* watchCartItemPreviewShowAsync() {
  yield takeLatest(SHOW_CART_ITEM_PREVIEW.ASYNC, showCartItemPreviewHandler);
}

export function* downloadCartItemPreviewHandler(action) {
  const download = (blobUrl, fileName) => {
    if (navigator.msSaveOrOpenBlob) {
      // IE11 or Edge
      const xhr = new XMLHttpRequest();
      xhr.open('GET', blobUrl, true);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const blob = xhr.response;
          navigator.msSaveOrOpenBlob(blob, fileName);
        }
      };
      xhr.send();
    } else {
      let link = document.createElement('a');
      link.download = fileName;
      link.href = blobUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  // check if item has already large preview generated
  let largePreviewUrl = action.payload.item.get('largePreviewImageUrl');
  const fileName = action.payload.item.getIn(['sku', 'sku']) + '.jpeg';
  if (largePreviewUrl) {
    download(largePreviewUrl, fileName);
    yield put(downloadCartItemPreviewSuccess(largePreviewUrl));
    return;
  }
  // generate if not
  const space = action.payload.item.getIn(['sku', 'spaces', 0]).toJS();
  try {
    // Generate product previews for items in Cart
    largePreviewUrl = yield generatePreview(space, {
      width: 1000,
      height: 1000,
      imageWidth: 1000,
      imageHeight: 1000,
      preview: 'product',
      changeOrientation: action.payload.item.getIn(['sku', 'orientation']) === 'changed'
    });
    download(largePreviewUrl, fileName);
    yield put(
      downloadCartItemPreviewSuccess(largePreviewUrl, action.payload.item.get('_random_id'))
    );
  } catch (err) {
    // Suppress here to not break user flow, since these previews are not critical
    Log.error(err, 'Large cart preview download suppressed');
  }
}

function* editDesignHandler(action) {
  // first check if product has variants loaded, and if not, fetch them...
  const selectedProduct = yield select(selectedProductSelector);

  if (!selectedProduct.get('variants')) {
    const variants = yield call(
      [productDataService, productDataService.getProductVariants],
      selectedProduct.get('name')
    );
    yield put(
      fetchAsyncSuccess(PRODUCT_VARIANTS_FETCH, {
        productName: selectedProduct.get('name'),
        variants
      })
    );
  }

  // if product.details doesn't exist, it has to be fetched, because we need it before calling 'setupOrientation'
  // in product details there's an information about ORIENTATION option, and based on that 'setupOrientation' works...
  if (!selectedProduct.get('details')) {
    const details = yield call(
      [productDataService, productDataService.getProductDetails],
      selectedProduct.get('name')
    );
    yield put(fetchAsyncSuccess(PRODUCT_DETAILS_FETCH, details));
  }

  // check if default orientation exist, if not, calculate it
  const defaultOrientation = yield select(defaultOrientationSelector);
  const template = action.payload.item.getIn(['sku', 'template']);
  if (!defaultOrientation) {
    yield put(
      setDefaultOrientation(
        orientationService.getDefaultTemplatesOrientation({
          sku: isImmutable(template) ? template.toJS() : template
        })
      )
    );
  }

  // get saved orientation from item, and store it
  if (action.payload.item.getIn(['sku', 'orientation']) === 'changed') {
    yield put(setPublishedOrientationChanged('changed'));
  }
  yield call(setupOrientation);

  // Goto Image Upload
  yield put(goto('ImageUpload'));
}

export function* watchDownloadCartItemPreviewAsync() {
  yield takeLatest(DOWNLOAD_CART_ITEM_PREVIEW.ASYNC, downloadCartItemPreviewHandler);
}

function* watchEditDesign() {
  yield takeLatest(EDIT_CART_ITEM, editDesignHandler);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchAddToCart(),
    watchCartItemsChange(),
    watchCartInit(),
    watchShippingCountryUpdate(),
    watchCartItemPreviewShowAsync(),
    watchDownloadCartItemPreviewAsync(),
    watchEditDesign()
  ]);
}
