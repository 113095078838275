import { connect } from 'react-redux';
import HubProductSelection from './HubProductSelection';
import {
  queryNextPage,
  querySearch,
  selectProduct,
  publishProduct
} from './HubProductSelectionActions';
import {
  hubProductsForSelectSelector,
  hasMoreProductsSelector,
  canFilterLocallySelector,
  queryInProgressSelector,
  querySelector,
  selectedProductSelector,
  publishInProgressSelector
} from './HubProductSelectionSelectors';
import { canGoNextSelector } from './../../../../SKUsSelectionViewSelectors';
import { deselectLastSKU } from './../../../../SKUsSelectionViewActions';
import { continueButtonLabelSelector } from '../../ProductSelectionSelectors';

const mapDispatchToProps = {
  queryNextPage: () => queryNextPage(),
  querySearch: filter => querySearch(filter),
  selectProduct: product => selectProduct(product),
  deselectLastSKU: () => deselectLastSKU(),
  publishProduct: () => publishProduct()
};

const mapStateToProps = state => {
  return {
    hubProductsForSelect: hubProductsForSelectSelector(state),
    hasMoreProducts: hasMoreProductsSelector(state),
    canFilterLocally: canFilterLocallySelector(state),
    queryInProgress: queryInProgressSelector(state),
    query: querySelector(state),
    selectedProduct: selectedProductSelector(state),
    canGoNext: canGoNextSelector(state),
    publishInProgress: publishInProgressSelector(state),
    continueButtonLabel: continueButtonLabelSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubProductSelection);
