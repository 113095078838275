import { connect } from 'react-redux';
import CartView from './CartView';
import { loadingStatusSelector, sourceSelector, hasCartItemsSelector } from './CartViewSelectors';
import { cofShippingCountrySelector } from 'gooten-components/src/store/selectors/countriesSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    loadingStatus: loadingStatusSelector(state),
    // allow to restore country from config
    shippingCountry: cofShippingCountrySelector(state),
    source: sourceSelector(state),
    hasCartItems: hasCartItemsSelector(state)
  };
};

export default connect(mapStateToProps)(CartView);
