import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Wizard } from 'gooten-components';
import Config from '../../../config';
import AppStore from '../../../store';
import analyticsService from 'gooten-components/src/services/analyticsService';

import './ProductDesignView.scss';

class ProductDesignView extends React.Component {
  componentDidMount() {
    this.init(this.props);
  }

  init(props) {
    if (!props.flowVerified) {
      // flow is broken - redirect to listing page of store products from current URL
      window.location.hash = window.location.hash.split('/link-product')[0];
      return;
    }
    analyticsService.track('Hub - Link Flow - Design', 'Started', 'Hub');

    const hasData = this.props.storeProduct && this.props.product && this.props.mappings.size;
    if (!hasData) {
      // some data is not set: redirecting to hub store
      props.router.replace(`/hub/${props.router.params.provider}/${props.router.params.store}`);
      return null;
    } else if (!props.hasSelectedSkus) {
      this.props.prepareLinkData(
        new Map({
          product: props.product,
          storeProduct: props.storeProduct,
          mappings: props.mappings.filter(m => m.get('uniqueProduct')),
          storeId: parseInt(props.router.params.store)
        }),
        props.stores
      );
    } else if (!props.isReady) {
      // NOTE: Wait until product details loaded
      return null;
    }
  }

  render() {
    const ready = this.props.storeProduct && this.props.product && this.props.mappings.size;

    if (!ready || !this.props.hasSelectedSkus || !this.props.isReady) {
      return null;
    }

    // if all data ready init wizard with updated config to start from design step
    const defaultConfig = Config.toJS();
    const steps = defaultConfig.steps;
    let stores = this.props.stores.toJS();
    const publishStep = {
      ...steps.find(s => s.id === 'SaveAndPublish'),
      config: {
        stores: stores.map(s => ({
          id: s.id,
          name: s.storeName,
          provider: s.provider,
          collections: s.collections,
          tags: s.tags
        }))
      }
    };

    let config = {
      ...defaultConfig,
      steps: [
        // skip product selection and sku selection
        steps.find(s => s.id === 'ImageUpload'),
        steps.find(s => s.id === 'ProductPreview'),
        publishStep
      ],
      linkMode: true
    };

    // init analytics
    config.analyticsSource = 'Hub - Link Flow';
    config.analyticsCategory = 'Hub';

    return (
      <div className="link-product-design-view-container">
        <Wizard config={config} store={AppStore} />
      </div>
    );
  }
}

ProductDesignView.propTypes = {
  /*eslint-disable */
  // location: PropTypes.object.isRequired,
  flowVerified: PropTypes.bool,
  router: PropTypes.object.isRequired,
  hasSelectedSkus: PropTypes.bool,
  isReady: PropTypes.bool,
  stores: PropTypes.instanceOf(List),
  prepareLinkData: PropTypes.func.isRequired,
  /* eslint-enable */
  storeProduct: PropTypes.any,
  product: PropTypes.any,
  mappings: PropTypes.instanceOf(List)
};

export default ProductDesignView;
