export default class Strings {
  static FIELD_REQUIRED = `Field required`;
  static FIRST_NAME = `First Name`;
  static LAST_NAME = `Last Name`;
  static INVALID_NAME = `Invalid name`;
  static ADDRESS_LINE_1 = `Address Line 1`;
  static ADDRESS_LINE_2 = `Address Line 2`;
  static CITY = `City`;
  static STATE_PROVINCE_REGION = `State/Province/Region`;
  static COUNTRY = `Country`;
  static POSTAL_CODE = `Postal Code`;
  static INVALID_ZIPCODE = `Invalid zipcode`;
  static PHONE_NUMBER = `Phone Number`;
  static INVALID_PHONE_NUMBER = `Invalid Phone Number`;
  static YOUR_EMAIL = `Your Email Address`;
  static INVALID_EMAIL = `Invalid email`;
  static SAVE_THIS_ADDRESS_FOR_LATER = `Save this address for later`;
  static USE_THIS_ADDRESS = `Use this address`;
}
