import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../config';

class ImagesViewService {
  getSkuData(recipeId, sku) {
    const url = `${Config.get('api')}producttemplates/`;

    return fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        recipeid: recipeId,
        sku: sku
      }
    });
  }
}

export default new ImagesViewService();
