import { connect } from 'react-redux';
import UploadImage from './UploadImage';
import { modalClose } from '../../ImageSelectionModalActions';

const mapDispatchToProps = {
  modalClose: () => modalClose()
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
