import { connect } from 'react-redux';
import PastOrdersView from './PastOrdersView';
import { searchTextSelector } from './PastOrdersViewSelectors';
import { searchChange } from './PastOrdersViewActions';
import { hasCartItemsSelector } from '../CartView/CartViewSelectors';

const mapDispatchToProps = {
  searchReset: () => searchChange(''),
  doSearch: searchText => searchChange(searchText)
};

const mapStateToProps = state => {
  return {
    searchText: searchTextSelector(state),
    hasCartItems: hasCartItemsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastOrdersView);
