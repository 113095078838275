import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List, Map } from 'immutable';
import NavigationBar from './atoms/NavigationBar';
import ProvidersTab from './atoms/ProvidersTab';
import factory from './ProviderForms/providersFactory';
import { windowWidth } from '../shared/ComponentsHooks';
import PageBody from '../shared/PageBody';
import Dialogue from '../shared/Dialogue';

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  margin-top: 1px;

  .content {
    max-width: 970px;
    width: 94%;

    .platform-form {
      width: 100%;
      justify-content: space-between;

      .product-settings {
        width: ${props => (props.width > 992 ? '638px' : '100%')};
        position: relative;

        div.autocomplete-container .row form button.submit-btn {
          padding-top: 7px;
          padding-right: 13px;
        }

        .mockups-design-mobile-holder {
          padding: 40px 0 24px 0;
          flex-direction: ${props => (props.width <= 576 ? 'column' : 'row')};
          flex-wrap: wrap;

          .product-publish-mockups {
            width: ${props => (props.width <= 576 ? '100%' : '50%')};
            padding-right: ${props => (props.width <= 576 ? '0' : '40')}px;
            margin-bottom: ${props => (props.width > 576 && props.width <= 992 ? 0 : 58)}px;
          }

          .product-publish-design {
            width: ${props => (props.width <= 576 ? '100%' : '50%')};
          }

          .product-publish-necklabels {
            width: ${props => (props.width <= 576 ? '100%' : '50%')};
          }
        }

        .product-publish-duplicate-changes {
          width: ${props => (props.width >= 768 ? '45%' : props.width >= 576 ? '50%' : '84%')};
        }
      }

      .mockups-design-holder {
        flex-direction: column;
        width: 309px;
        padding-left: 2rem;
      }
    }
  }
`;

const ProductPublish = props => {
  const width = windowWidth();
  const [loadingText, setLoadingText] = useState('Initializing');
  const [loaderInterval, setLoaderInterval] = useState(0);
  const loaderIntervalRef = useRef();

  useEffect(() => {
    loaderIntervalRef.current = loaderInterval;
  }, [loaderInterval]);

  useEffect(() => {
    return () => {
      clearInterval(loaderIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (props.publishingState !== 'WORKING') {
      clearInterval(loaderInterval);
      setLoaderInterval(0);
    }

    if (props.publishingState === 'WORKING' && loaderInterval === 0) {
      initiateLoader();
    }
  }, [props.publishingState]);

  useEffect(() => {
    props.configure(props.config);
  }, [props.config]);

  useEffect(() => {
    if (!props.isDataReady) {
      props.prepareData();
    }
    props.componentMounted();
  }, []);

  const initiateLoader = () => {
    const i = [
      'Processing Images',
      'Updating Data',
      'Updating Storage Products',
      'Connecting to Stores',
      'Updating our Databases'
    ];
    let y = 0;
    const loadingMessageInterval = setInterval(() => {
      setLoadingText(i[y]);
      if (y === i.length - 1) {
        y = 0;
      } else {
        y++;
      }
    }, 7000);
    setLoaderInterval(loadingMessageInterval);
  };

  const productPublishLoader = loadingState => {
    return (
      <>
        <Dialogue isOpen noBackdrop title="Processing...">
          {loadingText}
          <div className="tracking-bar mt-3 w-100 d-flex justify-content-start">
            <div className="fill-bar" />
          </div>
        </Dialogue>
        <style jsx>
          {`
            .tracking-bar {
              height: 2rem;
              border-radius: 4px;
              overflow: hidden;
              box-shadow: inset 2px 1px 7px #e2e2e2;
            }
            .fill-bar {
              background: #092dc5;
              height: 2rem;
              width: 95%;
              animation-name: coolanimation;
              animation-iteration-count: 1;
              animation-timing-function: ease-in-out;
              animation-duration: 40s;
            }
            @keyframes coolanimation {
              from {
                width: 0%;
              }
              to {
                width: 95%;
              }
            }
          `}
        </style>
      </>
    );
  };

  const handleNext = useCallback(() => props.save(), [props.save]);

  const warningDialogContent = () => (
    <Dialogue
      isOpen={props.warningDialog?.get('show')}
      title={props.warningDialog?.get('title') || ''}
      cancelClick={() => props.closeWarningDialog()}
      cancelText="Got it"
    >
      {props.warningDialog?.get('content')}
      <a href={props.warningDialog?.get('link')} target="blank">
        Learn More
      </a>
      <>
        <ul className="warning-dialog-content-holder">
          {props.warningDialog?.get('additionalData')?.map(d => (
            <li key={d}>{d}</li>
          ))}
        </ul>
        <style>{`
          .warning-dialog-content-holder {
            overflow: auto;
            max-height: 100px;
            margin-top: 10px !important;
            list-style-type: none;
          }
        `}</style>
      </>
    </Dialogue>
  );

  return (
    <Container className="product-publish-container flex-container" width={width}>
      {warningDialogContent()}
      <NavigationBar handleNext={handleNext} />
      <PageBody className="content">
        <ProvidersTab />
        {props.selectedProvider && props.anyProviderAvailable
          ? factory.from(
              props.selectedProvider,
              props.sortedSelectedSkus,
              props.skusWithNeckTag,
              props.isProvidersTabHidden,
              props.isMultiproduct
            )
          : null}
      </PageBody>
      {props.publishingState === 'WORKING' ? productPublishLoader(props.publishingState) : null}
    </Container>
  );
};

ProductPublish.propTypes = {
  selectedProvider: PropTypes.instanceOf(Map),
  isDataReady: PropTypes.bool,
  prepareData: PropTypes.func,
  save: PropTypes.func,
  publishingState: PropTypes.string,
  componentMounted: PropTypes.func,
  anyProviderAvailable: PropTypes.bool,
  isProvidersTabHidden: PropTypes.bool,
  skusWithNeckTag: PropTypes.object,
  sortedSelectedSkus: PropTypes.instanceOf(List),
  isMultiproduct: PropTypes.bool,
  warningDialog: PropTypes.instanceOf(Map),
  closeWarningDialog: PropTypes.func
};

export default ProductPublish;
