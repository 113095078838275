import { connect } from 'react-redux';
import ProductionReport from './ProductionReport';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import {
  dataSelector,
  errorSelector,
  inProgressSelector,
  periodsSelector,
  selectedPeriodSelector,
  sortSelector,
  tableHeaderSelector,
  tableMaxItemsSelector,
  dataExistSelector
} from './ProductionReportSelectors';
import { resetFilterAndSort, setSelectedPeriod, setSort } from './ProductionReportActions';

const mapDispatchToProps = {
  setPeriod: period => setSelectedPeriod(period),
  setSort: sort => setSort(sort),
  reset: () => resetFilterAndSort()
};

const mapStateToProps = (state, ownProps) => {
  return {
    periods: periodsSelector(state),
    tableHeader: tableHeaderSelector(state),
    data: dataSelector(state),
    error: errorSelector(state),
    inProgress: inProgressSelector(state),
    selectedPeriod: selectedPeriodSelector(state),
    tableMaxItems: tableMaxItemsSelector(state),
    sort: sortSelector(state),
    dataExist: dataExistSelector(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(ProductionReport));
