import { hexToRgb } from '../../utils/math';
import { sortByZIndexAsc } from '../../utils/array';
import PositioningService from '../../utils/positioningService';
import { getImageResizerUrl } from 'gooten-js-utils/src/url';

const ALL_LAYER_TYPES = new Set(['design', 'image', 'mask', 'shadow']);

const DESIGN_LAYER_TYPES = new Set(['design', 'mask', 'shadow']);


const _getCombineMapArg = (x, y) => {
  if (x >= 0) {
    x = '+' + x.toString();
  }
  else {
    x = x.toString();
  }

  if (y >= 0) {
    y = '+' + y.toString();
  }
  else {
    y = y.toString();
  }

  return x + y;
};

class ImgManipService {
  convertILtoImgManip(il, size, changeOrientation) {
    // map IL to ImgManip string
    let iter = 0;
    let mapArgs = [];

    let cmdObj = {
      name: 'canvas',
      layers: [],
      commands: [],
      settings: {
        width: il.width,
        height: il.height,
      },
    };

    let backgroundColor;
    il.layers.forEach((layer) => {
      const _convertToImgManipLayer = (img, isImage) => {
        // it may happen that image url isn't provided. In such case, imagmanip service will fail to generate
        // image. avoid such layers...
        if (!img.url) return

        let iobj = {
          name: 'image',
          settings: {
            uri: img.url,
          },
          commands: [],
        };

        //Set mask
        if (layer.backgroundColor) {
          //Take first background for extent later
          backgroundColor = backgroundColor || layer.backgroundColor;
          let rgb = hexToRgb(layer.backgroundColor);
          iobj.commands.push({
            name: 'setRGBChannels',
            args: {
              R: rgb.r.toString(),
              G: rgb.g.toString(),
              B: rgb.b.toString(),
            },
          });
        }
        else if (isImage) {
          // checking only for image layers b/c image can be resized
          // and background area should be filled with transparent
          // background always transparent
          iobj.commands.push({ name: 'repage' });
          iobj.commands.push({
            name: 'backgroundadd',
            args: { color: 'rgba(0,0,0,0)' },
          });
        }

        // crop
        if (img.crop) {
          iobj.commands.push({
            name: 'crop',
            args: {
              x1: img.crop.x1,
              y1: img.crop.y1,
              x2: img.crop.x2,
              y2: img.crop.y2,
            },
          });
        }

        // resize
        iobj.commands.push({
          name: 'resize',
          args: { width: img.width, height: img.height },
        });

        // rotate
        if (img.rotation) {
          iobj.commands.push({
            name: 'rotate',
            args: { degrees: img.rotation },
          });
        }

        let lobj = {
          name: 'canvas',
          layers: [iobj],
          commands: [
            {
              name: 'combine',
              args: { map: '0=' + _getCombineMapArg(img.left, img.top) },
            },
          ],
          settings: {
            width: img.viewport ? img.viewport.width : img.width,
            height: img.viewport ? img.viewport.height : img.height,
          },
        };

        cmdObj.layers.push(lobj);

        // image positioning
        mapArgs.push(
          iter++ +
            '=' +
            _getCombineMapArg(
              img.viewport ? img.viewport.x1 : img.x1,
              img.viewport ? img.viewport.y1 : img.y1
            )
        );
      };

      if (layer.images) {
        layer.images.forEach((img) => _convertToImgManipLayer(img, true));
      }
      else {
        _convertToImgManipLayer(layer);
      }
    });

    // add in final commands
    cmdObj.commands.push({
      name: 'combine',
      args: { map: mapArgs.join(',') },
      index: 1,
    });

    // final crop for single templates only
    if (il.type === 'print' && il.final) {
      cmdObj.commands.push({
        name: 'crop',
        args: {
          x1: il.final.x1,
          x2: il.final.x2,
          y1: il.final.y1,
          y2: il.final.y2,
        },
        index: 2,
      });
    }

    // add rotate command if orientation has been changed
    if (changeOrientation) {
      cmdObj.commands.push({ name: 'rotate', args: { degrees: 90 }, index: 1 });
    }

    cmdObj.commands.push({
      name: 'resample2',
      args: { dpi: 300, units: 'PixelsPerInch' },
      index: 998,
    });

    if (il.type === 'preview' && size) {
      // Resize to preview sizes, size obj
      cmdObj.commands.push({
        name: 'extent',
        args: {
          width: size.width,
          height: size.height,
          color: backgroundColor || 'white',
        },
        index: 898,
      });
      // commented out since resize is most heavy operation, and this is seems like redundant here.
      // cmdObj.commands.push({ 'name': 'resize', 'args': { 'height': size.height, 'width': size.width, 'color': backgroundColor || 'white' }, 'index': 899 });
    }

    // Todo: imageFormatExtension??
    // if (imageFormatExtension && imageFormatExtension.convert) {
    //   cmdObj.commands.push({ 'name': 'convert',  'args': { 'format': imageFormatExtension.convert }, 'index' :999 });
    // }

    return cmdObj;
  }

  generatePreviewIl(config, backgroundColor, previewSize) {
    // contains also design layers
    let previewIL = {
      type: 'preview',
      top: 0,
      left: 0,
      layers: [],
    };

    // template layer
    const templateRect = PositioningService.getMaxRect(config.template.layers);

    // template scale DX
    const tempScaleDx = Math.min(
      previewSize.width / templateRect.width,
      previewSize.height / templateRect.height
    );

    // proportional image size
    const firstImageLayer = config.il.layers.find(
      (l) => l.images && l.images.length
    );
    const imageW = firstImageLayer.images[0].sourceWidth;
    const imageH = firstImageLayer.images[0].sourceHeight;
    const maxImageSize = Math.max(imageW, imageH);

    // sort by index for upcoming operations
    const layers = sortByZIndexAsc(config.template.layers);

    let ilWidth = 0;
    let ilHeight = 0;

    layers
      .filter(l => ALL_LAYER_TYPES.has(l.type))
      .forEach(l => {
        let layerIL = {};
        layerIL.layerId = l.id;

        if (DESIGN_LAYER_TYPES.has(l.type)) {
          layerIL.url = getImageResizerUrl(
            l.imageurl,
            maxImageSize,
            maxImageSize
          );

          const origW = l.x2 - l.x1;
          const origH = l.y2 - l.y1;

          // design layers x, y start from top left corner
          layerIL.left = l.x1 * tempScaleDx;
          layerIL.top = l.y1 * tempScaleDx;
          layerIL.width = origW * tempScaleDx;
          layerIL.height = origH * tempScaleDx;
          layerIL.rotation = 0;
          layerIL.scaleDx = 1; // design layers are static
          layerIL.x1 = l.x1 * tempScaleDx;
          layerIL.x2 = l.x2 * tempScaleDx;
          layerIL.y1 = l.y1 * tempScaleDx;
          layerIL.y2 = l.y2 * tempScaleDx;
          layerIL.centerX = layerIL.width / 2;
          layerIL.centerY = layerIL.height / 2;
          if ((l.type === 'mask' || l.mask === true) && backgroundColor) {
            layerIL.backgroundColor = backgroundColor;
          }

          ilWidth = Math.max(ilWidth, layerIL.width);
          ilHeight = Math.max(ilHeight, layerIL.height);
        }

        if (l.type === 'image') {
          let ilLayer = config.il.layers.find((ilL) => ilL.layerId == l.id);
          if (ilLayer) {
            // go through each image here and apply new sizes
            layerIL.images = ilLayer.images.map((img) => {
              let imgIL = {};
              imgIL.url = getImageResizerUrl(
                img.url,
                maxImageSize,
                maxImageSize
              );

              // Old Viewport
              let _vW = img.viewport.x2 - img.viewport.x1;
              let _vH = img.viewport.y2 - img.viewport.y1;
              let _dW = _vW / img.realSourceWidth;
              let _dH = _vH / img.realSourceHeight;
              let _initialScale = Math.max(_dW, _dH);

              // New Viewport coordinates in canvas
              let vW =
                img.viewport.x2 * tempScaleDx - img.viewport.x1 * tempScaleDx;
              let vH =
                img.viewport.y2 * tempScaleDx - img.viewport.y1 * tempScaleDx;
              let dW = vW / imageW;
              let dH = vH / imageH;
              //Will extend/squize image to fill viewport width or height
              let initialScale = Math.max(dW, dH);

              let zoomScaleDx = img.scaleDx / _initialScale;
              let scale = zoomScaleDx * initialScale;

              const _imgWidth = img.crop
                ? img.crop.width * img.scaleDx
                : img.width;

              const _imgHeight = img.crop
                ? img.crop.height * img.scaleDx
                : img.height;

              const _newImgWidth = img.crop
                ? (img.crop.width * img.scaleDx * tempScaleDx) / scale
                : imageW;

              const _newImgHeight = img.crop
                ? (img.crop.height * img.scaleDx * tempScaleDx) / scale
                : imageH;

              const newLeft =
                (img.left + img.viewport.x1 + _imgWidth / 2) * tempScaleDx -
                (_newImgWidth * scale) / 2 -
                img.viewport.x1 * tempScaleDx;
              // avoid e numbers
              imgIL.left = imgIL.x1 = parseFloat(newLeft.toFixed(4));

              const newTop =
                (img.top + img.viewport.y1 + _imgHeight / 2) * tempScaleDx -
                (_newImgHeight * scale) / 2 -
                img.viewport.y1 * tempScaleDx;
              // avoid e numbers
              imgIL.top = imgIL.y1 = parseFloat(newTop.toFixed(4));

              imgIL.x2 = (img.x2 - _imgWidth / 2) * tempScaleDx;
              imgIL.y2 = (img.y2 - _imgHeight / 2) * tempScaleDx;

              imgIL.centerX = img.centerX * scale;
              imgIL.centerY = img.centerY * scale;

              imgIL.width = _newImgWidth * scale;
              imgIL.height = _newImgHeight * scale;

              imgIL.rotation = img.rotation;

              imgIL.viewport = {};
              imgIL.viewport.x1 = img.viewport.x1 * tempScaleDx;
              imgIL.viewport.x2 = img.viewport.x2 * tempScaleDx;
              imgIL.viewport.y1 = img.viewport.y1 * tempScaleDx;
              imgIL.viewport.y2 = img.viewport.y2 * tempScaleDx;
              imgIL.viewport.width = img.viewport.width * tempScaleDx;
              imgIL.viewport.height = img.viewport.height * tempScaleDx;

              if (img.crop) {
                imgIL.crop = {};
                imgIL.crop.x1 =
                  (img.crop.x1 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.y1 =
                  (img.crop.y1 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.x2 =
                  (img.crop.x2 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.y2 =
                  (img.crop.y2 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.width =
                  (img.crop.width * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.height =
                  (img.crop.height * img.scaleDx * tempScaleDx) / scale;
              }

              imgIL.scaleDx = scale;

              return imgIL;
            });
          }
          else {
            layerIL.images = [];
          }
        }

        previewIL.layers.push(layerIL);
      });

    previewIL.width = ilWidth;
    previewIL.height = ilHeight;

    return previewIL;
  }

  getPrintIl(config) {
    // Check and fix invalid IL
    let printIl = { ...config.il };
    if (!printIl.width || !printIl.height) {
      const templateRect = PositioningService.getMaxRect(
        config.template.layers
      );
      printIl.width = templateRect.width;
      printIl.height = templateRect.height;
    }

    if (!printIl.final || !printIl.final.x1) {
      const imageLayers = config.template.layers.filter(
        (l) => l.type === 'image'
      );
      const printRect = PositioningService.getMaxRect(imageLayers);
      printIl.final = {
        x1: printRect.x1,
        x2: printRect.x2,
        y1: printRect.y1,
        y2: printRect.y2,
      };
    }

    return printIl;
  }

  exportImgManipCmd(
    config,
    backgroundColor,
    previewSize,
    changeOrientation
  ) {
    // config.il contains Print IL
    if (!config.il || config.il.type !== 'print') {
      throw new Error('exportImgManipCmd config require print il');
    }

    // config.il contains Print IL with at least 1 layer
    if (!config.il.layers || !config.il.layers.length) {
      throw new Error(
        'exportImgManipCmd config require print il with at least 1 layer'
      );
    }

    // config.il contains Print IL with at least 1 layer with at least 1 image
    if (!config.il.layers.find((l) => l.images && l.images.length)) {
      throw new Error(
        'exportImgManipCmd config require print il with at least 1 layer with at least 1 image'
      );
    }

    // config.template contains template
    if (!config.template) {
      throw new Error('exportImgManipCmd config require template');
    }

    const previewIL = this.generatePreviewIl(
      config,
      backgroundColor,
      previewSize
    );

    // Convert to manip command in privided size
    const previewImgManip = this.convertILtoImgManip(
      previewIL,
      previewSize,
      changeOrientation
    );

    const printIL = this.getPrintIl(config);
    // Convert to manip command in REAL size
    const printImgManip = this.convertILtoImgManip(
      printIL,
      null,
      changeOrientation
    );

    return { previewImgManip, printImgManip };
  }

  generateSmallPrintIL(config, previewSize) {
    // NOTE: This similar to  generatePreviewIl
    // but it generate print IL proportional to preview size.
    let smallPrintIL = {
      type: 'print',
      top: 0,
      left: 0,
      layers: [],
    };

    // template layer
    const templateRect = PositioningService.getMaxRect(config.template.layers);

    // template scale DX
    const tempScaleDx = Math.min(
      previewSize.width / templateRect.width,
      previewSize.height / templateRect.height
    );

    // proportional image size
    const firstImageLayer = config.il.layers.find(
      (l) => l.images && l.images.length
    );
    const imageW = firstImageLayer.images[0].sourceWidth;
    const imageH = firstImageLayer.images[0].sourceHeight;
    const maxImageSize = Math.max(imageW, imageH);

    // sort by index for upcoming operations
    const layers = sortByZIndexAsc(config.template.layers);

    let ilWidth = 0;
    let ilHeight = 0;

    layers
      .filter(l => ALL_LAYER_TYPES.has(l.type))
      .forEach(l => {
        let layerIL = {};
        layerIL.layerId = l.id;

        if (DESIGN_LAYER_TYPES.has(l.type)) {
          const origW = l.x2 - l.x1;
          const origH = l.y2 - l.y1;
          layerIL.width = origW * tempScaleDx;
          layerIL.height = origH * tempScaleDx;
          ilWidth = Math.max(ilWidth, layerIL.width);
          ilHeight = Math.max(ilHeight, layerIL.height);
        }

        if (l.type === 'image') {
          let ilLayer = config.il.layers.find((ilL) => ilL.layerId == l.id);
          if (ilLayer) {
            // go through each image here and apply new sizes
            layerIL.images = ilLayer.images.map((img) => {
              let imgIL = {};
              imgIL.url = getImageResizerUrl(
                img.url,
                maxImageSize,
                maxImageSize
              );

              // Old Viewport
              let _vW = img.viewport.x2 - img.viewport.x1;
              let _vH = img.viewport.y2 - img.viewport.y1;
              let _dW = _vW / img.realSourceWidth;
              let _dH = _vH / img.realSourceHeight;
              let _initialScale = Math.max(_dW, _dH);

              // New Viewport coordinates in canvas
              let vW =
                img.viewport.x2 * tempScaleDx - img.viewport.x1 * tempScaleDx;
              let vH =
                img.viewport.y2 * tempScaleDx - img.viewport.y1 * tempScaleDx;
              let dW = vW / imageW;
              let dH = vH / imageH;
              //Will extend/squize image to fill viewport width or height
              let initialScale = Math.max(dW, dH);

              let zoomScaleDx = img.scaleDx / _initialScale;
              let scale = zoomScaleDx * initialScale;

              const _imgWidth = img.crop
                ? img.crop.width * img.scaleDx
                : img.width;

              const _imgHeight = img.crop
                ? img.crop.height * img.scaleDx
                : img.height;

              const _newImgWidth = img.crop
                ? (img.crop.width * img.scaleDx * tempScaleDx) / scale
                : imageW;

              const _newImgHeight = img.crop
                ? (img.crop.height * img.scaleDx * tempScaleDx) / scale
                : imageH;

              const newLeft =
                (img.left + img.viewport.x1 + _imgWidth / 2) * tempScaleDx -
                (_newImgWidth * scale) / 2 -
                img.viewport.x1 * tempScaleDx;
              // avoid e numbers
              imgIL.left = imgIL.x1 = parseFloat(newLeft.toFixed(4));

              const newTop =
                (img.top + img.viewport.y1 + _imgHeight / 2) * tempScaleDx -
                (_newImgHeight * scale) / 2 -
                img.viewport.y1 * tempScaleDx;
              // avoid e numbers
              imgIL.top = imgIL.y1 = parseFloat(newTop.toFixed(4));

              imgIL.x2 = (img.x2 - _imgWidth / 2) * tempScaleDx;
              imgIL.y2 = (img.y2 - _imgHeight / 2) * tempScaleDx;

              imgIL.centerX = img.centerX * scale;
              imgIL.centerY = img.centerY * scale;

              imgIL.width = _newImgWidth * scale;
              imgIL.height = _newImgHeight * scale;

              imgIL.rotation = img.rotation;

              imgIL.viewport = {};
              imgIL.viewport.x1 = img.viewport.x1 * tempScaleDx;
              imgIL.viewport.x2 = img.viewport.x2 * tempScaleDx;
              imgIL.viewport.y1 = img.viewport.y1 * tempScaleDx;
              imgIL.viewport.y2 = img.viewport.y2 * tempScaleDx;
              imgIL.viewport.width = img.viewport.width * tempScaleDx;
              imgIL.viewport.height = img.viewport.height * tempScaleDx;

              if (img.crop) {
                imgIL.crop = {};
                imgIL.crop.x1 =
                  (img.crop.x1 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.y1 =
                  (img.crop.y1 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.x2 =
                  (img.crop.x2 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.y2 =
                  (img.crop.y2 * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.width =
                  (img.crop.width * img.scaleDx * tempScaleDx) / scale;
                imgIL.crop.height =
                  (img.crop.height * img.scaleDx * tempScaleDx) / scale;
              }

              imgIL.scaleDx = scale;

              return imgIL;
            });
          }
          else {
            layerIL.images = [];
          }

          smallPrintIL.layers.push(layerIL);
        }
      });

    smallPrintIL.width = ilWidth;
    smallPrintIL.height = ilHeight;

    smallPrintIL.final = {
      x1: config.il.final.x1 * tempScaleDx,
      x2: config.il.final.x2 * tempScaleDx,
      y1: config.il.final.y1 * tempScaleDx,
      y2: config.il.final.y2 * tempScaleDx,
    };

    return smallPrintIL;
  }

  exportSmallPrintImgManipCmd(config, previewSize, changeOrientation) {
    // config.il contains Print IL
    if (!config.il || config.il.type !== 'print') {
      throw new Error('exportResizedPrintImgManipCmd config require print il');
    }

    // config.il contains Print IL with at least 1 layer
    if (!config.il.layers || !config.il.layers.length) {
      throw new Error(
        'exportResizedPrintImgManipCmd config require print il with at least 1 layer'
      );
    }

    // config.il contains Print IL with at least 1 layer with at least 1 image
    if (!config.il.layers.find((l) => l.images && l.images.length)) {
      throw new Error(
        'exportResizedPrintImgManipCmd config require print il with at least 1 layer with at least 1 image'
      );
    }

    // config.template contains template
    if (!config.template) {
      throw new Error('exportResizedPrintImgManipCmd config require template');
    }

    const smallPrintIL = this.generateSmallPrintIL(config, previewSize);

    const smallPrintImgManip = this.convertILtoImgManip(
      smallPrintIL,
      null,
      changeOrientation
    );

    return smallPrintImgManip;
  }
}

export default new ImgManipService();
