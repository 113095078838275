import { createSelector } from 'reselect';

const storeBulkMigrationSelector = state => state.getIn(['admin', 'storeBulkMigration']);

export const showLoadingSelector = createSelector([storeBulkMigrationSelector], state =>
  state.get('loading')
);

export const providersSelector = createSelector(
  [storeBulkMigrationSelector],
  state => state.get('providers') && state.get('providers').toJS()
);

export const storesSelector = createSelector(
  [storeBulkMigrationSelector],
  state => state.get('stores') && state.get('stores').toJS()
);

export const fetchStoresErrorSelector = createSelector([storeBulkMigrationSelector], state =>
  state.get('fetchStoresError')
);

export const csvDataSelector = createSelector(
  [storeBulkMigrationSelector],
  state => state.get('csvData') && state.get('csvData').toJS()
);

export const validateCsvError = createSelector([storeBulkMigrationSelector], state =>
  state.get('validateCsvError')
);

export const validateCsvSuccess = createSelector([storeBulkMigrationSelector], state =>
  state.get('validateCsvSuccess')
);

export const publishCsvError = createSelector([storeBulkMigrationSelector], state =>
  state.get('publishCsvError')
);

export const publishCsvSuccess = createSelector([storeBulkMigrationSelector], state =>
  state.get('publishCsvSuccess')
);

export const historySelector = createSelector(
  [storeBulkMigrationSelector],
  state => state.get('history') && state.get('history').toJS()
);

export const fetchHistoryErrorSelector = createSelector([storeBulkMigrationSelector], state =>
  state.get('fetchHistoryError')
);
