import Button from 'gooten-components/src/components/shared/Button';
import Modal from 'gooten-components/src/components/shared/Modal';
import bigcommerce from 'gooten-components/src/components/Stores/atoms/ConnectStore/assets/bigcommerce.png';
import React, { useState } from 'react';
import { Link } from 'react-router';

const ConnectSuccessModal = props => {
  const [showModal, setShowModal] = useState(true);

  return (
    <Modal
      hideCloseButton
      isOpen={showModal}
      cancelClick={() => setShowModal(false)}
      className="small"
    >
      <div className="image-container" />
      <div className="m-4 d-flex flex-column justify-content-center align-items-center text-center">
        <div className="title-2 mb-2">Your BigCommerce store was successfully connected!</div>
        <div className="body-text-2">
          Start by syncing your existing products to your BigCommerce store.
        </div>
        <div className="mt-3">
          <Link to="/hub/create-product/product-selection">
            <Button onClick={null} className="button-secondary m-2 large">
              Add new product
            </Button>
          </Link>
          <Link to="/hub/bigcommerce/635/link-product/product-selection">
            <Button onClick={null} className="button-primary m-2 large">
              Sync products
            </Button>
          </Link>
        </div>
      </div>
      <style jsx>
        {`
          .image-container {
            width: 25rem;
            height: 14rem;
            background: url(${bigcommerce}) no-repeat center 63% / cover;
            overflow: hidden;
          }
          :global(.big-commerce-connect-container .cerulean-modal) {
            overflow: hidden !important;
          }
        `}
      </style>
    </Modal>
  );
};

export default ConnectSuccessModal;
