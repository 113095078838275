import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import Select from 'gooten-components/src/components/shared/Select';
import Button from 'gooten-components/src/components/shared/Button';
import TextInputWithButton from 'gooten-components/src/components/shared/TextInputWithButton';
import { imagesPattern } from 'gooten-components/src/utils/regex';
import ImageEvidenceUpload from './atoms/ImageEvidenceUpload';
import ShippingAddress from './atoms/ShippingAddress';
import Loader from 'gooten-components/src/components/shared/Loader';
import Icon from 'gooten-components/src/components/shared/Icon';
import Toast from 'gooten-components/src/components/shared/Toast';
import ShipmentGroups from './atoms/ShipmentGroups';
import orderDetailsService from 'gooten-components/src/services/orderDetailsService';
import { flatten } from 'gooten-components/src/utils/array';
import Alert from 'gooten-components/src/components/shared/Alert';
import Config from 'gooten-components/src/config';
import KnowledgeBaseInfo from 'gooten-components/src/components/shared/KnowledgeBaseInfo';
import {
  COLORS,
  SELF_SERVICE_REPRINT_GUIDE_KB_LINK,
  SELF_SERVICE_REPRINT_GUIDE_KB_LINK_FUJI,
  SELF_SERVICE_REPRINT_GUIDE_KB_LINK_SENSARIA,
  SELF_SERVICE_REPRINT_GUIDE_KB_TEXT,
  SELF_SERVICE_REPRINT_REASONS_KB_LINK,
  SELF_SERVICE_REPRINT_REASONS_KB_LINK_FUJI,
  SELF_SERVICE_REPRINT_REASONS_KB_LINK_SENSARIA,
  SELF_SERVICE_REPRINT_REASONS_KB_TEXT,
  SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK,
  SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK_FUJI,
  SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK_SENSARIA,
  SELF_SERVICE_PHOTO_REQUIREMENTS_KB_TEXT,
  ORDER_SUPPORT_POLICIES_KB_TEXT,
  ORDER_SUPPORT_POLICIES_REPRINT_KB_LINK,
  ORDER_SUPPORT_POLICIES_KB_LINK,
  ORDER_SUPPORT_POLICIES_KB_LINK_FUJI,
  ORDER_SUPPORT_POLICIES_KB_LINK_SENSARIA
} from 'gooten-components/src/constants';

const ReprintSelfService = ({
  reprintReasons,
  uploadImage,
  imagesEvidence,
  initSelfService,
  orderSummary,
  reprintData,
  showLoading,
  submitReprint,
  shipmentGroups,
  toastText,
  clearToast,
  reason,
  reasonSelect
}) => {
  const textInput = useRef(null);
  const notesTextArea = useRef(null);
  const [errors, setErrors] = useState({});
  const [reprintError, setReprintError] = useState(false);

  useEffect(() => {
    initSelfService();
  }, []);

  useEffect(() => {
    if (imagesEvidence.size) {
      checkErrors();
    }
  }, [imagesEvidence]);

  useEffect(() => {
    checkErrors();
  }, [reason]);

  const createOption = option => (
    <option key={option.get('Id')} value={option.get('Id')}>
      {option.get('ReasonName')}
    </option>
  );

  // can upload file or url
  const uploadImageEvidence = files => {
    uploadImage(files);
  };

  const onSelectReason = e => {
    reasonSelect(parseInt(e.target.value));
  };

  const checkErrors = option => {
    let objs = errors;
    if (!notesTextArea.current.value.length) {
      objs = { ...objs, notes: 'Please add notes about reprint' };
    } else {
      delete objs.notes;
    }
    if (!imagesEvidence.size) {
      objs = { ...objs, images: 'You need to upload at least one image evidence' };
    } else {
      delete objs.images;
    }
    // eslint-disable-next-line
    if (reason == 0) {
      objs = { ...objs, reason: 'Please add reprint reason' };
    } else {
      delete objs.reason;
    }

    setErrors({ ...objs });
    return Object.keys(objs).length;
  };

  async function handleSubmit() {
    // NOTE: This block calls the GetItems API again to double check that
    // multiple reprints are not being placed
    setReprintError(false);
    const selectedIds = reprintData
      .get('items')
      ?.toJS()
      ?.map(x => x.Item);
    const mostRecentItems = await orderDetailsService.getItemsData(orderSummary.get('OrderId'));

    const reprintedItems = flatten(mostRecentItems.map(group => group.Items)).filter(
      itm => !!itm.Meta.ReprintOf || !!itm.Meta.ReprintedBy
    );

    if (reprintedItems.some(x => selectedIds.includes(x.Item))) {
      setReprintError(true);
      return;
    }
    //

    if (!checkErrors()) {
      submitReprint({
        adminComments: notesTextArea.current.value,
        refundReprintReason: reprintReasons.find(r => r.get('Id') === reason)?.get('ReasonName')
      });
    }
  }

  const errorToast = () => (
    <div className="toast-container" onClick={() => clearToast()}>
      <Toast
        className="mt-3"
        isOpen={!!toastText.length}
        textButtonClick={() => clearToast()}
        buttonText="Close"
      >
        {toastText}
      </Toast>
      <style jsx>{`
        position: absolute;
        top: 10px;
        right: 0;
        left: 0;
      `}</style>
    </div>
  );

  const errorMessage = type =>
    Object.keys(errors).includes(type) && (
      <React.Fragment>
        <div className="error-field caption-text d-flex align-items-center">
          <Icon icon="alertCircle" small />
          {errors[type]}
        </div>
        <style jsx>{`
          color: ${COLORS.cayennePepper};
          fill: ${COLORS.cayennePepper};
        `}</style>
      </React.Fragment>
    );

  const getOrderSupportPoliciesKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return ORDER_SUPPORT_POLICIES_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return ORDER_SUPPORT_POLICIES_KB_LINK_FUJI;
      case 2:
        return ORDER_SUPPORT_POLICIES_KB_LINK_SENSARIA;
      default:
        return ORDER_SUPPORT_POLICIES_KB_LINK;
    }
  };

  const getSelfServiceReprintGuideKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return SELF_SERVICE_REPRINT_GUIDE_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return SELF_SERVICE_REPRINT_GUIDE_KB_LINK_FUJI;
      case 2:
        return SELF_SERVICE_REPRINT_GUIDE_KB_LINK_SENSARIA;
      default:
        return SELF_SERVICE_REPRINT_GUIDE_KB_LINK;
    }
  };

  const getSelfServiceReprintReasonsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return SELF_SERVICE_REPRINT_REASONS_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return SELF_SERVICE_REPRINT_REASONS_KB_LINK_FUJI;
      case 2:
        return SELF_SERVICE_REPRINT_REASONS_KB_LINK_SENSARIA;
      default:
        return SELF_SERVICE_REPRINT_REASONS_KB_LINK;
    }
  };

  const getSelfServicePhotoRequirementsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK_FUJI;
      case 2:
        return SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK_SENSARIA;
      default:
        return SELF_SERVICE_PHOTO_REQUIREMENTS_KB_LINK;
    }
  };

  return (
    <CoreLayout>
      <div className="reprint-self-service">
        {!!toastText.length && errorToast()}
        {showLoading ? <Loader /> : null}
        <div className="title-1">Shipping Address</div>
        {orderSummary.size ? (
          <ShippingAddress shippingAddress={orderSummary.get('ShippingAddress')} />
        ) : (
          <Loader inline />
        )}
        <div className="review-title title-1">Review your order</div>
        <KnowledgeBaseInfo
          className="support-policies-kb"
          positionLeft
          kbArticles={[
            {
              visibleText: ORDER_SUPPORT_POLICIES_KB_TEXT,
              link: getOrderSupportPoliciesKbLink()
            }
          ]}
        />
        <div className="reprint-reason">
          <div className="title-3">Reprint reason:</div>
          <div className="reasons-holder">
            <Select
              label="Choose reprint reason"
              disabled={false}
              autoComplete="false"
              value={reason}
              placeholder=""
              onChange={onSelectReason}
            >
              {reprintReasons.map(createOption)}
            </Select>
            {errorMessage('reason')}
            <KnowledgeBaseInfo
              positionLeft
              kbArticles={[
                {
                  visibleText: SELF_SERVICE_REPRINT_REASONS_KB_TEXT,
                  link: getSelfServiceReprintReasonsKbLink()
                }
              ]}
            />
          </div>
        </div>
        <div className="notes-holder">
          <div className="title-3">Notes:</div>
          <div className="textarea-holder">
            <textarea
              className="textarea-notes"
              rows="6"
              ref={notesTextArea}
              onChange={checkErrors}
            />
            {errorMessage('notes')}
            <KnowledgeBaseInfo
              positionLeft
              kbArticles={[
                {
                  visibleText: SELF_SERVICE_REPRINT_GUIDE_KB_TEXT,
                  link: getSelfServiceReprintGuideKbLink()
                }
              ]}
            />
          </div>
        </div>
        <div className="images-holder">
          <div className="title-3">Image evidence:</div>
          <div className="image-upload-holder">
            <TextInputWithButton
              label="Upload from Url"
              placeholder="Paste image URL"
              onButtonClick={() =>
                textInput.current.state.value.length &&
                uploadImageEvidence(textInput.current.state.value)
              }
              buttonText="Save image"
              showValidation
              ref={textInput}
              validationMsg="This URL isn't working. Check supported file type or try another one!"
              regexp={imagesPattern}
            />
            <ImageEvidenceUpload callback={uploadImageEvidence} />
            {imagesEvidence.size ? (
              <div className="evidence-holder">
                <div className="image-holder">
                  {imagesEvidence.map(image => (
                    <div
                      style={{ background: `url(${image}) center center / contain no-repeat` }}
                      key={image}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {errorMessage('images')}
            <KnowledgeBaseInfo
              positionLeft
              kbArticles={[
                {
                  visibleText: SELF_SERVICE_PHOTO_REQUIREMENTS_KB_TEXT,
                  link: getSelfServicePhotoRequirementsKbLink()
                }
              ]}
            />
          </div>
        </div>
        <hr />
        <ShipmentGroups groups={shipmentGroups} />
        <hr />
        <Alert isOpen={reprintError} type="important" className="w-100">
          Our system shows that you have already made a Reprint request on at least one selected
          item.
        </Alert>
        <div className="button-holder">
          <Button
            disabled={reprintError || showLoading}
            loading={showLoading}
            className="large-only button-primary large ml-2"
            onClick={handleSubmit}
          >
            SUBMIT REPRINT
          </Button>
          {!!Object.keys(errors).length && (
            <div className="error-field caption-text d-flex align-items-center">
              <Icon icon="alertCircle" small />
              Please fix errors above
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          margin: 20px;
          width: 80%;
          display: flex;
          flex-direction: column;

          .title-3 {
            width: 50%;
          }

          .reasons-holder {
            width: 100%;
            margin: 0;
          }

          .error-field {
            color: ${COLORS.cayennePepper};
            fill: ${COLORS.cayennePepper};
            flex-direction: row;
            width: 130%;
            margin-left: 0px;
            margin-bottom: 0px;
          }

          .textarea-holder {
            width: 100%;
            margin: 0;
          }

          .evidence-holder {
            height: 158px;
            width: 100%;
            margin: 0;
            white-space: nowrap;
            overflow: auto;
          }

          .evidence-holder div {
            height: 140px;
            width: 140px;
            margin: 0 12px 0 0;
            display: inline-block;
          }

          .reprint-reason {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0;
          }

          .notes-holder {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .notes-holder .textarea-notes {
            resize: none;
            border-color: ${COLORS.neutralLight400};
            border-radius: 5px;
            width: 100%;
            padding: 10px;
          }

          .notes-holder .textarea-notes:focus {
            outline: none !important;
            border-color: ${COLORS.neutralLight400};
            padding: 10px;
          }

          .images-holder {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 54px;
          }

          .image-upload-holder {
            width: 100%;
            margin: 4px;
          }

          .button-holder {
            width: 140px;
          }

          .review-title {
            margin-bottom: 0;
          }

          .reprint-self-service :global(.support-policies-kb) {
            margin-left: 20px;
          }

          .reprint-self-service :global(.select-container) {
            margin-bottom: 0;
          }
        `}
      </style>
    </CoreLayout>
  );
};

ReprintSelfService.propTypes = {
  reprintReasons: PropTypes.instanceOf(List).isRequired,
  imagesEvidence: PropTypes.instanceOf(List).isRequired,
  shipmentGroups: PropTypes.instanceOf(List).isRequired,
  uploadImage: PropTypes.func.isRequired,
  initSelfService: PropTypes.func.isRequired,
  submitReprint: PropTypes.func.isRequired,
  clearToast: PropTypes.func.isRequired,
  orderSummary: PropTypes.object.isRequired,
  reprintData: PropTypes.object.isRequired,
  showLoading: PropTypes.bool.isRequired,
  toastText: PropTypes.string.isRequired,
  reason: PropTypes.number.isRequired,
  reasonSelect: PropTypes.func.isRequired
};

export default ReprintSelfService;
