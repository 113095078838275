import { takeLatest, put, call, all } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import {
  FETCH_PAYMENT_METHODS,
  REMOVE_METHOD,
  UPDATE_CARD,
  UPDATE_PAYPAL,
  updateCardFailure,
  fetchPaymentMethods
} from './PaymentActions';
import { fetchAsyncSuccess, fetchAsyncFail } from '../../../../store/actions/dataActions';
import { showLoading, hideLoading } from '../../../../store/actions/globalActions';
import paymentMethodsService from './services/paymentMethodsService';
import Log from '../../../../services/logService';
import { push } from '../../../shared/Notifications/NotificationsActions';
import analyticsService from '../../../../services/analyticsService';

const errorCodeToMessage = {
  PAYMENT_GATEWAY_ERROR:
    'We are experiencing an issue with our payment gateway provider. Please try again later'
};

export function* getPaymentMethods(action) {
  let getPaymentMethodsResult;
  let getBraintreeClientTokenResult;

  try {
    yield put(showLoading());
    getPaymentMethodsResult = yield call(paymentMethodsService.getPaymentSettings);
    getBraintreeClientTokenResult = yield call(paymentMethodsService.getBraintreeClientToken);

    // NOTE: Sanitize payment info before storing in state
    delete getPaymentMethodsResult.BraintreeKey;

    yield put(
      fetchAsyncSuccess(
        FETCH_PAYMENT_METHODS,
        fromJS({
          paymentMethods: getPaymentMethodsResult,
          braintreeToken: getBraintreeClientTokenResult.token
        })
      )
    );
  } catch (err) {
    if (err && errorCodeToMessage[err]) {
      yield put(push(errorCodeToMessage[err], 'failure'));
    }

    yield put(fetchAsyncFail(FETCH_PAYMENT_METHODS, err));
    throw Log.withFriendlyMsg('Failed to fetch payment methods', err, {
      action,
      getPaymentMethodsResult
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* removeMethodAsync(action) {
  try {
    yield put(showLoading());
    yield call(paymentMethodsService.removeMethod, action.payload);
    yield put(push('Payment method removed', 'success'));
    analyticsService.track(
      'Custom Order Form - Credit Card',
      'Payment Method Removed',
      'Custom Order Form'
    );
    yield put(fetchPaymentMethods());
  } catch (err) {
    yield put(hideLoading());
    yield put(updateCardFailure(UPDATE_CARD, err.msg));
    yield put(push(err.msg, 'failure'));
  }
}

export function* updateCardAsync(action) {
  try {
    yield put(showLoading());
    let updateCardPayload = action.payload;
    let method = paymentMethodsService.updateCard;

    if (!action.payload.token) {
      updateCardPayload = yield call(paymentMethodsService.tokenizeCard, action.payload);
      method = paymentMethodsService.addCard;
    }

    yield call(method, updateCardPayload);
    yield put(push('Credit Card information updated', 'success'));
    analyticsService.track(
      'Custom Order Form - Credit Card',
      'Payment Method Added',
      'Custom Order Form'
    );
    yield put(fetchPaymentMethods());
  } catch (err) {
    yield put(hideLoading());
    yield put(updateCardFailure(UPDATE_CARD, err.msg));
    yield put(push(err.msg, 'failure'));
  }
}

export function* updatePaypalAsync({ nonce, braintreeToken }) {
  let result = null;
  try {
    yield put(showLoading());
    result = yield call(paymentMethodsService.updatePaypal, nonce, braintreeToken);
    const newData = fromJS(result);
    yield put(fetchAsyncSuccess(UPDATE_PAYPAL, { nonce, braintreeToken, newData }));
    yield put(push('PayPal information updated', 'success'));
    analyticsService.track(
      'Custom Order Form - Credit Card',
      'Payment Method Added',
      'Custom Order Form'
    );
    yield put(fetchPaymentMethods());
  } catch (err) {
    yield put(hideLoading());
    yield put(fetchAsyncFail(UPDATE_CARD, err.msg));
    yield put(push(err.msg, 'failure'));
  }
}

export function* watchForLoadCommand() {
  yield takeLatest(FETCH_PAYMENT_METHODS.ASYNC, getPaymentMethods);
}

export function* watchRemoveMethod() {
  yield takeLatest(REMOVE_METHOD.ASYNC, removeMethodAsync);
}

export function* watchUpdateCard() {
  yield takeLatest(UPDATE_CARD.ASYNC, updateCardAsync);
}

export function* watchUpdatePaypal() {
  yield takeLatest(UPDATE_PAYPAL.ASYNC, updatePaypalAsync);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchForLoadCommand(), watchRemoveMethod(), watchUpdateCard(), watchUpdatePaypal()]);
}
