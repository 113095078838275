/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Config from '../config';
import { Helmet } from 'react-helmet';
import { mainStyles } from 'gooten-components/src/styles.js';
import '../Signup/SignupView.scss';
import Header from '../Signup/pages/Header';
import {
  getShopifyOAuthUrl,
  parseStoreUrl,
  openOAuthPage,
  OAUTH_STATUS
} from 'gooten-components/src/utils/oauth';

class ShopifyOAuthView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.openShopifyOAuth();
  }

  openShopifyOAuth = async () => {
    const signInUrl = Config.get('signInUrl');
    if (window.location !== window.parent.location) {
      //We are inside Shopify dashboard iframe, just folow old logic
      window.location.replace(signInUrl);
      return;
    }
    const storeName = parseStoreUrl(Config.get('shop'));
    const key = Config.get('clientId');
    const url = getShopifyOAuthUrl(storeName, key);
    window.location.replace(decodeURIComponent(url));
  };

  render() {
    const { failedReason } = this.state;
    return (
      <div className="sign-up">
        <Helmet>
          <meta
            name="description"
            content="Create your Gooten account and get started with our print on-demand order management system."
          />
        </Helmet>
        <Header />
        <div className="middle-content">
          {failedReason ? (
            <p>{failedReason}</p>
          ) : (
            <p>
              Don't close this page. You will be redirected to Gooten after completing actions at
              Shopify's dashboard.
            </p>
          )}
        </div>
        <style jsx global>
          {mainStyles}
        </style>
      </div>
    );
  }
}

ShopifyOAuthView.propTypes = {
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default ShopifyOAuthView;
