import React from 'react';
import PropTypes from 'prop-types';
import ServiceWrapper from '../shared/services/ServiceWrapper';
import ErrorModal from 'gooten-components/src/components/shared/ErrorModal';
import Strings from './strings';

import './IntegrateShopifyStoreView.scss';

class IntegrateShopifyStoreView extends React.Component {
  static propTypes = {
    location: PropTypes.any,
    router: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      storeIsLoading: false,
      hasErrors: false,
      statusMsg: '',
      showErrorModal: false
    };
  }

  componentDidMount() {
    if (this.state.storeIsLoading === false) {
      this.setState({ storeIsLoading: true }, this.loadStore());
    }
  }

  handleModalClose = () => {
    this.props.router.push('/onboardingdashboard');
  };

  showErrorModal = errorMessage => {
    this.setState({
      hasErrors: true,
      statusMsg: errorMessage,
      showErrorModal: true
    });
  };

  loadStore = () => {
    const searchParams = new URLSearchParams(this.props.router.location.search);

    ServiceWrapper.connectToStore(
      searchParams.get('shop').replace('.myshopify.com', ''),
      searchParams.get('code')
    ).then(
      resp => {
        if (resp && resp.AccessToken && resp.StoreName) {
          this.props.router.push('/hub/all');
        } else {
          this.showErrorModal(Strings.CONNECT_TO_STORE_ERROR_MESSAGE);
        }
      },
      reject => {
        this.showErrorModal(Strings.CONNECT_TO_STORE_ERROR_MESSAGE);
      }
    );
  };

  render() {
    const padding = { paddingTop: '100px' };
    return (
      <div className="integrate-shopify-store-view-container">
        <ErrorModal
          isOpen={this.state.showErrorModal}
          errorMessage={this.state.statusMsg}
          modalClose={this.handleModalClose}
        />
        <div className="col-md-offset-2 col-md-8" style={padding}>
          <div className="col-sm-12 text-center">
            <div className="icon-slothy-connect" />
            <h3 className="text-center">{Strings.PAGE_TEXT}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default IntegrateShopifyStoreView;
