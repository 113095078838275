'use strict';
// @flow
export const INTERNAL_EVENTS = {
  IMAGE_UPDATE: 'imageUpdate'
};

export const PUBLIC_EVENTS = {
  CHANGE: 'change',
  ROTATE: 'rotate',
  CROP_START: 'cropstart',
  CROP_END: 'cropend',
  CROP_CANCEL: 'cropcancel',
  ORIENTATION_CHANGE: 'orientationchange',
  DRAG_START: 'dragstart',
  DRAG_END: 'dragend',
  UNDO: 'undo',
  REDO: 'redo',
  REMOVE: 'remove',
  CENTER_IMAGE: 'centerimage',
  ALIGN_IMAGE_HORIZONTAL: 'alignimagehorizontal',
  ALIGN_IMAGE_VERTICAL: 'alignimagevertical',
  SELECT_IMAGE: 'imageselect',
  DESELECT_IMAGE: 'imagedeselect',
  SCALE: 'scale',
  ERROR: 'error',
  TEMPLATE_LOADED: 'templateloaded',
  CHANGEDX: 'imagechangedx',
  BULK_MODE_TOGGLE: 'bulkmode',
  IMAGES_LOADED: 'imagesloaded',
  CANVAS_ZOOM: 'canvaszoom'
};

export const ANALYTIC_EVENTS = {
  rotate: 'Rotate clicked',
  cropstart: 'Crop clicked',
  cropend: 'Crop successful',
  dragend: 'Image moved',
  undo: 'Undo clicked',
  centerimage: 'Center clicked',
  alignimagevertical: 'Align Vertical clicked',
  alignimagehorizontal: 'Align Horizontal clicked',
  canvaszoom: 'Canvas zoomed'
};
