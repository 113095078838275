import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'gooten-components/src/components/shared/Spinner';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import { COLORS } from 'gooten-components/src/constants';

class SKUsListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { imageLoaded: false };
  }

  onLoadImage = () => {
    this.setState({ imageLoaded: true });
  };

  clickOnItem = () => {
    this.props.onSelectVariantID(this.props.item.get('id'));
  };

  render() {
    const columns = this.props.item.get('options') ? (
      this.props.item.get('options').map(o => (
        <Cell key={this.props.item.get('sku') + o.get('id')} onClick={() => this.clickOnItem()}>
          <div className="overflow-text">{o.get('value')}</div>
        </Cell>
      ))
    ) : (
      <Cell key={this.props.item.get('sku')} onClick={() => this.clickOnItem()}>
        <div className="overflow-text">{this.props.item.get('sku')}</div>
      </Cell>
    );

    const imageUrl = getSmallPreviewImageResizerUrl(
      this.props.item.getIn(['gootenMapping', 'images', 0, 'url']) ||
        this.props.item.get('imageUrl')
    );

    const columnsLength = this.props.item.get('options')
      ? this.props.item
          .get('options')
          .map(o => {
            return 'minmax(6rem, 1fr)';
          })
          .join(' ')
          .toString()
      : 'minmax(6rem, 1fr)';

    return (
      <>
        <TableRow
          columns={`8rem ${columnsLength} 8rem 5rem`}
          className={`skus-list-item ${this.props.selected ? 'selected' : ''}`}
        >
          <Cell
            className={`selection-holder sticky-left ${
              this.props.selected ? 'with-border-success' : 'with-border'
            }`}
            onClick={() => this.clickOnItem()}
          >
            <div className="selection-holder-inner d-flex">
              <Checkbox
                className="mr-3"
                checked={this.props.selected}
                onChange={() => this.clickOnItem()}
              />
              <div className="image-holder">
                <img className="item-image" src={imageUrl} onLoad={this.onLoadImage} />
                <Spinner />
              </div>
            </div>
          </Cell>
          {columns}
          <Cell className="cost-holder" onClick={() => this.clickOnItem()}>
            <div className="overflow-text">
              {this.props.item.getIn(['price', 'formatted_price'])}
            </div>
          </Cell>
          <Cell
            rightAlign
            className={`${this.props.selected ? 'with-border-success' : 'with-border'}`}
          >
            <Button
              className="button-default small visible-md-inline-block visible-lg-inline-block"
              disabled={this.state.disableEdit}
              onClick={() =>
                this.props.disableEdit ? null : this.props.onEditItem(this.props.item.get('id'))
              }
            >
              <Icon icon="edit2" small />
              <span className="edit-design-button">Edit</span>
            </Button>
            <Button
              className="button-default small visible-xs visible-sm"
              iconOnly
              disabled={this.state.disableEdit}
              onClick={() =>
                this.props.disableEdit ? null : this.props.onEditItem(this.props.item.get('id'))
              }
            >
              <Icon icon="edit2" small />
            </Button>
          </Cell>
        </TableRow>
        <style jsx>
          {`
            :global(.skus-list-item .overflow-text) {
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .item-image {
              max-height: 2.75rem;
              max-width: 2.75rem;
              opacity: ${this.state.imageLoaded ? 1 : 0};
              transition: opacity 0.3s;
            }
            .image-holder {
              position: relative;
              width: 50px;
            }
            :global(.spinner-container) {
              display: ${this.state.imageLoaded ? 'none' : 'block'};
            }
            :global(.selection-holder-inner) {
              position: absolute;
              padding-left: 1rem;
              left: 1rem;
              background: ${COLORS.neutralLight50};
            }
            :global(.selection-holder-inner.head-left-content) {
              background: ${COLORS.neutralLight50};
            }
            :global(.skus-list) {
              overflow: auto;
            }
            :global(.skus-list-item) {
              position: unset !important;
            }
          `}
        </style>
      </>
    );
  }
}

SKUsListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectVariantID: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disableEdit: PropTypes.bool
};

export default SKUsListItem;
