import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'gooten-components/src/components/shared/Loader';
import VariantsSelection from './components/VariantsSelection';
import ProductSelection from './components/ProductSelection';
import SKUsList from './components/SKUsList/NewProductSKUsList';
import HubSKUsList from './components/SKUsList/HubProductSKUsList';
import InfoLine from './components/InfoLine';
import ToastModal from 'gooten-components/src/components/shared/ToastModal';
import observer from './../../../utils/NavigationPanelObserver';

import './SKUsSelectionView.scss';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import PageBody from 'gooten-components/src/components/shared/PageBody';

class SKUsSelectionView extends React.Component {
  static propTypes = {
    ready: PropTypes.bool.isRequired,
    selectedProduct: PropTypes.any,
    router: PropTypes.object,
    init: PropTypes.func.isRequired,
    onUnmount: PropTypes.func,
    regionsActive: PropTypes.bool,
    subCategoriesActive: PropTypes.bool,
    flowSelectedIndex: PropTypes.number,
    publishInProgress: PropTypes.bool,
    resetTopNotification: PropTypes.func.isRequired,
    topNotification: PropTypes.object.isRequired
  };

  get providerPath() {
    return `/hub/${this.props.router.params.provider}/${this.props.router.params.store}`;
  }

  constructor(props) {
    super(props);

    this.state = {
      marginLeft: observer.marginLeft()
    };
  }

  componentDidMount() {
    this.props.init(this.providerPath);

    // navigation-panel size observer
    observer.connect(() => {
      this.setState({ marginLeft: observer.marginLeft() });
    });
  }

  componentWillUnmount() {
    observer.disconnect();
    this.props.onUnmount && this.props.onUnmount();
  }

  renderTopNotification() {
    return (
      <ToastModal
        show={this.props.topNotification.get('show')}
        title={this.props.topNotification.get('message')}
        duration={30}
        onShowNotification={() => this.props.resetTopNotification()}
        fullScreen
        zIndex={1001}
      />
    );
  }

  renderSKUsList() {
    if (this.props.selectedProduct) {
      if (this.props.ready) {
        // flowSelectedIndex 1 => New Product
        if (this.props.flowSelectedIndex) {
          if (!this.props.regionsActive && !this.props.subCategoriesActive) {
            return <SKUsList marginLeft={this.state.marginLeft} />;
          } else {
            return null;
          }
        } else {
          // flowSelectedIndex 0 => Saved(HUB) Product
          return <HubSKUsList marginLeft={this.state.marginLeft} />;
        }
      }
      return <Loader />;
    }
    return null;
  }

  render() {
    let content = null;

    if (!this.props.ready && !this.props.selectedProduct) {
      content = <Loader />;
    } else {
      content = (
        <div className="container-fluid container-mobile">
          <PageHeader
            size="standard"
            title="Sync a Product"
            backHref={`#${this.providerPath}/link-product/product-selection`}
            backText="Select a Product"
          />
          <PageBody>
            <div className="col-md-6">
              <VariantsSelection />
            </div>
            <div className="col-md-6 container-mobile">
              <ProductSelection marginLeft={this.state.marginLeft} />
            </div>
            <div className="col-sm-12 hidden-xs" />
            {this.props.selectedProduct &&
            this.props.ready &&
            !this.props.regionsActive &&
            !this.props.subCategoriesActive ? (
              <InfoLine />
            ) : null}
            {this.renderSKUsList()}
            {this.props.publishInProgress ? <Loader /> : null}
          </PageBody>
        </div>
      );
    }

    return (
      <div className="link-skus-selection-view-container">
        {this.renderTopNotification()}
        {content}
      </div>
    );
  }
}

export default SKUsSelectionView;
