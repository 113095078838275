import React from 'react';
import './index.scss';

const SignUpInfo = props => {
  return (
    <div className="sign-up-info">
      <div className="info-header">When operations matter, Gooten makes the difference.</div>
      <div className="checklist-item">
        High-quality, scalable, on-demand order fulfillment for fast growing stores
      </div>
      <div className="checklist-item">
        100+ fully customizable product types from a dynamic, global manufacturing network
      </div>
      <div className="checklist-item">
        Built-in integrations for Shopify, Etsy, WooCommerce, BigCommerce and an industry leading
        API
      </div>
      <div className="checklist-item">Simple, comprehensive shipping options</div>
    </div>
  );
};

export default SignUpInfo;
