import _loadImage from 'blueimp-load-image';

export default class ImageLoader {
  // return HtmlImageElement with loaded fullsize image
  static loadImage(image: File | string, corsEnabled: boolean): Promise  {
    return new Promise((resolve, reject) => {

      // Editor doesn't require CORS enabled images
      // Since it doesn't export canvases to data urls or blobs
      // But it's still can be enabled optionally
      let opts = corsEnabled ? { crossOrigin : 'Anonymous' } : undefined;

      let retries = 0;

      const onImageLoad = (res, data) => {
        if (res.type === 'error') {
          if (retries < 3) {
            console.log(`Image editor: retry to load ${image}`);
            retries++;
            _loadImage(image, onImageLoad, opts);
          }
          else {
            reject(new Error(`Image editor: failed to load ${image}, after ${retries} retries`));
          }
        }
        else {
          _loadImage.parseMetaData(image, (data) => {
            let orientation = false;
            if (data && data.exif) {
              orientation = data.exif.get('Orientation');
            }

            resolve({image: res, orientation});
          });
        }
      };

      _loadImage(image, onImageLoad, opts);
    });
  };


  // Serg: Commented out upon perf optimization
  // as redundant step
  // static resizeImage(image, orientation, maxWidth, maxHeight) {
  //   return _loadImage.scale(image, {
  //     maxWidth,
  //     maxHeight,
  //     canvas: true,
  //     orientation
  //   });
  // }
}
