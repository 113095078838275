import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DesignFullscreen from './DesignFullscreen';
// import Spinner from '../../../shared/Spinner'
import { List } from 'immutable';
import './Design.scss';
import Button from '../../../shared/Button';
import Modal from '../../../shared/Modal';
import Thumbnail from '../../../shared/Thumbnail';

const Design = props => {
  const [isModalOpen, setModalOpen] = useState(false);

  const item = (design, i) => (
    <div className="design-item d-flex mb-2" key={`${design.get('imageUrl')}${i}`}>
      <div className="mr-3">
        <Thumbnail image={design.get('imageUrl')} />
      </div>
      <div className="mb-3">
        <p className="body-text-2 heavy m-0">{`${design.get('height')} x ${design.get(
          'width'
        )} px`}</p>
        <p className="caption-text m-0">{design.get('format').toUpperCase()}</p>
      </div>
    </div>
  );

  const designs = props.designs.map((design, i) => item(design, i));

  const handleEdit = () => {
    props.gotoImageUpload();
  };

  return (
    <div className="product-publish-design flex-container">
      <div className="title-holder d-flex justify-content-between align-items-center">
        <span className="title-2">Design</span>
        <Button className="design-edit button-default small" onClick={() => handleEdit()}>
          Edit
        </Button>
      </div>
      <div className="images-grid mt-4 mb-4 flex-container">{designs}</div>
      {props.showViewAll ? (
        <React.Fragment>
          <Button className="button-default medium" onClick={() => setModalOpen(true)}>
            View all
          </Button>
          {/* <div className='text-holder flex-container'>
          <div className='flex-container'>
            <span className='icon-checkbox-selected' />
            <span>Hold for customization</span>
          </div>
          <span className='help-text'>This will hold the product from going into
            production until customization has been made</span>
          </div> */}
        </React.Fragment>
      ) : null}
      <Modal
        isOpen={isModalOpen}
        onRequestHide={null}
        className="medium"
        cancelClick={() => setModalOpen(false)}
        primaryClick={() => handleEdit()}
        actionText="Edit design"
        cancelText="Done"
        title="Edit Designs"
      >
        <div className="pt-4 pb-4">
          <DesignFullscreen title="Design files" designs={props.designs} />
        </div>
      </Modal>
      <style jsx>
        {`
          .product-publish-design {
            flex-direction: column;
          }
          .images-grid {
            height: 156px;
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
          }
        `}
      </style>
    </div>
  );
};

Design.propTypes = {
  gotoImageUpload: PropTypes.func,
  designs: PropTypes.instanceOf(List),
  showViewAll: PropTypes.bool
};

export default Design;
