import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Icon from './Icon.js';

const SwitchField = ({ disabled, checked, className, label, secondaryText, onChange }) => {
  return (
    <label className={`d-flex ${className || ''} ${disabled ? 'disabled' : ''}`}>
      {label && (
        <div>
          <p className="m-0 heavy body-text-2">{label}</p>
          {secondaryText && <p className="caption-text d-flex m-0">{secondaryText}</p>}
        </div>
      )}
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={e => onChange(e.target.checked)}
      />
      <div className="indicator ml-2">
        <div className="nipple d-flex justify-content-center">
          {checked ? <Icon icon="check" small /> : <Icon icon="x" small />}
        </div>
      </div>
      <style jsx>
        {`
          // input must be 'visible' to screen readers for accessiblity
          input {
            border: 0;
            clip: rect(0 0 0 0);
            clippath: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
          }
          label {
            min-height: ${label ? '2.5rem' : 'auto'};
            display: flex;
            align-items: ${label ? 'flex-start' : 'center'};
            justify-content: ${label ? 'space-between' : 'center'};
            width: 100%;
            cursor: pointer;
          }
          .indicator {
            box-sizing: border-box;
            border-radius: 1rem;
            width: 2.625rem;
            min-width: 2.625rem;
            height: 1.625rem;
            background: ${checked ? COLORS.gootenBlue : COLORS.neutralLight600};
            display: flex;
            align-items: center;
            transition-duration: 0.3s;
          }
          .nipple {
            height: 1rem;
            width: 1rem;
            border-radius: 0.5rem;
            background: ${COLORS.neutralLight50};
            fill: ${checked ? COLORS.gootenBlue : COLORS.neutralLight600};
            margin-left: ${checked ? '1.25rem' : '.25rem'};
            transition-duration: 0.3s;
          }
          .disabled {
            cursor: not-allowed;
          }
        `}
      </style>
    </label>
  );
};

SwitchField.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  secondaryText: PropTypes.string
};

export default SwitchField;
