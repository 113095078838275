import { Layer, Rect, Group } from 'konva';
import { ImageEvents } from '../../../flow/konvaTypes';
import { select } from '../../../state/actions/actionCreators';
import PositioningService from '../../../utils/positioningService';
import ToggleEditModeAction from '../toggleEditMode.action';
import ImageService from '../../image/image.service';
import EventEmitter from 'events';
import { noBubble } from '../../../utils/eventUtils';

export default class ViewportComponent {

  layer: Layer;
  editModeToggler: ToggleEditModeAction;
  publicEvents: EventEmitter;

  constructor(layer: Layer, publicEvents: EventEmitter) {
    this.layer = layer;
    this.publicEvents = publicEvents;
    this.editModeToggler = new ToggleEditModeAction(this.publicEvents);
  }

  draw(ctx: any) {
    let rects = [];
    for (let layer of ctx.state.template.layers.filter(c => c.type === 'image')) {

      let viewportPosition = PositioningService.convertToCanvasSize(
        PositioningService.getViewportRect(layer),
        ctx.state,
        layer.id
      );
      let rectGroup = new Group({x : viewportPosition.x, y: viewportPosition.y});
      rectGroup.listening(true);

      let rect = new Rect(Object.assign(viewportPosition, {x : 0, y : 0}));
      // add cursor styling
      rectGroup.off('mouseover').on('mouseover', () => {
        document.body.style.cursor = 'pointer';
      });
      rectGroup.off('mouseout').on('mouseout', () => {
        document.body.style.cursor = 'default';
      });
      rectGroup.off('mouseenter').on('mouseenter', () => {
        ctx.events.emit(ImageEvents.mouseEnter + '_' + layer.id);
        this.layer.batchDraw();
      });
      rectGroup.off('mouseleave').on('mouseleave', () => {
        ctx.events.emit(ImageEvents.mouseLeave + '_' + layer.id);
        this.layer.batchDraw();
      });

      rectGroup.off('click tap').on('click tap', (e) => {
        this.editModeToggler.toggle(e, ctx, layer.id);
      });

      rectGroup.add(rect);

      rects.push(rectGroup);

      if (ctx.state.images.current.selected.layerId === layer.id && ctx.state.editor.mode === 'edit') {
        // draw rects for images
        for (let image of ctx.state.images.current.images.filter(i => i.layerId === layer.id)) {

          let imagePos = ImageService.getSingleImageCanvasState(ctx.state, image, layer, true);

          // Need this to support rotation
          // TODO: refactor - move this to ImageService.getSingleImageArguments()
          // and update all the places to just use x,y and offsetX,Y
          imagePos.offsetX = imagePos.width / 2;
          imagePos.offsetY = imagePos.height / 2;
          imagePos.x = imagePos.x + imagePos.offsetX;
          imagePos.y = imagePos.y + imagePos.offsetY;

          // debug
          //imagePos.fill = 'red';
          let imageRect = new Rect(imagePos);
          let imageId = image.id;

          imageRect.off('mouseenter').on('mouseenter', () => {
            ctx.events.emit(ImageEvents.mouseEnter + '_' + layer.id);
          });
          imageRect.off('mouseleave').on('mouseleave', () => {
            ctx.events.emit(ImageEvents.mouseLeave + '_' + layer.id);
          });
          imageRect.off('click tap').on('click tap', (e) => {
            if (imageId === ctx.state.images.current.selected.imageId) {
              this.editModeToggler.toggle(e, ctx, layer.id);
            }
            else {
              // NOTE: Cancel bubble to skip it in stage content click
              // drag.action add listener to stage.contentClick event
              noBubble(e.evt);

              ctx.actionProcessorCb(select(imageId, layer.id));
            }
          });
          rects.push(imageRect);
        }
      }
    }

    ctx.viewportAreas = rects;
    for(let va of ctx.viewportAreas) {
      this.layer.add(va);
    }
  }

}
