import { connect } from 'react-redux';
import Regions from './Regions';
import { getRegionNames, isRegionsShown } from '../../../../SKUSelectionSelectors';
import {
  selectRegions,
  cleanSelectedOptions,
  deselectRegion
} from '../../../../SKUSelectionActions';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = {
  selectRegions: r => selectRegions(r),
  deselectRegion: r => deselectRegion(r),
  cleanSelectedOptions: () => cleanSelectedOptions()
};

const mapStateToProps = state => {
  return {
    isRegionsShown: isRegionsShown(state),
    regions: getRegionNames(state),
    selectedRegions: state.get('SKUSelection').get('selectedRegions')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
