import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from 'react16-fileupload';
import Input from 'gooten-components/src/components/shared/Input';
import { COLORS } from 'gooten-components/src/constants';

// NOTE: This is a class component because the fileupload package needs a string ref below
class PersonalizeImageUpload extends React.Component {
  state = {
    newUrl: null,
    selectedFile: undefined
  };

  componentDidMount() {
    this.props.setThumbImage({});
    this.props.setFilesToSubmit({});
  }

  fileUploadOptions = {
    wrapperDisplay: 'inline-block',
    chooseAndUpload: false,
    baseUrl: './',
    multiple: false,
    numberLimit: 1,
    accept: 'image/jpeg,image/png',
    chooseFile: file => {
      if (file.length) {
        this.setState({ selectedFile: file });
        this.props.setThumbImage({
          ...this.props.thumbImage,
          [this.props.image.Id]: URL.createObjectURL(file[0])
        });
        this.props.setFilesToSubmit({
          ...this.props.filesToSubmit,
          [this.props.image.Id]: { type: 'file', image: file }
        });
      }
    }
  };

  render() {
    return (
      <>
        <div>
          <div className="d-flex mr-n1 ml-n1">
            <Input
              id={`upload-url-${this.props.image.Id}`}
              className="mr-1 ml-1"
              type="text"
              value={this.state.newUrl || ''}
              onChange={e => {
                this.setState({ newUrl: e.target.value });
                this.props.setThumbImage({
                  ...this.props.thumbImage,
                  [this.props.image.Id]: e.target.value
                });
                this.props.setFilesToSubmit({
                  ...this.props.filesToSubmit,
                  [this.props.image.Id]: { type: 'url', image: e.target.value }
                });
              }}
              label="Artwork URL"
              placeholder="Paste image URL"
            />
          </div>
          <div>
            <FileUpload
              className={`file-upload-field mt-n4 mr-n1 ml-n1 flex-wrap`}
              options={this.fileUploadOptions}
            >
              <div ref="chooseBtn">
                <div className="caption-text ml-1 mr-1">
                  {this.state.selectedFile ? this.state.selectedFile[0].name : 'Select from device'}
                </div>
              </div>
            </FileUpload>
          </div>
        </div>
        <style jsx>{`
          .caption-text {
            text-decoration: none;
            border-bottom: 1px solid;
            color: ${COLORS.neutralDark200};
            cursor: pointer;
            max-width: 23rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            direction: rtl;
            text-align: left;
          }
          .caption-text:hover {
            text-decoration: none;
          }
        `}</style>
      </>
    );
  }
}

PersonalizeImageUpload.propTypes = {
  image: PropTypes.object.isRequired,
  setThumbImage: PropTypes.func,
  thumbImage: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setFilesToSubmit: PropTypes.func.isRequired,
  filesToSubmit: PropTypes.object.isRequired
};

export default PersonalizeImageUpload;
