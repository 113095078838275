import { connect } from 'react-redux';
import TaskNotification from './TaskNotification';
import { taskNotificationSelector } from '../ProductsSelectors';
import { hideTaskNotitication } from '../ProductsActions';

const mapDispatchToProps = {
  hide: taskId => hideTaskNotitication(taskId)
};

const mapStateToProps = (state, ownProps) => ({
  notification: taskNotificationSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskNotification);
