import PositioningService from '../../utils/positioningService';

export class ViewportService {

  getViewports(state) {
    let results = [];

    for(let layer of state.template.layers) {
      if (layer.type === 'image') {
        let hasImages = !!state.images.current.images.find(i => i.layerId === layer.id);

        let args = PositioningService.convertToCanvasSize(
          PositioningService.getViewportRect(layer),
          state,
          layer.id);
        args.visible = !hasImages;
        args.layer = layer;
        results.push(args);
      }
    }

    return results;
  }

  isViewportShown(state, layer) {
    let hasImages = !!state.images.current.images.find(i => i.layerId === layer.id);
    let isSublimated = layer.x1 === 0 && layer.y1 === 0
      && layer.x2 === state.template.templateWidth
      && layer.y2 === state.template.templateHeight;
    return !hasImages && !isSublimated;
  }
}

// singleton
export default new ViewportService();
