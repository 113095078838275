import { connect } from 'react-redux';
import HubProductsView from './HubProductsView';
import { searchTextSelector } from './HubProductsViewSelectors';
import {
  deselectAll,
  toggleHubProductSelected,
  setSortValue
} from './components/HubProducts/HubProductsActions';
import { selectedItemsSelector, sortSelector } from './components/HubProducts/HubProductsSelectors';
import { searchChange } from './HubProductsViewActions';
import { hasCartItemsSelector } from '../CartView/CartViewSelectors';
import analyticsService from 'gooten-components/src/services/analyticsService';
import { editDesign } from '../EditDesignView/EditDesignViewActions';
import { mapEditDesignItemFromProductVariant } from '../../models/EditDesignItem';
import { addToCartRequestAsync, updateSource } from '../CartView/CartViewActions';
import { fromJS } from 'immutable';

const source = 'COF - Hub Products Flow';

const mapDispatchToProps = {
  searchReset: () => searchChange(''),
  doSearch: searchText => searchChange(searchText),
  changeSortValue: sortValue => setSortValue(sortValue),

  deselectAll: () => deselectAll(),
  toggleHubProductSelected: (hubProduct, isSelected) =>
    toggleHubProductSelected(hubProduct, isSelected),
  addToCart: hubProducts => {
    updateSource(source);
    analyticsService.track(source, 'Add To Cart Clicked', 'COF');

    hubProducts.forEach(hp => {
      const productAddObj = {
        product_sku: (hp.gooten_mapping && hp.gooten_mapping.sku) || '',
        product_name: hp.sku,
        product_price: hp.price
      };

      analyticsService._track('custom order', 'product added to order', null, null, productAddObj);
    });

    const data = fromJS({ products: hubProducts.toJS() });

    return addToCartRequestAsync(data, source);
  },
  editDesign: productVariant => {
    analyticsService.track('COF - Hub Products Flow', 'Hub Product Edit Design Clicked', 'COF');
    return editDesign(mapEditDesignItemFromProductVariant(productVariant));
  }
};

const mapStateToProps = state => {
  return {
    sortValue: sortSelector(state),
    searchText: searchTextSelector(state),
    hasCartItems: hasCartItemsSelector(state),
    selectedItems: selectedItemsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubProductsView);
