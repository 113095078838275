import { postJson } from '../../../utils/http';
import { removeDuplicatesBy } from '../../../utils/array';
import Config from '../../../config';
import Log from '../../../services/logService';
import { getGuid, getDateNowStr } from '../../../utils/random';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import { skusWithPreview } from '../atoms/Mockups/MockupsSelectors';

const REGIONS_OPTION_ID = 'REGIONS';
const ORIENTATION_OPTION_ID = 'ORIENTATION';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    }
  };
};

const getPublishUrl = () => `${Config.get('storeApi')}publish`;
const getDeleteVariantsUrl = () => `${Config.get('storeApi')}variants/delete`;

const fillData = (
  productName,
  productDescription,
  productOptions,
  skus,
  selectedOptionIDs,
  previewData
) => {
  return {
    productName: productName,
    productDesc: productDescription,
    productType: '',
    selectedTags: [],
    selectedCollections: [],
    options: fillProductOptions(productOptions),
    selectedOptions: fillSelectedOptions(productOptions, selectedOptionIDs),
    variants: skus.map((item, index) => {
      // tricky way to get sku options at this point
      const options = Object.keys(item)
        .filter(key => key.length > 30)
        .map(key => ({ id: key, value: item[key] }));
      return {
        sku: item.sku,
        index: item.index,
        customSku: `${item.sku}-${getDateNowStr()}`,
        customerPrice: item.maxCost,
        maxCost: item.maxCost,
        minCost: item.minCost,
        previewUrl: getPreviewUrl(item, previewData),
        options
      };
    })
  };
};

const fillProductOptions = productOptions => {
  return productOptions.map(o => ({
    id: o.id,
    title: o.title
  }));
};

const fillSelectedOptions = (productOptions, selectedOptionIDs) => {
  const selectedOptions = productOptions
    .filter(option => option.prefill)
    .map((option, i) => {
      return {
        id: selectedOptionIDs[i],
        value: option.id
      };
    });

  return selectedOptions.length
    ? selectedOptions
    : [
        {
          id: selectedOptionIDs[0],
          value: productOptions[0].id
        }
      ];
};

const getPreviewUrl = (sku, previewData) => {
  const withPreviews = skusWithPreview(previewData);
  if (withPreviews.indexOf(sku.sku + sku.index) === -1) {
    return null;
  }
  return sku.proPreview
    ? sku.proPreview.imageUrls[0] && getSmallPreviewImageResizerUrl(sku.proPreview.imageUrls[0])
    : sku.spaces[0].previewImgUrl && getSmallPreviewImageResizerUrl(sku.spaces[0].previewImgUrl);
};

class PublishService {
  publishType() {
    return Config.get('editMode') && Config.get('duplicateMode')
      ? 'duplicate'
      : Config.get('editMode')
      ? 'edit'
      : Config.get('linkMode')
      ? 'link'
      : 'create';
  }

  mergeConfigs(defaultConfig, customConfig) {
    return defaultConfig.mergeDeep(customConfig).toJS();
  }

  verifyPublishData(data, skus, productOptions, selectedOptionIDs, isLinkMode, previewData) {
    if (!data.verified) {
      // save initial publish state
      data.storage.wasConnected = data.storage.selected;
      data.stores.forEach(s => {
        s.wasConnected = s.selected;
        s.wasDraft = s.draft;
      });

      // Set storage options from common Gooten product
      if (!isLinkMode) {
        // in Link mode we need this to be store options
        data.storage.options = fillProductOptions(productOptions);
        data.storage.selectedOptions = fillSelectedOptions(productOptions, selectedOptionIDs);
      }

      const sources = [data.storage];
      sources.push.apply(
        sources,
        data.stores.map(store => {
          if (!store.wasConnected) {
            // for new stors use common Gooten options
            store.options = fillProductOptions(productOptions);
            store.selectedOptions = fillSelectedOptions(productOptions, selectedOptionIDs);
          }
          return store;
        })
      );

      sources.forEach(s => {
        s.variants.forEach(v => {
          const sku =
            isLinkMode || Config.get('editMode')
              ? skus.find(
                  sku => sku.sku.toLowerCase() === v.sku.toLowerCase() && sku.index === v.index
                )
              : skus.find(sku => sku.sku.toLowerCase() === v.sku.toLowerCase());
          if (sku) {
            const options = Object.keys(sku)
              .filter(key => key.length > 30)
              .map(key => ({ id: key, value: sku[key] }));
            v.customerPrice = v.customerPrice || sku.maxCost;
            v.maxCost = v.maxCost || sku.maxCost;
            v.minCost = v.minCost || sku.minCost;
            v.options = options;
            v.previewUrl =
              getPreviewUrl(sku, previewData) === v.previewUrl
                ? v.previewUrl
                : getPreviewUrl(sku, previewData);
          }
        });
      });

      // data.verified = true
    }

    // Check if sku order was changed in Preview step, and sort by it if needed.
    // It will be the order in which they will be submitted to API.
    // Must be the same on UI to allow highlight of invalid SKUs by index from API validation response.
    const skuOrderChanged =
      data.storage.variants.map(v => v.sku).join(',') !== skus.map(v => v.sku).join(',');

    if (skuOrderChanged) {
      const previewSkusSortOrder = skus.map(v => v.sku + v.index);
      const source = [data.storage, ...data.stores];
      source.forEach(s =>
        s.variants.sort(
          (a, b) =>
            previewSkusSortOrder.indexOf(a.sku + a.index) -
            previewSkusSortOrder.indexOf(b.sku + b.index)
        )
      );
    }

    return data;
  }

  getProductDecsription = (product, previewData) => {
    // given a specific sku from preview data, we first try to find if we have a general description
    // included for the required product, for example t-shirts, accent mugs, etc...

    // if no description was found we try to find a description for a specific product such as bella-3001

    // if all fails an empty string should be returned.
    let previewSku, subCategories;
    const description =
      (product.details && product.details.product && product.details.product.final_desc) || '';

    if (description) {
      return description;
    }

    if (!previewData.items.length) {
      return '';
    }

    try {
      previewSku = previewData.items[0].sku;
      subCategories = product.details.product.regions[0]['sub-categories'];

      const skuKeywords = previewSku.toLowerCase().split(/-/);

      let max = 0;
      let subCategory;

      for (let x = 0; x < subCategories.length; x++) {
        const intersection = subCategories[x].id
          .toLowerCase()
          .split(/[+_]/)
          .filter(element => skuKeywords.includes(element)).length;

        if (intersection > max) {
          max = intersection;
          subCategory = subCategories[x];
        }
      }

      return subCategory ? (subCategory.final_desc ? subCategory.final_desc : '') : '';
    } catch (error) {
      // this happens occasional, and throws errors in console. product description will be ''
      // if it can not be fetched, and that's OK
      //
      // Log.error(error, 'failed to find product description', { product, previewData });
      return '';
    }
  };

  createPublishData(
    skus,
    product,
    config,
    productOptions,
    selectedOptionIDs,
    oldData,
    previewData
  ) {
    const productDescription = this.getProductDecsription(product, previewData);

    return {
      storage: {
        ...fillData(
          product.name,
          productDescription,
          productOptions,
          skus,
          selectedOptionIDs,
          previewData
        ),
        selected: oldData ? oldData.storage.selected : false,
        enabled: oldData ? oldData.storage.enabled : false,
        // Holds all unique tag names
        tags: Array.from(
          config.stores.reduce(
            (tags, store) => new Set([...tags, ...store.tags.map(t => t.name)]),
            new Set()
          )
        ),
        // Storage does not support collections.
        collections: [],
        options: fillProductOptions(productOptions)
      },
      stores: config.stores
        ? config.stores.map(s => ({
            id: s.id,
            name: s.name,
            inactive: s.inactive,
            provider: s.provider,
            collections: s.collections,
            selected: oldData ? oldData.stores.find(st => st.id === s.id).selected : s.selected,
            enabled: oldData ? oldData.stores.find(st => st.id === s.id).enabled : s.selected,
            draft: s.draft,
            wasDraft: s.draft,
            updatePreview: true,
            ...fillData(
              product.name,
              productDescription,
              productOptions,
              skus,
              selectedOptionIDs,
              previewData
            )
          }))
        : [],
      validation: {
        errors: [],
        issues: {
          variantsSkus: skus.map(() => null)
        },
        failures: {
          variantsSkus: [],
          productNames: []
        },
        stores: config.stores
          ? config.stores.map(s => ({
              id: s.id,
              failures: {
                variantsSkus: [],
                productNames: []
              },
              issues: {
                variantsSkus: skus.map(() => null)
              }
            }))
          : []
      }
    };
  }

  adoptSkus(data) {
    var result = {
      ...data,
      stores: data.stores.map(s => {
        if (s.provider === 'tiktok') {
          // simplify SKUs

          const strs = s.variants.map(v => v.customSku);
          var leng = Math.max(...strs.map(s => s.length));
          // split skus to terms
          var terms = strs.map(s => s.split('-').reverse());
          var gIndex = 0;
          // remove dupicated terms
          while (leng > 50) {
            var words = terms.map(ts => ts[gIndex]);
            var word = words[0];
            if (words.every(w => w === word)) {
              terms = terms.map(tm => {
                tm.splice(gIndex, 1);
                return tm;
              });
              leng = leng - word.length - 1;
            } else {
              gIndex++;
            }
          }
          // combine back
          const res = terms.map(ts => ts.reverse().join('-'));
          return {
            ...s,
            variants: s.variants.map((v, index) => {
              return {
                ...v,
                customSku: res[index]
              };
            })
          };
        }

        return s;
      })
    };
    return result;
  }

  mapFromSkus(skus, products, oldData, previewData, config = {}) {
    let filteredProductOptions;
    products
      .map(p => {
        return { productName: p.details.product.name, options: [...p.details.product.options] };
      })
      .forEach(product => {
        filteredProductOptions = product.options
          .reduce((a, b) => a.concat(b), [])
          // we want to filter some options out of the product mapping when publishing: region, orientation, gender, brand, mode, decoration and sleeve
          // but we have expections for some product, at the moment we added exceptions based on product name
          // ('pajamas' - needs 'gender' option) ('phone' and 'body pillows' - needs 'model' option)
          .filter(
            o =>
              o.id !== REGIONS_OPTION_ID &&
              o.id !== ORIENTATION_OPTION_ID &&
              (product.productName?.toLowerCase().includes('pajamas') ||
                !o.title?.toLowerCase().includes('gender')) &&
              !o.title?.toLowerCase().includes('brand') &&
              (product.productName?.toLowerCase().includes('phone') ||
                product.productName?.toLowerCase().includes('body pillows') ||
                product.productName?.toLowerCase().includes('porcelain ornaments') ||
                !o.title?.toLowerCase().includes('model')) &&
              !o.title?.toLowerCase().includes('decoration') &&
              !o.title?.toLowerCase().includes('sleeve')
          );
      });

    // NOTE: product details data options can contains duplicated options
    // e.g. complex product like t-shirts - has multi groups - steps on sku SKUSelection
    // and some step has same options
    const productOptions = removeDuplicatesBy(opt => opt.id, filteredProductOptions);

    // create ids for selected options here, to avoid recreation on each iteration,
    // through all stores. number of selected option ids can be <= productOptions array length
    const selectedOptionIDs = Array.from(new Array(productOptions.length), () => getGuid());

    // edit product flow...
    if (Config.get('editMode')) {
      const originalOldData = Object.assign({}, oldData);

      // for existing (old) variants, we will verify publish data...
      this.verifyPublishData(oldData, skus, productOptions, selectedOptionIDs, false, previewData);

      // for newly added variants, we will create new publish data...
      const newData = this.createPublishData(
        skus,
        products[0],
        config,
        productOptions,
        selectedOptionIDs,
        originalOldData,
        previewData
      );

      const mergedData = this.mergeVariants(oldData, newData);

      return this.adoptSkus(mergedData);
    }

    // if nothing was changed, restore data...
    const canRestore =
      oldData &&
      oldData.storage.variants
        .map(p => p.sku)
        .sort()
        .join(',') ===
        skus
          .map(p => p.sku)
          .sort()
          .join(',');

    // create or link product flow...

    const publishData = canRestore
      ? this.verifyPublishData(
          oldData,
          skus,
          productOptions,
          selectedOptionIDs,
          Config.get('linkMode'),
          previewData
        )
      : this.createPublishData(
          skus,
          products[0],
          config,
          productOptions,
          selectedOptionIDs,
          oldData,
          previewData
        );

    return this.adoptSkus(publishData);
  }

  mergeVariants(data, newData) {
    // find mutual skus from old data and new data
    // we can use storage variants, because gooten skus are the same for all platforms...
    const oldSkus = data.storage.variants.map(v => v.sku);
    const newSkus = newData.storage.variants.map(v => v.sku);
    const mutualSkus = oldSkus.filter(s => newSkus.indexOf(s) !== -1);

    // merge new and old data in storage...
    data.storage.variants = data.storage.variants
      .filter(v => mutualSkus.indexOf(v.sku) !== -1)
      .concat(newData.storage.variants.filter(v => mutualSkus.indexOf(v.sku) === -1));

    // merge new and old data for each store...
    data.stores.forEach(store => {
      const newStoreData = newData.stores.find(s => s.id === store.id);
      if (newStoreData) {
        store.variants = store.variants
          .filter(v => mutualSkus.indexOf(v.sku) !== -1)
          .concat(newStoreData.variants.filter(v => mutualSkus.indexOf(v.sku) === -1));
      }
    });
    return data;
  }

  publish(request) {
    return new Promise((resolve, reject) => {
      postJson(getPublishUrl(), request, getAuthQueryParams())
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  validateResponse(response, config) {
    const errors = response.tasks.reduce(
      (res, t) => {
        // NOTE: task.error - if something unhandled happened
        // but task.result = false if handled error happened
        if (t.error || !t.result) {
          var storeName = null;
          if (t.name.indexOf('store') === 0 && t.options && t.options.store_id) {
            const store = config.get('stores')
              ? config.get('stores').find(s => s.get('id') === t.options.store_id)
              : null;
            storeName = store ? store.get('name') : 'store';
            res.stores.push(storeName);
          } else if (t.name.indexOf('storage') === 0) {
            res.storage = true;
          }
          if (t.error_message) {
            res.reasons.push({ store: storeName || 'storage', message: t.error_message });
          }
        }
        return res;
      },
      { storage: false, stores: [], reasons: [] }
    );
    const storesErrorsPart = !errors.stores.length
      ? null
      : errors.stores.length === 1
      ? `store: ${errors.stores[0]}`
      : `some stores: ${errors.stores.join(', ')}`;
    return [
      errors.storage
        ? storesErrorsPart
          ? `Something is preventing this product from connecting to storage and ${storesErrorsPart}`
          : `Something is preventing this product from connecting to storage`
        : storesErrorsPart
        ? `Something is preventing this product from connecting to ${storesErrorsPart}`
        : null,
      errors.reasons
    ];
  }

  deleteVariants(request) {
    return new Promise((resolve, reject) => {
      postJson(getDeleteVariantsUrl(), request, getAuthQueryParams())
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

// singleton
export default new PublishService();
