import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'STATUS_VIEW');

export const GET_ACCEPTABLE_STATUSES = getActionName('GET_ACCEPTABLE_STATUSES');
export const UPDATE_ACCEPTABLE_STATUSES = getActionName('UPDATE_ACCEPTABLE_STATUSES');

export function getAcceptableStatuses(orderId) {
  return {
    type: GET_ACCEPTABLE_STATUSES,
    payload: { orderId }
  };
}

export function updateAcceptableStatuses(data) {
  return {
    type: UPDATE_ACCEPTABLE_STATUSES,
    payload: { data }
  };
}
