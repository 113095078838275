import { connect } from 'react-redux';
import AddToCart from '../../../shared/AddToCart';
import { selectedOrderItemsSelector } from './AddToCartSelectors';
import {
  isLoadingSelector,
  isFailedSelector,
  isCartEmptySelector
} from '../../../shared/AddToCart/AddToCartSelectors';
import { addToCartRequestAsync, updateSource } from '../../../CartView/CartViewActions';
import { mapAddToCartRequestFromOrderItems } from '../../../../models/AddToCartRequest';
import analyticsService from 'gooten-components/src/services/analyticsService';

const source = 'COF - Past Orders Flow';

const mapDispatchToProps = (dispatch, ownProps) => ({
  addToCart: orderItems => {
    analyticsService.track(source, 'Add To Cart Clicked', 'COF');
    orderItems.forEach(oi => {
      const productAddObj = {
        product_sku: oi.sku,
        product_name: oi.productName,
        product_price: oi.price
      };
      // This is to maintain old COF events
      analyticsService._track('custom order', 'product added to order', null, null, productAddObj);
    });
    dispatch(addToCartRequestAsync(mapAddToCartRequestFromOrderItems(orderItems), source));
  },
  onProceedToCart: () => {
    dispatch(updateSource(source));
    analyticsService.track(source, 'Proceed To Cart Clicked', 'COF');
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    selectedItems: selectedOrderItemsSelector(state),
    loading: isLoadingSelector(state),
    failed: isFailedSelector(state),
    cartEmpty: isCartEmptySelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
