import { fromJS } from 'immutable';
import { UPDATE_CUSTOM_COLOR } from './BackgroundSelectActions';
import { SELECT_ITEMS, DESELECT_ALL_ITEMS, DESELECT_ITEM } from './../../../ProductPreviewActions';

const initialState = fromJS({
  presets: ['#FFFFFF', '#F1643A', '#DEDEDE', '#2C2229', '#F48766'],
  customColor: ''
});

export const backgroundReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOM_COLOR: {
      return state.set('customColor', action.payload);
    }

    case SELECT_ITEMS:
    case DESELECT_ALL_ITEMS:
    case DESELECT_ITEM:
      return state.set('customColor', '');

    default:
      return state;
  }
};
