import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Autocomplete from '../../../../shared/Autocomplete';
import ErrorMessage from '../ErrorMessage';
import Rules from '../../../ErrorsHandler/ValidationRules';

const ProductTags = props => {
  const [error, setError] = useState(null);

  // Error messages
  const errors = {
    maxNumberOfTags: `Max number of tags is ${props.maxNumberOfTags}.`,
    maxTagLength: `Max tag length is ${props.maxTagLength} chars.`,
    invalidCharacters: `Etsy does not allow special characters in tags`
  };

  useEffect(() => {
    let err = Rules.productTags.allowNewTags(props.selectedTags.size, props.maxNumberOfTags)
      ? Rules.productTags.tagLengthExceeded(props.selectedTags, props.maxTagLength)
        ? errors.maxTagLength
        : props.regex
        ? props.selectedTags.find(tag => Rules.productTags.isRegexValid(props.regex, tag))
          ? errors.invalidCharacters
          : null
        : null
      : errors.maxNumberOfTags;

    setError(err);
    props.setError(props.storeId, 'ProductTags', err);
  }, [props.selectedTags.toString()]);

  return (
    <div className="product-publish-tags pb-4 flex-container">
      <Autocomplete
        items={props.tags.toJS()}
        selectedItems={props.selectedTags.toJS()}
        maxSelected={20}
        onNew={t => props.newTag(props.storeId, t)}
        onAdd={t => props.addTag(props.storeId, t)}
        onRemove={t => props.removeTag(props.storeId, t)}
        label="Tags"
      />
      {error ? <ErrorMessage message={error} /> : null}
      <style jsx>
        {`
          :global(.product-publish-tags input.rbt-input-main) {
            height: 40px;
            border-color: ${error ? '#F1643A' : '#ccc'} !important;
          }
          .product-publish-tags {
            flex-direction: column;
          }
        `}
      </style>
    </div>
  );
};

ProductTags.propTypes = {
  tags: PropTypes.instanceOf(List).isRequired,
  selectedTags: PropTypes.instanceOf(List).isRequired,
  storeId: PropTypes.number,
  setError: PropTypes.func,
  addTag: PropTypes.func.isRequired,
  newTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  // validation below
  maxNumberOfTags: PropTypes.number,
  maxTagLength: PropTypes.number
};

export default ProductTags;
