import { connect } from 'react-redux';
import SidePanel from './SidePanel';
import {
  downloadPreviews,
  showRemoveModal,
  removeItems,
  updatePreviewSize
} from '../../ProductPreviewActions';
import {
  selectedItemsSelector,
  previewDataSelector,
  isExclusiveMockupSelector,
  previewConfigSelector,
  previewSizeSelector,
  disableControls
} from '../../ProductPreviewSelectors';

const mapDispatchToProps = dispatch => {
  return {
    downloadPreviews: () => dispatch(downloadPreviews()),
    showRemoveModal: value => dispatch(showRemoveModal(value)),
    removeItems: items => dispatch(removeItems(items)),
    updatePreviewSize: value => dispatch(updatePreviewSize(value))
  };
};

const mapStateToProps = state => {
  const shouldRemoveItemWOConfirmation =
    previewDataSelector(state).items.length > selectedItemsSelector(state).length;
  const selectedItems = selectedItemsSelector(state);
  const disabled = !selectedItems.length || disableControls(state);
  const disableDownload = disabled;

  return {
    previewItems: previewDataSelector(state).items,
    selectedItems,
    shouldRemoveItemWOConfirmation,
    isExclusiveMockup: isExclusiveMockupSelector(state),
    config: previewConfigSelector(state),
    previewSize: previewSizeSelector(state),
    disabled,
    disableDownload
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
