export class ToolbarOptions {
  // class to be added to toolBar container
  customClass: string;

  // false by default
  appendToBody: boolean;

  constructor(config: any) {
    // array of buttons,
    // will be rendered using order in array
    // example [{type: 'crop', customClass: 'my-crop-btn', name: 'My Crop'}, {type: 'orientation', customClass: 'my-orientation-btn', name: 'Custom label'}]
    this.buttons = [
      {
        type: 'alignHorizontal',
        name: 'Align Horizontal'
      },
      {
        type: 'alignVertical',
        name: 'Align Vertical'
      },
      {
        type: 'crop',
        name: 'Crop'
      },
      {
        type: 'center',
        name: 'Center'
      },
      {
        type: 'rotate',
        name: 'Rotate'
      },
      {
        type: 'orientation',
        name: 'Orientation'
      },
      {
        type: 'undo',
        name: 'Undo'
      },
      {
        type: 'redo',
        name: 'Redo'
      },
      {
        type: 'remove',
        name: 'Remove'
      }
    ];

    //init from config
    //or use defaults
    config = config || {};
    this.customClass = config.customClass || '';
    this.appendToBody = config.appendToBody || false;
    this.buttons = config.buttons || this.buttons;
  }
}
