import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer'
import ListItem from 'gooten-components/src/components/shared/ListItem';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';

const ChangeStatusComponent = ({ statuses, changeStatus, disabled }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <DropDownContainer
      className="ml-2 m-1"
      rightAlign
      title="Change Status"
      isOpen={dropdownOpen}
      disabled={disabled}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      {statuses.map(status => (
        <ListItem
          key={status.OrderItemId || status.Id}
          onSelect={() => changeStatus(status.OrderItemId || status.Id)}
          text={status.Name}
        />
      ))}
    </DropDownContainer>
  );
};

ChangeStatusComponent.propTypes = {
  statuses: PropTypes.array,
  changeStatus: PropTypes.func,
  disabled: PropTypes.bool
};

export default ChangeStatusComponent;
