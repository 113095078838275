import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'gooten-components/src/components/shared/Alert';
import Button from 'gooten-components/src/components/shared/Button';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Card from 'gooten-components/src/components/shared/Card';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import CollapsibleTextComponent from '../../OrdersView/components/CollapsibleTextComponent';
import moment from 'moment';
import Chip from 'gooten-components/src/components/shared/Chip';

const VendorsView = props => {
  useEffect(() => {
    props.getOrderFailedInfo(props.params.id);
    props.getOrderLogs(props.params.id);
  }, []);

  function renderVendor(data) {
    function getSeverity(severity) {
      switch (severity) {
        case 'Error':
          return 'red';
        case 'Warning':
          return 'yellow';
        case 'Info':
          return 'green';
        case 'Audit':
          return 'blue';
        case 'Verbose':
          return '';
      }
    }

    return (
      <Card type="fullwidth" collapsible title={data.VendorName}>
        <TableRow size="small" columns={'minmax(auto, auto) 75% minmax(auto,auto)'}>
          <Cell key={0} header>
            Type
          </Cell>
          <Cell key={1} header>
            Message
          </Cell>
          <Cell key={2} header>
            Timestamp
          </Cell>

          {data.Logs.map((item, index) => (
            <Fragment key={index}>
              <Cell key={0}>
                <Chip className={getSeverity(item.Severity)}>{item.Severity}</Chip>
              </Cell>
              <Cell key={1}>
                <CollapsibleTextComponent text={item.Description} />
              </Cell>
              <Cell key={2}>{moment(item.Timestamp).format('MM/DD/YYYY h:mm:ss A')}</Cell>
            </Fragment>
          ))}
        </TableRow>
      </Card>
    );
  }

  return (
    <>
      {props.loading && <LoadingSpinner />}

      {!props.loading && props.failedOrderInfo && (
        <Card type="fullwidth" title="Vendor Errors">
          <div className="vendors-tab">
            <div className="vendors-content mt-4">
              <div className="pt-3">
                {props.failedOrderInfo.ErrorMessage ? (
                  <Alert isOpen type="failure" className="m-0">
                    {props.failedOrderInfo.ErrorMessage}
                  </Alert>
                ) : (
                  'No Errors Found. All is good. :)'
                )}
              </div>
              <div className="pt-3">
                {props.failedOrderInfo.Trace
                  ? props.failedOrderInfo.Trace.map((trace, key) => (
                      <>
                        <div className="title-3 mt-4">Vendor Trace</div>
                        <pre>
                          <code key={key}>{JSON.stringify(trace)}</code>
                        </pre>
                      </>
                    ))
                  : null}
              </div>
              {props.failedOrderInfo.AttemptId && (
                <Button
                  onClick={() => props.reattemptOrder(props.failedOrderInfo.AttemptId)}
                  className="button-primary large mt-4"
                >
                  Resubmit to vendor
                </Button>
              )}
            </div>
          </div>
        </Card>
      )}

      <hr></hr>

      {!props.loading &&
        props.orderLogs &&
        props.orderLogs
          .filter(l => l.Logs.length > 0)
          .map((item, index) => (
            <div key={index} className="mb-2">
              {renderVendor(item)}
            </div>
          ))}
    </>
  );
};

VendorsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  getOrderFailedInfo: PropTypes.func.isRequired,
  failedOrderInfo: PropTypes.object,
  reattemptOrder: PropTypes.func
};

export default VendorsView;
