import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './PrintSpaceItem.scss';

const Container = styled.div`
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 12px;

  .number-of-images {
    margin: -34px 0 18px 1px;
    font-size: 12px;
    font-weight: 900;
    height: 16px;
    color: white;
    padding: 0px 10px 4px 10px;
    border-radius: 8px;
  }

  .image-placeholder {
    height: 104px;
    width: 104px;
    align-items: center;
    overflow: hidden;
    background-size: ${props => (props.imageLoaded && props.imageUrl ? '0px' : '40px')};
    background-color: ${props => (props.selected ? '#c9e7e4' : '#f8f8f8')};
    border: ${props => (props.selected ? '2px solid #62c3b1' : '1px solid #dedede')};

    img.space-image {
      width: 100%;
    }
  }

  .space-title {
    font-weight: 900;
    font-size: 13px;
    margin-top: 5px;
  }
`;

const PrintSpaceItem = props => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Container
      className={`image-selection-print-space-item flex-container ${props.customClass || ''}`}
      imageLoaded={imageLoaded}
      imageUrl={props.imageUrl}
      selected={props.selected}
    >
      <div
        className={`image-placeholder flex-container ${props.selected ? 'selected' : ''}`}
        onClick={props.handleSelect}
      >
        <img className="space-image" src={props.imageUrl} onLoad={() => setImageLoaded(true)} />
      </div>
      {props.numberOfImages > 1 && !props.imageUrl ? (
        <span className="number-of-images">{props.numberOfImages}</span>
      ) : null}
      <span className="space-title">{props.title.toUpperCase()}</span>
    </Container>
  );
};

PrintSpaceItem.propTypes = {
  title: PropTypes.string,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool,
  imageUrl: PropTypes.string,
  customClass: PropTypes.string,
  numberOfImages: PropTypes.number
};

export default PrintSpaceItem;
