import styled from 'styled-components';

export const Container = styled.div`
  height: 238px;

  @media (max-width: 991px) and (min-width: 576px) {
    height: 288px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }

  margin-top: 30px;

  .options-modal {
    position: absolute;
    right: 21px;
    bottom: -82px;
  }

  .image-item-holder {
    height: 100%;

    .image-placeholder {
      width: 100%;
      height: 85%;
      align-items: center;
      overflow: hidden;

      @media (max-width: 991px) and (min-width: 576px) {
        height: 88%;
      }

      @media (max-width: 480px) {
        height: 83%;
      }

      .spinner-container {
        top: 80px;
        display: ${props => (props.imageLoaded ? 'none' : 'initial')};
      }

      .item-image {
        height: 100%;
        width: 100%;
        object-fit: contain;
        width: 100%;
        opacity: ${props => (props.imageLoaded ? 1 : 0)};
        transition: opacity 0.3s;
      }
    }

    .image-data {
      padding: 3px 5px 5px 5px;
      align-items: center;
      justify-content: space-between;

      .options-button {
        font-size: 18px;
        margin-right: 3px;
        cursor: pointer;
      }

      .image-res {
        padding-top: 2px;
        font-size: 12px;
        font-weight: 900;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .image-type {
        display: inline-block;
        float: left;
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        padding: 1px 7px 0 7px;
        border-radius: 17px;
        text-align: center;
        width: 40px;
        height: 20px;
        margin-right: 5px;
      }
    }

    .image-title {
      font-size: 13px;
      padding: 0 5px 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover .button-secondary.image-item-select {
    opacity: 1;
  }

  .button-secondary.image-item-select {
    position: absolute;
    top: 58%;
    left: 15%;
    width: 70%;
    opacity: 0;
    transition: opacity 0.35s ease;
  }
`;
