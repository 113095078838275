import React, { useState } from 'react';
import PropTypes from 'prop-types';
import storageService from '../../../../../services/storageService';
import Modal from '../../../../shared/Modal';
import Checkbox from '../../../../shared/Checkbox';
import { COLORS } from '../../../../../constants';
import { Map } from 'immutable';

const EmbroideryDisclaimer = ({
  showDisclaimer,
  disclaimerVisible,
  disclaimerPayload,
  handleAccept
}) => {
  const [checked, setChecked] = useState(false);

  const setModalVisibility = () => {
    if (checked) {
      storageService.setItem('hide_emb_disclaimer_modal', true);
    }
  };

  const handleCloseAction = () => {
    setModalVisibility();
    showDisclaimer(false);
    setChecked(false);
  };

  const onCancel = () => {
    handleCloseAction();
  };

  const onAccept = () => {
    handleCloseAction();
    handleAccept(disclaimerPayload?.toJS());
  };

  return (
    <>
      <Modal
        isOpen={disclaimerVisible}
        title="Important Embroidery Steps"
        className="medium"
        cancelClick={() => onCancel()}
        primaryClick={() => onAccept()}
        actionText="Accept"
        disableOutsideClose
        hideCancelButton
      >
        <div className="text-holder d-flex align-items-start m-4 flex-column">
          <span>
            We recommend designing and formatting your artwork files according to our guidelines to
            ensure that your product is properly and quickly embroidered. Please note that if your
            design does not meet our requirements, we can’t guarantee the quality of the end
            product. We recommend you to:
          </span>
          <br />
          <ul>
            <li>
              Prepare your artwork using our{' '}
              <a
                className="emb-guide-link"
                target="_blank"
                href="https://help.gooten.com/hc/en-us/articles/360047284192-Creating-Artwork-for-Embroidery"
              >
                embroidery guide.
              </a>
            </li>
            <li>Order a sample of your embroidery product to see if it meets your expectations.</li>
          </ul>
        </div>
        <Checkbox
          className="pr-4 pb-4 pl-4 pt-3"
          noBorder
          checked={checked}
          onChange={() => setChecked(!checked)}
          label="I understand, don’t show this message again"
        />
      </Modal>
      <style jsx>{`
        .text-holder {
          padding: 12px 16px;
          border-radius: 6px;
          background: ${COLORS.bananaPeel200};
        }
      `}</style>
    </>
  );
};

EmbroideryDisclaimer.propTypes = {
  showDisclaimer: PropTypes.func.isRequired,
  disclaimerVisible: PropTypes.bool,
  handleAccept: PropTypes.func.isRequired,
  disclaimerPayload: PropTypes.instanceOf(Map)
};

export default EmbroideryDisclaimer;
