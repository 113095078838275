import { connect } from 'react-redux';
import InfoLine from './InfoLine';
import { skipMapping } from '../../SKUsSelectionViewActions';
import {
  variantsMappingsSelector,
  selectedOptionsLabelSelector,
  canGoNextSelector
} from '../../SKUsSelectionViewSelectors';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/
const mapDispatchToProps = {
  skip: () => skipMapping()
};

const mapStateToProps = state => {
  return {
    variants: variantsMappingsSelector(state),
    selectedOptionsLabel: selectedOptionsLabelSelector(state),
    canGoNext: canGoNextSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoLine);
