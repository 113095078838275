import React, { useState } from 'react';
import Loader from '../../../../../gooten-components/src/components/shared/Loader';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { object, string, ref, bool } from 'yup';
import Input from 'gooten-components/src/components/shared/Input';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Button from 'gooten-components/src/components/shared/Button';
import './index.scss';
import Icon from 'gooten-components/src/components/shared/Icon';

const UserDetails = props => {
  const [previousEmail, setPreviousEmail] = useState(props.userDetailValues.email);
  const [emailError, setEmailError] = useState('');

  const validateEmail = value => {
    return new Promise(resolve => {
      if (previousEmail === value) {
        resolve(emailError);
      } else {
        setPreviousEmail(value);
        fetch(`/admin/account/checkIfEmailTaken?email=${encodeURIComponent(value)}`).then(
          response => {
            response.json().then(resp => {
              if (resp.taken) {
                setEmailError('Email is not unique.');
                resolve('Email is not unique.');
              } else {
                setEmailError('');
                resolve('');
              }
            });
          }
        );
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{
          email: props.userDetailValues.email,
          password: '',
          validatePassword: '',
          agreeToTerms: false
        }}
        validationSchema={() =>
          object().shape({
            email: string()
              .required('Please provide a valid Email address.')
              .email('Please provide a valid Email address.'),
            password: string()
              .required('Please provide a Password.')
              .min(8, 'Password should contain 8 characters minimum.'),
            validatePassword: string()
              .required("Passwords don't match.")
              .oneOf([ref('password')], "Passwords don't match"),
            agreeToTerms: bool().oneOf(
              [true],
              "Please agree to Gooten's Privacy Policy and Terms of Service to continue."
            )
          })
        }
        onSubmit={async (data, { setSubmitting, setFieldError }) => {
          if (!data.agreeToTerms) {
            setFieldError(
              'agreeToTerms',
              "Please agree to Gooten's Privacy Policy and Terms of Service to continue."
            );
            return;
          }

          await fetch(`/admin/account/checkIfEmailTaken?email=${encodeURIComponent(data.email)}`)
            .then(response => {
              response.json().then(resp => {
                if (resp.taken) {
                  setFieldError('email', 'Email is not unique.');
                } else {
                  props.onSubmit(data);
                }
              });
            })
            .catch(err => console.error(err));
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          submitCount
        }) => (
          <div className="sign-up-card">
            {(isSubmitting || props.isSubmitting) && <Loader />}
            <div className="main-header">Sign Up - Enter Password / Email</div>
            <div className="main-subheader">No Credit Card Required. Takes 30 seconds!</div>
            <div className="card-section">
              <div className="card-header">YOUR DETAILS</div>
            </div>
            <div className="ui input fluid">
              <Field name="email" validate={validateEmail}>
                {({ field, form }) => (
                  <Input
                    className="signup-email-field"
                    name="email"
                    type="email"
                    label="Email"
                    icon="mail"
                    iconPlacement="trailing"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={true}
                    hasError={
                      (touched.email && !!errors.email) || errors.email === 'Email is not unique.'
                    }
                    errorText={
                      (touched.email && !!errors.email) || errors.email === 'Email is not unique.'
                        ? errors.email
                        : ''
                    }
                  />
                )}
              </Field>
            </div>
            <div className="ui input fluid">
              <Field name="password">
                {({ field, form }) => (
                  <Input
                    className="signup-password-field"
                    name="password"
                    type="password"
                    label="Password"
                    icon="lock"
                    iconPlacement="trailing"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    disabled={isSubmitting || props.isSubmitting}
                    hasError={touched.password && !!errors.password}
                    errorText={
                      touched.password && !!errors.password
                        ? errors.password
                          ? errors.password
                          : '8 character minimum'
                        : ''
                    }
                  />
                )}
              </Field>
            </div>
            {values.password.length > 0 && (
              <div className="ui input fluid">
                <Field name="validatePassword">
                  {({ field, form }) => (
                    <Input
                      className="signup-password-field"
                      name="validatePassword"
                      type="password"
                      label="Confirm Password"
                      icon="lock"
                      iconPlacement="trailing"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.validatePassword}
                      disabled={isSubmitting || props.isSubmitting}
                      hasError={touched.validatePassword && !!errors.validatePassword}
                      errorText={
                        touched.validatePassword && !!errors.validatePassword
                          ? errors.validatePassword
                          : ''
                      }
                    />
                  )}
                </Field>
              </div>
            )}

            <div
              className={`sign-up-field button-spacing ${
                submitCount > 0 && !!errors.agreeToTerms ? 'field-error' : ''
              }`}
            >
              <div className="d-flex">
                <Checkbox
                  className="signup-store-details-agreement mr-1"
                  checked={values.agreeToTerms}
                  onChange={() => setFieldValue('agreeToTerms', !values.agreeToTerms)}
                  disabled={isSubmitting || props.isSubmitting}
                />
                <p className="body-text-2 mb-1 ml-2">
                  I agree to Gooten's{' '}
                  <a
                    className="privacy-link"
                    href="https://www.gooten.com/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    className="terms-of-service-link"
                    href="https://www.gooten.com/terms"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                </p>
              </div>
              {submitCount > 0 && !!errors.agreeToTerms && (
                <div className="error-text d-flex caption-text mt-n1">
                  <Icon className="mr-1" icon="alertCircle" small />
                  {errors.agreeToTerms}
                </div>
              )}
              <style jsx>
                {`
                  .error-text {
                    fill: #c80404;
                    color: #c80404;
                  }
                `}
              </style>
            </div>

            <div className="pull-right">
              <Button
                className="button-secondary extra-large signup-user-details-continue"
                onClick={() => (isSubmitting || props.isSubmitting ? null : handleSubmit())}
                loading={isSubmitting || props.isSubmitting}
                iconRight="chevronRight"
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

UserDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userDetailValues: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default UserDetails;
