import { connect } from 'react-redux';
import SKUsList from './SKUsList';
import { selectSKU, skipMapping, resetNotification } from '../../../SKUsSelectionViewActions';
import {
  pageSelector,
  optionsSelector,
  skuNotificationSelector,
  pageVariantsSelector,
  pagesSelector,
  disableFilteringSelector,
  suggestedMatchesSelector,
  allVariantsSelector
} from './SKUsListSelectors';
import { setPage, setOptionSelectedValues } from './SKUsListActions';
import {
  mappingsProgressSelector,
  selectedOptionsLabelSelector
} from '../../../SKUsSelectionViewSelectors';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    componentsetSelectOptions
*/
const mapDispatchToProps = {
  select: (sku, value) => selectSKU(sku, value),
  next: () => skipMapping(),
  changePage: page => setPage(page),
  setOptionSelectedValues: (optionId, values, isSuggested) =>
    setOptionSelectedValues(optionId, values, isSuggested),
  resetNotification: () => resetNotification()
};

const mapStateToProps = state => {
  return {
    suggestedVariants: suggestedMatchesSelector(state),
    allVariants: allVariantsSelector(state),
    pageVariants: pageVariantsSelector(state),
    options: optionsSelector(state),
    page: pageSelector(state),
    pages: pagesSelector(state),
    mappingProgress: mappingsProgressSelector(state),
    skuNotification: skuNotificationSelector(state),
    disableFiltering: disableFilteringSelector(state),
    selectedOptionsLabel: selectedOptionsLabelSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SKUsList);
