import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const TEST_CONNECTION = customActionFor('EMAILS').async('TEST_CONNECTION');
export const UPDATE_EMAILS_DATA = customActionFor('EMAILS')('UPDATE_EMAILS_DATA');

// ------------------------------------
// Actions
// ------------------------------------

export function testConnection(payload) {
  return {
    type: TEST_CONNECTION.ASYNC,
    payload
  };
}

export function updateEmailsData(mailMethod, ordersEmail, shippingEmail) {
  return {
    type: UPDATE_EMAILS_DATA,
    payload: { mailMethod, ordersEmail, shippingEmail }
  };
}
