import { takeLatest, select, put, call, all } from 'redux-saga/effects';
import { PLACE_ORDER } from './CheckoutActions';
import { fetchAsyncSuccess, fetchAsyncFail } from '../../store/actions/dataActions';
import { showLoading, hideLoading } from '../../store/actions/globalActions';
import { placeOrderRequestSelector, selectedShippingAddressSelector } from './CheckoutSelectors';
import { shippingSelector } from '../shared/OrderSummary/OrderSummarySelectors';
import ordersService from './services/ordersService';
import Log from '../../services/logService';
import analyticsService from '../../services/analyticsService';
import Config from '../../config';

export function* placeOrder(action) {
  yield put(showLoading());
  const placeOrderRequest = yield select(placeOrderRequestSelector);
  let placeOrderResult;
  let orderId;

  // Place Order
  try {
    placeOrderResult = yield call(ordersService.placeOrder, placeOrderRequest);
    analyticsService._track('order submission', 'order submission success', 'admin');
    orderId = placeOrderResult.Id;
    const shippingTotal = yield select(shippingSelector);
    const totalCartItemsQty = placeOrderRequest.Items.reduce(
      (totalQuantity, item) => totalQuantity + item.Quantity,
      0
    );
    analyticsService._track('custom order', 'order placed', null, null, {
      total: placeOrderRequest.Payment.Total,
      shipping: shippingTotal,
      items_in_order: totalCartItemsQty
    });

    const selectedShippingAddress = yield select(selectedShippingAddressSelector);

    // Retrieve GootenID (order number)
    let orderNumberResponse;
    try {
      orderNumberResponse = yield call(ordersService.getOrderNumber, { id: orderId });
      analyticsService._track('get order number', 'get order number success', 'admin');

      const orderNumber = orderNumberResponse.GootenId;

      yield put(
        fetchAsyncSuccess(PLACE_ORDER, {
          ...placeOrderResult,
          orderNumber,
          selectedShippingAddress
        })
      );
    } catch (error) {
      const partnerId = Config.get('partnerId');
      const recipeId = Config.get('recipeId');
      const adminUserName = Config.get('adminUserName');
      const errorDetails = {
        partnerId,
        recipeId,
        adminUserName,
        orderId,
        error
      };
      analyticsService._track(
        'get order number',
        'get order number failure',
        'admin',
        null,
        errorDetails
      );
      Log.error(error, 'Failed to get order number', { action, orderNumberResponse });
      yield put(
        fetchAsyncSuccess(PLACE_ORDER, {
          ...placeOrderResult,
          orderNumber: orderId,
          selectedShippingAddress,
          hadErrorFetchingOrderNumber: true
        })
      );
    }
  } catch (error) {
    const partnerId = Config.get('partnerId');
    const recipeId = Config.get('recipeId');
    const adminUserName = Config.get('adminUserName');
    const errorDetails = {
      partnerId,
      recipeId,
      adminUserName,
      placeOrderRequest,
      error
    };
    analyticsService._track(
      'order submission',
      'order submission failure',
      'admin',
      null,
      errorDetails
    );
    Log.error(error, 'Failed to place order', { action, placeOrderResult });
    yield put(fetchAsyncFail(PLACE_ORDER, error));
  }

  yield put(hideLoading());
}

export function* watchForPlaceOrderCommand() {
  yield takeLatest(PLACE_ORDER.ASYNC, placeOrder);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchForPlaceOrderCommand()]);
}
