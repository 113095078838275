export const getPossibleVendors = (ruleString, countryCode) => {
  if (!ruleString) {
    throw new Error(`ruleString argument missing`);
  }
  if (!countryCode) {
    throw new Error(`countryCode argument missing`);
  }

  const countryCodeUpperCase = countryCode.toUpperCase();

  const res = [];

  var rules = ruleString.split('|');
  rules.forEach(rule => {
    if (!rule) {
      return;
    }

    var preds = rule.split(';').filter(a => !!a);
    var vendorId = parseInt(preds[0]);
    var countries = preds[1].split(',').filter(a => !!a);

    if (countries.includes('*')) {
      if (!countries.includes('!' + countryCodeUpperCase)) {
        res.push(vendorId);
      }
    } else {
      if (countries.includes(countryCodeUpperCase)) {
        res.push(vendorId);
      }
    }
  });

  return res;
};
