import { createSelector } from 'reselect';
import { publishDataSelector } from '../../ProductPublishSelectors';

export const isStorageOnlySelector = createSelector(
  [publishDataSelector],
  data =>
    data.getIn(['storage', 'enabled']) &&
    data.get('stores') &&
    data.get('stores').every(s => !s.get('enabled'))
);
