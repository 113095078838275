import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProfileInformationCard from '../Cards/ProfileInformation';
import PartnerTierCard from '../Cards/PartnerTier';
import PasswordCard from '../Cards/Password';
import AdvancedSettingsCard from '../Cards/AdvancedSettings';
import EmailPreferences from '../Cards/EmailPreferences';

const Container = styled.div`
  .profile-view {
  }
`;

class ProfileView extends React.Component {
  /* eslint-disable-next-line */
  render() {
    return (
      <Container>
        <PartnerTierCard cardView />
        <ProfileInformationCard />
        <EmailPreferences />
        <PasswordCard />
        <AdvancedSettingsCard />
      </Container>
    );
  }
}

ProfileView.propTypes = {
  // eslint-disable-next-line
  router: PropTypes.object.isRequired
};

export default ProfileView;
