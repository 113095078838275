import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import BaseCard from '../BaseCard';
import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';

class PendingInvitations extends React.Component {
  static get name() {
    return 'pendingInvitations';
  }
  static get messages() {
    return { onInitFailure: 'Failed to load Pending Invitations' };
  }

  renderItem(item, first) {
    return (
      <div key={item.get('Id')} className="col-xs-12 item-line-row">
        <div className="row">
          {first ? null : <hr />}
          <div className="user-remove-container">
            <div className="col-md-1 col-sm-2 col-xs-3 fixed-column">
              <div className="item-block">
                {this.props.ready ? (
                  <span className="user-remove" onClick={() => this.props.removeInvite(item)}>
                    <Icon icon="user" className="user-icon mt-1" />
                    <Icon icon="x" className="close-icon" />
                  </span>
                ) : (
                  <span className="user-remove">
                    <Icon icon="user" className="user-icon mt-1 icon-disabled" />
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-7 col-sm-6 col-xs-9">
              <div className="item-block ellipsis">{item.get('Email')}</div>
            </div>
          </div>
          <div className="col-sm-3 col-xs-8 col-sm-offset-1 col-xs-offset-3">
            <Button
              className="button-default large"
              disabled={!this.props.ready}
              onClick={() => this.props.resendInvite(item)}
            >
              Resend invite
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    this.inputs = {};
    return (
      <div>
        <BaseCard card={PendingInvitations.name} title="Pending Invitations">
          <div className="pending-invitations-card">
            <div className="row">
              {this.props.data.size > 0 ? (
                this.props.data.map((item, index) => this.renderItem(item, index === 0))
              ) : (
                <div className="col-xs-12">
                  <p>There is no pending invitations.</p>
                </div>
              )}
            </div>
          </div>
        </BaseCard>
        <style jsx>
          {`
            .user-icon {
              cursor: pointer;
            }
            .panel .panel-body {
              padding-bottom: 42px;
            }
          `}
        </style>
      </div>
    );
  }
}

PendingInvitations.propTypes = {
  data: PropTypes.instanceOf(List),
  resendInvite: PropTypes.func.isRequired,
  removeInvite: PropTypes.func.isRequired,
  ready: PropTypes.bool
};

export default PendingInvitations;
