import { connect } from 'react-redux';
import ProductPricingFullscreen from './ProductPricingFullscreen';
import { selectedVariantsSelector } from '../../../ProvidersTab/ProvidersTabSelectors';
import {
  selectStorePriceIndex,
  selectStorePrices,
  changeStorePrices
} from '../ProductPricingActions';

const mapDispatchToProps = {
  selectPriceIndex: (storeId, index, set) => selectStorePriceIndex({ storeId, index, set }),
  selectPrices: (storeId, option) => selectStorePrices({ option, storeId }),
  onChange: (storeId, item) => changeStorePrices({ storeId, item })
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isAnyPriceSelected: selectedVariantsSelector(state).some(v => !!v.get('priceSelected')),
    numberOfSelectedPrices: selectedVariantsSelector(state).filter(v => !!v.get('priceSelected'))
      .size
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPricingFullscreen);
