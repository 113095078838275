import { editActivate, editFinish, select } from '../../state/actions/actionCreators';
import { selectedImageSelector } from '../../state/selectors/imagesSelectors';
import EventEmitter from 'events';
import { noBubble } from '../../utils/eventUtils';
import { PUBLIC_EVENTS } from '../../ImageEditor.events';

export default class ToggleEditModeAction {
  publicEvents: EventEmitter;

  constructor(publicEvents: EventEmitter){
    this.publicEvents = publicEvents;
  }

  toggle(event, ctx, layerId: string) {
    // NOTE: Cancel bubble to skip it in stage content click
    // drag.action add listener to stage.contentClick event
    // if it's not canceled then it will toggle edit mode
    // when click outside of image
    noBubble(event.evt);

    const isEditMode = ctx.state.editor.mode === 'edit';
    if (isEditMode) {
      ctx.actionProcessorCb(editFinish());
      this.publicEvents.emit(PUBLIC_EVENTS.SELECT_IMAGE);
    }
    else {
      const image = selectedImageSelector(ctx.state, layerId);

      if (!image) {
        return;
      }

      ctx.actionProcessorCb(select(image.id, layerId));
      ctx.actionProcessorCb(editActivate());
      this.publicEvents.emit(PUBLIC_EVENTS.SELECT_IMAGE, image.id);
    }
  }
}
