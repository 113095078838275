import { connect } from 'react-redux';
import ProductImage from './ProductImage';
import { previewSelector, infoSelector } from './ProductImageSelectors';
import {
  productNameSelector,
  failedReasonSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import { areMockupsLoadingSelector, publishStateSelector } from '../../../ProductPublishSelectors';

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    preview: previewSelector(state),
    failedReason: failedReasonSelector(state),
    isLoading: areMockupsLoadingSelector(state),
    title: productNameSelector(state),
    info: infoSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImage);
