import { connect } from 'react-redux';
import ImageSelectionModal from './ImageSelectionModal';
import { modalClose, IMAGES_CHECK, showScreen } from './ImageSelectionModalActions';
import {
  isOpenSelector,
  hasImagesSelector,
  screenTypeSelector
} from './ImageSelectionModalSelectors';
import { fetchAsync } from '../../../store/actions/dataActions';
import { loadingImagesCountSelector } from './atoms/MyArtwork/MyArtworkSelectors';
import { isEmbroiderySelected } from '../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';
import { requiredImageSizeSelector } from '../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';

const mapDispatchToProps = {
  modalClose: () => modalClose(),
  checkImages: () => fetchAsync(IMAGES_CHECK),
  showScreen: type => showScreen(type)
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isOpen: isOpenSelector(state),
    hasImages: hasImagesSelector(state),
    loadingImagesCount: loadingImagesCountSelector(state),
    isEmbroidery: isEmbroiderySelected(state),
    screenType: screenTypeSelector(state),
    requiredImageSizeData: requiredImageSizeSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelectionModal);
