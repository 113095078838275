import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../../shared/Spinner';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';

const PreviewImagesItem = props => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={`publish-preview-image-item ${props.customClass || ''}`}>
      <div
        className="image-placeholder"
        onClick={() => (props.onSelect ? props.onSelect(props.imageUrl) : null)}
      >
        <span className="helper" />
        <img
          className="item-image"
          src={getSmallPreviewImageResizerUrl(props.imageUrl)}
          onLoad={() => setImageLoaded(true)}
        />
        <div className="spinner-container">
          <Spinner />
        </div>
      </div>
      <style jsx>
        {`
          .publish-preview-image-item {
            height: ${props.height ? props.height : '4.5rem'};
            margin-bottom: ${props.marginBot ? props.marginBot : '.5rem'};
            width: ${props.width ? props.width : '4.5rem'};
          }

          .image-placeholder {
            width: 100%;
            padding-bottom: ${props.paddingBottom ? props.paddingBottom : '100%'};
            height: ${props.height ? props.height : '0px'};
            overflow: hidden;
            position: relative;
            border-radius: 4px;
            background-color: ${props.backgroundColor ? props.backgroundColor : '#f2f3f7'};
            border: ${props.main ? '2px solid #62C3B1' : '1px solid #dedede'};
            box-sizing: initial;
          }
          .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }
          .spinner-container {
            display: ${imageLoaded ? 'none' : 'initial'};
          }
          .item-image {
            width: 100%;
            opacity: ${imageLoaded ? 1 : 0};
            transition: opacity 0.3s;
          }
        `}
      </style>
    </div>
  );
};

PreviewImagesItem.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  paddingBottom: PropTypes.string,
  marginBot: PropTypes.string,
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string,
  customClass: PropTypes.string,
  main: PropTypes.bool,
  onSelect: PropTypes.func
};

export default PreviewImagesItem;
