import React from 'react';
import PropTypes from 'prop-types';
import Toast from '../Toast';

class ToastModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { visible: false || props.avoidTimer, tId: -1 };
  }

  componentDidUpdate() {
    if (this.props.show && !this.props.avoidTimer) {
      this.props.onShowNotification();
      clearTimeout(this.state.tId);

      const tId = setTimeout(() => {
        this.setState({ visible: false });
      }, this.props.duration * 1000);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ visible: true, tId });
    }
  }

  handleClickOnButton() {
    if (this.props.clickOnButton) {
      this.props.clickOnButton();
    }
    this.handleCloseNotification();
  }

  handleCloseNotification() {
    this.setState({ visible: false });
  }

  render = () => {
    return (
      <>
        <Toast
          isOpen={this.state.visible}
          // type={this.props.type}
          href={this.props.url}
          textButtonClick={() => this.handleClickOnButton()}
          buttonText={this.props.butonTitle}
          closeClick={() => this.handleCloseNotification()}
        >
          {this.props.title}
        </Toast>
      </>
    );
  };
}

ToastModal.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  show: PropTypes.bool,
  duration: PropTypes.number,
  title: PropTypes.string.isRequired,
  butonTitle: PropTypes.string,
  onShowNotification: PropTypes.func,
  // fullScreen: PropTypes.bool,
  clickOnButton: PropTypes.func,
  // type: PropTypes.string,
  // zIndex: PropTypes.number,
  url: PropTypes.string,
  avoidTimer: PropTypes.bool
};

export default ToastModal;
