import { connect } from 'react-redux';

import { storesConnectStep, storesConnectProvider } from '../../StoresActions';

import { connectStepSelector } from '../../StoresSelectors';
import SelectPlatform from './SelectPlatform';

const mapStateToProps = state => ({
  connectStep: connectStepSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setConnectStep: step => dispatch(storesConnectStep(step)),
  setConnectProvider: provider => dispatch(storesConnectProvider(provider))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlatform);
