import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import OrderPanelLayout from '../../layouts/OrderPanelLayout';
import OrderSummary from '../shared/OrderSummary';
import ImageThumb from '../shared/ImageThumb';
import CartItems from './atoms/CartItems';
import CountrySelectorInline from '../shared/CountrySelectorInline';
import './Cart.scss';
import Strings from './strings';
import analyticsService from '../../services/analyticsService';
import Button from '../shared/Button';
import Icon from '../shared/Icon';
import Dialogue from '../shared/Dialogue';
import Modal from '../shared/Modal';

class Cart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isClearCartModalShown: false,
      isInlineCountrySelectorShown: false
    };
  }

  componentDidMount() {
    analyticsService.trackWithConfigSource('Cart Page Reached');
    if (this.props.shippingCountries.size === 0) {
      this.props.fetchShippingCountries();
    }
  }

  handleTapOnKeepShopping = () => {
    analyticsService.trackWithConfigSource('Add More Products');
    const defaultFn = () => this.props.goToStep(this.props.prodSelectionStep);
    if (this.props.config.onKeepShoppingClick) {
      if (this.props.config.onKeepShoppingClick() === true) {
        // Execute default handler only if config override return true
        defaultFn();
      }
    } else {
      // Default handler
      defaultFn();
    }
  };

  handleProceedToCheckout = () => {
    analyticsService.trackWithConfigSource('Proceed to Checkout');
    // This to maintain old cof events
    analyticsService._track('custom order', 'proceed to checkout', null, null, {
      items_in_order: this.getCartItemsTotalQty()
    });
    this.props.goNext();
  };

  handleShowCartItemPreview = item => {
    this.props.showCartItemPreview(item);
  };

  handleCloseCartItemPreview = () => {
    this.props.closeCartItemPreview();
  };

  hasProductsWithErrors = () => {
    return !!this.props.items.find(item => !!item.get('itemSpecificError'));
  };

  showClearCartModal = () => {
    this.setClearCartModalOpen(true);
  };

  hideClearCartModal = () => {
    this.setClearCartModalOpen(false);
  };

  setClearCartModalOpen = isOpen => {
    this.setState({
      isClearCartModalShown: isOpen
    });
  };

  toggleInlineCountrySelectorOpen = () => {
    this.setState({
      isInlineCountrySelectorShown: !this.state.isInlineCountrySelectorShown
    });
  };

  getCartItemsTotalQty = () => {
    return this.props.items.reduce(
      (totalQuantity, cartItem) => totalQuantity + cartItem.get('quantity'),
      0
    );
  };

  render() {
    const cart = (
      <div>
        <CartItems
          items={this.props.items}
          editCartItem={this.props.editCartItem}
          removeCartItem={this.props.removeCartItem}
          toggleItemAsSample={this.props.toggleItemAsSample}
          updateCartItemQuantity={this.props.updateCartItemQuantity}
          showCartItemPreview={this.handleShowCartItemPreview}
          downloadCartItemPreview={this.props.downloadCartItemPreview}
        />
        <div
          onClick={this.handleTapOnKeepShopping}
          className="btn-keep-shopping flex-inline-container"
        >
          <div className="cart-item-lookalike flex-inline-container flex-dead-centered">
            <Icon icon="plusCircle" />
          </div>
          <Button className="ml-4 button-default large add-more-products">
            {Strings.ADD_MORE_PRODUCTS_TO_CART}
          </Button>
        </div>
      </div>
    );

    const emptyCart = (
      <div className="empty-cart flex-container flex-center-aligned">
        <h3>You have an empty cart.</h3>
        <div className="background-image" />
        <Button className="button-primary large" onClick={this.handleTapOnKeepShopping}>
          {Strings.ADD_PRODUCTS_TO_CART}
        </Button>
      </div>
    );

    const itemOrItems = this.props.items.size === 1 ? Strings.ITEM : Strings.ITEMS;

    const totalQuantity = this.getCartItemsTotalQty();

    const cartItemsCount = `(${totalQuantity} ${itemOrItems})`;

    const cartButtons = (
      <div className="cart-title-buttons">
        <Button className="button-default m-1 medium clear-cart" onClick={this.showClearCartModal}>
          <Icon icon="xCircle" />
          <span> {Strings.CLEAR_CART}</span>
        </Button>
        <Button
          className="button-default m-1 medium update-shipping"
          onClick={this.toggleInlineCountrySelectorOpen}
        >
          <Icon icon="mapPin" />
          <span> {Strings.UPDATE_SHIPPING_COUNTRY}</span>
        </Button>
      </div>
    );

    const cartItemPreviewModal = (
      <Modal
        isOpen={this.props.isCartItemPreviewShown}
        className="medium"
        cancelText={Strings.CLOSE}
        title="Product Preview"
        cancelClick={this.handleCloseCartItemPreview}
      >
        <div className="w-75 m-auto p-3">
          <ImageThumb
            loading={this.props.previewImageUrl === undefined}
            imageUrl={this.props.previewImageUrl}
          />
        </div>
      </Modal>
    );

    const clearCartModal = (
      <Dialogue
        isOpen={this.state.isClearCartModalShown}
        cancelClick={this.hideClearCartModal}
        destructiveClick={() => {
          this.props.clearCart();
          this.hideClearCartModal();
        }}
        actionText={'Delete'}
        title={'Are you sure you want to clear your cart?'}
      >
        All items in your cart will be deleted. This action cannot be undone.
      </Dialogue>
    );

    return (
      <div className="cart-container">
        {cartItemPreviewModal}
        {clearCartModal}
        <OrderPanelLayout
          onNextButtonClick={this.handleProceedToCheckout}
          nextButtonLabel={Strings.PROCEED_TO_CHECKOUT}
          nextButtonHidden={this.props.items.isEmpty()}
          nextButtonDisabled={!this.props.canProceedToCheckout}
        >
          <div key="left-side-title">
            <CountrySelectorInline
              isOpen={this.hasProductsWithErrors() || this.state.isInlineCountrySelectorShown}
              cartHasErrors={this.hasProductsWithErrors()}
            />
            <div className="cart-items-header d-flex justify-content-between align-items-center flex-wrap pt-4 mt-2 pb-2">
              <div className="title-1 ml-2">{`${Strings.CART} ${
                this.props.items.isEmpty() ? '' : cartItemsCount
              }`}</div>
              {this.props.items.isEmpty() ? null : cartButtons}
            </div>
          </div>
          <div className="mt-5" key="left-side">
            {this.props.items.isEmpty() ? emptyCart : cart}
          </div>
          <OrderSummary key="right-side" />
        </OrderPanelLayout>
        <style jsx>
          {`
            @media screen and (min-width: 992px) {
              .cart-items-header {
                padding: 0 1.5rem;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

Cart.propTypes = {
  config: PropTypes.object.isRequired,
  goNext: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  prodSelectionStep: PropTypes.string,
  editCartItem: PropTypes.func,
  removeCartItem: PropTypes.func,
  toggleItemAsSample: PropTypes.func,
  clearCart: PropTypes.func,
  updateCartItemQuantity: PropTypes.func,
  isCartItemPreviewShown: PropTypes.bool,
  previewImageUrl: PropTypes.string,
  showCartItemPreview: PropTypes.func,
  closeCartItemPreview: PropTypes.func,
  downloadCartItemPreview: PropTypes.func,
  items: PropTypes.instanceOf(List).isRequired,
  canProceedToCheckout: PropTypes.bool.isRequired,
  shippingCountries: PropTypes.instanceOf(List),
  fetchShippingCountries: PropTypes.func
};

export default Cart;
