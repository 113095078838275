import React, { useState } from 'react';
import PropTypes from 'prop-types';

const maxLength = 36;

const ReasonItem = props => {
  const ellipsis = props.reason.message.length > maxLength;
  const [readMore, setReadMore] = useState(ellipsis);
  const message =
    readMore && ellipsis
      ? `${props.reason.message.substring(0, maxLength)}.. `
      : props.reason.message;

  return (
    <p className="reason-item-container">
      {props.reason.store} : {message}
      {readMore ? (
        <i className="read-more" onClick={() => setReadMore(false)}>
          read more
        </i>
      ) : null}
      <style jsx>
        {`
          .read-more {
            cursor: pointer;
          }
        `}
      </style>
    </p>
  );
};

ReasonItem.propTypes = {
  reason: PropTypes.object.isRequired
};

export default ReasonItem;
