import React from 'react';
import PropTypes from 'prop-types';
import BorderBlock from 'gooten-components/src/components/shared/BorderBlock';
import COF_TEXTS from '../../const/COF_TEXTS.json';
import analyticsService from 'gooten-components/src/services/analyticsService';
import Loader from 'gooten-components/src/components/shared/Loader';

import './OrderFromView.scss';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import PageBody from 'gooten-components/src/components/shared/PageBody';
import { CheckoutWithToast } from 'gooten-components/src/components/Checkout/CheckoutContainer';
import { COLORS } from 'gooten-components/src/constants';
import KnowledgeBaseInfo from 'gooten-components/src/components/shared/KnowledgeBaseInfo';
import Config from 'gooten-components/src/config';
import {
  IMAGE_TRANSPARENCY_KB_TEXT,
  IMAGE_TRANSPARENCY_KB_LINK,
  IMAGE_TRANSPARENCY_KB_LINK_FUJI,
  IMAGE_TRANSPARENCY_KB_LINK_SENSARIA
} from 'gooten-components/src/constants';

class OrderFromView extends React.PureComponent {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.checkSources();
    }
  }

  onSourceSelected = source => {
    analyticsService.track('COF - Order From', 'Order From Selected', 'COF', source);
  };

  getImageTransparencyKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return IMAGE_TRANSPARENCY_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return IMAGE_TRANSPARENCY_KB_LINK_FUJI;
      case 2:
        return IMAGE_TRANSPARENCY_KB_LINK_SENSARIA;
      default:
        return IMAGE_TRANSPARENCY_KB_LINK;
    }
  };

  render() {
    if (!this.props.loaded) {
      return (
        <div className="order-from-view-container">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="order-from-view-container">
          <CheckoutWithToast />
          <PageHeader title={COF_TEXTS.MAIN_TITLE} size="standard" />
          <PageBody className="flex-wrap order-form">
            <div className="order-form-grid">
              <BorderBlock
                linkTo="/custom-order-form/create-new/start"
                customClassName="d-flex align-items-start justify-content-start cof-create-new"
                onClick={() => this.onSourceSelected('New Product')}
              >
                <div className="border-block-img mr-4 pull-left">
                  <div className="img-responsive img-create-new" />
                </div>
                <div className="border-block-text">
                  <div className="title-2">{COF_TEXTS.CREATE_NEW_BOX_TITLE}</div>
                  <p className="body-text-2 gray">{COF_TEXTS.CREATE_NEW_BOX_DESC}</p>
                </div>
              </BorderBlock>

              {this.props.hasPastOrders && (
                <BorderBlock
                  linkTo="/custom-order-form/past-orders/start"
                  customClassName="d-flex align-items-start justify-content-start cof-past-orders"
                  onClick={() => this.onSourceSelected('Past Orders')}
                >
                  <div className="border-block-img mr-4 pull-left">
                    <div className="img-responsive img-past-orders" />
                  </div>
                  <div className="border-block-text">
                    <div className="title-2">{COF_TEXTS.PAST_ORDERS_BOX_TITLE}</div>
                    <p className="body-text-2 gray">{COF_TEXTS.PAST_ORDERS_BOX_DESC}</p>
                  </div>
                </BorderBlock>
              )}

              {this.props.hasHubProducts && (
                <BorderBlock
                  linkTo="/custom-order-form/hub-products/start"
                  customClassName="d-flex align-items-start justify-content-start cof-hub-products"
                  onClick={() => this.onSourceSelected('Hub Products')}
                >
                  <div className="border-block-img mr-4 pull-left">
                    <div className="img-responsive img-hub-products" />
                  </div>
                  <div className="border-block-text">
                    <div className="title-2">{COF_TEXTS.HUB_PRODUCTS_BOX_TITLE}</div>
                    <p className="body-text-2 gray">{COF_TEXTS.HUB_PRODUCTS_BOX_DESC}</p>
                  </div>
                </BorderBlock>
              )}
            </div>
            <KnowledgeBaseInfo
              kbArticles={[
                {
                  visibleText: IMAGE_TRANSPARENCY_KB_TEXT,
                  link: this.getImageTransparencyKbLink()
                }
              ]}
            />
          </PageBody>
          <style jsx>
            {`
              :global(.order-form) {
                min-height: calc(100vh - 120px);
              }
              .order-form-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
                row-gap: 1rem;
                column-gap: 1rem;
                margin-bottom: 3rem;
              }
              .border-block-img .img-responsive {
                height: 7rem;
                width: 7rem;
              }
              .gray {
                color: ${COLORS.neutralLight600};
              }
            `}
          </style>
        </div>
      );
    }
  }
}

OrderFromView.propTypes = {
  loaded: PropTypes.bool.isRequired,
  hasPastOrders: PropTypes.bool.isRequired,
  hasHubProducts: PropTypes.bool.isRequired,
  checkSources: PropTypes.func.isRequired
};

export default OrderFromView;
