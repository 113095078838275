import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Strings from './strings';
import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import { COLORS } from 'gooten-components/src/constants';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import HoverImage from 'gooten-components/src/components/shared/HoverImage';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';

// This component renders individual order items
export const PastOrdersListItem = props => {
  // NOTE: order item may miss image - in case if it was just submitted
  // ImageManip can take some time to generate it
  const disabled = !props.orderItem.thumbnailUrl || !props.orderItem.IsReOrderable;
  // const selected = props.isSelected ? 'selected' : ''

  const handleSelect = () => {
    // don't select items where images missing
    if (props.orderItem.thumbnailUrl && props.orderItem.IsReOrderable) {
      props.onOrderItemSelectionToggled(props.orderItem, !props.isSelected);
    }
  };

  const [showHoverImage, setShowHoverImage] = useState(false);

  const handleClickEdit = e => {
    e.stopPropagation();
    props.editDesign();
  };

  const editButton = props.orderItem.thumbnailUrl && (
    <Link to="/custom-order-form/edit-design/start" className="btn-link" onClick={handleClickEdit}>
      <div className="visible-md-inline-block visible-lg-inline-block">
        <Button className="button-default small">
          <Icon icon="edit2" small />
          <span className="text visible-md-inline-block visible-lg-inline-block">
            {Strings.EDIT_DESIGN}
          </span>
        </Button>
      </div>
      <div className="visible-sm visible-xs">
        <Button iconOnly className="button-default small">
          <Icon icon="edit2" small />
        </Button>
      </div>
    </Link>
  );

  let warningMessage = null;
  if (!props.isReOrderable) {
    let messageText = null;
    if (props.isOutOfStock) {
      messageText = Strings.OUT_OF_STOCK;
    } else {
      messageText = Strings.DISCONTINUED;
    }

    warningMessage = (
      <div className="flex-inline-container flex-center-aligned margin-offset">
        <Icon className="mr-1" icon="alertCircle" fill={COLORS.cayennePepper500} />
        <span className="text-warning">{messageText}</span>
      </div>
    );
  }

  return (
    // Note: Currently does not have ${disabled} and ${selected} states. May incorporate at a later time
    <>
      <TableRow
        size="medium"
        disabled={disabled}
        onClick={handleSelect}
        columns="2rem 6rem 20% minmax(10rem, 50%) 6.5rem 6.5rem"
      >
        <Cell className="checkbox-container p-0">
          <div className="ignore-clicks">
            <Checkbox
              checked={props.isSelected}
              disabled={!props.orderItem.thumbnailUrl || !props.orderItem.IsReOrderable}
            />
          </div>
        </Cell>
        <Cell className="pt-2 pb-2">
          <div
            onMouseOver={() => setShowHoverImage(true)}
            onMouseLeave={() => setShowHoverImage(false)}
            className="position-relative past-orders-thumbnail"
          >
            {showHoverImage && <HoverImage image={props.orderItem.thumbnailUrl} />}
            <Thumbnail className="medium" image={props.orderItem.thumbnailUrl} />
          </div>
        </Cell>
        <Cell>{props.orderItem.productName}</Cell>
        <Cell>
          <div className="item-title pt-1 pb-1" title={props.orderItem.productInfo}>
            {props.orderItem.productInfo}
          </div>
        </Cell>
        <Cell>
          <a
            className="link"
            href={`/admin#/orders-new/${props.orderItem.orderId}/summary`}
            target="_blank"
          >
            {props.orderItem.orderId}
          </a>
        </Cell>
        <Cell rightAlign>{warningMessage || editButton}</Cell>
      </TableRow>
      <style jsx>
        {`
          .past-orders-thumbnail {
            width: 4rem;
            height: 4rem;
          }
          .past-orders-thumbnail :global(.cerulean-thumbnail) {
            position: absolute;
            top: 0;
          }
          .ignore-clicks {
            pointer-events: none;
          }
          .item-title {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}
      </style>
    </>
  );
};

PastOrdersListItem.propTypes = {
  orderItem: PropTypes.object,
  isOutOfStock: PropTypes.bool,
  isReOrderable: PropTypes.bool,
  isSelected: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  onOrderItemSelectionToggled: PropTypes.func.isRequired,
  editDesign: PropTypes.func.isRequired
};

export default PastOrdersListItem;
