'use strict';
// @flow

import * as ACTIONS from '../actions/actionTypes';
import initialState from '../initialState';

export default (state = initialState.get('editor'), action) => {
  switch (action.type) {

  case ACTIONS.EDITOR_RESTORE: {
    return action.payload.editor
      ? state.merge(action.payload.editor)
      : state;
  }

  case ACTIONS.EDITOR_INIT: {
    return state.merge(action.payload);
  }

  case ACTIONS.EDITOR_RECONSTITUTE: {
    return state.merge(action.payload.editor);
  }

  case ACTIONS.TEMPLATE_LOAD: {
    return state.set('templateloaded', false);
  }

  case ACTIONS.TEMPLATE_LOADED: {
    return state.set('templateloaded', true);
  }

  case ACTIONS.IMAGE_ADD: {
    return state
      .set('imageloading', true)
      .set('imageloaded', false);
  }

  case ACTIONS.IMAGE_LOADED: {
    return state
      .set('imageloading', false)
      .set('imageloaded', true);
  }

  case ACTIONS.EDITOR_MODE_EDIT:
  // Swithc to edit mode on undo/redo
  case ACTIONS.IMAGE_UNDO:
  case ACTIONS.IMAGE_REDO: {
    return state
      .set('mode', 'edit');
  }

  case ACTIONS.EDITOR_MODE_PREVIEW: {
    return state
      .set('mode', 'preview');
  }

  case ACTIONS.PREVIEW_MODE_PRINT: {
    return state
      .set('preview', 'print');
  }

  case ACTIONS.PREVIEW_MODE_PRODUCT: {
    return state
      .set('preview', 'product');
  }

  case ACTIONS.EDITOR_ORIENTATION_LANDSCAPE: {
    return state
      .set('orientation', 'landscape');
  }

  case ACTIONS.EDITOR_ORIENTATION_PORTRAIT: {
    return state
      .set('orientation', 'portrait');
  }

  case ACTIONS.EDITOR_CROPPING_MODE_ON: {
    return state.set('cropActivated', true);
  }

  case ACTIONS.EDITOR_CROPPING_MODE_OFF: {
    return state.set('cropActivated', false);
  }
  case ACTIONS.EDITOR_CROPPING_SAVE_MODE_ON: {
    return state.set('cropSaving', true);
  }
  case ACTIONS.EDITOR_CROPPING_SAVE_MODE_OFF: {
    return state.set('cropSaving', false);
  }

  case ACTIONS.IMAGE_DELETE: {
    return state
      .set('mode', 'preview');
  }
  case ACTIONS.ENABLE_BULK_EDITING: {
    if (state.get('bulkEditAvailable')) {
      return state.set('bulkEdit', true);
    }
    else {
      return state;
    }
  }
  case ACTIONS.DISABLE_BULK_EDITING: {
    return state.set('bulkEdit', false);
  }
  case ACTIONS.ENABLE_BULK_EDITING_AVAILABILITY: {
    return state.set('bulkEditAvailable', true);
  }
  case ACTIONS.DISABLE_BULK_EDITING_AVAILABILITY: {
    return state.set('bulkEditAvailable', false).set('bulkEdit', false);
  }
  case ACTIONS.SET_CONTAINER_COLOR: {
    return state.set('containerColor', action.payload);
  }
  default:
    return state;
  }
};
