import { connect } from 'react-redux';
import Options from './Options';
import {
  allOptionsSelector,
  getRightPanel,
  getFilterSkusByGroup,
  getCascade,
  shouldInitFromRawSkus,
  getSelectedOptionsFromRawSkus,
  getMaxGroupId,
  shouldPreselectOptionsSelector
} from '../../../SKUSelectionSelectors';

import {
  selectOption,
  deselectOption,
  gotoNextGroup,
  selectBatchOptions,
  setCascadeOption,
  deselectBatchOptions,
  gotoGroup
} from '../../../SKUSelectionActions';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = {
  selectOption: (optionId, valueId) => selectOption(optionId, valueId),
  selectBatchOptions: opts => selectBatchOptions(opts),
  deselectOption: (filterId, optionId) => deselectOption(filterId, optionId),
  deselectBatchOptions: optionIds => deselectBatchOptions(optionIds),
  setCascadeOption: (optionId, valueId, topOptionsIds) =>
    setCascadeOption(optionId, valueId, topOptionsIds),
  gotoNextGroup: () => gotoNextGroup(),
  gotoGroup: groupId => gotoGroup(groupId)
};

const mapStateToProps = state => {
  return {
    allOptions: allOptionsSelector(state),
    options: getRightPanel(state),
    selectedRegions: state.get('SKUSelection').get('selectedRegions'),
    selectedOptions: state.get('SKUSelection').get('selectedOptions'),
    filteredSkusByGroup: getFilterSkusByGroup(state),
    cascade: getCascade(state),
    currentGroup: state.get('SKUSelection').get('groupId'),
    shouldInitFromRawSkus: shouldInitFromRawSkus(state),
    preselectedOptions: getSelectedOptionsFromRawSkus(state),
    maxGroupId: getMaxGroupId(state),
    shouldPreselectOptions: shouldPreselectOptionsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
