import { connect } from 'react-redux';
import SKUsList from './SKUsList';
import { cartSKUItemsSelector } from '../../PlaceOrderViewSelectors';

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    cartItems: cartSKUItemsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SKUsList);
