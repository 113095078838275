import { Record, List } from 'immutable';
import camelcaseKeysDeep from 'camelcase-keys-deep';

const EditDesignItemImage = new Record({
  spaceId: '',
  imageUrl: '',
  imageId: 0,
  il: null,
  prpIL: null
});

const EditDesignItem = new Record({
  productId: 0,
  sku: 0,
  prpSKU: '',
  orientation: '',
  neck_tag_id: 0,
  neck_tag_image_url: '',
  images: new List() // List of EditDesignItemImage
});

export default EditDesignItem;

export const mapEditDesignItemFromOrderItem = orderItem =>
  new EditDesignItem({
    productId: orderItem.productId,
    sku: orderItem.sku,
    neck_tag_id: orderItem?.get('meta')?.get('necktag_id'),
    neck_tag_image_url: orderItem?.get('meta')?.get('addon_necktag_image_url'),
    images: new List(
      orderItem.images.map(
        i =>
          new EditDesignItemImage({
            spaceId: i.spaceId,
            imageUrl: i.imageUrl,
            imageId: i.imageId
          })
      )
    )
  });

export const mapEditDesignItemFromProductVariant = productVariant => {
  const data = new EditDesignItem({
    productId: productVariant.gootenMapping.getIn(['items', 0, 'productId']),
    sku: productVariant.gootenMapping.getIn(['items', 0, 'sku']),
    prpSKU: productVariant.gootenMapping.get('sku') || '',
    orientation: productVariant.gootenMapping.get('orientation'),
    neck_tag_id: productVariant.gootenMapping.get('neck_tag_id'),
    neck_tag_image_url: productVariant.gootenMapping.get('neck_tag_image_url'),
    images: new List(
      productVariant.gootenMapping.getIn(['items', 0, 'preconfigurations']).map(
        i =>
          new EditDesignItemImage({
            spaceId: i.spaceId,
            imageUrl: i.url,
            imageId: i.imageId,
            il: i.il,
            // used in COF for comparsion to check whether prp image was edited
            prpIL: i.il
          })
      )
    )
  });
  return data;
};

export const mapEditDesignItemFromProductVariantUpdated = gootenMapping => {
  const data = new EditDesignItem({
    productId: gootenMapping.items[0].product_id,
    sku: gootenMapping.items[0].product_variant_sku,
    prpSKU: gootenMapping.sku || '',
    orientation: gootenMapping.orientation,
    neck_tag_id: gootenMapping.neck_tag_id,
    neck_tag_image_url: gootenMapping.neck_tag_image_url,
    images: new List(
      gootenMapping.items[0].preconfigurations.map(
        i =>
          new EditDesignItemImage({
            spaceId: i.space_id,
            imageUrl: i.url,
            imageId: i.image_id,
            il: camelcaseKeysDeep(i.il),
            // used in COF for comparsion to check whether prp image was edited
            prpIL: camelcaseKeysDeep(i.il)
          })
      )
    )
  });

  return data;
};
