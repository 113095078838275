import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import PackingSlipPreviewComponent from './PackingSlipPreviewComponent';
import Input from 'gooten-components/src/components/shared/Input';
import { COLORS } from 'gooten-components/src/constants';
import { object, string } from 'yup';
import Button from 'gooten-components/src/components/shared/Button';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import Icon from 'gooten-components/src/components/shared/Icon';
import Loader from 'gooten-components/src/components/shared/Loader';
import Modal from 'gooten-components/src/components/shared/Modal';
import imageUploadService from 'gooten-components/src/components/shared/ImageSelectionModal/services/imageSelectionService';
import { postRecipeAddOns, getRecipeAddOns } from './PackingSlipService';
import { setToast } from './PackingSlipsActions';
import { connect } from 'react-redux';

const PackingSlips = props => {
  let formikRef = useRef();

  const [initialState, setInitialState] = useState({
    storeName: '',
    logoUrl: '',
    message: '',
    storeUrl: '',
    helpContact: ''
  });

  const [shopLogo, setShopLogo] = useState(); // This is only used for image uploading
  const [logoPreview, setLogoPreview] = useState(null); // This is only used when an image is updated
  const [imageDimensions, setImageDimensions] = useState(null); // This is only used when an image is updated

  const [imageError, setImageError] = useState(null);

  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [addOnId, setAddOnId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const addOns = await getRecipeAddOns();

      if (addOns.length && addOns[0].Id) {
        // Set the id
        setAddOnId(addOns[0].Id);
        setDisabled(addOns[0].IsDisabled);
        setInitialState(JSON.parse(addOns[0].SettingsJSON));
      }

      setLoading(false);
    } catch (error) {
      props.setToast('There was a problem fetching the packing slip data');
      setLoading(false);
    }
  };

  const replaceImage = () => {
    document.getElementById('file-input-store-image').click();
  };

  const imageDimensionValidation = (height, width) => {
    // TODO Set rules for image dimensions
    setImageError();
    if (height < 64 || width < 64) {
      props.setToast('Image must be at least 64x64 pixels');
      return false;
    }
    return true;
  };

  const submitImageAndForm = async () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  const toggleEnableDisable = () => {
    setDisabled(!disabled);
  };

  const navigateBack = () => {
    window.history.back();
  };

  const imageUpload = e => {
    setImageDimensions(null);
    if (e.currentTarget.files[0]) {
      var filereader = new FileReader();
      filereader.onload = e => {
        setLogoPreview(e.target.result);
        var image = new Image();
        image.src = filereader.result;
        image.onload = () => {
          setImageDimensions({ height: image.height, width: image.width });
          imageDimensionValidation(image.height, image.width);
        };
      };
      filereader.readAsDataURL(e.currentTarget.files[0]);
      setShopLogo(e.currentTarget.files[0]);
    }
  };

  const packingSlipsForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          storeName: initialState.storeName,
          message: initialState.message,
          storeUrl: initialState.storeUrl,
          helpContact: initialState.helpContact
        }}
        validationSchema={() =>
          object().shape({
            storeName: string()
              .max(300, 'Store name be less than 300 characters')
              .required('Please enter a valid storename.'),
            message: string().max(300, 'Message must be less than 300 characters'),
            storeUrl: string().max(300, 'Store URL must be less than 300 characters'),
            helpContact: string().max(300, 'Help contact must be less than 300 characters')
          })
        }
        innerRef={formikRef}
        onSubmit={async (values, actions) => {
          try {
            var data = {
              IsDisabled: disabled,
              Name: 'Packing Slip',
              Type: 1,
              SettingsJSON: { ...values }
            };

            if (addOnId > 0) {
              data.Id = addOnId;
            }

            setLoading(true);
            if (shopLogo) {
              if (!imageDimensionValidation(imageDimensions.height, imageDimensions.width)) {
                setLoading(false);
                return;
              }
              const s3Config = await imageUploadService.getS3Config();
              const imageUpload = await imageUploadService.uploadImageFile(shopLogo, s3Config);
              if (imageUpload.url) {
                data.SettingsJSON.logoUrl = imageUpload.url;
              } else {
                props.setToast('There was an issue uploading your image');
              }
              setShopLogo();
            } else {
              data.SettingsJSON.logoUrl = initialState.logoUrl;
            }

            data.SettingsJSON = JSON.stringify(data.SettingsJSON);
            await postRecipeAddOns(data);
            setLoading(false);
            props.setToast('Packing slip successfully updated');
          } catch (error) {
            setLoading(false);
            props.setToast('There was an issue submitting your changes');
          }
        }}
      >
        {({ values, touched, errors, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Input
              disabled={loading}
              name="storeName"
              type="text"
              className="login-email-field"
              placeholder="Store Name*"
              value={values.storeName}
              onChange={handleChange}
              hasError={touched.storeName && !!errors.storeName}
              errorText={touched.storeName && !!errors.storeName ? errors.storeName : ''}
            />

            <div className="mb-4">
              <div className="logo-header">Logo</div>
              <div className="image-upload-container">
                <div className="image-upload-left">
                  <Thumbnail className="large" image={logoPreview || initialState.logoUrl} />
                </div>

                <div className="image-upload-right">
                  <label className="custom-file-upload">
                    <input
                      disabled={loading}
                      name="storeimage"
                      onChange={imageUpload}
                      id="file-input-store-image"
                      type="file"
                      accept=".png,.jpg"
                    />
                  </label>
                  <Button onClick={() => replaceImage()} className={'button-default large'}>
                    {initialState.logoUrl ? 'Replace Image' : 'Upload Image'}
                  </Button>
                  <div className="sub-text">64px x 64px min dimensions</div>
                </div>
              </div>
              {imageError && (
                <div className="image-error-field mt-3">
                  <Icon icon="alertCircle" small /> {imageError}
                </div>
              )}
            </div>

            <Input
              disabled={loading}
              name="message"
              type="text"
              className="login-email-field"
              placeholder="Thank you message"
              value={values.message}
              onChange={handleChange}
              hasError={touched.message && !!errors.message}
              errorText={touched.message && !!errors.message ? errors.message : ''}
            />
            <Input
              disabled={loading}
              name="storeUrl"
              type="text"
              className="login-email-field"
              placeholder="Store Website"
              value={values.storeUrl}
              onChange={handleChange}
              hasError={touched.storeUrl && !!errors.storeUrl}
              errorText={touched.storeUrl && !!errors.storeUrl ? errors.storeUrl : ''}
            />
            <Input
              disabled={loading}
              name="helpContact"
              type="text"
              className="login-email-field"
              placeholder="Help contact"
              value={values.helpContact}
              onChange={handleChange}
              hasError={touched.helpContact && !!errors.helpContact}
              errorText={touched.helpContact && !!errors.helpContact ? errors.helpContact : ''}
            />
            <style jsx>
              {`
                textarea {
                  resize: none;
                  border-color: ${COLORS.neutralLight400};
                  border-radius: 4px;
                  width: 100%;
                }
                :global(#file-input-store-image) {
                  display: none !important;
                }
                .image-upload-container {
                  display: flex;
                  flex-direction: row;
                }
                .logo-header {
                  font-family: Avenir, Avenir-Roman, Avenir, sans-serif;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 15px;
                  margin-bottom: 10px;
                }
                .sub-text {
                  font-family: Avenir, Avenir-Roman, Avenir, sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 24px;
                  color: #646b72;
                }
                .image-upload-left {
                  margin-right: 10px;
                }
                .image-upload-right {
                }
                .image-error-field {
                  display: flex;
                  align-items: center;
                  // justify-content: center;
                  font-family: Avenir, Avenir-Roman, Avenir, sans-serif;
                  color: #c80404;
                  fill: #c80404;
                  font-size: 0.8125rem;
                  line-height: 0.8125rem;
                }
              `}
            </style>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <PageHeader
        title="Packing Slip"
        secondaryText="Cancel"
        secondaryButtonClass="button-default"
        secondaryClick={() => navigateBack()}
        secondaryDisabled={loading}
        primaryText="Save"
        primaryClick={() => submitImageAndForm()}
        primaryDisabled={loading}
        backClick={() => navigateBack()}
      />
      <Modal
        title="Packing Slip Preview"
        isOpen={previewModalOpen}
        maxWidth="583px"
        cancelClick={() => setPreviewModalOpen(false)}
      >
        <div className="p-5">
          <PackingSlipPreviewComponent
            storeName={formikRef.current && formikRef.current.values.storeName}
            logoUrl={logoPreview || initialState.logoUrl}
            message={formikRef.current && formikRef.current.values.message}
            storeUrl={formikRef.current && formikRef.current.values.storeUrl}
            helpContact={formikRef.current && formikRef.current.values.helpContact}
          />
        </div>
      </Modal>
      <div className="mt-5 left-container">
        <div className="top-buttons mb-4">
          <Button
            disabled={loading}
            className="mr-2 button-default large"
            onClick={() => setPreviewModalOpen(true)}
          >
            Preview template
          </Button>
          <Button
            disabled={loading}
            className="button-default large"
            onClick={() => toggleEnableDisable()}
          >
            {disabled ? 'Enable' : 'Disable'}
          </Button>
        </div>

        {packingSlipsForm()}
      </div>
      {loading && <Loader />}

      <style jsx>
        {`
          .main-container {
            display: flex;
            flex-direction: row;
            width: 100%;
          }
          .top-buttons {
            display: flex;
          }
          .left-container {
            max-width: 432px;
            padding-left: 2rem;
          }
          .right-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-end;
          }
        `}
      </style>
    </div>
  );
};

const mapDispatchToProps = {
  setToast: message => setToast(message)
};

export default connect(null, mapDispatchToProps)(PackingSlips);
