import { getActionName } from './../../SKUsSelectionViewActions';

export const SET_FLOW_SELECTED_INDEX = getActionName('SET_FLOW_SELECTED_INDEX');

export const setFlowSelectedIndex = payload => {
  return {
    type: SET_FLOW_SELECTED_INDEX,
    payload
  };
};
