import { connect } from 'react-redux';
import ProductOptions from './ProductOptions';
import {
  selectedOptionsSelector,
  optionsSelector,
  storeIdSelector,
  selectedProviderSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import {
  changeProductOption,
  removeProductOption,
  addProductOption
} from './ProductOptionsActions';
import Config from '../../../../../config';

const mapDispatchToProps = {
  changeProductOption: (storeId, optionId, valueId) =>
    changeProductOption({ storeId, optionId, valueId }),
  removeProductOption: (storeId, optionId) => removeProductOption({ storeId, optionId }),
  addProductOption: storeId => addProductOption({ storeId })
};

const mapStateToProps = state => {
  return {
    linkMode: Config.get('linkMode'),
    editMode: Config.get('editMode'),
    selectedOptions: selectedOptionsSelector(state),
    options: optionsSelector(state),
    storeId: storeIdSelector(state),
    selectedProvider: selectedProviderSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductOptions);
