import { connect } from 'react-redux';
import ImagesView from './ImagesView';
import {
  submitManipCommand,
  revertImage,
  submitImageFile,
  submitImageUrl,
  setActiveImage,
  storeRealImageDimensions,
  splitOrderItem
} from '../OrderDetailsActions';
import EditDesignItem from '../../../models/EditDesignItem';
import { Record, List } from 'immutable';
import { editDesign } from '../../../CustomOrderForm/EditDesignView/EditDesignViewActions';
import { activeImage, loadingStatus } from '../OrderDetailsSelectors';

const EditDesignItemImage = new Record({
  spaceId: '',
  imageUrl: '',
  imageId: 0,
  il: null,
  prpIL: null,
  width: null,
  height: null
});

export const mapEditDesignItemFromOrderItem = orderItem =>
  new EditDesignItem({
    productId: orderItem.ProductId,
    sku: orderItem.SKU,
    images: new List(
      orderItem.Images.map(
        i =>
          new EditDesignItemImage({
            spaceId: i.SpaceId,
            imageUrl: i.SourceUrl,
            imageId: i.Id,
            width: i.Dimensions ? i.Dimensions.Width : null,
            height: i.Dimensions ? i.Dimensions.Height : null
          })
      )
    )
  });

const mapDispatchToProps = {
  submitManipCommand: (imageId, manip) => submitManipCommand(imageId, manip),
  editDesign: orderItem => {
    return editDesign(mapEditDesignItemFromOrderItem(orderItem));
  },
  revertImage: (id, oldImage, itemIndex, imageIndex) =>
    revertImage(id, oldImage, itemIndex, imageIndex),
  submitImageFile: (imageId, file) => submitImageFile(imageId, file),
  submitImageUrl: (imageId, url) => submitImageUrl(imageId, url),
  resetActiveImage: () => setActiveImage(null),
  storeRealImageDimensions: (width, height, index, imageIndex) =>
    storeRealImageDimensions(width, height, index, imageIndex),
  splitOrderItem: (orderId, orderItemId) => splitOrderItem(orderId, orderItemId)
};

const mapStateToProps = state => {
  return {
    loading: loadingStatus(state),
    activeImage: activeImage(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesView);
