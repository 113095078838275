'use strict';
// @flow

import {PrintPreviewButton} from './printPreview.button';
import {ProductPreviewButton} from './productPreview.button';
import {MaximizeButton} from './maximize.button';

export default {
  PrintPreviewButton,
  ProductPreviewButton,
  MaximizeButton
};
