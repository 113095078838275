import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';
import Icon from '../../shared/Icon';
import ShowPaymentMethod from './Show';
import EditCreditCard from './EditCreditCard';
import PayPalButton from './PayPalButton';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Config from '../../../config';

const ConditionalModal = ({
  editInModal,
  cancelEdit,
  handleSubmit,
  isSubmitting,
  stripeFormBusy,
  children
}) =>
  editInModal ? (
    <Modal
      isOpen={true}
      cancelClick={cancelEdit}
      title={'Add Credit Card'}
      cancelText="Cancel"
      className="medium"
      customButtons={
        <Button
          disabled={isSubmitting || stripeFormBusy}
          loading={isSubmitting}
          onClick={handleSubmit}
          loading={isSubmitting || stripeFormBusy}
          className="button-primary large ml-2"
          type="submit"
          form="edit-credit-card-form"
        >
          Add card
        </Button>
      }
    >
      <div className="m-4">{children}</div>
    </Modal>
  ) : (
    <>
      {children}
      <div className="buttons-on-page d-flex align-items-center justify-content-start mt-4">
        <Button
          onClick={handleSubmit}
          loading={isSubmitting || stripeFormBusy}
          className="button-primary large"
          type="submit"
          form="edit-credit-card-form"
        >
          Save credit card
        </Button>
      </div>
    </>
  );

const PaymentMethod = ({
  method,
  isDefault,
  editInModal,
  braintreeToken,
  countries,
  sameAsAddress,
  updatePaypal,
  updateDefault,
  removeMethod,
  getSetupIntentId,
  loadingSetupIntentId,
  setupIntentId,
  clientSecret,
  handleStripeResponse,
  stripeFormBusy,
  paymentMethodCount,
  isOnCheckoutPage
}) => {
  const emptyMethodData = {
    type: '',
    creditCardType: '',
    lastFour: '',
    token: '',
    number: '',
    cvv: '',
    name: '',
    month: '',
    year: '',
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    },
    paypalUserEmail: '',
    isDefault: false
  };

  let [methodData, setMethodData] = useState(emptyMethodData);
  let [existing, setExisting] = useState(false);
  let [stripe, setStripe] = useState(null);
  let [parentLoading, setParentLoading] = useState(false);
  let [showingCreditCardModal, showCreditCardModal] = useState(false);

  useEffect(() => {
    if (method) {
      let methodData = {
        type: method.PaymentMethodType,
        creditCardType: method.CreditCardCardType,
        lastFour: method.CreditCardLastFour,
        token: method.Token,
        name: method.NameOnCard,
        month: parseInt(method.ExpirationMonth).toString(),
        year: method.ExpirationYear,
        paypalUserEmail: method.PayPalUserEmail,
        isDefault: method.IsDefault
      };

      const billingAddress = method.BillingAddress;
      if (billingAddress) {
        methodData = {
          ...methodData,
          billingAddress: {
            line1: billingAddress.Line1,
            line2: billingAddress.Line2,
            city: billingAddress.City,
            state: billingAddress.State,
            postalCode: billingAddress.PostalCode,
            country: billingAddress.CountryCode
          }
        };
      }

      setMethodData(methodData);
      setExisting(true);
      //showCreditCardModal(false);
    } else {
      setMethodData(emptyMethodData);
      setExisting(false);
      //showCreditCardModal(isDefault);
    }
  }, [method]);

  useEffect(() => {
    setStripe(
      loadStripe(
        Config.get('env') === 'staging'
          ? 'pk_test_51InUUeFkpjYgOiRUpgFz6i97PyPOZrmfYlelAJjgEhVwsd5no2vCvjnEk79IkrEwUA3OGy0JtteLh1u19zSkVG4m00lLZKtuUQ'
          : 'pk_live_51InUUeFkpjYgOiRUszqx40XKXAsscxDeR46wFGgeaKVopRONiuTS5gJo9O2P6xp9XHyc6943bDVzJbCThp3M2G9d00A4fSZZ8Z'
      )
    );
  }, []);

  useEffect(() => {
    if (isOnCheckoutPage && !method) {
      showCreditCardModal(true);
    }
  }, [method]);

  return (
    <div className="payment-method-wrapper">
      {showingCreditCardModal && (
        <Elements stripe={stripe}>
          <ConditionalModal
            editInModal={editInModal}
            cancelEdit={() => showCreditCardModal(false)}
            isSubmitting={parentLoading}
            stripeFormBusy={stripeFormBusy}
          >
            <EditCreditCard
              countries={countries}
              editInModal={editInModal}
              sameAsAddress={sameAsAddress}
              getSetupIntentId={getSetupIntentId}
              loadingSetupIntentId={loadingSetupIntentId}
              setupIntentId={setupIntentId}
              clientSecret={clientSecret}
              handleStripeResponse={handleStripeResponse}
              stripeFormBusy={stripeFormBusy}
              paymentMethodCount={paymentMethodCount}
              setParentLoading={setParentLoading}
              showCreditCardModal={showCreditCardModal}
              isOnCheckoutPage={isOnCheckoutPage}
            />
          </ConditionalModal>
        </Elements>
      )}

      {!showingCreditCardModal && method && methodData.type === 1 && (
        <>
          <ShowPaymentMethod
            key={methodData.token}
            icon={<Icon icon="creditCard" />}
            info={
              <span>
                Card ending in <strong>{methodData.lastFour}</strong>
              </span>
            }
            isDefault={methodData.isDefault}
            token={methodData.token}
            setDefault={updateDefault}
            removeMethod={removeMethod}
          />
        </>
      )}

      {!showingCreditCardModal && method && methodData.type === 2 && (
        <>
          <ShowPaymentMethod
            key={methodData.token}
            icon={<Icon icon="paypal" />}
            info={
              <span>
                Account email <strong>{methodData.paypalUserEmail}</strong>
              </span>
            }
            isDefault={isDefault}
            token={methodData.token}
            setDefault={updateDefault}
            removeMethod={removeMethod}
          />
        </>
      )}

      {(!showingCreditCardModal || isOnCheckoutPage) && !method && (
        <div className="d-flex align-items-center justify-content-start">
          {!isOnCheckoutPage && (
            <div className="add-credit-card mr-2">
              <Button className="button-primary large" onClick={() => showCreditCardModal(true)}>
                Add card
              </Button>
            </div>
          )}
          <div className="add-paypal w-100">
            {isOnCheckoutPage && <hr className="large mr-n4 ml-n4" />}
            <PayPalButton
              token={braintreeToken}
              onChange={nonce =>
                updatePaypal({
                  nonce,
                  braintreeToken,
                  isDefault: paymentMethodCount === 0 ? true : false
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

PaymentMethod.propTypes = {
  method: PropTypes.object,
  isDefault: PropTypes.bool.isRequired,
  braintreeToken: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  sameAsAddress: PropTypes.object,
  sameAsText: PropTypes.string,
  removeMethod: PropTypes.func.isRequired,
  updateDefault: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  updatePaypal: PropTypes.func.isRequired,
  getSetupIntentId: PropTypes.func.isRequired,
  loadingSetupIntentId: PropTypes.bool.isRequired,
  setupIntentId: PropTypes.string.isRequired,
  clientSecret: PropTypes.string.isRequired,
  handleStripeResponse: PropTypes.func.isRequired,
  stripeFormBusy: PropTypes.bool.isRequired,
  paymentMethodCount: PropTypes.number.isRequired,
  isOnCheckoutPage: PropTypes.bool
};

PaymentMethod.defaultProps = {
  sameAsText: 'business'
};

export default PaymentMethod;
