import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const UPDATE_ACCCESS = customActionFor('TEAM').async('UPDATE_ACCCESS');
export const REMOVE_MEMBER = customActionFor('TEAM').async('REMOVE_MEMBER');

// ------------------------------------
// Actions
// ------------------------------------

export function updateAccess(payload) {
  return {
    type: UPDATE_ACCCESS.ASYNC,
    payload
  };
}

export function removeMember(payload) {
  return {
    type: REMOVE_MEMBER.ASYNC,
    payload
  };
}
