import { toShortDate } from 'gooten-components/src/utils/strings';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const { vfs } = vfsFonts.pdfMake;
pdfMake.vfs = vfs;

class BillingHistoryService {
  downloadReciept(reciept, partnerDetails) {
    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      content: [
        { text: `Date: ${toShortDate(reciept.ChargeTime)}` },
        '\n',
        { text: `Transaction: ${reciept.TransactionId}` },
        '\n',
        { text: `Account: ${partnerDetails.CompanyName}` },
        '\n',
        '\n',
        {
          columns: [
            { text: 'Description', style: 'header' },
            { text: 'Total USD', style: 'header' }
          ]
        },
        { canvas: [{ type: 'line', x1: 0, y1: 3, x2: 595 - 2 * 40, y2: 3, lineWidth: 1 }] },
        {
          columns: [{ text: `Costs for order ${reciept.OrderId}` }, { text: `$${reciept.Amount}` }]
        },
        '\n',
        '\n',
        { text: `Billed to:` },
        '\n',
        { text: partnerDetails.CompanyName },
        '\n',
        { text: partnerDetails.Line1 },
        '\n',
        ...(partnerDetails.Line2 ? [{ text: partnerDetails.Line2 }, '\n'] : []),
        {
          text: `${partnerDetails.City}, ${partnerDetails.State} ${partnerDetails.PostalCode}`
        },
        '\n'
      ],
      styles: {
        header: {
          bold: true
        }
      }
    };

    pdfMake.createPdf(documentDefinition).download(`${reciept.OrderId}.pdf`);
  }
}

export default new BillingHistoryService();
