import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import NotesCard from './NotesCard';
import { COLORS } from 'gooten-components/src/constants';

const NotesView = props => {
  const useItemMeta = useMemo(() => {
    return (
      props.summaryData.Meta &&
      props.summaryData.Meta.RefundItems &&
      props.itemsData.reduce((p, i) => p || (i.Meta && !!i.Meta.AdminComments), false)
    );
  }, [props.summaryData.Meta, props.itemsData]);

  const refundItems = useMemo(() => {
    return useItemMeta
      ? Object.values(
          props.itemsData.reduce((p, i, idx) => {
            if (i.Meta && (i.Meta.RefundReprintReason || i.Meta.AdminComments)) {
              const key = `${i.Meta.AdminComments}-${i.Meta.RefundReprintReason}-${i.Meta.ZendeskTicketId}-${i.Meta.ImageEvidence}`;
              if (p[key]) {
                p[key].ids.push(i.Item);
                p[key].indexes.push(idx);
              } else {
                p[key] = {
                  ids: [i.Item],
                  indexes: [idx],
                  meta: i.Meta
                };
              }
            }
            return p;
          }, {})
        )
      : null;
  }, [props.summaryData.Meta, props.itemsData]);

  // Formatting Meta info into useable data
  const getMetaNotes =
    props.summaryData.Meta && props.itemsData.length
      ? props.summaryData.Meta.ReprintItems
        ? {
            title: 'Reprint',
            itemIds: props.summaryData.Meta.ReprintItems
          }
        : props.summaryData.Meta && props.summaryData.Meta.RefundItems
        ? {
            title: 'Refund',
            itemIds: props.summaryData.Meta.RefundItems
          }
        : {
            title: '',
            itemIds: [],
            adminComments: unescape(props.summaryData.Meta.AdminComments)
          }
      : null;

  return (
    <>
      <div className="notes-tab">
        <div className="notes-content mt-4">
          <div className="title-2">Notes</div>
          {useItemMeta &&
            refundItems.map(item => {
              return (
                <NotesCard
                  key={`notes-card-${item.ids.join()}`}
                  Meta={item.meta}
                  title={'Refund'}
                  itemIds={item.ids.join(', ')}
                  updateMeta={meta =>
                    props.updateOrderItemsMeta(props.params.id, item.indexes, item.ids, meta)
                  }
                />
              );
            })}
          {getMetaNotes && !useItemMeta && (getMetaNotes.adminComments || getMetaNotes.title) && (
            <NotesCard
              Meta={props.summaryData.Meta}
              title={getMetaNotes.title}
              itemIds={getMetaNotes.itemIds}
              updateMeta={meta => props.updateOrderMeta(props.params.id, meta)}
            />
          )}
        </div>
      </div>
      <style jsx>
        {`
          .notes-content {
            width: 35rem;
          }
          .notes-content .caption-text {
            color: ${COLORS.cayennePepper};
          }
        `}
      </style>
    </>
  );
};

NotesView.propTypes = {
  summaryData: PropTypes.object.isRequired,
  updateOrderMeta: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
};

export default NotesView;
