import { fromJS } from 'immutable';

// ========================================================
// Define Initial State
// ========================================================

const initialState = {
  // data: {
  //   categories: [],
  //   products: [],
  //   sku: null
  // }
  // globalUI: {
  //   backdrop: false
  // }
  /*
  productSelection: {
    search: '',
    selectedCategory: null,
    selectedSubcategory: null,
    selectedProduct: null,
  }
  */
};

export default fromJS(initialState);
