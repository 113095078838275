import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COF_HUB_PRODUCTS', 'HUB_PRODUCTS_VIEW');

// ------------------------------------
// Action Types
// ------------------------------------
export const SEARCH_CHANGE = getActionName('SEARCH_CHANGE');

// ------------------------------------
// Actions
// ------------------------------------
export function searchChange(searchText = '') {
  return {
    type: SEARCH_CHANGE,
    payload: { searchText }
  };
}
