import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from '../../../../shared/Spinner';
import { windowWidth } from '../../../../shared/ComponentsHooks';
import Alert from '../../../../shared/Alert';

import './ProductImage.scss';

const Container = styled.div`
  padding: 0 0 24px 0;

  .product-image-holder {
    height: ${props => (props.width <= 480 ? '115' : '160')}px;
    width: ${props => (props.width <= 480 ? '115' : '160')}px;
    align-items: center;
    overflow: hidden;
    position: relative;

    .spinner-container {
      display: ${props => (props.imageLoaded ? 'none' : 'initial')};
    }

    .item-image {
      width: 100%;
      opacity: ${props => (props.imageLoaded ? 1 : 0)};
      transition: opacity 0.3s;
    }
  }

  .title-holder {
    padding-left: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    flex-direction: column;
    width: calc(100% - ${props => (props.width <= 480 ? '115' : '160')}px);

    span:nth-child(1) {
      font-weight: 900;
      font-size: 21px;
    }

    span:nth-child(2) {
      font-size: 12px;
    }
  }
`;

const ProductImage = props => {
  const width = windowWidth();
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Container
      className="product-publish-product-image flex-container"
      imageLoaded={imageLoaded}
      width={width}
    >
      <div className="product-image-holder flex-container">
        {!props.isLoading ? (
          <React.Fragment>
            <img className="item-image" src={props.preview} onLoad={() => setImageLoaded(true)} />
            <Spinner />
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </div>
      <div className="title-holder flex-container">
        <span>{props.title}</span>
        <span>{props.info}</span>
        {props.failedReason && (
          <Alert isOpen type="important">
            {props.failedReason}
          </Alert>
        )}
      </div>
    </Container>
  );
};

ProductImage.propTypes = {
  preview: PropTypes.string,
  failedReason: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  info: PropTypes.string
};

export default ProductImage;
