import { ActionNameFactory } from '../../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'PRODUCT-OPTIONS');
export const CHANGE_PRODUCT_OPTION = getActionName('CHANGE_PRODUCT_OPTION');
export const REMOVE_PRODUCT_OPTION = getActionName('REMOVE_PRODUCT_OPTION');
export const ADD_PRODUCT_OPTION = getActionName('ADD_PRODUCT_OPTION');

export const changeProductOption = payload => ({
  type: CHANGE_PRODUCT_OPTION,
  payload
});

export const removeProductOption = payload => ({
  type: REMOVE_PRODUCT_OPTION,
  payload
});

export const addProductOption = payload => ({
  type: ADD_PRODUCT_OPTION,
  payload
});
