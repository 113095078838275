import React from 'react';
import PropTypes from 'prop-types';
import { Wizard } from 'gooten-components';
import AppStore from '../../store';
import Config from '../../config';
import CountrySelection from 'gooten-components/src/components/shared/CountrySelection';

import './CreateNewView.scss';

class CreateNewView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countrySelected: false
    };
  }

  onCountrySelect = () => {
    this.setState({
      countrySelected: true
    });
  };

  renderCOFWizard() {
    // get initial config and modify it depends on how you want Wizard to show up
    const config = Config.toJS();

    config.cof = true;

    config.analyticsSource = 'COF - New Product Flow';
    config.analyticsCategory = 'COF';

    config.steps = [
      {
        id: 'ProductSelection',
        config: {
          hideStatusBar: true
        }
      },
      {
        id: 'SKUSelection',
        config: {
          hideStatusBar: true
        }
      },
      {
        id: 'ImageUpload',
        config: {
          showNeckLabelSelection: true,
          hideStatusBar: true,
          nextButtonLabel: 'Add to cart',
          cartEditMode: {
            backButtonLabel: 'Cancel',
            nextButtonLabel: 'Save',
            parentStep: 'Cart'
          },
          editorConfig: {
            container: '.gooten-image-editor',
            state: null,
            allowMultiImages: false,
            zoomControlShown: true,
            previewControlShown: false,
            toolbarShown: true,
            toolbarOptions: {
              customClass: 'my-toolbar',
              buttons: [
                {
                  type: 'alignHorizontal',
                  name: 'Align Horizontal',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                {
                  type: 'alignVertical',
                  name: 'Align Vertical',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                { type: 'crop', name: 'Crop', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'rotate', name: 'Rotate', customClass: 'my-toolbar-action editor-tooltip' },
                // { type: 'orientation', name: 'Orientation', customClass: 'my-toolbar-action' },
                { type: 'undo', name: 'Undo', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'redo', name: 'Redo', customClass: 'my-toolbar-action editor-tooltip' }
              ]
            }
          },
          sliderConfig: {
            width: 400,
            height: 600,
            contentHeight: 490
          }
        }
      },
      {
        id: 'Cart',
        config: {
          hideStatusBar: true,
          onKeepShoppingClick: () => {
            if (this.props.hasPastOrders || this.props.hasHubProducts) {
              this.props.router.push('/custom-order-form');
              // NOTE: return false here to prevent default handler execute
              return false;
            } else {
              return true;
            }
          }
        }
      },
      {
        id: 'Checkout',
        config: {
          hideStatusBar: true
        }
      }
    ];

    return <Wizard config={config} store={AppStore} />;
  }

  render() {
    if (!this.props.hasCartItems && !this.state.countrySelected) {
      return <CountrySelection onNextClick={this.onCountrySelect} />;
    } else {
      return this.renderCOFWizard();
    }
  }
}

CreateNewView.propTypes = {
  router: PropTypes.object.isRequired,
  hasPastOrders: PropTypes.bool.isRequired,
  hasHubProducts: PropTypes.bool.isRequired,
  hasCartItems: PropTypes.bool.isRequired
};

export default CreateNewView;
