import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import adminApiService from '../../services/adminApiService';
import analyticsService from '../../services/analyticsService';
import Log from '../../services/logService';
import storesService from '../../services/storesService';

import { getShopifyOAuthUrl, parseStoreUrl, openOAuthPage, OAUTH_STATUS } from '../../utils/oauth';

import Config from '../../config';

import {
  STORES_CONNECT_PROVIDER,
  STORE_CONNECT,
  STORE_LOG_OUT,
  storesConnectStep,
  connectStoreSuccess,
  connectStoreFail,
  storeLogOutSuccess,
  storeLogOutFail
} from './StoresActions';

export function* platformSelected(action) {
  yield put(storesConnectStep(2));
}

export function* storeConnectHandler(action) {
  switch (action.payload.provider) {
    case 'etsy':
      yield etsyStoreConnectHandler(action);
      break;
    case 'shopify':
      yield shopifyStoreConnectHandler(action);
      break;
    case 'woocommerce':
      yield wooStoreConnectHandler(action);
      break;
    case 'bigcommerce':
      yield bigcommerceStoreConnectHandler(action);
      break;
    default:
      yield put(connectStoreFail('Unsupported eCommerce Platform.'));
  }
}

export function* wooStoreConnectHandler(action) {
  const domainName = (action.payload.store.domainName || action.payload.store)
    .replace(/(^\w+:|^)\/\//, '')
    .replace(/\/+$/, '');

  const recipeId = Config.get('recipeId');
  // const callbackUrl = 'https://bogie.ngrok.io/oauth/woocommerce/'
  // + domainName + '/' + recipeId
  // const returnUrl = 'https://bogie-dev.ngrok.io/generic-oauth.html'

  const { error } = yield call(
    [storesService, storesService.getOAuth],
    action.payload.provider,
    domainName
  );

  if (error) {
    analyticsService._track('Hub - Integrations', 'Woo Store Connect Failure', 'Woo', null, {
      error
    });
    // Show API validation error
    yield put(connectStoreFail(error));
    return;
  }

  const callbackUrl =
    `${Config.get('storeApi')}`.replace(/gooten.com.*/, 'gooten.com') +
    '/oauth/woocommerce/' +
    domainName +
    '/' +
    recipeId;

  const returnUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    '/admin-assets/scripts/areas/shopify/vendors/' +
    'generic-oauth.html';

  // We are encoding the callback and return urls to prevent any issues with special characters
  // https://gooten.atlassian.net/browse/TECH-12551
  const url =
    'http://' +
    domainName +
    '/wc-auth/v1/authorize?app_name=Gooten&user_id=' +
    recipeId +
    '&scope=read_write&return_url=' +
    encodeURIComponent(returnUrl) +
    '&callback_url=' +
    encodeURIComponent(callbackUrl);

  try {
    const oauth = yield openOAuthPage(url);
    if (oauth.query.success === '1') {
      const stores = yield call([storesService, storesService.getUserStores]);

      const hit = stores.filter(
        s => s.provider === 'woocommerce' && s.settings.get('shop_domain').includes(domainName)
      );

      if (hit && hit.size === 1) {
        const newStore = hit.get(0);
        // Adds store to all stores in state
        yield put(connectStoreSuccess(newStore));
      } else {
        yield put(
          connectStoreFail('Cannot connect to your store, please check domain name and try again')
        );
      }
    } else {
      yield put(
        connectStoreFail('Cannot connect to your store, please check domain name and try again')
      );
    }
  } catch (err) {
    yield put(connectStoreFail('Please allow popup window.'));
  }
}

export function* etsyStoreConnectHandler(action) {
  try {
    const redirectUrl =
      window.location.protocol +
      '//' +
      window.location.hostname +
      '/admin-assets/scripts/areas/shopify/vendors/' +
      'generic-oauth.html';
    // const redirectUrl = 'https://bogie-dev.ngrok.io/generic-oauth.html'

    const {
      url,
      codeVerifier, //v3
      state, //v3
      version, //v3
      tokenKey, //v2
      tokenSecret, //v2
      error
    } = yield call(
      [storesService, storesService.getOAuth],
      action.payload.provider + '-v3',
      action.payload.store,
      redirectUrl
    );

    if (error || (!url && !tokenKey && !tokenSecret)) {
      analyticsService._track('Hub - Integrations', 'Etsy Store Connect Failure', 'Etsy', null, {
        error
      });
      // Show API validation error
      yield put(connectStoreFail(error || `Store doesn't exist.`));
      return;
    }

    try {
      const oauth = yield openOAuthPage(decodeURIComponent(url));
      if (oauth.query.state !== state) {
        throw 'Etsy V3 OAuth failed.';
      }

      const storeAuth = {
        //OAuth 2.0 for Etsy API v3
        code: oauth.query.code,
        state: oauth.query.state,
        redirectUrl,
        codeVerifier,
        //OAuth 1.0 for Etsy API v2
        storeName: action.payload.store,
        tokenKey,
        tokenSecret,
        verifierCode: oauth.query.oauth_verifier
      };

      // TODO: API should return connected store info
      // so we can add it to stores in state and redirect user to it
      const store = yield call([storesService, storesService.storeV3Connect], storeAuth);

      if (store.error) {
        analyticsService._track('Hub - Integrations', 'Etsy Store Connect Failure', 'Etsy', null, {
          error: store.error
        });
        yield put(connectStoreFail(store.error));
        return;
      } else {
        analyticsService._track('Hub - Integrations', 'Etsy Store Connect Success', 'Etsy');
        // Adds store to all stores in state
        yield put(connectStoreSuccess(store));
      }
    } catch (err) {
      analyticsService._track('Hub - Integrations', 'Etsy Store Connect Failure', 'Etsy', null, {
        error: 'Popup not allowed'
      });
      yield put(connectStoreFail('Please allow popup window.'));
    }
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    yield put(connectStoreFail('Etsy oauth failed.'));
  }
}

// This function is for connecting a new Shopify Store to OAuth directly
export function* shopifyStoreConnectHandler(action) {
  try {
    const partnerId = Config.get('partnerId');
    const storeName = parseStoreUrl(action.payload.store);
    //Save partner id for super-admin user
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 3600;
    now.setTime(expireTime);
    document.cookie = `hubPartnerId=${partnerId};max-age=${now.toUTCString()};path=/`;

    const kData = yield call(
      adminApiService.get,
      `ShopifyAccounts/GetAppClientKey?partnerId=${partnerId}`
    );
    const sData = yield call(adminApiService.post, `ShopifyAccounts/IsStoreConnected`, {
      Store: storeName,
      PartnerId: partnerId
    });

    // Don't check for existing store on ReAuthorization
    if ((sData.isConnected || !sData.exist) && !action.payload.isReauth) {
      const error = sData.isConnected
        ? 'This store is already connected to a different Gooten account.'
        : 'This store is temporarily unavailable or not exist.';
      analyticsService._track(
        'Hub - Integrations',
        'Shopify Store Connect Failure',
        'Shopify',
        null,
        { error }
      );
      yield put(connectStoreFail(error));
      return;
    }

    const url = getShopifyOAuthUrl(storeName, kData.Key);
    window.location.replace(decodeURIComponent(url));
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    switch (err ? err.status : null) {
      case OAUTH_STATUS.POPUP_BOCKED:
        yield put(connectStoreFail('Popup blocked.'));
        break;
      case OAUTH_STATUS.REQUEST_FAILED:
        yield put(connectStoreFail('Shopify oauth request failed.'));
        break;
      default:
        yield put(connectStoreFail('Shopify oauth failed.'));
    }
  }
}

export function* bigcommerceStoreConnectHandler(action) {
  const appId = Config.get('env') === 'staging' ? '29325' : '26835';
  try {
    const storeTruncated = action.payload.store.replace('.mybigcommerce.com', '');
    const storeId = storeTruncated.replace(/[^a-zA-Z0-9-]/g, '');
    const store = yield call([storesService, storesService.storeConnectBigCommerce], {
      storeId,
      token: ''
    });

    if (store.error) {
      if (store.error === 'No Access Token') {
        // The store hasn't installed the Gooten app yet
        window.location =
          'https://' + storeId + '.mybigcommerce.com/manage/marketplace/apps/' + appId;
      } else {
        yield put(connectStoreFail(store.error));
      }
    } else {
      analyticsService._track(
        'Hub - Integrations',
        'BigCommerce Store Connect Success',
        'BigCommerce'
      );
      yield put(connectStoreSuccess(store));

      hashHistory.push(`/hub/bigcommerce/${store.id || ''}`);
    }
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    switch (err ? err.status : null) {
      case OAUTH_STATUS.POPUP_BOCKED:
        yield put(connectStoreFail('Popup blocked.'));
        break;
      case OAUTH_STATUS.REQUEST_FAILED:
        yield put(connectStoreFail('BigCommerce oauth request failed.'));
        break;
      default:
        yield put(connectStoreFail('BigCommerce oauth failed.'));
    }
  }
}

export function* storeLogOutAsyncHandler(action) {
  const storeId = action.payload.storeId;
  try {
    yield call([storesService, storesService.storeLogOut], storeId);
    yield put(storeLogOutSuccess());
  } catch (err) {
    yield put(storeLogOutFail(err));
    console.error(err);
  }
}

export function* storesLoadHandler() {
  try {
    yield call([storesService, storesService.getUserStores], true);
  } catch (err) {
    console.error(err);
  }
}

export function* watchForPlatformSelect() {
  yield takeEvery(STORES_CONNECT_PROVIDER, platformSelected);
}

export function* watchStoreConnectAsync() {
  yield takeLatest(STORE_CONNECT.ASYNC, storeConnectHandler);
}

export function* watchStoreConnectSuccess() {
  yield takeEvery(STORE_CONNECT.SUCCESS, storesLoadHandler);
}

export function* watchStoreLogOutAsync() {
  yield takeEvery(STORE_LOG_OUT.ASYNC, storeLogOutAsyncHandler);
}

export function* watchStoreLogOutSuccess() {
  yield takeEvery(STORE_LOG_OUT.SUCCESS, storesLoadHandler);
}

export default function* rootSaga() {
  yield all([
    watchForPlatformSelect(),
    watchStoreConnectAsync(),
    watchStoreConnectSuccess(),
    watchStoreLogOutAsync(),
    watchStoreLogOutSuccess()
  ]);
}
