import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { SET_USER_ENTERED_SHIPPING_ADDRESS, setSuggestedAddress } from './AddressFormActions';
import { showLoading, hideLoading } from '../../../../../store/actions/globalActions';
import { addressValidationRequestSelector } from './AddressFormSelectors';
import addressValidationService from './services/addressValidationService';

export function* updateSuggestedAddress(action) {
  yield put(showLoading());
  let addressValidationRequest = yield select(addressValidationRequestSelector);
  if (addressValidationRequest) {
    let addressValidationResult = fromJS(
      yield call(addressValidationService.validate, addressValidationRequest)
    );

    const rawSuggestedAddress = addressValidationResult.get('ProposedAddress');
    if (rawSuggestedAddress) {
      let normalizedSuggestedAddress = {
        city: rawSuggestedAddress.get('City'),
        country: rawSuggestedAddress.get('CountryCode'),
        zipCode: rawSuggestedAddress.get('PostalCode'),
        state: rawSuggestedAddress.get('StateOrProvinceCode')
      };

      if (rawSuggestedAddress.getIn(['StreetLines', 0])) {
        normalizedSuggestedAddress = {
          ...normalizedSuggestedAddress,
          addressLine1: rawSuggestedAddress.getIn(['StreetLines', 0])
        };
      }

      if (rawSuggestedAddress.getIn(['StreetLines', 1])) {
        normalizedSuggestedAddress = {
          ...normalizedSuggestedAddress,
          addressLine2: rawSuggestedAddress.getIn(['StreetLines', 1])
        };
      }
      addressValidationResult = addressValidationResult.set(
        'ProposedAddress',
        normalizedSuggestedAddress
      );
    }

    yield put(setSuggestedAddress(addressValidationResult));
  }
  yield put(hideLoading());
}

export function* watchShippingAddressChange() {
  yield takeLatest(SET_USER_ENTERED_SHIPPING_ADDRESS, updateSuggestedAddress);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchShippingAddressChange()]);
}
