import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { GOTO_PREVIEWS_PAGE, SAVE_MAIN_MOCKUP, setPreviewOptions } from './MockupsActions';
import { COMPONENT_MOUNTED } from '../../ProductPublishActions';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import {
  previewDataSelector,
  previewItemsSelector
} from '../../../ProductPreview/ProductPreviewSelectors';
import { updatePreviewData } from '../../../ProductPreview/ProductPreviewActions';
import { delay } from 'redux-saga';
import Config from '../../../../config';
import { goto } from '../../../../containers/NavManager/NavManagerActions';
import { PUBLISH_PREPARE } from '../../../../store/actions/dataActions';

export function* saveMainMockupHandler(action) {
  const newMainUrl = action.payload;
  const previewData = yield select(previewDataSelector);
  const previewItems = previewData.items;
  let orderedPreviews;

  // if preview items exist, just change the order
  if (previewItems.length) {
    const newMainMockup = previewItems.filter(
      pi =>
        pi.previewImageUrl &&
        (getSmallPreviewImageResizerUrl(pi.previewImageUrl).includes(newMainUrl) ||
          pi.previewImageUrl.includes(newMainUrl))
    );
    orderedPreviews = newMainMockup.concat(
      previewItems.filter(
        pi =>
          pi.previewImageUrl && getSmallPreviewImageResizerUrl(pi.previewImageUrl) !== newMainUrl
      )
    );
  }

  if (orderedPreviews) {
    yield put(updatePreviewData(orderedPreviews));
  }
}

export function* componentMountedHandler() {
  yield call(delay, 300);

  // set preview data in edit mode, because Preview step is skipped
  if (Config.get('editMode')) {
    const previews = yield select(previewItemsSelector);
    yield put(updatePreviewData(previews));
  }
}

export function* gotoPreviewsPageHandler() {
  yield put(goto('ProductPreview'));
  yield put(setPreviewOptions({ editMode: true, allSelected: true }));
}

export function* watchSaveMainMockup() {
  yield takeLatest(SAVE_MAIN_MOCKUP, saveMainMockupHandler);
}

export function* watchComponentMount() {
  yield takeLatest([COMPONENT_MOUNTED, PUBLISH_PREPARE.ASYNC], componentMountedHandler);
}

export function* watchGotoPreviewsPage() {
  yield takeLatest(GOTO_PREVIEWS_PAGE, gotoPreviewsPageHandler);
}

export default function* rootSaga() {
  yield all([watchSaveMainMockup(), watchComponentMount(), watchGotoPreviewsPage()]);
}
