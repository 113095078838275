import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from './../ProductSelectionStyle';
import Multiselect from 'gooten-components/src/components/shared/MultiselectInfiniteScroll';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { stepIndexElement } from 'gooten-components/src/components/shared/SelectionElements/SelectionElements';
import './HubProductSelection.scss';
import { nextSection } from '../ProductSelectionSharedComponents';
import Button from 'gooten-components/src/components/shared/Button';

const multiselectConfig = {
  showFilter: true
};
const selectedProductDefaultState = { label: 'Search for Product...', key: -1 };

export class HubProductSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: selectedProductDefaultState
    };
  }

  componentDidMount() {
    // on component mount, load hub products to populate dropdown list
    this.props.querySearch('');
  }

  onProductSelectionChange(selected) {
    if (selected.length) {
      this.setState({ selectedProduct: selected[0] });

      // give time user to see what has been selected
      setTimeout(() => {
        this.props.selectProduct(selected[0].key);
      }, 300);
    } else {
      // reset product selection
      this.props.selectProduct(null);
      this.setState({ selectedProduct: selectedProductDefaultState });
    }
  }

  onCancelFullfillment() {
    this.props.deselectLastSKU();
  }

  onPublishProduct() {
    this.props.publishProduct();
  }

  onLoadMore() {
    this.props.queryNextPage();
  }

  onFilterText(filter) {
    this.props.querySearch(filter);
  }

  render() {
    return (
      <Container
        className="hub-product-selection-container"
        marginLeft={this.props.marginLeft}
        isNewProductFlow={false}
      >
        {this.renderProductSelection()}
        {this.renderNextSection()}
        {this.renderFooter()}
      </Container>
    );
  }

  renderProductSelection() {
    return (
      <div>
        {this.props.initialStepIndex > 1 ? <hr className="delimiter" /> : null}
        <div className="row step-container">
          <div className="step-index-container">
            {this.props.selectedProduct ? (
              <div className="step-index">
                <i className="icon-progress-selected-check" />
              </div>
            ) : (
              stepIndexElement(this.props.initialStepIndex)
            )}
          </div>
          {!this.props.selectedProduct ? (
            <div className="step-content">
              <p className={!this.props.selectedProduct ? 'step-title active ' : 'step-title'}>
                Select your matching product
              </p>
              <div>
                <Multiselect
                  initialValue={this.props.query.get('search')}
                  title={this.state.selectedProduct.label}
                  placeholder={'Type Product Name...'}
                  options={this.props.hubProductsForSelect}
                  onChange={s => this.onProductSelectionChange(s)}
                  config={multiselectConfig}
                  hasMore={this.props.hasMoreProducts}
                  filterLocally={this.props.canFilterLocally}
                  onLoadMore={() => this.onLoadMore()}
                  onFilterText={filter => this.onFilterText(filter)}
                  showLoading={this.props.queryInProgress && this.props.query.get('page') === 1}
                  scrollHeight={180}
                />
              </div>
            </div>
          ) : (
            <div className="step-content">
              <span>{this.props.selectedProduct.get('name')}</span>
              <span
                className="pull-right clear-link"
                onClick={() => this.onProductSelectionChange([])}
              >
                change
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderNextSection() {
    if (!this.props.selectedProduct) {
      return nextSection('Next: Sync your Variants', this.props.selectedProduct);
    }
  }

  renderFooter() {
    return this.props.selectedProduct && this.props.canGoNext ? (
      <ReactCSSTransitionGroup
        // eslint-disable-next-line
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
        transitionName="fade"
      >
        <div className="can-go-next-container text-center">
          <ReactCSSTransitionGroup
            // eslint-disable-next-line
            transitionAppear={true}
            transitionAppearTimeout={800}
            transitionEnter={false}
            transitionLeave={false}
            transitionName="slide-up"
          >
            <div className="content-holder">
              <p>Your SKUs are now synced!</p>
              <Button
                onClick={() => this.onPublishProduct()}
                className={`button-cta large ${this.props.publishInProgress ? 'disabled' : ''}`}
              >
                {this.props.continueButtonLabel}
              </Button>
              <br />
              <Button
                className={`button-default small ${this.props.publishInProgress ? 'disabled' : ''}`}
                onClick={() => this.onCancelFullfillment()}
              >
                Cancel
              </Button>
            </div>
          </ReactCSSTransitionGroup>
        </div>
      </ReactCSSTransitionGroup>
    ) : null;
  }
}

HubProductSelection.propTypes = {
  hubProductsForSelect: PropTypes.array.isRequired,
  initialStepIndex: PropTypes.number.isRequired,
  hasMoreProducts: PropTypes.bool.isRequired,
  queryNextPage: PropTypes.func.isRequired,
  querySearch: PropTypes.func.isRequired,
  canFilterLocally: PropTypes.bool.isRequired,
  queryInProgress: PropTypes.bool.isRequired,
  query: PropTypes.object.isRequired,
  selectProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  canGoNext: PropTypes.bool,
  deselectLastSKU: PropTypes.func.isRequired,
  publishProduct: PropTypes.func.isRequired,
  publishInProgress: PropTypes.bool.isRequired,
  marginLeft: PropTypes.number,
  continueButtonLabel: PropTypes.string
};

export default HubProductSelection;
