import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { List, Map } from 'immutable';
import throttle from 'gooten-components/src/utils/throttle';
import ActionConfirmationModal from './ActionConfirmationModal';
import analyticsService from 'gooten-components/src/services/analyticsService';
import ActionsLine from './ActionsLine';

import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import Search from '../Search';
import { COLORS } from 'gooten-components/src/constants';

const Actions = ({
  router,
  display,
  viewBy,
  sortBy,
  changeViewBy,
  changeSortBy,
  deleteProducts,
  publishProducts,
  unpublishProducts,
  unlinkProducts,
  availableOptions,
  viewByOptions,
  changeDisplay,
  selectedProducts,
  productUrlInStore
}) => {
  const actionConfirmationModal = useRef(null);
  const [showActionsBorder, setShowActionsBorder] = useState(false);

  let { canPlaceOrder, canDelete, canUnpublish, canPublish, canUnlink, canPersonalize } =
    availableOptions;

  // add sticky position to action line if it's not visible
  // to user anymore due to scrolling down.
  useEffect(() => {
    const onScroll = throttle(() => {
      if (
        document.getElementsByClassName('gooten-components-container').length &&
        document.getElementsByClassName('actions-line').length
      ) {
        const container = document.getElementsByClassName('gooten-components-container')[0];
        const child = document.getElementsByClassName('actions-line')[0];

        setShowActionsBorder(
          container.scrollTop - child.offsetTop - child.parentElement.offsetTop >= 0 &&
            selectedProducts.size
        );
      }
    }, 300);

    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  });

  const displayAsGrid = () => {
    analyticsService.track('Hub - Page', 'Grid view selected', 'Hub', null, {
      page: router.location.pathname
    });
    changeDisplay('grid');
  };

  const displayAsList = () => {
    analyticsService.track('Hub - Page', 'List view selected', 'Hub', null, {
      page: router.location.pathname
    });
    changeDisplay('list');
  };

  const renderDisplaySwitcher = () => (
    <div className="display-controls" role="group" aria-label="display">
      <Button
        dataEvent="view-grid"
        iconOnly
        type="button"
        onClick={() => displayAsGrid()}
        className={`grid-view large mr-1 ${
          display === 'grid' ? 'button-primary' : 'button-default'
        }`}
      >
        <Icon icon="grid" />
      </Button>
      <Button
        dataEvent="view-list"
        iconOnly
        type="button"
        onClick={() => displayAsList()}
        className={`list-view large ${display === 'list' ? 'button-primary' : 'button-default'}`}
      >
        <Icon icon="list" />
      </Button>
    </div>
  );

  const renderViewByDropdown = () =>
    viewByOptions.size > 0 ? (
      <DropDownContainer title={viewBy ? viewBy + ' ' : 'View by '} customClassName="viewBy">
        {viewByOptions.map(option => (
          <ListItem
            key={option.get('key')}
            onSelect={() => {
              changeViewBy(option.get('key'));
              // Push viewBy to URL query
              router.push({
                ...router.location,
                query: {
                  ...router.location.query,
                  viewBy,
                  page: 1
                }
              });
            }}
            tooltip={option.get('name')}
            text={option.get('name')}
          />
        ))}
      </DropDownContainer>
    ) : null;

  const sortByOptions = new List([
    new Map({ key: 'date-created', name: 'Date Created' }),
    new Map({ key: 'product-title', name: 'Product Title A-Z' }),
    new Map({ key: 'product-title-reversed', name: 'Product Title Z-A' }),
    new Map({ key: 'date-updated', name: 'Date Modified' })
  ]);

  const renderSortByDropdown = () => (
    <DropDownContainer
      title={
        sortBy ? sortByOptions.find(op => op.get('key') === sortBy).get('name') + ' ' : 'Sort by '
      }
      customClassName="sortBy"
    >
      {sortByOptions.map(option => (
        <ListItem
          key={option.get('key')}
          onSelect={() => {
            changeSortBy(option.get('key'));
            router.push({
              ...router.location,
              query: {
                ...router.location.query,
                sortBy,
                page: 1
              }
            });
          }}
          tooltip={option.get('name')}
          text={option.get('name')}
        />
      ))}
    </DropDownContainer>
  );

  const actionLineOptions = () => {
    const titles = [
      'Place an Order',
      'Edit',
      'Duplicate',
      'Stop Fulfillment',
      'Delete',
      'Unpublish',
      'Publish',
      'View in Store',
      'Personalized'
    ];
    const icons = [
      'shoppingBag',
      'edit2',
      'copy',
      'xSquare',
      'trash',
      'slash',
      'checkCircle',
      'eye'
    ];
    // order of actions:
    // canPlaceOrder, canEdit, canDuplicate, canUnlink, canDelete, canUnpublish, canPublish, canViewInStore, canPersonalize
    const availableOptionsValues = Object.values(availableOptions);
    return titles
      .map((title, pos) => ({
        title,
        icon: icons[pos],
        enabled: availableOptionsValues[pos]
      }))
      .filter(item => item.enabled);
  };

  const onOptionSelect = action => {
    analyticsService.track('Hub - Page', action, 'Hub', null, {
      page: router.location.pathname
    });
  };

  const getGroupLabel = () => {
    return selectedProducts.size > 1 ? '(group)' : '';
  };

  const mobileScreenActionsPanel = (
    canPlaceOrder,
    canDelete,
    canUnpublish,
    canPublish,
    canUnlink
  ) => {
    const canShow =
      selectedProducts.size === 1 ||
      productUrlInStore ||
      canUnpublish ||
      canUnlink ||
      canDelete ||
      canPlaceOrder;
    return canShow ? (
      <div className="visible-xs actions-panel-container ">
        <div className="panel panel-default">
          <div className="actions-panel d-flex dropshadow-2">
            {selectedProducts.size > 0 && (
              <div className="selected-label pt-3 pr-2 pl-2">
                {selectedProducts.size} item{selectedProducts.size > 1 && 's'}
              </div>
            )}
            <div className="actions-icons p-2">
              {canPlaceOrder && (
                <Button
                  iconOnly
                  className="button-default medium mr-2"
                  title="Place an Order"
                  onClick={() => router.push('/hub/place-order')}
                >
                  <Icon icon="shoppingBag" />
                </Button>
              )}
              {selectedProducts.size === 1 && (
                <Link to="/hub/edit-product/start">
                  <Button iconOnly className="button-default medium mr-2" title="Edit">
                    <Icon icon="edit2" />
                  </Button>
                </Link>
              )}
              {canUnlink && (
                <Button
                  iconOnly
                  className="button-default medium mr-2"
                  title="Stop Fulfillment"
                  onClick={() => {
                    onOptionSelect(`Stop Fulfillment selected ${getGroupLabel()}`);
                    const subject = selectedProducts.size > 1 ? 'these products' : 'this product';
                    actionConfirmationModal.current.openModal(
                      `Are you sure you want to delete ${subject}?`,
                      () => {
                        unlinkProducts(selectedProducts);
                      }
                    );
                  }}
                >
                  <Icon icon="xSquare" />
                </Button>
              )}
              {canDelete && (
                <Button
                  iconOnly
                  className="button-default medium mr-2"
                  title="Delete"
                  onClick={() => {
                    onOptionSelect(`Delete product started ${getGroupLabel()}`);
                    const subject = selectedProducts.size > 1 ? 'these products' : 'this product';
                    actionConfirmationModal.current.openModal(
                      `Are you sure you want to delete ${subject}?`,
                      () => {
                        deleteProducts(selectedProducts);
                      }
                    );
                  }}
                >
                  <Icon icon="trash" />
                </Button>
              )}
              {canUnpublish && (
                <Button
                  iconOnly
                  className="button-default medium mr-2"
                  title="Mark as Unpublished"
                  onClick={() => {
                    onOptionSelect(`Mark as Unpublished selected ${getGroupLabel()}`);
                    unpublishProducts(selectedProducts);
                  }}
                >
                  <Icon icon="slash" />
                </Button>
              )}
              {canPublish && (
                <Button
                  iconOnly
                  className="button-default medium mr-2"
                  title="Mark as Published"
                  onClick={() => {
                    onOptionSelect(`Mark as Published selected ${getGroupLabel()}`);
                    publishProducts(selectedProducts);
                  }}
                >
                  <Icon icon="checkCircle" />
                </Button>
              )}
              {productUrlInStore && (
                <a href={productUrlInStore}>
                  <Button iconOnly className="button-default medium mr-2" title="View in Store">
                    <Icon icon="eye" />
                  </Button>
                </a>
              )}
            </div>
          </div>
        </div>
        <style jsx>
          {`
            .actions-panel-container {
              position: fixed;
              width: 100%;
              left: 0;
              bottom: 0;
              padding: 0px 1.5rem;
              z-index: 200;
            }
            .panel-default {
              border-radius: 4px;
            }
          `}
        </style>
      </div>
    ) : null;
  };
  return (
    <>
      <div className="actions-container d-flex justify-content-between w-100">
        <form className="pr-2 w-100">
          <Search router={router} />
        </form>
        <div className="d-flex">
          <div className="dropdown-wrapper hidden-sm hidden-xs">{renderViewByDropdown()}</div>
          <div className="dropdown-wrapper hidden-sm hidden-xs ml-2">{renderSortByDropdown()}</div>
          <div className="pl-2 d-flex display-switcher hidden-xs">{renderDisplaySwitcher()}</div>
        </div>
      </div>
      <ActionsLine router={router} items={actionLineOptions()} />
      {mobileScreenActionsPanel(canPlaceOrder, canDelete, canUnpublish, canPublish, canUnlink)}
      <ActionConfirmationModal ref={actionConfirmationModal} />
      <style jsx>
        {`
          :global(.actions-line) {
            border-bottom: ${showActionsBorder ? '1px solid #dedede' : 'none'};
            position: ${selectedProducts.size ? 'sticky' : 'initial'};
            z-index: 5;
            top: 0;
            background: ${COLORS.neutralLight50};
          }
          .display-switcher :global(.display-controls) {
            width: 5.25rem;
          }
          @media screen and (max-width: 767px) {
            .display-switcher {
              display: none;
            }
            .actions-container {
              position: initial;
            }
          }
        `}
      </style>
    </>
  );
};

Actions.propTypes = {
  router: PropTypes.object,
  viewBy: PropTypes.string,
  sortBy: PropTypes.string,
  display: PropTypes.string.isRequired,
  changeViewBy: PropTypes.func.isRequired,
  changeSortBy: PropTypes.func.isRequired,
  deleteProducts: PropTypes.func,
  unlinkProducts: PropTypes.func,
  publishProducts: PropTypes.func,
  unpublishProducts: PropTypes.func,
  productUrlInStore: PropTypes.string,
  availableOptions: PropTypes.object.isRequired,
  viewByOptions: PropTypes.instanceOf(List),
  changeDisplay: PropTypes.func.isRequired,
  selectedProducts: PropTypes.instanceOf(List).isRequired
};

export default Actions;
