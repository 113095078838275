import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PagedCollection from '../../../../../models/PagedCollection';
import ProductsListItem from './ProductListItem';
import Pager from 'gooten-components/src/components/shared/Pager';

import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';

export class ProductList extends Component {
  render() {
    return (
      <div className="product-list-container">
        {!this.props.products.get('items').size ? (
          <div className="col-xs-12 text-center">
            <h3 className="center-block">No Products to Show</h3>
          </div>
        ) : (
          <div className="sync-products-table mt-4">
            {this.renderHead()}
            {this.renderBody()}
          </div>
        )}
        {this.renderPager()}
      </div>
    );
  }

  renderHead() {
    return (
      <TableRow header size="medium" columns="minmax(5%, 20%) auto">
        <Cell />
        <Cell>
          <div className={this.props.desc ? 'dropup col-xs-6 text-wrap' : 'col-xs-6 text-wrap'}>
            Product Name
          </div>
          <div className="col-sm-2 hidden-xs text-center text-wrap">Synced variants</div>
          <div className="col-xs-6 visible-xs text-center text-wrap">Synced / Total variants</div>
          <div className="col-sm-2 hidden-xs text-center text-wrap">Total variants</div>
          <div className="col-sm-2 hidden-xs text-center text-wrap">View in store</div>
        </Cell>
        <style jsx>
          {`
            .text-wrap {
              white-space: normal;
              line-height: 1;
              word-break: break-word;
            }
          `}
        </style>
      </TableRow>
    );
  }

  renderBody() {
    return (
      <div className="mb-5">
        {this.props.products.get('items').map(p => (
          <ProductsListItem
            key={p.get('id')}
            product={p}
            provider={this.props.provider}
            select={selected => this.props.select(p, selected)}
            selected={
              this.props.selectedProduct && this.props.selectedProduct.get('id') === p.get('id')
            }
          />
        ))}
      </div>
    );
  }

  renderPager() {
    const totalPageSize = Math.ceil(this.props.products.get('total') / this.props.pageSize);

    const pages =
      this.props.products.get('items').size && totalPageSize
        ? Array.from(Array(totalPageSize).keys()).map(i => ({ name: i + 1, index: i + 1 }))
        : [];

    return (
      !!pages.length && (
        <div className="text-center">
          <Pager
            current={this.props.page}
            items={pages}
            goTo={index => this.props.changePage(index)}
          />
        </div>
      )
    );
  }
}

ProductList.propTypes = {
  products: PropTypes.instanceOf(PagedCollection).isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  changePage: PropTypes.func,
  selectedProduct: PropTypes.any,
  select: PropTypes.func,
  desc: PropTypes.bool,
  provider: PropTypes.string
};

export default ProductList;
