import { injectReducer } from '../../store/reducers';
import { injectSaga } from '../../store/sagas';
import SKUSelectionReducer from './SKUSelectionReducer';
import SKUSelectionSaga from './SKUSelectionSaga';
import SKUSelection from './SKUSelectionContainer';

const componentName = 'SKUSelection';

/* Sync Inject reducers and return Component */

export default store => {
  injectReducer(store, { key: componentName, reducer: SKUSelectionReducer });
  injectSaga(store, { key: componentName, saga: SKUSelectionSaga });

  return SKUSelection;
};

/*
  Async Inject reducers and return Component
  Assets for sku selection will be loaded only when component activated
*/

// export default (store) => ({
//   path : 'skuSelection',
//   /*  Async getComponent is only invoked when route matches   */
//   getComponent (nextState, cb) {
//     /*  Webpack - use 'require.ensure' to create a split point
//      and embed an async module loader (jsonp) when bundling   */
//
//     require.ensure([], (require) => {
//       /*  Webpack - use require callback to define
//        dependencies for bundling   */
//       const SKUSelectionComponent = require('./SKUSelectionContainer').default
//       const SKUSelectionReducer = require('./SKUSelectionReducer').default
//       // const SKUSelectionSaga = require('./SKUSelectionSaga').default
//
//       /*  Add the reducer to the store on key 'counter'  */
//       injectReducer(store, { key: componentName, reducer: SKUSelectionReducer })
//       // injectSaga(SKUSelectionSaga)
//
//       /*  Return getComponent   */
//       cb(null, SKUSelectionComponent)
//
//       /* Webpack named bundle   */
//     }, 'skuSelection')
//   }
// })
