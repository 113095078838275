import React from 'react';
import Config from 'gooten-components/src/config';

const PartnerTierHeader = () => {
  const getVimFaqKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return 'https://help.gooten.com/hc/en-us/articles/360048165912-VIM-FAQ';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/6202027418907-VIM-FAQ';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7615448444315-VIM-FAQ';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360048165912-VIM-FAQ';
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <p className="title-2 m-0">VIM</p>
          <p className="title-3">Merchant loyalty program</p>
        </div>
        <a className="m-0" href={getVimFaqKbLink()} target="_blank">
          <button className="px-3">FAQ</button>
        </a>
      </div>
      <style jsx>
        {`
          .title-2 {
            line-height: 120%;
            font-size: 25px;
            font-weight: 800;
            color: #1e2731 !important;
          }
          .title-3 {
            font-family: Avenir-Roman;
            color: #1e2731 !important;
          }
          a {
            height: max-content;
          }
          a button {
            padding-top: 9.5px;
            padding-bottom: 9.5px;
            font-family: Avenir-Heavy;
            border: 0;
            outline: 0;
            color: #373f49;
            font-size: 13px;
            text-decoration: none;
            text-transform: capitalize;
            border-radius: 4px;
          }
        `}
      </style>
    </>
  );
};

export default PartnerTierHeader;
