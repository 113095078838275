import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NeckLabels from '../Cards/NeckLabels';
import EmailServices from '../Cards/EmailServices';
import EmailsContent from '../Cards/EmailsContent';
import EmailCredentials from '../Cards/EmailCredentials';
import PackingSlipsAddOnComponent from '../Cards/PackingSlips/PackingSlipsAddOnComponent';
import Config from '../../config';

const Container = styled.div``;
class AddOnsView extends React.Component {
  render() {
    return (
      <Container>
        {Config.get('showPackingSlips') && <PackingSlipsAddOnComponent />}
        <NeckLabels />
        <EmailServices />
        <EmailsContent />
        <EmailCredentials />
      </Container>
    );
  }
}

AddOnsView.propTypes = {
  // eslint-disable-next-line
  router: PropTypes.object.isRequired
};

export default AddOnsView;
