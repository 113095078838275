import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UploadFromDevice from '../shared/UploadFromDevice';
import UploadFromUrl from '../shared/UploadFromUrl';
import { windowWidth } from '../../../ComponentsHooks';
import Modal from '../../../Modal';
import Icon from '../../../Icon';
import Button from '../../../Button';
import Toast from '../../../Toast';

const UploadImage = props => {
  const isSmallMobile = windowWidth() <= 480;
  const [error, setError] = useState(null);

  const onError = error => {
    setError(error);

    // dismiss error after 5 seconds
    setTimeout(() => setError(null), 5000);
  };

  return (
    <React.Fragment>
      {error && (
        <Toast isOpen className="upload-error">
          {error}
        </Toast>
      )}
      <Modal
        cancelClick={props.onClose}
        isOpen={props.isOpen}
        title="Upload Artwork"
        className="image-selection-upload-image"
        disableOutsideClose
      >
        {/* <NavigationBar title='My Artwork'
          showBackButton={props.showBack}
          onClose={props.onClose}
          onBack={props.onBack} /> */}
        <div className="pt-4 pr-4 pl-4">
          {props.showBack && (
            <Button className="button-default small mb-2" onClick={props.onBack}>
              <Icon small icon="arrowLeft" className="mr-2" /> Back to My Artwork
            </Button>
          )}
          <UploadFromDevice
            isSmallMobile={isSmallMobile}
            fileTypes={props.fileTypes}
            requiredImageSize={props.requiredImageSize}
            onError={error => onError(error)}
          />
          <UploadFromUrl
            fileTypes={props.fileTypes}
            requiredImageSize={props.requiredImageSize}
            onError={error => onError(error)}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

UploadImage.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  showBack: PropTypes.bool,
  isOpen: PropTypes.bool,
  fileTypes: PropTypes.string, // format: 'image/jpeg,image/png'
  requiredImageSize: PropTypes.array // format: [{ width: 140, height: 200 }]
};

export default UploadImage;
