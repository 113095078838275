import { createSelector } from 'reselect';
import {
  gootenProductsSelector,
  selectedGootenProductSelector
} from '../../../../../LinkProductSelectors';
import { sortBy } from 'gooten-components/src/utils/array';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import {
  regionsSelector,
  selectedRegionIndexSelector
} from './../../../../SKUsSelectionViewSelectors';

export const productsForSelectSelector = createSelector([gootenProductsSelector], products => {
  // TO-DO hardcoded for now, until we get sections from API...
  const firstSection = [{ key: -1, label: 'All Products', isSection: true }];

  let productsForSelect = products
    .filter(p => !p.get('out_of_stock'))
    .map(p => ({
      key: p.get('id'),
      label: p.get('name'),
      imageUrl: getSmallPreviewImageResizerUrl(p.get('image'))
    }))
    .toJS()
    .sort(sortBy('label'));

  // TO-DO hardcoded for now, until we get sections from API...
  return firstSection.concat(productsForSelect);
});

export const selectedProductsForSelectSelector = createSelector(
  [productsForSelectSelector, selectedGootenProductSelector],
  (products, selectedProduct) =>
    products.filter(p => {
      return selectedProduct && p.key === selectedProduct.get('id');
    })
);

export const subCategoriesForSelectSelector = createSelector(
  [regionsSelector, selectedRegionIndexSelector],
  (regions, selectedRegionIndex) => {
    if (!regions || !regions.size || !regions.get(selectedRegionIndex).get('sub-categories')) {
      return [];
    }

    return regions
      .get(selectedRegionIndex)
      .get('sub-categories')
      .map((c, i) => ({
        key: i,
        label: c.get('name'),
        imageUrl:
          c.get('images') && c.get('images').size
            ? getSmallPreviewImageResizerUrl(c.get('images').first().get('url'))
            : ''
      }))
      .toJS();
  }
);
