import { fromJS, List, Map } from 'immutable';
import {
  CREATE_NECKTAG,
  FETCH_NECKTAGS,
  SELECT_NECKTAG,
  NECKTAG_GENERATION_ERROR
} from './NeckLabelsActions';

import NeckLabel from '../../../../models/NeckLabel';
import { RESET_PUBLISH_DATA } from '../../ProductPublishActions';

const NeckLabelsReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_NECKTAGS.SUCCESS: {
      const state = new Map();
      return state.update(
        'neckLabelList',
        neckLabelList => new List(action.payload.neckTags.map(x => new NeckLabel(x)))
      );
    }
    case CREATE_NECKTAG.SUCCESS: {
      return state.update('neckLabelList', neckLabelList =>
        neckLabelList.push(new NeckLabel(action.payload.neckTag))
      );
    }
    case CREATE_NECKTAG.FAIL:
    case FETCH_NECKTAGS.FAIL:
    case NECKTAG_GENERATION_ERROR: {
      return state.set('error', action.payload.err);
    }
    case SELECT_NECKTAG: {
      const newNeckLabel = state
        .get('neckLabelList')
        .find(x => x.get('id') === parseInt(action.payload.neckTagId));
      return state.update('selectedNeckLabel', selectedNeckLabel => newNeckLabel);
    }
    case RESET_PUBLISH_DATA:
      return null;
    default:
      return state;
  }
};

export default NeckLabelsReducer;
