import { takeLatest, call, put, all } from 'redux-saga/effects';
import countrySelectionService from './CountrySelectionService';
import {
  fetchAsyncSuccess,
  fetchAsyncFail,
  FETCH_COUNTRIES
} from '../../../store/actions/dataActions';

export function* loadCountriesRequestHandler(action) {
  // Call CountrySelectionService to load shipping countries
  try {
    const countries = yield call([countrySelectionService, countrySelectionService.loadCountries]);
    // dispatch async success action with type FETCH_COUNTRIES and pass countries data
    yield put(fetchAsyncSuccess(FETCH_COUNTRIES, { countries }));
  } catch (err) {
    // dispatch async error action with type FETCH_COUNTRIES and pass error data
    yield put(fetchAsyncFail(FETCH_COUNTRIES, err));
    throw err;
  }
}

export function* watchLoadCountriesRequest() {
  // Watch when FETCH_COUNTRIES.ASYNC got dispatched
  yield takeLatest(FETCH_COUNTRIES.ASYNC, loadCountriesRequestHandler);
}

export default function* rootSaga() {
  yield all([watchLoadCountriesRequest()]);
}
