import { Record, List } from 'immutable';
import camelcaseKeysDeep from 'camelcase-keys-deep';

// Hold data for 1 product prp preconfiguration
// prp.Data.Items[0]
const ProductPreconfiguration = new Record({
  productId: '', // gooten product id
  sku: '', // gooten unique product sku
  preconfigurations: new List() // spaceId, url, il
});

export default ProductPreconfiguration;

const sanitizeIl = il => {
  // In net-api these are strings - need to be parsed to numbers
  if (il.Final) {
    il.Final.X1 = parseFloat(il.Final.X1);
    il.Final.X2 = parseFloat(il.Final.X2);
    il.Final.Y1 = parseFloat(il.Final.Y1);
    il.Final.Y2 = parseFloat(il.Final.Y2);
  }
  il.Height = parseFloat(il.Height);
  il.Width = parseFloat(il.Width);
  il.Left = parseFloat(il.Left);
  il.Top = parseFloat(il.Top);
  il.Layers.forEach(l => {
    if (l.Images) {
      l.Images.forEach(i => {
        i.CenterX = parseFloat(i.CenterX);
        i.CenterY = parseFloat(i.CenterY);
        i.Height = parseFloat(i.Height);
        i.Left = parseFloat(i.Left);
        i.RealSourceHeight = parseFloat(i.RealSourceHeight);
        i.RealSourceWidth = parseFloat(i.RealSourceWidth);
        i.Rotation = parseFloat(i.Rotation);
        i.ScaleDx = parseFloat(i.ScaleDx);
        i.SourceHeight = parseFloat(i.SourceHeight);
        i.SourceWidth = parseFloat(i.SourceWidth);
        i.Top = parseFloat(i.Top);
        i.Width = parseFloat(i.Width);
        i.X1 = parseFloat(i.X1);
        i.X2 = parseFloat(i.X2);
        i.Y1 = parseFloat(i.Y1);
        i.Y2 = parseFloat(i.Y2);
        if (i.Viewport) {
          i.Viewport.Height = parseFloat(i.Viewport.Height);
          i.Viewport.Width = parseFloat(i.Viewport.Width);
          i.Viewport.X1 = parseFloat(i.Viewport.X1);
          i.Viewport.X2 = parseFloat(i.Viewport.X2);
          i.Viewport.Y1 = parseFloat(i.Viewport.Y1);
          i.Viewport.Y2 = parseFloat(i.Viewport.Y2);
        }
      });
    }
  });
  return il;
};

const mapPreconfigurations = obj => {
  if (obj.preconfigurations) {
    return new List(
      obj.preconfigurations.map(p => ({
        url: p.url,
        spaceId: p.space_id,
        il: p.il ? camelcaseKeysDeep(p.il) : null,
        imageId: p.image_id
      }))
    );
  } else if (obj.Preconfigurations) {
    return new List(
      obj.Preconfigurations.map(p => ({
        url: p.Url,
        spaceId: p.SpaceId,
        il: p.Il ? camelcaseKeysDeep(sanitizeIl(p.Il)) : null
      }))
    );
  }
};

export const mapProductPreconfiguration = obj => {
  return new ProductPreconfiguration({
    productId: obj.product_id || obj.ProductId,
    sku: obj.product_variant_sku || obj.ProductVariantSku,
    preconfigurations: mapPreconfigurations(obj)
  });
};
