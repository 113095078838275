import React from 'react';
import PropTypes from 'prop-types';
import Strings from './strings';
import Button from '../../../../shared/Button';

class AddressItem extends React.PureComponent {
  stopEventPropagation = e => e.stopPropagation();

  render() {
    // firstName: 'Test',
    // lastName: 'Partner',
    // addressLine1: '416 Park Ave S',
    // addressLine2: '',
    // city: 'New York',
    // state: 'NY',
    // country: 'US',
    // zipCode: '10016',
    // number: '+1234567890',
    // email: 'test@partner.com'

    let address = this.props.address;

    let showEditButton = !!this.props.onClickEdit;
    let showDeleteButton = !!this.props.onClickDelete;

    return (
      <div className="address-item d-flex">
        <div className="address-item-content mb-3">
          <div className="top-line">
            <div className="first-name">
              {address.get('firstName')} {address.get('lastName')}
            </div>
            <div>
              {address.get('addressLine1') ? address.get('addressLine1') : null}&nbsp;
              {address.get('addressLine2') ? address.get('addressLine2') : null}&nbsp;
              {address.get('city') ? address.get('city') : null},&nbsp;
              {address.get('state') ? address.get('state') : null}&nbsp;
              {address.get('zipCode') ? address.get('zipCode') : null}
            </div>
          </div>
          <div className="caption-text heavy">
            {address.get('number') ? address.get('number') : null}&nbsp;
            {address.get('email') ? address.get('email') : null}
          </div>
        </div>
        <div className="buttons mb-4" onClick={this.stopEventPropagation}>
          {showEditButton ? (
            <Button
              className="button-default small mr-2 ml-0"
              onClick={() => this.props.onClickEdit(address)}
            >
              {Strings.EDIT}
            </Button>
          ) : null}
          {showDeleteButton ? (
            <Button
              className="button-default small mr-2 ml-0"
              onClick={() => this.props.onClickDelete(address)}
            >
              {Strings.DELETE}
            </Button>
          ) : null}
        </div>
        <style jsx>
          {`
            .address-item {
              flex-wrap: wrap;
              justify-content: space-between;
            }
            .address-item-content {
              width: calc(100% - 9rem);
              word-break: break-word;
            }
            .buttons {
              width: 9rem;
            }
            @media screen and (max-width: 992px) {
              .address-item-content {
                width: 100%;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

AddressItem.propTypes = {
  address: PropTypes.object,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
};

export default AddressItem;
