import React from 'react';
import PropTypes from 'prop-types';
import BaseCard from '../BaseCard';
import Button from 'gooten-components/src/components/shared/Button';
import Config from 'gooten-components/src/config';

class TaxExemption extends React.Component {
  static get name() {
    return 'taxExemption';
  }
  static get messages() {
    return { onInitFailure: 'Failed to load TaxExemption Information' };
  }

  componentDidMount() {
    this.props.init();
  }

  get canUpdate() {
    return this.props.ready;
  }

  getSalesTaxExemptionsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360049091292-Setting-up-Sales-Tax-Exemptions';

    switch (partnerGroup.get('Id')) {
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7613715817883-Setting-up-Sales-Tax-Exemptions';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360049091292-Setting-up-Sales-Tax-Exemptions';
    }
  };

  render() {
    const button =
      this.props.data && this.props.data.URL ? (
        <a href={this.props.data.URL} target="_blank" rel="noopener noreferrer">
          <Button className="button-default large" iconRight="arrowUpRight">
            Manage certificates
          </Button>
        </a>
      ) : null;

    const message =
      this.props.data && this.props.data.HasError ? (
        <p>Enter your business address information above, before continuing.</p>
      ) : (
        <p>
          Create and manage exemption certificates. Etsy accounts require additional setup steps.
          &nbsp;
          <a className="settings-link" href={this.getSalesTaxExemptionsKbLink()} target="_blank">
            Learn more.
          </a>
        </p>
      );

    return (
      <BaseCard card={TaxExemption.name} title="Sales Tax Exemption">
        <div className="row">
          <div className="col-sm-10 col-xs-7">{message}</div>
        </div>

        <div className="row">
          <div className="col-sm-10 col-xs-12 cart-buttons-block">{button}</div>
        </div>
      </BaseCard>
    );
  }
}

TaxExemption.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.object,
  ready: PropTypes.bool
};

export default TaxExemption;
