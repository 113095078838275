import React from 'react';
import PropTypes from 'prop-types';
import Strings from './strings';
import Button from '../../../../../shared/Button';
import Modal from '../../../../../shared/Modal';

class AddressConfirmation extends React.PureComponent {
  render() {
    let body = null;
    if (this.props.suggestedAddress) {
      const addressState = this.props.suggestedAddress.get('state');
      body = (
        <div className="address-confirmation-modal-container">
          <div className="address-confirmation-modal-body">
            <div className="addresses-container p-4">
              <div className="address-container border-bottom">
                <div className="title-3">{Strings.SUGGESTED_ADDRESS}</div>
                <div className="shorter-line">
                  {this.props.suggestedAddress.get('addressLine1')}
                </div>
                <div>{this.props.suggestedAddress.get('addressLine2')}</div>
                <div>
                  {this.props.originalAddress.get('firstName')}&nbsp;
                  {this.props.originalAddress.get('lastName')}
                </div>
                <div>
                  {this.props.suggestedAddress.get('city')}
                  {addressState ? `, ${addressState}` : ''},
                  {this.props.suggestedAddress.get('zipCode')}
                </div>
                <div>{this.props.suggestedAddress.get('country')}</div>
                <Button
                  className="button-primary large mt-3"
                  onClick={this.props.onClickUseSuggested}
                >
                  {Strings.USE_SUGGESTED_ADDRESS}
                </Button>
              </div>
              <hr className="medium" />
              <div className="address-container">
                <div className="title-3">{Strings.ORIGINAL_ADDRESS}</div>
                <div className="shorter-line">{this.props.originalAddress.get('addressLine1')}</div>
                <div>{this.props.originalAddress.get('addressLine2')}</div>
                <div>
                  {this.props.originalAddress.get('firstName')}&nbsp;
                  {this.props.originalAddress.get('lastName')}
                </div>
                <div>
                  {this.props.originalAddress.get('city')}
                  {addressState ? `, ${addressState}` : ''},
                  {this.props.originalAddress.get('zipCode')}
                </div>
                <div>{this.props.originalAddress.get('country')}</div>
                <Button className="button-primary large mt-3" onClick={this.props.onClickIgnore}>
                  {Strings.IGNORE}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        cancelClick={this.props.modalClose}
        title={Strings.DID_YOU_MEAN_THIS_ADDRESS}
        cancelText="Go back"
        className="small"
      >
        {body}
      </Modal>
    );
  }
}

AddressConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  suggestedAddress: PropTypes.object,
  originalAddress: PropTypes.object,
  onClickUseSuggested: PropTypes.func.isRequired,
  onClickIgnore: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired
};

export default AddressConfirmation;
