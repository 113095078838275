import React from 'react';
import { COLORS } from 'gooten-components/src/constants';
import Config from 'gooten-components/src/config';

const ReportInfo = () => {
  const getProductionReportKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return 'https://help.gooten.com/hc/en-us/articles/360057459971';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521609270811-Fujifilm-Production-Report';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7613783422875-Sensaria-Production-Report';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360057459971';
    }
  };

  return (
    <>
      <div className="report-info w-100 mt-3 p-4">
        <div className="report-info-content p-3">
          <span className="body-text-2">
            As you process orders fulfilled by Gooten, this will begin to populate with production
            data.&nbsp;
            <a className="link" href={getProductionReportKbLink()} target="_blank">
              Learn more
            </a>
          </span>
        </div>
      </div>
      <style jsx>{`
        .report-info {
          border-radius: 6px;
          border: 1px solid ${COLORS.neutralLight300};
        }

        .report-info-content {
          background-color: ${COLORS.neutralLight300};
          border-radius: 6px;
        }
      `}</style>
    </>
  );
};

export default ReportInfo;
