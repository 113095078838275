import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../shared/Checkbox';

const Draft = props => {
  return (
    <div className="d-flex">
      <Checkbox
        onChange={() => props.toogleDraftState(props.storeId, !props.selected)}
        disabled={props.provider.toLowerCase() === 'tiktok'}
        label={
          props.provider.toLowerCase() === 'shopify'
            ? 'Publish as hidden product'
            : 'Push to store as draft'
        }
        checked={props.selected}
        className="w-100"
      />
      <style jsx>
        {`
          div {
            position: relative;
          }
        `}
      </style>
    </div>
  );
};

Draft.propTypes = {
  selected: PropTypes.bool,
  storeId: PropTypes.number,
  toogleDraftState: PropTypes.func,
  provider: PropTypes.any
};

export default Draft;
