import { connect } from 'react-redux';
import EditDesignView from './EditDesignView';
import { loadingStatusSelector, hasRequiredProductInfoSelector } from './EditDesignViewSelectors';
import { updateLoadingStatus } from './EditDesignViewActions';

const mapDispatchToProps = {
  updateLoadingStatus: status => updateLoadingStatus(status)
};

const mapStateToProps = (state, ownProps) => {
  return {
    loadingStatus: loadingStatusSelector(state),
    hasRequiredProductInfo: hasRequiredProductInfoSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDesignView);
