import { connect } from 'react-redux';
import Products from './Products';
import {
  selectedProductsSelector,
  displaySelector,
  currentPageSelector,
  currentPageSizeSelector,
  totalPagesCountSelector,
  productsQuerySelector,
  productsQueryDataSelector,
  pendingProductsSelector,
  storeToCheckLinkSelector,
  isUpdatingSelector,
  currentProviderSelector,
  notificationSelector,
  currentQueryProductsSelector,
  shopifyRedirectRequiredStoresSelector
} from './ProductsSelectors';
import {
  productSelect,
  productSelectById,
  productSelectExclusive,
  productUnselect,
  pageChange,
  pageSizeChange,
  queryLoad,
  showNotification
} from './ProductsActions';
import {
  viewByChange,
  sortByChange,
  unselectAll,
  unpublishProducts,
  publishProducts,
  deleteProducts,
  unlinkProducts,
  fetchShopifyTokenScopes
} from '../Actions/ActionsActions';
import { refreshStoreData } from '../Stores/StoresActions';

const mapDispatchToProps = {
  selectProduct: product => productSelect(product),
  selectProductById: productId => productSelectById(productId),
  selectExclusive: product => productSelectExclusive(product),
  unselectProduct: product => productUnselect(product),
  unselectAll: () => unselectAll(),
  changePage: page => pageChange(page),
  changesortBy: sortBy => sortByChange(sortBy),
  changeViewBy: viewBy => viewByChange(viewBy),
  changePageSize: pageSize => pageSizeChange(pageSize),
  refreshStoreData: store => refreshStoreData(store),
  loadProductsQuery: query => queryLoad(query),
  unpublishProducts: products => unpublishProducts(products),
  publishProducts: products => publishProducts(products),
  deleteProducts: products => deleteProducts(products),
  unlinkProducts: products => unlinkProducts(products),
  showNotification: payload => showNotification(payload),
  fetchShopifyTokenScopes: payload => fetchShopifyTokenScopes(payload)
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedProducts: selectedProductsSelector(state),
    isUpdating: isUpdatingSelector(state),
    display: displaySelector(state),
    currentPage: currentPageSelector(state),
    currentPageSize: currentPageSizeSelector(state),
    totalPagesCount: totalPagesCountSelector(state),
    productsQuery: productsQuerySelector(state),
    productsQueryData: productsQueryDataSelector(state),
    storeToCheckLink: storeToCheckLinkSelector(state),
    pendingProducts: pendingProductsSelector(state),
    currentProvider: currentProviderSelector(state),
    notification: notificationSelector(state),
    currentQueryProducts: currentQueryProductsSelector(state),
    shopifyRedirectRequiredStores: shopifyRedirectRequiredStoresSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
