import { Rect, FastLayer } from 'konva';
import { SurfaceLayer, KonvaStage } from '../../flow/konvaTypes';

/*
* Simple color layer
*/
export default class BackgroundSurfaceLayer implements SurfaceLayer {

  id: String;
  layer: FastLayer;
  color: String;
  savedSize: String;

  constructor(id: string) {
    this.id = id;
    this.layer = new FastLayer();
    this.layer.getCanvas()._canvas.id = id;
    this.savedSize = '';
  }

  addToCanvas(stage: KonvaStage) {
    stage.add(this.layer);
  }

  draw(ctx: any) {
    // redraw layer only when color changes, and when container size changes
    if (ctx.state.editor.containerColor === this.color &&
      this.savedSize === ctx.stage.attrs.width + ctx.stage.attrs.height + '') {
      return;
    }

    this.color = ctx.state.editor.containerColor || 'white';
    this.savedSize = ctx.stage.attrs.width + ctx.stage.attrs.height + '';
    this.layer.destroyChildren();
    this.layer.clear();

    let rect = new Rect({
      x: 0,
      y: 0,
      width: ctx.stage.attrs.width,
      height: ctx.stage.attrs.height,
      fill: this.color
    });

    this.layer.add(rect);
    
    ctx.stage.on('dragmove', () => {
      ctx.stage.container().style.backgroundColor = this.color;
    });

    this.layer.batchDraw();
  }
}
