import { createSelector } from 'reselect';

const historyViewSelector = state => state.getIn(['orders', 'historyView']);

export const historyViewData = createSelector(
  [historyViewSelector],
  historyViewStore => historyViewStore
);
export const filterData = createSelector([historyViewSelector], historyViewStore =>
  historyViewStore.get('filters')
);
