import { connect } from 'react-redux';
import ProductPanel from './ProductPanel';
import { gotoGroup, setCurrentImage } from '../../SKUSelectionActions';
import { getProductDetails } from '../../SKUSelectionSelectors';

const mapDispatchToProps = {
  gotoGroup: groupId => gotoGroup(groupId),
  setCurrentImage: i => setCurrentImage(i)
};

const mapStateToProps = state => {
  return {
    productDetails: getProductDetails(state),
    currentImage: state.get('SKUSelection').get('currentImage')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPanel);
