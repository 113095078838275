import PartnerTier from './PartnerTier';
import { connect } from 'react-redux';
import Config from '../../../config';
import { PartnerTierModel } from '../../Models/PartnerTierModel';

const mapStateToProps = state => {
  const partnerTierSettings = PartnerTierModel(Config.get('partnerTierSettings'));
  return {
    data: partnerTierSettings.toJS(),
    showVimSettings:
      !Config.get('isOLMPartner') &&
      !Config.get('isPartnerAdmin') &&
      !Config.get('isFutureShirtsPartner')
  };
};

export default connect(mapStateToProps, null)(PartnerTier);
