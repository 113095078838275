import css from 'styled-jsx/css';
import { COLORS } from './constants.js';

// Global styles. Import at top level apps.
export const mainStyles = css.global`
  .dropshadow-1 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.04);
  }
  .dropshadow-2 {
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.04);
  }
  .dropshadow-3 {
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.04);
  }

  .title-1 {
    font-family: Avenir-Heavy, Avenir-Roman, Avenir, sans-serif;
    font-weight: 400;
    font-size: 1.5625rem;
    color: ${COLORS.neutralDark600};
  }
  .title-2 {
    font-family: Avenir-Heavy, Avenir-Roman, Avenir, sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    color: ${COLORS.neutralDark600};
  }
  .title-3 {
    font-family: Avenir-Heavy, Avenir-Roman, Avenir, sans-serif;
    font-weight: 400;
    font-size: 0.9375rem;
    color: ${COLORS.neutralDark600};
  }

  .body-text-1 {
    font-family: Avenir-Roman, Avenir, sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 175%;
    color: ${COLORS.neutralDark500};
    font-weight: 500;
  }
  .body-text-2 {
    font-family: Avenir-Roman, Avenir, sans-serif;
    font-size: 0.9375rem;
    line-height: 160%;
    color: ${COLORS.neutralDark500};
    font-weight: 500;
  }
  .caption-text {
    font-family: Avenir-Roman, Avenir, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 156%;
    color: ${COLORS.neutralDark200};
    letter-spacing: 0.15px;
  }
  .button-2 {
    font-family: Avenir-Roman, Avenir, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.8125rem;
    line-height: 185%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${COLORS.neutralDark200};
  }
  .button-1 {
    font-family: Avenir-Roman, Avenir, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.8125rem;
    line-height: 160%;
    letter-spacing: 0.03em;
    color: ${COLORS.neutralDark200};
  }
  .overline {
    font-family: Avenir Heavy, Avenir-Roman, Avenir, sans-serif;
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
    color: ${COLORS.neutralDark200};
  }
  .fine-print {
    font-family: Avenir-Roman, Avenir, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6875rem;
    line-height: 160%;
    color: ${COLORS.neutralDark200};
  }
  .link,
  a.link,
  .link a {
    text-decoration: underline !important;
    color: ${COLORS.neutralDark500} !important;
    cursor: pointer;
  }

  .heavy {
    font-weight: 800;
  }
  .text-tight {
    line-height: 150%;
  }

  hr.micro {
    position: relative;
    border: none;
    height: 1px;
    background: ${COLORS.neutralLight200};
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  hr.small {
    position: relative;
    border: none;
    height: 1px;
    background: ${COLORS.neutralLight200};
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  hr.medium {
    position: relative;
    border: none;
    height: 1px;
    background: ${COLORS.neutralLight200};
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  hr.large {
    position: relative;
    border: none;
    height: 1px;
    background: ${COLORS.neutralLight200};
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .w-25 {
    width: 25% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-auto {
    width: auto !important;
  }

  .h-25 {
    height: 25% !important;
  }

  .h-50 {
    height: 50% !important;
  }

  .h-75 {
    height: 75% !important;
  }

  .h-100 {
    height: 100% !important;
  }

  .h-auto {
    height: auto !important;
  }

  .mw-100 {
    max-width: 100% !important;
  }

  .mh-100 {
    max-height: 100% !important;
  }

  .min-vw-100 {
    min-width: 100vw !important;
  }

  .min-vh-100 {
    min-height: 100vh !important;
  }

  .vw-100 {
    width: 100vw !important;
  }

  .vh-100 {
    height: 100vh !important;
  }

  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0);
  }

  .m-0 {
    margin: 0 !important;
  }

  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }

  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }

  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }

  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }

  .m-1 {
    margin: 0.25rem !important;
  }

  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }

  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }

  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }

  .m-2 {
    margin: 0.5rem !important;
  }

  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }

  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }

  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }

  .m-3 {
    margin: 1rem !important;
  }

  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }

  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }

  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }

  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }

  .m-4 {
    margin: 1.5rem !important;
  }

  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }

  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }

  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }

  .m-5 {
    margin: 3rem !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }

  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }

  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }

  .m-6 {
    margin: 5rem !important;
  }

  .mt-6,
  .my-6 {
    margin-top: 5rem !important;
  }

  .mr-6,
  .mx-6 {
    margin-right: 5rem !important;
  }

  .mb-6,
  .my-6 {
    margin-bottom: 5rem !important;
  }

  .ml-6,
  .mx-6 {
    margin-left: 5rem !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }

  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }

  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }

  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }

  .p-1 {
    padding: 0.25rem !important;
  }

  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }

  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }

  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }

  .p-2 {
    padding: 0.5rem !important;
  }

  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }

  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }

  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }

  .p-3 {
    padding: 1rem !important;
  }

  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }

  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }

  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }

  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }

  .p-4 {
    padding: 1.5rem !important;
  }

  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }

  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }

  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }

  .p-5 {
    padding: 3rem !important;
  }

  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }

  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }

  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }

  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }

  .m-n1 {
    margin: -0.25rem !important;
  }

  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-n1,
  .mx-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-n1,
  .mx-n1 {
    margin-left: -0.25rem !important;
  }

  .m-n2 {
    margin: -0.5rem !important;
  }

  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-n2,
  .mx-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-n2,
  .mx-n2 {
    margin-left: -0.5rem !important;
  }

  .m-n3 {
    margin: -1rem !important;
  }

  .mt-n3,
  .my-n3 {
    margin-top: -1rem !important;
  }

  .mr-n3,
  .mx-n3 {
    margin-right: -1rem !important;
  }

  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-n3,
  .mx-n3 {
    margin-left: -1rem !important;
  }

  .m-n4 {
    margin: -1.5rem !important;
  }

  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem !important;
  }

  .m-n5 {
    margin: -3rem !important;
  }

  .mt-n5,
  .my-n5 {
    margin-top: -3rem !important;
  }

  .mr-n5,
  .mx-n5 {
    margin-right: -3rem !important;
  }

  .mb-n5,
  .my-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-n5,
  .mx-n5 {
    margin-left: -3rem !important;
  }

  .m-auto {
    margin: auto !important;
  }

  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }

  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }

  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }

  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }

  .d-none {
    display: none !important;
  }

  .d-inline {
    display: inline !important;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .d-block {
    display: block !important;
  }

  .d-table {
    display: table !important;
  }

  .d-table-row {
    display: table-row !important;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .position-static {
    position: static !important;
  }

  .position-relative {
    position: relative !important;
  }

  .position-absolute {
    position: absolute !important;
  }

  .position-fixed {
    position: fixed !important;
  }

  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-column {
    flex-direction: column;
  }

  .no-text-decoration,
  .no-text-decoration:hover,
  a.no-text-decoration,
  .no-text-decoration a:hover {
    text-decoration: none !important;
    color: inherit !important;
  }
  .ellipsis-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-align-left {
    text-align: left !important;
  }
`;
