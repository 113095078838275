import React, { useState, useEffect } from 'react';
import BaseCard from '../BaseCard/BaseCard';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import { postRecipeAddOns, getRecipeAddOns } from './PackingSlipService';
import Loader from 'gooten-components/src/components/shared/Loader';
import Config from 'gooten-components/src/config';
import { connect } from 'react-redux';
import { setToast } from './PackingSlipsActions';

const PackingSlipsAddOnComponent = props => {
  const [packingSlipData, setPackingSlipData] = useState();
  const [addOnId, setAddOnId] = useState();
  const [disabled, setDisabled] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      // Fetch data
      const addOns = await getRecipeAddOns();

      if (addOns.length && addOns[0].Id) {
        setAddOnId(addOns[0].Id);
        setDisabled(addOns[0].IsDisabled);
        setPackingSlipData(JSON.parse(addOns[0].SettingsJSON));
      }

      setLoading(false);
    } catch (error) {
      props.setToast(`There was a problem fetching the packing slip data`);
      setLoading(false);
    }
  };

  const toggleDisabled = async () => {
    try {
      await postRecipeAddOns({
        Id: addOnId,
        IsDisabled: !disabled,
        Name: 'Packing Slip',
        Type: 1,
        SettingsJSON: JSON.stringify(packingSlipData)
      });
      props.setToast(`Packing slip add-on ${disabled ? 'enabled' : 'disabled'}`);
      setDisabled(!disabled);
    } catch (error) {
      props.setToast(`There was a problem enabling/disabling packing slips`);
    }
  };

  const navigateToAddOn = () => {
    window.location.hash = '#/settings/add-ons/packingslips';
  };

  const getCustomizedPackingSlipsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return 'https://help.gooten.com/hc/en-us/articles/360056780691';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521537337499-Customized-Packing-Slips';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612837861659-Customized-Packing-Slips';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360056780691';
    }
  };

  return (
    <BaseCard isOpen title="Packing Slip">
      <div>
        <div className="mb-4">
          Create a branded{' '}
          <a href={getCustomizedPackingSlipsKbLink()} target="_blank">
            packing slip
          </a>{' '}
          by including your logo, support info, and a short message. Even if a branded packing slip
          isn’t available, there won’t be any Gooten or vendor branding on the package.
        </div>
        {packingSlipData ? (
          <>
            <Button
              className="button-default large add-new-necklabel mr-3"
              onClick={() => navigateToAddOn()}
            >
              <span>Edit</span>
            </Button>
            <Button
              className="button-default large add-new-necklabel"
              onClick={() => toggleDisabled()}
            >
              <span>{disabled ? 'Enable' : 'Disable'}</span>
            </Button>
          </>
        ) : (
          <Button
            className="button-default large add-new-necklabel"
            onClick={() => navigateToAddOn()}
          >
            <Icon icon="plusCircle" />
            <span>Create new </span>
          </Button>
        )}
        {loading && <Loader />}
      </div>
    </BaseCard>
  );
};

const mapDispatchToProps = {
  setToast: message => setToast(message)
};

export default connect(null, mapDispatchToProps)(PackingSlipsAddOnComponent);
