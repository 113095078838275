import { connect } from 'react-redux';
import AdvancedSettings from './AdvancedSettings';
import ProfileInformation from '../ProfileInformation/ProfileInformation';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { changeFor } from '../BaseCard/BaseCardActions';
import { deactivate, downloadData } from './AdvancedSettingsActions';

const cardSelector = cardSelectorFor(AdvancedSettings.name);
const profileInformationSelector = cardSelectorFor(ProfileInformation.name);
const change = changeFor(AdvancedSettings.name);

const mapDispatchToProps = {
  change,
  deactivate,
  downloadData
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    profileInformation: profileInformationSelector(state).get('data'),
    isPristine: cardState.get('isPristine'),
    canDeactivate: cardState.get('canDeactivate'),
    canDownloadData: cardState.get('canDownloadData')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings);
