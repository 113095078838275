import braintree from 'braintree-web';

export const tokenizeCard = (cardData, token) => {
  return new Promise((resolve, reject) => {
    const client = new braintree.api.Client({ clientToken: token });
    client.tokenizeCard(
      {
        number: cardData.number,
        cardholderName: cardData.name,
        expirationMonth: cardData.month,
        expirationYear: cardData.year,
        cvv: cardData.cvv,
        billingAddress: {
          streetAddress: cardData.billingAddress.line1 || '',
          extendedAddress: cardData.billingAddress.line2 || '',
          locality: cardData.billingAddress.city || '',
          region: cardData.billingAddress.state || '',
          postalCode: cardData.billingAddress.postalCode || '',
          countryCodeAlpha2: (countryCode => {
            switch (countryCode) {
              case 'UK':
                return 'GB';
              default:
                return countryCode;
            }
          })(cardData.billingAddress.country)
        }
      },
      function (err, nonce) {
        if (err) {
          reject(err);
        } else {
          resolve({ nonce });
        }
      }
    );
  });
};
