import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../shared/Modal';
import FileUpload from 'react16-fileupload';
import ReactDOMServer from 'react-dom/server';
import DropzoneComponent from 'react-dropzone-component';
import { COLORS } from '../../../../../constants';
import { truncate } from '../../../../../utils/strings';
import Icon from '../../../../shared/Icon';
import Button from '../../../../shared/Button';
import { Set } from 'immutable';
import { getImageDimensions } from '../../../../../utils/image';
import { dstStitchCountPattern } from '../../../../../utils/regex';

/**
 * This component has to be written as class component, in order to
 * support 'react16-fileupload' and 'react-dropzone-component'
 */
class EmbroideryDSTUpload extends PureComponent {
  // selected files holder
  state = {
    png: null,
    dst: null,
    pdf: null,
    stitchCount: null,
    error: Set()
  };

  componentDidMount() {
    const zones = document.getElementsByClassName('emb-dropdown-zone');
    for (const zone of zones) {
      zone.addEventListener('dragenter', this.dragEffectEnter, false);
      zone.addEventListener('dragleave', this.dragEffectLeave, false);
      zone.addEventListener('drop', this.dragEffectLeave, false);
    }
  }

  componentWillUnmount() {
    const zones = document.getElementsByClassName('emb-dropdown-zone');
    for (const zone of zones) {
      zone.removeEventListener('dragenter', this.dragEffectEnter);
      zone.removeEventListener('dragleave', this.dragEffectLeave);
      zone.removeEventListener('drop', this.dragEffectLeave);
    }
  }

  checkFileRequirements(file, type, onSuccess, onFailure) {
    switch (type) {
      case 'png':
        getImageDimensions(file).then(val => {
          if (
            this.props.requiredPNGSize &&
            this.props.requiredPNGSize.some(r => val.width === r.width && val.height === r.height)
          ) {
            onSuccess();
          } else {
            onFailure();
          }
        });
        break;
      case 'dst':
        // parse stitch count from file...
        file.text().then(t => {
          const stitch = t.match(dstStitchCountPattern);
          const count = stitch && stitch[1];
          if (count) {
            // we have valid stitch file, set stitch count, and remove stitch_count error if exist...
            this.setState({
              stitchCount: parseInt(count),
              error: this.state.error.delete('stitch_count')
            });
            onSuccess();
          } else {
            // we have valid file type, but stitch_count is missing in file,
            // remove all other DST errors, and show only stitch count error...
            this.setState({ error: this.state.error.delete('dst') });
            onFailure('stitch_count');
          }
        });
        break;
      default:
        onSuccess();
        break;
    }
  }

  // checks if all 3 files are selected...
  canUpload = () => this.state.png && this.state.dst && this.state.pdf;

  dragEffectEnter = e => {
    if (e.target.className.indexOf('emb-dropdown-zone') !== -1) {
      e.target.style.backgroundColor = COLORS.neutralLight400;
    }
  };

  dragEffectLeave = e => {
    if (e.target.className.indexOf('emb-dropdown-zone') !== -1) {
      e.target.style.backgroundColor = COLORS.neutralLight100;
    }
  };

  handleCancelClick = () => this.props.handleCancel();

  handleUploadClick = () =>
    this.props.uploadFiles(
      Object.values(this.state)
        // exclude 'error' (Set) and stitch count
        .filter(v => !Set.isSet(v) && !Number.isInteger(v))
        // map stitch count to DST file
        .map(f =>
          this.getFileType(f) === 'dst' ? { file: f, stitchCount: this.state.stitchCount } : f
        )
    );

  handleBackClick = () => this.props.handleBack();

  dropzoneEventHandlers = type => ({
    addedfile: f => {
      this.addFile(f, type);
    }
  });

  storeFileInState = (f, reset) => {
    switch (this.getFileType(f)) {
      case 'png':
        this.setState({ png: reset ? null : f });
        break;
      case 'dst':
        this.setState({ dst: reset ? null : f });
        break;
      case 'pdf':
        this.setState({ pdf: reset ? null : f });
        break;
    }
  };

  callback = f => {
    // give time to react-dropzone to cancel all subscriptions and asynchronous tasks
    setTimeout(() => this.storeFileInState(f), 200);
  };

  types = {
    png: 'image/png',
    dst: '.dst',
    pdf: '.pdf'
  };

  getFileType = f => f.name?.split('.').pop().toLowerCase();

  addFile = (f, type) => {
    const onError = addon => this.setState({ error: this.state.error.add(addon || type) });

    if (this.getFileType(f) === type) {
      this.checkFileRequirements(
        f,
        type,
        () => {
          this.callback(f);
          this.setState({ error: this.state.error.delete(type) });
        },
        addon => onError(addon)
      );
    } else {
      onError();
    }
  };

  fileUploadOptions = type => ({
    wrapperDisplay: 'block',
    baseUrl: '',
    multiple: false,
    numberLimit: 1,
    accept: this.types[type],
    chooseFile: files => {
      this.addFile(files[0], type);
    }
  });

  componentConfig = type => ({
    showFiletypeIcon: false,
    postUrl: 'no-url',
    dropzoneSelector: `.zone-${type}`
  });

  dropzoneConfig = () => ({
    addRemoveLinks: false,
    autoProcessQueue: false,
    autoQueue: false,
    clickable: false,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(<div />)
  });

  handleRemoveFile = file => this.storeFileInState(file, true);

  imageFile = file => (
    <>
      <div className="image-file d-flex align-items-center w-25 mt-3 mb-3 p-2 justify-content-between">
        <span className="heavy w-100">{truncate(file.name, 20)}</span>
        <div className="d-flex" onClick={() => this.handleRemoveFile(file)}>
          <Icon icon={'x'} />
        </div>
      </div>
      <style jsx>{`
        .image-file {
          border-radius: 4px;
          background: ${COLORS.neutralLight200};
        }
        .image-file span {
          font-size: 13px;
        }
        .image-file div {
          cursor: pointer;
        }
      `}</style>
    </>
  );

  dropdownZone = type =>
    this.state[type] ? (
      this.imageFile(this.state[type])
    ) : (
      <>
        <div
          className={`emb-dropdown-zone zone-${type} d-flex justify-content-center align-items-center`}
        >
          <div className="d-flex align-items-center">
            <span style={{ paddingTop: '2px' }}>Drag & drop or &nbsp;</span>
            <FileUpload options={this.fileUploadOptions(type)}>
              <span className="btn-browse" ref="chooseBtn">
                browse
              </span>
            </FileUpload>
          </div>
          <DropzoneComponent
            config={this.componentConfig(type)}
            eventHandlers={this.dropzoneEventHandlers(type)}
            djsConfig={this.dropzoneConfig()}
          />
        </div>
        <style jsx>{`
          .emb-dropdown-zone {
            height: 56px;
            border: 1px dashed #9aa4ac;
            border-radius: 8px;
            margin: 10px 0;
            background: ${COLORS.neutralLight100};
          }
          .emb-dropdown-zone span {
            font-size: 13px;
            font-weight: 400;
          }
          .btn-browse {
            text-decoration: underline;
            cursor: pointer;
          }
        `}</style>
      </>
    );

  fileError = type => {
    let message;
    switch (type) {
      case 'png':
        message = `Image doesn’t meet file type requirements or image size isn't exactly any of ${this.props.requiredPNGSize
          ?.map(r => `${r.width}x${r.height}`)
          .join(', ')
          .replace(/, ([^,]*)$/, ' and $1')}`;
        break;

      case 'stitch_count':
        message = 'Stitch count is missing in file';
        break;

      default:
        message = 'File doesn’t meet file type requirements';
        break;
    }
    return this.state.error.has(type) ? (
      <>
        <div className="caption-text">{message}</div>
        <style jsx>{`
          color: ${COLORS.cayennePepper};
        `}</style>
      </>
    ) : null;
  };

  render() {
    return (
      <>
        <Modal
          className="large"
          isOpen={true}
          title="Upload .DST"
          actionText="Upload"
          cancelText="Cancel"
          cancelClick={() => this.handleCancelClick()}
          primaryClick={() => this.handleUploadClick()}
          primaryDisabled={!this.canUpload()}
          disableOutsideClose
        >
          <>
            <div className="d-flex flex-column p-4">
              <Button
                className="button-default back-button small mb-3"
                onClick={() => this.handleBackClick()}
              >
                <Icon small icon="arrowLeft" className="mr-2" /> Back to My Artwork
              </Button>
              <div className="body-text-2 heavy">PNG</div>
              {this.dropdownZone('png')}
              <div className="caption-text">
                The PNG version of your artwork is used to preview and accurately place your artwork
                on a product
              </div>
              {this.fileError('png')}
              <div className="body-text-2 heavy">DST</div>
              {this.dropdownZone('dst')}
              <div className="caption-text">The embroidery machine readable file</div>
              {this.fileError('dst')}
              {this.fileError('stitch_count')}
              <div className="body-text-2 heavy">Colorway</div>
              {this.dropdownZone('pdf')}
              <div className="caption-text">
                The PDF file which assigns thread color to embroidery machine needles
              </div>
              {this.fileError('pdf')}
            </div>
          </>
        </Modal>
        <style jsx>{`
          .body-text-2 {
            margin-top: 24px;
          }

          :global(.button-default.back-button) {
            max-width: fit-content !important;
          }
        `}</style>
      </>
    );
  }
}

EmbroideryDSTUpload.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  requiredPNGSize: PropTypes.array.isRequired
};

export default EmbroideryDSTUpload;
