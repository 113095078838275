import { connect } from 'react-redux';
import HubProducts from './HubProducts';
import {
  isHubProductsLoadedSelector,
  hubProductsQuerySelector,
  hubProductsItemsSelector,
  selectedItemsSelector,
  currentPageSelector,
  totalPagesSelector
} from './HubProductsSelectors';
import {
  hubProductsLoad,
  toggleHubProductSelected,
  deselectAll,
  setCurrentPage
} from './HubProductsActions';
import { updateProductId } from '../../../EditDesignView/EditDesignViewActions';
import analyticsService from 'gooten-components/src/services/analyticsService';
import withRouteStep from 'gooten-components/src/hocs/withRouteStep';

const mapDispatchToProps = {
  loadHubProducts: query => hubProductsLoad(query),
  onHubProductSelectionToggled: (hubProduct, isChecked) => {
    if (isChecked) {
      analyticsService.track('COF - Hub Products Flow', 'Hub Product Selected', 'COF');
    }
    return toggleHubProductSelected(hubProduct, isChecked);
  },
  deselectAll: () => deselectAll(),
  setCurrentPage: page => setCurrentPage(page),
  editDesign: productVariant => {
    analyticsService.track('COF - Hub Products Flow', 'Hub Product Edit Design Clicked', 'COF');
    return updateProductId(
      (productVariant.gooten_mapping && productVariant.gooten_mapping.id) || productVariant.id
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    isHubProductsLoaded: isHubProductsLoadedSelector(state),
    hubProductsQuery: hubProductsQuerySelector(state),
    hubProducts: hubProductsItemsSelector(state),
    selectedItems: selectedItemsSelector(state),
    currentPage: currentPageSelector(state),
    totalPages: totalPagesSelector(state)
  };
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(HubProducts),
  'products-selection'
);
