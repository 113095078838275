import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import FileUpload from 'react16-fileupload';
import { scrollToWithDelay } from 'gooten-components/src/utils/scroll';
import Icon from 'gooten-components/src/components/shared/Icon';
import adminApiService from '../../../services/adminApiService';
import Config from '../../../config';
import { firstNameRegex, lastNameRegex, emailRegex } from 'gooten-components/src/utils/regex';
import Analytics from 'gooten-components/src/services/analyticsService';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div`
  .img-responsive {
    width: 85px;
  }

  .logo-container {
    margin-bottom: 10px;
  }

  .file-upload-container {
    width: 90px;
  }
`;

class ProfileInformation extends React.Component {
  static get name() {
    return 'profileInformation';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Profile Information',
      onFailure: 'Failed to update Profile Information',
      onSuccess: 'Your Profile Information has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};
    this.fileUploadOptions = {
      wrapperDisplay: 'block',
      baseUrl: adminApiService.getAdminApiUrl(
        `PartnersApi/UpdateLogo?partnerId=${Config.get('partnerId')}`
      ),
      chooseAndUpload: true,
      multiple: false,
      numberLimit: 1,
      accept: 'image/jpeg,image/png',
      requestHeaders: adminApiService.getAuthHeaders(),
      chooseFile: files => {
        this.props.uploadStart(files);
      },
      uploadSuccess: resp => {
        this.props.uploadDone(resp);
      },
      uploadFail: resp => {
        this.props.uploadFailed(resp);
      }
    };
  }

  componentDidMount() {
    this.props.init({ userId: this.props.userId });
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.props.init({ userId: this.props.userId });
      if (this.props.userId) {
        scrollToWithDelay(ReactDOM.findDOMNode(this.wrapper), 100);
      }
    }
  }

  get canUpdate() {
    return (
      this.props.ready &&
      this.props.data &&
      !this.props.data.equals(this.props.unmodified) &&
      Object.values(this.inputs).every(i => !i || i.isValid())
    );
  }

  update() {
    this.props.update({ data: this.props.data, userId: this.props.userId });
  }

  renderInput(field, label, placeholder, className, regexp, note) {
    return (
      <TextInput
        className={className}
        id={field}
        showValidation
        isRequired
        emptyMsg="This field is required."
        regexp={regexp}
        validationMsg="Wrong format."
        label={label}
        disabled={this.props.disabled}
        placeholder={placeholder}
        onChange={(value, field) => this.props.change(value, field)}
        ref={ref => {
          this.inputs[field] = ref;
        }}
        value={this.props.data.get(field) || ''}
      >
        {note ? (
          <p className="input-note">
            <Icon className="help-icon" /> {note}
          </p>
        ) : null}
      </TextInput>
    );
  }

  render() {
    return (
      <BaseCard
        card={ProfileInformation.name}
        title="Profile Information"
        ref={wrapper => {
          this.wrapper = wrapper;
        }}
      >
        <Container>
          <div className="profile-information-card">
            <div className="row">
              <div className="col-xs-12">
                <p className="card-description">
                  This information is used to verify your account and allows you to create orders in
                  our system.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-12 text-center mb-3 avatar-column pull-right">
                <label>Company Logo</label>
                {this.props.logo ? (
                  <div className="logo-container">
                    <img
                      className="img-responsive center-block"
                      src={this.props.logo}
                      alt={this.props.logo}
                    />
                  </div>
                ) : null}
                <div className="center-block file-upload-container">
                  {this.props.disabled ? null : (
                    <FileUpload options={this.fileUploadOptions}>
                      <span ref="chooseAndUpload">
                        <Button className="button-default medium full-width" type="button">
                          Upload
                        </Button>
                      </span>
                    </FileUpload>
                  )}
                </div>
              </div>
              <div className="col-sm-8 col-xs-12">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    {this.renderInput(
                      'FirstName',
                      'First Name',
                      'First Name...',
                      '',
                      firstNameRegex
                    )}
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    {this.renderInput('LastName', 'Last Name', 'Last Name...', '', lastNameRegex)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {this.renderInput(
                      'EmailAddress',
                      'Email Address',
                      'johannes@gooten.com…',
                      '',
                      emailRegex
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.disabled ? null : (
            <Button
              className="button-primary large"
              disabled={!this.canUpdate}
              onClick={() => this.update()}
            >
              Update profile information
            </Button>
          )}
        </Container>
      </BaseCard>
    );
  }
}

ProfileInformation.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.object,
  logo: PropTypes.string,
  unmodified: PropTypes.object,
  change: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  uploadStart: PropTypes.func.isRequired,
  uploadDone: PropTypes.func.isRequired,
  uploadFailed: PropTypes.func.isRequired,
  userId: PropTypes.string,
  disabled: PropTypes.bool,
  ready: PropTypes.bool
};

export default ProfileInformation;
