import React from 'react';
import Logo from '../../assets/logo.svg';
import './index.scss';

const Header = props => {
  return (
    <div className="top-content">
      <a href="https://www.gooten.com">
        <img src={Logo} alt="logo" />
      </a>
      <div className="has-existing-account">
        <div className="already-registered">ALREADY REGISTERED?</div>
        <a href="/Admin/Account/LogIn" className="log-in">
          LOG IN
        </a>
      </div>
    </div>
  );
};

export default Header;
