import React, { useState, useEffect, useRef } from 'react';
import Input from 'gooten-components/src/components/shared/Input';
import Button from 'gooten-components/src/components/shared/Button';
import Alert from 'gooten-components/src/components/shared/Alert';
import Select from 'gooten-components/src/components/shared/Select';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import { CSV_TEMPLATE_CREATION_URL } from '../../../constants';
import PagedTable from '../../../Shared/PagedTable';
import UploadCSVButton from '../../../Shared/UploadCSVButton';
import LoadingSpinnerOverlay from '../../../Shared/LoadingSpinnerOverlay';

export const PublishView = ({
  isLoading,
  fetchUserData,
  fetchStores,
  validateCsv,
  emailAddress,
  providers,
  stores,
  fetchStoresErrorMsg,
  fetchEmailErrorMsg,
  validateCsvError,
  validateCsvSuccess,
  publishCsv,
  publishCsvError,
  publishCsvSuccess,
  csvData,
  loadCsv,
  resetValidateAndPublish
}) => {
  const [form, setForm] = useState({
    partnerId: '',
    provider: '',
    store: '',
    publishToStore: false
  });

  const didMount = React.useRef(false);
  useEffect(() => {
    if (didMount.current) {
      const timeOutId = setTimeout(() => fetchStores(form.partnerId), 500);
      return () => clearTimeout(timeOutId);
    } else {
      didMount.current = true;
    }
  }, [form.partnerId]);

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    resetValidateAndPublish();
  }, [form.partnerId, form.store]);

  function renderImportDataForm() {
    return (
      <>
        {/* email */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Input
              type="text"
              placeholder="email@domain.com"
              label={'Agent email'}
              value={emailAddress || ''}
              disabled
              errorText={fetchEmailErrorMsg}
            />
          </div>
        </div>

        {/* partnerId */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Input
              type="number"
              placeholder="1234"
              label={'Partner Id'}
              value={form.partnerId}
              onChange={e =>
                setForm({ ...form, partnerId: e.target.value, store: '', provider: '' })
              }
              errorText={fetchStoresErrorMsg}
            />
          </div>
        </div>

        {/* providers */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Select
              label={'Provider'}
              placeholder="Select provider"
              onChange={e => setForm({ ...form, store: '', provider: e.target.value })}
              value={form.provider}
            >
              {providers &&
                providers.map(provider => (
                  <option key={provider} value={provider} id={provider}>
                    {provider.toUpperCase()}
                  </option>
                ))}
            </Select>
          </div>
        </div>

        {/* stores */}
        <div className="row">
          <div className="col-4 col-sm-4">
            <Select
              label={'Store'}
              placeholder="Select store"
              onChange={e => setForm({ ...form, store: e.target.value })}
              value={form.store}
            >
              {stores &&
                stores
                  .filter(store => store.provider === form.provider)
                  .map(store => (
                    <option key={store.id} value={store.storeName} id={store.id}>
                      {store.storeName}
                    </option>
                  ))}
            </Select>
          </div>
        </div>
      </>
    );
  }

  function renderCsvButtons() {
    return (
      <div className="row">
        <div className="col-8 col-sm-8">
          <div className="d-flex flex-wrap justify-content-between csv-buttons mr-n1 ml-n1 mb-2 mt-4">
            <UploadCSVButton onUpload={e => loadCsv(e)}></UploadCSVButton>
            <Button className={'button-primary large ml-2 mr-2 mb-4'}>
              <a href={CSV_TEMPLATE_CREATION_URL} className="w-100 h-100 position-absolute"></a>
              CSV Template
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function renderValidate() {
    return (
      <div className="row">
        <div className="col-4 col-sm-4">
          <div className="d-flex flex-wrap csv-buttons mr-n1 ml-n1 mb-0 mt-4">
            <Button
              className={'button-primary large ml-1 mr-1 mb-2'}
              disabled={!(emailAddress && form.partnerId && form.store && csvData?.length)}
              onClick={() =>
                validateCsv({
                  partnerId: form.partnerId,
                  provider: form.provider,
                  storeName: form.store,
                  csv: csvData
                })
              }
            >
              {'Validate'}
            </Button>
          </div>
        </div>

        <div className="col-sm-12">
          {validateCsvError && (
            <Alert isOpen type="failure" className={'ml-0 mr-0 mb-2 w-100'}>
              <div>{'Validate CSV result'}</div>
              <p dangerouslySetInnerHTML={{ __html: validateCsvError }}></p>
            </Alert>
          )}
          {validateCsvSuccess && (
            <Alert isOpen type="success" className={'ml-0 mr-0 mb-2 w-100'}>
              <div>{'Validate CSV result'}</div>
              <p>{validateCsvSuccess}</p>
            </Alert>
          )}
        </div>

        <div className="col-4 col-sm-4">
          {validateCsvSuccess && (
            <>
              <Checkbox
                className="ml-0 mr-0 mb-2"
                checked={form.publishToStore}
                label={'Publish to store'}
                onChange={() => setForm({ ...form, publishToStore: !form.publishToStore })}
              ></Checkbox>
              <div className="d-flex flex-wrap csv-buttons mr-n1 ml-n1 mb-0 mt-4">
                <Button
                  className={'button-primary large ml-1 mr-1 mb-2'}
                  disabled={publishCsvSuccess}
                  onClick={() => {
                    publishCsv({
                      partnerId: form.partnerId,
                      provider: form.provider,
                      storeName: form.store,
                      publishToStore: form.publishToStore,
                      csv: csvData
                    });
                  }}
                >
                  {'Publish'}
                </Button>
              </div>
            </>
          )}
        </div>

        <div className="col-sm-12">
          {publishCsvError && (
            <Alert isOpen type="failure" className={'ml-0 mr-0 mb-2 w-100'}>
              <div>{'Publish CSV result'}</div>
              <p>{publishCsvError}</p>
            </Alert>
          )}
          {publishCsvSuccess && (
            <Alert isOpen type="success" className={'ml-0 mr-0 mb-2 w-100'}>
              <div>{'Publish CSV result'}</div>
              <p>{publishCsvSuccess}</p>
            </Alert>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {isLoading && <LoadingSpinnerOverlay />}
      <div className="publish-tab mt-4">
        <div className="publish-content">
          {renderImportDataForm()}
          {renderCsvButtons()}
          <PagedTable data={csvData} showLineNumber={true}></PagedTable>
          {renderValidate()}
        </div>
      </div>
      <style jsx>
        {`
          .publish-content :global(.dropdown-container) {
            max-width: 100%;
          }
          :global(.csv-buttons button) {
            flex-grow: 1;
          }
        `}
      </style>
    </>
  );
};

export default PublishView;
