import { connect } from 'react-redux';
import InviteTeamMember from './InviteTeamMember';
import YourTeam from '../YourTeam/YourTeam';
import PendingInvitations from '../PendingInvitations/PendingInvitations';
import { changeFor, updateFor } from '../BaseCard/BaseCardActions';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';

const change = changeFor(InviteTeamMember.name);
const update = updateFor(InviteTeamMember.name);
const cardSelector = cardSelectorFor(InviteTeamMember.name);
const yourTeamSelector = cardSelectorFor(YourTeam.name);
const pendingInvitationsSelector = cardSelectorFor(PendingInvitations.name);

const mapDispatchToProps = {
  notifyError: error => push(error, 'failure'),
  change,
  update
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    teamMembers: yourTeamSelector(state).get('data'),
    invitations: pendingInvitationsSelector(state).get('data'),
    isPristine: cardState.get('isPristine'),
    ready: !cardState.get('busy'),
    data: cardState.get('data'),
    invited: cardState.get('invited'),
    unmodified: cardState.get('unmodified')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteTeamMember);
