import React from 'react';
import PropTypes from 'prop-types';
import './UrlLink.scss';
import Config from '../../../config';

export default class UrlLink extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    boldPrefix: PropTypes.any,
    handleAnalytics: PropTypes.func
  };

  getUrlKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    var linkData = this.props.data;
    if (!partnerGroup) return linkData?.url;

    if (partnerGroup.get('Id') === 1 && linkData?.fujiUrl) return linkData.fujiUrl;
    if (partnerGroup.get('Id') === 2 && linkData?.sensariaUrl) return linkData.sensariaUrl;
    return linkData?.url;
  };

  handleAnalytics() {
    if (this.props.handleAnalytics) {
      const data = this.props.data;
      const id = data.analyticsId ? data.analyticsId : 'UrlLink';
      const partnerGroup = Config.get('partnerGroup');

      this.props.handleAnalytics(
        partnerGroup && partnerGroup.get('Id') === 1 && data.fujiUrl ? data.fujiUrl : data.url,
        'Clicked',
        id
      );
    }
  }

  render() {
    var linkData = this.props.data;
    const boldPrefix = this.props.boldPrefix ? (
      <span className={'bold-content'}>{this.props.boldPrefix}</span>
    ) : null;

    return (
      <div className="url-link-container">
        <a
          href={this.getUrlKbLink()}
          target={linkData.target}
          onClick={() => this.handleAnalytics()}
        >
          <span>
            {boldPrefix}
            {linkData.text}
          </span>
        </a>
      </div>
    );
  }
}
