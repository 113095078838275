import React from 'react';
import PropTypes from 'prop-types';
import Input from 'gooten-components/src/components/shared/Input';
import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';

export const HubProductProductsSearch = props => {
  return (
    <div className="hubproducts-search-wrapper">
      {props.searchText.length > 0 && (
        <Button
          iconOnly
          className="small hub-products-search-temp-button"
          onClick={() => props.doSearch()}
        >
          <Icon small icon="x" />
        </Button>
      )}
      <Input
        noLabel
        icon={'search'}
        iconPlacement={'leading'}
        placeholder="Search..."
        value={props.searchText}
        onChange={e => props.doSearch(e.target.value)}
      />
      <style jsx>
        {`
          .hubproducts-search-wrapper {
            position: relative;
          }
          :global(.hub-products-search-temp-button) {
            position: absolute !important;
            z-index: 1000;
            right: 8px;
            top: 8px;
          }
        `}
      </style>
    </div>
  );
};

HubProductProductsSearch.propTypes = {
  searchText: PropTypes.string,
  doSearch: PropTypes.func.isRequired
};

export default HubProductProductsSearch;
