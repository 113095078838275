import React from 'react';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import Config from 'gooten-components/src/config';

const NoOrdersComponent = () => {
  const cbhSettings = Config.get('cbhSettings').toJS();

  return (
    <div className="no-orders d-flex w-100 flex-wrap-reverse mt-2">
      <div className="inner-div d-flex flex-column justify-content-center">
        <div className="title-1 mb-2">No orders yet</div>
        <div className="body-text-2 mb-4">
          View and manage all your orders fulfilled with Gooten
        </div>
        <a href={`/admin#/custom-order-form`}>
          <Button className="button-primary large">
            <Icon height={20} width={20} icon="plusCircle" /> Create order
          </Button>
        </a>
      </div>
      {cbhSettings.Images.showNoOrdersImage && <div className="inner-div cool-image" />}
      <style jsx>{`
        .inner-div {
          width: 50%;
          justify-content: start;
          min-height: 15rem;
          padding: 3rem;
        }
        .no-orders {
          background-color: #f2f4f7;
          border-radius: 8px;
        }
        .cool-image {
          background-image: url('/admin-assets/img/AssetsOrdersList/no-orders-available.png');
          background-repeat: no-repeat;
          background-position: top center;
        }
        @media screen and (max-width: 992px) {
          .inner-div {
            width: 100%;
            align-items: center;
            padding: 1.5rem;
          }
          .cool-image {
            background-size: cover;
          }
        }
      `}</style>
    </div>
  );
};

NoOrdersComponent.propTypes = {};

export default NoOrdersComponent;
