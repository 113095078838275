import { connect } from 'react-redux';
import Notifications from './Notifications';
import { allNotificationsSelector, lastNotificationSelector } from './NotificationsSelectors';
import { dismiss } from './NotificationsActions';

const mapDispatchToProps = {
  dismiss: key => dismiss(key)
};

const mapStateToProps = state => {
  return {
    notifications: allNotificationsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
