import { connect } from 'react-redux';
import ProductType from './ProductType';
import { productTypeSelector, storeIdSelector } from '../../ProvidersTab/ProvidersTabSelectors';
import { changeStoreField } from '../../../ProductPublishActions';

const mapDispatchToProps = {
  onChange: (storeId, field, value) => changeStoreField({ storeId, field, value })
};

const mapStateToProps = state => {
  return {
    value: productTypeSelector(state),
    storeId: storeIdSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductType);
