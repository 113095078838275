import { createSelector } from 'reselect';
import { rootSelector } from './../../../SKUsSelectionViewSelectors';
import { selectedProductSelector } from './../../ProductSelection/components/HubProductSelection/HubProductSelectionSelectors';
import skusListService from './../services/skusListService';

export const pageSizeSelector = createSelector([], () => 8);

export const suggestedMatchesSelector = createSelector(
  [rootSelector, selectedProductSelector],
  (root, selectedProduct) =>
    skusListService.filterSuggestedVariants(
      selectedProduct.get('variants'),
      root.getIn(['variantsMappings', root.get('currentVariantIndex')])
    )
);

export const allVariantsSelector = createSelector([selectedProductSelector], selectedProduct =>
  selectedProduct.get('variants')
);

export const optionsSelector = createSelector([rootSelector], root =>
  root.getIn(['hubProductSelection', 'options'])
);

export const pageSelector = createSelector(
  [rootSelector],
  root => root.getIn(['hubProductSelection', 'page']) || 1
);

/**
 * All variants that should be displayed on single page in skus list, filtered by visibility
 * and page
 */
export const pageVariantsSelector = createSelector(
  [suggestedMatchesSelector, allVariantsSelector, optionsSelector, pageSizeSelector, pageSelector],
  (suggestedMatches, allVariants, options, pageSize, page) =>
    skusListService.pageVariants(suggestedMatches, allVariants, options, page, pageSize)
);

export const pagesSelector = createSelector(
  [suggestedMatchesSelector, allVariantsSelector, optionsSelector, pageSizeSelector],
  (suggestedMatches, allVariants, options, pageSize) => {
    const skusSize =
      skusListService.filterVisibleVariants(suggestedMatches, options, true).size +
      skusListService.filterVisibleVariants(allVariants, options, false).size;
    return skusListService.createPages(skusSize, pageSize);
  }
);

export const skuNotificationSelector = createSelector([rootSelector], root =>
  root.get('skuSelectedNotification')
);

export const disableFilteringSelector = createSelector(
  [suggestedMatchesSelector],
  suggestedMatches => suggestedMatches.size < 6
);
