import { put, $delete, fetch } from '../utils/http';
import Config from '../config';

class ActionsService {
  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }

  getAuthBearerParams() {
    return {
      Authorization: 'Bearer ' + Config.get('adminApiToken')
    };
  }

  publishProducts(products) {
    return Promise.all(
      products.map(p => {
        const url = `${Config.get('storeApi')}stores/${p.get('storeId')}/products/${p.get(
          'id'
        )}/publish`;
        return put(url, null, { queryParams: this.getAuthQueryParams() });
      })
    );
  }

  unpublishProducts(products) {
    return Promise.all(
      products.map(p => {
        const url = `${Config.get('storeApi')}stores/${p.get('storeId')}/products/${p.get(
          'id'
        )}/unpublish`;
        return put(url, null, { queryParams: this.getAuthQueryParams() });
      })
    );
  }

  deleteProducts(products) {
    return Promise.all(
      products.map(p => {
        const url = `${Config.get('storeApi')}products/${p.get('id')}/delete`;
        const queryParams = p.get('storeId')
          ? { ...this.getAuthQueryParams(), storeId: p.get('storeId') }
          : this.getAuthQueryParams();
        return $delete(url, null, { queryParams });
      })
    );
  }

  unlinkProducts(products) {
    return Promise.all(
      products.map(p => {
        const url = `${Config.get('storeApi')}stores/${p.get('storeId')}/products/${p.get(
          'id'
        )}/unlink`;
        return put(url, null, { queryParams: this.getAuthQueryParams() });
      })
    );
  }

  personalizeProducts(products) {
    return Promise.all(
      products.map(p => {
        const url = `${Config.get('storeApi')}stores/${p.get('storeId')}/products/${p.get(
          'id'
        )}/personalize`;
        return put(url, null, { queryParams: this.getAuthQueryParams() });
      })
    );
  }

  depersonalizeProducts(products) {
    return Promise.all(
      products.map(p => {
        const url = `${Config.get('storeApi')}stores/${p.get('storeId')}/products/${p.get(
          'id'
        )}/depersonalize`;
        return put(url, null, { queryParams: this.getAuthQueryParams() });
      })
    );
  }
}

export default new ActionsService();
