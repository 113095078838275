import { ActionNameFactory } from '../../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'TAGS');
export const ADD_TAG = getActionName('ADD_TAG');
export const REMOVE_TAG = getActionName('REMOVE_TAG');
export const NEW_TAG = getActionName('NEW_TAG');

export const addTag = payload => ({
  type: ADD_TAG,
  payload
});

export const removeTag = payload => ({
  type: REMOVE_TAG,
  payload
});

export const newTag = payload => ({
  type: NEW_TAG,
  payload
});
