import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

export const Pill = props => {
  const onToggleClick = () => {
    if (!props.disabled) {
      props.toggleValue(props.optionId, props.valueId);
    }
  };

  return (
    <Button
      onClick={onToggleClick}
      className={`select-option ${props.active ? 'button-primary' : 'button-default'} large`}
      disabled={props.disabled}
    >
      <>{props.title}</>
    </Button>
  );
};

Pill.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
  valueId: PropTypes.string.isRequired,
  toggleValue: PropTypes.func.isRequired
};

export default Pill;
