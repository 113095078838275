import React, { Fragment, useEffect, useState } from 'react';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import TableFooter from './TableFooter';

export const PagedTable = ({ data, showLineNumber = false, lineNumberStart = 0 }) => {
  const [pager, setPager] = useState({
    currentPage: 1,
    pageSize: 10
  });

  useEffect(() => {
    setPager({ ...pager, currentPage: 1 });
  }, [data]);

  const getItems = () => {
    return data.slice((pager.currentPage - 1) * pager.pageSize, pager.currentPage * pager.pageSize);
  };

  const getLineNumber = index => (pager.currentPage - 1) * pager.pageSize + lineNumberStart + index;

  const getColumnsCount = () => Object.keys(data[0]).length + (showLineNumber && 1);

  return (
    <>
      {data?.length ? (
        <>
          <TableRow
            scrollableX
            size="small"
            columns={'minmax(auto, auto)'.repeat(getColumnsCount())}
          >
            {showLineNumber && <Cell header>Line</Cell>}
            {Object.keys(data[0]).map((headerValue, index) => (
              <Cell key={index} header>
                {headerValue}
              </Cell>
            ))}

            {getItems().map((item, index) => (
              <Fragment key={index}>
                {showLineNumber && <Cell key={index}>{getLineNumber(index)}</Cell>}
                {Object.values(item).map((cell, cellIndex) => (
                  <Cell key={cellIndex}>{cell}</Cell>
                ))}
              </Fragment>
            ))}
          </TableRow>
          <TableFooter
            total={data.length}
            currentPage={pager.currentPage}
            pageSize={pager.pageSize}
            onCurrentPageChanged={p => setPager({ ...pager, currentPage: p })}
            onPageSizeChanged={p => setPager({ ...pager, pageSize: p, currentPage: 1 })}
          ></TableFooter>
        </>
      ) : null}
    </>
  );
};

export default PagedTable;
