import { ActionNameFactory } from '../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'PROVIDERS-SELECTION-PANEL');

export const SET_DEFAULT_STATE = getActionName('SET_DEFAULT_STATE');
export const CHANGE_PROVIDER_STATE = getActionName('CHANGE_PROVIDER_STATE');

export const setDefaultState = payload => ({
  type: SET_DEFAULT_STATE,
  payload
});

export const changeProviderState = payload => ({
  type: CHANGE_PROVIDER_STATE,
  payload
});
