import { combineReducers } from 'redux-immutable';
import { fromJS, Map } from 'immutable';
import { initialState } from './ImageSelectionModalInitialState';
import MyArtworkReducer from './atoms/MyArtwork/MyArtworkReducer';
import { RESET } from '../../../store/actions/globalActions';
import {
  MODAL_OPEN,
  MODAL_CLOSE,
  IMAGES_CHECK,
  SHOW_SCREEN,
  IMAGES_FETCH,
  SHOW_LOADING
} from './ImageSelectionModalActions';

export const currentState = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return state.set('open', true).set('options', fromJS(action.payload));

    case MODAL_CLOSE:
      return state.set('open', false).set('options', new Map());

    case IMAGES_CHECK.SUCCESS:
      return state.set('hasImages', action.payload.exists);

    case IMAGES_CHECK.FAIL:
      return state.set('hasImages', false);

    case IMAGES_FETCH.SUCCESS:
    case IMAGES_FETCH.FAIL:
      return state.set('loading', false);

    case SHOW_SCREEN:
      return state.set('showScreen', action.payload);

    case SHOW_LOADING:
      return state.set('loading', true);

    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  currentState,
  myArtwork: MyArtworkReducer
});

// make root reducer
export default (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
