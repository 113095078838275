import { fetch } from '../../../utils/http';
import Config from '../../../config';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId')
    }
  };
};

const productionTimesUrl = () => `${Config.get('api')}productiontimes`;

class ProductionTimesService {
  getProductionTimeData(productId) {
    let queryParams = getAuthQueryParams();
    const params = { ...queryParams };
    params.queryParams.productId = productId;

    return new Promise((resolve, reject) => {
      fetch(productionTimesUrl(), params)
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

// singleton
export default new ProductionTimesService();
