// @flow

import { imageUpdate } from '../../../state/actions/actionCreators';
import ToggleEditModeAction from '../toggleEditMode.action';
import { ImageEvents } from '../../../flow/konvaTypes';
import DragService from './drag.service';
import EventEmitter from 'events';
import { PUBLIC_EVENTS, INTERNAL_EVENTS } from '../../../ImageEditor.events';

export default class DragAction {

  actionGroup: any;
  actionRectElm: any
  editModeToggler: ToggleEditModeAction;
  publicEvents: EventEmitter;

  constructor(actionGroup: any, actionRectElm: any, publicEvents: EventEmitter){
    this.publicEvents = publicEvents;
    this.actionGroup = actionGroup;
    this.actionRectElm = actionRectElm;
    // add cursor styling
    this.actionRectElm.off('mouseover').on('mouseover', () => {
      document.body.style.cursor = 'pointer';
    });
    this.actionRectElm.off('mouseout').on('mouseout', () => {
      document.body.style.cursor = 'default';
    });
    this.editModeToggler = new ToggleEditModeAction(this.publicEvents);
  }

  draw(ctx: any) {

    // Ignore drag in case of multitouch events
    let ignoreDrag = false;
    //After starting drag mode multi-touch events should be disabled
    let dragMode = false;

    this.actionGroup.off('dragend').on('dragend', (event) => {
      //Clear flag
      dragMode = false;
      // Tell TemplateLayer dragging is over
      ctx.events.emit(ImageEvents.dragEnd + '_' + ctx.state.images.current.selected.layerId);

      if (ignoreDrag) {
        ignoreDrag = false;
        return;
      }

      // Persist to state image position on drag end
      ctx.actionProcessorCb(
        imageUpdate(
          ctx.state.images.current.selected.imageId,
          DragService.getUpdatedPositionRectOnDragEnd(
            event.target.x(),
            event.target.y(),
            ctx.state
          )
        )
      );

      //Send public event
      this.publicEvents.emit(PUBLIC_EVENTS.DRAG_END);
    });

    this.actionGroup.off('dragmove').on('dragmove', (event) => {
      if (ignoreDrag || (event.evt.touches && event.evt.touches.length > 1)){
        //Concurrency wins multi-touch
        if (!dragMode) {
          ignoreDrag = true;
          return;
        }
      }
      else {
        //Concurrency wins drag
        dragMode = true;
      }

      // Tell ImageLayer it should update image
      ctx.events.emit(INTERNAL_EVENTS.IMAGE_UPDATE, {
        imageId: ctx.state.images.current.selected.imageId,
        x: event.target.x(),
        y: event.target.y()
      });
    });

    // Tell TemplateLayer about user manipulation
    this.actionGroup.off('dragstart').on('dragstart', () => {

      //Send public event
      this.publicEvents.emit(PUBLIC_EVENTS.DRAG_START);
      ctx.events.emit(ImageEvents.dragStart + '_' + ctx.state.images.current.selected.layerId);
    });
    this.actionRectElm.off('mouseenter').on('mouseenter', () => ctx.events.emit(ImageEvents.mouseEnter + '_' + ctx.state.images.current.selected.layerId));
    this.actionRectElm.off('mouseleave').on('mouseleave', () => ctx.events.emit(ImageEvents.mouseLeave + '_' + ctx.state.images.current.selected.layerId));

    // Activate/Deactivate edit mode
    this.actionRectElm.off('click tap').on('click tap', (e) => {
      this.editModeToggler.toggle(e, ctx, ctx.state.images.current.selected.layerId);
    });

  }
}
