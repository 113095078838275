import { getActionName } from '../../ProductSelectionViewActions';

// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCT_SELECT = getActionName('PRODUCT_SELECT');

export const PAGE_SELECT = getActionName('PAGE_SELECT');

// ------------------------------------
// Actions
// ------------------------------------
export const selectProduct = (product, selected) => {
  return {
    type: PRODUCT_SELECT,
    payload: { product, selected }
  };
};

export const selectPage = value => {
  return {
    type: PAGE_SELECT,
    payload: value
  };
};
