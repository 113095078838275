import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import BaseCard from '../BaseCard';

import PaymentMethod from 'gooten-components/src/components/shared/PaymentMethod';

class CreditCard extends React.Component {
  static get name() {
    return 'creditCard';
  }
  static get messages() {
    return {
      onInitFailure:
        'Failed to load Credit Card info. Check your Business Information is filled and valid.',
      onFailure: 'Failed to update Credit Card',
      onSuccess: 'Your Credit Card has been updated'
    };
  }

  constructor(props) {
    super(props);

    this.wrapUpdateCard = this.wrapUpdateCard.bind(this);
  }

  componentDidMount() {
    this.props.init();
  }

  wrapUpdateCard(card, sameAddress, captchaToken, makeDefault) {
    card = { ...card, makeDefault, captchaToken: captchaToken };

    if (sameAddress) {
      card = {
        ...card,
        billingAddress: {
          line1: this.props.addressData.get('Line1'),
          line2: this.props.addressData.get('Line2'),
          city: this.props.addressData.get('City'),
          state: this.props.addressData.get('State'),
          postalCode: this.props.addressData.get('PostalCode'),
          country: this.props.addressData.get('CountryCode')
        }
      };
    }

    this.props.updateCard({ data: fromJS(card) });
  }

  render() {
    const defaultMethod =
      this.props.data && this.props.data.PaymentMethods
        ? this.props.data.PaymentMethods.find(m => m.get('IsDefault'))
        : null;

    const secondaryMethod =
      this.props.data && this.props.data.PaymentMethods
        ? this.props.data.PaymentMethods.find(m => !m.get('IsDefault'))
        : null;

    const paymentMethodCount =
      this.props.data && this.props.data.PaymentMethods && this.props.data.PaymentMethods.size
        ? this.props.data.PaymentMethods.size
        : 0;

    return (
      <BaseCard
        card={CreditCard.name}
        title={this.props.isOnCheckoutPage ? null : 'Payment Information'}
      >
        <div className="profile-information-card">
          {!this.props.isOnCheckoutPage && (
            <div className="row">
              <div className="col-xs-12">
                {defaultMethod ? (
                  <p className="body-text-2 mt-n3">
                    Check below for your preferred payment methods for your Gooten orders.
                  </p>
                ) : (
                  <p className="body-text-2 mt-n3">
                    Your credit card or PayPal will be used to accept and charge orders placed by
                    you or your customers.
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-xs-12">
              {this.props.braintreeToken && (
                <PaymentMethod
                  method={defaultMethod && defaultMethod.toJS()}
                  isDefault
                  editInModal={!this.props.isOnCheckoutPage}
                  braintreeToken={this.props.braintreeToken}
                  countries={this.props.countries}
                  sameAsAddress={this.props.addressData}
                  updateCard={this.wrapUpdateCard}
                  updatePaypal={this.props.updatePayPal}
                  updateDefault={this.props.updateDefault}
                  removeMethod={this.props.removeMethod}
                  getSetupIntentId={this.props.getSetupIntentId}
                  loadingSetupIntentId={this.props.loadingSetupIntentId}
                  setupIntentId={this.props.setupIntentId}
                  clientSecret={this.props.clientSecret}
                  showCreditCardModal={this.props.showCreditCardModal}
                  showingCreditCardModal={this.props.showingCreditCardModal}
                  handleStripeResponse={this.props.handleStripeResponse}
                  stripeFormBusy={this.props.stripeFormBusy}
                  paymentMethodCount={paymentMethodCount}
                  isOnCheckoutPage={this.props.isOnCheckoutPage}
                />
              )}

              {defaultMethod && !this.props.isOnCheckoutPage && (
                <PaymentMethod
                  method={secondaryMethod && secondaryMethod.toJS()}
                  isDefault={false}
                  editInModal={true}
                  braintreeToken={this.props.braintreeToken}
                  countries={this.props.countries}
                  sameAsAddress={this.props.addressData}
                  updateCard={this.wrapUpdateCard}
                  updatePaypal={this.props.updatePayPal}
                  updateDefault={this.props.updateDefault}
                  removeMethod={this.props.removeMethod}
                  getSetupIntentId={this.props.getSetupIntentId}
                  loadingSetupIntentId={this.props.loadingSetupIntentId}
                  setupIntentId={this.props.setupIntentId}
                  clientSecret={this.props.clientSecret}
                  showCreditCardModal={this.props.showCreditCardModal}
                  showingCreditCardModal={this.props.showingCreditCardModal}
                  handleStripeResponse={this.props.handleStripeResponse}
                  stripeFormBusy={this.props.stripeFormBusy}
                  paymentMethodCount={paymentMethodCount}
                />
              )}

              {defaultMethod && secondaryMethod && !this.props.isOnCheckoutPage && (
                <div className="method-count-info">
                  <i className="info-icon" />
                  <span>Payment methods limited to 2</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <style jsx>
          {`
            :global(.panel .panel-body) {
              border: ${this.props.isOnCheckoutPage ? 'none !important' : 'inherit'};
              padding: ${this.props.isOnCheckoutPage ? '0 !important' : 'inherit'};
            }
            :global(.panel-body .settings-card) {
              padding: ${this.props.isOnCheckoutPage ? '0 !important' : 'inherit'};
            }
          `}
        </style>
      </BaseCard>
    );
  }
}

CreditCard.propTypes = {
  init: PropTypes.func.isRequired,
  countries: PropTypes.array,
  data: PropTypes.object,
  braintreeToken: PropTypes.string,
  updateCard: PropTypes.func.isRequired,
  updatePayPal: PropTypes.func.isRequired,
  updateDefault: PropTypes.func.isRequired,
  removeMethod: PropTypes.func.isRequired,
  getSetupIntentId: PropTypes.func.isRequired,
  loadingSetupIntentId: PropTypes.bool.isRequired,
  showCreditCardModal: PropTypes.func.isRequired,
  showingCreditCardModal: PropTypes.bool.isRequired,
  handleStripeResponse: PropTypes.func.isRequired,
  setupIntentId: PropTypes.string,
  clientSecret: PropTypes.string,
  addressData: PropTypes.object,
  isOnCheckoutPage: PropTypes.bool
};

export default CreditCard;
