// @flow
'use strict';
import StateManager from '../../state/stateManager';

export default class ValidationComponent {
  messageHolder: any;
  domElement: any;

  constructor(stateManager: StateManager) {
    let element = document.createElement('div');
    element.className = 'validation-toolbar';
    element.innerHTML = `
      <div class='validation-wrapper editor-tooltip'>
        <span class="editor-tooltip-text">Low Image Resolution!</span>
        ${this._getIcon()}
      </div>`;

    this.domElement = element;
    this.messageHolder = this.domElement.querySelector('.validation-wrapper');
    stateManager.subscribe(this._stateChange.bind(this));
  }

  _getIcon() {
    return `<svg width="24" height="24" viewBox="0 0 24 24">
        <g>
          <path fill="#E8CA25" d="M11.9999 21.1666C6.91658 21.1666 2.83325 17.0833 2.83325 12C2.83325 6.91665 6.91658 2.83331 11.9999 2.83331C17.0833 2.83331 21.1666 6.91665 21.1666 12C21.1666 17.0833 17.0833 21.1666 11.9999 21.1666ZM11.9999 4.49998C7.83325 4.49998 4.49992 7.83331 4.49992 12C4.49992 16.1666 7.83325 19.5 11.9999 19.5C16.1666 19.5 19.4999 16.1666 19.4999 12C19.4999 7.83331 16.1666 4.49998 11.9999 4.49998Z M11.9999 12.8333C11.4999 12.8333 11.1666 12.5 11.1666 12V8.66665C11.1666 8.16665 11.4999 7.83331 11.9999 7.83331C12.4999 7.83331 12.8333 8.16665 12.8333 8.66665V12C12.8333 12.5 12.4999 12.8333 11.9999 12.8333Z M11.9999 16.1666C11.7499 16.1666 11.5833 16.0833 11.4166 15.9166C11.2499 15.75 11.1666 15.5833 11.1666 15.3333C11.1666 15.25 11.1666 15.0833 11.2499 15C11.3333 14.9166 11.3333 14.8333 11.4166 14.75C11.6666 14.5 11.9999 14.4166 12.3333 14.5833C12.4166 14.5833 12.4166 14.5833 12.4999 14.6666C12.4999 14.6666 12.5833 14.75 12.6666 14.75C12.7499 14.8333 12.8333 14.9166 12.8333 15C12.8333 15.0833 12.8333 15.25 12.8333 15.3333C12.8333 15.4166 12.8333 15.5833 12.7499 15.6666C12.6666 15.75 12.6666 15.8333 12.5833 15.9166C12.4166 16.0833 12.2499 16.1666 11.9999 16.1666Z"/>
        </g>
      </svg>`;
  }

  _stateChange(state: any) {
    let hasSmallImages = !!state.getIn(['images', 'current', 'images']).find(i => i.get('scale') > 1);
    this.updateLabel(!hasSmallImages);
  }

  updateLabel(valid: boolean) {
    this.messageHolder.style.display = valid ? 'none' : 'inline-block';
  }
}
