
// @flow

import { Group } from 'konva';
import { KonvaLayer } from '../../../flow/konvaTypes';
import CropArea from './cropArea.component';
import EventEmitter from 'events';


export default class CropAction {

  actionGroup: Group;
  layer: KonvaLayer;
  publicEvents: EventEmitter;

  constructor(actionLayer: KonvaLayer, actionGroup: Group, publicEvents: EventEmitter) {
    this.layer = actionLayer;
    this.actionGroup = actionGroup;
    this.publicEvents = publicEvents;
  }

  /**
   * Draw either crop mode toggler or crop area, depends on editor.cropActivated
   * @param {Object} ctx - editor context
   */
  draw(ctx: any) {
    if(ctx.state.editor.cropActivated){
      const cropArea = new CropArea(this.layer, this.actionGroup, this.publicEvents);
      cropArea.draw(ctx);
    }
  }

}
