import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import {
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail
} from 'gooten-components/src/store/actions/dataActions';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COF_ORDER_FROM', 'ORDER_FROM_VIEW');

// ------------------------------------
// Action Types
// ------------------------------------
export const CHECK_SOURCES = getActionName.async('CHECK_SOURCES');

// ------------------------------------
// Actions
// ------------------------------------
export const checkSourcesAsync = () => fetchAsync(CHECK_SOURCES);

export const checkSourcesSuccess = payload => fetchAsyncSuccess(CHECK_SOURCES, payload);

export const checkSourcesFail = err => fetchAsyncFail(CHECK_SOURCES, err);
