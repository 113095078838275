import React from 'react';
import PropTypes from 'prop-types';
import ProductImage from '../atoms/shared/ProductImage';
import ProductName from '../atoms/shared/ProductName';
import ProductDescription from '../atoms/shared/ProductDescription';
import ProductTags from '../atoms/shared/ProductTags';
import ProductOptions from '../atoms/shared/ProductOptions';
import ProductCollection from '../atoms/shared/ProductCollection';
import ProductPricing from '../atoms/shared/ProductPricing';
import ProductVariants from '../atoms/shared/ProductVariants';
import DuplicateChanges from '../atoms/shared/DuplicateChanges';
import Draft from '../atoms/shared/Draft';
import { windowWidth } from '../../shared/ComponentsHooks';
import Mockups from '../atoms/Mockups';
import Design from '../atoms/Design';
import NeckLabels from '../atoms/NeckLabels';
import ProvidersSelectionPanel from '../atoms/ProvidersSelectionPanel';
import Config from '../../../config';
import Advanced from '../atoms/Advanced';

export const validationRules = {
  productVariants: {
    maxNumberOfSkus: 100
  },
  productCollections: {
    maxCollectionLength: 200
  },
  productOptions: {
    maxNumberOfOptions: 3
  }
};

const provider = props => {
  const width = windowWidth();

  const mockupsAndDesign = (
    <React.Fragment>
      <Mockups maxNumberOfImages={9999} />
      <Design showViewAll />
      <Advanced />
      <NeckLabels />
    </React.Fragment>
  );

  return (
    <div className="platform-form flex-container">
      <div className="product-settings">
        <ProductImage />
        {width <= 992 && !props.providersTabHidden ? <ProvidersSelectionPanel /> : null}
        <ProductName />
        <ProductDescription />
        <ProductTags />
        <ProductOptions maxOptions={validationRules.productOptions.maxNumberOfOptions} />
        <ProductCollection
          maxCollectionLength={validationRules.productCollections.maxCollectionLength}
        />
        <Draft />
        <DuplicateChanges
          options={[
            'productName',
            'productDesc',
            'selectedTags',
            'selectedOptions',
            'selectedCollections',
            'draft'
          ]}
        />
        <ProductPricing />
        <DuplicateChanges options={['pricing']} />
        {width <= 992 ? (
          <div className="mockups-design-mobile-holder flex-container">{mockupsAndDesign}</div>
        ) : null}
        <ProductVariants
          maxNumberOfSkus={validationRules.productVariants.maxNumberOfSkus}
          disableRemovingSkus={!Config.get('editMode')}
          forbidDuplicatedSkus
        />
        <DuplicateChanges options={['variants']} />
      </div>
      {width > 992 ? (
        <div className="mockups-design-holder flex-container">
          {props.providersTabHidden ? null : <ProvidersSelectionPanel />}
          {mockupsAndDesign}
        </div>
      ) : null}
    </div>
  );
};

provider.propTypes = {
  providersTabHidden: PropTypes.bool,
  isMultiProduct: PropTypes.bool
};

export default provider;
