import { sortByZIndexAsc } from '../../utils/array';
import PositioningService from '../../utils/positioningService';
import IdGenerator from '../../utils/idGenerator';
import { roundFloatCoordinate } from '../../utils/math';

class ILService {

  _sanitizeIL(il: Object): Object {
    const roundFloatCoordinates = (l) => ({
      left: roundFloatCoordinate(l.left),
      top: roundFloatCoordinate(l.top),
      height: roundFloatCoordinate(l.height),
      width: roundFloatCoordinate(l.width),
      rotation: roundFloatCoordinate(l.rotation),
      scaleDx: roundFloatCoordinate(l.scaleDx),
      x1: roundFloatCoordinate(l.x1),
      x2: roundFloatCoordinate(l.x2),
      y1: roundFloatCoordinate(l.y1),
      y2: roundFloatCoordinate(l.y2),
      centerX: roundFloatCoordinate(l.centerX),
      centerY: roundFloatCoordinate(l.centerY)
    });
    return {
      ...il,
      layers: il.layers.map(l => {
        if (l.images) {
          return {
            ...l,
            images: l.images.map(i => ({
              ...i,
              ...roundFloatCoordinates(i)
            }))
          };
        }
        else {
          return {
            ...l,
            ...roundFloatCoordinates(l)
          };
        }
      })
    };
  }

  exportIL(state: Object): Object {
    // TODO: Validate IL


    // map editor state to IL
    // since editor state is very specific to Konva.js

    // contains only layers which will be printer
    let printIL = {
      type: 'print',
      top: 0,
      left: 0,
      layers: [],
      spaceId: state.template.spaceId
    };

    // contains also design layers
    let previewIL = {
      type: 'preview',
      top: 0,
      left: 0,
      layers: [],
      spaceId: state.template.spaceId
    };

    // sort by index for upcoming operations
    const layers = sortByZIndexAsc(state.template.layers);

    // get max width/height
    printIL.width = previewIL.width = state.template.templateWidth;
    printIL.height = previewIL.height = state.template.templateHeight;

    // filter only design and image layers
    layers.filter(l => ~['design', 'image'].indexOf(l.type)).forEach(l => {
      let layerIL = {};
      layerIL.layerId = l.id;

      if (l.type === 'design') {
        layerIL.url = l.imageurl;

        // design layers x, y start from top left corner
        layerIL.left = l.x1;
        layerIL.top = l.y1;
        layerIL.width = l.x2 - l.x1;
        layerIL.height = l.y2 - l.y1;
        layerIL.rotation = 0; // design layers are static
        layerIL.scaleDx = 1; // design layers are static
        layerIL.x1 = l.x1;
        layerIL.x2 = l.x2;
        layerIL.y1 = l.y1;
        layerIL.y2 = l.y2;
        layerIL.centerX = layerIL.width / 2;
        layerIL.centerY = layerIL.height / 2;

        // template layer.IncludeInPrint
        if (l.print) {
          printIL.layers.push(layerIL);
        }
      }

      if (l.type === 'image') {
        layerIL.images = [];
        // grab all images for current layer
        const layerImages = sortByZIndexAsc(state.images.current.images.filter(i => i.layerId === l.id));
        layerImages.forEach(img => {
          let imgIL = {};
          imgIL.url = img.src;

          // CanFill|MustFill
          // imgIL.imageFill = l.imageFill;

          if (img.crop) {
            // NOTE: img.crop contains x, y, height, width
            // relative to image top left point
            imgIL.crop = {
              x1: img.crop.x,
              y1: img.crop.y,
              x2: img.crop.x + img.crop.width,
              y2: img.crop.y + img.crop.height,
              width: img.crop.width,
              height: img.crop.height
            };
          }

          // NOTE: Use cropped width/height if exists
          const actualWidth = (img.crop ? img.crop.width : img.realSourceWidth)*img.scale;
          const actualHeight = (img.crop ? img.crop.height : img.realSourceHeight)*img.scale;

          const imgHalfWidth = actualWidth/2;
          const imgHalfHeight = actualHeight/2;
          const rotation = img.rotation || 0;


          // coordiantes for images starting from image center
          // we need this b/c of konva.js has weird coordinates positioning

          const center = {
            x: img.x,
            y: img.y
          };
          const notRotatedTopLeft = {
            x: (img.x - imgHalfWidth),
            y: (img.y - imgHalfHeight)
          };
          const notRotatedBottomLeft = {
            x: (img.x - imgHalfWidth),
            y: (img.y + imgHalfHeight)
          };
          const notRotatedTopRight = {
            x: (img.x + imgHalfWidth),
            y: (img.y - imgHalfHeight)
          };
          const notRotatedBottomRight = {
            x: (img.x + imgHalfWidth),
            y: (img.y + imgHalfHeight)
          };

          let topLeftPoint = notRotatedTopLeft;
          let bottomRightPoint = notRotatedBottomRight;

          if (rotation !== 0) {

            // NOTE: After rotation need to rotate each point
            // ImgManip will create rotated image with maxed boundaries
            // Imagine romb inside rect
            // Need to get maxed boundaries for topLeft, bottomRight
            const rotatedTopLeft = PositioningService.getRotatedPoint(
              notRotatedTopLeft,
              center,
              -img.rotation || 0
            );
            const rotatedBottomLeft = PositioningService.getRotatedPoint(
              notRotatedBottomLeft,
              center,
              -img.rotation || 0
            );
            const rotatedTopRight = PositioningService.getRotatedPoint(
              notRotatedTopRight,
              center,
              -img.rotation || 0
            );
            const rotatedBottomRight = PositioningService.getRotatedPoint(
              notRotatedBottomRight,
              center,
              -img.rotation || 0
            );

            // Get max boundaries rect
            const maxRect = PositioningService.getMaxRect([
              {x1: rotatedBottomLeft.x, y1: rotatedBottomLeft.y, x2: rotatedTopRight.x, y2: rotatedTopRight.y},
              {x1: rotatedTopLeft.x, y1: rotatedTopLeft.y, x2: rotatedBottomRight.x, y2: rotatedBottomRight.y},
              {x1: rotatedTopRight.x, y1: rotatedTopRight.y, x2: rotatedBottomLeft.x, y2: rotatedBottomLeft.y},
              {x1: rotatedBottomRight.x, y1: rotatedBottomRight.y, x2: rotatedTopLeft.x, y2: rotatedTopLeft.y}
            ]);

            topLeftPoint = {x: maxRect.x1, y: maxRect.y1};
            bottomRightPoint = {x: maxRect.x2, y: maxRect.y2};
          }

          // NOTE: reduce by template offset - layer.x1, y1
          // it will be added in imgManip from imgIL.viewport
          imgIL.left = topLeftPoint.x - l.x1;
          imgIL.top = topLeftPoint.y - l.y1;
          imgIL.x1 = imgIL.left;
          imgIL.y1 = imgIL.top;
          imgIL.x2 = bottomRightPoint.x;
          imgIL.y2 = bottomRightPoint.y;
          imgIL.centerX = center.x;
          imgIL.centerY = center.y;

          // should contain real image height/width
          // cropped or zoomed - that sizes which is on canvas but scaled
          // to real template size
          imgIL.width = actualWidth;
          imgIL.height = actualHeight;

          // save source image sizes which editor is operate with
          // used in previewImgManip generation to resize image
          imgIL.sourceWidth = img.sourceWidth;
          imgIL.sourceHeight = img.sourceHeight;
          imgIL.realSourceWidth = img.realSourceWidth;
          imgIL.realSourceHeight = img.realSourceHeight;

          imgIL.scaleDx = img.scale;

          imgIL.rotation = rotation;

          // save viewport - template image layer
          // result image will be clipped by this
          // aka obj.parent in widget
          // obj.parent = getDataFn($el.parent().attr("id"))
          //     .data
          //     .getPosition()
          //     .scaleDOMPerceivedForPrint(scaleDx);
          imgIL.viewport = {
            x1: l.x1,
            x2: l.x2,
            y1: l.y1,
            y2: l.y2,
            width: l.x2 - l.x1,
            height: l.y2 - l.y1
          };

          layerIL.images.push(imgIL);
        });

        printIL.layers.push(layerIL);
      }

      previewIL.layers.push(layerIL);
    });

    // final crop image by total printable area
    printIL.final = {
      x1: state.template.finalX1,
      x2: state.template.finalX2,
      y1: state.template.finalY1,
      y2: state.template.finalY2
    };

    return {
      print: this._sanitizeIL(printIL),
      preview: this._sanitizeIL(previewIL)
    };
  }

  exportImageIL(template: Object, image: Object): Object {
    return this.exportIL({
      template,
      images: {
        current: {
          images: [image]
        }
      }
    });
  }

  importIL(il: Object): Array {
    // map print IL to editor state
    let imagesState = [];
    il.layers.forEach((l) => {
      if (l.images) {
        l.images.forEach((img, i) => {
          // map to editor image state
          let imgState = {
            id: IdGenerator.generateId(),
            layerId: l.layerId,
            src: img.url,
            initialScale: img.scaleDx,
            scale: img.scaleDx,
            zindex: i,
            rotation: img.rotation,
            x: img.centerX,
            y: img.centerY,
            sourceWidth: img.sourceWidth,
            sourceHeight: img.sourceHeight,
            realSourceWidth: img.realSourceWidth,
            realSourceHeight: img.realSourceHeight,
          };

          // map crop
          if (img.crop) {
            imgState.crop = {
              x: img.crop.x1,
              y: img.crop.y1,
              width: img.crop.width,
              height: img.crop.height
            };
          }

          imagesState.push(imgState);
        });
      }
    });

    return imagesState;
  }
}

export default new ILService();
