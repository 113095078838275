import React from 'react';
import PropTypes from 'prop-types';
import Toast from '../Toast';

const NotificationsComponent = props => {
  return props.notifications ? (
    <>
      {props.withBackDrop ? (
        <div className="back-drop" onClick={() => props.dismiss('backdrop')} />
      ) : null}
      <div className="notifications-container p-2">
        {props.notifications?.toJS().map(
          (msg, key) =>
            !msg.dismiss && (
              <div key={key} className="mb-2">
                <Toast
                  isOpen
                  // type={msg.type}
                  closeClick={() => props.dismiss(key)}
                >
                  {msg.text}
                </Toast>
              </div>
            )
        )}
      </div>
      <style jsx>
        {`
          .back-drop {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
          }
          .notifications-container {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto;
            z-index: 5;
          }
        `}
      </style>
    </>
  ) : null;
};

NotificationsComponent.propTypes = {
  withBackDrop: PropTypes.bool,
  dismiss: PropTypes.func,
  notifications: PropTypes.object
};

export default NotificationsComponent;
