import { createSelector } from 'reselect';

const billingViewSelector = state => state.getIn(['orders', 'billingView']);

export const billingViewData = createSelector(
  [billingViewSelector],
  billingViewStore => billingViewStore
);
export const billingLoading = createSelector([billingViewSelector], billingViewStore =>
  billingViewStore.get('loading')
);
export const billingErrors = createSelector([billingViewSelector], billingViewStore =>
  billingViewStore.get('errors')
);
export const costBreakdown = createSelector([billingViewSelector], billingViewStore =>
  billingViewStore.get('costBreakdown')
);
export const orderBilling = createSelector([billingViewSelector], billingViewStore =>
  billingViewStore.get('orderBilling')
);
