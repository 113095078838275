import { connect } from 'react-redux';
import Advanced from './Advanced';
import { isStorageOnlySelector } from './AdvancedSelectors';
import { holdForPersonalization } from './AdvancedActions';
import { holdForPersonalizationSelector } from '../../ProductPublishSelectors';
import Config from '../../../../config';

const mapDispatchToProps = {
  holdForPersonalization: payload => holdForPersonalization(payload)
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    customization: Config.get('holdForPersonalization') || isStorageOnlySelector(state),
    checked: holdForPersonalizationSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Advanced);
