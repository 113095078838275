import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import FileUpload from 'react16-fileupload';
import adminApiService from '../../../services/adminApiService';
import Config from '../../../config';
import { emailOrEmptyRegex, urlRegex } from 'gooten-components/src/utils/regex';
import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Icon from 'gooten-components/src/components/shared/Icon';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';

const Container = styled.div`
  .img-responsive,
  .btn-upload {
    width: 210px;
  }

  .img-missing {
    height: 150px;
  }

  .add-from-url-btn {
    height: 40px;
  }

  .upload-container {
    margin-top: 10px;
    .file-upload-container {
      float: left;
      margin-right: 10px;

      .btn.btn-default {
        height: 40px;
      }
    }
  }

  .__react_component_tooltip {
    max-width: 330px;
    text-align: center;
  }
`;

const tips = {
  OrderStatusPage:
    'The URL where the customer can check the status of their order; Leaving this blank will direct \
  customers to email the address below for order status.',
  ContentSupportEmail:
    'The email address where customers can request support and order status updates.',
  ConfirmationSubjectTemplate: 'The email subject used for confirmation emails.',
  ShippingSubjectTemplate: 'The email subject used for shipping notification emails.',
  Legalese: 'The copyright section at the bottom of emails.'
};

class EmailsContent extends React.Component {
  static get name() {
    return 'emailsContent';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Emails content',
      onFailure: 'Failed to update Emails content',
      onSuccess: 'Your Emails content has been updated'
    };
  }

  constructor(props) {
    super(props);
    this.inputs = {};

    const pId = Config.get('partnerId');
    this.fileUploadOptions = {
      baseUrl: adminApiService.getAdminApiUrl(
        `PartnersApi/UpdateLogo?partnerId=${pId}&onlyEmailLogo=true`
      ),
      chooseAndUpload: true,
      wrapperDisplay: 'block',
      multiple: false,
      numberLimit: 1,
      accept: 'image/jpeg,image/png',
      requestHeaders: adminApiService.getAuthHeaders(),
      chooseFile: files => {
        this.props.uploadStart(files);
      },
      uploadSuccess: resp => {
        this.props.uploadDone(resp);
      },
      uploadFail: resp => {
        this.props.uploadFailed(resp);
      }
    };
  }

  componentDidMount() {
    this.props.init();
  }

  changeUploadFromUrl(value) {
    this.props.changeUploadFromUrl(value);
  }

  onUrlUpload() {
    this.props.uploadFromUrlOpen
      ? this.props.uploadFrom(this.props.uploadFromUrl)
      : this.props.toggleUploadFromUrl();
  }

  get canUpdate() {
    return (
      this.props.ready &&
      this.props.data &&
      !this.props.data.equals(this.props.unmodified) &&
      Object.values(this.inputs).every(i => !i || i.isValid())
    );
  }

  renderInput(name, label, placeholder, className, regexp) {
    return (
      <>
        <TextInput
          className={className}
          id={name}
          showValidation
          label={label}
          regexp={regexp}
          validationMsg="Wrong format"
          placeholder={placeholder}
          onChange={(value, id) => this.props.change(value, id)}
          ref={ref => {
            this.inputs[name] = ref;
          }}
          value={this.props.data.get(name) || ''}
        />
        <div className="tooltip-email">
          <Tooltip content={tips[name]}>
            <Icon icon="alertCircle" />
          </Tooltip>
        </div>
        <style jsx>
          {`
            .tooltip-email {
              position: absolute;
              right: 1.5rem;
              top: 0.75rem;
            }
          `}
        </style>
      </>
    );
  }

  getSettingUpEmailTemplatesKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047283632-Setting-up-email-templates-for-order-and-shipping-confirmations';

    switch (partnerGroup.get('Id')) {
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612175554843-How-do-I-set-up-emails-for-order-and-shipping-notifications-';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047283632-Setting-up-email-templates-for-order-and-shipping-confirmations';
    }
  };

  render() {
    if (!this.props.useShippingConfirmation && !this.props.useOrderConfirmation) {
      return null;
    }
    return (
      <BaseCard card={EmailsContent.name} title="Customize Email Content">
        <Container>
          <div className="emails-content-card">
            <div className="row">
              <div className="col-xs-12">
                <p className="card-description">
                  Customize the content of emails. For more information read our&nbsp;
                  <a
                    href={this.getSettingUpEmailTemplatesKbLink()}
                    target="_blank"
                    className="settings-link"
                  >
                    mail template reference.
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <div className="logo-container">
                  {this.props.logo ? (
                    <img className="img-responsive" src={this.props.logo} alt={this.props.logo} />
                  ) : (
                    <div className="img-responsive email-img-missing text-center">
                      Upload PNG or JPG
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row upload-container">
              <div className="col-xs-12">
                <FileUpload options={this.fileUploadOptions} className="file-upload-container">
                  <span ref="chooseAndUpload">
                    <Button className="button-default large full-width" type="button">
                      Add logo
                    </Button>
                  </span>
                </FileUpload>
                {this.props.uploadFromUrlOpen ? (
                  <div className="d-flex">
                    <div>
                      {this.props.uploadFromUrlOpen ? (
                        <Input
                          type="text"
                          value={this.props.uploadFromUrl}
                          label="File URL"
                          placeholder="https://...."
                          onChange={e => this.changeUploadFromUrl(e.target.value)}
                        />
                      ) : null}
                    </div>
                    <Button
                      className="button-secondary extra-large ml-3"
                      onClick={() => this.onUrlUpload()}
                      disabled={
                        this.props.uploadFromUrlOpen && !urlRegex.test(this.props.uploadFromUrl)
                      }
                      type="button"
                    >
                      Add from Url
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="button-default large"
                    onClick={() => this.onUrlUpload()}
                    type="button"
                  >
                    {this.props.uploadFromUrlOpen ? 'Upload' : 'Add from Url'}
                  </Button>
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-8 col-xs-12">
                {this.renderInput(
                  'OrderStatusPage',
                  'Order Status Page',
                  'mystore.com/orderstatus',
                  '',
                  urlRegex
                )}
              </div>
              <div className="col-sm-8 col-xs-12">
                {this.renderInput(
                  'ContentSupportEmail',
                  'Support Email Address',
                  'support@mystore.com',
                  '',
                  emailOrEmptyRegex
                )}
              </div>
              <div className="col-sm-8 col-xs-12">
                {this.props.useOrderConfirmation
                  ? this.renderInput(
                      'ConfirmationSubjectTemplate',
                      'Order Confirmation Subject Line',
                      'Your Order Confirmation {{OrderID}}',
                      ''
                    )
                  : null}
              </div>
              <div className="col-sm-8 col-xs-12">
                {this.props.useShippingConfirmation
                  ? this.renderInput(
                      'ShippingSubjectTemplate',
                      'Shipping Confirmation Subject Line',
                      'Your order #{{OrderID}} has shipped!',
                      ''
                    )
                  : null}
              </div>
              <div className="col-sm-8 col-xs-12">
                {this.renderInput(
                  'Legalese',
                  'Legalese',
                  'Copyright@ 2019, All rights reserved.',
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="cart-buttons-block">
            <Button
              className="button-primary large"
              disabled={!this.canUpdate}
              onClick={() => this.props.update({ data: this.props.data })}
            >
              Save email content
            </Button>
            <Button className="button-default large ml-2" onClick={() => this.props.cancel()}>
              Cancel
            </Button>
          </div>
        </Container>
      </BaseCard>
    );
  }
}

EmailsContent.propTypes = {
  init: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.object,
  logo: PropTypes.string,
  unmodified: PropTypes.object,
  ready: PropTypes.bool,
  uploadStart: PropTypes.func.isRequired,
  uploadDone: PropTypes.func.isRequired,
  uploadFailed: PropTypes.func.isRequired,
  uploadFromUrlOpen: PropTypes.bool,
  uploadFromUrl: PropTypes.string,
  uploadFrom: PropTypes.func,
  toggleUploadFromUrl: PropTypes.func,
  changeUploadFromUrl: PropTypes.func,
  useShippingConfirmation: PropTypes.bool,
  useOrderConfirmation: PropTypes.bool
};

export default EmailsContent;
