import Config from '../config';
import { postJson } from '../utils/http';

const getPreviewGenerateUrl = () => `${Config.get('proPreviewApi')}generate/`;

// most of the parameters are irrelevant and it's just there to pass request
// validation on out clojure api endpoint. the only important parts of this
// request template are : 1- sku,
//                        2- template (which tells our api to forward the request
//                                     to our serverless api),
//                        3- command (contains required necktag image info)
// NOTE: keep is also irrevelant as it's passed as a queryparameter with the request.

const generateData = (sku, image) => {
  const imageLayer = sku.neckTagSpace.Layers.find(x => x.Type === 'Image');

  imageLayer.ImageUrl = image.imgUrl;

  return {
    template: 'classic',
    areas: [
      {
        layerId: 'string',
        spaceId: 'string',
        objId: 'string',
        url: 'string',
        commands: [
          {
            name: 'string',
            args: {}
          }
        ]
      }
    ],
    sku: 'necktag',
    keep: false,
    commands: [
      {
        name: 'necktag',
        args: {
          neckTagSpace: sku.neckTagSpace
        }
      }
    ]
  };
};

const getAuthQueryParams = () => ({
  queryParams: {
    recipeId: Config.get('recipeId'),
    partnerBillingKey: Config.get('storeApiKey'),
    staging: Config.get('env') === 'staging'
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

const generateNeckTagImageUrl = (skusWithNecktags, neckTagImage) =>
  Promise.all(
    skusWithNecktags.map(async sku => {
      const response = await postJson(
        getPreviewGenerateUrl(),
        generateData(sku, neckTagImage),
        getAuthQueryParams()
      );
      if (!response.error) {
        return {
          skuIndex: sku.skuIndex,
          sku: sku.sku,
          neckTagId: neckTagImage.id,
          neckTagImgUrl: response.url
        };
      } else {
        throw new Error('NeckTag image generation failed', response);
      }
    })
  );

export default generateNeckTagImageUrl;
