import { connect } from 'react-redux';
import CountrySelectionInline from './CountrySelectorInline';
import {
  supportedShippingCountriesOptionsSelector,
  cofShippingCountrySelector,
  defaultCountryCodeSelector
} from '../../../store/selectors/countriesSelectors';
import { updateShippingCountry } from '../../../store/actions/dataActions';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  const shippingCountries = supportedShippingCountriesOptionsSelector(state);
  const shippingCountry = cofShippingCountrySelector(state);
  const defaultCountryCode = defaultCountryCodeSelector(state);

  return {
    shippingCountries,
    shippingCountry,
    defaultCountryCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateShippingCountry: shippingCountry => dispatch(updateShippingCountry(shippingCountry))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelectionInline);
