export class PreviewOptions {

  // class to be added to toolBar container
  customClass: string;

  // false by default
  appendToBody: boolean;

  previewShown: boolean;

  maximizeShown: boolean;

  editorConfig: any;

  constructor(config: any, editorConfig: any) {
    this.editorConfig = editorConfig;
    // let defaultButtons = [
    //   {
    //     name: 'Product Preview',
    //   },
    //   {
    //     name: 'Print Preview',
    //   }
    // ];

    //init from config
    //or use defaults
    config = config || {};

    this.previewShown = config.previewShown === undefined ? true : config.previewShown;
    this.maximizeShown = config.maximizeShown === undefined ? true : config.maximizeShown;

    this.customClass = config.customClass || '';
    this.appendToBody = config.appendToBody === undefined ? false : config.appendToBody;
  }
}
