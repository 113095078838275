// @flow

import PositioningService from '../../utils/positioningService';

export class ImageService {

  /**
   * Return actual canvas states for all images, active image last
   * @param {Object} state
   */
  static getImagesCanvasStates(state) {
    let results = [];
    let selectedImage;
    for(let image of state.images.current.images) {
      let isLayerActive = state.images.current.selected.layerId === image.layerId;
      let layer = state.template.layers.find(l => l.id === image.layerId);
      let isImageSelected = state.images.current.selected.imageId === image.id;
      let imgCanvasState = this.getSingleImageCanvasState(state, image, layer, isLayerActive);
      if (isImageSelected) {
        selectedImage = imgCanvasState;
      }
      else {
        results.push(imgCanvasState);
      }
    }

    // put selected image last, so it will be rendered in top
    if (selectedImage) {
      results.push(selectedImage);
    }

    return results;
  }

  /**
   * Return actual canvas state of the selected image
   * @param {Object} state
   */
  static getSelectedImageCanvasState(state) {
    if (state.images.current.selected.imageId && state.images.current.selected.layerId) {
      let image = state.images.current.images.find(i => i.id === state.images.current.selected.imageId);
      let layer = state.template.layers.find(l => l.id === image.layerId);

      if (state.images.current.selected.layerId !== layer.id) {
        throw new Error('Selected image do not belong to selected layer');
      }

      return this.getSingleImageCanvasState(state, image, layer, true);
    }

    throw new Error('No selected image');
  }

  /**
   * Return actual canvas state of the given image
   * @param {Object} state
   */
  static getSingleImageCanvasState(state, image, layer, isLayerActive) {
    let imgClone = {...image};

    let imageArgs = {
      id: image.id,
      layerId: layer.id,
      opacity: 1,
      visible: true,
      clip: {
        x: 0,
        y: 0,
        width: state.editor.width,
        height: state.editor.height
      },
      image: image.image,
      rotation: image.rotation || 0,
    };

    if (image.crop) {
      imageArgs.crop = { ...image.crop };
    }

    // in preview mode, or when other layer is active now - clipping image by viewport rect
    if (state.editor.mode === 'preview' || !isLayerActive) {

      let viewportPosition = PositioningService.convertToCanvasSize(
        PositioningService.getViewportRect(layer),
        state
      );
      imageArgs.clip = viewportPosition;
    }
    else if (state.editor.mode === 'edit') {
      imageArgs.opacity = 0.7;
    }

    // set scaling from zoom api based on state.scale
    if(image.scale) {
      imgClone.width = image.realSourceWidth * image.scale;
      imgClone.height = image.realSourceHeight * image.scale;
    }

    // converting properties { x, y, width, height, scale } to canvas size
    // scaling depends on canvas size minus paddings
    let imagePosition = PositioningService.convertToCanvasSize(imgClone, state);

    Object.assign(imageArgs, imagePosition);

    // difference between canvas and full sized image
    imageArgs.scaleFromOriginal = image.realSourceWidth / imagePosition.width;
    // difference between canvas and image in memory (reduced to 1000x1000)
    imageArgs.scaleFromCurrent = image.sourceWidth / imagePosition.width;

    // Apply crop
    if (imageArgs.crop) {

      // Convert crop object to canvas size
      this._applyZoomToCrop(imageArgs);

      // If crop control is not active for this image - just apply crop by setting sourceImageCrop property
      if (!state.editor.cropActivated || state.images.current.selected.imageId !== image.id) {
        this._applyCrop(imageArgs);
      }
      // if crop is active - calculate coordinates of uncropped image to render it in crop control
      else {
        imageArgs.x = imageArgs.x - imageArgs.crop.x - imageArgs.crop.width / 2 + imageArgs.width / 2;
        imageArgs.y = imageArgs.y - imageArgs.crop.y - imageArgs.crop.height / 2 + imageArgs.height / 2;
        
        // hide image in image layer (crop control will render own image component)
        imageArgs.hidden = true;
      }
    }

    // get coordinates of top left point - needed by UI component
    imageArgs.x -= imageArgs.width/2;
    imageArgs.y -= imageArgs.height/2;

    return imageArgs;
  }

  //imageArgs - scaled to canvas image
  //returned by image.service
  static _applyZoomToCrop(imageArgs: any) {
    let crop = imageArgs.crop;
    

    crop.width = crop.width / imageArgs.scaleFromOriginal;
    crop.height = crop.height / imageArgs.scaleFromOriginal;
    crop.x = crop.x / imageArgs.scaleFromOriginal;
    crop.y = crop.y / imageArgs.scaleFromOriginal;
  }

  static _applyCrop(imageState: any) {
    // Konva crop works with real size of image. Since we use smaller image in editor - we need to convert crop object from canvas scale to image scale
    imageState.sourceImageCrop = {
      x: imageState.crop.x * imageState.scaleFromCurrent,
      y: imageState.crop.y * imageState.scaleFromCurrent,
      width: imageState.crop.width * imageState.scaleFromCurrent,
      height: imageState.crop.height * imageState.scaleFromCurrent
    };

    // override image width and height after crop applys
    this._setCroppedPositionToImage(imageState);
  }

  static _setCroppedPositionToImage(imageState: any) {
    let crop = imageState.crop;
    imageState.width = crop.width;
    imageState.height = crop.height;
  }

}

// singleton
export default ImageService;
