'use strict';
// @flow

import { AlignVerticalButton } from './alignVertical.button';
import { AlignHorizontalButton } from './alignHorizontal.button';
import { CropButton } from './crop.button';
import { CenterButton } from './center.button';
import { RotateButton } from './rotate.button';
import { OrientationButton } from './orientation.button';
import { UndoButton } from './undo.button';
import { RedoButton } from './redo.button';
import { RemoveButton } from './remove.button';

export default {
  AlignVerticalButton,
  AlignHorizontalButton,
  CropButton,
  CenterButton,
  RotateButton,
  OrientationButton,
  UndoButton,
  RedoButton,
  RemoveButton
};
