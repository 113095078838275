import { connect } from 'react-redux';
import OptionsPanel from './OptionsPanel';

import { getFilteredSkusMinCost, getFilteredSkusMaxCost } from '../../SKUSelectionSelectors';

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    minCost: getFilteredSkusMinCost(state),
    maxCost: getFilteredSkusMaxCost(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPanel);
