import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import ChangeTrackingComponent from './ChangeTrackingComponent';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import Tooltip from 'gooten-components/src/components/shared/Tooltip';
import PaymentImageComponent from './PaymentImageComponent';
import Icon from 'gooten-components/src/components/shared/Icon';
import moment from 'moment';
import Chip from 'gooten-components/src/components/shared/Chip';
import SecurityService from '../../../Settings/SecurityService';
import { Link } from 'react-router';
import PersonalizeActionsComponent from './PersonalizeActionsComponent';

const OrdersViewTableComponent = ({
  selectedItems,
  toggleSelectedRows,
  ordersListItems,
  updateSearchFields,
  searchFields,
  toggleSelectedRow,
  personalizeView
}) => {
  const chevronIcon = filter => {
    if (searchFields.SortField === filter) {
      return <Icon icon={searchFields.Desc ? 'chevronDown' : 'chevronUp'} />;
    } else {
      return null;
    }
  };

  const orderStatusCreator = (orderStatusId, orderStatusIds) => {
    if (orderStatusId) {
      return <Chip className="mt-1 ml-1" statusNumber={parseInt(orderStatusId)} />;
    }

    if (!orderStatusIds || !orderStatusIds.length) {
      return null;
    }

    return orderStatusIds.map(id => {
      return <Chip className="mt-1 ml-1" key={id} statusNumber={parseInt(id)} />;
    });
  };

  const tableColums = (viewType, userType) => {
    const itemView = '7.5rem 5.75rem auto auto auto auto auto auto auto auto auto';
    const orderView = '7.5rem auto auto auto auto auto auto auto auto';
    const personalizeViewColumns = 'auto auto auto auto auto auto auto';

    if (personalizeView) {
      return personalizeViewColumns;
    }

    if (
      viewType === 'OrderItem' &&
      SecurityService.isSuperAdmin() &&
      (searchFields.group === 3 || searchFields.group === 0)
    ) {
      return '7.5rem 5.75rem auto auto auto auto auto auto auto auto auto';
    }

    if (
      viewType === 'OrderItem' &&
      !SecurityService.isSuperAdmin() &&
      (searchFields.group === 3 || searchFields.group === 0)
    ) {
      return '7.5rem 5.75rem auto auto auto auto auto auto auto auto';
    }

    if (viewType === 'OrderItem' && SecurityService.isSuperAdmin()) {
      return '7.5rem 5.75rem auto auto auto auto auto auto auto auto auto auto';
    }

    return viewType === 'OrderItem' ? itemView : orderView;
  };

  const getPlatFormOrderId = item => {
    if (
      item.PartnerSourceId?.includes('etsy') ||
      item.PartnerSourceId?.includes('woo') ||
      item.PartnerSourceId?.includes('bigcommerce')
    ) {
      return item.PartnerSourceId?.split('-').reverse()[0];
    } else {
      return item.PartnerSourceId;
    }
  };

  return (
    <>
      <TableRow
        size="small"
        scrollableX
        className="orders-table pt-5 pb-4"
        columns={tableColums(searchFields.itemsType)}
      >
        {/* COLUMN HEADERS */}
        <Cell sticky noTextWrap className="text-left pl-0" header>
          <Checkbox
            checked={!!selectedItems.length}
            onChange={() => toggleSelectedRows(!!selectedItems.length)}
            partial={
              !!selectedItems.length &&
              !!ordersListItems.length &&
              selectedItems.length < ordersListItems.length
            }
          />
          <div
            className="pointer-cursor ml-3 d-flex align-items-center"
            onClick={() => {
              // sort by dbId in Orders view but orderId in OrderItems view
              if (searchFields.itemsType === 'OrderItem' && personalizeView) {
                updateSearchFields('Desc', !searchFields.Desc, 'sourceId');
              } else if (searchFields.itemsType === 'OrderItem') {
                updateSearchFields('Desc', !searchFields.Desc, 'orderId');
              } else {
                updateSearchFields('Desc', !searchFields.Desc, 'orderId');
              }
            }}
          >
            Order ID
            {searchFields.itemsType === 'OrderItem'
              ? chevronIcon('sourceId')
              : chevronIcon('orderId')}
            {personalizeView && (
              <Tooltip containerClassName="d-flex align-items-center" content="Platform order ID">
                <Icon small icon="helpCircle" />
              </Tooltip>
            )}
          </div>
        </Cell>
        {searchFields.itemsType === 'OrderItem' && (
          <Cell
            className="text-left pointer-cursor"
            header
            onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'orderItemId')}
          >
            Item ID
            {chevronIcon('orderItemId')}
            {personalizeView && (
              <Tooltip containerClassName="d-flex align-items-center" content="Gooten item ID">
                <Icon small icon="helpCircle" />
              </Tooltip>
            )}
          </Cell>
        )}
        {!personalizeView && (
          <Cell
            className="text-left pointer-cursor"
            header
            noTextWrap
            onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'sourceId')}
          >
            Partner Order ID {chevronIcon('sourceId')}
          </Cell>
        )}
        <Cell
          className="text-left pointer-cursor"
          header
          onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'dateCreated')}
        >
          Created {chevronIcon('dateCreated')}
        </Cell>
        {personalizeView && (
          <Cell className="text-left pointer-cursor" header>
            Items
          </Cell>
        )}
        {searchFields.itemsType === 'OrderItem' && (
          <Cell
            className="text-left pointer-cursor"
            header
            onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'sku')}
          >
            SKU {chevronIcon('sku')}
          </Cell>
        )}
        {searchFields.itemsType === 'Order' && (
          <Cell className="text-left" header>
            Items
          </Cell>
        )}
        {!personalizeView && (
          <Cell
            className="text-left pointer-cursor"
            header
            onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'partnerName')}
          >
            Partner {chevronIcon('partnerName')}
          </Cell>
        )}
        {!personalizeView &&
          searchFields.itemsType === 'OrderItem' &&
          SecurityService.isSuperAdmin() && (
            <Cell
              className="text-left pointer-cursor"
              header
              onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'vendorName')}
            >
              Vendor {chevronIcon('vendorName')}
            </Cell>
          )}
        {!personalizeView && (
          <Cell
            className="text-left pointer-cursor"
            header
            onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'countryCode')}
          >
            Country {chevronIcon('countryCode')}
          </Cell>
        )}
        <Cell
          className="text-left pointer-cursor"
          header
          onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'lastName')}
        >
          Name {chevronIcon('lastName')}
        </Cell>
        {!personalizeView && (
          <Cell
            className="text-left pointer-cursor"
            header
            onClick={() => updateSearchFields('Desc', !searchFields.Desc, 'total')}
          >
            Payment {chevronIcon('total')}
          </Cell>
        )}
        {!personalizeView && (
          <Cell className="text-left" header>
            Status
          </Cell>
        )}
        {!personalizeView &&
          searchFields.itemsType === 'OrderItem' &&
          !(searchFields.group === 3 || searchFields.group === 0) && (
            <Cell className="text-left" header>
              Tracking
            </Cell>
          )}
        {personalizeView && <Cell header />}
        {/* ORDER ITEM ROWS */}
        {ordersListItems.map(item => {
          // hide completed NeedsPersonalization (status 35) tasks in personalize view
          if (!personalizeView || item.OrderStatusId === 35) {
            return (
              <React.Fragment key={item.OrderItemId || item.OrderId}>
                <Cell
                  dataEvent={`checkbox ${item.OrderItemId || item.OrderId}`}
                  className="order-id pl-0"
                  sticky
                >
                  <Checkbox
                    checked={item.selectedByUser || false}
                    onChange={() => {
                      toggleSelectedRow({
                        type: searchFields.itemsType,
                        orderId: item.OrderItemId || item.OrderId
                      });
                    }}
                  />
                  <Link
                    className="no-text-decoration ml-3"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    <div className={!personalizeView && 'no-wrap-text'}>
                      {personalizeView ? getPlatFormOrderId(item) : item.OrderId}
                    </div>
                  </Link>
                </Cell>
                {searchFields.itemsType === 'OrderItem' && (
                  <Cell
                    dataEvent={`item-id ${item.OrderItemId || item.OrderId}`}
                    className="item-id"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    <div className="no-wrap-text">{item.Id}</div>
                  </Cell>
                )}

                {!personalizeView && (
                  <Cell
                    dataEvent={`partner-order-id ${item.OrderItemId || item.OrderId}`}
                    tooltip={item.PartnerSourceId}
                    className="partner-order-id pt-1 pb-1"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    <div className="no-wrap-text">{item.PartnerSourceId}</div>
                  </Cell>
                )}
                <Cell
                  dataEvent={`date-created ${item.OrderItemId || item.OrderId}`}
                  className="date-created"
                  to={`/orders-new/${item.OrderId}/summary`}
                >
                  <div className="no-wrap-text">
                    {moment(item.DateCreated).format('MMM DD, YYYY')}
                  </div>
                </Cell>
                {personalizeView && (
                  <Cell
                    dataEvent={`item-quantity ${item.OrderItemId || item.OrderId}`}
                    className="item-quantity"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    {item.Quantity}
                  </Cell>
                )}
                {searchFields.itemsType === 'OrderItem' && (
                  <Cell
                    dataEvent={`item-sku ${item.OrderItemId || item.OrderId}`}
                    className="item-sku"
                    tooltip={item.SKU}
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    <div>{item.SKU}</div>
                  </Cell>
                )}
                {searchFields.itemsType === 'Order' && (
                  <Cell
                    dataEvent={`items-count ${item.OrderItemId || item.OrderId}`}
                    className="item-count"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    {item.ItemsCount}
                  </Cell>
                )}
                {!personalizeView && (
                  <Cell
                    dataEvent={`item-partner-name ${item.OrderItemId || item.OrderId}`}
                    className="partner-name"
                    tooltip={item.PartnerName}
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    <div className="no-wrap-text">{item.PartnerName}</div>
                  </Cell>
                )}
                {!personalizeView &&
                  searchFields.itemsType === 'OrderItem' &&
                  SecurityService.isSuperAdmin() && (
                    <Cell
                      dataEvent={`vendor-name ${item.OrderItemId || item.OrderId}`}
                      className="vendor-name"
                      to={`/orders-new/${item.OrderId}/summary`}
                    >
                      <div className="no-wrap-text">{item.VendorName}</div>
                    </Cell>
                  )}
                {!personalizeView && (
                  <Cell
                    dataEvent={`country-image ${item.OrderItemId || item.OrderId}`}
                    className="country-image"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    <Tooltip content={item.ShipToCountry}>
                      <img
                        width="32px"
                        height="21px"
                        src={`https://appassets.azureedge.net/app-imgs/${item.ShipToCountry}.png`}
                      />
                    </Tooltip>
                  </Cell>
                )}
                <Cell
                  dataEvent={`ship-to-name ${item.OrderItemId || item.OrderId}`}
                  className="ship-to-name"
                  tooltip={item.ShipToName}
                  to={`/orders-new/${item.OrderId}/summary`}
                >
                  <div className="no-wrap-text">
                    {/* Only shows first name in Orders view UNLESS in NeedsPersonalization view */}
                    {searchFields.itemsType === 'OrderItem' && !personalizeView
                      ? item.ShipToName?.split(' ')[1]
                      : item.ShipToName}
                  </div>
                </Cell>
                {!personalizeView && (
                  <Cell
                    dataEvent={`items-price ${item.OrderItemId || item.OrderId}`}
                    className="items-price d-flex"
                    to={`/orders-new/${item.OrderId}/summary`}
                  >
                    {item.Total > 0 && (
                      <>
                        <PaymentImageComponent
                          paymentId={item.PaymentMethodId || item.PaymentMethod}
                        />
                        <div className="ml-1">
                          {item.Currency}&nbsp;
                          {item.PartnerChargedAmt?.toFixed(2) ||
                            (!!item.PartnerTotal && item.PartnerTotal.toFixed(2))}
                        </div>
                      </>
                    )}
                  </Cell>
                )}
                {!personalizeView && (
                  <Cell
                    dataEvent={`items-status ${item.OrderItemId || item.OrderId}`}
                    className="items-status flex-wrap pb-1"
                  >
                    {orderStatusCreator(item.OrderStatusId, item.OrderStatusIds)}
                  </Cell>
                )}
                {!personalizeView &&
                  searchFields.itemsType === 'OrderItem' &&
                  !(searchFields.group === 3 || searchFields.group === 0) && (
                    <Cell
                      dataEvent={`tracking-url ${item.OrderItemId || item.OrderId}`}
                      className="flex-wrap pb-1"
                    >
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <Tooltip
                          isToggleable
                          long
                          position="left"
                          tooltipChildren={closeTooltip => (
                            <ChangeTrackingComponent onSave={closeTooltip} item={item} />
                          )}
                        >
                          <div className="no-wrap-text tracking-info">{item.TrackingNumber}</div>
                        </Tooltip>
                        {item.TrackingUrl ? (
                          <a href={item.TrackingUrl} className="ml-1" target="_blank">
                            <Icon icon="externalLink" />
                          </a>
                        ) : null}
                      </div>
                    </Cell>
                  )}
                {personalizeView && (
                  <Cell>
                    <PersonalizeActionsComponent
                      orderId={item.OrderId}
                      itemId={item.Id}
                      platformOrderId={getPlatFormOrderId(item)}
                      dateCreated={moment(item.DateCreated).format('MMM DD, YYYY')}
                      canBeSplit={item.Quantity > 1}
                    />
                  </Cell>
                )}
              </React.Fragment>
            );
          }
        })}
      </TableRow>
      <style jsx>
        {`
          .no-wrap-text {
            max-width: ${searchFields.itemsType === 'Order'
              ? 'calc(6vw + 3rem)'
              : 'calc(4vw + 2rem)'};
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .tracking-info {
            cursor: pointer;
            max-width: 5.75rem;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
};

OrdersViewTableComponent.propTypes = {
  selectedItems: PropTypes.array,
  ordersListItems: PropTypes.array,
  toggleSelectedRows: PropTypes.func,
  toggleSelectedRow: PropTypes.func,
  updateSearchFields: PropTypes.func,
  searchFields: PropTypes.object,
  personalizeView: PropTypes.bool
};

export default OrdersViewTableComponent;
