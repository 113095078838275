import { connect } from 'react-redux';
import ImageSelectionModal from './ImageUploadModal';
import { reset, showUpload, IMAGE_FILE_UPLOAD } from './ImageUploadModalActions';
import { fetchAsync } from '../../../store/actions/dataActions';
import { imgUploadModalStateSelector } from './ImageUploadModalSelectors';

const mapDispatchToProps = {
  uploadFiles: files => fetchAsync(IMAGE_FILE_UPLOAD, { files }),
  showUpload,
  reset
};

const mapStateToProps = (state, ownProps) => {
  const componentState = imgUploadModalStateSelector(state);
  return {
    state: componentState,
    image: componentState.get('image') || ownProps.presetImage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelectionModal);
