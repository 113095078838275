import { connect } from 'react-redux';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import {
  fetchSuccess,
  loadCsv,
  publishCsv,
  validateCsv,
  resetValidateAndPublish
} from '../../StoreBulkMigrationActions';
import {
  csvDataSelector,
  fetchStoresErrorSelector,
  publishCsvError,
  publishCsvSuccess,
  showLoadingSelector,
  providersSelector,
  storesSelector,
  validateCsvError,
  validateCsvSuccess
} from '../../StoreBulkMigrationSelectors';
import PublishView from './PublishView';

const mapDispatchToProps = {
  fetchStores: payload => fetchSuccess(payload),
  validateCsv: payload => validateCsv(payload),
  publishCsv: payload => publishCsv(payload),
  loadCsv: payload => loadCsv(payload),
  resetValidateAndPublish: payload => resetValidateAndPublish(payload)
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: showLoadingSelector(state),
    providers: providersSelector(state),
    stores: storesSelector(state),
    fetchStoresError: fetchStoresErrorSelector(state),
    validateCsvError: validateCsvError(state),
    validateCsvSuccess: validateCsvSuccess(state),
    publishCsvError: publishCsvError(state),
    publishCsvSuccess: publishCsvSuccess(state),
    csvData: csvDataSelector(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(PublishView));
