import _fetch from 'isomorphic-fetch';

const queryParams = params => {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
};

export const tryPrefillWithQueryParams = (url, config) => {
  if (config && config.queryParams) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(config.queryParams);
    delete config.queryParams;
  }
  return url;
};

export const fetch = (url, config) => {
  return _fetch(tryPrefillWithQueryParams(url, config), {
    headers: {
      Accept: 'application/json'
    },
    ...config
  }).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      return new Promise((resolve, reject) => {
        res.json().then(parsedError => reject(parsedError));
      });
    }
  });
};

export const fetchBlob = (url, config) => {
  // NOTE: Browser can return cached version without CORS headers
  // https://bugs.chromium.org/p/chromium/issues/detail?id=158131
  return _fetch(url + '?_=blob', config).then(res => {
    if (res.ok) {
      return res.blob();
    } else {
      return Promise.reject(res);
    }
  });
};

export const post = (url, body = {}, config = {}) => {
  return _fetch(tryPrefillWithQueryParams(url, config), {
    method: 'POST',
    headers: {
      Accept: 'application/json'
    },
    body,
    ...config
  }).then(
    res => {
      if (res.ok) {
        if (res.status === 204) {
          // no content
          return Promise.resolve();
        } else {
          return res.json();
        }
      } else {
        return new Promise((resolve, reject) => {
          res
            .json()
            .then(parsedError => reject(parsedError))
            .catch(reject);
        });
      }
    },
    res => {
      return Promise.reject(res);
    }
  );
};

export const put = (url, body, config = {}) => {
  return _fetch(tryPrefillWithQueryParams(url, config), {
    method: 'PUT',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    ...config
  }).then(res => {
    if (res.ok) {
      if (res.status === 204) {
        // no content
        return Promise.resolve();
      } else if (res.status === 200) {
        return res.json();
      } else {
        return res.text();
      }
    } else {
      return res.status === 409
        ? new Promise((resolve, reject) => res.json().then(data => reject(data)))
        : Promise.reject(res);
    }
  });
};

export const $delete = (url, body = {}, config = {}) => {
  return _fetch(tryPrefillWithQueryParams(url, config), {
    method: 'DELETE',
    body,
    ...config
  }).then(res => {
    if (res.ok) {
      return Promise.resolve();
    } else {
      return new Promise((resolve, reject) => {
        res.json().then(parsedError => reject(parsedError));
      });
    }
  });
};

export const postJson = (url, body = {}, config = {}) => {
  return post(url, JSON.stringify(body), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    ...config
  });
};

export const fileUpload = (url, body, config) => {
  return _fetch(url, {
    method: 'POST',
    body: body,
    ...config
  }).then(res => {
    if (res.ok && res.status === 201) {
      return Promise.resolve();
    } else if (res.ok && res.status === 200) {
      return Promise.resolve(res.json());
    } else if (res.status === 400) {
      return Promise.reject();
    } else {
      return new Promise((resolve, reject) => {
        res.json().then(parsedError => reject(parsedError));
      });
    }
  });
};
