import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { productsQueryDataSelector, selectedProductsSelector } from '../Products/ProductsSelectors';
import {
  storesLoadedSelector,
  availableStoresSelector,
  selectedStoreSelector
} from '../Stores/StoresSelectors';
import { selectedProviderSelector } from '../Providers/ProvidersSelectors';
import productsService from '../Products/ProductsService';
import Config from '../../../../config';

export const productsCountSelector = createSelector(
  [productsQueryDataSelector],
  productsQueryData =>
    productsQueryData.get('loaded') ? productsQueryData.get('data').get('items').size : 0
);

export const viewByOptionsSelector = createSelector(
  [storesLoadedSelector, availableStoresSelector, selectedStoreSelector, selectedProviderSelector],
  (storesLoaded, availableStores, selectedStoreId, selectedProvider) => {
    if (!storesLoaded) {
      return new List();
    }

    if (selectedProvider === 'storage') {
      return new List();
    }

    let options = new List([
      new Map({ key: 'published', name: 'Published' }),
      new Map({ key: 'unpublished', name: 'Unpublished' })
    ]);

    if (selectedProvider === 'all') {
      // add all option to the beginning
      options = options.unshift(new Map({ key: '', name: 'All' }));
      // add all unique store collections names\
      const uniqueCollections = availableStores
        .flatMap(s => s.get('collections'))
        .map(c => new Map({ key: c.get('name'), name: c.get('name') }))
        .toSet();
      options = options.concat(uniqueCollections);
    } else if (selectedStoreId) {
      const selectedStore = availableStores.find(s => s.get('id') === selectedStoreId);
      // add selected store collections names
      options = options.concat(
        selectedStore
          .get('collections')
          .map(c => new Map({ key: c.get('name'), name: c.get('name') }))
          .toSet()
      );
    }

    return options;
  }
);

export const availableOptionsSelector = createSelector([selectedProductsSelector], products => {
  let canDelete,
    canUnpublish,
    canPublish,
    canPlaceOrder,
    canEdit,
    canDuplicate,
    canViewInStore,
    canUnlink,
    canPersonalize;
  canDelete = canUnlink = canUnpublish = canPublish = canPlaceOrder = products.size > 0;
  canPersonalize = products.size > 0 && !Config.get('holdForPersonalization');
  canEdit = canViewInStore = products.size === 1;
  canDuplicate = canViewInStore = products.size === 1;

  products.forEach(p => {
    canDelete = true;
    canUnlink = canUnlink && p.get('type') === 'store';
    canUnpublish = canUnpublish && p.get('type') === 'store' && p.get('status') === 'published';
    canPublish = canPublish && p.get('type') === 'store' && p.get('status') === 'unpublished';
    canPlaceOrder = true;
    canViewInStore =
      canViewInStore && !!productsService.getProductUrlInStore(p.get('storeName'), p);
    canPersonalize = canPersonalize && p.get('type') === 'store';
  });

  return {
    canPlaceOrder,
    canEdit,
    canDuplicate,
    canUnlink,
    canDelete,
    canUnpublish,
    canPublish,
    canViewInStore,
    canPersonalize
  };
});
