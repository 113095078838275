import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SidePanel from 'gooten-components/src/components/shared/SidePanel';
import Input from 'gooten-components/src/components/shared/Input';
import Multiselect from 'gooten-components/src/components/shared/Multiselect';
import MultiselectInfiniteScroll from 'gooten-components/src/components/shared/MultiselectInfiniteScroll';
import Config from '../../../config/';
import Chip from 'gooten-components/src/components/shared/Chip';
import SecurityService from '../../../Settings/SecurityService';
import { useDebounce } from 'use-debounce';

const SidePanelComponent = ({
  setOpenFilters,
  openFilters,
  sendAction,
  helpData,
  searchFieldData
}) => {
  const [filterState, setFilterState] = useState({
    OrderId: '',
    ShortId: '',
    BillingName: '',
    ShippingName: '',
    PartnerOrderId: '',
    ShippingEmail: '',
    VendorIds: [],
    StatusIds: [],
    Platform: [],
    PartnerIds: [],
    PartnerGroupIds: [],
    BeginningDate: '',
    EndingDate: '',
    OrderSource: ''
  });

  const [loadingPartners, setLoadingPartners] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [partnerResponseLength, setPartnerResponseLength] = useState(0);
  const [hasMorePartnerData, setHasMorePartnerData] = useState(true);
  const [endMessage, setEndMessage] = useState('');
  const [partnersPageNumber, setPartnersPageNumber] = useState(1);
  const [partnerSearchString, setPartnerSearchString] = useState('');

  const [debouncedPartnerSearchString] = useDebounce(partnerSearchString, 500);

  useEffect(() => {
    if (partnersData.length === 0 && !loadingPartners) {
      setHasMorePartnerData(false);
      setEndMessage('No results found');
    } else if (partnerResponseLength === partnersData.length) {
      setHasMorePartnerData(false);
      setEndMessage('No more results');
    } else {
      setHasMorePartnerData(true);
      setEndMessage('');
    }
  }, [partnersData, loadingPartners]);

  useEffect(() => {
    fetchPartnerData();
  }, [debouncedPartnerSearchString, partnersPageNumber]);

  // Sets state from parent
  useEffect(() => {
    const data = searchFieldData.toJS();

    setFilterState({
      ...filterState,
      OrderId: data.OrderId,
      ShortId: data.ShortId,
      BillingName: data.BillingName,
      ShippingName: data.ShippingName,
      PartnerOrderId: data.PartnerOrderId,
      ShippingEmail: data.ShippingEmail,
      VendorId: data.VendorId,
      VendorIds: createVendorSelectKeysFromIds(data.VendorIds),
      StatusIds: createStatusSelectKeysFromIds(data.StatusIds),
      PartnerGroupIds: createPartnerGroupSelectKeysFromIds(data.PartnerGroupIds),
      Platform: createPlatformsArray(data.Platform),
      BeginningDate: data.BeginningDate,
      EndingDate: data.EndingDate,
      Version: data.Version,
      OrderSource: data.OrderSource
    });
  }, [searchFieldData]);

  const [partnerName, setPartnerName] = useState('Partner');

  const fetchPartnerData = async () => {
    if (!(SecurityService.isSuperAdmin() || SecurityService.isPartnerAdmin())) {
      return;
    }
    setLoadingPartners(true);
    try {
      let url = `${Config.get(
        'adminApi'
      )}partnersapi/partnerselect?_=1596731914810&page=${partnersPageNumber}`;
      if (partnerSearchString) {
        url = `${url}&q=${partnerSearchString}`;
      }

      const fetchPartners = await fetch(url, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${Config.get('adminApiToken')}`,
          'content-type': `application/json`
        }
      });
      const partners = await fetchPartners.json();
      const parseStatePartnersData = partners.Item1.map(partner => {
        return {
          key: partner.Id,
          label: partner.CompanyName
        };
      });
      setPartnerResponseLength(partners.Item2);

      if (partnersPageNumber === 1) {
        setPartnersData(parseStatePartnersData);
      } else {
        setPartnersData([...partnersData, ...parseStatePartnersData]);
      }
      setLoadingPartners(false);
    } catch (e) {
      setLoadingPartners(false);
    }
  };

  const createVendorSelectKeysFromIds = data => {
    if (!data.length) {
      return [];
    }

    const updatedData = data.map(statusId => {
      let selectedItem = {};
      helpData.vendorsList.forEach(element => {
        if (element.Id === statusId) {
          selectedItem = element;
        }
      });
      return selectedItem;
    });
    return parseAllowedStatuses(updatedData);
  };

  const createPartnerGroupSelectKeysFromIds = data => {
    if (data === null || data === []) {
      return [];
    }

    const updatedData = data.map(statusId => {
      let selectedItem = {};
      helpData.partnerGroupsList.forEach(element => {
        if (element.Id === statusId) {
          selectedItem = element;
        }
      });
      return selectedItem;
    });
    return parseParterGroups(updatedData);
  };

  const createStatusSelectKeysFromIds = data => {
    const updatedData = data.map(statusId => {
      let selectedItem = {};
      helpData.allowedStatuses.forEach(element => {
        if (element.Id === statusId) {
          selectedItem = element;
        }
      });
      return selectedItem;
    });
    return parseAllowedStatuses(updatedData);
  };

  const createPlatformsArray = data => {
    let selectedItem = [];
    platforms.forEach(element => {
      if (element.key === data) {
        selectedItem.push(element);
      }
    });
    return selectedItem;
  };

  const platforms = [
    { key: 'API', label: 'API', orderSource: 'api' },
    { key: 'Shopify', label: 'Shopify', orderSource: 'Shopify' },
    { key: 'Etsy', label: 'Etsy', orderSource: 'Etsy' },
    { key: 'WooCommerce', label: 'WooCommerce', orderSource: 'WooCommerce' },
    { key: 'BigCommerce', label: 'BigCommerce', orderSource: 'BigCommerce' },
    { key: 'Tiktok', label: 'Tiktok', orderSource: 'Tiktok' }
  ];

  const updateFilterState = (key, value) => {
    setFilterState({
      ...filterState,
      [key]: value
    });
  };

  const parseAllowedStatuses = arrayData => {
    return arrayData.map(objectItem => {
      return {
        key: objectItem.Id,
        label: objectItem.Name
      };
    });
  };

  const parseParterGroups = arrayData => {
    if (!arrayData) {
      return [];
    }
    return arrayData.map(objectItem => {
      return {
        key: objectItem.Id,
        label: objectItem.Name
      };
    });
  };

  const setSelectedStatusKeys = selected => {
    setFilterState({
      ...filterState,
      StatusIds: [...selected]
    });
  };

  const filterStateCallbackParse = filterData => {
    const data = { ...filterData };
    data.StatusIds = data.StatusIds.map(idObject => {
      return idObject.key;
    });
    data.VendorIds = data.VendorIds.map(vendor => {
      return vendor.key;
    });
    data.PartnerGroupIds = data.PartnerGroupIds.map(partnerGroup => {
      return partnerGroup.key;
    });
    data.Platform = data.Platform.length ? data.Platform[0].key : '';
    sendAction(data);
  };

  const localTimeConversion = data => {
    if (!data) {
      return '';
    }

    const date = new Date(data);
    if (isNaN(date.getTime())) {
      return '';
    }

    const updatedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];
    return updatedDate;
  };

  const covertDateTime = data => {
    const date = new Date(data);
    const dateWithTimeZone = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
    return dateWithTimeZone.toJSON();
  };

  return (
    <SidePanel
      title="Filters"
      cancelClick={() => setOpenFilters(false)}
      actionClick={() => {
        filterStateCallbackParse(filterState);
        setOpenFilters(false);
      }}
      cancelText="Cancel"
      actionText="Apply"
      isOpen={openFilters}
    >
      <div className="section-wrapper pl-3 pr-3">
        <div className="selector-wrapper mb-4">
          <div className="overline">Status</div>
          <Multiselect
            title={'Any Status'}
            options={parseAllowedStatuses(helpData.allowedStatuses)}
            selectedOptions={filterState.StatusIds}
            onChange={setSelectedStatusKeys}
            config={{
              showFilter: true,
              multiple: true
            }}
          />
          {filterState.StatusIds.length ? (
            <Chip
              className="chip-override"
              iconLeft="x"
              onClick={() => {
                setFilterState({
                  ...filterState,
                  StatusIds: []
                });
              }}
            >
              Clear
            </Chip>
          ) : null}
        </div>
        <div className="selector-wrapper mb-4">
          <div className="date-wrapper m-0 row">
            <div className="col-sm-12 col-lg-6 p-1">
              <div className="overline">Start Date</div>
              <Input
                noLabel
                type="date"
                className="pl-2 pr-1"
                label="Start Date"
                value={localTimeConversion(filterState.BeginningDate)}
                onChange={e => {
                  setFilterState({ ...filterState, BeginningDate: covertDateTime(e.target.value) });
                }}
              />
            </div>
            <div className="col-sm-12 col-lg-6 p-1">
              <div className="overline">End Date</div>
              <Input
                noLabel
                type="date"
                className="pl-2 pr-1"
                label="End Date"
                value={localTimeConversion(filterState.EndingDate)}
                onChange={e => {
                  setFilterState({ ...filterState, EndingDate: covertDateTime(e.target.value) });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="seperator" />
      <div className="section-wrapper pl-3 pr-3">
        <div className="title-2 admin-title">Admin</div>
        <div className="selector-wrapper mb-4">
          {SecurityService.isSuperAdmin() && (
            <div className="selector-wrapper mb-4">
              <div className="overline">Partner Group</div>
              <Multiselect
                title={'Partner Group'}
                options={parseParterGroups(helpData.partnerGroupsList)}
                selectedOptions={filterState.PartnerGroupIds}
                onChange={selectedArray =>
                  setFilterState({ ...filterState, PartnerGroupIds: selectedArray })
                }
                config={{
                  showFilter: true,
                  multiple: false
                }}
              />
            </div>
          )}
          {(SecurityService.isSuperAdmin() || SecurityService.isPartnerAdmin()) && (
            <div className="selector-wrapper mb-4">
              <div className="overline">Partner</div>
              <MultiselectInfiniteScroll
                title={partnerName}
                options={partnersData}
                hasMore={hasMorePartnerData}
                endMessage={endMessage}
                onLoadMore={() => {
                  setPartnersPageNumber(partnersPageNumber + 1);
                }}
                dropdownOpen={openValue => {
                  if (!openValue) {
                    setPartnerSearchString('');
                    setPartnersPageNumber(1);
                  }
                }}
                initialValue={partnerSearchString}
                onFilterText={searchText => {
                  setPartnerSearchString(searchText);
                  setPartnersPageNumber(1);
                }}
                showLoading={loadingPartners}
                scrollHeight={250}
                onChange={selectedPartner => {
                  setPartnerName(selectedPartner[0].label);
                  setFilterState({
                    ...filterState,
                    PartnerIds: selectedPartner
                  });
                }}
                config={{
                  showFilter: true,
                  multiple: false
                }}
              />
              {partnerName !== 'Partner' && (
                <Chip
                  className="chip-override"
                  iconLeft="x"
                  onClick={() => {
                    setPartnerName('Partner');
                    setFilterState({
                      ...filterState,
                      PartnerIds: ''
                    });
                  }}
                >
                  Clear
                </Chip>
              )}
            </div>
          )}

          {SecurityService.isSuperAdmin() && (
            <div className="selector-wrapper mb-4">
              <div className="overline">Vendor</div>
              <Multiselect
                title={'Vendors'}
                options={parseAllowedStatuses(helpData.vendorsList)}
                selectedOptions={filterState.VendorIds}
                onChange={selectedArray =>
                  setFilterState({ ...filterState, VendorIds: selectedArray })
                }
                config={{
                  showFilter: true,
                  multiple: false
                }}
              />
            </div>
          )}

          <div className="selector-wrapper mb-4">
            <div className="overline">Platform</div>
            <Multiselect
              title={'Platform'}
              options={platforms}
              selectedOptions={filterState.Platform}
              onChange={selectedPlatform =>
                setFilterState({
                  ...filterState,
                  Platform: selectedPlatform,
                  orderSource:
                    selectedPlatform[0] && selectedPlatform[0].key == 'API'
                      ? selectedPlatform[0].orderSource
                      : ''
                })
              }
              config={{
                showFilter: true,
                multiple: false
              }}
            />
          </div>
          {!SecurityService.isVendor() && SecurityService.isPartnerOwner() && (
            <Input
              label="Version"
              value={filterState.Version}
              onChange={e => updateFilterState('Version', e.target.value)}
            />
          )}
          <Input
            label="Order Number"
            value={filterState.OrderId}
            onChange={e => updateFilterState('OrderId', e.target.value)}
          />
          <Input
            label="Order Short ID"
            value={filterState.ShortId}
            onChange={e => updateFilterState('ShortId', e.target.value)}
          />
          {!SecurityService.isVendor() && SecurityService.isPartnerOwner() && (
            <Input
              label="Billing Last Name"
              value={filterState.BillingName}
              onChange={e => updateFilterState('BillingName', e.target.value)}
            />
          )}
          <Input
            label="Shipping Last Name"
            value={filterState.ShippingName}
            onChange={e => updateFilterState('ShippingName', e.target.value)}
          />
          {!SecurityService.isVendor() && SecurityService.isPartnerOwner() && (
            <Input
              label="Partner Order ID"
              value={filterState.PartnerOrderId}
              onChange={e => updateFilterState('PartnerOrderId', e.target.value)}
            />
          )}
          {(SecurityService.isVendor() || SecurityService.isSuperAdmin()) && (
            <Input
              label="Vendor Order ID"
              value={filterState.VendorId}
              onChange={e => updateFilterState('VendorId', e.target.value)}
            />
          )}
          {!SecurityService.isVendor() && SecurityService.isPartnerOwner() && (
            <Input
              label="Customer Email"
              value={filterState.ShippingEmail}
              onChange={e => updateFilterState('ShippingEmail', e.target.value)}
            />
          )}
        </div>
      </div>
      <style jsx>{`
        .overline {
          margin-bottom: 0.25rem;
        }
        .admin-title {
          margin-bottom: 24px;
        }
        :global(.chip-override) {
          cursor: pointer !important;
          margin-top: 16px;
        }
        .selector-wrapper :global(.tooltip-container) {
          margin-left: auto !important;
        }
        .seperator {
          height: 16px;
          border-top: 1px solid #f4f4f5;
          border-bottom: 1px solid #f4f4f5;
          background-color: #fcfcfc;
          margin-bottom: 16px;
        }
        .section-wrapper :global(.dropdown-container),
        .section-wrapper :global(.dropdown-list) {
          max-width: 100%;
        }
      `}</style>
    </SidePanel>
  );
};

SidePanelComponent.propTypes = {
  setOpenFilters: PropTypes.func,
  openFilters: PropTypes.bool,
  sendAction: PropTypes.func,
  helpData: PropTypes.object,
  searchFieldData: PropTypes.object
};

export default SidePanelComponent;
