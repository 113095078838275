import { createSelector } from 'reselect';

const summaryViewSelector = state => state.getIn(['orders', 'summaryView']);

export const countries = createSelector([summaryViewSelector], orders =>
  orders.getIn(['countries', 'data']).toJS()
);

export const addressTypes = createSelector([summaryViewSelector], orders =>
  orders.getIn(['addressTypes', 'data']).toJS()
);

export const productionTimes = createSelector([summaryViewSelector], orders =>
  orders.getIn(['productionTimes']).toJS()
);

export const changeSkuData = createSelector([summaryViewSelector], orders =>
  orders.getIn(['changeSkuView']).toJS()
);

export const editingShippingAddress = createSelector([summaryViewSelector], orders =>
  orders.getIn(['editingShippingAddress'])
);

export const editingBillingAddress = createSelector([summaryViewSelector], orders =>
  orders.getIn(['editingBillingAddress'])
);

export const isShippingAddressEditDisabledSelector = createSelector([summaryViewSelector], orders =>
  orders.get('disableShippingAddressEdit')
);
