import * as ACTIONS from './actionTypes';
import IdGenerator from '../../utils/idGenerator';

export const templateLoad = (template={}) => {
  return {
    type: ACTIONS.TEMPLATE_LOAD,
    payload: template
  };
};

export const templateLoaded = (template={}) => {
  return {
    type: ACTIONS.TEMPLATE_LOADED,
    payload: template
  };
};

export const imageAdd = (image, layerId, allowMultiImages, width, height) => {
  let id = IdGenerator.generateId();
  return {
    type: ACTIONS.IMAGE_ADD,
    payload: {id, image, layerId, allowMultiImages, realSourceWidth: width, realSourceHeight: height}
  };
};

export const imageLoaded = (image=null) => {
  return {
    type: ACTIONS.IMAGE_LOADED,
    payload: image
  };
};

export const imageUpdate = (id, updates = {}) => {
  return {
    type: ACTIONS.IMAGE_UPDATE,
    payload: {id, ...updates}
  };
};

export const setCroppingMode = (isOn) => {
  return {
    type: isOn ? ACTIONS.EDITOR_CROPPING_MODE_ON : ACTIONS.EDITOR_CROPPING_MODE_OFF
  };
};

export const setCroppingSaveMode = (isOn) => {
  return {
    type: isOn ? ACTIONS.EDITOR_CROPPING_SAVE_MODE_ON : ACTIONS.EDITOR_CROPPING_SAVE_MODE_OFF
  };
};

export const imageDelete = (id) => {
  return {
    type: ACTIONS.IMAGE_DELETE,
    payload: {id}
  };
};

export function imageUndo() {
  return {
    type: ACTIONS.IMAGE_UNDO
  };
}

export function imageRedo() {
  return {
    type: ACTIONS.IMAGE_REDO
  };
}

export function imageClearHistory() {
  return {
    type: ACTIONS.IMAGE_CLEAR_HISTORY
  };
}

export const editorInit = (payload = {}) => {
  return {
    type: ACTIONS.EDITOR_INIT,
    payload: payload
  };
};

export const editorRestore = (payload = {}) => {
  return {
    type: ACTIONS.EDITOR_RESTORE,
    payload: payload
  };
};

export const editorReconstitute = (payload = {}) => {
  return {
    type: ACTIONS.EDITOR_RECONSTITUTE,
    payload: payload
  };
};

export function editActivate() {
  return {
    type: ACTIONS.EDITOR_MODE_EDIT
  };
}

export function editFinish() {
  return {
    type: ACTIONS.EDITOR_MODE_PREVIEW
  };
}

export function switchToPrintPreview() {
  return {
    type: ACTIONS.PREVIEW_MODE_PRINT
  };
}

export function switchToProductPreview() {
  return {
    type: ACTIONS.PREVIEW_MODE_PRODUCT
  };
}
export function enableBulkEditing(){
  return {
    type: ACTIONS.ENABLE_BULK_EDITING
  };
}
export function disableBulkEditing(){
  return {
    type: ACTIONS.DISABLE_BULK_EDITING
  };
}
export function enableBulkEditingAvailability () {
  return {
    type: ACTIONS.ENABLE_BULK_EDITING_AVAILABILITY
  };
}
export function disableBulkEditingAvailability () {
  return {
    type: ACTIONS.DISABLE_BULK_EDITING_AVAILABILITY
  };
}
export function select(imageId, layerId) {
  return {
    type: ACTIONS.SELECT,
    payload:{
      imageId,
      layerId
    }
  };
}

export function toLandscape() {
  return {
    type: ACTIONS.EDITOR_ORIENTATION_LANDSCAPE
  };
}

export function toPortrait() {
  return {
    type: ACTIONS.EDITOR_ORIENTATION_PORTRAIT
  };
}

export const setContainerColor = color => ({
  payload: color,
  type: ACTIONS.SET_CONTAINER_COLOR
});
