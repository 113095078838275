import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Wizard } from 'gooten-components';
import AppStore from '../../../store';
import Config from '../../../config';

const Container = styled.div`
  .status-message {
    text-align: center;
    margin-top: 50px;
  }
`;

class EditDesignView extends React.Component {
  static statusMessages = {
    initial: 'Loading products..',
    productsLoaded: 'Loading product variants..',
    variantsLoaded: 'Loading product details..',
    detailsLoaded: 'Loading templates..',
    productNotAvailable: 'Selected product is not available',
    unorderableSKUs: 'Selected variants are not available'
  };

  renderLoadingStatusMessage(status) {
    let message = EditDesignView.statusMessages[status] || 'Loading';
    return (
      <Container>
        <h3 className="status-message">{message}</h3>
      </Container>
    );
  }

  renderCOFWizard() {
    // get initial config and modify it depends on how you want Wizard to show up
    const config = Config.toJS();

    config.cof = true;

    config.analyticsSource = 'COF - Edit Design Flow';
    config.analyticsCategory = 'COF';

    config.steps = [
      {
        id: 'ImageUpload',
        config: {
          isOrderPageEdit: true,
          hideStatusBar: true,
          nextButtonLabel: 'Save Changes',
          backButtonLabel: 'Cancel',
          // placeOrderSource: this.props.params.step === 'place-order',
          // cartEditMode: {
          //   backButtonLabel: 'Cancel',
          //   nextButtonLabel: 'Update',
          //   parentStep: 'Cart'
          // },
          editDesignMode: {
            active: true
          },
          editorConfig: {
            container: '.gooten-image-editor',
            state: null,
            allowMultiImages: false,
            zoomControlShown: true,
            previewControlShown: false,
            toolbarShown: true,
            toolbarOptions: {
              customClass: 'my-toolbar',
              buttons: [
                {
                  type: 'alignHorizontal',
                  name: 'Align Horizontal',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                {
                  type: 'alignVertical',
                  name: 'Align Vertical',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                { type: 'crop', name: 'Crop', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'rotate', name: 'Rotate', customClass: 'my-toolbar-action editor-tooltip' },
                // { type: 'orientation', name: 'Orientation', customClass: 'my-toolbar-action' },
                { type: 'undo', name: 'Undo', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'redo', name: 'Redo', customClass: 'my-toolbar-action editor-tooltip' }
              ]
            }
          },
          sliderConfig: {
            width: 400,
            height: 600,
            contentHeight: 490
          }
        }
      },
      {
        id: 'Cart',
        config: {
          hideStatusBar: true,
          onKeepShoppingClick: () => {
            this.props.router.push('/custom-order-form');
            // NOTE: return false here to prevent default handler execute
            return false;
          }
        }
      }
    ];

    return <Wizard config={config} store={AppStore} />;
  }

  componentDidMount() {
    this.props.initEditWithPrpSku(this.props.prpSku);
  }

  render() {
    if (this.props.loadingStatus !== 'ready') {
      return this.renderLoadingStatusMessage(this.props.loadingStatus);
    } else {
      return this.renderCOFWizard();
    }
  }
}

EditDesignView.propTypes = {
  router: PropTypes.object.isRequired,
  loadingStatus: PropTypes.string.isRequired,
  initEditWithPrpSku: PropTypes.func.isRequired,
  prpSku: PropTypes.string
};

export default EditDesignView;
