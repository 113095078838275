import { Map, fromJS } from 'immutable';
import {
  IS_READY_CHANGE,
  WORKING_JOBS_CHANGE,
  INIT_ITEMS_TO_PROCESS,
  DECREMENT_ITEMS_TO_PROCESS,
  VARIANT_SELECT,
  DEFAULT_VARIANT_SELECT,
  EDITOR_MODE_CHANGE,
  TOGGLE_PANEL,
  RESET_IMAGE_UPLOAD,
  HIDE_IMAGES_MISSING_MODAL,
  SHOW_IMAGES_MISSING_MODAL,
  SHOW_SIDE_BAR,
  EDITOR_NEXT,
  SET_BULK_LOADER_PROGRESS,
  SET_NECK_LABELS_PREVIEWS_LOADING
} from './ImageUploadActions';
import { IMAGE_ADD } from '../../store/actions/dataActions';
import { IMAGE_EDITOR_BULK_MODE_TOGGLE } from './atoms/ImageEditor/ImageEditorActions';
import { RESET, GLOBAL_CLOSE_SIDEBAR } from '../../store/actions/globalActions';
import { BACK } from '../../containers/NavManager/NavManagerActions';
import { SHOW_DISCLAIMER } from './atoms/Embroidery/EmbroideryDisclamer/EmbroideryDisclaimerActions';

const DefaultState = new Map({
  selectedVariant: 0,
  selectedSpace: 0,
  editorMode: 'product',
  panelShown: true,
  workingJobs: 0,
  totalItemsToProcess: 0,
  remainingItemsToProcess: 0,
  isReady: false,
  bulkEdit: true,
  sideBarOpened: false,
  bulkLoaderProgress: null,
  embroideryDisclaimer: null,
  loadingNeckTagPreviews: false
});

// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [IS_READY_CHANGE]: (state, action) => state.set('isReady', action.payload),
  [WORKING_JOBS_CHANGE]: (state, action) => state.set('workingJobs', action.payload),
  [INIT_ITEMS_TO_PROCESS]: (state, action) =>
    state.set('totalItemsToProcess', action.payload).set('remainingItemsToProcess', action.payload),
  [DECREMENT_ITEMS_TO_PROCESS]: (state, action) =>
    state.set('remainingItemsToProcess', state.get('remainingItemsToProcess') - 1),
  [EDITOR_MODE_CHANGE]: (state, action) => state.set('editorMode', action.payload.mode),
  [TOGGLE_PANEL]: (state, action) => state.set('panelShown', !state.get('panelShown')),
  [VARIANT_SELECT]: (state, action) =>
    state
      .set('selectedVariant', action.payload.skuIndex)
      .set('selectedSpace', action.payload.spaceIndex),
  [DEFAULT_VARIANT_SELECT]: (state, action) =>
    state
      .set('selectedVariant', (action.payload.variants[0] || { index: 0 }).index)
      .set('selectedSpace', 0),
  [HIDE_IMAGES_MISSING_MODAL]: (state, action) => state.set('isImagesMissingModalOpen', false),
  [SHOW_IMAGES_MISSING_MODAL]: (state, action) => state.set('isImagesMissingModalOpen', true),
  [IMAGE_EDITOR_BULK_MODE_TOGGLE]: (state, action) => state.set('bulkEdit', action.payload),
  [IMAGE_ADD]: (state, action) => {
    // Disable bulk editing depends on if images were added via bulk or single SKU mode.
    // In single SKU mode variant indexes will be the same - for each space
    // In bulk mode images may be 1 or multi.
    // In case of 1 image - added via apply image to SKU
    // Check bulk flag
    if (!action.payload.images.length) {
      return state;
    }
    const newState = state.set('finishedEditorTasks', fromJS({}));

    const singleVariantIndex = action.payload.images.every(
      (img, i, arr) => img.variantIndex === arr[0].variantIndex
    );
    const isBulkApplyImage = action.payload.images[0].bulk;
    if (singleVariantIndex && !isBulkApplyImage) {
      return newState.set('bulkEdit', false);
    }
    return newState;
  },
  [GLOBAL_CLOSE_SIDEBAR]: (state, action) => state.set('sideBarOpened', false),
  [SHOW_SIDE_BAR]: (state, action) => state.set('sideBarOpened', action.payload),
  [BACK]: (state, action) => state.set('finishedEditorTasks', fromJS({})),
  [EDITOR_NEXT]: (state, action) => state.set('finishedEditorTasks', fromJS({})),
  [SET_BULK_LOADER_PROGRESS]: (state, action) =>
    state.set(
      'bulkLoaderProgress',
      fromJS({ current: action.payload.current, total: action.payload.total })
    ),
  [SHOW_DISCLAIMER]: (state, action) => state.set('embroideryDisclaimer', fromJS(action.payload)),
  [SET_NECK_LABELS_PREVIEWS_LOADING]: (state, action) =>
    state.set('loadingNeckTagPreviews', action.payload),
  [RESET_IMAGE_UPLOAD]: (state, action) => DefaultState,
  [RESET]: (state, action) => DefaultState
};

// ------------------------------------
// Reducer
// ------------------------------------
const ImageUploadReducer = (state = DefaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default ImageUploadReducer;
