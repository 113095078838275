import React from 'react';
import PropTypes from 'prop-types';
import Strings from './strings';
import Dialogue from '../Dialogue';

class ErrorModal extends React.PureComponent {
  render() {
    const errorTitle = this.props.errorTitle || Strings.GENERIC_ERROR_TITLE;
    const errorMessage = this.props.errorMessage || Strings.GENERIC_ERROR_MESSAGE;

    return (
      <div className="error-modal-container">
        <Dialogue
          isOpen={this.props.isOpen}
          cancelClick={this.props.modalClose}
          cancelText="Okay"
          title={errorTitle}
        >
          {errorMessage}
        </Dialogue>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  modalClose: PropTypes.func.isRequired,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string
};

export default ErrorModal;
