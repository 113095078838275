import { fromJS } from 'immutable';
import { initialState } from './ShippingTimeInitialState';
import { SHIPPING_TIME_LOAD } from './ShippingTimeActions';

export default (state = fromJS(initialState), action) => {
  switch (action.type) {
    case SHIPPING_TIME_LOAD.ASYNC:
      return state.set(
        'shippingData',
        fromJS({
          ...state.get('shippingData').toJS(),
          [action.payload.uniqueKey]: { loading: true }
        })
      );
    case SHIPPING_TIME_LOAD.SUCCESS:
      return state.set(
        'shippingData',
        fromJS({ ...state.get('shippingData').toJS(), ...action.payload })
      );
    case SHIPPING_TIME_LOAD.FAIL:
      return state.set(
        'shippingData',
        fromJS({
          ...state.get('shippingData').toJS(),
          [action.payload.uniqueKey]: { failed: true }
        })
      );
    default:
      return state;
  }
};
