import { delay } from 'redux-saga';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { SHIPPING_TIME_LOAD } from './ShippingTimeActions';
import ShippingTimeService from './services/shippingTimeService';
import { fetchAsyncSuccess, fetchAsyncFail } from '../../../store/actions/dataActions';

export function* shippingTimeFetchHandler(action) {
  yield call(delay, 500);
  const uniqueKey = JSON.stringify(action.payload.query);
  const currentDateTime = new Date();

  try {
    const shippingTime = yield call(
      [ShippingTimeService, ShippingTimeService.fetchShippingTimeData],
      action.payload.query
    );

    const reducerObject = {
      [uniqueKey]: {
        cachedDateTime: currentDateTime,
        query: action.payload.query,
        ...shippingTime
      }
    };

    yield put(fetchAsyncSuccess(SHIPPING_TIME_LOAD, reducerObject));
  } catch (err) {
    const reducerObject = {
      uniqueKey
    };
    yield put(fetchAsyncFail(SHIPPING_TIME_LOAD, reducerObject));
    throw err;
  }
}

export function* watchShippingLoad() {
  yield takeLatest(SHIPPING_TIME_LOAD.ASYNC, shippingTimeFetchHandler);
}

export default function* rootSaga() {
  yield all([watchShippingLoad()]);
}
