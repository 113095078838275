import { createSelector } from 'reselect';
import { totalSelector } from '../shared/OrderSummary/OrderSummarySelectors';
import { cofShippingCountrySelector } from '../../store/selectors/countriesSelectors';
import { isPrice } from '../../utils/price';
import { dataSelector } from '../../store/selectors/productDataSelectors';

const allStepsSelector = state => state.get('__nav__').get('steps');

const cartSelector = createSelector(dataSelector, data => {
  return data.get('cart');
});

const cartErrorsSelector = state => cartSelector(state).get('errors');

export const cartItemsSelector = createSelector(
  [cartSelector, cartErrorsSelector, cofShippingCountrySelector],
  (cart, errors, shippingCountry) => {
    const cartItemsRaw = cart.get('items');

    if (errors.size === 0) {
      return cartItemsRaw;
    }

    return cartItemsRaw.map(cartItem => {
      const skuLowercase = cartItem.getIn(['sku', 'sku']).toLowerCase();
      let itemSpecificError = errors.find(error => {
        const atv = error.get('AttemptedValue');
        if (atv) {
          const atvLowercase = atv.toLowerCase();
          return atvLowercase.includes(skuLowercase);
        }
      });
      if (!itemSpecificError) {
        itemSpecificError = errors.find(error => {
          const msg = error.get('Message') || error.get('ErrorMessage');
          if (msg) {
            const msgLowercase = msg.toLowerCase();
            return msgLowercase.includes(skuLowercase);
          }
        });
      }
      if (itemSpecificError) {
        let errorText = itemSpecificError.get('Message') || itemSpecificError.get('ErrorMessage');
        if (errorText.includes('unavailable for')) {
          errorText =
            `Sorry, we cannot ship this item to ${shippingCountry}. ` +
            `You can remove this product or update your shipping country.`;
        }
        return cartItem.set('itemSpecificError', errorText);
      } else {
        return cartItem.delete('itemSpecificError');
      }
    });
  }
);

export const sanitizedCartItemsSelector = createSelector(cartSelector, cart => {
  // Remove canvas images from state
  const cartItems = cart.get('items').toJS();
  cartItems.forEach(item => {
    // delete pricing - fetch new fresh prices
    delete item.pricing;
    // delete blob url so they will be re-generated
    delete item.previewImageUrl;
    item.sku.spaces.forEach(space => {
      space.template.layers.forEach(layer => {
        // delete canvas image which can't be serialized/deserialized
        delete layer.image;
      });
      // delete state which contains canvas images and also to reduce localStorage space usage
      delete space.dumpedState;
      // delete base64 preview to reduce localStorage space usage
      delete space.base64;
    });
  });
  return cartItems;
});

export const prodSelectionStepSelector = createSelector(allStepsSelector, allSteps => {
  return allSteps.filter(step => step === 'ProductSelection').first();
});

export const isCartItemPreviewShownSelector = createSelector(cartSelector, cart => {
  return cart.get('isCartItemPreviewShown');
});

export const previewImageUrlSelector = createSelector(cartSelector, cart => {
  return cart.get('previewImageUrl');
});

export const isCartRestoredSelector = createSelector(cartSelector, cart => {
  return cart.get('restored');
});

export const canProceedToCheckoutSelector = createSelector(
  [cartSelector, totalSelector],
  (cart, total) => {
    let canProceedToCheckout = true;
    // Do not allow to proceed to checkout if
    if (cart.get('errors') && cart.get('errors').size) {
      // cart errors
      canProceedToCheckout = false;
    } else if (!isPrice(total)) {
      // total is not valid price
      canProceedToCheckout = false;
    }

    return canProceedToCheckout;
  }
);
