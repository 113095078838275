import { createSelector } from 'reselect';
import PagedQuery from '../../../../models/PagedQuery';
import { cofShippingCountrySelector } from 'gooten-components/src/store/selectors/countriesSelectors';

const cofSelector = state => state.get('customOrderForm');

const pastOrdersViewSelector = createSelector([cofSelector], cof => cof.get('pastOrdersView'));

const pastOrdersSelector = createSelector([pastOrdersViewSelector], pastOrdersView =>
  pastOrdersView.get('pastOrders')
);

export const searchTextSelector = createSelector([pastOrdersViewSelector], pastOrdersView =>
  pastOrdersView.get('search')
);

export const isOrderItemsLoadedSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('loaded')
);

export const currentPageSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('page')
);

export const totalPagesSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('totalPages')
);

const pageSizeSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('pageSize')
);

export const totalSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('total')
);

export const orderItemsQuerySelector = createSelector(
  [searchTextSelector, currentPageSelector, pageSizeSelector, cofShippingCountrySelector],
  (searchText, currentPage, pageSize, shippingCountry) =>
    new PagedQuery({
      searchText: searchText,
      page: currentPage,
      pageSize: pageSize,
      countryCode: shippingCountry
    })
);

export const orderItemsSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('items')
);

export const selectedItemsSelector = createSelector([pastOrdersSelector], pastOrders =>
  pastOrders.get('selected')
);

const dataSelector = state => state.get('data');

const productsSelector = createSelector([dataSelector], data => data.get('products'));

export const outOfStockItemsSelector = createSelector([productsSelector], products =>
  products.filter(product => product.out_of_stock)
);
