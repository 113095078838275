import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const TEST_CONNECTION = customActionFor('WEBHOOKS').async('TEST_CONNECTION');

// ------------------------------------
// Actions
// ------------------------------------

export function testConnection(payload) {
  return {
    type: TEST_CONNECTION.ASYNC,
    payload
  };
}
