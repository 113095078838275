import ReprintSelfService from './index';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';

const reprintRoutes = {
  path: '/reprint',
  component: ReprintSelfService,
  indexRoute: { component: withErrorBoundary(ReprintSelfService) }
};

export default reprintRoutes;
