import { connect } from 'react-redux';
import OrderItemsSelection from './OrderItemsSelection';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import {
  groupedItemsSelector,
  orderItemsSelectionLoading,
  orderItemsArchivedStatuses
} from './OrderItemsSelectors';
import { getArchivedStatuses } from './OrderItemsSelectionActions';
import { summaryData } from '../OrderDetailsSelectors';

const mapDispatchToProps = {
  getArchivedStatuses: (vendorId, skus) => getArchivedStatuses(vendorId, skus)
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: orderItemsSelectionLoading(state),
    groupedItems: groupedItemsSelector(state),
    archivedStatuses: orderItemsArchivedStatuses(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(OrderItemsSelection));
