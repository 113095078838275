import { connect } from 'react-redux';
import ProductSelection from './ProductSelection';
import { flowSelectedIndexSelector } from './ProductSelectionSelectors';
import { setFlowSelectedIndex } from './ProductSelectionActions';

const mapDispatchToProps = {
  setFlowSelectedIndex: index => setFlowSelectedIndex(index)
};

const mapStateToProps = state => {
  return {
    flowSelectedIndex: flowSelectedIndexSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelection);
