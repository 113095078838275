import React from 'react';
import PropTypes from 'prop-types';
import StoreConnect from '../shared/StoreConnect';
import analyticsService from 'gooten-components/src/services/analyticsService';

import './BigCommerceConnect.scss';

// eslint-disable-next-line
const description = (
  <span>
    Please provide your store's unique subdomain name <br /> (the text preceeding your
    ".mybigcommerce.com" url)
    <br />
    You'll be redirected to your BigCommerce account <br /> and prompted to allow Gooten to access
    it. <br /> You can also connect directly{' '}
    <a href="https://apps.bigcommerce.com/details/26835">here</a>.
  </span>
);

const betaNotification =
  // eslint-disable-next-line no-multi-str
  'This is an early-release beta integration. If this is a critical piece of your business, we \
strongly recommend that you wait a few weeks until we’re finished with the official integration.';

class BigCommerceConnect extends React.Component {
  static propTypes = {
    connectStore: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { notification: { text: betaNotification, type: 'success' } };
    analyticsService._track(
      'Hub - Integrations',
      'Accessed',
      'BigCommerce',
      '/bigcommerce/connect-store',
      null
    );
  }

  render() {
    return (
      <div className="big-commerce-connect-container bla">
        <StoreConnect
          storeProvider="BigCommerce"
          description={description}
          inputPlaceholder="store-a1b2c3def.mybigcommerce.com"
          inputLabel="Store subdomain "
          connectStore={storeName => this.props.connectStore(storeName)}
        >
          <div className="flex-container border-block-img-big-commerce-container">
            <div className="border-block-img" />
          </div>
        </StoreConnect>
      </div>
    );
  }
}

export default BigCommerceConnect;
