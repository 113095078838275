import { ActionNameFactory } from '../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'ADVANCED');

export const HOLD_FOR_PERSONALIZATION = getActionName('HOLD_FOR_PERSONALIZATION');

export const holdForPersonalization = payload => ({
  type: HOLD_FOR_PERSONALIZATION,
  payload
});
