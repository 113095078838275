import { connect } from 'react-redux';
import MainPanel from './MainPanel';
import { generationStateSelector } from '../../ProductPreviewSelectors';
import { updatePreviewData } from './../../ProductPreviewActions';

const mapDispatchToProps = dispatch => {
  return {
    updatePreviewData: skus => {
      dispatch(updatePreviewData(skus));
    }
  };
};

const mapStateToProps = state => {
  return {
    generationState: generationStateSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPanel);
