export const tierGradients = {
  insider: {
    id: 'insider',
    startColor: '#6A85B6',
    endColor: '#BAC8E0',
    cardBackground:
      'linear-gradient(135deg, rgba(106, 133, 182, 0.1) 0%, rgba(186, 200, 224, 0.1) 100%)',
    badge: {
      x1: '0.666748',
      y1: '0.799988',
      x2: '22.8937',
      y2: '25.979'
    },
    icon: {
      x1: '0.666748',
      y1: '0.833374',
      x2: '18.9171',
      y2: '17.4246'
    },
    wheel: {
      x1: '4',
      y1: '4',
      x2: '42.4',
      y2: '23.2'
    },
    chip: {
      x1: '0.333374',
      y1: '0.666626',
      x2: '11.4468',
      y2: '13.2561'
    }
  },
  plus: {
    id: 'plus',
    startColor: '#0FFFA9',
    endColor: '#00CCD9',
    cardBackground:
      'linear-gradient(135deg, rgba(15, 255, 169, 0.1) 0%, rgba(0, 204, 217, 0.1) 100%), #FFFFFF',
    badge: {
      x1: '0.262939',
      y1: '0.262817',
      x2: '27.7375',
      y2: '27.7373'
    },
    icon: {
      x1: '0.666748',
      y1: '0.833374',
      x2: '18.9171',
      y2: '17.4246'
    },
    wheel: {
      x1: '4',
      y1: '4',
      x2: '42.4',
      y2: '23.2'
    },
    chip: {
      x1: '0.333374',
      y1: '0.666626',
      x2: '11.4468',
      y2: '13.2561'
    }
  },
  pro: {
    id: 'pro',
    startColor: '#21B3FD',
    endColor: '#4521FD',
    cardBackground:
      'linear-gradient(135deg, rgba(33, 179, 253, 0.1) 0%, rgba(69, 33, 253, 0.1) 100%)',
    badge: {
      x1: '0.666748',
      y1: '0.241394',
      x2: '26.159',
      y2: '26.8822'
    },
    icon: {
      x1: '0.666748',
      y1: '0.833374',
      x2: '18.9171',
      y2: '17.4246'
    },
    wheel: {
      x1: '4',
      y1: '4',
      x2: '42.4',
      y2: '23.2'
    },
    chip: {
      x1: '0.333374',
      y1: '0.666626',
      x2: '11.4468',
      y2: '13.2561'
    }
  },
  elite: {
    id: 'elite',
    startColor: '#AC32E4',
    endColor: '#4801FF',
    cardBackground:
      'linear-gradient(135deg, rgba(172, 50, 228, 0.1) 0%, rgba(72, 1, 255, 0.1) 100%)',
    badge: {
      x1: '0',
      y1: '0.381836',
      x2: '27.02',
      y2: '24.1911'
    },
    icon: {
      x1: '0.666748',
      y1: '0.833374',
      x2: '18.9171',
      y2: '17.4246'
    },
    wheel: {
      x1: '4',
      y1: '4',
      x2: '42.4',
      y2: '23.2'
    },
    chip: {
      x1: '0',
      y1: '0.190918',
      x2: '13.51',
      y2: '12.0955'
    }
  },
  allaccess: {
    id: 'allAccess',
    startColor: '#A445B2',
    endColor: '#FF2D2D',
    cardBackground:
      'linear-gradient(135deg, rgba(164, 69, 178, 0.1) 0%, rgba(255, 45, 45, 0.1) 100%)',
    badge: {
      x1: '0',
      y1: '0.381836',
      x2: '27.02',
      y2: '24.1911'
    },
    icon: {
      x1: '0.666748',
      y1: '0.833374',
      x2: '18.9171',
      y2: '17.4246'
    },
    wheel: {
      x1: '4',
      y1: '4',
      x2: '42.4',
      y2: '23.2'
    },
    chip: {
      x1: '0.333374',
      y1: '0.666626',
      x2: '11.4468',
      y2: '13.2561'
    }
  }
};

export const badgeModels = {
  insider: {
    width: 27,
    height: 24,
    viewBox: '0 0 27 24',
    path: 'M11.9686 1.59999C12.5845 0.533322 14.1241 0.533321 14.7399 1.59999L25.825 20.8C26.4409 21.8667 25.6711 23.2 24.4394 23.2H2.26914C1.03746 23.2 0.267654 21.8667 0.883494 20.8L11.9686 1.59999Z'
  },

  plus: {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    path: 'M12.8688 0.731446C13.4937 0.106607 14.5067 0.106608 15.1316 0.731447L27.2688 12.8687C27.8937 13.4935 27.8937 14.5066 27.2688 15.1314L15.1316 27.2687C14.5067 27.8935 13.4937 27.8935 12.8688 27.2687L0.731568 15.1314C0.106729 14.5066 0.10673 13.4935 0.731569 12.8687L12.8688 0.731446Z'
  },

  pro: {
    width: 28,
    height: 26,
    viewBox: '0 0 28 26',
    path: 'M13.0653 0.54512C13.6227 0.140152 14.3775 0.140152 14.9349 0.54512L26.6777 9.07683C27.2351 9.4818 27.4684 10.1996 27.2555 10.8549L22.7701 24.6595C22.5572 25.3147 21.9466 25.7584 21.2576 25.7584H6.74257C6.0536 25.7584 5.44298 25.3147 5.23008 24.6595L0.744693 10.8549C0.531789 10.1996 0.765023 9.4818 1.32241 9.07683L13.0653 0.54512Z'
  },

  elite: {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    path: 'M11.2302 0.588104C11.7066 0.31308 12.2934 0.31308 12.7698 0.588103L23.2302 6.62742C23.7066 6.90244 24 7.41071 24 7.96075V20.0394C24 20.5894 23.7066 21.0977 23.2302 21.3727L12.7698 27.412C12.2934 27.6871 11.7066 27.6871 11.2302 27.412L0.769801 21.3727C0.293447 21.0977 0 20.5894 0 20.0394V7.96076C0 7.41071 0.293446 6.90244 0.7698 6.62742L11.2302 0.588104Z'
  },

  allaccess: {
    width: 30,
    height: 25,
    viewBox: '0 0 30 25',
    path: 'M8.31461 0H14.9999H21.6852C22.0943 0 22.4808 0.187851 22.7336 0.509571L28.9519 8.42381C29.362 8.94566 29.3258 9.68961 28.867 10.1692L15.9634 23.6593C15.4383 24.2083 14.5614 24.2083 14.0364 23.6593L1.13276 10.1692C0.67402 9.68961 0.637831 8.94566 1.04785 8.42381L7.26619 0.509571C7.51897 0.187851 7.90546 0 8.31461 0Z'
  }
};

export const tierBenefits = {
  insider: [
    {
      icon: 'customerSupport',
      text: '72 hour first response times for customer support requests'
    },
    {
      icon: 'educationalVideo',
      text: 'Access to educational and industry content'
    },
    {
      icon: 'plusTwo',
      text: 'All the great features that come standard with Gooten'
    }
  ],
  plus: [
    {
      icon: 'customerSupport',
      text: '48 hour first response times for customer support requests'
    },
    {
      icon: 'creditCard',
      text: 'Annual sample credit'
    },
    {
      icon: 'educationalVideo',
      text: 'Access to educational and industry content'
    },
    {
      icon: 'plusTwo',
      text: 'All benefits from lower VIM Status'
    }
  ],
  pro: [
    {
      icon: 'customerSupport',
      text: '24 hour first response times for customer support requests'
    },
    {
      icon: 'partnerManager',
      text: 'Dedicated partnership manager'
    },
    {
      icon: 'pieChart',
      text: 'Personalized campaign planning support'
    },
    {
      icon: 'creditCard',
      text: 'Annual sample credit'
    },
    {
      icon: 'star',
      text: 'Product roadmap reviews & early-access to new features'
    },
    {
      icon: 'plusTwo',
      text: 'All benefits from lower VIM Status'
    }
  ],
  elite: [
    {
      icon: 'customerSupport',
      text: '12 hour first response times for customer support requests'
    },
    {
      icon: 'partnerManager',
      text: 'Dedicated partnership manager'
    },
    {
      icon: 'calendar',
      text: 'Custom cut-off dates during holidays'
    },
    {
      icon: 'educationalVideo',
      text: 'Access to educational and industry content'
    },
    {
      icon: 'star',
      text: 'Product roadmap reviews & early-access to new features'
    },
    {
      icon: 'analyticsArrowUp',
      text: 'Personalized campaign planning support'
    },
    {
      icon: 'pieChart',
      text: 'Quarterly & bi-weekly performance reports '
    },
    {
      icon: 'alertCircle',
      text: 'Escalation Path'
    },
    {
      icon: 'creditCard',
      text: 'Annual sample credit'
    },
    {
      icon: 'plusTwo',
      text: 'All benefits from lower VIM Status'
    }
  ],
  allaccess: []
};

export const tierRanks = [
  {
    name: 'insider',
    displayName: 'Insider',
    nextTier: 'plus'
  },
  {
    name: 'plus',
    displayName: 'Plus',
    nextTier: 'pro'
  },
  {
    name: 'pro',
    displayName: 'Pro',
    nextTier: 'elite'
  },
  {
    name: 'elite',
    displayName: 'Elite',
    nextTier: null
  }
];
