import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccountActivity from '../Cards/AccountActivity';

const Container = styled.div``;
class ActivityView extends React.Component {
  render() {
    return (
      <Container>
        <AccountActivity />
      </Container>
    );
  }
}

ActivityView.propTypes = {
  // eslint-disable-next-line
  router: PropTypes.object.isRequired
};

export default ActivityView;
