import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from 'gooten-components/src/components/shared/Alert';
import Button from 'gooten-components/src/components/shared/Button';
import Select from 'gooten-components/src/components/shared/Select';
import Input from 'gooten-components/src/components/shared/Input';
import Pager from 'gooten-components/src/components/shared/Pager';
import Loader from 'gooten-components/src/components/shared/Loader';
import HistoryTable from './components/HistoryTable';

const HistoryView = ({
  fetchHistoryDataAsync,
  setFilters,
  historyViewData,
  updatePageNumber,
  updateOrderId,
  params
}) => {
  useEffect(() => {
    updateOrderId(params.id);
  }, []);

  const updatedBy = [
    { value: 'Anyone', string: 'Anyone' },
    { value: 'Systems', string: 'Systems' },
    { value: 'Users', string: 'Users' }
  ];

  const updatedByOptions = updatedBy.map(option => (
    <option key={option.value}>{option.string}</option>
  ));

  const paginationItems = (totalNumber, divider) => {
    let i = 1;
    let items = [];

    totalNumber = totalNumber / divider;

    while (i < totalNumber + 1) {
      items.push({ name: i, id: i });
      i++;
    }

    return items;
  };

  const updateDateFilter = (key, value) => {
    value = new Date(value).toISOString();
    setFilters({ [key]: value });
  };

  const converDateToDateString = data => {
    if (data) {
      return data.substring(0, 10);
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="history-tab mt-4">
        <div className="history-filters-row m-n1">
          <div className="p-1">
            <Select
              onChange={e => setFilters({ UserType: e.target.value })}
              noLabel
              placeholder="Updated By"
              value={historyViewData.get('filters').get('UserType')}
              label="Updated By"
            >
              {updatedByOptions}
            </Select>
          </div>
          <div className="p-1">
            <Input
              type="date"
              onChange={e => updateDateFilter('StartDate', e.target.value)}
              value={converDateToDateString(historyViewData.get('filters').get('StartDate')) || ''}
              noLabel
              placeholder="Start Date"
              label="Start Date"
            />
          </div>
          <div className="p-1">
            <Input
              type="date"
              onChange={e => updateDateFilter('EndDate', e.target.value)}
              value={converDateToDateString(historyViewData.get('filters').get('EndDate')) || ''}
              noLabel
              placeholder="End Date"
              label="End Date"
            />
          </div>
          <div className="p-1">
            <Button onClick={() => fetchHistoryDataAsync()} className="button-primary large w-100">
              Apply Filters
            </Button>
          </div>
        </div>
        <hr className="medium" />

        <HistoryTable historyViewData={historyViewData} />

        {historyViewData.get('loading') && <Loader />}

        {!historyViewData.getIn(['data', 'items']).length &&
          !historyViewData.get('errors').length &&
          !historyViewData.get('loading') && (
            <Alert isOpen type="warning">
              There is no history attached to this order
            </Alert>
          )}

        {historyViewData.get('errors').length > 0 && (
          <Alert isOpen type="important">
            There was an error trying to fetch history data
          </Alert>
        )}

        <Pager
          current={historyViewData.get('filters').get('Page') - 1}
          maxSize={10}
          items={paginationItems(
            historyViewData.get('data').get('total'),
            historyViewData.get('filters').get('Size')
          )}
          goTo={pageNumber => updatePageNumber(pageNumber + 1)}
        />
      </div>
      <style jsx>
        {`
          .history-filters-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
          @media screen and (max-width: 767px) {
            .history-filters-row {
              grid-template-columns: 1fr;
            }
          }
        `}
      </style>
    </>
  );
};

HistoryView.propTypes = {
  fetchHistoryDataAsync: PropTypes.func,
  setFilters: PropTypes.func,
  historyViewData: PropTypes.object,
  updatePageNumber: PropTypes.func,
  updateOrderId: PropTypes.func,
  params: PropTypes.object.isRequired
};

export default HistoryView;
