import React from 'react';
import Button from 'gooten-components/src/components/shared/Button';

export const UploadCSVButton = ({ onUpload }) => {
  const hiddenFileInput = React.useRef(null);

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      onUpload(text);
      hiddenFileInput.current.value = null;
    };
    reader.readAsText(fileUploaded);
  };
  return (
    <>
      <input
        type={'file'}
        accept=".csv"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Button
        className={'button-default large ml-1 mr-4 mb-4'}
        iconLeft="uploadCloud"
        onClick={() => hiddenFileInput.current.click()}
      >
        Upload CSV
      </Button>
    </>
  );
};

export default UploadCSVButton;
