import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from './Icon';
import TextInput from './TextInput';

const CopyOrChange = ({ isEditable, initialValue, onSave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);

  return (
    <div className={`copy-or-change ${isOpen ? 'open' : ''}`}>
      <Button
        className="copy-btn button-default"
        iconOnly
        onClick={() => navigator.clipboard.writeText(initialValue)}
      >
        <Icon icon="copy" />
      </Button>
      {isEditable && (
        <>
          <TextInput noLabel value={initialValue} onChange={setValue} />
          <Button
            onClick={_ => {
              if (!isOpen) {
                setIsOpen(true);
              } else {
                setIsOpen(false);
                onSave(value);
              }
            }}
            className="edit-btn button-default"
          >
            {isOpen ? 'Save' : 'Edit'}
          </Button>
        </>
      )}
      <style jsx>
        {`
          .copy-or-change {
            display: flex;
          }

          .copy-or-change :global(.text-input-container) {
            margin: 0;
            width: 0;

            transition: width 250ms;
          }

          .copy-or-change.open :global(.text-input-container) {
            width: 172px;
          }

          .copy-or-change :global(input) {
            border-radius: 0;
          }

          .copy-or-change :global(.copy-btn) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          .copy-or-change :global(.edit-btn) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 1px solid #dedfe1;
            width: 64px;
          }

          .copy-or-change.open :global(.edit-btn) {
            border-left: none;
          }
        `}
      </style>
    </div>
  );
};

CopyOrChange.propTypes = {
  isEditable: PropTypes.bool,
  initialValue: PropTypes.string,
  onSave: PropTypes.func
};

export default CopyOrChange;
