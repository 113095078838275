import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import './DuplicateChanges.scss';
import Button from '../../../../shared/Button';
import DropDown from '../../../../shared/DropDown';
import ListItem from '../../../../shared/ListItem';

const DuplicateChanges = props => {
  const [showStoreItems, setShowStoreItems] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState(fromJS([]));

  const handleStoreItemClick = index => {
    if (selectedIndexes.includes(index)) {
      setSelectedIndexes(selectedIndexes.filter(i => i !== index));
    } else {
      setSelectedIndexes(selectedIndexes.push(index));
    }
  };

  const handleApply = () => {
    setShowStoreItems(false);
    props.duplicateChanges(props.options, selectedIndexes);
    setSelectedIndexes(fromJS([]));
  };

  const enabledStores = props.stores.filter(store => {
    const disabledOptions =
      (props.disabledStoreOptions && props.disabledStoreOptions[store.get('provider')]) || [];

    // disable the store all the desired options are disabled
    return props.options.filter(opt => !disabledOptions.includes(opt)).length;
  });

  const storeItems = props.stores.map((s, i) => {
    if (!enabledStores.includes(s)) return null;

    const value = s.get('sId')
      ? `(${s.get('provider').charAt(0).toUpperCase() + s.get('provider').slice(1)}) ${s.get(
          'name'
        )}`
      : s.get('name');

    return (
      <ListItem
        key={value + i}
        text={value}
        rightIcon={selectedIndexes.includes(i) ? 'check' : null}
        onSelect={() => handleStoreItemClick(i)}
      />
    );
  });

  return enabledStores.size ? (
    <div className="product-publish-duplicate-changes pb-4">
      <span className="section-title">Duplicate changes to another store</span>
      <DropDown
        toggle={() => setShowStoreItems(!showStoreItems)}
        title={'Select stores'}
        isOpen={!!showStoreItems}
      >
        <div>
          {storeItems}
          <div className="mr-n2 ml-n2 mb-1">
            <Button
              className={`mt-1 button-secondary large full-width ${
                selectedIndexes.size || 'disabled'
              }`}
              onClick={() => selectedIndexes.size && handleApply()}
            >
              Apply
            </Button>
          </div>
        </div>
      </DropDown>
      <style jsx>
        {`
          .product-publish-duplicate-changes {
            flex-direction: column;
            width: 45%;
            position: relative;
          }
          .section-title {
            font-size: 12px;
            font-weight: 900;
          }
        `}
      </style>
    </div>
  ) : null;
};

DuplicateChanges.propTypes = {
  stores: PropTypes.instanceOf(List).isRequired,
  options: PropTypes.array.isRequired,
  disabledStoreOptions: PropTypes.object,
  duplicateChanges: PropTypes.func.isRequired
};

export default DuplicateChanges;
