import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ErrorMessage from '../shared/ErrorMessage';
import DropDownContainer from '../../../shared/DropDown/DropDownContainer';
import NeckTagUploadModal from '../../../shared/NeckTagUploadModal';
import Button from '../../../shared/Button';
import ListItem from '../../../shared/ListItem';

const Container = styled.div`
  margin-top: 50px;
  flex-direction: column;
  p {
    font-size: 12px;
    line-height: 18px;
  }
  .create-button {
    margin-top: 30px;
  }
  .price-necktag {
    color: green;
    margin-top: 8px;
    margin-bottom: -15px;
  }
  .btn.btn-default.pull-right {
    background-color: white;
    color: #62c3b1;
    border-color: #62c3b1;
  }
  .disabled-select {
    outline: none;
    cursor: not-allowed;
    border: none;
    &:hover {
      outline: none;
      cursor: not-allowed;
      border: none;
    }
    &:focus {
      outline: none;
      cursor: not-allowed;
      border: none;
    }
  }
`;

const NeckLabels = ({
  error,
  fetchNeckTags,
  neckLabelList,
  setSelectedNeckTag,
  createNeckTag,
  skusWithNeckTags,
  selectedNeckLabel
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');

  // if all skus selected don't contain a neck tag space, hide this component.
  const showComponent = !!skusWithNeckTags.size;

  useEffect(() => {
    showComponent && !selectedNeckLabel && fetchNeckTags();
  }, []);

  const NeckLabelItems = neckLabelList.map(item => (
    <ListItem
      key={item.id}
      onSelect={() => setSelectedNeckTag({ neckTagId: item.id, skusWithNeckTag: skusWithNeckTags })}
      text={item.name}
      leftIcon="tag"
    />
  ));

  const createNewNeckLabel = image => {
    createNeckTag(image);
    setModalOpen(false);
    setNewTagName('');
  };

  if (showComponent) {
    return (
      <Container className="product-publish-necklabels flex-container">
        {error ? <ErrorMessage message={error} /> : null}
        {isModalOpen ? (
          <NeckTagUploadModal
            neckLabelsList={neckLabelList}
            onSave={image => createNewNeckLabel(image)}
            onCancel={() => setModalOpen(false)}
            onChange={(value, id) => setNewTagName(value)}
            value={newTagName}
          />
        ) : null}
        <div className="title-holder d-flex justify-content-start">
          <span className="title-2">Neck Label</span>
        </div>
        <p>
          Create customized neck labels that can be applied to eligible apparel. To learn more and
          see a list of eligible products visit our blog post.
        </p>
        <div className="option-select-container flex-container">
          <DropDownContainer
            title={selectedNeckLabel ? selectedNeckLabel.name : 'Select Label'}
            disabled={!neckLabelList.size}
            className={!neckLabelList.size ? 'disabled-select' : ''}
          >
            {selectedNeckLabel ? (
              <ListItem
                onSelect={() =>
                  setSelectedNeckTag({ neckTagId: null, skusWithNeckTag: skusWithNeckTags })
                }
                text="Remove neck label"
                leftIcon="trash"
              />
            ) : null}
            {NeckLabelItems}
          </DropDownContainer>
        </div>
        {selectedNeckLabel ? <p className="price-necktag">+$2.00</p> : null}
        <div className="create-button">
          <Button
            className="create-necklabel button-default medium"
            onClick={() => setModalOpen(true)}
          >
            Create New
          </Button>
        </div>
      </Container>
    );
  }
  return null;
};

NeckLabels.propTypes = {
  error: PropTypes.string,
  neckLabelList: PropTypes.any,
  selectedNeckLabel: PropTypes.any,
  createNeckTag: PropTypes.func.isRequired,
  setSelectedNeckTag: PropTypes.func.isRequired,
  fetchNeckTags: PropTypes.func.isRequired,
  skusWithNeckTags: PropTypes.instanceOf(List)
};

export default NeckLabels;
