// Main javascript entry point
// Should handle bootstrapping/starting application

// This is really just exports

'use strict';

import ImageEditorControl from './imageEditorControl';

var glob = typeof global !== 'undefined'
  ? global
  : typeof window !== 'undefined'
    ? window
    : typeof WorkerGlobalScope !== 'undefined' ? self : {};

if (glob.ImageEditorControl) {
  console.error(
    'ImageEditorControl instance is already exist in current eviroment. ' +
      'Please use only one instance.'
  );
}
glob.ImageEditorControl = ImageEditorControl;

export default ImageEditorControl;

// if (typeof exports === 'object') {
//   module.exports = ImageEditorControl;
// }
// else if (typeof define === 'function' && define.amd) {
//   // AMD. Register as an anonymous module.
//   define(function() {
//     return ImageEditorControl;
//   });
// }
