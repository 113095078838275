import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Icon from '../../../shared/Icon';
import Checkbox from '../../../shared/Checkbox';
import Tooltip from '../../../shared/Tooltip';

const ProvidersSelectionPanel = props => {
  const iconName = provider => {
    switch (provider) {
      case 'etsy':
        return 'etsy';
      case 'woocommerce':
        return 'wooCommerce';
      case 'shopify':
        return 'shopify';
      case 'bigcommerce':
        return 'bigCommerce';
      case 'tiktok':
        return 'tiktok';
      case undefined:
        return 'gootenLogoAlt';
    }
  };

  const tooltipProviderName = provider => {
    switch (provider) {
      case 'etsy':
        return 'Etsy';
      case 'woocommerce':
        return 'WooCommerce';
      case 'shopify':
        return 'Shopify';
      case 'bigcommerce':
        return 'BigCommerce';
      case 'tiktok':
        return 'TikTok';
      case undefined:
        return 'Gooten';
    }
  };

  const disableDeselect = p => {
    if (props.isDisabled) {
      return true;
    }

    const numberOfStoresEnabled = props.availableProviders
      .toJS()
      .filter(provider => provider.enabled);
    if (numberOfStoresEnabled.length === 1 && p.get('enabled')) {
      return true;
    }
  };

  const providers = useMemo(
    () =>
      props.availableProviders.map(p => (
        <div className="provider" key={p.get('name') + p.get('provider')}>
          <div className="provider-holder w-100">
            <Checkbox
              noBorder
              checked={p.get('enabled') || false}
              disabled={disableDeselect(p)}
              onChange={() => props.changeProviderState(p)}
              label={
                <span className="d-flex">
                  <Tooltip content={tooltipProviderName(p.get('provider'))}>
                    <Icon className="provider-icon mr-2" icon={iconName(p.get('provider'))} />
                  </Tooltip>
                  <Tooltip content={p.get('name')}>
                    <div className="provider-name">{p.get('name')}</div>
                  </Tooltip>
                </span>
              }
            />
          </div>
          <style jsx>{`
            .provider {
              height: 22px;
              margin-bottom: 27px;
            }
            .provider:last-child {
              margin-bottom: 0px;
            }
            .provider-holder {
              cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
            }
            .provider-holder :global(.provider-name) {
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 8rem;
            }
            @media screen and (max-width: 992px) {
              .provider-holder :global(.provider-name) {
                width: calc(100vw - 18rem);
                max-width: calc(100vw - 18rem);
              }
            }
            @media screen and (max-width: 767px) {
              .provider-holder :global(.provider-name) {
                width: calc(100vw - 10rem);
                max-width: calc(100vw - 10rem);
              }
            }
            .provider-holder :global(.label-container) {
              white-space: nowrap;
            }
            .provider-holder :global(.provider-icon) {
              margin: -2px 2px 0 5px;
            }
          `}</style>
        </div>
      )),
    [props.availableProviders.map(p => p.get('enabled')).toString()]
  );

  return (
    <div className="flex-container mb-4 p-4" disabled={props.isDisabled}>
      <span className="overline mb-4">Sales Channels</span>
      {providers}
      <style jsx>
        {`
          .flex-container {
            flex-direction: column;
            background-color: 'white';
            border: 1px solid #ededed;
            border-radius: 4px;
          }
          @media only screen and (max-width: 991px) {
            .flex-container {
              border-left: none;
              border-right: none;
              border-radius: none;
            }
          }
        `}
      </style>
    </div>
  );
};

ProvidersSelectionPanel.propTypes = {
  changeProviderState: PropTypes.func,
  availableProviders: PropTypes.instanceOf(List).isRequired,
  isDisabled: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ProvidersSelectionPanel;
