import { connect } from 'react-redux';
import EditDesignView from './EditDesignView';
import {
  loadingStatusSelector,
  hasRequiredProductInfoSelector
} from '../../../CustomOrderForm/EditDesignView/EditDesignViewSelectors';
import { initEditWithPrpSku } from '../../../CustomOrderForm/EditDesignView/EditDesignViewActions';

const mapDispatchToProps = {
  initEditWithPrpSku: prpSku => initEditWithPrpSku(prpSku)
};

const mapStateToProps = (state, ownProps) => {
  return {
    loadingStatus: loadingStatusSelector(state),
    hasRequiredProductInfo: hasRequiredProductInfoSelector(state),
    prpSku: ownProps.params.step
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDesignView);
