import { connect } from 'react-redux';
import Orientation from './Orientation';
import { selectOption } from '../../../SKUSelection/SKUSelectionActions';
import { selectedOptionsSelector } from '../../../SKUSelection/SKUSelectionSelectors';

const mapDispatchToProps = {
  selectOption: value => selectOption('orientation', value)
};

const mapStateToProps = state => {
  const orientation = selectedOptionsSelector(state).get('orientation');
  return {
    selectedOrientation:
      orientation && orientation.indexOf('portrait') !== -1
        ? 'portrait'
        : orientation && orientation.indexOf('landscape') !== -1
        ? 'landscape'
        : ''
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orientation);
