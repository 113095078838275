import React from 'react';
import PropTypes from 'prop-types';
import Config from 'gooten-components/src/config';

import './EmptyLanding.scss';

export const StorageEmptyLanding = props => {
  const getUsingTheProductHubKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047286452-Using-the-Product-Hub';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521278551835-Using-the-Product-Hub';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612585616667-Using-the-Product-Hub';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047286452-Using-the-Product-Hub';
    }
  };

  return (
    <div className="store-storage-empty-landing-container text-center">
      <h3 className="text-center">There are no products in Storage yet</h3>
      <div className="landing-image img-storage" />
      <p>
        {/* eslint-disable max-len */}
        <a href={getUsingTheProductHubKbLink()} target="_blank" className="link-default">
          Here
        </a>
        ‏‏‎ are some things Storage is used for
      </p>
      {props.children}
    </div>
  );
};

StorageEmptyLanding.propTypes = {
  children: PropTypes.string
};

export default StorageEmptyLanding;
