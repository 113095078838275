export const TEMPLATE_LOAD = 'TEMPLATE::LOAD';
export const TEMPLATE_LOADED = 'TEMPLATE::LOADED';

export const IMAGE_ADD = 'IMAGE::ADD';
export const IMAGE_LOADED = 'IMAGE::LOADED';

export const EDITOR_INIT = 'EDITOR::INIT';
export const EDITOR_RESTORE = 'EDITOR::RESTORE';
export const EDITOR_RECONSTITUTE = 'EDITOR::RECONSTITUTE';
// Suggest to rename value to 'EDITOR::MODE_EDIT'
export const EDITOR_MODE_EDIT = 'EDITOR_MODE::EDIT';
export const EDITOR_MODE_PREVIEW = 'EDITOR_MODE::PREVIEW';
export const EDITOR_CROPPING_MODE_ON = 'CROPPING_MODE::ON';
export const EDITOR_CROPPING_MODE_OFF = 'CROPPING_MODE::OFF';
export const EDITOR_CROPPING_SAVE_MODE_ON = 'CROPPING_SAVE_MODE::ON';
export const EDITOR_CROPPING_SAVE_MODE_OFF = 'CROPPING_SAVE_MODE::OFF';

export const ENABLE_BULK_EDITING = 'EDITOR::ENABLE_BULK_EDITING';
export const DISABLE_BULK_EDITING = 'EDITOR::DISABLE_BULK_EDITING';

export const ENABLE_BULK_EDITING_AVAILABILITY = 'EDITOR::ENABLE_BULK_EDITING_AVAILABILITY';
export const DISABLE_BULK_EDITING_AVAILABILITY = 'EDITOR::DISABLE_BULK_EDITING_AVAILABILITY';

export const IMAGE_UPDATE = 'IMAGE::UPDATE';
export const IMAGE_DELETE = 'IMAGE::DELETE';
export const IMAGE_UNDO = 'IMAGE::UNDO';
export const IMAGE_REDO = 'IMAGE::REDO';
export const IMAGE_CLEAR_HISTORY = 'IMAGE::CLEAR_HISTORY';

export const PREVIEW_MODE_PRINT = 'PREVIEW_MODE::PRINT';
export const PREVIEW_MODE_PRODUCT = 'PREVIEW_MODE::PRODUCT';

export const SELECT = 'IMAGE_EDITOR::SELECT';

export const EDITOR_ORIENTATION_LANDSCAPE = 'EDITOR_ORIENTATION::LANDSCAPE';
export const EDITOR_ORIENTATION_PORTRAIT = 'EDITOR_ORIENTATION::PORTRAIT';

export const SET_CONTAINER_COLOR = 'EDITOR::SET_CONTAINER_COLOR';
