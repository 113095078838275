import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Card from 'gooten-components/src/components/shared/Card';
import Chip from 'gooten-components/src/components/shared/Chip';
import { COLORS } from 'gooten-components/src/constants';
import InputRows from './components/InputRows';
import Config from '../../../config';
import { Link } from 'react-router';
import SecurityService from '../../../Settings/SecurityService';
import { getOrderDetailsImageResizerUrl } from 'gooten-js-utils/src/url';
import OrderImageUpload from './components/OrderImageUpload';
import Alert from 'gooten-components/src/components/shared/Alert';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';

const ImagesView = props => {
  const [revertImageData, setRevertImageData] = useState([]);
  const [splitConfirmation, setSplitConfirmation] = useState(null);

  const canChangeImage = id => {
    const PreSubmitStatuses = [27, 31, 13, 12, 32, 14, 28, 7, 30, 18, 26, 15, 20, 35];
    const partnerAllowedChangeStatuses = [
      12, // Imageissue
      20, // VendorAPIIssue
      26, // ReadyForImageDi
      31, // Hold
      32, // NeedsManualApproval
      35 // NeedsCustomization
    ];
    return (
      ((SecurityService.isPartner() || SecurityService.isPartnerOwner()) &&
        partnerAllowedChangeStatuses.includes(id)) ||
      (SecurityService.isSuperAdmin() && PreSubmitStatuses.includes(id))
    );
  };

  const getOldImage = id => {
    return revertImageData.length
      ? revertImageData.find(x => x.ImageId === id)
        ? revertImageData.find(x => x.ImageId === id).OldImage
        : null
      : null;
  };

  async function fetchImageRevertValidator() {
    const url = `${Config.get('adminApi')}ImagesApi/CanRevertOrderItems?orderId=${props.params.id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
    res
      .json()
      .then(revertData => {
        setRevertImageData(revertData);
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    if (props.params.id) {
      fetchImageRevertValidator();
    }
  }, [props.params.id, props.itemsData]);

  useEffect(() => {
    if (props.activeImage && document.getElementById(`${props.activeImage}`)) {
      document.getElementById(`${props.activeImage}`).scrollIntoView({ behavior: 'smooth' });
      props.resetActiveImage();
    }
  }, [props.activeImage]);

  return (
    <>
      {props.loading ? (
        <LoadingSpinner />
      ) : (
        <div className="images-tab mt-4">
          <div className="title-row mb-4">
            <div className="title-2">Image Control &amp; Review</div>
            {props.itemsData &&
              props.itemsData.map(
                (item, itemIndex) =>
                  item.Images &&
                  item.Images.length && (
                    <Card
                      key={itemIndex}
                      id={item.Item}
                      className="w-100 mt-3 mb-3"
                      collapsible
                      startsOpen={item.Images.length === 1}
                      title={
                        <div className="d-flex align-items-center">
                          {item.Item} - {item.SKU} - {item.Images.length}
                          <Chip noTooltip className="ml-3" statusNumber={item.StatusId} />
                        </div>
                      }
                    >
                      {item.Images.map((image, imageIndex) => {
                        const resizedImage = getOrderDetailsImageResizerUrl(
                          image.FileName || image.SourceUrl
                        );
                        return (
                          <div className="mb-4" key={imageIndex}>
                            {item.Images.length > 1 ? (
                              <div className="title-3 mt-3 mb-3 d-flex justify-content-center">
                                Image
                                {item.Images.length > 1
                                  ? `${imageIndex + 1}/${item.Images.length}`
                                  : ''}
                              </div>
                            ) : null}
                            {!image.FileName && !image.SourceUrl && !image.ThumbnailUrl ? (
                              <Alert isOpen type="good" className="mt-4 mb-4 w-100">
                                Images are being processed and may take a few minutes to appear.
                              </Alert>
                            ) : null}
                            {image && (
                              <>
                                <div
                                  key={imageIndex}
                                  className="d-flex flex-wrap justify-content-around mr-n2 ml-n2"
                                >
                                  <div className="image-section m-2">
                                    <div className="image-view-container">
                                      {image.FileName || image.SourceUrl ? (
                                        <img src={resizedImage} />
                                      ) : (
                                        <div
                                          className={`icons-container d-flex align-items-center
                                        justify-content-center w-100 h-100`}
                                        >
                                          <Icon
                                            width={40}
                                            height={40}
                                            fill={COLORS.neutralDark200}
                                            icon="image"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-3">
                                      <div className="body-text-2 mr-2">Preview</div>
                                      <a target="_blank" href={image.FileName || image.SourceUrl}>
                                        <Button
                                          className="button-default small mt-2 mb-2 ml-1 mr-1"
                                          iconOnly
                                        >
                                          <Icon icon="externalLink" />
                                        </Button>
                                      </a>
                                      <a
                                        target="_blank"
                                        download
                                        href={image.FileName || image.SourceUrl}
                                      >
                                        <Button
                                          className="button-default small mt-2 mb-2 ml-1 mr-1"
                                          iconOnly
                                        >
                                          <Icon icon="download" />
                                        </Button>
                                      </a>
                                    </div>
                                  </div>
                                  {image.ThumbnailUrl ? (
                                    <div className="image-section m-2">
                                      <div className="image-view-container">
                                        <img src={image.ThumbnailUrl} />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-center mt-3">
                                        <div className="body-text-2 mr-2"> Product Preview</div>
                                        <a target="_blank" href={image.ThumbnailUrl}>
                                          <Button
                                            className="button-default small mt-2 mb-2 ml-1 mr-1"
                                            iconOnly
                                          >
                                            <Icon icon="externalLink" />
                                          </Button>
                                        </a>
                                        <a target="_blank" download href={image.ThumbnailUrl}>
                                          <Button
                                            className="button-default small mt-2 mb-2 ml-1 mr-1"
                                            iconOnly
                                          >
                                            <Icon icon="download" />
                                          </Button>
                                        </a>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="image-section m-2">
                                    <div className="image-view-container">
                                      {image.SourceUrl ? (
                                        <img
                                          className="full"
                                          src={getOrderDetailsImageResizerUrl(image.SourceUrl)}
                                        />
                                      ) : (
                                        <div
                                          className={`icon-container icon-container d-flex align-items-center
                                        justify-content-center w-100 h-100`}
                                        >
                                          <Icon
                                            width={40}
                                            height={40}
                                            fill={COLORS.neutralDark200}
                                            icon="image"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-3">
                                      <div className="body-text-2 mr-2">Preview at 100%</div>
                                      <a target="_blank" href={image.SourceUrl}>
                                        <Button
                                          className="button-default small mt-2 mb-2 ml-1 mr-1"
                                          iconOnly
                                        >
                                          <Icon icon="externalLink" />
                                        </Button>
                                      </a>
                                      <a target="_blank" download href={image.SourceUrl}>
                                        <Button
                                          className="button-default small mt-2 mb-2 ml-1 mr-1"
                                          iconOnly
                                        >
                                          <Icon icon="download" />
                                        </Button>
                                      </a>
                                    </div>
                                  </div>
                                  {image.FileName || image.SourceUrl ? (
                                    <div className="image-section m-2">
                                      <div className="image-view-container">
                                        <img src={resizedImage} />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-center mt-3">
                                        <div className="body-text-2 mr-2">Vendor Image</div>
                                        <a
                                          target="_blank"
                                          href={(image.FileName || image.SourceUrl).split('?')[0]}
                                        >
                                          <Button
                                            className="button-default small mt-2 mb-2 ml-1 mr-1"
                                            iconOnly
                                          >
                                            <Icon icon="externalLink" />
                                          </Button>
                                        </a>
                                        <a
                                          target="_blank"
                                          download
                                          href={(image.FileName || image.SourceUrl).split('?')[0]}
                                        >
                                          <Button
                                            className="button-default small mt-2 mb-2 ml-1 mr-1"
                                            iconOnly
                                          >
                                            <Icon icon="download" />
                                          </Button>
                                        </a>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}
                            <div
                              className={`m-3 d-flex align-items-center justify-content-center mt-3
                            no-text-decoration`}
                            >
                              {canChangeImage(item.StatusId) && (
                                <>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className={`d-flex flex-wrap mr-n1 ml-n1 align-items-center
                                    justify-content-center`}
                                    >
                                      <OrderImageUpload
                                        submitImageFile={props.submitImageFile}
                                        submitImageUrl={props.submitImageUrl}
                                        image={image}
                                      />
                                      {getOldImage(image.Id) ? (
                                        <Button
                                          iconLeft="rotateCCW"
                                          className="button-default large m-1"
                                          onClick={() =>
                                            props.revertImage(
                                              image.Id,
                                              getOldImage(image.Id),
                                              itemIndex,
                                              imageIndex
                                            )
                                          }
                                        >
                                          Revert Image
                                        </Button>
                                      ) : null}
                                      {(image.FileName || image.SourceUrl) && (
                                        <Link
                                          to={`orders-new/edit-design/${item.Meta.PreconfigSku}`}
                                          onClick={() => {
                                            props.editDesign(item);
                                          }}
                                        >
                                          <Button
                                            className="button-default large m-1"
                                            iconLeft="crop"
                                          >
                                            Image Editor
                                          </Button>
                                        </Link>
                                      )}
                                      {item.Quantity > 1 &&
                                        props.summaryData.Meta &&
                                        props.summaryData.Meta.needs_customization === 'true' && (
                                          <Button
                                            className="button-default large m-1"
                                            iconLeft="layers"
                                            onClick={() => setSplitConfirmation(item.Item)}
                                          >
                                            Split Order
                                          </Button>
                                        )}
                                    </div>
                                  </div>
                                  <Dialogue
                                    isOpen={splitConfirmation === item.Item}
                                    title={'Split Order Item ' + item.Item}
                                    cancelClick={() => setSplitConfirmation(null)}
                                    primaryClick={() => {
                                      props.splitOrderItem(props.params.id, item.Item);
                                      setSplitConfirmation(null);
                                    }}
                                    actionText="Split Order Item"
                                  >
                                    This action will split your grouped order item into separate
                                    rows for editing. This action cannot be reversed.
                                  </Dialogue>
                                </>
                              )}
                            </div>
                            <div className="input-rows m-auto">
                              <InputRows
                                recipeId={props.summaryData.PartnerApiKey}
                                image={image}
                                item={item}
                                imageIndex={imageIndex}
                                itemIndex={itemIndex}
                                submitManipCommand={props.submitManipCommand}
                                storeRealImageDimensions={props.storeRealImageDimensions}
                                {...props}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Card>
                  )
              )}
          </div>
          <style jsx>
            {`
              .image-section {
                background-color: ${COLORS.neutralLight100};
                border: 1px solid ${COLORS.neutralLight400};
                border-radius: 4px;
              }
              .image-view-container {
                width: 18.75rem;
                height: 12.5rem;
                overflow: hidden;
                text-align: center;
                display: block;
              }
              .image-view-container img {
                object-fit: contain;
                max-height: 100%;
                max-width: 100%;
              }
              img.full {
                max-height: 18.75rem !important;
                max-width: 18.75rem !important;
              }
              .input-rows {
                max-width: 71rem;
              }
            `}
          </style>
        </div>
      )}
    </>
  );
};

ImagesView.propTypes = {
  loading: PropTypes.bool.isRequired,
  itemsData: PropTypes.array.isRequired,
  submitManipCommand: PropTypes.func.isRequired,
  editDesign: PropTypes.func.isRequired,
  params: PropTypes.object,
  revertImage: PropTypes.func,
  submitImageFile: PropTypes.func,
  submitImageUrl: PropTypes.func,
  summaryData: PropTypes.object,
  resetActiveImage: PropTypes.func,
  activeImage: PropTypes.number,
  storeRealImageDimensions: PropTypes.func,
  splitOrderItem: PropTypes.func
};

export default ImagesView;
