import React from 'react';
import PropTypes from 'prop-types';
import UrlLink from '../UrlLink';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import Collapse from '../Collapse';
import Strings from './strings';
import ServiceWrapper from '../services/ServiceWrapper';
import ErrorModal from 'gooten-components/src/components/shared/ErrorModal';
import ButtonType from '../ButtonType';
import {
  companyNameRegex,
  shopifyUrlRegex
} from '../../../../../gooten-components/src/utils/regex';
import Config from '../../../config';
import Button from 'gooten-components/src/components/shared/Button';
import { getShopifyOAuthUrl } from 'gooten-components/src/utils/oauth';

class FormCard extends React.Component {
  render() {
    return (
      <div id={'FormCard-' + this.props.id} className="card">
        <FormCardContent {...this.props} />
      </div>
    );
  }
}

class FormCardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectButtonEnabled: false,
      connectButtonClicked: false,
      companyName: '',
      shopifyUrl: '',
      hasErrors: false,
      statusMsg: '',
      appClientKey: null,
      partnerData: null,
      showErrorModal: false,
      openCollapseComponent: false,
      companyNameError: 'Please update store name to valid format'
    };
  }

  inputs = {}; // Will contain refs to all input fields

  componentDidMount() {
    // If we cannot get the name, field stays unpopulated ...
    // If we get the response, we set appropriate state fields.
    ServiceWrapper.getPartnerData().then(resp => {
      if (resp) {
        var newState = { ...this.state, partnerData: resp };

        if (resp.Settings && resp.Settings.StoreNameFromSignup) {
          newState.shopifyUrl = resp.Settings.StoreNameFromSignup;
        }

        if (resp.CompanyName) {
          newState.companyName = resp.CompanyName;
        }

        this.setState(newState);
      }
    });
  }

  getValidationState = () => {
    const isValid = Object.keys(this.inputs).reduce(
      (previous, key) => (previous &= this.inputs[key] && this.inputs[key].isValid()),
      true
    );

    return isValid;
  };

  updateFormValidationState = () => {
    const isValid = this.getValidationState();
    this.setState({
      connectButtonEnabled: isValid,
      hasErrors: !isValid
    });
  };

  handleButtonClick = () => {
    // Ensure we do not allow multiple clicks,
    // meaning we allow the first click to do the job ...
    if (this.state.connectButtonClicked) {
      return;
    }

    this.props.handleAnalytics(this.state.shopifyUrl, 'Clicked', this.props.data);

    // The user modified the existing storeName or supplied a storeName.
    // In either case the data needs to be upserted ...
    // First we get details from admin partners api, change one field and
    // send the update. Once it comes back ... we also update the local state.
    if (this.state.partnerData && this.state.partnerData.CompanyName !== this.state.companyName) {
      // 1. Retrieve the structure ...
      ServiceWrapper.getPartnerDetails().then(resp => {
        if (resp.PartnerName) {
          // 2. Update the record ...
          resp.PartnerName = this.state.companyName;
          ServiceWrapper.updatePartnerDetails(resp).then(resp1 => {
            var newPartnerData = { ...this.state.partnerData };
            newPartnerData.CompanyName = this.state.companyName;
            this.setState({ partnerData: newPartnerData });
          });
        }
      });
    }

    ServiceWrapper.isStoreConnected(this.state.shopifyUrl).then(resp => {
      if (resp.isConnected === true) {
        this.setState({
          hasErrors: true,
          statusMsg: 'This store is already connected.',
          connectButtonClicked: false
        });
      } else {
        this.integrateStore();
      }
    });
  };

  integrateStore = () => {
    if (!this.state.hasError) {
      ServiceWrapper.getAppClientKey().then(
        resp => {
          resp.Key
            ? this.setState({ appClientKey: resp.Key }, () => {
                this.authorizeStore();
              })
            : this.setState({
                hasErrors: true,
                statusMsg: 'Cannot obtain app client key.',
                showErrorModal: true,
                connectButtonClicked: false
              });
        },
        reject => {
          this.setState({
            hasErrors: true,
            statusMsg: 'Cannot obtain app client key.',
            showErrorModal: true,
            connectButtonClicked: false
          });
        }
      );
    }
  };

  authorizeStore = () => {
    if (this.state.appClientKey) {
      const url = getShopifyOAuthUrl(
        this.state.shopifyUrl,
        this.state.appClientKey,
        'generic-oauth-hash.html'
      );
      window.open(encodeURI(url), '_self');
    }
  };

  handleTextInputChange = (value, textInputId) => {
    let newState = { ...this.state, [textInputId]: value };

    if (this.state.hasErrors || this.state.reportStatus) {
      newState = { ...newState, statusMsg: '', reportStatus: false, hasErrors: false };
    }
    newState.connectButtonEnabled = newState.companyName !== '' && newState.shopifyUrl !== '';
    this.setState(newState);
  };

  handleCollapseToggle = (e, value) => {
    this.setState({ ...this.state, openCollapseComponent: value });
  };

  handleModalClose = () => {
    this.setState({
      ...this.state,
      hasErrors: false,
      statusMsg: '',
      showErrorModal: false
    });
  };

  render() {
    const data = this.props.data;
    const actionWidgets = [];
    const textAlign = {
      textAlign: 'center',
      paddingTop: '1.5em',
      fontSize: '16px',
      fontWeight: 'bold'
    };
    const cardParagraphs = data.text.map((line, i) => {
      return <p key={i}>{line}</p>;
    });
    const cbhSettings = Config.get('cbhSettings').toJS();

    for (var i = 0; i < data.actions.length; i++) {
      if (data.actions[i].type.toLowerCase() === 'button' && data.integration === 'shopify') {
        actionWidgets.push(
          <ButtonType
            type="button"
            key={data.integration + 'Button-' + i}
            disabled={!this.state.connectButtonEnabled}
            onClick={this.handleButtonClick}
            handleAnalytics={this.props.handleAnalytics}
            data={data.actions[i]}
          />
        );
      } else if (data.actions[i].type.toLowerCase() === 'button') {
        const btnUrl = data.actions[i].url;
        const analyticsId = data.actions[i].analyticsId;
        actionWidgets.push(
          <div className="button-container" key={data.integration + 'Button-' + i}>
            <a
              className="button-enabled"
              href={data.actions[i].url}
              key={data.integration + 'Button-' + i}
              onClick={() => this.props.handleAnalytics(analyticsId, 'Clicked', btnUrl)}
            >
              <Button className="button-primary large full-width">{data.actions[i].text}</Button>
            </a>
          </div>
        );
      }

      if (data.actions[i].type.toLowerCase() === 'link') {
        actionWidgets.push(
          <span style={textAlign} key={'actionWidgets-span-' + i}>
            <UrlLink
              data={data.actions[i]}
              handleAnalytics={this.props.handleAnalytics}
              key={'UrlLink-' + i}
            />
          </span>
        );
      }
    }

    const margin = { paddingLeft: '35px', paddingRight: '15px' };
    const padding = { paddingBottom: '20px' };
    const paragraphPadding = { paddingRight: '35px' };

    return (
      <div className="card-content">
        <ErrorModal
          isOpen={this.state.showErrorModal}
          errorMessage={this.state.statusMsg}
          modalClose={this.handleModalClose}
        />
        <Collapse
          open={!this.props.stores || this.state.openCollapseComponent}
          checked={this.props.stores}
          smallTitle
          title={data.title}
          onToggle={this.handleCollapseToggle}
        >
          <div className="flex-container-column" style={margin}>
            <div className="flex-container" style={paragraphPadding}>
              <div>
                {cardParagraphs}
                <br />
              </div>
            </div>
            <div className="flex-container flex-wrap">
              <div className="flex-container-column flex-self-align-end" style={padding}>
                {data.integration === 'shopify' && (
                  <TextInput
                    id="companyName"
                    isRequired
                    emptyMsg={Strings.FIELD_REQUIRED}
                    showValidation
                    regexp={companyNameRegex}
                    validationMsg={Strings.INVALID_COMPANY_NAME}
                    maxLength={50}
                    label={Strings.COMPANY_NAME_LABEL}
                    placeholder={Strings.COMPANY_NAME_PLACEHOLDER}
                    value={this.state.companyName}
                    onChange={this.handleTextInputChange}
                    customError={
                      this.state.companyName && !companyNameRegex.test(this.state.companyName)
                        ? this.state.companyNameError
                        : null
                    }
                    ref={input => (this.inputs['companyName'] = input)}
                    onErrorsStatusUpdated={this.updateFormValidationState}
                  />
                )}

                {/* eslint-disable max-len */}
                {data.integration === 'shopify' && (
                  <div className="flex-container-form flex-inline-container flex-no-wrap">
                    <div className="flex-container-column-base" style={{ width: '65%' }}>
                      <TextInput
                        id="shopifyUrl"
                        isRequired
                        emptyMsg={Strings.FIELD_REQUIRED}
                        showValidation
                        regexp={shopifyUrlRegex}
                        validationMsg={Strings.INVALID_SHOPIFY_URL}
                        maxLength={50}
                        label={Strings.SHOPIFY_URL_LABEL}
                        placeholder={Strings.SHOPIFY_URL_PLACEHOLDER}
                        value={this.state.shopifyUrl}
                        customError={this.state.hasErrors ? this.state.statusMsg : null}
                        onChange={this.handleTextInputChange}
                        ref={input => (this.inputs['shopifyUrl'] = input)}
                        onErrorsStatusUpdated={this.updateFormValidationState}
                      />
                    </div>
                    <div className="flex-container-column-base flex-align-self-start">
                      <div className="shopify-text">.myshopify.com</div>
                    </div>
                  </div>
                )}
                {actionWidgets}
              </div>
              {cbhSettings.Images.showSloth && (
                <div className="flex-inline-container flex-no-wrap flex-align-self-end">
                  <div className="border-block-img pull-right">
                    <div className={'img-responsive ' + data.img} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Collapse>
        <style jsx>
          {`
            .shopify-text {
              border-radius: 0 4px 4px 0;
              background-color: #f2f3f7;
              width: auto;
              height: 3rem;
              display: flex;
              align-items: center;
              margin-left: -0.25rem;
              padding: 0 1rem;
              border: 1px solid #b1b5b9;
            }
          `}
        </style>
      </div>
    );
  }
}

FormCard.propTypes = {
  id: PropTypes.any
};

FormCardContent.propTypes = {
  data: PropTypes.object,
  stores: PropTypes.any,
  handleAnalytics: PropTypes.func
};

export default FormCard;
