import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';

const RadioField = ({ disabled, checked, className, label, secondaryText, onClick, name }) => {
  return (
    <label className={`d-flex mb-2 ${className || ''} ${disabled ? 'disabled' : ''}`}>
      <input
        type="radio"
        defaultChecked={checked}
        onClick={onClick}
        disabled={disabled}
        name={name}
        readOnly
      />
      <div className="indicator" />
      {label && (
        <div className="label-text">
          <div className="mt-0 heavy body-text-2">{label}</div>
          {secondaryText && <p className="caption-text d-flex m-0">{secondaryText}</p>}
        </div>
      )}
      <style jsx>
        {`
          // input must be 'visible' to screen readers for accessiblity
          input {
            border: 0;
            clip: rect(0 0 0 0);
            clippath: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
          }
          label {
            min-height: ${label ? '2rem' : 'auto'};
            display: flex;
            align-items: ${label ? 'flex-start' : 'center'};
            justify-content: ${label ? 'flex-start' : 'center'};
            text-align: left;
            cursor: pointer !important;
          }
          .label-text {
            margin-left: 0.75rem;
          }
          .indicator {
            box-sizing: border-box;
            border-radius: 50%;
            min-width: 1.25rem;
            height: 1.25rem;
            border: ${checked
              ? `6px solid ${disabled ? COLORS.neutralLight400 : COLORS.gootenBlue500}`
              : `1px solid ${COLORS.neutralLight400}`};
            background: ${COLORS.neutralLight50};
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.125rem 0;
          }
          .body-text-2 {
            margin: 0;
          }
          .disabled {
            cursor: not-allowed;
          }
        `}
      </style>
    </label>
  );
};

RadioField.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.any,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string
};

export default RadioField;
