import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { injectReducer } from '../store/reducers';
import { injectSaga } from '../store/sagas';
import dataReducer from '../store/reducers/dataReducer';
import { backdropReducer } from '../store/reducers/genericReducer';
import { partnerDataLoaded, reset } from '../store/actions/globalActions';
// import { updateShippingCountry } from '../store/actions/dataActions'
import ProductSelectionFactory from '../components/ProductSelection';
import ImageUploadFactory from '../components/ImageUpload';
import SKUSelectionFactory from '../components/SKUSelection';
import ProductPreviewFactory from '../components/ProductPreview';
import PublishFactory from '../components/ProductPublish';
import CartFactory from '../components/Cart';
import CheckoutFactory from '../components/Checkout';
import ShippingTimeReducer from '../components/shared/ShippingTime/ShippingTimeReducer';
import ShippingTimeSaga from '../components/shared/ShippingTime/ShippingTimeSaga';
import NavManager from './NavManager';
import NavManagerReducer from './NavManager/NavManagerReducer';
import ImageSelectionModalReducer from '../components/shared/ImageSelectionModal/ImageSelectionModalReducer';
import ImageSelectionModalSaga from '../components/shared/ImageSelectionModal/ImageSelectionModalSaga';
import ImageUploadModalReducer from '../components/shared/ImageUploadModal/ImageUploadModalReducer';
import ImageUploadModalSaga from '../components/shared/ImageUploadModal/ImageUploadModalSaga';
import NotificationsReducer from '../components/shared/Notifications/NotificationsReducer';
import NotificationsSaga from '../components/shared/Notifications/NotificationsSaga';
import StoresReducer from '../components/Stores/StoresReducer';
import StoresSaga from '../components/Stores/StoresSaga';

import Config from '../config';

import '../styles/_core.scss';

class AppContainer extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    store: PropTypes.object
  };

  constructor(props) {
    super(props);

    const { config } = props;
    const store = props.store || require('../store/index').default;
    AppContainer.preInit(store, config);
  }

  // NOTE: All availabe steps factories are listed here
  static Factories = {
    ProductSelection: ProductSelectionFactory,
    SKUSelection: SKUSelectionFactory,
    ImageUpload: ImageUploadFactory,
    ProductPreview: ProductPreviewFactory,
    SaveAndPublish: PublishFactory,
    Cart: CartFactory,
    Checkout: CheckoutFactory
  };

  static preInit = (store, config) => {
    // Save immutable config
    Config.destroy();
    Config.init(config);

    // will register all reducers and sagas
    // used in integrations where some functionality is used before rendering wizard
    // E.g.: Load product data before show Wizard
    // Inject required store parts
    injectReducer(store, { key: 'data', reducer: dataReducer });
    injectReducer(store, { key: 'globalUI', reducer: backdropReducer });
    // Init NavManager
    injectReducer(store, { key: '__nav__', reducer: NavManagerReducer });
    // Init Image Select Modal
    injectReducer(store, { key: '__img_select_modal__', reducer: ImageSelectionModalReducer });
    injectSaga(store, { key: '__img_select_modal__', saga: ImageSelectionModalSaga });
    injectReducer(store, { key: '__img_upload_modal__', reducer: ImageUploadModalReducer });
    injectSaga(store, { key: '__img_upload_modal__', saga: ImageUploadModalSaga });

    // Global notifications
    injectReducer(store, { key: 'notifications', reducer: NotificationsReducer });
    injectSaga(store, { key: 'notifications', saga: NotificationsSaga });

    // ShippingTime
    injectReducer(store, { key: 'shippingTime', reducer: ShippingTimeReducer });
    injectSaga(store, { key: 'shippingTime', saga: ShippingTimeSaga });

    // Stores Connection
    injectReducer(store, { key: 'stores', reducer: StoresReducer });
    injectSaga(store, { key: 'stores', saga: StoresSaga });

    // NOTE: Init only needed steps from config
    config.steps.forEach(step => {
      // Once factory is called, step component will register inside global state
      const factory = AppContainer.Factories[step.id];
      if (factory) {
        step.component = factory(store);
      }
    });
  };

  shouldComponentUpdate() {
    return false;
  }

  getStore() {
    // store can be passed via props
    return this.props.store || require('../store/index').default;
  }

  componentDidMount() {
    // comes from main.js
    // const { config } = this.props
    // store can be passed via props in case of using it outside
    const store = this.getStore();

    // if (!config.cof) {
    //   // reset shipping country if not in COF
    //   store.dispatch(updateShippingCountry())
    // }

    if (Config.get('recipeId')) {
      store.dispatch(partnerDataLoaded());
    }
  }

  componentWillUnmount() {
    // reset components state
    this.getStore().dispatch(reset());
  }

  render() {
    const store = this.getStore();
    return (
      <div>
        <Provider store={store}>
          <NavManager config={this.props.config} />
        </Provider>
      </div>
    );
  }
}

export default AppContainer;
