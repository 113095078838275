import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../constants';

export const EmptySearchResult = props => {
  return (
    <div className="empty-products-result-container">
      <img
        className="image-empty"
        src={
          props.catalogSearch
            ? 'https://adminassets.azureedge.net/admin-assets/img/no_results_catalog.svg'
            : 'https://adminassets.azureedge.net/admin-assets/img/no_results_hub.svg'
        }
      />
      <span className="title-1">{`No results found${
        props.searchText && props.searchText.length ? ' for "' + props.searchText + '"' : ''
      }`}</span>
      {props.children}
      <style jsx>{`
        .empty-products-result-container {
          display: flex;
          flex-direction: column;
          text-align: center;
        }
        span.title-1 {
          color: ${COLORS.offBlack};
          margin-bottom: 10px;
        }

        p {
          color: ${COLORS.grey};
          font-size: 1.125rem;
        }

        .image-empty {
          height: 312px;
          margin-bottom: 42px;
        }
      `}</style>
    </div>
  );
};

EmptySearchResult.propTypes = {
  catalogSearch: PropTypes.bool,
  searchText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default EmptySearchResult;
