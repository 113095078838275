import { connect } from 'react-redux';
import SKUsListItem from './SKUsListItem';
import { cartLoadingStatusSelector } from '../../PlaceOrderViewSelectors';

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    disableEdit: cartLoadingStatusSelector(state) !== 'ready'
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SKUsListItem);
