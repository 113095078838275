import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BillingAddress from '../Cards/BillingAddress';
import BillingHistory from '../Cards/BillingHistory';
import CreditCard from '../Cards/CreditCard';
import TaxExemption from '../Cards/TaxExemption';
import security from '../SecurityService';
const Container = styled.div``;
class BillingView extends React.Component {
  render() {
    return security.access('page', 'billing') ? (
      <Container>
        <BillingAddress />
        <CreditCard />
        <TaxExemption />
        <BillingHistory />
      </Container>
    ) : null;
  }
}

BillingView.propTypes = {
  // eslint-disable-next-line
  router: PropTypes.object.isRequired
};

export default BillingView;
