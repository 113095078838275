import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  /*
    Can be used as a hook to listen for window resize events
    Example: windowSize = useWindowSize()
    returns an objects[width, height]
  */
  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => setWindowSize(getSize());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
