import React from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';

export default class ButtonType extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    handleAnalytics: PropTypes.func
  };

  handleOnClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleAnalytics() {
    if (this.props.handleAnalytics && this.props.data.analyticsId) {
      this.props.handleAnalytics(this.props.data.url, 'Clicked', this.props.data.analyticsId);
    }
  }

  render() {
    const linkData = this.props.data;
    const buttonType = (
      <Button
        type="button"
        disabled={this.props.disabled}
        onClick={() => {
          this.handleAnalytics();
          this.handleOnClick();
        }}
        className={`full-width large ${
          this.props.disabled ? 'button-primary disabled' : 'button-primary'
        }`}
      >
        {linkData.text}
      </Button>
    );

    const hrefType = (
      <a href={linkData.url} target={linkData.target}>
        <Button
          className={`full-width large ${
            this.props.disabled ? 'button-primary disabled' : 'button-primary'
          }`}
          onClick={() => {
            this.handleAnalytics();
            this.handleOnClick();
          }}
        >
          {linkData.text}
        </Button>
      </a>
    );

    const widget = this.props.type && this.props.type === 'button' ? buttonType : hrefType;

    return <div className="button-container">{widget}</div>;
  }
}
