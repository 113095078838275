import React from 'react';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';

export const LoadingSpinnerOverlay = () => {
  return (
    <div className="overlay-container">
      <LoadingSpinner />
      <style jsx>
        {`
          .overlay-container {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(30, 39, 49, 0.45);
            z-index: 1009;
            display: block;
          }
        `}
      </style>
    </div>
  );
};

export default LoadingSpinnerOverlay;
