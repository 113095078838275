import { injectReducer } from '../../store/reducers';
import { injectSaga } from '../../store/sagas';
import ImageUploadComponent from './ImageUploadContainer';
import ImageUploadReducer from './ImageUploadReducer';
import ImageUploadSaga from './ImageUploadSaga';

const componentName = 'imageUpload';

/*
  Async Inject reducers and return Component
  Assets for product selection will be loaded only when component activated
*/

export default store => {
  injectReducer(store, { key: componentName, reducer: ImageUploadReducer });
  injectSaga(store, { key: componentName, saga: ImageUploadSaga });

  return ImageUploadComponent;
};
