import { connect } from 'react-redux';
import ProductCollection from './ProductCollection';
import { collectionsSelector } from './ProductCollectionSelectors';
import {
  selectedProviderSelector,
  storeIdSelector,
  productIdSelector,
  selectedCollectionsSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import { addCollection, removeCollection, newCollection } from './ProductCollectionActions';
import { setError } from '../../../ProductPublishActions';
import Config from '../../../../../config';

const mapDispatchToProps = dispatch => ({
  setError: (storeId, section, message) => dispatch(setError({ storeId, section, message })),
  addCollection: (storeId, item) => dispatch(addCollection({ storeId, item })),
  removeCollection: (storeId, item) => dispatch(removeCollection({ storeId, item })),
  newCollection: (storeId, collName) => {
    const item = {
      name: collName,
      id: new Date().getUTCMilliseconds()
    };
    dispatch(newCollection({ storeId, item }));
    dispatch(addCollection({ storeId, item }));
  }
});

const mapStateToProps = state => {
  return {
    linkMode: Config.get('linkMode'),
    editMode: Config.get('editMode'),
    selectedProvider: selectedProviderSelector(state),
    collections: collectionsSelector(state),
    selectedCollections: selectedCollectionsSelector(state),
    productId: productIdSelector(state),
    storeId: storeIdSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCollection);
