import React, { useState } from 'react';
import Icon from '../../../../../shared/Icon';
import { COLORS } from '../../../../../../constants';
import copy from 'copy-to-clipboard';
import Toast from '../../../../../shared/Toast';
import PropTypes from 'prop-types';

const PreparingArtwork = props => {
  const [message, setMessage] = useState(null);

  const handleCopy = color => {
    copy(color);
    setMessage('Copied');
    // dismiss message after 1 seconds
    setTimeout(() => setMessage(null), 1000);
  };

  const availableColors = [
    { title: 'Gold', color: '#FFC72C' },
    { title: 'Navy', color: '#141B4D' },
    { title: 'Vegas Gold', color: '#EBBC4E' },
    { title: 'Orange', color: '#FC4C02' },
    { title: 'Red', color: '#EF3340' },
    { title: 'Black', color: '#000000' },
    { title: 'White', color: '#FFFFFF' },
    { title: 'Light Grey', color: '#CFCDC9' },
    { title: 'Light Blue', color: '#92C1E9' },
    { title: 'Hunter Green', color: '#115740' },
    { title: 'Grey', color: '#9EA2A2' },
    { title: 'Purple', color: '#5A2D8A' },
    { title: 'Royal Blue', color: '#0067B9' },
    { title: 'Kelly Green', color: '#00AD50' },
    { title: 'Pink', color: '#F04E98' }
  ];

  const colorItem = obj => (
    <React.Fragment key={obj.color}>
      <div className="color-item">
        <div className="color-holder">
          <div className="color-sample" />
          <div className="color-description">
            <span>{obj.title}</span>
            <span>{obj.color}</span>
          </div>
        </div>
        <div className="btn-copy" onClick={() => handleCopy(obj.color)}>
          <Icon icon="copy" className="mr-2 alert-icon" fill={COLORS.neutralDark200} />
        </div>
      </div>
      <style jsx>{`
        .btn-copy {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }

        .color-holder {
          display: flex;
        }

        .color-item {
          width: 50%;
          display: flex;
          margin-top: 24px;
          justify-content: space-between;
          padding-right: 26px;
          align-items: center;
        }

        .color-description {
          display: flex;
          flex-direction: column;
          padding-left: 12px;
        }

        .color-description span:nth-child(1) {
          font-weight: 800;
          font-size: 15px;
          line-height: 160%;
          color: ${COLORS.neutralDark};
        }

        .color-description span:nth-child(2) {
          font-size: 13px;
          line-height: 156%;
          color: ${COLORS.neutralDark200};
        }

        .color-sample {
          background-color: ${obj.color};
          height: 48px;
          width: 48px;
          border: 1px solid #ededed;
          box-sizing: border-box;
          border-radius: 2px;
        }
      `}</style>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {message && (
        <Toast isOpen className="copy-message">
          {message}
        </Toast>
      )}
      <div>
        <span className="title">File type and size</span>
        <span className="description body-text-2">
          • Supported file sizes are{' '}
          {props.requiredImageSizeData
            ?.map(r => `${r.width}x${r.height}`)
            .join(', ')
            .replace(/, ([^,]*)$/, ' and $1')}
          <br />• Supported file types are DST, PDF and PNG
        </span>
        <span className="title">Artwork details</span>
        <span className="description body-text-2">
          • Artwork shapes and text height have a minimum 5 mm
          <br />• Simple artwork designs work best, photographs and gradients don’t translate to
          embroidery
        </span>
        <div className="sample-images-holder">
          <div className="image-content">
            <div className="image-left" />
            <span className="description body-text-2">
              Use simple designs and solid colors on a transparent background
            </span>
          </div>
          <div className="image-content">
            <div className="image-right" />
            <span className="description body-text-2">Use photographs and gradients</span>
          </div>
        </div>
        <span className="title">Available colors</span>
        <span className="description body-text-2">
          Embroidery colors are limited to the available thread colors listed below
        </span>
        <div className="color-items-holder">{availableColors.map(obj => colorItem(obj))}</div>
      </div>
      <style jsx>{`
        display: block;

        .color-items-holder {
          display: flex;
          flex-wrap: wrap;
        }

        .title {
          font-weight: 800;
          font-size: 20px;
          line-height: 160%;
          margin-top: 20px;
        }

        .description {
          margin-top: 10px;
        }

        .sample-images-holder {
          display: flex;
          justify-content: space-between;
        }

        .image-left {
          height: 184px;
          background: url('https://adminassets.azureedge.net/admin-assets/img/embroidery-right-image.jpg')
            center center / contain no-repeat;
        }

        .image-right {
          height: 184px;
          background: url('https://adminassets.azureedge.net/admin-assets/img/embroidery-image-wrong.jpg')
            center center / contain no-repeat;
        }

        .image-content {
          width: 48%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        .image-content span:nth-child(2) {
          color: ${COLORS.neutralDark200};
        }
      `}</style>
    </React.Fragment>
  );
};

PreparingArtwork.propTypes = {
  requiredImageSizeData: PropTypes.array.isRequired
};

export default PreparingArtwork;
