import { postJson } from '../../../utils/http';
import Config from '../../../config';

class ProductImageDataService {
  getImageTemplates(skus) {
    return postJson(
      `${Config.get('api')}producttemplates`,
      { skus },
      {
        queryParams: {
          recipeId: Config.get('recipeId')
        }
      }
    ).then(resp => resp.SkuTemplates);
  }
}

// singleton
export default new ProductImageDataService();
