import { createSelector } from 'reselect';

const cartViewSelector = state => state.getIn(['customOrderForm', 'cartView']);

export const loadingStatusSelector = createSelector([cartViewSelector], cartView =>
  cartView.get('loadingStatus')
);

export const hasRequiredProductInfoSelector = createSelector([cartViewSelector], cartView => {
  return cartView.getIn(['request', 'products']).size > 0;
});

export const requiredProductInfoSelector = createSelector([cartViewSelector], cartView =>
  cartView.get('request').get('products').first()
);

export const sourceSelector = createSelector([cartViewSelector], cartView =>
  cartView.get('source')
);

export const hasProductsSelector = state =>
  state.getIn(['data', 'products']) && state.getIn(['data', 'products']).size > 0;

export const selectedProductSelector = state =>
  state.getIn(['data', 'products']).find(p => p.id === state.getIn(['data', 'selectedProductId']));

export const hasCartItemsSelector = state => state.getIn(['data', 'cart', 'items']).size > 0;
