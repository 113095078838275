/**
 * Normal event
 * event      | |      |
 * time     ----------------
 * callback   | |      |
 *
 * Call search at most once per 300ms while keydown
 * keydown     | |     |  |
 * time     -----------------
 * search          |       |
 *             |300|   |300|
 */

// Usage
// const handleKeydown = throttle((e) => {
//   console.log(e.target.value)
// }, 300)
// input.addEventListener('keydown', handleKeydown)

const throttle = (callback, wait, context = this) => {
  let timeout = null;
  let callbackArgs = null;

  const later = () => {
    callback.apply(context, callbackArgs);
    timeout = null;
  };

  return () => {
    if (!timeout) {
      // eslint-disable-next-line no-undef
      callbackArgs = arguments;
      timeout = setTimeout(later, wait);
    }
  };
};

export default throttle;
