import { createSelector } from 'reselect';
import { selectedProduct } from 'gooten-components/src/components/SKUSelection/SKUSelectionSelectors';
import { selectedProductsSelector } from '../HubView/components/Products/ProductsSelectors';

const selectedProductId = state => state.getIn(['data', 'selectedProductId']);
const allProducts = state => state.getIn(['data', 'products']);
const placeOrderSelector = state => state.getIn(['hub', 'placeOrder']);

export const hasSelectedSkusSelector = state =>
  state.getIn(['data', 'selectedSkus']) && state.getIn(['data', 'selectedSkus']).size > 0;

export const hasProductsSelector = state =>
  state.getIn(['data', 'products']) && state.getIn(['data', 'products']).size > 0;

export const productsSelector = state =>
  state.getIn(['data', 'products']) ? state.getIn(['data', 'products']).toJS() : [];

export const currentStepSelector = state => state.getIn(['hub', 'duplicateProductView', 'step']);

export const hasProductDetailsSelector = createSelector(
  [selectedProduct],
  selectedProduct => !!selectedProduct && !!selectedProduct.get('details')
);

export const selectedProductSelector = createSelector(
  [selectedProductId, allProducts],
  (selectedProductId, allProducts) => allProducts.find(p => p.id === selectedProductId)
);

const editProductIdSelector = createSelector([placeOrderSelector], placeOrder =>
  placeOrder.get('editProductID')
);

export const selectedProductForEditSelector = createSelector(
  [editProductIdSelector, selectedProductsSelector],
  (editProductId, selectedProducts) =>
    editProductId
      ? selectedProducts.find(p => p.get('id') === editProductId).toJS()
      : selectedProducts.size
      ? selectedProducts.first().toJS()
      : null
);
