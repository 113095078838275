import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SKUsList from './../SKUsList';
import { List } from 'immutable';
import throttle from 'gooten-components/src/utils/throttle';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import { COLORS } from 'gooten-components/src/constants';

class PlaceOrderLine extends Component {
  state = {
    isMobile: false
  };

  constructor(props) {
    super(props);
    this.onResize = throttle(this.onResize.bind(this), 300);
  }

  onResize() {
    if (this._ismounted) {
      const offsetWidth =
        window.innerWidth -
          (document.getElementsByClassName('navigation-panel').length &&
            document.getElementsByClassName('navigation-panel')[0] &&
            document.getElementsByClassName('navigation-panel')[0].offsetWidth) || 0;

      this.setState({
        isMobile: offsetWidth <= 870 // includes side bar width
      });
    }
  }

  componentDidMount() {
    this._ismounted = true;

    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    return (
      <div className="row place-order-line m-0 pt-4 pb-4">
        <div
          className={`item-info-holder ${
            this.state.isMobile ? 'full-width' : ''
          } col-md-4 col-xs-12`}
        >
          <div className="row pt-0 pr-3 pb-0 pl-3">
            <Thumbnail
              image={this.props.item.get('image') || this.props.item.get('sourceImage')}
              className="large col-md-6 col-xs-6 mb-3"
            />
            <div className="col-md-6 col-xs-6">
              <div className="d-flex flex-column">
                <div className="overline">
                  {this.props.item.get('status') === 'storage'
                    ? 'STORAGE'
                    : this.props.item.get('storeName').toUpperCase()}
                </div>
                <div className="product-name body-text-2">{this.props.item.get('name')}</div>
                <div className="caption-text">{`${this.props.item.get('variants').size} variant${
                  this.props.item.get('variants').size === 1 ? '' : 's'
                }`}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`sku-info-holder ${
            this.state.isMobile ? 'full-width' : ''
          } col-md-8 col-xs-12`}
        >
          <SKUsList
            item={this.props.item}
            onSelectVariantID={this.props.onSelectVariantID}
            onEditItem={this.props.onEditItem}
            selectedVariantIDs={this.props.selectedVariantIDs}
            onSelectAll={this.props.onSelectAll}
          />
        </div>
        <hr className="medium" />
        <style jsx>
          {`
            .place-order-line {
              border-bottom: 1px solid ${COLORS.neutralLight300};
            }
          `}
        </style>
      </div>
    );
  }
}

PlaceOrderLine.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectVariantID: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  selectedVariantIDs: PropTypes.instanceOf(List).isRequired
};

export default PlaceOrderLine;
