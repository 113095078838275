import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import RadioField from '../RadioField';

class RadioButtonGroup extends React.Component {
  // radioButtons = []

  onClick(index) {
    this.props.disabled || this.props.onChange(this.props.items.get(index), index);
  }

  render() {
    var buttons;
    if (this.props.items) {
      buttons = this.props.items.map((item, index) => {
        return (
          <RadioField
            key={index}
            className={typeof item === 'string' ? item.replace(/\s+/g, '-').toLowerCase() : ''}
            disabled={this.props.disabled}
            onClick={() => this.onClick(index)}
            checked={this.props.selectionIndex === index}
            // ref={(radioButton) => (this.radioButtons[index] = radioButton)}
            label={item}
          />
        );
      });
    }

    return <div className="radio-button-group">{buttons}</div>;
  }
}

RadioButtonGroup.propTypes = {
  items: PropTypes.instanceOf(List),
  selectionIndex: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default RadioButtonGroup;
