// @flow
import CanvasSurface from './components/canvas/canvasSurface';
import StateManager from './state/stateManager';
import ImageLoader from './utils/imageLoader';
import PositioningService from './utils/positioningService';
import * as actions from './state/actions/actionCreators';
import ILService from './services/data/il.service';
import ImgManipService from './services/data/imgmanip.service';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { PreviewComponent } from './components/preview/preview.component';
import ZoomComponent from './components/zoom/zoom.component';
import ValidationComponent from './components/validation/validation.component';
import { ImageEditorOptions } from './imageEditor.options';
import EventEmitter from 'events';
import ValidationService from './services/data/validation.service';
import './utils/rangeInputWorkaround';
import analytics from 'gooten-components/src/services/analyticsService';
import { PUBLIC_EVENTS, ANALYTIC_EVENTS } from './ImageEditor.events';
import {
  MAX_IMAGE_WIDTH,
  MAX_IMAGE_HEIGHT
} from './ImageEditor.const';
import { isObject } from './utils/object';
import { calcResizedImageSizeByMax } from './utils/math';

// Has to be 270 otherwise Canvas Wraps shows image upside down
const ORIENTATION_CHANGE_ROTATION = 270;

export default class ImageEditorControl {
  _destroyed: boolean;
  _container: any;
  _loadingSpinner: any;
  _eventslisteners: any;
  _surface: CanvasSurface;
  _options: ImageEditorOptions;
  _publicEvents: EventEmitter;
  _stateManager: StateManager;
  _templatesLoading: boolean;
  _imagesLoading: boolean;
  on: any;

  constructor(config) {

    this._options = new ImageEditorOptions(config);
    this._container = document.querySelector(this._options.container);
    this._publicEvents = new EventEmitter();
    this._stateManager = new StateManager(this._publicEvents);
    this._eventslisteners = {};

    //building editor DOM
    //using components
    // Set HTML Element Container
    this._options.domContainer = this._container;
    let canvasContainer = document.createElement('div');

    canvasContainer.className = 'editor-canvas';
    this._container.appendChild(canvasContainer);

    this._loadingSpinner = document.createElement('span');
    this._loadingSpinner.className = 'loading-spinner';
    this._loadingSpinner.style.left = `${config.width / 2 - 20}px`;
    this._loadingSpinner.style.top = `${config.height / 2 - 20}px`;

    this._container.appendChild(this._loadingSpinner);
    let zoomComponent = null;
    if (this._options.zoomControlShown) {
      let validationComponent;
      if (this._options.validationShown) {
        validationComponent = new ValidationComponent(this._stateManager);
      }
      zoomComponent = new ZoomComponent(
        this._stateManager, this._publicEvents, { validationComponent });
      this._container.appendChild(zoomComponent.domElement);
    }

    //init surface
    this._surface = new CanvasSurface(
      this._publicEvents,
      (this._options.maximized ? this._options.maximizedWidth : this._options.width),
      (this._options.maximized ? this._options.maximizedHeight : this._options.height),
      canvasContainer,
      zoomComponent,
      (action) => this.processAction(action)
    );

    if (this._options.toolbarShown) {
      let toolbarComponent = new ToolbarComponent(this._stateManager, this._options.toolbarOptions, this._publicEvents);
      this._container.appendChild(toolbarComponent.domElement);
    }

    if (this._options.previewControlShown) {
      let previewComponent = new PreviewComponent(this._stateManager, this._options.previewControlOptions);
      this._container.insertBefore(previewComponent.domElement, canvasContainer);
    }

    //events api
    //TODO: avoid memory leaks
    this.on = (evt, cb) => {
      this._publicEvents.on(evt, cb);
    };

    Object.keys(ANALYTIC_EVENTS).reduce((res, k) => {
      this._publicEvents.addListener(k, res[k] = (d) => {
        analytics.track('Image Editor', ANALYTIC_EVENTS[k], 'Editor Action', isObject(d) ? null : d, isObject(d) ? d : null);
      });
      return res;
    }, this._eventslisteners);

    this._options.onInit();

    // Dispatch init and template load
    this._stateManager.dispatchAction(
      actions.editorInit({
        width: (this._options.maximized ? this._options.maximizedWidth : this._options.width),
        height: (this._options.maximized ? this._options.maximizedHeight : this._options.height)
      })
    );

    // Subscribe to state changes after init
    this._stateManager.subscribe((state) => this.onStateChange(state));

    if (this._options.dumpedState) {
      // restore from dumped state
      this.restoreFromState(this._options.dumpedState);
    }
    else {
      // usual load template
      this._stateManager.dispatchAction(actions.templateLoad(this._options.template));
    }
  }

  showLoadingSpinner() {
    this._loadingSpinner.style.display = 'block';
  }

  hideLoadingSpinner() {
    this._loadingSpinner.style.display = 'none';
  }

  onStateChange(state) {
    if (this._destroyed) {
      return;
    }
    if (!state.getIn(['editor', 'templateloaded'])) {
      // load template
      this.loadTemplate(state.get('template'));
    }
    else if (state.getIn(['editor', 'imageloading'])) {
      // load image
      this.loadImages(state.getIn(['images', 'current', 'images']));
    }
    else {
      // redraw surface
      this._surface.redraw(state);
      // NOTE: Call it after redraw to prevent not smooth transition
      ImageEditorControl.resetSurfaceSize(this._surface.stage, state.get('editor'));
    }
  }

  /* Static method for generate default IL for one image
   * Used for pregenerating states for all images without loading Image Editor UI
   * {isRotated} - true when being called for generting IL in background with changed orientation
   */
  static initImageState(template, image, isRotated, corsEnabled) {
    let img = {
      src: image.imageUrl,
      layerId: image.layerId
    };

    // NOTE: Here we should try to calc image size without loading image
    // To Speed Things UP !!!
    if (!image.height || !image.width) {
      // Fallback to load image
      return ImageLoader.loadImage(image.imageUrl, corsEnabled)
        .then((res) => {
          let loadedImage = {...img};
          loadedImage.realSourceWidth = image.width || res.image.naturalWidth;
          loadedImage.realSourceHeight = image.height || res.image.naturalHeight;

          if (res.orientation > 4) {
            // exif orientation - swap sizes
            loadedImage.realSourceWidth = image.height || res.image.naturalHeight;
            loadedImage.realSourceHeight = image.width || res.image.naturalWidth;
          }

          loadedImage.sourceWidth = loadedImage.image.width;
          loadedImage.sourceHeight = loadedImage.image.height;

          PositioningService.initTemplatePosition(template);
          PositioningService.initImagePosition(loadedImage, template, isRotated);
          if (isRotated) {
            PositioningService.rotateImageObj(loadedImage, ORIENTATION_CHANGE_ROTATION);
            PositioningService.centerImageObj(loadedImage, template);
          }
          return ILService.exportImageIL(template, loadedImage);
        });
    }
    else {
      return new Promise((resolve, reject) => {
        try {
          // Ok we have realSource sizes
          // and we know resized source sizes - 1000x1000
          // Resize using max method - which means resized width and heigh will <= max
          // We can calc resized image size by formula here
          const max = Math.max(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT);
          const newSize = calcResizedImageSizeByMax(image.height, image.width, max);
          let resizedImage = {
            ...img,
            realSourceWidth: image.width,
            realSourceHeight: image.height,
            sourceWidth: newSize.width,
            sourceHeight: newSize.height
          };

          PositioningService.initTemplatePosition(template);
          PositioningService.initImagePosition(resizedImage, template, isRotated);
          if (isRotated) {
            PositioningService.rotateImageObj(resizedImage, ORIENTATION_CHANGE_ROTATION);
            PositioningService.centerImageObj(resizedImage, template);
          }

          const il = ILService.exportImageIL(template, resizedImage);
          resolve(il);
        }
        catch (err) {
          reject(err);
        }
      });
    }
  }

  static resetSurfaceSize(stage, editorState) {
    // TODO: Refactor this
    // TODO: Move to CanvasSurface
    if (stage.rotation() === 0) {
      stage.width(editorState.get('width'));
      stage.height(editorState.get('height'));
    }
    else {
      stage.width(editorState.get('height'));
      stage.height(editorState.get('width'));
    }
  }

  /* Async method to preload all images from template
   */
  loadTemplate(templateState) {
    if (this._templatesLoading) {
      return;
    }
    this._templatesLoading = true;
    let template = templateState.toJS();
    let promises = [];

    for (let layer of template.layers) {

      if (!layer.imageurl) continue;

      promises.push(
        ImageLoader.loadImage(layer.imageurl, this._options.cors)
          .then((res) => {
            // template image is not always correct sizes
            // take correct sizes from coordiantes
            layer.width = layer.x2 - layer.x1;
            layer.height = layer.y2 - layer.y1;

            // resize to width and height
            // with which editor is operated
            layer.image = res.image;
            // Serg: Commented out upon perf optimization
            // as redundant step
            // layer.image = ImageLoader.resizeImage(
            //   res.image,
            //   res.orientation,
            //   MAX_IMAGE_WIDTH,
            //   MAX_IMAGE_HEIGHT
            // );
          })
          .catch((err) => {
            this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
            // TODO: editor error state? recover?
          })
      );
    }

    Promise.all(promises).then(() => {
      try {
        // Update template with real height/width and scale
        PositioningService.initTemplatePosition(template);
      }
      catch (err) {
        err.friendlyMsg = 'Empty layers!';
        err.details = { template };
        throw err;
      }
      this.hideLoadingSpinner();
      this._stateManager.dispatchAction(actions.templateLoaded(template));

      this._publicEvents.emit(PUBLIC_EVENTS.TEMPLATE_LOADED);
      this._templatesLoading = false;
    })
    .catch((err) => {
      this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
      this.hideLoadingSpinner();
      this._templatesLoading = false;
      // TODO: editor error state? recover?
    });
  }

  /* Async method to preload all user images
   */
  loadImages(imagesState) {
    if (this._imagesLoading) {
      return;
    }
    this._imagesLoading = true;
    let images = imagesState.toJS();
    this.showLoadingSpinner();
    let notLoadedImages = images.filter(i => !i.image);
    let promises = [];
    for (let img of notLoadedImages) {
      if (!img.src) continue;

      promises.push(
        ImageLoader.loadImage(img.src, this._options.cors)
          .then((res) => {
            let loadedImage = {...img};

            let width = loadedImage.realSourceWidth || res.image.naturalWidth;
            let height = loadedImage.realSourceHeight || res.image.naturalHeight;

            if (res.orientation > 4) {
              // exif orientation - swap sizes
              [width, height] = [height, width];
            }

            loadedImage.realSourceWidth = width;
            loadedImage.realSourceHeight = height;

            loadedImage.image = res.image;
            // Serg: Commented out upon perf optimization
            // as redundant step
            // resize to width and height
            // with which editor is operated
            // loadedImage.image = ImageLoader.resizeImage(
            //   res.image,
            //   res.orientation,
            //   MAX_IMAGE_WIDTH,
            //   MAX_IMAGE_HEIGHT
            // );

            loadedImage.sourceWidth = loadedImage.image.width;
            loadedImage.sourceHeight = loadedImage.image.height;

            const state = this._stateManager.getState();
            // isRotated means orientation changed (not default), by default it's 'landscape'
            // NOTE: May not always represent the real orientation in hub - bug
            // Serves just as a flag that orientation was changed.
            const isRotated = state.editor.orientation === 'portrait';
            PositioningService.initImagePosition(loadedImage, state.template, isRotated);

            if (isRotated) {
              PositioningService.rotateImageObj(loadedImage, ORIENTATION_CHANGE_ROTATION);
              PositioningService.centerImageObj(loadedImage, state.template);
            }

            this._stateManager.dispatchAction(actions.imageLoaded(loadedImage));

            // emit when all images are loaded
            this._publicEvents.emit(PUBLIC_EVENTS.IMAGES_LOADED);
          })
          .catch((err) => {
            this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
            // TODO: editor error state? recover?
          })
      );
    }
    Promise.all(promises).then(() => {
      this.hideLoadingSpinner();
      this._imagesLoading = false;
    });

    if (promises.length === 0) {
      this._stateManager.dispatchAction(actions.imageLoaded());
      this.hideLoadingSpinner();
      this._imagesLoading = false;
    }
  }

  /* Send any action to redux state manager
   */
  processAction(action: any) {
    this._stateManager.dispatchAction(action);
  }

  /* Add new image to editor
   * This action will initiate async loading operation
   */
  addImage(url: string, layerId: string = null, width = null, height = null) {
    // NOTE: if this._options.allowMultiImages === false
    // then reducer will check it and do not add more than 1 image to image layer
    this._stateManager.dispatchAction(
      actions.imageAdd(url, layerId, this._options.allowMultiImages, width, height)
    );
  }

  /* Remove all history from redux image area
   */
  clearHistory() {
    this._stateManager.dispatchAction(actions.imageClearHistory());
  }

  /* Resize image editor. Affects both - UI element and size used in calculations
   */
  changeEditorSize(newSize) {
    const state = this._stateManager.getState();
    let payload = PositioningService.reconstituteEditor(
      state,
      newSize.width,
      newSize.height
    );
    this._stateManager.dispatchAction(actions.editorReconstitute(payload));
  }

  /* Destroy component instance
   */
  destroy() {
    this._destroyed = true;
    this._surface.destroy();
    this._container.innerHTML = '';
    this._container.setAttribute('style', '');

    const pe = this._publicEvents;
    Object.keys(this._eventslisteners).map((k) => {
      pe.removeListener(k, this._eventslisteners[k]);
    });
  }

  /* Export IL from current editor instance
   */
  exportIL() {
    return ILService.exportIL(this._stateManager.getState());
  }

  /* Export ImgManip command from current editor instance
   */
  exportImgManipCmd() {
    return ImgManipService.exportImgManipCmd(this._stateManager.getState());
  }

  /* Validate that images uploaded and correctly placed
   */
  validate() {
    // NOTE: Validate image rules
    return ValidationService.validate(
      this._surface,
      this._stateManager.getState(),
      this._options
    );
  }

  /* Export entire editor state
   */
  dumpState(clean: boolean = true) {
    return this._stateManager.getDumpedState(clean);
  }

  /* Public method to switch between print and product preview mode
   */
  changeMode(mode: string) {
    if (mode === 'product') {
      this._stateManager.dispatchAction(actions.switchToProductPreview());
    }
    else {
      this._stateManager.dispatchAction(actions.switchToPrintPreview());
    }
  }

  setBulkEditingAvailability (isAvailable: boolean) {
    if (isAvailable) {
      this._stateManager.dispatchAction(actions.enableBulkEditingAvailability());
    }
    else {
      this._stateManager.dispatchAction(actions.disableBulkEditingAvailability());
    }
  }

  changeBulkEditing(bulk: boolean) {
    if (bulk) {
      this._stateManager.dispatchAction(actions.enableBulkEditing());
    }
    else {
      this._stateManager.dispatchAction(actions.disableBulkEditing());
    }
  }

  /* Restore image editor from existing state
   */
  restoreFromState(dumpedState: Object) {
    this.showLoadingSpinner();
    let promises = [];
    const loadImage = (image, imgUrl): Promise => {
      if (!imgUrl) {
        return;
      }

      return ImageLoader.loadImage(imgUrl, this._options.cors)
        .then((res) => {

          image.image = res.image;
          // Serg: Commented out upon perf optimization
          // as redundant step
          // image.image = ImageLoader.resizeImage(
          //   res.image,
          //   res.orientation,
          //   MAX_IMAGE_WIDTH,
          //   MAX_IMAGE_HEIGHT
          // );
        })
        .catch((err) => {
          this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
          // TODO: editor error state? recover?
        });
    };

    // load template images
    dumpedState.template.layers.forEach(l => {
      if (!l.image && l.imageurl) {
        promises.push(
          loadImage(l, l.imageurl)
        );
      }
    });

    // load images for current
    dumpedState.images.current.images.forEach(img => {
      if (!img.image) {
        promises.push(
          loadImage(img, img.src)
        );
      }
    });

    // load images for past
    dumpedState.images.past.forEach(p =>
      p.images.forEach(img => {
        if (!img.image) {
          promises.push(
            loadImage(img, img.src)
          );
        }
      })
    );

    // load images for future
    dumpedState.images.future.forEach(p =>
      p.images.forEach(img => {
        if (!img.image) {
          promises.push(
            loadImage(img, img.src)
          );
        }
      })
    );

    if (promises.length) {
      // Wait all promises
      Promise.all(promises).then(() => {
        // inject state
        this._stateManager.injectDumpedState(dumpedState);

        this.hideLoadingSpinner();
      })
        .catch((err) => {
          this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
          // TODO: editor error state? recover?
          this.hideLoadingSpinner();
        });
    }
    else {
      // all images already there
      this._stateManager.injectDumpedState(dumpedState);
      this.hideLoadingSpinner();
    }
  }

  /*
  * Applies DX update to IL.
  * Returns updated IL.
   */
  static applyDxUpdateToIL(il, dx, template) {
    if (!il || !dx || !template) {
      return;
    }
    // add height/width and finals to template
    PositioningService.initTemplatePosition(template);
    const imagesState = ILService.importIL(il);
    // take first image since only single templates available
    const image = imagesState[0];
    if (!image) {
      return;
    }
    const layer = template.layers.find(l => l.id === image.layerId);
    const updatedState = PositioningService.getImageStateUpdateFromDx(dx, image, layer);
    const state = {
      images: {
        current: {
          images: [updatedState]
        }
      },
      template: template
    };

    return ILService.exportIL(state);
  }

  /* Restore image editor from existing IL data
   */
  restoreFromIL(il: Object, sourceImages = [], ILHistory = null) {
    if (il.type !== 'print') {
      throw 'Gooten Image Editor: Require print IL';
    }

    this.showLoadingSpinner();

    // restore past and future IL's
    let past = [];
    let future = [];
    if (ILHistory) {
      if (!ILHistory.past || !ILHistory.future) {
        throw 'ILHistory obj must have .past and .future properties';
      }
      past = ILHistory.past.map(il => {
        const images = ILService.importIL(il);
        return {
          images,
          selected: {imageId: images[0].id, layerId: images[0].layerId}
        };
      });

      future = ILHistory.future.map(il => {
        const images = ILService.importIL(il);
        return {
          images,
          selected: {imageId: images[0].id, layerId: images[0].layerId}
        };
      });
    }

    // map to editor images state format
    let imagesState = ILService.importIL(il);

    // load images async
    let promisesCacheMap = {};

    const loadImage = (image): void => {
      // get image source which contains resized(1000x1000) img url
      const imageSource = sourceImages.find(s => s.layerId === image.layerId);
      if (imageSource) {
        image.src = imageSource.imageUrl;
        image.realSourceWidth = imageSource.width;
        image.realSourceHeight = imageSource.height;
      }

      // try resolve cached promise
      let promise = promisesCacheMap[image.src];

      if (!promise) {
        promise = ImageLoader.loadImage(image.src, this._options.cors)
          .catch(err => {
            this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
            // TODO: editor error state? recover?
          });
      }

      promise = promise.then(res => {
        // add real sizes
        image.realSourceWidth = image.realSourceWidth || res.image.naturalWidth;
        image.realSourceHeight = image.realSourceHeight || res.image.naturalHeight;
        if (res.orientation > 4) {
          // exif orientation - swap sizes
          image.realSourceWidth = image.realSourceHeight || res.image.naturalHeight;
          image.realSourceHeight = image.realSourceWidth || res.image.naturalWidth;
        }

        image.image = res.image;
        // Serg: Commented out upon perf optimization
        // as redundant step
        // image.image = ImageLoader.resizeImage(
        //   res.image,
        //   res.orientation,
        //   MAX_IMAGE_WIDTH,
        //   MAX_IMAGE_HEIGHT
        // );

        // add resized to max editor(1000x1000) sizes
        image.sourceWidth = image.image.width;
        image.sourceHeight = image.image.height;

        return res;
      });

      promisesCacheMap[image.src] = promise;
    };

    imagesState.forEach(img => {
      if (!img.image) {
        loadImage(img);
      }
    });

    past.forEach(p =>
      p.images.forEach(img => {
        if (!img.image) {
          loadImage(img);
        }
      })
    );

    future.forEach(f =>
      f.images.forEach(img => {
        if (!img.image) {
          loadImage(img);
        }
      })
    );

    const promises = Object.values(promisesCacheMap);
    const injectState = () => {
      // inject state
      this._stateManager.injectImagesState(imagesState, past, future);

      // if template is still loading, do not hide loading spinner
      if (!this._templatesLoading) {
        this.hideLoadingSpinner();
      }
    };

    if (promises.length) {
      // Wait all promises
      Promise.all(promises).then(() => {
        injectState();
      })
        .catch((err) => {
          this._publicEvents.emit(PUBLIC_EVENTS.ERROR, err);
          // TODO: editor error state? recover?
          this.hideLoadingSpinner();
        });
    }
    else {
      // all images already there
      injectState();
    }
  }

  // Change orientation
  changeOrientation (changeOrientation) {
    if (changeOrientation) {
      this._stateManager.dispatchAction(actions.toPortrait());
    }
    else {
      this._stateManager.dispatchAction(actions.toLandscape());
    }
  }

  // Set stage background color (needed to match print area color of product)
  setContainerColor (color) {
    this._stateManager.dispatchAction(actions.setContainerColor(color));
  }
}
