import { ActionNameFactory } from '../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'MOCKUPS');

export const SAVE_MAIN_MOCKUP = getActionName('SAVE_MAIN_MOCKUP');
export const STORE_UPDATE_PREVIEW_CHANGE = getActionName('STORE_UPDATE_PREVIEW_CHANGE');
export const SET_PREVIEW_OPTIONS = getActionName('SET_PREVIEW_OPTIONS');
export const GOTO_PREVIEWS_PAGE = getActionName('GOTO_PREVIEWS_PAGE');

export const saveMainMockup = payload => ({
  type: SAVE_MAIN_MOCKUP,
  payload
});

export const toggleUpdatePreview = payload => ({
  type: STORE_UPDATE_PREVIEW_CHANGE,
  payload
});

export const setPreviewOptions = payload => ({
  type: SET_PREVIEW_OPTIONS,
  payload
});

export const gotoPreviewsPage = () => ({
  type: GOTO_PREVIEWS_PAGE
});
