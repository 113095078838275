import React, { useEffect } from 'react';
import { hashHistory } from 'react-router';

const Catalog = () => {
  useEffect(() => {
    hashHistory.push('/hub/create-product/start');
  }, []);

  return <div className="w-100 d-flex justify-content-center">Redirecting to Catalog</div>;
};

export default Catalog;
