import React from 'react';
import PropTypes from 'prop-types';
import UrlLink from '../UrlLink';
import './ReferenceCard.scss';

class ReferenceCard extends React.Component {
  static propTypes = {
    id: PropTypes.number
  };

  render() {
    const padding = { padding: '20px 15px 10px 15px' };
    return (
      <div id={this.props.id} style={padding} className="reference-card-container card">
        <ReferenceCardContent {...this.props} id={'reference-card-' + this.props.id} />
      </div>
    );
  }
}

class ReferenceCardContent extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    handleAnalytics: PropTypes.func,
    integrations: PropTypes.any
  };

  render() {
    const data = this.props.data;
    const refData = data.references.filter(r => this.props.integrations.includes(r.integration));
    const referenceLinks = [];

    for (var i = 0; i < refData.length; i++) {
      referenceLinks.push(
        <li key={'li-' + i + refData[i].type + refData[i].text}>
          <UrlLink
            handleAnalytics={this.props.handleAnalytics}
            boldPrefix={refData[i].type}
            data={refData[i]}
          />
        </li>
      );
    }

    return (
      <div className="card-content" id={data.id}>
        <span className="card-title">{data.title}</span>
        <div className="card-list-container">
          <ol className="ref-list">{referenceLinks}</ol>
        </div>
      </div>
    );
  }
}

export default ReferenceCard;
