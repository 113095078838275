class StorageService {
  localStorageAvailable() {
    try {
      localStorage.setItem('test-check', 'test-check');
      localStorage.removeItem('test-check');

      return true;
    } catch (e) {
      return false;
    }
  }

  setItem(key, value) {
    if (this.localStorageAvailable()) {
      localStorage.setItem(key, value);
    }
  }

  getItem(key) {
    if (this.localStorageAvailable()) {
      return localStorage.getItem(key);
    }
  }
}

export default new StorageService();
