import { createSelector } from 'reselect';
import { rootSelector } from './../../SKUsSelectionViewSelectors';

export const flowSelectedIndexSelector = createSelector([rootSelector], root =>
  root.get('flowSelectedIndex', -1)
);

export const continueButtonLabelSelector = createSelector(
  // flowSelectedIndex 1 => New Product
  // flowSelectedIndex 0 => Saved(HUB) Product
  [flowSelectedIndexSelector],
  flow => (flow === 0 ? 'START FULFILLMENT' : 'CONTINUE TO UPLOAD ARTWORK')
);
