import { connect } from 'react-redux';
import HubSKUsList from './HubSKUsList';
import {
  pageVariantsSelector,
  optionsSelector,
  pageSelector,
  pagesSelector,
  skuNotificationSelector,
  disableFilteringSelector,
  suggestedMatchesSelector,
  allVariantsSelector
} from './HubSKUsListSelectors';
import { setOptionSelectedValues, setPage } from './HubSKUsListActions';
import { selectSKU, skipMapping, resetNotification } from './../../../SKUsSelectionViewActions';
import {
  mappingsProgressSelector,
  selectedOptionsLabelSelector
} from './../../../SKUsSelectionViewSelectors';

const mapDispatchToProps = {
  setOptionSelectedValues: (optionId, values, isSuggested) =>
    setOptionSelectedValues(optionId, values, isSuggested),
  changePage: page => setPage(page),
  select: (sku, value) => selectSKU(sku, value),
  next: () => skipMapping(),
  resetNotification: () => resetNotification()
};

const mapStateToProps = state => {
  return {
    suggestedVariants: suggestedMatchesSelector(state),
    allVariants: allVariantsSelector(state),
    pageVariants: pageVariantsSelector(state),
    options: optionsSelector(state),
    page: pageSelector(state),
    pages: pagesSelector(state),
    mappingProgress: mappingsProgressSelector(state),
    skuNotification: skuNotificationSelector(state),
    disableFiltering: disableFilteringSelector(state),
    selectedOptionsLabel: selectedOptionsLabelSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubSKUsList);
