import { takeLatest, select, put, call, all } from 'redux-saga/effects';
import { FETCH_SHIPPING_ADDRESSES, DELETE_SHIPPING_ADDRESS } from './AddressSelectorActions';
import { fetchAsyncSuccess, fetchAsyncFail } from '../../../../store/actions/dataActions';
import {
  showLoading,
  hideLoading,
  PARTNER_DATA_LOADED
} from '../../../../store/actions/globalActions';
import { SET_SHIPPING_ADDRESS, setShippingAddress, PLACE_ORDER } from '../../CheckoutActions';
import shippingAddressesService from './services/shippingAddressesService';
import Log from '../../../../services/logService';
import { cofShippingCountrySelector } from '../../../../store/selectors/countriesSelectors';
import { currentPageSelector } from './AddressSelectorSelectors';
import Config from '../../../../config';

export function* getShippingAddresses(action) {
  yield put(showLoading());
  try {
    const shippingCountry = yield select(cofShippingCountrySelector);
    const requestParams = {
      page: action.payload,
      pageSize: 10
    };
    const shippingAddressesResponse = yield call(
      shippingAddressesService.getShippingAddresses,
      shippingCountry,
      requestParams
    );
    yield put(
      fetchAsyncSuccess(FETCH_SHIPPING_ADDRESSES, {
        loadedPage: action.payload,
        shippingAddresses: shippingAddressesResponse.addresses,
        totalAddresses: shippingAddressesResponse.total
      })
    );
  } catch (err) {
    yield put(fetchAsyncFail(FETCH_SHIPPING_ADDRESSES, err));
    throw Log.withFriendlyMsg('Failed to fetch shipping addresses', err, { action });
  }
  yield put(hideLoading());
}

export function* watchForLoadCommand() {
  yield takeLatest(FETCH_SHIPPING_ADDRESSES.ASYNC, getShippingAddresses);
}

export function* updateShippingAddressHandler(action) {
  // Call callback defined in config when address changed
  const onShippingAddressUpdate = Config.get('onShippingAddressUpdate');
  if (onShippingAddressUpdate) {
    onShippingAddressUpdate(action.payload.address && action.payload.address.toJS());
  }
}

export function* watchShippingAddressUpdate() {
  yield takeLatest(SET_SHIPPING_ADDRESS, updateShippingAddressHandler);
}

export function* shippingAddressInitHandler() {
  // Restore shipping address
  const shippingAddress = Config.get('shippingAddress');
  const shippingCountry = yield select(cofShippingCountrySelector);
  if (shippingAddress && shippingAddress.get('country') === shippingCountry) {
    yield put(setShippingAddress(shippingAddress));
  }
}

export function* watchShippingAddressInit() {
  yield takeLatest(PARTNER_DATA_LOADED, shippingAddressInitHandler);
}

export function* deleteShippingAddresses(action) {
  yield put(showLoading());
  try {
    yield call(shippingAddressesService.deleteShippingAddress, action.payload.addressId);
    yield put(fetchAsyncSuccess(DELETE_SHIPPING_ADDRESS, action.payload));
  } catch (err) {
    yield put(fetchAsyncFail(DELETE_SHIPPING_ADDRESS, err));
    Log.withFriendlyMsg('Failed to delete shipping address', err, { action });
  }
  yield put(hideLoading());
}

export function* watchForDeleteAddressCommand() {
  yield takeLatest(DELETE_SHIPPING_ADDRESS.ASYNC, deleteShippingAddresses);
}

export function* refreshCurrentPage(action) {
  const currentPage = yield select(currentPageSelector);
  yield getShippingAddresses({ payload: currentPage });
}

export function* watchForDeleteAddressSuccess() {
  yield takeLatest(DELETE_SHIPPING_ADDRESS.SUCCESS, refreshCurrentPage);
}

export function* watchForPlaceOrderSuccess() {
  yield takeLatest(PLACE_ORDER.SUCCESS, refreshCurrentPage);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchForLoadCommand(),
    watchShippingAddressUpdate(),
    watchShippingAddressInit(),
    watchForDeleteAddressCommand(),
    watchForDeleteAddressSuccess(),
    watchForPlaceOrderSuccess()
  ]);
}
