import StateManager from '../../../state/stateManager';
import { switchToProductPreview } from '../../../state/actions/actionCreators';
export class ProductPreviewButton {

  customClass: string;
  name: string;
  element: any;
  active: boolean;

  constructor(config: any, stateManager: StateManager) {
    config = config || {};
    this.customClass = config.customClass || '';
    this.name = config.name || 'Product Preview';
    this.stateManager = stateManager;
    // active by default
    this.active = config.editorConfig.preview === 'product'
      || config.editorConfig.preview === undefined;
  }

  getElement() {
    let element = document.createElement('div');
    element.className += ` editor-btn productPreview ${this.customClass}`;
    if (this.active) {
      element.className += ' active';
    }
    element.innerHTML = this.name;

    element.addEventListener('click', () => this.action());
    this.element = element;
    return element;
  }

  action() {
    this.stateManager.dispatchAction(switchToProductPreview());
    this.element.nextSibling.classList.remove('active');
    this.element.classList.add('active');
  }
}
