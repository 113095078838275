import React from 'react';
import PropTypes from 'prop-types';
import './FirstOrderBanner.scss';
import { Link } from 'react-router';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';

const FirstOrderBanner = props => {
  return (
    <div className="banner-container">
      <div className="banner-image" />
      <div className="banner-text">
        <h1>1st Order Placed!</h1>
        <p>
          Congrats {props.userName}, you placed your first Gooten order like a pro! <br />
          Check our <Link to="/orders">orders page</Link> to see the status of your product.
        </p>
      </div>

      <Button iconOnly className="button-default small close-banner" onClick={props.hideBanner}>
        <Icon icon="x" small />
      </Button>
    </div>
  );
};

FirstOrderBanner.propTypes = {
  userName: PropTypes.string,
  hideBanner: PropTypes.func
};

export default FirstOrderBanner;
