import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB', 'SEARCH');

// ------------------------------------
// Constants
// ------------------------------------
export const SEARCH_CHANGE = getActionName('SEARCH_CHANGE');

// ------------------------------------
// Actions
// ------------------------------------
export function searchChange(search = '') {
  return {
    type: SEARCH_CHANGE,
    payload: search
  };
}
