/**
 * Copies input value to clipboard.
 * @param {String} id
 */
export const copy = id => {
  const element = document.getElementById(id);
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const editable = element.contentEditable;
    const readOnly = element.readOnly;
    element.contentEditable = true;
    element.readOnly = false;
    const range = document.createRange();
    range.selectNodeContents(element);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    element.setSelectionRange(0, 999999);
    element.contentEditable = editable;
    element.readOnly = readOnly;
    document.execCommand('copy');
  } else if (navigator.permissions && navigator.clipboard) {
    element.select();
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(element.value);
      }
    });
  } else {
    element.select();
    document.execCommand('copy');
  }
};
