import React, { useState } from 'react';
import PropTypes from 'prop-types';
import storageService from 'gooten-components/src/services/storageService';
import Modal from 'gooten-components/src/components/shared/Modal';
import Checkbox from 'gooten-components/src/components/shared/Checkbox';
import { Map } from 'immutable';

const OrderSampleModal = ({
  title,
  text,
  showModal,
  modalVisible,
  router,
  selectProduct,
  productId
}) => {
  const [checked, setChecked] = useState(false);

  const setModalVisibility = () => {
    if (checked) {
      storageService.setItem('hide_sample_order_modal', true);
    }
  };

  const handleCancel = () => {
    setModalVisibility();
    showModal(false);
  };

  const handleOrder = () => {
    setModalVisibility();
    showModal(false);
    selectProduct(productId);
    router.push('/hub/place-order?sample=true');
  };

  return (
    <>
      <Modal
        isOpen={modalVisible}
        title={title}
        className="small"
        cancelClick={() => handleCancel()}
        cancelText="Cancel"
        primaryClick={() => handleOrder()}
        actionText="Order sample"
      >
        <div className="p-4">{text}</div>
        <Checkbox
          className="pr-4 pb-3 pl-4"
          noBorder
          checked={checked}
          onChange={() => setChecked(!checked)}
          label={'Don’t show again'}
        />
      </Modal>
    </>
  );
};

OrderSampleModal.propTypes = {
  router: PropTypes.object,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  notification: PropTypes.instanceOf(Map),
  showModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  selectProduct: PropTypes.func.isRequired,
  productId: PropTypes.number
};

export default OrderSampleModal;
