import { connect } from 'react-redux';
import StatusView from './StatusView';
import { loadingStatus, acceptableStatuses } from './StatusViewSelectors';
import { getAcceptableStatuses } from './StatusViewActions';
import { changeTrackingNumber } from '../OrderDetailsActions';

const mapDispatchToProps = {
  getAcceptableStatuses: orderId => getAcceptableStatuses(orderId),
  changeTrackingNumber: data => changeTrackingNumber(data)
};

const mapStateToProps = state => {
  return {
    loading: loadingStatus(state),
    acceptableStatuses: acceptableStatuses(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusView);
