import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS');

export const RESET = getActionName('RESET');

export function resetOrderState() {
  return {
    type: RESET
  };
}
