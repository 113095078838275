import NeckLabels from './NeckLabels';
import { connect } from 'react-redux';
import { skusWithNeckTagSelector } from '../../ProductPublishSelectors';
import { createNeckTag, fetchNeckTags, setSelectedNeckTag } from './NeckLabelsActions';
import { neckLabelsSelector, currentNeckLabelSelector, errorSelector } from './NeckLabelsSelectors';

const mapDispatchToProps = {
  fetchNeckTags: () => fetchNeckTags(),
  setSelectedNeckTag: payload => setSelectedNeckTag({ ...payload }),
  createNeckTag: item => createNeckTag(item)
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    error: errorSelector(state),
    neckLabelList: neckLabelsSelector(state),
    skusWithNeckTags: skusWithNeckTagSelector(state),
    selectedNeckLabel: currentNeckLabelSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NeckLabels);
