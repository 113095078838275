import { connect } from 'react-redux';
import NotesView from './NotesView';
import { updateOrderMeta, updateOrderItemsMeta } from '../OrderDetailsActions';

const mapDispatchToProps = {
  updateOrderMeta: (orderId, meta) => updateOrderMeta(orderId, meta),
  updateOrderItemsMeta: (orderId, itemIndexes, itemIds, meta) =>
    updateOrderItemsMeta(orderId, itemIndexes, itemIds, meta)
};

export default connect(null, mapDispatchToProps)(NotesView);
