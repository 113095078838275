import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { VimPromotionCard } from './CardView/PromotionCard';
import { PartnerTierCardView } from './CardView';
import { PartnerTierPageView } from './PageView';

import { tierRanks } from 'gooten-components/src/components/shared/PartnerTier';
import { mapCurrentTierSettingsDto, mapNextTierSettingsDto } from './partnerTierSettingsDto';

const PartnerTier = ({ cardView, data, isSuperAdmin, showVimSettings }) => {
  const [currentTierStatus, setCurrentTierStatus] = useState('');
  const [currentTierSettings, setCurrentTierSettings] = useState({});
  const [nextTierSettings, setNextTierSettings] = useState({});

  useEffect(() => {
    const currentStatus = data.Status && data.Status.toLowerCase();
    if (tierRanks.some(x => x.name === currentStatus)) {
      setCurrentTierStatus(currentStatus);
      setCurrentTierSettings(mapCurrentTierSettingsDto(data));
      setNextTierSettings(mapNextTierSettingsDto(data));
    }
  }, [JSON.stringify(data)]);

  if (!showVimSettings) {
    return null;
  }

  return currentTierStatus ? (
    cardView ? (
      <PartnerTierCardView status={currentTierStatus} />
    ) : (
      <PartnerTierPageView
        status={currentTierStatus}
        currentTierData={currentTierSettings}
        nextTierData={nextTierSettings}
      />
    )
  ) : cardView ? (
    <VimPromotionCard />
  ) : null;
};

PartnerTier.propTypes = {
  data: PropTypes.any,
  cardView: PropTypes.bool,
  showVimSettings: PropTypes.bool.isRequired
};

Object.defineProperty(PartnerTier, 'name', { value: 'partnerTier' });

export default PartnerTier;
