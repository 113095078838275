import { connect } from 'react-redux';
import BackgroundSelect from './BackgroundSelect';
import {
  backgroundPresetsSelector,
  backgroundCustomColorSelector
} from './BackgroundSelectSelectors';
import { updateCustomColor } from './BackgroundSelectActions';
import { generateBackgrounds, updateBackgrounds } from '../../../ProductPreviewActions';
import previewService from '../../../services/productPreviewService';

const mapDispatchToProps = dispatch => {
  return {
    updateBackground: (skus, color) => {
      const data = previewService.updateBackgrounds(skus, color);
      dispatch(updateBackgrounds(data));
      dispatch(generateBackgrounds(data));
    },
    updateCustomColor: color => dispatch(updateCustomColor(color))
  };
};

const mapStateToProps = state => {
  return {
    presets: backgroundPresetsSelector(state),
    customColor: backgroundCustomColorSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundSelect);
