import { imageUpdate } from '../../../state/actions/actionCreators';
import ImageService from '../../image/image.service';
import ZoomService from './zoom.service';
import EventEmitter from 'events';
import PositioningService from '../../../utils/positioningService';
import { containerColorSelector } from '../../../state/selectors/editorSelector'
import { PUBLIC_EVENTS, INTERNAL_EVENTS } from '../../../ImageEditor.events';
import {
  MAX_SCALE,
  MIN_SIDE_SIZE
} from '../../../ImageEditor.const';

export default class ZoomApi {

  ctx: any;
  currentImage: any;
  currentImageCanvasState: any;
  currentLayer: any;
  consumer: any;
  publicEvents: EventEmitter;
  //Will contains max scale for selected image
  maxScale : number;


  constructor(consumer: any, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;
    
    if (!consumer) {
      return;
    }
    this.consumer = consumer;
    this.consumer.register({
      'onInteract': (scale: number) => this.interactImageScale(scale),
      'onApply' : (scale: number) => this.applyImageScale(scale),
      'onZoomCanvas': (zoom: number) => this.applyCanvasZoom(zoom)
    });
  }

  setActive(ctx : any) {
    this.ctx = ctx;
    this.currentImage = !!ctx.state.images.current.selected.imageId
      ? ctx.state.images.current.images.find((i) => i.id == ctx.state.images.current.selected.imageId)
      : null;

    let previewMode = ctx.state.editor.mode === 'preview';
    let havingMultipleImages = ctx.state.images.current.images.length > 1;
    if (!this.currentImage || !this.currentImage.sourceWidth || ctx.state.editor.cropActivated || (previewMode && havingMultipleImages)) {
      if (!!this.consumer) {
        this.consumer.disable(ctx.state.editor.cropActivated ? this.currentImage.scale : null);
        this.updateDpi(ctx.state.editor.cropActivated ? this.currentImage.scale : null);
      }
      return null;
    }

    //If image to small use initial scale as maximum
    this.maxScale = Math.max(this.currentImage.initialScale, MAX_SCALE);
    this.currentLayer = !!ctx.state.images.current.selected.layerId
      ? ctx.state.template.layers.find((i) => i.id == ctx.state.images.current.selected.layerId)
      : null;

    this.currentImageCanvasState = ImageService.getSelectedImageCanvasState(this.ctx.state);

    if (!this.consumer) {
      return;
    }

    let minScale = Math.min(0.01, this.currentImage.initialScale);

    if(this.currentImageCanvasState.sourceImageCrop){
      let minVisibleScale = (MIN_SIDE_SIZE/Math.min(this.currentImageCanvasState.width, this.currentImageCanvasState.height) * this.currentImage.scale);
      minScale = Math.max(minVisibleScale, minScale);
    }

    this.consumer.setActive({
      //Calculated max scale for current iteration
      maxScale : Math.min(this.currentImage.scale < 1
        ? 2
        : this.currentImage.scale * 2
      , this.maxScale),
      scale: this.currentImage.scale,
      minScale: minScale,
      stageScaleX: this.ctx.stage.scaleX()
    });
    this.updateDpi(this.currentImage.scale);
  }

  interactImageScale(scale: number) {
    scale = Math.min(scale, this.maxScale);

    let newWidth = this.currentImage.realSourceWidth * scale;
    let newHeight = this.currentImage.realSourceHeight * scale;

    if (this.currentImageCanvasState.sourceImageCrop) {
      newWidth = this.currentImageCanvasState.sourceImageCrop.width /
        this.currentImageCanvasState.scaleFromCurrent *
        this.currentImageCanvasState.scaleFromOriginal * scale;
      newHeight = this.currentImageCanvasState.sourceImageCrop.height /
        this.currentImageCanvasState.scaleFromCurrent *
        this.currentImageCanvasState.scaleFromOriginal * scale;
    }

    let inCanvasSize = PositioningService.convertToCanvasSize({
      x : this.currentImage.x,
      y : this.currentImage.y,
      width : newWidth,
      height : newHeight
    }, this.ctx.state);

    // do emit imageUpdate
    let eventArgs = {
      imageId: this.currentImage.id,
      width: inCanvasSize.width,
      height: inCanvasSize.height,
      offsetX: inCanvasSize.width/2,
      offsetY: inCanvasSize.height/2,
      action: 'zoom'
    };
    this.ctx.events.emit(INTERNAL_EVENTS.IMAGE_UPDATE, eventArgs);
    this.updateDpi(scale);

    return {width : inCanvasSize.width, height : inCanvasSize.height};
  }

  getCurrentScale() {
    return this.currentImage.scale;
  }

  applyImageScale(scale: number) {
    scale = Math.min(scale, this.maxScale);
    let pos = {};

    // NOTE: Check if scaled image is no longer inside printable area
    // do the centering then
    const image = this.currentImage;
    if (ZoomService.isZoomedImageOutsideOfViewport(scale, image, this.ctx.state)) {
      pos = PositioningService.getCenterPosition(this.ctx.state);
    }

    this.publicEvents.emit(PUBLIC_EVENTS.SCALE, scale);
    this.updateDpi(scale);
    this.ctx.actionProcessorCb(
      imageUpdate(
        this.currentImage.id, {
          scale: scale,
          ...pos
        }
      )
    );
  }

  applyCanvasZoom(zoom: number) {
    let newZoom = Number(zoom);

    var pointerInMiddle = { x: this.ctx.stage.height()/2, y: this.ctx.stage.width()/2 };

    this.ctx.stage.scale({ x: newZoom, y: newZoom });

    var newPos = {
      x: pointerInMiddle.x - pointerInMiddle.x * newZoom,
      y: pointerInMiddle.y - pointerInMiddle.y * newZoom,
    };

    if(this.ctx.stage.rotation() === 90) {
      newPos = {
        x: this.ctx.stage.width() * newZoom + pointerInMiddle.x - pointerInMiddle.x * newZoom,
        y: pointerInMiddle.y - pointerInMiddle.y * newZoom,
      };
    }

    const containerColor = containerColorSelector(this.ctx.state);
    if(containerColor){
      this.ctx.stage.container().style.backgroundColor = containerColor;
    }  

    this.publicEvents.emit(PUBLIC_EVENTS.CANVAS_ZOOM, zoom);
    this.ctx.stage.position(newPos);
    this.ctx.stage.batchDraw();
  }

  updateDpi(scale) {
    if (!this.consumer) {
      return;
    }

    if (!scale) {
      this.consumer.updateDpi(null);
      return;
    }

    let dpiValue = ZoomService.getDpiValue(scale, this.currentImage, this.ctx.state);
    this.consumer.updateDpi(dpiValue);
  }

};
