import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB', 'PROVIDERS');

// ------------------------------------
// Constants
// ------------------------------------
export const PROVIDER_SELECT = getActionName('PROVIDER_SELECT');

// ------------------------------------
// Actions
// ------------------------------------
export function providerSelect({ provider = 'all', reset = true }) {
  return {
    type: PROVIDER_SELECT,
    payload: { provider, reset }
  };
}
