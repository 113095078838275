import { connect } from 'react-redux';
import ProductName from './ProductName';
import {
  productNameSelector,
  providerNameSelector,
  storeIdSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import { changeStoreField, changeStorageField, setError } from '../../../ProductPublishActions';
import { associatedErrorNames, serverErrorsSelector } from '../../../ProductPublishSelectors';

const mapDispatchToProps = {
  setError: (storeId, section, message) => setError({ storeId, section, message }),
  onChange: (storage, storeId, field, value) =>
    storage ? changeStorageField({ field, value }) : changeStoreField({ storeId, field, value })
};

const mapStateToProps = state => {
  return {
    productName: productNameSelector(state),
    storeId: storeIdSelector(state),
    serverErrors: serverErrorsSelector(state),
    selectedProviderName: providerNameSelector(state),
    associatedErrorNames: associatedErrorNames(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductName);
