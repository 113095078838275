import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { COLORS } from '../../constants.js';
import Button from './Button';

export const DropDown = ({
  id,
  isOpen,
  disabled,
  className,
  toggle,
  title,
  children,
  buttonOnly = false,
  rightAlign,
  bottomAlign,
  hasError = false,
  fullWidth,
  scrollHeight,
  size = 'large'
}) => {
  return (
    <div className={` ${className || ''} dropdown-container`}>
      {buttonOnly ? (
        <Button
          className={`button-default ${size}`}
          id={id}
          disabled={!!disabled}
          onClick={toggle}
          iconOnly
          dataEvent={title && typeof title === 'string' ? title : null}
        >
          <Icon icon="moreVertical" />
        </Button>
      ) : (
        <Button
          id={id}
          className={`dropdown-button button-default ${size} w-100 ${
            typeof title === 'string' ? title.replace(/\s+/g, '-').toLowerCase() : ''
          }`}
          disabled={!!disabled}
          onClick={toggle}
          type="button"
          dataEvent={title && typeof title === 'string' ? title : null}
        >
          <div className="dropdown-text w-100">
            <span className="pr-3 button-text" title={typeof title === 'string' ? title : null}>
              {title}
            </span>
            <Icon className="down-icon ml-2 mr-n2" icon="chevronDown" />
          </div>
        </Button>
      )}
      <div className={`dropshadow-1 dropdown-list ${size}`}>{children}</div>
      <style jsx>
        {`
          .dropdown-container {
            position: relative;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: ${fullWidth ? '100%' : '20rem'};
          }
          .dropdown-container :global(button) {
            border: ${hasError
              ? `1px solid ${COLORS.cayennePepper}`
              : `1px sold ${COLORS.neutralLight200}`};
          }
          .dropdown-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .dropdown-container :global(.dropdown-button .button-text) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .dropdown-list {
            display: ${isOpen ? 'flex' : 'none'};
            flex-direction: column;
            position: absolute;
            margin: 0;
            width: ${fullWidth ? '100%' : 'fit-content'};
            padding: 0;
            background: ${COLORS.neutralLight50};
            z-index: 1000;
            padding: 0.25rem 1rem;
            border-radius: 4px;
            top: ${bottomAlign ? 'unset' : '3rem'};
            bottom: ${bottomAlign ? '2rem' : 'unset'};
            left: ${rightAlign ? 'unset' : '0'};
            right: ${rightAlign ? '0' : 'unset'};
            margin-bottom: 1rem;
            max-width: ${fullWidth ? '100%' : '14.5rem'};
            max-height: ${scrollHeight ? `${scrollHeight}rem` : 'initial'};
            overflow-y: ${scrollHeight ? `auto` : 'initial'};
          }
          .dropdown-list.medium {
            top: ${bottomAlign ? 'unset' : '2.5rem'};
            bottom: ${bottomAlign ? '1.5rem' : 'unset'};
          }
          .dropdown-list.small {
            top: ${bottomAlign ? 'unset' : '2rem'};
            bottom: ${bottomAlign ? '1.5rem' : 'unset'};
          }
          :global(.dropdown-button .down-icon) {
            min-width: 24px;
          }
        `}
      </style>
    </div>
  );
};

DropDown.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
  buttonOnly: PropTypes.bool,
  rightAlign: PropTypes.bool,
  bottomAlign: PropTypes.bool,
  hasError: PropTypes.bool,
  fullWidth: PropTypes.bool,
  scrollHeight: PropTypes.number
};

export default DropDown;
