import FileUpload from 'react16-fileupload';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { imageEvidenceOptions } from '../../ReprintSelfServiceService';

// this has to be class component, because FileUpload takes only string refs...
class ImageEvidenceUpload extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="upload-from-device">
          <FileUpload options={imageEvidenceOptions(this.props.callback)}>
            <a className="upload-from-device-link" ref="chooseBtn">
              upload from your device
            </a>
          </FileUpload>
        </div>
        <style jsx>{`
          margin: -7px 0 30px 0;
          z-index: 1000;

          .upload-from-device-link {
            height: 20px;
            margin: 0;
            cursor: pointer;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

ImageEvidenceUpload.propTypes = {
  callback: PropTypes.func.isRequired
};

export default ImageEvidenceUpload;
