import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import HubView from './HubView';
import CreateProductView from './CreateProductView';
import EditProductView from './EditProductView';
import DuplicateProductView from './DuplicateProductView';
import EditDesignView from '../CustomOrderForm/EditDesignView';
import ConnectStoreView from './ConnectStoreView';
import linkProductRoutes from './LinkProduct/LinkProductRoutes';
import PlaceOrderView from './PlaceOrderView';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import Catalog from './CreateProductView/Catalog';

const hubRoutes = {
  path: '/hub',
  component: CoreLayout,
  indexRoute: { component: withErrorBoundary(HubView) },
  childRoutes: [
    {
      path: 'create-product(/:step)',
      component: withErrorBoundary(CreateProductView)
    },
    {
      path: 'edit-product(/:step)',
      component: withErrorBoundary(EditProductView)
    },
    {
      path: 'duplicate-product(/:step)',
      component: withErrorBoundary(DuplicateProductView)
    },
    {
      path: 'edit-design(/:step)',
      component: withErrorBoundary(EditDesignView)
    },
    {
      path: 'place-order',
      component: withErrorBoundary(PlaceOrderView)
    },
    {
      path: 'catalog',
      component: withErrorBoundary(Catalog)
    },
    {
      path: ':provider',
      component: withErrorBoundary(HubView)
    },
    {
      path: ':provider/connect-store',
      component: withErrorBoundary(ConnectStoreView)
    },
    {
      path: ':provider/:store/link-product',
      ...linkProductRoutes
    },
    {
      path: ':provider/:store',
      component: withErrorBoundary(HubView)
    }
  ]
};

export default hubRoutes;
