import React from 'react';
import PropTypes from 'prop-types';

const ShippingAddress = ({ shippingAddress }) => {
  const lineItem = (name, value) => {
    return (
      <React.Fragment>
        <div className="shipping-line-item">
          <div className="title-3">{name}</div>
          <div className="title-4">{value}</div>
        </div>
        <style jsx>{`
          display: flex;
          flex-direction: row;
          margin: 4px 0 0 10px;

          .title-3 {
            width: 150px;
          }
        `}</style>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="shipping-address-part">
        {lineItem('Name', `${shippingAddress.get('FirstName')} ${shippingAddress.get('LastName')}`)}
        {lineItem('Address Line 1', shippingAddress.get('Line1'))}
        {lineItem('Address Line 2', shippingAddress.get('Line2'))}
        {lineItem('City', shippingAddress.get('City'))}
        {lineItem('State/Region', shippingAddress.get('State'))}
        {lineItem('Country', shippingAddress.get('CountryCode'))}
        {lineItem('Postal/Zip', shippingAddress.get('PostalCode'))}
        {lineItem('Phone Number', shippingAddress.get('Phone'))}
        {lineItem('Email', shippingAddress.get('Email'))}
      </div>
      <style jsx>{`
        .shipping-address-part {
          margin: 20px;
        }

        .shipping-address-part .shipping-line-item {
          display: flex;
          flex-direction: row;
          margin: 0 0 10px 20px;
        }
      `}</style>
    </React.Fragment>
  );
};

ShippingAddress.propTypes = {
  shippingAddress: PropTypes.object.isRequired
};

export default ShippingAddress;
