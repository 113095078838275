import React from 'react';
import { StandardLogin } from './StandardLogin';
import { CustomizedLogin } from './CustomizedLogin';

// All of the white labeled config below defines the styling of the Login page only (no other pages)
// If any properties below are not provided for a specific partner, then the default value is used

// partnerName: This is what the code matches to the subdomain to see what config to use
// formattedName: This text becomes the page title, bookmark text and displayed in place of the logo if no logo is supplied,
// bgColor: This is the color used as the background color behind the login component (default: #fff)
// headerColor: This is the color used for the page header (ie. Login) (default: #2c2229)
// inputPlaceholderColor: This is the color used for the input field labels (default: #2c2229) (note that typed text within input fields currently cannot have their color changed)
// inputBorderColor: This is the color of the border for the input fields in their default state (default: #ccc)
// inputBorderHoverColor: This is the color of the border for the input fields when user hovers their mouse over them (default: #000)
// buttonBgColor: This is the color used for the button's background (default: #000)
// buttonTextColor: This is the color used for the text on the button (default: #fff)
// loaderColor: This is the color used for the spinning loader on the button (default: #3f3f3f)
// bulletTextColor: This is the color of the bullet text (default: #fff)
// bulletLinkColor: This is the color of any hyperlinks that may exist in the bullet text (default: #faebd7)
// errorTextColor: This is the color of the error text (ie. they entered an incorrect password) (default: #c80404)
// logoImage: The location of the partner's logo
// backgroundImage: The location of their background image
// backgroundImageDarken: A decimal between 0 and 1 that represent how much to darken the background image (0 = Leave the image as is, 1 is completely black) (default: 0)
// favIcon: The location of the partner's favicon
// bullets: An array of sentences to be displayed as bullet points on the page

const whiteLabelledPartners = [
  {
    partnerName: 'wlm',
    formattedName: 'WLM',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogo.svg',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/wlm/logo.svg',
    backgroundImage: '/admin-assets/img/AssetsWhiteLabel/wlm/bg.jpg',
    backgroundImageDarken: 0.48,
    favIcon: '/admin-assets/img/AssetsWhiteLabel/wlm/favicon.ico',
    bullets: [
      'Create White Label MFG. apparel items and dozens more fully customizable products at preferential pricing',
      'High-quality, scalable, on-demand order fulfillment for global rights holders & their brands',
      'Built-in Shopify integration with tools to create, link, and sell new & existing products in minutes',
      'White glove customer service & account management for established brands'
    ]
  },
  {
    partnerName: 'redwall',
    formattedName: 'Redwall',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogo.svg',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/redwall/logo.svg',
    backgroundImage: '/admin-assets/img/AssetsWhiteLabel/redwall/bg.jpg',
    backgroundImageDarken: 0.48,
    favIcon: '/admin-assets/img/AssetsWhiteLabel/redwall/favicon.ico',
    bullets: [
      'Create Redwall apparel items and dozens more fully customizable products at preferential pricing',
      'High-quality, scalable, on-demand order fulfillment for global rights holders & their brands',
      'Built-in Shopify integration with tools to create, link, and sell new & existing products in minutes',
      'White glove customer service & account management for established brands'
    ]
  },
  {
    partnerName: 'alleius',
    formattedName: 'Alleius',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogo.svg',
    inputBorderHoverColor: '#ccc',
    buttonBgColor: '#092dc5',
    logoImage: null,
    backgroundImage: null,
    favIcon: null,
    bullets: []
  },
  {
    partnerName: 'fujifilm',
    formattedName: 'Fujifilm',
    inputBorderColor: '#386093',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogo.svg',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/fujifilm/logo.svg',
    backgroundImage: '/admin-assets/img/AssetsWhiteLabel/fujifilm/bg.jpg',
    backgroundImageDarken: 0.48,
    favIcon: '/admin-assets/img/AssetsWhiteLabel/fujifilm/favicon.ico',
    bullets: [
      'Create Fujifilm: Print on Demand apparel items and dozens more fully customizable products at preferential pricing',
      'High-quality, scalable, on-demand order fulfillment for global rights holders & their brands',
      'Built-in Shopify integration with tools to create, link, and sell new & existing products in minutes',
      'White glove customer service & account management for established brands'
    ]
  },
  {
    partnerName: 'merchcast',
    formattedName: 'MerchCast',
    inputBorderColor: '#386093',
    buttonBgColor: '#84b73e',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogo.svg',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/merchcast/logo.svg',
    backgroundImage: '/admin-assets/img/AssetsWhiteLabel/merchcast/bg.jpg',
    backgroundImageDarken: 0.48,
    favIcon: '/admin-assets/img/AssetsWhiteLabel/merchcast/favicon.ico',
    bullets: [
      'High-quality, scalable, on-demand order fulfillment for global rights holders & their brands',
      '100+ fully customizable products with preferential pricing from a best-in-class global manufacturing network',
      'Built-in Shopify integration with tools to create, link, and sell new & existing products in minutes',
      'White glove customer service & account management for established brands'
    ]
  },
  {
    partnerName: 'sensaria',
    formattedName: 'Sensaria',
    bgColor: '#1d5f79',
    headerColor: '#fff',
    buttonBgColor: '#cb8f29',
    errorTextColor: '#ffd4d4',
    xStyle: 'white-x',
    gootenLogoImage: '/admin-assets/img/AssetsWhiteLabel/gootenLogoWhite.svg',
    logoImage: '/admin-assets/img/AssetsWhiteLabel/sensaria/logo.svg',
    backgroundImage: '/admin-assets/img/AssetsWhiteLabel/sensaria/bg.jpg',
    favIcon: '/admin-assets/img/AssetsWhiteLabel/sensaria/favicon.ico',
    bullets: []
  }
];

export const LoginView = props => {
  let subDomain = window.location.host.split('.')[0];
  const parterData = whiteLabelledPartners.find(
    element => element.partnerName === subDomain || `${element.partnerName}-stage` === subDomain
  );

  return <>{parterData ? <CustomizedLogin partnerData={parterData} /> : <StandardLogin />}</>;
};

LoginView.displayName = 'LoginView';
