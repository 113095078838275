import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Wizard } from 'gooten-components';
import AppStore from '../../store';
import Config from '../../config';
import CountrySelection from 'gooten-components/src/components/shared/CountrySelection';

const Container = styled.div`
  .status-message {
    text-align: center;
    margin-top: 50px;
  }
`;

class CartView extends React.Component {
  static statusMessages = {
    initial: 'Loading products..',
    productsLoaded: 'Loading product variants..',
    variantsLoaded: 'Loading product details..',
    detailsLoaded: 'Loading templates..',
    productNotAvailable: 'Selected product is not available',
    failed: 'Failed adding products to cart'
  };

  renderLoadingStatusMessage(status) {
    let message = CartView.statusMessages[status] || 'Loading';
    return (
      <Container>
        <h3 className="status-message">{message}</h3>
      </Container>
    );
  }

  renderCOFWizard() {
    // get initial config and modify it depends on how you want Wizard to show up
    const config = Config.toJS();

    config.cof = true;

    if (this.props.source) {
      // don't pass null
      config.analyticsSource = this.props.source;
    }

    config.analyticsCategory = 'COF';

    // NOTE: ImageUpload is a last step since - we need it for edit in Cart.
    // We should pass it to make it available for NavManager
    config.steps = [
      {
        id: 'Cart',
        config: {
          hideStatusBar: true,
          onKeepShoppingClick: () => {
            this.props.router.push('/custom-order-form');
            // NOTE: return false here to prevent default handler execute
            return false;
          }
        }
      },
      {
        id: 'Checkout',
        config: {
          hideStatusBar: true
        }
      },
      {
        id: 'ImageUpload',
        config: {
          hideStatusBar: true,
          nextButtonLabel: 'Add to cart',
          cartEditMode: {
            backButtonLabel: 'Cancel',
            nextButtonLabel: 'Save',
            parentStep: 'Cart'
          },
          editDesignMode: {
            active: true
          },
          editorConfig: {
            container: '.gooten-image-editor',
            state: null,
            allowMultiImages: false,
            zoomControlShown: true,
            previewControlShown: false,
            toolbarShown: true,
            toolbarOptions: {
              customClass: 'my-toolbar',
              buttons: [
                {
                  type: 'alignHorizontal',
                  name: 'Align Horizontal',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                {
                  type: 'alignVertical',
                  name: 'Align Vertical',
                  customClass: 'my-toolbar-action editor-tooltip'
                },
                { type: 'crop', name: 'Crop', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'rotate', name: 'Rotate', customClass: 'my-toolbar-action editor-tooltip' },
                // { type: 'orientation', name: 'Orientation', customClass: 'my-toolbar-action' },
                { type: 'undo', name: 'Undo', customClass: 'my-toolbar-action editor-tooltip' },
                { type: 'redo', name: 'Redo', customClass: 'my-toolbar-action editor-tooltip' }
              ]
            }
          },
          sliderConfig: {
            width: 400,
            height: 600,
            contentHeight: 490
          }
        }
      }
    ];

    return <Wizard config={config} store={AppStore} />;
  }

  render() {
    if (this.props.loadingStatus !== 'ready') {
      return this.renderLoadingStatusMessage(this.props.loadingStatus);
    } else if (this.props.hasCartItems && !this.props.shippingCountry) {
      return <CountrySelection />;
    } else {
      return this.renderCOFWizard();
    }
  }
}

CartView.propTypes = {
  router: PropTypes.object.isRequired,
  loadingStatus: PropTypes.string.isRequired,
  shippingCountry: PropTypes.string,
  source: PropTypes.string,
  hasCartItems: PropTypes.bool.isRequired
};

export default CartView;
