import { connect } from 'react-redux';
import ConnectStoreView from './ConnectStoreView';
import { isConnectingSelector, errorMsgSelector } from './ConnectStoreViewSelectors';
import { selectedProviderSelector } from '../HubView/components/Providers/ProvidersSelectors';
import { connectStoreCancel, connectStore } from './ConnectStoreViewActions';
import { availableStoresSelector } from '../HubView/components/Stores/StoresSelectors';

const mapStateToProps = state => {
  return {
    selectedProvider: selectedProviderSelector(state),
    isConnecting: isConnectingSelector(state),
    oauthErrorMsg: errorMsgSelector(state),
    stores: availableStoresSelector(state)
  };
};

const mapDispatchToProps = {
  cancel: () => connectStoreCancel(),
  connectStore: (provider, storeName) => connectStore(provider, storeName)
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectStoreView);
