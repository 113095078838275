import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Wizard } from 'gooten-components';
import AppStore from '../../store';
import Config from '../../config';
import analyticsService from 'gooten-components/src/services/analyticsService';
import MobileNotificationModal from 'gooten-components/src/components/shared/MobileNotificationModal';

class EditProductView extends React.Component {
  static statusMessages = {
    initial: 'Loading products..',
    productsLoaded: 'Loading product variants..',
    variantsLoaded: 'Loading product details..',
    detailsLoaded: 'Loading templates..',
    productMappingsInvalid: 'This product cannot be edited at this time.',
    productNotAvailable: 'Selected product is not available'
  };

  loadingResponse(status) {
    let message = EditProductView.statusMessages[status] || 'Loading';
    return (
      <div className="edit-product-view-container">
        <h3 className="status-message">{message}</h3>
        <style jsx>
          {`
            .edit-product-view-container .status-message {
              text-align: center;
              margin-top: 50px;
            }
          `}
        </style>
      </div>
    );
  }

  componentDidMount() {
    if (!this.props.selectedProduct) {
      this.props.router.replace('/hub');
      return;
    }

    analyticsService.track('Hub - Edit Flow', 'Started', 'Hub');
    this.props.changeStep('initial');
  }

  render() {
    if (this.props.currentStep !== 'ready') {
      return this.loadingResponse(this.props.currentStep);
    }

    // Serg - looks like it is not used, b/c all publish data mapping happens
    // in editProductViewService
    let stores = this.props.stores;
    // if all data ready init wizard with updated config to start from design step
    const defaultConfig = Config.toJS();
    const steps = defaultConfig.steps;
    const saveAndPublishConfig = {
      stores: stores.map(s => ({
        id: s.id,
        name: s.storeName,
        provider: s.provider,
        collections: s.collections,
        tags: s.tags
      }))
    };
    steps.find(s => s.id === 'SaveAndPublish').config = saveAndPublishConfig;

    let config = {
      ...defaultConfig,
      steps: [
        // skip product selection
        // steps.find(s => s.id === 'ImageUpload'),
        // steps.find(s => s.id === 'ProductPreview'),
        steps.find(s => s.id === 'SaveAndPublish'),
        // NOTE: Leave this step here just to make it available for StatusBar
        steps.find(s => s.id === 'SKUSelection'),
        steps.find(s => s.id === 'ImageUpload'),
        steps.find(s => s.id === 'ProductPreview')
      ],
      editMode: true
    };

    config.analyticsSource = 'Hub - Edit Flow';
    config.analyticsCategory = 'Hub';

    return (
      <div>
        <Wizard config={config} store={AppStore} />
        <MobileNotificationModal />
      </div>
    );
  }
}

EditProductView.propTypes = {
  router: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object,
  stores: PropTypes.instanceOf(List),
  currentStep: PropTypes.string,
  changeStep: PropTypes.func.isRequired
};

export default EditProductView;
