import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';
import PagedQuery from '../../../../models/PagedQuery';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COF_PAST_ORDERS', 'PAST_ORDERS');

// ------------------------------------
// Action Types
// ------------------------------------
export const ORDER_ITEMS_LOAD = getActionName.async('ORDER_ITEMS_LOAD');
export const TOGGLE_ORDER_ITEM_SELECTED = getActionName('TOGGLE_ORDER_ITEM_SELECTED');
export const DESELECT_ALL = getActionName('DESELECT_ALL');
export const SET_CURRENT_PAGE = getActionName('SET_CURRENT_PAGE');

// ------------------------------------
// Actions
// ------------------------------------
export function orderItemsLoad(query = new PagedQuery()) {
  return fetchAsync(ORDER_ITEMS_LOAD, { query });
}

export function toggleOrderItemSelected(orderItem, isSelected) {
  return {
    type: TOGGLE_ORDER_ITEM_SELECTED,
    payload: { orderItem, isSelected }
  };
}

export function deselectAll() {
  return {
    type: DESELECT_ALL
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: { page }
  };
}
