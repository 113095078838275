import { ActionNameFactory } from '../../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'PRICING');
export const SELECT_STORE_PRICE_INDEX = getActionName('SELECT_STORE_PRICE_INDEX');
export const SELECT_STORE_PRICES = getActionName('SELECT_STORE_PRICES');
export const CHANGE_STORE_PRICES = getActionName('CHANGE_STORE_PRICES');

export const changeStorePrices = payload => ({
  type: CHANGE_STORE_PRICES,
  payload
});

export const selectStorePriceIndex = payload => ({
  type: SELECT_STORE_PRICE_INDEX,
  payload
});

export const selectStorePrices = payload => ({
  type: SELECT_STORE_PRICES,
  payload
});
