import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import SvgGradient from '../SvgGradient';

const BenefitCard = ({ icon, text, gradient, viewAll, fillColor = '#373F49' }) => {
  return (
    <>
      <div className="benefits-card">
        {gradient ? <SvgGradient {...gradient} /> : null}
        <Icon
          fill={gradient ? `url(#${gradient.id}badge)` : fillColor}
          className="b-icon"
          icon={icon}
          height={24}
          width={24}
        />
        <div className="card-text">
          <p>{text}</p>
          <a href="https://www.gooten.com/merchants/vim" target="_blank">
            {viewAll ? 'View all' : 'Learn more'}
          </a>
        </div>
      </div>
      <style jsx>{`
        :global(.b-icon) {
          margin-bottom: auto;
        }
        .card-text {
          padding-right: 12px;
        }
        .card-text p,
        a {
          margin: 0;
          font-family: Avenir-Roman;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 156%;
          letter-spacing: 0.15px;
          color: #1e2731;
          padding-bottom: 8px;
        }
        .benefits-card {
          flex: 1 0 220px;
          padding-right: 4px;
          margin-bottom: 24px;
        }
        .card-text a {
          font-family: Avenir-Roman;
          text-decoration-line: underline;
          padding-bottom: 0px;
          color: #646b72;
        }
      `}</style>
    </>
  );
};

SvgGradient.propTypes = {
  startColor: PropTypes.string.isRequired,
  endColor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  x1: PropTypes.string,
  x2: PropTypes.string,
  y1: PropTypes.string,
  y2: PropTypes.string
};

BenefitCard.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  viewAll: PropTypes.bool,
  fillColor: PropTypes.string,
  gradient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startColor: PropTypes.string.isRequired,
    endColor: PropTypes.string.isRequired,
    x1: PropTypes.string,
    x2: PropTypes.string,
    y1: PropTypes.string,
    y2: PropTypes.string
  })
};

export default BenefitCard;
