import { createSelector } from 'reselect';
import { publishDataSelector } from '../../../ProductPublishSelectors';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import { selectedSKUsSelector } from '../../../../../store/selectors/productDataSelectors';

const previewState = state => state.get('preview');

const previewDataSelector = createSelector([previewState], preview => preview.get('data'));

const previewFromDataSelector = createSelector([selectedSKUsSelector], skus => {
  if (skus.size) {
    const firstSku = skus.first();
    if (firstSku.getIn(['proPreview', 'imageUrls', 0])) {
      return getSmallPreviewImageResizerUrl(firstSku.getIn(['proPreview', 'imageUrls', 0]));
    } else if (firstSku.get('spaces').first().previewImgUrl) {
      return getSmallPreviewImageResizerUrl(firstSku.get('spaces').first().previewImgUrl);
    }
  }
  return null;
});

export const previewSelector = createSelector(
  [previewDataSelector, previewFromDataSelector],
  (previewData, previewFromData) => {
    return previewData.get('items').size
      ? previewData.getIn(['items', 0, 'previewImageUrl'])
        ? getSmallPreviewImageResizerUrl(previewData.getIn(['items', 0, 'previewImageUrl']))
        : null
      : previewFromData;
  }
);

export const infoSelector = createSelector([publishDataSelector], data => data && data.get('info'));
