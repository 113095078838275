import React from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import { parseCurrency } from './helpers';

const PartnerCostsCard = ({ orderBilling }) => {
  return (
    <div className="card-container">
      <Card title="Partner Costs">
        {!!orderBilling.get('PartnerFees') && (
          <div className="d-flex justify-content-between">
            <p>Products Cost</p>
            <div className="partner-costs-product d-flex flex-wrap justify-content-end">
              {parseCurrency(orderBilling.get('PartnerProductsCost'))}
            </div>
          </div>
        )}

        {!!orderBilling.get('PartnerFees') && (
          <div className="d-flex justify-content-between">
            <p>Digitization Fees</p>
            <div className="partner-costs-digitization d-flex flex-wrap justify-content-end">
              {parseCurrency(orderBilling.get('PartnerFees'))}
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <p>Sub Total</p>
          <div className="partner-costs-subtotal d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PartnerSubTotal'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Shipping</p>
          <div className="partner-costs-shipping d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PartnerShipping'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Surcharge</p>
          <div className="partner-costs-surcharge d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('ShippingSurcharge', 0))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Tax</p>
          <div className="partner-costs-tax d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PartnerTax'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Discount</p>
          <div className="partner-costs-discount d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PartnerDiscount'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Total</p>
          <div className="partner-costs-total d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PartnerTotal'))}
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <p>Partner Charge</p>
          <div className="partner-costs-partner-charge d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PartnerChargeAmount'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Transaction Provider</p>
          <div className="partner-costs-transaction-provider d-flex flex-wrap justify-content-end">
            {orderBilling.get('PartnerChargeTransactionProvider') || '-'}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Transaction Id</p>
          <div className="partner-costs-transaction-id d-flex flex-wrap justify-content-end">
            {orderBilling.get('PartnerChargeTransactionId') || '-'}
          </div>
        </div>
      </Card>
      <style jsx>
        {`
          .partner-costs-transaction-id {
            word-break: break-all;
            margin-left: 1rem;
          }
        `}
      </style>
    </div>
  );
};

PartnerCostsCard.propTypes = {
  orderBilling: PropTypes.object
};

export default PartnerCostsCard;
