import React from 'react';
import PropTypes from 'prop-types';

export const SvgGradient = ({ startColor, endColor, x1, x2, y1, y2, id }) => {
  return (
    <svg style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id={id} x1={x1} x2={x2} y1={y1} y2={y2} gradientUnits="userSpaceOnUse">
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

SvgGradient.propTypes = {
  id: PropTypes.string.isRequired,
  startColor: PropTypes.string.isRequired,
  endColor: PropTypes.string.isRequired,
  x1: PropTypes.string,
  x2: PropTypes.string,
  y1: PropTypes.string,
  y2: PropTypes.string
};

export default SvgGradient;
