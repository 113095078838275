// @flow

import type { Rect, Point } from '../../../flow/types.js';
import ImageService from '../../image/image.service';
import PositioningService from '../../../utils/positioningService';

export default class DragService {

  /**
   * Return template size image position after drag ended
   * @param {Number} x - event.target.x()
   * @param {Number} y - event.target.y()
   * @param {Object} state
   */
  static getUpdatedPositionRectOnDragEnd(x: number, y: number, state: any): Rect {

    const activeImageCanvasState = ImageService.getSelectedImageCanvasState(state);

    let updatedPosition = {
      x: x,
      y: y,
      width: activeImageCanvasState.width,
      height: activeImageCanvasState.height
    };

    // convert to template sizes
    return PositioningService.convertToTemplateSize(
      updatedPosition,
      state,
      state.images.current.selected.layerId
    );
  }

  /**
   * Return point limited to viewport canvas size boundaries
   * @param {Number} x
   * @param {Number} y
   * @param {Number} width
   * @param {Number} height
   * @param {Object} canvasImageState
   * @param {Object} state
   */
  static getLimitedDragAreaPointOnDrag(
    x: number,
    y: number,
    width: number,
    height: number,
    canvasImageState: any,
    state: any): Point {

    const isOrientationChanged =
      state.editor.orientation !== state.editor.defaultOrientation;

    const posRect = !isOrientationChanged
      ? { x, y, width, height}
      : { x: y, y: state.editor.height - x, width, height};

    const layerId = canvasImageState.layerId;
    const layer = state.template.layers.find(l => l.id === layerId);
    const viewportPosition = PositioningService.convertToCanvasSize(
      PositioningService.getViewportRect(layer),
      state,
      layer.id
    );

    const rotatedCanvasImageMaxRect = PositioningService.getRotatedMaxRect(
      canvasImageState.x + canvasImageState.width/2,
      canvasImageState.y + canvasImageState.height/2,
      canvasImageState.width,
      canvasImageState.height,
      canvasImageState.rotation
    );

    let limitedPos = posRect;
    // NOTE: Left some border space to allow grip image
    const BORDER = 10;
    // NOTE: Limit to viewport position
    if (posRect.x <= viewportPosition.x - rotatedCanvasImageMaxRect.width/2 + BORDER) {
      limitedPos.x = viewportPosition.x - rotatedCanvasImageMaxRect.width/2 + BORDER;
    }

    if (posRect.x >= (viewportPosition.x + rotatedCanvasImageMaxRect.width/2) + viewportPosition.width - BORDER) {
      limitedPos.x = (viewportPosition.x + rotatedCanvasImageMaxRect.width/2) + viewportPosition.width - BORDER;
    }

    if (posRect.y <= viewportPosition.y - rotatedCanvasImageMaxRect.height/2 + BORDER) {
      limitedPos.y = viewportPosition.y - rotatedCanvasImageMaxRect.height/2 + BORDER;
    }

    if (posRect.y >= (viewportPosition.y + rotatedCanvasImageMaxRect.height/2) + viewportPosition.height - BORDER) {
      limitedPos.y = (viewportPosition.y + rotatedCanvasImageMaxRect.height/2) + viewportPosition.height - BORDER;
    }

    // revert x and y if orientation changed
    const finalLimitedPos = !isOrientationChanged
      ? { x: limitedPos.x, y: limitedPos.y}
      : { x: state.editor.height - limitedPos.y, y: limitedPos.x };

    return finalLimitedPos;
  }
}
