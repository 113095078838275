import { ActionNameFactory } from '../../../utils/actions';
import { fetchAsync } from '../../../store/actions/dataActions';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COMPONENTS', 'SHIPPING_TIME');

// ------------------------------------
// Action Types
// ------------------------------------
export const SHIPPING_TIME_LOAD = getActionName.async('SHIPPING_TIME_LOAD');

// TODO Add model to query object
export const shippingTimeLoad = query => {
  return fetchAsync(SHIPPING_TIME_LOAD, { query, uniqueKey: JSON.stringify(query) });
};
