/*
 * This is a shared modal between Settings ==> Add-ons ==> NeckLabels,
 * and save and publish container when creating or editing, main functionalities
 * are avoiding repeating code and fetch image natural size on load.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput';
import ImageUploadModal from '../ImageUploadModal';
import ErrorMessage from '../../ProductPublish/atoms/shared/ErrorMessage';
import Config from '../../../config';

const IMAGE_LEGEND = () => {
  const getAddingNeckLabelsKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047743111-Adding-neck-labels-to-apparel-products';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4521346827291-Adding-neck-labels-to-apparel-products';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612611287195-Adding-neck-labels-to-apparel-products';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047743111-Adding-neck-labels-to-apparel-products';
    }
  };

  return (
    <div className="row" style={{ fontSize: '12px' }}>
      <div className="col-xs-12 paragraph">
        <ul>
          <li>Print space size is 2.5” × 1”</li>
          <li>
            Single color, we recommend Pantone Cool Grey 6C (#A7A8AA) because it will be visible on
            black and white fabrics.
          </li>
        </ul>
        <div className="col-xs-12 margin-top-10 paragraph">
          <a
            href={getAddingNeckLabelsKbLink()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#62C3B1', cursor: 'pointer', fontSize: '12px' }}
          >
            learn more
          </a>
          &nbsp;or&nbsp;
          <a
            href="https://printmeeappassets.blob.core.windows.net/placeholder/NeckLabel_Template_v2.psd"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#62C3B1', cursor: 'pointer', fontSize: '12px' }}
          >
            download template
          </a>
          <br />
        </div>
      </div>
    </div>
  );
};

const UploadNeckLabelLogo = props => {
  const [dimensions, setDimensions] = useState({});
  const [formIsInvalid, setFormIsInvalid] = useState(false);
  const [duplicateNameErr, setDuplicateNameErr] = useState(false);

  const createNewNeckLabel = image => {
    props.onSave({
      imgUrl: image,
      name: props.value,
      width: dimensions.width,
      height: dimensions.height
    });
  };

  useEffect(() => {
    props.value || setFormIsInvalid(true);
  }, []);

  const onChangeValue = value => {
    props.onChange(value);
    const neckTagName = value?.toLowerCase().replace(/\s+/g, ' ').trim();
    let ExistingNames =
      !!props.neckLabelsList &&
      props.neckLabelsList.map(n => n.name?.toLowerCase().replace(/\s+/g, ' ').trim());

    if (props.editName) {
      ExistingNames = ExistingNames.filter(
        x => x !== props.editName?.toLowerCase().replace(/\s+/g, ' ').trim()
      );
    }

    const nameExists = ExistingNames.contains(neckTagName);

    if (neckTagName) {
      setDuplicateNameErr(nameExists);
      setFormIsInvalid(nameExists);
      return;
    }
    setFormIsInvalid(true);
  };

  const handleSize = evt =>
    setDimensions({
      width: evt.target.naturalWidth,
      height: evt.target.naturalHeight
    });

  const errorStyles = props.errorStyles ? props.errorStyles : { marginTop: '-5px' };

  return (
    <ImageUploadModal
      modalTitle="Neck Label"
      isOpen
      neckTagPreview
      customFormUpdated={props.customFormUpdated}
      customFormInvalid={formIsInvalid}
      presetImage={props.presetImage}
      imageLegend={IMAGE_LEGEND}
      onSave={image => createNewNeckLabel(image)}
      getImgSize={handleSize}
      onCancel={props.onCancel}
    >
      <div className="col-xs-12">
        <TextInput
          id="neck-label-name"
          emptyMsg="This field is required"
          label="Name"
          placeholder="Label Name"
          onChange={value => onChangeValue(value)}
          maxLength={64}
          value={props.value}
        />
        {duplicateNameErr ? (
          <div style={errorStyles}>
            <ErrorMessage message={'Label name already taken'} />
            <br />
          </div>
        ) : null}
        {props.value.length >= 64 ? (
          <div style={errorStyles}>
            <ErrorMessage message={'Max length is 64'} />
            <br />
          </div>
        ) : null}
      </div>
      <style jsx>
        {`
          :global(.panel-container) {
            z-index: unset !important;
          }
        `}
      </style>
    </ImageUploadModal>
  );
};

UploadNeckLabelLogo.propTypes = {
  onSave: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  presetImage: PropTypes.string,
  neckLabelsList: PropTypes.any,
  customFormUpdated: PropTypes.bool,
  editName: PropTypes.any,
  errorStyles: PropTypes.object
};

UploadNeckLabelLogo.defaultProps = {
  presetImage: '',
  customFormUpdated: true,
  customFormInvalid: false
};

export default UploadNeckLabelLogo;
