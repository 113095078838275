import React from 'react';
import RadioButtonGroup from '../../../../../gooten-components/src/components/shared/RadioButtonGroup';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, string } from 'yup';
import Button from 'gooten-components/src/components/shared/Button';

const integrations = new List(['Shopify', 'Etsy', 'BigCommerce', 'WooCommerce', 'API', 'Other']);

const BusinessDetails = props => {
  return (
    <Formik
      initialValues={{
        storeStatus: props.businessDetailValues.storeStatus,
        integration: props.businessDetailValues.integration
      }}
      validationSchema={() =>
        object().shape({
          storeStatus: string().required('Please select an option.'),
          integration: string().required('Please select an option.')
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        props.onSubmit(values);
      }}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting, submitCount }) => (
        <div className="sign-up-card" style={{ display: !props.show && 'none' }}>
          <div className="main-header">Sign Up</div>
          <div className="main-subheader">No Credit Card Required. Takes 90 seconds!</div>
          <div className="card-section">
            <div className="card-header">YOUR BUSINESS</div>
            <div className="progress-step">
              STEP <span className="current-step">2</span>/3
            </div>
          </div>
          <div
            className={`sign-up-field ${
              submitCount > 0 && !!errors.storeStatus ? 'field-error' : ''
            }`}
          >
            <div className="field-question heavy mb-2">You are...</div>
            <RadioButtonGroup
              items={new List(['Owner of an existing store', 'Opening a new store'])}
              selectionIndex={values.storeStatus}
              onChange={(_, index) => setFieldValue('storeStatus', index)}
              disabled={isSubmitting}
              radioStyle
            />
            {submitCount > 0 && !!errors.storeStatus && (
              <div className="error-msg radio-button-error">{errors.storeStatus}</div>
            )}
          </div>
          <div
            className={`sign-up-field ${
              submitCount > 0 && !!errors.integration ? 'field-error' : ''
            }`}
          >
            <div className="field-question heavy">How do you sell?</div>
            <div className="caption-text mb-3">
              You will be able to connect to multiple stores once inside Gooten!
            </div>
            <RadioButtonGroup
              items={integrations}
              selectionIndex={integrations.indexOf(values.integration)}
              onChange={(_, index) => {
                setFieldValue('integration', integrations.get(index));
              }}
              disabled={isSubmitting}
              radioStyle
            />
            {submitCount > 0 && !!errors.integration && (
              <div className="error-msg radio-button-error">{errors.integration}</div>
            )}
          </div>
          <div className="button-area">
            <Button
              className="button-default extra-large mr-2 signup-business-details-back"
              onClick={() => props.onBackButton()}
            >
              Back
            </Button>
            <Button
              iconRight="chevronRight"
              className="button-secondary extra-large signup-business-details-continue"
              onClick={() => (isSubmitting ? null : handleSubmit())}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

BusinessDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  businessDetailValues: PropTypes.object.isRequired,
  onBackButton: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default BusinessDetails;
