import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Config from '../config';
import { LoginView } from '../Login/index.js';

import '../styles/_core.scss';
import { mainStyles } from 'gooten-components/src/styles';

class LoginContainer extends Component {
  static displayName = 'LoginContainer';

  static propTypes = {
    config: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    // Save immutable config
    const { config } = props;
    Config.destroy();
    Config.init(config);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { store } = this.props;
    return (
      <>
        <Provider store={store}>
          <LoginView wrongToken={this.props.config.wrongToken} />
        </Provider>
        <style jsx global>
          {mainStyles}
        </style>
      </>
    );
  }
}

export default LoginContainer;
