import { connect } from 'react-redux';
import ShipmentView from './ShipmentView';
import { shipmentData, loadingShipment } from './ShipmentViewSelectors';
import { getShipmentData } from './ShipmentViewActions';

const mapDispatchToProps = {
  getShipmentData: orderId => getShipmentData(orderId)
};

const mapStateToProps = state => {
  return {
    shipmentData: shipmentData(state),
    loading: loadingShipment(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentView);
