import React from 'react';
import PropTypes from 'prop-types';

import './EmptyLanding.scss';

export const StoreEmptyLanding = props => {
  return (
    <div className="store-storage-empty-landing-container text-center">
      <h3 className="text-center">There’s no products in this store yet.</h3>
      <div className="landing-image img-storage" />
      <p>Link your existing products from {props.integration}, or create new ones.</p>
    </div>
  );
};

StoreEmptyLanding.propTypes = { integration: PropTypes.string.isRequired };

export default StoreEmptyLanding;
