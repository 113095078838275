import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import { COLORS } from 'gooten-components/src/constants';
import Icon from 'gooten-components/src/components/shared/Icon';

const ReportTable = ({ fullscreen, header, data, maxItems, headerClick, sort }) => {
  const columns = () => (fullscreen ? '60% 10% 10% 10% 10%' : '36% 16% 16% 16% 16%');

  const arrowIcon = field => {
    if (sort.get('type') === field && fullscreen) {
      return (
        <Icon
          small
          fill={COLORS.neutralDark200}
          icon={sort.get('aToZ') ? 'arrowDown' : 'arrowUp'}
        />
      );
    } else {
      return null;
    }
  };

  const headerJSX = () => (
    <>
      <TableRow header size="small" columns={columns()}>
        {header.map((h, i) => {
          const headerShort = h.toLowerCase().replace(/\s/g, '');
          return (
            <Cell key={i} rightAlign={i !== 0}>
              {arrowIcon(headerShort)}
              <span
                className="caption-text heavy"
                onClick={() => fullscreen && headerClick(headerShort)}
              >
                {h}
              </span>
            </Cell>
          );
        })}
      </TableRow>
      <style jsx>{`
        span {
          font-size: 11px;
        }
      `}</style>
    </>
  );

  const item = (d, i) => (
    <React.Fragment key={i}>
      <TableRow size="small" columns={columns()}>
        <Cell>
          <span className="caption-text">{d.get('name')}</span>
        </Cell>
        {d.get('percents').map((p, index) => (
          <Cell key={index} rightAlign>
            <span className="caption-text">{p}</span>
          </Cell>
        ))}
      </TableRow>
      <style jsx>{`
        span {
          color: black !important;
        }
      `}</style>
    </React.Fragment>
  );

  const tableItems = () =>
    fullscreen
      ? data.map((d, i) => item(d, i))
      : data.skipLast(data.size - maxItems).map((d, i) => item(d, i));

  return (
    <>
      <div className="report-table mt-3 mb-4">
        {headerJSX()}
        {tableItems()}
      </div>
      <style jsx>{`
        .report-table {
          border: 1px solid ${COLORS.neutralLight400};
          border-radius: 8px;
        }

        :global(.table-cell) {
          background-color: transparent !important;
        }

        :global(.header-row) {
          background-color: ${COLORS.neutralLight100};
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          border-bottom: ${data.size ? '1px solid ${COLORS.neutralLight400' : 'none'};
          border-bottom-right-radius: ${data.size ? 'none' : '7px'};
          border-bottom-left-radius: ${data.size ? 'none' : '7px'};
        }

        :global(.header-row span) {
          cursor: ${fullscreen ? 'pointer' : 'default'} !important;
        }
      `}</style>
    </>
  );
};

ReportTable.propTypes = {
  fullscreen: PropTypes.bool.isRequired,
  header: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(List).isRequired,
  maxItems: PropTypes.number.isRequired,
  headerClick: PropTypes.func.isRequired,
  sort: PropTypes.instanceOf(Map).isRequired
};

export default ReportTable;
