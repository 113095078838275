import React from 'react';
import { all, select, put, takeLatest, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import Log from '../../services/logService';
import publishService from './services/publishService';
import validationService from './services/validationService';
import analyticsService from '../../services/analyticsService';

import {
  PUBLISH_PREPARE,
  PUBLISH,
  fetchAsyncFail,
  fetchAsyncSuccess,
  PREVIEWS_PREPARE,
  fetchAsync,
  EXCLUSIVE_MOCKUPS_PREPARE,
  SKU_SELECT,
  GET_NECK_TAG_PREVIEWS
} from '../../store/actions/dataActions';

import {
  updatePublishData,
  getReady,
  COMPONENT_MOUNTED,
  updateValidation,
  resetPublishData,
  setOrientationChanged,
  backupSkus,
  setWarningDialog
} from './ProductPublishActions';

import {
  productsSelector,
  allDetailedSkusSelector,
  publishConfigSelector,
  publishRequestSelector,
  selectedProductsSelector,
  publishValidationSelector,
  publishDataSelector,
  skusWithNeckTagSelector,
  holdForPersonalizationSelector,
  hubPersonalizationSelector,
  savedMainMockupSelector,
  backupSkusSelector,
  warningDialogSelector
} from './ProductPublishSelectors';
import { infoSelector } from './atoms/shared/ProductImage/ProductImageSelectors';
import { fetchNeckTags, neckTagGenerationError } from './atoms/NeckLabels/NeckLabelsActions';
import { currentNeckLabelSelector } from './atoms/NeckLabels/NeckLabelsSelectors';

import {
  updateSkuNeckTagImgUrl,
  ADD_VARIANT
} from './atoms/shared/ProductVariants/ProductVariantsActions';

import { getDateUTCNowShortStr } from '../../utils/random';
import { updatePreviewSize } from '../ProductPreview/ProductPreviewActions';
import {
  previewDataSelector,
  previewItemsSelector
} from '../ProductPreview/ProductPreviewSelectors';
import defaultPreviewConfig from '../ProductPreview/ProductPreviewDefaultConfig';
import { shouldChangeOrientation } from '../SKUSelection/SKUSelectionSelectors';

import Config from '../../config';
import generateNeckTagImageUrl from '../../services/imgManipForNeckTags';
import {
  disabledSKUsSelector,
  selectedSKUsSelector
} from '../../store/selectors/productDataSelectors';
import { GOTO_PREVIEWS_PAGE, saveMainMockup } from './atoms/Mockups/MockupsActions';
import { GOTO_DESIGN_PAGE } from './atoms/Design/DesignActions';
import { holdForPersonalization } from './atoms/Advanced/AdvancedActions';
import { isEmbroiderySelected } from '../ImageUpload/atoms/Embroidery/EmbroiderySelectors';

function* publishPrepareAsyncHandler(action) {
  let products;
  let skus;
  try {
    products = yield select(productsSelector);
    skus = yield select(allDetailedSkusSelector);

    const config = yield select(publishConfigSelector);
    let previewData = yield select(previewDataSelector);
    const disabledSkus = yield select(disabledSKUsSelector);
    const previewItems = yield select(previewItemsSelector);

    // if we are to early here, just skip... (can be triggered by pro previews creation)
    if (JSON.stringify(config) === JSON.stringify({})) {
      return;
    }

    const oldData = yield select(publishDataSelector);
    const allSkus = ((disabledSkus && disabledSkus.toJS()) || []).concat(skus.toJS());
    const data = publishService.mapFromSkus(
      allSkus,
      products,
      oldData && oldData.toJS(),
      previewData,
      config.toJS()
    );
    data.info = yield select(infoSelector);

    yield put(updatePublishData(data));
    yield put(getReady());

    // if orientation has been changed, update publish data
    const changeOrientation = yield select(shouldChangeOrientation);
    yield put(setOrientationChanged(changeOrientation));

    // fetch neck tags here, in order to apply it to newly added skus if exist...
    yield put(fetchNeckTags());

    // in Edit flow we need to generate previews, because that step is skipped,
    // if previews don't exist already
    const externalMockups = skus.filter(item => item.get('externalMockup'));
    const proPreviews = skus.filter(item => item.get('proPreview'));
    const otherMockups = skus.filter(
      item => !item.get('proPreview') && !item.get('externalMockup')
    );

    const shouldCreatePreviews =
      proPreviews.every(item => item.getIn(['proPreview', 'imageUrls']).size === 0) &&
      externalMockups.every(item => item.getIn(['externalMockup', 'imageUrls']).size === 0) &&
      otherMockups.every(
        item =>
          item.getIn(['spaces', 0, 'base64']) === undefined &&
          item.getIn(['spaces', 0, 'previewImgUrl']) === undefined
      );

    if (Config.get('editMode') && shouldCreatePreviews) {
      // set default preview size
      yield put(
        updatePreviewSize({
          width: defaultPreviewConfig.get('defaultWidth'),
          height: defaultPreviewConfig.get('defaultHeight')
        })
      );

      if (otherMockups.size) {
        yield put(
          fetchAsync(PREVIEWS_PREPARE, {
            items: previewItems,
            disableNextStep: true
          })
        );
      }
      if (proPreviews.size || externalMockups.size) {
        yield put(
          fetchAsync(EXCLUSIVE_MOCKUPS_PREPARE, {
            skus: Array.from(
              new Set(
                proPreviews.map(item => item.sku).concat(externalMockups.map(item => item.sku))
              )
            )
          })
        );
      }
    }

    // check for personalization here...
    const localPersonalize = yield select(holdForPersonalizationSelector);
    if (Config.get('editMode') && localPersonalize === undefined) {
      const hubProductPersonalize = yield select(hubPersonalizationSelector);
      // set local personalize state with personalize state from saved product...
      yield put(holdForPersonalization(hubProductPersonalize));
    }

    // backup is created when user left S&P page
    const backup = yield select(backupSkusSelector);

    // set Main mockup from saved product, only when user lands on S&P for the first time...
    if ((Config.get('editMode') || Config.get('duplicateMode')) && !backup) {
      const savedMainMockupUrl = yield select(savedMainMockupSelector);
      yield put(saveMainMockup(savedMainMockupUrl));
    }

    // check if we have any disabled skus on first S&P init, and inform user about that...
    const warningDialog = yield select(warningDialogSelector);
    if (disabledSkus.size && !warningDialog) {
      yield put(
        setWarningDialog({
          show: true,
          title: 'Disabled SKUs found:',
          content:
            'Please note that some variants of this product are no longer offered. They will be removed from this product. ',
          link: 'https://help.gooten.com/hc/en-us/articles/4411698591131-Discontinued-Products',
          additionalData: disabledSkus.map(ds => ds.get('sku'))
        })
      );
    }
  } catch (err) {
    yield put(fetchAsyncFail(PUBLISH_PREPARE, err));
    throw Log.withFriendlyMsg('Failed to prepare to publish', err, { action, products, skus });
  }
}

function* publishAsyncHandler(action) {
  const skus = yield select(skusWithNeckTagSelector);
  const neckLabel = yield select(currentNeckLabelSelector);

  if (neckLabel) {
    try {
      const neckTagImagesUrls = yield generateNeckTagImageUrl(skus, neckLabel);
      yield put(updateSkuNeckTagImgUrl(neckTagImagesUrls));
    } catch (err) {
      const error = 'A convenient neck tag could not be generated for the provided image.';
      yield put(neckTagGenerationError(error));
      Log.error(error, 'Failed to generate neck tag', { action, skus, neckLabel });
      return;
    }
  }

  // NOTE: select needed data from state via selector
  // and pass to submit publish api
  const publishType = publishService.publishType();
  const request = yield select(publishRequestSelector);
  const config = yield select(publishConfigSelector);
  const isEmbroidery = yield select(isEmbroiderySelected);

  try {
    const results = yield call(publishService.publish, request);
    const [error, reasons] = yield call(publishService.validateResponse, results, config);
    if (!error) {
      analyticsService.trackWithConfigSource('Product created successfully', null, {
        product: request.product.name,
        result: results
      });
      // NOTE: This is special analytic event which should be triggered for each SKU
      // upon create or link
      if (publishType === 'link' || publishType === 'create') {
        const selectedProducts = yield select(selectedProductsSelector);

        request.product.variants.forEach(v => {
          const matchingProduct = selectedProducts.find(
            p => p.get('id') === v.gooten_mapping.items[0].product_id
          );
          analyticsService._track('Hub', 'product creation success', null, null, {
            product_sku: v.gooten_mapping.sku,
            product_name: request.product.name,
            product_price: v.customer_price,
            product_category: matchingProduct && matchingProduct.getIn(['categories', 0]),
            date_of_last_product_created: getDateUTCNowShortStr(),
            created_via: 'hub'
          });
        });
      }
      yield put(fetchAsyncSuccess(PUBLISH, { request, results, publishType, isEmbroidery }));
    } else {
      analyticsService.trackWithConfigSource('Product creation failed', null, {
        product: request.product.name,
        error
      });
      yield put(fetchAsyncFail(PUBLISH, { request, publishType, error, reasons }));
    }
  } catch (error) {
    const validation = yield select(publishValidationSelector);
    const newValidation =
      error.errors && validationService.apply(validation, error.errors, request);
    if (newValidation && !newValidation.get('fatal')) {
      yield put(updateValidation(newValidation));
      yield call(delay, 1000);
      // validationService.scrollToInvalid()
    } else {
      // Cannot clarify error
      Log.error(error, 'Failed to publish', { action, request });
      analyticsService.trackWithConfigSource('Product creation failed', null, {
        product: request.product.name,
        error
      });
      yield put(fetchAsyncFail(PUBLISH, { request, publishType, error: undefined }));
    }
  }
}

function* skuSelectHandler(action) {
  try {
    // check if skus are changed, in order to reset publish data
    const oldData = yield select(publishDataSelector);
    const canRestore =
      oldData &&
      oldData.getIn(['storage', 'variants']) &&
      oldData
        .getIn(['storage', 'variants'])
        .map(s => s.get('sku'))
        .sort()
        .join(',') ===
        action.payload.skus
          .map(s => s.sku)
          .sort()
          .join(',');

    // do not reset publish data if we are in edit mode...
    if (!canRestore && !Config.get('editMode')) {
      yield put(resetPublishData());
    }
  } catch (err) {
    console.log('error reseting publish data', err);
  }
}

function* addVariantFlowHandler() {
  // this fires when user tries to edit published (edit product flow) or unsaved (create product flow) product
  // we need to handle editing already published product... three action bring us here:
  // edit mockups, edit design and add new variant
  // all three action allow user to edit product, and to change skus...
  // we will handle only edit mode:
  if (Config.get('editMode')) {
    // backup selected skus, if user desides to cancel all edits...
    const selectedSkus = yield select(selectedSKUsSelector);
    yield put(backupSkus(selectedSkus));
  }
}

function* getNeckTagPreviewsHandler() {
  const skus = yield select(skusWithNeckTagSelector);
  const neckLabel = yield select(currentNeckLabelSelector);
  if (neckLabel) {
    try {
      const neckTagImagesUrls = yield generateNeckTagImageUrl(skus, neckLabel);

      yield put(updateSkuNeckTagImgUrl(neckTagImagesUrls));
    } catch (err) {
      const error = 'A convenient neck tag could not be generated for the provided image.';
      Log.error(error, 'Failed to generate neck tag', { action, skus, neckLabel });
      return;
    }
  }
}

function* watchPublishPrepareAsync() {
  yield takeLatest(
    [
      PUBLISH_PREPARE.ASYNC,
      PREVIEWS_PREPARE.SUCCESS,
      EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS,
      COMPONENT_MOUNTED
    ],
    publishPrepareAsyncHandler
  );
}

function* watchPublishAsync() {
  yield takeLatest(PUBLISH.ASYNC, publishAsyncHandler);
}

function* watchSkuSelect() {
  yield takeLatest(SKU_SELECT, skuSelectHandler);
}

function* watchAddVariantFlow() {
  yield takeLatest([GOTO_PREVIEWS_PAGE, GOTO_DESIGN_PAGE, ADD_VARIANT], addVariantFlowHandler);
}

function* watchGetNeckTagPreviews() {
  yield takeLatest([GET_NECK_TAG_PREVIEWS.ASYNC], getNeckTagPreviewsHandler);
}

export default function* rootSaga() {
  yield all([
    watchPublishPrepareAsync(),
    watchPublishAsync(),
    watchSkuSelect(),
    watchAddVariantFlow(),
    watchGetNeckTagPreviews()
  ]);
}
