import React from 'react';
import Alert from '../../../../Alert';
// TEMPORARY - should be removed soon...
import { supportDST } from '../../../../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';

const EmbroideryImageRequirements = props => {
  return (
    <React.Fragment>
      <Alert className="emb-image-requirements" isOpen type="warning">
        <div className="inner-content">
          <b>Image requirements</b>
          <span>
            • Supported file sizes are{' '}
            {props.requiredImageSizeData
              ?.map(r => `${r.width}x${r.height}`)
              .join(', ')
              .replace(/, ([^,]*)$/, ' and $1')}
          </span>
          <span>• Supported file types are DST, PDF and PNG</span>
        </div>
      </Alert>
      <style jsx>{`
        .inner-content {
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </React.Fragment>
  );
};

export default EmbroideryImageRequirements;
