import { RESET } from '../../store/actions/globalActions';
import {
  SET_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_METHODS,
  UPDATE_SURCHARGE_COSTS,
  SET_SHIPPING_METHOD_FOR_GROUP,
  PLACE_ORDER,
  CLEAR_ORDER
} from './CheckoutActions';
import { UPDATE_SHIPPING_COUNTRY } from '../../store/actions/dataActions';
import AddressSelectorReducer from './atoms/AddressSelector/AddressSelectorReducer';
import PaymentReducer from './atoms/Payment/PaymentReducer';
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

// ------------------------------------
// Reducer
// ------------------------------------
const methodsDefaultState = fromJS({
  groups: []
});

const methodsReducer = (state = methodsDefaultState, action) => {
  switch (action.type) {
    case UPDATE_SHIPPING_COUNTRY:
    case SET_SHIPPING_ADDRESS:
      return methodsDefaultState;
    case UPDATE_SURCHARGE_COSTS:
      return state.set('surcharge', fromJS(action.payload.surchargeCosts));
    case UPDATE_SHIPPING_METHODS:
      return state
        .set('groups', fromJS(action.payload.shippingMethods))
        .set('errors', fromJS(action.payload.errors));
    case SET_SHIPPING_METHOD_FOR_GROUP:
      let skusToLookFor = action.payload.SKUs;
      let shippingMethodId = action.payload.shippingMethodId;
      let groups = state.get('groups');
      let groupIndex = groups.findIndex(group => group.get('SKUs') === skusToLookFor);
      return state.setIn(['groups', groupIndex, 'SelectedMethodId'], shippingMethodId);
    case RESET:
      return methodsDefaultState;
    default:
      return state;
  }
};

const orderReducer = (state = null, action) => {
  switch (action.type) {
    case PLACE_ORDER.SUCCESS:
      return fromJS(action.payload);
    case PLACE_ORDER.FAIL:
      return fromJS({ Errors: action.payload });
    case CLEAR_ORDER:
      return null;
    case RESET:
      return state;
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  shipping: combineReducers({
    addresses: AddressSelectorReducer,
    methods: methodsReducer
  }),
  payment: PaymentReducer,
  order: orderReducer
});

// make root reducer
export default (state, action) => {
  return combinedReducer(state, action);
};
