import { Layer, Group, Rect } from 'konva';
import { SurfaceLayer, KonvaStage, KonvaLayer } from '../../flow/konvaTypes';
import ViewportComponent from './viewport/viewport.component';
import RotateAction from './rotate/rotate.action';
import CropAction from './crop/crop.action';
import DragAction from './drag/drag.action';
import TouchAction from './touch.action';
import ImageService from '../image/image.service';
import DragService from './drag/drag.service';
import TooltipComponent from './tooltip/tooltip.component';
import ToggleEditModeAction from './toggleEditMode.action';
import EventEmitter from 'events';

export default class ActionSurfaceLayer implements SurfaceLayer {

  id: string;
  layer: KonvaLayer;
  rotationTooltip: TooltipComponent;
  publicEvents: EventEmitter;
  editModeToggler: ToggleEditModeAction;
  orientation: string;

  constructor(id: string, publicEvents: EventEmitter) {
    this.publicEvents = publicEvents;
    this.id = id;
    this.layer = new Layer();
    this.layer.getCanvas()._canvas.id = id;
    this.editModeToggler = new ToggleEditModeAction(this.publicEvents);
  }

  addToCanvas(stage: KonvaStage) {
    stage.add(this.layer);
    this.rotationTooltip = new TooltipComponent(stage, this.orientation);
  }

  draw(ctx: any) {
    this.layer.destroyChildren();
    this.layer.clear();

    //set orientation for rotation tooltip
    this.rotationTooltip.setOrientation(ctx.state.editor.orientation);

    const isEditMode = ctx.state.editor.mode === 'edit';
    const isCropActivated = ctx.state.editor.cropActivated;
    const hasSelected = !!ctx.state.images.current.selected.imageId && !!ctx.state.images.current.selected.layerId;

    let viewport = new ViewportComponent(this.layer, this.publicEvents);
    viewport.draw(ctx);

    if (isEditMode && hasSelected) {

      const selectedImageState = ImageService.getSelectedImageCanvasState(ctx.state);

      let actionGroup = new Group({
        x: selectedImageState.x + selectedImageState.width / 2,
        y: selectedImageState.y + selectedImageState.height / 2,
        draggable: true,
        // Limit drag area to not go outside of viewport boundaries
        dragBoundFunc: (pos) => {
          return DragService.getLimitedDragAreaPointOnDrag(
            pos.x,
            pos.y,
            actionGroup.width(),
            actionGroup.height(),
            selectedImageState,
            ctx.state
          );
        },
        offsetX: selectedImageState.width / 2,
        offsetY: selectedImageState.height / 2,
        rotation: selectedImageState.rotation
      });

      actionGroup.addName('actionGroup');

      if(selectedImageState.crop){
        actionGroup.setOffsetX(selectedImageState.crop.width/2);
        actionGroup.setOffsetY(selectedImageState.crop.height/2);
      }

      if(!isCropActivated){
        // actionRectElm - is transparent rect size as image layer for dragging
        let actionRectElm = new Rect({
          x: 0,
          y: 0,
          width: selectedImageState.width,
          height: selectedImageState.height,
          stroke: '#F7E61B',
          strokeWidth: 1,
          fillEnabled: true
        });
        actionGroup.add(actionRectElm);

        let dragAction = new DragAction(actionGroup, actionRectElm, this.publicEvents);
        dragAction.draw(ctx);

        let rotateAction = new RotateAction(actionGroup, actionRectElm, this.rotationTooltip, this.publicEvents);
        rotateAction.draw(ctx);

        let touchAction = new TouchAction(actionGroup, actionRectElm, this.rotationTooltip, this.publicEvents);
        touchAction.draw(ctx);

        // Closed edit mode on click outside(stage) of image
        ctx.stage.off('contentClick contentTap').on('contentClick contentTap', (e) =>  {
          if (!e.evt.cancelBubble && (!e.evt.touches || e.evt.touches.length <= 1)) {
            this.editModeToggler.toggle(e, ctx, ctx.state.images.current.selected.layerId);
            // rmeove listener, will be added when edit mode activated
            ctx.stage.off('contentClick contentTap');
          }
        });


        // NOTE: All immediate image updates happens only here
        ctx.events.on('imageUpdate', eventArgs => {
          if(eventArgs.action == 'zoom'){
            actionRectElm.width(eventArgs.width);
            actionRectElm.height(eventArgs.height);
            actionGroup.setOffsetX(eventArgs.offsetX);
            actionGroup.setOffsetY(eventArgs.offsetY);
            this.layer.draw();
          }
        });

      }
      else {
        //In crop mode we don't need in edit mode toggler on stage
        ctx.stage.off('contentClick contentTap');
      }

      let cropAction = new CropAction(this.layer, actionGroup, this.publicEvents);
      cropAction.draw(ctx);

      this.layer.add(actionGroup);
    }

    this.layer.batchDraw();
  }

};
