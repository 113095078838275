import { connect } from 'react-redux';
import StoreBulkCreationView from './StoreBulkCreationView';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';

const mapDispatchToProps = {};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(StoreBulkCreationView)
);
