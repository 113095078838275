import { all, takeEvery, call, put } from 'redux-saga/effects';
import loadImage from 'blueimp-load-image';
import { showLoading, IMAGE_FILE_UPLOAD } from './ImageUploadModalActions';
import { fetchAsyncSuccess, fetchAsyncFail } from '../../../store/actions/dataActions';
import Log from '../../../services/logService';
import Analytics from '../../../services/analyticsService';
import imageService from '../ImageSelectionModal/services/imageSelectionService';

export function* imageFileUploadAsyncHandler(action) {
  try {
    put(showLoading());
    // get AWS S3 Signature once before upload
    const s3Config = yield call([imageService, imageService.getS3Config]);
    // NOTE: action.payload.files is FileList there is no .map on it
    const file = action.payload.files[0];
    const task = new Promise((resolve, reject) => {
      // Load image to get it width and height
      loadImage(file, (res, data) => {
        if (res.type === 'error') {
          reject(res);
        } else {
          // res is Img here, not Canvas
          imageService
            .uploadImageFile(file, s3Config, file.name, file.name.split('.').pop())
            .then(({ url }) =>
              resolve({
                url,
                width: res.naturalWidth,
                height: res.naturalHeight
              })
            )
            .catch(err => reject(err));
        }
      });
    });
    const result = yield task;
    yield put(fetchAsyncSuccess(IMAGE_FILE_UPLOAD, result.url));
  } catch (error) {
    Analytics.trackWithConfigSource('back_neck_image_upload_failed');
    Log.error(error, 'Failed to upload images from files', { files: action.payload.files });
    yield put(fetchAsyncFail(IMAGE_FILE_UPLOAD, error));
  }
}

// watchers
export function* watchImageFileUploadAsync() {
  yield takeEvery(IMAGE_FILE_UPLOAD.ASYNC, imageFileUploadAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchImageFileUploadAsync()]);
}
