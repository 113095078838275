import { ActionNameFactory } from '../../../../utils/actions';

const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'NECKLABELS');

export const CREATE_NECKTAG = getActionName.async('CREATE_NECKTAG');
export const FETCH_NECKTAGS = getActionName.async('FETCH_NECKTAGS');
export const SELECT_NECKTAG = getActionName('SELECT_NECKTAG');
export const NECKTAG_GENERATION_ERROR = getActionName('NECKTAG_GENERATION_ERROR');

export const fetchNeckTags = () => ({
  type: FETCH_NECKTAGS.ASYNC
});

export const createNeckTag = (neckTag, isCof) => ({
  type: CREATE_NECKTAG.ASYNC,
  payload: { neckTag, isCof }
});

export const setSelectedNeckTag = payload => ({
  type: SELECT_NECKTAG,
  payload
});

export const neckTagGenerationError = err => ({
  type: NECKTAG_GENERATION_ERROR,
  payload: { err }
});
