import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { COLORS } from '../../../constants';

export const BorderBlock = props => {
  const onClickHandler = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <>
      {props.linkTo ? (
        <Link
          to={props.linkTo}
          className={
            'border-block-container border-block-link p-4' +
            (props.customClassName ? ` ${props.customClassName}` : '')
          }
          onClick={onClickHandler}
        >
          {props.children}
        </Link>
      ) : (
        <div
          className={
            'border-block-container p-4' +
            (props.customClassName ? ` ${props.customClassName}` : '')
          }
          onClick={onClickHandler}
        >
          {props.children}
        </div>
      )}
      <style jsx global>
        {`
          .border-block-container {
            border: 1px solid #dedfe1;
            border-radius: 4px;
          }
          .border-block-link:focus,
          .border-block-link:hover {
            cursor: pointer;
            border: 1px solid ${COLORS.gootenBlue};
            background: ${COLORS.gootenBlue200};
            text-decoration: none !important;
            transition-duration: 0.3s;
          }
        `}
      </style>
    </>
  );
};

BorderBlock.propTypes = {
  linkTo: PropTypes.string,
  customClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  onClick: PropTypes.func
};

export default BorderBlock;
