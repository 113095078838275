import { connect } from 'react-redux';
import EmptySearchResult from './EmptySearchResult';
import { searchSelector } from '../../SearchBar/SearchBarSelectors';
import { searchTextSelector } from './SearchSelectors';

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  searchText: searchSelector(state) || searchTextSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(EmptySearchResult);
