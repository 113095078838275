import ProductSelectionView from './ProductSelectionView';
import SKUsSelectionView from './SKUsSelectionView';
import ProductDesignView from './ProductDesignView';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';

const linkProductRoutes = {
  indexRoute: { component: withErrorBoundary(ProductSelectionView) },
  childRoutes: [
    {
      path: 'product-selection',
      component: withErrorBoundary(ProductSelectionView)
    },
    {
      path: 'skus-selection',
      component: withErrorBoundary(SKUsSelectionView)
    },
    {
      path: 'product-design(/:step)',
      component: withErrorBoundary(ProductDesignView)
    }
  ]
};

export default linkProductRoutes;
