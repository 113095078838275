import { connect } from 'react-redux';
import Stores from './Stores';
import {
  storesLoadedSelector,
  availableStoresSelector,
  selectedStoreSelector,
  selectedStoreNameSelector
} from './StoresSelectors';

import { selectedProviderSelector } from '../Providers/ProvidersSelectors';

import { storeSelect, storesLoad } from './StoresActions';

const mapDispatchToProps = {
  selectStore: ({ store, reset }) => storeSelect({ store, reset }),
  loadStores: () => storesLoad()
  // storeLogOut: id => storeLogOut(id)
};

const mapStateToProps = (state, ownProps) => {
  return {
    storesLoaded: storesLoadedSelector(state),
    availableStores: availableStoresSelector(state),
    selectedStore: selectedStoreSelector(state),
    selectedStoreName: selectedStoreNameSelector(state),
    selectedProvider: selectedProviderSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
