import React from 'react';
import PropTypes from 'prop-types';
import PreviewItem from './PreviewItem';
import LinkButton from '../../../shared/LinkButton';
import MultiBackend, { Preview } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import { DragDropContext } from 'react-dnd';
import analyticsService from './../../../../services/analyticsService';
import './MainPanel.scss';
import Checkbox from '../../../shared/Checkbox';

class MainPanel extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    onEditClick: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    isMaximized: PropTypes.bool,
    isExclusiveMockup: PropTypes.bool,
    selectedItems: PropTypes.array,
    generationState: PropTypes.object,
    restoreItems: PropTypes.func,
    updatePreviewData: PropTypes.func,
    maxNumberOfItems: PropTypes.number,
    exceedMaxItemsMessage: PropTypes.string
  };

  moveBlock = (currentId, finalId) => {
    const items = this.props.items;
    const currentPosition = items.filter(r => r.index === currentId)[0];
    const finalPosition = items.filter(r => r.index === finalId)[0];

    const currentPositionIndex = items.indexOf(currentPosition);
    const finalPositionIndex = items.indexOf(finalPosition);

    items.splice(currentPositionIndex, 1);
    items.splice(finalPositionIndex, 0, currentPosition);

    this.props.updatePreviewData(items);

    analyticsService.trackWithDynamicSource('Product preview', 'Image order changed');
  };

  generatedDragAndDropPreview = (type, item, style) => {
    Object.assign(style, {
      background: `url(${item.smallPreviewImageUrl}) center center / contain no-repeat`
    });
    return <div className="generated-preview" style={style} />;
  };

  render() {
    const isPageEmpty = !this.props.items.length;
    const lessThanHalfItemsSelected = this.props.selectedItems.length < this.props.items.length / 2;

    const selectAllButton = isPageEmpty ? null : (
      <div className="product-preview-select-all">
        <Checkbox
          key={'key'}
          noBorder
          checked={!lessThanHalfItemsSelected}
          onChange={() => this.props.onSelectAll()}
          label={lessThanHalfItemsSelected ? 'Select All' : 'Deselect All'}
        />
      </div>
    );

    const editButton = isPageEmpty ? null : (
      <div>
        <LinkButton
          title="Edit"
          onClick={() => this.props.onEditClick(true)}
          icon="edit2"
          class="visible-xs"
        />
        <LinkButton
          title="Edit"
          onClick={() => this.props.onEditClick(false)}
          icon="edit2"
          class="hidden-xs"
        />
      </div>
    );

    const subtitle = isPageEmpty ? (
      <span>
        You have no mockups for this product. If you want to restore the ones you deleted, click
        <a onClick={() => this.props.restoreItems()}>here</a>
      </span>
    ) : this.props.isEditMode ? (
      <span>
        Select mockups to delete or download. You can download up to 20 mockups at a time.
      </span>
    ) : (
      <div>
        <span className="visible-xs visible-sm visible-md">
          These are the mockups that will be pushed to your store or saved to storage. To change,
          delete, or download these, click the "edit" button.
        </span>
        <span className="hidden-xs hidden-sm hidden-md">
          These are the mockups that will be pushed to your store or saved to storage. To change,
          delete, or download these, click the "edit" button. To change the order, drag & drop.
        </span>
      </div>
    );

    const items = this.props.items.map((item, i) => {
      const selected =
        this.props.selectedItems.find(
          x =>
            x.sku.toLowerCase() === item.sku.toLowerCase() &&
            x.sId === item.sId &&
            x.index === item.index
        ) !== undefined;
      return (
        <PreviewItem
          key={item.sku + item.index}
          id={item.index}
          isEditMode={this.props.isEditMode}
          isMaximized={this.props.isMaximized}
          onClick={this.props.onClickItem}
          onSelect={this.props.onSelectItem}
          position={i}
          selected={selected}
          item={item}
          numberOfItems={this.props.items.length}
          moveBlock={this.moveBlock}
          showLoading={
            !this.props.isExclusiveMockup &&
            this.props.generationState.state === 'WORKING' &&
            !this.props.generationState.finishedSKUs.has(item.sku + item.sId + item.index) &&
            (selected || item.generatePreview)
          }
        />
      );
    });

    const itemsAboveLine = items.slice(0, this.props.maxNumberOfItems);
    const itemsBellowLine = items.slice(this.props.maxNumberOfItems);

    const separatorContent = (
      <div className="separator-content">
        <hr />
        <span>{this.props.exceedMaxItemsMessage}</span>
      </div>
    );

    const labelSelectedItems = (
      <div className="number-of-selected-items">{this.props.selectedItems.length} selected</div>
    );

    return (
      <div
        className={`main-panel-container row no-gutters${this.props.isMaximized ? ' margin' : ''}`}
      >
        <h2>Product Mockups</h2>
        {subtitle}
        {this.props.isEditMode ? selectAllButton : editButton}
        {this.props.selectedItems.length ? labelSelectedItems : null}
        {isPageEmpty ? <div className="empty-page" /> : itemsAboveLine}
        {isPageEmpty || !itemsBellowLine.length ? null : separatorContent}
        {isPageEmpty ? null : itemsBellowLine}
        <Preview generator={this.generatedDragAndDropPreview} />
      </div>
    );
  }
}

export default DragDropContext(MultiBackend(HTML5toTouch))(MainPanel);
