/* eslint-disable no-control-regex */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import SecurityService from '../../../../../Settings/SecurityService';
import Card from 'gooten-components/src/components/shared/Card';
import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Select from 'gooten-components/src/components/shared/Select';

const BillingAddressCard = props => {
  const {
    orderId,
    summaryData: { BillingAddress },
    countries,
    addressTypes,
    getCountries,
    getAddressTypes,
    saveBillingAddress,
    editingBillingAddress,
    updateEditingBillingAddress
  } = props;

  return (
    <>
      {!SecurityService.isVendor() && (
        <>
          <Card
            className="billing-address-card"
            title={editingBillingAddress ? 'Billing Address' : null}
          >
            {!editingBillingAddress && (
              <>
                <div className="d-flex justify-content-between">
                  <p className="title-2">Billing Address</p>
                  <Button
                    className={'button-default medium'}
                    onClick={() => {
                      if (countries.length === 0) getCountries();
                      if (addressTypes.length === 0) getAddressTypes();
                      updateEditingBillingAddress(!editingBillingAddress);
                    }}
                  >
                    Edit
                  </Button>
                </div>
                <div className="d-flex justify-content-between billing-address-name">
                  {BillingAddress && (
                    <p>
                      <span className="billing-address-firstname">{BillingAddress.FirstName}</span>
                      <span className="billing-address-lastname"> {BillingAddress.LastName}</span>
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between billing-address-line1">
                  {BillingAddress && <p>{BillingAddress.Line1}</p>}
                </div>
                <div className="d-flex justify-content-between billing-address-line2">
                  {BillingAddress && <p>{BillingAddress.Line2}</p>}
                </div>
                <div className="d-flex justify-content-between billing-address-citystate">
                  {BillingAddress && (
                    <p>
                      <span className="billing-address-city">{BillingAddress.City}, </span>
                      <span className="billing-address-state">{BillingAddress.State}, </span>
                      <span className="billing-address-postal">{BillingAddress.PostalCode}</span>
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between billing-address-country">
                  {BillingAddress && <p>{BillingAddress.CountryCode}</p>}
                </div>
                <div className="d-flex justify-content-between billing-address-phone">
                  {BillingAddress && <p>Phone: {BillingAddress.Phone}</p>}
                </div>
                <div className="d-flex justify-content-between billing-address-email">
                  {BillingAddress && (
                    <p>
                      <a href={`mailto:${BillingAddress.Email}`}>{BillingAddress.Email}</a>
                    </p>
                  )}
                </div>
              </>
            )}
            {editingBillingAddress && (
              <Formik
                enableReinitialize
                initialValues={{
                  firstName: BillingAddress ? BillingAddress.FirstName : '',
                  lastName: BillingAddress ? BillingAddress.LastName : '',
                  address1: BillingAddress ? BillingAddress.Line1 : '',
                  address2: BillingAddress ? BillingAddress.Line2 : '',
                  city: BillingAddress ? BillingAddress.City : '',
                  stateRegion: BillingAddress ? BillingAddress.State : '',
                  zipPostalCode: BillingAddress ? BillingAddress.PostalCode : '',
                  country: BillingAddress ? BillingAddress.CountryCode : 'US',
                  phone: BillingAddress ? BillingAddress.Phone : '',
                  email: BillingAddress ? BillingAddress.Email : '',
                  addressType: BillingAddress ? BillingAddress.AddressTypeId : 1
                }}
                validationSchema={() =>
                  object().shape({
                    firstName: string()
                      .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid First Name') // name
                      .matches(/[\x00-\x7F]+/, 'Enter a valid First Name') // onlyEnglish
                      .required('Enter a valid First Name'), // required
                    lastName: string()
                      .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid Last Name') // name
                      .matches(/[\x00-\x7F]+/, 'Enter a valid Last Name') // onlyEnglish
                      .required('Enter a valid Last Name'), // required
                    address1: string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
                      .matches(/[\x00-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
                      .required('Enter a valid Address 1'), // required
                    address2: string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                      .matches(/[\x00-\x7F]+/, 'Enter a valid Address 2'), // onlyEnglish
                    city: string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
                      .matches(/[\x00-\x7F]+/, 'Enter a valid City') // onlyEnglish
                      .required('Enter a valid City'), // required
                    stateRegion: string()
                      .when('country', {
                        is: country => {
                          if (country) {
                            return ['CA', 'US'].includes(country);
                          }
                          return false;
                        },
                        then: string().required('Enter a valid State/Region')
                      }) // required if country is CA or US
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Region') // shippingRequiredUSandCA
                      .matches(/[\x00-\x7F]+/, 'Enter a valid State/Region'), // onlyEnglish
                    zipPostalCode: string().when('country', {
                      is: country => country === 'US',
                      then: string()
                        .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                        .required('Enter a valid zip code'),
                      otherwise: string().when('country', {
                        is: country => country === 'CA',
                        then: string()
                          .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                          .required('Enter a valid postal code'),
                        otherwise: string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                      })
                    }),
                    country: string().required('Enter a Country'), // required
                    phone: string()
                      .matches(
                        /^[+\-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                        'Enter a valid Phone Number'
                      )
                      .matches(/^.{0,15}$/, 'Please limit to 15 characters')
                      .required('Enter a valid Phone Number'),
                    email: string()
                      .matches(
                        // eslint-disable-next-line max-len
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        'Enter a valid Email'
                      ) // email
                      .required('Enter a valid Email'),
                    addressType: number().required('Enter an Address Type')
                  })
                }
                onSubmit={(values, { setSubmitting }) => {
                  const data = {
                    AddressTypeId: values.addressType,
                    City: values.city,
                    CountryCode: values.country,
                    Email: values.email,
                    FirstName: values.firstName,
                    LastName: values.lastName,
                    Line1: values.address1,
                    Line2: values.address2,
                    Phone: values.phone,
                    PostalCode: values.zipPostalCode,
                    State: values.stateRegion,
                    type: 'billing'
                  };
                  saveBillingAddress(orderId, data);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  submitCount,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="firstLastName d-flex">
                      <Input
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className="mr-3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        disabled={isSubmitting}
                        hasError={submitCount > 0 && !!errors.firstName}
                        errorText={submitCount > 0 && !!errors.firstName ? errors.firstName : null}
                      />
                      <Input
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        disabled={isSubmitting}
                        hasError={submitCount > 0 && !!errors.lastName}
                        errorText={submitCount > 0 && !!errors.lastName ? errors.lastName : null}
                      />
                    </div>
                    <Input
                      name="address1"
                      type="text"
                      placeholder="Address 1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address1}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.address1}
                      errorText={submitCount > 0 && !!errors.address1 ? errors.address1 : null}
                    />
                    <Input
                      name="address2"
                      type="text"
                      placeholder="Address 2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address2}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.address2}
                      errorText={submitCount > 0 && !!errors.address2 ? errors.address2 : null}
                    />
                    <Input
                      name="city"
                      type="text"
                      placeholder="City"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.city}
                      errorText={submitCount > 0 && !!errors.city ? errors.city : null}
                    />
                    <Input
                      name="stateRegion"
                      type="text"
                      placeholder="State/Region"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stateRegion}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.stateRegion}
                      errorText={
                        submitCount > 0 && !!errors.stateRegion ? errors.stateRegion : null
                      }
                    />
                    <Input
                      name="zipPostalCode"
                      type="text"
                      placeholder="Zip/Postal Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zipPostalCode}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.zipPostalCode}
                      errorText={
                        submitCount > 0 && !!errors.zipPostalCode ? errors.zipPostalCode : null
                      }
                    />
                    <Select
                      name="country"
                      label={'Country'}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      value={values.country}
                      hasError={submitCount > 0 && !!errors.country}
                      errorText={submitCount > 0 && !!errors.country ? errors.country : null}
                    >
                      {countries
                        .filter(country => !['Cuba', 'Iran', 'Syria'].includes(country.Name))
                        .map(country => (
                          <option key={country.Id} value={country.CountryCode}>
                            {country.CountryCode} - {country.Name}
                          </option>
                        ))}
                    </Select>
                    <Input
                      name="phone"
                      type="text"
                      placeholder="Phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.phone}
                      errorText={submitCount > 0 && !!errors.phone ? errors.phone : null}
                    />
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      disabled={isSubmitting}
                      hasError={submitCount > 0 && !!errors.email}
                      errorText={submitCount > 0 && !!errors.email ? errors.email : null}
                    />
                    <Select
                      label={'Address Type'}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      name="addressType"
                      value={values.addressType}
                      hasError={submitCount > 0 && !!errors.addressType}
                      errorText={
                        submitCount > 0 && !!errors.addressType ? errors.addressType : null
                      }
                    >
                      {addressTypes.map(addressType => (
                        <option key={addressType.Id} value={addressType.Id}>
                          {addressType.Name}
                        </option>
                      ))}
                    </Select>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="mr-2 button-default large cancel-button"
                        onClick={() =>
                          isSubmitting ? null : updateEditingBillingAddress(!editingBillingAddress)
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        className="button-primary large submit-button"
                        onClick={() => (isSubmitting ? null : handleSubmit())}
                      >
                        Update
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </Card>
          <style jsx>
            {`
              .order-page-content {
                background: white;
              }

              .firstName {
                margin-right: 15px !important;
              }

              :global(.submit-btn),
              :global(.cancel-btn) {
                width: 150px !important;
              }
            `}
          </style>
        </>
      )}
    </>
  );
};

BillingAddressCard.propTypes = {
  orderId: PropTypes.string.isRequired,
  summaryData: PropTypes.object.isRequired,
  saveBillingAddress: PropTypes.func.isRequired,
  getAddressTypes: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  updateEditingBillingAddress: PropTypes.func.isRequired,
  editingBillingAddress: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  addressTypes: PropTypes.array.isRequired
};

export default BillingAddressCard;
