import React from 'react';
import PropTypes from 'prop-types';
import OptionsModal from 'gooten-components/src/components/shared/OptionsModal';
import analyticsService from 'gooten-components/src/services/analyticsService';
import productsService from '../ProductsService';

import ProductCard from 'gooten-components/src/components/shared/ProductCard';
import Chip from 'gooten-components/src/components/shared/Chip';
import { errorInfo } from '../productsUtils';

class ProductsGridItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  handleSelectModalOption = action => {
    if (action === 'viewinstore') {
      analyticsService.track('Hub - Place an Order', 'View in Store', 'Hub');
      window.open(this.productUrlInStore(), '_blank');
    } else {
      this.props.onSelectModalOption(this.props.item.get('id'), action);
    }
  };

  productUrlInStore = () =>
    productsService.getProductUrlInStore(this.props.storeName, this.props.item);

  preventStatusChange = () =>
    this.props.currentProvider === 'tiktok' &&
    ['PENDING', 'PLATFORM_DEACTIVATED', 'FREEZE', 'FAILED'].includes(this.props.item.get('state'));

  changeSatatus = () => (this.props.item.get('status') === 'unpublished' ? 'Publish' : 'Unpublish');

  options = () => {
    if (this.props.item.get('type').toLowerCase() === 'storage') {
      return ['Place an Order', 'Edit', 'Duplicate', 'Delete'];
    } else {
      let opts =
        this.productUrlInStore() && this.props.currentProvider !== 'tiktok'
          ? ['Place an Order', 'View In Store', 'Edit', 'Duplicate', 'Stop Fulfillment', 'Delete']
          : ['Place an Order', 'Edit', 'Duplicate', 'Stop Fulfillment', 'Delete'];

      if (!this.preventStatusChange()) {
        opts.splice(1, 0, this.changeSatatus());
      }

      return opts;
    }
  };

  collection = () => this.props.item.get('collections') && this.props.item.get('collections')[0];

  providerInfo = () => {
    let status;

    if (
      this.props.currentProvider === 'shopify' ||
      this.props.currentProvider === 'etsy' ||
      this.props.currentProvider === 'woocommerce' ||
      this.props.currentProvider === 'tiktok' ||
      this.props.currentProvider === 'bigcommerce'
    ) {
      const pStatus =
        this.props.item.get('status').charAt(0).toUpperCase() +
        this.props.item.get('status').slice(1);
      const state = this.props.item.get('state');
      const pState =
        this.props.currentProvider === 'tiktok' && state === 'PENDING' ? (
          <Chip className="ml-n2 mt-1 mb-1 yellow">{state}</Chip>
        ) : (
          ''
        );
      const fState =
        this.props.currentProvider === 'tiktok' &&
        ['PLATFORM_DEACTIVATED', 'FREEZE', 'FAILED'].includes(state) ? (
          <Chip className="ml-n2 mt-1 mb-1 red">{state}</Chip>
        ) : (
          ''
        );
      status = (
        <div className="store-name">
          {pStatus} {pState}
          {fState}
          {this.collection() && typeof this.collection().name === 'string' ? (
            <Chip className="ml-n2 mt-1 mb-1">{this.collection().name}</Chip>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      const storeName = this.props.storeName ? `STORE ${this.props.storeName}` : '';
      status = <span className="store-name">{storeName}</span>;
    }
    return this.props.currentProvider !== 'storage' ? status : null;
  };

  render() {
    return (
      <div className="product-grid">
        <div className="products-grid-item-container h-100">
          <ProductCard
            type="hub"
            title={this.props.name}
            description={this.props.desc}
            active={false}
            selected={this.props.selected}
            loading={this.props.pending}
            stores={0}
            image={this.props.image}
            selectClick={this.props.select}
            productClick={() => this.props.onSelectModalOption(this.props.item.get('id'), 'edit')}
            editItemClick={() => this.props.onSelectModalOption(this.props.item.get('id'), 'edit')}
            additionalInfo={this.providerInfo()}
            menuClick={() => this.setState({ showModal: true })}
            errorInfo={errorInfo(this.props.item.get('disabledSkus'))}
          />
          {this.state.showModal ? (
            <OptionsModal
              items={this.options()}
              onClickItem={this.handleSelectModalOption}
              onDismiss={() => {
                this.setState({ showModal: false });
              }}
            />
          ) : null}
        </div>
        <style jsx>
          {`
            .product-grid {
              position: relative;
            }
            .product-grid :global(.options-modal) {
              top: 3.25rem;
              right: 1rem;
            }
            .products-grid-item-container {
              cursor: pointer;
              max-width: 18rem;
              margin: auto;
              position: relative;
            }
          `}
        </style>
      </div>
    );
  }
}

ProductsGridItem.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  storeName: PropTypes.string,
  item: PropTypes.object,
  onSelectModalOption: PropTypes.func,
  currentProvider: PropTypes.string
};

export default ProductsGridItem;
