import React from 'react';

export const nextSection = (label, isActive) => {
  return (
    <div>
      <hr className="delimiter" />
      <div className="row step-container">
        <div className="step-index-container">
          <div className="step-index active next">
            <i className="icon-scroll-right" />
          </div>
        </div>
        <div className="step-content">
          <p className={isActive ? 'step-title active ' : 'step-title'}>{label}</p>
        </div>
      </div>
    </div>
  );
};
