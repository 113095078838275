import * as ACTIONS from '../actions/actionTypes';
import { PositioningService } from '../../utils/positioningService';

const createPublicEventsTriggerMiddleware = (publicEvents, stateManager) => {
  return state => next => action => {
    // Call the next dispatch method in the middleware chain.
    let returnValue = next(action);
    switch (action.type) {
      case ACTIONS.EDITOR_RESTORE:
      case ACTIONS.IMAGE_DELETE:
      case ACTIONS.IMAGE_LOADED:
      case ACTIONS.IMAGE_UPDATE:
      case ACTIONS.IMAGE_UNDO:
      case ACTIONS.IMAGE_REDO: {
        const stateJS = stateManager.getState();
        const isBulkEdit = stateJS.editor.bulkEdit;
        publicEvents.emit('imagechange', stateJS);

        if (isBulkEdit) {
          switch (action.type) {
            case ACTIONS.IMAGE_UNDO:
              publicEvents.emit('imageundo');
              break;
            case ACTIONS.IMAGE_REDO:
              publicEvents.emit('imageredo');
              break;
            case ACTIONS.IMAGE_UPDATE:
              publicEvents.emit(
                'imagechangedx',
                new PositioningService().getProportionalDxUpdate(
                  action.payload,
                  stateJS
                )
              );
              break;
          }
        }
      }
    }
    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
};

export default createPublicEventsTriggerMiddleware;
