import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import BenefitsCard from 'gooten-components/src/components/shared/PartnerTier/BenefitsCard';
import {
  tierBenefits,
  tierRanks,
  tierGradients,
  PartnerTierBadge
} from 'gooten-components/src/components/shared/PartnerTier';

const CurrentTierCard = ({ status, expireAt, assignedAt, annual, children }) => {
  const [partnerStatus, setPartnerStatus] = useState('');
  const [tierGradient, setTierGradient] = useState({});
  const [tierBenefitsData, setTierBenefitsData] = useState([]);

  useEffect(() => {
    setTierGradient(tierGradients[status]);
    setPartnerStatus(status);
    setTierBenefitsData(tierBenefits[status]);
  }, [status]);

  return (
    <>
      <div className="d-flex justify-content-between py-3 px-4">
        <div className="d-flex tier-title">
          <PartnerTierBadge id={partnerStatus} />
          <p className="title-2 my-auto mx-3">
            {tierRanks.find(x => x.name === status).displayName}
          </p>
        </div>
        <div className="tier-dates">
          <p className="caption-text mb-0">
            Member since
            {assignedAt ? ` ${moment(assignedAt).format('MMM DD, YYYY')}` : ' NA'}
          </p>
          <p className="caption-text mb-0">
            Expires
            {expireAt ? ` ${moment(expireAt).format('MMM DD, YYYY')}` : ' NA'}
          </p>
        </div>
      </div>
      {children}
      <div className="pt-0 pr-4 pb-4">
        <p className={'title-1 pt-3 mb-4 ' + (annual ? '' : 'ml-4')}>
          {tierRanks.find(x => x.name === status).displayName} Benefits
        </p>
        <div className="d-flex flex-wrap ml-4">
          {tierBenefitsData.map((b, i) => (
            <BenefitsCard
              key={i}
              gradient={{ ...tierGradient, ...tierGradient.icon }}
              icon={b.icon}
              text={b.text}
              viewAll={i === tierBenefitsData.length - 1}
            />
          ))}
          <div style={{ flex: '1 0 220px', height: 0 }} />
        </div>
      </div>
      <style jsx>{`
        .justify-content-between {
          background: ${tierGradient.cardBackground};
        }
        .tier-title {
          flex: 4;
        }
        .tier-dates {
          flex: 2;
        }
        .caption-text {
          font-size: 11px;
          text-align: right;
          color: #646b72 !important;
        }
        .title-1 {
          font-size: 11px;
          align-items: center;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #646b72 !important;
        }
      `}</style>
    </>
  );
};

CurrentTierCard.propTypes = {
  status: PropTypes.string.isRequired,
  assignedAt: PropTypes.string,
  expireAt: PropTypes.string
};

export default CurrentTierCard;
