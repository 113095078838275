import { createSelector } from 'reselect';

const editDesignViewSelector = state => state.getIn(['customOrderForm', 'editDesignView']);

export const loadingStatusSelector = createSelector([editDesignViewSelector], editDesignView =>
  editDesignView.get('loadingStatus')
);

export const hasRequiredProductInfoSelector = createSelector(
  [editDesignViewSelector],
  editDesignView =>
    !!editDesignView.getIn(['item', 'productId']) &&
    !!editDesignView.getIn(['item', 'sku']) &&
    !!editDesignView.getIn(['item', 'images']) &&
    editDesignView.getIn(['item', 'images']).size > 0
);

export const hasProductsSelector = state =>
  state.getIn(['data', 'products']) && state.getIn(['data', 'products']).size > 0;

export const selectedProductSelector = state =>
  state.getIn(['data', 'products']).find(p => p.id === state.getIn(['data', 'selectedProductId']));

export const skipCountryCheckSelector = createSelector([editDesignViewSelector], editDesignView =>
  editDesignView.get('skipCountryCheck')
);
