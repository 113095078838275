import React from 'react';
import PropTypes from 'prop-types';
import './InputNumber.scss';
import Button from '../Button';
import Icon from '../Icon';
import Input from '../Input';

class InputNumber extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.notifyListeners = this.notifyListeners.bind(this);
    this.handleClickMinus = this.handleClickMinus.bind(this);
    this.handleClickPlus = this.handleClickPlus.bind(this);

    this.state = {
      value: props.value
    };
  }

  static getDerivedStateFromProps(props, state) {
    let initialValue = props.value;
    if (initialValue < 1) {
      initialValue = 1;
    }

    return {
      value: initialValue
    };
  }

  static defaultProps = {
    value: 1
  };

  onChange(event) {
    let newValueRaw = event.target.value;
    this.setState({
      value: newValueRaw
    });
    if (!isNaN(parseInt(newValueRaw))) {
      this.validate(event);
    }
  }

  validate(event) {
    let newValueRaw = event.target.value;
    let newValue = parseInt(newValueRaw);
    if (newValue < 1 || isNaN(newValue)) {
      newValue = 1;
      this.setState({
        value: newValue
      });
    }
    this.notifyListeners(newValue);
  }

  handleClickMinus() {
    let currentValue = parseInt(this.state.value);
    if (currentValue === 1) return;
    this.setState({
      value: currentValue - 1
    });
    this.notifyListeners(currentValue - 1);
  }

  handleClickPlus() {
    let currentValue = parseInt(this.state.value);
    this.setState({
      value: currentValue + 1
    });
    this.notifyListeners(currentValue + 1);
  }

  notifyListeners(newValue) {
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  render() {
    return (
      <div className="input-number-container flex-inline-container flex-center-aligned">
        <Button onClick={this.handleClickMinus} iconOnly className="btn-minus button-default large">
          <Icon icon="minusCircle" />
        </Button>
        <Input noLabel onChange={this.onChange} onBlur={this.validate} value={this.state.value} />
        <Button onClick={this.handleClickPlus} iconOnly className="btn-plus button-default large">
          <Icon icon="plusCircle" />
        </Button>
        <style jsx>
          {`
            .input-number-container :global(.input-container) {
              width: 4rem;
              margin: 0 0.25rem;
            }
            .input-number-container :global(input) {
              text-align: center;
            }
          `}
        </style>
      </div>
    );
  }
}

InputNumber.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default InputNumber;
