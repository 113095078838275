import { connect } from 'react-redux';
import PastOrdersView from './OrderFromView';
import {
  isLoadedSelector,
  hasPastOrdersSelector,
  hasHubProductsSelector
} from './OrderFromViewSelectors';
import { checkSourcesAsync } from './OrderFromViewActions';

const mapDispatchToProps = {
  checkSources: () => checkSourcesAsync()
};

const mapStateToProps = state => {
  return {
    loaded: isLoadedSelector(state),
    hasPastOrders: hasPastOrdersSelector(state),
    hasHubProducts: hasHubProductsSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastOrdersView);
