import React from 'react';

const OrderSample = () => {
  return (
    <React.Fragment>
      <div>
        <span className="title">The importance of samples</span>
        <span className="description body-text-2">
          Due to the complexity of translating artwork into embroidery, we highly recommend ordering
          a sample to ensure final product meets your expectations
        </span>
      </div>
      <style jsx>{`
        display: block;

        .title {
          font-weight: 800;
          font-size: 20px;
          line-height: 160%;
          margin-top: 20px;
        }

        .description {
          margin-top: 10px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default OrderSample;
