import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ShopifyConnect from './ShopifyConnect';
import { initStoreConnect, connectStore } from '../ConnectStoreViewActions';

const mapStateToProps = state => ({ state: state.getIn(['hub', 'connectStoreView']).toJS() });

const mapDispatchToProps = {
  init: () => initStoreConnect('shopify'),
  connectStore: storeName => connectStore('shopify', storeName)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopifyConnect));
