import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import SearchInput from 'gooten-components/src/components/shared/SearchInput';

import ListItem from 'gooten-components/src/components/shared/ListItem';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';

export const ProductsFiltering = props => {
  const viewByChanged = props.viewByChanged;

  return (
    <div className="products-filtering-container">
      <div className="d-flex m-0">
        <div className="w-100 pr-2">
          <SearchInput searchText={props.search} onSearchChange={props.searchChanged} />
        </div>
        <div>
          <DropDownContainer
            title={props.viewBy ? props.viewBy + ' ' : 'View by... '}
            className="viewBy"
          >
            {props.viewByOptions.map(o => (
              <ListItem
                key={o.get('key')}
                onSelect={() => viewByChanged(o.get('key'))}
                tooltip={o.get('name')}
                text={o.get('name')}
              />
            ))}
          </DropDownContainer>
        </div>
      </div>
      <style jsx>
        {`
          .products-filtering-container :global(.dropdown-container) {
            max-width: 100%;
          }
        `}
      </style>
    </div>
  );
};

ProductsFiltering.propTypes = {
  search: PropTypes.string,
  viewByOptions: PropTypes.instanceOf(List).isRequired,
  viewBy: PropTypes.string,
  searchChanged: PropTypes.func.isRequired,
  viewByChanged: PropTypes.func.isRequired
};

export default ProductsFiltering;
