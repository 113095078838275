// @flow

import ImageService from '../../image/image.service';
import type { Rect } from '../../../flow/types';

export default class RotateService {

  /**
   * Return rotate anchor point
   * @param {Number} state
   */
  static getRotateAnchorPoint(state: any): any {
    // NOTE: Return x,y point on centerTop, either centerRight
    // depends on canvas size and selected image size
    // to make sure that rotation grip is visible in canvas zone
    const selectedImageCanvasState = ImageService.getSelectedImageCanvasState(state);

    const imageWidth = selectedImageCanvasState.crop
      ? selectedImageCanvasState.crop.width
      : selectedImageCanvasState.width;

    const imageHeight = selectedImageCanvasState.crop
      ? selectedImageCanvasState.crop.height
      : selectedImageCanvasState.height;

    const PADDING = 20;

    let x,y;
    let position;
    if (Math.floor(imageHeight + PADDING*2) <= state.editor.height) {
      x = imageWidth/2;
      y = -15;
      position = 'top';
    }
    else {
      x = imageWidth + 15;
      y = imageHeight/2;
      position = 'right';
    }

    return { x, y, position };
  }

  /**
   * Return rotate center rect
   * @param {Number} state
   */
  static getRotateCenterRect(state: any): Rect {
    let currentImageCanvasState = ImageService.getSelectedImageCanvasState(state);

    return {
      x: (currentImageCanvasState.width / 2) - 15,
      y: (currentImageCanvasState.height / 2) - 15,
      width: 30,
      height: 30,
    };
  }

  /**
   * Return rotation angle degrees
   * @param {Number} centerX
   * @param {Number} centerY
   * @param {Number} mouseX
   * @param {Number} mouseY
   * @param {Number} stageWidth
   * @param {Boolean} hasOrientationChanged
   */
  static getRotationAngleDegrees(
    centerX: number,
    centerY: number,
    mouseX: number,
    mouseY: number,
    stageWidth: number,
    hasOrientationChanged: boolean = false,
    correlation: number
  ): number {
    // swap axis if orientation changed
    if (hasOrientationChanged) {
      mouseY = [stageWidth - mouseX, mouseX = mouseY][0];
    }

    let x = mouseX - centerX;
    let y = mouseY - centerY;

    let radians = Math.PI * 0.5 + Math.atan(y / x);
    let degrees = (180 * (radians / Math.PI)) - correlation;

    if (x < 0) {
      degrees += 180;
    }

    if (degrees < 0 ) {
      degrees +=360; // Normalize angle in range from 0 to 360
    }

    return degrees;
  }

}
