import { connect } from 'react-redux';
import ProductList from './ProductList';
import { productsSelector } from './ProductListSelectors';
import {
  pageSelector,
  selectedProductSelector,
  providerPathSelector
} from '../../ProductSelectionViewSelectors';
import { selectProduct, selectPage } from './ProductListActions';
import linkProductService from '../../../LinkProductService';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/
const mapDispatchToProps = {
  select: (product, selected) => selectProduct(product, selected),
  changePage: index => selectPage(index)
};

const getProvider = providerPath => {
  const elems = providerPath ? providerPath.split('/') : null;
  const prov = elems.length >= 4 ? elems[elems.length - 2] : null;
  return prov ? prov.charAt(0).toUpperCase() + prov.slice(1) : null;
};

const mapStateToProps = state => {
  const products = productsSelector(state);
  const page = pageSelector(state);
  const selectedProduct = selectedProductSelector(state);
  const pageSize = linkProductService.pageSize;
  const providerPath = providerPathSelector(state);
  const provider = getProvider(providerPath);

  return {
    products,
    selectedProduct,
    pageSize,
    page,
    provider
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
