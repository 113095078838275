export const intersectRect = (r1, r2) => {
  return r1.x1 < r2.x2 &&
    r1.x2 > r2.x1 &&
    r1.y1 < r2.y2 &&
    r1.y2 > r2.y1;
};

/**
 * Check if two line segments intersect
 *
 * @param p0x - line 1 start point x
 * @param p0y - line 1 start point y
 * @param p1x - line 1 end point x
 * @param p1y - line 1 end point y
 * @param p2x - line 2 start point x
 * @param p2y - line 2 start point y
 * @param p3x - line 2 end point x
 * @param p3y - line 2 end point y
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
const lineIntersect = (p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y) => {

  let s1x, s1y, s2x, s2y;
  s1x = p1x - p0x;
  s1y = p1y - p0y;
  s2x = p3x - p2x;
  s2y = p3y - p2y;

  let s, t;
  s = (-s1y * (p0x - p2x) + s1x * (p0y - p2y)) / (-s2x * s1y + s1x * s2y);
  t = ( s2x * (p0y - p2y) - s2y * (p0x - p2x)) / (-s2x * s1y + s1x * s2y);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    // Collision detected
    return true;
  }

  return false; // No collision
};

/**
 * Check if point inside polygon
 *
 * @param point - array of point coordinates - [x, y]
 * @param poly - array of array of polygon points coordinates
 * @return true if there point is inside polygon
 */
const pointInsidePoly = (point, poly) => {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  let x = point[0], y = point[1];

  let inside = false;
  for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
    let xi = poly[i][0], yi = poly[i][1];
    let xj = poly[j][0], yj = poly[j][1];

    let intersect = ((yi > y) != (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
};

/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices.
 *
 * @param a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
export const polyIntersect = (a, b) => {
  // define lines for a polygon
  const al1 = [a[0], a[1]];
  const al2 = [a[1], a[2]];
  const al3 = [a[2], a[3]];
  const al4 = [a[3], a[0]];
  // group the to perimeter
  const pa = [al1, al2, al3, al4];

  // define lines for b polygon
  const bl1 = [b[0], b[1]];
  const bl2 = [b[1], b[2]];
  const bl3 = [b[2], b[3]];
  const bl4 = [b[3], b[0]];
  // group the to perimeter
  const pb = [bl1, bl2, bl3, bl4];

  let intersected = false;
  for(let al of pa) {
    if (intersected) {
      break;
    }
    for(let bl of pb) {
      // check if lines are intersect
      if (intersected) {
        break;
      }

      intersected = lineIntersect(
        al[0].x, al[0].y, al[1].x, al[1].y,
        bl[0].x, bl[0].y, bl[1].x, bl[1].y
      );
    }
  }

  if (!intersected) {
    // NOTE: If no pairs of lines intersect and one of
    // the line end-points of polygon A is inside polygon B,
    // then A is entirely inside B
    // => All points of A inside B

    // check a inside b
    let aInsideB = pointInsidePoly(
      [a[0].x, a[0].y],
      [
        [b[0].x, b[0].y],
        [b[1].x, b[1].y],
        [b[2].x, b[2].y],
        [b[3].x, b[3].y],
      ]
    );

    if (aInsideB) {
      return true;
    }

    let bInsideA = pointInsidePoly(
      [b[0].x, b[0].y],
      [
        [a[0].x, a[0].y],
        [a[1].x, a[1].y],
        [a[2].x, a[2].y],
        [a[3].x, a[3].y],
      ]
    );

    if (bInsideA) {
      return true;
    }
  }

  return intersected;
};

/**
* Rounds number to 5 digit after comma
* to avoid small exponential notation numbers
* @param {Number} number to round
*/
export const roundFloatCoordinate = (num: number) => {
  const decimals = Math.pow(10, 5);
  const rounded = Math.round(num * decimals) / decimals;
  return rounded;
};

/**
 * Helper function to calculate new size when resizing by maximum limit.
 *
 * @param origHeight an original image height
 * @param origWidth an original image width
 * @param max maximum limit to resize
 * @return size object with new width and height
 */
export const calcResizedImageSizeByMax = (origHeight, origWidth, max) => {
  const landscape = origWidth > origHeight;
  if (landscape) {
    const newHeight = Math.ceil((origHeight/origWidth) * 1000);
    return {
      width: max,
      height: newHeight
    };
  }
  else {
    const newWidth = Math.ceil((origWidth/origHeight) * 1000);
    return {
      width: newWidth,
      height: max
    };
  }
};
