import Rules from '../ErrorsHandler/ValidationRules';

const indexMatcher = /\[([^)]+)\]/;

// Has no value or has no values in seq
const checkIssues = v => !!v && (!v.find || v.find(vv => !!vv));

class ValidationService {
  scrollToInvalid() {
    const elements = document.getElementsByClassName('has-error');
    const firstItem = elements.length && elements[0];
    if (firstItem && firstItem.scrollIntoView) {
      firstItem.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  /*
  Runs after storeState update in Save and Publish,
  receive fieldName, newValue, storeState and variantIndex
  checks new value and updates store state with validation issues if needed.
  */
  validate(fieldName, newValue, storeValidationState, variantIndex, storeState) {
    switch (fieldName) {
      case 'productName':
        return storeValidationState.update('issues', issues =>
          issues.set(
            'productName',
            !newValue
              ? 'Name required'
              : newValue.length > 140
              ? 'Max length is 140'
              : storeValidationState
                  .get('failures')
                  .get('productNames')
                  .find(n => n === newValue)
              ? 'Product name already taken'
              : null
          )
        );
      case 'customSku':
        let error;
        if (!newValue) {
          error = 'Sku required';
        } else if (newValue.length > 100) {
          error = 'Max length is 100';
        } else if (
          storeState &&
          storeState.get('provider').toLowerCase() === 'tiktok' &&
          newValue.length > 50
        ) {
          error = 'Max length for TikTok SKU is 50';
        } else if (
          storeValidationState.getIn(['failures', 'variantsSkus']).find(n => n === newValue)
        ) {
          error = 'SKU name already taken';
        } else if (
          storeState &&
          (storeState.get('provider').toLowerCase() === 'etsy' ||
            storeState.get('provider').toLowerCase() === 'bigcommerce')
        ) {
          // Etsy specific validation on SKUs
          //
          // This part is covered localy. it will raise an error, if skus are duplicated
          // here we will just check for duplication error, in order to show clear
          // notification to user because we don't allow Etsy to have duplicated SKUs withing a product
          const isAnySkuDuplicated = Rules.productVariants.duplicatedSkus(
            storeState.get('variants')
          );
          return storeValidationState.setIn(
            ['issues', 'variantsSkus', 0],
            isAnySkuDuplicated ? 'SKU name duplicated' : null
          );
        }
        return storeValidationState.setIn(['issues', 'variantsSkus', variantIndex], error);
    }
    return storeValidationState;
  }

  validateSelectedStoresState(state) {
    const selectedStoresIndexes = state
      .get('stores')
      .map((s, i) => ({
        index: i,
        selected: s.get('selected')
      }))
      .filter(s => s.selected)
      .map(s => s.index);

    // validate
    selectedStoresIndexes.forEach(i => {
      const storeState = state.getIn(['stores', i]);
      // product
      state = state.updateIn(['validation', 'stores', i], storeValidation =>
        this.validate('productName', storeState.get('name'), storeValidation)
      );

      // variants
      storeState.get('variants').forEach((v, vi) => {
        state = state.updateIn(['validation', 'stores', i], storeValidation =>
          this.validate('customSku', v.get('customSku'), storeValidation, vi, storeState)
        );
      });
    });

    return state;
  }

  isValid(validation, stores) {
    return (
      !Object.values(validation.issues).find(checkIssues) &&
      !validation.stores.find(
        s => stores.find(os => os.id === s.id).selected && Object.values(s.issues).find(checkIssues)
      )
    );
  }

  apply(validation, errors, request) {
    // Try parse API errors
    let oldVaildation = validation;
    errors
      .filter(e => e['type'] === 'Validation Error')
      .forEach(e => {
        const msg = e['message'] || '';
        const prop = e['property'] || '';
        // Parse failed SKU
        const value = msg.split("'")[1];
        if (!value) {
          return;
        }
        if (prop.indexOf('product.variants') === 0) {
          // Storage variants
          const matches = indexMatcher.exec(prop);
          if (!matches) return;
          const variantIndex = matches[1];
          if (prop.indexOf('gooten_mapping.sku') > -1) {
            validation = this.validate(
              'customSku',
              value,
              validation.update('failures', failures =>
                failures.update('variantsSkus', variantsSkus => variantsSkus.push(value))
              ),
              variantIndex
            );
          }
        } else if (prop === 'product.name') {
          // Storage product
          validation = this.validate(
            'productName',
            value,
            validation.update('failures', failures =>
              failures.update('productNames', productNames => productNames.push(value))
            )
          );
        } else if (~prop.indexOf('.overrides.product.variants')) {
          // Store variants
          if (~msg.indexOf('is already used')) {
            const taskIndexMatches = indexMatcher.exec(prop.split('.overrides')[0]);
            if (!taskIndexMatches) return;
            const taskIndex = taskIndexMatches[1];
            const storeId = request.tasks[taskIndex].options.store_id;
            const storeValidationIndex = validation
              .get('stores')
              .findIndex(s => s.get('id') === storeId);
            if (storeValidationIndex === -1) return;
            const varIndexMatches = indexMatcher.exec(prop.split('overrides.')[1]);
            if (!varIndexMatches) return;
            const variantIndex = varIndexMatches[1];
            validation = validation.updateIn(['stores', storeValidationIndex], storeValidation =>
              this.validate(
                'customSku',
                value,
                storeValidation.update('failures', failures =>
                  failures.update('variantsSkus', variantsSkus => variantsSkus.push(value))
                ),
                variantIndex
              )
            );
          }
        }
      });

    if (oldVaildation === validation) {
      // Found nothing to help
      validation = validation.set('fatal', true);
    }
    return validation;
  }
}

// singleton
export default new ValidationService();
