import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Input from '../Input';

class TextInputWithButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      hasError: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let hasError = false;
    if (this.props.regexp) {
      // validate
      hasError = !this.props.regexp.test(event.target.value);
    }
    this.setState({
      value: event.target.value,
      hasError
    });
  }

  render() {
    return (
      <div className={'text-input-with-button-container text-left' + this.props.className}>
        <div className="row pr-2 pl-2">
          <div className="col-xs-12 col-md-9 pl-1 pr-1">
            <Input
              type="text"
              label={this.props.label}
              placeholder={this.props.placeholder}
              value={this.state.value}
              onChange={this.handleChange}
              hasError={this.props.showValidation && this.state.hasError}
              errorText={
                this.props.showValidation && this.state.hasError ? this.props.validationMsg : null
              }
            />
          </div>
          <div className="col-xs-12 col-md-3 pr-1 pl-1">
            <Button
              className="button-secondary extra-large full-width mb-3"
              type="button"
              onClick={this.props.onButtonClick}
            >
              {this.props.buttonText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

TextInputWithButton.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  showValidation: PropTypes.bool.isRequired,
  validationMsg: PropTypes.string.isRequired,
  regexp: PropTypes.object.isRequired,
  label: PropTypes.string
};

export default TextInputWithButton;
