import React from 'react';

const PaymentImageComponent = ({ paymentId }) => {
  const imageData = {
    1: 'icon-visa.png',
    2: 'icon-creditcard.png',
    3: 'icon-creditcard.png',
    4: 'icon-creditcard.png',
    5: 'icon-mastercard.png',
    6: 'icon-paypal.png',
    7: 'icon-amex.png',
    8: 'icon-creditcard.png',
    9: 'icon-creditcard.png',
    10: 'icon-paypal.png',
    13: 'icon-paypal.png'
  };

  if (paymentId in imageData) {
    return <img src={`/admin-assets/img/AssetsOrdersList/${imageData[paymentId]}`} />;
  } else {
    return null;
  }
};

export default PaymentImageComponent;
