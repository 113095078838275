import React from 'react';
import Logo from '../../assets/logo.svg';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const LoginHeader = ({ contentDescription }) => (
  <>
    <div className="top-content">
      <Helmet>
        <meta name="description" content={contentDescription} />
      </Helmet>
      <a href="https://www.gooten.com">
        <img src={Logo} alt="logo" />
      </a>
      <div className="has-existing-account">
        <div className="already-registered">NEW TO GOOTEN?</div>
        <a href="https://www.gooten.com/merchants/contact">GET STARTED</a>
      </div>
    </div>
    <style jsx>{`
      .top-content {
        display: flex;
        font-family: Avenir-Heavy;
        justify-content: space-between;
        font-weight: 900;
        font-size: 11px;
        z-index: 10;
      }
      .top-content a img {
        margin: 18px 0 0 32px;
      }
      .has-existing-account {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 18px 32px 0 32px;
        letter-spacing: 1px;
      }
      .already-registered {
        color: #646b72;
        white-space: nowrap;
      }
      .already-registered + a {
        text-decoration: none;
        margin-left: 10px;
        color: #0a2599;
      }
    `}</style>
  </>
);

LoginHeader.propTypes = {
  contentDescription: PropTypes.string.isRequired
};
