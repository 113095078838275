import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import ReportTable from './atoms/ReportTable';
import ReportInfo from './atoms/ReportInfo';
import Spinner from 'gooten-components/src/components/shared/Spinner';
import ReportTooltip from './atoms/ReportTooltip';

const ProductionReport = ({
  periods,
  tableHeader,
  data,
  error,
  inProgress,
  selectedPeriod,
  setPeriod,
  tableMaxItems,
  setSort,
  sort,
  reset,
  dataExist
}) => {
  const [fullscreen, setFullscreen] = useState(false);

  const periodItems = () =>
    periods.map((item, i) => <ListItem text={item} onSelect={() => setPeriod(i)} key={item} />);

  const handleHeaderClick = header => {
    setSort(header);
  };

  const handleFullscreenClick = value => {
    reset();
    setFullscreen(value);
  };

  return (
    <>
      <div className="production-report">
        {fullscreen && (
          <Button
            className="button-white medium ml-6 pr-2 mt-4 mb-2"
            onClick={() => handleFullscreenClick(false)}
          >
            <Icon icon="chevronLeft" className="ml-n1 mr-2" />
            Back
          </Button>
        )}
        <div
          className={`product-report-content d-flex flex-column ${
            fullscreen ? 'pl-5 pr-5 ml-5 mr-5' : ''
          }`}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span className="title-2">Gooten Production Report</span>
              <ReportTooltip position={fullscreen ? 'bottom' : 'top'} />
            </div>
            {!!dataExist && (
              <DropDownContainer title={periods.get(selectedPeriod)}>
                {periodItems()}
              </DropDownContainer>
            )}
          </div>
          {inProgress ? (
            <Spinner customClass="report-spinner d-flex justify-content-around align-items-center" />
          ) : error.length ? (
            <span className="report-error d-flex align-items-center justify-content-center body-text-1">
              {error}
            </span>
          ) : !dataExist ? (
            <ReportInfo />
          ) : (
            <ReportTable
              fullscreen={fullscreen}
              header={tableHeader}
              data={data}
              maxItems={tableMaxItems}
              headerClick={handleHeaderClick}
              sort={sort}
            />
          )}
          {!fullscreen && dataExist > tableMaxItems && (
            <Button
              className="button-action pl-2 pr-2 pt-1 pb-1"
              onClick={() => handleFullscreenClick(true)}
            >
              View all
            </Button>
          )}
        </div>
      </div>
      <style jsx>{`
        .production-report {
          width: 100%;
          position: ${fullscreen ? 'absolute' : 'initial'};
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1000;
          background: white;
        }

        :global(.report-spinner, .report-error) {
          position: initial !important;
          height: 200px !important;
          transform: initial !important;
        }
      `}</style>
    </>
  );
};

ProductionReport.propTypes = {
  periods: PropTypes.instanceOf(List).isRequired,
  tableHeader: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(List),
  error: PropTypes.string,
  inProgress: PropTypes.bool.isRequired,
  selectedPeriod: PropTypes.number.isRequired,
  setPeriod: PropTypes.func.isRequired,
  tableMaxItems: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  sort: PropTypes.instanceOf(Map).isRequired,
  reset: PropTypes.func.isRequired,
  dataExist: PropTypes.func.bool
};

export default ProductionReport;
