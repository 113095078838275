import { connect } from 'react-redux';
import VendorsView from './VendorsView';
import { loadingStatus, failedOrderInfo, orderLogs } from '../OrderDetailsSelectors';
import { getOrderFailedInfo, getOrderLogs, reattemptOrder } from '../OrderDetailsActions';

const mapDispatchToProps = {
  getOrderFailedInfo: orderId => getOrderFailedInfo(orderId),
  getOrderLogs: orderId => getOrderLogs(orderId),
  reattemptOrder: attemptId => reattemptOrder(attemptId)
};

const mapStateToProps = state => {
  return {
    loading: loadingStatus(state),
    failedOrderInfo: failedOrderInfo(state),
    orderLogs: orderLogs(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorsView);
