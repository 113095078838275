import { connect } from 'react-redux';
import OrderSampleModal from './OrderSampleModal';
import { taskNotificationSelector } from '../ProductsSelectors';
import { productSelectById } from '../ProductsActions';
import {
  sampleOrderModalTextSelector,
  sampleOrderModalTitleSelector,
  showSelector,
  sampleOrderProductIdSelector
} from './OrderSampleModalSelectors';
import { showModal } from './OrderSampleModalActions';

const mapDispatchToProps = {
  showModal: value => showModal(value),
  selectProduct: id => productSelectById(id)
};

const mapStateToProps = (state, ownProps) => ({
  title: sampleOrderModalTitleSelector(state),
  text: sampleOrderModalTextSelector(state),
  notification: taskNotificationSelector(state),
  modalVisible: !!showSelector(state),
  productId: sampleOrderProductIdSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSampleModal);
