import React from 'react';
import PropTypes from 'prop-types';
import Dialogue from '../../Dialogue';

class ConfirmNavigationModal extends React.PureComponent {
  confirm() {
    this.props.connectStore(this.props.url);
  }

  render() {
    return (
      <Dialogue
        isOpen={this.props.isOpen}
        actionText="CONTINUE"
        cancelClick={this.props.hideModal ? this.props.hideModal : null}
        destructiveClick={() => {
          this.confirm();
        }}
        title="Update to Shopify Authorization"
      >
        We recently made a mandatory update to your Shopify integration. Please re-authorize your
        Shopify store to avail new features.
        <br />
        <br />
        <ul>
          <li>
            This update will allow us to send accurate Cost information to your Shopify store.
          </li>
        </ul>
      </Dialogue>
    );
  }
}

ConfirmNavigationModal.propTypes = {
  isOpen: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  connectStore: PropTypes.func
};

ConfirmNavigationModal.defaultProps = {
  isOpen: false
};

export default ConfirmNavigationModal;
