import { List, Map, Set, OrderedSet, Seq, is } from 'immutable';

export const isImmutable = arg => {
  return (
    List.isList(arg) ||
    Map.isMap(arg) ||
    Set.isSet(arg) ||
    OrderedSet.isOrderedSet(arg) ||
    Seq.isSeq(arg)
  );
};

export const deepEqual = (arg1, arg2) => {
  if (isImmutable(arg1) && isImmutable(arg2)) {
    return is(arg1, arg2);
  }
  return JSON.stringify(arg1) === JSON.stringify(arg2);
};
