import { Record } from 'immutable';
import { mapCurrency } from './Currency';

// Hold data for 1 shipping country
const Country = new Record({
  name: '',
  code: '',
  isSupported: false,
  measurementCode: '',
  flagUrl: '',
  defaultCurrency: {}
});

export default Country;

export const mapCountry = obj =>
  new Country({
    name: obj.Name,
    code: obj.Code,
    isSupported: obj.IsSupported,
    measurementCode: obj.MeasurementCode,
    flagUrl: obj.FlagUrl,
    defaultCurrency: mapCurrency(obj.DefaultCurrency)
  });
