import { fetch } from '../../../../../../utils/http';
import Config from '../../../../../../config';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    }
  };
};

const addressValidationUrl = () => `${Config.get('api')}addressvalidation`;

class AddressValidationService {
  validate(request) {
    console.log(request);
    let combinedParams = getAuthQueryParams();
    let queryParams = combinedParams.queryParams;
    combinedParams.queryParams = { ...queryParams, ...request };
    return new Promise((resolve, reject) => {
      fetch(addressValidationUrl(), combinedParams)
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

// singleton
export default new AddressValidationService();
