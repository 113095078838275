import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { queryStringToJSON, paginationItems, pageShowingCalculation } from './helper';
import SidePanelComponent from './components/SidePanelComponent';
import ChangeStatusComponent from './components/ChangeStatusComponent';
import OrdersViewTabsComponent from './components/OrdersViewTabsComponent';
import OrdersViewTableComponent from './components/OrdersViewTableComponent';

import Alert from 'gooten-components/src/components/shared/Alert';
import Button from 'gooten-components/src/components/shared/Button';
import DropDown from 'gooten-components/src/components/shared/DropDown';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import Chip from 'gooten-components/src/components/shared/Chip';
import Input from 'gooten-components/src/components/shared/Input';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import LoadingSpinner from 'gooten-components/src/components/shared/LoadingSpinner';
import Notifications from 'gooten-components/src/components/shared/Notifications';
import PageBody from 'gooten-components/src/components/shared/PageBody';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import Pager from 'gooten-components/src/components/shared/Pager';
import SecurityService from '../../Settings/SecurityService';
import { withRouter, Link } from 'react-router';
import Icon from 'gooten-components/src/components/shared/Icon';
import NoOrdersComponent from './components/NoOrdersComponent';
import KnowledgeBaseInfo from 'gooten-components/src/components/shared/KnowledgeBaseInfo';
import Config from 'gooten-components/src/config';
import {
  ORDER_SUPPORT_POLICIES_KB_TEXT,
  ORDER_SUPPORT_POLICIES_KB_LINK,
  ORDER_SUPPORT_POLICIES_KB_LINK_FUJI,
  ORDER_SUPPORT_POLICIES_KB_LINK_SENSARIA,
  SHIPPING_TIMES_KB_TEXT,
  SHIPPING_TIMES_KB_LINK,
  SHIPPING_TIMES_KB_LINK_FUJI,
  SHIPPING_TIMES_KB_LINK_SENSARIA
} from 'gooten-components/src/constants';

const OrdersView = props => {
  const [openFilters, setOpenFilters] = useState(false);
  const [openPageDropDown, setOpenPageDropDown] = useState(false);
  const [showPartnerWarning, setShowPartnerWarning] = useState(true);
  const [showPartnerPaymentIssueWarning, setShowPartnerPaymentIssueWarning] = useState(true);
  const [showPartnerShopifyWarning, setShowPartnerShopifyWarning] = useState(true);
  const [personalizeView, setPersonalizeView] = useState(false);
  const [lastViewSettings, setLastViewSettings] = useState('Order');

  useEffect(() => {
    props.searchStart();
    props.fetchHelpData();
    convertQueryStringToObject();
  }, []);

  useEffect(() => {
    setUrlQueryString();
  }, [props.searchFields]);

  useEffect(() => {
    // set to personalizeView if it is in NeedsPersonalization tab
    if (props.searchFields.get('group') === 5) {
      setPersonalizeView(true);
    }
  }, [props.searchFields]);

  const selectedItems = props.ordersList
    .get('items')
    .toJS()
    .filter(item => item.selectedByUser);
  const selectedItemIds = selectedItems.map(x => x.OrderId);

  const getExportLink = () => {
    let link = '';
    const itemType = props.searchFields.get('itemsType');

    if (selectedItems.length && itemType === 'Order') {
      link =
        '/admin/Orders/Export?ids=' +
        selectedItemIds.join() +
        '&offset=240' +
        '&mode=' +
        (props.searchFields.get('itemsType') === 'OrderItem' ? 'OrderItem' : 'Order');
    } else if (selectedItems.length && itemType === 'OrderItem') {
      const selectedIds = selectedItems.map(x => x.Id);
      link =
        '/admin/Orders/Export?ids=' +
        selectedIds.join() +
        '&offset=240' +
        '&mode=' +
        (props.searchFields.get('itemsType') === 'OrderItem' ? 'OrderItem' : 'Order');
    }
    return link;
  };

  const handleTabChange = setTrue => {
    setPersonalizeView(setTrue);
    // if going to NeedsPersonalization tab, ALWAYS set to OrderItem View
    if (setTrue) {
      props.updateOrderOrderItem({ itemsMode: 'OrderItem', itemsType: 'OrderItem' });

      // NOTE: handles for switching for NeedsPersonalization and sort view is not available
      if (['orderId', 'sourceId'].includes(props.searchFields.get('SortField'))) {
        props.updateSearchFields('Desc', true, 'dateCreated');
      }

      // if LEAVING NeedsPersonalization tab, reset to previous view mode
    } else if (props.searchFields.get('group') === 5) {
      props.updateOrderOrderItem({
        itemsMode: lastViewSettings,
        itemsType: lastViewSettings
      });

      // NOTE: handles for switching from NeedsPersonalization and sort view is not available
      if (
        lastViewSettings === 'Order' &&
        ['orderItemId', 'sku', 'vendorName', 'orderId'].includes(
          props.searchFields.get('SortField')
        )
      ) {
        props.updateSearchFields('Desc', true, 'dateCreated');
      }
    }
  };

  const footerContainer = () => {
    return (
      <div className="footer-container d-flex align-items-center justify-content-between flex-wrap ml-n2 mr-n2">
        <div className="page-size-container m-2">
          <DropDown
            bottomAlign
            isOpen={openPageDropDown}
            toggle={() => setOpenPageDropDown(!openPageDropDown)}
            title={`Show ${props.searchFields.get('pageSize')}`}
          >
            <ListItem
              text="25"
              onSelect={() => {
                setOpenPageDropDown(!openPageDropDown);
                props.updateSearchFields('pageSize', 25);
              }}
            />
            <ListItem
              text="50"
              onSelect={() => {
                setOpenPageDropDown(!openPageDropDown);
                props.updateSearchFields('pageSize', 50);
              }}
            />
            <ListItem
              text="100"
              onSelect={() => {
                setOpenPageDropDown(!openPageDropDown);
                props.updateSearchFields('pageSize', 100);
              }}
            />
          </DropDown>
        </div>
        <div className="m-2">
          {!props.searchLoading &&
            pageShowingCalculation(
              props.searchFields.get('pageSize'),
              props.searchFields.get('page'),
              props.ordersList.get('total')
            )}
        </div>
        <div className="m-2">
          {!!props.searchFields.get('pageSize') &&
            !!props.ordersList.get('total') &&
            !props.searchLoading && (
              <Pager
                current={props.searchFields.get('page') - 1}
                maxSize={10}
                items={paginationItems(
                  props.ordersList.get('total'),
                  props.searchFields.get('pageSize')
                )}
                goTo={pageNumber => props.updatePageNumber(pageNumber + 1)}
              />
            )}
        </div>
        <style jsx>{`
          .pager-container div:nth-child(1) {
            margin-right: 16px;
          }
          .pager-container div:nth-child(2) {
            flex: 1;
        `}</style>
      </div>
    );
  };

  const updateItemStatus = statusId => {
    props.changeStatus({ statusId });
  };

  const serializeObject = data => {
    const params = new URLSearchParams();
    for (var key in data) {
      if (!data[key]) {
        continue;
      }

      if (Array.isArray(data[key])) {
        if (data[key].length) {
          data[key].map(value => {
            if (value instanceof Object) {
              params.append(key, value.key);
            } else {
              params.append(key, value);
            }
          });
        }
      } else {
        params.append(key, data[key]);
      }
    }

    return params.toString();
  };

  const handleSearchFieldsWithObject = search => {
    props.updateSearchFieldsWithObject(search);
  };

  const convertQueryStringToObject = () => {
    const hashUrl = window.location.hash;
    const splitHash = hashUrl.split('?');
    const urlQueryString = splitHash[1];
    const queryStringJson = queryStringToJSON(urlQueryString);
    if (urlQueryString) {
      props.updateSearchFieldsWithObjectInitial(queryStringJson);
    }
  };

  const setUrlQueryString = () => {
    const queryStringUpdated = serializeObject(props.searchFields.toJS());
    const currenQueryString = props.router.location.search;
    if (currenQueryString === `?${queryStringUpdated}`) {
      return;
    }
    props.router.push(`/orders-new?${queryStringUpdated}`);
    // window.history.pushState(null, '', `/#/orders-new?${queryStringUpdated}`)
  };

  const acceptableStatuses = () => {
    const acceptableStatusAdjusted = props.helpData.get('acceptableStatuses').toJS();
    if (SecurityService.isPartner() || SecurityService.isPartnerAdmin()) {
      acceptableStatusAdjusted.unshift({ Id: 'FakeReadyToPrint', Name: 'SendToProduction' });
    }
    return acceptableStatusAdjusted;
  };

  const getOrderSupportPoliciesKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return ORDER_SUPPORT_POLICIES_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return ORDER_SUPPORT_POLICIES_KB_LINK_FUJI;
      case 2:
        return ORDER_SUPPORT_POLICIES_KB_LINK_SENSARIA;
      default:
        return ORDER_SUPPORT_POLICIES_KB_LINK;
    }
  };

  const getShippingTimesKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup) return SHIPPING_TIMES_KB_LINK;

    switch (partnerGroup.get('Id')) {
      case 1:
        return SHIPPING_TIMES_KB_LINK_FUJI;
      case 2:
        return SHIPPING_TIMES_KB_LINK_SENSARIA;
      default:
        return SHIPPING_TIMES_KB_LINK;
    }
  };

  const isBillingAddressMissing = !!props.helpData && props.helpData.toJS().isBillingAddressMissing;
  const isPaymentMethodMissing = !!props.helpData && props.helpData.toJS().isPaymentMethodMissing;
  const partnerWarning = isBillingAddressMissing || isPaymentMethodMissing;
  const partnerShopifyWarning =
    !!props.helpData && props.helpData.toJS().hasShopifyConnectionWarning;
  const partnerPaymentIssueWarning =
    !!props.helpData &&
    !!props.helpData.toJS().partnerPaymentIssueCount &&
    props.helpData.toJS().partnerPaymentIssueCount > 0;
  const isPartnerOnCredit = !!props.helpData && props.helpData.toJS().isOnCredit;
  const partnerPaymentIssueCount =
    !!props.helpData && props.helpData.toJS().partnerPaymentIssueCount;
  const vendorsList = !!props.helpData && props.helpData.toJS().vendorsList;
  const partnerGroupsList = !!props.helpData && props.helpData.toJS().partnerGroupsList;
  return (
    <>
      <Notifications />
      {partnerWarning && showPartnerWarning && (
        <div className="ml-4 mr-4">
          <Alert
            className="mt-3 w-100"
            isOpen
            type="warning"
            closeClick={() => setShowPartnerWarning(false)}
          >
            <div>
              Let's get your orders into production! Please add your&nbsp;
              {isPaymentMethodMissing && (
                <Link className="link" to={`/settings/billing/creditCard`}>
                  Payment Method
                </Link>
              )}
              {isPaymentMethodMissing && isBillingAddressMissing && <span> and your </span>}
              {isBillingAddressMissing && (
                <Link className="link" to={`/settings/billing/billingAddress`}>
                  billing/business address
                </Link>
              )}
              &nbsp;so that we can do so.
            </div>
          </Alert>
        </div>
      )}

      {partnerPaymentIssueWarning && showPartnerPaymentIssueWarning && (
        <div className="ml-4 mr-4">
          <Alert
            className="mt-3 w-100"
            isOpen
            type="important"
            closeClick={() => setShowPartnerPaymentIssueWarning(false)}
          >
            <div>
              {!isPartnerOnCredit && (
                <span>
                  You have {partnerPaymentIssueCount} order(s) awaiting payment. Please{' '}
                  <Link className="link" to={`/settings/billing/creditCard`}>
                    update your credit card
                  </Link>{' '}
                  on file.
                </span>
              )}
              {isPartnerOnCredit && (
                <span>
                  Some of your orders are stuck in paymentIssue but your account is currently set on
                  credit. Please contact us to print this order.
                </span>
              )}
            </div>
          </Alert>
        </div>
      )}

      {partnerShopifyWarning && showPartnerShopifyWarning && (
        <div className="ml-4 mr-4">
          <Alert
            className="mt-3 w-100"
            isOpen
            type="important"
            closeClick={() => setShowPartnerShopifyWarning(false)}
          >
            <div>
              <b>No store connected</b> <br />
            </div>
            <div>
              You didn't connect your Shopify store to Gooten account yet.{' '}
              <Link className="link" to={`/hub`}>
                Connect your store
              </Link>{' '}
              so we can process your orders for fulfillment.
            </div>
          </Alert>
        </div>
      )}

      <div className="mt-3 ml-4 pl-2 mb-n4 d-flex justify-content-start flex-column hidden-xs hidden-sm caption-text">
        <div className="d-flex align-items-center">
          <Icon height={16} width={16} className="mr-2" icon="sparkle" fill={'#FFCC4D'} /> We’re
          updating the orders page with new features and a new look.&nbsp;
          <Link className="link" to={`/orders`}>
            View old orders page
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <Icon height={16} width={16} className="mr-2" icon="info" />
          <a className="link" target="_blank" href={getOrderSupportPoliciesKbLink()}>
            {ORDER_SUPPORT_POLICIES_KB_TEXT}
          </a>
          ,&nbsp;
          <a className="link" target="_blank" href={getShippingTimesKbLink()}>
            {SHIPPING_TIMES_KB_TEXT}
          </a>
        </div>
      </div>
      <PageHeader
        title={`Orders`}
        customButtons={
          <div className="d-flex order-details-buttons m-n1">
            {props.searchFields.get('itemsType') === 'OrderItem' && (
              <ChangeStatusComponent
                disabled={!selectedItems.length}
                changeStatus={statusId => updateItemStatus(statusId)}
                statuses={acceptableStatuses()}
              />
            )}
            <Link to="/orders/add">
              <Button iconLeft="uploadCloud" className="button-secondary m-1 large">
                Import
              </Button>
            </Link>
            {!!selectedItems.length && (
              <a href={getExportLink()}>
                <Button
                  disabled={!selectedItems.length}
                  iconLeft="downloadCloud"
                  className="button-default m-1 large"
                >
                  Export CSV
                </Button>
              </a>
            )}
            <Link to="/custom-order-form">
              <Button iconLeft="plusCircle" className="button-primary m-1 large">
                Create Order
              </Button>
            </Link>
          </div>
        }
      />

      <PageBody size="full">
        {props.noOrders && !props.searchLoading && <NoOrdersComponent />}
        {!props.noOrders && (
          <>
            {!!props.helpData && !!props.helpData.get('allowedStatuses').size && (
              <SidePanelComponent
                setOpenFilters={() => setOpenFilters(!openFilters)}
                openFilters={openFilters}
                helpData={props.helpData.toJS()}
                searchFieldData={props.searchFields}
                sendAction={searchObject => {
                  setUrlQueryString(searchObject);
                  handleSearchFieldsWithObject(searchObject);
                }}
              />
            )}

            <OrdersViewTabsComponent
              tabs={props.ordersList.get('tabs').toJS()}
              group={parseInt(props.searchFields.get('group'))}
              updateGroupSelection={key => props.updateSearchFields('group', key)}
              setPersonalizeView={personalizeTrue => handleTabChange(personalizeTrue)}
            />

            <div className="d-flex mb-1 mt-4">
              <Input
                placeholder="Search orders"
                noLabel
                icon="search"
                iconPlacement="leading"
                value={props.searchFields.get('SimpleSearch')}
                onChange={e => handleSearchFieldsWithObject({ SimpleSearch: e.target.value })}
              />
              <Button
                iconLeft="filter"
                className="button-default large ml-2"
                onClick={() => setOpenFilters(true)}
              >
                Filters
              </Button>
              {/* hide below options for "needs personalization view"; that should ONLY be order items view */}
              {!personalizeView && (
                <DropDownContainer
                  className="ml-2"
                  rightAlign
                  title={props.searchFields.get('itemsType') === 'Order' ? 'Orders' : 'Order Items'}
                >
                  <ListItem
                    onSelect={() => {
                      props.updateOrderOrderItem({ itemsMode: 'Order', itemsType: 'Order' });
                      setLastViewSettings('Order');
                    }}
                    text="Orders"
                  />
                  <ListItem
                    onSelect={() => {
                      props.updateOrderOrderItem({
                        itemsMode: 'OrderItem',
                        itemsType: 'OrderItem'
                      });
                      setLastViewSettings('OrderItem');
                    }}
                    text="Order Items"
                  />
                </DropDownContainer>
              )}
            </div>

            <div className="d-flex mr-n1 ml-n1 flex-wrap remove-filter-actions">
              {/* This is written out instead of mapped because it needs a
              specific list order and each item has unique characteristics for display */}
              {!!props.helpData && !!props.helpData.get('allowedStatuses').size && (
                <>
                  {props.searchFields.toJS().SimpleSearch && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ SimpleSearch: '' })}
                    >
                      Keywords: {props.searchFields.toJS().SimpleSearch}
                    </Chip>
                  )}
                  {props.searchFields.toJS().StatusIds.length ? (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ StatusIds: [] })}
                    >
                      Status{`${props.searchFields.toJS().StatusIds.length > 1 ? 'es' : ''}`}:
                      {props.searchFields
                        .toJS()
                        .StatusIds.map(
                          z =>
                            props.helpData
                              .get('allowedStatuses')
                              .toJS()
                              .find(x => x.Id === z).Name
                        )
                        .join(', ')}
                    </Chip>
                  ) : null}
                  {props.searchFields.toJS().BeginningDate && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ BeginningDate: '' })}
                    >
                      Start date:{' '}
                      {new Date(props.searchFields.toJS().BeginningDate).toLocaleDateString()}
                    </Chip>
                  )}
                  {props.searchFields.toJS().EndingDate && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ EndingDate: '' })}
                    >
                      End date:{' '}
                      {new Date(props.searchFields.toJS().EndingDate).toLocaleDateString()}
                    </Chip>
                  )}
                  {props.searchFields.toJS().PartnerIds.length ? (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ PartnerIds: [] })}
                    >
                      Partner: {props.searchFields.toJS().PartnerIds[0].label}
                    </Chip>
                  ) : null}
                  {props.searchFields.toJS().PartnerGroupIds.length ? (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ PartnerGroupIds: [] })}
                    >
                      Partner Group:{' '}
                      {props.searchFields
                        .toJS()
                        .PartnerGroupIds.map(partnerGroupId =>
                          partnerGroupsList
                            ? partnerGroupsList.find(
                                partnerGroup => partnerGroup.Id === partnerGroupId
                              ).Name
                            : partnerGroupId
                        )
                        .join(',')}
                    </Chip>
                  ) : null}
                  {props.searchFields.toJS().PartnerGroupId && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ PartnerGroupId: '' })}
                    >
                      Partner Group ID: {props.searchFields.toJS().PartnerGroupId}
                    </Chip>
                  )}
                  {props.searchFields.toJS().VendorIds.length ? (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ VendorIds: [] })}
                    >
                      Vendor:{' '}
                      {props.searchFields
                        .toJS()
                        .VendorIds.map(vendorId =>
                          vendorsList
                            ? vendorsList.find(vendor => vendor.Id === vendorId).Name
                            : vendorId
                        )
                        .join(',')}
                    </Chip>
                  ) : null}
                  {props.searchFields.toJS().VendorId && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ VendorId: '' })}
                    >
                      Vendor ID: {props.searchFields.toJS().VendorId}
                    </Chip>
                  )}
                  {props.searchFields.toJS().PartnerName && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ PartnerName: '' })}
                    >
                      Partner name: {props.searchFields.toJS().PartnerName}
                    </Chip>
                  )}
                  {props.searchFields.toJS().PartnerOrderId && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ PartnerOrderId: '' })}
                    >
                      Partner order id: {props.searchFields.toJS().PartnerOrderId}
                    </Chip>
                  )}
                  {props.searchFields.toJS().Platform && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => {
                        handleSearchFieldsWithObject({ Platform: '' });
                        handleSearchFieldsWithObject({ orderSource: '' });
                      }}
                    >
                      Platform: {props.searchFields.toJS().Platform}
                    </Chip>
                  )}
                  {props.searchFields.toJS().Version && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ Version: '' })}
                    >
                      Version: {props.searchFields.toJS().Version}
                    </Chip>
                  )}
                  {props.searchFields.toJS().OrderId && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ OrderId: '' })}
                    >
                      Order id: {props.searchFields.toJS().OrderId}
                    </Chip>
                  )}
                  {props.searchFields.toJS().ShortId && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ ShortId: '' })}
                    >
                      Short Id: {props.searchFields.toJS().ShortId}
                    </Chip>
                  )}
                  {props.searchFields.toJS().BillingName && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ BillingName: '' })}
                    >
                      Billing name: {props.searchFields.toJS().BillingName}
                    </Chip>
                  )}
                  {props.searchFields.toJS().ShippingName && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ ShippingName: '' })}
                    >
                      Shipping name: {props.searchFields.toJS().ShippingName}
                    </Chip>
                  )}
                  {props.searchFields.toJS().ShippingEmail && (
                    <Chip
                      iconRight="x"
                      className="m-1"
                      onClick={() => handleSearchFieldsWithObject({ ShippingEmail: '' })}
                    >
                      Customer email: {props.searchFields.toJS().ShippingEmail}
                    </Chip>
                  )}
                </>
              )}
            </div>

            {props.ordersViewErrors.length > 0 && (
              <Alert className="mt-3" isOpen type="failure">
                {props.ordersViewErrors.map(error => error)}
              </Alert>
            )}

            <OrdersViewTableComponent
              selectedItems={selectedItems}
              toggleSelectedRows={data => props.toggleSelectedRows(!!data)}
              ordersListItems={props.ordersList.get('items').toJS()}
              updateSearchFields={(key, value, sortField) =>
                props.updateSearchFields(key, value, sortField)
              }
              searchFields={props.searchFields.toJS()}
              toggleSelectedRow={selectedRowObject => props.toggleSelectedRow(selectedRowObject)}
              personalizeView={personalizeView}
              changeStatus={statusId => updateItemStatus(statusId)}
            />

            {props.searchLoading && <LoadingSpinner />}

            {footerContainer()}
          </>
        )}
        <style jsx>{`
          :global(.pointer-cursor) {
            cursor: pointer !important;
          }
          :global(.order-status-cell) {
            display: flex;
            flex-direction: row;
          }
          .cell-link {
            color: inherit;
            text-decoration: none;
          }
          .cell-link:hover {
            color: inherit;
            text-decoration: none;
          }
          :global(.products-actions-container h3) {
            display: none;
          }
          .remove-filter-actions :global(.chip) {
            cursor: pointer;
          }
        `}</style>
      </PageBody>
    </>
  );
};

OrdersView.propTypes = {
  searchLoading: PropTypes.bool,
  noOrders: PropTypes.bool,
  searchStart: PropTypes.func,
  fetchHelpData: PropTypes.func,
  ordersList: PropTypes.object,
  ordersViewErrors: PropTypes.any,
  updateSearchFieldsWithObject: PropTypes.func,
  updateSearchFieldsWithObjectInitial: PropTypes.func,
  searchFields: PropTypes.object,
  updateSearchFields: PropTypes.func,
  helpData: PropTypes.object,
  toggleSelectedRows: PropTypes.func,
  toggleSelectedRow: PropTypes.func,
  changeStatus: PropTypes.func,
  updatePageNumber: PropTypes.func,
  updateOrderOrderItem: PropTypes.func,
  router: PropTypes.object
};

export default withRouter(OrdersView);
