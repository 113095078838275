import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import StoresContainer from './StoresContainer';

const storesRoutes = {
  path: '/stores',
  component: CoreLayout,
  indexRoute: { component: StoresContainer }
};

export default storesRoutes;
