import React from 'react';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';

class ActionConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      content: 'Are you sure?',
      action: () => {}
    };
  }

  openModal = (content, action) => {
    this.setState({ isOpen: true, content, action });
  };

  hideModal = () => {
    this.setState({ ...this.state, isOpen: false });
  };

  confirm = () => {
    this.state.action();
    this.hideModal();
  };

  render() {
    return (
      <Dialogue
        isOpen={this.state.isOpen}
        cancelText="Cancel"
        actionText="Delete"
        cancelClick={this.hideModal}
        destructiveClick={this.confirm}
        title={this.state.content}
      />
    );
  }
}

export default ActionConfirmationModal;
