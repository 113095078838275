import React from 'react';

import './EmptyLanding.scss';

export const AllEmptyLanding = props => {
  return (
    <div className="store-storage-empty-landing-container text-center">
      <h3 className="text-center">Here you will see a list of all products you have created.</h3>
      <div className="landing-image img-storage" />
      <p>
        When ready, start creating products. You can publish them directly
        <br />
        to your store, or save them for later in Storage.
      </p>
    </div>
  );
};

export default AllEmptyLanding;
