import { base64ToBlob, getImageResizerUrl } from 'gooten-js-utils/src/url';
import ImagePreviewControl from 'gooten-js-preview/src/_scripts/main';

const TEMP_CONTAINER_ID = 'temp-preview-gen';
let containerElement = null;

function createContainer() {
  const wrapper = document.createElement('div');
  wrapper.style.display = 'none';
  document.body.appendChild(wrapper);

  let container = document.createElement('div');
  container.id = TEMP_CONTAINER_ID;
  container.style.cssText = 'display: none';
  wrapper.appendChild(container);

  return container;
}

function renderPreviewControl(space, config, containerId, oldPreviewUrlToDelete) {
  return new Promise((resolve, reject) => {
    const options = {
      preview: config.preview, // print | product
      container: containerId,
      width: config.width,
      height: config.height,
      changeOrientation: config.changeOrientation,
      maximizedWidth: config.maximizedWidth,
      maximizedHeight: config.maximizedHeight,
      backgroundColor: config.backgroundColor,
      imageSources: space.images.map(i => ({
        ...i,
        imageUrl: getImageResizerUrl(i.imageUrl, config.imageWidth, config.imageHeight)
      })),
      template: space.template,
      il: space.il,
      onRedraw: () => {
        // pixelRatio may be changed to device pixelRatio to fix issues on retina displays
        // had no ability to check
        const preview = URL.createObjectURL(
          base64ToBlob(previewControl.toDataUrl('image/jpeg', 1), 'image/jpeg')
        );

        // clear resources for the old preview
        if (oldPreviewUrlToDelete) {
          URL.revokeObjectURL(oldPreviewUrlToDelete);
        }

        previewControl.destroy();
        resolve(preview);
      },
      onError: err => {
        previewControl.destroy();
        // Important to reject promise so that it can be cathed in Saga
        reject(err);
      }
    };
    const previewControl = new ImagePreviewControl(options);
  });
}

export function* generatePreview(space, config, oldPreviewUrlToDelete = null) {
  if (!containerElement) {
    containerElement = createContainer();
  }

  const base64BlobUrl = yield renderPreviewControl(
    space,
    config,
    `#${TEMP_CONTAINER_ID}`,
    oldPreviewUrlToDelete
  );

  containerElement.innerHTML = '';

  return base64BlobUrl;
}
