import CanvasImageElement from '../canvas/canvasImageElement';
import CanvasLayer from '../canvas/canvasLayer';
import ImageService from './image.service';
import { SurfaceLayer, KonvaStage } from '../../flow/konvaTypes';

export default class ImageSurfaceLayer implements SurfaceLayer {

  id: string;
  layer: CanvasLayer;

  constructor(id: string) {
    this.id = id;
    this.layer = new CanvasLayer({ id });
  }

  addToCanvas(stage: KonvaStage) {
    this.layer.addToStage(stage);
  }

  draw(options: any) {
    this.layer.clear();

    const imagesCanvasStates = ImageService.getImagesCanvasStates(options);

    for(let imageState of imagesCanvasStates) {
      let imageElement = new CanvasImageElement(imageState);
      this.layer.addElement(imageElement);
    }

    this.layer.draw();
  }
};
