import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hashHistory, Router } from 'react-router';
import Config from '../config';
import { Wizard } from 'gooten-components';
import { PRODUCTS_FETCH, fetchAsync } from 'gooten-components/src/store/actions/dataActions';
import { partnerDataLoaded } from 'gooten-components/src/store/actions/globalActions';

import '../styles/_core.scss';
import { mainStyles } from 'gooten-components/src/styles';

import _JSXStyle from 'styled-jsx/style';
if (typeof global !== 'undefined') {
  Object.assign(global, { _JSXStyle });
}

class AppContainer extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    // Save immutable config
    const { config, store } = props;
    Config.destroy();
    Config.init(config);

    // init wizard steps, inject reducers, sagas
    Wizard.preInit(store, config);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    // comes from main.js
    const { store } = this.props;

    // Pre load products data
    store.dispatch(fetchAsync(PRODUCTS_FETCH));

    // Dispatch config init
    // Allow components to restore cart items, shipping country, address
    if (Config.get('recipeId')) {
      store.dispatch(partnerDataLoaded());
    }
  }

  render() {
    const { store, routes } = this.props;
    return (
      <>
        <Provider store={store}>
          <Router history={hashHistory} children={routes} />
        </Provider>
        <style jsx global>
          {mainStyles}
        </style>
      </>
    );
  }
}

export default AppContainer;
