import React from 'react';
import SizeSelect from './../../../shared/SizeSelect';
import PropTypes from 'prop-types';
import BackgroundSelect from './BackgroundSelect';
import LinkButton from './../../../shared/LinkButton';
import analyticsService from './../../../../services/analyticsService';
import './SidePanel.scss';

class SidePanel extends React.Component {
  static propTypes = {
    previewItems: PropTypes.array,
    selectedItems: PropTypes.array,
    downloadPreviews: PropTypes.func,
    showRemoveModal: PropTypes.func,
    shouldRemoveItemWOConfirmation: PropTypes.bool,
    removeItems: PropTypes.func,
    isExclusiveMockup: PropTypes.bool,
    updatePreviewSize: PropTypes.func,
    config: PropTypes.object,
    previewSize: PropTypes.object,
    disabled: PropTypes.bool,
    disableDownload: PropTypes.bool
  };

  handleAddMockups = () => {};

  handleDownload = () => {
    this.props.downloadPreviews();
    analyticsService.trackWithDynamicSource('Product preview', 'Download');
  };

  handleRemove = () => {
    if (this.props.shouldRemoveItemWOConfirmation) {
      this.props.removeItems(this.props.selectedItems);
    } else {
      this.props.showRemoveModal(true);
    }
    analyticsService.trackWithDynamicSource('Product preview', 'Remove');
  };

  render() {
    return (
      <div className="side-panel-container">
        <span className="title">Edit Mockups</span>
        <hr />
        <LinkButton
          title="Remove"
          className={this.props.disabled ? 'disabled' : ''}
          onClick={this.handleRemove}
          icon="trash"
        />
        <LinkButton
          title="Download"
          className={this.props.disableDownload ? 'disabled' : ''}
          onClick={this.handleDownload}
          icon="download"
        />

        {/** there's no support for background change in ProPreview */}
        {this.props.isExclusiveMockup ? null : (
          <div>
            <hr />
            <span className={`subtitle col-md-12${this.props.disabled ? ' disabled' : ''}`}>
              Mockup Background
            </span>
            <BackgroundSelect disabled={this.props.disabled} items={this.props.selectedItems} />
          </div>
        )}

        {/** there's no support for preview size change in ProPreview */}
        {this.props.isExclusiveMockup ? null : (
          <div>
            <span
              className={`subtitle col-md-12${!this.props.previewItems.length ? ' disabled' : ''}`}
            >
              Mockup Image Size
            </span>
            <span className="subtitle desc col-md-12">This applies to all mockups.</span>
            <SizeSelect
              disabled={!this.props.previewItems.length}
              updatePreviewSize={this.props.updatePreviewSize}
              config={this.props.config}
              previewSize={this.props.previewSize}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SidePanel;
