import { createSelector } from 'reselect';

const dataSelector = state => state.get('data');

const cartSelector = createSelector([dataSelector], dataState => dataState.get('cart'));

export const cofSelector = state => state.get('customOrderForm');

const addToCartFailureStatuses = new Set(['failed', 'productNotAvailable', 'unorderableSKUs']);

export const isLoadingSelector = createSelector([cofSelector], cofState => {
  const status = cofState.getIn(['cartView', 'loadingStatus']);
  return status !== 'ready' && !addToCartFailureStatuses.has(status);
});

export const isFailedSelector = createSelector([cofSelector], cofState => {
  const status = cofState.getIn(['cartView', 'loadingStatus']);
  return addToCartFailureStatuses.has(status);
});

export const isCartEmptySelector = createSelector([cartSelector], cart =>
  cart.get('items').isEmpty()
);
