import { getActionName } from '../../../ProductPreviewActions';

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_CUSTOM_COLOR = getActionName('UPDATE_CUSTOM_COLOR');

// ------------------------------------
// Actions
// ------------------------------------
export const updateCustomColor = color => {
  return {
    type: UPDATE_CUSTOM_COLOR,
    payload: color
  };
};
