import React from 'react';
import PropTypes from 'prop-types';
import analyticsService from 'gooten-components/src/services/analyticsService';
import StoreConnect from '../shared/StoreConnect';
import Loader from 'gooten-components/src/components/shared/Loader';
import Notifications from 'gooten-components/src/components/shared/Notifications/Notifications';

import './ShopifyConnect.scss';

// eslint-disable-next-line
const description = (
  <span>
    Please provide the name of your store (you can find it inside your URL as pictured below) and
    click Connect. You’ll be redirected to your Shopify store and prompted to install the Gooten App
    plugin.
  </span>
);

class ShopifyConnect extends React.Component {
  static propTypes = {
    init: PropTypes.func.isRequired,
    state: PropTypes.object,
    location: PropTypes.object,
    connectStore: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    analyticsService._track(
      'Hub - Integrations',
      'Accessed',
      'Shopify',
      '/shopify/connect-store',
      null
    );
    this.state = { notification: null };
  }

  closeNotification() {
    this.setState({ notification: null });
  }

  componentDidMount() {
    if (this.props.location.query.shop && !this.props.state.errorMsg) {
      this.props.connectStore(this.props.location.query.shop);
    }
    if (!this.props.state.initData.shopify) {
      // this.setState({ notification: { text : betaNotification, type: 'success', icon: 'error-icon' } })
      this.props.init('shopify');
    }
  }

  render() {
    const predefinedShop = this.props.state.initData.shopify
      ? this.props.location.query.shop || this.props.state.initData.shopify.storeNameFromSignup
      : null;
    const inputAddon = <div className="input-group-addon">.myshopify.com</div>;
    return (
      <div className="shopify-connect-container">
        <Notifications
          notification={this.state.notification}
          dismiss={() => this.closeNotification()}
          withBackDrop
        />
        {!this.props.state.initData.shopify ? (
          <Loader />
        ) : (
          <StoreConnect
            predefinedStoreName={predefinedShop}
            storeProvider="Shopify"
            description={description}
            inputAddon={inputAddon}
            inputLabel="Enter Your Shopify Store Address"
            inputPlaceholder="myperfectstore"
            connectStore={storeName =>
              !this.props.state.errorMsg && this.props.connectStore(storeName)
            }
          >
            <div className="flex-container border-block-img-shopify-container">
              <div className="border-block-img" />
            </div>
          </StoreConnect>
        )}
      </div>
    );
  }
}

export default ShopifyConnect;
