import { List } from 'immutable';
import { fetch } from '../../../utils/http';
import Config from '../../../config';
import { mapCountry } from '../../../models/Country';

class CountrySelectionService {
  loadCountries(recipeId, apiUrl) {
    // url
    const url = `${apiUrl || Config.get('api')}countries/`;

    // request params
    const queryParams = {
      ...this.getAuthQueryParams(recipeId)
    };

    // do fetch call
    return fetch(url, { queryParams }).then(res => {
      return new List(
        // Use ShippingCountry model to map each item of PagedCollection
        res.Countries.map(mapCountry)
      );
    });
  }

  // recipeId, partnerBillingKey auth params
  getAuthQueryParams(recipeId) {
    return {
      recipeId: recipeId || Config.get('recipeId')
    };
  }
}

export default new CountrySelectionService();
