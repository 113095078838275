import React from 'react';
import { COLORS } from '../../constants.js';
import PropTypes from 'prop-types';
import Icon from './Icon.js';

const ErrorField = ({ className, children }) => {
  return (
    <div className={`ErrorField-container ${className || ''}`}>
      <div className="error-field caption-text">
        <Icon small icon="alertCircle" />
        {children}
      </div>

      <style jsx>
        {`
          .error-field {
            color: ${COLORS.cayennePepper};
            display: flex;
            align-items: center;
            fill: ${COLORS.cayennePepper};
            font-size: 0.8125rem;
            line-height: 0.8125rem;
          }
          .error-field :global(svg) {
            margin-right: 0.25rem;
          }
        `}
      </style>
    </div>
  );
};

ErrorField.propTypes = {
  children: PropTypes.any
};

export default ErrorField;
