import Config from '../../../config';
import { postJson, fetch } from 'gooten-components/src/utils/http';

export const getPackingSlipsApiUrl = () =>
  `${Config.get('api')}RecipeAddons?recipeid=${Config.get('recipeId')}&addonsType=1`;

export const postRecipeAddOns = async data => {
  new Promise((resolve, reject) => {
    postJson(`${getPackingSlipsApiUrl()}`, data)
      .then(res => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => reject(err));
  });
};

export const getRecipeAddOns = async () => {
  return new Promise((resolve, reject) =>
    fetch(`${getPackingSlipsApiUrl()}`)
      .then(res => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => reject(err))
  );
};
