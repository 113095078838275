// @flow
import ImageLoader from './utils/imageLoader';
import CanvasSurface from './components/canvas/canvasSurface';
import ImagePreviewOptions from './imagePreview.options';
import PositioningService from './utils/positioningService';
import ImgManipService from './services/data/imgmanip.service';

// internal max width/height of images loaded in canvas
// const MAX_IMAGE_WIDTH = 1000;
// const MAX_IMAGE_HEIGHT = 1000;
const UPDATE_OPTIONS = ['width', 'height', 'backgroundColor'];

export default class ImagePreviewControl {
  _destroyed: boolean;
  _options: ImagePreviewOptions;
  _container: any;
  _loadingSpinner: any;
  _surface: CanvasSurface;

  constructor(config) {
    // Init options
    this._options = new ImagePreviewOptions(config);
    // building DOM
    this._container = document.querySelector(this._options.container);
    let canvasContainer = document.createElement('div');
    this._options.domContainer = this._container;
    canvasContainer.className += ' preview-canvas';
    this._container.appendChild(canvasContainer);

    this._loadingSpinner = document.createElement('span');
    this._loadingSpinner.className = 'loading-spinner';
    this._container.appendChild(this._loadingSpinner);

    // Init canvas
    // Render canvas
    this._surface = new CanvasSurface(
      (this._options.maximized ? this._options.maximizedWidth : this._options.width),
      (this._options.maximized ? this._options.maximizedHeight : this._options.height),
      canvasContainer
    );
    this._surface.setOrientation(config.changeOrientation ? 'portrait' : 'landscape');

    // Load images async: template and images
    this.thenReady = this._loadImagesAsync(this._options)
      .then(() => {
        if (this._destroyed) {
          return;
        }
        this._surface.redraw(this._options);
        this.hideLoadingSpinner();
      })
      .catch(err => {
        this._options.onError(err);
      });
  }

  showLoadingSpinner() {
    this._loadingSpinner.style.display = 'block';
  }

  hideLoadingSpinner() {
    this._loadingSpinner.style.display = 'none';
  }

  changeBackgroundColor(colorHex: string): void {
    this._options.setBackgroundColor(colorHex);
    this._surface.redraw(this._options);
  }

  update(props: Object): void {
    if (UPDATE_OPTIONS.find(o => this._options[o] !== props[o])) {
      this._options = Object.assign(this._options, props);
      this.thenReady.then(() => {
        if (this._destroyed) {
          return;
        }
        this.showLoadingSpinner();
        this._surface.redraw(this._options);
        this.hideLoadingSpinner();
      });
    }
  }

  toImageAsync(format) {
    return new Promise ((res, rej) => {
      this._surface.stage.toImage({
        mimeType : format || 'image/jpeg',
        callback : (img) => {
          res(img);
        }
      });
    });
  }

  toDataUrl(format, pixelRatio) {
    this._surface.stage.clearCache();
    this._surface.stage.draw();
    // missed in documentation, but exist in
    // https://github.com/konvajs/konva/blob/8d1fd1218f9063b4f61eb4c4760131caf6f7bce3/src/Stage.js#L263
    // https://konvajs.github.io/api/konva.js.html#line10003
    pixelRatio = pixelRatio || 1;
    return this._surface.stage.toDataURL({mimeType : format || 'image/jpeg', pixelRatio : pixelRatio});
  }

  // Not implemented
  changeBackgroundImage(image: File | string): void {
    throw new Error('Image preview error: changeBackgroundImage is not implemented');
    // Load image async
    // ImageLoader.loadImage(image)
    //   .then(res => {
    //     this._options.backgroundImageUrl = image;
    //     this._options.backgroundImage = res.image;
    //     this._surface.redraw(this._options);
    //   });
  }

  static exportImgManipCmd(config, bgColor, previewSize, changeOrientation) {
    return ImgManipService.exportImgManipCmd(config, bgColor, previewSize, changeOrientation);
  }

  static exportSmallPrintImgManipCmd(config, previewSize, changeOrientation) {
    return ImgManipService.exportSmallPrintImgManipCmd(config, previewSize, changeOrientation);
  }

  destroy() {
    this._destroyed = true;
    this._surface.destroy();
    this._container.innerHTML = '';
    this._container.setAttribute('style', '');
  }

  _loadImagesAsync(options: any): Promise {
    let promises = [];

    // load template images
    // Skip if it's not product preview
    if (this._options.preview === 'product') {
      for (let layer of options.template.layers) {
        if (!layer.imageurl) continue;

        promises.push(
          ImageLoader.loadImage(layer.imageurl)
            .then((res) => {
              layer.image = res.image;
              // Serg: Commented out upon perf optimization
              // as redundant step
              // resize to width and height
              // with which preview is operated
              // layer.image = ImageLoader.resizeImage(
              //   res.image,
              //   res.orientation,
              //   MAX_IMAGE_WIDTH,
              //   MAX_IMAGE_HEIGHT
              // );
            })
        );
      }
    }

    // load images
    for (let img of options.images) {
      if (!img.src) continue;

      let imageUrl = img.src;
      // try to get url from images sources first
      // url can be clarified there with additional parameters
      const imageSource = options.imageSources
        ? options.imageSources.find((s) => s.layerId ===  img.layerId)
        : null;

      if (imageSource) {
        imageUrl = imageSource.imageUrl;
      }

      promises.push(
        ImageLoader.loadImage(imageUrl)
          .then(res => {
            // add real sizes
            // try to get from provided source of images or take from loaded images
            img.realSourceWidth = imageSource ? imageSource.width : res.image.naturalWidth;
            img.realSourceHeight = imageSource ? imageSource.height : res.image.naturalHeight;
            if (res.orientation > 4) {
              // exif orientation - swap sizes
              [img.realSourceWidth, img.realSourceHeight] = [img.realSourceHeight, img.realSourceWidth];
            }

            img.image = res.image;
            // Serg: Commented out upon perf optimization
            // as redundant step
            // img.image = ImageLoader.resizeImage(
            //   res.image,
            //   res.orientation,
            //   MAX_IMAGE_WIDTH,
            //   MAX_IMAGE_HEIGHT
            // );

            // add resized to max editor(1000x1000) sizes
            img.sourceWidth = img.image.width;
            img.sourceHeight = img.image.height;
          })
      );
    }

    return Promise.all(promises).then(() => {
      // Update template with real height/width and scale
      PositioningService.initTemplatePosition(
        options.template,
        this._surface.width,
        this._surface.height
      );
    });
  }
}
