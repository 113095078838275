import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'immutable';
import ImageItem, { getImageType } from './ImageItem';
import { windowWidth } from '../../../../ComponentsHooks';

const IMAGES_GRID_HEIGHT = {
  multiSpace: 475,
  single: 460,
  multiSpaceMobile: 300,
  singleMobile: 360,
  embroidery: 400
};

export const imagesGridHeight = (isMobile, isMultiSpace, isEmbroidery) =>
  isEmbroidery
    ? IMAGES_GRID_HEIGHT.embroidery
    : isMobile
    ? isMultiSpace
      ? IMAGES_GRID_HEIGHT.multiSpaceMobile
      : IMAGES_GRID_HEIGHT.singleMobile
    : isMultiSpace
    ? IMAGES_GRID_HEIGHT.multiSpace
    : IMAGES_GRID_HEIGHT.single;

const Container = styled.div`
  height: ${props => props.height}px;
  overflow: auto;

  .infinite-scroll-component {
    overflow-x: visible;

    .loading {
      width: 100%;
      text-align: center;
    }
  }
`;

const ImagesGrid = props => {
  const width = windowWidth();
  const height = imagesGridHeight(width <= 480, props.isMultiSpace, props.isEmbroidery);

  const loadingImages = [];
  let count = props.loadingImagesCount;
  while (count--) {
    loadingImages.push(
      <ImageItem
        key={count}
        customClass={
          props.threeColumns ? 'col-md-4 col-sm-6 col-xs-6' : 'col-md-3 col-sm-6 col-xs-6'
        }
      />
    );
  }

  // if image type is EMB or DST, and flow (selected product) is not embroidery product,
  // instead of EMB and DST file types, show PNG badge
  const imageTypeBasedOnFlow = type => {
    switch (type) {
      case 'dst':
      case 'emb':
        return !props.isEmbroidery ? 'png' : type;

      default:
        return type;
    }
  };

  const items = loadingImages.concat(
    props.images
      .map((e, i) => {
        return (
          <ImageItem
            key={i + props.loadingImagesCount}
            number={i}
            imageRes={`${e.get('width')} x ${e.get('height')} px`}
            imageUrl={e.get('previewUrl')}
            imageType={imageTypeBasedOnFlow(e.get('imageType'))}
            url={e.get('fullSizeUrl')}
            customClass={
              props.threeColumns ? 'col-md-4 col-sm-6 col-xs-6' : 'col-md-3 col-sm-6 col-xs-6'
            }
            selected={
              width < 992 && !!props.spaces.find(s => s.getIn(['image', 'id']) === e.get('id'))
            }
            isMobile={width < 992}
            disabled={
              props.isEmbroidery &&
              !props.requiredImageSizeData?.some(
                size => e.get('width') === size.width && e.get('height') === size.height
              )
            }
            handleSelect={() => props.handleSelect(e.toJS())}
            handleDelete={() => props.handleDelete(e.toJS())}
          />
        );
      })
      .toJS()
  );

  return (
    <Container className="images-grid row" height={height}>
      <InfiniteScroll
        next={a => props.onLoadMore()}
        dataLength={items ? items.length : 0}
        hasMore={props.hasMore}
        height={height}
        loader={<h4 className="loading">Loading...</h4>}
      >
        {items}
      </InfiniteScroll>
    </Container>
  );
};

ImagesGrid.propTypes = {
  images: PropTypes.instanceOf(List).isRequired,
  loadingImagesCount: PropTypes.number,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  threeColumns: PropTypes.bool,
  handleSelect: PropTypes.func,
  handleDelete: PropTypes.func,
  isMultiSpace: PropTypes.bool,
  spaces: PropTypes.instanceOf(List).isRequired,
  isEmbroidery: PropTypes.bool,
  requiredImageSizeData: PropTypes.array
};

export default ImagesGrid;
