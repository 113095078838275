import CanvasImageElement from '../canvas/canvasImageElement';
import CanvasLayer from '../canvas/canvasLayer';
import ImageService from './image.service';
import { SurfaceLayer, KonvaStage } from '../../flow/konvaTypes';

export default class ImageSurfaceLayer implements SurfaceLayer {

  id: string;
  layer: CanvasLayer;

  constructor(id: string) {
    this.id = id;
    this.layer = new CanvasLayer({ id });
  }

  addToCanvas(stage: KonvaStage) {
    this.layer.addToStage(stage);
  }

  draw(ctx: any) {
    this.layer.clear();

    const imagesCanvasStates = ImageService.getImagesCanvasStates(ctx.state);

    for(let imageState of imagesCanvasStates) {
      if (!imageState.hidden) {
        let imageElement = new CanvasImageElement(imageState);
        this.layer.addElement(imageElement);
      }
    }

    this.layer.draw();

    // NOTE: All immediate image updates happens only here
    ctx.events.on('imageUpdate', eventArgs => {
      // Do immediate image update
      const imageElement = this.layer.getElement(eventArgs.imageId);
      imageElement.update(eventArgs);
      this.layer.draw();
    });
  }
};
