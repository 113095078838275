import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, fromJS } from 'immutable';
import { roundStringPrice } from '../../../../../../utils/price';
import ProductPricingItem from './ProductPricingItem';
// import RoundButton from '../../../../../shared/RoundButton'
import OptionsModal from '../../../../../shared/OptionsModal';
import Icon from '../../../../../shared/Icon';
import Checkbox from '../../../../../shared/Checkbox';
import Button from '../../../../../shared/Button';
import { TableRow, Cell } from '../../../../../shared/Table';

const ProductPricingFullscreen = props => {
  const [showModal, setShowModal] = useState(false);
  const [variants, setVariants] = useState(props.priceData.get('variants'));
  const [isMultipleMarginLocked, lockMultipleMargin] = useState(true);
  const [isMultipleCustomerPriceLocked, lockMultipleCustomerPrice] = useState(true);

  useEffect(() => {
    // on mount set prices from parent
    setVariants(props.priceData.get('variants'));
  }, []);

  useEffect(() => {
    // update selection states
    const newVariants = [...variants.toJS()]; // clone variants
    props.priceData.get('variants').forEach((v, i) => {
      newVariants[i].priceSelected = v.get('priceSelected');
    });
    setVariants(fromJS(newVariants));
  }, [props.priceData]);

  const handleSelectModalOption = option => {
    props.selectPrices(props.storeId, option);
  };

  const handleSelectItem = (index, set) => {
    props.selectPriceIndex(props.storeId, index, set);
  };

  const selectedIndexes = props.priceData
    .get('variants')
    .map((v, i) => (v.get('priceSelected') ? i : -1))
    .filter(ind => ind !== -1);

  const handleCloseModal = () => {
    props.onClose();
    props.selectPrices(props.storeId, 'none');
  };

  const onInput = (index, type, value) => {
    const val = (value + '').replace(/\$/g, '');
    if (selectedIndexes.includes(index)) {
      const newVariants = [...variants.toJS()]; // clone variants
      selectedIndexes.forEach(ind => {
        newVariants[ind][type] = val;
      });
      setVariants(fromJS(newVariants));
      type === 'customerPrice' ? lockMultipleCustomerPrice(false) : lockMultipleMargin(false);
    } else {
      setVariants(variants.setIn([index, type], val));
    }
  };

  const onMarginChange = (index, value) => {
    let val = roundStringPrice(value) || 0;
    val = val < 0 ? 0 : val;
    if (selectedIndexes.includes(index) && !isMultipleMarginLocked) {
      const newVariants = [...variants.toJS()]; // clone variants
      selectedIndexes.forEach(ind => {
        newVariants[ind].customerPrice = (newVariants[ind].maxCost + val).toFixed(2);
        newVariants[ind].margin = val.toFixed(2);
      });
      setVariants(fromJS(newVariants));
      lockMultipleMargin(true);
    } else {
      const variant = variants.get(index);
      setVariants(
        variants
          .setIn([index, 'customerPrice'], (variant.get('maxCost') + val).toFixed(2))
          .setIn([index, 'margin'], val.toFixed(2))
      );
    }
  };

  const onPriceChange = (index, value) => {
    let val = roundStringPrice(value);
    if (selectedIndexes.includes(index) && !isMultipleCustomerPriceLocked) {
      const newVariants = [...variants.toJS()]; // clone variants
      selectedIndexes.forEach(ind => {
        const newVal = isNaN(val)
          ? newVariants[ind].maxCost
          : val < newVariants[ind].maxCost
          ? newVariants[ind].maxCost
          : val;
        newVariants[ind].margin = (newVal - newVariants[ind].maxCost).toFixed(2);
        newVariants[ind].customerPrice = newVal.toFixed(2);
      });
      setVariants(fromJS(newVariants));
      lockMultipleCustomerPrice(true);
    } else {
      const variant = variants.get(index);
      val = isNaN(val)
        ? variant.get('maxCost')
        : val < variant.get('maxCost')
        ? variant.get('maxCost')
        : val;
      setVariants(
        variants
          .setIn([index, 'margin'], (val - variant.get('maxCost')).toFixed(2))
          .setIn([index, 'customerPrice'], val.toFixed(2))
      );
    }
  };

  const handleSave = () => {
    variants.forEach(v => {
      props.onChange(props.storeId, v.toJS());
    });
    props.onClose();
  };

  const modalOptions = () => ['All', 'None', 'Inverse'];

  // const renderSearchAndFilters = () =>
  //   <div className='search-and-filters flex-container'>
  //     {/* <div className='pricing-search'>Search
  //     </div> */}
  //     <span className='filters'>FILTERS</span>
  //     <RoundButton title='COST' />
  //   </div>

  const renderHead = () => (
    <TableRow
      header
      className="product-pricing-item"
      columns="3rem minmax(15rem, calc(100% - 28rem)) 8rem 8rem 8rem"
    >
      <Cell className="d-flex">
        <div className="sticky-checkbox d-flex ml-3">
          <Checkbox
            onChange={
              props.numberOfSelectedPrices > 0
                ? () => props.selectPrices(props.storeId, 'none')
                : () => props.selectPrices(props.storeId, 'all')
            }
            className="mt-1"
            checked={props.numberOfSelectedPrices > 0}
            partial={props.numberOfSelectedPrices !== variants.size}
          />
          <Button
            className="button-white small ml-1 mt-1"
            iconOnly
            onClick={() => setShowModal(true)}
          >
            <Icon icon="chevronDown" />
          </Button>
        </div>
      </Cell>
      {props.isAnyPriceSelected ? (
        <Cell>
          <span>{`Editing ${props.numberOfSelectedPrices} price${
            props.numberOfSelectedPrices > 1 ? 's' : ''
          } as a group`}</span>
        </Cell>
      ) : (
        <React.Fragment>
          <Cell>
            <span className="header-title">SKU Name</span>
          </Cell>
          <Cell>
            <span className="header-title">Cost</span>
          </Cell>
          <Cell>
            <span className="header-title">Profit</span>
          </Cell>
          <Cell>
            <span className="header-title">Retail Price</span>
          </Cell>
        </React.Fragment>
      )}
    </TableRow>
  );

  const renderBody = () => {
    return variants.map((v, i) => (
      <ProductPricingItem
        skuName={v.get('sku')}
        key={i}
        variant={v}
        index={i}
        handleSelectItem={() => handleSelectItem(i, !v.get('priceSelected'))}
        onInput={onInput}
        onMarginChange={onMarginChange}
        onPriceChange={onPriceChange}
      />
    ));
  };

  return (
    <div className="product-publish-pricing-fullscreen flex-container pt-3 pb-5 pr-4 pl-4">
      <div className="pricing-fullscreen-content flex-container">
        {/* {renderSearchAndFilters()} */}
        <div className="table-holder">
          {renderHead()}
          {renderBody()}
        </div>
        {showModal ? (
          <OptionsModal
            items={modalOptions()}
            onClickItem={handleSelectModalOption}
            onDismiss={() => setShowModal(false)}
          />
        ) : null}
      </div>
      <div className="d-flex bottom-buttons p-4">
        <Button className="button-default large mr-2" onClick={() => handleCloseModal()}>
          Cancel
        </Button>
        <Button className="button-primary large" onClick={() => handleSave()}>
          Save
        </Button>
      </div>
      <style jsx>
        {`
          .product-publish-pricing-fullscreen {
            flex-direction: column;
            margin-bottom: 4.5rem;
          }
          .pricing-fullscreen-content {
            flex-direction: column;
            position: relative;
          }
          .table-holder {
            min-height: 300px;
            overflow-x: auto;
          }
          .bottom-buttons {
            justify-content: flex-end;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            background: white;
            border-top: 1px solid #ddd;
            z-index: 100;
          }
          :global(.sticky-checkbox) {
            position: absolute;
            left: 0;
          }
          :global(.product-publish-pricing-fullscreen .options-modal) {
            top: 3rem;
            left: 0.5rem;
          }
        `}
      </style>
    </div>
  );
};

ProductPricingFullscreen.propTypes = {
  priceData: PropTypes.instanceOf(Map),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  selectPriceIndex: PropTypes.func,
  selectPrices: PropTypes.func,
  // eslint-disable-next-line
  isAnyPriceSelected: PropTypes.bool,
  // eslint-disable-next-line
  numberOfSelectedPrices: PropTypes.number,
  storeId: PropTypes.number
};

export default ProductPricingFullscreen;
