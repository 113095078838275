import React, { useState } from 'react';
import PageBody from 'gooten-components/src/components/shared/PageBody';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import { Tab, TabContainer } from 'gooten-components/src/components/shared/Tab';
import HistoryView from './Components/HistoryView/index';
import PublishView from './Components/PublishView/index';

export const StoreBulkMigrationView = () => {
  const tabs = ['Publish', 'History'];
  const [tabView, setTabView] = useState('Publish');

  return (
    <React.Fragment>
      <PageHeader title={`Store Bulk Migration`}></PageHeader>
      <PageBody size="full">
        <TabContainer className="ml-n2">
          {tabs.map((tab, key) => (
            <Tab active={tab === tabView} onClick={() => setTabView(tab)} key={key}>
              {tab}
            </Tab>
          ))}
        </TabContainer>
        <div className="container-fluid setting-nav-bar dropshadow-1">
          <div className="mt-3">
            {tabView == 'Publish' && <PublishView />}
            {tabView == 'History' && <HistoryView />}
          </div>
        </div>
      </PageBody>
    </React.Fragment>
  );
};

export default StoreBulkMigrationView;
