import { connect } from 'react-redux';
import ProductsFiltering from './ProductsFiltering';
import * as Actions from './ProductsFilteringActions';
import { searchSelector, viewBySelector } from '../../ProductSelectionViewSelectors';
import { productsViewByOptionsSelector } from '../../../LinkProductSelectors';

const mapDispatchToProps = {
  searchChanged: search => {
    return Actions.searchChanged(search);
  },
  viewByChanged: viewBy => {
    return Actions.viewByChanged(viewBy);
  }
};

const mapStateToProps = state => {
  // Do all state data mapping only inside selectors
  // const search = Selectors.search(state)
  return {
    search: searchSelector(state),
    viewByOptions: productsViewByOptionsSelector(state),
    viewBy: viewBySelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFiltering);
