import { ActionNameFactory } from '../../../../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('IMAGE_SELECTION_MODAL', 'MY_ARTWORK');

// ------------------------------------
// Constants
// ------------------------------------
export const SET_FILTER = getActionName('SET_FILTER');
export const SET_SORT = getActionName('SET_SORT');
export const SELECT_SPACE = getActionName('SELECT_SPACE');
export const SET_SPACES = getActionName('SET_SPACES');
export const SELECT_IMAGE = getActionName('SELECT_IMAGE');

export const setFilter = payload => ({
  type: SET_FILTER,
  payload
});

export const setSort = payload => ({
  type: SET_SORT,
  payload
});

export const selectSpace = payload => ({
  type: SELECT_SPACE,
  payload
});

export const setSpaces = payload => ({
  type: SET_SPACES,
  payload
});

export const selectImage = payload => ({
  type: SELECT_IMAGE,
  payload
});
