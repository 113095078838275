import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './MainView.scss';

import onboardingData from '../store/data';

import ResourceCard from '../shared/ResourceCard';
import ReferenceCard from '../shared/ReferenceCard';
import ActionCard from '../shared/ActionCard';
import FormCard from '../shared/FormCard';

import Config from '../../config';

import WelcomeHeader from '../shared/WelcomeHeader';

import ServiceWrapper from '../shared/services/ServiceWrapper';
import analyticsService from 'gooten-components/src/services/analyticsService';
import storageService from 'gooten-components/src/services/storageService';

import Loader from 'gooten-components/src/components/shared/Loader';
import FirstOrderBanner from '../shared/FirstOrderBanner';
import OrderSummary from '../shared/OrderSummary';
// import BlogList from '../shared/BlogList';
import PageBody from 'gooten-components/src/components/shared/PageBody';
import ProductionReport from '../../ProductionReport';
import paymentMethodsService from 'gooten-components/src/components/Checkout/atoms/Payment/services/paymentMethodsService';
import SecurityService from '../../Settings/SecurityService';

class MainView extends React.Component {
  componentDidMount() {
    analyticsService._track(
      'Onboarding Dashboard - Main',
      'Accessed',
      'Dashboard',
      '/onboardingdashboard',
      null
    );
  }

  render() {
    return (
      <div className="onboarding-dashboard-view-container">
        <CardsContainer data={onboardingData} {...this.props} />
      </div>
    );
  }
}

class CardsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: undefined,
      products: undefined,
      billing: undefined,
      orders: undefined,
      showFirstOrder: false,
      isOnCredit: undefined
    };
  }

  componentDidMount() {
    // Get connected store count
    // If at least one exists we will collapse the 'connect your shop' card
    ServiceWrapper.getUserStores().then(resp => {
      this.setState({ stores: resp.stores.length > 0 });
    });

    // Get products
    // If at least one exists we will collapse the 'create a product' card
    ServiceWrapper.getAllProducts().then(resp => {
      this.setState({ products: resp.products.length > 0 });
    });

    // Digits in BraintreeCardLastFour indicate billing setup was completed
    // If that is the case, the 'billing' card will be collapsed
    ServiceWrapper.getPaymentSettings().then(resp => {
      this.setState({
        billing: !!(resp.PaymentMethods && resp.PaymentMethods.length)
      });
    });

    // Get past orders
    // If at least one exists we will collapse the 'order a sample' card
    ServiceWrapper.getPastOrders().then(resp => {
      this.setState({ orders: resp.OrderItems.length > 0 });
    });

    // Get Order Stats (order counts, issue status, etc.)
    this.props.fetchOrderStats();

    // Display production report
    this.props.initProductionReport();

    // Get isOnCreditInfo
    paymentMethodsService.getPaymentSettings().then(resp => {
      this.setState({ isOnCredit: resp.IsOnCredit });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.ordersCountTotal !== prevProps.ordersCountTotal) {
      // Show First Order banner if one order is placed, and banner hasn't been viewed before
      let firstOrderPlaced = this.props.ordersCountTotal === 1;
      let firstOrderViewed = storageService.getItem(
        'firstOrderNotificationViewed_' + Config.get('partnerId')
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showFirstOrder: firstOrderPlaced && !firstOrderViewed });
    }
  }

  handleAnalytics = (category, action, val, obj = {}) => {
    analyticsService._track('Home', action, category, val, obj);
  };

  showLoader() {
    return (
      this.state.stores === undefined ||
      this.state.products === undefined ||
      this.state.billing === undefined ||
      this.state.orders === undefined
    );
  }

  hideBanner = () => {
    this.setState({ showFirstOrder: false });
    storageService.setItem('firstOrderNotificationViewed_' + Config.get('partnerId'), true);
  };

  render() {
    // determine which stores to connect to and which action/resource/form cards to display
    let integrationConnect = false;
    let integrationConnectType = ['all'];

    if (!Config.get('hideShopifyConnect')) {
      integrationConnect = true;
      integrationConnectType.push('shopify');
    }

    if (!Config.get('hideEtsyConnect')) {
      integrationConnect = true;
      integrationConnectType.push('etsy');
    }

    // identify OLM partners
    let isOLMPartner = false;

    if (Config.get('isOLMPartner')) {
      isOLMPartner = true;
    }

    const data = this.props.data;

    // eslint-disable-next-line max-len
    const cards = data.cards
      .filter(card => integrationConnectType.includes(card.integration))
      .sort((a, b) => a.index - b.index);

    var cardColumns = { left: [], right: [] };

    // determine if user completed onboarding based on integration type
    // Shopify & Etsy partner completed steps: billing + orders + products + stores
    // COF & Dev partner completed steps: billing + orders + products
    // eslint-disable-next-line max-len
    const onboardingCompleted =
      (this.state.isOnCredit && this.state.orders) ||
      (integrationConnect
        ? this.state.stores && this.state.products && this.state.billing && this.state.orders
        : this.state.products && this.state.billing && this.state.orders);
    // action/resource/form cards
    for (var i = 0; i < cards.length; i++) {
      cards[i].expired = new Date() > new Date(cards[i].expiration);

      // Cards with negative index are excluded
      // Cards with no type are excluded
      // Expired Cards are excluded
      if (cards[i].index < 0 || !cards[i].type || cards[i].expired) {
        continue;
      }

      switch (cards[i].type.toLowerCase()) {
        case 'formcard':
          // Show connectToStore card for selected integration(s)
          if (integrationConnect) {
            cardColumns.left.push(
              <FormCard
                data={cards[i]}
                {...this.state}
                id={i}
                handleAnalytics={this.handleAnalytics}
                key={'FormCard-' + i}
              />
            );
          }
          break;
        case 'resourcecard':
          // Show card if it does not monitor anything or if monitored condition is true
          if (!cards[i].monitors || this.state[cards[i].monitors]) {
            cardColumns.right.push(
              <ResourceCard
                data={cards[i]}
                id={i}
                handleAnalytics={this.handleAnalytics}
                key={'ResourceCard-' + i}
              />
            );
          }
          break;
        case 'referencecard':
          // Show card if it does not monitor anything or if monitored condition is true
          if (!cards[i].monitors || this.state[cards[i].monitors]) {
            cardColumns.left.push(
              <ReferenceCard
                data={cards[i]}
                id={i}
                handleAnalytics={this.handleAnalytics}
                key={'ReferenceCard-' + i}
                integrations={integrationConnectType}
              />
            );
          }
          break;
        case 'actioncard':
          cardColumns.left.push(
            <ActionCard
              data={cards[i]}
              {...this.state}
              id={i}
              handleAnalytics={this.handleAnalytics}
              key={'ActionCard-' + i}
            />
          );
          break;
      }
    }

    const OLMResourceCard = (
      <ResourceCard
        data={cards.filter(c => c.type === 'OLMResourceCard')}
        id={111}
        handleAnalytics={this.handleAnalytics}
        key={'ResourceCard-OLMResourceCard'}
      />
    );

    if (this.showLoader()) {
      return <Loader />;
    } else {
      return (
        <Fragment>
          {/* eslint-disable max-len */}
          {this.state.showFirstOrder && (
            <FirstOrderBanner hideBanner={this.hideBanner} userName={Config.get('adminUserName')} />
          )}
          <WelcomeHeader
            userName={Config.get('adminUserName')}
            onboardingCompleted={onboardingCompleted}
            isOLMPartner={isOLMPartner}
          />
          <PageBody className="flex-container flex-wrap">
            <div className="left-flex-container">
              {isOLMPartner ? (
                <Fragment>
                  {<OrderSummary {...this.props} handleAnalytics={this.handleAnalytics} />}
                  <div className="olm-video-container">
                    <iframe
                      src="https://player.vimeo.com/video/350843471"
                      width="640"
                      height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {/* when onboarding steps are completed, show 'Orders Summary' and hide onboarding cards */}
                  {onboardingCompleted && (
                    <OrderSummary {...this.props} handleAnalytics={this.handleAnalytics} />
                  )}
                  {/* eslint-disable max-len */}
                  {/* Note: Hide onboarding dashboard flow from partner-admin parent accounts */}
                  {(SecurityService.isPartner() || SecurityService.isSuperAdmin()) && (
                    <>
                      {onboardingCompleted
                        ? cardColumns.left.filter(c => c.props.data.onboardingStep === false)
                        : cardColumns.left}
                    </>
                  )}
                  <ProductionReport />
                </Fragment>
              )}
            </div>
            <div className="right-flex-container">
              {isOLMPartner ? (
                <Fragment>{OLMResourceCard}</Fragment>
              ) : (
                <Fragment>{cardColumns.right}</Fragment>
              )}
            </div>
          </PageBody>
        </Fragment>
      );
    }
  }
}

CardsContainer.propTypes = {
  data: PropTypes.any,
  fetchOrderStats: PropTypes.func,
  ordersCountTotal: PropTypes.number.isRequired,
  initProductionReport: PropTypes.func
};

export default MainView;
