import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div``;

class PasswordByEmail extends React.Component {
  static get name() {
    return 'passwordByEmail';
  }

  get userName() {
    const user = this.props.users.find(u => u.get('Id').toString() === this.props.userId);
    return user ? user.get('FullName') : 'User';
  }

  render() {
    const userId = this.props.userId;
    return userId ? (
      <BaseCard card={PasswordByEmail.name} title={`${this.userName} Password`}>
        <Container>
          <Button
            className="button-primary large"
            disabled={!this.props.ready}
            onClick={() => this.props.sendEmail({ userId })}
          >
            Send password update email
          </Button>
        </Container>
      </BaseCard>
    ) : null;
  }
}

PasswordByEmail.propTypes = {
  ready: PropTypes.bool,
  userId: PropTypes.string,
  users: PropTypes.instanceOf(List),
  sendEmail: PropTypes.func.isRequired
};

export default PasswordByEmail;
