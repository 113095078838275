import React from 'react';
import PropTypes from 'prop-types';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';

class LogOutConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  openModal = () => {
    this.setState({
      isOpen: true
    });
  };

  hideModal = () => {
    this.setState({
      isOpen: false
    });
  };

  confirm = () => {
    this.hideModal();
    this.props.confirm();
  };

  render() {
    return (
      <Dialogue
        isOpen={this.state.isOpen}
        cancelText="CANCEL"
        actionText="LOGOUT"
        cancelClick={this.hideModal}
        destructiveClick={this.confirm}
        title={'Are you sure you want to log out from this store?'}
      />
    );
  }
}

LogOutConfirmationModal.propTypes = {
  confirm: PropTypes.func
};

export default LogOutConfirmationModal;
