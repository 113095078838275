import { connect } from 'react-redux';
import Search from './Search';
import { searchTextSelector } from 'gooten-components/src/components/ProductSelection/atoms/ProductList/EmptySearchResult/SearchSelectors';
import { searchChange } from './SearchActions';

const mapDispatchToProps = {
  doSearch: searchText => searchChange(searchText)
};

const mapStateToProps = (state, ownProps) => {
  return {
    searchText: searchTextSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
