import { connect } from 'react-redux';
import ProductVariants from './ProductVariants';
import {
  selectVariant,
  selectAllVariants,
  renameSkuStorage,
  renameSkuStore,
  saveSkuMockup,
  DELETE_PRODUCT,
  deleteVariants,
  addVariant
} from './ProductVariantsActions';
import {
  selectedVariantsSelector,
  storeIdSelector
} from '../../ProvidersTab/ProvidersTabSelectors';
import {
  filtersSelector,
  filterOptionsSelector,
  disabledSkusWithPreviewSelector
} from './ProductVariantsSelector';
import { mockupsSelector } from '../../Mockups/MockupsSelectors';
import { setError } from '../../../ProductPublishActions';
import Config from '../../../../../config';
import { List } from 'immutable';
import {
  serverErrorsSelector,
  areMockupsLoadingSelector,
  multiproductSelector
} from '../../../ProductPublishSelectors';
import { fetchAsync } from '../../../../../store/actions/dataActions';
import {
  skusWithNeckTagSelector,
  variantNeckLabelPreviewSelector
} from '../../../ProductPublishSelectors';
import { currentNeckLabelSelector } from '../../../atoms/NeckLabels/NeckLabelsSelectors';
const mapDispatchToProps = {
  setError: (storeId, section, message) => setError({ storeId, section, message }),
  selectVariant: sku => selectVariant(sku),
  deleteVariants: () => deleteVariants(),
  deleteProduct: () => fetchAsync(DELETE_PRODUCT),
  selectAllVariants: state => selectAllVariants(state),
  saveSkuMockup: (storage, storeId, skuMockupUrl, selectedSkuIndex) =>
    saveSkuMockup({ storage, storeId, skuMockupUrl, selectedSkuIndex }),
  renameSku: (storage, storeId, index, value) =>
    storage ? renameSkuStorage({ index, value }) : renameSkuStore({ storeId, index, value }),
  addVariant: () => addVariant()
};

const mapStateToProps = state => {
  const variants = selectedVariantsSelector(state);
  return {
    variants,
    disabledSkus: disabledSkusWithPreviewSelector(state),
    filters: Config.get('editMode') || Config.get('linkMode') ? new List() : filtersSelector(state),
    filterOptions:
      Config.get('editMode') || Config.get('linkMode') ? new List() : filterOptionsSelector(state),
    anyVariantSelected: variants.some(v => !!v.get('selected')),
    allVariantsSelected: variants.filter(v => !!v.get('selected')).size === variants.size,
    storeId: storeIdSelector(state),
    mockups: mockupsSelector(state),
    serverErrors: serverErrorsSelector(state), // errors returned from server
    isLoading: areMockupsLoadingSelector(state),
    isEditMode: Config.get('editMode'),
    isDuplicateMode: Config.get('duplicateMode'),
    isMultiproduct: multiproductSelector(state),
    skusWithNeckTag: skusWithNeckTagSelector(state),
    variantsInPublish: variantNeckLabelPreviewSelector(state),
    currentNeckLabel: currentNeckLabelSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariants);
