import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from './../Spinner';
import { letterPatern } from '../../../utils/regex';

import Input from '../Input';
import DropDown from '../DropDown';
import ListItem from '../ListItem';

class MultiselectInfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.optionsElements = [];
    this.state = {
      open: false,
      filter: '',
      position: this.getFirstAvailablePosition(props.options)
    };
  }

  componentDidMount() {
    const component = this;

    // TODO: Re-use closeOnClickOutsideManager
    this.outsideClickListener = event => {
      if (component.wrapper && !component.wrapper.contains(event.target) && component.state.open) {
        component.toggleDropdown();
      }
    };

    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('click', this.outsideClickListener);
    } else {
      document.addEventListener('click', this.outsideClickListener);
    }
  }

  componentWillUnmount() {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('click', this.outsideClickListener);
    } else {
      document.removeEventListener('click', this.outsideClickListener);
    }
  }

  toggleDropdown() {
    if (this.props.dropdownOpen) {
      this.props.dropdownOpen(!this.state.open);
    }
    this.setState({
      open: !this.state.open,
      filter: this.props.initialValue
    });
  }

  filterOptions(filter) {
    if (!this.props.filterLocally) {
      this.props.onFilterText(filter);
    }
    document.getElementsByClassName('infinite-scroll-component')[0].scrollTop = 0;
    this.setState({ filter, position: this.getFirstAvailablePosition() });
  }

  onKeyPressed(e) {
    let position = this.state.position;
    let index = this.getAvailablePositions().indexOf(position);

    switch (e.key) {
      case 'ArrowUp':
        if (index > 0) {
          e.preventDefault();
          position = this.getAvailablePositions()[--index];
          this.setState({ position });
          this.checkScroll(position);
        }
        break;
      case 'ArrowDown':
        if (index < this.getAvailablePositions().length - 1) {
          e.preventDefault();
          position = this.getAvailablePositions()[++index];
          this.setState({ position });
          this.checkScroll(position);
        }
        break;
      case 'Enter':
        this.check(this.getFilteredOptions()[position]);
        break;
    }
  }

  getFilteredOptions() {
    const filterStr = this.state.filter
      ? this.state.filter.toLocaleLowerCase().replace(letterPatern, '')
      : null;
    return this.props.options.filter(
      o =>
        !filterStr ||
        (o.label ? o.label.toLocaleLowerCase().replace(letterPatern, '').indexOf(filterStr) : 0) >
          -1 ||
        o.isSection
    );
  }

  getAvailablePositions(options) {
    return (
      options ||
      this.getFilteredOptions()
        .map((o, i) => ({ isSection: o.isSection, position: i }))
        .filter(o => !o.isSection)
        .map(o => o.position)
    );
  }

  getFirstAvailablePosition(options) {
    return this.getAvailablePositions(options).length ? this.getAvailablePositions(options)[0] : 0;
  }

  checkScroll(position) {
    const element = this.optionsElements[position];
    const scrollContainer = document.getElementsByClassName('infinite-scroll-component')[0];
    const scrollPosition = scrollContainer.scrollTop;
    const elementOffset = element.offsetTop;

    if (elementOffset - scrollPosition > 200) {
      scrollContainer.scrollTop = elementOffset - 60;
    } else if (elementOffset < scrollPosition + element.offsetHeight) {
      scrollContainer.scrollTop = elementOffset - 60;
    }
  }

  check(option) {
    if (!option) {
      return;
    }
    this.toggleDropdown();
    this.props.onChange([option]);
  }

  render() {
    const showFilter = this.props.config && this.props.config.showFilter;

    const filteredOptions = this.props.filterLocally
      ? this.getFilteredOptions()
      : this.props.options;

    const options = filteredOptions.map((o, i) => {
      const onClick = o.isSection ? () => null : () => this.check(o);

      return (
        <div
          key={i}
          title={o.label}
          ref={e => {
            this.optionsElements[i] = e;
          }}
        >
          <ListItem
            className="dropdown-item"
            active={this.state.position === i}
            onSelect={onClick}
            thumbnail={!o.isSection ? o.imageUrl : null}
            text={o.label}
          />
        </div>
      );
    });

    const dropDown = this.state.open ? (
      <div className="dropdown-menu-container" tabIndex="0" onKeyDown={e => this.onKeyPressed(e)}>
        {showFilter ? (
          <div className="filter-item mb-1 mt-1">
            <Input
              type="text"
              icon="search"
              noLabel
              iconPlacement="trailing"
              autoFocus="autofocus"
              value={this.state.filter}
              placeholder={this.props.placeholder}
              onChange={e => this.filterOptions(e.target.value)}
            />
          </div>
        ) : null}
        <InfiniteScroll
          dataLength={options.length}
          next={a => {
            this.props.onLoadMore();
          }}
          hasMore={this.props.hasMore}
          height={this.props.scrollHeight}
          loader={<div className="loading">Loading...</div>}
          endMessage={<div className="loading">{this.props.endMessage || 'No results found'}</div>}
        >
          {options}
        </InfiniteScroll>
        {this.props.showLoading ? (
          <div className="multiselect-loading">
            <Spinner />
          </div>
        ) : null}
      </div>
    ) : null;

    return (
      <div
        className="dropdown multiselect-infinite-scroll-component"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        ref={ref => {
          this.wrapper = ref;
        }}
      >
        <DropDown
          title={this.props.title}
          isOpen={!!dropDown}
          toggle={() => this.toggleDropdown()}
          id="dropdownMenuButton"
        >
          {dropDown}
        </DropDown>
        <style jsx>
          {`
            .multiselect-infinite-scroll-component :global(.infinite-scroll-component) {
              overflow-y: auto;
              margin: 0.25rem 0rem;
              padding: 0 0.5rem;
            }
            .multiselect-infinite-scroll-component :global(.dropdown-menu-container) {
              outline: none;
              margin: 0 -0.5rem;
            }
            :global(.infinite-scroll-component) {
              height: 20rem !important;
            }
            :global(.multiselect-loading) {
              height: calc(100% - 3.5rem);
              width: calc(100% - 1rem);
              position: absolute;
              top: 3.5rem;
              background: rgb(255, 255, 255, 0.6);
            }
          `}
        </style>
      </div>
    );
  }
}

MultiselectInfiniteScroll.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  endMessage: PropTypes.string,
  // elements, which have isSection set, and key < 0
  // are not clickable elements, and can be used as Section titles
  options: PropTypes.array.isRequired,
  dropdownOpen: PropTypes.func,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
  onFilterText: PropTypes.func.isRequired,
  filterLocally: PropTypes.bool,
  showLoading: PropTypes.bool,
  scrollHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired
};

export default MultiselectInfiniteScroll;
