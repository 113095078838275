/* eslint-disable prefer-promise-reject-errors */
export const getImageDimensions = (file, url) =>
  new Promise((resolve, reject) => {
    if (file || url) {
      const img = new Image();
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      img.onerror = function () {
        reject();
      };
      const _URL = window.URL || window.webkitURL;
      img.src = url || _URL.createObjectURL(file);
    } else {
      reject();
    }
  });
