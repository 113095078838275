import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  CHANGE_PRODUCT_OPTION,
  REMOVE_PRODUCT_OPTION,
  ADD_PRODUCT_OPTION
} from '../shared/ProductOptions/ProductOptionsActions';
import {
  ADD_COLLECTION,
  REMOVE_COLLECTION
} from '../shared/ProductCollection/ProductCollectionActions';
import { setProvider } from './ProvidersTabActions';
import { selectedProviderSelector } from './ProvidersTabSelectors';
import { publishDataSelector } from '../../ProductPublishSelectors';
import { ADD_TAG, REMOVE_TAG } from '../shared/ProductTags/ProductTagsActions';
import { CHANGE_STORE_FIELD, CHANGE_STORAGE_FIELD, DATA_READY } from '../../ProductPublishActions';
import {
  SELECT_VARIANT,
  RENAME_SKU_STORE,
  RENAME_SKU_STORAGE,
  UPDATE_SKU_PREVIEW,
  SELECT_ALL_VARIANTS
} from './../shared/ProductVariants/ProductVariantsActions';
import {
  CHANGE_STORE_PRICES,
  SELECT_STORE_PRICES,
  SELECT_STORE_PRICE_INDEX
} from '../shared/ProductPricing/ProductPricingActions';
import Config from '../../../../config';
import { STORE_UPDATE_PREVIEW_CHANGE } from '../Mockups/MockupsActions';
import { SELECT_NECKTAG } from '../NeckLabels/NeckLabelsActions';

export function* productChangesHandler() {
  const publishData = yield select(publishDataSelector);
  const selectedProvider = yield select(selectedProviderSelector);
  const storeId = selectedProvider && selectedProvider.get('id');
  let provider;
  if (!storeId) {
    provider = Config.get('linkMode')
      ? publishData.get('stores').find(s => s.get('selected'))
      : publishData.get('storage');
  } else {
    provider = publishData.get('stores').find(s => s.get('id') === storeId);
  }
  yield put(setProvider(provider));
}

export function* watchProductChanges() {
  yield takeLatest(
    [
      CHANGE_PRODUCT_OPTION,
      REMOVE_PRODUCT_OPTION,
      ADD_PRODUCT_OPTION,
      ADD_COLLECTION,
      REMOVE_COLLECTION,
      ADD_TAG,
      REMOVE_TAG,
      CHANGE_STORE_FIELD,
      CHANGE_STORAGE_FIELD,
      SELECT_VARIANT,
      CHANGE_STORE_PRICES,
      SELECT_STORE_PRICES,
      SELECT_STORE_PRICE_INDEX,
      RENAME_SKU_STORAGE,
      RENAME_SKU_STORE,
      DATA_READY,
      STORE_UPDATE_PREVIEW_CHANGE,
      UPDATE_SKU_PREVIEW,
      SELECT_NECKTAG,
      SELECT_ALL_VARIANTS
    ],
    productChangesHandler
  );
}

export default function* rootSaga() {
  yield all([watchProductChanges()]);
}
