// @flow

import type { Rect, Position, Point } from '../../../flow/types';
import ImageService from '../../image/image.service';
import PositioningService from '../../../utils/positioningService';
import { polyIntersect } from '../../../utils/math';

const MIN_CROP_WIDTH = 30;
const MIN_CROP_HEIGHT = 30;

export default class CropService {

  static cropState: any;

  /**
   * Return true if crop area rect is NOT overlapping with viewport
   * @param {Object} crop
   * @param {Object} state
   */
  static isCropOutsideOfViewport(crop: any, state: any): boolean {
    // form viewport polygon in template sizes
    const viewportRect = state.template.layers.find(
      l => l.id === state.images.current.selected.layerId);

    // creating polygon from viewport rect corners
    const viewportPolygon = [
      {x: viewportRect.x1, y: viewportRect.y1},
      {x: viewportRect.x2, y: viewportRect.y1},
      {x: viewportRect.x2, y: viewportRect.y2},
      {x: viewportRect.x1, y: viewportRect.y2}
    ];

    // form croparea polygon in template sizes
    const scale = state.images.current.images.find(
      i => i.id === state.images.current.selected.imageId &&
      i.layerId === state.images.current.selected.layerId
    ).scale;

    // crop.x, crop.y - is center of crop, rotating every corner of crop rect around center
    const rotatedTopLeft = PositioningService.getRotatedPoint(
      { x: crop.x - crop.width*scale/2, y: crop.y - crop.height*scale/2 },
      crop,
      -crop.rotation
    );
    const rotatedTopRight = PositioningService.getRotatedPoint(
      { x: crop.x + crop.width*scale/2, y: crop.y - crop.height*scale/2 },
      crop,
      -crop.rotation
    );
    const rotatedBottomRight = PositioningService.getRotatedPoint(
      { x: crop.x + crop.width*scale/2, y: crop.y + crop.height*scale/2 },
      crop,
      -crop.rotation
    );
    const rotatedBottomLeft = PositioningService.getRotatedPoint(
      { x: crop.x - crop.width*scale/2, y: crop.y + crop.height*scale/2 },
      crop,
      -crop.rotation
    );

    // creating polygon based on rotated crop corners
    const cropAreaPolygon = [
      rotatedTopLeft,
      rotatedTopRight,
      rotatedBottomRight,
      rotatedBottomLeft
    ];

    // check if they are collide
    return !polyIntersect(viewportPolygon, cropAreaPolygon);
  }

  static saveCropState(position: any, state: any) {
    let currImageState = ImageService.getSelectedImageCanvasState(state);

    // saving crop state in singleton object to preserve it in case of rendering event
    let currentCropState = {
      imageId: state.images.current.selected.imageId,
      position: {
        x: position.x * currImageState.scaleFromOriginal,
        y: position.y * currImageState.scaleFromOriginal,
        width: position.width * currImageState.scaleFromOriginal,
        height: position.height * currImageState.scaleFromOriginal,
      }
    };

    this.cropState = currentCropState;
  }

  static resetCropState() {
    this.cropState = null;
  }

  /**
   * Return crop area rect
   * @param {Object} state
   */
  static getCropAreaRect(state: any, useCache: boolean): Rect {
    const selectedImageCanvasState = ImageService.getSelectedImageCanvasState(state);

    if (useCache && this.cropState && this.cropState.imageId == state.images.current.selected.imageId) {
      // use cached crop state
      let position = this.cropState.position;
      return {
        x: position.x / selectedImageCanvasState.scaleFromOriginal,
        y: position.y / selectedImageCanvasState.scaleFromOriginal,
        width: position.width / selectedImageCanvasState.scaleFromOriginal,
        height: position.height / selectedImageCanvasState.scaleFromOriginal,
      };
    }

    // get default crop area rect for image
    let cropAreaRect = this._getCropAreaRectInitialPosition(state, selectedImageCanvasState);

    // if image already has crop applied - restore it
    if (selectedImageCanvasState.crop) {
      cropAreaRect.x = selectedImageCanvasState.crop.x;
      cropAreaRect.y = selectedImageCanvasState.crop.y;
      cropAreaRect.width = selectedImageCanvasState.crop.width;
      cropAreaRect.height = selectedImageCanvasState.crop.height;
    }

    return cropAreaRect;
  }

  /**
   * Get Crop area initial position
   * for example: if image covers printable area
   * Crop are should be same as printable area
   * @param {Object} state
   * @param {Object} imageCanvasState
   */
  static _getCropAreaRectInitialPosition(state: any, imageCanvasState: any){

    //get viewport from state and convert it to canvas size
    let viewportLayer = state.template.layers.find(x => x.id === imageCanvasState.layerId);
    let viewportRect = PositioningService.getViewportRect(viewportLayer);
    let viewport = PositioningService.convertToCanvasSize(viewportRect, state);

    //calulate intersection between image and viewport
    let intersection = {
      x1: Math.max(imageCanvasState.x, viewport.x),
      y1: Math.max(imageCanvasState.y, viewport.y),
      x2: Math.min(imageCanvasState.x + imageCanvasState.width, viewport.x + viewport.width),
      y2: Math.min(imageCanvasState.y + imageCanvasState.height, viewport.y + viewport.height)
    };


    let viewportInsideImage = !(intersection.x1 >= intersection.x2 || intersection.y1 >= intersection.y2);

    if(viewportInsideImage && imageCanvasState.rotation === 0){

      let cropAreaPosition =  {
        x: intersection.x1,
        y: intersection.y1,
        width: intersection.x2 - intersection.x1,
        height: intersection.y2 - intersection.y1
      };
      cropAreaPosition = this._limitIntersection(cropAreaPosition, imageCanvasState, viewport);

      //rect is relative to group
      //which has the same coordinates as image
      //viewport is relative to canvas,
      //in this place coordinates relative to group are calculated
      return {
        x: cropAreaPosition.x - imageCanvasState.x,
        y: cropAreaPosition.y - imageCanvasState.y,
        width: cropAreaPosition.width,
        height: cropAreaPosition.height
      };
    }
    else {
      return {
        x: 0,
        y: 0,
        width: imageCanvasState.width,
        height: imageCanvasState.height
      };
    }
  }

  /**
   * Set Limits to crop area if intersection with viewport is too small
   * @param {Object} cropAreaPosition
   * @param {Object} imageCanvasState
   * @param {Object} viewport
   */
  static _limitIntersection(cropAreaPosition: any, imageCanvasState: any, viewport: any){
    let cropAreaPositionToLimit = {...cropAreaPosition};
    let setLimit = cropAreaPositionToLimit.width < MIN_CROP_WIDTH || cropAreaPositionToLimit.height < MIN_CROP_HEIGHT;
    if(!setLimit){
      return cropAreaPositionToLimit;
    }

    // img is below viewport
    // height of cropArea should be increased
    if(imageCanvasState.y > viewport.y && cropAreaPositionToLimit.height < MIN_CROP_HEIGHT){
      cropAreaPositionToLimit.height = MIN_CROP_HEIGHT;
    }

    // img is above viewport
    // y of cropArea should be decreased
    // height should be set to MIN_CROP_HEIGHT
    if(imageCanvasState.y < viewport.y && cropAreaPositionToLimit.height < MIN_CROP_HEIGHT){
      cropAreaPositionToLimit.y -= MIN_CROP_HEIGHT - cropAreaPositionToLimit.height;
      cropAreaPositionToLimit.height = MIN_CROP_HEIGHT;
    }

    // img is to the left of viewport
    // x of cropArea should be decreased
    // width should be set to MIN_CROP_WIDTH
    if(imageCanvasState.x < viewport.x && cropAreaPositionToLimit.width < MIN_CROP_WIDTH){
      cropAreaPositionToLimit.x -= MIN_CROP_WIDTH - cropAreaPositionToLimit.width;
      cropAreaPositionToLimit.width = MIN_CROP_WIDTH;
    }

    // img is to the right of viewport
    // width should be set to MIN_CROP_WIDTH
    if(imageCanvasState.x > viewport.x && cropAreaPositionToLimit.width < MIN_CROP_WIDTH){
      cropAreaPositionToLimit.width = MIN_CROP_WIDTH;
    }

    return cropAreaPositionToLimit;
  }

  /**
   * Return position for crop area group
   * @param {Object} state
   */
  static getCropAreaGroupPosition(imageCanvasState: any, cropAreaRect: Rect): Position {
    return {
      x: imageCanvasState.x + cropAreaRect.width/2 + cropAreaRect.x,
      y: imageCanvasState.y + cropAreaRect.height/2 + cropAreaRect.y,
      offsetX: cropAreaRect.width/2 + cropAreaRect.x,
      offsetY: cropAreaRect.height/2 + cropAreaRect.y,
      width: imageCanvasState.width,
      height: imageCanvasState.height,
      rotation: imageCanvasState.rotation
    };
  }

  /**
   * Return point limited to rotated canvasImageState boundaries
   * @param {Number} x
   * @param {Number} y
   * @param {Number} width
   * @param {Number} height
   * @param {Object} canvasImageState
   * @param {Object} state
   */
  static getLimitedCropAreaPointOnDrag(x: number,
    y: number,
    width: number,
    height: number,
    canvasImageState: any,
    state: any) {

    let isOrientationChanged =
      state.editor.orientation !== state.editor.defaultOrientation;

    let posRect = !isOrientationChanged
      ? { x: x, y: y, width, height}
      : { x: y, y: state.editor.height - x, width, height};

    // center of original image
    let center = {
      x: canvasImageState.x + (canvasImageState.width / 2),
      y: canvasImageState.y + (canvasImageState.height / 2)
    };

    // center of crop area
    let cropCenter = {
      x: canvasImageState.x +
        (canvasImageState.crop
          ? canvasImageState.crop.x + (canvasImageState.crop.width / 2)
          : canvasImageState.width / 2),
      y: canvasImageState.y +
        (canvasImageState.crop
          ? canvasImageState.crop.y + (canvasImageState.crop.height / 2)
          : canvasImageState.height / 2)
    };

    // center of original image with cropped image rotation applied
    let rotatedCenter = PositioningService.getRotatedPoint(
      center,
      cropCenter,
      -canvasImageState.rotation
    );

    // new cropped area start with rotation applied
    let rotatedPos = PositioningService.getRotatedPoint(
      {x: posRect.x, y: posRect.y},
      rotatedCenter,
      canvasImageState.rotation
    );

    // real start position of original image based or calculated image center
    let realImagePosition =  {
      x: rotatedCenter.x - (canvasImageState.width / 2),
      y: rotatedCenter.y - (canvasImageState.height / 2)
    };

    // limit conditions on each side
    let left = rotatedPos.x <= realImagePosition.x;
    let top = rotatedPos.y <= realImagePosition.y;
    let right = rotatedPos.x + posRect.width >= canvasImageState.width + realImagePosition.x;
    let bottom = rotatedPos.y + posRect.height >= canvasImageState.height + realImagePosition.y;

    // limit values for each side
    let leftLimit = realImagePosition.x;
    let topLimit = realImagePosition.y;
    let rightLimit = canvasImageState.width + realImagePosition.x - posRect.width;
    let bottomLimit = canvasImageState.height + realImagePosition.y - posRect.height;

    // new position with all limits applied
    let limitedPos = {
      x: left ? leftLimit : right ? rightLimit : rotatedPos.x,
      y: top ? topLimit : bottom ? bottomLimit : rotatedPos.y
    };

    // new position with current rotation applied
    let rotatedBack = PositioningService.getRotatedPoint(
      limitedPos,
      {x: rotatedCenter.x, y: rotatedCenter.y},
      -canvasImageState.rotation
    );

    return !isOrientationChanged
      ? { x: rotatedBack.x, y: rotatedBack.y }
      : { x: state.editor.height - rotatedBack.y, y: rotatedBack.x };
  }


  /**
   * Return new crop center.
   * When new crop area is applied - we get center of previous crop rect and based on slace, rotation - calculate center position of new crop rect
   * @param {Rect} cropRect
   * @param {Position} croppingGroupPos
   * @param {Object} state
   */
  static getCropAreaCenterPoint(cropRect: Rect, croppingGroupPos: Position, state: any): Point {
    // center of previous crop area (or center of image if there was no crop before)
    let oldCenter = {
      x: croppingGroupPos.x,
      y: croppingGroupPos.y,
    };

    // center of new crop area
    let newCenter = {
      x: croppingGroupPos.x - croppingGroupPos.offsetX + cropRect.x + cropRect.width/2,
      y: croppingGroupPos.y - croppingGroupPos.offsetY + cropRect.y + cropRect.height/2,
    };

    // center of new crop area rotated around old crop center
    let newRotatedCenterPoint = PositioningService.getRotatedPoint(
      newCenter,
      oldCenter,
      -croppingGroupPos.rotation
    );

    // convert crop center from canvas size to template size
    let newRotatedCenterPointTemplateSize = PositioningService.convertToTemplateSize(
      newRotatedCenterPoint,
      state,
      state.images.current.selected.layerId);

    return newRotatedCenterPointTemplateSize;
  }

  /**
   * Return limited crop area rect
   * @param {Rect} cropArea
   * @param {Object} canvasImageState
   * @param {Boolean} isTop
   * @param {Boolean} isLeft
   */
  static getLimitedCropAreaRect(cropArea, canvasImageState, isTop, isLeft) {
    let limitedCropArea = {...cropArea};

    // preserve min width
    if (cropArea.width < 30) {
      if (isLeft) {
        limitedCropArea.x -= 30 - cropArea.width;
      }
      limitedCropArea.width = 30;
    }

    // preserve min height
    if (cropArea.height < 30) {
      if (isTop) {
        limitedCropArea.y -= 30 - cropArea.height;
      }
      limitedCropArea.height = 30;
    }

    // keep crop area left border position inside canvas
    if (cropArea.x < 0) {
      limitedCropArea.width += cropArea.x;
      limitedCropArea.x = 0;
    }

    // keep crop area top border position inside canvas
    if (cropArea.y < 0) {
      limitedCropArea.height += cropArea.y;
      limitedCropArea.y = 0;
    }

    // keep crop area right border position inside canvas
    if (limitedCropArea.width + limitedCropArea.x > canvasImageState.width) {
      if (isLeft) {
        limitedCropArea.x -= limitedCropArea.width + limitedCropArea.x - canvasImageState.width;
      }

      limitedCropArea.width = canvasImageState.width - limitedCropArea.x;
    }

    // keep crop area bottom border position inside canvas
    if (limitedCropArea.height + limitedCropArea.y > canvasImageState.height) {
      if (isTop) {
        limitedCropArea.y -= limitedCropArea.height + limitedCropArea.y - canvasImageState.height;
      }
      limitedCropArea.height = canvasImageState.height - limitedCropArea.y;
    }

    return limitedCropArea;
  }
}
