import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import Modal from 'gooten-components/src/components/shared/Modal';
import Thumbnail from 'gooten-components/src/components/shared/Thumbnail';
import Alert from 'gooten-components/src/components/shared/Alert';
import Loader from 'gooten-components/src/components/shared/Loader';
import DropDownContainer from 'gooten-components/src/components/shared/DropDown/DropDownContainer';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import { Link } from 'react-router';
import { COLORS } from 'gooten-components/src/constants';
import PersonalizeImageUpload from './PersonalizeImageUpload';
import ImagesViewService from '../../../OrderDetails/ImagesView/ImagesViewService';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';

const PersonalizeActionsComponent = ({
  orderId,
  summaryData,
  itemsData,
  getSharedOrderData,
  itemId,
  loading,
  editDesign,
  splitOrderItem,
  updatePersonalizedImages,
  platformOrderId,
  dateCreated,
  canBeSplit
}) => {
  const setCurrentItem = () => {
    setShowModal(true);
    getSharedOrderData(orderId);
  };
  const [showModal, setShowModal] = useState(false);
  const [thumbImage, setThumbImage] = useState({});
  const [filesToSubmit, setFilesToSubmit] = useState({});
  const [noFilesToSubmit, setNoFilesTOSubmit] = useState(false);
  const [splitConfirmDialogue, setSplitConfirmDialogue] = useState(false);

  useEffect(() => {
    if (Object.keys(filesToSubmit).length) {
      setNoFilesTOSubmit(false);
    }
  }, [filesToSubmit]);

  const currentItem = itemsData.find(x => x.Item === itemId);

  const submitToProd = () => {
    // show error if no images found
    if (
      !currentItem.Images.filter(image => image.ThumbnailUrl || image.FileName || image.SourceUrl)
        .length &&
      !Object.keys(filesToSubmit).length
    ) {
      setNoFilesTOSubmit(true);
    } else {
      updatePersonalizedImages({ filesToSubmit: filesToSubmit, itemId: currentItem.Item });
      setShowModal(false);
    }
  };

  return (
    <div className="w-100 d-flex justify-content-end">
      <Button className="button-default medium mr-1" onClick={() => setCurrentItem()}>
        Replace Artwork
      </Button>
      <DropDownContainer size="medium" rightAlign title={'Name'} buttonOnly>
        {canBeSplit && (
          <ListItem
            leftIcon="gitFork"
            text="Split items"
            onSelect={() => setSplitConfirmDialogue(true)}
          />
        )}
        <ListItem
          leftIcon="downloadCloud"
          text="Export CSV"
          onSelect={() =>
            window.location.assign(
              `/admin/Orders/Export?ids=${itemId}&offset=240&mode=OrderItem`,
              '_blank'
            )
          }
        />
      </DropDownContainer>
      {splitConfirmDialogue && (
        <Dialogue
          isOpen
          title={'Split Order Item ' + itemId}
          cancelClick={() => setSplitConfirmDialogue(false)}
          primaryClick={() => {
            splitOrderItem(orderId, itemId);
            setSplitConfirmDialogue(false);
          }}
          actionText="Split Order Item"
        >
          This action will split your grouped order item into separate rows for editing. This action
          cannot be reversed.
        </Dialogue>
      )}

      {showModal && (
        <Modal
          className="medium personalize-product"
          isOpen
          hideCancelButton
          cancelClick={() => setShowModal(false)}
          title="Personalize Product"
        >
          <div>
            {loading ? (
              <Loader />
            ) : currentItem ? (
              <>
                <div className="header-section m-3">
                  <div className="general-info overline">
                    <span>
                      Order {platformOrderId} &bull; {dateCreated} &bull;{' '}
                      {summaryData.ShippingAddress.FirstName} {summaryData.ShippingAddress.LastName}
                    </span>
                  </div>
                  <div className="small-thumbnail d-flex mt-2">
                    <div>
                      <Thumbnail image="" />
                    </div>
                    <div className="mt-2 ml-3 d-flex flex-column">
                      <div className="item-friendly-sku body-text-2">
                        {currentItem.Meta.PreconfigProductName}
                      </div>
                      <div className="item-sku caption-text">{currentItem.SKU}</div>
                    </div>
                  </div>
                </div>
                <hr className="micro" />
                <div className="images-section m-3">
                  {noFilesToSubmit && (
                    <Alert className="w-auto" isOpen type="warning">
                      No files found. Please select your images to upload.
                    </Alert>
                  )}
                  {currentItem.Images.map((image, key) => (
                    <div key={key}>
                      <div className="image-row flex-wrap d-flex ml-n2 mr-n2">
                        <div className="m-2">
                          <Thumbnail
                            className="extra-large"
                            image={
                              thumbImage[image.Id] ||
                              image.ThumbnailUrl ||
                              image.FileName ||
                              image.SourceUrl
                            }
                          />
                        </div>
                        <div className="change-image-fields m-2">
                          <div className="overline mb-2 mt-2">
                            Print Space –&nbsp;
                            <ImageDimensions
                              image={image}
                              imageIndex={key}
                              currentItem={currentItem}
                              recipeId={summaryData.PartnerApiKey}
                            />
                          </div>
                          <PersonalizeImageUpload
                            setFilesToSubmit={setFilesToSubmit}
                            filesToSubmit={filesToSubmit}
                            image={image}
                            thumbImage={thumbImage}
                            setThumbImage={setThumbImage}
                            setShowModal={setShowModal}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="buttons-footer-section d-flex flex-wrap justify-content-between position-absolute w-100">
                  <div className="m-2">
                    {(currentItem.Images[0].FileName || currentItem.Images[0].SourceUrl) && (
                      <Link
                        to={`orders-new/edit-design/${currentItem.Meta.PreconfigSku}`}
                        onClick={() => {
                          editDesign(currentItem);
                        }}
                      >
                        <Button className=" button-default large" iconRight="externalLink">
                          Product Editor
                        </Button>
                      </Link>
                    )}
                  </div>
                  <div className="m-2">
                    <Button
                      className="mr-1 button-default large"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button className="ml-1 button-primary large" onClick={submitToProd}>
                      Ready for production
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <Alert className="m-2 w-auto mt-5" isOpen type="warning">
                Error: Order Item Not Found
              </Alert>
            )}
          </div>
        </Modal>
      )}
      <style jsx>{`
        .change-image-fields {
          flex-grow: 1;
        }
        .overline {
          color: ${COLORS.neutralDark600};
        }
        .buttons-footer-section {
          border-top: 1px solid ${COLORS.neutralLight300};
          bottom: 0;
        }
        :global(.personalize-product .modal-bod) {
          margin-bottom: 3.5rem;
        }
      `}</style>
    </div>
  );
};

PersonalizeActionsComponent.propTypes = {
  orderId: PropTypes.number.isRequired,
  getSharedOrderData: PropTypes.func.isRequired,
  summaryData: PropTypes.object.isRequired,
  itemsData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  editDesign: PropTypes.func.isRequired,
  updatePersonalizedImages: PropTypes.func.isRequired,
  splitOrderItem: PropTypes.func.isRequired,
  platformOrderId: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  canBeSplit: PropTypes.bool.isRequired
};

// NOTE: This component below is to get the design image template size (height x width)
// We need an easier way to get print space image dimensions for each image
const ImageDimensions = ({ image, currentItem, imageIndex, recipeId }) => {
  const [imageDesignSizes, setImageDesignSizes] = useState(null);

  async function fetchImageSizeValidator() {
    if (!image) return;
    // get orientation info from order item meta...
    const orientationChanged =
      currentItem && currentItem.Meta && currentItem.Meta['OrientationChanged'] === 'true';

    // check if image is rotated
    if (image.ManipCommand) {
      let rotateCommand = JSON.parse(image.ManipCommand).commands.find(function (x) {
        return x.name === 'rotate';
      });
      if (rotateCommand) {
        let rotationAngle = rotateCommand.args.degrees;
        if ([-270, -90, 90, 270].includes(rotationAngle)) {
          image.rotated = true;
        }
      }
    }

    const imageTemplateData = await ImagesViewService.getSkuData(recipeId, currentItem.SKU);
    let option = imageTemplateData.Options.find(o => o.Name.toLowerCase() == 'single');
    let space = option.Spaces[imageIndex];
    if (space) {
      image.Dimensions.Width = space.FinalX2 - space.FinalX1;
      image.Dimensions.Height = space.FinalY2 - space.FinalY1;
    }

    if (!(image.Dimensions.Width + image.Dimensions.Height > 0)) {
      if (!space.Layers || !space.Layers) {
        return;
      }
      var layer = space.Layers.filter(function (s) {
        return s.Type && s.Type.toLowerCase() == 'image';
      })[0];
      if (!layer) {
        return;
      }
      image.Dimensions.Width = layer.X2 - layer.X1;
      image.Dimensions.Height = layer.Y2 - layer.Y1;
      if (!(image.Dimensions.Width + image.Dimensions.Height > 0)) {
        return;
      }
    }

    // apply orientation to dimensions...
    const width = orientationChanged ? image.Dimensions.Height : image.Dimensions.Width;
    const height = orientationChanged ? image.Dimensions.Width : image.Dimensions.Height;

    image.Resolution = width + ' x ' + height;
    setImageDesignSizes(image.Resolution);
  }

  useEffect(() => {
    if (currentItem && currentItem.Images) {
      fetchImageSizeValidator();
    }
  }, [currentItem]);

  return <>{imageDesignSizes} PX</>;
};

PersonalizeActionsComponent.prototypes = {
  image: PropTypes.object.isRequired,
  imageIndex: PropTypes.number.isRequired,
  currentItem: PropTypes.object.isRequired,
  recipeId: PropTypes.string.isRequired
};

export default PersonalizeActionsComponent;
