import { delay } from 'redux-saga';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import {
  RESTORE_CART,
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  UPDATE_CART_ITEM_QUANTITY,
  UPDATE_CART_ITEM_PRICING,
  UPDATE_SHIPPING_COUNTRY,
  setOrderSummaryLoading,
  updateOrderSummaryTax,
  updateOrderSummarySubTotal,
  updateOrderSummaryShipTotal,
  updateOrderSummarySurcharge
} from '../../../store/actions/dataActions';
import {
  SET_SHIPPING_ADDRESS,
  SET_SHIPPING_METHOD_FOR_GROUP,
  UPDATE_SHIPPING_METHODS
} from '../../Checkout/CheckoutActions';
import { shippingMethodsGroupsSelector } from '../../Checkout/atoms/ShippingMethod/ShippingMethodSelectors';
import { cartItemsSelector } from '../../Cart/CartSelectors';
import { selectedShippingAddressSelector } from '../../Checkout/CheckoutSelectors';
import priceEstimateService from './services/priceEstimateService';
import { roundFloatPrice } from '../../../utils/price';
import { surchargeSelector, highestSurchargeSelector } from './OrderSummarySelectors';

export function* updateTax(action) {
  yield put(setOrderSummaryLoading(true));
  // NOTE: can occure too offten
  // wait 500ms so this can be canceled while waiting
  yield call(delay, 500);

  const shippingAddress = yield select(selectedShippingAddressSelector);
  const shipMethods = yield select(shippingMethodsGroupsSelector);
  const cartItems = yield select(cartItemsSelector);

  // If the Shipping Address is undefined then clear the tax estimate
  if (shippingAddress === undefined) {
    yield put(updateOrderSummaryTax(null, []));
    return;
  }

  if (shipMethods.size > 0 && shippingAddress) {
    const items = cartItems.map(item => {
      const variant = item.get('variant');
      const vendor = variant.get('vendors').get(0);

      return {
        sku: variant.get('sku'),
        price: vendor.get('price'),
        quantity: item.get('quantity'),
        vendorId: vendor.get('vendorId')
      };
    });

    const surchargeAmount = yield select(highestSurchargeSelector);

    const shippingPrices = shipMethods.map(shipMethod => {
      const selectedShipOption = shipMethod
        .get('ShipOptions')
        .find(s => s.get('Id') === shipMethod.get('SelectedMethodId'));

      return {
        name: selectedShipOption.get('Name'),
        price: selectedShipOption.getIn(['PartnerPrice', 'Price']) + (surchargeAmount || 0)
      };
    });

    const partnerTaxEstimateRequest = {
      items: items.toJS(),
      shippingPrices: shippingPrices.toJS(),
      shippingAddress: {
        ...shippingAddress.toJS(),
        line1: shippingAddress.addressLine1,
        line2: shippingAddress.addressLine2,
        postalCode: shippingAddress.zipCode,
        countryCode: shippingAddress.country
      }
    };

    try {
      const taxEstimateResponse = yield call(
        priceEstimateService.getPartnerTaxEstimate,
        partnerTaxEstimateRequest
      );
      yield put(updateOrderSummaryTax(taxEstimateResponse, []));
    } catch (error) {
      yield put(
        updateOrderSummaryTax(null, error.Errors || [{ Message: 'Partner Tax estimate error' }])
      );
    }
  }
}

export function* updateSubTotal(action) {
  //
  // NOTE: PriceEstimage requests returns customer prices
  // For COF we need partner prices
  // Partner prices for items are loaded in CartSaga and set for each item
  //
  // We also not using ShippingPrice from PriceEstimage requests
  // since it price vary from ShippingOptions prices
  // We are getting shipping price only on Checkout page when shipping options are loaded
  //
  // One thing which we are take from PriceEstimage request is Tax
  //
  yield put(setOrderSummaryLoading(true));
  // get subTotal from cart items pricing
  let subTotal;
  const cartItems = yield select(cartItemsSelector);
  if (cartItems.size && cartItems.every(cartItem => !!cartItem.get('pricing'))) {
    // Calculate subTotal only if all items in Cart has pricings
    subTotal = 0;
    cartItems.forEach(item => {
      subTotal += roundFloatPrice(
        item.getIn(['pricing', 'PartnerPrice', 'Price']) * item.get('quantity')
      );
      subTotal = roundFloatPrice(subTotal);
    });
  }

  yield put(updateOrderSummarySubTotal(subTotal));
  yield put(setOrderSummaryLoading(false));
}

export function* updateShippingTotal(action) {
  //
  // NOTE: PriceEstimage requests returns customer prices
  // For COF we need partner prices
  // Partner prices for items are loaded in CartSaga and set for each item
  //
  // We also not using ShippingPrice from PriceEstimage requests
  // since it price vary from ShippingOptions prices
  // We are getting shipping price only on Checkout page when shipping options are loaded
  //
  // One thing which we are take from PriceEstimage request is Tax
  //
  yield put(setOrderSummaryLoading(true));
  // get shipTotal from checkout shipping methods selected options
  let shipTotal;
  const shipMethods = yield select(shippingMethodsGroupsSelector);

  const surchargeCosts = yield select(surchargeSelector);
  const selectedShipGroups = shipMethods.map(sm => sm.get('SelectedMethodId'));

  const highestSurcharge = surchargeCosts
    .filter(scData => selectedShipGroups.includes(scData.get('id')))
    .reduce((acc, sc) => Math.max(acc, sc.get('cost')), 0);

  if (shipMethods.size && shipMethods.every(shipMethod => !!shipMethod.get('SelectedMethodId'))) {
    // Calculate shipTotal only if all items in shipMethods has SelectedMethodId
    shipTotal = 0;
    shipMethods.forEach(shipMethod => {
      const selectedShipOption = shipMethod
        .get('ShipOptions')
        .find(s => s.get('Id') === shipMethod.get('SelectedMethodId'));
      shipTotal += selectedShipOption.getIn(['PartnerPrice', 'Price']);
      shipTotal = roundFloatPrice(shipTotal);
    });
  }

  yield put(updateOrderSummaryShipTotal(shipTotal));
  yield put(updateOrderSummarySurcharge(highestSurcharge));
  yield put(setOrderSummaryLoading(false));
}

export function* watchCartChanges() {
  yield takeLatest(
    [
      RESTORE_CART,
      ADD_TO_CART,
      REMOVE_CART_ITEM,
      CLEAR_CART,
      SET_SHIPPING_ADDRESS,
      UPDATE_SHIPPING_COUNTRY,
      UPDATE_SHIPPING_METHODS,
      SET_SHIPPING_METHOD_FOR_GROUP
    ],
    updateTax
  );

  yield takeLatest(
    [
      RESTORE_CART,
      ADD_TO_CART,
      REMOVE_CART_ITEM,
      CLEAR_CART,
      UPDATE_CART_ITEM_QUANTITY,
      UPDATE_CART_ITEM_PRICING,
      UPDATE_SHIPPING_COUNTRY
    ],
    updateSubTotal
  );

  yield takeLatest(
    [
      SET_SHIPPING_ADDRESS,
      UPDATE_SHIPPING_METHODS,
      SET_SHIPPING_METHOD_FOR_GROUP,
      UPDATE_SHIPPING_COUNTRY
    ],
    updateShippingTotal
  );
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchCartChanges()]);
}
