import { createSelector } from 'reselect';

const connectStoreViewStateSelector = state => state.getIn(['hub', 'connectStoreView']);

export const isConnectingSelector = createSelector(
  [connectStoreViewStateSelector],
  connectStoreViewState => connectStoreViewState.get('connecting') === true
);

export const errorMsgSelector = createSelector(
  [connectStoreViewStateSelector],
  connectStoreViewState => connectStoreViewState.get('errorMsg')
);
