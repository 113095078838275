import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { COMPONENT_MOUNTED } from '../../ProductPublishActions';
import { setDefaultState } from './ProvidersSelectionPanelActions';
import { publishDataSelector, publishConfigSelector } from '../../ProductPublishSelectors';
import { delay } from 'redux-saga';

export function* componentMountedHandler() {
  yield call(delay, 300);
  const data = yield select(publishDataSelector);
  const config = yield select(publishConfigSelector);

  // if all enables are false, that means default state is not set
  // so it means proceed to SET DEFAULT STATE
  // otherwise, just copy current enabled states
  if (data) {
    const allSelected =
      !data.getIn(['storage', 'selected']) && data.get('stores').every(s => !s.get('selected'));
    const allDisabled =
      !data.getIn(['storage', 'enabled']) && data.get('stores').every(s => !s.get('enabled'));

    if (allDisabled) {
      let storage = {
        ...data.get('storage').toJS(),
        enabled:
          allSelected || data.getIn(['storage', 'selected']) || config.get('storageSelected'),
        name: 'Storage'
      };

      let stores = data.get('stores').toJS();
      stores = stores.map(s => ({
        ...s,
        enabled: config.get('storageSelected') ? false : allSelected || s.selected
      }));
      yield put(setDefaultState({ storage, stores }));
    } else {
      yield put(
        setDefaultState({
          storage: data.get('storage').toJS(),
          stores: data.get('stores').toJS()
        })
      );
    }
  }
}

export function* watchComponentMount() {
  yield takeLatest(COMPONENT_MOUNTED, componentMountedHandler);
}

export default function* rootSaga() {
  yield all([watchComponentMount()]);
}
