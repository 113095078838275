import { connect } from 'react-redux';
import YourTeam from './YourTeam';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { initFor } from '../BaseCard/BaseCardActions';
import { updateAccess, removeMember } from './YourTeamActions';
import { withRouter } from 'react-router';

const init = initFor(YourTeam.name, YourTeam.messages);
const cardSelector = cardSelectorFor(YourTeam.name);

const mapDispatchToProps = {
  init,
  updateAccess,
  removeMember
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    data: cardState.get('data'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YourTeam));
