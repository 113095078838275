import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  tierGradients,
  PartnerTierChip
} from 'gooten-components/src/components/shared/PartnerTier';
import moment from 'moment';
import CircularProgressBar from 'gooten-components/src/components/shared/CircularProgressBar';

const NextTierCard = ({
  limit,
  periodName,
  periodEnd,
  ordersTotal,
  nextLevelStatus,
  updatedAt
}) => {
  const [amountSpent, setAmountSpent] = useState(0);

  useEffect(() => {
    // timeout allows progress bar animation.
    const timer = setTimeout(() => {
      setAmountSpent(ordersTotal);
    }, 500);
    return () => clearTimeout(timer);
  }, [ordersTotal]);

  return (
    <>
      <div className="px-4 pt-3 pb-3">
        <div className="d-flex justify-content-between">
          <p className="title-1 next-level-title ml-0 my-auto mr-auto">next vim status</p>
          <PartnerTierChip id={nextLevelStatus} />
          <br />
        </div>
        <div className="d-flex my-4 mx-auto">
          <div className="circle-progress-bar m-auto mr-1">
            <CircularProgressBar
              value={amountSpent}
              maxValue={limit}
              gradient={tierGradients[nextLevelStatus]}
            />
          </div>
          <div className="orders-total m-auto pl-2">
            <p>
              <span className="title-2">${ordersTotal}</span>
              <span className="caption-text total">/{limit}</span>
            </p>
            <p className="caption-text period">{periodName} Sales</p>
          </div>
        </div>
        <p className="caption-text">Updated {moment(updatedAt).format('hh:mm A')}</p>
      </div>
      <hr />
      <div className="px-4 pt-4 pb-3">
        <p className="caption-text">You have until {periodEnd} to progress to higher VIM status</p>
      </div>
      <style jsx>
        {`
          .caption-text {
            font-size: 11px;
            color: #646b72 !important;
          }
          hr {
            margin: 0 !important;
          }
          .next-level-title {
            font-size: 11px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #646b72 !important;
          }

          .circle-progress-bar {
            flex: 3;
            max-width: 85px;
            min-width: 60px;
          }
          .orders-total {
            flex: 5;
            text-align: center;
          }
          .title-2 {
            color: #373f49 !important;
          }
          .total {
            font-size: 13px;
          }
          .period {
            font-size: 13px;
            color: #373f49 !important;
          }
          @media (max-width: 690px) {
            .circle-progress-bar {
              flex: 1;
            }
            .orders-total {
              flex: 5;
              text-align: left;
              padding-left: 1rem !important;
            }
          }
        `}
      </style>
    </>
  );
};

NextTierCard.propTypes = {
  nextLevelStatus: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  periodName: PropTypes.string.isRequired,
  ordersTotal: PropTypes.number.isRequired,
  periodEnd: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};
export default NextTierCard;
