import { connect } from 'react-redux';
import {
  FETCH_SHIPPING_ADDRESSES,
  setFormVisible,
  storeUserAddress,
  deleteShippingAddress,
  deleteUserEnteredShippingAddress
} from './AddressSelectorActions';
import { setShippingAddress } from '../../CheckoutActions';
import { fetchAsync } from '../../../../store/actions/dataActions';
import {
  isShippingAddressesLoadedSelector,
  isFormVisibleSelector,
  shippingAddressesSelector,
  userEnteredShippingAddressesCountSelector,
  totalAddressesSelector,
  currentPageSelector,
  selectedShippingAddressSelector
} from './AddressSelectorSelectors';
import AddressSelector from './AddressSelector';
import analyticsService from '../../../../services/analyticsService';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  const isShippingAddressesLoaded = isShippingAddressesLoadedSelector(state);
  const isFormVisible = isFormVisibleSelector(state);
  const shippingAddresses = shippingAddressesSelector(state);
  const userEnteredShippingAddressesCount = userEnteredShippingAddressesCountSelector(state);
  const totalAddresses = totalAddressesSelector(state);
  const currentPage = currentPageSelector(state);
  const selectedShippingAddress = selectedShippingAddressSelector(state);

  return {
    isShippingAddressesLoaded,
    isFormVisible,
    shippingAddresses,
    userEnteredShippingAddressesCount,
    totalAddresses,
    currentPage,
    selectedShippingAddress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFormVisible: (isVisible, address) => dispatch(setFormVisible(isVisible)),
    fetchShippingAddresses: page => dispatch(fetchAsync(FETCH_SHIPPING_ADDRESSES, page)),
    setShippingAddress: address => {
      if (address) {
        analyticsService.trackWithConfigSource('Shipping Address Selected');
      }
      // This to maintain old cof events
      analyticsService._track('custom order', 'shipping info added');
      return dispatch(setShippingAddress(address));
    },
    storeUserAddress: address => dispatch(storeUserAddress(address)),
    deleteShippingAddress: addressId => dispatch(deleteShippingAddress(addressId)),
    deleteUserEnteredShippingAddress: addressId =>
      dispatch(deleteUserEnteredShippingAddress(addressId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressSelector);
