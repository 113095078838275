import Config from '../config';
import { fetch, postJson } from '../utils/http';

// These are available ProPreviews SKUs
const whiteListedProPreviewSKUs = new Set([
  // Accent Mugs
  'Mug11oz-White-BlackAccent',
  'Mug11oz-White-BlueAccent',
  'Mug11oz-White-GreenAccent',
  'Mug11oz-White-OrangeAccent',
  'Mug11oz-White-PinkAccent',
  'Mug11oz-White-RedAccent',
  'Mug11oz-White-YellowAccent',

  // Beer Stein
  'BeerStein-16oz',

  // Canvas Wraps
  'CanvsWrp-WhtWrp-12x16',
  'CanvsWrp-WhtWrp-12x12',
  'CanvsWrp-WhtWrp-10x10',
  'CanvsWrp-BlkWrp-10x10',
  'CanvsWrp-ImgWrp-32x48',
  'CanvsWrp-ImgWrp-24x36',
  'CanvsWrp-WhtWrp-18x24',
  'CanvsWrp-WhtWrp-5x7',
  'CanvsWrp-ImgWrp-20x20',
  'CanvsWrp-WhtWrp-10x20',
  'CanvsWrp-BlkWrp-16x24',
  'CanvsWrp-WhtWrp-6x6',
  'CanvsWrp-BlkWrp-12x16',
  'CanvsWrp-BlkWrp-20x30',
  'CanvsWrp-ImgWrp-20x60',
  'CanvsWrp-ImgWrp-11x14',
  'CanvsWrp-BlkWrp-12x18',
  'CanvsWrp-ImgWrp-8x10',
  'CanvsWrp-WhtWrp-20x30',
  'CanvsWrp-BlkWrp-16x16',
  'CanvsWrp-WhtWrp-11x14',
  'CanvsWrp-ImgWrp-30x30',
  'CanvsWrp-ImgWrp-8x8',
  'CanvsWrp-BlkWrp-12x12',
  'CanvsWrp-ImgWrp-5x7',
  'CanvsWrp-ImgWrp-20x24',
  'CanvsWrp-WhtWrp-8x8',
  'CanvsWrp-BlkWrp-18x24',
  'CanvsWrp-WhtWrp-20x20',
  'CanvsWrp-BlkWrp-30x30',
  'CanvsWrp-ImgWrp-6x6',
  'CanvsWrp-WhtWrp-20x60',
  'CanvsWrp-ImgWrp-10x10',
  'CanvsWrp-ImgWrp-12x12',
  'CanvsWrp-ImgWrp-18x24',
  'CanvsWrp-WhtWrp-32x48',
  'CanvsWrp-BlkWrp-20x24',
  'CanvsWrp-WhtWrp-24x36',
  'CanvsWrp-WhtWrp-20x24',
  'CanvsWrp-ImgWrp-12x16',
  'CanvsWrp-WhtWrp-16x20',
  'CanvsWrp-BlkWrp-32x48',
  'CanvsWrp-BlkWrp-24x36',
  'CanvsWrp-BlkWrp-24x24',
  'CanvsWrp-BlkWrp-20x20',
  'CanvsWrp-WhtWrp-30x30',
  'CanvsWrp-BlkWrp-11x14',
  'CanvsWrp-ImgWrp-20x30',
  'CanvsWrp-WhtWrp-8x10',
  'CanvsWrp-BlkWrp-20x60',
  'CanvsWrp-BlkWrp-16x20',
  'CanvsWrp-BlkWrp-10x20',
  'CanvsWrp-BlkWrp-6x6',
  'CanvsWrp-BlkWrp-24x30',
  'CanvsWrp-ImgWrp-16x24',
  'CanvsWrp-ImgWrp-36x36',
  'CanvsWrp-BlkWrp-8x10',
  'CanvsWrp-ImgWrp-30x40',
  'CanvsWrp-WhtWrp-12x18',
  'CanvsWrp-ImgWrp-12x36',
  'CanvsWrp-ImgWrp-12x18',
  'CanvsWrp-BlkWrp-8x8',
  'CanvsWrp-ImgWrp-16x16',
  'CanvsWrp-ImgWrp-24x24',
  'CanvsWrp-WhtWrp-24x30',
  'CanvsWrp-WhtWrp-16x16',
  'CanvsWrp-ImgWrp-16x20',
  'CanvsWrp-WhtWrp-30x40',
  'CanvsWrp-BlkWrp-12x36',
  'CanvsWrp-WhtWrp-36x36',
  'CanvsWrp-WhtWrp-16x24',
  'CanvsWrp-BlkWrp-5x7',
  'CanvsWrp-ImgWrp-10x20',
  'CanvsWrp-ImgWrp-24x30',
  'CanvsWrp-BlkWrp-30x40',
  'CanvsWrp-WhtWrp-12x36',
  'CanvsWrp-BlkWrp-36x36',
  'CanvsWrp-WhtWrp-24x24',

  // 'Socks'
  'Socks-DTG',

  // All over printover hoodies.
  'Apparel-Sublimation-Pullover-2XL-AOP',
  'Apparel-Sublimation-Pullover-XL-AOP',
  'Apparel-Sublimation-Pullover-L-AOP',
  'Apparel-Sublimation-Pullover-M-AOP',
  'Apparel-Sublimation-Pullover-S-AOP',
  'Apparel-Sublimation-Pullover-XS-AOP',

  // All over printover zipup hoodies.
  'Apparel-Sublimation-ZipUp-2XL-AOP',
  'Apparel-Sublimation-ZipUp-XL-AOP',
  'Apparel-Sublimation-ZipUp-L-AOP',
  'Apparel-Sublimation-ZipUp-M-AOP',
  'Apparel-Sublimation-ZipUp-S-AOP',
  'Apparel-Sublimation-ZipUp-XS-AOP',

  // Journal Hardcover
  'Journal-Hardcover-625x825-160',

  // Sweatshirts
  'Apparel-Sublimation-Sweatshirt-2XL-AOP',
  'Apparel-Sublimation-Sweatshirt-XL-AOP',
  'Apparel-Sublimation-Sweatshirt-L-AOP',
  'Apparel-Sublimation-Sweatshirt-M-AOP',
  'Apparel-Sublimation-Sweatshirt-S-AOP',
  'Apparel-Sublimation-Sweatshirt-XS-AOP',

  // Koozie
  'Koozie-Bottle-Zippered',
  'Koozie-Can',
  'Koozie-Velcro',

  // Mugs
  'Mug-11oz',
  'Mug-White-11oz',
  'Mug-White-15oz',
  'Mug-15oz',
  'Mug-Black-11oz',
  'Mug-Black-15oz',

  // GlassCutBoard
  'GlassCutBoard-8x11',

  // Pet-Bowl-Cat
  'Pet-Bowl-Cat-156x225',
  'Pet-Bowl-Dog-22x315',

  // Beer Mugs
  'BeerMug-Ceramic-WhiteWithGoldTrim-22oz',
  'BeerMug-Glass-Frosted-22oz',

  // Magic Mugs
  'MagicMug-11oz-Black',

  // Peristyle Bottle
  'PeristyleBottle-16oz-Black',
  'PeristyleBottle-16oz-Copper',
  'PeristyleBottle-16oz-Stainless',
  'PeristyleBottle-16oz-Mint',
  'PeristyleBottle-16oz-White',

  // Travel Bags
  'TravelBag-Large',
  'TravelBag-Small',

  // Throw Pillows
  'ThrowPillow-SpunPolyester-14x14-Zippered',
  'ThrowPillow_Zipper_18x18',
  'ThrowPillow_Zipper_20x20',
  'ThrowPillow_Zipper_26x26',
  'ThrowPillow-SpunPolyester-28x28-Zippered',
  'ThrowPillow-SpunPolyester-36x36-Zippered',
  'ThrowPillow-SpunPolyester-40x40-Zippered',
  'ThrowPillow-CottonTwill-14x20-Zippered',
  'ThrowPillow-PolyTwill-14x14-Zippered',
  'ThrowPillow-PolyTwill-14x20-Zippered',
  'ThrowPillow-PolyTwill-16x16-Zippered',
  'ThrowPillow-PolyTwill-18x18-Zippered',
  'ThrowPillow-PolyTwill-20x20-Zippered',
  'ThrowPillow-PolyTwill-26x26-Zippered',
  'ThrowPillow-Suede-14x14-Zippered',
  'ThrowPillow-Suede-14x20-Zippered',
  'ThrowPillow-Suede-16x16-Zippered',
  'ThrowPillow-Suede-18x18-Zippered',
  'ThrowPillow-Suede-20x20-Zippered',
  'ThrowPillow-Suede-26x26-Zippered',
  'ThrowPillow-WhiteFauxLinen-14x14-Zippered',
  'ThrowPillow-WhiteFauxLinen-14x20-Zippered',
  'ThrowPillow-WhiteFauxLinen-16x16-Zippered',
  'ThrowPillow-WhiteFauxLinen-18x18-Zippered',
  'ThrowPillow-WhiteFauxLinen-20x20-Zippered',
  'ThrowPillow-WhiteFauxLinen-26x26-Zippered',
  'ThrowPillow-SpunPolyester-14x20-Zippered',
  'ThrowPillow_Sewn_14x14',
  'ThrowPillow_Sewn_14x20',
  'ThrowPillow_Sewn_16x16',
  'ThrowPillow_Sewn_18x18',
  'ThrowPillow_Sewn_20x20',
  'ThrowPillow_Sewn_26x26',
  'ThrowPillow-CottonTwill-14x14-Zippered',
  'ThrowPillow-CottonTwill-16x16-Zippered',
  'ThrowPillow-CottonTwill-18x18-Zippered',
  'ThrowPillow-CottonTwill-20x20-Zippered',
  'ThrowPillow-CottonTwill-26x26-Zippered',

  // Viking Tumbler
  'VikingTumbler-20oz-Black',
  'VikingTumbler-20oz-Copper',
  'VikingTumbler-20oz-Stainless',
  'VikingTumbler-20oz-Mint',
  'VikingTumbler-20oz-White',

  // Sherpa Blanket
  'SherpaBlanket-50x60',
  'SherpaBlanket-60x80',
  'SherpaBlanket-Tan-50x60',
  'SherpaBlanket-Tan-60x80',

  // Watter Bottles
  'WaterBottle-Aluminum-Silver-ScrewOneLid-7x3-600ml',

  // Wine Tumbler
  'WineTumbler-12oz-Black',
  'WineTumbler-12oz-StainlessSteel',
  'WineTumbler-12oz-Mint',
  'WineTumbler-12oz-White',

  // Pajamas
  'Pajama-Mens-M',
  'Pajama-Womens-M',

  // Face Maks
  'FaceMask-Cover',

  // Leggings - Glossy
  'Leggings-Glossy-Womens-2XL',
  'Leggings-Glossy-Womens-XL',
  'Leggings-Glossy-Womens-L',
  'Leggings-Glossy-Womens-M',
  'Leggings-Glossy-Womens-S',
  'Leggings-Glossy-Womens-XS',

  'Leggings-Suede-Womens-2XL',
  'Leggings-Suede-Womens-XL',
  'Leggings-Suede-Womens-L',
  'Leggings-Suede-Womens-M',
  'Leggings-Suede-Womens-S',
  'Leggings-Suede-Womens-XS'
]);

const whiteListedProPreviewStyle = new Set(['Studio-Minimal']);

const getProPreviewSkuTemplateUrl = sku =>
  `${Config.get('proPreviewApi')}templates/for-sku/${sku}?strict=true`;

const getProPreviewGenerateUrl = () => `${Config.get('proPreviewApi')}generate/`;

const getProPreviewRotationCommand = angle => ({
  name: 'rotate',
  args: {
    angle
  }
});

const getProPreviewCommands = commands => {
  const cmds = [];

  // get 'rotate' command
  if (commands && commands.changeOrientation) {
    cmds.push(getProPreviewRotationCommand(90));
  }
  return cmds;
};

const getProPreviewGenerateBody = (templateName, sku, areas, commands) => {
  return areas && areas.length
    ? {
        template: templateName,
        areas: areas.map(area => {
          // Try match pro preview are by space Id
          let matchedSpace = sku.spaces.find(s => s.id === area.spaceId);
          if (!matchedSpace) {
            console.debug(
              'ProPreview area image by space Id not found, attempting to look up by layer Id.',
              area
            );
            // try to match by layer Id
            matchedSpace = sku.spaces.find(
              s => s.il && s.il.layers.find(l => l.layerId === area.layerId)
            );
            if (matchedSpace) {
              console.debug('ProPreview area image found by layer Id.', area);
            } else {
              console.error(
                'ProPreview area image not found, will attempt to use first space image.',
                area
              );
            }
          }

          return matchedSpace
            ? {
                layerId: area.layerId,
                spaceId: area.spaceId,
                url: matchedSpace.smallPrintImgUrl,
                commands: getProPreviewCommands(commands)
              }
            : {
                url: sku.spaces[0].smallPrintImgUrl,
                commands: getProPreviewCommands(commands)
              };
        })
      }
    : {
        template: templateName,
        areas: [
          {
            url: sku.spaces[0].smallPrintImgUrl,
            commands: getProPreviewCommands(commands)
          }
        ]
      };
};

const getAuthQueryParams = () => ({
  queryParams: {
    recipeId: Config.get('recipeId'),
    partnerBillingKey: Config.get('storeApiKey'),
    staging: Config.get('env') === 'staging'
  }
});

class ProPreviewService {
  *getProPreviewTemplates(skus) {
    const requests = [];
    const proPreviewTemplates = {};
    skus.forEach(sku => {
      // validate sku
      if (whiteListedProPreviewSKUs.has(sku)) {
        requests.push(
          fetch(getProPreviewSkuTemplateUrl(sku), getAuthQueryParams()).then(res => {
            proPreviewTemplates[sku] = res.templates.filter(t =>
              whiteListedProPreviewStyle.has(t.style)
            );
          })
        );
      }
    });

    yield Promise.all(requests);

    return proPreviewTemplates;
  }

  generateProPreview(templateName, sku, areas, commands) {
    return postJson(
      getProPreviewGenerateUrl(),
      getProPreviewGenerateBody(templateName, sku, areas, commands),
      getAuthQueryParams()
    );
  }
}

export default new ProPreviewService();
