import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

const storesStateSelector = state => state.getIn(['data', 'stores']) || fromJS({});
const connectStateSelector = state => state.getIn(['data', 'stores', 'connect']) || fromJS({});

export const allStoresSelector = createSelector(
  [storesStateSelector],
  storesState => storesState.get('stores') || fromJS([])
);
export const connectStepSelector = createSelector([connectStateSelector], connectState =>
  connectState.get('step')
);
export const connectProviderSelector = createSelector([connectStateSelector], connectState =>
  connectState.get('provider')
);
