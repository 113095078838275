import { connect } from 'react-redux';
import {
  isReadySelector,
  regionsActiveSelector,
  subCategoriesActiveSelector
} from './SKUsSelectionViewSelectors';
import { flowSelectedIndexSelector } from './components/ProductSelection/ProductSelectionSelectors';
import {
  selectedProductSelector,
  publishInProgressSelector,
  topNotificationSelector
} from './components/ProductSelection/components/HubProductSelection/HubProductSelectionSelectors';
import { showTopNotification } from './components/ProductSelection/components/HubProductSelection/HubProductSelectionActions';
import { selectedGootenProductSelector } from '../LinkProductSelectors';
import { INIT, setReady } from './SKUsSelectionViewActions';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';
import SKUsSelectionView from './SKUsSelectionView';

const mapDispatchToProps = {
  init: providerPath => fetchAsync(INIT, { providerPath }),
  onUnmount: () => setReady(false),
  resetTopNotification: () => showTopNotification(false)
};

const mapStateToProps = state => {
  // flowSelectedIndex 1 => New Product
  // flowSelectedIndex 0 => Saved(HUB) Product */}
  const flowSelectedIndex = flowSelectedIndexSelector(state);

  return {
    ready: isReadySelector(state),
    regionsActive: regionsActiveSelector(state),
    subCategoriesActive: subCategoriesActiveSelector(state),
    selectedProduct: flowSelectedIndex
      ? selectedGootenProductSelector(state)
      : selectedProductSelector(state),
    flowSelectedIndex: flowSelectedIndexSelector(state),
    publishInProgress: publishInProgressSelector(state),
    topNotification: topNotificationSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SKUsSelectionView);
