import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Card from 'gooten-components/src/components/shared/Card';
import SecurityService from '../../../../../Settings/SecurityService';

const PartnerInfoCard = props => {
  const { summaryData, billingData } = props;

  return (
    <>
      <Card title="Partner Info">
        <div className="d-flex justify-content-between partner-name-row">
          <p className="partner-name-key">Partner Name</p>
          {SecurityService.isSuperAdmin() ? (
            <p>
              <Link
                className="no-text-decoration ml-3"
                to={`/admin/partnerDetails/${summaryData.PartnerInternalId}/Basic`}
              >
                {summaryData.PartnerName || '-'}
              </Link>
            </p>
          ) : (
            <p className="partner-name-value">{summaryData.PartnerName || '-'}</p>
          )}
        </div>
        <div className="d-flex justify-content-between partner-id-row">
          <p className="partner-id-key">Partner Id</p>
          <p className="partner-id-value">{summaryData.PartnerInternalId || '-'}</p>
        </div>
        {SecurityService.isSuperAdmin() && (
          <div className="d-flex justify-content-between partner-group-id-row">
            <p className="partner-group-id-key">Partner Group</p>
            <p className="partner-group-id-value">{summaryData.PartnerGroupName || 'N/A'}</p>
          </div>
        )}
        <div className="d-flex justify-content-between vim-status-row">
          <p className="vim-status-key">VIM Status</p>
          <p className="vim-status-value">{summaryData.PartnerTierSettings?.Status || '-'}</p>
        </div>
        <div className="d-flex justify-content-between partner-credit-terms-row">
          <p className="partner-credit-terms-key">Credit Terms</p>
          <p className="partner-credit-terms-value">
            {billingData.Billing ? (billingData.Billing.IsOnCredit ? 'Yes' : 'No') : '-'}
          </p>
        </div>
        <div className="d-flex justify-content-between partner-order-id-row">
          <p className="partner-order-id-key">Partner Order ID</p>
          <p className="partner-order-id-value">{summaryData.PartnerId || '-'}</p>
        </div>
        <div className="d-flex justify-content-between partner-contact-row">
          <p className="partner-contact-key">Contact</p>
          <p className="partner-contanct-value">{summaryData.AccountRep || '-'}</p>
        </div>
        <div className="d-flex justify-content-between partner-email-row">
          <p className="partner-email-key">Email</p>
          <p className="partner-email-value">
            {summaryData.AccountRep ? (
              <a href={`mailto:${summaryData.RepEmail}`}>{summaryData.RepEmail}</a>
            ) : (
              '-'
            )}
          </p>
        </div>
        <div className="d-flex justify-content-between partner-phone-row">
          <p className="partner-phone-key">Phone</p>
          <p className="partner-phone-value">{summaryData.RepPhone || '-'}</p>
        </div>
        <div className="d-flex justify-content-between partner-standing">
          <p className="partner-standing-key">Standing</p>
          <p className="partner-standing-value">good</p>
        </div>
      </Card>
      <style jsx>
        {`
          .order-page-content {
            background: white;
          }
        `}
      </style>
    </>
  );
};

PartnerInfoCard.propTypes = {
  summaryData: PropTypes.object.isRequired,
  billingData: PropTypes.object.isRequired
};

export default PartnerInfoCard;
