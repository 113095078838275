import { connect } from 'react-redux';
import ProductTags from './ProductTags';
import { tagsSelector } from './ProductTagsSelectors';
import { addTag, removeTag, newTag } from './ProductTagsActions';
import { selectedTagsSelector, storeIdSelector } from '../../ProvidersTab/ProvidersTabSelectors';
import { setError } from '../../../ProductPublishActions';

const mapDispatchToProps = dispatch => ({
  setError: (storeId, section, message) => dispatch(setError({ storeId, section, message })),
  addTag: (storeId, item) => dispatch(addTag({ storeId, item })),
  removeTag: (storeId, item) => dispatch(removeTag({ storeId, item })),
  newTag: (storeId, item) => {
    dispatch(newTag({ item }));
    dispatch(addTag({ storeId, item }));
  }
});

const mapStateToProps = state => {
  return {
    tags: tagsSelector(state),
    selectedTags: selectedTagsSelector(state),
    storeId: storeIdSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTags);
