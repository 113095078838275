import SettingsLayout from './Layout';
import ProfileView from './Views/ProfileView';
import BillingView from './Views/BillingView';
import FulfillmentView from './Views/FulfillmentView';
import ApiView from './Views/ApiView';
import TeamView from './Views/TeamView';
import AddOnsView from './Views/AddOnsView';
import ActivityView from './Views/ActivityView';
import PartnerTierView from './Views/PartnerTierView';
import PackingSlips from '../Settings/Cards/PackingSlips/PackingSlips';

const settingsRoutes = {
  path: '/settings',
  component: SettingsLayout,
  indexRoute: { onEnter: (nextState, replace) => replace('settings/profile') },
  childRoutes: [
    {
      path: 'profile/vim',
      component: PartnerTierView
    },
    {
      path: 'profile(/:card)',
      component: ProfileView
    },
    {
      path: 'billing(/:card)',
      component: BillingView
    },
    {
      path: 'api(/:card)',
      component: ApiView
    },
    {
      path: 'team(/:card)',
      component: TeamView
    },
    {
      path: 'fulfillment(/:card)',
      component: FulfillmentView
    },
    {
      path: 'add-ons/packingslips',
      component: PackingSlips
    },
    {
      path: 'add-ons(/:card)',
      component: AddOnsView
    },
    {
      path: 'activity(/:card)',
      component: ActivityView
    }
  ]
};

export default settingsRoutes;
