import React from 'react';
import Button from 'gooten-components/src/components/shared/Button';

export const VimPromotionCard = () => {
  return (
    <>
      <div className="d-flex mb-4 base-card">
        <div className="d-flex flex-column">
          <img
            className="vim-logo mb-3"
            src="https://adminassets.azureedge.net/admin-assets/img/vim-promotion-card-logo-1.png"
          />
          <p className="caption-text mb-4">
            VIM, our new loyalty program, gives you access to exclusive industry offers, curated
            content, personalized service, and much more.
          </p>
          <p className="caption-text mb-2 mt-2" />
          <a className="mt-4" href="https://www.gooten.com/merchants/vim" target="_blank">
            <Button iconRight="arrowUpRight" className="button-primary extra-large mt-0">
              Learn more
            </Button>
          </a>
        </div>
        <img
          className="promotion-logo"
          src="https://adminassets.azureedge.net/admin-assets/img/vim-promotino-card-logo.svg"
          alt="promotion-card-logo"
        />
      </div>
      <style jsx>
        {`
          :global(.base-card button) {
            margin-top: 2rem;
            width: max-content !important;
            padding: 12px 20px 13.5px 20px !important;
          }
          .base-card {
            margin-bottom: -2rem;
            padding: 2rem;
            background: #fcfcfc;
            border-radius: 4px;
          }
          .vim-logo {
            max-width: 250px;
            min-width: 182px;
            height: auto;
            margin-left: -12px;
          }
          .caption-text {
            font-size: 15px;
            color: #373f49 !important;
          }
          .flex-column {
            flex: 3;
          }
          .promotion-logo {
            flex: 2;
            max-width: 183px;
            max-height: 409px;
            width: auto;
            height: auto;
          }
          a {
            width: max-content;
          }
          @media (min-width: 768px) {
            .promotion-logo {
              max-width: 250px;
            }
          }
          @media (max-width: 576px) {
            .promotion-logo {
              display: none;
              flex: 0;
            }
          }
        `}
      </style>
    </>
  );
};
