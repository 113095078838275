import { postJson } from '../../../../utils/http';
import Config from '../../../../config';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId')
    }
  };
};

const getPartnerTaxUrl = () => `${Config.get('api')}v/5/source/api/partnertaxestimate/`;

class PriceEstimateService {
  getPartnerTaxEstimate(request) {
    return new Promise((resolve, reject) => {
      postJson(getPartnerTaxUrl(), request, getAuthQueryParams())
        .then(res => {
          if (!res.error) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

// singleton
export default new PriceEstimateService();
