import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import PastOrdersListItem from './PastOrdersListItem';

export const PastOrdersList = props => {
  const outOfStockItems = props.outOfStockItems;
  const selectedItems = props.selectedItems;
  const editDesign = props.editDesign;
  return (
    <div>
      {props.orderItems.map(orderItem => (
        <PastOrdersListItem
          key={orderItem.orderItemId}
          orderItem={orderItem}
          isReOrderable={!!orderItem.IsReOrderable}
          isOutOfStock={!!outOfStockItems.find(i => i.id === orderItem.productId)}
          isSelected={!!selectedItems.find(i => i.orderItemId === orderItem.orderItemId)}
          onOrderItemSelectionToggled={props.onOrderItemSelectionToggled}
          editDesign={() => editDesign(orderItem)}
        />
      ))}
    </div>
  );
};

PastOrdersList.propTypes = {
  orderItems: PropTypes.instanceOf(List),
  outOfStockItems: PropTypes.instanceOf(List),
  selectedItems: PropTypes.instanceOf(List),
  onOrderItemSelectionToggled: PropTypes.func.isRequired,
  editDesign: PropTypes.func.isRequired
};

export default PastOrdersList;
