import { injectSaga } from '../../store/sagas';
import CartContainer from './CartContainer';
import CartSaga from './CartSaga';

const componentName = 'cart';

export default store => {
  injectSaga(store, { key: componentName, saga: CartSaga });

  return CartContainer;
};
