import { Record } from 'immutable';
import { getProductImageResizerUrl } from 'gooten-js-utils/src/url';

// Hold data for 1 product either storage or store
const Product = new Record({
  id: 0,
  name: '',
  description: '', // can be html here
  image: '', // resized image
  sourceImage: '', // original sized image
  type: '', // storage | store
  status: undefined, // storage | published | unpublished
  state: undefined, // used for tiktok product
  failedReason: undefined, // used for tiktok product
  collections: [], // empty in case of storage, array of strings in case of store
  tags: [], // empty in case of storage, array of strings in case of store
  options: [], // empty in case of storage, array of strings in case of store
  // equal to number of skus in case of storage, equal to total variants in case of store
  totalVariants: 0,
  connectedVariants: 0,
  // here goes meta and fields which parsed out from meta upon mapping
  meta: [], // empty in case of storage, array of objects in case of store
  storeId: 0,
  storeName: '',
  handle: '', // used for shopify product link
  productType: '', // empty in case of storage
  variants: [], // should be populated on edit,
  disabledSkus: [],
  personalize: undefined // tells us if product is marked for personalization (NeedsCustomization flow)
});

export default Product;

export const mapProduct = product => {
  if (product.status === 'storage') {
    return mapStorageProduct(product);
  } else {
    return mapStoreProduct(product);
  }
};

export const mapDiffProduct = p =>
  new Product({
    id: p.id,
    name: p.name,
    description: p.description_html,
    image: p.image_url ? getProductImageResizerUrl(p.image_url) : null,
    sourceImage: p.image_url,
    type: 'store',
    status: p.status,
    state: p.state,
    failedReason: p.failed_reason,
    productType: p.product_type,
    collections: p.collections,
    tags: p.tags,
    options: p.options,
    totalVariants: p.total_variants,
    connectedVariants: p.connected_variants,
    variants: p.variants,
    meta: p.meta,
    // storeId: p.meta.find(m => m.name === 'store_id').value,
    // storeName: p.meta.find(m => m.name === 'store_name').value,
    // handle: p.meta.find(m => m.name === 'handle').value,
    // productType: p.meta.find(m => m.name === 'product_type').value
    disabledSkus: p.disabled_skus,
    personalize: p.personalize
  });

export const mapStoreProduct = product =>
  new Product({
    id: product.id,
    name: product.name,
    description: product.description_html,
    image: product.image_url ? getProductImageResizerUrl(product.image_url) : null,
    sourceImage: product.image_url,
    type: 'store',
    status: product.status,
    state: product.state,
    failedReason: product.failed_reason,
    collections: product.collections.map(x => ({ id: x.id, name: x.title })),
    tags: product.tags,
    options: product.options,
    totalVariants: product.total_variants,
    connectedVariants: product.connected_variants,
    meta: product.meta,
    storeId: product.meta.find(m => m.name === 'store_id').value,
    storeName: product.meta.find(m => m.name === 'store_name').value,
    handle: product.meta.find(m => m.name === 'handle').value,
    productType: product.meta.find(m => m.name === 'product_type').value,
    disabledSkus: product.disabled_skus,
    personalize: product.personalize
  });

export const mapStorageProduct = product =>
  new Product({
    id: product.id,
    name: product.name,
    description: product.description_html,
    image: product.image_url ? getProductImageResizerUrl(product.image_url) : null,
    sourceImage: product.image_url,
    type: 'storage',
    status: 'storage',
    disabledSkus: product.disabled_skus,
    personalize: product.personalize
  });
