import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'ORDER_ITEMS_SELECTION');

export const GET_ARCHIVED_STATUSES = getActionName.async('GET_ARCHIVED_STATUSES');

export function getArchivedStatuses(vendorId, skus) {
  return {
    type: GET_ARCHIVED_STATUSES.ASYNC,
    vendorId,
    payload: { vendorId, skus }
  };
}
