import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../shared/Alert';

const Embroidery = props => {
  return (
    <React.Fragment>
      <div className="embroidery-content">
        <Alert
          isOpen
          buttonText="View guide"
          buttonClassName="button-primary"
          iconClassName="maximize2"
          textButtonClick={props.onClick}
        >
          Embroidery Guide
        </Alert>
      </div>
      <style jsx>{`
        margin-bottom: 1rem;
      `}</style>
    </React.Fragment>
  );
};

Embroidery.propTypes = {
  onClick: PropTypes.func
};

export default Embroidery;
