import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';

export const createImmutableSelector = createSelectorCreator(defaultMemoize, (a, b) => {
  if (Immutable.Iterable.isIterable(a) && Immutable.Iterable.isIterable(b)) {
    return Immutable.is(a, b);
  } else {
    return a === b;
  }
});
