import React, { useState, useEffect } from 'react';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MockupsFullscreen from './MockupsFullscreen';
import Spinner from '../../../shared/Spinner';
import ErrorMessage from '../shared/ErrorMessage';
import './Mockups.scss';
import Button from '../../../shared/Button';
import Checkbox from '../../../shared/Checkbox';
import Modal from '../../../shared/Modal';
import Thumbnail from '../../../shared/Thumbnail';

const Container = styled.div`
  flex-direction: column;
  margin-bottom: 58px;

  .publish-error-message {
    margin-top: -10px;
    margin-bottom: 10px;
    align-items: baseline;
    height: auto;

    &.no-mockups-error {
      margin-top: 36px;
      color: #717176;
    }
  }

  div.btn-view-all {
    margin-bottom: 14px;
  }

  .upload-holder {
    margin-top: 10px;
    cursor: pointer;

    .icon-checkbox-selected,
    .icon-checkbox-empty {
      margin-right: 6px;
    }
  }
`;

const Mockups = props => {
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [mainMockupUrl, setMainMockupUrl] = useState();

  // Error messages
  const errors = {
    maxNumberOfImages: `${
      props.providerName && props.providerName.charAt(0).toUpperCase() + props.providerName.slice(1)
    } only allows ${props.maxNumberOfImages} images per product.
      Everything above this number won't be published.`
  };

  useEffect(() => {
    let err = props.mockups.size > props.maxNumberOfImages ? errors.maxNumberOfImages : null;

    setError(err);
    props.setError(props.storeId, 'Mockups', err);
  }, []);

  const mockups = props.mockups.slice(0, 6).map((m, i) => (
    <div key={i} className="w-25">
      <Thumbnail image={m.get('imageUrl')} key={`${m}${i}`} />
    </div>
  ));

  const handleSelectItem = item => {
    setMainMockupUrl(item);
  };

  const handleSaveMainMockup = () => {
    props.saveMainMockup(mainMockupUrl);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMainMockupUrl(null);
  };

  return (
    <Container
      className="product-publish-mockups flex-container"
      mockupsSize={props.mockups.size}
      isLoading={props.isLoading}
    >
      <div className="title-holder d-flex justify-content-between align-items-center">
        <span className="title-2">Mockups</span>
        <Button
          className="edit-mockup button-default small"
          onClick={() => props.gotoPreviewsPage()}
          loading={props.isLoading || props.isWorking}
          disabled={props.isLoading || props.isWorking}
        >
          Edit
        </Button>
      </div>
      {!props.mockups.size ? null : (
        <div
          className="images-grid d-flex flex-wrap"
          onClick={() => (props.isLoading ? null : setModalOpen(true))}
        >
          {props.isLoading ? <Spinner customClass="mockups" /> : mockups}
        </div>
      )}
      {error ? <ErrorMessage message={error} /> : null}
      <React.Fragment>
        {props.mockups.size > 6 ? (
          <Button className="button-default medium" onClick={() => setModalOpen(true)}>
            View all
          </Button>
        ) : null}
        {props.hideUploadMockups || !props.mockups.size ? null : (
          <div className="upload-holder flex-container">
            <Checkbox
              onChange={() => props.toggleUpdatePreview(props.storeId, !props.updatePreview)}
              checked={props.updatePreview}
              className="w-100"
              label="Upload mockups to store"
            />
          </div>
        )}
        {!props.mockups.size ? (
          <ErrorMessage
            message="No mockups exist for this product, click the edit button above to add mockups"
            customClass="no-mockups-error"
          />
        ) : null}
      </React.Fragment>
      <Modal
        isOpen={isModalOpen}
        className="medium"
        cancelClick={() => handleCloseModal()}
        primaryClick={handleSaveMainMockup}
        actionText="Save"
        title="Select main mockup"
      >
        <MockupsFullscreen
          mockups={props.mockups}
          mainMockupUrl={mainMockupUrl}
          onSelectItem={handleSelectItem}
        />
      </Modal>
      <style jsx>
        {`
          .images-grid {
            height: ${props.mockups.size > 4 ? '156px' : 'fit-content'};
            overflow-y: hidden;
            cursor: ${props.isLoading ? 'auto' : 'pointer'};
          }
          :global(.spinner-container.mockups) {
            position: sticky;
            margin: 2rem 0;
          }
        `}
      </style>
    </Container>
  );
};

Mockups.propTypes = {
  isLoading: PropTypes.bool,
  mockups: PropTypes.instanceOf(List),
  hideUploadMockups: PropTypes.bool,
  gotoPreviewsPage: PropTypes.func,
  setError: PropTypes.func,
  storeId: PropTypes.number,
  providerName: PropTypes.string,
  saveMainMockup: PropTypes.func,
  toggleUpdatePreview: PropTypes.func,
  updatePreview: PropTypes.bool,
  // validation below
  maxNumberOfImages: PropTypes.number
};

export default Mockups;
