import React from 'react';
import PropTypes from 'prop-types';
import Log from '../../../services/logService';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
      .isRequired
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    const errorMsg = `Component ${error.friendlyMsg || error}`;
    Log.error(error, errorMsg, { ...error.details, info });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h2 style={{ textAlign: 'center' }}>Something went wrong :(</h2>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
