import hubRoutes from '../Hub/HubRoutes';
import ordersRoutes from '../Orders/OrdersRoutes';
import customOrderFormRoutes from '../CustomOrderForm/CustomOrderFormRoutes';
import settingsRoutes from '../Settings/SettingsRoutes';
import onboardingDashboardRoutes from '../OnboardingDashboard/OnboardingDashboardRoutes';
import storesRoutes from '../Stores/StoresRoutes';
import reprintRoutes from '../ReprintSelfService/ReprintSelfServiceRoutes';
import storeBulkCreationRoutes from '../Tools/StoreBulkCreation/StoreBulkCreationRoutes';
import storeBulkMigrationRoutes from '../Tools/StoreBulkMigration/StoreBulkMigrationRoutes';

const createRoutes = () => {
  return [
    hubRoutes,
    ordersRoutes,
    customOrderFormRoutes,
    settingsRoutes,
    onboardingDashboardRoutes,
    storesRoutes,
    reprintRoutes,
    storeBulkCreationRoutes,
    storeBulkMigrationRoutes
  ];
};

export default createRoutes;
