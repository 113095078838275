import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../shared/Modal';
import Alert from '../../../../shared/Alert';
import Card from '../../../../shared/Card';
import Digitization from '../content/Digitization';
import OrderSample from '../content/OrderSample';
import PreparingArtwork from '../content/PreparingArtwork';
import { templatesUrl } from '../EmbroiderySelectors';

const EmbroideryGuide = props => {
  const clickOnDownload = () => {
    window.open(templatesUrl() || '', '_blank');
  };

  const addCard = (title, body) => (
    <Card type="fullwidth" margin="1.5rem 0 0 0" collapsible title={title}>
      {body}
    </Card>
  );

  return (
    <React.Fragment>
      <div className="embroidery-guide-content">
        <Modal
          isOpen={props.isOpen}
          title={'Embroidery Guide'}
          cancelClick={props.onClose}
          cancelText="Done"
          className="medium"
        >
          <div className="inner-content">
            <Alert
              isOpen
              buttonText="Download"
              buttonClassName="button-primary"
              iconClassName="maximize2"
              textButtonClick={clickOnDownload}
            >
              Embroidery template
            </Alert>
            {addCard('Preparing Artwork', <PreparingArtwork />)}
            {addCard('Digitization', <Digitization />)}
            {addCard('Order a sample', <OrderSample />)}
          </div>
          <style jsx>{`
            margin: 1.5rem;
          `}</style>
        </Modal>
      </div>
    </React.Fragment>
  );
};

EmbroideryGuide.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default EmbroideryGuide;
