import React from 'react';
import PropTypes from 'prop-types';
import { hashHistory } from 'react-router';
import { List } from 'immutable';
import Loader from 'gooten-components/src/components/shared/Loader';
import PlaceOrderLine from './components/PlaceOrderLine';
import AddToCart from './components/AddToCart';
import { mapEditDesignItemFromProductVariant } from '../../models/EditDesignItem';
import PageHeader from 'gooten-components/src/components/shared/PageHeader';
import PageBody from 'gooten-components/src/components/shared/PageBody';

class PlaceOrderView extends React.PureComponent {
  componentDidMount() {
    // first check do we have selected product(s)
    if (!this.props.selectedProducts.size) {
      hashHistory.push('/hub');
      return;
    }

    // check is this sample order...
    if (this.props.router.location.query?.sample === 'true') {
      this.props.setOrderAsSample();
    }

    // first check if we have variants loaded
    const variantsLoaded = !this.props.selectedProducts.some(p => !p.get('variants').size);

    if (!variantsLoaded) {
      this.props.loadVariants(this.props.selectedProducts);
    }
  }

  handleEditItem = (productID, variantID) => {
    // we will use Edit Design from COF
    // but first we have to prepare all needed data
    const productToEdit = this.props.selectedProducts.find(p => p.get('id') === productID);
    const variantToEdit =
      productToEdit && productToEdit.get('variants').find(v => v.get('id') === variantID);

    if (variantToEdit) {
      this.props.removeVariant(variantID);
      this.props.editDesign(mapEditDesignItemFromProductVariant(variantToEdit));
      // eslint-disable-next-line
      this.props.router.push('/hub/edit-design/place-order');
    }
  };

  render() {
    const lines = this.props.selectedProducts.map(p => (
      <PlaceOrderLine
        key={p.get('id')}
        item={p}
        onSelectVariantID={this.props.selectVariant}
        onSelectAll={this.props.selectAll}
        onEditItem={this.handleEditItem}
        selectedVariantIDs={this.props.selectedVariantIDs}
      />
    ));

    return (
      <div className="place-order-view">
        <PageHeader size="standard" title="Place an Order" customButtons={<AddToCart />} />
        <PageBody>
          {this.props.isReady ? <div className="products-list">{lines}</div> : <Loader />}
        </PageBody>
      </div>
    );
  }
}

PlaceOrderView.propTypes = {
  selectedProducts: PropTypes.instanceOf(List).isRequired,
  loadVariants: PropTypes.func.isRequired,
  selectVariant: PropTypes.func.isRequired,
  removeVariant: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  selectedVariantIDs: PropTypes.instanceOf(List).isRequired,
  selectAll: PropTypes.func.isRequired,
  editDesign: PropTypes.func.isRequired,
  setOrderAsSample: PropTypes.func.isRequired
};

export default PlaceOrderView;
