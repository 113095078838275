import { RESET } from '../../../../store/actions/globalActions';
import { UPDATE_SHIPPING_COUNTRY, CLEAR_CART } from '../../../../store/actions/dataActions';
import {
  SET_FORM_VISIBLE,
  FETCH_SHIPPING_ADDRESSES,
  STORE_USER_ADDRESS,
  DELETE_SHIPPING_ADDRESS,
  DELETE_USER_ENTERED_SHIPPING_ADDRESS
} from './AddressSelectorActions';
import { SET_SHIPPING_ADDRESS, PLACE_ORDER } from './../../CheckoutActions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as AddressFormReducers from './AddressForm/AddressFormReducer';

// ------------------------------------
// Reducer
// ------------------------------------
const formVisibleReducer = (state = false, action) => {
  switch (action.type) {
    case SET_FORM_VISIBLE:
      return action.payload.isVisible;
    case FETCH_SHIPPING_ADDRESSES.SUCCESS:
      return action.payload.shippingAddresses.size === 0;
    case RESET:
      return false;
    default:
      return state;
  }
};

const loadedReducer = (state = false, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_ADDRESSES.SUCCESS:
      return true;
    case UPDATE_SHIPPING_COUNTRY:
      return false;
    default:
      return state;
  }
};

const itemsReducer = (state = fromJS([]), action) => {
  switch (action.type) {
    case FETCH_SHIPPING_ADDRESSES.SUCCESS:
      return action.payload.shippingAddresses;
    case DELETE_SHIPPING_ADDRESS.SUCCESS:
      return state.filterNot(item => item.id === action.payload.addressId);
    case UPDATE_SHIPPING_COUNTRY:
      return fromJS([]);
    default:
      return state;
  }
};

const userItemsReducer = (state = fromJS([]), action) => {
  switch (action.type) {
    case STORE_USER_ADDRESS:
      return fromJS([action.payload.address]);
    case DELETE_USER_ENTERED_SHIPPING_ADDRESS:
      return state.filterNot(item => item.get('id') === action.payload.addressId);
    case UPDATE_SHIPPING_COUNTRY:
      return fromJS([]);
    case PLACE_ORDER.SUCCESS:
      // If 'save address' checkbox was checked, delete the address from state
      // to prevent showing duplicate address, because newly saved address will be loaded from API
      return state.filterNot(
        item =>
          item.get('save') && item.get('id') === action.payload.selectedShippingAddress.get('id')
      );
    default:
      return state;
  }
};

const totalAddressesReducer = (state = 0, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_ADDRESSES.SUCCESS:
      return action.payload.totalAddresses;
    case DELETE_SHIPPING_ADDRESS.SUCCESS:
      return state - 1;
    case UPDATE_SHIPPING_COUNTRY:
      return 0;
    default:
      return state;
  }
};

const currentPageReducer = (state = 1, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_ADDRESSES.ASYNC:
      return action.payload;
    case UPDATE_SHIPPING_COUNTRY:
      return 1;
    default:
      return state;
  }
};

const loadedPageReducer = (state = 1, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_ADDRESSES.SUCCESS:
      return action.payload.loadedPage;
    case UPDATE_SHIPPING_COUNTRY:
      return 1;
    default:
      return state;
  }
};

const selectedDefaultState = fromJS({
  address: undefined
});
const selectedReducer = (state = selectedDefaultState, action) => {
  switch (action.type) {
    case SET_SHIPPING_ADDRESS:
      return state.set('address', action.payload.address);
    case CLEAR_CART:
      return selectedDefaultState;
    case UPDATE_SHIPPING_COUNTRY:
      return selectedDefaultState;
    case PLACE_ORDER.SUCCESS:
      return selectedDefaultState;
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  loaded: loadedReducer,
  formVisible: formVisibleReducer,
  items: itemsReducer,
  userItems: userItemsReducer,
  totalAddresses: totalAddressesReducer,
  currentPage: currentPageReducer,
  loadedPage: loadedPageReducer,
  selected: selectedReducer,
  userEntered: AddressFormReducers.userEnteredReducer,
  suggested: AddressFormReducers.suggestedReducer,
  temp: AddressFormReducers.tempReducer
});

// make root reducer
export default (state, action) => {
  return combinedReducer(state, action);
};
