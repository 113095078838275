import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InviteTeamMember from '../Cards/InviteTeamMember';
import PendingInvitations from '../Cards/PendingInvitations';
import YourTeam from '../Cards/YourTeam';
import ProfileInformation from '../Cards/ProfileInformation';
import PasswordByEmail from '../Cards/PasswordByEmail';
import security from '../SecurityService';

const Container = styled.div``;
class TeamView extends React.Component {
  render() {
    return security.access('page', 'team') ? (
      <Container>
        <InviteTeamMember />
        <PendingInvitations />
        <YourTeam />
        {this.props.location.query.user ? (
          <ProfileInformation
            userId={this.props.location.query.user}
            disabled={!security.isPartnerOwner()}
          />
        ) : null}
        {this.props.location.query.user && security.isPartnerOwner() ? (
          <PasswordByEmail userId={this.props.location.query.user} />
        ) : null}
      </Container>
    ) : null;
  }
}

TeamView.propTypes = {
  location: PropTypes.object.isRequired
};

export default TeamView;
