import React, { useState } from 'react';
import DropDown from 'gooten-components/src/components/shared/DropDown';
import ListItem from 'gooten-components/src/components/shared/ListItem';
import Pager from 'gooten-components/src/components/shared/Pager';

export const TableFooter = ({
  total,
  pageSize,
  onPageSizeChanged,
  currentPage,
  onCurrentPageChanged
}) => {
  const [openPageDropDown, setOpenPageDropDown] = useState(false);

  const pageShowingCalculation = (pageSize, pageNumber, totalItems) => {
    pageSize = parseInt(pageSize);
    pageNumber = parseInt(pageNumber);
    totalItems = parseInt(totalItems);
    const showingFrom = pageSize * (pageNumber - 1);
    const showingTo = showingFrom + pageSize;
    return `Showing ${showingFrom} - ${showingTo} of ${totalItems}`;
  };

  const paginationItems = (totalNumber, divider) => {
    let i = 1;
    const items = [];

    totalNumber = totalNumber / divider;

    while (i < totalNumber + 1) {
      items.push({ name: i, id: i });
      i++;
    }

    return items;
  };

  return (
    <div className="footer-container d-flex align-items-center justify-content-between flex-wrap ml-n2 mr-n2">
      <div className="page-size-container m-2">
        <DropDown
          bottomAlign
          isOpen={openPageDropDown}
          toggle={() => setOpenPageDropDown(!openPageDropDown)}
          title={`Show ${pageSize}`}
        >
          <ListItem
            text="10"
            onSelect={() => {
              setOpenPageDropDown(!openPageDropDown);
              onPageSizeChanged(10);
            }}
          />
          <ListItem
            text="25"
            onSelect={() => {
              setOpenPageDropDown(!openPageDropDown);
              onPageSizeChanged(25);
            }}
          />
          <ListItem
            text="50"
            onSelect={() => {
              setOpenPageDropDown(!openPageDropDown);
              onPageSizeChanged(50);
            }}
          />
        </DropDown>
      </div>
      <div className="m-2">{pageShowingCalculation(pageSize, currentPage, total)}</div>
      <div className="m-2">
        {pageSize && total && (
          <Pager
            current={currentPage - 1}
            maxSize={10}
            items={paginationItems(total, pageSize)}
            goTo={page => onCurrentPageChanged(page + 1)}
          />
        )}
      </div>
      <style jsx>{`
          .pager-container div:nth-child(1) {
            margin-right: 16px;
          }
          .pager-container div:nth-child(2) {
            flex: 1;
        `}</style>
    </div>
  );
};

export default TableFooter;
