import { connect } from 'react-redux';
import ProfileInformation from './ProfileInformation';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { initFor, changeFor, updateFor } from '../BaseCard/BaseCardActions';
import { uploadStart, uploadDone, uploadFailed } from './ProfileInformationActions';

const init = initFor(ProfileInformation.name);
const change = changeFor(ProfileInformation.name);
const update = updateFor(ProfileInformation.name);
const cardSelector = cardSelectorFor(ProfileInformation.name);

const mapDispatchToProps = {
  init,
  change,
  update,
  uploadStart,
  uploadDone,
  uploadFailed
};

const mapStateToProps = (state, ownProps) => {
  const cardState = cardSelector(state);
  return {
    logo: cardState.get('logo'),
    data: cardState.get('data'),
    unmodified: cardState.get('unmodified'),
    ready: !cardState.get('loading') && !cardState.get('busy')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInformation);
