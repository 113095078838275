import {
  SET_USER_ENTERED_SHIPPING_ADDRESS,
  SET_SUGGESTED_ADDRESS,
  DELETE_SUGGESTED_ADDRESS,
  STORE_TEMP_ADDRESS,
  CLEAR_TEMP_ADDRESS
} from './AddressFormActions';
import { fromJS } from 'immutable';

// ------------------------------------
// Reducer
// ------------------------------------
export const userEnteredReducer = (state = fromJS({ address: undefined }), action) => {
  switch (action.type) {
    case SET_USER_ENTERED_SHIPPING_ADDRESS:
      return state.set('address', action.payload.address);
    case SET_SUGGESTED_ADDRESS:
      return state.set('isValid', action.payload.suggestedAddress.get('IsValid'));
    default:
      return state;
  }
};

const suggestedDefaultState = {
  address: undefined,
  isValid: false
};

export const suggestedReducer = (state = fromJS(suggestedDefaultState), action) => {
  switch (action.type) {
    case SET_SUGGESTED_ADDRESS:
      let normalizedSuggestedAddress = action.payload.suggestedAddress.get('ProposedAddress');
      return state
        .set('address', fromJS(normalizedSuggestedAddress))
        .set('isValid', action.payload.suggestedAddress.get('IsValid'));
    case DELETE_SUGGESTED_ADDRESS:
      return state.set('address', undefined).set('isValid', false);
    default:
      return state;
  }
};

export const tempReducer = (state = fromJS({ address: undefined }), action) => {
  switch (action.type) {
    case STORE_TEMP_ADDRESS:
      return state.set('address', action.payload.tempAddress);
    case CLEAR_TEMP_ADDRESS:
      return state.set('address', undefined);
    default:
      return state;
  }
};
