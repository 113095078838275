import React from 'react';
import PropTypes from 'prop-types';
import Button from 'gooten-components/src/components/shared/Button';
import { Cell } from 'gooten-components/src/components/shared/Table';

export const HubSKUsListItem = props => {
  const options = props.item.get('options');
  const columns =
    options && options.size ? (
      options.map(o => {
        return (
          <Cell
            className="option-column hidden-sm hidden-xs"
            key={props.item.get('sku') + o.get('id')}
          >
            <div className="col-md-12 col-sm-6 col-xs-6">{o.get('value')}</div>
          </Cell>
        );
      })
    ) : (
      <Cell className="option-column row hidden-sm hidden-xs" key="1">
        <div className="col-md-12 col-sm-6 col-xs-6">
          <b>There are no options for this SKU: {props.item.get('sku')}</b>
        </div>
      </Cell>
    );

  const columnsMobile =
    options && options.size ? (
      options.map(o => {
        return (
          <div className="option-column row" key={props.item.get('sku') + o.get('id')}>
            <div className="col-sm-6 col-xs-6 visible-sm visible-xs text-right">
              <b>{o.get('name')}:</b>
            </div>
            <div className="col-md-12 col-sm-6 col-xs-6 p-0">{o.get('value')}</div>
          </div>
        );
      })
    ) : (
      <div className="option-column" key="1">
        <div className="col-md-12 col-sm-6 col-xs-6 p-0 visible-sm visible-xs">
          <b>There are no options for this SKU: {props.item.get('sku')}</b>
        </div>
      </div>
    );

  return (
    <>
      {/* Desktop View */}
      <Cell className="selection-column centered hidden-sm hidden-xs">
        <Button className="button-primary small" onClick={() => props.select(props.item)}>
          Select
        </Button>
      </Cell>
      {columns}
      <Cell className="price-column hidden-sm hidden-xs">
        <div className="col-md-11 col-sm-6 col-xs-6 price-value">
          <span className="no-wrap">
            {props.item.get('price') ? '$ ' + props.item.getIn(['price', 'price']).toFixed(2) : '-'}
          </span>
        </div>
      </Cell>

      {/* Mobile view below */}
      <Cell className="selection-column centered visible-sm visible-xs pt-2">
        <Button className="button-primary small" onClick={() => props.select(props.item)}>
          Select
        </Button>
      </Cell>
      <Cell className="visible-sm visible-xs pt-2 pb-2">
        <div className="products-column-mobile row w-100">
          {columnsMobile}
          <div className="col-sm-6 col-xs-6 visible-sm visible-xs text-right">
            <b>Price:</b>
          </div>
          <div className="col-md-11 col-sm-6 col-xs-6 price-value">
            <span className="no-wrap">
              {props.item.get('price')
                ? '$ ' + props.item.getIn(['price', 'price']).toFixed(2)
                : '-'}
            </span>
          </div>
        </div>
      </Cell>

      <style jsx>
        {`
          .products-column-mobile {
            flex-direction: column;
            line-height: 1.2;
            white-space: normal;
            word-break: break-word;
          }
        `}
      </style>
    </>
  );
};

HubSKUsListItem.propTypes = {
  item: PropTypes.any.isRequired,
  select: PropTypes.func.isRequired
};

export default HubSKUsListItem;
