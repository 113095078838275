import ViewportService from '../../components/viewport/viewport.service';
import CanvasLayer from '../../components/canvas/canvasLayer';
import CanvasImageElement from '../../components/canvas/canvasImageElement';
import ImageService from '../../components/image/image.service';

const ALLOWED_EMPTY_BORDER = 1;

class ValidationService {

  _validationLayer: CanvasLayer;

  validate(canvasSurface: any, state: any, config: any, round2: boolean) {

    if (!this._validationLayer) {
      this._validationLayer = new CanvasLayer({id: 'validation_layer'});
      this._validationLayer.addToStage(canvasSurface.stage);
    }

    // NOTE: Validate image rules
    // CanFill - allow to have image with transparent pixels
    // MustFill - check that viewport covered by image
    let valid = true;

    // NOTE: If in cropping mode - not validate
    if (state.editor.cropActivated === true) {
      return;
    }

    // NOTE: Validation occur in validation layer
    // in product preview mode and not maximized
    let stateCopy = {...state};
    if (state.editor.preview === 'print') {
      stateCopy.editor = {...state.editor, ...{preview: 'product'}};
    }

    if (state.editor.maximized === true) {
      stateCopy.editor = {
        ...stateCopy.editor,
        ...{
          width: config.width,
          height: config.height
        }
      };
    }

    const imagesCanvasStates = ImageService.getImagesCanvasStates(stateCopy);

    for(let imageState of imagesCanvasStates) {
      let imageElement = new CanvasImageElement(imageState);
      this._validationLayer.addElement(imageElement);
    }
    this._validationLayer.drawImmediately();

    const viewportRects = ViewportService.getViewports(stateCopy);
    for(let viewport of viewportRects) {
      // skip CanFill viewports
      if (viewport.layer.imageFill === 'CanFill') {
        continue;
      }

      // validate MustFill
      const startX = Math.ceil(viewport.x) + ALLOWED_EMPTY_BORDER;
      const endX = Math.floor(viewport.x + viewport.width) - ALLOWED_EMPTY_BORDER;
      for(let x = startX; x<= endX; x++) {
        if (!valid) {
          break;
        }

        const startY = Math.ceil(viewport.y) + ALLOWED_EMPTY_BORDER;
        const endY = Math.floor(viewport.y + viewport.height) - ALLOWED_EMPTY_BORDER;
        for(let y = startY; y <= endY; y++) {
          const shape = this._validationLayer.layer.getIntersection({x, y});
          if (shape === null || shape.className !== 'Image') {
            valid = false;
            break;
          }
        }
      }
    }

    // clear validation layer
    this._validationLayer.clear();

    // NOTE: Sometimes on first run the result is notValid
    // But valid on second time
    // TODO: Need to fix it
    if (round2 || valid) {
      return valid;
    }

    let valid2 = this.validate(canvasSurface, state, config, true);

    return valid || valid2;
  }
};


export default new ValidationService();
