import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'gooten-components/src/components/shared/Button';
import Input from 'gooten-components/src/components/shared/Input';
import Alert from 'gooten-components/src/components/shared/Alert';

export const ResetPasswordForm = ({ email, token }) => {
  const [submissionError, setSubmissionError] = useState('');
  return (
    <Formik
      validateOnBlur
      initialValues={{
        password: '',
        password2: ''
      }}
      validationSchema={() =>
        Yup.object().shape({
          password: Yup.string()
            .required('Please provide a Password.')
            .min(8, 'Password should contain 8 characters minimum.'),
          password2: Yup.string()
            .required('Please confirm Password.')
            .min(8, 'Password should contain 8 characters minimum.')
            .test('passwords-match', "Passwords don't match.", function (value) {
              return this.parent.password === value;
            })
        })
      }
      onSubmit={async (data, { setSubmitting, setFieldError }) => {
        try {
          let postUpdatedPassword = await fetch(`/Admin/Account/resetPassword`, {
            method: 'POST',
            body: JSON.stringify({
              Password: data.password,
              ConfirmPassword: data.password2,
              ChangePasswordCode: token
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (postUpdatedPassword.ok) {
            setSubmitting(false);
            setSubmissionError('');
            window.location = '/admin/account/login/?alert=resetsuccess';
          } else {
            setSubmitting(false);
            setSubmissionError('An error occurred please try again');
          }
        } catch (error) {
          setSubmitting(false);
          setSubmissionError('An error occurred please try again');
        }
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched }) => (
        <div
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          {submissionError.length > 0 ? (
            <Alert isOpen type="important">
              {submissionError}
            </Alert>
          ) : null}
          <div className="main-header">
            Reset your password
            <p>for {email}</p>
          </div>
          <div
            className={`sign-in-field ${
              touched.password && !!errors.password ? 'field-error' : ''
            }`}
          >
            <div className="ui input fluid relative-div">
              <Input
                name="password"
                type="password"
                className="password-reset-email"
                placeholder="New password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                disabled={isSubmitting}
                hasError={touched.password && !!errors.password}
                errorText={touched.password && !!errors.password ? errors.password : ''}
              />
              {!touched.password || (touched.password && !errors.password) ? (
                <div className="help-message">8 characters minimum.</div>
              ) : null}
            </div>
            <div className="ui input fluid">
              <Input
                name="password2"
                type="password"
                className="password-reset-email"
                placeholder="Re-type password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password2}
                disabled={isSubmitting}
                hasError={touched.password2 && !!errors.password2}
                errorText={touched.password2 && !!errors.password2 ? errors.password2 : ''}
              />
            </div>
          </div>

          <div className="btn-group">
            <Button
              loading={isSubmitting}
              className="button-primary large sm-media-full password-reset-submit"
              type="submit"
              onClick={() => (isSubmitting ? null : handleSubmit())}
            >
              Reset Password
            </Button>
          </div>
          <style jsx>{`
            .main-header {
              font-family: Avenir-Heavy;
              font-weight: 800;
              font-size: 1.5rem;
              line-height: 160%;
              margin-bottom: 1rem;
            }
            .relative-div {
              position: relative;
            }
            .main-header > p {
              font-family: Avenir-Roman;
              color: #646b72;
              font-size: 13.5px;
              margin-top: -12px;
              font-weight: 400;
            }
            .help-message {
              position: absolute;
              top: 48px;
              left: 16px;
            }
            :global(.alert-content) {
              display: flex;
            }
            :global(.alert-body) {
              margin-left: 5px;
            }
            .btn-group {
              display: flex !important;
              align-items: flex-end;
              flex-direction: column;
              justify-content: space-between;
              flex-wrap: wrap;
            }
            @media (max-width: 768px) {
              .btn-group {
                margin-top: 0;
              }
            }
          `}</style>
        </div>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  email: PropTypes.string,
  token: PropTypes.string
};
