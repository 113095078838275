import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { dataSelector } from '../../../store/selectors/productDataSelectors';

export const imgSelectionModalState = state => state.get('__img_select_modal__');
const imageUploadState = state => state.get('imageUpload');

export const currentStateSelector = createSelector([imgSelectionModalState], imgSelection =>
  imgSelection.get('currentState')
);

export const isOpenSelector = createSelector([currentStateSelector], currentState =>
  currentState.get('open')
);

export const hasImagesSelector = createSelector(
  [currentStateSelector, imgSelectionModalState],
  (currentState, imgSel) =>
    currentState.get('hasImages') || !!imgSel.getIn(['myArtwork', 'images', 'all', 'images']).size
);

export const showLoadingSelector = createSelector([currentStateSelector], currentState =>
  currentState.get('loading')
);

export const selectedSpaceNameInEditorSelector = createSelector(
  [imageUploadState, dataSelector],
  (imageUpload, data) =>
    fromJS({
      index: imageUpload.get('selectedSpace'),
      name: data.getIn([
        'selectedSkus',
        imageUpload.get('selectedVariant'),
        'spaces',
        imageUpload.get('selectedSpace'),
        'name'
      ])
    })
);

export const SCREEN_TYPE = {
  none: 0,
  myArtwork: 1,
  uploadImage: 2,
  uploadAdditionalFiles: 3
};

export const screenTypeSelector = createSelector([currentStateSelector], currentState =>
  currentState.get('showScreen')
);
