import React from 'react';
import PropTypes from 'prop-types';
import PartnerTierHeader from './PartnerTierHeader';
import NextTierCard from './NextTierCard';
import AnnualSpendIndicator from './AnnualSpendIndicator';
import CurrentTierCard from './CurrentTierCard';
import NextTierBenefitsCard from './NextTierBenefitsCard';

export const PartnerTierPageView = ({ status, currentTierData, nextTierData }) => {
  let annual = currentTierData.annual;
  const statusAssignedManually =
    nextTierData && nextTierData.assignedBy !== 'auto' && nextTierData.assignedBy !== 'signup';
  return (
    <>
      <PartnerTierHeader />
      <div className="d-inline-flex justify-content-between flex-wrap">
        <div className={annual ? '' : 'tier-card'}>
          <CurrentTierCard status={status} {...currentTierData} annual={annual}>
            {annual && nextTierData ? (
              <>
                {!statusAssignedManually ? (
                  <div className="tier-card-annual my-0 mx-auto next-tier-card mb-4">
                    <AnnualSpendIndicator {...currentTierData} nextTierData={nextTierData} />
                  </div>
                ) : (
                  <div style={{ flex: '4 0 190px', height: 0, border: 0 }} />
                )}
              </>
            ) : null}
          </CurrentTierCard>
        </div>
        {!annual && nextTierData ? (
          <>
            {!statusAssignedManually ? (
              <div className="tier-card my-0 mx-auto next-tier-card">
                <NextTierCard {...nextTierData} />
              </div>
            ) : (
              <div style={{ flex: '4 0 190px', height: 0, border: 0 }} />
            )}
            <div className="tier-card benefits-cards">
              <NextTierBenefitsCard status={nextTierData.nextLevelStatus} annual={annual} />
            </div>
          </>
        ) : null}
        {annual && nextTierData ? (
          <>
            <hr />
            <div className="tier-card-annual benefits-cards">
              <NextTierBenefitsCard status={nextTierData.nextLevelStatus} annual={annual} />
            </div>
          </>
        ) : (
          <div style={{ flex: '4 0 190px', height: 0, border: 0 }} />
        )}
      </div>
      <div className="mt-5">
        <p className="caption-text">
          VIM merchant loyalty program is subject to our&nbsp;
          <a className="link" href="https://www.gooten.com/terms-of-service" target="_blank">
            Terms of Service
          </a>
          <span>.</span>
        </p>
      </div>
      <style jsx>
        {`
          .d-inline-flex {
            gap: 24px;
          }
          .tier-card {
            border-radius: 8px;
            border: 1px solid #ededed;
            flex: 10 0 350px;
          }
          .tier-card-annual {
          }
          .next-tier-card {
            flex: 4 0 190px;
            height: max-content;
          }
          .caption-text,
          .link {
            color: #646b72 !important;
          }
          hr {
            margin: 0 !important;
            width: 100%;
            border-top: 1px solid #ededed;
          }
        `}
      </style>
    </>
  );
};

PartnerTierPageView.propTypes = {
  status: PropTypes.string.isRequired,
  currentTierData: PropTypes.any,
  nextTierData: PropTypes.any
};
