import StateManager from '../../state/stateManager';
import { PreviewOptions } from './preview.options';
import * as Buttons from './buttons';

export class PreviewComponent {
  domElement: any;

  constructor(stateManager: StateManager, config: PreviewOptions) {

    let container = document.createElement('div');
    container.className = 'preview' + (config.customClass ? ` ${config.customClass}` : '');

    if(config.previewShown){
      let productPreview = new Buttons.default.ProductPreviewButton(config, stateManager);
      let printPreview = new Buttons.default.PrintPreviewButton(config, stateManager);
      container.appendChild(productPreview.getElement());
      container.appendChild(printPreview.getElement());
    }

    if(config.maximizeShown){
      let maximizeButton = new Buttons.default.MaximizeButton(config, stateManager);
      container.appendChild(maximizeButton.getElement());
    }

    this.domElement = container;
  }
}
