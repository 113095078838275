import { createSelector } from 'reselect';
import { List } from 'immutable';
import { spacesSelector, filterSelector, sortSelector } from '../../MyArtwork/MyArtworkSelectors';
import { whiteSpaces } from '../../../../../../utils/regex';

export const filterItems = () => new List(['JPG', 'PNG', 'Landscape', 'Portrait', 'Square']);

export const sortItems = () => new List(['Large - Small', 'Small - Large']);

export const selectedSpaceTitleSelector = createSelector(
  [spacesSelector],
  spaces =>
    spaces.find(s => s.get('selected')) &&
    spaces
      .find(s => s.get('selected'))
      .get('name')
      .toUpperCase()
);

export const filterTitleSelector = createSelector(
  [filterSelector, filterItems],
  (filter, items) => {
    const index = items.map(item => item.replace(whiteSpaces, '').toLowerCase()).indexOf(filter);
    return index !== -1 ? items.get(index) : null;
  }
);

export const sortTitleSelector = createSelector([sortSelector, sortItems], (sort, items) => {
  const index = items.map(item => item.replace(whiteSpaces, '').toLowerCase()).indexOf(sort);
  return index !== -1 ? items.get(index) : null;
});
