import React from 'react';
import Logo from '../../assets/logo.svg';
import './index.scss';

const Header = props => {
  return (
    <div className="top-content">
      <div>
        <a href="/">
          <img
            src={props.partnerData ? props.partnerData.logoImage : Logo}
            className="signup-logo"
            alt="logo"
          />
        </a>
        {props.partnerData && props.partnerData.gootenLogoImage && (
          <>
            <span className={`p-3 ${props.partnerData.xStyle || ''}`}>&#10006;</span>
            <img
              className="gooten-logo"
              src={props.partnerData.gootenLogoImage}
              alt="gooten logo"
            />
          </>
        )}
      </div>
      <div className="has-existing-account">
        <div className="already-registered">ALREADY REGISTERED?</div>
        <a href="/Admin/Account/LogIn" className="log-in">
          LOG IN
        </a>
      </div>
    </div>
  );
};

export default Header;
