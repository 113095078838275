import React from 'react';

const Digitization = () => {
  return (
    <React.Fragment>
      <div>
        <span className="title">What is digitization?</span>
        <span className="description body-text-2">
          Digitization is the process of translating your PNG artwork file into a stitch file used
          by embroidery machines. This process isn’t as straightforward as it sounds, a master
          embroider artist has to make creative decisions how to best translate your artwork so it
          can be recreated with an embroidery machine.
        </span>
        <span className="title">Timeline</span>
        <span className="description body-text-2">
          When your embroidery order is submitted to the manufacturer, your artwork will be
          digitized. The process takes 24-48 hours on average. You can follow progress on the orders
          page. Subsequent orders utilizing the same artwork file will not need to be digitized
          again.
        </span>
        <span className="title">Approving your stitch file</span>
        <span className="description body-text-2">
          After your stitch file has been created we will notify you and send a preview for you to
          inspect, approve, or give feedback for adjustments.
        </span>
      </div>
      <style jsx>{`
        display: block;

        .title {
          font-weight: 800;
          font-size: 20px;
          line-height: 160%;
          margin-top: 20px;
        }

        .description {
          margin-top: 10px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default Digitization;
