import React from 'react';
import PropTypes from 'prop-types';
import Card from 'gooten-components/src/components/shared/Card';
import { parseCurrency } from './helpers';

const GootenProfitCard = ({ orderBilling }) => {
  return (
    <div className="card-container">
      <Card title="Gooten Profit">
        <div className="d-flex justify-content-between">
          <p>Product Profit</p>
          <div className="d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioProductProfit'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Ship Profit</p>
          <div className="d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioShipProfit'))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p>Total Profit</p>
          <div className="d-flex flex-wrap justify-content-end">
            {parseCurrency(orderBilling.get('PrintioTotalProfit'))}
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <p>Profit Margin</p>
          <div className="d-flex flex-wrap justify-content-end">
            {orderBilling.get('PrintioProfitMargin')}%
          </div>
        </div>
      </Card>
    </div>
  );
};

GootenProfitCard.propTypes = {
  orderBilling: PropTypes.object
};

export default GootenProfitCard;
