export const isNotMissingRequiredFields = address =>
  address.firstName &&
  address.lastName &&
  address.addressLine1 &&
  address.city &&
  address.zipCode &&
  address.country &&
  address.number &&
  address.email &&
  (address.country === 'US' || address.country === 'CA' ? address.state : true);

export const isEmpty = address =>
  !(
    address.firstName ||
    address.lastName ||
    address.addressLine1 ||
    address.addressLine2 ||
    address.city ||
    address.state ||
    address.zipCode ||
    address.number ||
    address.email
  );
