import { createSelector } from 'reselect';

// This Selector is specifically for when in edit mode to select the associated necklabel id
// with the required skus.
const hubDataSelector = state => state.getIn(['hub', 'hubView', 'products', 'variants']);

const neckLabelDataSelector = state => state.getIn(['publish', 'neckLabel']);

export const selectedNeckLabelIdSelector = createSelector(hubDataSelector, state => {
  const skuWithNeckTag =
    state && state.map(x => !!x && x.get('gootenMapping')).find(x => x.get('neck_tag_id'));
  return skuWithNeckTag && skuWithNeckTag.get('neck_tag_id');
});

export const errorSelector = createSelector(neckLabelDataSelector, data => {
  return data && data.get('error');
});

export const neckLabelsSelector = createSelector(neckLabelDataSelector, data => {
  // eslint-disable-nexr-live no-mixed-operators
  return (data && data.get('neckLabelList')) || [];
});

export const currentNeckLabelSelector = createSelector(neckLabelDataSelector, data => {
  return data && data.get('selectedNeckLabel');
});
