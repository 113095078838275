import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Log from 'gooten-components/src/services/logService';
import Icon from 'gooten-components/src/components/shared/Icon';
import { flatObject } from 'gooten-components/src/utils/strings';
import BaseCard from '../BaseCard';
import Button from 'gooten-components/src/components/shared/Button';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import { COLORS } from 'gooten-components/src/constants';

class AccountActivity extends React.Component {
  static get name() {
    return 'accountActivity';
  }
  static get messages() {
    return { onInitFailure: 'Failed to load Account Activity' };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.init({ page: this.props.page, pageSize: this.props.pageSize });
  }

  setPriorData(index, data) {
    this.setState({
      detailsIndex: index,
      priorData: data
    });
  }

  closeDetails(e) {
    e.stopPropagation();
    this.setState({
      detailsIndex: -1,
      priorData: null
    });
  }

  openDetails(index) {
    const priorDataJson = this.props.data.get(index).OldObj;
    if (!priorDataJson) {
      return this.setPriorData(-1, {});
    }
    let formated = null;
    try {
      formated = flatObject(JSON.parse(priorDataJson));
    } catch (e) {
      Log.warn(`Failed to parse Account activity detaild `, { priorDataJson });
      return this.setPriorData(-1, {});
    }
    if (formated) {
      this.setPriorData(index, formated);
    }
  }

  loadMore() {
    this.props.changePage(this.props.page + 1, this.props.pageSize);
  }

  get hasMore() {
    return this.props.total > this.props.page * this.props.pageSize;
  }

  getFriendlyDate(date) {
    const d = new Date(date);
    return `${d.toDateString()} ${d.toLocaleTimeString()}`;
  }

  renderPriorData() {
    return Object.keys(this.state.priorData).map(key => (
      <div className="row" key={key}>
        <div className="col-sm-4 col-xs-12 break-words history-item-key">{key}:</div>
        <div className="col-sm-8 col-xs-12 break-words history-item-value">
          {this.state.priorData[key] ? this.state.priorData[key].toString() : ''}
        </div>
      </div>
    ));
  }

  renderBody() {
    return (
      <>
        {this.props.data.map((i, key) => (
          <React.Fragment key={key}>
            <TableRow
              size="medium"
              columns="auto 3.5rem"
              className={i.OldObj && i.OldObj.length ? 'has-prior-data' : ''}
              onClick={() => this.openDetails(key)}
            >
              <Cell>
                <div className="row w-100 p-2">
                  <div className="col-sm-3 col-xs-12 history-row">
                    <b>{i.User ? i.User.Name : ''}</b>
                  </div>
                  <div className="col-sm-4 col-xs-12 history-row">{i.Description}</div>
                  <div className="col-sm-5 col-xs-12 history-row date-row">
                    {this.getFriendlyDate(i.Date)}
                  </div>
                </div>
              </Cell>
              <Cell>
                {i.OldObj && i.OldObj.length ? (
                  <div className="open-close-addon">
                    {key === this.state.detailsIndex ? (
                      <a onClick={e => this.closeDetails(e)}>
                        <Icon icon="chevronUp" />
                      </a>
                    ) : (
                      <Icon icon="chevronDown" />
                    )}
                  </div>
                ) : null}
              </Cell>
            </TableRow>
            {key === this.state.detailsIndex ? (
              <TableRow className=" history-details">
                <div className="panel-body p-3 mt-2 mb-2">
                  <div className="row history-caption">
                    <div className="p-3">
                      <b>Prior values</b>
                    </div>
                  </div>
                  {this.renderPriorData()}
                </div>
              </TableRow>
            ) : null}
          </React.Fragment>
        ))}
        <style jsx>
          {`
            :global(.has-prior-data) {
              cursor: pointer !important;
            }
            .panel-body {
              border-radius: 4px;
              border: none;
              background: ${COLORS.neutralLight200};
            }
          `}
        </style>
      </>
    );
  }

  render() {
    return (
      <BaseCard card={AccountActivity.name} title="Account Activity">
        <div>
          <div className="account-activity-card">
            {this.props.data.size ? (
              <div className="table table-hover">{this.renderBody()}</div>
            ) : (
              <p className="card-description">
                Your settings has no activity, yet. Whenever you or a team member updates something,
                you’ll be able to see the details here.
              </p>
            )}
          </div>
          {this.hasMore ? (
            <div className="text-center">
              <Button className="button-default large" onClick={() => this.loadMore()}>
                SHOW MORE
              </Button>
            </div>
          ) : null}
        </div>
      </BaseCard>
    );
  }
}

AccountActivity.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(List),
  total: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  changePage: PropTypes.func
};

export default AccountActivity;
