import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../shared/TextInput';
import Checkbox from '../../../../shared/Checkbox';
import AddressConfirmation from './AddressConfirmation';
import { usStatesIndex, caStatesIndex } from '../../../../../utils/states';
import { isEmpty } from '../../../../../utils/address';
import { Address } from '../../../../../models/Address';
import Strings from './strings';
import analyticsService from '../../../../../services/analyticsService';
import {
  firstNameRegex,
  lastNameRegex,
  emailRegex,
  zipCodeRegex,
  phoneNumberRegex
} from '../../../../../utils/regex';
import Button from '../../../../shared/Button';
import Select from '../../../../shared/Select';

class AddressForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveAddress: false,
      saveButtonEnabled: false,
      address: {
        addressLine2: '',
        state: ''
      },
      skipAddressConfirmationModal: false
    };
  }

  componentDidMount() {
    if (this.props.tempAddress) {
      this.setState({
        address: {
          firstName: this.props.tempAddress.firstName,
          lastName: this.props.tempAddress.lastName,
          addressLine1: this.props.tempAddress.addressLine1,
          addressLine2: this.props.tempAddress.addressLine2,
          city: this.props.tempAddress.city,
          state: this.props.tempAddress.state,
          country: this.props.shippingCountry,
          zipCode: this.props.tempAddress.zipCode,
          number: this.props.tempAddress.number,
          email: this.props.tempAddress.email
        }
      });
      this.saveAddress = this.props.tempAddress.save;

      this.props.clearTempAddress();
      this.forceValidate(true);
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this.state.address)) {
      this.props.storeTempAddress({
        firstName: this.state.address.firstName,
        lastName: this.state.address.lastName,
        addressLine1: this.state.address.addressLine1,
        addressLine2: this.state.address.addressLine2,
        city: this.state.address.city,
        state: this.state.address.state,
        country: this.props.shippingCountry,
        zipCode: this.state.address.zipCode,
        number: this.state.address.number,
        email: this.state.address.email,
        save: this.state.saveAddress
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const userEnteredShippingAddress = props.userEnteredShippingAddress;
    const suggestedShippingAddress = props.suggestedShippingAddress;
    if (!!userEnteredShippingAddress && !!suggestedShippingAddress) {
      const mergedAddress = userEnteredShippingAddress.merge(suggestedShippingAddress);
      const areAddressesEqual = AddressForm.compareAddresses(
        userEnteredShippingAddress.toJS(),
        mergedAddress.toJS()
      );
      if (areAddressesEqual) {
        return { skipAddressConfirmationModal: true };
      }
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.state.skipAddressConfirmationModal) {
      this.onClickIgnore();
    }

    if (this.state.scheduleForceValidate && !prevProps.isVisible && this.props.isVisible) {
      this.forceValidate(this.state.scheduleForceValidate.revealErrors);
    }
  }

  prefillAddress = address => {
    this.setState({ address, saveAddress: address.save }, () => {
      this.forceValidate(true);
    });
  };

  regexes = {
    firstName: firstNameRegex,
    lastName: lastNameRegex,
    email: emailRegex,
    zipCode: zipCodeRegex,
    number: phoneNumberRegex
  };

  inputs = {}; // Will contain refs to all input fields

  handleAddressEntered = () => {
    if (this.state.saveAddress) {
      analyticsService.trackWithConfigSource('New Shipping address added (saved)');
    }
    this.props.setUserEnteredShippingAddress(this.getAddress());
  };

  static compareAddresses = (address1, address2) =>
    address1.addressLine1 === address2.addressLine1 &&
    (address1.addressLine2 === address2.addressLine2 ||
      (!address1.addressLine2 && !address2.addressLine2) ||
      (!address1.addressLine2 && address2.addressLine2 === '')) &&
    address1.city === address2.city &&
    address1.zipCode === address2.zipCode &&
    address1.state === address2.state &&
    address1.country === address2.country;

  onClickUseSuggestedAddress = () => {
    const mergedAddress = this.props.userEnteredShippingAddress.merge(
      this.props.suggestedShippingAddress
    );
    this.props.deleteSuggestedAddress();
    this.props.setAddress(mergedAddress);
    this.clear();
  };

  onClickIgnore = () => {
    this.props.deleteSuggestedAddress();
    this.props.setAddress(this.props.userEnteredShippingAddress);
    this.clear();
    this.setState({ skipAddressConfirmationModal: false });
  };

  onClickAddressConfirmationModalClose = () => {
    this.props.deleteSuggestedAddress();
  };

  getValidationState = () => {
    let isValid = Object.keys(this.inputs).reduce(
      (previous, key) => (previous &= this.inputs[key] && this.inputs[key].isValid()),
      true
    );
    if (this.isUseStateDropdown()) {
      // Validate States dropdown as well
      isValid &= !!this.state.address.state;
    }
    return isValid;
  };

  forceValidate = revealErrors => {
    if (this.props.isVisible) {
      Object.keys(this.inputs).forEach(key => {
        const input = this.inputs[key];
        if (input && input.updateErrorsStatus) {
          input.updateErrorsStatus();
          if (revealErrors) {
            input.revealErrors();
          }
        }
      });
      this.unscheduleForceValidateOnNextUpdate();
    } else {
      this.scheduleForceValidateOnNextUpdate(revealErrors);
    }
  };

  scheduleForceValidateOnNextUpdate = revealErrors => {
    this.setState({
      scheduleForceValidate: {
        revealErrors
      }
    });
  };

  unscheduleForceValidateOnNextUpdate = () => {
    if (this.state.scheduleForceValidate) {
      this.setState({
        scheduleForceValidate: undefined
      });
    }
  };

  updateFormValidationState = () => {
    const isValid = this.getValidationState();
    this.setState({ saveButtonEnabled: isValid });
  };

  handleDropdownStateSelected = selection => {
    this.setState({ address: { ...this.state.address, state: selection } }, () => {
      this.forceValidate();
    });
  };

  handleTextInputChange = (value, textInputId) => {
    this.setState({ address: { ...this.state.address, [textInputId]: value } });
  };

  getAddress = () => {
    if (!this.state.saveButtonEnabled) {
      return null;
    }
    return new Address({
      firstName: this.state.address.firstName,
      lastName: this.state.address.lastName,
      addressLine1: this.state.address.addressLine1,
      addressLine2: this.state.address.addressLine2,
      city: this.state.address.city,
      state: this.state.address.state,
      // country: this.inputs['country'].state.value,
      country: this.props.shippingCountry,
      zipCode: this.state.address.zipCode,
      number: this.state.address.number,
      email: this.state.address.email,
      save: this.state.saveAddress
    });
  };

  toggleSaveAddress = () => {
    this.setState({ saveAddress: !this.state.saveAddress });
  };

  clear = () => {
    this.setState(
      {
        address: {
          addressLine2: '',
          state: ''
        }
      },
      () => {
        Object.keys(this.inputs).forEach(key => {
          if (key !== 'country') {
            // Clear all fields except country
            const input = this.inputs[key];
            if (input) {
              input.clear();
            }
          }
        });
      }
    );
  };

  isUseStateDropdown = () => {
    return this.props.shippingCountry === 'US' || this.props.shippingCountry === 'CA';
  };

  render() {
    if (!this.props.isVisible) {
      return null;
    }

    const addressConfirmationModal = (
      <AddressConfirmation
        isOpen={!!this.props.suggestedShippingAddress && !this.state.skipAddressConfirmationModal}
        suggestedAddress={this.props.suggestedShippingAddress}
        originalAddress={this.props.userEnteredShippingAddress}
        onClickUseSuggested={this.onClickUseSuggestedAddress}
        onClickIgnore={this.onClickIgnore}
        modalClose={this.onClickAddressConfirmationModalClose}
      />
    );

    let statesIndex = {};
    switch (this.props.shippingCountry) {
      case 'US': {
        statesIndex = usStatesIndex;
        break;
      }
      case 'CA': {
        statesIndex = caStatesIndex;
        break;
      }
    }

    const statesDropdown = (
      <div>
        <Select
          id="state-dropdown"
          label="State"
          onChange={event => this.handleDropdownStateSelected(event.target.value)}
          value={this.state.address.state || -1}
        >
          {!this.state.address.state ? (
            <option key={-1} value="">
              Select a State
            </option>
          ) : null}
          {Object.keys(statesIndex).map(key => (
            <option key={key} value={key}>
              {`${key} - ${statesIndex[key]}`}
            </option>
          ))}
        </Select>
      </div>
    );

    const statesTextInput = (
      <TextInput
        id="state"
        maxLength={50}
        label={Strings.STATE_PROVINCE_REGION}
        value={this.state.address.state}
        onChange={this.handleTextInputChange}
        ref={input => (this.inputs['state'] = input)}
        onErrorsStatusUpdated={this.updateFormValidationState}
      />
    );

    return (
      <div className="address-form">
        {addressConfirmationModal}
        <TextInput
          id="firstName"
          isRequired
          emptyMsg={Strings.FIELD_REQUIRED}
          showValidation
          regexp={this.regexes.firstName}
          validationMsg={Strings.INVALID_NAME}
          maxLength={50}
          label={Strings.FIRST_NAME}
          value={this.state.address.firstName}
          onChange={this.handleTextInputChange}
          ref={input => (this.inputs['firstName'] = input)}
          onErrorsStatusUpdated={this.updateFormValidationState}
        />
        <TextInput
          id="lastName"
          isRequired
          emptyMsg={Strings.FIELD_REQUIRED}
          showValidation
          regexp={this.regexes.lastName}
          validationMsg={Strings.INVALID_NAME}
          maxLength={50}
          label={Strings.LAST_NAME}
          value={this.state.address.lastName}
          onChange={this.handleTextInputChange}
          ref={input => (this.inputs['lastName'] = input)}
          onErrorsStatusUpdated={this.updateFormValidationState}
        />
        <TextInput
          id="addressLine1"
          isRequired
          emptyMsg={Strings.FIELD_REQUIRED}
          maxLength={150}
          label={Strings.ADDRESS_LINE_1}
          value={this.state.address.addressLine1}
          onChange={this.handleTextInputChange}
          ref={input => (this.inputs['addressLine1'] = input)}
          onErrorsStatusUpdated={this.updateFormValidationState}
        />
        <TextInput
          id="addressLine2"
          maxLength={150}
          label={Strings.ADDRESS_LINE_2}
          value={this.state.address.addressLine2}
          onChange={this.handleTextInputChange}
          ref={input => (this.inputs['addressLine2'] = input)}
          onErrorsStatusUpdated={this.updateFormValidationState}
        />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TextInput
              id="city"
              isRequired
              emptyMsg={Strings.FIELD_REQUIRED}
              maxLength={75}
              label={Strings.CITY}
              value={this.state.address.city}
              onChange={this.handleTextInputChange}
              ref={input => (this.inputs['city'] = input)}
              onErrorsStatusUpdated={this.updateFormValidationState}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            {this.isUseStateDropdown() ? statesDropdown : statesTextInput}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TextInput
              disabled
              id="country"
              maxLength={50}
              label={Strings.COUNTRY}
              value={
                this.props.shippingCountryFullName
                  ? `${this.props.shippingCountry} - ${this.props.shippingCountryFullName}`
                  : this.props.shippingCountry
              }
              ref={input => (this.inputs['country'] = input)}
              onErrorsStatusUpdated={this.updateFormValidationState}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TextInput
              id="zipCode"
              isRequired
              emptyMsg={Strings.FIELD_REQUIRED}
              showValidation
              regexp={this.regexes.zipCode}
              validationMsg={Strings.INVALID_ZIPCODE}
              maxLength={30}
              label={Strings.POSTAL_CODE}
              value={this.state.address.zipCode}
              onChange={this.handleTextInputChange}
              ref={input => (this.inputs['zipCode'] = input)}
              onErrorsStatusUpdated={this.updateFormValidationState}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TextInput
              id="number"
              isRequired
              emptyMsg={Strings.FIELD_REQUIRED}
              showValidation
              regexp={this.regexes.number}
              validationMsg={Strings.INVALID_PHONE_NUMBER}
              maxLength={20}
              label={Strings.PHONE_NUMBER}
              value={this.state.address.number}
              onChange={this.handleTextInputChange}
              ref={input => (this.inputs['number'] = input)}
              onErrorsStatusUpdated={this.updateFormValidationState}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <TextInput
              id="email"
              isRequired
              emptyMsg={Strings.FIELD_REQUIRED}
              showValidation
              regexp={this.regexes.email}
              validationMsg={Strings.INVALID_EMAIL}
              maxLength={69}
              label={Strings.YOUR_EMAIL}
              value={this.state.address.email}
              onChange={this.handleTextInputChange}
              ref={input => (this.inputs['email'] = input)}
              onErrorsStatusUpdated={this.updateFormValidationState}
            />
          </div>
        </div>
        <div className="row flex-center-aligned">
          <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
            <Checkbox
              checked={this.state.saveAddress}
              onChange={this.toggleSaveAddress}
              label={Strings.SAVE_THIS_ADDRESS_FOR_LATER}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <Button
              className="button-secondary extra-large use-this-address"
              onClick={this.handleAddressEntered}
              disabled={!this.state.saveButtonEnabled}
            >
              {Strings.USE_THIS_ADDRESS}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AddressForm.propTypes = {
  isVisible: PropTypes.bool,
  shippingCountry: PropTypes.string.isRequired,
  shippingCountryFullName: PropTypes.string,
  setAddress: PropTypes.func,
  setUserEnteredShippingAddress: PropTypes.func.isRequired,
  userEnteredShippingAddress: PropTypes.object,
  // isUserEnteredShippingAddressValid: PropTypes.bool,
  suggestedShippingAddress: PropTypes.object,
  // isSuggestedShippingAddressValid: PropTypes.bool,
  deleteSuggestedAddress: PropTypes.func,
  tempAddress: PropTypes.object,
  storeTempAddress: PropTypes.func,
  clearTempAddress: PropTypes.func
};

AddressForm.displayName = 'AddressForm';

export default AddressForm;
