import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'immutable';
import PagedQuery from '../../../../models/PagedQuery';
import PastOrdersList from './PastOrdersList';
import PastOrdersListHeader from './PastOrdersListHeader';
import Loader from 'gooten-components/src/components/shared/Loader';
import Pager from 'gooten-components/src/components/shared/Pager';

// NOTE: We have convention to style each component with container class
// Here may be some styles specific for this component
const Container = styled.div`
  display: block;
`;

class PastOrders extends React.PureComponent {
  // Higher Order Component (HOC) - wraps it's content in Container styled div
  inContainer = children => <Container>{children}</Container>;

  componentDidMount() {
    if (!this.props.isOrderItemsLoaded) {
      this.props.loadOrderItems(this.props.orderItemsQuery);
    }
  }

  componentDidUpdate() {
    if (!this.props.isOrderItemsLoaded) {
      this.props.loadOrderItems(this.props.orderItemsQuery);
    }
  }

  // NOTE: PastOrders is a component wrapper which should pass order items down
  // to PastOrdersList component to render as list
  // Here we can put some logic which can switch how order items are rendered depends on screen size etc.
  render() {
    if (!this.props.isOrderItemsLoaded) {
      return this.inContainer(<Loader />);
    } else if (this.props.orderItems.size === 0) {
      return this.inContainer(<h3>Nothing found</h3>);
    } else {
      const pages = Array.from(Array(this.props.totalPages).keys()).map(i => ({
        name: i + 1,
        index: i + 1
      }));

      // const visiblePages = pages.map(pageNumber => {
      //   const FIRST_AND_LAST_PAGES_TO_DISPLAY = 1
      //   const PAGES_AROUND_CURRENT_TO_DISPLAY = 1
      //   const firstFew = (pageNumber.index < (1 + FIRST_AND_LAST_PAGES_TO_DISPLAY))
      //   const lastFew = (pageNumber.index > this.props.totalPages - FIRST_AND_LAST_PAGES_TO_DISPLAY)
      //   const fewAroundCurrent = (
      //     (pageNumber.index > (this.props.currentPage - (1 + PAGES_AROUND_CURRENT_TO_DISPLAY))) &&
      //     (pageNumber.index < (this.props.currentPage + (1 + PAGES_AROUND_CURRENT_TO_DISPLAY)))
      //   )
      //   if (firstFew || lastFew || fewAroundCurrent) {
      //     return { name :  (pageNumber.index), index : pageNumber.index }
      //   } else {
      //     return -1
      //   }
      // }).filter((item, index, arr) => (arr[index] !== arr[index + 1]))

      return this.inContainer(
        <div className="past-orders-container">
          <div className="mt-5" />
          <PastOrdersListHeader
            totalItems={this.props.total}
            selectedItemsCount={this.props.selectedItems.size}
            deselectAll={this.props.deselectAll}
          />
          <PastOrdersList
            orderItems={this.props.orderItems}
            outOfStockItems={this.props.outOfStockItems}
            selectedItems={this.props.selectedItems}
            onOrderItemSelectionToggled={this.props.onOrderItemSelectionToggled}
            editDesign={this.props.editDesign}
          />
          <div className="mt-5" />
          <Pager
            current={this.props.currentPage}
            maxSize={5}
            items={pages}
            goTo={this.props.setCurrentPage}
          />
          <div className="mt-5" />
          <style jsx>
            {`
              @media screen and (max-width: 576px) {
                .past-orders-container {
                  overflow: auto;
                }
              }
            `}
          </style>
        </div>
      );
    }
  }

  componentWillUnmount() {
    this.props.deselectAll();
  }
}

PastOrders.propTypes = {
  isOrderItemsLoaded: PropTypes.bool.isRequired,
  orderItemsQuery: PropTypes.instanceOf(PagedQuery).isRequired,
  orderItems: PropTypes.instanceOf(List).isRequired,
  outOfStockItems: PropTypes.instanceOf(List),
  loadOrderItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.instanceOf(List),
  onOrderItemSelectionToggled: PropTypes.func.isRequired,
  deselectAll: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPages: PropTypes.number,
  total: PropTypes.number,
  editDesign: PropTypes.func.isRequired
};

export default PastOrders;
