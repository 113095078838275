import { delay, buffers } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
  select,
  actionChannel,
  take,
  takeEvery,
  all
} from 'redux-saga/effects';
import {
  TEMPLATES_FETCH,
  IMAGE_ADD,
  VARIANT_DELETE,
  UPDATE_SPACES_PREVIEWS_BY_SPACE_INDEX,
  fetchAsyncSuccess,
  fetchAsyncFail,
  dumpEditorState,
  updateSpacePreview,
  imageAdd,
  appendILHistory,
  bulkUpdateSpacesILs,
  editorBulkUndo,
  editorBulkRedo,
  updateSpacePreviewsBySpaceIndex,
  DUMP_EDITOR_STATE,
  NECK_LABEL_SELECTION_CHANGED
} from '../../store/actions/dataActions';
import {
  IMAGE_EDITOR_CHANGE,
  IMAGE_EDITOR_DX_CHANGE,
  IMAGE_EDITOR_UNDO,
  IMAGE_EDITOR_REDO
} from './atoms/ImageEditor/ImageEditorActions';
import {
  EDITOR_NEXT,
  EDITOR_BACK,
  VARIANT_SELECT,
  EDITOR_MODE_CHANGE,
  TOGGLE_PANEL,
  setReady,
  startWorking,
  finishWorking,
  initItemsToProcess,
  decrementItemsToProcess,
  defaultVariantSelect,
  IMAGES_PREPARE,
  EDIT_ORDERED_IMAGE,
  setBulkLoaderProgress,
  INIT_IMAGE_MODAL,
  setNeckLabelsPreviewsLoading,
  prepareImages
} from './ImageUploadActions';
import {
  variantsSelector,
  selectedVariantIndexSelector,
  selectedSpaceIndexSelector,
  selectedSpaceSelector,
  printAreaColorSelector,
  selectedAllOptionsSelector,
  originalDesignsSelector,
  latestDesignsSelector,
  noPreviewsThresholdNumber
} from './ImageUploadSelectors';
import { bulkEditSelector, selectedSKUsSelector } from '../../store/selectors/productDataSelectors';
import { next, back, goto } from '../../containers/NavManager/NavManagerActions';
import productImageDataService from './services/productImageDataService';
import imageEditorService from './services/imageEditorService';
import { generatePreview } from '../../services/previewGenerationService';
import { getEditorImageResizerUrl } from 'gooten-js-utils/src/url';
import { move } from 'gooten-js-utils/src/array';
import { isImmutable } from '../../utils/object';
import Config from '../../config';
import Log from '../../services/logService';
import { fromJS, Map } from 'immutable';
import proPreviewService from '../../services/proPreviewService';
import {
  isOrientationChangedSelector,
  publishedOrientationSelector,
  shouldChangeOrientation
} from '../SKUSelection/SKUSelectionSelectors';
import {
  orientationChanged,
  SELECT_OPTION,
  setDefaultOrientation,
  setPublishedOrientationChanged
} from '../SKUSelection/SKUSelectionActions';
import orientationService, {
  setupOrientation
} from '../SKUSelection/atoms/OptionsPanel/Options/services/orientationService';
import printAreaService from './services/printAreaService';
import imgmanipService from 'gooten-js-preview/src/_scripts/services/data/imgmanip.service';
import OrderDetailsService from '../../services/orderDetailsService';
import { push } from '../shared/Notifications/NotificationsActions';
import { requiredProductInfoSelector } from '../../store/selectors/hubSelectors';
import { modalOpen } from '../shared/ImageSelectionModal/ImageSelectionModalActions';
import { isEmbroiderySelected } from './atoms/Embroidery/EmbroiderySelectors';
import { isDisclaimerDisabledSelector } from './atoms/Embroidery/EmbroideryDisclamer/EmbroideryDisclaimerSelectors';
import { showDisclaimer } from './atoms/Embroidery/EmbroideryDisclamer/EmbroideryDisclaimerActions';
import { itemsDataSelector } from '../../store/selectors/hubSelectors';
import { disabledSkusInProductHubSelector } from '../ProductPublish/ProductPublishSelectors';
import { setCofNeckLabel } from '../../store/actions/dataActions';
import { skusWithNeckTagSelector } from '../ProductPublish/ProductPublishSelectors';
import { selectedNeckLabelSelector } from '../ImageUpload/atoms/NeckLabels/NeckLabelsCOFSelectors';
import generateNeckTagImageUrl from '../../services/imgManipForNeckTags';

export function precalculateTemplateSize(template, imageIndex) {
  let space = template.Spaces[imageIndex || 0];
  if (space.FinalX2 && space.FinalY2) {
    template.size = { width: space.FinalX2 - space.FinalX1, height: space.FinalY2 - space.FinalY1 };
  } else {
    let layer = space.Layers.find(l => l.Type === 'Image');
    template.size = { width: layer.X2 - layer.X1, height: layer.Y2 - layer.Y1 };
  }
}

function* applySameArtworkToNewSkus(variants) {
  let spacesVariants = [];
  const isEmbroidery = yield select(isEmbroiderySelected);

  variants
    .filter(v => v.spaces.find(x => (x.images && x.images.length) || x.il))
    .forEach(variant =>
      variant.spaces.forEach(s => {
        let spaceVariant = spacesVariants.find(x => x.spaceVariant === variant.index);
        if (!spaceVariant) {
          spacesVariants.push({
            spaceVariant: variant.index,
            images: [
              {
                spaceName: s.name,
                imageUrl: s.images[0]?.imageUrl,
                imageId: s.images[0]?.imageId,
                height: s.images[0]?.height,
                width: s.images[0]?.width,
                layerId: s.images[0]?.layerId,
                spaceId: s.images[0]?.spaceId,
                il: s.il
              }
            ]
          });
        } else {
          spaceVariant.images.push({
            spaceName: s.name,
            imageUrl: s.images[0]?.imageUrl,
            imageId: s.images[0]?.imageId,
            height: s.images[0]?.height,
            width: s.images[0]?.width,
            layerId: s.images[0]?.layerId,
            spaceId: s.images[0]?.spaceId,
            il: s.il
          });
        }
      })
    );

  if (spacesVariants.length > 0) {
    let spacesToCompare = spacesVariants[0].images;
    let isSameNoOfSpaces = true;
    let isImageOkToApply = true;

    variants.some(v => {
      if (v.spaces.length !== spacesToCompare.length) {
        isSameNoOfSpaces = false;
        return true;
      }

      let isSpaceConformConditions = true;

      v.spaces.some(space => {
        if (space.images && space.images.length === 0) return false;

        let findSpace = spacesToCompare.find(s => {
          let spaceNameCheck = s.spaceName === space.name;
          let imageUrlCheck = s.imageUrl === space.images[0].imageUrl;
          let ilCheck = false;
          if (s.il && space.il) ilCheck = imageEditorService.compareBasicPrintIls(s.il, space.il);
          return spaceNameCheck && imageUrlCheck && ilCheck;
        });

        if (!findSpace) isSpaceConformConditions = false;
        if (!isSpaceConformConditions) return true;
      });

      if (!isSpaceConformConditions) {
        isImageOkToApply = false;
        return true;
      }
    });

    if (!isSameNoOfSpaces) {
      isImageOkToApply = false;
      return;
    }

    let imagePrepareTasks = [];

    if (isImageOkToApply) {
      variants.forEach(v => {
        v.spaces
          .filter(s => s.images && s.images.length == 0)
          .forEach(s => {
            let imageExisting = spacesToCompare.find(item => item.spaceName === s.name);
            if (!imageExisting || !imageExisting.imageUrl) return;

            // Check if product is new embroidery sku and current image size is correct
            if (
              isEmbroidery &&
              (imageExisting.width !== s.template.requiredImageSize.width ||
                imageExisting.height !== s.template.requiredImageSize.height)
            )
              return;

            let imageTemp = {
              variantIndex: v.index,
              spaceId: s.id,
              layerId: s.template.layers.find(l => l.type === 'image').id,
              imageUrl: imageExisting.imageUrl,
              imageId: imageExisting.imageId,
              width: imageExisting.width,
              height: imageExisting.height,
              bulk: true,
              template: s.template,
              toBeRemove: false,
              spaceName: s.name
            };
            imagePrepareTasks.push(imageTemp);
          });
      });
    }

    if (imagePrepareTasks.length) {
      for (let image of imagePrepareTasks) {
        let alreadyExistingVariant = variants.find(x =>
          x.spaces.find(s => s.name === image.spaceName && s.il)
        );
        if (alreadyExistingVariant) {
          let alreadyExistingSpace = alreadyExistingVariant.spaces.find(
            s => s.name === image.spaceName && s.il
          );
          let defaultIlForAlreadyExistingSpace = yield call(
            getIl,
            alreadyExistingSpace.template,
            alreadyExistingSpace.images[0]
          );
          let isEdited = !imageEditorService.compareBasicPrintIls(
            defaultIlForAlreadyExistingSpace.print,
            alreadyExistingSpace.il
          );
          if (isEdited) image.toBeRemove = true;
        }
      }
      let variantIndexToBeRemoved = imagePrepareTasks
        .filter(img => img.toBeRemove)
        .map(x => x.variantIndex);
      imagePrepareTasks = imagePrepareTasks.filter(
        img => !variantIndexToBeRemoved.includes(img.variantIndex)
      );
      yield put(prepareImages(imagePrepareTasks));
      yield put(imageAdd(imagePrepareTasks));
    }
  }
}

export async function getIl(template, imageTemp) {
  return await imageEditorService.initImageState(template, imageTemp, false);
}

function* preparePredefinedImages(variants) {
  // NOTE: This occur when template loaded
  let imagePrepareTasks = [];
  const item = yield select(requiredProductInfoSelector);
  const items = yield select(itemsDataSelector);
  const orientation = yield select(publishedOrientationSelector);
  variants.forEach(v =>
    v.spaces.forEach((s, skey) => {
      if (!s.il) {
        s.images.forEach(i => {
          // logic for predefinited images from orders pages
          // find item in redux, get original height and width (esp if user has submitted new image)
          const itemIndex = items.length
            ? items.indexOf(items.find(z => z.Images.find(y => y.Id === i.imageId))) >= 0
              ? items.indexOf(items.find(z => z.Images.find(y => y.Id === i.imageId)))
              : null
            : null;

          const itemImage =
            items.length > 0 && itemIndex >= 0
              ? {
                  width: items[itemIndex] ? items[itemIndex].Images[skey].trueWidth : null,
                  height: items[itemIndex] ? items[itemIndex].Images[skey].trueHeight : null
                }
              : item.toJS().images[skey];
          //

          imagePrepareTasks.push({
            variantIndex: v.index,
            spaceId: s.id,
            layerId: s.template.layers.find(l => l.type === 'image').id,
            imageUrl: getEditorImageResizerUrl(i.imageUrl),
            imageId: i.imageId,
            // If item was added from hub products and IL was ignored since id's not match
            // this will hold original image sizes which need to be stored.
            width:
              i.width ||
              (itemImage ? itemImage.width : null) ||
              (orientation === 'changed'
                ? s.template.requiredImageSize.height
                : s.template.requiredImageSize.width),
            height:
              i.height ||
              (itemImage ? itemImage.height : null) ||
              (orientation === 'changed'
                ? s.template.requiredImageSize.width
                : s.template.requiredImageSize.height),
            id: i.id
          });
        });
      }
    })
  );
  if (imagePrepareTasks.length) {
    yield put(imageAdd(imagePrepareTasks));
  }
}

function* preparePredefinedImagesPreview(variants) {
  // NOTE: This occur when template loaded
  //
  let imagePrepareTasks = [];
  variants.forEach(v =>
    v.spaces
      .filter(s => s.il && s.images && s.images.length)
      .forEach(s =>
        imagePrepareTasks.push({
          variantIndex: v.index,
          space: s
        })
      )
  );
  const changeOrientation = yield select(shouldChangeOrientation);
  const selectedOptions = yield select(selectedAllOptionsSelector);

  for (let res of imagePrepareTasks) {
    const variant = variants.find(v => v.index === res.variantIndex);
    const backgroundColor = yield call(
      printAreaService.getPrintAreaColor,
      fromJS(variant),
      selectedOptions
    );

    // Generate small previews for sku list on editor page
    // NOTE: Small print preview is 100x100
    // resize images to 200x200 to make it faster
    // also 200x200 should be already cached since this sizes used in image select modal
    try {
      const smallSpacePreviewUrl = yield generatePreview(
        res.space,
        {
          width: 100,
          height: 100,
          imageWidth: 200,
          imageHeight: 200,
          preview: 'print',
          changeOrientation,
          backgroundColor
        },
        res.space.base64
      );
      // TODO: This is intersect with preview generated on preview step!
      // Need to remove canvas preview generation from preview step
      yield put(updateSpacePreview(res.variantIndex, res.space.id, smallSpacePreviewUrl));
    } catch (err) {
      // Suppress here to not break user flow, since these previews are not critical
      Log.error(err, 'Small preview generation suppressed');
    }
  }
}

function prepareTemplateImages(templates) {
  // NOTE: Some templates like e.g. Canvases 30x40 etc.
  // has design layer images with size 15000x150000
  // this is really slowdown editor and preview generation
  // and can cause page crashing
  // resize all template images to standard editor size 1000x1000
  Object.keys(templates).forEach(k => {
    const template = templates[k];
    template.Spaces.forEach(s => {
      s.Layers.forEach(l => {
        if (l.BackgroundImageUrl) {
          l.BackgroundImageUrl = getEditorImageResizerUrl(l.BackgroundImageUrl);
        } else if (l.OverlayImageUrl) {
          l.OverlayImageUrl = getEditorImageResizerUrl(l.OverlayImageUrl);
        } else if (l.BleedImageUrl) {
          l.BleedImageUrl = getEditorImageResizerUrl(l.BleedImageUrl);
        }
      });
    });
  });
}

export function preloadTemplateImages(templates) {
  try {
    // collect all image urls, with specific layer type, from all spaces and layers.
    // huge number of images will be duplicated, because skus shares overlay images, so use Set.
    // once browser caches images, all next fetches will be from cache...
    const imageUrls = new Set(
      Object.values(templates)
        .reduce(
          (a, c) =>
            a.concat(
              c.Spaces.reduce(
                (m, k) =>
                  m.concat(
                    k.Layers.filter(
                      l => l.BackgroundImageUrl || l.OverlayImageUrl || l.BleedImageUrl
                    )
                  ),
                []
              )
            ),
          []
        )
        .map(l => l.BackgroundImageUrl || l.OverlayImageUrl || l.BleedImageUrl)
    );

    imageUrls.forEach(url => {
      var img = new Image();
      img.src = url;
    });
    return imageUrls;
  } catch (error) {
    // if something brakes in preload, we don't care. user doesn't need to know.
  }
}

export function* initImageTemplateHandler(action) {
  // NOTE: This occure when editor page request templates to be loaded from API
  let variants = null;
  let templates = null;
  let proPreviewTemplates = null;
  let disabledSkus = null;
  try {
    variants = yield select(variantsSelector);
    disabledSkus = yield select(disabledSkusInProductHubSelector);
    const skus = variants.map(v => v.sku);
    templates = yield call(
      [productImageDataService, productImageDataService.getImageTemplates],
      skus
    );
    proPreviewTemplates = yield call(
      [proPreviewService, proPreviewService.getProPreviewTemplates],
      skus
    );
    if (!Object.keys(templates).length) {
      // If API doesn't returned any template redirect on prev step
      console.warn(`Failed to load templates for SKU(s): ${skus.join(',')}`);
      if (Config.get('linkMode') === true || Config.get('editMode') === true) {
        // in link and edit mode the 1st step is image upload, so if click back then exit to hub
        window.history.go(-1);
      } else {
        yield put(back());
      }
    } else {
      // templates loaded
      for (let sku in templates) {
        precalculateTemplateSize(templates[sku]);
      }

      // Determine default orientation based on template spaces
      const defaultOrientation = orientationService.getDefaultTemplatesOrientation(templates);
      yield put(setDefaultOrientation(defaultOrientation));

      prepareTemplateImages(templates);
      preloadTemplateImages(templates);
      yield put(
        fetchAsyncSuccess(TEMPLATES_FETCH, {
          templates,
          proPreviewTemplates,
          isLinkMode: Config.get('linkMode') === true,
          disabledSkus
        })
      );
      variants = yield select(variantsSelector);
      yield call(setupOrientation);
      yield applySameArtworkToNewSkus(variants);
      yield preparePredefinedImages(variants);
      yield preparePredefinedImagesPreview(variants);
      yield put(defaultVariantSelect(variants));
    }
  } catch (err) {
    yield put(fetchAsyncFail(TEMPLATES_FETCH, err));
    throw Log.withFriendlyMsg('Failed to load templates', err, { action, variants, templates });
  }
  yield put(setReady());
}

export function* prepareImageHandler(action) {
  // NOTE: This occur when image just added
  // Instantiate generating IL and small space preview
  // NOTE: This will inc workingJobs count, Next button will be disabled until this is 0
  yield put(startWorking(action.payload.images.length));
  yield put(initItemsToProcess(action.payload.images.length));
  const changeOrientation = yield select(shouldChangeOrientation);
  const isOrientationChanged = yield select(isOrientationChangedSelector);
  const selectedOptions = yield select(selectedAllOptionsSelector);
  const selectedVariantIndex = yield select(selectedVariantIndexSelector);

  // to avoid ImageEditor flickering, first generate/prepare currently selected variant,
  // by putting it on a first place...
  if (action.payload.images.length) {
    const imageIndex = action.payload.images.findIndex(
      im => im.variantIndex === selectedVariantIndex
    );
    move(action.payload.images, imageIndex, 0);
  }

  for (var image of action.payload.images) {
    // NOTE: putting in call delay because otherwise actions lock up the DOM
    yield call(delay, 100);

    let variants = yield select(variantsSelector);

    // Check if zero variants and early finish
    // Case when user clicks back button before all images were processed
    // https://gooten.atlassian.net/browse/TECH-13319
    if (variants.length === 0) break;

    let variant = variants.find(v => v.index === image.variantIndex);
    let space = variant.spaces.find(s => s.id === image.spaceId);
    // NOTE: COF and place order flows use this handler to prepare images for each variant (can be from different products)
    // added to Cart. 'changeOrientation' returns orientation on Product level, not on variant level,
    // and can be used only in Create, Edit, Duplicate and Sync product flows. For COF and Place order flows,
    // we have to pull orientation directly from each variant and generate preview images with proper orientation
    // ONLY if user choose Saved Products or Reorder Products. Create a New Product (COF) flow, should use 'changeOrientation'...
    let orientation =
      Config.get('editMode') ||
      Config.get('createMode') ||
      Config.get('linkMode') ||
      Config.get('duplicateMode') ||
      (Config.get('cof') && window.location.href.includes('create-new'))
        ? changeOrientation
        : variant.orientation === 'changed';

    if ((!space.il || isOrientationChanged) && space.images.length) {
      let il = yield imageEditorService.initImageState(
        space.template,
        space.images[0],
        orientation
      );
      il = !space.il ? il : { print: space.il };

      yield put(dumpEditorState(image.variantIndex, image.spaceId, null, il.print));

      const backgroundColor = yield call(
        printAreaService.getPrintAreaColor,
        fromJS(variant),
        selectedOptions
      );

      // NOTE: we do not generate previews when there are 10+ variants. Takes too much time/resources
      const selectedSkus = yield select(selectedSKUsSelector);
      if (selectedSkus.size > noPreviewsThresholdNumber && !Config.get('editMode')) {
        yield put(setBulkLoaderProgress(image.variantIndex + 1, selectedSkus.size));
      } else {
        try {
          // NOTE: Small print preview is 100x100
          // resize images to 200x200 to make it faster
          // also 200x200 should be already cached since this sizes used in image select modal
          const smallSpacePreviewUrl = yield generatePreview(
            {
              ...space,
              il: il.print
            },
            {
              width: 100,
              height: 100,
              imageWidth: 200,
              imageHeight: 200,
              preview: 'print',
              changeOrientation: orientation,
              backgroundColor
            },
            space.base64
          );
          yield put(updateSpacePreview(image.variantIndex, image.spaceId, smallSpacePreviewUrl));
        } catch (err) {
          // Suppress here to not break user flow, since these previews are not critical
          Log.error(err, 'Small preview generation suppressed');
        }
      }
    }
    yield put(decrementItemsToProcess());
  }
  yield put(finishWorking());
}

export function* generateInstantImagePreview(action) {
  // during bulk edit updateSpacesPreviewsBySpaceIndexHandler is responsible for updating spaces previews
  const isBulkEditing = yield select(bulkEditSelector);
  if (isBulkEditing) {
    return;
  }

  // NOTE: This event occur when IMAGE_EDITOR_CHANGE action dispatcthed
  const editorState = action.payload;
  const il = imageEditorService.getIl();
  // skip state save if editor is not ready
  if (!editorState || !editorState.editor.imageloaded || !il) {
    return;
  }

  const space = yield select(selectedSpaceSelector);
  const currentSkuIndex = yield select(selectedVariantIndexSelector);

  if (!space.il) {
    Log.debug('Skip preview generation, since initial IL was not generated yet');
    return;
  }

  const shouldUpdatePreview = !imageEditorService.comparePrintIls(space.il, il.print);
  if (!shouldUpdatePreview) {
    Log.debug('Skip preview generation, since IL was not changed');
    return;
  }

  yield put(startWorking());
  // NOTE: IMAGE_EDITOR_CHANGE can occure too offten
  // wait 0.5s so this can be canceled while waiting
  yield call(delay, 500);

  const backgroundColor = yield select(printAreaColorSelector);

  // NOTE: Small print preview is 100x100
  // resize images to 200x200 to make it faster
  // also 200x200 should be already cached since this sizes used in image select modal
  const changeOrientation = yield select(shouldChangeOrientation);
  if (il && il.print) {
    try {
      const smallSpacePreviewUrl = yield generatePreview(
        {
          ...space,
          il: il.print
        },
        {
          width: 100,
          height: 100,
          imageWidth: 200,
          imageHeight: 200,
          preview: 'print',
          changeOrientation,
          backgroundColor
        },
        space.base64
      );
      yield put(updateSpacePreview(currentSkuIndex, space.id, smallSpacePreviewUrl));
    } catch (err) {
      // Suppress here to not break user flow, since these previews are not critical
      Log.error(err, 'Small preview generation suppressed');
    } finally {
      yield put(finishWorking());
    }
  }
}

export function* adjustPrintAreaColorHandler(action) {
  if (action.type === DUMP_EDITOR_STATE && !action.payload.editorState) {
    return;
  }

  let isPrintMode = false;
  switch (action.type) {
    case IMAGE_EDITOR_CHANGE:
      isPrintMode = action.payload.editor.preview === 'print';
      break;
    case EDITOR_MODE_CHANGE:
      isPrintMode = action.payload.mode === 'print';
      break;
    case DUMP_EDITOR_STATE:
      isPrintMode = action.payload.editorState.editor.preview === 'print';
      break;
    default:
      isPrintMode = false;
  }

  const printAreaColor = yield select(printAreaColorSelector);
  imageEditorService.setContainerColor(isPrintMode ? printAreaColor : 'white');
}

export function* dumpEditorStateHandler(action) {
  const isBulkEditing = yield select(bulkEditSelector);
  // We disabled dump editor state in bulk mode since - we using updated IL's
  if (isBulkEditing) {
    return;
  }
  // This inc workingJobs count, if more than 0 - next button is disabled
  yield put(startWorking());
  try {
    let currentSkuIndex;

    let variants = yield select(variantsSelector);
    if (variants.length === 0) {
      return;
    }

    if (action.type === VARIANT_SELECT) {
      currentSkuIndex = action.payload.prevSkuIndex;
    } else {
      currentSkuIndex = yield select(selectedVariantIndexSelector);
    }

    let editorState = imageEditorService.getState();

    // skip state save if editor is not ready
    if (!editorState || !editorState.editor.templateloaded || !editorState.editor.imageloaded) {
      return;
    }

    let spaceId = editorState.template.spaceId;
    let printIl = imageEditorService.getIl().print;
    yield put(dumpEditorState(currentSkuIndex, spaceId, editorState, printIl));
  } finally {
    // This dec workingJobs count, if more than 0 - next button is disabled
    yield put(finishWorking());
  }
}

export function* editorChangeHandler(action) {
  yield generateInstantImagePreview(action);
  yield dumpEditorStateHandler(action);
}

export function* updateSpacesPreviewsBySpaceIndexHandler(action) {
  // Updates spaces previews in bulk edit mode
  const selectedSpaceIndex = action.payload.selectedSpaceIndex;
  const selectedSkus = yield select(selectedSKUsSelector);
  const selectedOptions = yield select(selectedAllOptionsSelector);

  let variantIndex = 0;
  const changeOrientation = yield select(shouldChangeOrientation);
  for (const sku of selectedSkus) {
    const backgroundColor = yield call(printAreaService.getPrintAreaColor, sku, selectedOptions);
    const spaceRaw = sku.getIn(['spaces', selectedSpaceIndex]);
    let space = spaceRaw && (isImmutable(spaceRaw) ? spaceRaw.toJS() : spaceRaw);

    // space can be Record (immutable) or plain js, but some of nested objects can be still immutable...
    if (space && space.il) {
      space = isImmutable(space.template) ? space.toJS() : space;
      try {
        const selectedSkus = yield select(selectedSKUsSelector);
        if (selectedSkus.size > noPreviewsThresholdNumber && !Config.get('editMode')) {
        } else {
          const smallSpacePreviewUrl = yield generatePreview(
            space,
            {
              width: 100,
              height: 100,
              imageWidth: 200,
              imageHeight: 200,
              preview: 'print',
              changeOrientation,
              backgroundColor
            },
            space.base64
          );
          yield put(updateSpacePreview(variantIndex, space.id, smallSpacePreviewUrl));
        }
      } catch (err) {
        // Suppress here to not break user flow, since these previews are not critical
        Log.error(err, 'Small preview generation suppressed');
      }
    }
    variantIndex++;
  }
}

export function* editorBackHandler(action) {
  yield dumpEditorStateHandler(action);
  if (Config.get('linkMode') === true) {
    // in link mode the 1st step is image upload, so if click back then exit to hub
    window.history.go(-1);
  } else if (Config.get('editMode') === true) {
    // in edit mode the 1st step is sku selection, so if click back then go to sku selection step...
    yield put(goto('SKUSelection'));
  } else {
    yield put(back());
  }
}

export function* editorNextHandler(action) {
  yield dumpEditorStateHandler(action);
  yield put(next());

  // if orientation exist, reset here orientation change flag, because we assume
  // that proper orientation has been applied
  yield put(orientationChanged(false));
}

export function* variantDeleteHandler(action) {
  imageEditorService.destroyEditor();
  const variants = yield select(variantsSelector);
  yield put(defaultVariantSelect(variants));
}

export function* undo(action) {
  // Updates history of IL's in bulk edit mode
  const bulkEditEnabled = yield select(bulkEditSelector);
  if (!bulkEditEnabled) return;
  const selectedSpaceIndex = yield select(selectedSpaceIndexSelector);
  const selectedVariant = yield select(selectedVariantIndexSelector);
  yield put(editorBulkUndo(selectedSpaceIndex, selectedVariant));
  yield put(updateSpacePreviewsBySpaceIndex(selectedSpaceIndex));
}

export function* redo(action) {
  // Updates history of IL's in bulk edit mode
  const bulkEditEnabled = yield select(bulkEditSelector);
  if (!bulkEditEnabled) return;
  const selectedSpaceIndex = yield select(selectedSpaceIndexSelector);
  const selectedVariant = yield select(selectedVariantIndexSelector);
  yield put(editorBulkRedo(selectedSpaceIndex, selectedVariant));
  yield put(updateSpacePreviewsBySpaceIndex(selectedSpaceIndex));
}

export function* bulkUpdateILsOnDxChange(action) {
  const bulkEditEnabled = yield select(bulkEditSelector);
  if (bulkEditEnabled) {
    const selectedSkus = yield select(selectedSKUsSelector);
    // Update IL's by dx update of all spaces by index
    const selectedSpaceIndex = yield select(selectedSpaceIndexSelector);
    // Prepare updates IL's
    const spacesWithSku = selectedSkus
      .map(sku => {
        const space = sku.getIn(['spaces', selectedSpaceIndex]);
        // other SKUs may have different amount of spaces
        if (space) {
          const spaceIL = isImmutable(space.get('il')) ? space.get('il').toJS() : space.get('il');
          const template = isImmutable(space.get('template'))
            ? space.get('template').toJS()
            : space.get('template');
          const newIL = imageEditorService.getUpdatedILbyDx(spaceIL, action.payload, template);
          if (newIL) {
            return new Map({
              space: space.set('il', newIL.print),
              sku: sku.sku,
              index: sku.index
            });
          }
        }
      })
      .filter(obj => !!obj);

    // NOTE: putting in call delay because otherwise actions lock up the DOM
    yield call(delay, 10);
    // Dispatch update IL's action
    yield put(bulkUpdateSpacesILs(spacesWithSku, selectedSpaceIndex));
    // Update spaces previews
    yield put(updateSpacePreviewsBySpaceIndex(selectedSpaceIndex));
  }
}

export function* updateHistoryAndILonDxChange(action) {
  const selectedSkus = yield select(selectedSKUsSelector);
  const spaceIndex = yield select(selectedSpaceIndexSelector);
  let SKUWithIL = new Map();
  selectedSkus.forEach((sku, index) => {
    const il = sku.getIn(['spaces', spaceIndex, 'il']);
    if (il) {
      if (il.layers[0].images.length > 0) {
        SKUWithIL = SKUWithIL.set(sku.sku, il);
      }
    }
  });
  yield put(appendILHistory(SKUWithIL, spaceIndex));
}

// new flow for updating images from the orders page
export function* editOrderedImageAsync() {
  const changeOrientation = yield select(shouldChangeOrientation);
  const selectedSkus = yield select(selectedSKUsSelector);
  const sku = selectedSkus.toJS()[0];
  const allSpaces = sku.spaces;
  const originalDesigns = yield select(originalDesignsSelector);
  const latestDesigns = yield select(latestDesignsSelector);

  yield put(startWorking(latestDesigns.length));
  try {
    for (const space of allSpaces) {
      const spaceIndex = allSpaces.indexOf(space);

      if (
        space.il.layers.find(x => x.images) &&
        space.il.layers.find(x => x.images).images.length > 0
      ) {
        const newManipCommands = yield call(
          imgmanipService.convertILtoImgManip,
          space.il,
          null,
          changeOrientation
        );
        const oldImage = originalDesigns[spaceIndex].imageUrl;
        const replacementImage = space.images[0].imageUrl;

        if (oldImage !== replacementImage) {
          yield call(
            OrderDetailsService.postOrderImageUrlUpdate,
            originalDesigns[spaceIndex].imageId,
            replacementImage
          );
        }
        yield call(
          OrderDetailsService.postManipCommand,
          originalDesigns[spaceIndex].imageId,
          JSON.stringify(newManipCommands)
        );
      }
    }
    yield put(push(`Images have been updated. It may take a few minutes to update below.`));
    window.history.go(-1);
  } catch (err) {
    console.log('Error updating images');
    yield put(push(`Error updating images`));
    window.history.go(-1);
  }
  yield put(finishWorking());
}

function* handleInitImageModal(action) {
  // for Embroidery products, show Disclaimer first...
  const isEmbroidery = yield select(isEmbroiderySelected);
  const isDisclaimerDisabled = yield select(isDisclaimerDisabledSelector);

  if (isEmbroidery && !isDisclaimerDisabled) {
    yield put(showDisclaimer({ visible: true, payload: action.payload }));
  } else {
    yield put(modalOpen(action.payload));
  }
}

function* handleOptionChange(action) {
  // when user changes "orientation", update all spaces previews...
  if (action.type === SELECT_OPTION && action.payload?.optionId.toLowerCase() === 'orientation') {
    const selectedSkus = yield select(selectedSKUsSelector);
    if (selectedSkus && selectedSkus.size) {
      yield preparePredefinedImagesPreview(selectedSkus.toJS());
    }
  }
}

export function* watchImageTemplatesFetchAsync() {
  yield takeEvery(TEMPLATES_FETCH.ASYNC, initImageTemplateHandler);
}

export function* watchBackTransition() {
  yield takeLatest(EDITOR_BACK, editorBackHandler);
}

export function* watchNextTransition() {
  yield takeLatest(EDITOR_NEXT, editorNextHandler);
}

export function* watchImageAddAsync() {
  // Using actionChannel we can buffer incomming actions
  // and process them sequentially
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel([IMAGE_ADD, IMAGES_PREPARE], buffers.expanding(20));
  while (true) {
    // 2- take from the channel
    const action = yield take(requestChan);
    // 3- Note that we're using a blocking call
    yield call(prepareImageHandler, action);
  }
}

export function* watchVariantDelete() {
  yield takeEvery(VARIANT_DELETE, variantDeleteHandler);
}

export function* watchEditorChange() {
  yield all([takeLatest(IMAGE_EDITOR_CHANGE, editorChangeHandler)]);
}

export function* watchEditOrderedImageAsync() {
  yield all([takeLatest(EDIT_ORDERED_IMAGE, editOrderedImageAsync)]);
}

export function* watchImageStateChange() {
  yield all([
    takeEvery(IMAGE_EDITOR_UNDO, undo),
    takeEvery(IMAGE_EDITOR_REDO, redo),
    takeEvery(IMAGE_EDITOR_DX_CHANGE, updateHistoryAndILonDxChange),
    takeLatest([IMAGE_EDITOR_DX_CHANGE], bulkUpdateILsOnDxChange),
    takeLatest(UPDATE_SPACES_PREVIEWS_BY_SPACE_INDEX, updateSpacesPreviewsBySpaceIndexHandler),
    takeLatest(VARIANT_SELECT, dumpEditorStateHandler),
    takeLatest(EDITOR_MODE_CHANGE, dumpEditorStateHandler),
    takeLatest(TOGGLE_PANEL, dumpEditorStateHandler)
  ]);
}

export function* watchPrintMode() {
  yield all([
    takeLatest(
      [EDITOR_MODE_CHANGE, IMAGE_EDITOR_CHANGE, DUMP_EDITOR_STATE],
      adjustPrintAreaColorHandler
    )
  ]);
}

export function* watchInitImageModal() {
  yield takeLatest(INIT_IMAGE_MODAL, handleInitImageModal);
}

function* watchOptionChange() {
  yield takeLatest([SELECT_OPTION], handleOptionChange);
}

function* handleNeckLabelSelection(action) {
  const skus = yield select(skusWithNeckTagSelector);
  const neckLabel = action?.payload?.toJS();

  try {
    if (neckLabel) {
      yield put(setNeckLabelsPreviewsLoading(true));
      const neckTagImagesUrls = yield generateNeckTagImageUrl(skus, neckLabel);
      const skuNeckLabels = neckTagImagesUrls.map(n => {
        return {
          sku: n.sku,
          neckTagId: n.neckTagId,
          neckTagImgUrl: n.neckTagImgUrl
        };
      });
      yield put(setCofNeckLabel({ skuNeckLabels: skuNeckLabels }));
      yield put(setNeckLabelsPreviewsLoading(false));
    } else {
      yield put(setCofNeckLabel(null));
    }
  } catch (err) {
    yield put(setNeckLabelsPreviewsLoading(false));
    const error = 'A convenient neck tag could not be generated for the provided image.';
    Log.error(error, 'Failed to generate neck tag', { action, skus, neckLabel });
    return;
  }
}

function* watchNeckLabelChange() {
  yield takeLatest([NECK_LABEL_SELECTION_CHANGED], handleNeckLabelSelection);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchPrintMode(),
    watchImageTemplatesFetchAsync(),
    watchImageAddAsync(),
    watchImageStateChange(),
    watchVariantDelete(),
    watchBackTransition(),
    watchNextTransition(),
    watchEditorChange(),
    watchEditOrderedImageAsync(),
    watchInitImageModal(),
    watchOptionChange(),
    watchNeckLabelChange()
  ]);
}
