import React from 'react';
import PropTypes from 'prop-types';
import './ColorPreset.scss';
import analyticsService from './../../../services/analyticsService';

class ColorPreset extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    getRealColor: PropTypes.func,
    applyColor: PropTypes.func
  };

  onClickHandler() {
    if (this.props.disabled) return;
    analyticsService.trackWithDynamicSource(
      'Preview',
      'Background color selected',
      this.props.getRealColor()
    );
    this.props.applyColor();
  }

  render() {
    // TODO: Reuse ColorPill to render each color
    const style = { backgroundColor: this.props.color };
    let className =
      this.props.getRealColor() === this.props.color
        ? 'component-color-preset selected'
        : 'component-color-preset';

    if (this.props.disabled) {
      className += ' disabled';
    }

    return (
      <div className={className}>
        <div onClick={() => this.onClickHandler()} style={style}>
          <span className="icon-checkmark" />
        </div>
      </div>
    );
  }
}

ColorPreset.displayName = 'ColorPreset';

export default ColorPreset;
