import React from 'react';
import PropTypes from 'prop-types';
import CancelButton from '../../components/shared/CancelButton';
import PanelLayout from '../../layouts/PanelLayout';
import MainPanel from './atoms/MainPanel';
import SidePanel from './atoms/SidePanel';
import defaultConfig from './ProductPreviewDefaultConfig';
import FullScreenPreview from './atoms/FullScreenPreview';
import ToastModal from '../shared/ToastModal';
import analyticsService from '../../services/analyticsService';

import './ProductPreview.scss';
import Button from '../shared/Button';
import Dialogue from '../shared/Dialogue';
import TrackingBar from '../shared/TrackingBar';

const MAX_NUMBER_OF_ITEMS = 250;

class ProductPreviewComponent extends React.Component {
  static propTypes = {
    allSkus: PropTypes.array,
    items: PropTypes.array,
    previewData: PropTypes.object,
    updatePreviewData: PropTypes.func.isRequired,
    isMaximized: PropTypes.bool,
    isFullScreenPreview: PropTypes.bool,
    toggleMaximize: PropTypes.func.isRequired,
    setFullScreenPreview: PropTypes.func.isRequired,
    setFullScreenCurrentItems: PropTypes.func.isRequired,
    cancel: PropTypes.func,
    selectItems: PropTypes.func,
    deselectItem: PropTypes.func,
    deselectAllItems: PropTypes.func,
    selectedItems: PropTypes.array,
    generationState: PropTypes.object,
    removeItems: PropTypes.func,
    isRemoveModalVisible: PropTypes.bool,
    showRemoveModal: PropTypes.func,
    restoreAllItems: PropTypes.func,
    restoreLastDeletedItems: PropTypes.func,
    removeItemsModalData: PropTypes.object,
    resetNotificationState: PropTypes.func,
    prepareToPublish: PropTypes.func,
    isExclusiveMockup: PropTypes.bool,
    enableEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    updatePreviewSize: PropTypes.func,
    proPreviewModal: PropTypes.object,
    downloadModal: PropTypes.object,
    generatingModal: PropTypes.object,
    generatePreviews: PropTypes.func,
    shouldRegeneratePreviews: PropTypes.bool,
    updateEditState: PropTypes.func,
    cancelEditMockups: PropTypes.func,
    resetEditState: PropTypes.func,
    editData: PropTypes.object,
    cancelPreviewsGeneration: PropTypes.func,
    shopifyImagesCountModalData: PropTypes.object,
    showShopifyImagesCountModal: PropTypes.func
  };

  componentDidMount() {
    // this.props.toggleMaximize(this.props.isExclusiveMockup)
    this.props.toggleMaximize(false);

    // set default preview size values...
    if (!this.props.previewData.previewSize) {
      this.props.updatePreviewSize({
        width: defaultConfig.get('defaultWidth'),
        height: defaultConfig.get('defaultHeight')
      });
    }

    if (this.props.items.length) {
      this.props.updatePreviewData(this.props.items);
      this.props.generatePreviews(this.props.items);
    }

    // show Shopify images count limitation
    if (this.props.items.length > MAX_NUMBER_OF_ITEMS) {
      this.props.showShopifyImagesCountModal();
    }

    analyticsService.trackWithConfigSource('Preview Page Reached');
  }

  handleContinueButtonClick = () => {
    if (!this.isContinueDisabled()) {
      this.props.prepareToPublish(
        this.props.previewData.items,
        this.props.allSkus,
        this.props.shouldRegeneratePreviews
      );
    }
  };

  handleEditClick = mobile => {
    this.props.enableEditMode(true);
    this.props.toggleMaximize(!mobile);
    this.props.updateEditState(this.props.previewData);

    analyticsService.trackWithDynamicSource('Product preview', 'Edit');
  };

  handleDoneEditingClick = () => {
    this.props.enableEditMode(false);
    // if (!this.props.isExclusiveMockup) {
    this.props.toggleMaximize(false);
    // }
    this.props.deselectAllItems();
    this.props.resetEditState();
  };

  handleCancelEditingClick = () => {
    this.props.cancelEditMockups(this.props.editData);
    this.props.cancelPreviewsGeneration(this.props.previewData.items);
    this.handleDoneEditingClick();
  };

  handleSelectAll = () => {
    this.props.deselectAllItems();
    if (this.props.selectedItems.length < this.props.previewData.items.length / 2) {
      this.props.selectItems(this.props.previewData.items);
    }
  };

  handleSelectItem = (value, item) => {
    if (value) {
      this.props.selectItems([item]);
    } else {
      this.props.deselectItem(item);
    }
  };

  handleItemClick = item => {
    if (item.previewImageUrl || item.smallPreviewImageUrl) {
      this.props.setFullScreenCurrentItems([item]);
      this.props.setFullScreenPreview(true);
    }
  };

  isContinueDisabled() {
    return (
      this.props.generationState.state !== 'SUCCESS' &&
      this.props.generationState.state !== 'CANCELLED'
    );
  }

  render() {
    const buttonsContainerBottom = (
      <div className="nav-buttons-container">
        <CancelButton ignoreConfirmation cancel={() => this.props.cancel()}>
          {'Back'}
        </CancelButton>
        <Button
          className={`button-cta large`}
          disabled={false}
          onClick={() => this.handleContinueButtonClick()}
        >
          Continue
        </Button>
      </div>
    );

    const generatingPreviewsModal = (
      <Dialogue
        title="Generating previews. Please wait..."
        isOpen={this.props.generatingModal.show}
        cancelClick={() => {
          return false;
        }}
      />
    );

    const downloadingModal = (
      <Dialogue
        title="Downloading Your Mockups"
        children={
          <>
            Part {this.props.downloadModal.current}/{this.props.downloadModal.total}
          </>
        }
        isOpen={this.props.downloadModal.show}
        cancelClick={() => {
          return false;
        }}
      />
    );

    const removeItemsModal = (
      <Dialogue
        isOpen={this.props.isRemoveModalVisible}
        cancelClick={() => this.props.showRemoveModal(false)}
        destructiveClick={() => this.props.removeItems(this.props.selectedItems)}
        actionText={'Delete'}
        title="Are you sure you want to remove all mockups?"
      />
    );

    const previewGeneration = this.props.generationState?.previewGeneration;
    const progressBarDialogue = previewGeneration && (
      <Dialogue isOpen={this.props.proPreviewModal.show} title="Processing...">
        Generating {previewGeneration.done} of {previewGeneration.from} mockups <br />
        <TrackingBar
          className="mt-3"
          current={previewGeneration.done}
          total={previewGeneration.from}
        />
      </Dialogue>
    );

    const removeItemsNotification = (
      <ToastModal
        show={this.props.removeItemsModalData.show}
        title={`${this.props.removeItemsModalData.numberOfRemovedItems}
        mockup${this.props.removeItemsModalData.numberOfRemovedItems > 1 ? 's' : ''} removed.`}
        duration={15}
        butonTitle="Undo"
        clickOnButton={this.props.restoreLastDeletedItems}
        onShowNotification={() => this.props.resetNotificationState()}
        fullScreen={this.props.isMaximized}
        zIndex={1001}
      />
    );

    const shopifyImagesCountNofitication = (
      <ToastModal
        show={this.props.shopifyImagesCountModalData.show}
        title={`Shopify allows up to 250 product images. You have ${this.props.previewData.items.length}.     `}
        duration={20}
        butonTitle="Learn more"
        onShowNotification={() => this.props.resetNotificationState()}
        fullScreen={this.props.isMaximized}
        type="info"
        url="https://support.gooten.com/support/solutions/articles/
        36000088444-why-can-t-i-publish-more-than-250-images-for-my-product-"
      />
    );

    return (
      <div
        className={`container component-product-preview
        ${this.props.isExclusiveMockup && !this.props.isEditMode ? ' pro-preview-mode' : ''}`}
      >
        {removeItemsNotification}
        {shopifyImagesCountNofitication}
        {generatingPreviewsModal}
        {removeItemsModal}
        {progressBarDialogue}
        {downloadingModal}
        <div>
          <PanelLayout
            collapsed={this.props.isMaximized}
            togglePanel={() => {
              // if (!this.props.isExclusiveMockup && this.props.isMaximized) {
              if (this.props.isMaximized) {
                this.props.enableEditMode(true);
                this.props.updateEditState(this.props.previewData);
              }
              this.props.toggleMaximize(this.props.isMaximized);
            }}
            backButtonLabel={this.props.isEditMode ? 'Cancel' : 'Back'}
            nextButtonLabel={this.props.isEditMode ? 'Done Editing' : 'Continue'}
            onNextButtonClick={
              this.props.isEditMode ? this.handleDoneEditingClick : this.handleContinueButtonClick
            }
            onBackButtonClick={
              this.props.isEditMode ? this.handleCancelEditingClick : this.props.cancel
            }
            nextButtonDisabled={this.isContinueDisabled()}
            ignoreBackConfirmation
          >
            <MainPanel
              key="content"
              items={this.props.previewData.items}
              onEditClick={this.handleEditClick}
              onSelectAll={this.handleSelectAll}
              onClickItem={this.handleItemClick}
              onSelectItem={this.handleSelectItem}
              isEditMode={this.props.isEditMode}
              isMaximized={this.props.isMaximized}
              isExclusiveMockup={this.props.isExclusiveMockup}
              selectedItems={this.props.selectedItems}
              restoreItems={this.props.restoreAllItems}
              maxNumberOfItems={MAX_NUMBER_OF_ITEMS}
              exceedMaxItemsMessage="250 IMAGE MAX    You can drag and drop to rearrange your images.
                Anything below this line won’t be published to Shopify."
            />
            <SidePanel key="panel" />
          </PanelLayout>
          {this.props.isMaximized ? buttonsContainerBottom : null}
          {this.props.isFullScreenPreview ? (
            <FullScreenPreview
              items={this.props.previewData.items}
              onCloseFullScreenPreview={() => this.props.setFullScreenPreview(false)}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default ProductPreviewComponent;
