import { INIT, GOTO, NEXT, BACK } from './NavManagerActions';
import { RESET } from '../../store/actions/globalActions';
import { List, Map } from 'immutable';

const defaultState = new Map({
  current: '',
  steps: new List([])
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [INIT]: (state, action) =>
    state
      .set('steps', new List(action.payload))
      .set('current', state.get('current') || action.payload[0]),

  [GOTO]: (state, action) => state.set('current', action.payload),

  [NEXT]: (state, action) =>
    state.set(
      'current',
      state.get('steps').getIn([state.get('steps').indexOf(state.get('current')) + 1])
    ),

  [BACK]: (state, action) =>
    state.set(
      'current',
      state.get('steps').getIn([state.get('steps').indexOf(state.get('current')) - 1])
    ),

  [RESET]: (state, action) => state.set('current', '').set('steps', new List())
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
