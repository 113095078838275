import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CopyOrChange from 'gooten-components/src/components/shared/CopyOrChange';
import OrderDetailsService from 'gooten-components/src/services/orderDetailsService';
import { changeTrackingAsync } from '../../OrdersViewActions';
import SecurityService from '../../../../Settings/SecurityService';

const ChangeTrackingComponent = ({ changeTrackingNumber, item, onSave }) => {
  const isEditable = SecurityService.isSuperAdmin() || SecurityService.isVendor();

  return (
    <div className="change-tracking">
      <CopyOrChange
        isEditable={isEditable}
        initialValue={item.TrackingNumber}
        onSave={async v => {
          if (v !== item.TrackingNumber) {
            await changeTrackingNumber(item.OrderItemId, v);
          }
          onSave && onSave(v);
        }}
      />
    </div>
  );
};

ChangeTrackingComponent.propTypes = {
  changeTrackingNumber: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func
};

export default ChangeTrackingComponent;
