import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view-if-needed';
import Spinner from '../../../../Spinner';
import { Container } from './ImageItemStyle';
import OptionsModal from '../../../../OptionsModal';
import './ImageItem.scss';
import Button from '../../../../Button';
import Icon from '../../../../Icon';
import Tooltip from '../../../../Tooltip';

export const getImageType = imageType => {
  const types = [
    {
      type: 'png',
      name: 'PNG',
      background: '#F4F4F5',
      color: '#373F49'
    },
    {
      type: 'jpg',
      name: 'JPG',
      background: '#F4F4F5',
      color: '#373F49'
    },
    {
      type: 'jpeg',
      name: 'JPG',
      background: '#F4F4F5',
      color: '#373F49'
    },
    {
      type: 'bmp',
      name: 'BMP',
      background: '#F4F4F5',
      color: '#373F49'
    },
    {
      type: 'emb',
      name: 'EMB',
      background: '#DBF0E2',
      color: '#317247'
    },
    {
      type: 'dst',
      name: 'DST',
      background: '#F4F4F5',
      color: '#373F49'
    }
  ];
  return types.find(o => imageType && imageType.toLowerCase().includes(o.type));
};

const ImageItem = props => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageType, setImageType] = useState(null);

  useEffect(() => {
    setImageType(getImageType(props.imageType));
  }, [props.imageUrl]);

  const options = ['View Full-size', 'Delete'];

  const handleSelectModalOption = action => {
    switch (action) {
      case 'viewfull-size':
        window.open(props.url || '', '_blank');
        break;
      case 'delete':
        props.handleDelete();
        break;
    }
  };

  const imageTypeJSX = () => {
    const imageTypeDIV = type => (
      <div className="image-type" style={{ color: type.color, background: type.background }}>
        {type.name}
      </div>
    );

    if (imageType.type === 'dst') {
      return (
        <>
          {imageTypeDIV(getImageType('png'))}
          {imageTypeDIV(imageType)}
        </>
      );
    } else {
      return imageTypeDIV(imageType);
    }
  };

  return (
    <Container
      className={`image-item ${props.customClass ? props.customClass : ''}`}
      imageLoaded={imageLoaded}
      onClick={props.isMobile ? props.handleSelect : null}
    >
      <div className={`image-item-holder ${props.selected ? 'selected' : ''} d-flex flex-column`}>
        <div className="image-placeholder flex-container">
          <img
            className="item-image"
            src={props.imageUrl || ''}
            onLoad={() => setImageLoaded(true)}
          />
          <Spinner />
        </div>
        <div className="image-data d-flex">
          <div className="d-flex flex-column">
            <div className="image-res">{props.imageRes || ''}</div>
            {imageType ? (
              <Tooltip
                // positioning below is to keep tooltip inside modal
                position={
                  props.customClass === 'col-md-4 col-sm-6 col-xs-6'
                    ? props.number % 3 === 0
                      ? 'right'
                      : 'top'
                    : props.number % 2 === 0
                    ? 'right'
                    : 'top'
                }
                disabled={imageType.type !== 'emb'}
                content="A stitch file for this image has already been created and is ready for use"
              >
                {imageTypeJSX()}
              </Tooltip>
            ) : null}
          </div>
          <Button
            className="button-default small"
            iconOnly
            small
            onClick={() => {
              setShowModal(true);
              setTimeout(() => {
                if (document.querySelector('.options-modal')) {
                  scrollIntoView(document.querySelector('.options-modal'), {
                    scrollMode: 'if-needed',
                    block: 'nearest'
                  });
                }
              }, 200);
            }}
          >
            <Icon icon="moreVertical" />
          </Button>
        </div>
        {/* <div className='image-title'>{props.title}</div> */}
      </div>
      {!props.isMobile ? (
        <Button
          disabled={props.disabled}
          className="button-secondary large image-item-select dropshadow-1"
          onClick={props.handleSelect}
        >
          Select
        </Button>
      ) : null}
      {showModal ? (
        <OptionsModal
          items={options}
          onClickItem={handleSelectModalOption}
          onDismiss={() => setShowModal(false)}
        />
      ) : null}
    </Container>
  );
};

ImageItem.propTypes = {
  imageRes: PropTypes.string,
  imageUrl: PropTypes.string,
  imageType: PropTypes.string,
  url: PropTypes.string,
  customClass: PropTypes.string,
  handleSelect: PropTypes.func,
  handleDelete: PropTypes.func,
  selected: PropTypes.bool,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  number: PropTypes.number
};

export default ImageItem;
